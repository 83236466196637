import { Company } from './company';
import { type OfficeLocation } from './office_location';
import { type Tag } from './tag';

export enum Remote {
  None = 'NONE',
  Full = 'FULL',
  Partial = 'PARTIAL',
  Global = 'GLOBAL',
}

export class Job {
  id: string;
  isNew: boolean;
  company: Company;
  location: string;
  experienceLevelLabel: string;
  experienceMin: string;
  experienceMax: string;
  mustHaveSkills: Tag[];
  remote: Remote;
  salary: string;
  rate: string;
  slug: string;
  tags: Tag[];
  title: string;
  locationIcon: string;
  officeLocations: OfficeLocation[];
  remotePolicy: string;
  timezoneTolerance: string;
  stateName: string;
  rateAndSalary: boolean;
  hiringBonus: number;
  countryCode: string;
}
