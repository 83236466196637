import React from 'react';
import { Assessment } from './models';
import TestCardItem from './test_card_item';
const styles = require('./test_cards_list.module.scss');

interface Props {
  standardTests: Assessment[];
  handleTestIdSelect: Function;
  selectedTest: number;
}

export default function TestCardsList(props: Props) {
  function renderCardsList() {
    return props.standardTests.map(card => (
      <TestCardItem
        technicalTest={card}
        key={card.attributes.id}
        handleTestIdSelect={props.handleTestIdSelect}
        selectedTest={props.selectedTest}
      />
    ));
  }

  return (
    <div className={styles.container}>
      {props.standardTests.length > 0 && (
        <div className={styles.title}>
          Select a test:<span className={styles.required}> *</span>
        </div>
      )}
      {renderCardsList()}
    </div>
  );
}
