export function getJSON(url, options = {}) {
  const params = Object.assign({}, options);
  setIfUndef(params, 'credentials', 'same-origin');
  setIfUndef(params, 'headers', {});
  setIfUndef(params['headers'], 'Accept', 'application/json');
  return fetch(url, params).then(response => response.json());
}

export function postFormData(url, body) {
  return fetch(url, {
    method: 'POST',
    headers: {
      'X-CSRF-Token': getCSRFToken(),
      credentials: 'same-origin',
    },
    body,
  }).then(response => response.json());
}

export function postJSON(url, body?, options = {}) {
  return writeRequest('POST', url, body, options);
}

export function deleteJSON(url, body?, options = {}) {
  return writeRequest('DELETE', url, body, options);
}

export function patchJSON(url, body?, options = {}) {
  return writeRequest('PATCH', url, body, options);
}

export function writeRequest(method, url, body?, options = {}) {
  const params = { method, body: JSON.stringify(body), ...options };
  setIfUndef(params, 'headers', {});
  setIfUndef(params['headers'], 'Content-Type', 'application/json');
  params['headers']['X-CSRF-Token'] = getCSRFToken();
  return getJSON(url, params);
}

export function handleRequestError(e) {
  if (e.alert) {
    window.Alerts && window.Alerts.alert(e.alert);
  } else {
    window.Alerts &&
      window.Alerts.alert('Oops, something went wrong. Please refresh the screen and try again.');
  }
  window.toggleSpinner && window.toggleSpinner(false);
}

export function handleSuccessAlert(e) {
  if (e.notice) {
    window.Alerts && window.Alerts.notice(e.notice);
  } else if (e.alert) {
    window.Alerts && window.Alerts.alert(e.alert);
  }
}

function setIfUndef(object, key, value) {
  const current = object[key];

  if (current === undefined) {
    return (object[key] = value);
  } else {
    return current;
  }
}

export function getCSRFToken() {
  const element = document.querySelector('meta[name=csrf-token]');
  const token = element && element.getAttribute('content');
  return token;
}
