import React from 'react';
import { shallow } from 'enzyme';
import AboutSection from './AboutSection';

describe('AboutSection', () => {
  it('shallow renders without crashing', () => {
    const wrapped = shallow(<AboutSection companySizes={[]} industries={[]} />);
    expect(wrapped.exists()).toBe(true);
  });
});
