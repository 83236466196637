import * as React from 'react';
import Button from './button';
import { shallow, ShallowWrapper } from 'enzyme';

let wrapped: ShallowWrapper;

describe('Button component', () => {
  beforeEach(() => {
    wrapped = shallow(<Button onClick='' />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
