import React from 'react';
import FormInput from 'components/general/form_input/form_input';
import CVUploader from 'components/general/cv_uploader/cv_uploader';
import { Cv } from '__models__/cv';

export interface Props {
  error: string;
  cvs: Cv[];
  handleChangeCV: any;
}

export default function CvInput(props: Props) {
  return (
    <FormInput name='cvFileName' label='Upload your CV' error={props.error}>
      <CVUploader cvs={props.cvs} handleChangeCV={props.handleChangeCV} replace />
    </FormInput>
  );
}
