import classNames from 'classnames';
import { uniqBy } from 'lodash';
import * as React from 'react';
import '../../lib/globals';

import '../conversations/stylesheets/chat.scss';

import * as Models from '../conversations/components/models';
import ConversationsList from '../conversations/components/conversations_list';
import Conversation from '../conversations/components/conversation';
import ChatLoading from '../conversations/components/chat_loading';
import { CableConsumer } from '../../lib/cable_consumer';
import { browserNotify } from '../../lib/browser_notification';
import { getJSON, handleRequestError } from '../../lib/request_deprecated';
import { screenTooSmall, onloadPrepareButtons } from '../conversations/chat_functions';
import ConversationSidebar from '../conversations/components/conversation_sidebar';

export interface Props {
  conversations_url: string;
  create_conversation_url: string;
  current_user_id: number;
  find_conversation_url: string;
  get_notes_url: string;
  hide_mini_chat?: boolean;
  messageable_id: string;
  messageable_type: string;
  messages_url: string;
  mini: boolean;
  note_destroy_url: string;
  notes_url: string;
  other_contacts_url: string;
  unread_counts: object;
  user_type: string;
}

interface State {
  contactsQuery: string;
  conversations: Models.Conversation[];
  otherContacts: Models.Messageable[];
  otherContactsPage: number;
  otherContactsLastPage: boolean;
  loading: boolean;
  selected: Models.Messageable;
  sidebarOpen: boolean;
  notes: Models.Note[];
  isMobile: boolean;
  unreadCounts: object;
  conversationListMinimized: boolean;
  conversationMinimized: boolean;
}

export default class Chat extends React.Component<Props, State> {
  static instance;

  readonly ID_KEY = 'chat_messageableId';
  readonly TYPE_KEY = 'chat_messageableType';
  readonly CONVERSATION_LIST_MINIMIZED_KEY = 'chat_conversationsListMinimized';
  readonly CONVERSATION_MINIMIZED_KEY = 'chat_conversationMinimized';
  conversationRef: any;
  fetchController: any;
  subscription: any;

  constructor(props: Props) {
    super(props);
    Chat.instance = this;
    this.state = {
      contactsQuery: '',
      conversations: null,
      otherContacts: [],
      otherContactsPage: 0,
      otherContactsLastPage: false,
      selected: null,
      loading: true,
      sidebarOpen: false,
      notes: [],
      isMobile: false,
      unreadCounts: this.props.unread_counts || {},
      conversationListMinimized:
        localStorage.getItem(this.CONVERSATION_LIST_MINIMIZED_KEY) !== 'false',
      conversationMinimized: localStorage.getItem(this.CONVERSATION_MINIMIZED_KEY) !== 'false',
    };
    this.handleConversationChange = this.handleConversationChange.bind(this);
    this.handleConversationClose = this.handleConversationClose.bind(this);
    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.getOtherContacts = this.getOtherContacts.bind(this);
    this.messagesUrl = this.messagesUrl.bind(this);
    this.findConversationUrl = this.findConversationUrl.bind(this);
    this.handleNewConversation = this.handleNewConversation.bind(this);
    this.handleUpdateNotification = this.handleUpdateNotification.bind(this);
    this.setConversationRef = this.setConversationRef.bind(this);
    this.handleConversationListMinimize = this.handleConversationListMinimize.bind(this);
    this.handleConversationMinimizeClick = this.handleConversationMinimizeClick.bind(this);
    this.fetchController = null;
  }

  componentDidMount() {
    const { mini } = this.props;
    this.connectToCable();

    if (!mini || (mini && !this.state.conversationListMinimized)) {
      this.getConversations()
        .then(() => {
          this.getOtherContacts();
        })
        .catch(_e => handleRequestError);
    }

    if (mini) {
      onloadPrepareButtons();
    }

    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    this.subscription && this.subscription.unsubscribe();
  }

  updateWindowDimensions = () => {
    this.setState({ isMobile: window.innerWidth < 993 });
  };

  componentDidUpdate(_prevProps, prevState) {
    localStorage.setItem(
      this.CONVERSATION_LIST_MINIMIZED_KEY,
      this.state.conversationListMinimized.toString()
    );
    localStorage.setItem(
      this.CONVERSATION_MINIMIZED_KEY,
      this.state.conversationMinimized.toString()
    );
    const selected = this.state.selected;

    if (selected) {
      localStorage.setItem(this.ID_KEY, selected.id);
      localStorage.setItem(this.TYPE_KEY, selected.type);
    } else if (prevState.selected) {
      localStorage.removeItem(this.ID_KEY);
      localStorage.removeItem(this.TYPE_KEY);
    }

    if (
      prevState.conversationListMinimized !== this.state.conversationListMinimized &&
      !this.state.conversations
    ) {
      this.getConversations()
        .then(() => {
          this.getOtherContacts();
        })
        .catch(_e => handleRequestError);
    }
  }

  connectToCable() {
    const handler = { received: this.handleMessageReceived.bind(this) };
    this.subscription = CableConsumer.subscriptions.create(
      { channel: 'UserMessagesChannel' },
      handler
    );
  }

  handleConversationListMinimize() {
    this.setState({
      conversationListMinimized: !this.state.conversationListMinimized,
    });
  }

  handleConversationMinimizeClick() {
    this.setState({
      conversationMinimized: !this.state.conversationMinimized,
    });
  }

  handleMessageReceived(message) {
    if (message.sender_id !== this.props.current_user_id) {
      this.incrementUnreadCount(message.conversation_id);
    }

    if (this.conversationRef) {
      this.conversationRef.handleMessageReceived(message);
    }

    if (
      (document.hidden || !document.hasFocus()) &&
      message.sender_id !== this.props.current_user_id
    ) {
      this.showNotification(message);
    }
  }

  incrementUnreadCount(conversationId) {
    const count = (this.state.unreadCounts[conversationId] || 0) + 1;
    const unreadCounts = { ...this.state.unreadCounts };
    unreadCounts[conversationId] = count;
    this.setState({ unreadCounts });
  }

  showNotification(message) {
    const title = `New message from ${message.sender_name}`;
    const tag = `Message:${message.id}`;
    const onClick = () => this.setSelectedConversation(message.conversation_id.toString());

    browserNotify({ body: message.body, tag, title, onClick });
  }

  initialSelected() {
    let id = this.props.messageable_id;
    let type = this.props.messageable_type;

    if (id && type) return { id, type };

    id = localStorage.getItem(this.ID_KEY);
    type = localStorage.getItem(this.TYPE_KEY);

    if (this.props.mini && id && type) return { id, type };

    return { id: undefined, type: undefined };
  }

  setSelectedConversation(id) {
    const selected = this.state.conversations.find(c => c.id === id);
    if (selected) this.setState({ selected });
  }

  setSelectedTalentUser(id) {
    id = parseInt(id, 10);
    const matchId = m => m.attributes.talent_user_id === id;

    let selected;
    if (this.state.conversations) {
      selected = this.state.conversations.find(matchId);
    } else if (this.state.otherContacts) {
      selected = this.state.otherContacts.find(matchId);
    }

    if (selected) {
      this.setState({ selected });
    } else {
      this.getAndSelectPerson(id);
    }
  }

  static openConversationWithCandidate(id) {
    if (screenTooSmall()) {
      window.location = Chat.instance.findConversationUrl(id);
    } else if (Chat.instance.state.conversations) {
      Chat.instance.setSelectedTalentUser(id);
      Chat.instance.setState({ conversationMinimized: false });
    } else {
      Chat.instance
        .getConversations()
        .then(() => {
          Chat.instance.getOtherContacts();
          Chat.instance.setSelectedTalentUser(id);
          Chat.instance.setState({ conversationMinimized: false });
        })
        .catch(_e => handleRequestError);
    }
  }

  async getConversations() {
    this.setState({ loading: true });

    await getJSON(this.props.conversations_url).then(data => {
      const conversations = data.data;
      const initial = this.initialSelected();
      let selected = this.state.selected;

      if (initial.type === 'conversation') {
        selected = conversations.find(c => initial.id === c.id);

        if (!selected) {
          selected = conversations[0];
        }
      }

      const loading = this.props.mini;

      this.setState(() => {
        return {
          conversations,
          selected,
          loading,
        };
      });
    });
  }

  getAndSelectPerson(id) {
    this.setState({ loading: true });
    const params = { talent_user_id: id };

    getJSON(this.buildUrl(this.props.other_contacts_url, params)).then(data => {
      const contacts = data.data;
      this.setState(prevState => ({
        otherContacts: uniqBy(prevState.otherContacts.concat(contacts), 'id'),
        loading: false,
        selected: contacts[0],
      }));
    });
  }

  getOtherContacts() {
    if (!this.state.otherContactsLastPage && !this.fetchController) {
      this.setState({ loading: true });
      const page = this.state.otherContactsPage + 1;
      const query = this.state.contactsQuery;
      const params = { page, query };
      const initial = this.initialSelected();

      if (initial.type === 'person') {
        params['include_person_id'] = initial.id;
      }

      this.fetchController = $.get(this.buildUrl(this.props.other_contacts_url, params)).then(
        data => {
          const contacts = data.data;
          let selected = this.state.selected;
          if (!this.state.selected && initial.type === 'person') {
            selected = contacts.find(c => initial.id === c.id);
          }

          this.setState(
            prevState => {
              return {
                otherContacts: uniqBy(prevState.otherContacts.concat(contacts), 'id'),
                otherContactsPage: page,
                loading: false,
                otherContactsLastPage: contacts.length === 0,
                selected,
              };
            },
            () => {
              this.fetchController = null;
            }
          );
        }
      );
    } else {
      this.setState({ loading: false });
    }
  }

  handleConversationChange(messageable: Models.Messageable) {
    if (this.state.selected !== messageable) {
      this.setState(() => {
        return {
          selected: messageable,
        };
      });
    }

    if (messageable) {
      const element = document.querySelector('.toggle-sidebar-icon') as HTMLElement;
      if (element) element.style.display = 'block';
    }

    if (!this.props.mini && messageable && document.querySelector('.lj-conversation')) {
      (document.querySelector('.lj-conversation') as HTMLElement).style.display = 'flex';
    }
  }

  handleConversationClose() {
    this.handleConversationChange(null);
  }

  handleExpandClick() {
    let id;
    this.props.user_type === 'TalentUser'
      ? (id = { company_id: this.state.selected.attributes.company_id })
      : (id = { talent_id: this.state.selected.attributes.talent_user_id });
    const expandUrl = window.Routes.find_conversation(id);
    window.Turbolinks.visit(expandUrl);
  }

  handleNewConversation(messageable, message) {
    const data = {
      user_id: messageable.attributes.talent_user_id,
      body: message,
    };
    $.post(this.props.create_conversation_url, data).then(result => {
      const conversation = result.data;
      this.setState({
        conversations: [conversation].concat(this.state.conversations || []),
        otherContacts: this.state.otherContacts.filter(c => c !== messageable),
        selected: conversation,
      });
    });
  }

  handleUpdateNotification(id) {
    const unreadCounts = { ...this.state.unreadCounts };
    const notiDiv = $('.ld-inbox-icon .ld-unread-notifications-count');
    const topNotiNum = parseInt(notiDiv.text(), 10);
    if (unreadCounts[id] && topNotiNum === unreadCounts[id]) {
      notiDiv.hide();
    } else if (unreadCounts[id]) {
      notiDiv.text(`${topNotiNum - unreadCounts[id]}`);
    }
    delete unreadCounts[id];
    this.setState({ unreadCounts });
  }

  renderDeleteConfirmation(id) {
    const noteDelete = document.createElement('div');
    noteDelete.classList.add('lj-applicationNotes-note', 'note-trail');
    noteDelete.setAttribute('id', 'trail');
    noteDelete.innerHTML = 'Note successfully deleted';

    document.getElementById(`note-${id}`).after(noteDelete);

    setInterval(() => {
      $('#trail').fadeOut(() => $('#trail').remove());
    }, 5000);
  }

  render() {
    if (this.props.mini && screenTooSmall()) {
      return null;
    } else {
      return this.renderContent();
    }
  }

  renderContent() {
    const sidebarWidth = this.state.isMobile ? '100%' : '362px';
    const mobileSidebar = {
      width: sidebarWidth,
    };
    const desktopSidebar = {
      flexBasis: sidebarWidth,
    };
    const sidebarStyle = this.state.isMobile ? mobileSidebar : desktopSidebar;

    if (this.state.loading) {
      if (this.props.mini) {
        if (this.props.hide_mini_chat) {
          return null;
        } else {
          return (
            <div
              className={classNames('lj-chat', {
                'lj-chat--mini': this.props.mini,
              })}
            >
              <ConversationsList
                conversations={this.state.conversations || []}
                otherContacts={this.state.otherContacts}
                selected={this.state.selected}
                handleConversationChange={this.handleConversationChange}
                getOtherContacts={this.getOtherContacts}
                unread_counts={this.state.unreadCounts}
                userType={this.props.user_type}
                loading={this.state.loading}
                mini={this.props.mini}
                minimized={this.state.conversationListMinimized}
                handleMinimize={this.handleConversationListMinimize}
              />
            </div>
          );
        }
      } else {
        return <ChatLoading userType={this.props.user_type} />;
      }
    }

    return (
      <div
        className={classNames('lj-chat', {
          'lj-chat--mini': this.props.mini,
          'lj-chat--mini-hide-convo': this.props.hide_mini_chat,
        })}
      >
        {(!this.props.hide_mini_chat || !this.props.mini) && (
          <ConversationsList
            conversations={this.state.conversations || []}
            otherContacts={this.state.otherContacts}
            selected={this.state.selected}
            handleConversationChange={this.handleConversationChange}
            getOtherContacts={this.getOtherContacts}
            unread_counts={this.state.unreadCounts}
            userType={this.props.user_type}
            loading={this.state.loading}
            mini={this.props.mini}
            minimized={this.state.conversationListMinimized}
            handleMinimize={this.handleConversationListMinimize}
          />
        )}
        <Conversation
          handleConversationClose={this.handleConversationClose}
          handleExpandClick={this.handleExpandClick}
          messagesUrl={this.messagesUrl}
          findConversationUrl={this.findConversationUrl}
          messageable={this.state.selected}
          userType={this.props.user_type}
          currentUserId={this.props.current_user_id}
          handleNewConversation={this.handleNewConversation}
          handleUpdateNotification={this.handleUpdateNotification}
          mini={this.props.mini}
          minimized={this.state.conversationMinimized}
          handleMinimizeClick={this.handleConversationMinimizeClick}
          ref={this.setConversationRef}
        />
        <ConversationSidebar
          messageable={this.state.selected}
          notes_url={this.props.notes_url}
          note_destroy_url={this.props.note_destroy_url}
          get_notes_url={this.props.get_notes_url}
          userType={this.props.user_type}
          sidebarWidth={sidebarStyle}
          mini={this.props.mini}
        />
      </div>
    );
  }

  setConversationRef(element) {
    this.conversationRef = element;
  }

  buildUrl(base, params) {
    const query = Object.keys(params)
      .map(k => `${k}=${params[k]}`)
      .join('&');
    return `${base}?${query}`;
  }

  findConversationUrl(id) {
    return this.props.find_conversation_url.replace('~id', id);
  }

  messagesUrl(id) {
    return this.props.messages_url.replace(':id', id);
  }
}
