import React from 'react';
import ApplyButton from './ApplyButton';
import { Application, JobAd } from '__models__/models';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import { getJobApplication } from 'lib/application';
import ApplicationStartedToast from 'components/application_started_toast/application_started_toast';
import SubscribeButton from 'components/subscribeButton/SubscribeButton';

const styles = require('./Title.module.scss');

interface Props {
  applications: Application[];
  cancelReasons: any;
  companyId: number;
  companySlug: string;
  companyName: string;
  currentUser: any;
  fixedHeader: boolean;
  handshake: any;
  jobAd: JobAd;
  jobSuggestion: any;
  logo: string;
  referralId: number;
  shortPitch: string;
  title: string;
  jobSearchFiltersAb?: string;
  bookmark: any;
}

export default function Title(props: Props) {
  const companySlug = props.companySlug?.length > 0 ? props.companySlug : props.companyId;
  const application = getJobApplication(props.applications, props.jobAd);

  return (
    <>
      <div
        className={classNames(styles.wrapper, {
          [styles.fixedTitle]: props.fixedHeader,
        })}
      >
        <div
          className={classNames(styles.leftSide, {
            [styles.leftSidePadding]: props.fixedHeader,
          })}
        >
          <div className={styles.logo}>
            <img src={props.logo} alt={`${props.companyName} logo`} title={props.companyName} loading="lazy" />
          </div>
          <div className={styles.text}>
            <div className={styles.title}>{props.title || 'No title'}</div>
            <div className={styles.secondLine}>
              <a className={styles.company} href={window.Routes.company(companySlug)}>
                {props.companyName}
              </a>
              <div className={styles.pitch}>{props.shortPitch}</div>
            </div>
          </div>
        </div>
        <div className={styles.rightSide}>
          {props.fixedHeader && !props.handshake && !props.jobSuggestion && (
            <div className={styles.ctaContainer}>
              {application?.inDraftStates && <ApplicationStartedToast />}
              <div className={styles.actionButtons}>
                <ApplyButton
                  applications={props.applications}
                  buttonSize={isMobile ? 'small' : application?.inDraftStates ? 'mid' : 'large'}
                  cancelReasons={props.cancelReasons}
                  fixedHeader={props.fixedHeader}
                  currentUser={props.currentUser}
                  handshake={props.handshake}
                  jobAd={props.jobAd}
                  jobSuggestion={props.jobSuggestion}
                  referralId={props.referralId}
                  style={styles.applyButton}
                  jobSearchFiltersAb={props.jobSearchFiltersAb}
                />
                {!props.handshake && !props.jobSuggestion && (
                  <SubscribeButton
                    currentUser={props.currentUser}
                    job={{
                      id: props.jobAd.id,
                      title: props.jobAd.attributes.title,
                      stateName: props.jobAd.attributes.state_name,
                    }}
                    bookmark={props.bookmark}
                    style={styles.subscribeButton}
                    size='large'
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {!props.fixedHeader && <hr className={styles.line} />}
    </>
  );
}
