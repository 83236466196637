import React from 'react';
const styles = require('./rating_box.module.scss');
import { range } from 'lib/range';

export interface Props {
  classes?: string;
  value: any;
  ratingRef?: any;
  name?: string;
}

export default class RatingBox extends React.Component<Props> {
  render() {
    return (
      <>
        <select
          className={styles.hidden}
          name={this.props.name}
          key={(this.props.name || '') + this.props.value}
          ref={this.props.ratingRef}
          defaultValue={this.props.value}
        >
          <option />
          {range(1, 5).map(i => (
            <option key={i} value={i}>
              {i} / {5}
            </option>
          ))}
        </select>

        <div className={styles.box}>{this.props.value}/5</div>
      </>
    );
  }
}
