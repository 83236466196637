import React from 'react';
import ReactDOM from 'react-dom';
import DotLoader from 'lj_shared/dot_loader/dot_loader';
import Modal from '../general/modal/modal';
import Button from 'lj_shared/button/button';
import { getJSON, postJSON, handleRequestError } from '../../lib/request_deprecated';
import 'lib/globals';
import { TestResult as TestResultModel } from '__models__/models';
import { TestsItem } from './tests_item';
import { EvaluationItems } from './evaluation_items';

const styles = require('./send_test_modal.module.scss');

interface Props {
  sendTestAftermath: Function;
  stepId: number;
  personId: string;
  jobAdId: string;
  testInviteUrl: string;
  testCheckUrl: string;
}

interface State {
  loading: boolean;
  previousTest?: TestResultModel;
  hiringStep?: {
    test_name: string;
  };
}
export default class SendTestModal extends React.Component<Props, State> {
  modalRef: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
    };

    this.modalRef = React.createRef();
    this.handleSend = this.handleSend.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    getJSON(
      this.props.testCheckUrl +
        `?step_id=${this.props.stepId}` +
        `&person_id=${this.props.personId}` +
        `&job_ad_id=${this.props.jobAdId}`
    )
      .then(e => {
        this.setState({
          loading: false,
          previousTest: e.test,
          hiringStep: e.hiring_step,
        });
      })
      .catch(e => {
        // Basically if it fails we're showing the regular interface to the employer
        // There is no need to give not serious errors here
        // Everything will function normally even if there is a previous test
        this.setState({
          loading: false,
          previousTest: null,
        });
      });
  }

  handleSend() {
    window.toggleSpinner(true);
    postJSON(this.props.testInviteUrl, {
      step: this.props.stepId,
      person: this.props.personId,
      job: this.props.jobAdId,
    })
      .then(e => {
        if (e.notice) {
          window.Alerts.notice(e.notice);
          this.props.sendTestAftermath();
          this.handleCancel();
        } else {
          window.Alerts.alert(e.alert);
        }
        window.toggleSpinner(false);
      })
      .catch(e => {
        handleRequestError(e);
        window.toggleSpinner(false);
      });
  }

  handleCancel() {
    this.modalRef.current.close();
  }

  renderModalWithTest() {
    return (
      <Modal title='Send Technical Test' defaultOpen={true} ref={this.modalRef}>
        <div id='send-test-modal'>
          This candidate has previously been invited to this test
          <EvaluationItems
            items={[
              {
                evaluationItem: TestsItem(
                  [this.state.previousTest],
                  this.state.hiringStep,
                  false,
                  false
                ),
              },
            ]}
          />
        </div>
        <br />
        <div className={styles.footer}>
          <div className={styles.buttons}>
            <Button
              buttonColor='ripePlum'
              buttonType='border'
              onClick={this.handleCancel}
              otherClasses={styles.button}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

  renderModalNoTest() {
    return (
      <Modal title='Send Technical Test' defaultOpen={true} ref={this.modalRef}>
        <div id='send-test-modal' className={styles.content}>
          Would you like to send the Technical Test to the candidate?
        </div>
        <br />
        {this.state.previousTest && (
          <div className={styles.note}>
            Note: You can Cancel and send the test later in this Candidate’s Application Page.
          </div>
        )}
        <div className={styles.footer}>
          <div className={styles.buttons}>
            <Button buttonColor='ripePlum' onClick={this.handleSend} otherClasses={styles.button}>
              Send Test
            </Button>
            <Button
              buttonColor='ripePlum'
              buttonType='border'
              onClick={this.handleCancel}
              otherClasses={styles.button}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
  render() {
    if (this.state.loading) {
      return (
        <Modal title='Send Technical Test' defaultOpen={true} ref={this.modalRef}>
          <DotLoader />;
        </Modal>
      );
    } else {
      if (this.state.previousTest) {
        return this.renderModalWithTest();
      } else {
        return this.renderModalNoTest();
      }
    }
  }

  static show(testInviteUrl, testCheckUrl, stepId, personId, jobAdId, sendTestAftermath) {
    const root = $('<div></div>');

    $('body').append(root);

    ReactDOM.render(
      <SendTestModal
        testInviteUrl={testInviteUrl}
        testCheckUrl={testCheckUrl}
        stepId={stepId}
        personId={personId}
        jobAdId={jobAdId}
        sendTestAftermath={sendTestAftermath}
      />,
      root[0]
    );
  }
}
