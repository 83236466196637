import React from 'react';
interface Props {
  stepClosed: boolean;
  step: any[];
}

export default class MobileText extends React.Component<Props> {
  render() {
    return (
      <div>
        {this.props.stepClosed && (
          <div className='descriptionMobile'>
            <div>{this.props.step[1]}</div>
          </div>
        )}
      </div>
    );
  }
}
