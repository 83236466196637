import React from 'react';

import * as Models from '__models__/gql/all';
import Icon, { Size } from 'components/general/icon/icon';
const styles = require('./MetaTags.module.scss');

interface Props {
  company: Partial<Models.Company>;
}

export default function MetaTags({ company }: Props) {
  const { city, companySize, countryCode, countryName, industry, glassdoorUrl, rating, websites } =
    company;
  const websiteUrl = simplifyUrl(company.websiteUrl || '');

  return (
    <div className={styles.metaTags}>
      <MetaTag
        icon='location'
        internalUrl={true}
        text={formatLocation(countryName, city)}
        url={window.Routes.job_ads({ city, country_code: countryCode })}
      />
      <MetaTag icon='link' text={websiteUrl} url={company.websiteUrl} />
      {companySize && (
        <MetaTag
          icon='people'
          internalUrl={true}
          text={companySize?.label}
          url={window.Routes.companies({ cs: companySize.id })}
        />
      )}
      {industry && (
        <MetaTag
          icon='briefcase'
          internalUrl={true}
          text={industry?.name}
          url={window.Routes.companies({ in: industry.id })}
        />
      )}
      <MetaTag
        addon={<GlassdoorLink url={glassdoorUrl} />}
        icon='star'
        text={rating?.toString()}
        url={glassdoorUrl}
      />
      <HeaderLinks websites={websites} />
    </div>
  );
}

interface MetaTagProps {
  addon?: JSX.Element;
  icon: string;
  internalUrl?: boolean;
  text?: string;
  url: string;
}

function MetaTag({ addon, icon, internalUrl, text, url }: MetaTagProps) {
  const urlProps = internalUrl ? {} : { rel: 'noopener', target: '_blank' };

  if (text) {
    return (
      <span className={styles.metaTag}>
        <Icon className={styles.metaTagIcon} name={icon} />
        <a className={styles.metaTagLink} href={url} {...urlProps}>
          {text}
        </a>
        {addon}
      </span>
    );
  } else {
    return null;
  }
}

function GlassdoorLink({ url }) {
  return (
    <a className={styles.glassdoorLink} href={url} rel='noopener noreferrer' target='_blank'>
      <img
        className={styles.glassdoorLogo}
        src='https://assets.landing.jobs/cms-assets/files/381/original/gd-logo-80.png'
        title='Glassdoor logo'
        alt='Glassdoor logo'
      />
    </a>
  );
}

function formatLocation(country, city) {
  if (country && city) {
    return `${city}, ${country}`;
  } else if (country) {
    return country;
  }
}

function simplifyUrl(url) {
  return url.replace(/https?:\/\/(.+)/, '$1').replace(/\/$/, '');
}

function HeaderLinks({ websites }) {
  return (
    <div className={styles.websites}>
      {websites &&
        websites.map(website => {
          const category = website.category;

          return (
            <a
              key={website.id}
              href={website.url}
              target='_blank'
              title={category}
              rel='noreferrer'
            >
              <Icon name={category} className={styles.socialIcon} size={category == 'twitter' ? Size.Small : Size.MediumLarge} />
            </a>
          );
        })}
    </div>
  );
}
