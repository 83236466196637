import React from 'react';
import { shallow } from 'enzyme';
import SelfEvaluation from './selfEvaluation';

const SelfEvaluationDummyData = {
  must_have_skills: [
    { id: 220, name: 'PHP' },
    { id: 26194, name: 'REST APIs' },
    { id: 7328, name: 'Go' },
  ],
  nice_to_have_skills: [
    { id: 472, name: 'Test Driven Development' },
    { id: 62420, name: 'bamboo' },
    { id: 3295, name: 'JIRA' },
  ],
  other_required_skills: [
    { id: 62364, name: 'Postman' },
    { id: 62764, name: 'Behat' },
    { id: 488, name: 'JSON' },
    { id: 552, name: 'PostgreSQL' },
    { id: 62835, name: 'YAML' },
  ],
  self_evaluations: [
    { tag_id: 220, score: 7 },
    { tag_id: 7328, score: 4 },
  ],
  application_id: 275427,
};
let wrapped;

describe('SelfEvaluation', () => {
  it('shallow renders without crashing', () => {
    wrapped = shallow(<SelfEvaluation self_evaluation_data={SelfEvaluationDummyData} />);
    expect(wrapped.exists()).toBe(true);
  });
});
