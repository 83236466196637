import gql from 'graphql-tag';
import { doTrack } from '../tracking';
import { serializeSkillsState } from '../skills_dimension';

export const CURATE_GQL = gql`
  mutation (
    $applicationId: ID!
    $automaticNotes: [[String!]!]!
    $bonusConcern: String!
    $dataNames: [String!]!
    $dataValues: [String!]!
    $handpicked: Boolean
    $notes: String
    $ratingNames: [String!]!
    $ratingOverall: Float!
    $ratingValues: [Int!]!
    $rejection: Boolean!
    $rejectionCandidateMessage: String
    $rejectionLandingMessage: String
  ) {
    curate(
      applicationId: $applicationId
      automaticNotes: $automaticNotes
      bonusConcern: $bonusConcern
      dataNames: $dataNames
      dataValues: $dataValues
      handpicked: $handpicked
      notes: $notes
      ratingNames: $ratingNames
      ratingOverall: $ratingOverall
      ratingValues: $ratingValues
      rejection: $rejection
      rejectionCandidateMessage: $rejectionCandidateMessage
      rejectionLandingMessage: $rejectionLandingMessage
    ) {
      errors {
        field
        message
      }
    }
  }
`;

export const GET_OVERALL_GQL = gql`
  query ($ratings: [RatingInput!]!) {
    appRatingComputation(ratings: $ratings)
  }
`;

export const DIMENSIONS = [
  { name: 'location', label: 'Location Match' },
  { name: 'language', label: 'Language Match' },
  { name: 'skills', label: 'Skills Match' },
  { name: 'experience', label: 'Experience Level Match' },
  { name: 'info', label: 'Additional Information' },
  { name: 'overall', label: 'Overall' },
];

export const LABELS = [
  { value: 'skills', label: 'Skills' },
  { value: 'experience_level', label: 'Experience' },
  { value: 'location', label: 'Location' },
  { value: 'language', label: 'Language' },
  { value: 'other', label: 'Other' },
];

export const CHECKBOX_LABELS = {
  skills: {
    negative: [
      'Doesn’t have enough years of experience in a Must Have skill.',
      'Doesn’t have enough years of experience in a specific skill.',
    ],
    positive: ['Has relevant knowledge in a related skill.'],
  },
  experience_level: {
    negative: [
      "Doesn't have work experience on on-site jobs.",
      'Changes jobs too often.',
      'Has very difficult statements to background check.',
      'Has/had a huge gap of unemployment.',
    ],
    positive: [
      'Has international experience.',
      'Has/had relevant personal development experiences.',
      'Worked in the same industry.',
      'Worked in renowned companies.',
    ],
  },
  location: {
    negative: ['Time difference of 3 or more hours.'],
    positive: ['The candidate is within a reasonable commuting distance.'],
  },
  language: {
    negative: ['Doesn’t speak a mandatory language.'],
    positive: ['Candidate speaks a Nice to Have language.'],
  },
  other: {
    negative: ['Has lots of typos on the CV.', 'Has unprofessional remarks on the CV or profile.'],
    positive: ['Has a good application letter/questions.'],
  },
};

export const TOTAL_STEPS = DIMENSIONS.length;
export const DONT_SHOW_TOUR_COOKIE = 'curator-dont_show_tour_modal';

export const handleOldModel = applicationId => {
  doTrack(applicationId, 'show-old-model');
};

export const isLast = step => step === TOTAL_STEPS;

function locationData(dataNames, dataValues, valuesData) {
  dataNames.push('location');
  dataValues.push(
    JSON.stringify({
      talent_location: valuesData['talent_location'],
      talent_city: valuesData['talent_city'],
      talent_country_code: valuesData['talent_country_code'],
      work_permit: valuesData['work_permit'],
      time_zone: valuesData['time_zone'],
      time_zone_negative: valuesData['time_zone_negative'],
    })
  );
}

function languageData(dataNames, dataValues, valuesData) {
  dataNames.push('language');
  dataValues.push(
    JSON.stringify({
      preferred_language: valuesData['preferred_language'],
      nice_to_have_language: valuesData['nice_to_have_language'],
    })
  );
}

function skillsData(dataNames, dataValues, skills) {
  dataNames.push('skills');
  dataValues.push(serializeSkillsState(skills));
}

function experienceData(dataNames, dataValues, valuesData) {
  dataNames.push('experience');
  let backgroundYears;
  if (!valuesData['no_relevant_background']) {
    backgroundYears = valuesData['background_years'];
  }
  dataValues.push(
    JSON.stringify({
      background_years: backgroundYears,
      same_role_performed: valuesData['same_role_performed'],
      no_relevant_background: valuesData['no_relevant_background'],
    })
  );
}

export function bonusConcernData(bonusConcern, bonusConcernInfo) {
  const text = bonusConcernInfo.checkbox
    ? bonusConcernInfo.checkbox.join(' ').concat(` ${bonusConcernInfo.note}`)
    : bonusConcernInfo.note;

  return JSON.stringify({
    concern_level: bonusConcern.slider,
    concern: bonusConcern.selected,
    concern_note: text,
  });
}

// group fields by dimension
export function computeDataNamesAndValues(dataNames, dataValues, valuesData, skills) {
  locationData(dataNames, dataValues, valuesData);
  languageData(dataNames, dataValues, valuesData);
  skillsData(dataNames, dataValues, skills);
  experienceData(dataNames, dataValues, valuesData);
}
