import * as React from 'react';
import StepColor from './step_color';
import { shallow, ShallowWrapper } from 'enzyme';

let wrapped: ShallowWrapper;

describe('Step Color component', () => {
  beforeEach(() => {
    wrapped = shallow(<StepColor slug='state-employer_reviewing' />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
