import React from 'react';
import { mount } from 'enzyme';
import MainCardContainer from './MainCardContainer';
import MainCardUtils from './MainCardUtils';
import profileDummy from '__fixtures__/profile_dummy';

let wrapped;

window.Routes = {
  resume: () => '',
  employer_sourcing_person_resume: () => '',
};

const incompleteFields = [
  { name: 'Relocation', star: false },
  { name: 'Phone Number', star: true },
];

let handshakeProfile: boolean;
let recentlyUpdated: boolean;
let unlocked: boolean;
let mainCard: any;
let isProfilePublic: boolean;
let publicProfilePage: boolean;
let publicLink: string;

const mainCardProps = () => {
  return MainCardUtils(profileDummy, 80, {
    recentlyUpdated,
    unlocked,
    handshakeProfile,
    isProfilePublic,
    publicLink,
    incompleteFields,
    personId: '1235',
  });
};

describe('Handshake profile is locked and recently updated', () => {
  beforeAll(() => {
    handshakeProfile = true;
    recentlyUpdated = true;
    isProfilePublic = false;
    publicProfilePage = false;
    unlocked = false;
    publicLink = 'abcd1234';
    mainCard = mainCardProps();

    wrapped = mount(
      <MainCardContainer
        avatarProps={mainCard.avatarProps}
        bioProps={mainCard.bioProps}
        cardHeaderProps={mainCard.cardHeaderProps}
        openModal={jest.fn()}
        skillsProps={mainCard.skillsProps}
        handshakeProfile={handshakeProfile}
        publicProfilePage={publicProfilePage}
      />
    );
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('does not show a copy public profile tag', () => {
    expect(wrapped.find('.publicShareLink').exists()).toBeFalsy();
  });

  it('shows a recently updated tag', () => {
    expect(wrapped.find('.tag.puertoRico').text()).toMatch(/Recently Updated/);
  });

  it('shows blurred links', () => {
    expect(wrapped.find('.rightLinks .blur')).toHaveLength(1);
  });

  it('does not have enabled links in <a> tags', () => {
    expect(wrapped.find('.rightLinks a').first()).toHaveLength(0);
  });

  it('does not show an edit button', () => {
    expect(wrapped.find('.editHeadline').exists()).toBeFalsy();
  });
});

describe('Handshake profile is unlocked and not recently updated', () => {
  beforeAll(() => {
    handshakeProfile = true;
    recentlyUpdated = false;
    isProfilePublic = false;
    unlocked = true;
    publicProfilePage = false;
    publicLink = 'abcd1234';
    mainCard = mainCardProps();

    wrapped = mount(
      <MainCardContainer
        avatarProps={mainCard.avatarProps}
        bioProps={mainCard.bioProps}
        cardHeaderProps={mainCard.cardHeaderProps}
        openModal={jest.fn()}
        skillsProps={mainCard.skillsProps}
        handshakeProfile={handshakeProfile}
        publicProfilePage={publicProfilePage}
      />
    );
  });

  it('does not show a copy public profile tag', () => {
    expect(wrapped.find('.publicShareLink').exists()).toBeFalsy();
  });

  it('does not show a recently updated tag', () => {
    expect(wrapped.find('.tag.puertoRico').exists()).toBeFalsy();
  });

  it('is not blurred', () => {
    expect(wrapped.find('.rightLinks .blur')).toHaveLength(0);
  });

  it('has enabled links in <a> tags', () => {
    expect(wrapped.find('.rightLinks a').first()).toHaveLength(1);
  });

  it('does not show an edit button', () => {
    expect(wrapped.find('.editHeadline').exists()).toBeFalsy();
  });
});

describe('Talent User profile page', () => {
  beforeAll(() => {
    handshakeProfile = false;
    isProfilePublic = true;
    recentlyUpdated = true;
    unlocked = true;
    publicProfilePage = false;
    publicLink = 'abcd1234';
    mainCard = mainCardProps();

    const supportedCommands = ['copy'];
    Object.defineProperty(document, 'execCommand', {
      value: cmd => supportedCommands.includes(cmd),
    });

    wrapped = mount(
      <MainCardContainer
        avatarProps={mainCard.avatarProps}
        bioProps={mainCard.bioProps}
        cardHeaderProps={mainCard.cardHeaderProps}
        openModal={jest.fn()}
        skillsProps={mainCard.skillsProps}
        handshakeProfile={handshakeProfile}
        publicProfilePage={publicProfilePage}
      />
    );
  });

  it('shows a copy public profile tag', () => {
    expect(wrapped.find('.publicShareLink').exists()).toBeTruthy();
  });

  it('does not show a recently updated tag', () => {
    expect(wrapped.find('.tag.puertoRico').exists()).toBeFalsy();
  });

  it('is not blurred', () => {
    expect(wrapped.find('.rightLinks .blur')).toHaveLength(0);
  });

  it('shows an edit button', () => {
    expect(wrapped.find('.editHeadline').exists()).toBeTruthy();
  });
});

describe('Public profile can be seen and is blurred', () => {
  beforeAll(() => {
    handshakeProfile = false;
    recentlyUpdated = true;
    isProfilePublic = true;
    publicProfilePage = true;
    unlocked = true;
    publicLink = 'abcd1234';
    mainCard = mainCardProps();

    wrapped = mount(
      <MainCardContainer
        avatarProps={mainCard.avatarProps}
        bioProps={mainCard.bioProps}
        cardHeaderProps={mainCard.cardHeaderProps}
        openModal={jest.fn()}
        skillsProps={mainCard.skillsProps}
        handshakeProfile={handshakeProfile}
        publicProfilePage={publicProfilePage}
      />
    );
  });

  it('does not show a copy public profile tag', () => {
    expect(wrapped.find('.publicShareLink').exists()).toBeFalsy();
  });

  it('does not show a recently updated tag', () => {
    expect(wrapped.find('.tag.puertoRico').exists()).toBeFalsy();
  });

  it('shows blurred links', () => {
    expect(wrapped.find('.rightLinks .blur')).toHaveLength(1);
  });

  it('does not have enabled links in <a> tags', () => {
    expect(wrapped.find('.rightLinks a').first()).toHaveLength(0);
  });

  it('does not show an edit button', () => {
    expect(wrapped.find('.editHeadline').exists()).toBeFalsy();
  });
});
