const curatorsApplicationDummy = {
  data: {
    id: '174782',
    type: 'curators_application',
    attributes: {
      application_letter:
        '<p><span data-mce-style="vertical-align: inherit;" style="vertical-align: inherit;"><span data-mce-style="vertical-align: inherit;" style="vertical-align: inherit;">Dear Recruiter,</span></span></p>',
      application_questions: null,
      cancel_reason: null,
      cancelled: false,
      close_detail: null,
      close_reason: null,
      closed: false,
      company_account_manager_notes: [
        {
          admin_user: 'Kirito',
          note_date: '2019-08-08T15:45:42.898Z',
          note_text: 'text',
        },
        {
          admin_user: 'Asuna',
          note_date: '2019-08-09T15:45:42.898Z',
          note_text: 'text text',
        },
      ],
      curator_notes: 'this is a note for the curator',
      has_work_permit: false,
      job_ad: {
        allows_remote: true,
        citizenship: false,
        company_name: 'Glovo',
        experience_level: 'Senior',
        job_title: 'Engineering Manager',
        location: 'Barcelona, ES',
        main_requirements:
          '<ul><li>A degree in Computer Science/Software Engineering or relevant experience</li>',
        must_have_skills: [
          {
            id: 144,
            name: 'Java',
          },
          {
            id: 249,
            name: 'Ruby',
          },
        ],
        needs_education_for_visa: false,
        nice_to_have: '',
        nice_to_have_skills: [],
        other_required_skills: [
          {
            id: 11,
            name: 'Android',
          },
          {
            id: 442,
            name: 'Team Leadership',
          },
        ],
        preferred_language: 'English',
        nice_to_have_language: 'Portuguese',
        role_description:
          '<p>At Glovo, we are expanding our engineering team significantly as we aim to make everything accessible to anyone, anywhere.',
        salary: '€40.000 - €70.000',
        visa_support: true,
        office_locations: [
          {
            google_place_id: '1232jfifnowefn',
            city: 'Lisbon',
            country_code: 'PT',
            label: 'Lisbon, Portugal',
          },
          {
            google_place_id: '1232jsssfifnowefn',
            city: 'Lisbone',
            country_code: 'PTE',
            label: 'Lisbone, Portugale',
          },
        ],
      },
      job_ad_account_manager_notes: [
        {
          admin_user: 'Kirito',
          note_date: '2019-08-08T15:45:42.898Z',
          note_text: 'texttexttext',
        },
      ],

      need_work_permit: false,
      referral_recomendation: null,
      referral_related: null,
      referral_from: null,
      reject_candidate_message: 'Rejected',
      reject_employer_message: null,
      reject_landing_message: null,
      reject_reason: null,
      rejected: 'true',
      request_info_already_made: false,
      screening_notes: null,
      selected_referral: false,
      skill_assessment_notes: null,
      talent_notes: null,
      work_permit_city: 'Lisbon',
      work_permit_country_code: 'PT',
      work_permit_expires_at: '2020-08-08T15:45:42.898Z',
      work_permit_notes: null,
      work_permit_number: '123',
      work_permit_type: 'type',
      self_evaluations: [],
    },
  },
};

export default curatorsApplicationDummy;
