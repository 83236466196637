import * as React from 'react';
import ArticlePage from './article_page';
import Button from 'lj_shared/button/button';
import OtherArticles from './other_articles';
import { shallow } from 'enzyme';

const pageProps = () => ({
  article: {
    data: {
      id: '5',
      type: 'help_article',
      attributes: {
        id: 5,
        title: 'dsafg',
        body: '<p>Tuntz tuntz</p>',
      },
    },
  },
  otherArticles: {
    data: [
      {
        id: '3',
        type: 'help_article',
        attributes: {
          id: 3,
          title: 'lkjsdalaks',
          body: null,
        },
      },
    ],
  },
  indexPath: '/employer/help',
});

let props;
let wrapped;

beforeAll(() => {
  props = pageProps();
  wrapped = shallow(<ArticlePage {...props} />);
});

it('shallow renders without crashing', () => {
  expect(wrapped.exists()).toBe(true);
});

it('it has a Button', () => {
  expect(wrapped.find(Button).length).toEqual(1);
});

it('it has Other Articles', () => {
  expect(wrapped.find(OtherArticles).length).toEqual(1);
});
