import React from 'react';
import HelpHeader from './help_header';
import LastArticlesContainer from '../help_article/last_articles_container';

interface Props {
  baseArticleUrl: string;
  origin?: string;
  data: any;
  isCompany: boolean;
}

export default function HelpContainer(props: Props) {
  return (
    <div>
      <HelpHeader origin={props.origin} />
      <LastArticlesContainer articles={props.data} baseArticleUrl={props.baseArticleUrl} />
    </div>
  );
}
