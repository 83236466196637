import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import PricingQuestionMarkTooltip from './pricing_question_mark_tooltip';

const createProps = () => ({
  pricingDisabledFeature: true,
  pricingLimitType: null,
  type: 'technical assessments',
});

let props;
let wrapped: ShallowWrapper;

describe('PricingQuestionMarkTooltip', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<PricingQuestionMarkTooltip {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
