import classNames from 'classnames';
import * as React from 'react';
const styles = require('./button.module.scss');

interface Props {
  onClick: any;
  text?: string;
  syncing?: boolean;
}

export default function Button(props: Props) {
  return (
    <button
      className={classNames(styles.connectButton, { [styles.syncing]: props.syncing })}
      onClick={props.onClick}
    >
      {props.text || 'Connect'}
    </button>
  );
}
