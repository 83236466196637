import React from 'react';
import TextInput from 'components/form/text/text';
import track from '../../lib/drip';
import AuthButtons from './auth_buttons';
import { getCSRFToken } from 'lib/request_deprecated';
import Button from 'lj_shared/button/button';

const styles = require('./login.module.scss');
const logo = require('images/lj_logo.png');

export interface LoginProps {
  urls: {
    password: string;
    login: string;
    socialUrls: {
      linkedin: string;
      facebook: string;
      github: string;
      google: string;
    };
  };
  language: {
    title: string;
    mail: string;
    password: string;
    forgot: string;
    login_btn: string;
    yet: string;
  };
}

const LoginContainer: React.FunctionComponent<LoginProps> = props => {
  const { title, mail, password, forgot, login_btn, yet } = props.language;

  return (
    <div className={styles.container}>
      <img className={styles.logo} src={logo} alt='Landing.Jobs' />
      <div className={styles.title}>{title}</div>
      <form className={styles.form} action={props.urls.login} method='post'>
        <input name='authenticity_token' value={getCSRFToken()} type='hidden' />
        <TextInput
          label='Email'
          name='user[email]'
          placeholder={mail}
          color='tuftsBlue'
          type='email'
        />
        <TextInput
          label='Password'
          name='user[password]'
          placeholder={password}
          color='tuftsBlue'
          type='password'
        />
        <a
          onClick={() =>
            track('action', {
              name: 'work-login-click',
              controller: 'work',
              action: 'havent-signed-up-yet',
            })
          }
          className={styles.forgot}
          href={props.urls.password}
          target=''
        >
          {forgot}
        </a>
        <Button
          onClick={() =>
            track('action', {
              name: 'work-login-click',
              controller: 'work',
              action: 'login',
            })
          }
          buttonColor='puertoRico'
          otherClasses={styles.button}
          buttonSize='mid'
          isButton={true}
        >
          {login_btn}
        </Button>
      </form>
      <a
        onClick={() =>
          track('action', {
            name: 'work-login-click',
            controller: 'work',
            action: 'havent-signed-up-yet',
          })
        }
        className={styles.signup}
        href='/join'
        target=''
      >
        {yet}
      </a>
      <AuthButtons kind='Log in' urls={props.urls.socialUrls} />
      <div className={styles.authNote}>Social login only available for Job Seekers, for now.</div>
    </div>
  );
};

export default LoginContainer;
