import React from 'react';
import { mount } from 'enzyme';
import CvInput, { Props } from './CvInput';

const createProps = (otherProps = {}) => ({
  error: '',
  cvUrl: '',
  cvFileName: '',
  cvs: [],
  handleChangeCV: jest.fn,
  ...otherProps,
});

let props: Props;
let wrapped;

describe('Input is empty', () => {
  beforeAll(() => {
    props = createProps();
    wrapped = mount(<CvInput {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('shows the label', () => {
    expect(wrapped.find('.label').text()).toEqual('Upload your CV');
  });

  it('shows the placeholder', () => {
    expect(wrapped.find('ol.cvEmpty')).toHaveLength(1);
  });
});

describe('Input has value selected', () => {
  beforeAll(() => {
    props = createProps({
      cvs: [
        {
          id: 60372,
          cvContentType: 'application/pdf',
          cvFileName: 'CV.pdf',
          cvFileSize: 93399,
          standard: true,
          personId: 137656,
          createdAt: '2020-07-06T14:00:57.661Z',
          updatedAt: '2020-07-06T14:00:57.661Z',
        },
      ],
    });
    wrapped = mount(<CvInput {...props} />);
  });

  it('shows the selected value', () => {
    expect(wrapped.find('.skillName').text()).toEqual('CV.pdf');
  });
});
