import React from 'react';
import classNames from 'classnames';
import Icon, { Size } from 'components/general/icon/icon';

const styles = require('./Timeline.module.scss');

interface Props {
  certifications: any[];
  education: any[];
  experiences: any[];
  handshakeProfile: boolean;
  hideInstitutionAndCompany: boolean;
}

const Timeline = (props: Props) => {
  const startYear = Math.min(
    ...props.experiences.map(o => o.startYear),
    ...props.education.map(e => e.attributes.graduation),
    ...props.certifications.map(c => c.year)
  );
  const endYear = new Date().getFullYear() + 1;
  const timelineLength = +endYear - +startYear < 2 ? 36 : (+endYear - +startYear) * 12;
  const minLength = items => (items.length >= 1 ? Math.max(items.length, 2) : 0);
  const itemsLength =
    minLength(props.experiences) + minLength(props.education) + minLength(props.certifications);
  const gridStyle = {
    gridTemplateColumns: `30px repeat(${timelineLength + 2}, minmax(6px, 1fr))`,
    gridTemplateRows: `50px repeat(${itemsLength}, 14px`,
  };

  const headers = [<div key={-1} />];

  for (let i = 0; i <= timelineLength; i++) {
    if (i % 12 === 0) {
      headers.push(
        <div
          key={i}
          className={classNames(styles.header, styles.normal)}
          style={i === timelineLength ? { border: 'none' } : {}}
        >
          <span>{startYear + i / 12}</span>
        </div>
      );
    } else {
      headers.push(<div key={i} className={classNames(styles.header)} />);
    }
  }

  const renderExperiences = () => {
    if (props.experiences.length === 0) {
      return null;
    }

    const sortedExperiences = props.experiences.sort((a, b) => a.startYear - b.startYear);

    const experiences = sortedExperiences.map((experience, index) => {
      const start = (+experience.startYear - startYear) * 12 + experience.startMonth + 1;
      const end =
        +experience.endYear !== 0
          ? (+experience.endYear - startYear) * 12 + experience.endMonth + 1
          : (endYear - 1 - startYear) * 12 + new Date().getMonth() + 1;
      const itemStyle = {
        gridRow: `${index + 2} / ${index + 3}`,
        gridColumn: `${start} / ${end}`,
      };

      return (
        <div
          key={experience.id}
          className={classNames(
            styles.timelineItem,
            props.handshakeProfile ? styles.experienceRipePlum : styles.experienceTuftsBlue,
            styles.tooltip
          )}
          style={itemStyle}
        >
          <div className={classNames(styles.tooltipTextBelow, styles['color-silverSandXxDark'])}>
            {experience.position} ({experience.contractType})
            {!props.hideInstitutionAndCompany && ' @ ' + experience.company}
          </div>
        </div>
      );
    });

    return [icon('experience'), experiences];
  };

  const renderEducation = () => {
    if (props.education.length === 0) {
      return null;
    }

    const sortedEducation = props.education.sort(
      (a, b) => a.attributes.graduation - b.attributes.graduation
    );

    const educations = sortedEducation.map((education, index) => {
      const start = (+education.attributes.graduation - startYear) * 12 + 2;
      const itemStyle = {
        gridRow: `${index + minLength(props.experiences) + 2} / span 1`,
        gridColumn: `${start} / span 12`,
      };

      return (
        <div
          key={education.id}
          className={classNames(
            styles.timelineItem,
            props.handshakeProfile ? styles.educationRipePlum : styles.educationTuftsBlue,
            styles.tooltip
          )}
          style={itemStyle}
        >
          <div className={classNames(styles.tooltipTextBelow, styles['color-silverSandXxDark'])}>
            {education.attributes.degree}{' '}
            {education.attributes.info && `| ${education.attributes.info}`}
            {!props.hideInstitutionAndCompany && ' @ ' + education.attributes.institution.label}
          </div>
        </div>
      );
    });

    return [icon('education'), educations];
  };

  const renderCertifications = () => {
    if (props.certifications.length === 0) {
      return null;
    }

    const sortedCertifications = props.certifications.sort((a, b) => a.year - b.year);

    const certifications = sortedCertifications.map((certification, index) => {
      const start = (+certification.year - startYear) * 12 + 2;
      const itemStyle = {
        gridRow: `${
          index + minLength(props.experiences) + minLength(props.education) + 2
        } / span 1`,
        gridColumn: `${start} / span 12`,
      };

      return (
        <div
          key={certification.id}
          className={classNames(
            styles.timelineItem,
            props.handshakeProfile ? styles.certificationRipePlum : styles.certificationTuftsBlue,
            styles.tooltip
          )}
          style={itemStyle}
        >
          <div className={classNames(styles.tooltipTextBelow, styles['color-silverSandXxDark'])}>
            {certification.title} @ {certification.authority}
          </div>
        </div>
      );
    });

    return [icon('certification'), certifications];
  };

  const icon = type => {
    let items: any[];
    let iconName: string;
    let start: number;
    const color = props.handshakeProfile ? 'RipePlum' : 'TuftsBlue';

    switch (type) {
      case 'experience':
        items = props.experiences;
        iconName = 'briefcase';
        start = 2;
        break;
      case 'education':
        items = props.education;
        iconName = 'school';
        start = minLength(props.experiences) + 2;
        break;
      case 'certification':
        items = props.certifications;
        iconName = 'certificate';
        start = minLength(props.experiences) + minLength(props.education) + 2;
        break;
    }

    return (
      <div
        key={type}
        className={classNames(styles.icon, styles[type + color])}
        style={{
          gridRow: `${start} / span ${items.length}`,
          gridColumn: '1 / 2',
        }}
      >
        <Icon name={iconName} color='white' size={Size.Medium} />
      </div>
    );
  };

  if (itemsLength === 0) {
    return (
      <div>
        {props.handshakeProfile
          ? "We can't generate a timeline since this candidate hasn't added Education or Professional Experience details."
          : "Your timeline is created automatically from you profile's professional experiences, education and certifications. Include your experiences now to see your timeline."}
      </div>
    );
  }

  return (
    <>
      {props.handshakeProfile ? 'This ' : 'Your '} timeline is created automatically from{' '}
      {props.handshakeProfile ? 'the ' : 'your '} profile&apos;s professional experiences, education
      and certifications.
      <div className={styles.container}>
        <div className={styles.timelineGrid} style={gridStyle}>
          {headers}
          {renderExperiences()}
          {renderEducation()}
          {renderCertifications()}
        </div>
      </div>
    </>
  );
};

export default Timeline;
