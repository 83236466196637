import React from 'react';
import {
  deleteJSON,
  handleRequestError,
  handleSuccessAlert,
  postFormData,
} from 'lib/request_deprecated';
import EmptyState from '../empty_state/empty_state';
import DotLoader from 'lj_shared/dot_loader/dot_loader';
import ResumeCard from 'components/registration/resume_card';

const styles = require('./file_uploader.module.scss');

type FileExtension = 'mp4' | 'mov' | 'webm' | 'ogg' | 'pdf';

const extensionToFileType = (fileExtension: FileExtension) => {
  switch (fileExtension) {
    case 'mp4':
    case 'webm':
      return `video/${fileExtension}`;
    case 'mov':
      return 'video/quicktime';
    default:
      return fileExtension;
  }
};

interface Props {
  fileName: string;
  handleChangeFile: any;
  url: string;
  validations?: {
    fileTypes?: FileExtension[];
    fileMaximumSizeMB?: number;
    fileMaximumDurationMinutes?: number;
  };
}

interface State {
  error: string;
  loading: boolean;
}

class FileUploader extends React.Component<Props, State> {
  state: State = {
    error: '',
    loading: false,
  };

  handleChange = event => {
    const file = event.target.files[0];

    const handleSuccess = () => {
      this.setState({ loading: true });
      const formData = new FormData();
      formData.append('file', file);
      formData.append('name', 'file_attachment');

      postFormData(this.props.url, formData)
        .then(response => {
          handleSuccessAlert(response);
          this.setState({ loading: false });

          if (response.success) {
            if (response.video) {
              const video = response.video;
              this.props.handleChangeFile({ name: video.name, url: video.url });
            } else {
              this.props.handleChangeFile(response);
            }
          }
        })
        .catch(error => {
          handleRequestError(error);
          this.setState({ loading: false });
        });
    };

    this.validateAndUpload(file, handleSuccess);
  };

  validateAndUpload = (file, handleSuccess) => {
    this.setState({ error: '' });

    if (this.props.validations) {
      const types = this.props.validations.fileTypes;
      if (types) {
        if (
          !types.some(
            type =>
              file.type.toLowerCase().endsWith(extensionToFileType(type)) &&
              file.name.toLowerCase().endsWith(type)
          )
        ) {
          this.setState({ error: 'Wrong file format' });
          return;
        }
      }

      const maxSizeMB = this.props.validations.fileMaximumSizeMB;
      if (maxSizeMB) {
        if (file.size > maxSizeMB * 1048576) {
          this.setState({
            error: `Maximum file size is ${maxSizeMB} Megabytes`,
          });
          return;
        }
      }

      // As this validation is not sync, this must be the last validation to execute
      const maxDurationMinutes = this.props.validations.fileMaximumDurationMinutes;
      if (maxDurationMinutes) {
        const vid = document.createElement('video');
        const fileURL = URL.createObjectURL(file);
        vid.src = fileURL;
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const target = this;

        vid.ondurationchange = function () {
          const duration = this['duration'];
          if (duration > maxDurationMinutes * 60) {
            target.setState({
              error: `Maximum file duration is ${maxDurationMinutes} minutes`,
            });
          } else {
            handleSuccess();
          }
        };

        // Returning not to run the handleSuccess at the end
        return;
      }
    }

    handleSuccess();
  };

  handleDelete = () => {
    this.setState({ loading: true });

    deleteJSON(this.props.url)
      .then(response => {
        handleSuccessAlert(response);

        this.setState({ loading: false });
        this.props.handleChangeFile('');
      })
      .catch(error => {
        handleRequestError(error);
        this.setState({ loading: false });
      });
  };

  renderFileInfo = () => {
    const types = this.props.validations.fileTypes;
    const maxSizeMB = this.props.validations.fileMaximumSizeMB;
    const maxDurationMinutes = this.props.validations.fileMaximumDurationMinutes;
    return (
      <div className={styles.fileInfo}>
        <strong>
          {types && (
            <>
              Allowed file types: {types.join(', ')}
              <br />
            </>
          )}
          {maxSizeMB && (
            <>
              Max. file size: {maxSizeMB} MB
              <br />
            </>
          )}
          {maxDurationMinutes && (
            <>
              Max. file duration: {maxDurationMinutes} minutes
              <br />
            </>
          )}
        </strong>
      </div>
    );
  };

  renderEmptyState = (children: any) => {
    const style = {
      padding: '0.3rem 1.5rem',
      borderWidth: '1px',
      minWidth: '18.75rem',
    };
    return <EmptyState style={style}>{children}</EmptyState>;
  };

  renderLoading() {
    const dotLoaderStyle = {
      minHeight: '0',
      position: 'absolute',
      bottom: '3.625rem',
      left: '5.625rem',
    };

    return this.renderEmptyState(
      <div style={{ position: 'relative' }}>
        Loading
        <span>
          <DotLoader style={dotLoaderStyle} />
        </span>
      </div>
    );
  }

  renderFileName() {
    return (
      <div>
        <ResumeCard
          title={this.props.fileName}
          handleDeleteClick={() => this.handleDelete()}
          url={this.props.url}
        />
      </div>
    );
  }

  renderEmptyFile() {
    const button = styles.button;
    return (
      <>
        {this.renderEmptyState(<>Your file will go here...</>)}
        <label className={button} htmlFor='file_attachment'>
          <div className={styles.uploadButton}>UPLOAD FILE</div>
        </label>
        <input
          type='file'
          name='file_attachment'
          id='file_attachment'
          onChange={this.handleChange}
        />
      </>
    );
  }

  render() {
    const wrapper = styles.wrapper;

    let body;
    if (this.state.loading) {
      body = this.renderLoading();
    } else if (this.props.fileName) {
      body = this.renderFileName();
    } else {
      body = this.renderEmptyFile();
    }

    return (
      <div className={wrapper}>
        <div className={styles.mainContent}>
          {body}
          <div>{this.state.error && <div className={styles.error}>{this.state.error}</div>}</div>
        </div>
        {!this.props.fileName && <div className={styles.line}>{this.renderFileInfo()}</div>}
      </div>
    );
  }
}

export default FileUploader;
