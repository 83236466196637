import React from 'react';
import classNames from 'classnames';
import Button from 'lj_shared/button/button';
import Icon, { Size } from 'components/general/icon/icon';
import { isLast } from './utils/sidebarUtils';

const styles = require('./sidebar.module.scss');

export interface Props {
  dimension: string;
  disableNext?: boolean;
  handleBack: Function;
  handleNext: Function;
  jobType: string;
  jobWithTimezone: boolean;
  step: number;
  overall: number;
  values: any;
  valuesData: any;
}

export default function SidebarButtons(props: Props) {
  const isFirst = props.step === 1;

  function nextButtonDisabled(dimension) {
    return (
      (isLast(props.step) && props.overall === null) ||
      (dimension === 'experience' && disableExperienceNextButton()) ||
      (dimension === 'location' && disableLocationNextButton()) ||
      props.disableNext
    );
  }

  function disableExperienceNextButton() {
    return (
      !props.valuesData.no_relevant_background &&
      (!props.valuesData.background_years || !props.valuesData.background_checkbox)
    );
  }

  function disableLocationNextButton() {
    return (
      props.jobType !== 'onsite_job' &&
      props.jobType !== 'partial_remote' &&
      !props.valuesData.time_zone &&
      !props.valuesData.time_zone_negative &&
      props.jobType !== 'global_remote' &&
      props.jobType !== 'full_remote' &&
      !props.jobWithTimezone
    );
  }

  return (
    <div className={classNames(styles.container, styles.buttons)}>
      <div className={styles.left}>
        {!isFirst && (
          <Button
            isButton={true}
            buttonSize='small'
            buttonType='border'
            onClick={props.handleBack()}
            otherClasses={classNames(styles.previousButton, 'previous-button')}
          >
            <Icon name={'chevronLeft'} size={Size.Medium} color='puertoRico' /> Back
          </Button>
        )}
      </div>
      <div className={styles.right}>
        <Button
          isButton={true}
          buttonSize='small'
          onClick={props.handleNext()}
          disabled={nextButtonDisabled(props.dimension)}
          otherClasses={
            isLast(props.step)
              ? classNames(styles.confirmButton, 'confirm-button')
              : classNames(styles.nextButton, 'next-button')
          }
        >
          {isLast(props.step) ? (
            <>
              <Icon name={'check'} size={Size.Medium} color='white' />
              Confirm
            </>
          ) : (
            <>
              Next
              <Icon name={'chevronRight'} size={Size.Big} color='white' />
            </>
          )}
        </Button>
      </div>
    </div>
  );
}
