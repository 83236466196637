import React from 'react';

import TeamMemberList from '../../teamMember/TeamMemberList';
import { Props } from '../../companyInfoForm/TeamElementsList';

const styles = require('./Team.module.scss');

export default function Team(props: Props) {
  return (
    <main className={styles.team}>
      <div className={styles.sectionHeader}>
        <h2 className={styles.h2}>{'TEAM'}</h2>
      </div>
      {props.description && <div className={styles.teamDescription}>{props.description}</div>}
      <TeamMemberList teamMembers={props.teamMembers} />
    </main>
  );
}
