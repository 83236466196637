import React from 'react';
import RenderFAQ from './render_faq';
import './integration_faq.scss';

interface State {
  isOpen: boolean;
}

class IntegrationFaq extends React.Component<{}, State> {
  constructor(props) {
    super(props);
    this.OpenRenderFAQ = this.OpenRenderFAQ.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  render() {
    return (
      <div className='ld-FAQ-Read'>
        Having trouble?
        <a
          href=''
          onClick={event => this.OpenRenderFAQ(event)}
          title='Open FAQ'
          className='purplefy'
        >
          {' Read our FAQ'}
        </a>
        {this.state.isOpen && <RenderFAQ />}
      </div>
    );
  }

  OpenRenderFAQ(event) {
    event.preventDefault();
    this.setState({ isOpen: !this.state.isOpen });
  }
}

export default IntegrationFaq;
