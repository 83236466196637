import React, { useState } from 'react';
import { TeamMember } from '../companyInfoForm/TeamElementsList';

const styles = require('./TeamMemberItem.module.scss');

export default function TeamMemberItem(props: TeamMember) {
  const resumeWrapper = () => {
    return (
      <div className={styles.teamMemberResume}>
        <span className={styles.resumeWrapper}>“</span>
        {props.resume}
        <span className={styles.resumeWrapper}>”</span>
      </div>
    );
  };

  return (
    <div className={styles.teamMemberElement}>
      <div className={styles.teamMemberPhoto}>
        <img src={props.photo.imageURL} />
      </div>
      <div className={styles.teamMemberInfo}>
        <div className={styles.teamMemberName}>{props.fullName}</div>
        <div className={styles.teamMemberRole}>{props.role}</div>
        {resumeWrapper()}
      </div>
    </div>
  );
}
