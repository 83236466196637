import React from 'react';
import classNames from 'classnames';
import Icon, { Size } from 'components/general/icon/icon';
import Tooltip from 'components/general/tooltip/tooltip';

const styles = require('./radio_buttons.module.scss');

interface Props {
  selectedValue: any;
  options: { value: any; label: string; tooltip?: string; children?: any }[];
  onChange: any;
  color?: string;
  name?: string;
  tooltipText?: string;
  textInsideLabel?: boolean;
}

interface RadioButtonProps {
  children?: any;
  color?: string;
  name?: string;
  onChange: any;
  option: { value: any; label: string; tooltip?: string };
  selectedValue: any;
  styles?: { radio?: any; checkmark?: any };
  textInsideLabel?: boolean;
}

export default function RadioButtons(props: Props): JSX.Element {
  return (
    <div className={classNames(props.textInsideLabel && styles.inlineButtons)}>
      {props.options.map(option => {
        return (
          <RadioButton
            color={props.color}
            key={option.value}
            name={props.name}
            onChange={props.onChange}
            option={option}
            selectedValue={props.selectedValue}
            textInsideLabel={props.textInsideLabel}
          />
        );
      })}
    </div>
  );
}

const ButtonLabel = ({ children, label }) => (
  <label className={styles.container}>
    {label}
    {children}
  </label>
);

const ButtonLabelTooltip = ({ children, label, tooltip }) => (
  <div className={styles.button}>
    <label className={styles.container}>
      {label} {children}
    </label>
    <Tooltip text={tooltip}>
      <Icon name={'helpCircle'} color={'black'} size={Size.QuiteSmall} />
    </Tooltip>
  </div>
);

const LabelWithText = ({ children, label }) => (
  <label className={classNames(styles.container, styles.textLabel)}>{children}</label>
);

export function RadioButton(props: RadioButtonProps): JSX.Element {
  const Component = props.textInsideLabel
    ? LabelWithText
    : props.option.tooltip
    ? ButtonLabelTooltip
    : ButtonLabel;

  if (props.color === 'tuftsBlue') {
    document.documentElement.style.setProperty('--color-radio-button', '#DADADA');
    document.documentElement.style.setProperty('--color-radio-button-dark', '#2B92FC');
  } else if (props.color === 'ripePlum') {
    document.documentElement.style.setProperty('--color-radio-button', '#DADADA');
    document.documentElement.style.setProperty('--color-radio-button-dark', '#CA30FF');
  }

  return (
    <>
      <Component {...props.option}>
        <input
          className={styles.radio}
          type='radio'
          value={props.option.value}
          checked={props.option.value === props.selectedValue}
          onChange={() => props.onChange(props.option.value, props.name)}
        />
        <span
          className={classNames(styles.checkmark, props.textInsideLabel && styles.checkmarkText)}
          style={props.styles?.checkmark ? props.styles.checkmark : {}}
        >
          {props.textInsideLabel && props.option.label}
        </span>
        {props.children}
      </Component>
    </>
  );
}
