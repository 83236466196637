import React from 'react';
import Modal, { ButtonsLayout } from '../../general/modal/modal';
import Icon from 'components/general/icon/icon';

const styles = require('./EngagementWarningModal.module.scss');

interface Props {
  onClose: Function;
}

export const suggestionList = [
  'Send Messages',
  'Request Handshakes',
  'Move applications to In Process',
  'Move applications between steps',
  'Reject Applications',
  'See applications',
  'Download CV’s',
  'Download applications’ PDF’s',
  'Share applications by E-mail',
];

export default function EngagementWarningModal(props: Props) {
  const { onClose } = props;

  const Title = () => (
    <div className={styles.title}>HOW TO INCREASE MY ACTIVITY LEVEL ON THE PLATFORM?</div>
  );

  const IntroductionText = () => (
    <div className={styles.introduction}>
      We've noticed you haven't been very active lately. Active companies provide candidates a
      better experience, and don't have screening restrictions.
      <p>
        Here are some suggestions of actions to perform on the platform if you want to change this:
      </p>
    </div>
  );

  const Item = (suggestion: string) => (
    <div className={styles.itemText}>
      <Icon name='check' className={styles.icon} />
      {suggestion}
    </div>
  );

  const ItemList = (suggestions: string[]) => {
    return (
      <div className={styles.itemList}>
        {suggestions.map(suggestion => (
          <div key={suggestion}>{Item(suggestion)}</div>
        ))}
      </div>
    );
  };
  return (
    <Modal
      title={<Title />}
      defaultOpen={true}
      buttonsLayout={ButtonsLayout.OkOnly}
      buttonName='Got It!'
      buttonColor={'ripePlum'}
      buttonOnClick={onClose}
      buttonClass={styles.button}
      className={styles.modalStyle}
      onClose={onClose}
    >
      <div className={styles.modalBody}>
        <IntroductionText />
        {ItemList(suggestionList)}
      </div>
    </Modal>
  );
}
