import React, { useState } from 'react';
import { patchJSON, handleRequestError } from '../../lib/request_deprecated';
import Button from 'lj_shared/button/button';
import RevokeHandshakeModal from './RevokeHandshakeModal';

export interface Props {
  cancelReasons: any;
  handshake: any;
  handshake_company: { name: string; id: number; logo: string };
  handshake_job_ad: { title: string; id: number };
  jobPage?: boolean;
}

export default function RevokeHandshake(props: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRevoke = () => {
    if (props.handshake_job_ad?.id) {
      setIsModalOpen(true);
    } else {
      patchJSON(window.Routes.revoke_dashboard_handshake(props.handshake.id))
        .then(_ => {
          window.Alerts.notice('Handshake revoked');
          window.location.reload();
        })
        .catch(handleRequestError);
    }
  };

  return (
    <>
      <Button
        buttonColor='tuftsBlueMidLight'
        isButton={true}
        buttonSize={props.jobPage ? 'small' : 'xSmall'}
        disabled={false}
        onClick={handleRevoke}
      >
        {'Revoke'}
      </Button>
      {isModalOpen && <RevokeHandshakeModal setIsModalOpen={setIsModalOpen} {...props} />}
    </>
  );
}
