import * as React from 'react';
import { JobAd } from '../../__models__/models';
import Icon, { Size } from 'components/general/icon/icon';
import InlineIcon from '../general/inline_icon/inline_icon';
import JobState from 'components/general/job/state';
import JobMoreButton from './job_more_button';
import Button from 'lj_shared/button/button';
import PublishModal from './publish_modal';
import UnpublishModal from './unpublish_modal';
import { handleRequestError } from '../../lib/request_deprecated';
import { formatDate } from '../../lib/format_date';
import * as Cookies from 'js-cookie';
import classNames from 'classnames';
import Tooltip from 'components/general/tooltip/tooltip';
import { isMobile } from 'react-device-detect';
import FollowersModal from './followers_modal';
import AdminCard from 'components/general/admin_card/admin_card';
import Modal from 'components/general/modal/modal';

const iconEdit = require('iconic/pencil.svg');
const iconDelete = require('iconic/trash.svg');
const iconReview = require('iconic/document.svg');
const iconDuplicate = require('iconic/layers.svg');
const iconPublish = require('iconic/bullhorn.svg');

const styles = require('./job_card.module.scss');

const DONT_SHOW_UNPUBLISH_COOKIE = 'employer-skip_unpublish_modal';
const DONT_SHOW_PUBLISH_COOKIE = 'employer-skip_publish_modal';

interface Props {
  isFollowedJob: boolean;
  job: JobAd;
  privacy: string;
  termsOfService: string;
  topFilter: string;
  setJobsState: Function;
  maxPublishedReached: boolean;
}

export default class JobCard extends React.Component<Props> {
  followersModalRef: any;
  maxJobsModalRef: any;

  constructor(props: Props) {
    super(props);
    this.handleUnpublish = this.handleUnpublish.bind(this);
    this.handlePublish = this.handlePublish.bind(this);
    this.handleSubmitForReview = this.handleSubmitForReview.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDoneUnpublish = this.handleDoneUnpublish.bind(this);
    this.handleDonePublish = this.handleDonePublish.bind(this);
    this.followersModalRef = React.createRef();
    this.maxJobsModalRef = React.createRef();
  }

  handleDoneUnpublish(dontShowCheck) {
    Cookies.set(DONT_SHOW_UNPUBLISH_COOKIE, dontShowCheck);
  }

  handleDonePublish(dontShowCheck) {
    Cookies.set(DONT_SHOW_PUBLISH_COOKIE, dontShowCheck);
  }

  handleManageFollowersClick = event => {
    event.preventDefault();
    this.followersModalRef.current.open();
  };

  handleUnpublish(event) {
    if (Cookies.get(DONT_SHOW_UNPUBLISH_COOKIE) === 'true') {
      const url = this.props.job.attributes.urls.job_ad_unpublish;

      $.ajax({
        url,
        type: 'PUT',
      });
    } else {
      event.preventDefault();
      UnpublishModal.show(this.props.job, this.handleDoneUnpublish).catch(handleRequestError);
    }
  }

  handlePublish(event) {
    if (Cookies.get(DONT_SHOW_PUBLISH_COOKIE) === 'true') {
      const url = this.props.job.attributes.urls.job_ad_publish;
      $.ajax({
        url,
        type: 'PUT',
      });
    } else {
      event.preventDefault();
      PublishModal.show(
        this.props.job,
        this.props.termsOfService,
        this.props.privacy,
        this.handleDonePublish
      ).catch(handleRequestError);
    }
  }
  handleDelete() {
    const url = this.props.job.attributes.urls.delete_job_url;

    if (window.confirm('Delete this draft?')) {
      window.toggleSpinner(true);
      $.ajax({
        url,
        type: 'DELETE',
      });
    }
  }

  handleSubmitForReview() {
    const url = this.props.job.attributes.urls.send_to_review_url;

    $.ajax({
      url,
      type: 'PUT',
    });
  }

  getEditUrl() {
    if (this.props.job.attributes.state === 1) {
      return this.props.job.attributes.urls.revision_edit_url;
    } else {
      return this.props.job.attributes.urls.job_edit_url;
    }
  }

  renderNewCounter(newCounter) {
    return <span className={styles.newCounter}>{newCounter}</span>;
  }

  linkToApplications() {
    const buttons = [
      {
        className: 'waitingScreeningButton',
        borderClassName: 'waitingScreeningBorderButton',
        counter: this.props.job.attributes.waiting_counter,
        name: 'Waiting for Screening',
        partialUrl: '/waiting',
        newCounter: this.props.job.attributes.waiting_new_counter,
      },
      {
        className: 'inboxButton',
        borderClassName: 'inboxBorderButton',
        counter: this.props.job.attributes.inbox_counter,
        name: 'Inbox',
        partialUrl: '',
        newCounter: this.props.job.attributes.inbox_new_counter,
      },
      {
        className: 'inProcessButton',
        borderClassName: 'inProcessBorderButton',
        counter: this.props.job.attributes.review_counter,
        name: 'In Process',
        partialUrl: '/reviewing',
        newCounter: this.props.job.attributes.review_new_counter,
      },
    ];
    return (
      <div className={styles.buttons}>
        {buttons.map(button => {
          return (
            <div key={button.name}>
              <Button
                buttonColor='ripePlum'
                buttonSize='small'
                buttonUrl={`/employer/applications${button.partialUrl}?job_id=${this.props.job.id}`}
                otherClasses={classNames(styles.linkToApplications, {
                  [styles[button.className]]: this.props.isFollowedJob,
                  [styles[button.borderClassName]]: !this.props.isFollowedJob,
                })}
                buttonType={!this.props.isFollowedJob && 'border'}
              >
                {button.name}
                {<span className={styles.badge}>{button.counter || 0}</span>}
                {button.newCounter &&
                  (isMobile ? (
                    this.renderNewCounter(button.newCounter)
                  ) : (
                    <Tooltip
                      className={styles.tooltip}
                      style={{
                        marginBottom: '3rem',
                        marginLeft: '2.1rem',
                        width: '8rem',
                      }}
                      text={
                        <div>
                          You have {button.newCounter} new applications{' '}
                          {button.name === 'In Process' ? '' : 'on '}
                          {button.name}
                        </div>
                      }
                    >
                      {this.renderNewCounter(button.newCounter)}
                    </Tooltip>
                  ))}
              </Button>
            </div>
          );
        })}
      </div>
    );
  }

  ongoingButtons() {
    const isWaitingRevision = this.props.job.attributes.state_name === 'waiting_revision';

    return (
      <div className={styles.buttons}>
        {this.renderEditButton()}
        {isWaitingRevision ? this.renderPreviewButton() : this.renderViewJobButton()}
        {this.renderManageFollowersButton()}
        {this.renderPublishJobButton()}
        <JobMoreButton
          jobAd={this.props.job}
          duplicateUrl={this.props.job.attributes.urls.job_ad_duplicate}
          closeUrl={this.props.job.attributes.urls.job_ad_close}
          isPublished={this.props.job.attributes.status_label === 'Published'}
          hasATS={this.props.job.attributes.company_has_ats}
          termsOfService={this.props.termsOfService}
          privacy={this.props.privacy}
          handleUnpublish={this.handleUnpublish}
          handlePublish={this.handlePublish}
          setJobsState={this.props.setJobsState}
          isWaitingRevision={isWaitingRevision}
        />
        <FollowersModal
          jobId={this.props.job.id}
          modalRef={this.followersModalRef}
          setJobsState={this.props.setJobsState}
        />
      </div>
    );
  }

  draftsButtons() {
    return (
      <div className={styles.buttons}>
        {this.props.job.attributes.valid && (
          <div className={styles.editButton}>
            <Button
              buttonColor='silverSand'
              buttonSize='xSmall'
              buttonType='border'
              isButton={true}
              onClick={this.handleSubmitForReview}
            >
              <InlineIcon className={styles.menuItemIcon} path={iconReview} />
              Submit for Review
            </Button>
          </div>
        )}
        {this.renderEditButton()}
        {this.renderPreviewButton()}
        <div className={styles.atsButton}>
          <Button
            isButton={true}
            buttonColor='silverSand'
            onClick={this.handleDelete}
            buttonType='border'
            buttonSize='xSmall'
          >
            <InlineIcon className={styles.menuItemIcon} path={iconDelete} />
            Delete
          </Button>
        </div>
      </div>
    );
  }

  archivedButtons() {
    return (
      <a href={this.props.job.attributes.urls.job_ad_duplicate} data-method='post'>
        <div className={styles.buttons}>
          <div className={styles.editButton}>
            <Button
              isButton={true}
              buttonColor='silverSand'
              buttonSize='xSmall'
              buttonType='border'
            >
              <InlineIcon className={styles.menuItemIcon} path={iconDuplicate} />
              Duplicate
            </Button>
          </div>
        </div>
      </a>
    );
  }

  getJobUrl() {
    if (this.props.topFilter === 'drafts' || this.props.topFilter === 'ongoing') {
      return this.props.job.attributes.urls.job_ad_ongoing;
    } else {
      return null;
    }
  }

  jobCardDateStatusParams() {
    const createdAt = formatDate(this.props.job.attributes.created_at);

    if (this.props.topFilter === 'ongoing') {
      return (
        <div className={styles.dateStatus}>
          Expires in {this.props.job.attributes.expires_days} days
        </div>
      );
    } else if (this.props.topFilter === 'drafts') {
      return <div className={styles.dateStatus}>Created on {createdAt}</div>;
    } else if (this.props.job.attributes.closed_at != null) {
      const closedAt = formatDate(this.props.job.attributes.closed_at);
      return (
        <div className={styles.dateStatus}>
          <div className={styles.jobCardCol}>Created on {createdAt}</div>
          <div className={styles.jobCardCol}>Closed on {closedAt}</div>
        </div>
      );
    }
  }

  render() {
    const stateName = this.props.job.attributes.state_name;

    return (
      <div className={styles.jobCardWrapper}>
        <div className={styles.jobCard}>
          <div className={styles.jobCardRow}>
            <div className={styles.jobCardCol}>
              <div className='lj-heading--mid'>{this.renderJobTitle()}</div>
            </div>
            <div className={styles.jobCardCol}>
              {this.props.topFilter === 'ongoing'
                ? this.ongoingButtons()
                : this.props.topFilter === 'drafts'
                ? this.draftsButtons()
                : this.archivedButtons()}
            </div>
          </div>
          <div className={styles.jobCardRow}>
            <div className={styles.jobCardCol}>
              <div>
                {this.renderLocation()}
                <JobState className={styles.state} state={stateName} />
              </div>
              {this.jobCardDateStatusParams()}
            </div>
            <div className={styles.jobCardCol}>
              {this.props.topFilter === 'ongoing' && this.linkToApplications()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderLocation() {
    const job = this.props.job;

    return (
      <div className={styles.jobCardLocation}>
        {this.props.job.attributes.location && (
          <Icon className={styles.locationIcon} name='location' />
        )}
        <span data-testid='location'>
          {job.attributes.remote_working_label}
          {job.attributes.location ? ` - ${job.attributes.location}` : null}
        </span>
      </div>
    );
  }

  renderEditButton() {
    return (
      <div data-testid='edit-button' className={styles.editButton}>
        <Button
          isButton={false}
          buttonColor='ripePlum'
          buttonUrl={this.getEditUrl()}
          buttonType='border'
          buttonSize='xSmall'
        >
          <InlineIcon className={styles.editIcon} path={iconEdit} />
          Edit
        </Button>
      </div>
    );
  }

  renderViewJobButton() {
    return (
      <div data-testid='view-button' className={styles.atsButton}>
        <Button
          isButton={false}
          buttonColor='silverSand'
          buttonUrl={this.getJobUrl()}
          buttonType='border'
          buttonSize='xSmall'
          targetBlank
        >
          View
        </Button>
      </div>
    );
  }

  renderManageFollowersButton() {
    return (
      <div data-testid='manage-followers-button' className={styles.atsButton}>
        <Button
          isButton={false}
          buttonColor='silverSand'
          buttonType='border'
          buttonSize='xSmall'
          onClick={this.handleManageFollowersClick}
        >
          <Icon
            className={styles.followersIcon}
            color='silverSand-dark'
            name='people'
            size={Size.MediumLarge}
          />
          Followers
        </Button>
      </div>
    );
  }

  renderPreviewButton() {
    return (
      <div data-testid='preview-button' className={styles.atsButton}>
        <Button
          isButton={false}
          buttonColor='silverSand'
          buttonUrl={this.getJobUrl()}
          buttonType='border'
          buttonSize='xSmall'
          targetBlank
        >
          PreView
        </Button>
      </div>
    );
  }

  openMaxJobsModal = event => {
    event.preventDefault();
    this.maxJobsModalRef.current.open();
  };

  renderPublishJobButton() {
    const companyId = this.props.job.attributes.company_id;

    if (this.props.job.attributes.state_name === 'unpublished') {
      return (
        <>
          <div data-testid='publish-button' className={styles.atsButton}>
            <Button
              isButton={false}
              buttonColor='silverSand'
              onClick={this.props.maxPublishedReached ? this.openMaxJobsModal : this.handlePublish}
              buttonType='border'
              buttonSize='xSmall'
            >
              <InlineIcon className={styles.menuItemIcon} path={iconPublish} />
              Publish
            </Button>
          </div>
          <Modal title='Publish' ref={this.maxJobsModalRef} disableOutsideClose={true}>
            <AdminCard companyId={companyId} pricingLimitType={'jobs'} />
          </Modal>
        </>
      );
    }
  }

  renderJobTitle() {
    return (
      <div
        className={this.props.job.attributes.title != null ? styles.jobTitle : styles.jobTitleNull}
      >
        {this.props.job.attributes.title != null ? this.props.job.attributes.title : 'No title'}
      </div>
    );
  }
}
