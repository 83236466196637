import { gql } from 'lib/graphql';

export const GET_COMPANY_GQL = gql`
  query CompanyPageData($id: ID!) {
    company(id: $id) {
      averageHiringProcess(id: $id) {
        companyAvg
        marketAvg
      }
      city
      companySize {
        id
        label
      }
      countryCode
      countryName
      coverPhotoUrl
      description
      glassdoorUrl
      id
      industry {
        id
        name
      }
      logoUrl
      members {
        fullName
        id
        imageName
        imageUrl
        resume
        role
      }
      milestones {
        id
        description
        year
      }
      name
      numbers
      offices {
        id
        addressLine
        addressLineOther
        city
        country
        imageUrl
        phoneNumber
        postalCode
        state
      }
      perks
      rating
      slug
      shortPitch
      teamDescription
      values
      websiteUrl
      websites(id: $id) {
        id
        category
        url
      }
    }

    currentUser {
      id
      type
    }

    currentCompany {
      id
    }

    jobs(companyId: $id) {
      nodes {
        location
        id
        isNew
        company {
          id
          logoUrl
          name
          slug
        }
        experienceLevelLabel
        experienceMin
        experienceMax
        remote
        salary
        slug
        tags {
          id
          name
        }
        title
        locationIcon
        remotePolicy
        officeLocations {
          googlePlaceId
          label
        }
        timezoneTolerance
        stateName
      }
    }

    person {
      applications(companyId: $id) {
        jobId
      }
      companySubscriptions(companyId: $id) {
        id
      }
      jobDismissals {
        id
        jobId
      }
    }

    user {
      bookmarks {
        id
        jobId
      }
    }
  }
`;
