import React from 'react';
import { mount } from 'enzyme';
import AreaInterestsInput, { Props } from './AreaInterestsInput';

const createProps = (otherProps = {}) => ({
  categories: [],
  error: '',
  handleFormChange: jest.fn,
  options: [],
  ...otherProps,
});

let props: Props;
let wrapped;

describe('Input is empty', () => {
  beforeAll(() => {
    props = createProps();
    wrapped = mount(<AreaInterestsInput {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('shows the label', () => {
    expect(wrapped.find('.label').text()).toEqual(
      'What are your areas of interest? *Please choose up to 3 areas of interest.'
    );
  });

  it('shows the placeholder', () => {
    expect(wrapped.find('.___placeholder > .___placeholder').text()).toEqual('e.g. Ux designer');
  });
});

describe('Input has one value selected', () => {
  beforeAll(() => {
    props = createProps({
      categories: [
        {
          id: 6,
          value: 6,
          label: 'Data Scientist',
        },
      ],
    });
    wrapped = mount(<AreaInterestsInput {...props} />);
  });

  it('does not show the placeholder', () => {
    expect(wrapped.find('.___placeholder > .___placeholder')).toHaveLength(0);
  });

  it('shows the selected value', () => {
    expect(wrapped.find('input[name="categories"]').props().value).toEqual(6);
  });
});

describe('Input has multiple values selected', () => {
  beforeAll(() => {
    props = createProps({
      categories: [
        {
          id: 6,
          value: 6,
          label: 'Data Scientist',
        },
        {
          id: 1,
          value: 1,
          label: 'Full-stack Developer',
        },
        {
          id: 3,
          value: 3,
          label: 'Back-end Developer',
        },
      ],
    });
    wrapped = mount(<AreaInterestsInput {...props} />);
  });

  it('shows multiple values', () => {
    const values = wrapped.find('input[name="categories"]').map(input => input.props().value);
    expect(values).toEqual([6, 1, 3]);
  });
});
