import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import AnonymousModal from './anonymous_modal';

const createProps = (hasAnon, isAnon) => ({
  accountManager: null,
  hasAnon,
  isAnon,
});

let props;
let wrapped: ShallowWrapper;

describe('Company with anonymous available', () => {
  beforeEach(() => {
    props = createProps(true, false);
    wrapped = shallow(<AnonymousModal {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});
