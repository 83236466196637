import * as React from 'react';
import CompanyPage from './CompanyPage';
import { shallow } from 'enzyme';

describe('CompanyPage', () => {
  it.skip('shallow renders without crashing', () => {
    const component = shallow(<CompanyPage id={'1'} />);
    expect(component.exists()).toBe(true);
  });
});
