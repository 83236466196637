import React from 'react';
import Icon, { Size } from 'components/general/icon/icon';
import classnames from 'classnames';

const styles = require('./help_header.module.scss');

interface Props {
  origin?: string;
}

export default function HelpHeader(props: Props) {
  const klass =
    props.origin === 'candidate'
      ? classnames(styles.hero, styles.heroCandidate)
      : classnames(styles.hero, styles.heroEmployer);

  return (
    <div className={klass}>
      <div className={styles.iconContainer}>
        <Icon color='white' size={Size.Hero} name='help' />
      </div>
      <div className={styles.textContainer}>
        <h2 className={styles.heroHeader}>Hi, We're here to help.</h2>
      </div>
    </div>
  );
}
