import { CompanySize } from './company_size';
import { Industry } from './industry';
import { CompanyMilestone } from './company_milestone';
import { CompanyOffice } from './company_office';
import { AdminUser } from './admin_user';

export class Company {
  static entityName = 'company';

  accountManager?: AdminUser;
  averageHiringProcess?: { companyAvg: string; marketAvg: string };
  city?: string;
  companySize?: CompanySize;
  countryCode?: string;
  countryName?: string;
  coverPhotoUrl: string;
  description?: string;
  glassdoorUrl?: string;
  id: string;
  industry?: Industry;
  logoUrl?: string;
  milestones?: CompanyMilestone[];
  name: string;
  numbers: string[];
  offices?: CompanyOffice[];
  perks: string[];
  rating?: number;
  shortPitch?: string;
  slug: string;
  teamDescription?: string;
  values?: string[];
  websiteUrl?: string;
  websites?: { id: number; url: string; category: string }[];
}
