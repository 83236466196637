import classNames from 'classnames';
import * as React from 'react';
import { Person } from '../../__models__/person';
import Button from 'lj_shared/button/button';
import ApplicationCard from './application_card/application_card';
import CopyButton from './copy_button';
import InlineIcon from '../general/inline_icon/inline_icon';
import Tooltip from 'components/general/tooltip/tooltip';

require('./application_contacts.scss');
const contactsIcon = require('iconic/connections.svg');
const emailIcon = require('iconic/envelope.svg');

export interface Props {
  person: Person;
  profileUrl: string;
  waitingForReview: boolean;
}

export default function ApplicationContacts(props: Props) {
  const { phone_number, email } = props.person.attributes;
  const blurred = props.waitingForReview;

  return (
    <ApplicationCard
      title='Contacts'
      icon={contactsIcon}
      alt='contacts'
      buttonOne={
        blurred ? null : (
          <Button
            buttonColor='ripePlum'
            buttonType='border'
            buttonSize='xSmall'
            buttonUrl={props.profileUrl}
          >
            More Contacts
          </Button>
        )
      }
    >
      <div className='lj-application-contacts'>
        <div className='childOne'>
          <div className='left-side'>
            <div className='rowH'>Email</div>
            <div className='row'>
              <InlineIcon alt='mail' path={emailIcon} />
              {displayContacts(email, 'row-mail')}
            </div>
          </div>
          <div className='right-side icon-clip'>
            {email !== 'No info' && !blurred && <CopyButton content={email} />}
          </div>
        </div>
        <div className='childTwo'>
          <div className='left-side'>
            <div className='rowH'>Phone number</div>
            {displayContacts(phone_number, 'row')}
          </div>
          <div className='right-side icon-clip'>
            {phone_number !== 'No info' && !blurred && <CopyButton content={phone_number} />}
          </div>
        </div>
      </div>
    </ApplicationCard>
  );

  function displayContacts(item, className) {
    return blurred ? (
      <Tooltip text='Move the candidate into process to access this information' textBelow>
        <div className={classNames(className, { blur: blurred })}>{item}</div>
      </Tooltip>
    ) : (
      <div className={classNames(className, { blur: blurred })}>{item}</div>
    );
  }
}
