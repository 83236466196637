import React from 'react';
import FormInput from 'components/general/form_input/form_input';
import LocationInput from 'components/form/location_input/location_input';
const styles = require('./CityInput.module.scss');

export interface Props {
  error: string;
  handleFormChange: any;
  city: string;
  countryName: string;
  country: string;
}

export default function CityInput(props: Props) {
  return (
    <div className={styles.wrapper}>
      <FormInput name='location' label='Which city are you based in?' error={props.error} required>
        <LocationInput
          handleLocationChange={event => props.handleFormChange(event, 'location')}
          defaultValue={{
            city: props.city,
            country: props.countryName,
            countryCode: props.country,
          }}
          value={{
            city: props.city,
            country: props.countryName,
            countryCode: props.country,
          }}
          color={'silverSand'}
          class={styles.locationInput}
          placeholder='E.g. Lisbon'
        />
      </FormInput>
    </div>
  );
}
