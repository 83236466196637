import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import WhatsNewHelpContainer from './WhatsNewHelpContainer';
import Accordion from '../general/accordion/accordion';

const createProps = () => ({});

let props;
let wrapped: ShallowWrapper;

describe("what's new help ", () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<WhatsNewHelpContainer {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('has an Accordion', () => {
    expect(wrapped.find(Accordion).length).toEqual(1);
  });
});

test.todo('add more tests');
