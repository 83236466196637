import { createContainer } from 'unstated-next';
import { unionBy } from 'lodash';
import { useState } from 'react';

import { apolloClient, gql } from 'lib/graphql';
import { tagManagerTrack } from 'lib/marketing';
import { getter } from 'lib/object';

const CREATE_BOOKMARK_GQL = gql`
  mutation ($jobId: ID!) {
    user {
      createBookmark(jobId: $jobId) {
        errors {
          field
          message
        }
        resource {
          id
          jobId
        }
      }
    }
  }
`;

const DELETE_BOOKMARK_GQL = gql`
  mutation ($id: ID!) {
    user {
      deleteBookmark(id: $id) {
        errors {
          field
          message
        }
      }
    }
  }
`;

function useBookmarks(initial) {
  const [bookmarks, setBookmarks] = useState(initial);

  const create = async jobId => {
    const { data, errors } = await apolloClient.mutate({
      mutation: CREATE_BOOKMARK_GQL,
      variables: { jobId },
    });
    const bookmark = data?.user?.createBookmark?.resource;
    if (bookmark) {
      tagManagerTrack({ event: 'candidate-subscribedJob' });
      setBookmarks(prevBookmarks => unionBy(prevBookmarks, [bookmark], getter('id')));
    }
  };

  const destroy = async id => {
    const { data, errors } = await apolloClient.mutate({
      mutation: DELETE_BOOKMARK_GQL,
      variables: { id },
    });
    if (!data.errors) {
      setBookmarks(prevBookmarks => prevBookmarks.filter(bookmark => bookmark.id !== id));
    }
  };

  return { bookmarks, create, destroy };
}

export const BookmarksContainer = createContainer(useBookmarks);
