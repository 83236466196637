import React from 'react';
import { shallow, mount } from 'enzyme';

import CompanyInfoForm from './CompanyInfoForm';
import AboutSection from './AboutSection';

window.Routes = {
  employer_company_members: () => '',
};

const createProps = () => ({
  company: {
    data: {
      id: '8',
      type: 'company',
      attributes: {
        name: 'Landing.jobs',
        perks: ['Pumbas'],
        numbers: ['234567', 'adsfgh'],
        values: ['innovation'],
        job_types: [2],
        cover_photo:
          'https://assets.landing.jobs/cms-assets/files/1779/original/default_company_header.jpg',
        location: {
          city: 'Lisbon',
          country: 'Portugal',
          country_code: 'PT',
        },
        logo: 'https://s3-eu-west-1.amazonaws.com/jobbox-assets-local-dev/attachments/companies/logos/adddb14c8317022e7a1244798134980853dec64c/original.png?1564071954',
        company_values: [
          'courageous',
          'environment',
          'communication',
          'diversity',
          'passion',
          'innovation',
          'respect',
          'integrity',
          'speed',
          'mindful',
          'empathy',
          'impact',
          'growth',
          'privacy',
          'education',
        ],
        max_values: 8,
      },
      relationships: {
        milestones: {
          data: [],
        },
        offices: {
          data: [
            {
              id: '5',
              type: 'office',
            },
            {
              id: '4',
              type: 'office',
            },
          ],
        },
      },
    },
    included: [
      {
        id: '5',
        type: 'company_office',
        attributes: {
          id: 5,
          phone_number: '+351911706344',
          postal_code: '4715-012',
          city: 'Braga',
          country: 'Portugal',
          image_file_size: 29804,
          state: 'Braga',
          image_updated_at: '2020-01-30T12:28:54.812Z',
          address_line: 'Rua Cândido Oliveira nº181, hab. 81',
          address_line_other: '',
          image_file_name: 'Screenshot_2019-12-17_at_19.00.06.png',
          image_content_type: 'image/png',
        },
      },
      {
        id: '4',
        type: 'company_office',
        attributes: {
          id: 4,
          phone_number: '+351911706344',
          postal_code: '4715-012',
          city: 'Braga',
          country: 'Portugal',
          image_file_size: 77009,
          state: 'Braga',
          image_updated_at: '2020-01-30T12:26:18.103Z',
          address_line: 'Rua Cândido Oliveira nº181, hab. 81',
          address_line_other: '',
          image_file_name: 'Screenshot_2020-01-14_at_17.00.20.png',
          image_content_type: 'image/png',
        },
      },
    ],
  },
  companySizes: {
    data: [
      {
        id: '1',
        type: 'company_size',
        attributes: {
          id: 1,
          label: '1 to 5',
        },
      },
    ],
  },
  formName: 'company_form',
  errors: {},
  industries: {
    data: [
      {
        id: '1',
        type: 'industry',
        attributes: {
          id: 1,
          name: 'Arts & Music',
        },
      },
      {
        id: '2',
        type: 'industry',
        attributes: {
          id: 2,
          name: 'E-commerce',
        },
      },
      {
        id: '3',
        type: 'industry',
        attributes: {
          id: 3,
          name: 'Education',
        },
      },
      {
        id: '4',
        type: 'industry',
        attributes: {
          id: 4,
          name: 'Enterprise & B2B',
        },
      },
      {
        id: '5',
        type: 'industry',
        attributes: {
          id: 5,
          name: 'Entertainment',
        },
      },
      {
        id: '6',
        type: 'industry',
        attributes: {
          id: 6,
          name: 'Fashion & Beauty',
        },
      },
      {
        id: '7',
        type: 'industry',
        attributes: {
          id: 7,
          name: 'Finance',
        },
      },
      {
        id: '8',
        type: 'industry',
        attributes: {
          id: 8,
          name: 'Food & Beverages',
        },
      },
      {
        id: '9',
        type: 'industry',
        attributes: {
          id: 9,
          name: 'Health',
        },
      },
      {
        id: '10',
        type: 'industry',
        attributes: {
          id: 10,
          name: 'Internet of Things (IoT)',
        },
      },
      {
        id: '11',
        type: 'industry',
        attributes: {
          id: 11,
          name: 'Logistics',
        },
      },
      {
        id: '12',
        type: 'industry',
        attributes: {
          id: 12,
          name: 'Media',
        },
      },
      {
        id: '13',
        type: 'industry',
        attributes: {
          id: 13,
          name: 'Miscellaneous',
        },
      },
      {
        id: '14',
        type: 'industry',
        attributes: {
          id: 14,
          name: 'Technology',
        },
      },
      {
        id: '15',
        type: 'industry',
        attributes: {
          id: 15,
          name: 'Travel & Leisure',
        },
      },
    ],
  },
});

let props;
let wrapped;
let component;

describe('CompanyInfoForm Shallow Render', () => {
  it('shallow renders without crashing', () => {
    props = createProps();
    wrapped = shallow(<CompanyInfoForm {...props} />);
    expect(wrapped.exists()).toBe(true);
  });
});

describe('CompanyInfoForm children', () => {
  beforeAll(() => {
    props = createProps();
    component = mount(<CompanyInfoForm {...props} />);
  });

  it('should have an AboutSection Component', () => {
    expect(component.find(AboutSection)).toHaveLength(1);
  });
});
