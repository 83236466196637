import * as React from 'react';
import AskNotificationsPermission from './ask_notifications_permission';
import { shallow, ShallowWrapper } from 'enzyme';

let wrapped: ShallowWrapper;

describe('Ask Notifications Permission component', () => {
  beforeEach(() => {
    wrapped = shallow(<AskNotificationsPermission userType='' />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
