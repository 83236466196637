import React, { useRef } from 'react';
import Modal, { ButtonsLayout } from 'components/general/modal/modal';
import { AdminUser } from '__models__/gql/admin_user';
const styles = require('./anonymous_modal.module.scss');

interface Props {
  accountManager: AdminUser;
  hasAnon: boolean;
  isAnon: boolean;
}

export default function AnonymousModal(props: Props) {
  const modalRef = useRef(null);
  const handleOpenModal = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    modalRef.current.open();
  };

  const buttonName = () => {
    if (props.hasAnon || props.isAnon) {
      return 'SWITCH ACCOUNT';
    } else {
      return 'Ok';
    }
  };

  const buttonOnClick = () => {
    if (props.hasAnon || props.isAnon) {
      const f = document.createElement('form');
      f.style.display = 'none';
      document.querySelector('body').appendChild(f);
      f.method = 'DELETE';
      f.action = window.Routes.destroy_user_session();
      f.submit();
      return false;
    } else {
      modalRef.current.close();
    }
  };

  function anonModal() {
    return (
      <Modal
        centerOnPage
        defaultOpen={false}
        ref={modalRef}
        title={props.isAnon ? 'Start from scratch' : 'I want to post an anonymous job'}
        noOverflow
        buttonsLayout={ButtonsLayout.OkOnly}
        buttonName={buttonName()}
        buttonOnClick={buttonOnClick}
      >
        <div className={styles.wrapper}>
          {props.hasAnon ? (
            <div>
              Your company already has an anonymous account. Please log in with that account to post
              an anonymous job.
            </div>
          ) : props.isAnon ? (
            <div>
              You&#39;re currently on your company&#39;s anonymous account. To post a public job,
              please log in with your normal account.
            </div>
          ) : (
            <div>
              For you to post an anonymous job you need to have an anonymous company account. You
              can request this from{' '}
              {props.accountManager
                ? `${props.accountManager.name} by email: ${props.accountManager.email}.`
                : 'us by emailing wegotyourback@landing.jobs.'}
            </div>
          )}
        </div>
      </Modal>
    );
  }

  return (
    <>
      <a onClick={handleOpenModal}>
        {props.isAnon ? (
          <div className={styles.linkText}>
            Click here if you don&#39;t want to post an anonymous job.
          </div>
        ) : (
          <div className={styles.linkText}>
            Click here to post a job without showing your company name.
          </div>
        )}
      </a>
      {anonModal()}
    </>
  );
}
