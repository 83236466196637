import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';

export { default as gql } from 'graphql-tag';

const element = document.querySelector('meta[name=csrf-token]');
const token = element?.getAttribute('content');

function handleError(errors) {
  const { graphQLErrors } = errors;

  let errorMessage = 'Oops, something went wrong.';

  if (authenticationError(graphQLErrors) || unauthorizedError(graphQLErrors)) {
    errorMessage = graphQLErrors[0].message;
  } else if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      // eslint-disable-next-line no-console
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  }

  if (authenticationError(graphQLErrors)) {
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }

  window.Alerts.alert(errorMessage);
}

export const apolloClient = new ApolloClient<object>({
  link: ApolloLink.from([
    onError(handleError),
    createUploadLink({
      credentials: 'same-origin',
      headers: { 'X-CSRF-Token': token },
    }),
  ]),
  cache: new InMemoryCache(),
});

export function errorsToHash(errorsList) {
  if (errorsList) {
    const errors = {};
    for (const error of errorsList) {
      errors[error['field']] = error['message'];
    }
    return errors;
  } else return {};
}

export function authenticationError(graphQLErrors) {
  return errorCode(graphQLErrors) === 'AUTHENTICATION_ERROR';
}

export function unauthorizedError(graphQLErrors) {
  return errorCode(graphQLErrors) === 'UNAUTHORIZED_ERROR';
}

export function errorCode(graphQLErrors) {
  return graphQLErrors?.[0]?.extensions?.code;
}
