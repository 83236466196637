import React, { useEffect, useRef, useState } from 'react';
import Icon, { Size } from 'components/general/icon/icon';
import Modal, { ModalSize } from 'components/general/modal/modal';
import JobList from 'components/jobList/JobList';
import { getJSON } from 'lib/request';

const styles = require('./after_handshake_modal.module.scss');

interface Props {
  handshakeAccepted: boolean;
  companyName: string;
  jobTitle: string;
  personId: number;
}

export default function AfterHandshakeModal(props: Props) {
  const { handshakeAccepted, companyName, jobTitle, personId } = props;
  const [hottestJobMatches, setHottestJobMatches] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    if (!hottestJobMatches && !isLoading) {
      setIsLoading(true);

      getJSON(window.Routes.hottest_matches_dashboard_job_ads({ person_id: personId }), data => {
        const jobsData = data.jobs.data;
        const jobs = jobsData.map(job => job.attributes);

        setHottestJobMatches(jobs);
        setIsLoading(false);
      });
    }
  });

  const title = () => {
    const icon = handshakeAccepted ? 'okPuertoRico' : 'chillRed';
    const text = handshakeAccepted ? 'Success!' : 'Handshake rejected.';

    return (
      <>
        <Icon name={icon} size={Size.Big} />
        {text}
      </>
    );
  };

  const secondaryTitle = () => {
    return (
      <>
        <Icon name='flame' size={Size.Big} color='flamingo' />
        Your hottest job matches
      </>
    );
  };

  const content = () => {
    return (
      <div className={styles.content}>
        {handshakeAccepted ? (
          <>
            We created an application for you to the <strong>{jobTitle}</strong> job at{' '}
            <strong>{companyName}</strong>. Check your applications' status{' '}
            <a href={window.Routes.dashboard_applications()}>here</a>.
            <br />
            <div className={styles.profileCta}>
              In the meantime, make sure your{' '}
              <a href={window.Routes.user_profile()}>profile is updated</a>.
            </div>
          </>
        ) : (
          <>
            <div>
              <strong>You've rejected this handshake!</strong>{' '}
              {jobTitle ? (
                <>
                  The <strong>{jobTitle}</strong> job at
                </>
              ) : (
                'Connecting with'
              )}{' '}
              <strong>{companyName}</strong> wasn't the best choice for you at the moment.
            </div>
            <div className={styles.profileCta}>
              In the meantime, <a href={window.Routes.user_profile()}>update your profile</a> and
              <a href={window.Routes.jobs_search()}> search</a> for the best jobs for you.
            </div>
          </>
        )}
      </div>
    );
  };

  const secondaryContent = () => {
    return (
      <div className={styles.hottestContainer}>
        <JobList isHottest={true} jsonJobs={hottestJobMatches} />
      </div>
    );
  };

  return (
    <Modal
      title={title()}
      defaultOpen={true}
      disableOutsideClose={true}
      buttonColor={'tuftsBlue'}
      onClose={() => window.location.reload()}
      size={ModalSize.Big}
      ref={modalRef}
      secondaryTitle={hottestJobMatches && secondaryTitle()}
      secondaryContent={hottestJobMatches && secondaryContent()}
    >
      {content()}
    </Modal>
  );
}
