import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import SearchTagList from './SearchTagList';
import SearchTagItem from './SearchTagItem';

const createProps = () => ({
  handleDeleteTag: jest.fn(),
  searchTags: [
    { label: 'Mikoto', name: 'Misaka' },
    { label: 'Order', name: 'Last' },
    { label: 'Worst', name: 'Misaka' },
  ],
});

let props;
let wrapped: ShallowWrapper;

describe('Search Tag List with some Items', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<SearchTagList {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('has correct number of items', () => {
    expect(wrapped.find(SearchTagItem)).toHaveLength(props.searchTags.length);
  });
});
