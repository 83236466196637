import React, { useRef, useState } from 'react';
import Modal, { ModalSize } from 'components/general/modal/modal';
import { ButtonsLayout } from '../general/modal/modal';
import Icon, { Size } from 'components/general/icon/icon';
import CVUploader from '../general/cv_uploader/cv_uploader';
import { patchJSON, handleRequestError } from '../../lib/request_deprecated';
import { Cv } from '__models__/cv';

const styles = require('components/handshakes/accept_handshake_modal.module.scss');

export interface Props {
  cvs: Cv[];
  handleAccept?: Function;
  handleChangeCv?: any;
  selectedCv?: Cv;
  jobAd: any;
  jobSuggestion?: any;
  setIsModalOpen: Function;
  hasFollowUpModal?: boolean;
  setIsAfterAccept?: Function;
}

export default function AcceptSuggestionModal(props: Props) {
  const modalRef = useRef(null);
  const company = props.jobAd.attributes.company_name;
  const [disabledAcceptButton, setDisabledAcceptButton] = useState(false);

  const renderSuggestionRequested = () => {
    return (
      <div className={styles.requestedHandshake}>
        {`Our team thought that `}
        <a className={styles.acceptWarning}>{props.jobAd.attributes.title}</a>
        {` would be a good match for you!`}
      </div>
    );
  };

  const renderFlavourtext = () => {
    return (
      <div className={styles.flavourText}>
        {renderSuggestionRequested()}
        <div className={styles.acceptWarning}>
          By accepting you are agreeing to create an application to this job.
        </div>
        <div className={styles.shareTitle}>{`Share your CV with ${company}`}</div>

        <div className={styles.shareYourCv}>
          {company} would like to know your life's story, so they can better analyze your profile.
          <br />
          <strong>Note:</strong> Please make sure your CV is in english. By uploading your CV you're
          adding/updating it in your profile.
        </div>
      </div>
    );
  };

  const handleAccept = () => {
    if (props.cvs.length > 0 && !props.selectedCv) {
      window.Alerts.alert("Please make sure you've selected a CV");
      return;
    }
    setDisabledAcceptButton(true);

    const body = { cv_id: props.selectedCv.id, job_ad: props.jobAd };
    patchJSON(window.Routes.accept_dashboard_suggestion(props.jobSuggestion.id), body)
      .then(data => {
        props.setIsModalOpen(false);

        if (!props.hasFollowUpModal) {
          data.alert
            ? window.Alerts.alert(data.alert)
            : window.Alerts.notice('Suggestion accepted!');
        }

        if (props.handleAccept) {
          props.handleAccept(data);
        } else if (props.hasFollowUpModal) {
          props.setIsAfterAccept(true);
        } else {
          window.location.reload();
        }
      })
      .catch(handleRequestError);
  };

  return (
    <Modal
      title={
        <>
          <Icon size={Size.Big} color='tuftsBlue' name='jobSuggestion' />
          Accept Suggestion
        </>
      }
      defaultOpen={true}
      ref={modalRef}
      cancelButton={true}
      buttonColor={'tuftsBlueMidLight'}
      buttonName='Accept'
      buttonOnClick={handleAccept}
      buttonsLayout={ButtonsLayout.OkCancel}
      cancelButtonTitle={'Cancel'}
      onClose={() => props.setIsModalOpen(false)}
      disableButton={!props.selectedCv || disabledAcceptButton}
      size={ModalSize.Big}
    >
      {renderFlavourtext()}
      <div className={styles.cvUploaderContainer}>
        <CVUploader
          handleChangeCV={props.handleChangeCv}
          cvs={props.cvs}
          kind='jobs'
          selectedCv={props.selectedCv || props.cvs[0]}
        />
      </div>
    </Modal>
  );
}
