import * as React from 'react';
import classNames from 'classnames';
import { ICONS } from './icons';
const styles = require('./icon.module.scss');

export enum Size {
  Tiny = 'tiny',
  QuiteSmall = 'quiteSmall',
  Small = 'small',
  Medium = 'medium',
  MediumLarge = 'mediumLarge',
  MediumBig = 'mediumBig',
  Big = 'big',
  BigHero = 'bigHero',
  Hero = 'hero',
  Huge = 'huge',
  Enormous = 'enormous',
}

export enum Style {
  Normal = 'normal',
  Bold = 'bold',
  Thick = 'thick',
}

interface Props {
  className?: string;
  color?: string;
  name: string;
  size?: Size;
  clickHandler?: any;
  style?: Style;
  display?: string;
  id?: string;
}

export default function Icon(props: Props) {
  const color = props.color || 'silverSand';
  const size = props.size || Size.Medium;
  const style = props.style || Style.Normal;
  const className = classNames(
    styles.wrapper,
    styles[`color-${color}`],
    styles[`size-${size}`],
    styles[`size-${size}`],
    styles[`style-${style}`],
    props.display && styles[`display-${props.display}`],
    props.className
  );

  return (
    <span
      id={props.id}
      className={className}
      dangerouslySetInnerHTML={{ __html: ICONS[props.name] }}
      onClick={props.clickHandler}
    />
  );
}
