import * as React from 'react';
import classNames from 'classnames';
import Button from 'lj_shared/button/button';
import { Application } from '../../__models__/models';
import RejectionForm from 'components/rejection_modal/rejection_form';
import { postJSON, handleRequestError } from 'lib/request_deprecated';
import Modal from 'components/general/modal/modal';
import Icon from 'components/general/icon/icon';
import Select from 'components/form/select/select';

const styles = require('./BlockCandidateButton.module.scss');

interface Props {
  application: Application;
}

export default function BlockCandidateButton(props: Props) {
  const { application } = props;
  const modalRef = React.useRef(null);
  const id = application.attributes.person.attributes.user_id;
  const rejectionForm = new RejectionForm({});
  const reasons = React.useState(rejectionForm.reasons());
  const [selectedReason, setSelectedReason] = React.useState(null);
  const [sending, setSending] = React.useState(false);
  const [userBlocked, setUserBlocked] = React.useState(application.attributes.user_blocked);

  const handleChangeBlockReason = value => {
    setSelectedReason(value);
  };

  const openModal = () => {
    modalRef.current.open();
  };

  const handleBlockCandidate = event => {
    event.preventDefault();
    const url = userBlocked
      ? window.Routes.unblock_backoffice_candidate_blocks()
      : window.Routes.backoffice_candidate_blocks();
    const candidateBlock = userBlocked ? { id } : { value: selectedReason.value, id };
    setSending(true);

    if ((!userBlocked && selectedReason) || userBlocked) {
      postUserBlock(url, candidateBlock);
    } else {
      window.alert('Please choose a reason before submitting');
      setSending(false);
    }
  };

  const postUserBlock = (url, candidateBlock) => {
    postJSON(url, {
      candidate_block: candidateBlock,
    })
      .then(e => {
        if (e.notice) {
          setUserBlocked(!userBlocked);
          setSelectedReason(null);
          window.Alerts.notice(e.notice);
          window.location.reload();
        } else {
          window.Alerts.alert(e.alert);
        }
        setSending(false);
        modalRef.current.close();
      })
      .catch(handleRequestError);
  };

  const filterItems = [
    { id: 1, label: reasons[0][0], value: rejectionForm.reasonSerializer(reasons[0][0]) },
    { id: 2, label: reasons[0][1], value: rejectionForm.reasonSerializer(reasons[0][1]) },
    { id: 3, label: reasons[0][2], value: rejectionForm.reasonSerializer(reasons[0][2]) },
    { id: 4, label: reasons[0][3], value: rejectionForm.reasonSerializer(reasons[0][3]) },
    { id: 5, label: reasons[0][4], value: rejectionForm.reasonSerializer(reasons[0][4]) },
    { id: 6, label: reasons[0][5], value: rejectionForm.reasonSerializer(reasons[0][5]) },
    { id: 7, label: reasons[0][6], value: rejectionForm.reasonSerializer(reasons[0][6]) },
    { id: 8, label: reasons[0][7], value: rejectionForm.reasonSerializer(reasons[0][7]) },
    { id: 9, label: reasons[0][8], value: rejectionForm.reasonSerializer(reasons[0][8]) },
    { id: 10, label: reasons[0][9], value: rejectionForm.reasonSerializer(reasons[0][9]) },
    { id: 11, label: reasons[0][10], value: rejectionForm.reasonSerializer(reasons[0][10]) },
  ];

  function blockUnblockCandidateModal() {
    return (
      <Modal
        defaultOpen={false}
        ref={modalRef}
        title={`${userBlocked ? 'un' : ''}block candidate ${
          application.attributes.person.attributes.name
        }`}
        noOverflow
      >
        {userBlocked ? (
          <>
            <div className={styles.note}>
              <strong>Are you sure you want to unblock candidate?</strong>
            </div>
            <div className={styles.note}>
              By clicking 'unblock' you will be able to receive applications from this candidate
              again.
            </div>
          </>
        ) : (
          <div className={styles.blockModal}>
            <Select
              name='reason'
              color='ripePlum'
              options={filterItems}
              label={'Choose a rejection reason'}
              placeholder={'Select reason'}
              onChange={handleChangeBlockReason}
            />
            <div className={styles.note}>
              <strong>Note:</strong> By enabling this feature you will block this candidate for a
              period of 6 months. All ongoing and future applications during this time period will
              be automatically rejected with the 'rejection reason' selected above. This action can
              be reverted, however all rejected applications will not be restored.
            </div>
          </div>
        )}
        <div className={styles.footer}>
          <div className={styles.modalButtons}>
            <Button
              buttonColor='ripePlum'
              buttonType='border'
              onClick={() => modalRef.current.close()}
              disabled={sending}
              otherClasses={styles.modalButton}
            >
              Cancel
            </Button>
            <Button
              buttonColor='ripePlum'
              onClick={handleBlockCandidate}
              otherClasses={styles.modalButton}
              disabled={sending}
            >
              {userBlocked
                ? sending
                  ? 'Unblocking...'
                  : 'Unblock'
                : sending
                ? 'Blocking...'
                : 'Block'}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <>
      <div className={classNames(styles.buttons, styles.blockButton)}>
        {userBlocked ? (
          <Button
            otherClasses={styles.blockUnblockButton}
            buttonColor='silverSand'
            buttonType='border'
            buttonSize='xSmall'
            onClick={openModal}
          >
            <Icon name='eye' color='silverSand' className={styles.shareIcon} />
            Unblock candidate
          </Button>
        ) : (
          <Button
            otherClasses={styles.blockUnblockButton}
            buttonColor='silverSand'
            buttonType='border'
            buttonSize='xSmall'
            onClick={openModal}
          >
            <Icon name='eyeClosed' color='silverSand' className={styles.shareIcon} />
            Block candidate
          </Button>
        )}
      </div>
      {blockUnblockCandidateModal()}
    </>
  );
}
