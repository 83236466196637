import React from 'react';
import GenericSelectRange, { Range } from './GenericSelectRange';

interface Props {
  handleFormChange: (event: any, input: string) => void;
  rate: Range;
}

const RATE_LIST: Range[] = [
  { minimum: 150, maximum: 200 },
  { minimum: 200, maximum: 250 },
  { minimum: 250, maximum: 300 },
  { minimum: 300, maximum: 350 },
  { minimum: 350, maximum: 400 },
  { minimum: 400, maximum: 450 },
  { minimum: 450, maximum: 500 },
  { minimum: 500 },
];

export default function RateRange(props: Props) {
  return (
    <GenericSelectRange
      formInputLabel={'What rate per day are you expecting as a contractor?'}
      formInputSubLabel={'(this information is not visible to companies)'}
      formInputName={'rate_range'}
      handleFormChange={props.handleFormChange}
      placeholder={'e.g. € 350 - € 400'}
      profileAttribute={'rateRange'}
      range={props.rate}
      rangesList={RATE_LIST}
    />
  );
}
