import RatingUtils from './rating_utils';

export default class RatingBodyUtils extends RatingUtils {
  props = () => ({
    title: 'Curation',
    landingRatingId: this.landingRating ? this.landingRating.id : 0,
    landingRating: this.landingRating ? this.landingRating.rating : 0,
    dimensions: this.landingRating ? this.landingRating.dimensions : [],
    screeningNotes: this.screeningNotes,
    showRatingBody: this.showRatingBody,
    hideCurationNotesIfLegacy: this.hideCurationNotesIfLegacy,
  });
}
