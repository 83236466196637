import React from 'react';
import { mount } from 'enzyme';
import RegistrationPage, { Props } from './RegistrationPage';
import personDummy from '__fixtures__/person_dummy';
import registrationProfileDummy from '__fixtures__/registration_profile_dummy';

window.Routes = {
  account: () => '',
  resume: () => '',
};

const createProps = () => ({
  profile: {
    categories: [],
    citizenships: [],
    maximumNumberOfSkills: 40,
    job_types: [],
    person: { data: personDummy },
    proposedTags: [{ id: 286809, tag: 'test' }],
    share_preferences: {},
    showLinkedin: false,
    skills: { data: [] },
    start_years: [2000, 2001, 2002],
    urls: {
      person_institutions_url: '',
      person_institutions_destroy_url: '',
      edit_profile_url: '',
      websites_url: '',
      websites_destroy_url: '',
      languages_url: '',
      languages_destroy_url: '',
      cv_url: '',
    },
  },
});

let props: Props;
let wrapped;

const expected = {
  hadName: true,
  profileForm: registrationProfileDummy,
  revision: false,
};

describe('Registration Page shows all the inputs', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = mount(<RegistrationPage {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('has a status input', () => {
    expect(wrapped.find('#availability')).toHaveLength(1);
  });

  it('has a city input', () => {
    expect(wrapped.find('#location')).toHaveLength(1);
  });

  it('has a citizenships input', () => {
    expect(wrapped.find('#citizenships')).toHaveLength(1);
  });

  it('has a remote policies input', () => {
    expect(wrapped.find('#remote_policy')).toHaveLength(1);
  });

  it('has a job types input', () => {
    expect(wrapped.find('#job_types')).toHaveLength(1);
  });

  it('has a areas of interest input', () => {
    expect(wrapped.find('#categories')).toHaveLength(1);
  });

  it('has a experience years input', () => {
    expect(wrapped.find('#startYear')).toHaveLength(1);
  });

  it('has a skills input', () => {
    expect(wrapped.find('#skills')).toHaveLength(1);
  });

  it('has a share preferences input', () => {
    expect(wrapped.find('#share_profile')).toHaveLength(1);
  });

  it('has a CV input', () => {
    expect(wrapped.find('#cvFileName')).toHaveLength(1);
  });

  it('has a LinkedIn input', () => {
    expect(wrapped.find('#listOfLinks')).toHaveLength(1);
  });
});

describe('handleFormChange', () => {
  // it('should call setState on status', () => {
  //   personDummy.attributes.availability = 1;
  //   wrapped.instance().handleFormChange(1, 'availability');
  //   expect(wrapped.state()).toEqual(expected);
  // });
  // it('should call setState on city', () => {
  //   registrationProfileDummy.city = 'Lisbon';
  //   registrationProfileDummy.country = 'PT';
  //   registrationProfileDummy.countryName = 'Portugal';
  //   registrationProfileDummy.location = 'Lisbon, Portugal';
  //   registrationProfileDummy.googlePlaceId = 'e8727ff6f788a0eb9dd287a93f56b7ac2400af9b';
  //   const mockEvent = {
  //     address_components: [
  //       {
  //         long_name: 'Portugal',
  //         short_name: 'PT',
  //         types: ['country', 'political'],
  //       },
  //     ],
  //     name: 'Lisbon',
  //     id: 'e8727ff6f788a0eb9dd287a93f56b7ac2400af9b',
  //     formatted_address: 'Lisbon, Portugal',
  //   };
  //   wrapped.instance().handleFormChange(mockEvent, 'location');
  //   expect(wrapped.state()).toEqual(expected);
  // });
  // it('should call setState on citizenships', () => {
  //   const mockEvent = [{ id: 'American', value: 'American', label: 'American' }];
  //   registrationProfileDummy.citizenships = mockEvent;
  //   wrapped.instance().handleFormChange(mockEvent, 'citizenships');
  //   expect(wrapped.state()).toEqual(expected);
  // });
  // it('should call setState on job types interest', () => {
  //   const mockEvent = [
  //     { id: 0, label: 'Permanent employee' },
  //     { id: 1, label: 'Contractor' },
  //   ];
  //   registrationProfileDummy.jobTypesInterest = mockEvent;
  //   wrapped.instance().handleFormChange(mockEvent, 'jobTypesInterest');
  //   expect(wrapped.state()).toEqual(expected);
  // });
  // it('should call setState on areas of interest', () => {
  //   const mockEvent = [{ id: 1, value: 1, label: 'Full-stack Developer' }];
  //   registrationProfileDummy.categories = mockEvent;
  //   wrapped.instance().handleFormChange(mockEvent, 'categories');
  //   expect(wrapped.state()).toEqual(expected);
  // });
  // it('should call setState on years of experience', () => {
  //   const mockEvent = { value: 2015, label: 2015 };
  //   registrationProfileDummy.startYear = mockEvent;
  //   wrapped.instance().handleFormChange(mockEvent, 'startYear');
  //   expect(wrapped.state()).toEqual(expected);
  // });
  // it('should call setState on share profile', () => {
  //   registrationProfileDummy.shareProfile = 4;
  //   wrapped.instance().handleFormChange(4, 'shareProfile');
  //   expect(wrapped.state()).toEqual(expected);
  // });
  // it('should call setState on communication consent', () => {
  //   const mockEvent = { target: { checked: true, name: 'commsConsent' } };
  //   registrationProfileDummy.commsConsent = mockEvent.target.checked;
  //   wrapped.instance().handleFormChange(mockEvent, 'commsConsent');
  //   expect(wrapped.state()).toEqual(expected);
  // });
});
