import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import AvailableTierFeatureCounter from './available_tier_feature_counter';

const createProps = () => ({
  available: '1/3',
  type: 'weekly handshakes',
});

let props;
let wrapped: ShallowWrapper;

describe('Available Tier Feature Counter', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<AvailableTierFeatureCounter {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
