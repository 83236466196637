// Deprecated; use formatDate instead
const dateFormat = (date, options = {}) => {
  const newDate = Date.parse(date);
  return new Intl.DateTimeFormat('en-US', options).format(newDate);
};

export default dateFormat;

export function formatDate(date) {
  const format = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  const [month, , day, , year] = format.formatToParts(Date.parse(date));
  return `${year.value}-${month.value}-${day.value}`;
}
