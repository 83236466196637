import * as React from 'react';
import './application_page_notes.scss';

import ApplicationNotes from '../general/application_notes/application_notes';
import ApplicationCard from './application_card/application_card';

export interface Props {
  getNotesUrl: string;
  postNoteUrl: string;
  destroyNoteUrl: string;
  applicationId: number;
}

const ApplicationPageNotes = (props: Props) => {
  const pencilIcon = require('iconic/pencil.svg');

  return (
    <ApplicationCard title='Application Notes' icon={pencilIcon} alt='pencil'>
      <ApplicationNotes
        getNotesUrl={props.getNotesUrl}
        postNoteUrl={props.postNoteUrl}
        destroyNoteUrl={props.destroyNoteUrl}
        applicationId={props.applicationId}
      />
      <div className='fade-to-white' />
    </ApplicationCard>
  );
};

export default ApplicationPageNotes;
