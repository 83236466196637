import * as React from 'react';
import '../../../lib/globals';
import { isMobile } from 'react-device-detect';
import HandshakeTag from './handshake_tag';
import InlineIcon from '../../general/inline_icon/inline_icon';
import TagWithTooltip from 'components/general/tag_with_tooltip/tagWithTooltip';
const arrowPath = require('iconic/arrow-thick.svg');
const externalLinkPath = require('iconic/external-link.svg');
const xThinPath = require('iconic/x-thin.svg');

import { Messageable } from './models';

export interface Props {
  findConversationUrl: Function;
  handleConversationClose: Function;
  handleExpandClick: Function;
  handleMinimizeClick: (event) => void;
  messageable: Messageable;
  mini: boolean;
  userDeleted?: boolean;
  userType: string;
}

const ConversationHeader = (props: Props) => {
  // static defaultProps: { name: string; };
  const url =
    props.userType === 'CompanyUser' ? '/employer/applications/' : '/dashboard/applications/';
  const recipient_name = props.messageable.attributes.recipient_name;
  const applications = props.messageable.attributes.candidate_applications;

  let headerText;
  if (props.userDeleted) {
    headerText = 'User Deleted';
  } else if (applications && applications.length > 1) {
    headerText = 'Applications: ';
  } else if (applications && applications.length === 1) {
    headerText = 'Application: ';
  } else {
    headerText = 'Accepted Handshake';
  }

  const handleBackButton = () => {
    if (window.location.pathname === '/find_conversation') {
      window.history.back();
    } else {
      (
        document.querySelectorAll(
          '.lj-conversation, .toggle-sidebar-icon'
        ) as NodeListOf<HTMLElement>
      ).forEach(element => {
        element.style.display = 'none';
      });
    }
  };

  return (
    <div className='lj-conversation-header' onClick={props.handleMinimizeClick}>
      <div onClick={handleBackButton} id='conversation-back-button'>
        <img
          src={arrowPath}
          data-head='line-large-acute'
          data-direction='left'
          className='iconic iconic-lg'
        />
      </div>
      <h3>{recipient_name}</h3>
      {isMobile && props.messageable.attributes.recipient_recently_updated && (
        <TagWithTooltip
          tagTitle='Recently Updated'
          tooltipText='Updated in the last 3 months.'
          textBelow
          color='puertoRico'
          newIcon='starCircle'
          iconColor='white'
        />
      )}
      <div className='lj-chat-header-handshake-tag-container'>
        {getHandshakes(
          applications,
          recipient_name,
          props.userType,
          isMobile && !props.mini,
          false
        )}
      </div>
      <span className='lj-conversation-headerContext'>
        {headerText}
        {applications?.map((application, index) => {
          return (
            <span key={`application_${index}`}>
              <a href={url + application.id}>{application.job.title}</a>
              {index < applications.length - 1 ? ',' : ''}
            </span>
          );
        })}
      </span>
      <div className='lj-conversation-headerIcons'>
        <InlineIcon
          className='lj-conversation-headerExpand'
          path={externalLinkPath}
          onClick={props.handleExpandClick as (event: any) => any}
        />
        <InlineIcon
          className='lj-conversation-headerClose'
          path={xThinPath}
          onClick={props.handleConversationClose as (event: any) => any}
        />
      </div>
    </div>
  );
};

export default ConversationHeader;

export const getHandshakes = (applications, recipientName, userType, show, hideTooltip) => {
  const hanshakeIndex = applications && applications.map(a => a.handshake.exists).indexOf(true);

  return (
    show &&
    hanshakeIndex !== -1 && (
      <>
        <HandshakeTag
          recipientName={recipientName}
          jobTitle={applications[hanshakeIndex].job.title}
          handshake={applications[hanshakeIndex].handshake.data}
          userType={userType}
          noTooltip={hideTooltip}
        />
        <span className='lj-handshake-span-text'>Handshake</span>
      </>
    )
  );
};
