import BaseForm from './base_form';

class EducationForm extends BaseForm {
  id: number;
  degree: string;
  institution: { value: string; label: string };
  info: string;
  graduation: { value: number; label: number };
  errors: any;
  saved: boolean;

  constructor(args) {
    super(args);

    Object.keys(args).map(k => {
      this[k] = args[k];
    });

    if (!this.errors) {
      this.errors = {};
    }
  }

  serialize() {
    return {
      id: this.id,
      degree: this.degree,
      institution_id: this.institution ? this.institution.value : '',
      info: this.info,
      graduation: this.graduation ? this.graduation.value : '',
    };
  }
}

export default EducationForm;
