export function cropImage(fileData, canvasId) {
  const reader = new FileReader();

  if (fileData?.image) {
    reader.addEventListener('load', () => setCroppedImg(fileData, reader.result, canvasId));
    reader.readAsDataURL(fileData.image);
  }
}

function setCroppedImg(fileData, fileUrl, canvasId) {
  const canvas = document.getElementById(canvasId) as HTMLCanvasElement;
  canvas.hidden = false;
  const imageObj = new Image();
  imageObj.src = fileUrl;

  const scaleX = imageObj.naturalWidth / imageObj.width;
  const scaleY = imageObj.naturalHeight / imageObj.height;
  const { cropW, cropH, cropX, cropY } = fileData;
  canvas.width = cropW;
  canvas.height = cropH;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(
    imageObj,
    cropX * scaleX,
    cropY * scaleY,
    cropW * scaleX,
    cropH * scaleY,
    0,
    0,
    cropW,
    cropH
  );
}
