import React, { useState, useRef } from 'react';
const styles = require('./language_dimension.module.scss');
import RatingBox from './rating_box';
const settings = require('settings.json');

export interface Props {
  classes?: string;
  value: any;
  ratingRef: any;
  handleChange: Function;
  preferredLanguage: string;
  niceToHaveLanguage: string;
  preferredLanguageRef: any;
  niceToHaveLanguageRef: any;
  preferredLanguageValue: any;
  niceToHaveLanguageValue: any;
}

export default class LanguageDimension extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  handleChange = (event, dimension) => {
    this.props.handleChange(dimension);
  };

  render() {
    return (
      <>
        <div className={styles.container}>
          <h3 className={styles.h3}>Language Match</h3>
          <RatingBox value={this.props.value} name='language' ratingRef={this.props.ratingRef} />
        </div>

        <div className={styles.note}>
          <div className={styles.noteWord}>Note:</div> If you notice that the company mentions (in
          the notes or main requirements section) a required language other than the one identified
          here please use the old model and explain that on the notes.
        </div>
        <div className={styles.questions}>
          <p className={styles.languageSelect}>
            The candidate’s level in {this.props.preferredLanguage} is:
          </p>
          <select
            className={this.props.classes}
            name='preferred_language'
            key={'preferred_language' + this.props.preferredLanguageValue}
            ref={this.props.preferredLanguageRef}
            defaultValue={this.props.preferredLanguageValue}
            onChange={event => this.handleChange(event, 'language')}
          >
            {settings.languageProficiency.map(([label, value]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
          {this.props.niceToHaveLanguage ? (
            <>
              <p className={styles.languageSelect}>
                The candidate’s level in {this.props.niceToHaveLanguage} is:
              </p>
              <select
                className={this.props.classes}
                name='nice_to_have_language'
                key={'nice_to_have_language' + this.props.niceToHaveLanguageValue}
                ref={this.props.niceToHaveLanguageRef}
                defaultValue={this.props.niceToHaveLanguageValue}
                onChange={event => this.handleChange(event, 'language')}
              >
                {settings.languageProficiency.map(([label, value]) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </>
          ) : null}
        </div>
      </>
    );
  }
}
