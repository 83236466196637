import { shuffle } from 'lib/string';

export default class ProfessionalExperienceService {
  experiences: any;
  blurred: boolean;

  constructor(params) {
    this.experiences = params.experiences;
    this.blurred = params.handshakeProfile && !params.unlocked && !params.open_profile;
  }

  experienceProps = experience => ({
    title: experience.position,
    subtitle: experience.contractType || experience.contract_type,
    date: [
      experience.startDateDecorated || experience.start_date_decorated,
      experience.endDateDecorated || experience.end_date_decorated,
    ],
    contentTitle: this.blurred ? shuffle(experience.company) : experience.company,
    content: experience.description,
    categories: experience.categories,
    id: experience.id,
    startMonth: experience.startMonth || experience.start_month,
    startYear: experience.startYear,
    endMonth: experience.endMonth,
    endYear: experience.endYear,
    currentPosition: experience.currentPosition,
    blurred: this.blurred,
  });

  props() {
    return this.experiences?.map(experience => this.experienceProps(experience));
  }
}
