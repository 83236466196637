import { Bookmark, Job } from '__models__/gql/all';
import { Remote } from '__models__/gql/job';

type JsonBookmark = [number, number];

const JobListUtils = () => {
  const getRemotePolicy = (job): Remote => {
    if (job.remote) {
      if (job.full_remote) {
        return Remote.Full;
      }
      if (job.global_remote) {
        return Remote.Global;
      }
      if (job.partial_remote) {
        return Remote.Partial;
      }
    }

    return Remote.None;
  };

  const getInfoTags = (job): string[] => {
    const tags = [];

    if (job.visa_support) {
      tags.push(job.visa_support);
    }

    if (job.full_remote) {
      tags.push('Fully Remote');
    }

    if (job.global_remote) {
      tags.push('Remote across borders');
    }

    tags.push(job.industry);
    tags.push(job.company_size);

    return tags;
  };

  const convertJobAdToJob = (jobAd): Job => {
    return {
      id: jobAd.id.toString(),
      isNew: jobAd.is_new,
      company: {
        id: jobAd.company_id,
        name: jobAd.company_name,
        logoUrl: jobAd.company_logo_url,
        slug: jobAd.company_slug,
      },
      location: jobAd.location,
      experienceLevelLabel: jobAd.experience_level,
      experienceMin: jobAd.experience_min,
      experienceMax: jobAd.experience_max,
      tags: jobAd.skills,
      remote: getRemotePolicy(jobAd),
      salary: jobAd.salary,
      rate: jobAd.rate,
      slug: jobAd.slug,
      hiringBonus: jobAd.hiring_bonus,
      title: jobAd.title,
      locationIcon: jobAd?.location_icon,
      officeLocations: jobAd.office_locations,
      remotePolicy: jobAd.remote_tooltip,
      timezoneTolerance: jobAd.timezone_tolerance,
      rateAndSalary: jobAd.rate_and_salary,
      stateName: 'published', // all search results are published
    } as Job;
  };

  const createBookmarks = (bookmarks: JsonBookmark[]): Partial<Bookmark>[] => {
    return bookmarks?.map(bookmark => ({
      id: bookmark[0].toString(),
      jobId: bookmark[1].toString(),
    }));
  };

  return { createBookmarks, convertJobAdToJob, getInfoTags, getRemotePolicy };
};

export default JobListUtils;
