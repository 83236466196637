import classnames from 'classnames';
import React from 'react';
const styles = require('./DotSpinner.module.scss');

interface Props {
  text?: string;
  style?: string;
  pageCenter?: boolean;
}

const DotSpinner = (props: Props) => {
  const { text, style, pageCenter } = props;

  return (
    <div className={classnames(style, pageCenter && styles.pageCenter)}>
      <span className={styles.text}>{text}</span>
      <div className={styles.container}>
        {Array.from({ length: 3 }, (_, k) => (
          <span key={k} className={styles.dotContainer}>
            <span className={styles.dot} />
          </span>
        ))}
      </div>
    </div>
  );
};

export default DotSpinner;
