import React from 'react';
import InlineIcon from 'components/general/inline_icon/inline_icon';

const markerIcon = require('iconic/map-marker.svg');
const styles = require('./application_body_title.module.scss');

interface Props {
  title?: string;
  city?: string;
  country?: string;
  children?: any;
  jobId?: string;
}

export default function ApplicationBodyTitle(props: Props) {
  return (
    <div className={styles.fullSize}>
      <div className={styles.halfSize}>
        <div className={styles.title}>
          <a href={window.Routes.job_ad(props.jobId)} target='_blank' rel='noreferrer'>
            {props.title}
          </a>
        </div>
        {props.city && props.country && (
          <div className={styles.location}>
            <InlineIcon alt='marker' path={markerIcon} />
            {`${props.city}, ${props.country}`}
          </div>
        )}
      </div>
      <div className={styles.halfSize}>
        <div className={styles.alignRight}>{props.children}</div>
      </div>
    </div>
  );
}
