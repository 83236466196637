import * as React from 'react';
import './application_banner.scss';

import { Person, Application, HiringStep } from '../../__models__/models';
import dateFormat from '../../lib/format_date';
import ApplicationCard from './application_card/application_card';
import ApplicationTags from '../general/application_tags/application_tags';
import ApplicationSteps from './application_steps';
import ApplicationStateButtons from './application_state_buttons';
import Chat from '../pages/chat';
import ApplicationTrail from '../general/application_trail/application_trail';
import ApplicationBodyTitle from './application_card/application_body_title';
import ApplicationBodyFooter from './application_card/application_body_footer';
import StepColor from './step_color';
import RatingContainer from 'components/general/rating/rating_container';
import ApplicationCardBody from './application_card/application_card_body';
import ApplicationHeaderButtonsContainer from 'components/employer_applications/applicationHeaderButtons/ApplicationHeaderButtonsContainer';

export interface Props {
  application: Application;
  chatUrl?: string;
  currentStep: string;
  delivered: boolean;
  hideRatingContainer?: boolean;
  hiringSteps: HiringStep[];
  moveToStep: any;
  onApplicationList: boolean;
  profileUrl: string;
  rejectApplication: any;
  seenList: number[];
  setApplication?: Function;
  stepLabel: string;
  tooManyApps?: boolean;
  topFilter: string;
  urls: any;
  waitingReview: boolean;
}

interface State {
  trail: boolean;
  showRatingBody?: boolean;
  label: string;
}

class ApplicationBanner extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      trail: false,
      label: null,
      showRatingBody: false,
    };

    this.handleChatClick = this.handleChatClick.bind(this);
    this.handleMoveToStep = this.handleMoveToStep.bind(this);
    this.toggleRatingBody = this.toggleRatingBody.bind(this);
  }

  handleChatClick() {
    const id = this.props.application.attributes.person.attributes.user_id;
    Chat.openConversationWithCandidate(id);
  }

  handleMoveToStep(step = null, app) {
    const state_name = this.props.application.attributes.state_name;
    if (
      state_name === 'pending_information' ||
      state_name === 'curated' ||
      state_name === 'unreviewed'
    ) {
      this.setState(() => {
        return { trail: true, label: 'In Process' };
      });

      setTimeout(() => {
        this.setState({
          trail: false,
        });
      }, 5000);
    }
    this.props.moveToStep(step, app);
  }

  toggleRatingBody = () =>
    this.setState(prevState => ({ showRatingBody: !prevState.showRatingBody }));

  render() {
    const person: Person = this.props.application.attributes.person;
    const jobAd = this.props.application.attributes.job_ad;
    const jobId = this.props.application.attributes.job_id;
    const ratings = this.props.application.attributes.ratings_with_dimensions;
    const { recipient_avatar, name, experience_years, location, person_tags, slug } =
      person.attributes;

    const { submitted_at, updated_at, application_tags, test_results } =
      this.props.application.attributes;
    const urlSlug = slug && slug.length > 0 ? slug : person.id;
    const cvUrl = `/employer/search/people/${urlSlug}/resume?open=true`;
    const submittedAt = dateFormat(submitted_at, {
      month: 'long',
      day: 'numeric',
    });
    const updatedAt = dateFormat(updated_at, { month: 'long', day: 'numeric' });
    const currentStep =
      this.props.application.attributes.current_state !== 'state-unreviewed'
        ? Object.values(this.props.application.attributes.hiring_steps).find(
            (step: HiringStep) => step.value === this.props.application.attributes.current_state
          )
        : null;

    if (this.state.trail && this.props.onApplicationList) {
      return <ApplicationTrail application={this.props.application} label={this.state.label} />;
    } else {
      return (
        <div
          className={
            this.props.seenList.some(item => this.props.application.id === item.toString()) ||
            this.props.application.attributes.seen_by_employer
              ? 'seen'
              : 'unseen'
          }
        >
          <ApplicationCard
            application={this.props.application}
            avatar={recipient_avatar}
            title={name}
            titleSize='mid'
            titleOnClick={this.props.onApplicationList ? this.props.setApplication : null}
            titleUrl={
              this.props.onApplicationList
                ? this.props.application.attributes.urls.application_url
                : this.props.waitingReview
                ? null
                : this.props.profileUrl
            }
            className='lj-application-profile'
            headerButtons={
              <ApplicationHeaderButtonsContainer
                application={this.props.application}
                topFilter={this.props.topFilter}
                urls={this.props.urls}
                waitingReview={this.props.waitingReview}
                moveToStep={this.props.moveToStep}
              />
            }
            experienceYears={experience_years}
            location={location}
          >
            <ApplicationBodyTitle {...jobAd} jobId={jobId}>
              <ApplicationSteps
                hiringSteps={this.props.hiringSteps}
                currentStep={this.props.currentStep}
                delivered={this.props.delivered}
                stepLabel={this.props.stepLabel}
                moveToStep={this.handleMoveToStep}
                showLabel={true}
                application={this.props.application}
                urls={this.props.urls}
              />
              {!this.props.delivered && (
                <>
                  <StepColor className='stepColor' slug={this.props.currentStep} />
                  <div className='stepText'>{this.props.stepLabel}</div>
                </>
              )}
            </ApplicationBodyTitle>
            <ApplicationCardBody>
              <RatingContainer
                rateApplicationUrl={this.props.application.attributes.urls.rate_application_url}
                ratings={ratings}
                screeningNotes={this.props.application.attributes.screening_notes}
                hideRatingContainer={this.props.hideRatingContainer}
                showRatingBody={this.state.showRatingBody}
                toggleRatingBody={this.toggleRatingBody}
              />
            </ApplicationCardBody>
            <ApplicationBodyFooter {...{ submittedAt, updatedAt, vertical: true }}>
              <ApplicationStateButtons
                delivered={this.props.delivered}
                moveToStep={this.handleMoveToStep}
                rejectApplication={this.props.rejectApplication}
                application={this.props.application}
                small={false}
                waitingReview={this.props.waitingReview}
              />
            </ApplicationBodyFooter>
          </ApplicationCard>
          <ApplicationTags
            application={this.props.application}
            applicationTags={application_tags}
            personTags={person_tags}
            testResults={test_results}
            testNotSent={currentStep && currentStep.test_name !== null && test_results.length === 0}
            handshake={this.props.application.attributes.handshake}
            recipientName={this.props.application.attributes.person.attributes.name}
            jobTitle={this.props.application.attributes.job_title}
          />
        </div>
      );
    }
  }
}

export default ApplicationBanner;
