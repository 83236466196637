import React from 'react';
import MultipleProcessesModal from './multiple_processes_modal';
import Modal from '../general/modal/modal';
import { shallow } from 'enzyme';
import applicationDummy from '__fixtures__/application_dummy';

let wrapped;

beforeAll(() => {
  wrapped = shallow(<MultipleProcessesModal application={applicationDummy} />);
});

it('should render correctly', () => {
  expect(wrapped.exists()).toBe(true);
});

it('should have a Modal component', () => {
  expect(wrapped.find(Modal).length).toEqual(1);
});
