import { shallow } from 'enzyme';
import * as React from 'react';
import RichText from './rich_text';

describe('<RichText>', () => {
  // Disabled because RichText currently only works correctly on a browser-like
  // environment
  xit('shallow renders without crashing', () => {
    const wrapped = shallow(<RichText />);
    expect(wrapped.exists()).toBe(true);
  });
});
