import React from 'react';

const styles = require('./rating_header.module.scss');

interface Props {
  dashboard?: boolean;
  employerRating: number;
  landingRating: number;
  showRatingBody: boolean;
  title: string;
  toggleRatingBody?: Function;
  url?: string;
}

export default function RatingHeader(props: Props) {
  const renderApplicationRating = () => {
    return (
      <div
        className={styles.readOnly}
      >{`${props.landingRating}/5 Landing.Jobs overall rating`}</div>
    );
  };
  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerLeft}>
        <div className={styles.headerTitle} id={props.dashboard ? styles.headerTitleBlue : null}>
          {props.title}
        </div>
      </div>
      <div className={styles.headerRight}>{renderApplicationRating()}</div>
    </div>
  );
}
