import Delimiter from '@editorjs/delimiter';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Embed from '@editorjs/embed';
import React from 'react';
import SimpleImage from '@editorjs/simple-image';
import uniqid from 'uniqid';
const styles = require('./rich_text.module.scss');

export enum Profile {
  Article = 'article',
  Default = 'default',
}

interface Props {
  className?: string;
  data?: any;
  label?: string;
  name?: string;
  onChange?: (data: any) => void;
  placeholder?: string;
  profile?: Profile;
}

interface State {
  data: any;
}

export default class RichText extends React.Component<Props, State> {
  editorId: any = uniqid();
  editor: EditorJS;

  constructor(props) {
    super(props);
    const data = this.props.data || {};
    this.state = { data };
  }

  componentDidMount() {
    const placeholder =
      this.props.placeholder === undefined
        ? "Let's write an awesome story!"
        : this.props.placeholder;

    const config = {
      data: this.state.data,
      holder: this.editorId,
      onChange: this.handleChange,
      minHeight: 100,
      placeholder,
      tools: this.getTools(),
    };

    this.editor = new EditorJS(config);
  }

  componentWillUnmount() {
    this.editor.destroy();
  }

  getTools() {
    const tools = {
      delimiter: Delimiter,
      header: Header,
      list: List,
      simpleImage: SimpleImage,
      embed: {
        class: Embed,
        inlineToolbar: true,
      },
    };

    return tools;
  }

  handleChange = async () => {
    const data = await this.editor.save();
    this.setState({ data });
    if (this.props.onChange) this.props.onChange(data);
  };

  render() {
    const data = JSON.stringify(this.state.data);
    const label = this.props.label;

    return (
      <label className={this.props.className}>
        {label && <div className={styles.label}>{label}</div>}
        <input type='hidden' name={this.props.name} value={data} />
        <div className={styles.editor} id={this.editorId} />
      </label>
    );
  }
}
