import * as React from 'react';
const rocketPath = require('iconic/rocket.svg');

interface Props {
  recipient_name: string;
}

class EmptyState extends React.Component<Props> {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    (window as any).IconicJS().inject('.iconic.iconic-sm');
  }
  render() {
    return (
      <div className='lj-empty-state'>
        <div className='lj-empty-content'>
          <p>
            This is the very beginning of your message history with{' '}
            <strong>{this.props.recipient_name}</strong>.
          </p>
          <p>Don’t be shy, start a conversation.</p>
          <img className='iconic iconic-sm' src={rocketPath} alt='rocket' />
        </div>
      </div>
    );
  }
}

export default EmptyState;
