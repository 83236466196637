const curatorsUrlsDummy = {
  application_notes: [
    {
      employee: 'Maria Cruz',
      url: '',
      date: '2019-08-09T15:45:42.898Z',
      text: 'This is a note\n',
    },
    {
      employee: 'Maria Cruz',
      url: '',
      date: '2019-09-09T15:45:42.898Z',
      text: 'This is a note\n',
    },
  ],
  cv: '/backoffice/people/104483/resume?open=true',
  feedbacks: [
    {
      feedback_date: '2019-08-09T15:45:42.898Z',
      feedback_link: '',
      feedback_message: 'This is feedback',
    },
    {
      feedback_date: '2019-09-09T15:45:42.898Z',
      feedback_link: '',
      feedback_message: 'This is feedback',
    },
  ],
  legacy_application_data_cv: null,
  other_referrals: [],
  referral_url: null,
  request_more_info_path: '/backoffice/applications/174782/info_requests/new',
};

export default curatorsUrlsDummy;
