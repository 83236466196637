import * as React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import FooterBanner from './FooterOverlay';
import Button from 'lj_shared/button/button';
import ApplyButton from 'components/jobPage/ApplyButton';

const jobDummy = require('components/jobs_page/fixtures/job_ad_dummy.json');
const createProps = () => ({
  applications: [{ jobId: 123, state: -2 }],
  currentUser: { id: 3, type: 'TalentUser' },
  handshake: null,
  jobAd: jobDummy,
  referralId: 12,
});

let props;
let wrapped: ShallowWrapper;

describe('Render footer banner props correctly', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<FooterBanner {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('should be hidden by default', () => {
    expect((wrapped.prop('className') as string).includes('showFooter')).toBe(false);
  });
});
