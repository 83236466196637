import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import Header from './Header';
import Icon from 'components/general/icon/icon';
import Tooltip from 'components/general/tooltip/tooltip';

const jobDummy = require('components/jobs_page/fixtures/job_ad_dummy.json');

const createProps = () => ({
  category: 'Full stack',
  citizenship: false,
  city: 'Lisbon',
  countryCode: 'PT',
  experienceLabel: 'Junior',
  isContractor: true,
  jobType: 'Permanent',
  niceToHaveLanguageLabel: 'French',
  preferredLanguageLabel: 'English',
  relocationPaid: true,
  remoteDetails: 'details',
  remoteWorkingLabel: 'label',
  salary: '20000-40000',
  visaSupport: true,
  officeLocations: [
    {
      google_place_id: '1232jfifnowefn',
      city: 'Lisbon',
      country_code: 'PT',
      label: 'Lisbon, Portugal',
    },
    {
      google_place_id: '1232jsssfifnowefn',
      city: 'Lisbone',
      country_code: 'PTE',
      label: 'Lisbone, Portugale',
    },
  ],
  jobAd: jobDummy,
});

let props;
let wrapped: ShallowWrapper;

describe('Header renders correctly', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<Header {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('should have six icons', () => {
    expect(wrapped.find(Icon)).toHaveLength(6);
  });

  it('should have two tooltips', () => {
    expect(wrapped.find(Tooltip)).toHaveLength(2);
  });

  it('should have a line', () => {
    expect(wrapped.find('hr')).toHaveLength(1);
  });
});
