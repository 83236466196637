import * as React from 'react';

import 'lib/globals';
import InlineIcon from '../inline_icon/inline_icon';
import Modal from '../modal/modal';
import './integration_modal.scss';

const xIcon = require('iconic/x.svg');

interface Props {
  url: string;
  atsName: string;
  atsPathToCreateToken: string;
  atsAPIDocUrl: string;
}

interface State {
  error: boolean;
  isSubmitting: boolean;
}

export default class IntegrationModal extends React.Component<Props, State> {
  private form: any;
  private modal: Modal;

  constructor(props) {
    super(props);
    this.setForm = this.setForm.bind(this);
    this.setModal = this.setModal.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = { error: false, isSubmitting: false };
  }

  handleClose(event) {
    event.preventDefault();
    this.modal.close();
  }

  handleSubmit(event) {
    event.preventDefault();
    if (!this.state.isSubmitting) {
      window.toggleSpinner(true);
      this.setState({ isSubmitting: true }, this.submit.bind(this));
    }
  }

  open() {
    this.modal.open();
  }

  render() {
    return (
      <Modal title={`Connect with ${this.props.atsName}`} defaultOpen={false} ref={this.setModal}>
        <form
          method='post'
          action={this.props.url}
          ref={this.setForm}
          onSubmit={this.handleSubmit}
          className='ld-FAQ-Modal'
        >
          <div className='lj-input-group u-marginBottom--mid'>
            <label className='lj-form-label ld-form-label lj-input--block u-marginTop--mid'>
              API token
              <input className='lj-input lj-input--mid lj-input--block' name='token' />
            </label>
            {this.state.error && (
              <div className='ld-alert ld-error-alert'>
                <InlineIcon path={xIcon} />
                Whoops, did you forget something? Please check if your credentials are correct.
              </div>
            )}
          </div>
          {this.props.atsName === 'Personio' && (
            <div className='lj-input-group'>
              <label className='lj-form-label ld-form-label lj-input--block'>
                Company ID
                <input className='lj-input lj-input--mid lj-input--block' name='company_id' />
              </label>
              {this.state.error && (
                <div className='ld-alert ld-error-alert'>
                  <InlineIcon path={xIcon} />
                  Whoops, did you forget something? Please check if your credentials are correct.
                </div>
              )}
            </div>
          )}
          {['Personio', 'Freshteam'].includes(this.props.atsName) && (
            <div className='lj-input-group u-marginBottom--mid'>
              <label className='lj-form-label ld-form-label lj-input--block u-marginTop--mid'>
                URL
              </label>
              <span>https://</span>
              <input
                className='lj-input lj-input--mid'
                name='company_name'
                placeholder='your-company'
              />
              <span>.{this.props.atsName.toLowerCase()}.com</span>
              {this.state.error && (
                <div className='ld-alert ld-error-alert'>
                  <InlineIcon path={xIcon} />
                  Whoops, did you forget something? Please check if your credentials are correct.
                </div>
              )}
            </div>
          )}
          {this.props.atsName === 'Teamtailor' && (
            <div className='lj-input-group u-marginBottom--mid'>
              <label className='lj-form-label ld-form-label lj-input--block u-marginTop--mid'>
                URL
              </label>
              <span>https://app.teamtailor.com/companies/ </span>
              <input
                className='lj-input lj-input--mid'
                name='company_id'
                placeholder='your-company-id'
              />
              <span> /</span>
              {this.state.error && (
                <div className='ld-alert ld-error-alert'>
                  <InlineIcon path={xIcon} />
                  Whoops, did you forget something? Please check if your credentials are correct.
                </div>
              )}
            </div>
          )}
          {`To get an ${
            this.props.atsName === 'Personio' ? 'API token and Company ID' : 'API token'
          }, log in to your ${this.props.atsName} acount, and then go to `}
          <a className='boldify'>{this.props.atsPathToCreateToken}</a>
          {this.props.atsName === 'Personio'
            ? ' and copy the token and company ID.'
            : ' and create a new token.'}
          <div className='ld-FAQ-subtext'>
            {'For more information, read '}
            <a
              className='purplefy'
              href={this.props.atsAPIDocUrl}
              rel='noopener noreferrer'
              target='_blank'
            >
              {`${this.props.atsName} API documentation.`}
            </a>
          </div>
          <div className='ld-modal-footer'>
            <button
              className='ld-button ld-dark-button ld-large-button ld-flat-button ld-border-button'
              onClick={this.handleClose}
            >
              CANCEL
            </button>
            <button className='ld-modal-primaryButton ld-large-button'>CONNECT</button>
          </div>
        </form>
      </Modal>
    );
  }

  submit() {
    $.post(this.props.url, $(this.form).serialize())
      .done(data => (window.location = data.location))
      .fail(() => this.setState({ error: true, isSubmitting: false }))
      .always(() => window.toggleSpinner(false));
  }

  setForm(element) {
    this.form = element;
  }

  setModal(element) {
    this.modal = element;
  }
}
