import React from 'react';
import SendTestModal from './send_test_modal';
import Modal from '../general/modal/modal';
import Button from 'lj_shared/button/button';
import { shallow } from 'enzyme';

let wrapped;

beforeAll(() => {
  wrapped = shallow(
    <SendTestModal
      sendTestAftermath={jest.fn()}
      testInviteUrl={''}
      testCheckUrl={''}
      stepId={2}
      personId='12345'
      jobAdId='1'
    />
  );
});

it('should render correctly', () => {
  expect(wrapped.exists()).toBe(true);
});

it('should have a Modal component', () => {
  expect(wrapped.find(Modal).length).toEqual(1);
});

it('should have a send test and cancel button', () => {
  expect(wrapped.find(Button).length).toEqual(2);
});
