import * as React from 'react';
import Accordion from '../general/accordion/accordion';
import Button from 'lj_shared/button/button';
import Checkbox from '../form/checkbox/checkbox';
import LocationInput from 'components/form/location_input/location_input';
import DotLoader from 'lj_shared/dot_loader/dot_loader';
import 'lib/globals';
import Icon, { Size, Style } from 'components/general/icon/icon';
import Text from 'components/form/text/text';
import RatingDimensionContainer from '../general/rating_dimension/rating_dimension_container';
import { RatingsToDimensions } from './ratingFilterUtils';
import { titleCase } from '../../lib/string';

const styles = require('./sidebar_filters.module.scss');

const expiredFilters = [
  'in_process',
  'meet_requirements',
  'worth_looking_at',
  'probably_not_a_match',
];

export interface Dimension {
  value: number;
  title: string;
}

export interface Props {
  applyFilters: Function;
  clearFilters: Function;
  dimensions: any;
  filters: {
    ratings: Dimension[];
    city: string;
    country: string;
    eu_citizenship: boolean;
    in_process: boolean;
    meet_requirements: boolean;
    worth_looking_at: boolean;
    probably_not_a_match: boolean;
    name: string;
  };
  googleMapsApiKey: string;
  mobileFiltersOpen: boolean;
  openCloseMobileFilters: Function;
  setDimension: Function;
  setFilter: Function;
  topFilter: string;
}

interface State {
  googleMapsReady: boolean;
  width: number;
}

/**
 * Filters for the employers applications page
 */

let nameRef: any;

export function handleCleanNameInput() {
  nameRef.current.input.value = '';
}
class SidebarFilters extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    nameRef = React.createRef();
    this.state = { googleMapsReady: true, width: 0 };

    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleClearFilters = this.handleClearFilters.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.handleWindowSizeChange(); // Set width
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  handleLocationChange(location) {
    if (location.address_components) {
      const countryObj = location.address_components.find(component =>
        component.types.includes('country')
      );
      const cityObj = location.address_components.find(component =>
        component.types.includes('locality')
      );
      if (countryObj || cityObj) {
        this.props.setFilter(countryObj?.long_name, 'country');
        this.props.setFilter(cityObj?.long_name, 'city');
      }
    }
  }

  handleClearFilters() {
    handleCleanNameInput();
    this.props.clearFilters();

    // Hack to clear location input
    this.setState({ googleMapsReady: false });
    setTimeout(() => {
      this.setState({ googleMapsReady: true });
    }, 5);
  }

  handleChange = (event, type) => {
    if (event.target.checked !== this.props.filters[type]) {
      this.props.setFilter(event.target.checked, type);
    }
  };

  handleNameChange = event => {
    this.props.setFilter(event.target.value, 'name');
  };

  itemYearsRender(year) {
    return <div id={year.id}>{year.title}</div>;
  }

  renderLoadingAnimation = () => {
    return (
      <div style={{ position: 'relative' }}>
        <DotLoader />
      </div>
    );
  };

  renderClearFiltersButton() {
    return window.screen.width < 767 ? (
      <div className={styles.purpleTopBarMobile}>
        <div className={styles.purpleTopBarMobileTitle}>Refine Search</div>
        <Button
          buttonSize='xSmall'
          otherClasses={styles.clearFiltersButtonMobile}
          onClick={this.handleClearFilters}
        >
          Clear all
        </Button>
        <Icon
          name='close'
          size={Size.Big}
          className={styles.closeButtonMobile}
          color='white'
          clickHandler={this.props.openCloseMobileFilters}
        />
      </div>
    ) : (
      <Button
        buttonSize='xSmall'
        buttonType='link'
        buttonColor='black'
        otherClasses={styles.clearFiltersButton}
        onClick={this.handleClearFilters}
      >
        Clear all
      </Button>
    );
  }

  renderStandardFilters = (mobile = false) => {
    return (
      <>
        {this.renderFilters()}
        {mobile ? this.renderApplyFiltersButton(true) : this.renderFilterButtons()}
      </>
    );
  };

  renderFilterButtons = () => {
    return (
      <div className={styles.filterButtonsContainer}>
        {this.renderClearFiltersButton()}
        {this.renderApplyFiltersButton()}
      </div>
    );
  };
  renderRefineSearchButton() {
    return this.props.mobileFiltersOpen ? (
      <>{this.renderStandardFilters(true)}</>
    ) : (
      <div className={styles.buttonPurpleBackground}>
        <Button
          otherClasses={styles.refineSearchButton}
          onClick={() => this.props.openCloseMobileFilters()}
        >
          Refine Search
        </Button>
      </div>
    );
  }

  handleMobileApplySearch = () => {
    this.props.openCloseMobileFilters();
    this.applyFilters();
  };

  applyFilters = () => {
    const newName = nameRef.current.input.value;
    if (newName && newName !== this.props.filters.name) {
      this.props.setFilter(newName, 'name', this.props.applyFilters);
    } else {
      this.props.applyFilters();
    }
  };

  renderApplyFiltersButton(mobile = false) {
    if (mobile) {
      return (
        <div className={styles.applyFilters}>
          <Button
            buttonColor='ripePlum'
            otherClasses={styles.applyMobileFilterButton}
            onClick={() => this.handleMobileApplySearch()}
          >
            Apply Filters
          </Button>
        </div>
      );
    }
    return (
      <Button
        buttonColor='ripePlum'
        otherClasses={styles.applyFilterButton}
        onClick={() => this.applyFilters()}
      >
        Apply
      </Button>
    );
  }

  renderFilters() {
    const RenderOpenIcon = () => (
      <div className={styles.accordionIcon}>
        <Icon name='plus' color='black' size={Size.MediumLarge} style={Style.Thick} />
      </div>
    );

    const RenderCloseIcon = () => (
      <div className={styles.accordionIcon}>
        <Icon name='minus' color='black' size={Size.MediumLarge} style={Style.Thick} />
      </div>
    );

    const renderFilterIfMobile = () => {
      if (this.props.mobileFiltersOpen) {
        return this.renderClearFiltersButton();
      }
    };

    return (
      <div className={styles.sidebarFiltersWrapper}>
        <div className={styles.filtersTitle}>Filters</div>
        {renderFilterIfMobile()}
        <div className='accordion-wrapper'>
          {this.state.googleMapsReady ? (
            <Accordion
              title='Candidate Location'
              style={{ borderBottom: '1px solid #d4d7d8' }}
              openIcon={<RenderOpenIcon />}
              closeIcon={<RenderCloseIcon />}
              filterList={true}
            >
              <LocationInput handleLocationChange={this.handleLocationChange} types='regions' />
              <div className={styles.checkboxList}>
                <Checkbox
                  name='eu_citizenship'
                  value='1'
                  label='Has EU citizenship'
                  color='ripePlum'
                  checked={this.props.filters.eu_citizenship}
                  onChange={this.handleChange}
                  controlled={true}
                />
              </div>
            </Accordion>
          ) : (
            this.renderLoadingAnimation()
          )}
          <Accordion
            title='Candidate Name'
            style={{ borderBottom: '1px solid #d4d7d8' }}
            openIcon={<RenderOpenIcon />}
            closeIcon={<RenderCloseIcon />}
            filterList={true}
          >
            <Text placeholder='E.g. John Smith' value={this.props.filters.name} ref={nameRef} />
          </Accordion>
          <Accordion
            title='Rating'
            style={{ borderBottom: '1px solid #d4d7d8' }}
            openIcon={<RenderOpenIcon />}
            closeIcon={<RenderCloseIcon />}
            filterList={true}
          >
            <RatingDimensionContainer
              dimensions={RatingsToDimensions(this.props.dimensions, this.props.setDimension)}
              showDimensions
              maxRating={5}
            />
          </Accordion>
          {this.props.topFilter === 'rejected' && (
            <Accordion
              title='Expired Applications'
              style={{ borderBottom: '1px solid #d4d7d8' }}
              openIcon={<RenderOpenIcon />}
              closeIcon={<RenderCloseIcon />}
              filterList={true}
            >
              <div className={styles.checkboxList}>
                {expiredFilters.map(name => (
                  <Checkbox
                    name={name}
                    value='1'
                    label={`From ${titleCase(name)}`}
                    color='ripePlum'
                    checked={this.props.filters[name]}
                    onChange={this.handleChange}
                    controlled={true}
                    key={name}
                  />
                ))}
              </div>
            </Accordion>
          )}
        </div>
      </div>
    );
  }

  render() {
    return window.screen.width < 767
      ? this.renderRefineSearchButton()
      : this.renderStandardFilters();
  }
}

export default SidebarFilters;
