const archivedJobDummy = {
  id: '7380',
  type: 'job_ad',
  attributes: {
    title: 'UX/UI Specialist',
    state: 99,
    city: 'Lisbon',
    location: 'Lisbon, Portugal',
    expires_days: null,
    closed_at: '2019-05-06T13:55:24.528Z',
    created_at: '2018-12-19T16:36:29.896Z',
    company_id: 8,
    updated_at: '2019-05-06T13:55:25.119Z',
    status_label: 'Closed',
    urls: {
      job_ad_ongoing: '/at/landing-jobs/ux-ui-specialist',
      job_ad_draft: '/post/ux-ui-specialist/submit_offer',
      job_ad_archived: '/employer/jobs/ux-ui-specialist/preview',
      job_ad_publish: '/employer/jobs/ux-ui-specialist/publish',
      job_ad_unpublish: '/employer/jobs/ux-ui-specialist/unpublish',
      job_ad_close: '/employer/jobs/ux-ui-specialist/close',
      job_ad_duplicate: '/post/from/offer?offer_id=7380',
      job_edit_url: '/employer/jobs/ux-ui-specialist/revisions/new',
      revision_edit_url: '/employer/jobs/ux-ui-specialist/edit',
      ats_pair_url: '/employer/jobs/7380/ats/new',
      ats_unpair_url: '/employer/jobs/7380/ats',
      send_to_review_url: '/employer/jobs/ux-ui-specialist/send_to_review',
      delete_job_url: '/employer/jobs/7380',
    },
    share_urls: null,
    job_has_ats_offer: null,
    company_has_ats: null,
    country: 'Portugal',
    applications_counter: null,
    status_color_class: 'ld-status-color u-fill--silverSand',
    valid: null,
  },
};

export default archivedJobDummy;
