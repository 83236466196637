import * as React from 'react';
import ViewCurationPool from './view_curation_pool';
import './curation_pool_page.scss';
import { createBrowserHistory } from 'history';

const baseUrl = '/backoffice/curation_pools/';
const history = createBrowserHistory();

interface Props {
  curation_pools: any;
  new_url: string;
}

interface State {
  current_pool: any;
}

class CurationPoolPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      current_pool: null,
    };

    this.handleView = this.handleView.bind(this);
    this.awaitingCurationCounter = this.awaitingCurationCounter.bind(this);
  }

  componentDidUpdate(_prevProps, prevState) {
    if (this.state.current_pool !== null) {
      history.push(baseUrl + String(this.state.current_pool.id));
    } else {
      history.push(baseUrl);
    }
  }

  handleView(event, pool) {
    event.preventDefault();

    this.setState({ current_pool: pool });
  }

  avgDaysWaiting(jobs) {
    let sum = 0;
    let avgDays = 0;
    const total = jobs.length;

    jobs.map(job => (sum += Number(job.waitingDays)));
    if (total !== 0) {
      avgDays = sum / total;
    }
    return Math.round(avgDays);
  }

  awaitingCurationCounter(jobs) {
    let appsWaitingCuration = 0;

    jobs.map(
      job =>
        (appsWaitingCuration += Number(job.total_applications) - Number(job.curated_applications))
    );
    return appsWaitingCuration;
  }

  render() {
    if (this.state.current_pool === null) {
      return (
        <article className='bo-container'>
          <h2 className='ld-h2'>
            Curation Pools{' '}
            <a className='ld-button ld-small-button ld-blue-button' href={this.props.new_url}>
              New Curation Pool
            </a>
          </h2>
          <div className='info'>
            Notice that the information presented below is updated every 6 hours.
          </div>
          <table className='ld-table'>
            <thead>
              <tr>
                <th />
                <th>Id</th>
                <th>Name</th>
                <th># Curators</th>
                <th># Jobs</th>
                <th>Avg days waiting</th>
                <th># Applications awaiting curation</th>
              </tr>
            </thead>

            <tbody>
              {this.props.curation_pools.map(pool => {
                return (
                  <tr key={pool.id}>
                    <td>
                      <div className='table-link' onClick={event => this.handleView(event, pool)}>
                        View
                      </div>
                      <a className='table-link' href={pool.urls.edit}>
                        Edit
                      </a>
                    </td>
                    <td>{pool.id}</td>
                    <td>{pool.name}</td>
                    <td>{pool.admins.length}</td>
                    <td>{pool.jobs.length}</td>
                    <td>{this.avgDaysWaiting(pool.jobs)}</td>
                    <td>{this.awaitingCurationCounter(pool.jobs)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </article>
      );
    } else {
      return (
        <div>
          <ViewCurationPool curation_pool={this.state.current_pool} />
        </div>
      );
    }
  }
}

export default CurationPoolPage;
