import React from 'react';
const styles = require('./location_dimension.module.scss');
import { GoogleMaps } from 'lib/scripts';
import Autocomplete from 'react-google-autocomplete';
import Checkbox from '../form/checkbox/checkbox';
import RatingBox from './rating_box';
import DotLoader from 'lj_shared/dot_loader/dot_loader';
import Icon from '../general/icon/icon';
export interface Props {
  candidateAcceptedJobTimezone: boolean;
  candidateHasTimezone: boolean;
  classes?: string;
  handleChangeLocation: Function;
  handleChangeTimeZone: Function;
  handleChangeTimeZoneNegative: Function;
  handleChangeWorkPermit: Function;
  jobCountryNames: string;
  jobType: string;
  jobWithTimezone: boolean;
  locationDefaultValue: any;
  locationRef: any;
  ratingRef: any;
  timeZoneDefaultValue: boolean;
  timeZoneMismatch: boolean;
  timeZoneNegativeDefaultValue: boolean;
  value: any;
  citizenship: boolean;
  workPermitDefaultValue: boolean;
}

interface State {
  googleMapsReady: boolean;
}

export default class LocationDimension extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { googleMapsReady: false };

    this.handleChangeLocation = this.handleChangeLocation.bind(this);
    this.handleChangeWorkPermit = this.handleChangeWorkPermit.bind(this);
    this.handleChangeTimeZone = this.handleChangeTimeZone.bind(this);
  }

  UNSAFE_componentWillMount() {
    void GoogleMaps.load().then(() => this.setState({ googleMapsReady: true }));
  }

  handleChangeLocation = location => {
    this.props.handleChangeLocation(location);
  };

  handleChangeWorkPermit = event => {
    this.props.handleChangeWorkPermit(event.target.checked);
  };

  handleChangeTimeZone = event => {
    this.props.handleChangeTimeZone(event.target.checked);
  };

  handleChangeTimeZoneNegative = event => {
    this.props.handleChangeTimeZoneNegative(event.target.checked);
  };

  renderLoadingAnimation = () => {
    return (
      <div style={{ position: 'relative' }}>
        <DotLoader />
      </div>
    );
  };

  renderRemoteWarning(partially = false) {
    return (
      <div className={styles.remoteWarning}>
        <div className={styles.remoteWarningTitle}>
          <Icon name='warning' color='flamingo' />
          &nbsp;<span>Attention:</span>
        </div>{' '}
        This job is
        {partially ? ' partially ' : null} remote. Please check <b>Remote Details</b>
        {this.props.jobWithTimezone && <b> and Job Time Zones</b>}.
      </div>
    );
  }

  renderLocationComponent() {
    return this.state.googleMapsReady ? (
      <>
        <p className={styles.locationSelect}>The candidate is based in:</p>
        <Autocomplete
          onPlaceSelected={this.handleChangeLocation}
          options={{
            types: ['(cities)'],
          }}
          placeholder='Location...'
          id='location-filter'
          name='talent_location'
          ref={this.props.locationRef}
          defaultValue={this.props.locationDefaultValue}
          className={this.props.classes}
          inputAutocompleteValue='off'
        />
      </>
    ) : (
      this.renderLoadingAnimation()
    );
  }

  renderWorkPermitCheckbox() {
    return (
      <div className={styles.checkbox}>
        <Checkbox
          onChange={this.handleChangeWorkPermit}
          name='work_permit'
          checked={this.props.workPermitDefaultValue}
          controlled={false}
          color='puertoRico'
          label={
            'The candidate has a valid work permit/citizenship for one of the following countries: ' +
            this.props.jobCountryNames
          }
        />
      </div>
    );
  }

  renderCandidateTimeZoneMismatchCheckbox() {
    return (
      <div className={styles.checkbox}>
        <Checkbox
          controlled
          color='silverSand'
          label='The candidate is willing to work in the Time Zone required for
          the job'
          checked
          disabled
        />
      </div>
    );
  }

  renderCandidateIsInTimezoneCheckbox() {
    return (
      <div className={styles.checkbox}>
        <Checkbox
          controlled
          color='silverSand'
          label='The candidate is in an eligible Time Zone for this job'
          checked
          disabled
        />
      </div>
    );
  }

  renderCandidateIsNotInTimezoneCheckbox() {
    return (
      <div className={styles.checkbox}>
        <Checkbox controlled color='silverSand' checked disabled>
          The candidate is <b>not</b> in an eligible Time Zone for this job
        </Checkbox>
      </div>
    );
  }

  renderJobsWithTimeZoneCheckboxes() {
    if (!this.props.timeZoneMismatch) {
      return this.renderCandidateIsInTimezoneCheckbox();
    } else if (this.props.timeZoneMismatch && this.props.candidateAcceptedJobTimezone) {
      return this.renderCandidateTimeZoneMismatchCheckbox();
    } else {
      return this.renderCandidateIsNotInTimezoneCheckbox();
    }
  }

  renderTimeZoneCheckboxes() {
    return (
      <>
        <div className={styles.checkbox}>
          <Checkbox
            onChange={this.handleChangeTimeZone}
            name='time_zone'
            controlled={true}
            color='puertoRico'
            label='The candidate is in an eligible Time Zone for this job'
            checked={this.props.timeZoneDefaultValue}
          />
        </div>
        <div className={styles.checkbox}>
          <Checkbox
            onChange={event => this.props.handleChangeTimeZoneNegative(event.target.checked)}
            name='time_zone_negative'
            controlled={true}
            color='puertoRico'
            checked={this.props.timeZoneNegativeDefaultValue}
          >
            The candidate is <b>not</b> in an eligible Time Zone for this job
          </Checkbox>
        </div>
      </>
    );
  }
  renderOnsiteJob() {
    if (this.props.citizenship) {
      return (
        <div className={styles.questions}>
          {this.renderLocationComponent()}
          {this.renderWorkPermitCheckbox()}
        </div>
      );
    } else {
      return <div className={styles.questions}>{this.renderLocationComponent()}</div>;
    }
  }

  renderRemoteJob() {
    const anyTimezone = !this.props.jobWithTimezone;

    return (
      <div className={styles.questions}>
        {this.renderRemoteWarning()}
        {this.props.citizenship && this.renderWorkPermitCheckbox()}
        {this.props.jobWithTimezone && this.props.candidateHasTimezone
          ? this.renderJobsWithTimeZoneCheckboxes()
          : anyTimezone
          ? this.renderCandidateIsInTimezoneCheckbox()
          : this.renderTimeZoneCheckboxes()}
      </div>
    );
  }

  render() {
    return (
      <>
        <div className={styles.container}>
          <h3 className={styles.h3}>Location Match</h3>
          <RatingBox value={this.props.value} name='location' ratingRef={this.props.ratingRef} />
        </div>
        {this.props.jobType === 'onsite_job'
          ? this.renderOnsiteJob()
          : this.props.jobType === 'full_remote'
          ? this.renderOnsiteJob()
          : this.props.jobType === 'partial_remote'
          ? this.renderOnsiteJob()
          : this.props.jobType === 'global_remote'
          ? this.renderRemoteJob()
          : null}
      </>
    );
  }
}
