import React from 'react';
import Card from 'components/general/card/card';
import CardHeader, { HeaderType } from 'components/general/card/card_header';
import VerticalCardContent from 'components/general/card/vertical_card_content';

export default function AboutYourselfContainer({
  openModal,
  aboutYourSelfCard,
  handshakeProfile,
  publicProfilePage,
}) {
  return (
    <Card>
      <CardHeader
        header='Personal details'
        headerKey='about_yourself_lj'
        type={HeaderType.Regular}
        openModal={openModal}
        iconAction='edit'
        public={handshakeProfile}
        publicProfilePage={publicProfilePage}
      />
      <VerticalCardContent {...aboutYourSelfCard} handshakeProfile={handshakeProfile} />
    </Card>
  );
}
