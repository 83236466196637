import * as React from 'react';
import JobHiringStep from './job_hiring_step';
import { shallow, ShallowWrapper } from 'enzyme';

let wrapped: ShallowWrapper;
const step = {
  description: '',
  hasApplications: true,
  id: 1,
  jobId: 1,
  kind: 'fixed',
  message: {
    id: 2,
    text: '',
  },
  name: '',
  order: 1,
  slug: '',
};
const urls = {};
const standardTests = {
  data: [
    {
      attributes: {
        id: 1,
        name: 'Test',
        duration: 15,
        language: ['Ruby', 'React'],
        experience_levels: ['1year', 'Junior'],
      },
    },
  ],
};

describe('Job Hiring Step component', () => {
  beforeEach(() => {
    wrapped = shallow(
      <JobHiringStep
        isNew={false}
        isRevision={true}
        jobId={1}
        backoffice={false}
        onDestroy={jest.fn()}
        onMessageSave={jest.fn()}
        onSave={jest.fn()}
        step={step}
        urls={urls}
        standardTests={standardTests}
        technicalAssessmentsEnabled
        landingScreeningEnabled
      />
    );
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
