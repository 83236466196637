import React from 'react';
import { User } from '__models__/gql/all';
import { JobAd } from '__models__/job_ad';
import Icon, { Size } from 'components/general/icon/icon';
import JobList from './JobList';
import Button from 'lj_shared/button/button';
const styles = require('./DashboardJobList.module.scss');

type JsonBookmark = [number, number];
interface Props {
  applications: number[];
  bookmarks: JsonBookmark[];
  jobResults: JobAd[];
  suggestedJobs?: JobAd[];
  subscriptionJobs?: JobAd[];
  user?: Partial<User>;
  profilePath?: string;
  maxHiringBonus?: number;
  isSearching: boolean;
  suggestionRelevantPath: string;
  suggestionNonRelevantPath: string;
  profileCompleted: boolean;
}

const DashboardJobList = (props: Props) => {
  const {
    applications,
    bookmarks,
    jobResults,
    suggestedJobs,
    subscriptionJobs,
    user,
    profilePath,
    suggestionRelevantPath,
    suggestionNonRelevantPath,
    profileCompleted,
  } = props;

  const JobOffers = () => {
    if (jobResults?.length === 0) {
      return null;
    }

    return (
      <>
        <JobList
          header={<h2>Recent jobs</h2>}
          footer={
            <a className={styles.seeMoreJobs} href={window.Routes.jobs_url()}>
              See more jobs...
            </a>
          }
          applications={applications}
          bookmarks={bookmarks}
          jsonJobs={jobResults}
          user={user}
        />
      </>
    );
  };

  const SuggestedJobs = () => {
    if (!suggestedJobs?.length) {
      return null;
    }

    const header = (
      <div className={styles.listHeader}>
        <Icon name='flame' size={Size.MediumBig} color='flamingo' />
        <h2>Your hottest job matches</h2>
        <span>- based on your profile.</span>
        {!profileCompleted && (
          <>
            <a href={profilePath}>Update your profile</a>
            <span>before applying to a job offer.</span>
          </>
        )}
      </div>
    );

    const footer = (
      <div className={styles.listFooter}>
        <span>Did these jobs match what you were looking for?</span>
        <a
          href={suggestionRelevantPath}
          className='lj-button lj-button--tuftsBlueMidLight lj-button--xSmall'
        >
          Yes, very much
        </a>
        <a
          href={suggestionNonRelevantPath}
          className='lj-button lj-button--tuftsBlueMidLight lj-button--xSmall lj-button--border'
        >
          No, not at all
        </a>
      </div>
    );

    return (
      <JobList
        header={header}
        footer={footer}
        applications={applications}
        bookmarks={bookmarks}
        jsonJobs={suggestedJobs}
        isHottest={true}
        user={user}
        showInfoTags={true}
      />
    );
  };

  const SubscriptionsJobs = () => {
    if (subscriptionJobs?.length === 0) {
      return null;
    }

    const header = (
      <div className={`${styles.listHeader} extra-margin`}>
        <h2>Jobs from your subscriptions</h2>
        <span>{` - showing ${subscriptionJobs.slice(0, 5).length} of ${
          subscriptionJobs.length
        }`}</span>
      </div>
    );

    const footer = (
      <div className={styles.seeAllButton}>
        <Button
          buttonSize='xSmall'
          buttonColor='tuftsBlue'
          buttonUrl={window.Routes.subscriptions()}
        >
          See All
        </Button>
      </div>
    );

    return (
      <JobList
        header={header}
        footer={footer}
        applications={applications}
        bookmarks={bookmarks}
        jsonJobs={subscriptionJobs.slice(0, 5)}
        isHottest={true}
        user={user}
        showInfoTags={false}
      />
    );
  };

  return (
    <div className={styles.jobListContainer}>
      <SuggestedJobs />
      <JobOffers />
      <SubscriptionsJobs />
    </div>
  );
};

export default DashboardJobList;
