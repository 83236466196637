/* eslint-disable indent */
import React, { useRef, useState, useEffect } from 'react';
import AboutYourselfModal from './aboutYourself/AboutYourselfModal';
import { cloneDeep } from 'lodash';

import { postJSON, deleteJSON, patchJSON, handleRequestError } from 'lib/request_deprecated';
import { titleize, titleCamel } from 'lib/string';
import {
  citizenships,
  jobCategories,
} from 'components/registration/registration_utils/registration_utils';
import { camelizeObj } from 'lib/object';
import ProfileForm from 'components/registration/profile_form';

import AboutYourselfContainer from './aboutYourself/AboutYourselfContainer';
import AboutYourselfFields from './services/aboutYourselfFields';
import Card from 'components/general/card/card';
import CardHeader, { HeaderType } from 'components/general/card/card_header';
import Certificate from './services/Certificate';
import CertificationsModal from 'components/registration/CertificationsModal';
import CertificationsForm from 'components/registration/CertificationsForm';
import Education from './services/education';
import EducationForm from 'components/registration/education_form';
import EducationModal from 'components/registration/education_modal';
import EmptyState from 'components/general/empty_state/empty_state';
import HorizontalCardContent from 'components/general/card/horizontal_card_content';
import JobInterestsFields from './services/jobInterestsFields';
import JobInterestsModalContainer from './jobInterestsModal/JobInterestsModalContainer';
import MainCardContainer from './mainCard/MainCardContainer';
import Modal from 'components/general/modal/modal';
import ProfessionalExperienceForm from 'components/registration/professional_experience_form';
import ProfessionalExperienceModal from 'components/registration/professional_experience_modal';
import ProfessionalExperienceService from './services/professionalExperience';
import Sidebar from 'components/general/sidebar/sidebar';
import IntroModal from 'components/talentUser/introModal/intro_modal';
import MainCardUtils from './mainCard/MainCardUtils';
import VerticalCardContent from 'components/general/card/vertical_card_content';
import { ViewerContext } from './ViewerContext';
import { gql, apolloClient } from 'lib/graphql';
import { Cv } from '__models__/cv';
import Timeline from './timeline/Timeline';
import { scrollTo } from 'lib/scrollTo';

const styles = require('./TalentProfile.module.scss');

const defaultAvatar = 'https://assets.landing.jobs/default_avatars/avatar2.png';

const UPDATE_PUBLIC_PROFILE_GQL = gql`
  mutation ($value: Boolean!) {
    person {
      updatePublicProfile(value: $value) {
        errors {
          field
          message
        }
      }
    }
  }
`;

const GQL_QUERY_PROFILE_URL = gql`
  query {
    profileUrl
  }
`;

export interface Props {
  profile: any;
  publicProfilePage?: boolean;
  origin?: string;
  unlocked?: boolean;
  open_profile?: boolean;
}

export default function TalentProfile({
  open_profile,
  origin,
  profile,
  publicProfilePage,
  unlocked,
}: Props) {
  useEffect(() => {
    // This will make the page scroll to the first error it encounters
    const errors = document.getElementsByClassName('errorMessage');
    if (errors && errors.length !== 0) {
      errors[0].parentElement.scrollIntoView();
    }
  });
  const handshakeProfile = origin === 'employer/people';
  const viewerContext = new ViewerContext(handshakeProfile, publicProfilePage);
  const profileAttributes = profile.data.attributes;
  const relocationCountries = profileAttributes.person.data.attributes.relocation_countries;
  const profilePublicLink = profileAttributes.person.data.attributes.profile_url;
  const personAttributes = camelizeObj(profileAttributes.person.data.attributes);
  const skills = profileAttributes.skills.data.map(({ id, attributes }) => ({
    id,
    tag: attributes.name,
    experienceLevel: attributes.experience_level,
  }));
  const listOfLinks = personAttributes.websites.map(({ categoryKey, id, fullUrl }) => ({
    category: categoryKey,
    id,
    title: categoryKey,
    url: fullUrl,
  }));
  const modalRef = useRef(null);
  const [alert, setAlert] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [publicLink, setPublicLink] = useState(profilePublicLink);
  const [modalActionId, setmodalActionId] = useState(null);
  const [revision, setRevision] = useState(0);
  const institutions = profileAttributes.institutions.data;
  const [education, setEducation] = useState(institutions);
  const [educationCard, setEducationCard] = useState(
    new Education({
      institutions,
      handshakeProfile,
      unlocked,
      open_profile,
    }).props()
  );
  const experiences = personAttributes.professionalExperiences;
  const [professionalExperiences, setProfessionalExperiences] = useState(experiences);
  const [professionalExperienceCard, setProfessionalExperienceCard] = useState(
    new ProfessionalExperienceService({
      experiences,
      handshakeProfile,
      unlocked,
      open_profile,
    }).props()
  );
  const certificates = personAttributes.certificates;
  const [certifications, setCertifications] = useState(certificates);
  const [certificationsCard, setCertificationsCard] = useState(
    new Certificate({
      certifications,
      handshakeProfile,
      unlocked,
      open_profile,
    }).props()
  );
  const [profileCompleteness, setProfileCompleteness] = useState(profileAttributes.completeness);

  const [profileForm, setProfileForm] = useState(
    new ProfileForm({
      availability: personAttributes.availability || '',
      avatar: profileAttributes.avatar || defaultAvatar,
      birthYear: personAttributes.birthYear || '',
      categories: personAttributes.categories,
      citizenships: citizenships(personAttributes.citizenships.split(', ')),
      city: personAttributes.city || '',
      contactPreference: personAttributes.contactPreference?.id,
      contactPreferenceOther: personAttributes.contactPreferenceOther,
      country: personAttributes.countryCode || '',
      countryName: personAttributes.countryName || '',
      cvFileName: personAttributes.cvFileName || '',
      cvs: personAttributes.cvs || [],
      description: personAttributes.bio || '',
      email: personAttributes.email,
      experienceYears: personAttributes.experienceYears || '',
      firstName: personAttributes.firstName,
      freelance: personAttributes.freelance,
      headline: personAttributes.headline,
      jobSearchAvailability: personAttributes.jobSearchAvailability,
      jobTypesInterest: personAttributes.jobTypesInterest || [],
      lastName: personAttributes.lastName,
      listOfLinks,
      location: personAttributes.location || '',
      minimumRate: publicProfilePage ? null : personAttributes.minimumRate,
      maximumRate: publicProfilePage ? null : personAttributes.maximumRate,
      minimumGAS: publicProfilePage ? null : personAttributes.minimumGas,
      maximumGAS: publicProfilePage ? null : personAttributes.maximumGas,
      personLanguages: personAttributes.languages,
      phoneNumber: personAttributes.phoneNumber || '',
      remote: personAttributes.remote || '',
      relocation: personAttributes.relocation,
      relocationCountries: citizenships(relocationCountries),
      shareProfile: personAttributes.shareProfile,
      skills,
      skypeId: personAttributes.skypeId || '',
      startYear: personAttributes.startYear || '',
      url: window.Routes.edit_user_profile(),
    })
  );
  const [profileInfo, setProfileInfo] = useState(profileForm);
  const aboutYourSelfCard = new AboutYourselfFields({
    profileInfo,
    handshakeProfile,
    publicProfilePage,
    unlocked,
  }).props();
  const mainCard = MainCardUtils(profileInfo, profileCompleteness, {
    handshakeProfile,
    publicLink,
    publicProfilePage,
    name: profileAttributes.name,
    recentlyUpdated: personAttributes.recentlyUpdated,
    unlocked,
    personId: profileAttributes.person.data.id,
    personSlug: personAttributes.slug,
  });

  function hasEducation() {
    return educationCard.length > 0;
  }

  const permanentEmployee = () => {
    return profileForm.jobTypesInterest?.some(item => item.id === 0);
  };

  const contractor = () => {
    return profileForm.jobTypesInterest?.some(item => item.id === 1);
  };

  const relevantFieldsForCompleteness = [
    'availability',
    'avatar',
    'city',
    'citizenships',
    'cvs',
    'description',
    'headline',
    'listOfLinks',
    'personLanguages',
    'relocation',
    profileForm.relocation && 'relocationCountries',
    'categories',
    'phoneNumber',
    'shareProfile',
    'skills',
    'startYear',
    contractor() && 'minimumRate',
    permanentEmployee() && 'minimumGAS',
  ];

  function renderEducation() {
    return (
      <Card>
        <CardHeader
          header='Education'
          headerKey='education'
          type={HeaderType.Regular}
          openModal={prepareModal}
          iconAction='add'
          public={handshakeProfile}
          publicProfilePage={publicProfilePage}
        />
        {hasEducation() ? (
          <HorizontalCardContent
            contentKey='education'
            content={educationCard}
            openModal={prepareModal}
            public={handshakeProfile}
            handleDelete={handleDeleteEducationClick}
            ljColor={handshakeProfile ? 'ripePlum' : 'tuftsBlue'}
            publicProfilePage={publicProfilePage}
          />
        ) : (
          renderEmptyState('academic experience')
        )}
      </Card>
    );
  }

  function hasCertifications() {
    return certifications && certifications.length > 0;
  }

  function renderCertifications() {
    return (
      <Card>
        <CardHeader
          header='Certifications'
          headerKey='certifications'
          type={HeaderType.Regular}
          openModal={prepareModal}
          iconAction='add'
          public={handshakeProfile}
          publicProfilePage={publicProfilePage}
        />
        {hasCertifications() ? (
          <HorizontalCardContent
            contentKey='certifications'
            content={certificationsCard}
            openModal={prepareModal}
            public={handshakeProfile}
            handleDelete={handleDeleteCertificateClick}
            ljColor={handshakeProfile ? 'ripePlum' : 'tuftsBlue'}
            publicProfilePage={publicProfilePage}
          />
        ) : (
          renderEmptyState('certifications')
        )}
      </Card>
    );
  }

  function hasProfessionalExperience() {
    return professionalExperiences && professionalExperiences.length > 0;
  }

  function renderEmptyState(context) {
    return (
      <EmptyState style={{ marginTop: '2rem' }}>
        {handshakeProfile
          ? `${profileAttributes.name} hasn’t added any ${context} ${
              context !== 'certifications' ? 'details' : ''
            } to their profile, once you request a handshake you can ask for more details.`
          : `Add your ${context} here.`}
      </EmptyState>
    );
  }

  const sortedProfessionalExperienceCards = professionalExperienceCard.sort(
    (a, b) => b.startYear - a.startYear
  );

  function renderProfessionalExperience() {
    return (
      <Card>
        <CardHeader
          header='Professional Experience'
          headerKey='professional_experience'
          type={HeaderType.Regular}
          openModal={prepareModal}
          iconAction='add'
          public={handshakeProfile}
          publicProfilePage={publicProfilePage}
        />
        {hasProfessionalExperience() ? (
          <HorizontalCardContent
            contentKey='professional_experience'
            content={sortedProfessionalExperienceCards}
            openModal={prepareModal}
            public={handshakeProfile}
            handleDelete={handleDeleteExperience}
            ljColor={handshakeProfile ? 'ripePlum' : 'tuftsBlue'}
            publicProfilePage={publicProfilePage}
          />
        ) : (
          renderEmptyState('professional experience')
        )}
      </Card>
    );
  }

  function renderTimeline() {
    return (
      <Card>
        <CardHeader
          header={handshakeProfile ? 'Timeline' : 'Your Timeline'}
          headerKey='timeline'
          type={HeaderType.Regular}
          public={handshakeProfile}
          publicProfilePage={publicProfilePage}
        />
        <Timeline
          experiences={professionalExperiences}
          education={education}
          certifications={certifications}
          handshakeProfile={handshakeProfile}
          hideInstitutionAndCompany={handshakeProfile && !unlocked && !open_profile}
        />
      </Card>
    );
  }

  function renderJobInterests() {
    const jobInterestsCard = new JobInterestsFields({
      profileForm,
      profileInfo,
      viewerContext,
    }).props();
    const props = { ...jobInterestsCard, handshakeProfile };

    return (
      <Card>
        <CardHeader
          header='Job Interests'
          headerKey='job_interests'
          type={HeaderType.Regular}
          openModal={prepareModal}
          iconAction='edit'
          public={handshakeProfile}
          publicProfilePage={publicProfilePage}
        />
        <VerticalCardContent {...props} />
      </Card>
    );
  }

  function renderModalContent() {
    switch (modalContent) {
      case 'intro':
        return (
          <Modal title='Basic Info' defaultOpen={false} ref={modalRef} disableOutsideClose={true}>
            <IntroModal
              showAvailabityOptions={true}
              cvUrl={window.Routes.resume_index()}
              experienceYearsList={profileAttributes.experience_years}
              handleAddWebsiteClick={handleAddWebsiteClick}
              handleChangeCV={handleChangeCV}
              handleDeleteWebsiteClick={handleDeleteWebsiteClick}
              handleFormChange={handleFormChange}
              handleSubmit={handleSubmit}
              maximumNumberOfSkills={profileAttributes.maximumNumberOfSkills}
              networks={profileAttributes.networks}
              profileForm={profileForm}
              proposedTags={profileAttributes.proposedTags}
              share_preferences={profileAttributes.share_preferences}
              showAvatar={true}
              startYearsList={profileAttributes.start_years}
              updateSkills={updateSkills}
            />
          </Modal>
        );
      case 'about_yourself_lj':
        return (
          <Modal
            title='About Yourself'
            defaultOpen={false}
            ref={modalRef}
            disableOutsideClose={true}
          >
            <AboutYourselfModal
              languages={profileAttributes.languages}
              citizenshipsList={profileAttributes.citizenships}
              proficiencyLevels={profileAttributes.proficiency_levels}
              handleFormChange={handleFormChange}
              handleSubmit={handleSubmit}
              profileForm={profileForm}
              setProfileForm={setProfileForm}
              profileInfo={profileInfo}
              setProfileInfo={setProfileInfo}
            />
          </Modal>
        );
      case 'professional_experience':
        const experienceParams = buildProfessionalExperienceParams();
        return (
          <ProfessionalExperienceModal
            professionalExperience={new ProfessionalExperienceForm(experienceParams)}
            ref={modalRef}
            categoriesList={jobCategories(profileAttributes.other_categories)}
            handleAddExperience={handleAddExperience}
            defaultOpen={false}
            handleEditExperience={handleEditExperience}
            handleDeleteExperience={modalActionId && handleDeleteExperience}
            action={modalActionId}
          />
        );
      case 'education':
        const educationParams = buildEducationParams();
        return (
          <EducationModal
            educationForm={new EducationForm(educationParams)}
            handleAddClick={handleAddEducationClick}
            ref={modalRef}
            defaultOpen={false}
            handleEditClick={handleEditEducationClick}
            handleDeleteClick={modalActionId && handleDeleteEducationClick}
            action={modalActionId}
          />
        );
      case 'certifications':
        const certificationParams = buildCertificationsParams();
        return (
          <CertificationsModal
            certificationsForm={new CertificationsForm(certificationParams)}
            fileName={certificationParams.fileName}
            handleAddClick={handleAddCertificateClick}
            ref={modalRef}
            defaultOpen={false}
            handleEditClick={handleEditCertificateClick}
            handleDeleteClick={modalActionId && handleDeleteCertificateClick}
            action={modalActionId}
          />
        );
      case 'job_interests':
        return (
          <Modal
            title='Job Interests'
            defaultOpen={false}
            ref={modalRef}
            disableOutsideClose={true}
          >
            <JobInterestsModalContainer
              handleFormChange={handleFormChange}
              handleSubmit={handleSubmit}
              profileForm={profileForm}
              categoriesList={profileAttributes.categories}
              countriesList={profileAttributes.relocation_countries}
              jobTypesList={profileAttributes.job_types}
            />
          </Modal>
        );
    }
  }

  function buildEducationParams() {
    let params;

    if (modalActionId) {
      const educationItem = education.find(edu => edu.attributes.id === modalActionId);

      if (educationItem) {
        params = {
          id: educationItem.attributes.id,
          degree: educationItem.attributes.degree,
          institution: educationItem.attributes.institution,
          info: educationItem.attributes.info,
          graduation: educationItem.attributes.graduation,
          saveUrl: window.Routes.person_institutions(),
          updateUrl: window.Routes.person_institution(educationItem.attributes.id),
          saved: true,
        };
      } else {
        params = {};
      }
    } else {
      params = {
        id: null,
        degree: '',
        institution: null,
        info: '',
        graduation: null,
        saveUrl: window.Routes.person_institutions(),
        saved: false,
      };
    }

    return params;
  }

  function buildCertificationsParams() {
    let params;

    if (modalActionId) {
      const certificationsItem = certifications.find(cert => cert.id === modalActionId);

      if (certificationsItem) {
        params = {
          id: certificationsItem.id,
          title: certificationsItem.title,
          authority: certificationsItem.authority,
          year: certificationsItem.year,
          file: certificationsItem.file,
          fileName: certificationsItem.fileName,
          url: certificationsItem.url,
          saveUrl: window.Routes.users_certifications(),
          updateUrl: window.Routes.users_certification(certificationsItem.id),
          saved: true,
        };
      } else {
        params = {};
      }
    } else {
      params = {
        id: null,
        title: '',
        authority: '',
        year: null,
        file: null,
        fileName: '',
        url: '',
        saveUrl: window.Routes.users_certifications(),
        saved: false,
      };
    }

    return params;
  }

  function buildProfessionalExperienceParams() {
    let params;
    if (modalActionId) {
      const experience = professionalExperiences.find(pe => pe.id === modalActionId);

      if (experience) {
        params = {
          categories: experience.categories,
          company: experience.company,
          contractType: experience.contractType || experience.contract_type,
          currentPosition: experience.currentPosition || experience.current_position,
          description: '',
          endDateMonth: experience.endMonth || experience.end_month,
          endDateYear: experience.endYear || experience.end_year,
          id: experience.id,
          position: experience.position,
          startDateMonth: experience.startMonth || experience.start_month,
          startDateYear: experience.startYear || experience.start_year,
          saved: true,
          saveUrl: profileAttributes.professionalExperienceUrls.save,
          updateUrl: profileAttributes.professionalExperienceUrls.update.replace(
            '~id',
            String(experience.id)
          ),
        };
      } else {
        params = {};
      }
    } else {
      params = {
        company: '',
        contractType: '',
        currentPosition: false,
        description: '',
        endDateMonth: '',
        endDateYear: '',
        position: '',
        saved: false,
        saveUrl: profileAttributes.professionalExperienceUrls.save,
        startDateMonth: '',
        startDateYear: '',
      };
    }
    return params;
  }

  const handleChangeCV = (object, action) => {
    const form = cloneDeep(profileForm);
    const profileInfoFormClone = cloneDeep(profileInfo);
    const cv = camelizeObj(object);
    let cvs: Cv[];
    const orderedCvs: Cv[] = [];

    switch (action) {
      case 'post':
        cvs = [...form.cvs, cv];
        break;
      case 'delete':
        cvs = form.cvs.filter(formCv => +formCv.id !== +cv.id);
        break;
      case 'other':
        cvs = form.cvs;
        break;
      case 'standard':
        object.forEach((id, index) => {
          const selectedCv: Cv = form.cvs.find(c => c.id.toString() === id.toString());
          if (selectedCv) {
            if (index === 0) {
              const cvId = selectedCv.standard ? 0 : selectedCv.id;
              patchJSON(window.Routes.resume(cvId)).catch(e => handleRequestError(e));
              selectedCv.standard = true;
            } else if (index === 1) {
              selectedCv.standard = false;
            }
            orderedCvs.push(selectedCv);
          }
        });
        cvs = form.cvs;
        break;
    }

    form.update({ cvs });

    setProfileInfo({ ...profileInfoFormClone, cvs });
    setProfileForm(form);
  };

  const updateSkills = skillList => {
    const form = cloneDeep(profileForm);
    const profileInfoFormClone = cloneDeep(profileInfo);
    form.update({ skills: skillList });

    setProfileInfo({ ...profileInfoFormClone, skills: skillList });
    setProfileForm(form);
  };

  const handleFormChange = (event, input = '') => {
    const update = {};
    const profileInfoFormClone = cloneDeep(profileInfo);

    if (input === 'location') {
      const addressArray = event.formatted_address.split(', ');
      const city = addressArray[0];
      const countryShort = event.address_components.find(
        addressComponent => addressComponent.types.indexOf('country') !== -1
      ).short_name;
      const countryLong = event.address_components.find(
        addressComponent => addressComponent.types.indexOf('country') !== -1
      ).long_name;
      update['city'] = city;
      update['country'] = countryShort;
      update['countryName'] = countryLong;
      update['location'] = event.formatted_address;
      update['googlePlaceId'] = event.id;
      update['latitude'] = event.geometry.location.lat();
      update['longitude'] = event.geometry.location.lng();
    } else if (input === 'rateRange') {
      update['maximumRate'] = event.maximum || null;
      update['minimumRate'] = event.minimum;
    } else if (input === 'gasRange') {
      update['maximumGAS'] = event.maximum || null;
      update['minimumGAS'] = event.minimum;
    } else if (input === 'relocation' && !event && profileForm.relocationCountries.length > 0) {
      update['relocationCountries'] = null;
      update['relocation'] = event;
    } else if (!input) {
      update[event.target.name] = event.target.value;
    } else if (['firstName', 'lastName', 'description', 'skypeId'].includes(input)) {
      update[input] = event.target.value;
    } else if (input === 'contactPreference') {
      update['contactPreference'] = event.id;
      update['contactPreferenceOther'] = event.other;
    } else if (input === 'avatar') {
      setProfileInfo({ ...profileInfoFormClone, avatar: event });
      update[input] = event;
    } else {
      update[input] = event;
    }

    const form = cloneDeep(profileForm);

    form.update(update);
    setProfileForm(form);
  };

  const handleAddWebsiteClick = website => {
    postJSON(window.Routes.users_websites(), {
      category: website.category,
      url: website.url,
      replace: false,
    })
      .then(response => {
        if (response.notice) {
          window.Alerts && window.Alerts.notice(response.notice);
          const links = [
            {
              url: response.website.url,
              title: response.website.category,
              category: response.website.category_key,
              id: response.website.id,
            },
            ...profileForm.listOfLinks,
          ];

          const form = cloneDeep(profileForm);
          const profileInfoFormClone = cloneDeep(profileInfo);
          form.update({ listOfLinks: links });

          setProfileInfo({ ...profileInfoFormClone, listOfLinks: links });
          setProfileForm(form);
        } else if (response.alert) {
          window.Alerts && window.Alerts.alert(response.alert);
        }
      })
      .catch(handleRequestError);
  };

  const handleDeleteWebsiteClick = id => {
    deleteJSON(window.Routes.users_website(id))
      .then(response => {
        if (response.notice) {
          window.Alerts && window.Alerts.notice(response.notice);

          const links = profileForm.listOfLinks.filter(link => +link.id !== +id);

          const form = cloneDeep(profileForm);
          const profileInfoFormClone = cloneDeep(profileInfo);
          form.update({ listOfLinks: links });

          setProfileInfo({ ...profileInfoFormClone, listOfLinks: links });
          setProfileForm(form);
        }
      })
      .catch(handleRequestError);
  };

  const handleAddExperience = experience => {
    const newExperience = camelizeObj(experience);
    setProfileCompleteness(newExperience.profileCompleteness);
    const updatedExperience = [newExperience, ...professionalExperiences];
    setProfessionalExperiences(updatedExperience);
    setProfessionalExperienceCard(
      new ProfessionalExperienceService({
        experiences: updatedExperience,
        handshakeProfile,
        unlocked,
      }).props()
    );
  };

  const handleEditExperience = experience => {
    const newExperience = camelizeObj(experience);
    const experienceIndex = professionalExperiences.findIndex(
      professionalExperience =>
        parseInt(professionalExperience.id, 10) === parseInt(newExperience.id, 10)
    );
    const currentExperience = professionalExperiences.map((professionalExperience, index) => {
      if (index === experienceIndex) {
        return newExperience;
      }
      return professionalExperience;
    });
    setProfessionalExperiences(currentExperience);
    setProfessionalExperienceCard(
      new ProfessionalExperienceService({
        experiences: currentExperience,
        handshakeProfile,
        unlocked,
      }).props()
    );
  };

  const handleDeleteExperience = id => {
    deleteJSON(profileAttributes.professionalExperienceUrls.update.replace('~id', id))
      .then(response => {
        const professionalExperience = professionalExperiences.filter(pe => +pe.id !== id);
        if (response.notice) {
          setProfessionalExperiences(professionalExperience);
          setProfessionalExperienceCard(
            new ProfessionalExperienceService({
              experiences: professionalExperience,
              handshakeProfile,
              unlocked,
            }).props()
          );
          setProfileCompleteness(response.profile_completeness);
        }
      })
      .catch(handleRequestError);
  };

  const handleAddEducationClick = currentEducation => {
    setProfileCompleteness(currentEducation.attributes.profile_completeness);
    const updatedEducation = [currentEducation, ...education];
    setEducation(updatedEducation);
    setEducationCard(
      new Education({
        institutions: updatedEducation,
        handshakeProfile,
        unlocked,
      }).props()
    );
  };

  const handleEditEducationClick = currentEducationItem => {
    const educationIndex = education.findIndex(
      educationItem => parseInt(educationItem.id, 10) === parseInt(currentEducationItem.id, 10)
    );

    const currentEducation = education.map((educationItem, index) => {
      if (index === educationIndex) {
        return currentEducationItem;
      }
      return educationItem;
    });

    setEducation(currentEducation);
    setEducationCard(
      new Education({
        institutions: currentEducation,
        handshakeProfile,
        unlocked,
      }).props()
    );
  };

  const handleDeleteEducationClick = id => {
    deleteJSON(window.Routes.person_institution(id))
      .then(response => {
        if (response.notice) {
          window.Alerts && window.Alerts.notice(response.notice);
          const currentEducation = education.filter(edu => +edu.id !== id);
          setEducation(currentEducation);
          setEducationCard(
            new Education({
              institutions: currentEducation,
              handshakeProfile,
              unlocked,
            }).props()
          );
          setProfileCompleteness(response.profile_completeness);
        }
      })
      .catch(handleRequestError);
  };

  const handleDeleteCertificateClick = id => {
    deleteJSON(window.Routes.users_certification(id))
      .then(response => {
        if (response.notice) {
          window.Alerts && window.Alerts.notice(response.notice);
          const currentCertifications = certifications.filter(cert => +cert.id !== id);
          setCertifications(currentCertifications);
          setCertificationsCard(
            new Certificate({
              certifications: currentCertifications,
              handshakeProfile,
              unlocked,
            }).props()
          );
        }
      })
      .catch(handleRequestError);
  };

  const handleAddCertificateClick = currentCertificate => {
    const updatedCertificate = [currentCertificate, ...certifications];
    setCertifications(updatedCertificate);
    setCertificationsCard(
      new Certificate({
        certifications: updatedCertificate,
        handshakeProfile,
        unlocked,
      }).props()
    );
  };

  const handleEditCertificateClick = currentCertificateItem => {
    const certificateIndex = certifications.findIndex(
      certificateItem =>
        parseInt(certificateItem.id, 10) === parseInt(currentCertificateItem.id, 10)
    );

    const currentCertificate = certifications.map((certificateItem, index) => {
      if (index === certificateIndex) {
        return currentCertificateItem;
      }
      return certificateItem;
    });

    setCertifications(currentCertificate);
    setCertificationsCard(
      new Certificate({
        certifications: currentCertificate,
        handshakeProfile,
        unlocked,
      }).props()
    );
  };

  const showErrorMessage = () => {
    window.alert('Oops, something wrong happened');
  };

  const handleClickCopyProfileUrl = hasLink => {
    const variables = { value: true };

    apolloClient
      .mutate({ mutation: UPDATE_PUBLIC_PROFILE_GQL, variables })
      .then(({ data }) => {
        const result = data.person.updatePublicProfile;
        if (result.errors.length === 0) {
          if (!hasLink) {
            getProfileUrl();
          }
        } else {
          showErrorMessage();
        }
      })
      .catch(showErrorMessage);
  };

  const getProfileUrl = () => {
    apolloClient
      .query({
        query: GQL_QUERY_PROFILE_URL,
      })
      .then(result => {
        setPublicLink(result.data.profileUrl);
      })
      .catch(showErrorMessage);
  };

  const prepareModal = (currentModalContent, currentModalActionId: null, section = null) => {
    const currentModalTitle = currentModalContent.split('_').map(titleize);
    setModalContent(currentModalContent);
    setModalTitle(currentModalTitle);
    setmodalActionId(currentModalActionId);
    if (currentModalActionId === modalActionId) {
      // if the modal is the same the useeffect will not trigger
      // if the modal is different we have to use useeffect otherwise the state gets messy
      openModal();
    }

    if (section) {
      setTimeout(() => scrollTo(section), 500);
    }
  };

  const openModal = () => {
    if (
      modalContent &&
      ['certifications', 'education', 'professional_experience'].indexOf(modalContent) !== -1
    ) {
      return modalRef.current.modalRef.current.open();
    } else if (modalContent) {
      modalRef.current.open();
    }
  };

  useEffect(() => {
    openModal();
  }, [modalActionId]);

  useEffect(() => {
    openModal();
  }, [modalContent]);

  const incompleteFields = () => {
    const emptyFields = [];
    for (const [key, value] of Object.entries(profileForm)) {
      if (
        key === 'skills' &&
        value?.some(skill => skill?.experienceLevel === -1 || skill?.experienceLevel === null)
      ) {
        emptyFields.push({
          name: 'Skills with years of experience',
          star: true,
        });
      } else if (key === 'phoneNumber' && (value?.length <= 1 || value === 'No info')) {
        emptyFields.push({ name: 'Phone Number', star: true });
      } else if (
        relevantFieldsForCompleteness.includes(key) &&
        (value === '' || value === null || value === undefined || value?.length === 0)
      ) {
        switch (key) {
          case 'cvs':
            emptyFields.push({ name: 'CV', star: true });
            break;
          case 'headline':
            emptyFields.push({ name: 'Title', star: false });
            break;
          case 'listOfLinks':
            emptyFields.push({ name: 'Social Links', star: true });
            break;
          case 'minimumGAS':
            emptyFields.push({ name: 'Gross annual salary', star: false });
            break;
          case 'minimumRate':
            emptyFields.push({ name: 'Daily rate', star: false });
            break;
          case 'personLanguages':
            emptyFields.push({ name: 'Languages', star: true });
            break;
          default:
            emptyFields.push({
              name: titleCamel(key),
              star: key === 'citizenships' ? true : false,
            });
        }
      }
    }
    if (professionalExperiences?.length === 0) {
      emptyFields.push({
        name: 'Professional Experience',
        star: false,
      });
    }
    if (education?.length === 0) {
      emptyFields.push({
        name: 'Education',
        star: false,
      });
    }
    if (!profileAttributes.avatar_present) {
      emptyFields.push({ name: 'Avatar', star: false });
    }

    return emptyFields;
  };

  const handleSubmit = event => {
    event.preventDefault();
    setRevision(revision + 1);

    if (profileForm.validate(modalContent)) {
      profileForm
        .save('lj', updateCompleteness, modalContent)
        .then(() => modalRef.current.close())
        .catch(handleRequestError);
      setProfileInfo(profileForm);
    }
  };

  const updateCompleteness = completeness => {
    setProfileCompleteness(completeness);
  };

  const showEducation = hasEducation() || !publicProfilePage;
  const showCertifications = hasCertifications() || !publicProfilePage;
  const showProfessionalExperience = hasProfessionalExperience() || !publicProfilePage;

  return (
    <div className={!handshakeProfile && !publicProfilePage ? styles.wrapper : {}}>
      {!handshakeProfile && !publicProfilePage && (
        <div className={styles.sidebarContainer}>
          <Sidebar
            userType='TalentUser'
            active='overview'
            userName={profileForm.firstName}
            userImage={profileForm.avatar}
            counter={{
              Handshakes: 0,
            }}
          />
        </div>
      )}
      <div
        className={
          handshakeProfile
            ? styles.handshakeProfileContainer
            : publicProfilePage
            ? styles.publicContainer
            : styles.profileContainer
        }
      >
        <MainCardContainer
          avatarProps={mainCard.avatarProps}
          bioProps={mainCard.bioProps}
          cardHeaderProps={mainCard.cardHeaderProps}
          openModal={prepareModal}
          skillsProps={mainCard.skillsProps}
          handshakeProfile={handshakeProfile}
          publicProfilePage={publicProfilePage}
          handleClick={handleClickCopyProfileUrl}
          incompleteFields={incompleteFields()}
        />
        <div className={styles.content}>
          <AboutYourselfContainer
            openModal={prepareModal}
            aboutYourSelfCard={aboutYourSelfCard}
            handshakeProfile={handshakeProfile}
            publicProfilePage={publicProfilePage}
          />
          {renderJobInterests()}
          {showProfessionalExperience && renderTimeline()}
          {showProfessionalExperience && renderProfessionalExperience()}
          {showEducation && renderEducation()}
          {showCertifications && renderCertifications()}
          {renderModalContent()}
        </div>
      </div>
    </div>
  );
}
