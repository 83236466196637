import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Modal from '../../general/modal/modal';
import classnames from 'classnames';
const styles = require('./technical_test_information.module.scss');

interface Props {
  isModal: boolean;
  className?: string;
}
export default class TechnicalTestInformation extends React.Component<Props> {
  modalRef: any;

  constructor(props) {
    super(props);

    this.renderModalContents = this.renderModalContents.bind(this);
    this.modalRef = React.createRef();
  }

  render() {
    if (this.props.isModal) {
      return (
        <Modal
          ref={this.modalRef}
          defaultOpen={true}
          title='TECHNICAL TEST'
          cancelButton={true}
          cancelButtonTitle='GOT IT!'
          cancelButtonColor='ripePlum'
        >
          {this.renderModalContents()}
        </Modal>
      );
    } else {
      return this.renderModalContents();
    }
  }

  renderModalContents() {
    return (
      <div
        className={classnames(
          styles.content,
          this.props.className ? styles[this.props.className] : null
        )}
      >
        <div className={styles.inner}>
          <div className={styles.header}>How it works</div>
          <table className={styles.table}>
            <tbody>
              <ListItem>
                The technical test will be done by Landing.jobs in a partnership with HackerRank
              </ListItem>
              <ListItem>Choose the category of the test</ListItem>
              <ListItem>We’ll set a generic 60-90min test for that category</ListItem>
              <ListItem>You can also tell us if you have specific requirements</ListItem>
              <ListItem>The test is sent to the candidate automatically</ListItem>
              <ListItem>Get the results in the chat, by email and on the application page</ListItem>
            </tbody>
          </table>
          <div className={styles.header}>Why this is perfect for you</div>
          <div className={styles.subHeader}>If you...</div>
          <table className={styles.table}>
            <tbody>
              <ListItem>
                Spend a lot of time (which you don’t have) testing your candidates
              </ListItem>
              <ListItem>Value a pre-screening tool</ListItem>
              <ListItem>
                Would like to test your candidates without spending too much time on it
              </ListItem>
              <ListItem>
                Want to make sure that the candidate you bring to the process has the right
                knowledge
              </ListItem>
            </tbody>
          </table>
          <div className={styles.subHeader}>This will allow you to…</div>
          <table className={styles.table}>
            <tbody>
              <ListItem>Have the perfect test for your recruitment needs</ListItem>
              <ListItem>Gain insights on your candidates’ technical skills</ListItem>
              <ListItem>
                Filter out the worst performing candidates and start the rest of the recruitment
                process with the best performing ones
              </ListItem>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  static show() {
    const root = $('<div></div>');

    $('body').append(root);

    ReactDOM.render(<TechnicalTestInformation isModal={true} className='modal' />, root[0]);
  }
}

const ListItem = props => {
  return (
    <tr>
      <td className={styles.check}>
        <div className='lj-searchDemo-check'>
          <div className='circle' />
          <div className='stem' />
          <div className='kick' />
        </div>
      </td>
      <td>{props.children}</td>
    </tr>
  );
};
