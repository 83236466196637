import React, { useEffect, useState } from 'react';
import BonusSlider from './bonus_slider';
import RadioButtons from 'components/form/radio_buttons/radio_buttons';
import { LABELS, CHECKBOX_LABELS } from './utils/sidebarUtils';
import Textarea from 'components/form/textarea/textarea';
import Checkbox from 'components/form/checkbox/checkbox';

const styles = require('./bonus_and_concerns.module.scss');

export interface Props {
  handleCheckboxNoteChange: Function;
  handleTextChange?: Function;
  notesRef: any;
  setAllowNext: Function;
  setRadioSelected: Function;
  setSliderValue: Function;
  values: {
    concern: string;
    selected: string;
    slider: number;
  };
}

export default function BonusAndConcerns(props: Props) {
  const [value, setValue] = useState(props.values.slider + 3);
  const [selectedLabel, setSelectedLabel] = useState(props.values.selected);
  const allCheckboxValues = checkboxValuesFlat();
  const { selectedCheckbox, cleanNotes } = checkSelectedCheckbox(
    allCheckboxValues,
    props.values.concern
  );
  const [checkboxNotes, setCheckboxNotes] = useState(selectedCheckbox);

  const updateSlider = e => {
    if ((value <= 3 && e[0] >= 3) || (value >= 3 && e[0] <= 3)) {
      setCheckboxNotes([]);
      props.handleCheckboxNoteChange([]);
      setSelectedLabel(null);
      props.setRadioSelected(null);
      if (e[0] !== 3) {
        props.setAllowNext(false);
      }
    }
    if (e[0] === 3) {
      props.setAllowNext(true);
    }
    setValue(e[0]);
    props.setSliderValue(e);
  };

  const updateLabel = e => {
    setCheckboxNotes([]);
    props.handleCheckboxNoteChange([]);
    setSelectedLabel(e);
    props.setRadioSelected(e);
    props.setAllowNext(true);
  };

  const addCheckbox = target => {
    if (target.checked) {
      setCheckboxNotes([...checkboxNotes, target.name]);
      props.handleCheckboxNoteChange([...checkboxNotes, target.name]);
    } else {
      const tempNotes = checkboxNotes.filter(note => note !== target.name);
      setCheckboxNotes(tempNotes);
      props.handleCheckboxNoteChange(tempNotes);
    }
  };

  useEffect(() => {
    props.setSliderValue([value]);
    props.setRadioSelected(selectedLabel);
  }, [value, selectedLabel]);

  return (
    <>
      <div className={styles.container}>
        <h3 className={styles.h3}>Additional Information:</h3>
      </div>
      <div className={styles.note}>
        Is there any extra positive or negative points regarding this application? If so, drag the
        bar below according to how you feel, select the dimension related to your concern and,
        explain it further in the field below.
      </div>
      <div className={styles.slider}>
        <BonusSlider value={value} onChange={e => updateSlider(e)} />
      </div>
      <div className={styles.radioButtons}>
        {value !== 3 && (
          <RadioButtons
            selectedValue={selectedLabel}
            options={LABELS}
            onChange={e => updateLabel(e)}
          />
        )}
      </div>
      {selectedLabel && (
        <>
          <div className={styles.note}>Please add further notes:</div>
          {value < 3 && (
            <div className={styles.checkbox}>
              {CHECKBOX_LABELS[selectedLabel].negative.map(box => {
                return (
                  <Checkbox
                    name={box}
                    key={box}
                    label={box}
                    controlled={true}
                    checked={checkboxNotes.includes(box)}
                    onChange={e => addCheckbox(e.target)}
                  />
                );
              })}
            </div>
          )}
          {value > 3 && (
            <div className={styles.checkbox}>
              {CHECKBOX_LABELS[selectedLabel].positive.map(box => {
                return (
                  <Checkbox
                    name={box}
                    key={box}
                    label={box}
                    controlled={true}
                    checked={checkboxNotes.includes(box)}
                    onChange={e => addCheckbox(e.target)}
                  />
                );
              })}
            </div>
          )}
          <div className={styles.noteArea}>
            <Textarea
              defaultValue={cleanNotes}
              ref={props.notesRef}
              placeholder='Other notes you consider important'
              handleChange={e => props.handleTextChange(e)}
            />
          </div>
        </>
      )}
    </>
  );
}

function checkboxValuesFlat() {
  const arrays = Object.entries(CHECKBOX_LABELS)
    .map(i => i[1])
    .map(i => i.positive.concat(i.negative));
  // eslint-disable-next-line prefer-spread
  const oneArray = [].concat.apply([], arrays);
  return oneArray;
}

function checkSelectedCheckbox(allCheckboxValues, notes) {
  const notesAsCheckbox = notes
    ? notes.split('. ').map(note => {
        if (note.length > 0) {
          if (note.charAt(note.length - 1) !== '.') {
            return note.concat('.');
          } else {
            return note;
          }
        }
      })
    : [];
  const selectedCheckbox = notesAsCheckbox.filter(note => allCheckboxValues.includes(note));
  const cleanNotes = notesAsCheckbox.filter(note => !allCheckboxValues.includes(note)).join(' ');
  return { selectedCheckbox, cleanNotes };
}
