import React, { useState } from 'react';
import TextInput from 'components/form/text/text';
import LocationInput from 'components/form/location_input/location_input';
import CandidateSkillsSection from 'components/general/skills_input/candidate_skills_section';
import AddWebsite from 'components/registration/add_website';
import ExperienceCard from 'components/registration/experience_card';
import ProfileForm from 'components/registration/profile_form';
import EmptyState from 'components/general/empty_state/empty_state';
import CVUploader from 'components/general/cv_uploader/cv_uploader';
import FormInput from '../../general/form_input/form_input';
import ImageInput from '../../form/imageInput/ImageInput';
import {
  availabilityOptions,
  startYears,
} from 'components/registration/registration_utils/registration_utils';
import ShareInput from 'components/registrationPage/ShareInput';
import LabelWithStarIcon from 'components/talentUser/LabelWithStarIcon';
import Select from 'components/form/select/select';
import Button from 'lj_shared/button/button';

const Settings = require('settings.json');
const styles = require('./intro_modal.module.scss');

interface Props {
  cvUrl: string;
  experienceYearsList?: any;
  handleAddWebsiteClick: any;
  handleChangeCV: any;
  handleDeleteWebsiteClick: any;
  handleFormChange: any;
  handleSubmit: any;
  maximumNumberOfSkills: any;
  networks: any;
  profileForm: ProfileForm;
  proposedTags: any;
  share_preferences?: any;
  showAvailabityOptions?: boolean;
  showAvatar: boolean;
  startYearsList?: any;
  updateSkills: Function;
}

export default function IntroModal(props: Props) {
  function renderImageUploader() {
    return (
      <div className={styles.imageUploadContainer}>
        <FormInput name='userAvatar' label='' error={props.profileForm.errors.image}>
          <ImageInput
            alt='avatar'
            defaultValue={props.profileForm.avatar}
            onUpload={url => props.handleFormChange(url, 'avatar')}
            recommendedWidth={150}
            recommendedHeight={150}
            kind='USER_AVATAR'
            maxSize={5242880}
            buttonColor='tuftsBlue'
            removeImage={!props.profileForm.avatar.includes('default')}
          />
        </FormInput>
      </div>
    );
  }

  function renderAvailability() {
    return (
      <FormInput name='availability' label='What is your current employment status?' required>
        <Select
          name='availability'
          options={availabilityOptions}
          color='silverSand'
          value={props.profileForm.availability}
          onChange={event => props.handleFormChange(event, 'availability')}
        />
      </FormInput>
    );
  }

  function renderFirstLastName() {
    return (
      <div>
        <div className={styles.firstName}>
          <FormInput
            name='firstName'
            label='First Name'
            error={props.profileForm.errors.firstName}
            required
          >
            <TextInput
              name='first_name'
              class={styles.textInput}
              color='silverSand'
              textarea={false}
              required={true}
              value={props.profileForm.firstName}
              onChange={event => props.handleFormChange(event, 'firstName')}
            />
          </FormInput>
        </div>

        <div className={styles.lastName}>
          <FormInput
            name='lastName'
            label='Last Name'
            error={props.profileForm.errors.lastName}
            required
          >
            <TextInput
              name='last_name'
              class={styles.textInput}
              color='silverSand'
              textarea={false}
              required={true}
              value={props.profileForm.lastName}
              onChange={event => props.handleFormChange(event, 'lastName')}
            />
          </FormInput>
        </div>
      </div>
    );
  }

  function renderExperience() {
    return (
      <div>
        <FormInput
          name='startYear'
          label='When did you start working in the tech area?'
          error={props.profileForm.errors.startYear}
          required
        >
          <Select
            name='start_year'
            placeholder='e.g. 2015'
            options={startYears(props.startYearsList)}
            color='silverSand'
            value={props.profileForm.startYear}
            onChange={event => props.handleFormChange(event, 'startYear')}
          />
        </FormInput>
      </div>
    );
  }

  function renderLocation() {
    return (
      <div className={styles.locationContainer}>
        <FormInput
          name='location'
          label="City you're currently based in"
          error={props.profileForm.errors.location}
          required
        >
          <LocationInput
            handleLocationChange={event => props.handleFormChange(event, 'location')}
            defaultValue={{
              city: props.profileForm.city,
              country: props.profileForm.countryName,
              countryCode: props.profileForm.country,
            }}
            color={'silverSand'}
            class={styles.location}
            fixedClass='dashboardFixedLocation'
            required={true}
          />
        </FormInput>
      </div>
    );
  }

  function renderBio() {
    return (
      <div className={styles.bio}>
        <FormInput
          name='description'
          label='Tell us a little more about yourself, your motivations, your interests and what makes you unique.'
          error={props.profileForm.errors.description}
        >
          <TextInput
            textarea
            name='description'
            value={props.profileForm.description}
            required={true}
            onChange={event => props.handleFormChange(event, 'description')}
            maxLength={Settings.textarea.talentProfileDescription.length}
            maxLengthShow
          />
        </FormInput>
      </div>
    );
  }

  function renderSkills() {
    return (
      <div>
        <FormInput
          name='skills'
          label={<LabelWithStarIcon label='Skills' value={props.profileForm.skills?.length > 0} />}
          sublabel='Please add at least 2 skills.'
          error={props.profileForm.errors.skills}
          required
        >
          <CandidateSkillsSection
            skills={props.profileForm.skills}
            proposedTags={props.proposedTags}
            errors={[]}
            updateSkills={props.updateSkills}
            maximumNumberOfSkills={props.maximumNumberOfSkills}
            readOnly={false}
            renderSignupVersion={false}
            hideText={true}
            color='silverSand'
          />
        </FormInput>
      </div>
    );
  }

  function renderSocialLinks() {
    return (
      <div className={styles.linksContainer}>
        <FormInput
          name='listOfLinks'
          label={
            <LabelWithStarIcon
              label='Social links'
              value={props.profileForm.listOfLinks?.some(item => item.category === 'linkedin')}
            />
          }
          error={props.profileForm.errors.listOfLinks}
        >
          <AddWebsite
            networks={props.networks}
            handleAddNetworkClick={props.handleAddWebsiteClick}
          />
          {props.profileForm.listOfLinks.length > 0 ? (
            <div className={styles.links}>
              {props.profileForm.listOfLinks
                .filter(({ type }) => type !== 'cv')
                .map(link => (
                  <ExperienceCard
                    key={link.id}
                    title={link.category}
                    description={link.url}
                    handleDeleteClick={() => props.handleDeleteWebsiteClick(link.id)}
                  />
                ))}
            </div>
          ) : (
            <EmptyState>Please add at least one social link</EmptyState>
          )}
        </FormInput>
      </div>
    );
  }

  function renderUserPreferences() {
    return (
      <ShareInput
        error={props.profileForm.errors.shareProfile}
        shareProfile={props.profileForm.shareProfile}
        handleFormChange={props.handleFormChange}
        options={props.share_preferences}
      />
    );
  }

  function renderHeadline() {
    return (
      <div className={styles.headline}>
        <FormInput name='headline' label='Title' error={props.profileForm.errors.headline}>
          <TextInput
            name='headline'
            placeholder='e.g. Ninja Full-stack Developer'
            color='tuftsBlue'
            value={props.profileForm.headline}
            onChange={props.handleFormChange}
            class={styles.headline}
            maxLength={Settings.textarea.talentProfileHeadline.length}
            maxLengthShow
          />
        </FormInput>
      </div>
    );
  }

  function renderCV() {
    return (
      <div>
        <FormInput
          name='cvFileName'
          label={
            <div className={styles.cvLabel}>
              <LabelWithStarIcon
                label='Curriculum Vitae (CV)'
                value={props.profileForm.cvFileName !== ''}
              />
              <div className={styles.subLabel}>- Max 5 files</div>
            </div>
          }
          error={props.profileForm.errors.cvFileName}
        >
          <CVUploader cvs={props.profileForm.cvs} handleChangeCV={props.handleChangeCV} />
        </FormInput>
      </div>
    );
  }

  return (
    <div className={styles.modalContainer}>
      <form className={styles.formPadding}>
        {props.showAvatar && renderImageUploader()}
        {renderFirstLastName()}
        {renderLocation()}
        {props.showAvailabityOptions && renderAvailability()}
        {props.experienceYearsList && renderExperience()}
        {renderHeadline()}
        {renderBio()}
        {renderSkills()}
        {renderCV()}
        {renderSocialLinks()}
        {renderUserPreferences()}
        <div className={styles.submitButton}>
          <Button buttonColor='tuftsBlue' onClick={event => props.handleSubmit(event)}>
            UPDATE
          </Button>
        </div>
      </form>
    </div>
  );
}
