import * as React from 'react';
import JobOpeningFilter from './job_opening_filter';
import { shallow, ShallowWrapper } from 'enzyme';

const createTestProps = () => {
  return {
    items: [{ id: 1, title: 'Job Opening 1', value: 1, group: 'ongoing' }],
    itemRender: jest.fn(),
    optGroups: [{ groupValue: 1, groupLabel: 'ongoing' }],
    onChange: jest.fn(),
    selected: [1],
  };
};

let wrapped: ShallowWrapper;

describe('Job Opening Filter component', () => {
  beforeEach(() => {
    const props = createTestProps();
    wrapped = shallow(<JobOpeningFilter {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
