import React from 'react';
import classNames from 'classnames';

import Icon from 'components/general/icon/icon';
import { titleCase } from 'lib/string';
import Tooltip from 'components/general/tooltip/tooltip';

const styles = require('./RatingConcernItem.module.scss');

interface Props {
  concern?: string;
  concernNote?: string;
  concernLevel?: number;
  hideTooltip?: boolean;
  noStretch?: boolean;
}

const concernsTooltip = concern =>
  concern === 'lack_of_information'
    ? "The curator considered that some information was lacking on the candidate's profile or CV."
    : 'The curator raised this concern related to the notes you left when posting the job.';

export default function RatingConcernItem({
  concern,
  concernNote,
  concernLevel,
  hideTooltip,
  noStretch,
}: Props) {
  return (
    <>
      {concern &&
        (hideTooltip ? (
          <InsideSection concern={concern} concernLevel={concernLevel} noStretch={noStretch} />
        ) : (
          <Tooltip textBelow text={concernNote} style={{ textAlign: 'start' }}>
            <InsideSection concern={concern} concernLevel={concernLevel} noStretch={noStretch} />
          </Tooltip>
        ))}
    </>
  );
}

function InsideSection({ concernLevel, concern, noStretch }) {
  return (
    <div
      className={classNames(
        styles.concernContainer,
        concernLevel > 0 ? styles.bonus : styles.concern,
        noStretch && styles.noStretch
      )}
    >
      {concernLevel > 0 ? (
        <Icon name='star' color='puertoRico' />
      ) : (
        <Icon name='flag' color='flamingoMidDark' />
      )}
      <span className={styles.concernText}>{titleCase(concern)}</span>
    </div>
  );
}
