import React, { useRef, useState } from 'react';
import { handleRequestError, handleSuccessAlert, postFormData } from 'lib/request_deprecated';
import Button from 'lj_shared/button/button';
import CsvUploader from 'components/general/csvUploader/CsvUploader';
import DotLoader from 'lj_shared/dot_loader/dot_loader';
import Modal, { ButtonsLayout } from 'components/general/modal/modal';
const styles = require('./SendTestInvitesModal.module.scss');

interface Props {
  provider: string;
  testId: number;
}

export default function SendTestInvitesModal(props: Props) {
  const modalRef = useRef(null);
  const [formData, setFormData] = useState(new FormData());
  const [csvError, setCsvError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClick = event => {
    event.preventDefault();
    modalRef.current.open();
    setLoading(false);
  };

  const handleSubmit = () => {
    if (formData.has('file')) {
      setLoading(true);
      postFormData(window.Routes.backoffice_assessment_send_invites(props.testId), formData)
        .then(response => {
          handleSuccessAlert(response);
          modalRef.current.close();
          if (response.notice) {
            window.location.reload();
          }
        })
        .catch(handleRequestError);
    } else {
      setCsvError('Please upload a CSV file');
    }
  };

  return (
    <div>
      <Button
        onClick={handleClick}
        buttonType='border'
        buttonColor='tuftsBlue'
        otherClasses={styles.backofficeButton}
      >
        Send Test Invites
      </Button>
      <Modal
        buttonColor='puertoRico'
        buttonsLayout={ButtonsLayout.OkCancel}
        buttonName='Send Test Invites'
        buttonOnClick={handleSubmit}
        cancelButton
        disableButton={loading}
        disableOutsideClose
        onClose={() => setCsvError('')}
        ref={modalRef}
        title='Send Test Invites'
      >
        <p>
          Upload your csv file, the list of emails will be sent to {props.provider} to trigger their
          invites.
        </p>
        <p>
          <strong>Note:</strong> Make sure your csv doesn't have headers and that all rows have the
          following content: <strong>email, first name, last name</strong>
        </p>
        <CsvUploader formData={formData} setFormData={setFormData} />
        {loading && <DotLoader style={{ position: 'absolute', bottom: '0.5rem', left: '50%' }} />}
        <div>{csvError && <div className={styles.mandatory}>{csvError}</div>}</div>
      </Modal>
    </div>
  );
}
