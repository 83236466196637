import { string } from 'prop-types';

export interface Step {
  kind: string;
  description?: string;
  hasApplications?: boolean;
  id?: number;
  jobId?: number;
  message?: Message;
  name?: string;
  order?: number;
  slug?: string;
  uid?: string;
  test?: TechnicalTest;
  late_stage?: boolean;
  manual_late_stage?: boolean;
  created_at?: Date;
  updated_at?: Date;
}

export interface Message {
  id?: number;
  text: string;
}

export interface TechnicalTest {
  id: string;
  description: string;
  test_id?: number;
}

export interface Assessment {
  attributes: {
    duration: number;
    experience_levels: string[];
    id: number;
    languages?: string[];
    name: string;
    public_url?: string;
    sample_url?: string;
    tags?: string[];
  };
}
export interface AssessmentResult {
  attributes: {
    assessment_id: number;
    end_time: string;
    external_id: string;
    score: number;
    completed: boolean;
    created_at: string;
  };
}

export interface AssessmentsList {
  data: Assessment[];
}
export interface AssessmentResultsList {
  data: AssessmentResult[];
}
