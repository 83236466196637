import * as React from 'react';
import { isNil } from 'lodash';

const styles = require('./top_filters.module.scss');

interface Props {
  setTopFilter: Function;
  ongoingCounter: number;
  draftsCounter: number;
  activeFilter: string;
}

export default class TopFilters extends React.Component<Props> {
  handleFilterSelect = filter => {
    this.props.setTopFilter(filter);
    this.setState({ activeFilter: filter });
  };

  render() {
    const { draftsCounter, ongoingCounter } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.labels}>
          {this.renderLabel('ongoing', 'Ongoing', ongoingCounter)}
          {this.renderLabel('drafts', 'Drafts', draftsCounter)}
          {this.renderLabel('archived', 'Archived')}
        </div>
      </div>
    );
  }

  renderLabel(name, label, count = null) {
    const klass = this.props.activeFilter === name ? 'labelActive' : 'label';

    return (
      <div className={styles[klass]} data-testid={klass}>
        <div onClick={this.handleFilterSelect.bind(null, name)} role='button' data-testid={name}>
          {label}
          {!isNil(count) && (
            <div className={styles.badge} data-testid='badge'>
              {count}
            </div>
          )}
        </div>
      </div>
    );
  }
}
