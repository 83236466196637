import React, { useRef } from 'react';
import AdminCard from 'components/general/admin_card/admin_card';
import Modal from 'components/general/modal/modal';
import Button from '../button';

interface Props {
  companyId: number;
}

export default function AtsDisabledButton(props: Props) {
  const modalRef = useRef(null);

  const openModal = e => {
    e.preventDefault();
    modalRef.current.open();
  };

  return (
    <>
      <Button onClick={openModal} />
      <Modal title='Connect to ATS' ref={modalRef} disableOutsideClose={true}>
        <AdminCard companyId={props.companyId} pricingDisabledFeature={'ATS Integration'} />
      </Modal>
    </>
  );
}
