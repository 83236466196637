const talentProfileLocked = {
  data: {
    id: '194',
    type: 'profile',
    attributes: {
      contractor: null,
      avatar: 'https://assets.landing.jobs/default_avatars/avatar2.svg',
      name: 'The Diaries from Across the Ocean',
      completeness: 94,
      person: {
        data: {
          id: '194',
          type: 'person',
          attributes: {
            first_name: 'The Diaries from Across the',
            last_name: 'Ocean',
            bio: 'Born and raised in Lisbon, I\'m a UX/UI designer, passionate for mobile interface design.\r\n\r\n"If we want users to like our software we should design it to behave like a likeable person: respectful, generous and helpful."\r\n– Alan Cooper',
            user_id: 194,
            birth_year: 1991,
            city: 'Lisbon Area, Portugal',
            country_code: 'PT',
            country_name: 'Portugal',
            minimum_rate: null,
            maximum_rate: null,
            minimum_gas: null,
            maximum_gas: null,
            headline: '',
            cv_file_name: 'CV_MarianaFernandes-EN.pdf',
            remote: 1,
            id: 194,
            relocation: false,
            relocation_countries: [],
            freelance: false,
            share_profile: 1,
            recently_updated: false,
            recipient_avatar:
              '<div class="lj-userAvatar--small lj-userAvatar"><img src="https://s3-eu-west-1.amazonaws.com/assets.landing.jobs/attachments/talent_users/avatars/6816c890add317ab18c1c9e6f808dcf33ade788c/original.jpg?1450220271" class="lj-userAvatar-img" /></div>',
            has_cv: true,
            cv_url:
              'https://s3-eu-west-1.amazonaws.com/assets.landing.jobs/attachments/people/cvs/7fd693e61c7a914b134008e723b6a3533c497b29/original.pdf?response-content-disposition=attachment%3B%20filename%3D%22CV_MarianaFernandes-EN.pdf%22&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAUNDMBB2RUPQ3TPBS%2F20200508%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200508T103433Z&X-Amz-Expires=10&X-Amz-SignedHeaders=host&X-Amz-Signature=43a69d7f19d41aecdd4bcc8fe64b96669364c1737d15d58dc008888be1ad1820',
            name: 'The Diaries from Across the Ocean',
            experience_years: {
              value: 5,
              label: '5 years of experience',
            },
            start_year: {
              value: '',
              label: '',
            },
            location: 'Lisbon Area, Portugal',
            citizenships: 'Portuguese',
            phone_number: '+49 937 5648892',
            phone: '',
            email: 'michale@jakubowski.us',
            websites: [
              {
                id: 358,
                full_url: 'https://linkedin.com/in/marianaffernandes',
                url: 'https://linkedin.com/in/marianaffernandes',
                title: 'Linkedin',
                category_key: 'linkedin',
              },
              {
                id: 359,
                full_url: 'https://github.com/what91',
                url: 'https://github.com/what91',
                title: 'Github',
                category_key: 'github',
              },
              {
                id: 360,
                full_url: 'http://www.behance.net/mariana_fernandes',
                url: 'http://www.behance.net/mariana_fernandes',
                title: 'Behance',
                category_key: 'behance',
              },
              {
                id: 361,
                full_url: 'https://facebook.com/mariana.f.fernandes91',
                url: 'https://facebook.com/mariana.f.fernandes91',
                title: 'Facebook',
                category_key: 'facebook',
              },
              {
                id: 362,
                full_url: 'https://twitter.com/Mariana_herself',
                url: 'https://twitter.com/Mariana_herself',
                title: 'Twitter',
                category_key: 'twitter',
              },
            ],
            languages: [
              {
                id: 170,
                name: 'French',
                experience_label: '1. Elementary',
              },
              {
                id: 169,
                name: 'English',
                experience_label: '4. Full professional proficiency',
              },
              {
                id: 168,
                name: 'Portuguese',
                experience_label: '5. Native or Bilingual proficiency',
              },
            ],
            skills: [
              {
                id: 297555,
                name: 'User Interface Design',
                experience_label: '4 Years',
                experience_level: 4,
              },
              {
                id: 1017694,
                name: 'UX Design',
                experience_label: '3 Years',
                experience_level: 3,
              },
              {
                id: 2865,
                name: 'HTML',
                experience_label: '4 Years',
                experience_level: 4,
              },
              {
                id: 2858,
                name: 'CSS',
                experience_label: '4 Years',
                experience_level: 4,
              },
              {
                id: 2864,
                name: 'Web Design',
                experience_label: '5 Years',
                experience_level: 5,
              },
              {
                id: 2867,
                name: 'Graphic Design',
                experience_label: '7 Years',
                experience_level: 7,
              },
              {
                id: 297556,
                name: 'Bohemian Coding Sketch',
                experience_label: '3 Years',
                experience_level: 3,
              },
              {
                id: 2859,
                name: 'Illustrator',
                experience_label: '10+ Years',
                experience_level: 10,
              },
              {
                id: 2861,
                name: 'Photoshop',
                experience_label: '10+ Years',
                experience_level: 10,
              },
              {
                id: 2866,
                name: 'InDesign',
                experience_label: '10+ Years',
                experience_level: 10,
              },
            ],
            certificates: [],
            person_tags: {
              multiple_processes: null,
            },
            work_availability: {
              id: '',
              value: '',
              label: '',
            },
            availability: {
              id: 2,
              value: 2,
              label: "I'm currently employed, but open to hear about new opportunities",
            },
            contact_preference: {
              id: 0,
              value: 0,
              label: 'Phone',
            },
            categories: [
              {
                id: 7,
                value: 7,
                label: 'UX / UI Designer',
              },
            ],
            skype_id: 'No info',
            professional_experiences: [],
            description:
              'Born and raised in Lisbon, I\'m a UX/UI designer, passionate for mobile interface design.\r\n\r\n"If we want users to like our software we should design it to behave like a likeable person: respectful, generous and helpful."\r\n– Alan Cooper',
            contractor_share_profile: {
              share_profile: null,
              profile_url: null,
            },
            job_types_interest: [],
          },
        },
      },
      institutions: {
        data: [],
      },
      currentUser: null,
      logoutUrl: '/logout',
      skills: {
        data: [
          {
            id: '297555',
            type: 'skills',
            attributes: {
              name: 'User Interface Design',
              experience_level: 4,
              experience_label: '4 Years',
            },
          },
          {
            id: '1017694',
            type: 'skills',
            attributes: {
              name: 'UX Design',
              experience_level: 3,
              experience_label: '3 Years',
            },
          },
          {
            id: '2865',
            type: 'skills',
            attributes: {
              name: 'HTML',
              experience_level: 4,
              experience_label: '4 Years',
            },
          },
          {
            id: '2858',
            type: 'skills',
            attributes: {
              name: 'CSS',
              experience_level: 4,
              experience_label: '4 Years',
            },
          },
          {
            id: '2864',
            type: 'skills',
            attributes: {
              name: 'Web Design',
              experience_level: 5,
              experience_label: '5 Years',
            },
          },
          {
            id: '2867',
            type: 'skills',
            attributes: {
              name: 'Graphic Design',
              experience_level: 7,
              experience_label: '7 Years',
            },
          },
          {
            id: '297556',
            type: 'skills',
            attributes: {
              name: 'Bohemian Coding Sketch',
              experience_level: 3,
              experience_label: '3 Years',
            },
          },
          {
            id: '2859',
            type: 'skills',
            attributes: {
              name: 'Illustrator',
              experience_level: 10,
              experience_label: '10+ Years',
            },
          },
          {
            id: '2861',
            type: 'skills',
            attributes: {
              name: 'Photoshop',
              experience_level: 10,
              experience_label: '10+ Years',
            },
          },
          {
            id: '2866',
            type: 'skills',
            attributes: {
              name: 'InDesign',
              experience_level: 10,
              experience_label: '10+ Years',
            },
          },
        ],
      },
      citizenships: [
        '',
        'Afghan',
        'Albanian',
        'Algerian',
        'American',
        'American Samoan',
        'Andorran',
        'Angolan',
        'Anguillian',
        'Antiguan, Barbudan',
        'Argentinean',
        'Armenian',
        'Aruban',
        'Australian',
        'Austrian',
        'Azerbaijani',
        'Bahamian',
        'Bahraini',
        'Bangladeshi',
        'Barbadian',
        'Belarusian',
        'Belgian',
        'Belizean',
        'Beninese',
        'Bermudian',
        'Bhutanese',
        'Bolivian',
        'Bosnian, Herzegovinian',
        'Brazilian',
        'British',
        'Bruneian',
        'Bulgarian',
        'Burkinabe',
        'Burundian',
        'Cambodian',
        'Cameroonian',
        'Canadian',
        'Cape Verdian',
        'Caymanian',
        'Central African',
        'Chadian',
        'Channel Islander',
        'Chilean',
        'Chinese',
        'Christmas Island',
        'Cocos Islander',
        'Colombian',
        'Comoran',
        'Congolese',
        'Cook Islander',
        'Costa Rican',
        'Croatian',
        'Cuban',
        'Cypriot',
        'Czech',
        'Danish',
        'Djibouti',
        'Dominican',
        'Dutch',
        'East Timorese',
        'Ecuadorean',
        'Egyptian',
        'Emirian',
        'Equatorial Guinean',
        'Eritrean',
        'Estonian',
        'Ethiopian',
        'Falkland Islander',
        'Faroese',
        'Fijian',
        'Filipino',
        'Finnish',
        'French',
        'French Guianan',
        'French Polynesian',
        'Gabonese',
        'Gambian',
        'Georgian',
        'German',
        'Ghanaian',
        'Gibraltar',
        'Greek',
        'Greenlandic',
        'Grenadian',
        'Guadeloupian',
        'Guamanian',
        'Guatemalan',
        'Guinea-Bissauan',
        'Guinean',
        'Guyanese',
        'Haitian',
        'Heard and McDonald Islander',
        'Honduran',
        'Hong Kongese',
        'Hungarian',
        'I-Kiribati',
        'Icelander',
        'Indian',
        'Indonesian',
        'Iranian',
        'Iraqi',
        'Irish',
        'Israeli',
        'Italian',
        'Ivorian',
        'Jamaican',
        'Japanese',
        'Jordanian',
        'Kazakhstani',
        'Kenyan',
        'Kirghiz',
        'Kittian and Nevisian',
        'Kosovan',
        'Kuwaiti',
        'Laotian',
        'Latvian',
        'Lebanese',
        'Liberian',
        'Libyan',
        'Liechtensteiner',
        'Lithuanian',
        'Luxembourger',
        'Macedonian',
        'Malagasy',
        'Malawian',
        'Malaysian',
        'Maldivan',
        'Malian',
        'Maltese',
        'Manx',
        'Marshallese',
        'Mauritanian',
        'Mauritian',
        'Mexican',
        'Micronesian',
        'Moldovan',
        'Monegasque',
        'Mongolian',
        'Montenegrin',
        'Montserratian',
        'Moroccan',
        'Mosotho',
        'Motswana',
        'Mozambican',
        'Myanmarian',
        'Namibian',
        'Nauruan',
        'Nepalese',
        'New Caledonian',
        'New Zealander',
        'Ni-Vanuatu',
        'Nicaraguan',
        'Nigerian',
        'Niuean',
        'Norfolk Islander',
        'North Korean',
        'Norwegian',
        'Omani',
        'Pakistani',
        'Palauan',
        'Palestinian',
        'Panamanian',
        'Papua New Guinean',
        'Paraguayan',
        'Peruvian',
        'Pitcairn Islander',
        'Polish',
        'Portuguese',
        'Puerto Rican',
        'Qatari',
        'Romanian',
        'Russian',
        'Rwandan',
        'Sahrawi',
        'Saint Barthélemy Islander',
        'Saint Helenian',
        'Saint Lucian',
        'Saint Martin Islander',
        'Saint Vincentian',
        'Salvadoran',
        'Sammarinese',
        'Samoan',
        'Sao Tomean',
        'Saudi Arabian',
        'Senegalese',
        'Serbian',
        'Seychellois',
        'Sierra Leonean',
        'Singaporean',
        'Slovak',
        'Slovene',
        'Solomon Islander',
        'Somali',
        'South African',
        'South Georgia and the South Sandwich Islander',
        'South Korean',
        'South Sudanese',
        'Spanish',
        'Sri Lankan',
        'Sudanese',
        'Surinamer',
        'Swazi',
        'Swedish',
        'Swiss',
        'Syrian',
        'Tadzhik',
        'Taiwanese',
        'Tanzanian',
        'Thai',
        'Togolese',
        'Tokelauan',
        'Tongan',
        'Trinidadian',
        'Tunisian',
        'Turkish',
        'Turkmen',
        'Turks and Caicos Islander',
        'Tuvaluan',
        'Ugandan',
        'Ukrainian',
        'Uruguayan',
        'Uzbekistani',
        'Venezuelan',
        'Vietnamese',
        'Virgin Islander',
        'Wallis and Futuna Islander',
        'Yemeni',
        'Zambian',
        'Zimbabwean',
      ],
      relocation_countries: [
        'Afghanistan',
        'Albania',
        'Algeria',
        'American Samoa',
        'Andorra',
        'Angola',
        'Anguilla',
        'Antarctica',
        'Antigua and Barbuda',
        'Argentina',
        'Armenia',
        'Aruba',
        'Australia',
        'Austria',
        'Azerbaijan',
        'Bahamas',
        'Bahrain',
        'Bangladesh',
        'Barbados',
        'Belarus',
        'Belgium',
        'Belize',
        'Benin',
        'Bermuda',
        'Bhutan',
        'Bolivia, Plurinational State of',
        'Bonaire, Sint Eustatius and Saba',
        'Bosnia and Herzegovina',
        'Botswana',
        'Bouvet Island',
        'Brazil',
        'British Indian Ocean Territory',
        'Brunei Darussalam',
        'Bulgaria',
        'Burkina Faso',
        'Burundi',
        'Cambodia',
        'Cameroon',
        'Canada',
        'Cape Verde',
        'Cayman Islands',
        'Central African Republic',
        'Chad',
        'Chile',
        'China',
        'Christmas Island',
        'Cocos (Keeling) Islands',
        'Colombia',
        'Comoros',
        'Congo',
        'Congo, The Democratic Republic of the',
        'Cook Islands',
        'Costa Rica',
        'Croatia',
        'Cuba',
        'Curaçao',
        'Cyprus',
        'Czech Republic',
        "Côte d'Ivoire",
        'Denmark',
        'Djibouti',
        'Dominica',
        'Dominican Republic',
        'Ecuador',
        'Egypt',
        'El Salvador',
        'Equatorial Guinea',
        'Eritrea',
        'Estonia',
        'Ethiopia',
        'Falkland Islands (Malvinas)',
        'Faroe Islands',
        'Fiji',
        'Finland',
        'France',
        'French Guiana',
        'French Polynesia',
        'French Southern Territories',
        'Gabon',
        'Gambia',
        'Georgia',
        'Germany',
        'Ghana',
        'Gibraltar',
        'Greece',
        'Greenland',
        'Grenada',
        'Guadeloupe',
        'Guam',
        'Guatemala',
        'Guernsey',
        'Guinea',
        'Guinea-Bissau',
        'Guyana',
        'Haiti',
        'Heard Island and McDonald Islands',
        'Holy See (Vatican City State)',
        'Honduras',
        'Hong Kong',
        'Hungary',
        'Iceland',
        'India',
        'Indonesia',
        'Iran, Islamic Republic of',
        'Iraq',
        'Ireland',
        'Isle of Man',
        'Israel',
        'Italy',
        'Jamaica',
        'Japan',
        'Jersey',
        'Jordan',
        'Kazakhstan',
        'Kenya',
        'Kiribati',
        "Korea, Democratic People's Republic of",
        'Korea, Republic of',
        'Kuwait',
        'Kyrgyzstan',
        "Lao People's Democratic Republic",
        'Latvia',
        'Lebanon',
        'Lesotho',
        'Liberia',
        'Libya',
        'Liechtenstein',
        'Lithuania',
        'Luxembourg',
        'Macao',
        'Macedonia, Republic of',
        'Madagascar',
        'Malawi',
        'Malaysia',
        'Maldives',
        'Mali',
        'Malta',
        'Marshall Islands',
        'Martinique',
        'Mauritania',
        'Mauritius',
        'Mayotte',
        'Mexico',
        'Micronesia, Federated States of',
        'Moldova, Republic of',
        'Monaco',
        'Mongolia',
        'Montenegro',
        'Montserrat',
        'Morocco',
        'Mozambique',
        'Myanmar',
        'Namibia',
        'Nauru',
        'Nepal',
        'Netherlands',
        'New Caledonia',
        'New Zealand',
        'Nicaragua',
        'Niger',
        'Nigeria',
        'Niue',
        'Norfolk Island',
        'Northern Mariana Islands',
        'Norway',
        'Oman',
        'Pakistan',
        'Palau',
        'Palestine, State of',
        'Panama',
        'Papua New Guinea',
        'Paraguay',
        'Peru',
        'Philippines',
        'Pitcairn',
        'Poland',
        'Portugal',
        'Puerto Rico',
        'Qatar',
        'Republic of Kosovo',
        'Romania',
        'Russian Federation',
        'Rwanda',
        'Réunion',
        'Saint Barthélemy',
        'Saint Helena, Ascension and Tristan da Cunha',
        'Saint Kitts and Nevis',
        'Saint Lucia',
        'Saint Martin (French part)',
        'Saint Pierre and Miquelon',
        'Saint Vincent and the Grenadines',
        'Samoa',
        'San Marino',
        'Sao Tome and Principe',
        'Saudi Arabia',
        'Senegal',
        'Serbia',
        'Seychelles',
        'Sierra Leone',
        'Singapore',
        'Sint Maarten (Dutch part)',
        'Slovakia',
        'Slovenia',
        'Solomon Islands',
        'Somalia',
        'South Africa',
        'South Georgia and the South Sandwich Islands',
        'South Sudan',
        'Spain',
        'Sri Lanka',
        'Sudan',
        'Suriname',
        'Svalbard and Jan Mayen',
        'Swaziland',
        'Sweden',
        'Switzerland',
        'Syrian Arab Republic',
        'Taiwan',
        'Tajikistan',
        'Tanzania, United Republic of',
        'Thailand',
        'Timor-Leste',
        'Togo',
        'Tokelau',
        'Tonga',
        'Trinidad and Tobago',
        'Tunisia',
        'Turkey',
        'Turkmenistan',
        'Turks and Caicos Islands',
        'Tuvalu',
        'Uganda',
        'Ukraine',
        'United Arab Emirates',
        'United Kingdom',
        'United States',
        'United States Minor Outlying Islands',
        'Uruguay',
        'Uzbekistan',
        'Vanuatu',
        'Venezuela, Bolivarian Republic of',
        'Viet Nam',
        'Virgin Islands, British',
        'Virgin Islands, U.S.',
        'Wallis and Futuna',
        'Western Sahara',
        'Yemen',
        'Zambia',
        'Zimbabwe',
        'Åland Islands',
      ],
      languages: [
        {
          text: 'Afrikaans',
          id: 'af',
        },
        {
          text: 'Arabic',
          id: 'ar',
        },
        {
          text: 'Bengali',
          id: 'bn',
        },
        {
          text: 'Tibetan',
          id: 'bo',
        },
        {
          text: 'Bulgarian',
          id: 'bg',
        },
        {
          text: 'Catalan',
          id: 'ca',
        },
        {
          text: 'Czech',
          id: 'cs',
        },
        {
          text: 'Welsh',
          id: 'cy',
        },
        {
          text: 'Danish',
          id: 'da',
        },
        {
          text: 'German',
          id: 'de',
        },
        {
          text: 'Greek',
          id: 'el',
        },
        {
          text: 'English',
          id: 'en',
        },
        {
          text: 'Estonian',
          id: 'et',
        },
        {
          text: 'Basque',
          id: 'eu',
        },
        {
          text: 'Persian',
          id: 'fa',
        },
        {
          text: 'Fijian',
          id: 'fj',
        },
        {
          text: 'Finnish',
          id: 'fi',
        },
        {
          text: 'French',
          id: 'fr',
        },
        {
          text: 'Irish',
          id: 'ga',
        },
        {
          text: 'Gujarati',
          id: 'gu',
        },
        {
          text: 'Hebrew',
          id: 'he',
        },
        {
          text: 'Hindi',
          id: 'hi',
        },
        {
          text: 'Croatian',
          id: 'hr',
        },
        {
          text: 'Hungarian',
          id: 'hu',
        },
        {
          text: 'Armenian',
          id: 'hy',
        },
        {
          text: 'Indonesian',
          id: 'id',
        },
        {
          text: 'Icelandic',
          id: 'is',
        },
        {
          text: 'Italian',
          id: 'it',
        },
        {
          text: 'Japanese',
          id: 'ja',
        },
        {
          text: 'Georgian',
          id: 'ka',
        },
        {
          text: 'Central Khmer',
          id: 'km',
        },
        {
          text: 'Korean',
          id: 'ko',
        },
        {
          text: 'Latin',
          id: 'la',
        },
        {
          text: 'Latvian',
          id: 'lv',
        },
        {
          text: 'Lithuanian',
          id: 'lt',
        },
        {
          text: 'Malayalam',
          id: 'ml',
        },
        {
          text: 'Marathi',
          id: 'mr',
        },
        {
          text: 'Macedonian',
          id: 'mk',
        },
        {
          text: 'Maltese',
          id: 'mt',
        },
        {
          text: 'Mongolian',
          id: 'mn',
        },
        {
          text: 'Maori',
          id: 'mi',
        },
        {
          text: 'Malay',
          id: 'ms',
        },
        {
          text: 'Nepali',
          id: 'ne',
        },
        {
          text: 'Dutch',
          id: 'nl',
        },
        {
          text: 'Norwegian',
          id: 'no',
        },
        {
          text: 'Panjabi/Punjabi',
          id: 'pa',
        },
        {
          text: 'Polish',
          id: 'pl',
        },
        {
          text: 'Portuguese',
          id: 'pt',
        },
        {
          text: 'Quechua',
          id: 'qu',
        },
        {
          text: 'Romanian',
          id: 'ro',
        },
        {
          text: 'Russian',
          id: 'ru',
        },
        {
          text: 'Slovak',
          id: 'sk',
        },
        {
          text: 'Slovenian',
          id: 'sl',
        },
        {
          text: 'Samoan',
          id: 'sm',
        },
        {
          text: 'Spanish',
          id: 'es',
        },
        {
          text: 'Albanian',
          id: 'sq',
        },
        {
          text: 'Serbian',
          id: 'sr',
        },
        {
          text: 'Swahili',
          id: 'sw',
        },
        {
          text: 'Swedish',
          id: 'sv',
        },
        {
          text: 'Tamil',
          id: 'ta',
        },
        {
          text: 'Tatar',
          id: 'tt',
        },
        {
          text: 'Telugu',
          id: 'te',
        },
        {
          text: 'Thai',
          id: 'th',
        },
        {
          text: 'Tongan',
          id: 'to',
        },
        {
          text: 'Turkish',
          id: 'tr',
        },
        {
          text: 'Ukrainian',
          id: 'uk',
        },
        {
          text: 'Urdu',
          id: 'ur',
        },
        {
          text: 'Uzbek',
          id: 'uz',
        },
        {
          text: 'Vietnamese',
          id: 'vi',
        },
        {
          text: 'Xhosa',
          id: 'xh',
        },
        {
          text: 'Mandarin Chinese',
          id: 'zh',
        },
        {
          text: 'Bosnian',
          id: 'bs',
        },
        {
          text: 'Kannada',
          id: 'kn',
        },
        {
          text: 'Kazakh',
          id: 'kk',
        },
        {
          text: 'Cantonese Chinese',
          id: 'yue',
        },
      ],
      proficiency_levels: {
        '1': '1. Elementary',
        '2': '2. Limited working proficiency',
        '3': '3. Professional working proficiency',
        '4': '4. Full professional proficiency',
        '5': '5. Native or Bilingual proficiency',
      },
      availability: {
        Immediate: 0,
        '1 Week': 1,
        '2 Weeks': 2,
        '3 Weeks': 3,
        '4 Weeks': 4,
        '5 Weeks': 5,
        '6 Weeks': 6,
        '7 Weeks': 7,
        '8 Weeks': 8,
        '9 Weeks': 9,
        '10 Weeks': 10,
        '11 Weeks': 11,
        '12 Weeks': 12,
        '13 Weeks': 13,
        '14 Weeks': 14,
        '15 Weeks': 15,
        '16 Weeks': 16,
        '17 Weeks': 17,
        '18 Weeks': 18,
        '19 Weeks': 19,
        '20 Weeks': 20,
        '21 Weeks': 21,
        '22 Weeks': 22,
        '23 Weeks': 23,
        '24 Weeks': 24,
        'Over 24 Weeks': 25,
        "I'm just looking around": 26,
      },
      experience_years: [
        ['< 1 Year', 0],
        ['1 Year', 1],
        ['2 Years', 2],
        ['3 Years', 3],
        ['4 Years', 4],
        ['5 Years', 5],
        ['6 Years', 6],
        ['7 Years', 7],
        ['8 Years', 8],
        ['9 Years', 9],
        ['10+ Years', 10],
      ],
      start_years: [
        2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010, 2009, 2008, 2007, 2006,
        2005, 2004, 2003, 2002, 2001, 2000, 1999, 1998, 1997, 1996, 1995, 1994, 1993, 1992, 1991,
        1990, 1989, 1988, 1987, 1986, 1985, 1984, 1983, 1982, 1981, 1980,
      ],
      contact_preferences: {
        Phone: 0,
        WhatsApp: 1,
        'E-Mail': 2,
      },
      job_types: {
        'Permanent employee': 0,
        Contractor: 1,
        'Fully remote': 2,
        'Partially remote': 3,
        'I’m not certain about this': 4,
      },
      categories: [
        {
          id: 6,
          name: 'Data Scientist',
          created_at: '2014-09-24T19:57:36.099Z',
          updated_at: '2015-11-26T15:34:47.416Z',
          slug: 'data-scientist',
          is_tech: true,
          group: null,
          disabled: false,
          emoji_symbol: null,
        },
        {
          id: 8,
          name: 'Product/Project Management',
          created_at: '2014-09-24T19:57:36.099Z',
          updated_at: '2015-11-26T15:34:47.449Z',
          slug: 'product-project-management',
          is_tech: true,
          group: null,
          disabled: false,
          emoji_symbol: null,
        },
        {
          id: 1,
          name: 'Full-stack Developer',
          created_at: '2014-09-24T19:57:36.099Z',
          updated_at: '2015-11-26T15:34:47.335Z',
          slug: 'full-stack-developer',
          is_tech: true,
          group: 'Development',
          disabled: false,
          emoji_symbol: null,
        },
        {
          id: 2,
          name: 'Front-end Developer',
          created_at: '2014-09-24T19:57:36.099Z',
          updated_at: '2015-11-26T15:34:47.367Z',
          slug: 'front-end-developer',
          is_tech: true,
          group: 'Development',
          disabled: false,
          emoji_symbol: null,
        },
        {
          id: 3,
          name: 'Back-end Developer',
          created_at: '2014-09-24T19:57:36.099Z',
          updated_at: '2015-11-26T15:34:47.383Z',
          slug: 'back-end-developer',
          is_tech: true,
          group: 'Development',
          disabled: false,
          emoji_symbol: null,
        },
        {
          id: 5,
          name: 'Mobile Apps Developer',
          created_at: '2014-09-24T19:57:36.099Z',
          updated_at: '2015-11-26T15:34:47.399Z',
          slug: 'mobile-apps-developer',
          is_tech: true,
          group: 'Development',
          disabled: false,
          emoji_symbol: null,
        },
        {
          id: 13,
          name: 'DevOps / Sysadmin',
          created_at: '2014-09-24T19:57:36.099Z',
          updated_at: '2015-11-26T15:34:47.506Z',
          slug: 'devops-sysadmin',
          is_tech: true,
          group: 'Development',
          disabled: false,
          emoji_symbol: null,
        },
        {
          id: 23,
          name: 'QA / Testing',
          created_at: '2015-09-04T15:44:19.876Z',
          updated_at: '2015-11-26T15:34:47.610Z',
          slug: 'qa-testing',
          is_tech: true,
          group: 'Development',
          disabled: false,
          emoji_symbol: null,
        },
        {
          id: 20,
          name: 'Maintenance & Support',
          created_at: '2014-10-17T09:47:32.987Z',
          updated_at: '2015-11-26T15:34:47.559Z',
          slug: 'maintenance-support',
          is_tech: false,
          group: 'Development',
          disabled: false,
          emoji_symbol: null,
        },
        {
          id: 7,
          name: 'UX / UI Designer',
          created_at: '2014-09-24T19:57:36.099Z',
          updated_at: '2016-11-16T09:49:21.182Z',
          slug: 'ux-ui-designer',
          is_tech: true,
          group: 'Design',
          disabled: false,
          emoji_symbol: null,
        },
        {
          id: 24,
          name: 'Computer & Network Security',
          created_at: '2016-12-15T15:12:37.042Z',
          updated_at: '2016-12-15T15:12:37.042Z',
          slug: 'computer-network-security',
          is_tech: true,
          group: 'Development',
          disabled: false,
          emoji_symbol: null,
        },
      ],
      urls: {
        person_institutions_url: '/person_institutions',
        person_institutions_destroy_url: '/person_institutions/~id',
        edit_profile_url: '/profile/edit',
        websites_url: '/users/websites',
        websites_destroy_url: '/users/websites/~id',
        languages_url: '/users/language_proficiencies',
        languages_destroy_url: '/users/language_proficiencies/~id',
        cv_url: '/profile/resume',
        show_cv_url: '/resume',
      },
      professionalExperienceUrls: {
        save: '/professional_experiences?person_id=194',
        update: '/professional_experiences/~id',
      },
      googleMapsApiKey: 'AIzaSyAvWL6aCwt0FStcFFhh2Ja8PQ8MNpYADtQ',
      maximumNumberOfSkills: 40,
      proposedTags: [],
      networks: [
        {
          id: 1,
          name: 'LinkedIn',
          base_url: 'https://linkedin.com/',
          value: 'linkedin',
        },
        {
          id: 2,
          name: 'GitHub',
          base_url: 'https://github.com/',
          value: 'github',
        },
        {
          id: 3,
          name: 'Bitbucket',
          base_url: 'https://bitbucket.org/',
          value: 'bitbucket',
        },
        {
          id: 4,
          name: 'Stack Overflow',
          base_url: 'https://www.stackoverflow.com/',
          value: 'stackoverflow',
        },
        {
          id: 5,
          name: 'Dribbble',
          base_url: 'https://www.dribbble.com/',
          value: 'dribbble',
        },
        {
          id: 6,
          name: 'Behance',
          base_url: 'http://www.behance.net/',
          value: 'behance',
        },
        {
          id: 7,
          name: 'Facebook',
          base_url: 'https://facebook.com/',
          value: 'facebook',
        },
        {
          id: 8,
          name: 'Twitter',
          base_url: 'https://twitter.com/',
          value: 'twitter',
        },
        {
          id: 12,
          name: 'Instagram',
          base_url: 'https://instagram.com/',
          value: 'instagram',
        },
        {
          id: 9,
          name: 'Blog',
          base_url: 'http://',
          value: 'blog',
        },
        {
          id: 10,
          name: 'Personal website',
          base_url: 'http://',
          value: 'personal',
        },
        {
          id: 11,
          name: 'Other',
          base_url: 'http://',
          value: 'other',
        },
      ],
      public: null,
      privacyPath: '/privacy',
      share_preferences: {
        "I don't allow Landing.Jobs to share my profile with potential employers, I'll do the job search by myself": 0,
        'I allow Landing.Jobs to anonymously share my profile with potential employers': 1,
        'I allow Landing.Jobs to share my profile with any potential employer (my contact info will be hidden)': 4,
      },
    },
  },
};

export default talentProfileLocked;
