import React from 'react';
import Icon, { Style } from 'components/general/icon/icon';

const styles = require('./SectionCard.module.scss');

interface Props {
  handleDelete?: any;
  header?: string;
  id?: number;
  text: string;
  title?: string;
  subtitle?: string;
}

export default function SectionCard(props: Props) {
  const renderCardHeader = () => (
    <div className={styles.cardHeader}>
      <div className={styles.headerContainer}>{props.header}</div>
      <div className={styles.closeContainer}>
        <div onClick={() => props.handleDelete(props.id)}>
          <Icon className={styles.close} name='close' color='ripePlumLight' style={Style.Bold} />
        </div>
      </div>
    </div>
  );
  return (
    <div className={styles.cardContainer}>
      {renderCardHeader()}
      <div className={styles.titleContainer}>{props.title}</div>
      <div className={styles.subtitleContainer}>{props.subtitle}</div>
      {props.text && <div className={styles.textContainer}>{props.text}</div>}
    </div>
  );
}
