import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import MultipleLocationsSection from './multiple_locations_section';

const createProps = () => ({
  currentLocations: [
    {
      city: 'Porto',
      country_code: 'PT',
      google_place_id: 'ChIJwVPhxKtlJA0RvBSxQFbZSKY',
    },
  ],
  isRequired: true,
});

let props;
let wrapped: ShallowWrapper;

describe('Office Locations Component', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<MultipleLocationsSection {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
