import { camelCase } from 'lodash';
import classNames from 'classnames';
import React, { useContext } from 'react';

import { FormContext } from 'components/form/FormProvider';
import FormError from 'components/form/form_error/form_error';
const styles = require('./InputWrapper.module.scss');

interface Props {
  className?: string;
  defaultValue?: any;
  error?: string;
  hint?: string;
  input: any;
  inputProps?: { [key: string]: any };
  label?: string;
  name?: string;
  required?: boolean;
  sublabel?: any;
}

export default function InputWrapper(props: Props) {
  const context = useContext(FormContext);
  const name = getName(context, props.name);
  const value = getValue(context, props.name, props.defaultValue);
  const inputProps = props.inputProps || {};
  const error = getError(context, props.name, props.error);

  return (
    <div className={classNames(styles.wrapper, props.className)}>
      {renderHeader(props, context)}
      <props.input {...inputProps} defaultValue={value} formName={context.formName} name={name} />
      {renderError(error)}
      {renderFooter(props.hint)}
    </div>
  );
}

function renderHeader(props, context) {
  const { label, name, required, sublabel } = props;
  const show = label || sublabel;

  return (
    show && (
      <div className={styles.header}>
        {renderLabel(props.label, props.required)}
        {renderSublabel(props.sublabel)}
      </div>
    )
  );
}

function renderLabel(text, required) {
  return (
    text && (
      <label className={styles.label}>
        {text}
        {required && <span className={styles.required}> *</span>}
      </label>
    )
  );
}

function renderSublabel(text) {
  return text && <small className={styles.sublabel}>{text}</small>;
}

function renderError(error) {
  return error && <FormError text={error} />;
}

function renderFooter(hint) {
  return (
    hint && (
      <div className={styles.footer}>
        <div className={styles.hint}>{hint}</div>
      </div>
    )
  );
}

function getError(context, name, error) {
  return error || context.errors[name]?.join(' ');
}

function getName(context, name) {
  return context.formName ? `${context.formName}[${name}]` : name;
}

function getValue(context, name, value) {
  name = name && camelCase(name);
  return value || (name && context.resource?.[name]);
}
