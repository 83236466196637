import * as React from 'react';
import Checkbox, { Props } from './checkbox';
import { shallow, ShallowWrapper } from 'enzyme';

const createTestProps = moreProps => {
  return {
    id: '2',
    name: '',
    value: '',
    label: '',
    checked: true,
    color: '',
    onChange: jest.fn(),
    className: '',
    controlled: true,
    ...moreProps,
  };
};

let props: Props;
let wrapped: ShallowWrapper;

describe('Checkbox component', () => {
  beforeEach(() => {
    props = createTestProps({});
    wrapped = shallow(<Checkbox {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
