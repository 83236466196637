import { useEffect, useState } from 'react';
import constants from './constants';

interface QueryParam {
  [Identifier: string]: string;
}

const getQueryParams = (query: string = null): QueryParam => {
  const params = query || window.location.search.replace('?', '');
  if (params === '') {
    return {};
  } else {
    return (
      // get array of KeyValue pairs
      params
        .split('&')

        // Decode values
        .map(pair => {
          const [key, val] = pair.split('=');

          return [key, decodeURIComponent(val || '')];
        })

        // array to object
        .reduce((result, [key, val]) => {
          result[key] = val;
          return result;
        }, {})
    );
  }
};

const isMobile = (): boolean => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const isMobileWidth = width <= constants.MOBILE_BREAKPOINT;

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [width]);

  return isMobileWidth;
};

export { getQueryParams, isMobile };
