import React, { useState, useContext, useRef } from 'react';
import { FormContext } from 'components/form/FormProvider';
import Text from 'components/form/text/text';
import Button from 'lj_shared/button/button';
import AboutSectionSmallCard from './AboutSectionSmallCard';
import { handleRequestError, postJSON } from 'lib/request_deprecated';
import FormError from '../form/form_error/form_error';

const styles = require('./AboutSectionNumbers.module.scss');

export default function AboutSectionNumbers() {
  const context = useContext(FormContext);
  const [numbersList, setNumbersList] = useState(context.resource.numbers);
  const [userError, setUserError] = useState(null);
  const numbersText = useRef(null);

  const deleteNumbersItem = numberItem => {
    const shouldRemove = window.confirm('Do you want to remove?');
    if (!shouldRemove) {
      return;
    }
    setUserError(null);
    const payload = {
      numbers: numbersList.filter(item => item !== numberItem),
    };
    postJSON(window.Routes.update_numbers_employer(), payload)
      .then(() => setNumbersList(payload.numbers))
      .catch(handleRequestError);
  };

  const addNumbers = event => {
    event.preventDefault();
    setUserError(null);
    const numbers = numbersText.current.value;
    const [valid, message] = validate(numbers);
    if (!valid) {
      return setUserError(message);
    }
    const payload = {
      numbers: numbersList.concat(numbers),
    };
    postJSON(window.Routes.update_numbers_employer(), payload)
      .then(() => setNumbersList(payload.numbers))
      .then(() => (numbersText.current.value = null))
      .catch(handleRequestError);
  };

  function validate(value) {
    if (value === '') {
      return [false, "Numbers can't be empty"];
    } else if (value.length > 200) {
      return [false, "Numbers can't have more than 200 characters"];
    } else if (numbersList.some(item => value === item)) {
      return [false, "Numbers can't be duplicated"];
    }
    return [true];
  }

  const renderForm = () => (
    <div className={styles.numbers}>
      <Text
        name='numbers'
        inputRef={numbersText}
        placeholder='e.g. Over 100 million users have given feedback through our app.'
        label='Numbers'
        note='Describe with numbers the growth of your company eg. number of offices, investors, years in business, employees, customers, revenue, solutions and etc.'
      />
      <div className={styles.numbersButton}>{renderButtons()}</div>
    </div>
  );

  const renderButtons = () => (
    <Button isButton={true} buttonColor='silverSand' onClick={addNumbers}>
      Add
    </Button>
  );

  const renderInputs = () => (
    <div className={styles.sectionList}>
      {numbersList.map(numberItem => (
        <AboutSectionSmallCard
          key={numberItem}
          text={numberItem}
          handleDelete={() => deleteNumbersItem(numberItem)}
        />
      ))}
    </div>
  );

  return (
    <div className={styles.section}>
      {renderForm()}
      {userError && <FormError text={userError} />}
      {renderInputs()}
    </div>
  );
}
