import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import CompanyInvoices from './company_invoices';

const createProps = () => {
  return {
    invoices: [
      {
        id: 1,
        invoice_id: '123456789',
      },
      {
        id: 2,
        invoice_id: '987654321',
      },
    ],
  };
};

let props;
let wrapped: ShallowWrapper;

describe('Your description here', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<CompanyInvoices {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
