import { apolloClient, gql } from 'lib/graphql';
import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'react-image-crop/dist/ReactCrop.css';

import CropModal from './CropModal';
import Button from 'lj_shared/button/button';
import FormError from 'components/form/form_error/form_error';
import Icon from 'components/general/icon/icon';
import Spinner from 'shared/dot_spinner/DotSpinner';
import RemoveImage from './RemoveImage';

const styles = require('./ImageInput.module.scss');

export interface ImageData {
  cropH: number;
  cropW: number;
  cropX: number;
  cropY: number;
  image: File;
}

interface Props {
  alt: string;
  companyId?: number;
  buttonColor?: string;
  defaultValue?: string;
  fallbackLetter?: string;
  kind?: string;
  maxSize?: number;
  name?: string;
  onSave?: (imageData: ImageData) => void;
  onUpload?: Function;
  recommendedHeight?: number;
  recommendedWidth?: number;
  uploadIcon?: boolean;
  removeImage?: boolean;
}

const DEFAULT_HEIGHT = 400;
const DEFAULT_WIDTH = 400;
const MAX_SIZE = 1048576;

const UPLOAD_IMAGE_GQL = gql`
  mutation (
    $cropH: Int!
    $cropW: Int!
    $cropX: Int!
    $cropY: Int!
    $image: Upload!
    $kind: ImageEnum!
  ) {
    user {
      updateImage(
        cropH: $cropH
        cropW: $cropW
        cropX: $cropX
        cropY: $cropY
        image: $image
        kind: $kind
      ) {
        errors {
          field
          message
        }
        url
      }
    }
  }
`;

const BACKOFFICE_UPLOAD_IMAGE_GQL = gql`
  mutation (
    $cropH: Int!
    $cropW: Int!
    $cropX: Int!
    $cropY: Int!
    $image: Upload!
    $kind: ImageEnum!
    $companyId: ID!
  ) {
    backoffice {
      updateCompanyImage(
        cropH: $cropH
        cropW: $cropW
        cropX: $cropX
        cropY: $cropY
        image: $image
        kind: $kind
        companyId: $companyId
      ) {
        errors {
          field
          message
        }
        url
      }
    }
  }
`;

export default function ImageInput(props: Props) {
  const isWide = props.kind === 'COMPANY_COVER_PHOTO';
  const recommendedHeight = props.recommendedHeight || DEFAULT_HEIGHT;
  const recommendedWidth = props.recommendedWidth || DEFAULT_WIDTH;
  const aspectRatio = recommendedWidth / recommendedHeight;
  const maxSize = props.maxSize || MAX_SIZE;
  const [imageUrl, setImageUrl] = useState(props.defaultValue);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [file, setFile] = useState(null);
  const modalRef = useRef(null);
  const squareImage = ['Cover Photo', 'Office Image'].includes(props.alt);
  const [removeImageButton, setRemoveButton] = useState(props.removeImage || false);

  const onSelectFile = event => {
    const newFile = event.target.files[0];

    if (newFile) {
      const [valid, newError] = validate(maxSize, newFile);
      if (valid) {
        setFile(newFile);
        modalRef.current.open();
      }
      setError(newError);
    }
  };

  return (
    <>
      <CropModal
        aspectRatio={aspectRatio}
        file={file}
        modalRef={modalRef}
        onSave={handleCropDone.bind(
          null,
          props.kind,
          props.onSave,
          props.onUpload,
          props.companyId,
          setImageUrl,
          setError,
          setLoading
        )}
        square={squareImage}
      />
      <div className={classNames(styles.container, { [styles.isWide]: isWide })}>
        {displayImage(props.kind, props.fallbackLetter, imageUrl, props.alt, loading, squareImage)}
        <div className={styles.rightColumn}>
          <Button
            isButton={false}
            buttonColor={props.buttonColor || 'silverSand'}
            otherClasses={styles.button}
          >
            Upload image
            {props.uploadIcon && <Icon name='uploadFile' color='ripePlumMidLight' />}
            <input
              className={styles.fileInput}
              onChange={onSelectFile}
              name={props.name}
              type='file'
            />
          </Button>
          <ul className={styles.requirements}>
            <li>
              Recommended dimensions: {recommendedWidth}x{recommendedHeight}px
            </li>
            <li>Max. file size: {toMB(maxSize)} MB</li>
            <li>JPG or PNG only</li>
            <li>
              {' '}
              {removeImageButton && (
                <RemoveImage
                  buttonColor='red'
                  kind={props.kind}
                  setError={setError}
                  setLoading={setLoading}
                  setImageUrl={setImageUrl}
                  onUpload={props.onUpload}
                />
              )}
            </li>
          </ul>
        </div>
      </div>
      {error && <FormError text={error} />}
    </>
  );
}

async function handleCropDone(
  kind,
  onSave,
  onUpload,
  companyId,
  setImageUrl,
  setError,
  setLoading,
  imageData
) {
  if (kind) {
    await saveImage(kind, setImageUrl, setError, setLoading, imageData, onUpload, companyId);
  }
  if (onSave) onSave(imageData);
}

async function saveImage(kind, setImageUrl, setError, setLoading, imageData, onUpload, companyId) {
  try {
    setLoading(true);

    const { data, errors } = companyId
      ? await apolloClient.mutate({
          mutation: BACKOFFICE_UPLOAD_IMAGE_GQL,
          variables: { ...imageData, kind, companyId },
        })
      : await apolloClient.mutate({
          mutation: UPLOAD_IMAGE_GQL,
          variables: { ...imageData, kind },
        });

    const url = companyId
      ? data?.backoffice?.updateCompanyImage?.url
      : data?.user?.updateImage?.url;

    if (url) {
      setImageUrl(url);
      setLoading(false);
      if (onUpload) onUpload(url);
    } else {
      setLoading(false);
      setError('Oops, something wrong happened');
    }
  } catch (error) {
    setLoading(false);
    setError('Oops, something wrong happened');
  }
}

function displayImage(kind, fallbackLetter, imageUrl, alt, loading, squareImage) {
  const image = loading ? (
    <Spinner />
  ) : (
    <img
      alt={alt}
      className={classNames(styles.image, { [styles.circle]: !squareImage })}
      src={imageUrl}
    />
  );
  if (imageUrl) {
    return <div className={styles.leftColumn}>{image}</div>;
  } else if (kind === 'COMPANY_LOGO') {
    const letter = fallbackLetter || 'L';
    return (
      <div className={classNames(styles.leftColumn, styles.textLogo)}>
        <span className={styles.logoLetter}>{letter}</span>
      </div>
    );
  }
}

function validate(maxSize, file) {
  const types = /(\.|\/)(jpg|jpeg|png)$/i;

  if (!(types.test(file.type) || types.test(file.name))) {
    return [false, 'The right format will do wonders. Please upload a JPG or PNG.'];
  } else if (file.size > maxSize) {
    const size = toMB(maxSize);
    return [false, `Size does matter in this case. File size needs to be under ${size} MB.`];
  } else {
    return [true];
  }
}

function toMB(size) {
  const inMB = size / 1048576;
  const rounded = Math.round((inMB + Number.EPSILON) * 10) / 10;
  return rounded;
}
