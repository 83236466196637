import * as React from 'react';
import JobMoreButton from './job_more_button';
import { JobAd } from '../../__models__/models';
import { shallow } from 'enzyme';

const jobDummy: JobAd = require('./fixtures/job_ad_dummy.json');

let wrapped;
let handleMock;

beforeAll(() => {
  handleMock = jest.fn();
  wrapped = shallow(
    <JobMoreButton
      jobAd={jobDummy}
      duplicateUrl='duplicate url'
      closeUrl='close url'
      isPublished={true}
      hasATS={true}
      termsOfService='tos'
      privacy='privacy'
      handleUnpublish={handleMock}
      handlePublish={handleMock}
      setJobsState={handleMock}
      isWaitingRevision={false}
    />
  );

  const button = wrapped.find('.lj-button');
  button.simulate('click');
  wrapped.update();
});

it('it should open the menu on click', () => {
  expect(wrapped.find('ul').length).toEqual(1);
});

describe('when job is published and has an ATS', () => {
  it('it should have five buttons', () => {
    expect(wrapped.find('ul li a').length).toEqual(5);
  });

  it('it should have a Share button', () => {
    expect(wrapped.find({ 'data-testid': 'share-button' }).text()).toEqual('Share');
  });

  it('it should have a ATS button', () => {
    expect(wrapped.find({ 'data-testid': 'ats-button' }).text()).toEqual('Pair with ATS');
  });

  it('it should have a Duplicate button', () => {
    expect(wrapped.find({ 'data-testid': 'duplicate-button' }).text()).toEqual('Duplicate');
  });

  it('it should have a Unublish button', () => {
    expect(wrapped.find({ 'data-testid': 'unpublish-button' }).text()).toEqual('Unpublish');
  });

  it('it should have a Close button', () => {
    expect(wrapped.find({ 'data-testid': 'close-button' }).text()).toEqual('Close Job');
  });
});

describe('when job is published and does not have an ATS', () => {
  beforeAll(() => {
    wrapped.setProps({ hasATS: false });
  });

  it('it should have four buttons', () => {
    expect(wrapped.find('ul li a').length).toEqual(4);
  });

  it('it should have a Share button', () => {
    expect(wrapped.find({ 'data-testid': 'share-button' }).text()).toEqual('Share');
  });

  it('it should have a Duplicate button', () => {
    expect(wrapped.find({ 'data-testid': 'duplicate-button' }).text()).toEqual('Duplicate');
  });

  it('it should have a Unpublish button', () => {
    expect(wrapped.find({ 'data-testid': 'unpublish-button' }).text()).toEqual('Unpublish');
  });

  it('it should have a Close button', () => {
    expect(wrapped.find({ 'data-testid': 'close-button' }).text()).toEqual('Close Job');
  });

  it('it should not have a ATS button', () => {
    expect(wrapped.find({ 'data-testid': 'ats-button' }).length).toEqual(0);
  });
});

describe('when job is unpublished and has an ATS', () => {
  beforeAll(() => {
    wrapped.setProps({ isPublished: false, hasATS: true });
  });

  it('it should have three buttons', () => {
    expect(wrapped.find('ul li a').length).toEqual(3);
  });

  it('it should not have a ATS button', () => {
    expect(wrapped.find({ 'data-testid': 'ats-button' }).length).toEqual(1);
  });

  it('it should have a Duplicate button', () => {
    expect(wrapped.find({ 'data-testid': 'duplicate-button' }).text()).toEqual('Duplicate');
  });

  it('it should have a Close button', () => {
    expect(wrapped.find({ 'data-testid': 'close-button' }).text()).toEqual('Close Job');
  });

  it('it should not have a Publish button', () => {
    wrapped.setProps({ isPublished: false });
    expect(wrapped.find({ 'data-testid': 'unpublish-button' }).length).toEqual(0);
  });

  it('it should not have a Share button', () => {
    expect(wrapped.find({ 'data-testid': 'share-button' }).length).toEqual(0);
  });
});

describe('when job is unpublished and does not have an ATS', () => {
  beforeAll(() => {
    wrapped.setProps({ isPublished: false, hasATS: false });
  });

  it('it should have two buttons', () => {
    expect(wrapped.find('ul li a').length).toEqual(2);
  });

  it('it should have a Duplicate button', () => {
    expect(wrapped.find({ 'data-testid': 'duplicate-button' }).text()).toEqual('Duplicate');
  });

  it('it should have a Close button', () => {
    expect(wrapped.find({ 'data-testid': 'close-button' }).text()).toEqual('Close Job');
  });

  it('it should not have a Share button', () => {
    expect(wrapped.find({ 'data-testid': 'share-button' }).length).toEqual(0);
  });

  it('it should not have a ATS button', () => {
    expect(wrapped.find({ 'data-testid': 'ats-button' }).length).toEqual(0);
  });

  it('it should not have a Publish button', () => {
    expect(wrapped.find({ 'data-testid': 'publish-button' }).length).toEqual(0);
  });
});

describe('when jobs han an ATS and is paired with it', () => {
  beforeAll(() => {
    wrapped.setProps({
      isPublished: true,
      hasATS: true,
      jobAd: { attributes: { job_has_ats_offer: true } },
    });
  });

  it('it should have a Unpair with ATS button', () => {
    expect(wrapped.find({ 'data-testid': 'ats-button' }).text()).toEqual('Unpair with ATS');
  });
});
