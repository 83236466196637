import React from 'react';
import ReactPaginate from 'react-paginate';

import 'lib/globals';

const styles = require('./pagination.module.scss');

export interface Props {
  breakLabel?: any;
  children: any;
  currentPage: number;
  handlePageClick: Function;
  itemName?: string;
  totalItemCount: number;
  next?: any;
  perPage?: number;
  prev?: any;
}

export default function Pagination(props: Props) {
  const perPage = props.perPage || 20;
  const numberPages = Math.ceil(props.totalItemCount / perPage);

  const paginationElement = () => {
    const previous = props.currentPage !== 0 && (props.prev || '< Prev');
    const next = props.currentPage !== numberPages - 1 && (props.next || 'Next >');
    const breakLabel = props.breakLabel || <span className={styles.paginationGap}>...</span>;

    return (
      numberPages > 1 && (
        <ReactPaginate
          previousLabel={previous}
          nextLabel={next}
          breakLabel={breakLabel}
          pageCount={numberPages}
          onPageChange={props.handlePageClick}
          forcePage={props.currentPage}
          containerClassName={'pagination ' + styles.reactPagination}
          previousLinkClassName={styles.paginationPrevious_page}
          nextLinkClassName={styles.paginationNext_page}
          disabledClassName={styles.paginationDisabled}
          activeClassName={styles.paginationActive}
        />
      )
    );
  };

  return (
    <div className={styles.paginatedContainer}>
      {paginationElement()}
      {props.children}
      {renderShowingPages(
        props.currentPage,
        perPage,
        props.totalItemCount,
        numberPages,
        props.itemName
      )}
      {paginationElement()}
    </div>
  );
}

export const renderShowingPages = (
  currentPage: number,
  perPage: number,
  totalItemCount: number,
  numberPages: number,
  itemName = ''
) => {
  const first = currentPage * perPage + 1;
  const last = Math.min((currentPage + 1) * perPage, totalItemCount);
  return (
    numberPages > 1 && (
      <div className={styles.paginationTotal}>
        Displaying {itemName}
        <b>{` ${first} - ${last} `}</b>
        of
        <b>{` ${totalItemCount} `}</b>
        in total
      </div>
    )
  );
};
