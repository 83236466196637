import React from 'react';
import { shallow, ShallowWrapper, mount } from 'enzyme';
import GenericSelectRange from './GenericSelectRange';
import Select from 'components/form/select/select';
import FormInput from 'components/general/form_input/form_input';
import JobTypeInterests from './JobTypeInterests';
import GrossAnnualSalaryRange from './GrossAnnualSalaryRange';
import RateRange from './RateRange';

const createProps = (jobTypesInterest: { id: number; label: string }[] = []) => ({
  handleFormChange: jest.fn(),
  jobTypesInterest,
  jobTypesList: {
    'Permanent employee': 0,
    Contractor: 1,
    'Full remote': 2,
    'Partially remote': 3,
    'I’m not certain about this': 4,
  },
  showRateAndGas: true,
  rate: { minimum: 300, maximum: 400 },
  gas: { minimum: 20000, maximum: 30000 },
});

let props;
let wrapped: any;

describe('Job types interest component', () => {
  describe('without selected job types', () => {
    beforeEach(() => {
      props = createProps();
      wrapped = mount(<JobTypeInterests {...props} />);
    });

    it('shallow renders without crashing', () => {
      expect(wrapped.exists()).toBeTruthy();
    });

    it('does not contains the component for selecting the gross annual salary range', () => {
      const gas = wrapped.find(GrossAnnualSalaryRange);
      expect(gas.exists()).toBeFalsy();
    });

    it('does not contains the component for selecting the gross annual salary range', () => {
      const rate = wrapped.find(RateRange);
      expect(rate.exists()).toBeFalsy();
    });
  });

  describe('with "Permanent employee" job type selected', () => {
    beforeEach(() => {
      props = createProps([{ id: 0, label: 'Permanent employee' }]);
      wrapped = mount(<JobTypeInterests {...props} />);
    });

    it('shallow renders without crashing', () => {
      expect(wrapped.exists()).toBeTruthy();
    });

    it('contains the component for selecting the gross annual salary range', () => {
      const gas = wrapped.find(GrossAnnualSalaryRange);
      expect(gas.exists()).toBeTruthy();
    });

    it('does not contains the component for selecting the gross annual salary range', () => {
      const rate = wrapped.find(RateRange);
      expect(rate.exists()).toBeFalsy();
    });
  });

  describe('with "Contractor" job type selected', () => {
    beforeEach(() => {
      props = createProps([{ id: 1, label: 'Contractor' }]);
      wrapped = mount(<JobTypeInterests {...props} />);
    });

    it('shallow renders without crashing', () => {
      expect(wrapped.exists()).toBeTruthy();
    });

    it('does not contains the component for selecting the gross annual salary range', () => {
      const gas = wrapped.find(GrossAnnualSalaryRange);
      expect(gas.exists()).toBeFalsy();
    });

    it('contains the component for selecting the gross annual salary range', () => {
      const rate = wrapped.find(RateRange);
      expect(rate.exists()).toBeTruthy();
    });
  });

  describe('with "Permanent employee" and "Contractor" job type selected', () => {
    beforeEach(() => {
      props = createProps([
        { id: 0, label: 'Permanent employee' },
        { id: 1, label: 'Contractor' },
      ]);
      wrapped = mount(<JobTypeInterests {...props} />);
    });

    it('shallow renders without crashing', () => {
      expect(wrapped.exists()).toBeTruthy();
    });

    it('does not contains the component for selecting the gross annual salary range', () => {
      const gas = wrapped.find(GrossAnnualSalaryRange);
      expect(gas.exists()).toBeTruthy();
    });

    it('contains the component for selecting the gross annual salary range', () => {
      const rate = wrapped.find(RateRange);
      expect(rate.exists()).toBeTruthy();
    });
  });

  describe('with all selected job types except "Permanent employee"" and "Contractor"', () => {
    beforeEach(() => {
      props = createProps([
        { id: 2, label: 'Full remote' },
        { id: 3, label: 'Partially remote' },
        { id: 4, label: 'I’m not certain about this' },
      ]);
      wrapped = mount(<JobTypeInterests {...props} />);
    });

    it('shallow renders without crashing', () => {
      expect(wrapped.exists()).toBeTruthy();
    });

    it('does not contains the component for selecting the gross annual salary range', () => {
      const gas = wrapped.find(GrossAnnualSalaryRange);
      expect(gas.exists()).toBeFalsy();
    });

    it('does not contains the component for selecting the gross annual salary range', () => {
      const rate = wrapped.find(RateRange);
      expect(rate.exists()).toBeFalsy();
    });
  });
});
