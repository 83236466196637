import { apolloClient, gql } from 'lib/graphql';
import React from 'react';
import 'react-image-crop/dist/ReactCrop.css';

const styles = require('./ImageInput.module.scss');

const REMOVE_IMAGE_GQL = gql`
  mutation ($kind: String!) {
    user {
      removeImage(kind: $kind) {
        errors {
          field
          message
        }
        url
      }
    }
  }
`;

interface Props {
  buttonColor?: string;
  kind?: string;
  setError?: Function;
  setLoading?: Function;
  setImageUrl?: Function;
  onUpload?: Function;
}

export default function RemoveImage(props: Props) {
  return (
    <>
      <div className={styles.rightColumn}>
        <a
          style={{ color: props.buttonColor }}
          className={'delete-link'}
          onClick={() =>
            saveDefaultImage(
              props.kind,
              props.setError,
              props.setLoading,
              props.setImageUrl,
              props.onUpload
            )
          }
        >
          Delete image
        </a>
      </div>
    </>
  );
}

async function saveDefaultImage(kind, setError, setLoading, setImageUrl, onUpload) {
  if (kind) {
    await saveImage(kind, setError, setLoading, setImageUrl, onUpload);
  }
}

async function saveImage(kind, setError, setLoading, setImageUrl, onUpload) {
  try {
    setLoading(true);

    const { data, errors } = await apolloClient.mutate({
      mutation: REMOVE_IMAGE_GQL,
      variables: { kind },
    });

    if (errors) {
      setLoading(false);
      setError('Oops, something wrong happened');
      return;
    }

    const url = data?.user?.removeImage?.url;

    if (url) {
      setImageUrl(url);
      setLoading(false);
      if (onUpload) onUpload(url);
    }
  } catch (error) {
    setLoading(false);
    setError('Oops, something wrong happened');
  }
}
