import * as React from 'react';
import './application_header.scss';
import ApplicationCard from './application_card/application_card';
import { Application, HiringStep } from '../../__models__/models';
import './application_evaluation.scss';
import showdown from 'showdown';
import RatingContainerUtils from 'components/general/rating/utils/rating_container_utils';
import HandpickedNotice from 'components/employer_applications/handpicked_notice';
import { EvaluationItems } from './evaluation_items';
import { RatingItem } from './rating_item';
import { TestsItem } from './tests_item';
import { SelfEvaluationItem } from './SelfEvaluationItem';
import { CurationHeader } from 'components/general/rating_dimension/rating_dimension_container';
import { concernText } from 'components/employer_applications/ratingFilterUtils';

const profileIcon = require('svg/icon_fit_grey.svg');
const styles = require('./application_evaluation.module.scss');

const converter = new showdown.Converter();
converter.setOption('simpleLineBreaks', true);
converter.setOption('strikethrough', true);

export interface Props {
  application: Application;
  sendTest?: any;
  dashboard?: boolean;
  ratingsWithDimensions?: any;
  rateApplicationUrl?: string;
  hideCurationNotesIfLegacy?: boolean;
  outdated?: boolean;
}

const ApplicationEvaluation = (props: Props) => {
  props.application.attributes = props.dashboard
    ? (props.application as any).data.attributes
    : props.application.attributes;

  const { screening_notes, test_results, current_state, hiring_steps, self_evaluations, job_ad } =
    props.application.attributes;

  const currentStep = Object.values(hiring_steps).find(
    (step: HiringStep) => step.value === current_state
  );
  const [concern, concernNote, concernLevel] = concernText(props.application);
  const ratings = new RatingContainerUtils(props.ratingsWithDimensions).props();
  const overall = ratings[0]?.rating || '-';

  const items = [
    {
      title: <CurationHeader hasTooltip appPage overall={overall} />,
      evaluationItem: RatingItem(
        ratings,
        props.rateApplicationUrl,
        props.hideCurationNotesIfLegacy,
        props.dashboard,
        screening_notes,
        props.outdated,
        concern,
        concernNote,
        concernLevel
      ),
    },
    self_evaluations.length > 0 && {
      title: 'Self-Evaluation',
      subtitle: "Candidate's self-evaluation on their fit to your job requirements",
      evaluationItem: SelfEvaluationItem(self_evaluations, job_ad),
    },
    {
      title: 'Technical Tests',
      evaluationItem: TestsItem(test_results, currentStep, props.sendTest, props.dashboard),
    },
  ];

  return (
    <ApplicationCard
      title='Evaluation'
      icon={profileIcon}
      alt='person'
      className='lj-application-evaluation'
    >
      {renderHandpickedNotice(props.application)}
      <EvaluationItems items={items} />
    </ApplicationCard>
  );
};

function renderHandpickedNotice(application) {
  const talentManager = application.attributes?.talent_manager;
  if (application.attributes?.is_handpicked && talentManager) {
    return (
      <HandpickedNotice
        avatarUrl={talentManager.avatar_url}
        className={styles.handpickedNotice}
        name={talentManager.name}
        note={application.attributes.handpicked_note}
      />
    );
  }
}

export default ApplicationEvaluation;
