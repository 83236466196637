import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'components/general/modal/modal';
import TextInput from 'components/form/text/text';
import Select from 'components/form/select/select';
import EducationForm from './education_form';
import { handleRequestError } from 'lib/request_deprecated';
import { apolloClient } from 'lib/graphql';
import { ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import FormInput from 'components/general/form_input/form_input';
import { PersonInstitution } from '__models__/models';
import { cloneDeep } from 'lodash';
import Button from 'lj_shared/button/button';

const styles = require('./modals.module.scss');

interface Props {
  action?: number;
  defaultOpen: boolean;
  educationForm: EducationForm;
  handleAddClick: Function;
  handleDeleteClick?: Function;
  handleEditClick?: Function;
}

interface State {
  educationForm: EducationForm;
  revision: boolean;
}

export default class EducationModal extends React.Component<Props, State> {
  modalRef: any;
  tagRef: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      educationForm: new EducationForm({
        ...this.props.educationForm,
      }),
      revision: false,
    };

    this.modalRef = React.createRef();
    this.tagRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.educationForm !== prevProps.educationForm) {
      this.setState({
        educationForm: new EducationForm({
          ...this.props.educationForm,
        }),
      });
    }
  }

  handleSubmit = event => {
    this.setState({ revision: true });
    this.state.educationForm
      .save()
      .then((response: { saved: boolean; data: { data: PersonInstitution }; post: boolean }) => {
        if (response.saved && response.post) {
          this.handleCancel();
          this.props.handleAddClick(response.data.data);
        } else if (response.saved) {
          this.handleCancel();
          this.props.handleEditClick(response.data.data);
        }
        this.setState({ revision: false });
      })
      .catch(e => {
        handleRequestError(e);
        this.setState({ revision: false });
      });
  };

  handleCancel = () => {
    this.modalRef.current.close();
  };

  handleFormChange = (event, input = '') => {
    const update = {};
    if (event.target) {
      update[event.target.name] = event.target.value;
    } else {
      update[input] = event;
    }

    const form = cloneDeep(this.state.educationForm);
    form.update(update);

    this.setState({ educationForm: form });
  };

  async loadOptions(query) {
    const result = await apolloClient.query({
      query: GQL_QUERY_INSTITUTIONS,
      variables: { query },
    });
    return result.data.institutions.nodes.map(t => ({
      label: t.name,
      value: t.id,
    }));
  }

  handleDelete = () => {
    this.props.handleDeleteClick(this.state.educationForm.id);
    this.handleCancel();
  };

  getYears = () => {
    const currentYear = new Date().getFullYear();
    const range = (start, stop, step) =>
      Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
    const years = range(currentYear, currentYear - 80, -1);
    return years.map(year => ({
      value: year,
      label: year,
    }));
  };

  selectValue = data => {
    if (data) {
      return {
        label: data,
        value: data,
      };
    }
  };

  render() {
    return <ApolloProvider client={apolloClient}>{this.renderInputs()}</ApolloProvider>;
  }

  renderInputs() {
    return (
      <Modal
        title={`${this.props.action ? 'Edit' : 'Add'} Academic Experience`}
        defaultOpen={this.props.defaultOpen}
        ref={this.modalRef}
        disableOutsideClose={true}
      >
        <FormInput
          name='degree'
          label='Name of Degree'
          error={this.state.educationForm.errors?.degree}
          required
        >
          <TextInput
            name='degree'
            placeholder="Bachelor's of..."
            value={this.state.educationForm.degree}
            onChange={this.handleFormChange}
            class={styles.textInput}
          />
        </FormInput>
        <FormInput
          name='institution'
          label='Institution'
          error={this.state.educationForm.errors?.institution}
          required
        >
          <Select
            name={'institution'}
            loadOptions={this.loadOptions}
            openMenuOnClick={false}
            placeholder='E.g. Stack Overflow'
            ref={this.tagRef}
            type='async-creatable'
            color='silverSand'
            value={this.state.educationForm.institution}
            onChange={event => this.handleFormChange(event, 'institution')}
          />
        </FormInput>
        <FormInput
          name='info'
          label='Level of Degree'
          error={this.state.educationForm.errors?.info}
        >
          <TextInput
            name='info'
            placeholder='Major in...'
            value={this.state.educationForm.info}
            onChange={this.handleFormChange}
            class={styles.textInput}
          />
        </FormInput>
        <FormInput
          name='graduation'
          label='Graduation Year'
          error={this.state.educationForm.errors?.graduation}
          required
        >
          <Select
            name={'graduation'}
            options={this.getYears()}
            placeholder='2019...'
            color='silverSand'
            defaultValue={this.selectValue(this.state.educationForm.graduation)}
            onChange={event => this.handleFormChange(event, 'graduation')}
          />
        </FormInput>
        <div className={styles.footer}>
          <div className={styles.buttons}>
            {this.props.handleDeleteClick && (
              <Button
                buttonColor='silverSand'
                buttonType='border'
                onClick={() => this.handleDelete()}
                otherClasses={styles.button}
              >
                Delete
              </Button>
            )}
            <Button
              buttonColor='tuftsBlue'
              onClick={this.handleSubmit}
              otherClasses={styles.button}
              disabled={this.state.revision}
              isButton
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

  static show(
    educationForm,
    handleAddClick,
    defaultOpen,
    handleEditClick = null,
    handleDeleteClick = null,
    action = null
  ) {
    const root = $('<div id="education-modal-container"></div>');

    $('body').append(root);

    ReactDOM.render(
      <EducationModal
        educationForm={educationForm}
        handleAddClick={handleAddClick}
        defaultOpen={defaultOpen}
        handleEditClick={handleEditClick}
        handleDeleteClick={handleDeleteClick}
        action={action}
      />,
      root[0]
    );
  }
}

const GQL_QUERY_INSTITUTIONS = gql`
  query ($query: String) {
    institutions(query: $query) {
      nodes {
        id
        name
      }
    }
  }
`;
