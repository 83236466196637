import React, { useRef, useState } from 'react';
import Button from 'lj_shared/button/button';
import DotLoader from 'lj_shared/dot_loader/dot_loader';
import Modal from 'components/general/modal/modal';
import FormInput from 'components/general/form_input/form_input';
import Textarea from 'components/form/textarea/textarea';
import { postJSON } from 'lib/request';

interface Props {
  userId: number;
  userName: string;
  isBanned: boolean;
}

export default function BanUserModal(props: Props) {
  const modalRef = useRef(null);
  const banReasonRef = useRef(null);

  const [validationError, setValidationError] = useState(false);
  const [isBanned, setIsBanned] = useState(props.isBanned);
  const [loading, setLoading] = useState(false);

  const error = validationError ? 'Minimum 20 characters' : null;
  const buttonClasses = 'ld-button ld-small-button ld-grey-button';

  const handleOpen = event => {
    event.preventDefault();
    modalRef.current.open();
  };

  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    const banReason = banReasonRef.current.value;

    const followUp = response => {
      setLoading(false);

      if (response.success) {
        window.Alerts.notice(`${props.userName} successfully banned`);
        response.message && window.Alerts.alert(response.message);

        setIsBanned(true);
      } else {
        window.Alerts.alert(response.message);
      }
      modalRef.current.close();
    };

    if (banReason.length < 20) {
      setValidationError(true);
      setLoading(false);
    } else {
      postJSON(
        window.Routes.backoffice_talent_user_bans(),
        {
          user_id: props.userId,
          banned_reason: banReason,
        },
        followUp
      );
    }
  }

  return (
    <div>
      <Button
        isButton={true}
        disabled={isBanned}
        onClick={handleOpen}
        otherClasses={buttonClasses}
        extraStyle={{ height: 'auto' }}
      >
        {isBanned ? 'User Banned' : 'Ban User'}
      </Button>
      <Modal
        disableButton={loading}
        disableOutsideClose={true}
        ref={modalRef}
        title={`Ban ${props.userName}`}
      >
        <span>
          <strong>Attention: </strong>
          This will remove the user from all ongoing applications.
        </span>
        <FormInput required={true} error={error} label='Reason'>
          <Textarea
            placeholder='Write a descriptive reason for banning the user.'
            ref={banReasonRef}
          />
        </FormInput>
        <div>
          <Button
            isButton={true}
            disabled={loading}
            onClick={handleSubmit}
            otherClasses={buttonClasses}
            extraStyle={{ height: 'auto', float: 'right', marginTop: '0.5rem' }}
          >
            Ban User
          </Button>
        </div>
        {loading && <DotLoader style={{ position: 'absolute', bottom: '0.5rem', left: '50%' }} />}
      </Modal>
    </div>
  );
}
