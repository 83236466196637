import { AssessmentResultsList, AssessmentsList } from 'components/job_hiring_steps/models';
import { isMobile } from 'react-device-detect';
import { postJSON } from 'lib/request';
import { startCase } from 'lodash/string';
import Button from 'lj_shared/button/button';
import classNames from 'classnames';
import EmptyState from 'components/general/empty_state/empty_state';
import Icon, { Size } from 'components/general/icon/icon';
import React from 'react';
import Tooltip from 'components/general/tooltip/tooltip';

const styles = require('./code_challenges.module.scss');

interface Props {
  assessments: AssessmentsList;
  results: AssessmentResultsList;
  scope: string;
}

export default function CodeChallenges(props: Props) {
  const official = props.scope === 'official';
  const testsOrChallenges = official ? 'challenges' : 'tests';

  const followup = response => {
    if (response.notice) {
      if (isMobile) {
        window.Alerts.notice(response.notice);
      } else {
        window.open(response.link);
      }
    } else {
      window.Alerts.alert(response.alert);
    }
    window.toggleSpinner(false);
    // This is a quick fix to prevent users from retaking the official challenges!
    // In a normal situation we would burn a developer in a fire pit, for doing a reload in React!
    window.location.reload();
  };

  const sendInvite = challenge => {
    window.toggleSpinner(true);
    postJSON(window.Routes.dashboard_code_challenge_invite(challenge.id), {}, followup);
  };

  const renderCardHeader = challenge => {
    return (
      <div
        className={classNames(styles.cardTitle, styles.cardTitleText, {
          [styles.blueHeader]: official,
        })}
      >
        {renderTestName(challenge.attributes.name)}
        {official && (
          <Tooltip
            text="This is an official test, its result will be considered in your evaluation in application contexts. You can't retake this test."
            className={styles.tooltip}
          >
            <Icon name='checkCircle' color='tuftsBlue' />
          </Tooltip>
        )}
      </div>
    );
  };

  const renderList = (title, list) => {
    return (
      list && (
        <div>
          <span className={styles.boldItem}>{title}: </span>
          {list.slice(0, 6).map((item, index) => {
            return (
              <span key={`${item}-${index}`}>
                {index !== 0 && ', '}
                {item}
              </span>
            );
          })}
          {list.length > 6 && <span>...</span>}
        </div>
      )
    );
  };

  const renderResult = result => {
    return (
      <div className={styles.cardItem}>
        <div className={styles.scoreItem}>
          Score: {result.attributes.score}%
          <a
            href={window.Routes.assessment_pdf({
              assessment_id: result.attributes.assessment_id,
              result_id: result.attributes.external_id,
            })}
            target='_blank'
            rel='noreferrer'
          >
            <Icon
              className={styles.downloadPdfIcon}
              color='tuftsBlue'
              name='downloadCircle'
              size={Size.MediumLarge}
            />
          </a>
        </div>
        <span className={styles.completedAt}>Completed on: {result.attributes.end_time}</span>
      </div>
    );
  };

  const renderIncompleteResult = result => {
    return (
      <div className={styles.cardItem}>
        <div className={styles.scoreItem}>
          Score: N/A
          <Tooltip
            text='The score should be available within 24 hours after you submit the test. Please contact us if this is not the case or you need a new invite for the challenge.'
            className={styles.infoIcon}
          >
            <Icon name='moreInfo' color='tuftsBlue' size={Size.MediumLarge} />
          </Tooltip>
        </div>
        <span className={styles.completedAt}>
          Waiting for results.
          <br />
          Invite sent on: {result.attributes.created_at}
        </span>
      </div>
    );
  };

  const renderButton = (challenge, firstTake) => {
    return (
      <div className={styles.button}>
        <Button
          buttonColor='tuftsBlue'
          buttonType={!official && 'border'}
          isRound
          targetBlank
          onClick={() => sendInvite(challenge)}
        >
          {isMobile ? 'Send invite' : firstTake ? 'Take test' : 'Retake test'}
        </Button>
      </div>
    );
  };

  const renderMobileText = (
    <span className={styles.smallText}>
      The technical tests are not optimized for mobile experience, click here if you want to receive
      an email from HackerRank with the test link to do it later.
    </span>
  );
  const renderChallenge = challenge => {
    const experience = challenge.attributes.experience_levels?.map(e => startCase(e));

    const tags = challenge.attributes.tags;
    const assessmentResult = props.results.data.filter(result => {
      return result.attributes.assessment_id === challenge.attributes.id;
    });
    const noPreviousTest = assessmentResult.length === 0;
    const showButton = challenge.attributes.state === 'training' ? true : noPreviousTest;

    return (
      <div key={challenge.id} className={styles.testCard}>
        {renderCardHeader(challenge)}
        <div className={styles.cardBody}>
          <div className={styles.cardItem}>{renderList('Categories', tags)}</div>
          <div className={styles.cardItem}>
            <span className={styles.boldItem}>Duration: </span>
            {challenge.attributes.duration} min
          </div>
          <div className={styles.cardItem}>{renderList('Experience', experience)}</div>
          {assessmentResult.length > 0 &&
            assessmentResult[0].attributes.completed &&
            renderResult(assessmentResult[0])}
          {assessmentResult.length > 0 &&
            !assessmentResult[0].attributes.completed &&
            renderIncompleteResult(assessmentResult[0])}
          {isMobile && renderMobileText}
          {showButton && renderButton(challenge, noPreviousTest)}
        </div>
      </div>
    );
  };

  return props.assessments.data.length > 0 ? (
    <div className={styles.testCardsList}>{props.assessments.data.map(renderChallenge)}</div>
  ) : (
    <EmptyState>There are no available {testsOrChallenges}.</EmptyState>
  );
}

function renderTestName(name) {
  const longName = (
    <Tooltip text={name} className={classNames(styles.tooltip)} position='centered'>
      <div className={styles.cardTitleText}>{name.substring(0, 23)}...</div>
    </Tooltip>
  );
  return name.length > 25 ? longName : name;
}
