import React from 'react';
import classNames from 'classnames';
import { Company, Job, User } from '__models__/gql/all';
import Button from 'lj_shared/button/button';
import Icon from 'components/general/icon/icon';
import CompanySideHandshake from './company_side_handshake';

import JobCard from 'components/mini_job_card/JobCard';
import AverageHiringProcessItem from 'components/averageHiringProcess/AverageHiringProcessItem';
import ShareSection from 'components/general/share_section/ShareSection';
const styles = require('./Sidebar.module.scss');

interface Props {
  company: Partial<Company>;
  jobs?: Partial<Job>[];
  setJobsTab: Function;
  showJobs: boolean;
  subscribed: boolean;
  user?: Partial<User>;
}

export default function Sidebar(props: Props) {
  const { company, jobs, setJobsTab, subscribed, user } = props;
  const showJobs = props.showJobs && jobs && jobs.length > 0;

  return (
    <aside className={styles.sidebar}>
      {user?.type === 'TalentUser' && <CompanySideHandshake company={company} />}
      <div className={styles.hiddenOnMobile}>
        {showJobs && <JobsSection jobs={jobs} setJobsTab={setJobsTab} />}
        <FollowSection company={company} subscribed={subscribed} user={user} />
        <div className={styles.shareSection}>
          <ShareSection company={company} />
        </div>
        <div className={styles.averageHiringProcess}>
          <div className={styles.averageHiringProcessTitle}>Useful Data</div>
          <AverageHiringProcessItem
            companyName={company.name}
            companyAvg={company.averageHiringProcess.companyAvg}
            marketAvg={company.averageHiringProcess.marketAvg}
            companyPage
          />
        </div>
      </div>
    </aside>
  );
}

function JobsSection({ jobs, setJobsTab }) {
  const MAX_JOBS = 3;
  const visibleJobs = jobs.slice(0, MAX_JOBS);

  return (
    <div className={styles.jobSection}>
      <div className={styles.jobSectionTitle}>Join our team</div>
      {visibleJobs.map(job => (
        <JobCard className={styles.jobCard} hideLogo={true} job={job} key={job.id} mini={true} />
      ))}
      {jobs.length > MAX_JOBS && (
        <button className={styles.jobsLink} onClick={setJobsTab}>
          See all jobs ({jobs.length})
        </button>
      )}
    </div>
  );
}

export function FollowSection({ company, subscribed, user, otherClasses = null }) {
  if (user?.type !== 'CompanyUser') {
    return (
      <>
        <div className={styles.followSectionText}>
          Get notified every time {company.name} publishes a new job.
        </div>
        <FollowButton
          company={company}
          subscribed={subscribed}
          user={user}
          otherClasses={classNames(otherClasses, styles.followButton)}
        />
      </>
    );
  } else {
    return null;
  }
}

function FollowButton({ company, subscribed, user, otherClasses = null }) {
  if (user?.type === 'TalentUser') {
    if (subscribed) {
      return (
        <Button
          buttonColor='black'
          buttonSize='xSmall'
          buttonType='border'
          buttonUrl={window.Routes.company_unfollow(company.id)}
          dataMethod='delete'
          otherClasses={classNames(otherClasses, styles.unfollowButton)}
        >
          <span className={styles.unfollowButtonNormal}>
            <Icon color='black' className={styles.followButtonIcon} name='bookmark' />
            Subscribed
          </span>
          <span className={styles.unfollowButtonHover}>Unsubscribe</span>
        </Button>
      );
    } else {
      return (
        <Button
          buttonColor='black'
          buttonSize='xSmall'
          buttonUrl={window.Routes.company_follow(company.id)}
          dataMethod='post'
          otherClasses={otherClasses}
        >
          <Icon className={styles.followButtonIcon} color='white' name='bookmarkOutline' />
          Subscribe to company
        </Button>
      );
    }
  } else {
    return (
      <Button
        buttonColor='tuftsBlue'
        buttonSize='xSmall'
        buttonUrl={window.Routes.company_sign_up_to_follow(company.id)}
        dataMethod='post'
        otherClasses={otherClasses}
      >
        <Icon className={styles.followButtonIcon} color='white' name='bookmark' />
        Sign up to subscribe
      </Button>
    );
  }
}
