import * as React from 'react';
import ApplicationCard from './application_card/application_card';
import Button from 'lj_shared/button/button';
import InlineIcon from 'components/general/inline_icon/inline_icon';
const eyeIcon = require('iconic/eye.svg');
const loopCircularIcon = require('iconic/loop-circular.svg');
require('./application_ats.scss');

export interface ATSProps {
  syncWithATS: any;
  exportToATS: any;
  ATSApplication: any;
  ATSOffer?: any;
  ATSName: string;
  applicationRejected: boolean;
}

export default class ApplicationATS extends React.Component<ATSProps> {
  constructor(props) {
    super(props);
  }

  loadAndSync = () => {
    window.toggleSpinner(true);
    this.props.syncWithATS();
  };

  atsApplicationRejected = () => {
    const atsApplicationData = this.props.ATSApplication.data;
    const atsStatus = atsApplicationData.status?.toLowerCase();
    const atsStage = atsApplicationData.stage?.toLowerCase();

    return atsApplicationData.disqualified || [atsStatus, atsStage].includes('rejected');
  };

  render() {
    return (
      <ApplicationCard
        title={this.props.ATSName + ' Integration'}
        icon={loopCircularIcon}
        alt='Loop Circular'
        className=' lj-application-ats'
      >
        <div className='lj-heading--mid'>ATS actions</div>
        {this.props.ATSApplication ? (
          <>
            {this.props.ATSApplication.deleted && (
              <div className='lj-application-ats-deleted'>
                This application seems to have been removed from {this.props.ATSName}.
              </div>
            )}

            {this.atsApplicationRejected() && !this.props.applicationRejected && (
              <div className='lj-application-ats-deleted'>
                This application is still rejected on {this.props.ATSName}. <br />
                Please update its status and click the button below to re-sync the data!
              </div>
            )}

            <div className='lj-application-ats-button-group'>
              {this.props.ATSName !== 'Personio' && (
                <Button
                  isButton={true}
                  buttonColor='ripePlum'
                  buttonSize='xSmall'
                  onClick={() => this.loadAndSync()}
                >
                  <InlineIcon path={loopCircularIcon} />
                  Sync
                </Button>
              )}

              {this.props.ATSApplication.data.profile_url && (
                <Button
                  isButton={false}
                  buttonColor='silverSand'
                  buttonSize='xSmall'
                  buttonUrl={this.props.ATSApplication.data.profile_url}
                  buttonType='border'
                  targetBlank={true}
                >
                  <InlineIcon path={eyeIcon} />
                  View on {this.props.ATSName}
                </Button>
              )}
            </div>
            <div className='lj-application-ats-sync'>
              <strong>{this.props.ATSName !== 'Personio' ? 'Last Sync:' : 'Exported on:'} </strong>
              {this.props.ATSApplication.last_sync_at.toString().slice(0, 10)}
            </div>
          </>
        ) : (
          <div className='lj-application-ats-button-group'>
            <Button
              isButton={true}
              buttonColor='black'
              buttonSize='xSmall'
              onClick={() => this.props.exportToATS()}
            >
              Export to ATS
            </Button>
          </div>
        )}
      </ApplicationCard>
    );
  }
}
