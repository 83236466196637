import * as React from 'react';
import ApplicationAts, { ATSProps } from './application_ats';
import { shallow, ShallowWrapper } from 'enzyme';

const createTestProps = moreProps => {
  return {
    syncWithATS: '',
    exportToATS: '',
    ATSApplication: {
      data: { disqualified: true },
      last_sync_at: new Date(),
    },
    ATSName: '',
    applicationRejected: false,
    ...moreProps,
  };
};

let props: ATSProps;
let wrapped: ShallowWrapper;

describe('Application ATS component', () => {
  beforeEach(() => {
    props = createTestProps({});
    wrapped = shallow(<ApplicationAts {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
