import React, { useRef, useState } from 'react';
import Modal, { ButtonsLayout } from 'components/general/modal/modal';
import Icon, { Size } from 'components/general/icon/icon';
import sanitizeHtml from 'sanitize-html-react';

const styles = require('./rejectionReasonModal.module.scss');

export interface Props {
  label: string;
  rejectionReason: string;
  rejectedAt: any;
}

const RejectionReasonModal = (props: Props) => {
  const modalRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const title = () => {
    return (
      <>
        <Icon name='handshake' color='silverSandMidDark' size={Size.Big} />
        <span className={styles.title}>Talent Comment</span>
      </>
    );
  };

  return (
    <>
      <a onClick={() => setIsOpen(true)}>{props.label}</a>
      <Modal
        buttonsLayout={ButtonsLayout.OkOnly}
        buttonName='Close'
        otherButtonClassesOnly={true}
        buttonClass={'ld-button ld-green-button ld-medium-button ld-flat-button'}
        title={title()}
        defaultOpen={false}
        ref={modalRef}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        buttonOnClick={() => setIsOpen(false)}
      >
        <p className={styles.text}>{sanitizeHtml(props.rejectionReason)}</p>
        <p className={styles.date}>Sent at {props.rejectedAt}</p>
      </Modal>
    </>
  );
};

export default RejectionReasonModal;
