import classNames from 'classnames';
import React from 'react';

import 'lib/globals';
import { Bookmark } from '__models__/gql/bookmark';
import { Job } from '__models__/gql/job';
import { JobDismissal } from '__models__/gql/job_dismissal';
import { User } from '__models__/gql/user';
import Actions from './Actions';
import Badge from 'components/general/badge/Badge';
import Main from './Main';

const styles = require('./JobCard.module.scss');

interface Props {
  applied?: boolean;
  bookmark?: Partial<Bookmark>;
  className?: string;
  hideLogo?: boolean;
  job: Partial<Job>;
  jobDismissal?: Partial<JobDismissal>;
  mini?: boolean;
  user?: Partial<User>;
}

export default function JobCard(props: Props) {
  const { applied, bookmark, hideLogo, job, jobDismissal, mini, user } = props;
  const { company } = job;
  const className = classNames(
    styles.card,
    { [styles.isDismissed]: jobDismissal },
    { [styles.isMini]: mini },
    props.className
  );

  if (mini) {
    return (
      <div className={className}>
        <Main job={job} mini={true} />
      </div>
    );
  } else {
    return (
      <div className={className}>
        <Badges applied={applied} job={job} jobDismissal={jobDismissal} />
        <Logo hide={hideLogo} name={company.name} url={company.logoUrl} />
        <Main job={job} mini={false} />
        <CallToAction job={job} />
        <Actions bookmark={bookmark} job={job} jobDismissal={jobDismissal} user={user} />
      </div>
    );
  }
}

function Badges({ applied, job, jobDismissal }) {
  return (
    <div className={styles.badges}>
      {job.isNew && <Badge color='blue'>New</Badge>}
      {applied && <Badge color='green'>Applied</Badge>}
      {jobDismissal && <Badge color='black'>Dismissed</Badge>}
    </div>
  );
}

function Logo({ hide, name, url }) {
  if (hide) return null;

  return (
    <div className={styles.logo}>
      <img alt={`${name} logo`} className={styles.logoImage} src={url} />
    </div>
  );
}

function CallToAction({ job }) {
  return (
    <a
      className={styles.callToAction}
      href={window.Routes.company_job_ad(job.company.slug, job.slug)}
    >
      View Job Description
    </a>
  );
}
