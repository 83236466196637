import * as React from 'react';
import { mount, shallow } from 'enzyme';
import JobList from './JobList';
import { JobResult } from './types';

const job: JobResult = require('./fixtures/job_result_dummy.json');

const props = {
  header: <div>header</div>,
  footer: <div>header</div>,
  paginationControl: <div>pages</div>,
  applications: [1],
  bookmarks: [],
  jsonJobs: [job],
  isHottest: true,
  showInfoTags: true,
};

window.Routes = {
  company: () => '',
  company_job_ad: () => '',
  join: () => '',
};

describe('JobList render', () => {
  it('shallow renders without crashing', () => {
    const component = shallow(<JobList {...props} />);

    expect(component.exists()).toBe(true);
  });

  it('converts a job and renders', () => {
    const component = mount(<JobList {...props} />);

    expect(component.exists()).toBe(true);
  });
});
