import * as React from 'react';
import ApplicationCard from './application_card/application_card';
import Button from 'lj_shared/button/button';

export interface Props {
  moveToReviewing: Function;
  rejectApplication: Function;
  application: any;
}

const ApplicationWaitingForReview = (props: Props) => {
  const bullhornIcon = require('iconic/bullhorn.svg');
  const { moveToReviewing, rejectApplication, application } = props;

  return (
    <ApplicationCard
      title='Awaiting your review'
      icon={bullhornIcon}
      alt='Bullhorn'
      className='lj-application-card--ripePlum'
    >
      <p>
        To have the ability to <strong>contact the candidate </strong>
        and <strong>add notes</strong> to their application, please choose one of the following
        actions:
      </p>
      <div style={{ margin: '-0.5rem' }}>
        <Button
          isButton={true}
          buttonColor='ripePlum'
          buttonSize='mid'
          onClick={() => moveToReviewing(null, application)}
        >
          Move to in process
        </Button>
        <Button
          isButton={true}
          buttonColor='light'
          buttonType='border'
          buttonSize='mid'
          onClick={() => rejectApplication()}
        >
          Reject
        </Button>
      </div>
    </ApplicationCard>
  );
};

export default ApplicationWaitingForReview;
