import React from 'react';
import { mount } from 'enzyme';
import SubscribeInput from './SubscribeInput';

const createProps = () => ({
  consent: true,
  error: '',
  handleFormChange: jest.fn(),
});

let props;
let wrapped;

describe('Subscribe input', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = mount(<SubscribeInput {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('shows the label', () => {
    expect(wrapped.find('.label').text()).toEqual(
      'You agree to receive email and communications, like job suggestions, community updates or notifications related to your job applications (you can unsubscribe at any time in the future).'
    );
  });

  it('has a checkbox', () => {
    expect(wrapped.find("input[type='checkbox']").exists()).toBeTruthy();
  });
});

test.todo('add more tests');
