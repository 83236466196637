import axios from 'axios';
import { useState, useEffect, useCallback } from 'react';
import { JobListInfiniteProps } from './JobListInfinite';

const useFetchJobs = (
  query: string,
  page: number,
  setJobList: React.Dispatch<React.SetStateAction<JobListInfiniteProps>>
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const sendQuery = useCallback(() => {
    setLoading(true);
    setError(false);

    axios
      .get(query)
      .then(res => {
        setJobList(prev => ({
          ...prev,
          jobResults: [...prev.jobResults, ...res.data.offers],
        }));
        setLoading(false);
      })
      .catch(err => setError(err));
  }, [page]);

  return { loading, error, sendQuery };
};

export default useFetchJobs;
