import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';

import Modal, { ButtonsLayout } from 'components/general/modal/modal';
const styles = require('./CropModal.module.scss');

interface Crop {
  aspect?: number;
  height?: number;
  width?: number;
  x?: number;
  y?: number;
}

export default function CropModal({ aspectRatio, file, modalRef, onSave, square }) {
  const imageUrl = useReadImage(file);
  const [sizes, setSizes] = useState(null);
  const [crop, setCrop] = useState({} as Crop);
  const handleImageLoaded = image => {
    const { height, naturalHeight, naturalWidth, width } = image;
    setSizes({ height, naturalHeight, naturalWidth, width });
    setCrop({ aspect: aspectRatio, height, width, x: 0, y: 0 });
    return false;
  };

  const handleOk = useCallback(async () => {
    const imageData = {
      cropH: adjustY(sizes, crop.height),
      cropW: adjustX(sizes, crop.width),
      cropX: adjustX(sizes, crop.x),
      cropY: adjustY(sizes, crop.y),
      image: file,
    };
    onSave(imageData);
    modalRef.current.close();
  }, [crop, file, modalRef, onSave]);

  return (
    <Modal
      buttonsLayout={ButtonsLayout.CancelOk}
      cancelButton={true}
      buttonName='Save'
      buttonOnClick={handleOk}
      disableOutsideClose={true}
      ref={modalRef}
      title='Adjust your photo'
    >
      <div className={styles.cropContainer}>
        <ReactCrop
          crop={crop}
          keepSelection={true}
          onChange={setCrop}
          onImageLoaded={handleImageLoaded}
          src={imageUrl}
          className={!square && styles.cropSelection}
        />
      </div>
    </Modal>
  );
}

function adjustX({ naturalWidth, width }, value) {
  return Math.round((value * naturalWidth) / width);
}

function adjustY({ naturalHeight, height }, value) {
  return Math.round((value * naturalHeight) / height);
}

function useReadImage(file) {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setImageUrl(reader.result));
      reader.readAsDataURL(file);
    }
  }, [file]);

  return imageUrl;
}
