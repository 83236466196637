import React, { useRef, useState } from 'react';
import Button from 'lj_shared/button/button';
import DotLoader from 'lj_shared/dot_loader/dot_loader';
import Modal from 'components/general/modal/modal';
import FileUploader from 'components/general/file_uploader/file_uploader';
import FormInput from 'components/general/form_input/form_input';
import RadioButtons from 'components/form/radio_buttons/radio_buttons';
import Select from 'components/form/select/select';
import { getJSON, handleRequestError } from 'lib/request';

interface Company {
  readonly value: number;
  readonly lable: string;
}

interface Props {
  candidateName: string;
  candidateId: number;
  date: string;
  readonly companies: Company[];
}

export class CompanyOption {
  id: string;
  name: string;
}

export default function UploadInterviewModal(props: Props) {
  const modalRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [jobType, setJobType] = useState('hiring');
  const [company, setCompany] = useState(null);
  const [companies, setCompanies] = useState<CompanyOption[]>(null);

  const handleClick = event => {
    event.preventDefault();
    modalRef.current.open();
    setLoading(false);
    getCompanies();
  };

  const handleChangeFile = () => {
    modalRef.current.close();
    window.location.reload();
  };

  const getCompanies = () => {
    if (companies) {
      return;
    }

    window.toggleSpinner(true);

    const params = {
      person_id: props.candidateId,
    };

    getJSON(window.Routes.company_options_backoffice_screening_pdfs_url(params), data => {
      if (data.error) {
        return;
      }

      setCompanies(data.options);

      window.toggleSpinner(false);
    });
  };

  return (
    <div>
      <Button
        onClick={handleClick}
        otherClasses={'ld-button ld-small-button ld-blue-button ld-border-button'}
        extraStyle={{ height: 'auto' }}
      >
        Upload Interview Report
      </Button>
      <Modal
        buttonColor='tuftsBlue'
        buttonName='Upload Report'
        disableButton={loading}
        disableOutsideClose={true}
        ref={modalRef}
        title='Upload Interview Report'
      >
        <p>Upload your completed report regarding candidate {props.candidateName}.</p>
        <FormInput name='interviewReportFileType' label='Choose the job type'>
          <RadioButtons
            selectedValue={jobType}
            options={[
              { value: 'hiring', label: 'Hiring' },
              { value: 'staffing', label: 'Staffing' },
            ]}
            onChange={value => setJobType(value)}
          />
        </FormInput>
        <FormInput
          name='screeningPdfCompanyId'
          label='Only visible to (leave empty if visible for all)'
        >
          {companies && (
            <Select
              value={company}
              options={companies}
              placeholder='Select a company if the report belongs to a company'
              onChange={setCompany}
              menuPosition={'fixed'}
            />
          )}
        </FormInput>
        <FormInput name='interviewReportFileName' label='Add the report'>
          <FileUploader
            url={window.Routes.backoffice_screening_pdf_upload_screening_pdf(0, {
              person_id: props.candidateId,
              report_type: jobType,
              company_id: company?.value,
            })}
            fileName=''
            validations={{
              fileTypes: ['pdf'],
              fileMaximumSizeMB: 30,
            }}
            handleChangeFile={handleChangeFile}
          />
        </FormInput>
        {loading && <DotLoader style={{ position: 'absolute', bottom: '0.5rem', left: '50%' }} />}
      </Modal>
    </div>
  );
}
