import React from 'react';

import Button from 'lj_shared/button/button';
import { DefaultCard } from './handshake_card';

const styles = require('./handshake_utils.module.scss');

export interface Props {
  handleReconsider: Function;
  date: Date;
  companyName: string;
  title?: JSX.Element;
  body?: string | JSX.Element;
  secondaryButton?: JSX.Element;
}

export function renderRejected(props: Props) {
  const { handleReconsider, date, companyName, title, body } = props;

  const rejectedCard: DefaultCard = {
    title: title ?? <span className={styles.rejectedSpan}>REJECTED</span>,
    body:
      body ??
      `On ${date}, ${companyName} requested a Handshake associated with this job. If you reconsider and have no previous application for this job, we will create one for you.`,
    buttons: (
      <>
        {' '}
        <Button
          buttonColor='tuftsBlueMidLight'
          buttonSize='small'
          isButton={true}
          disabled={false}
          onClick={() => handleReconsider()}
        >
          Reconsider
        </Button>
        {props.secondaryButton}
      </>
    ),
  };

  return rejectedCard;
}
