import * as React from 'react';
import Modal, { Props } from './modal';
import { shallow, ShallowWrapper } from 'enzyme';

const createTestProps = moreProps => {
  return {
    className: '',
    isOpen: true,
    onClose: jest.fn(),
    title: '',
    cancelButton: true,
    buttonColor: '',
    buttonName: '',
    buttonOnClick: jest.fn(),
    buttonIcon: '',
    buttonIconStyle: '',
    iconAlt: '',
    iconData: {},
    ...moreProps,
  };
};

let props: Props;
let wrapped: ShallowWrapper;

describe('Modal component', () => {
  beforeEach(() => {
    props = createTestProps({});
    wrapped = shallow(<Modal {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
