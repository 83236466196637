import * as React from 'react';
import JobCard from './JobCard';
import { shallow } from 'enzyme';

const company = require('__fixtures__/company_dummy');
const job = require('__fixtures__/job_dummy');
const tag = require('__fixtures__/tag_dummy');

window.Routes = {
  company: () => '',
  company_job_ad: () => '',
};

describe('JobCard', () => {
  it('shallow renders without crashing', () => {
    job.company = company;
    job.tags = [tag, tag];
    const component = shallow(<JobCard job={job} />);
    expect(component.exists()).toBe(true);
  });
});
