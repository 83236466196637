import * as React from 'react';
import ApplicationSteps, { Props } from './application_steps';
import { shallow, ShallowWrapper } from 'enzyme';
import applicationDummy from '__fixtures__/application_dummy';

const createTestProps = moreProps => {
  return {
    currentStep: '',
    delivered: true,
    hiringSteps: [],
    showLabel: true,
    moveToStep: jest.fn(),
    stepLabel: '',
    urls: [],
    application: applicationDummy,
    ...moreProps,
  };
};

let props: Props;
let wrapped: ShallowWrapper;

describe('Application Steps component', () => {
  beforeEach(() => {
    props = createTestProps({});
    wrapped = shallow(<ApplicationSteps {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
