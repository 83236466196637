/* eslint-disable quotes */
export const defaultErrorMessage =
  'Oops, something went wrong. Please refresh the screen and try again.';

export const statusErrorMessage = statusCode => errorMessages[statusCode] || defaultErrorMessage;

const errorMessages = {
  400: 'There was something wrong with the information submitted.',
  401: "You're not authorized to see this information/perform this action.",
  403: "You're not authorized to see this information/perform this action.",
  404: "The information you wanted to see doesn't seem to exist.",
  408: 'Request timed out. Please check your internet connection (Our servers may be under heavy load).',
  429: "You're making too many requests in a short period of time. Dial down, would'ya.",
  500: defaultErrorMessage,
  503: 'The server seems to be down temporarily. Please try again later.',
};
