import React from 'react';
const styles = require('./Trends.module.scss');
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend } from 'recharts';

interface Props {
  trends_data: any[];
}

export default function Trends(props: Props) {
  const [lines, trends] = props.trends_data;
  const colors = ['#00CFC5', '#007EFF', '#A729D2', '#F51F00', '#FF7A00'];

  return (
    <>
      <div className={styles.header}>Trends</div>
      <div className={styles.title}>Skills that are trending right now</div>
      <div className={styles.container}>
        <LineChart width={400} height={300} data={trends}>
          {lines.map((line, index) => {
            return (
              <Line
                key={line['name']}
                type='monotone'
                dataKey={line['name']}
                stroke={colors[index]}
                name={line['name']}
              />
            );
          })}
          <XAxis dataKey='name' />
          <YAxis />
          <Tooltip />
          <Legend />
        </LineChart>
      </div>
    </>
  );
}
