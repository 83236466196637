import * as React from 'react';
import OtherArticles from './other_articles';
import { shallow } from 'enzyme';

window.Routes = {
  dashboard_help: jest.fn(),
  employer_help: jest.fn(),
};

const otherArticlesProps = () => ({
  articles: [
    {
      id: '3',
      type: 'help_article',
      attributes: {
        id: 3,
        title: 'lkjsdalaks',
        body: null,
      },
    },
  ],
});

let props;
let wrapped;

beforeAll(() => {
  props = otherArticlesProps();
  wrapped = shallow(<OtherArticles {...props} />);
});

it('shallow renders without crashing', () => {
  expect(wrapped.exists()).toBe(true);
});
