import React, { useState } from 'react';
import classNames from 'classnames';

const styles = require('./SmallProfileDisplayItem.module.scss');

export interface DisplayProps {
  color?: string;
  header?: string | JSX.Element;
  isOther?: boolean;
  label?: string | JSX.Element;
  title: string;
}

export default function SmallProfileDisplayItem(props: DisplayProps) {
  const { header, title, isOther, label } = props;

  const color = props.color || 'tuftsBlue';
  const titleStyleArray = [styles.title, styles[color]];

  if (isOther) {
    titleStyleArray.push(styles.other);
  }

  const titleStyle = classNames(...titleStyleArray);

  return (
    <div className={styles.skillItem}>
      {header}
      <div className={titleStyle}>{title}</div>
      <div className={styles.label}>{label}</div>
    </div>
  );
}
