import classNames from 'classnames';
import Badge from 'components/general/badge/Badge';
import Icon, { Size } from 'components/general/icon/icon';
import SubscribeButton from 'components/subscribeButton/SubscribeButton';
import { isMobile } from 'lib/utils';
import React, { useContext } from 'react';
import { JobCardCtx } from './JobCardContext';
import JobDetails from './JobDetails';
const styles = require('./JobCard.module.scss');

const JobCard = () => {
  const jobCardContext = useContext(JobCardCtx);
  const isMobileDevice = isMobile();

  const handshakeBadge = () => {
    const days = jobCardContext?.handshakes?.[jobCardContext.job.id];

    return (
      <Badge color={days < 15 ? 'red' : 'blue'} className={styles.handshakesBadge}>
        <span>Handshake</span>
        <Icon name='timerOutline' size={Size.Small} color='white' />
        <span className={styles.noBold}> Expires in </span>
        <span>
          {days} day{days > 2 ? 's' : ''}
        </span>
      </Badge>
    );
  };

  const Badges = () => (
    <div className={styles.badgeContainer}>
      <div className={styles.badge}>
        {jobCardContext?.handshakes?.[jobCardContext.job.id] ? (
          handshakeBadge()
        ) : jobCardContext.applied ? (
          <Badge color='tuftsBlue' invertedFill={true}>
            <Icon name='checkThin' size={Size.QuiteSmall} color='tuftsBlue' />
            Applied
          </Badge>
        ) : (
          jobCardContext.job.isNew && <Badge color='green'>New</Badge>
        )}
      </div>
    </div>
  );

  const Logo = () => (
    <div className={styles.companyLogo}>
      <img
        loading="lazy"
        alt={`${jobCardContext.job.company.name} logo`}
        src={jobCardContext.job.company.logoUrl}
      />
    </div>
  );

  const ViewJobButton = () => (
    <a
      className={styles.viewJobButton + ' lj-button lj-button--mid lj-button--tuftsBlue'}
      target='_blank'
      href={window.Routes.company_job_ad(
        jobCardContext.job.company.slug || jobCardContext.job.company.id,
        jobCardContext.job.slug
      )}
      rel='noreferrer'
    >
      View Job
    </a>
  );

  const MoreInfo = () => (
    <>
      {jobCardContext.infoTags && (
        <div className={styles.moreInfoRow}>
          <div className={styles.moreInfoText}>
            <span className={styles.moreInfoIcon}>
              <Icon name='moreInfo' />
            </span>
            <span>More Info:</span>
          </div>
          {jobCardContext.infoTags.map((tag: string, i: number) => (
            <span key={i} className={styles.tag}>
              {tag}
            </span>
          ))}
        </div>
      )}
    </>
  );

  return (
    <div className={classNames(styles.jobCard, { paddingBottom: jobCardContext.infoTags?.length })}>
      <Badges />
      <Logo />
      <JobDetails />
      <div className={styles.buttonRow}>
        <ViewJobButton />
        <SubscribeButton
          bookmark={jobCardContext.bookmark}
          currentUser={jobCardContext.user}
          job={jobCardContext.job}
          style={styles.subscribeButton}
          size={isMobileDevice ? 'large' : 'small'}
        />
      </div>
      <MoreInfo />
    </div>
  );
};

export default JobCard;
