import * as React from 'react';
import './application_info.scss';

import { Application, ApplicationQuestion } from '../../__models__/models';
import ApplicationCard from './application_card/application_card';

import showdown from 'showdown';

export interface Props {
  application: Application;
  applicationQuestions: ApplicationQuestion[];
  dashboard?: boolean;
}

const ApplicationInfo = (props: Props) => {
  const plusIcon = require('iconic/plus.svg');
  const { text } = props.dashboard
    ? (props.application as any).data.attributes
    : props.application.attributes;
  const questions: ApplicationQuestion[] = props.dashboard
    ? (props.applicationQuestions as any).data
    : props.applicationQuestions;

  const converter = new showdown.Converter();
  converter.setOption('simpleLineBreaks', true);
  converter.setOption('strikethrough', true);

  if (!text && questions.length === 0) {
    return null;
  }

  return (
    <ApplicationCard
      icon={plusIcon}
      alt='plus'
      title='Additional Info'
      className='lj-application-info'
    >
      {text ? (
        <div className='lj-application-letter'>
          <div className='lj-heading--base'>Application Letter</div>
          <p className='lj-letter' dangerouslySetInnerHTML={{ __html: converter.makeHtml(text) }} />
        </div>
      ) : null}
      {questions.length > 0 ? (
        <div className='lj-application-questions'>
          <div className='lj-heading--base'>Answers to your questions</div>
          {questions.map((question: ApplicationQuestion) => {
            const { question_text, answer_text } = question.attributes;
            return (
              <div className='lj-application-question' key={question.id}>
                <div className='lj-question'>{question_text}</div>
                {answer_text ? (
                  <p
                    className='lj-answer'
                    dangerouslySetInnerHTML={{
                      __html: converter.makeHtml(answer_text),
                    }}
                  />
                ) : (
                  <p className='lj-application-empty-state'>
                    The candidate chose not to answer this question.
                  </p>
                )}
              </div>
            );
          })}
        </div>
      ) : null}
    </ApplicationCard>
  );
};

export default ApplicationInfo;
