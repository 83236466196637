import React from 'react';
import CompanySection from '../CompanySection';
import Icon from 'components/general/icon/icon';
import { officeAddressStreet, officeAdressLocation } from '../utils/OfficeUtils';

const styles = require('./Offices.module.scss');

export default function Offices({ offices }) {
  return (
    <CompanySection title='Offices'>
      <div className={styles.officesContainer}>
        {offices.map(office => (
          <CompanyOfficeCard key={office.id} office={office} />
        ))}
      </div>
    </CompanySection>
  );
}

function CompanyOfficeCard({ office }) {
  return (
    <div className={styles.officeItem}>
      {office.imageUrl && <img src={office.imageUrl} alt='office' />}
      <h3>{`${office.city}, ${office.country}`}</h3>
      {office.phoneNumber && (
        <div className={styles.phoneContainer}>
          <Icon name={'mobile'} />
          <span className={styles.inlineTextWithIcon}>{office.phoneNumber}</span>
        </div>
      )}
      <div className={styles.locationContainer}>
        <Icon name={'location'} />
        <span className={styles.inlineTextWithIcon}>{officeAddressStreet(office)}</span>
        <span className={styles.inlineText}>{officeAdressLocation(office)}</span>
      </div>
    </div>
  );
}
