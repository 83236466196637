interface FooterLink {
  key: string;
  title: string;
  path: string;
  img?: string;
}

interface SocialLink {
  name: string;
  url: string;
  icon: string;
}

const livroReclamacoes = require('../../images/livro_reclamacoes.png');

const footerLinksData: FooterLink[] = [
  {
    key: 'about',
    title: 'About Us',
    path: window.Routes?.about(),
  },
  {
    key: 'readingMaterial',
    title: 'Reading Material',
    path: window.Routes?.resources_download(),
  },
  {
    key: 'faq',
    title: 'Faq',
    path: window.Routes?.faq(),
  },
  {
    key: 'joinUs',
    title: 'Join Us',
    path: '/at/landing-jobs',
  },
  {
    key: 'privacy',
    title: 'Privacy',
    path: window.Routes?.privacy(),
  },
  {
    key: 'terms',
    title: 'Terms',
    path: window.Routes?.tos(),
  },
  {
    key: 'blog',
    title: 'Blog',
    path: 'https://landing.jobs/blog',
  },
  {
    key: 'thanks',
    title: 'Thanks',
    path: window.Routes?.thanks(),
  },
  {
    key: 'contacts',
    title: 'Contacts',
    path: window.Routes?.new_contact(),
  },
  {
    key: 'workRemotely',
    title: 'Work Remotely',
    path: window.Routes?.remoteworking(),
  },
  {
    key: 'pressKit',
    title: 'Press Kit',
    path: 'https://drive.google.com/open?id=1zhIwaq5nvGeFqo6ML80HC5ZK2StC5J8n',
  },
  {
    key: 'livroReclamacoes',
    title: '',
    path: 'https://www.livroreclamacoes.pt/Inicio/',
    img: livroReclamacoes,
  },
];

const socialLinksData: SocialLink[] = [
  {
    name: 'Facebook',
    icon: 'facebook',
    url: 'https://www.facebook.com/LandingJobsPage/',
  },
  {
    name: 'Instagram',
    icon: 'instagram',
    url: 'https://www.instagram.com/landing.jobs/',
  },
  {
    name: 'Twitter',
    icon: 'twitter',
    url: 'https://twitter.com/Landing_jobs/',
  },
  {
    name: 'Linkedin',
    icon: 'linkedin',
    url: 'https://www.linkedin.com/company/landing-jobs/',
  },
  {
    name: 'Github',
    icon: 'github',
    url: 'https://github.com/LandingJobs/',
  },
  {
    name: 'RSS',
    icon: 'rss',
    url: `${window.Routes?.feed()}`,
  },
];

export { footerLinksData, socialLinksData };
