import * as React from 'react';
import { ApolloProvider, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { apolloClient } from 'lib/graphql';

import Modal from 'components/general/modal/modal';
import TextInput from 'components/form/text/text';
import { ButtonsLayout } from '../general/modal/modal';
import Button from 'lj_shared/button/button';
import { addErrors, submitErrors, orReduce } from './tag_create_modal_helper';
import NameErrors from './name_errors';
import AlternateErrors from './alternate_errors';

interface Props {
  modalRef: any;
  name?: string;
  alternates?: string[];
  isEdit: boolean;
}
interface State {
  tagName: string;
  alternates: string[];
  nameIsInvalid: boolean;
  nameIsEmpty: boolean;
  nameIsLong: boolean;
  alternateIsInvalid: boolean;
  alternateIsEmpty: boolean;
  alternateIsRepeated: boolean;
  alternateIsLong: boolean;
  alternateIsName: boolean;
  nameExists: boolean;
}

const CREATE_GQL = gql`
  mutation ($name: String!, $alternates: [String!]) {
    createTag(name: $name, alternates: $alternates) {
      resource {
        id
        name
      }
      errors {
        field
        message
      }
    }
  }
`;

const EDIT_GQL = gql`
  mutation ($name: String!, $alternates: [String!], $oldName: String!) {
    editTag(name: $name, alternates: $alternates, oldName: $oldName) {
      resource {
        id
        name
      }
      errors {
        field
        message
      }
    }
  }
`;

export default class TagCreateModal extends React.Component<Props, State> {
  nameRef: any;
  alternateRef: any;

  constructor(props) {
    super(props);
    this.state = {
      tagName: props.isEdit ? props.name : '',
      alternates: props.isEdit ? props.alternates : [],
      nameIsInvalid: false,
      nameIsEmpty: false,
      nameIsLong: false,
      alternateIsInvalid: false,
      alternateIsEmpty: false,
      alternateIsRepeated: false,
      alternateIsLong: false,
      alternateIsName: false,
      nameExists: false,
    };
    this.nameRef = React.createRef();
    this.alternateRef = React.createRef();
  }

  handleAdd = (alternate: string) => {
    const errorObj = addErrors(alternate);

    const name = this.nameRef.current.value;

    const newstate = {
      ...errorObj,
      alternateIsRepeated: this.state.alternates
        .map(alt => alt.toUpperCase())
        .includes(alternate.toUpperCase()),
      alternateIsName: name.toUpperCase() === alternate.toUpperCase(),
    };

    this.setState(() => ({ ...newstate }));

    const hasError = orReduce(newstate);
    if (!hasError) {
      this.setState(() => ({
        alternates: [...this.state.alternates, alternate],
      }));
    }
  };

  renderAlternateList = () => {
    return (
      <div>
        {this.state.alternates &&
          this.state.alternates.map(element => this.renderAlternateElem(element))}
      </div>
    );
  };

  renderAlternateElem = (alt: string) => {
    return (
      <div key={alt}>
        {alt}
        <Button
          isButton={false}
          extraStyle={{ color: 'red', paddingLeft: '0.5rem' }}
          buttonColor='#FF2D00'
          buttonType='border'
          buttonSize='small'
          onClick={() => this.handleRejection(alt)}
        >
          x
        </Button>
      </div>
    );
  };

  handleRejection = (alt: string) => {
    this.setState(prevState => ({
      alternates: prevState.alternates.filter(elem => elem !== alt),
    }));
  };

  renderNewInput() {
    const nameObj = {
      nameRef: this.nameRef,
      isInvalid: this.state.nameIsInvalid,
      isEmpty: this.state.nameIsEmpty,
      isLong: this.state.nameIsLong,
      exists: this.state.nameExists,
    };
    const altObj = {
      alternateRef: this.alternateRef,
      isInvalid: this.state.alternateIsInvalid,
      isEmpty: this.state.alternateIsEmpty,
      isLong: this.state.alternateIsLong,
      isRepeated: this.state.alternateIsRepeated,
      isName: this.state.alternateIsName,
    };

    return (
      <div>
        <TextInput
          value={this.state.tagName}
          inputRef={this.nameRef}
          label='Skill new name'
          name='tag_name'
          placeholder='E.g. JavaScript'
        />
        <NameErrors {...nameObj} />
        <TextInput
          value={null}
          inputRef={this.alternateRef}
          label='Alternates (optional)'
          name='alternate_name'
          placeholder="E.g. 'JS' is an Alternate to 'JavaScript"
        />
        <Button
          isButton={false}
          buttonColor='#0058cc'
          buttonType='border'
          buttonSize='small'
          extraStyle={{ paddingLeft: '0.1em' }}
          onClick={() => this.handleAdd(this.alternateRef.current.value)}
        >
          Add Alternate
        </Button>
        <AlternateErrors {...altObj} />
        {this.renderAlternateList()}
      </div>
    );
  }

  render() {
    return (
      <ApolloProvider client={apolloClient}>
        <Mutation
          mutation={this.props.isEdit ? EDIT_GQL : CREATE_GQL}
          // eslint-disable-next-line react/no-children-prop
          children={this.renderModal}
          onCompleted={this.handleClose}
        />
      </ApolloProvider>
    );
  }

  renderModal = (mutate, { loading = false }) => {
    return (
      <Modal
        title={this.props.isEdit === true ? `Edit '${this.props.name}'` : 'Create Tag'}
        defaultOpen={false}
        ref={this.props.modalRef}
        buttonName={loading ? 'Accepting...' : 'Accept'}
        cancelButton={true}
        buttonColor='tuftsBlue'
        onClose={e => this.handleClose()}
        buttonOnClick={e => this.handleSubmit(mutate)(e)}
        buttonsLayout={ButtonsLayout.CancelOk}
      >
        {this.renderNewInput()}
      </Modal>
    );
  };

  handleSubmit = mutation => event => {
    event.preventDefault();

    const tagName = this.nameRef.current.value;
    const errorObj = submitErrors(tagName);
    const hasError = orReduce(errorObj);

    const newstate = {
      ...errorObj,
      nameExists: false,
    };

    this.setState(() => ({ ...newstate }));

    if (hasError) {
      return;
    }

    const variables = this.props.isEdit
      ? {
          name: tagName,
          alternates: this.state.alternates,
          oldName: this.props.name,
        }
      : {
          name: tagName,
          alternates: this.state.alternates,
        };

    this.handleMutation(mutation, variables);
  };

  handleMutation = (mutation, variables) => {
    mutation({ variables }).then(response => {
      if (response === null || response.data === null || response.data.createTag === null) {
        // Uncontrolled error
        window.Alerts.alert('Oops, something went wrong.');
        this.props.modalRef.current.close();
      } else {
        const tagResponse = this.props.isEdit ? response.data.editTag : response.data.createTag;
        this.mutationHelper(tagResponse);
      }
    });
  };

  mutationHelper = tagResponse => {
    if (tagResponse.errors != null && tagResponse.errors.length !== 0) {
      // Controlled error
      if (tagResponse.resource !== null) {
        this.setState({ nameExists: true });
      } else {
        window.Alerts.alert(tagResponse.errors[0].message);
        this.props.modalRef.current.close();
      }
    } else {
      const Tag = tagResponse.resource;
      window.Alerts.notice(
        `Tag: "${Tag.name}" ${this.props.isEdit ? 'edited' : 'created'}  successfully`
      );
      this.props.modalRef.current.close();
      window.location.reload();
    }
  };

  handleClose = () => {
    this.setState(() => ({
      nameIsInvalid: false,
      nameIsEmpty: false,
      nameIsLong: false,
      alternateIsInvalid: false,
      alternateIsEmpty: false,
      alternateIsRepeated: false,
      alternateIsLong: false,
      alternateIsName: false,
      nameExists: false,
      alternates: this.props.alternates,
    }));
  };
}
