import React from 'react';

import 'lib/globals';
import BillingInfo, { BillingInfoProps } from './billing_info';
import Icon from 'components/general/icon/icon';
import Button from 'lj_shared/button/button';

const styles = require('./invoice_page.module.scss');

interface Props {
  billingInfo?: BillingInfoProps;
  companyName: string;
  documentUrl: string;
  endDate: string;
  invoiceMonth: string;
  placementId: string;
  projectName: string;
  rejectionReason?: string;
  startDate: string;
  notes: string;
  number: string;
  valueWithVat: string;
  valueWithoutVat: string;
  workedDays: number;
}

export default function InvoicePage(props: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <div className={styles.projectsLinkContainer}>
          <Icon name='arrowLeft' />
          <a
            className={styles.projectsLink}
            href={window.Routes.dashboard_placement(props.placementId)}
          >
            Project
          </a>
        </div>
        {renderPlacement(props)}
        {renderInvoice(props)}
      </div>
      <div className={styles.sideBar}>
        <BillingInfo projectId={props.placementId} {...props.billingInfo} />
      </div>
    </div>
  );
}

function renderPlacement(props) {
  return (
    <div className={styles.project}>
      <div className={styles.projectHeader}>
        <span className={styles.projectName}>{props.projectName}</span> -{' '}
        <span className={styles.companyName}>{props.companyName}</span>
      </div>
      <div className={styles.invoiceDate}>{props.invoiceMonth} - Invoice</div>
    </div>
  );
}

function renderInvoice(props) {
  return (
    <div className={styles.invoice}>
      <InvoiceLine label='Dates'>
        <span>
          <span className={styles.date}>{props.startDate}</span> -{' '}
          <span className={styles.date}>{props.endDate}</span>
        </span>
      </InvoiceLine>
      <InvoiceLine label='Worked days'>{props.workedDays}</InvoiceLine>
      <InvoiceLine label='Invoice number'>{props.number}</InvoiceLine>
      <InvoiceLine label='Invoice document - PDF'>
        {' '}
        <a href={props.documentUrl} target='_blank' rel='noreferrer'>
          Open PDF
        </a>
      </InvoiceLine>
      <InvoiceLine label='Total value without VAT'>{props.valueWithoutVat}</InvoiceLine>
      <InvoiceLine label='Total value with VAT'>{props.valueWithVat}</InvoiceLine>
      <InvoiceLine label='Notes'>{props.notes}</InvoiceLine>
      {props.rejectionReason && <RejectionBox {...props} />}
    </div>
  );
}

function InvoiceLine({ children, label }) {
  return (
    <div className={styles.invoiceLine}>
      <span className={styles.invoiceLineLabel}>{label}</span>
      <span>{children}</span>
    </div>
  );
}

function RejectionBox({ rejectionReason, placementId }) {
  return (
    <div className={styles.rejectionBox}>
      <div className={styles.rejectionReason}>
        <span className={styles.rejectionReasonText}>Rejection Reason: </span>
        {rejectionReason}
      </div>
      <div className={styles.newInvoiceText}>Please submit a new invoice.</div>
      <Button
        buttonColor='tuftsBlue'
        buttonSize='small'
        buttonUrl={window.Routes.new_dashboard_placement_invoice(placementId)}
        otherClasses={styles.newInvoiceButton}
      >
        Report new invoice
      </Button>
    </div>
  );
}
