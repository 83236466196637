import * as React from 'react';
import RejectionModal from './rejection_modal';
import { Application } from '../../__models__/models';
import { mount } from 'enzyme';
import applicationDummy from '__fixtures__/application_dummy';
import rejectionDimensionsMapping from '__fixtures__/rejection_dimensions_mapping';

let wrapped;
window.scrollTo = jest.fn();

describe('Rejection Modal component', () => {
  beforeAll(() => {
    wrapped = mount(
      <RejectionModal
        application={applicationDummy}
        rejectionUrl=''
        rejectApplication={jest.fn()}
        rejectionDimensionsMapping={rejectionDimensionsMapping}
      />
    );
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
