import React from 'react';
import TechnicalTestInformation from './technical_test_information';
import Modal from '../../general/modal/modal';
import { shallow, ShallowWrapper } from 'enzyme';

let wrapped: ShallowWrapper;

describe('Test Notice Modal component', () => {
  wrapped = shallow(<TechnicalTestInformation isModal={true} />);

  it('renders correctly', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('to be wrapped in a Modal component', () => {
    expect(wrapped.find(Modal)).toHaveLength(1);
  });
});
