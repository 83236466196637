import React from 'react';
import SmallProfileDisplayList from '../../../smallProfileDisplay/SmallProfileDisplayList';

const styles = require('./CandidateSkillsDisplay.module.scss');
const Settings = require('settings.json');

interface Skill {
  experienceLevel: number;
  id: number;
  tag: string;
}

interface Props {
  color: string;
  skills: Skill[];
}

export default function CandidateSkillsDisplay(props: Props) {
  const skills = Object.values(props.skills).map(({ experienceLevel, tag }: Skill) => ({
    title: tag,
    label: Settings.experienceYears[experienceLevel]?.[0] || '',
  }));

  const renderTopSkills = () => (
    <div className={styles.skillsBlock}>
      <SmallProfileDisplayList color={props.color} skills={skills} />
    </div>
  );

  return <div className={styles.skillsSection}>{skills.length !== 0 && renderTopSkills()}</div>;
}
