import gql from 'graphql-tag';
export const settings = require('settings.json');

export const GET_EXPERIENCE_RATING_AND_NOTES_GQL = gql`
  query (
    $backgroundYears: Int
    $backgroundYearsLabel: String
    $jobId: Int!
    $noRelevantBackground: Boolean!
    $sameRolePerformed: Boolean!
  ) {
    experienceRatingAndNotesComputation(
      backgroundYears: $backgroundYears
      backgroundYearsLabel: $backgroundYearsLabel
      jobId: $jobId
      noRelevantBackground: $noRelevantBackground
      sameRolePerformed: $sameRolePerformed
    ) {
      rating
      notes
    }
  }
`;

export const resolveExperienceVariables = (dataValues, jobId) => {
  let backgroundYearsLabel;
  let backgroundYears;
  const sameRolePerformed = dataValues['same_role_performed'] || false;
  if (dataValues['background_years']) {
    backgroundYearsLabel = settings.experienceYears[dataValues['background_years']][0];
    backgroundYears = parseInt(dataValues['background_years'] as string, 10);
  }
  const noRelevantBackground = dataValues['no_relevant_background'] || false;
  const variables = {
    backgroundYears,
    backgroundYearsLabel,
    jobId,
    noRelevantBackground,
    sameRolePerformed,
  };

  return variables;
};
