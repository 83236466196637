import * as React from 'react';
import ExperienceDimension, { Props } from './experience_dimension';
import { shallow, ShallowWrapper } from 'enzyme';
import Checkbox from '../form/checkbox/checkbox';

let wrapped: ShallowWrapper;

describe('Experience dimension component', () => {
  beforeEach(() => {
    wrapped = shallow(
      <ExperienceDimension
        value={3}
        ratingRef={'ratingRef'}
        handleChange={jest.fn()}
        backgroundYearsDefaultValue={false}
        handleChangeBackgroundYears={jest.fn()}
        sameRoleDefaultValue={false}
        handleChangeSameRole={jest.fn()}
        backgroundYearsRef={'backgroundYearsRef'}
        backgroundYearsValue={5}
        handleNoRelevantBackground={jest.fn()}
        noRelevantBackgroundValue={true}
      />
    );
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('it has 3 checkboxs', () => {
    expect(wrapped.find(Checkbox).length).toEqual(3);
  });
});

test.todo('add more tests');
