import * as React from 'react';
import { shallow } from 'enzyme';
import AlertBox from './alert_box';

describe('<AlertBox />', () => {
  (window as any).IconicJS = function () {
    this.inject = _params => true;
    return this;
  };
  const component = shallow(
    <AlertBox>
      <p>Hey!</p>
    </AlertBox>
  );

  it('renders without crashing', () => {
    expect(component).toMatchSnapshot();
  });

  it('renders the children', () => {
    const expectedOutput = '<p>Hey!</p>';
    const output = component.find('p').html();

    expect(output).toEqual(expectedOutput);
  });

  it('renders the bullhorn', () => {
    const expectedOutput = '<img src="" class="iconic iconic-md iconic-bullhorn iconic"/>';
    const output = component.find('img').html();

    expect(output).toEqual(expectedOutput);
  });
});
