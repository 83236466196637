import React, { useState, useRef, useEffect } from 'react';
import { capitalize } from 'lodash/string';
import { camelize, snakeCase, titleize } from 'lib/string';
import classNames from 'classnames';
import { CompanyData } from '__models__/gql/company_data';
import Sidebar from 'components/general/sidebar/sidebar';
import TextInput from 'components/form/text/text';
import Button from 'lj_shared/button/button';
import Icon, { Size, Style } from 'components/general/icon/icon';
import Checkbox from 'components/form/checkbox/checkbox';
import { postJSON, handleRequestError } from 'lib/request_deprecated';
import Accordion from 'components/general/accordion/accordion';
import { isMobile } from 'react-device-detect';
import { gql, apolloClient } from 'lib/graphql';
import Modal from 'components/general/modal/modal';
import RadioButtons from 'components/form/radio_buttons/radio_buttons';
import AverageDurationForJobs from './AverageDurationForJobs';
import track from 'lib/drip';
import SettingsExportModal from './SettingsExportModal';
import Select from 'components/form/select/select';

const styles = require('./DataForCompanies.module.scss');

interface Props {
  company: any;
  notification_preferences: any;
}

interface DurationArgs {
  hsm: any[];
  toi: any[];
}

enum Tabs {
  GeneralData,
  FunnelByJob,
  ProcessDurationByJob,
}

const headerTabs = ['general data', 'funnel by job', 'process duration by job'];

export default function DataForCompanies(props: Props) {
  const [activeTab, setActiveTab] = useState(Tabs.GeneralData);
  const [jobAds, setJobAds] = useState(null);
  const [longAgo, setLongAgo] = useState(null);
  const [companyData, setCompanyData] = useState<CompanyData>(null);
  const [useful, setUseful] = useState(null);
  const [comments, setComments] = useState(null);
  const [thumbUp, setThumbUp] = useState(false);
  const [thumbDown, setThumbDown] = useState(false);
  const [sending, setSending] = useState(false);
  const company = companyData?.company;
  const overall = companyData?.overall;
  const companyDaysToHire = company?.daysToHire;
  const companyDaysToOffer = company?.daysToOffer;
  const companyDaysToReject = company?.daysToReject;
  const overallDaysToHire = overall?.daysToHire;
  const overallDaysToOffer = overall?.daysToOffer;
  const overallDaysToReject = overall?.daysToReject;
  const companyDaysToReview = company?.daysToReview;
  const companyDaysToEngage = company?.daysToEngage;
  const overallDaysToReview = overall?.daysToReview;
  const overallDaysToEngage = overall?.daysToEngage;
  const myFunnel = companyData?.funnel;
  const curated = companyData?.curatedApplications;
  const employerReviewing = companyData?.employerReviewing;
  const handshakeApplications = employerReviewing?.handshakeApplications;
  const reviewing = companyData && [['Handshake Applications', handshakeApplications]];
  const engagement = companyData?.platformEngagementLevel;
  const companyEngagement = (engagement?.company * 100).toFixed(0) + '%';
  const overallEngagement = (engagement?.overall * 100).toFixed(0) + '%';
  const exportOptions = [
    { name: 'General Data', show_deleted: 0 },
    { name: 'Funnel by job', show_deleted: 1 },
    { name: 'Process duration by job', show_deleted: 1 },
  ];
  const exportToOptions = [
    { value: 1, label: 'PDF' },
    { value: 2, label: 'CSV' },
  ];
  const [exportWhat, setExportWhat] = useState([exportOptions[0].name]);
  const [exportDeleted, setExportDeleted] = useState([false, false]);
  const [exportTo, setExportTo] = useState(exportToOptions[0].value);
  const commentsRef = useRef(null);
  const modalRef = useRef(null);
  const settingsModalRef = useRef(null);
  const dateMonthsAgo = monthsAgo => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const todayDate = new Date();
    const goToDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);

    goToDate.setMonth(goToDate.getMonth() - monthsAgo);

    return `${months[goToDate.getMonth()]} ${goToDate.getFullYear()}`;
  };
  const filterItems = [
    { id: 1, label: dateMonthsAgo(0), value: 0 },
    { id: 2, label: dateMonthsAgo(1), value: 1 },
    { id: 3, label: dateMonthsAgo(2), value: 2 },
    { id: 4, label: dateMonthsAgo(3), value: 3 },
    { id: 5, label: dateMonthsAgo(4), value: 4 },
    { id: 6, label: dateMonthsAgo(5), value: 5 },
    { id: 7, label: dateMonthsAgo(6), value: 6 },
    { id: 8, label: dateMonthsAgo(7), value: 7 },
    { id: 9, label: dateMonthsAgo(8), value: 8 },
    { id: 10, label: dateMonthsAgo(9), value: 9 },
    { id: 11, label: dateMonthsAgo(10), value: 10 },
    { id: 12, label: dateMonthsAgo(11), value: 11 },
    { id: 13, label: 'Last 365 days', value: 12 },
  ];

  const activeJobs = jobAds?.filter(jobAd => jobAd.closedAt === null);
  const closedJobs = jobAds?.filter(jobAd => jobAd.closedAt !== null);

  useEffect(() => {
    handleChange(filterItems[filterItems.length - 1]).catch(handleRequestError);
  }, []);

  const handleUpDown = direction => {
    if (direction === 'up') {
      if (!thumbUp) {
        setThumbUp(true);
      }
      setThumbDown(false);
      setUseful(true);
    } else if (direction === 'down') {
      if (!thumbDown) {
        setThumbDown(true);
      }
      setThumbUp(false);
      setUseful(false);
    }
  };

  const handleChangeExportToModal = (value, name) => {
    setExportTo(value);
  };

  const handleChangeExportModal = () => {
    const exportValues = Array.from(document.querySelectorAll('[name="export_form[values][]"]'))
      .filter((element: HTMLInputElement) => element.checked)
      .map((element: HTMLInputElement) => element.value);
    const deletedValues = Array.from(document.querySelectorAll('[name="deleted[values][]"]')).map(
      (element: HTMLInputElement) => element.checked
    );
    setExportWhat(exportValues);
    setExportDeleted(deletedValues);
  };

  const handleCommentChange = event => {
    setComments(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    setSending(true);

    if (comments || useful) {
      postJSON(window.Routes.company_feedbacks_url(), {
        comments,
        useful,
      })
        .then(e => {
          if (e.notice) {
            window.Alerts.notice(e.notice);
            setComments(null);
            setUseful(null);
            setThumbDown(false);
            setThumbUp(false);
          } else {
            window.Alerts.alert(e.alert);
          }
          setSending(false);
        })
        .then(() => (commentsRef.current.value = null))
        .catch(handleRequestError);
    } else {
      window.alert('Please complete your feedback before submitting');
      setSending(false);
    }
  };

  const handleChange = date => {
    if (date && date.value != null) {
      window.toggleSpinner(true);
      return apolloClient
        .query({
          query: GQL_QUERY_DATE,
          variables: { company_id: props.company.id, months_ago: date.value },
        })
        .then(result => {
          setCompanyData(result.data.companyData);
          setLongAgo(date);
          setJobAds(result.data.companyData.jobs);
        })
        .then(() => window.toggleSpinner(false));
    }
    return null;
  };

  const openExportModal = () => {
    modalRef.current.open();
  };

  const openExportSettingsModal = () => {
    settingsModalRef.current.open();
  };

  const exportEverything = () => {
    if (exportWhat.length > 0) {
      if (exportTo === 1) {
        track('action', {
          name: 'employer-data-download',
          controller: 'data_for_companies',
          action: 'download-pdf',
        });
        const exportParams = {};
        exportWhat.forEach(exportOption => {
          if (exportOption === 'General Data') {
            exportParams[exportOption] = { months_ago: longAgo.value };
          }
          if (exportOption === 'Funnel by job') {
            exportParams[exportOption] = {
              months_ago: longAgo.value,
              include_deleted: `${exportDeleted[0]}`,
            };
          }
          if (exportOption === 'Process duration by job') {
            exportParams[exportOption] = {
              months_ago: longAgo.value,
              include_deleted: `${exportDeleted[1]}`,
            };
          }
        });
        window.open(window.Routes.download_data_pdf_employer_url({ exportParams }));
      } else if (exportTo === 2) {
        track('action', {
          name: 'employer-data-download',
          controller: 'data_for_companies',
          action: 'download-csv',
        });

        exportWhat.forEach(exportOption => {
          if (exportOption === 'General Data') {
            window.open(
              window.Routes.download_general_data_csv_employer_url({
                months_ago: longAgo.value,
              })
            );
          }
          if (exportOption === 'Funnel by job') {
            window.open(
              window.Routes.download_funnel_data_csv_employer_url({
                months_ago: longAgo.value,
                include_deleted: `${exportDeleted[0]}`,
              })
            );
          }
          if (exportOption === 'Process duration by job') {
            window.open(
              window.Routes.download_process_data_csv_employer_url({
                months_ago: longAgo.value,
                include_deleted: `${exportDeleted[1]}`,
              })
            );
          }
        });
      }
      modalRef.current.close();
    } else {
      window.Alerts.alert('Please select any data to export');
    }
    return null;
  };

  const renderTab = tab => {
    switch (tab) {
      case 'generalData':
        setActiveTab(Tabs.GeneralData);
        break;
      case 'funnelByJob':
        setActiveTab(Tabs.FunnelByJob);
        break;
      case 'processDurationByJob':
        setActiveTab(Tabs.ProcessDurationByJob);
        break;
      default:
        setActiveTab(Tabs.GeneralData);
    }
  };

  const alternatieTabName = tabName => camelize(snakeCase(tabName));

  function Header() {
    return (
      <div className={styles.topBar}>
        {headerTabs.map(tabName => (
          <div
            key={tabName}
            className={classNames(
              styles.barTab,
              Tabs[activeTab] === titleize(alternatieTabName(tabName)) && styles.activeBarTab
            )}
            onClick={() => renderTab(alternatieTabName(tabName))}
          >
            {tabName}
          </div>
        ))}
      </div>
    );
  }

  function InfoIcon() {
    return <div className={styles.infoIcon}>i</div>;
  }

  function CheckIcon() {
    return (
      <div className={styles.checkIcon}>
        <Icon name='check' color='white' size={Size.QuiteSmall} />
      </div>
    );
  }

  const OpenFunnel = (vars: { deleted }) => {
    const { deleted } = vars;
    return (
      <div className={styles.funnelIcon}>
        <Icon name='chevronDown' color={deleted ? 'black' : 'white'} size={Size.Small} />
      </div>
    );
  };

  const CloseFunnel = (vars: { deleted }) => {
    const { deleted } = vars;
    return (
      <div className={styles.funnelIcon}>
        <Icon name='chevronUp' color={deleted ? 'black' : 'white'} size={Size.Small} />
      </div>
    );
  };

  function Side() {
    return (
      <div className={styles.sidebarContainer}>
        <Sidebar userType='CompanyUser' active='data' />
      </div>
    );
  }

  function renderEngagement() {
    let lastUpdate = engagement.lastUpdate;
    if (lastUpdate) {
      lastUpdate = new Date(lastUpdate).toLocaleString('en-GB').slice(0, 10);
    }
    return (
      <>
        <div className={styles.attentionCard}>
          <div className={styles.attentionLeft}>
            <div className={`${styles.attentionSubtitle} ${styles.purpleTitle}`}>
              <Icon name='warning' color='ripePlumDark' size={Size.Big} style={Style.Thick} />
              <span className={styles.attentionSpan}>Attention</span>
            </div>
            <div className={`${styles.attentionText} ${styles.purpleTitle}`}>
              This level of engagement impacts the results on searches and may have impacts on the
              curated apps you receive.
            </div>
            <div className={styles.attentionMetrics}>
              Your platform engagement level
              <div className={styles.metricsBar}>
                <div className={`${styles.bothBars} ${styles.backgroundBar}`} />
                <div
                  className={`${styles.bothBars} ${styles.foregroundBar}`}
                  style={{ width: companyEngagement }}
                >
                  {companyEngagement}
                </div>
              </div>
              Market overall
              <div className={styles.metricsBar}>
                <div className={`${styles.bothBars} ${styles.backgroundBar}`} />
                <div
                  className={`${styles.bothBars} ${styles.foregroundBar}`}
                  style={{ width: overallEngagement }}
                >
                  {overallEngagement}
                </div>
              </div>
            </div>
            {lastUpdate && (!longAgo || longAgo.value === 12) && (
              <span>Last update {lastUpdate}</span>
            )}
          </div>
          <div className={styles.attentionRight}>
            <div className={styles.attentionSubtitle}>
              How to increase my engagement level on the platform?
            </div>
            <div className={styles.accordionList}>
              <div>
                <CheckIcon />
                Move applications to In Process
              </div>
              <div>
                <CheckIcon />
                Move applications between steps
              </div>
              <div>
                <CheckIcon />
                Reject Applications
              </div>
              <div>
                <CheckIcon />
                See applications
              </div>
              <div>
                <CheckIcon />
                Download CV’s
              </div>
              <div>
                <CheckIcon />
                Download applications’ PDF’s
              </div>
              <div>
                <CheckIcon />
                Share applications by E-mail
              </div>
              <div>
                <CheckIcon />
                Send Messages
              </div>
              <div>
                <CheckIcon />
                Request Handshakes
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  function funnelList(cards, percentages, deleted = null, jobAttributes = null) {
    return (
      <>
        <div className={styles.funnelCardsList}>
          {cards.map((card, index) => {
            const colorStyles = deleted
              ? `${styles[`greyColor${index}`]}`
              : `${styles[`color${index}`]}`;
            const borderStyles = deleted
              ? `${styles[`greyBorder${index}`]}`
              : `${styles[`border${index}`]}`;
            return (
              <div className={styles.funnelCard} key={index}>
                {card[2] ? (
                  <Accordion
                    title={card[0]}
                    headerClassName={`${styles.colorCardAccordion} ${styles.colorCard} ${colorStyles}`}
                    headerStyle={{ zIndex: 19 - 3 * index }}
                    openIcon={<OpenFunnel deleted={deleted} />}
                    closeIcon={<CloseFunnel deleted={deleted} />}
                    filterList={true}
                    innerClassName={styles.accordionOpen}
                  >
                    <div
                      className={`${styles.funnelAccordion} ${borderStyles}`}
                      style={{ zIndex: 18 - 3 * index }}
                    >
                      {card[2].map((line, num) => {
                        return (
                          <div className={styles.innerFunnelAccordion} key={`line${num}`}>
                            <div>{line[1]}</div>
                            <div>{capitalize(line[0])}</div>
                          </div>
                        );
                      })}
                    </div>
                  </Accordion>
                ) : (
                  <div
                    className={`${styles.colorCard} ${colorStyles}`}
                    style={{ zIndex: 19 - 3 * index }}
                  >
                    <span>{card[0]}</span>
                  </div>
                )}
                <div className={`${styles.numberCard}`}>{card[1]}</div>
              </div>
            );
          })}
        </div>
        {(!longAgo || longAgo.value === 12) && (
          <div className={styles.percentageList}>
            {percentages.map((perc, index) => {
              return (
                <div
                  className={styles.percentageCard}
                  key={`perc-${index}`}
                  style={isMobile ? { zIndex: 17 - 3 * index } : {}}
                >
                  <span>{perc[0]}</span>
                </div>
              );
            })}
          </div>
        )}
        {jobAttributes.job && (
          <div className={styles.hiringStepText}>
            {jobAttributes.jobData.hiringStepMovements.map(move => {
              if (move.title) {
                return (
                  // eslint-disable-next-line react/jsx-key
                  <div className={styles.hiringStep}>
                    <span className={styles.purpleNumber}>{move.countMoving}</span> applications
                    moved into <span>{move.title}</span>.{' '}
                    <span className={styles.purpleNumber}>{move.countTotal}</span> applications
                    currently on this step.
                  </div>
                );
              }
            })}
          </div>
        )}
      </>
    );
  }

  function renderFunnelByJob() {
    return (
      <>
        {activeJobs.map(job => {
          return renderFunnel(job);
        })}
        {closedJobs && closedJobs.length > 0 && (
          <Accordion
            defaultOpened={true}
            title={'Closed job ads'}
            headerClassName={styles.hideClosedJobs}
            filterList
          >
            {closedJobs.map(job => {
              return renderFunnel(job, true);
            })}
          </Accordion>
        )}
      </>
    );
  }

  function renderFunnel(job = null, deleted = false) {
    let funnel = [];
    let percentages = [];
    let jobData = null;

    if (job) {
      jobData = companyData.dataByJob.filter(funnelJob => funnelJob.funnel.jobId === job.id)[0];

      if (!jobData) {
        return;
      }

      funnel = [
        ['Job views', jobData.funnel.jobViews || '-'],
        ['Submitted Applications', jobData.funnel.applicationsSubmitted || '-'],
        [
          'Curated Applications',
          jobData.funnel.curatedApplications || '-',
          jobData.curatedApplications.length !== 0 && jobData.curatedApplications,
        ],
        [
          'Employer Reviewing',
          jobData.funnel.employerReviewing || '-',
          jobData.employerReviewing.handshakeApplications && [
            ['Handshake Applications', jobData.employerReviewing.handshakeApplications],
          ],
        ],
        ['Engaged', jobData.funnel.engaged || '-'],
        ['Offer Made', jobData.funnel.offerMade || '-'],
        ['Hired', jobData.funnel.hired || '-'],
      ];
      percentages = [
        [jobData.funnel.toApplicationsSubmitted],
        [jobData.funnel.toCuratedApplications],
        [jobData.funnel.toEmployerReviewing],
        [jobData.funnel.toEngaged],
        [jobData.funnel.toOfferMade],
        [jobData.funnel.toHired],
      ];
    } else {
      funnel = [
        ['Job views', myFunnel.jobViews || '-'],
        ['Submitted Applications', myFunnel.applicationsSubmitted || '-'],
        [
          'Curated Applications',
          myFunnel.curatedApplications || '-',
          curated.length !== 0 && curated,
        ],
        ['Employer Reviewing', myFunnel.employerReviewing || '-', reviewing[0][1] && reviewing],
        ['Engaged', myFunnel.engaged || '-'],
        ['Offer Made', myFunnel.offerMade || '-'],
        ['Hired', myFunnel.hired || '-'],
      ];
      percentages = [
        [companyData.funnel.toApplicationsSubmitted],
        [companyData.funnel.toCuratedApplications],
        [companyData.funnel.toEmployerReviewing],
        [companyData.funnel.toEngaged],
        [companyData.funnel.toOfferMade],
        [companyData.funnel.toHired],
      ];
    }
    const jobAttributes = { job, jobData };

    return (
      <>
        {job && (
          <div className={styles.jobAdTitle} key={`${job.title}${job.id}`}>
            {job.title}
          </div>
        )}
        <div className={styles.cardBottom}>
          <div className={styles.funnelCards}>
            {funnelList(funnel, percentages, deleted, jobAttributes)}
          </div>
        </div>
        {!job && (
          <>
            <div className={styles.greyInfo}>
              These are the total numbers for each stage in the selected time period. The conversion
              rates are not calculated by month and will therefore only show the yearly value.
            </div>
            <div>
              <div>
                <div className={styles.info}>
                  <InfoIcon />
                  <span />
                  Are you happy with the reach your jobs are gettting? If not, make sure you check
                  if your job description is clear and has enough call to actions. You can always
                  get some ideas from your AM.
                </div>
                <div className={styles.info}>
                  <InfoIcon />
                  <span />
                  Make sure you are engaged with the platform to guarantee that you get as many
                  curated applications as possible.
                </div>
                <div className={styles.info}>
                  <InfoIcon />
                  <span />
                  Is your job description attracting the right talent? How is the inbox breakdown
                  looking? Do you have few candidates on 'Meet Requirements'? Make sure you're
                  aligned with your Account Manager and consider leaving some notes for curation.
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }

  function cardList(color, border, cards) {
    const style = border
      ? `${styles.innerCard} ${styles.borderCard}`
      : `${styles.innerCard} ${styles[`${color}`]}`;
    return (
      <div className={styles.cardsList}>
        {cards.map(card => {
          return (
            <div className={style} key={`${card[0]}-${border}`}>
              <div>
                <div className={styles.cardTitle}>{card[0]}</div>
                <div className={styles.cardNumber}>{card[1]}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  function averageDuration(args: DurationArgs = null, isGeneral = true, isDeleted = true) {
    const hsm = args?.hsm || [
      ['Days to Hire', Math.round(companyDaysToHire.average * 10) / 10 || '-'],
      ['Days to Offer', Math.round(companyDaysToOffer.average * 10) / 10 || '-'],
      ['Days to Reject', Math.round(companyDaysToReject.average * 10) / 10 || '-'],
    ];
    const hsmavg = [
      ['Days to Hire', Math.round(overallDaysToHire.average * 10) / 10],
      ['Days to Offer', Math.round(overallDaysToOffer.average * 10) / 10],
      ['Days to Reject', Math.round(overallDaysToReject.average * 10) / 10],
    ];
    const toi = args?.toi || [
      ['Days to Review', Math.round(companyDaysToReview.average * 10) / 10 || '-'],
      ['Days to Engage', Math.round(companyDaysToEngage.average * 10) / 10 || '-'],
    ];
    const toiavg = [
      ['Days to Review', Math.round(overallDaysToReview.average * 10) / 10],
      ['Days to Engage', Math.round(overallDaysToEngage.average * 10) / 10],
    ];
    return (
      <>
        <div className={styles.cardBottom}>
          <div className={styles.metricsCards}>
            <span className={styles.boldTitle}>Hiring Speed Metrics</span>
            {cardList(
              !isGeneral && isDeleted ? 'silverSand' : 'ripePlum',
              !isGeneral && isDeleted,
              hsm
            )}
            {isGeneral && (
              <>
                Market Overall
                {cardList('silverSand', true, hsmavg)}
              </>
            )}
          </div>
          <div className={styles.timeCards}>
            <span className={styles.boldTitle}>Time on Inbox</span>
            {cardList(
              !isGeneral && isDeleted ? 'silverSand' : 'puertoRico',
              !isGeneral && isDeleted,
              toi
            )}
            {isGeneral && (
              <>
                Market Overall
                {cardList('silverSand', true, toiavg)}
              </>
            )}
          </div>
        </div>
        {!args && (
          <div className={styles.info}>
            <InfoIcon />
            <span />
            Did you know that a lengthy recruitment process is among the top reasons for candidate
            disengagement? The longer your process is, the less opportunity for success. We know
            it's hard to keep the processes short but bare in mind that candidate experience is key.
            Make sure you give feedback to your candidates, ideally on a weekly basis.
          </div>
        )}
      </>
    );
  }

  function engagementHeader() {
    return (
      <div className={classNames(styles.dataCard, styles.card)}>
        {isMobile ? (
          <Accordion
            title='Platform Engagement Level'
            openIcon={<OpenIcon />}
            closeIcon={<CloseIcon />}
            filterList={true}
            headerClassName={styles.cardTop}
          >
            {renderEngagement()}
          </Accordion>
        ) : (
          <div className={styles.cardTop}>Platform Engagement Level</div>
        )}
        {!isMobile && renderEngagement()}
      </div>
    );
  }

  function averageDurationHeader(child: any = averageDuration()) {
    return (
      <div className={classNames(styles.dataCard, styles.card, styles.firstCard)}>
        {isMobile ? (
          <Accordion
            title='Average Process Duration'
            openIcon={<OpenIcon />}
            closeIcon={<CloseIcon />}
            filterList={true}
            defaultOpened={true}
            headerClassName={styles.cardTop}
          >
            {child}
          </Accordion>
        ) : (
          <div className={styles.cardTop}>Average Process Duration</div>
        )}
        {!isMobile && child}
      </div>
    );
  }

  function funnelHeader(tab) {
    return (
      <div className={classNames(styles.dataCard, styles.card)}>
        {isMobile ? (
          <Accordion
            title={tab === 'generalData' ? 'Funnel' : 'Funnel By Job'}
            openIcon={<OpenIcon />}
            closeIcon={<CloseIcon />}
            filterList={true}
            headerClassName={styles.cardTop}
            defaultOpened={tab !== 'generalData'}
          >
            {tab === 'generalData' ? renderFunnel() : renderFunnelByJob()}
          </Accordion>
        ) : (
          <div className={styles.cardTop}>{tab === 'generalData' ? 'Funnel' : 'Funnel By Job'}</div>
        )}
        {!isMobile && (tab === 'generalData' ? renderFunnel() : renderFunnelByJob())}
      </div>
    );
  }

  function renderLeftSide() {
    return (
      <div id='leftSide' className={styles.leftSide}>
        {averageDurationHeader()}
        {funnelHeader('generalData')}
        {engagementHeader()}
      </div>
    );
  }

  function renderLeftSideFunnelByJob() {
    return (
      <div
        id='leftSideFunnelByJob'
        className={classNames(styles.leftSide, styles.leftSideFunnelByJob)}
      >
        {funnelHeader('byJob')}
      </div>
    );
  }

  const renderLeftSideProcessDurationByJob = () => (
    <div className={styles.leftSide}>
      <AverageDurationForJobs
        averageDurationHeader={averageDurationHeader}
        averageDuration={averageDuration}
        activeJobs={activeJobs}
        closedJobs={closedJobs}
        dataByJob={companyData.dataByJob}
      />
    </div>
  );

  const renderTabs = () => {
    switch (activeTab) {
      case Tabs.GeneralData:
        return renderLeftSide();
      case Tabs.FunnelByJob:
        return renderLeftSideFunnelByJob();
      case Tabs.ProcessDurationByJob:
        return renderLeftSideProcessDurationByJob();
      default:
        return renderLeftSide();
    }
  };

  function upDownButtons() {
    return (
      <>
        <label className={styles.label}>Is this information useful?</label>
        <div className={styles.checkboxHidden}>
          <Checkbox
            id='useful'
            name='useful'
            label='Is this information useful?'
            controlled={false}
            checked={useful || false}
            value={`${useful}`}
          />
        </div>
        <div className={styles.thumbs}>
          <Button
            buttonColor='puertoRico'
            buttonType={thumbUp ? '' : 'border'}
            isButton={false}
            isRound
            disabled={false}
            onClick={() => handleUpDown('up')}
          >
            <div className={styles.thumbUpIcon}>
              <Icon name={'thumb'} size={Size.MediumBig} color={thumbUp ? 'white' : 'puertoRico'} />
            </div>
          </Button>
          <Button
            buttonColor='puertoRico'
            buttonType={thumbDown ? '' : 'border'}
            isButton={false}
            isRound
            disabled={false}
            onClick={() => handleUpDown('down')}
          >
            <div className={styles.thumbDownIcon}>
              <Icon
                name={'thumb'}
                size={Size.MediumBig}
                color={thumbDown ? 'white' : 'puertoRico'}
              />
            </div>
          </Button>
        </div>
      </>
    );
  }

  function feedbackCard() {
    return (
      <form className={styles.feedbackForm}>
        {upDownButtons()}
        <TextInput
          color='ripePlum'
          value={comments}
          label='Is there any important data missing in this report?'
          inputRef={commentsRef}
          textarea={true}
          onChange={e => handleCommentChange(e)}
        />
        <Button
          buttonColor='ripePlum'
          buttonType={'border'}
          buttonSize={'small'}
          isButton={true}
          isRound
          disabled={sending}
          otherClasses={styles.submitButton}
          onClick={handleSubmit}
        >
          {sending ? 'Sending...' : 'Send'}
        </Button>
      </form>
    );
  }

  function feedbackHeader() {
    return (
      <div className={classNames(styles.feedbackCard, styles.card)}>
        {isMobile ? (
          <Accordion
            title='Feedback'
            openIcon={<OpenIcon />}
            closeIcon={<CloseIcon />}
            headerClassName={styles.cardTop}
            filterList={true}
          >
            {feedbackCard()}
          </Accordion>
        ) : (
          <div className={styles.cardTop}>Feedback</div>
        )}
        {!isMobile && feedbackCard()}
      </div>
    );
  }

  function exportModal() {
    return (
      <Modal defaultOpen={false} ref={modalRef} title='Export'>
        <div className={styles.exportModal}>
          <div className={styles.exportText}>
            The data to be exported corresponds to the date range you have selected.
          </div>
          <div className={styles.exportLabel}>Select the data you want to export:</div>
          <div className={styles.exportButtons}>
            {exportOptions.map((option, index) => (
              <div key={option.name}>
                <Checkbox
                  name='export_form[values][]'
                  value={option.name}
                  label={option.name}
                  color='ripePlum'
                  checked={exportWhat.indexOf(option.name) !== -1}
                  onChange={handleChangeExportModal}
                  controlled={true}
                >
                  {option.show_deleted ? (
                    <div key={`${option.name} unpublished`} className={styles.unpublishedBoxes}>
                      <Checkbox
                        name='deleted[values][]'
                        value={`${option.name}`}
                        label={'Include unpublished jobs'}
                        checked={exportDeleted[index - 1]}
                        color='ripePlum'
                        onChange={handleChangeExportModal}
                        controlled={true}
                      />
                    </div>
                  ) : null}
                </Checkbox>
              </div>
            ))}
          </div>
          <div className={styles.exportLabel}>Choose the format you want to export to:</div>
          <div className={styles.exportButtons}>
            <RadioButtons
              selectedValue={exportTo}
              options={exportToOptions}
              color={'ripePlum'}
              onChange={handleChangeExportToModal}
            />
          </div>
          <div className={styles.exportButton}>
            <Button
              buttonColor='ripePlum'
              isButton={true}
              isRound
              disabled={false}
              onClick={exportEverything}
            >
              EXPORT
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

  function renderTopFilter() {
    return (
      <div className={styles.topRow}>
        <div className={styles.topRowLeftSide}>
          <div className={styles.topRowFilter}>
            <Select
              name='top_filter'
              color='ripePlum'
              options={filterItems}
              placeholder={'Select duration'}
              value={longAgo ? longAgo : filterItems[filterItems.length - 1]}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className={styles.topRowRightSide}>
          <div className={styles.topRowExport}>
            <Button
              buttonColor='ripePlum'
              buttonType={'border'}
              buttonSize={isMobile ? 'small' : 'mid'}
              isButton={true}
              isRound
              disabled={false}
              onClick={openExportSettingsModal}
            >
              EMAIL REPORT
            </Button>
          </div>
          <div className={styles.topRowExport}>
            <Button
              buttonColor='ripePlum'
              buttonType={'border'}
              buttonSize={isMobile ? 'small' : 'mid'}
              isButton={true}
              isRound
              disabled={false}
              onClick={openExportModal}
            >
              {isMobile ? (
                <Icon name={'export'} size={Size.MediumBig} color={'ripePlum'} />
              ) : (
                'EXPORT'
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.dataPage}>
      {exportModal()}
      <SettingsExportModal
        exportOptions={exportOptions}
        exportSettings={props.company.data_report_content}
        notificationPreferences={props.notification_preferences}
        ref={settingsModalRef}
      />
      <Side />
      <Header />
      {companyData && (
        <>
          {renderTopFilter()}
          <div className={styles.allCards}>
            {renderTabs()}
            {feedbackHeader()}
          </div>
        </>
      )}
    </div>
  );
}

const GQL_QUERY_DATE = gql`
  query ($company_id: Int!, $months_ago: Int!) {
    companyData(companyId: $company_id, monthsAgo: $months_ago) {
      jobs {
        id
        closedAt
        title
      }
      company {
        daysToEngage {
          average
          median
        }
        daysToHire {
          average
          median
        }
        daysToOffer {
          average
          median
        }
        daysToReject {
          average
          median
        }
        daysToReview {
          average
          median
        }
      }
      overall {
        daysToEngage {
          average
          median
        }
        daysToHire {
          average
          median
        }
        daysToOffer {
          average
          median
        }
        daysToReject {
          average
          median
        }
        daysToReview {
          average
          median
        }
      }
      funnel {
        jobViews
        applicationsSubmitted
        curatedApplications
        employerReviewing
        engaged
        offerMade
        hired
        toApplicationsSubmitted
        toCuratedApplications
        toEmployerReviewing
        toEngaged
        toOfferMade
        toHired
      }
      dataByJob {
        funnel {
          jobId
          jobViews
          applicationsSubmitted
          curatedApplications
          employerReviewing
          engaged
          offerMade
          hired
          toApplicationsSubmitted
          toCuratedApplications
          toEmployerReviewing
          toEngaged
          toOfferMade
          toHired
        }
        employerReviewing {
          handshakeApplications
        }
        curatedApplications
        speedMetrics {
          daysToEngage {
            average
            median
          }
          daysToHire {
            average
            median
          }
          daysToOffer {
            average
            median
          }
          daysToReject {
            average
            median
          }
          daysToReview {
            average
            median
          }
        }
        hiringStepMovements {
          countMoving
          countTotal
          title
        }
      }
      employerReviewing {
        handshakeApplications
      }
      curatedApplications
      platformEngagementLevel {
        company
        overall
        lastUpdate
      }
    }
  }
`;

export const OpenIcon = () => (
  <div>
    <Icon name='plus' color='black' size={Size.MediumLarge} style={Style.Thick} />
  </div>
);

export const CloseIcon = () => (
  <div>
    <Icon name='minus' color='black' size={Size.MediumLarge} style={Style.Thick} />
  </div>
);
