import * as React from 'react';
import LanguageDimension, { Props } from './language_dimension';
import { shallow, ShallowWrapper } from 'enzyme';

let wrapped: ShallowWrapper;

describe('Language Dimension component', () => {
  beforeEach(() => {
    wrapped = shallow(
      <LanguageDimension
        value={4}
        ratingRef=''
        handleChange={jest.fn()}
        preferredLanguage='English'
        niceToHaveLanguage='Portuguese'
        preferredLanguageRef=''
        niceToHaveLanguageRef=''
        preferredLanguageValue={5}
        niceToHaveLanguageValue={2}
      />
    );
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
