import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Modal, { ButtonsLayout } from '../general/modal/modal';
import Checkbox from '../form/checkbox/checkbox';
const styles = require('./publish_modal.module.scss');
const iconUnpublish = require('iconic/media.svg');
import { JobAd } from '../../__models__/models';

interface Props {
  jobAd: JobAd;
  handleDone: Function;
}

class UnpublishModal extends React.Component<Props> {
  modalRef: any;
  scrollRef: any;

  constructor(props: Props) {
    super(props);

    this.modalRef = React.createRef();
    this.scrollRef = React.createRef();

    this.handleUnpublish = this.handleUnpublish.bind(this);
    this.handleDontShowChange = this.handleDontShowChange.bind(this);
  }
  handleUnpublish(event) {
    const url = this.props.jobAd.attributes.urls.job_ad_unpublish;

    $.ajax({
      url,
      type: 'PUT',
    });
  }

  handleDontShowChange(event) {
    this.props.handleDone(event.target.checked);
  }

  render() {
    return (
      <Modal
        defaultOpen={true}
        ref={this.modalRef}
        title='Unpublish'
        cancelButton={true}
        buttonName='Unpublish'
        buttonOnClick={this.handleUnpublish}
        buttonIcon={iconUnpublish}
        iconAlt='pause'
        iconData={{ state: 'pause' }}
        buttonsLayout={ButtonsLayout.OkCancel}
      >
        <div className='modal-structure'>
          <p>
            You should unpublish a job when you don't want to receive further applications for a
            position. This action is always reversible.
          </p>
          <p>
            An unpublished job is no longer searchable neither accessible by anyone that hasn't
            already applied to that position.
          </p>
          <p>
            This way you can finish your current evaluation pipeline without receiving any more
            applications. If by the time you're finished you realize that you need some more
            candidates, just publish it again and you're set to go.
          </p>
          <p>
            If what you want to do is to close this position because you already hired someone or
            aren't further looking feel free to actually{' '}
            <a className={styles.link} href={this.props.jobAd.attributes.urls.job_ad_close}>
              close the job
            </a>{' '}
            instead.
          </p>
        </div>
        <div className={styles.lastRow}>
          <div className={styles.checkbox}>
            <Checkbox
              name='publish'
              label="Don't show this warning again"
              controlled={false}
              onChange={this.handleDontShowChange}
            />
          </div>
        </div>
      </Modal>
    );
  }

  static show(jobAd, handleDone) {
    return new Promise<void>(resolve => {
      const root = $('<div></div>');

      function onDone() {
        root.remove();
        resolve();
      }

      $('body').append(root);

      ReactDOM.render(<UnpublishModal jobAd={jobAd} handleDone={handleDone} />, root[0]);
    });
  }
}

export default UnpublishModal;
