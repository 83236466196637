import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import RejectHandshake from './reject_handshake';

const createProps = () => ({
  buttonColor: 'red',
  handshake: { id: 1234 },
  company: { name: 'LandingJobs', id: 4321, logo: 'lala' },
  jobAd: { title: 'Pokemon Master', id: 2323 },
});

let props;
let wrapped: ShallowWrapper;

describe('RejectHandshake', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<RejectHandshake {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});
