import * as React from 'react';
import NoteForScreening from 'components/backoffice/application/note_for_screening';
import InlineIcon from 'components/general/inline_icon/inline_icon';
const styles = require('./current_application_notes_card.module.scss');
const warningIcon = require('iconic/warning.svg');

export interface Props {
  employer_notes: any;
  job_ad_account_manager_notes: any;
  company_account_manager_notes: any;
  talent_notes: any;
}

export default class CurrentApplicationNotesCard extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  hasNotesFromLanding() {
    return (
      this.props.company_account_manager_notes ||
      this.props.job_ad_account_manager_notes ||
      this.props.talent_notes != null
    );
  }

  hasNotesFromEmployer() {
    return (
      this.props.employer_notes && this.props.employer_notes !== '<p><br data-mce-bogus="1"></p>'
    );
  }

  render() {
    if (!this.hasNotesFromEmployer() && !this.hasNotesFromLanding()) {
      // Show nothing if we have nothing to show
      return null;
    }
    return (
      <div className={styles.notesWrapper}>
        {this.hasNotesFromEmployer() && (
          <NoteForScreening
            title='Notes from the Employer'
            note={this.props.employer_notes}
            employer={true}
          />
        )}
        {this.hasNotesFromLanding() && (
          <div className={styles.title}>
            <InlineIcon path={warningIcon} className={styles.warningIcon} />
            Notes from Landing.jobs team
          </div>
        )}
        {this.props.company_account_manager_notes && (
          <NoteForScreening
            title='About the company'
            note={this.props.company_account_manager_notes}
          />
        )}
        {this.props.job_ad_account_manager_notes && (
          <NoteForScreening title='About the job' note={this.props.job_ad_account_manager_notes} />
        )}
        {this.props.talent_notes && (
          <NoteForScreening title='About the Candidate' note={this.props.talent_notes} />
        )}
      </div>
    );
  }
}
