export default class AboutYourselfForm {
  citizenships: any;
  languages: any;
  personAtts: any;
  proficiencyLevels: any;

  constructor(params) {
    this.citizenships = params.citizenships;
    this.languages = params.languages;
    this.personAtts = params.person.data.attributes;
    this.proficiencyLevels = params.proficiencyLevels;
  }

  props = () => ({
    birthYear: this.personAtts.birth_year,
    citizenshipList: this.citizenships,
    citizenships: this.personAtts.citizenships.split(', '),
    languageList: this.languages,
    languages: this.personAtts.languages,
    phoneNumber: this.personAtts.phone_number,
    proficiencyLevels: this.proficiencyLevels,
  });
}
