import React from 'react';
import NameErrors from './name_errors';
import { shallow, mount } from 'enzyme';

let wrapped1;
let wrapped2;
let wrapped3;

describe('Name Errors Component existance/structure', () => {
  const nameObj1 = {
    nameRef: { current: { value: 'Test Name 1' } },
    isInvalid: false,
    isEmpty: false,
    isLong: false,
    exists: false,
  };

  beforeAll(() => {
    wrapped1 = shallow(<NameErrors {...nameObj1} />);
  });

  it('exists', () => {
    expect(wrapped1.exists()).toBe(true);
  });

  it('has 0 error divs', () => {
    expect(wrapped1.find('div.ld-alert').length).toEqual(0);
  });
});

describe('Name Errors Component functionality', () => {
  const nameObj2 = {
    nameRef: { current: { value: 'Test Name 2' } },
    isInvalid: false,
    isEmpty: false,
    isLong: true,
    exists: false,
  };

  const nameObj3 = {
    nameRef: { current: { value: 'Test Name 3' } },
    isInvalid: true,
    isEmpty: false,
    isLong: true,
    exists: false,
  };

  beforeAll(() => {
    wrapped2 = mount(<NameErrors {...nameObj2} />);
    wrapped3 = mount(<NameErrors {...nameObj3} />);
  });
  it('has 1 error divs and correct error message', () => {
    expect(wrapped2.find('div.ld-alert').length).toEqual(1);
    expect(wrapped2.text()).toEqual('Skill name cannot be longer than 50 characters. Current: 11.');
  });

  it('has 2 error divs', () => {
    expect(wrapped3.find('div.ld-alert').length).toEqual(2);
  });
});
