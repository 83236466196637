import Icon, { Size } from 'components/general/icon/icon';
import Button from 'lj_shared/button/button';
import React from 'react';
const styles = require('./CandidateSearchCTA.module.scss');

interface Props {
  job: string;
  numberOfTalentUsers: string;
}

export default function CandidateSearchCTA(props: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.firstRow}>
        <div>
          <Icon name='handshake' color='ripePlum' size={Size.Big} />
        </div>
        <div className={styles.title}>Have you tried our Candidate Search tool?</div>
      </div>
      <div>
        <div className={styles.text}>
          Is your pipeline for this job empty or having a hard time getting dynamic? Use our
          Candidate Search tool to find potential candidates from our {props.numberOfTalentUsers}{' '}
          talent users pool!{' '}
          <span>Our pre-filtering mechanism will help you find candidates in record time!</span>
        </div>
        <div>
          <Button
            buttonColor='ripePlum'
            buttonSize='small'
            buttonUrl={window.Routes.sourcing_employer({ job_ad: props.job })}
          >
            Get candidates
          </Button>
        </div>
      </div>
    </div>
  );
}
