import React from 'react';
import sanitizeHtml from 'sanitize-html-react';
const styles = require('./JobSection.module.scss');

interface Props {
  title: string;
  text: string;
}

export default function JobSection(props: Props) {
  const html = sanitizeHtml(props.text, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['center', 'h2', 'iframe', 'img']),
    allowedAttributes: {
      a: ['href', 'rel', 'target'],
      iframe: ['allow', 'allowfullscreen', 'frameborder', 'height', 'src', 'width'],
      img: ['height', 'src', 'width'],
    },
  });
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{props.title}</div>
      <hr className={styles.line} />
      <div
        className={styles.text}
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    </div>
  );
}
