import * as React from 'react';
import PublishModal from './publish_modal';
import { JobAd } from '../../__models__/models';
import { shallow } from 'enzyme';
import Modal from '../general/modal/modal';
import Checkbox from '../form/checkbox/checkbox';

const jobDummy: JobAd = require('./fixtures/job_ad_dummy.json');
let wrapped;
let handleMock;

beforeAll(() => {
  handleMock = jest.fn();
  wrapped = shallow(
    <PublishModal
      jobAd={jobDummy}
      termsOfService='https://landing.jobs/tos'
      privacy='https://landing.jobs/privacy'
      handleDone={handleMock}
    />
  );
});

test('should render correctly', () => {
  expect(wrapped.exists()).toBe(true);
});

it('it has a Modal', () => {
  expect(wrapped.find(Modal).length).toEqual(1);
});

it('it has a checkbox', () => {
  expect(wrapped.find(Checkbox).length).toEqual(1);
});

it('has link to terms of service', () => {
  expect(wrapped.find('a').at(0).props().href).toEqual('https://landing.jobs/tos');
});

it('has link to privacy', () => {
  expect(wrapped.find('a').at(1).props().href).toEqual('https://landing.jobs/privacy');
});
