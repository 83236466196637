import * as React from 'react';
import classNames from 'classnames';
import InlineIcon from '../general/inline_icon/inline_icon';
import SendApplicationByEmailModal from './send_application_by_email_modal';
import { handleRequestError } from '../../lib/request_deprecated';
const iconDataTransfer = require('iconic/data-transfer.svg');
const iconShare = require('iconic/share.svg');
const styles = require('./application_more_button.module.scss');
export interface Props {
  emailUrl: string;
  pdfUrl: string;
  topFilter: string;
  moveToStep: Function;
  hiringSteps: any;
  application: any;
}

interface State {
  isOpen: boolean;
}

export default class ApplicationMoreButton extends React.Component<Props, State> {
  wrapperRef: any;

  constructor(props) {
    super(props);
    this.state = { isOpen: false };
    this.wrapperRef = React.createRef();
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleBodyClick = this.handleBodyClick.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.handleSendByEmailClick = this.handleSendByEmailClick.bind(this);
  }

  componentDidMount() {
    document.body.addEventListener('click', this.handleBodyClick);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleBodyClick);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleBodyClick(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  }

  handleClick() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  handleItemClick(event) {
    this.setState({ isOpen: false });
  }

  handleSendByEmailClick(event) {
    event.preventDefault();
    SendApplicationByEmailModal.show(
      this.props.emailUrl,
      this.props.topFilter,
      this.props.moveToStep,
      this.props.hiringSteps,
      this.props.application
    ).catch(handleRequestError);
  }

  render() {
    const klass = classNames(
      'lj-button lj-button--silverSand lj-button--xSmall lj-button--border',
      styles.button,
      { [styles.isOpen]: this.state.isOpen }
    );

    return (
      <div className={styles.container} ref={this.setWrapperRef}>
        <div className={klass} onClick={this.handleClick}>
          More
        </div>
        {this.state.isOpen && this.renderMenu()}
      </div>
    );
  }

  renderMenu() {
    return (
      <ul className={styles.menu}>
        <li className={styles.menuItem} onClick={this.handleItemClick}>
          <a onClick={this.handleSendByEmailClick}>
            <InlineIcon className={styles.menuItemIcon} path={iconDataTransfer} />
            <span className={styles.menuItemLabel}>Share by Email</span>
          </a>
        </li>
        <li className={styles.menuItem} onClick={this.handleItemClick}>
          <a href={this.props.pdfUrl} target='_blank' rel='noreferrer'>
            <InlineIcon className={styles.menuItemIcon} path={iconShare} />
            <span className={styles.menuItemLabel}>Download as PDF</span>
          </a>
        </li>
      </ul>
    );
  }
}
