import * as React from 'react';
import classNames from 'classnames';
import FormError from '../form_error/form_error';

const styles = require('./square_radio_buttons.module.scss');

interface Props {
  radioName: string;
  items?: any;
  error?: string;
  onClickYield?: Function;
  groupLabel?: string;
  required?: boolean;
  color?: string;
  borderButtons?: boolean;
  vertical?: boolean;
  labels?: string[];
  contents?: any[];
  selectedLabel?: any;
}

class SquareRadioButtons extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    document
      .querySelectorAll(`input[name="${this.props.radioName}"]`)
      .forEach((element: any) => (element.checked = false));

    const input = event.target.parentElement.parentElement.querySelector('input');
    input.checked = true;

    if (this.props.onClickYield) {
      this.props.onClickYield(input);
    }
  }

  render() {
    const items = this.props.items
      ? this.props.items
      : Array(this.props.labels.length)
          .fill(undefined)
          .map((_m, i) => String(i + 1));

    return (
      <>
        {this.props.groupLabel && (
          <label className={styles.groupLabel}>
            {this.props.groupLabel}{' '}
            {this.props.required && <span className={styles.required}>*</span>}
          </label>
        )}
        {this.props.error && <FormError text={this.props.error} />}
        <div
          className={classNames(
            styles.squareRadioButtons,
            this.props.color,
            this.props.vertical && styles.vertical,
            this.props.borderButtons && styles.borderButtons
          )}
        >
          {items.map((item, index) => {
            return this.renderItem(
              index,
              item,
              this.props.labels && this.props.labels[index],
              this.props.contents && this.props.contents[index]
            );
          })}
        </div>
      </>
    );
  }

  renderItem(index, item, label, content) {
    return (
      <span className={styles.radio} key={this.props.radioName + '_' + index}>
        <input
          className={styles.radioInput}
          type='radio'
          id={this.props.radioName + '_' + String(index)}
          name={this.props.radioName}
          value={this.props.items ? item : label}
          defaultChecked={label && this.props.selectedLabel && this.props.selectedLabel === label}
          required={this.props.required}
        />
        <label className={styles.radioLabel} onClick={this.onClick}>
          <div
            className={classNames(styles.labelMain, {
              [styles[this.props.color]]: this.props.color,
            })}
          >
            {item}
          </div>
          {label && (
            <div
              className={classNames(styles.labelExtra, {
                [styles[this.props.color]]: this.props.color,
              })}
            >
              {label}
            </div>
          )}
        </label>
        {content && <div className={styles.radioContent}>{content}</div>}
      </span>
    );
  }
}

export default SquareRadioButtons;
