import React from 'react';
import { generateSpacedNotes } from './rating_dimension_item';
import RatingDimension from '__models__/rating_dimension';
import Tooltip from 'components/general/tooltip/tooltip';
import CompletionBar from '../completion_bar/CompletionBar';
import Icon, { Size } from '../icon/icon';

const styles = require('./rating_dimension_container.module.scss');

export interface Props {
  dimensions: any[];
  hasTooltip?: boolean;
  showDimensions?: boolean;
  showHeader?: boolean;
  maxRating: number;
  required?: boolean;
  title?: string;
}

export default function RatingDimensionContainer(props: Props) {
  const { dimensions } = props;
  const overallValue = dimensions.filter(d => d.title === 'Overall')[0]?.value;
  const overall = overallValue?.value?.toFixed(1) || overallValue?.toFixed(1);

  const orders = RatingDimension.DIMENSIONS_ORDER;
  const orderByKey = (first, last) =>
    orders[first.title.toLowerCase()] - orders[last.title.toLowerCase()];

  const renderDimensions = () => {
    dimensions.sort(orderByKey);

    if (dimensions.length === 1 && dimensions[0].title === 'Overall') {
      return dimensions.map(dimension => (
        <CompletionBar
          key={dimension.uniqueKey}
          {...dimension}
          values={[dimension.value]}
          average={false}
        />
      ));
    } else {
      return dimensions
        .filter(d => !(d.title === 'Bonus' || d.title === 'Overall'))
        .map(dimension => (
          <CompletionBar
            key={dimension.uniqueKey}
            {...dimension}
            values={[dimension.value]}
            {...dimension}
            average={false}
          />
        ));
    }
  };

  return (
    <div className={styles.dimensionContainer}>
      <div className={styles.leftContainer}>
        {props.title && (
          <div className={styles.dimensionTitle}>
            {props.title}
            {props.required && <span className={styles.required}> *</span>}
          </div>
        )}
        {props.showHeader && <CurationHeader hasTooltip={props.hasTooltip} overall={overall} />}
        {props.showDimensions && <div className={styles.dimensionItems}>{renderDimensions()}</div>}
      </div>
    </div>
  );
}

export function CurationHeader({ hasTooltip, overall, appPage = false }) {
  const tipText = [
    'For details on each dimension, hover on the score below.',
    "For further detail, tap on the candidate's name.",
  ];

  const klass = appPage ? `${styles.oneLinerTitle} ${styles.appPageTitle}` : styles.oneLinerTitle;

  return (
    <div className={klass}>
      <div className={styles.screening}>Curation</div>
      {hasTooltip ? (
        <Tooltip
          text={generateSpacedNotes(tipText)}
          textBelow={true}
          style={{
            backgroundColor: '#343434',
            color: 'white',
            fontSize: '0.625rem',
            lineHeight: '1.5',
            textAlign: 'left',
            blankLine: 'always',
          }}
        >
          <div className={styles.helpfulInterrogationTip}>
            <Icon name='star' color='ripePlum' size={Size.MediumLarge} />
            {overall}
            <span className={styles.tinyOverall}>/5</span>
          </div>
        </Tooltip>
      ) : (
        <div className={styles.helpfulInterrogationTip}>
          <Icon name='star' color='ripePlum' size={Size.MediumLarge} />
          {overall}
          <span className={styles.tinyOverall}>/5</span>
        </div>
      )}
    </div>
  );
}
