import * as React from 'react';
import { getHandshakes } from './conversation_header';
import { isMobile } from 'react-device-detect';
import { Messageable, Application, Website } from './models';
import ApplicationNotes from '../../general/application_notes/application_notes';
import TagWithTooltip from 'components/general/tag_with_tooltip/tagWithTooltip';

export interface Props {
  messageable: Messageable;
  notes_url: string;
  note_destroy_url: string;
  get_notes_url: string;
  userType: string;
  sidebarWidth: Object;
  mini: boolean;
}

export default class ConversationSidebar extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    if (!this.props.messageable || this.props.mini) {
      return null;
    }

    const { messageable, userType, sidebarWidth, notes_url, note_destroy_url, get_notes_url } =
      this.props;
    const url = '/employer/search/people/';

    // attributes.person_id is only present when the conversation has started
    const userId = messageable.attributes.person_id || messageable.id;
    const applications: Application[] = messageable.attributes.candidate_applications;
    const applicationId = applications[0] ? applications[0].id : null;

    if (userType === 'TalentUser' || window.innerWidth < 993) {
      return null;
    }

    return (
      <div className='lj-sidebar-wrapper' style={sidebarWidth}>
        <div className='lj-sidebar-header'>
          <a href={url + userId} target='_blank' rel='noreferrer'>
            See profile
          </a>
          {messageable.attributes.recipient_recently_updated && (
            <TagWithTooltip
              tagTitle='Recently Updated'
              tooltipText='Updated in the last 3 months.'
              textBelow
              color='puertoRico'
              newIcon='starCircle'
              iconColor='white'
            />
          )}
        </div>
        <UserInfo messageable={messageable} />
        <div className='lj-chat-header-handshake-tag-container'>
          {getHandshakes(
            applications,
            messageable.attributes.recipient_name,
            userType,
            !isMobile,
            true
          )}
        </div>
        {applications.length > 0 ? (
          <>
            <Applications messageable={messageable} />
            <div className='lj-chat-notes'>
              <div className='lj-sidebar-title'>Notes</div>
              <ApplicationNotes
                getNotesUrl={get_notes_url}
                postNoteUrl={notes_url}
                destroyNoteUrl={note_destroy_url}
                applicationId={applicationId}
              />
            </div>
          </>
        ) : null}
      </div>
    );
  }
}

const UserInfo = props => {
  const websites: Website[] = props.messageable.attributes.websites;
  const socialIcon = require('iconic/social.svg');
  const dataAttr = website => {
    return { 'data-type': website.category_key };
  };
  let personSlug = props.messageable.attributes.person_slug;

  personSlug =
    personSlug && personSlug.length > 0 ? personSlug : props.messageable.attributes.person_id;

  const cvUrl = `/employer/search/people/${personSlug}/resume?open=true`;

  return (
    <div className='lj-user-info'>
      <div
        dangerouslySetInnerHTML={{
          __html: props.messageable.attributes.recipient_avatar,
        }}
      />
      <div className='lj-user-name'>{props.messageable.attributes.recipient_name}</div>
      <div className='lj-user-experience'>{props.messageable.attributes.experience_level}</div>
      <div className='lj-userLink-group'>
        {props.messageable.attributes.has_cv ? (
          <a
            href={cvUrl}
            className='lj-userLink lj-link--ripePlum lj-link--no-border lj-userLink--cv'
            target='_blank'
            rel='noreferrer'
          >
            CV
          </a>
        ) : null}
        {websites
          ? websites.map((website: Website) => {
              return (
                <a
                  key={website.id}
                  className='lj-userLink'
                  href={website.full_url}
                  target='_blank'
                  title={website.category_key}
                  rel='noreferrer'
                >
                  <img
                    src={socialIcon}
                    alt={website.category_key}
                    className={'lj-icon iconic iconic-sm ' + website.category_key}
                    {...dataAttr(website)}
                  />
                </a>
              );
            })
          : null}
      </div>
    </div>
  );
};

const Applications = props => {
  const url = '/employer/applications/';

  const getRating = (rating: number) => {
    let icon: string;
    let label: string;

    switch (rating) {
      case 1:
        icon = require('svg/icon_not_fit_purple');
        label = '1/5';
        break;
      case 2:
        icon = require('svg/icon_fit_purple');
        label = '2/5';
        break;
      case 3:
        icon = require('svg/icon_fit_purple');
        label = '3/5';
        break;
      case 4:
        icon = require('svg/icon_great_fit_purple');
        label = '4/5';
        break;
      case 5:
        icon = require('svg/icon_great_fit_purple');
        label = '5/5';
        break;
      default:
        icon = null;
        label = null;
    }

    return { icon, label };
  };

  return (
    <div className='lj-sidebar-applications'>
      <div className='lj-sidebar-title'>Ongoing Applications at Landing.jobs</div>
      {props.messageable.attributes.candidate_applications.map(({ id, job, app_rating }) => {
        const icon = getRating(app_rating).icon;
        const label = getRating(app_rating).label;
        return (
          <div key={id} className='lj-sidebar-application'>
            <a href={url + id} target='_blank' className='lj-application-link' rel='noreferrer'>
              {job.title}
            </a>
            <span className='lj-rating-label'>
              <img src={icon} alt={label} /> {label}
            </span>
          </div>
        );
      })}
    </div>
  );
};
