import * as React from 'react';
import * as Models from './models';
import InlineIcon from '../../general/inline_icon/inline_icon';
import formatDate from '../../../lib/format_date';
const eyeIcon = require('iconic/eye.svg');

export interface MessageProps {
  message: Models.Message;
  sender: boolean;
  converter: any;
  showAvatar: boolean;
  image: string;
  lastMessageSeen: boolean;
}

const Message = (props: MessageProps) => {
  const date = formatDate(props.message.attributes.created_at, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  });

  return (
    <div
      className={[
        'lj-message-container',
        props.sender && props.lastMessageSeen ? '--seen-message' : '',
      ].join(' ')}
    >
      {props.showAvatar ? (
        <div className='lj-message-avatar' dangerouslySetInnerHTML={{ __html: props.image }} />
      ) : (
        <div className='lj-message-avatar' />
      )}
      <div className={props.sender ? 'lj-own-message' : 'lj-other-message'} key={props.message.id}>
        <div
          dangerouslySetInnerHTML={{
            __html: props.converter.makeHtml(props.message.attributes.body),
          }}
        />
        <div className='lj-message-attributes'>
          {date}
          {props.sender && props.lastMessageSeen ? (
            <span className='seen-marker'>
              <InlineIcon alt='eye' path={eyeIcon} />
              Seen
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Message;
