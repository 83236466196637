import React from 'react';
import { range } from 'lib/range';
import classNames from 'classnames';
import Tooltip from 'components/general/tooltip/tooltip';

import FormError from 'components/form/form_error/form_error';

const styles = require('./rating_dimension_item.module.scss');

export interface DimensionItem {
  error?: string;
  handleCircleClick?: Function;
  hasTooltip?: boolean;
  maxRating: number;
  notes?: string[];
  title: string;
  uniqueKey: string;
  value: number;
}

export const generateSpacedNotes = notes => (
  <div>
    {notes.slice(1).reduce(
      (acc, line, index) => [
        ...acc,
        <p key={(1 + index) * 2 - 1} className={styles.emptyLine}>
          {line}
        </p>,
      ],
      [
        <p className={styles.text} key={0}>
          {notes[0]}
        </p>,
      ]
    )}
  </div>
);

export const generateJustifiedNotes = notes => (
  <div>
    {notes.slice(1).reduce(
      (acc, line, index) => [
        ...acc,
        <div key={(1 + index) * 2 - 1} className={styles.emptyLine}>
          {generateJustifiedNote(line)}
        </div>,
      ],
      [<div key={0}>{generateJustifiedNote(notes[0])}</div>]
    )}
  </div>
);

const generateJustifiedNote = notes =>
  notes &&
  notes.map((line, index) => [
    <p className={styles.text} key={index}>
      <span>{line[0]}</span>
      <span className={styles.rightSide}>{line[1]}</span>
    </p>,
  ]);

export default function RatingDimensionItem(props: DimensionItem) {
  const renderClicklessDimensionValues = () =>
    range(0, props.maxRating - 1).map(index => (
      <div
        key={`${props.uniqueKey}_item_${index}`}
        className={index < props.value ? styles.circleFilled : styles.circle}
      />
    ));

  const renderClickfulDimensionValues = () =>
    range(0, props.maxRating - 1).map(index => (
      <div
        key={`${props.uniqueKey}_item_${index}`}
        className={classNames(
          index < props.value ? styles.circleFilled : styles.circle,
          styles.clickeaball
        )}
        onClick={() => props.handleCircleClick(props.uniqueKey, index + 1)}
      />
    ));

  const hasNotes = () => !!props.notes && props.notes.length;

  const renderTipfulDimensionValues = () => {
    const filledBalls = () =>
      range(0, props.maxRating - 1).map(index => (
        <div
          key={`${props.uniqueKey}_item_${index}`}
          className={index < props.value ? styles.circleFilled : styles.circle}
        />
      ));

    if (hasNotes()) {
      return (
        <Tooltip
          text={generateSpacedNotes(props.notes)}
          textBelow={true}
          style={{
            backgroundColor: '#343434',
            color: 'white',
            fontSize: '0.625rem',
            lineHeight: '1.5',
            textAlign: 'left',
            blankLine: 'always',
          }}
        >
          {<div className={styles.straightBalls}>{filledBalls()}</div>}
        </Tooltip>
      );
    }

    return filledBalls();
  };

  const renderCorrectRatingComponent = () => {
    if (props.handleCircleClick) return renderClickfulDimensionValues();
    if (props.hasTooltip) return renderTipfulDimensionValues();

    return renderClicklessDimensionValues();
  };

  return (
    <div className={styles.container}>
      <div className={styles.itemContainer}>
        <div className={styles.title}>{props.title}</div>
        <div className={styles.rating}>{renderCorrectRatingComponent()}</div>
      </div>
      {props.error && <FormError text={props.error} />}
    </div>
  );
}
