import React, { useState } from 'react';
import FormInput from 'components/general/form_input/form_input';
import Checkbox from 'components/form/checkbox/checkbox';
import { Size } from 'components/general/tooltip/tooltip';

const styles = require('./JobInterestsModalContainer.module.scss');

interface Props {
  error?: string;
  handleFormChange: (event: any, input: string) => void;
  jobTypesInterest: { id: number; label: string }[];
  jobTypesList: Object;
  label?: string;
}

const REMOTE_POLICY_IDS = [2, 3, 5];

const tooltipContent = [
  {
    title: 'Remote',
    description: 'Working full-time away from the HQ from home or any other remote location.',
  },
  {
    title: 'Hybrid',
    description: 'Having the possibility to spare your working hours between the HQ or remotely.',
  },
  {
    title: 'Onsite',
    description: "Someone who works at the employer's office.",
  },
];

export default function JobTypeInterests(props: Props) {
  const [jobTypesInterest, setjobTypesInterest] = useState(props.jobTypesInterest);

  const updateJobTypesInterest = event => {
    const updatedJobTypesInterest = computeRemotePolicyChange(event.target, jobTypesInterest);
    setjobTypesInterest(updatedJobTypesInterest);
    props.handleFormChange(updatedJobTypesInterest, 'jobTypesInterest');
  };

  // Create tooltip content for each remote type
  const tooltip = tooltipContent.map(({ title, description }) => (
    <span key={title.replace(/\s/g, '')} className={styles.tooltipLine}>
      <strong>{title}</strong>
      {` - ${description}`}
    </span>
  ));

  // Filter data since we receive both the job types and the remote policy preferences
  const remotePolicies = Object.keys(props.jobTypesList)
    ?.filter(jt => REMOTE_POLICY_IDS.includes(props.jobTypesList[jt]))
    .reduce((obj, key) => {
      obj[key] = props.jobTypesList[key];
      return obj;
    }, {});

  return (
    <>
      <FormInput
        name='remote_policy'
        label={props.label || 'Which remote policies would you prefer?'}
        error={props.error}
        required={true}
        tooltip={{ content: tooltip, size: Size.Default }}
        sublabel='Help me here'
        tooltipOnSublabel
      >
        {Object.entries(remotePolicies).map(([label, value]) => (
          <div className={styles.checkbox} key={value.toString()}>
            <Checkbox
              onChange={event => updateJobTypesInterest(event)}
              name={label.toString()}
              value={value.toString()}
              controlled={false}
              label={label}
              checked={jobTypesInterest.some(jobTypeInterest => jobTypeInterest.id === value)}
            />
          </div>
        ))}
      </FormInput>
    </>
  );
}

const computeRemotePolicyChange = (target, jobTypesInterest) => {
  const id = Number(target.value);
  const label = target.name;
  const checked = target.checked;

  if (checked) {
    jobTypesInterest.push({ id, label });
  } else {
    jobTypesInterest = jobTypesInterest.filter(i => i.id !== id);
  }
  return jobTypesInterest;
};
