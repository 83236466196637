export class CompanyInvoice {
  id: string;
  invoiceId: string;
  date: Date;
  description: string;
  dueDate: Date;
  number: number;
  status: string;
  value: string;
}
