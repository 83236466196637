import Button from 'lj_shared/button/button';
import React from 'react';
import track from '../../lib/drip';
import Login, { LoginProps } from './login_container';

import './welcome.scss';

const astroboy = require('images/astroboy.svg');

interface Props {
  language: {
    title: string;
    subtitle: string;
    text: string;
    buttons: {
      continue_btn: string;
      change_btn: string;
    };
  };
  urls: {
    positivePath: string;
    negativePath: string;
  };
  loginProps: LoginProps;
}

interface State {
  continue: boolean;
}
class WelcomeEmail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      continue: false,
    };

    this.clickOnContinue = this.clickOnContinue.bind(this);
  }

  clickOnContinue(_e) {
    track('action', {
      name: 'work-welcome-click',
      controller: 'work',
      action: 'welcome-continue',
    });

    this.setState({ continue: true });
  }

  render() {
    const { title, subtitle, text, buttons } = this.props.language;

    if (this.state.continue) {
      return <Login {...this.props.loginProps} />;
    } else {
      return (
        <div className='welcome_container'>
          <div className='welcome_title'>{title}</div>
          <div className='welcome_text'>{subtitle}</div>
          <img className='welcome_astroboy' src={astroboy} alt='astroboy' />
          <div className='welcome_text bottom_text'>{text}</div>
          <div className='welcome_midButtons welcome_double_buttons'>
            <Button
              onClick={() =>
                track('action', {
                  name: 'work-welcome-click',
                  controller: 'work',
                  action: 'welcome-continue',
                })
              }
              otherClasses={'midButtons-button welcome_individual_button1'}
              buttonColor='white'
              buttonSize='mid'
              isButton={false}
              buttonUrl={this.props.urls.negativePath}
            >
              {buttons.change_btn}
            </Button>
            <Button
              onClick={this.clickOnContinue}
              otherClasses={'midButtons-button welcome_individual_button2'}
              buttonColor='flamingo'
              buttonSize='mid'
              isButton={false}
            >
              {buttons.continue_btn}
            </Button>
          </div>
        </div>
      );
    }
  }
}

export default WelcomeEmail;
