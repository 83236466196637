import classNames from 'classnames';
import React, { useRef, useState, useEffect } from 'react';
import Modal, { ButtonsLayout } from 'components/general/modal/modal';
import Button from 'lj_shared/button/button';
import { useFind } from '__models__/gql/data-hooks/use_find';
import { Application } from '__models__/gql/application';
import Spinner from 'shared/dot_spinner/DotSpinner';
import Rating from '__models__/rating';
import DimensionsContainer from './dimensions_container';
import { DocumentNode } from 'graphql';
import { apolloClient, gql } from 'lib/graphql';
import Textarea from 'components/form/textarea/textarea';
import BonusAndConcerns from 'components/curators/bonus_and_concerns';
const styles = require('./re_rate_application.module.scss');

interface Props {
  applicationId: number;
}

const GQL_MUTATION_RE_RATE = gql`
  mutation (
    $applicationId: ID!
    $dimensionRatings: [RatingInput!]!
    $notes: String
    $concern: String
    $concernLevel: Int
    $concernNote: String
  ) {
    application {
      reRate(
        applicationId: $applicationId
        dimensionRatings: $dimensionRatings
        notes: $notes
        concern: $concern
        concernLevel: $concernLevel
        concernNote: $concernNote
      ) {
        errors {
          field
          message
        }
      }
    }
  }
`;

export default function ReRateApplication(props: Props) {
  const modalRef = useRef(null);
  const buttonClass = classNames(
    'ld-button ld-small-button ld-blue-button ld-border-button',
    styles.openButton
  );

  return (
    <>
      <Button
        buttonColor='tuftsBlue'
        buttonSize='small'
        buttonType='border'
        onClick={() => modalRef.current.open()}
        otherClasses={buttonClass}
        otherClassesOnly={true}
      >
        Edit Rating
      </Button>
      <ReRateApplicationModal applicationId={props.applicationId} modalRef={modalRef} />
    </>
  );
}

function ReRateApplicationModal(props: { applicationId: number; modalRef: any }) {
  const [rating, setRating] = useState(null);
  const [sliderValue, setSliderValue] = useState(null);
  const [radioSelected, setRadioSelected] = useState(null);
  const [checkboxNotes, setCheckboxNotes] = useState(null);
  const [allowNext, setAllowNext] = useState(true);
  const notesRef = useRef(null);
  const concernNotesRef = useRef(null);
  const { data, error, loading } = useFind(Application, props.applicationId, [
    'rating',
    'rating',
    'concern',
    'concernLevel',
    'concernNote',
    'note',
    ['dimensions', 'dimension', 'value', 'automaticNotes'],
  ]);
  useEffect(() => {
    if (data?.application?.rating) {
      setRating(data?.application?.rating);
    }
  }, [data]);

  const handleRating = (newRating: Rating) => {
    setRating(newRating);
  };

  const showErrorMessage = (errorMessage?: string) => {
    window.Alerts.alert(`Oops, something wrong happened: ${errorMessage || 'no details'}`);
  };

  const handleCheckboxNoteChange = e => {
    setCheckboxNotes(e);
  };

  const computeBonusNotes = (checkboxes, notes) => {
    return checkboxes?.length > 0 ? checkboxes.join(' ').concat(` ${notes}`) : notes;
  };

  const computeSliderValue = () => {
    return sliderValue?.length > 0 ? sliderValue[0] - 3 : null;
  };

  const onConfirm = () => {
    const variables = {
      applicationId: props.applicationId,
      dimensionRatings: rating.dimensions
        .filter(e => e.dimension !== 'bonus')
        .map(d => ({
          name: d.dimension,
          rating: d.value,
        })),
      notes: notesRef.current.value,
      concern: radioSelected,
      concernLevel: computeSliderValue(),
      concernNote: computeBonusNotes(checkboxNotes, concernNotesRef.current?.value),
    };
    const mutation: DocumentNode = GQL_MUTATION_RE_RATE;
    apolloClient
      .mutate({ mutation, variables })
      .then(({ data: reRateData }) => {
        const result = reRateData.application.reRate;
        if (result.errors.length === 0) {
          window.Alerts.notice('The rating was successfully updated!');
          window.location.reload();
        } else {
          const errors = result.errors.map(e => e.message).join(' | ');
          this.showErrorMessage(errors);
        }
      })
      .catch(showErrorMessage);
  };

  const renderContent = () => {
    if (loading) {
      return <Spinner />;
    }

    if (error) {
      return (
        <>
          OOps, something went wrong: <strong>{error.message}</strong>
        </>
      );
    }

    if (rating) {
      const notes = rating.note;

      return (
        <div className={styles.containerPanel}>
          <p>
            <strong>NOTE:</strong> By updating any dimension rating, the automatic notes created on
            curation will be cleared. If you want to inform the employer, please fill in the Notes
            below.
          </p>
          <div className={styles.container}>
            <DimensionsContainer rating={rating} handleRating={handleRating} />
          </div>
          <div className={styles.bonusSection}>
            <BonusAndConcerns
              setAllowNext={setAllowNext}
              values={{
                slider: rating.concernLevel,
                selected: rating.concern,
                concern: rating.concernNote,
              }}
              handleCheckboxNoteChange={handleCheckboxNoteChange}
              notesRef={concernNotesRef}
              setSliderValue={setSliderValue}
              setRadioSelected={setRadioSelected}
            />
          </div>
          <div className={styles.container}>
            <NotesContainer notes={notes} notesRef={notesRef} />
          </div>
        </div>
      );
    }

    return <>This is embarrassing... but we're not able to load any information</>;
  };

  return (
    <Modal
      className={styles.modal}
      defaultOpen={false}
      ref={props.modalRef}
      title='Edit Rating'
      buttonsLayout={ButtonsLayout.OkCancel}
      buttonName={'Confirm'}
      buttonColor={'puertoRico'}
      buttonOnClick={onConfirm}
      cancelButton={true}
      disableButton={!allowNext || loading || error != null}
      disableOutsideClose
    >
      {renderContent()}
    </Modal>
  );
}

function NotesContainer(props: { notes: string; notesRef: any }) {
  return (
    <>
      <h3>Notes:</h3>
      <p>The employer and the candidate will have access to this.</p>
      <Textarea ref={props.notesRef} defaultValue={props.notes} rows={10} />
    </>
  );
}
