import React from 'react';

const styles = require('./FooterBanner.module.scss');

interface Props {
  title?: string | JSX.Element;
  subtitle?: string;
  info?: string;
  primaryButton?: JSX.Element;
  secondaryButton?: JSX.Element;
  secondaryButtonText: string;
}

const FooterBanner = (props: Props) => {
  const { title, subtitle, info, primaryButton, secondaryButton } = props;

  return (
    <div className={styles.footerBanner}>
      <div className={styles.title}>{title}</div>
      <div className={styles.subtitle}>{subtitle}</div>
      <div className={styles.info}>{info}</div>
      <div className={styles.buttonContainer}>
        {primaryButton}
        {secondaryButton}
      </div>
    </div>
  );
};

export default FooterBanner;
