import { camelizeObj } from 'lib/object';

interface CompanyProps {
  data: {
    id: string;
    type: string;
    attributes: {
      company_values: string[][];
      cover_photo: string;
      description: string;
      full_remote: boolean;
      location: {
        city?: string;
        country?: string;
        country_code?: string;
      };
      logo?: string;
      max_values: number;
      name: string;
      numbers: string[];
      perks: string[];
      short_pitch?: string;
      team_description?: string;
      website_url?: string;
      values: string[];
    };
    relationships: any;
  };
  included: {
    id: string;
    type: string;
    attributes: {
      id: number;
      year: number;
      description: string;
    };
  }[];
}

export function CompanyInfoFormUtils(props: CompanyProps) {
  const { included } = props;
  const milestones = () =>
    included.filter(({ type }) => type === 'company_milestone').map(({ attributes }) => attributes);
  const attrs = props.data.attributes;
  const offices = () =>
    included
      .filter(({ type }) => type === 'company_office')
      .map(({ attributes }) => camelizeObj(attributes));
  return {
    companyValues: attrs.company_values,
    coverPhoto: attrs.cover_photo,
    companySize: findModel('company_size', included),
    description: attrs.description,
    fullRemote: attrs.full_remote,
    id: props.data.id,
    industry: findModel('industry', included),
    location: {
      city: attrs.location.city,
      country: attrs.location.country,
      countryCode: attrs.location.country_code,
    },
    logo: attrs.logo,
    maxValues: attrs.max_values,
    milestones: milestones(),
    name: attrs.name,
    numbers: attrs.numbers,
    offices: offices(),
    perks: attrs.perks,
    shortPitch: attrs.short_pitch,
    teamDescription: attrs.team_description,
    websiteUrl: attrs.website_url,
    values: attrs.values,
  };
}

function findModel(type, included) {
  const data = included.find(({ type: type2 }) => type === type2);
  return data && data.attributes;
}

interface IndustryProps {
  data: {
    id: string;
    type: string;
    attributes: {
      id: string;
      name: string;
    };
  }[];
}

export function IndustryFormUtils(props: IndustryProps) {
  return props.data.map(item => item.attributes);
}

interface CompanySizeProps {
  data: {
    id: string;
    type: string;
    attributes: {
      id: string;
      label: string;
    };
  }[];
}

export function CompanySizesUtils(props: CompanySizeProps) {
  return props.data.map(item => item.attributes);
}
