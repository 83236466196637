import * as React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider, Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import { apolloClient } from 'lib/graphql';
import Modal from 'components/general/modal/modal';
import TextInput from 'components/form/text/text';
import { ButtonsLayout } from '../general/modal/modal';
import Button from 'lj_shared/button/button';
import AdvancedSkillsInput from './advanced_skills_input';
import RadioButtons from 'components/form/radio_buttons/radio_buttons';

interface Props {
  selectedTags: any;
  nameIsChosen: boolean;
}

enum AcceptActions {
  Create,
  Alternate,
  Correct,
}

interface State {
  tagName: string;
  selectedTags: any;
  nameIsInvalidError: boolean;
  nameCannotBeEmptyError: boolean;
  nameCannotBeLongError: boolean;
  nameIsChosen: boolean;
  action: AcceptActions;
}

const CREATE_GQL = gql`
  mutation ($name: String!, $selectedTags: [String!]!, $isAlternate: Boolean!) {
    advancedCreateTag(name: $name, selectedTags: $selectedTags, isAlternate: $isAlternate) {
      resource {
        description
        id
        name
      }
      errors {
        field
        message
      }
    }
  }
`;

export default class AdvancedAcceptModal extends React.Component<Props, State> {
  modalRef: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      ...props,
      tagName: '',
      nameIsInvalidError: false,
      nameCannotBeEmptyError: false,
      nameCannotBeLongError: false,
      action: null,
    };
    this.modalRef = React.createRef();

    this.handleCreateTag = this.handleCreateTag.bind(this);
    this.handleSelectTag = this.handleSelectTag.bind(this);
    this.handleActions = this.handleActions.bind(this);
  }
  handleCreateTag(proposedTagName) {
    this.setState({
      tagName: proposedTagName,
      action: AcceptActions.Create,
      nameIsChosen: true,
    });
    this.validateTagName(proposedTagName);
  }

  handleSelectTag(proposedTagName) {
    this.setState({
      tagName: proposedTagName.label,
      nameIsChosen: true,
      action: null,
    });
  }

  handleActions(value, name) {
    this.setState({
      action: value,
    });
  }

  renderNewInput() {
    const skillLabelError = message => (
      <div className='ld-alert ld-error-alert title-error'>{message}</div>
    );
    return (
      <div>
        {this.state.nameCannotBeEmptyError && skillLabelError("This field can't be empty.")}
        {this.state.nameCannotBeLongError &&
          skillLabelError(`Skill name cannot be longer than 50 characters.`)}
        {this.state.nameIsInvalidError && skillLabelError("Skill name cannot contain ',' or ';'.")}
        {!this.state.nameCannotBeEmptyError &&
          !this.state.nameCannotBeLongError &&
          !this.state.nameIsInvalidError &&
          this.renderActions([
            {
              value: AcceptActions.Create,
              label: `Accept  ${this.getSelectedTagsAsString()} as "${
                this.state.tagName
              }" (this skill will be created)`,
            },
          ])}
      </div>
    );
  }

  renderDefaultInput(mutate, { loading = false }) {
    return (
      <div>
        <div style={{ width: '50%', float: 'left', marginRight: '2rem' }}>
          <AdvancedSkillsInput
            errors={[]}
            createTagCallback={this.handleCreateTag}
            selectTagCallback={this.handleSelectTag}
          />
        </div>
        <Button
          buttonColor='tuftsBlue'
          isButton={true}
          disabled={
            this.state.action === null ||
            this.state.nameIsInvalidError ||
            this.state.nameCannotBeEmptyError ||
            this.state.nameCannotBeLongError ||
            loading
          }
          onClick={e => this.handleSubmit(mutate)(e)}
        >
          {loading ? 'Accepting...' : 'Accept'}
        </Button>
      </div>
    );
  }

  renderSecondaryInputs() {
    return this.state.action === AcceptActions.Create
      ? this.renderNewInput()
      : this.renderAlternateAndCorrectActions();
  }

  renderAlternateAndCorrectActions() {
    const selectedTags = this.getSelectedTagsAsString();
    return this.renderActions([
      {
        value: AcceptActions.Alternate,
        label: `Accept ${selectedTags} as alternate(s) to "${this.state.tagName}"`,
      },
      {
        value: AcceptActions.Correct,
        label: `Accept ${selectedTags} as "${this.state.tagName} (this skill already exists)`,
      },
    ]);
  }

  renderActions(options) {
    return (
      <div style={{ marginTop: '1rem' }}>
        <RadioButtons
          selectedValue={this.state.action}
          options={options}
          onChange={this.handleActions}
        />
      </div>
    );
  }

  render() {
    return (
      <ApolloProvider client={apolloClient}>
        <Mutation
          mutation={CREATE_GQL}
          // eslint-disable-next-line react/no-children-prop
          children={this.renderModal}
          onCompleted={this.handleSubmitSuccess}
        />
      </ApolloProvider>
    );
  }

  getSelectedTagsAsString() {
    return this.state.selectedTags
      .map(tag => {
        return `"${tag.name}"`;
      })
      .join(', ');
  }

  renderModal = (mutate, { loading = false }) => {
    return (
      <Modal
        title={`Advanced Accept Proposed Tag`}
        defaultOpen={true}
        ref={this.modalRef}
        cancelButton={loading}
        buttonColor={'tuftsBlue'}
        buttonsLayout={ButtonsLayout.CancelOnly}
        cancelButtonTitle={'Close'}
      >
        <>
          <h4>Selected skill(s):</h4>
          <ul>
            {this.state.selectedTags.map(tag => {
              return <li key={tag.name}>{tag.name}</li>;
            })}
          </ul>
          <p>
            Here you may rename these skills, choose an already existing one or add them as
            alternate names for another skill.
            <br />
            Skills will count as accepted to the proposer.
          </p>
          {this.renderDefaultInput(mutate, { loading })}
          {this.state.nameIsChosen && this.renderSecondaryInputs()}
        </>
      </Modal>
    );
  };

  static show(selectedTags = []) {
    const root = $('<div id="advanced-accept-modal-container"></div>');

    $('body').append(root);

    ReactDOM.render(
      <AdvancedAcceptModal selectedTags={selectedTags} nameIsChosen={false} />,
      root[0]
    );
  }

  validateTagName(name) {
    let validationError = false;
    if (!name.trim()) {
      this.setState({ nameCannotBeEmptyError: true });
      validationError = true;
    } else {
      this.setState({ nameCannotBeEmptyError: false });
    }

    if (name.length > 50) {
      this.setState({ nameCannotBeLongError: true });
      validationError = true;
    } else {
      this.setState({ nameCannotBeLongError: false });
    }

    const invalidChars = [',', ';'];
    if (new RegExp(invalidChars.join('|')).test(name)) {
      this.setState({ nameIsInvalidError: true });
      validationError = true;
    } else {
      this.setState({ nameIsInvalidError: false });
    }

    return !validationError;
  }

  handleSubmit = mutation => event => {
    event.preventDefault();

    const tagName = this.state.tagName;
    if (!this.validateTagName(tagName)) {
      return;
    }

    const variables = {
      name: tagName,
      selectedTags: this.state.selectedTags.map(tag => {
        return tag.name;
      }),
      isNew: this.state.action === AcceptActions.Create,
      isAlternate: this.state.action === AcceptActions.Alternate,
    };

    mutation({ variables }).then(response => {
      if (response === null || response.data === null || response.data.advancedCreateTag === null) {
        // Uncontrolled error
        window.Alerts.alert('Oops, something went wrong.');
      } else if (
        response.data.advancedCreateTag.errors != null &&
        response.data.advancedCreateTag.errors.length !== 0
      ) {
        // Controlled error
        window.Alerts.alert(response.data.advancedCreateTag.errors[0].message);
      } else {
        const proposedTag = response.data.advancedCreateTag.resource;
      }
    });
  };

  handleSubmitSuccess = () => {
    this.modalRef.current.close();
    window.location.reload(); // to reload the updated skill list. There might be a better way.
    window.Alerts.notice('Skill accepted successfully');
  };
}
