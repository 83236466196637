import * as React from 'react';
import InlineIcon from 'components/general/inline_icon/inline_icon';
const copyIcon = require('svg/copy-squares.svg');
const styles = require('./copy_button.module.scss');

interface Props {
  content: string;
}

interface State {
  showTooltip: boolean;
}

export default class CopyButton extends React.Component<Props, State> {
  contentRef: any;

  constructor(props) {
    super(props);
    this.state = { showTooltip: false };
    this.contentRef = React.createRef();
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const node = this.contentRef.current;
    const selection = window.getSelection();
    const range = document.createRange();

    node.style.display = 'inline'; // Node needs to be visible to be copied.
    range.selectNode(node.firstChild); // Using child to avoid issues with newlines.
    selection.empty();
    selection.addRange(range);

    if (document.execCommand('copy')) {
      this.setState({ showTooltip: true });

      setTimeout(() => {
        this.setState({ showTooltip: false });
      }, 2000);
    }

    node.style.display = 'none';
    selection.empty();
  }

  render() {
    return (
      <>
        {this.state.showTooltip && (
          <div className={styles.tooltip}>Content copied to your clipboard.</div>
        )}
        <button onClick={this.handleClick}>
          <InlineIcon path={copyIcon} alt='copysquares' />
        </button>
        <span className={styles.content} ref={this.contentRef}>
          <span>{this.props.content}</span>
        </span>
      </>
    );
  }
}
