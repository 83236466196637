import * as React from 'react';
import ApplicationPageNotes, { Props } from './application_page_notes';
import { shallow, ShallowWrapper } from 'enzyme';

const createTestProps = moreProps => {
  return {
    getNotesUrl: '',
    postNoteUrl: '',
    destroyNoteUrl: '',
    applicationId: 1,
    ...moreProps,
  };
};

let props: Props;
let wrapped: ShallowWrapper;

describe('Application Page Notes component', () => {
  beforeEach(() => {
    props = createTestProps({});
    wrapped = shallow(<ApplicationPageNotes {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
