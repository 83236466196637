import * as React from 'react';
import classnames from 'classnames';
import InlineIcon from '../../general/inline_icon/inline_icon';

const styles = require('./form_error.module.scss');
const xIcon = require('iconic/x-transparent.svg');

interface Props {
  text: string | JSX.Element;
}

const FormError = (props: Props) => {
  return (
    <div className={classnames(styles.error, 'lj-error')}>
      <InlineIcon className={styles.icon} alt='x' path={xIcon} />
      <span>{props.text}</span>
    </div>
  );
};

export default FormError;
