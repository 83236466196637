import * as React from 'react';
import Selectize from '../selectize/selectize';

const styles = require('./job_opening_filter.module.scss');

interface Props {
  closeIcon?: JSX.Element;
  itemRender: Function;
  items: { id: number; title: string; value: number; group: string }[];
  onChange: Function;
  openIcon?: JSX.Element;
  optGroups: { groupValue: number; groupLabel: string }[];
  selected: number[];
}

export default class JobOpeningFilter extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Selectize
        className={styles.main}
        items={this.props.items}
        itemRender={this.props.itemRender}
        selectedRender={this.props.itemRender}
        optGroups={this.props.optGroups}
        placeholder='e.g. Ux designer'
        onChange={this.props.onChange}
        selected={this.props.selected}
        selectizeOptions={{ searchField: 'title' }}
      />
    );
  }
}
