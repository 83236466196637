import Button from 'lj_shared/button/button';
import React from 'react';

const styles = require('./Navbar.module.scss');

interface Item {
  label: string;
}

export interface Props {
  active: Item;
  companyId: number;
  currentUserCompanyId: number;
  items: Item[];
  onClick: Function;
}

export default function Navbar({ active, companyId, currentUserCompanyId, items, onClick }: Props) {
  const renderItem = (item: Item) => {
    const { label } = item;
    const navItemStyle = item === active ? styles.selectedNavItem : styles.navItem;

    return (
      <button className={navItemStyle} key={label} onClick={() => onClick(item)}>
        {label}
      </button>
    );
  };

  return (
    <div className={styles.navBar}>
      <div className={styles.navSection}>{items.map(renderItem)}</div>
      {currentUserCompanyId === companyId && (
        <Button
          isButton={false}
          buttonUrl={window.Routes.company_edit()}
          buttonColor='ripePlum'
          buttonSize='small'
        >
          Edit
        </Button>
      )}
    </div>
  );
}
