import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import ApplyButton from './ApplyButton';
import Button from 'lj_shared/button/button';
import AcceptAndRejectHandshake from 'components/handshakes/accept_and_reject_handshake';

window.Routes = {
  job_ad_new_application: () => '',
  new_talent_user_registration: () => '',
  job_ads: () => '',
};

const jobDummy = require('components/jobs_page/fixtures/job_ad_dummy.json');
const createProps = () => ({
  applications: [{ jobId: 123, state: -2 }],
  currentUser: { id: 3, type: 'TalentUser' },
  handshake: null,
  jobAd: jobDummy,
  referralId: 12,
});

let props;
let wrapped: ShallowWrapper;

describe('Your description here', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<ApplyButton {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('should have an apply button', () => {
    expect(wrapped.find(Button)).toHaveLength(1);
  });

  it('should have an accept/reject handshake button', () => {
    props = {
      applications: [],
      currentUser: { id: 3, type: 'TalentUser' },
      handshake: { data: { attributes: { id: 123, cvs: null } } },
      jobAd: jobDummy,
      referralId: 12,
    };
    wrapped = shallow(<ApplyButton {...props} />);
    expect(wrapped.find(AcceptAndRejectHandshake)).toHaveLength(1);
  });

  it('should not have button', () => {
    props = {
      applications: [],
      currentUser: { id: 3, type: 'CompanyUser' },
      handshake: null,
      jobAd: jobDummy,
      referralId: 12,
    };
    wrapped = shallow(<ApplyButton {...props} />);
    expect(wrapped.find(Button)).toHaveLength(0);
  });
});
