import React, { useRef } from 'react';
import InlineIcon from 'components/general/inline_icon/inline_icon';
import classNames from 'classnames';
import Icon, { Size } from 'components/general/icon/icon';
import Modal from 'components/general/modal/modal';
import Button from 'lj_shared/button/button';
import SmallProfileDisplayItem from 'components/smallProfileDisplay/SmallProfileDisplayItem';

const pencilIcon = require('iconic/pencil.svg');
const styles = require('./horizontal_card_entry.module.scss');

export default function HorizontalCardEntry(props) {
  const colorClass = props.ljColor ? styles[`color-${props.ljColor}`] : styles['color-flamingo'];

  function renderSubtitle() {
    if (!props.subtitle) {
      return null;
    }
    if (props.subtitle === 'fulltime') {
      return (
        <div className={styles.subtitle}>
          <span>{`| full-time`}</span>
        </div>
      );
    } else {
      return (
        <div className={styles.subtitle}>
          <span>{`| ${props.subtitle}`}</span>
        </div>
      );
    }
  }

  const handleClick = () => {
    props.openModal(props.type, props.id);
  };

  const modalRef = useRef(null);

  const hasValidUrl = () => {
    return props.url && props.url !== '/files/original/missing.png';
  };

  const hasCategories = () => {
    return props.categories && props.categories.length > 0;
  };

  function renderModalContent() {
    return (
      <Modal
        defaultOpen={false}
        ref={modalRef}
        className={styles.modalStyle}
        title={'Are you sure?'}
      >
        <p className={styles.topText}>
          Are you sure you want to delete this {props.type.split('_').join(' ')} entry?
        </p>
        <p>This cannot be undone.</p>
        <div className={styles.modalButtons}>
          <Button
            buttonColor={'tuftsBlue'}
            onClick={() => {
              props.handleDelete(props.id);
              modalRef.current.close();
            }}
          >
            I'm Sure
          </Button>
          <Button
            buttonColor={'light'}
            onClick={() => {
              modalRef.current.close();
            }}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    );
  }

  function renderEditButton() {
    if (props.public || props.publicProfilePage) {
      return null;
    }
    return (
      <div className={styles.editButton} onClick={() => handleClick()}>
        <InlineIcon alt={'Edit'} path={pencilIcon} />
      </div>
    );
  }

  function renderDeleteButton() {
    if (props.public || props.publicProfilePage) {
      return null;
    }
    return (
      <div className={styles.deleteButton} onClick={() => modalRef.current.open()}>
        <Icon name={'trash'} />
      </div>
    );
  }

  function renderUrl() {
    if (props.public || props.publicProfilePage) {
      return null;
    }
    return (
      <div className={classNames(styles.urlBox, colorClass)}>
        <a href={props.url} target='_blank' rel='noopener noreferrer' className={styles.linkItem}>
          <Icon
            key={'categories'}
            name={'shareBoxed'}
            color={props.ljColor}
            size={Size.QuiteSmall}
          />
          <span>Open Certificate</span>
        </a>
      </div>
    );
  }

  function renderCategories() {
    return (
      <div className={styles.categoriesList}>
        {props.categories.map((item, index) => {
          return (
            <div className={styles.categories} key={`category-${index}`}>
              <SmallProfileDisplayItem title={item.label} color={props.ljColor} />
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className={styles.contentItem}>
      <div className={styles.entryHeader}>
        <div className={styles.leftContent}>
          <div
            className={
              props.blurred && props.type === 'education'
                ? classNames(styles.title, colorClass, styles.blur)
                : classNames(styles.title, colorClass)
            }
          >
            <span>{props.title}</span>
          </div>
          {renderSubtitle()}
        </div>
        <div className={styles.rightContent}>
          <div className={styles.rightButtons}>
            {renderDeleteButton()}
            {renderEditButton()}
          </div>
          {props.date && (
            <div className={styles.date}>
              <span>{props.date.join(' - ')}</span>
            </div>
          )}
        </div>
      </div>
      <div
        className={
          props.blurred && props.type === 'professional_experience'
            ? classNames(styles.contentTitle, styles.blur)
            : styles.contentTitle
        }
      >
        {props.contentTitle}
      </div>
      {hasCategories() && renderCategories()}
      <div className={styles.content}>{props.content}</div>
      {hasValidUrl() && renderUrl()}
      {renderModalContent()}
    </div>
  );
}
