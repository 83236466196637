import React, { useEffect, useRef, useState } from 'react';
import Icon from 'components/general/icon/icon';
import { getJSON } from 'lib/request';
import { CompanyInvoice } from '__models__/company_invoice';
import FormInput from 'components/general/form_input/form_input';
import Select from 'components/form/select/select';

const styles = require('./company_invoices.module.scss');

interface Props {
  statuses: {
    value: string;
    label: string;
  };
}

export default function CompanyInvoices(props: Props) {
  const date = useRef(null);
  const dueDate = useRef(null);
  const [status, setStatus] = useState(null);
  const [invoices, setInvoices] = useState<CompanyInvoice[]>(null);
  const statuses = props.statuses;

  useEffect(() => {
    searchInvoices();
  }, [status, setStatus]);

  const callMagnifinanceAPI = (event, id) => {
    event.preventDefault();
    window.toggleSpinner(true);
    getJSON(window.Routes.company_company_invoice_fetch_pdf(id), data => {
      if (data.url) {
        window.open(data.url, '_blank');
      }
      window.toggleSpinner(false);
    });
  };

  const searchInvoices = () => {
    window.toggleSpinner(true);
    const params = {
      date: date.current.value,
      dueDate: dueDate.current.value,
      status: status?.value,
    };
    getJSON(window.Routes.search_company_company_invoices(params), data => {
      if (data.invoices) {
        setInvoices(data.invoices);
      }
      window.toggleSpinner(false);
    });
  };

  return (
    <div>
      <form className={styles.form}>
        <FormInput className={styles.formInput} label='Status'>
          <Select
            name='status'
            placeholder='Select a status'
            options={statuses}
            color='silverSand'
            value={status}
            onChange={setStatus}
            wrapperClassName={styles.input}
          />
        </FormInput>
        <FormInput className={styles.formInput} label='Invoice Date'>
          <input onChange={searchInvoices} className={styles.dateInput} ref={date} type='month' />
        </FormInput>
        <FormInput className={styles.formInput} label='Due Date'>
          <input
            onChange={searchInvoices}
            className={styles.dateInput}
            ref={dueDate}
            type='month'
          />
        </FormInput>
      </form>

      <table className={styles.table}>
        <thead className={styles.head}>
          <tr>
            <th>Invoice ID</th>
            <th>Date</th>
            <th>Description</th>
            <th>Value</th>
            <th>Status</th>
            <th>Due date</th>
            <th>File</th>
          </tr>
        </thead>
        <tbody>
          {invoices &&
            invoices.map(invoice => {
              const empty = '---';
              return (
                <tr key={invoice.id} className={styles.row}>
                  <td data-label='Invoice ID'>{invoice.invoiceId}</td>
                  <td data-label='Date'>{(invoice.date && invoice.date.toString()) || empty}</td>
                  <td data-label='Description'>{invoice.description || empty}</td>
                  <td data-label='Value'>{invoice.value || empty}</td>
                  <td data-label='Status'>{invoice.status || empty}</td>
                  <td data-label='Due date'>
                    {(invoice.dueDate && invoice.dueDate.toString()) || empty}
                  </td>
                  <td data-label='File'>
                    <a onClick={e => callMagnifinanceAPI(e, invoice.id)}>
                      <Icon name='export' color='ripePlum' />
                    </a>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
