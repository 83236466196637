import React from 'react';
import PlacementCard from './placement_card';
import { render } from '@testing-library/react';

window.Routes = { dashboard_placement: jest.fn() };

describe('PlacementCard', () => {
  it('renders the card', () => {
    const data = {
      companyName: 'FooCorp',
      endDate: '2015-01-01',
      expectedDuration: 'One month',
      id: '1',
      projectName: 'The Foo Project',
      startDate: '2015-01-20',
    };
    const { queryByText } = render(<PlacementCard {...data} />);

    for (const [key, value] of Object.entries(data)) {
      if (key !== 'id') {
        expect(queryByText(value, { exact: false })).toBeTruthy();
      }
    }
  });
});
