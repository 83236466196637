/** @jsx jsx */
import { jsx } from '@emotion/core';
import classNames from 'classnames';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Button from 'lj_shared/button/button';
import InlineIcon from '../inline_icon/inline_icon';
import Icon, { Size } from 'components/general/icon/icon';
const styles = require('./modal.module.scss');

export enum ButtonsLayout {
  CancelOk,
  OkCancel,
  CancelOnly,
  OkOnly,
}

export enum ModalSize {
  Small = 'modalSmall',
  Big = 'modalBig',
}

export interface Styles {
  closeIcon?: any;
  header?: any;
}

export interface Props {
  secondaryContent?: string | JSX.Element;
  secondaryTitle?: string | JSX.Element;
  buttonClass?: string;
  buttonColor?: string;
  buttonIcon?: string;
  buttonIconStyle?: string;
  buttonName?: string;
  buttonOnClick?: any;
  buttonsLayout?: ButtonsLayout;
  cancelButton?: boolean;
  cancelButtonColor?: string;
  cancelButtonTitle?: string;
  centerOnPage?: boolean;
  className?: string;
  defaultOpen?: boolean;
  disableButton?: boolean;
  disableClose?: boolean;
  disableOutsideClose?: boolean;
  forceScroll?: boolean;
  iconAlt?: string;
  iconData?: Object;
  noOverflow?: boolean;
  noPaddingMobile?: boolean;
  onClose?: Function;
  open?: boolean;
  styles?: Styles;
  title: string | JSX.Element;
  modalForm?: boolean;
  onSubmitHandler?: any;
  isButton?: boolean;
  buttonType?: 'button' | 'reset' | 'submit';
  roundButtons?: boolean;
  size?: string;
  otherButtonClassesOnly?: boolean;
  children: React.ReactNode;
  id?: string;
}

interface State {
  open: boolean;
  scrolling: boolean;
}

export default class Modal extends React.Component<Props, State> {
  readonly OPEN_BODY_CLASS = 'lj-modal-open';
  readonly modalContainerRef: any;
  readonly rootRef: any;

  constructor(props) {
    super(props);
    const { defaultOpen } = props;
    const open = typeof defaultOpen === 'boolean' ? defaultOpen : this.props.open;
    this.state = { open, scrolling: false };
    this.handleClose = this.handleClose.bind(this);
    this.modalContainerRef = React.createRef();
    this.rootRef = React.createRef();
    this.handleScroll = this.handleScroll.bind(this);
  }

  static getDerivedStateFromProps({ open }, prevState) {
    if (typeof open === 'boolean' && open !== prevState.open) {
      return { open };
    } else {
      return null;
    }
  }

  isOpen() {
    return this.state.open;
  }

  open() {
    this.setState({ open: true });
  }

  close(fireEvents = true) {
    if (this.state.open) {
      this.setState({ open: false });
      if (fireEvents && this.props.onClose) this.props.onClose();
    }
  }

  componentDidMount() {
    this.updateBody();
  }

  componentDidUpdate(prevProps) {
    this.updateBody();
  }

  componentWillUnmount() {
    window.document.body.classList.remove(this.OPEN_BODY_CLASS);
  }

  handleClose(event) {
    // We need this test to avoid closing when the user clicked something inside
    // the modal.
    if (event.currentTarget !== this.rootRef.current || event.currentTarget === event.target) {
      event.preventDefault();
      this.close();
    }
  }

  handleScroll(e) {
    if (this.props.secondaryContent) {
      // Detect scroll after 5px of scrolling and toggle headers
      // 5px before reaching the second container
      const effectsToggleTolerance = 5;
      const mainTitle = document.getElementById('mainTitle');
      const secondaryTitle = document.getElementById('secondaryTitle');
      const secondaryContent = document.getElementById('secondaryContent');
      const header = document.getElementById('header');
      const scrollTop = e.target.scrollTop;
      const headerToggleHeight =
        secondaryContent.offsetTop - header.offsetHeight - effectsToggleTolerance;

      if (scrollTop >= headerToggleHeight) {
        mainTitle.style.opacity = '0';
        secondaryTitle.style.opacity = '1';
      } else {
        mainTitle.style.opacity = '1';
        secondaryTitle.style.opacity = '0';
      }

      return this.setState({ scrolling: scrollTop >= effectsToggleTolerance });
    }
  }

  render() {
    const content = this.state.open ? this.renderModal() : null;
    return ReactDOM.createPortal(content, window.document.body);
  }

  renderModal() {
    const propsStyles = this.props.styles || {};
    const closeIconStyles = propsStyles.closeIcon || {};
    const modalClass = classNames(styles.modal, this.props.className, {
      [styles[this.props.size]]: this.props.size,
      [styles.withSecondaryContent]: this.props.secondaryContent,
    });
    const outsideClose = !(this.props.disableOutsideClose || this.props.disableClose);
    const contentStyle = this.props.forceScroll
      ? styles.contentWithScroll
      : this.props.noOverflow
      ? styles.contentNoOverflow
      : styles.content;
    let firstButton;
    let secondButton;

    if (this.props.buttonsLayout === ButtonsLayout.CancelOk) {
      firstButton = this.renderCancelButton();
      secondButton = this.renderOkButton();
    } else if (this.props.buttonsLayout === ButtonsLayout.OkCancel) {
      firstButton = this.renderOkButton();
      secondButton = this.renderCancelButton();
    } else if (this.props.buttonsLayout === ButtonsLayout.OkOnly) {
      firstButton = this.renderOkButton();
      secondButton = null;
    } else {
      firstButton = this.renderCancelButton();
      secondButton = null;
    }
    return (
      <div
        id={this.props.id}
        className={classNames(styles.root, {
          [styles.noPaddingMobile]: this.props.noPaddingMobile,
          [styles.centerOnPage]: this.props.centerOnPage,
        })}
        onClick={outsideClose ? this.handleClose : undefined}
        ref={this.rootRef}
      >
        <div className={modalClass} ref={this.modalContainerRef}>
          <div
            id='header'
            className={classNames(styles.header, { [styles.shadow]: this.state.scrolling })}
          >
            <div id='mainTitle' className={styles.title}>
              {this.props.title}
            </div>
            {this.props.secondaryTitle && (
              <div id='secondaryTitle' className={classNames(styles.title, styles.secondaryTitle)}>
                {this.props.secondaryTitle}
              </div>
            )}
            {!this.props.disableClose && (
              <button onClick={this.handleClose} className={styles.closeButton}>
                <Icon
                  color='silverSand'
                  css={closeIconStyles}
                  name='close'
                  size={this.props.size === ModalSize.Big ? Size.MediumBig : Size.Big}
                />
              </button>
            )}
          </div>
          {this.renderModalType(contentStyle, firstButton, secondButton)}
        </div>
      </div>
    );
  }

  renderModalType(contentStyle, firstButton, secondButton) {
    if (this.props.modalForm) {
      return (
        <form onSubmit={this.props.onSubmitHandler}>
          {this.modalContent(contentStyle)}
          <div className={styles.footer}>
            {firstButton}
            {secondButton}
          </div>
        </form>
      );
    } else {
      return (
        <React.Fragment>
          {this.modalContent(contentStyle)}
          <div className={styles.footer}>
            {firstButton}
            {secondButton}
          </div>
        </React.Fragment>
      );
    }
  }

  modalContent(contentStyle) {
    return (
      <div className={contentStyle} onScroll={this.handleScroll}>
        {this.props.children}
        {this.props.secondaryContent && (
          <div className={styles.secondaryContentContainer}>
            <div id='secondaryContentTitle' className={styles.secondaryContentHeader}>
              {this.props.secondaryTitle}
            </div>
            <div id='secondaryContent'>{this.props.secondaryContent}</div>
          </div>
        )}
      </div>
    );
  }

  renderCancelButton() {
    if (this.props.cancelButton != null) {
      return (
        <Button
          isRound={this.props.roundButtons}
          isButton={false}
          buttonColor='silverSand'
          buttonType='border'
          buttonSize='small'
          onClick={this.handleClose}
        >
          {this.props.cancelButtonTitle || 'Cancel'}
        </Button>
      );
    }
  }

  renderOkButton() {
    if (this.props.buttonName != null) {
      return (
        <Button
          isRound={this.props.roundButtons}
          isButton={this.props.isButton} // If we want to disable it, it must be a button
          disabled={this.props.disableButton}
          type={this.props.buttonType}
          buttonColor={this.props.buttonColor != null ? this.props.buttonColor : 'ripePlum'}
          buttonSize='small'
          onClick={this.props.buttonOnClick}
          otherClasses={this.props.buttonClass}
          otherClassesOnly={this.props.otherButtonClassesOnly}
        >
          {this.props.buttonIcon != null ? (
            <InlineIcon
              className={
                this.props.buttonIconStyle != null
                  ? this.props.buttonIconStyle
                  : styles.menuItemIcon
              }
              path={this.props.buttonIcon}
              alt={this.props.iconAlt}
              data={this.props.iconData}
            />
          ) : null}
          {this.props.buttonName}
        </Button>
      );
    }
  }

  updateBody() {
    const bodyClass = window.document.body.classList;

    if (this.state.open) {
      bodyClass.add(this.OPEN_BODY_CLASS);
    } else {
      bodyClass.remove(this.OPEN_BODY_CLASS);
    }
  }
}
