import React from 'react';
import Tooltip from 'components/general/tooltip/tooltip';
const styles = require('./group_tooltip.module.scss');

interface Props {
  className?: string;
  name: string;
}

export default function GroupTooltip(props: Props) {
  return (
    <Tooltip
      className={props.className}
      style={{
        backgroundColor: '#343434',
        color: 'white',
        fontSize: '0.625rem',
        lineHeight: '1.5',
      }}
      text={tooltipText(props.name)}
      textBelow={true}
    >
      <div className={styles.questionMark}>?</div>
    </Tooltip>
  );
}

export function tooltipText(title: string) {
  switch (title) {
    case 'Handpicked':
      return 'These candidates are suggested for you by your Talent Manager.';
    case 'Meet Requirements':
      return 'These candidates should fit most of your job requirements';
    case 'Worth Looking At':
      return 'These candidates miss some of the job requirements';
    case 'Probably Not A Match':
      return 'These candidates are missing critical elements of your job requirements';
  }
}
