import { useState, useRef } from 'react';
import { createContainer } from 'unstated-next';
import { apolloClient } from 'lib/graphql';
import {
  GET_LOCATION_RATING_AND_NOTES_GQL,
  resolveLocationVariables,
} from './utils/locationDimensionUtils';
import {
  GET_LANGUAGE_RATING_AND_NOTES_GQL,
  resolveLanguageNotesVariables,
  getLanguageProficiency,
} from './utils/languageDimensionUtils';
import { getInitialSkills } from './skills_dimension';
import {
  GET_EXPERIENCE_RATING_AND_NOTES_GQL,
  resolveExperienceVariables,
} from './utils/experienceDimensionUtils';
import { handleRequestError } from 'lib/request_deprecated';

function sidebarForm(initial) {
  // refs
  const backgroundYearsRef = useRef(null);
  const locationRef = useRef(null);
  const niceToHaveLanguageRef = useRef(null);
  const preferredLanguageRef = useRef(null);
  const ratingRef = useRef(null);
  const bonusConcernRef = useRef(null);
  const bonusConcernNotesRef = useRef(null);

  // state
  const [application, setApplication] = useState(initial.application);
  const [automaticNotesValues, setAutomaticNotes] = useState({
    info: { checkbox: [], note: '' },
  });
  const [bonusConcernValues, setBonusConcernValues] = useState({
    slider: 0,
    selected: null,
    concern: null,
  });
  const [currentLocation, setLocation] = useState({
    talent_location: initial.talentUser.location,
    talent_city: initial.talentUser.city,
    talent_country_code: initial.talentUser.country_code,
  });
  const [overallIsDirty, setOverallIsDirty] = useState(false);
  const [talentUser, setTalentUser] = useState(initial.talentUser);
  const [validWorkPermit, setWorkPermit] = useState(initial.application.resolve_work_permit);
  const [values, setValues] = useState({});
  const [overall, setOverall] = useState();
  const [valuesData, setValuesData] = useState({});
  const [skills, setSkills] = useState(getInitialSkills({ application, talentUser }));
  const [concern, setConcern] = useState(null);
  const [handpicked, setHandpicked] = useState(null);
  const [rejection, setRejection] = useState(false);

  // General
  function getInputValueData(ref) {
    const input = ref;
    return input.value;
  }

  function getInputValue() {
    const input = ratingRef.current;

    return input.value;
  }

  // Location
  const handleChangeTimeZone = timeZone => {
    const newDataValues = {
      ...valuesData,
      time_zone: timeZone,
      time_zone_negative: false,
    };
    setOverallIsDirty(true);
    setValuesData(prevState => ({ ...prevState, ...newDataValues }));
    computeLocationRatingAndNotes(newDataValues);
  };

  const handleChangeTimeZoneNegative = timeZone => {
    const newDataValues = {
      ...valuesData,
      time_zone_negative: timeZone,
      time_zone: false,
    };
    setOverallIsDirty(true);
    setValuesData(prevState => ({ ...prevState, ...newDataValues }));
    computeLocationRatingAndNotes(newDataValues);
  };

  const handleChangeWorkPermit = workPermit => {
    const newDataValues = {
      ...valuesData,
      work_permit: workPermit,
    };
    setOverallIsDirty(true);
    setWorkPermit(workPermit);
    setValuesData(prevState => ({ ...prevState, ...newDataValues }));
    computeLocationRatingAndNotes(newDataValues);
  };

  const handleChangeLocation = location => {
    let city;
    let country;
    let newDataValues;
    for (const local of location.address_components) {
      if (local.types.includes('country')) {
        country = local.short_name;
      } else if (local.types.includes('locality')) {
        city = local.short_name;
      }
    }

    if (location != null) {
      newDataValues = {
        ...valuesData,
        talent_location: location.formatted_address,
        talent_city: city,
        talent_country_code: country,
      };
    }
    setLocation({
      talent_location: location.formatted_address,
      talent_city: city,
      talent_country_code: country,
    });
    setOverallIsDirty(true);
    setValuesData(prevState => ({ ...prevState, ...newDataValues }));
    computeLocationRatingAndNotes(newDataValues);
  };

  const handleConcern = concernItem => setConcern(concernItem);

  const computeLocationRatingAndNotes = dataValues => {
    const locationVariables = resolveLocationVariables(
      dataValues,
      application.job_type,
      application.id
    );

    if (application.job_type) {
      apolloClient
        .query({
          query: GET_LOCATION_RATING_AND_NOTES_GQL,
          variables: locationVariables,
        })
        .then(result => {
          setValues(prevValues => ({
            ...prevValues,
            location: result.data.locationRatingAndNotesComputation.rating,
          }));
          setAutomaticNotes(prevValues => ({
            ...prevValues,
            location: result.data.locationRatingAndNotesComputation.notes,
          }));
        })
        .catch(e => {
          window.Alerts.alert('Oops, something went wrong.');
        });
    }
  };

  const getInitialLocationRating = () => {
    const newDataValues = {
      ...valuesData,
      talent_location: currentLocation['talent_location'],
      talent_city: currentLocation['talent_city'],
      talent_country_code: currentLocation['talent_country_code'],
      work_permit: validWorkPermit,
      time_zone: false,
      time_zone_negative: false,
    };
    setValuesData(prevValues => ({
      ...prevValues,
      talent_location: currentLocation['talent_location'],
      talent_city: currentLocation['talent_city'],
      talent_country_code: currentLocation['talent_country_code'],
      work_permit: validWorkPermit,
      time_zone: false,
      time_zone_negative: false,
    }));
    computeLocationRatingAndNotes(newDataValues);
  };

  // Language
  const handleLanguageChange = () => event => {
    let newDataValues;
    const input = ratingRef.current;
    const newValues = { ...values, [input.name]: getInputValue() };

    if (application.job_ad.nice_to_have_language) {
      newDataValues = {
        ...valuesData,
        [preferredLanguageRef.current.name]: getInputValueData(preferredLanguageRef.current),
        [niceToHaveLanguageRef.current.name]: getInputValueData(niceToHaveLanguageRef.current),
      };
    } else {
      newDataValues = {
        ...valuesData,
        [preferredLanguageRef.current.name]: getInputValueData(preferredLanguageRef.current),
      };
    }
    computeLanguageRatingAndNotes(newDataValues);
    setValues(newValues);
    setValuesData(prevState => ({ ...prevState, ...newDataValues }));
    setOverallIsDirty(true);
  };

  const computeLanguageRatingAndNotes = dataValues => {
    const languageNotesVariables = resolveLanguageNotesVariables(
      dataValues,
      application.job_ad.preferred_language,
      application.job_ad.nice_to_have_language
    );

    apolloClient
      .query({
        query: GET_LANGUAGE_RATING_AND_NOTES_GQL,
        variables: languageNotesVariables,
      })
      .then(result => {
        setValues(prevValues => ({
          ...prevValues,
          language: result.data.languageRatingAndNotesComputation.rating,
        }));
        setAutomaticNotes(prevValues => ({
          ...prevValues,
          language: result.data.languageRatingAndNotesComputation.notes,
        }));
      })
      .catch(e => {
        window.Alerts.alert('Oops, something went wrong.');
      });
  };

  function fillDefaultLanguageRating(language, language2 = null) {
    let proficiency2;
    let newDataValues;

    const proficiency = getLanguageProficiency(language, talentUser.languages);
    if (language2 != null) {
      proficiency2 = getLanguageProficiency(language2, talentUser.languages);
      newDataValues = {
        ...valuesData,
        preferred_language: proficiency,
        nice_to_have_language: proficiency2,
      };
      setValuesData(prevValues => ({
        ...prevValues,
        preferred_language: proficiency,
        nice_to_have_language: proficiency2,
      }));
    } else {
      newDataValues = {
        ...valuesData,
        preferred_language: proficiency,
      };
      setValuesData(prevValues => ({
        ...prevValues,
        preferred_language: proficiency,
      }));
    }
    computeLanguageRatingAndNotes(newDataValues);
  }

  // Skills

  function setSkillsNotes(notes) {
    setAutomaticNotes(prevValues => ({
      ...prevValues,
      skills: notes,
    }));
  }

  function setSkillsRating(rating) {
    setValues(prevValues => ({
      ...prevValues,
      skills: rating,
    }));
    setOverallIsDirty(true);
  }

  // Experience

  const handleExperienceChange = () => event => {
    const newDataValues = {
      ...valuesData,
      background_years: getInputValueData(backgroundYearsRef.current),
      background_checkbox: true,
      no_relevant_background: false,
    };
    computeExperienceRatingAndNotes(newDataValues);

    setValuesData(prevState => ({ ...prevState, ...newDataValues }));
    setOverallIsDirty(true);
  };

  const handleChangeBackgroundYears = backgroundCheckbox => {
    const newDataValues = {
      ...valuesData,
      background_checkbox: backgroundCheckbox,
      no_relevant_background: false,
    };
    setValuesData(prevState => ({ ...prevState, ...newDataValues }));
    if (valuesData['background_years']) {
      computeExperienceRatingAndNotes(newDataValues);
    }
  };

  const handleNoRelevantBackground = noRelevantBackground => {
    const newDataValues = {
      ...valuesData,
      no_relevant_background: noRelevantBackground,
      background_checkbox: false,
      same_role_performed: false,
      background_years: null,
    };
    setOverallIsDirty(true);
    setValuesData(prevState => ({ ...prevState, ...newDataValues }));
    computeExperienceRatingAndNotes(newDataValues);
  };

  const handleChangeSameRole = sameRole => {
    const newDataValues = {
      ...valuesData,
      same_role_performed: sameRole,
      no_relevant_background: false,
    };
    setOverallIsDirty(true);
    setValuesData(prevState => ({ ...prevState, ...newDataValues }));
    if (valuesData['background_years']) {
      computeExperienceRatingAndNotes(newDataValues);
    }
  };

  const computeExperienceRatingAndNotes = dataValues => {
    const experienceVariables = resolveExperienceVariables(dataValues, application.job_ad.job_id);
    apolloClient
      .query({
        query: GET_EXPERIENCE_RATING_AND_NOTES_GQL,
        variables: experienceVariables,
      })
      .then(result => {
        setValues(prevValues => ({
          ...prevValues,
          experience: result.data.experienceRatingAndNotesComputation.rating,
        }));
        setAutomaticNotes(prevValues => ({
          ...prevValues,
          experience: result.data.experienceRatingAndNotesComputation.notes,
        }));
      })
      .catch(handleRequestError);
  };

  // Bonus and Concerns

  const setSliderValue = e => {
    setBonusConcernValues(prevValues => ({
      ...prevValues,
      slider: e[0] - 3,
    }));
  };

  const setRadioSelected = e => {
    setBonusConcernValues(prevValues => ({
      ...prevValues,
      selected: e,
    }));
  };

  const handleTextChange = e => {
    setBonusConcernValues(prevValues => ({
      ...prevValues,
      concern: bonusConcernNotesRef.current.value,
    }));
    setAutomaticNotes(prevValues => ({
      ...prevValues,
      info: {
        note: bonusConcernNotesRef.current.value,
        checkbox: automaticNotesValues.info.checkbox,
      },
    }));
  };

  const handleCheckboxNoteChange = e => {
    setBonusConcernValues(prevValues => ({
      ...prevValues,
      checkbox: e,
    }));
    setAutomaticNotes(prevValues => ({
      ...prevValues,
      info: {
        note: automaticNotesValues.info.note,
        checkbox: e,
      },
    }));
  };

  return {
    application,
    automaticNotesValues,
    handpicked,
    handleConcern,
    overallIsDirty,
    ratingRef,
    rejection,
    concern,
    setAutomaticNotes,
    setHandpicked,
    setRejection,
    setOverall,
    setOverallIsDirty,
    setValues,
    setValuesData,
    talentUser,
    overall,
    values,
    valuesData,
    location: {
      currentLocation,
      getInitialLocationRating,
      locationRef,
      handleChangeLocation,
      handleChangeTimeZone,
      handleChangeTimeZoneNegative,
      handleChangeWorkPermit,
      validWorkPermit,
    },
    language: {
      fillDefaultLanguageRating,
      handleLanguageChange,
      niceToHaveLanguageRef,
      preferredLanguageRef,
    },
    skills: { skills, setSkillsNotes, setSkillsRating, setSkills },
    experience: {
      backgroundYearsRef,
      handleChangeBackgroundYears,
      handleChangeSameRole,
      handleExperienceChange,
      handleNoRelevantBackground,
    },
    bonusConcern: {
      setSliderValue,
      setRadioSelected,
      handleTextChange,
      handleCheckboxNoteChange,
      bonusConcernNotesRef,
      bonusConcernValues,
      bonusConcernRef,
    },
  };
}
export const SidebarFormContainer = createContainer(sidebarForm);
