export default class RatingDimension {
  id: number;
  app_rating_id: number;
  dimension: string;
  value: number;
  data: any;

  static DIMENSIONS_ORDER: {} = {
    skills: 0,
    experience: 1,
    location: 2,
    language: 3,
    overall: 4,
  };

  static DIMENSIONS_RATING_VALUES: number[] = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];

  static DIMENSIONS_FRIENDLY_NAME: {} = {
    location: 'Location',
    language: 'Language',
    skills: 'Skills',
    experience: 'Experience Level',
    overall: 'Overall',
  };
}
