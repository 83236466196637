import React, { useState } from 'react';
import Button from 'lj_shared/button/button';
import AddGasModal from './AddGasModal';

const styles = require('./AddGasButton.module.scss');

interface Props {
  application: number;
}

export default function AddGasButton(props: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const application = props.application;

  return (
    <div className={styles.alignButton}>
      <Button
        otherClassesOnly={true}
        otherClasses={styles.button}
        onClick={() => setIsModalOpen(true)}
      >
        Add GAS
      </Button>
      {isModalOpen && (
        <AddGasModal application={application} onClose={() => setIsModalOpen(false)} />
      )}
    </div>
  );
}
