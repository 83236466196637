import * as React from 'react';
const styles = require('./application_details_card.module.scss');

export interface Props {
  title: string;
  info1?: any;
  info2?: any;
  info3?: any;
  info4?: any;
  info5?: any;
  info6?: any;
  info7?: any;
  info8?: any;
  children?: any;
  fullwidth?: boolean;
  topBar?: boolean;
}

export default class ApplicationDetailsCard extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <div className={this.props.fullwidth ? styles.fullWidthCard : styles.card}>
        {this.props.topBar ? <div className={styles.topBar} /> : null}

        <div className={styles.content}>
          <div className={styles.title}>{this.props.title}</div>
          {this.props.info1 ? <div className={styles.text}>{this.props.info1}</div> : null}
          {this.props.info2 ? <div className={styles.text}>{this.props.info2}</div> : null}
          {this.props.info3 ? <div className={styles.text}>{this.props.info3}</div> : null}
          {this.props.info4 ? <div className={styles.text}>{this.props.info4}</div> : null}
          {this.props.info5 ? <div className={styles.text}>{this.props.info5}</div> : null}
          {this.props.info6 ? <div className={styles.text}>{this.props.info6}</div> : null}
          {this.props.info7 ? <div className={styles.text}>{this.props.info7}</div> : null}
          {this.props.children}
        </div>
      </div>
    );
  }
}
