import React, { useRef } from 'react';

import TagModal from './tag_modal';
import Button from 'lj_shared/button/button';

interface Props {
  name?: string;
  alternates?: string[];
  isEdit: boolean;
}

export default function TagButton(props: Props) {
  const modalRef = useRef(null);

  const handleClick = () => {
    modalRef.current.open();
  };

  return props.isEdit
    ? renderEdit(modalRef, handleClick, props)
    : renderCreate(modalRef, handleClick, props);
}

const renderEdit = (modalRef, handleClick, props) => {
  return (
    <div className='view_link'>
      <a onClick={handleClick}>Edit</a>
      <TagModal modalRef={modalRef} isEdit={true} alternates={props.alternates} name={props.name} />
    </div>
  );
};

const renderCreate = (modalRef, handleClick, props) => {
  return (
    <div>
      <Button
        isButton={false}
        buttonColor='#0058cc'
        buttonType='border'
        buttonSize='small'
        onClick={handleClick}
        extraStyle={{ paddingLeft: '0rem' }}
      >
        Add New
      </Button>
      <TagModal modalRef={modalRef} isEdit={false} />
    </div>
  );
};
