import * as React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import RatingContainer from './rating_container';

const createProps = () => ({
  rateApplicationUrl: '/employer/applications/199854/rating',
  ratings: [
    {
      id: 86605,
      rater_type: 'AdminUser',
      note: '<p>has excel, sql, bi tools</p>',
      rating: 5,
      dimensions: [],
    },
  ],
  screeningNotes: '<p>has excel, sql, bi tools</p>',
  showRatingBody: false,
  toggleRatingBody: 'bound ()',
});

let props;
let wrapped: ShallowWrapper;

describe('Rating Container component', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<RatingContainer {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
