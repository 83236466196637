import React from 'react';
import TextInput from 'components/form/text/text';
import FormInput from 'components/general/form_input/form_input';

type Kind = 'first' | 'last';

interface Props {
  error: string;
  handleFormChange: Function;
  kind: Kind;
  value: string;
}

export default function NameInput(props: Props) {
  const [field, label] =
    props.kind === 'first' ? ['firstName', 'First name'] : ['lastName', 'Last name'];

  return (
    <FormInput name='name' label={label} error={props.error} required={true}>
      <TextInput
        onChange={event => props.handleFormChange(event.target.value, field)}
        value={props.value}
      />
    </FormInput>
  );
}
