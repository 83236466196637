import * as React from 'react';
import Pagination, { Props, renderShowingPages } from './pagination';
import { mount } from 'enzyme';
import ReactPaginate from 'react-paginate';

const createTestProps = moreProps => {
  return {
    totalItemCount: 10,
    handlePageClick: jest.fn(),
    currentPage: 1,
    itemName: 'test',
    ...moreProps,
  };
};

let props: Props;
let wrapped = null;

describe('Pagination component', () => {
  beforeAll(() => {
    props = createTestProps({ perPage: 5 });
    wrapped = mount(<Pagination {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('has 2 pagination elements', () => {
    expect(wrapped.find(ReactPaginate)).toHaveLength(2);
  });

  it('has 1 footer element', () => {
    expect(wrapped.find('.paginationTotal')).toHaveLength(1);
  });

  it("has correct total element'", () => {
    expect(wrapped.find('.paginationTotal').text()).toContain(
      ` of ${props.totalItemCount} in total`
    );
  });

  it('has 1 pagination container', () => {
    expect(wrapped.find('.paginatedContainer')).toHaveLength(1);
  });

  it('has correct last page format - prev', () => {
    expect(wrapped.find('.paginatedContainer').text()).toContain(`Prev`);
  });

  it('has correct last page format - no next', () => {
    expect(wrapped.find('.paginatedContainer').text()).not.toContain(`Next`);
  });
});

test.todo('add more tests');
