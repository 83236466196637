import React from 'react';
import Modal, { ButtonsLayout } from '../modal/modal';
import { apolloClient } from 'lib/graphql';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import Select from 'components/form/select/select';
import { Query, ApolloProvider } from 'react-apollo';
import Spinner from 'shared/dot_spinner/DotSpinner';
import Checkbox from 'components/form/checkbox/checkbox';
import Textarea from 'components/form/text/text';
import EmailPreviewSuggestJob from 'components/general/email_preview/email_preview_suggest_job';
import TransferForm from './transfer_form';
import { cloneDeep } from 'lodash';

const styles = require('./transfer_to_another_job_modal.module.scss');
const publishedStateName = 'published';

export enum TransferMode {
  ImmediateTransfer,
  CreateSuggestion,
  CreateSuggestionWithoutApplication,
}
export interface Props {
  applicationId?: number;
  canCloseCurrentApplication: boolean;
  candidateId?: number;
  canTransferToAnUnpublishedJob?: boolean;
  companyId?: number;
  isPairedWithAts: boolean;
  originalJobName?: string;
  transferMode: TransferMode;
}

interface State {
  transferForm: TransferForm;
  transferingToAnUnpublishedJob: boolean;
  transferingOrSuggesting: boolean;
}

export default class TransferToAnotherJobModal extends React.Component<Props, State> {
  modalRef: any;
  timer: any;

  constructor(props) {
    super(props);
    this.state = {
      transferForm: new TransferForm({
        applicationId: this.props.applicationId,
        candidateId: this.props.candidateId,
        closeCurrentApplication: this.props.canCloseCurrentApplication,
        jobAdId: null,
        messageToCandidate: null,
      }),
      transferingOrSuggesting: false,
      transferingToAnUnpublishedJob: false,
    };
    this.modalRef = React.createRef();
    this.handleTransfer = this.handleTransfer.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.timer = null;
  }

  open() {
    this.modalRef.current.open();
  }

  isSuggestion() {
    return (
      this.props.transferMode === TransferMode.CreateSuggestion ||
      this.props.transferMode === TransferMode.CreateSuggestionWithoutApplication
    );
  }

  baseHandleTransfer(
    variables: Object,
    mutation: DocumentNode,
    extractResult: Function,
    getSuccessMessage: Function
  ) {
    this.setState({ transferingOrSuggesting: true });
    apolloClient
      .mutate({ mutation, variables })
      .then(({ data }) => {
        const result = extractResult(data);
        if (result.errors.length === 0) {
          this.close();
          if (this.state.transferForm.closeCurrentApplication) {
            // As the application was closed, we need to reload the page
            window.location.reload();
          }
          window.Alerts.notice(getSuccessMessage(result.resource));
        } else {
          const error = result.errors[0];
          this.showErrorMessage(`${error.message}`);
        }
      })
      .finally(() => {
        this.setState({ transferingOrSuggesting: false });
      })
      .catch(this.showErrorMessage);
  }

  handleTransfer() {
    const variables = {
      applicationId: this.props.applicationId,
      jobAdId: this.state.transferForm.jobAdId,
      closeCurrentApplication: this.state.transferForm.closeCurrentApplication,
    };
    let suggestVariables;
    let mutation: DocumentNode;
    let extractResult: Function;
    let getSuccessMessage: Function;

    if (this.props.transferMode === TransferMode.CreateSuggestion) {
      variables['messageToCandidate'] = this.state.transferForm.messageToCandidate;
      mutation = this.GQL_MUTATION_SUGGEST_TRANSFER;
      extractResult = data => {
        return data.application.suggestTransferToAnotherJob;
      };
      getSuccessMessage = resource => {
        return 'Offer sent to candidade!';
      };
    } else if (this.props.transferMode === TransferMode.CreateSuggestionWithoutApplication) {
      suggestVariables = {
        jobAdId: this.state.transferForm.jobAdId,
        candidateIds: [this.props.candidateId],
        companySuggestion: true,
        message: this.state.transferForm.messageToCandidate,
        sendEmail: true,
        useDefaultTemplate: true,
      };
      mutation = this.GQL_MUTATION_SUGGEST_JOB;
      extractResult = data => {
        return data.application.suggestJob;
      };
      getSuccessMessage = () => {
        return 'Offer sent to candidade!';
      };
    } else {
      mutation = this.GQL_MUTATION_TRANSFER;
      extractResult = data => {
        return data.application.transferToAnotherJob;
      };
      getSuccessMessage = resource => {
        return `Application ${resource.id} created!`;
      };
    }

    this.baseHandleTransfer(
      suggestVariables || variables,
      mutation,
      extractResult,
      getSuccessMessage
    );
  }

  handleCancel() {
    const transferForm = this.state.transferForm;
    transferForm.reset();
    this.setState({ transferForm });
    this.close();
  }

  showErrorMessage = errorMessage => {
    window.Alerts.alert(`Oops, something wrong happened: ${errorMessage}`);
  };

  close() {
    this.modalRef.current.close();
  }

  handleJobAdSelected = selectedJobAd => {
    let jobAdId;
    if (selectedJobAd && selectedJobAd.value) {
      jobAdId = parseInt(selectedJobAd.value, 10);
    } else {
      jobAdId = null;
    }

    const transferingToAnUnpublishedJob = selectedJobAd.state !== publishedStateName;

    const transferForm = cloneDeep(this.state.transferForm);
    transferForm.jobAdId = jobAdId;

    this.setState({ transferForm, transferingToAnUnpublishedJob });
  };

  handleMessageToCandidateChanged = event => {
    const transferForm = this.state.transferForm;
    transferForm.messageToCandidate = event.target.value;

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ transferForm });
    }, 1000);
  };

  handleCloseCurrentApplicationChecked = event => {
    const transferForm = this.state.transferForm;
    transferForm.closeCurrentApplication = event.target.checked;

    this.setState({ transferForm });
  };

  readonly GQL_QUERY_JOBS = gql`
    query ($applicationId: ID, $companyId: ID, $includeUnpublishedJobs: Boolean) {
      jobsForApplicationTransfer(
        applicationId: $applicationId
        companyId: $companyId
        includeUnpublishedJobs: $includeUnpublishedJobs
      ) {
        nodes {
          id
          title
          location
          stateName
        }
      }
    }
  `;

  readonly GQL_MUTATION_TRANSFER = gql`
    mutation ($applicationId: ID!, $jobAdId: ID!, $closeCurrentApplication: Boolean) {
      application {
        transferToAnotherJob(
          applicationId: $applicationId
          jobAdId: $jobAdId
          closeCurrentApplication: $closeCurrentApplication
        ) {
          errors {
            field
            message
          }
          resource {
            id
          }
        }
      }
    }
  `;

  readonly GQL_MUTATION_SUGGEST_TRANSFER = gql`
    mutation (
      $applicationId: ID!
      $jobAdId: ID!
      $closeCurrentApplication: Boolean
      $messageToCandidate: String
    ) {
      application {
        suggestTransferToAnotherJob(
          applicationId: $applicationId
          jobAdId: $jobAdId
          closeCurrentApplication: $closeCurrentApplication
          messageToCandidate: $messageToCandidate
        ) {
          errors {
            field
            message
          }
          resource {
            id
          }
        }
      }
    }
  `;

  readonly GQL_MUTATION_SUGGEST_JOB = gql`
    mutation (
      $jobAdId: ID!
      $candidateIds: [ID!]!
      $companySuggestion: Boolean
      $message: String
      $sendEmail: Boolean!
      $useDefaultTemplate: Boolean!
    ) {
      application {
        suggestJob(
          jobAdId: $jobAdId
          candidateIds: $candidateIds
          companySuggestion: $companySuggestion
          message: $message
          sendEmail: $sendEmail
          useDefaultTemplate: $useDefaultTemplate
        ) {
          errors {
            field
            message
          }
        }
      }
    }
  `;

  mapJobAdsToOptions(jobAds) {
    return jobAds.map(jobAd => this.mapJobAdToOption(jobAd));
  }

  mapJobAdToOption(jobAd) {
    let jobAdTitle = jobAd.title;
    if (jobAd.location) {
      jobAdTitle += ` @ ${jobAd.location}`;
    }
    if (jobAd.stateName.toLowerCase() !== publishedStateName) {
      jobAdTitle += ` (this jobs is ${jobAd.stateName})`;
    }
    return { label: jobAdTitle, value: jobAd.id, state: jobAd.stateName };
  }

  modalHeaderText() {
    return this.props.transferMode === TransferMode.ImmediateTransfer
      ? 'TRANSFER TO ANOTHER JOB'
      : 'SUGGEST ANOTHER JOB';
  }

  modalActionText() {
    return this.props.transferMode === TransferMode.ImmediateTransfer
      ? 'TRANSFER JOB'
      : 'SUGGEST JOB';
  }

  mainColor() {
    return this.props.transferMode === TransferMode.ImmediateTransfer ? 'puertoRico' : 'ripePlum';
  }

  renderLoading() {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  renderError(error) {
    return <div>Error: {error}</div>;
  }

  renderYouHaveNoEligibleJobs() {
    return (
      <span>Sorry, looks like you have no eligible jobs to transfer this application to.</span>
    );
  }

  renderModalContent(jobs) {
    return (
      <>
        <div className={styles.modalContentSection}>
          <Select
            label={`1. Choose a published ${
              this.props.canTransferToAnUnpublishedJob ? ' or unpublished' : ''
            } job`}
            required={true}
            options={jobs}
            openMenuOnClick={true}
            placeholder='Pick a job'
            type='sync'
            onChange={this.handleJobAdSelected}
            maxMenuHeight={150}
            color={this.mainColor()}
          />
          {this.state.transferingToAnUnpublishedJob && (
            <div className={styles.noticeSection}>
              <strong>Notice</strong> that you are about to transfer this application to an
              unpublished job.
            </div>
          )}
          {this.props.isPairedWithAts && (
            <div className={styles.noticeSection}>
              <strong>{this.props.originalJobName || 'This job'} is paired with an ATS.</strong>{' '}
              Proceeding with the application transfer will produce inconsistencies between the ATS
              and Landing.Jobs. It may be better to contact the Candidate and suggest the new job!
            </div>
          )}
        </div>

        {this.isSuggestion() && (
          <div className={styles.modalContentSection}>
            <Textarea
              label={'2. Message to the candidate'}
              name='message'
              onChange={this.handleMessageToCandidateChanged}
              placeholder={'Provide here some additional information to the candidate'}
              color={'ripePlum'}
              textarea={true}
            />
          </div>
        )}

        {this.props.canCloseCurrentApplication && (
          <div className={styles.modalContentSection}>
            <Checkbox
              className={styles.checkbox}
              color={this.mainColor()}
              label='Close the current application'
              controlled={false}
              checked={this.state.transferForm.closeCurrentApplication}
              onChange={this.handleCloseCurrentApplicationChecked}
            />
          </div>
        )}

        {this.isSuggestion() && (
          <div className={styles.modalContentSection}>
            <EmailPreviewSuggestJob
              hide='.js-rejectionFormContent'
              form={this.state.transferForm}
              url='/employer/offer_suggestions/preview'
              title='Email preview'
            />
          </div>
        )}
      </>
    );
  }

  render() {
    return (
      <ApolloProvider client={apolloClient}>
        <Modal
          title={this.modalHeaderText()}
          defaultOpen={false}
          ref={this.modalRef}
          buttonsLayout={ButtonsLayout.OkCancel}
          buttonName={this.modalActionText()}
          buttonColor={this.mainColor()}
          buttonOnClick={this.handleTransfer}
          disableButton={!this.state.transferForm.validate() || this.state.transferingOrSuggesting}
          cancelButton={true}
          onClose={this.handleCancel}
          disableClose={true}
        >
          <div className={styles.modalContent}>
            <Query
              query={this.GQL_QUERY_JOBS}
              variables={{
                applicationId: this.props.applicationId,
                companyId: this.props.companyId,
                includeUnpublishedJobs: this.props.canTransferToAnUnpublishedJob,
              }}
            >
              {result => {
                if (result.loading) return this.renderLoading();
                if (result.error) return this.renderError(result.error);

                const jobs = this.mapJobAdsToOptions(result.data.jobsForApplicationTransfer.nodes);

                if (jobs.length === 0) {
                  return this.renderYouHaveNoEligibleJobs();
                }

                return this.renderModalContent(jobs);
              }}
            </Query>
          </div>
        </Modal>
      </ApolloProvider>
    );
  }
}
