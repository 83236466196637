import React from 'react';
import Icon, { Style, Size } from 'components/general/icon/icon';
import classNames from 'classnames';

const styles = require('./UnicornWarning.module.scss');

interface Props {
  value: number;
}

export default function UnicornWarning({ value }: Props) {
  const [color, warningText] = defaultColours(value);
  const [defaultBackgroundColours, defaultTextColours] = ['background-', 'text-'].map(
    partialStyle => styles[`${partialStyle}${color}`]
  );

  const icon = value === 0 ? 'unicorn' : 'person';

  return (
    <div className={styles.unicornContainer}>
      <div className={styles.unicornIcons}>
        {Array.from(Array(5).keys()).map(index => (
          <Icon
            className={index <= value && defaultBackgroundColours}
            name={index === 0 ? icon : 'person'}
            color='white'
            style={Style.Bold}
            size={Size.MediumBig}
            key={index}
          />
        ))}
      </div>
      <div className={classNames(styles.unicornWarningText, defaultTextColours)}>{warningText}</div>
    </div>
  );
}

const defaultColours = (value: number) => {
  switch (value) {
    case 0: {
      return ['red', 'Oops, little chances of finding candidates with that set of skills'];
    }
    case 1:
    case 2: {
      return ['blue', 'Good chances of finding potential candidates'];
    }
    case 3:
    case 4: {
      return ['green', 'Great chances of finding potential candidates'];
    }
  }
};
