import * as React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import HelpHeader from './help_header';

let wrapped: ShallowWrapper;

describe('Help Header component', () => {
  beforeEach(() => {
    wrapped = shallow(<HelpHeader />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});
