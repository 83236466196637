import React, { useRef, useState } from 'react';
import Button from 'lj_shared/button/button';
import DotLoader from 'lj_shared/dot_loader/dot_loader';
import Modal, { ButtonsLayout } from 'components/general/modal/modal';
import FormInput from 'components/general/form_input/form_input';
import RadioButtons from 'components/form/radio_buttons/radio_buttons';
import Select from 'components/form/select/select';
import { postFormData, getJSON, handleRequestError, handleSuccessAlert } from 'lib/request';

const styles = require('./StartInterviewModal.module.scss');

interface Company {
  readonly value: number;
  readonly lable: string;
}

interface Job {
  readonly value: number;
  readonly lable: string;
}

interface Props {
  candidateName: string;
  candidateId: number;
  date: string;
  readonly companies: Company[];
  readonly jobs: Job[];
}

interface CompanyOption {
  id: string;
  name: string;
}

interface JobOption {
  id: string;
  name: string;
}

export default function UploadInterviewModal(props: Props) {
  const modalRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [interviewType, setInterviewType] = useState('onboarding');
  const [company, setCompany] = useState(null);
  const [job, setJob] = useState(null);
  const [companies, setCompanies] = useState<CompanyOption[]>(null);
  const [jobs, setJobs] = useState<JobOption[]>(null);

  const handleClick = event => {
    event.preventDefault();
    modalRef.current.open();
    setLoading(false);
  };

  const handleChangeType = value => {
    setInterviewType(value);

    if (value === 'screening') {
      getCompanies();
    } else {
      hideCompanies();
    }
  };

  const handleChangeCompany = value => {
    setCompany(value);
    setJob(null);
    setJobs(null);
    getJobs(value);
  };

  const hideCompanies = () => {
    setCompany(null);
    setCompanies(null);
    setJob(null);
    setJobs(null);
  };

  const getJobs = currentCompany => {
    if (!currentCompany || currentCompany.value === null) {
      return;
    }

    window.toggleSpinner(true);

    const params = {
      company_id: currentCompany.value,
    };

    getJSON(window.Routes.job_options_backoffice_interviews_url(params), data => {
      if (data.error) {
        return;
      }

      setJobs(data.options);

      window.toggleSpinner(false);
    });
  };

  const getCompanies = () => {
    if (companies) {
      return;
    }

    const loadingTimeoutID = setTimeout(() => window.toggleSpinner(true), 200);

    const params = {
      person_id: props.candidateId,
    };

    getJSON(window.Routes.company_options_backoffice_interviews_url(params), data => {
      if (data.error) {
        return;
      }

      setCompanies(data.options);

      window.toggleSpinner(false);
      clearTimeout(loadingTimeoutID);
    });
  };

  const handleOnSubmit = event => {
    event.preventDefault();
    const formData = new FormData(event.target);

    // yikes, disgusting, not sure why formData is empty
    formData.append('person_id', props.candidateId.toString());
    formData.append('interview[person_id]', props.candidateId.toString());
    formData.append('interview[type]', interviewType);
    formData.append('interview[company_id]', company ? company.value.toString() : '');
    formData.append('interview[job_ad_id]', job ? job.value.toString() : '');

    const submitPath = window.Routes.start_backoffice_interviews();
    postFormData(submitPath, formData, {
      Accept: 'application/json',
    })
      .then(data => {
        if (data.success) {
          if (!data.same_page) {
            window.toggleSpinner(false);
            window.open(data.redirect_url, '_blank');
          } else {
            window.location = data.redirect_url;
          }

          modalRef.current.close();
        } else {
          handleRequestError(data);
        }
      })
      .catch(handleRequestError);
  };

  return (
    <div>
      <Button
        onClick={handleClick}
        otherClasses={
          'ld-button ld-small-button ld-blue-button ld-border-button u-marginRight--small'
        }
        extraStyle={{ height: 'auto' }}
      >
        Start Interview
      </Button>
      <Modal
        buttonColor='tuftsBlue'
        buttonName='Start'
        disableButton={loading}
        disableOutsideClose={true}
        ref={modalRef}
        title='Start Interview'
        isButton
        buttonType='submit'
        modalForm
        onSubmitHandler={handleOnSubmit}
        buttonsLayout={ButtonsLayout.CancelOk}
      >
        <FormInput name='interview[type]' label='Type of Interview'>
          <RadioButtons
            selectedValue={interviewType}
            options={[
              { value: 'onboarding', label: 'Onboarding' },
              { value: 'screening', label: 'Screening' },
            ]}
            onChange={value => handleChangeType(value)}
            textInsideLabel={true}
          />
        </FormInput>
        {companies && (
          <FormInput name='interview[company_id]' label='Company'>
            <Select
              color={'tuftsBlue'}
              value={company}
              options={companies}
              placeholder='Select a company if the interview refers to a company'
              onChange={value => handleChangeCompany(value)}
              menuPosition={'fixed'}
            />
          </FormInput>
        )}
        {jobs && (
          <FormInput name='interview[job_ad_id]' label='Job'>
            <Select
              color={'tuftsBlue'}
              value={job}
              options={jobs}
              placeholder='Select a job if the interview refers to a job'
              onChange={value => setJob(value)}
              menuPosition={'fixed'}
            />
          </FormInput>
        )}
        {loading && <DotLoader style={{ position: 'absolute', bottom: '0.5rem', left: '50%' }} />}
      </Modal>
    </div>
  );
}
