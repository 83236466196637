import { Message } from 'components/conversations/components/models';

const messageDummy: Message = {
  id: 22518,
  type: 'message',
  attributes: {
    sender_type: 'CompanyUser',
    sender_id: 93481,
    conversation_id: 5961,
    body: 'Olá Carolina,\n\nAntes demais, desculpa a demora a entrarmos em contacto contigo após a tua candidatura. \nEstivemos agora a rever o teu perfil e tenho uma questão: sendo que ainda estás a estudar, isto não será uma incompatibilidade para te juntares a full-time à Landing.jobs?\n\nFicamos a aguardar uma resposta.\n\nCheers,',
    read: '2019-05-14T13:39:20.597Z',
    created_at: '2019-05-13T17:04:01.415Z',
    updated_at: '2019-05-13T17:04:01.415Z',
  },
};

export default messageDummy;
