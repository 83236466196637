import LoadingOverlay from './loading_overlay';
import React from 'react';
import Spinner from 'shared/dot_spinner/DotSpinner';
import { shallow, ShallowWrapper } from 'enzyme';
let wrapped: ShallowWrapper;

describe('Loading Overlay component', () => {
  beforeEach(() => {
    // eslint-disable-next-line react/no-children-prop
    wrapped = shallow(<LoadingOverlay active={true} children='<div>Test</div>' />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
  it('should have a Spinner', () => {
    expect(wrapped.find(Spinner).length).toEqual(1);
  });
});
