import React from 'react';
import ArticleItem from './article_item';
import { Article } from '__models__/article';

const styles = require('./last_articles_container.module.scss');

interface Props {
  articles: Article[];
  baseArticleUrl: string;
}

export default function LastArticlesContainer(props: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.list}>
        <div className={styles.title}>Last Articles</div>
        {props.articles.map(article => (
          <ArticleItem key={article.id} article={article} baseArticleUrl={props.baseArticleUrl} />
        ))}
      </div>
    </div>
  );
}
