import * as React from 'react';
import AskModal from './ask_modal';
import { shallow, ShallowWrapper } from 'enzyme';

let wrapped: ShallowWrapper;
const step = {
  description: '',
  hasApplications: true,
  id: 1,
  jobId: 1,
  kind: '',
  message: {
    id: 2,
    text: '',
  },
  name: '',
  order: 1,
  slug: '',
};
const urls = {};

describe('Ask Modal component', () => {
  beforeEach(() => {
    wrapped = shallow(<AskModal jobId={1} onDone={jest.fn()} step={step} urls={urls} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
