import React from 'react';
import { mount, shallow } from 'enzyme';
import JobPage from './JobPage';
import jobDummy from '__fixtures__/job_dummy';
import jobAdDummy from '__fixtures__/job_ad_dummy';

window.Routes = {
  join: () => '',
  new_user_session: () => '',
};

const createProps = () => ({
  jobAd: jobAdDummy,
  bookmark: null,
  cancelReasons: [
    'I got a more interesting proposal.',
    "I got second feelings and I'm backing down",
  ],
  handshake: null,
  jobSearchFiltersAb: 'hottest_job_matches',
  jobSuggestion: [],
  referralId: null,
});

let props;
let wrapped;

describe('Job Page renders correctly', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<JobPage {...props} />);
  });

  it.skip('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

// TODO: this needs to be fixed as well as the test above.
//        we need to simulate useQuery from appolo in order to correcty render the page and test components

// describe('It hides and shows footer banner', () => {
//   beforeEach(() => {
//     props = createProps();
//     wrapped = mount(<JobPage {...props} />);
//   });

//   it('shows banner when scrolling down', async () => {
//     const mEvent = {
//       target: { scrollWidth: 100, clientWidth: 50 },
//     };

//     await waitFor(() => {
//       expect(wrapped.find('.ld-page-body').simulate('scroll', mEvent));
//     });
//   });
// });

test.todo('add more tests');
