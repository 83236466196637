import * as React from 'react';
import ApplicationHeader from '../application_page/application_header';
import {
  HiringStep,
  Application,
  ATSApplication,
  ApplicationQuestion,
} from '../../__models__/models';
import './application_page.scss';
import { getJSON } from '../../lib/request_deprecated';
import ApplicationBanner from '../application_page/application_banner';
import ApplicationInfo from '../application_page/application_info';
import ApplicationPageNotes from '../application_page/application_page_notes';
import ApplicationLocation from '../application_page/application_location';
import ApplicationContacts from '../application_page/application_contacts';
import ApplicationBasicProfile from '../application_page/ApplicationBasicProfile';
import ApplicationEvaluation from '../application_page/application_evaluation';
import ApplicationWaitingForReview from '../application_page/application_waiting_for_review';
import ApplicationATS from '../application_page/application_ats';
import RejectionReason from '../application_page/rejection_reason';
import SuggestAnotherJob from '../application_page/suggest_another_job';

export interface Props {
  application: Application;
  applicationQuestions: ApplicationQuestion[];
  ats_name?: string;
  ats_offer?: any;
  ats_provider?: string;
  backButton: Function;
  delivered: boolean;
  giveAlert: Function;
  is_inbox: boolean;
  job_editable: boolean;
  loading: boolean;
  moveToStep: Function;
  outdated?: boolean;
  ratingsWithDimensions?: any;
  reconsiderApplication: any;
  rejectApplication: Function;
  seenList: number[];
  sendTest: any;
  showRatingBody?: boolean;
  step_label: string;
  topFilter: string;
  urls: any;
}

interface State {
  current_step: HiringStep;
  ats_application?: ATSApplication;
  hired: boolean;
  rejected: boolean;
  urls: any;
}

export default class ApplicationPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    (this as any).myRef = React.createRef();

    const ats_applications = this.props.application.attributes.ats_applications;
    let atsApplication = null;

    if (ats_applications) {
      atsApplication = ats_applications.data
        .sort((a, b) => {
          return a.attributes.created_at > b.attributes.created_at ? -1 : 1;
        })
        .filter(a => a.attributes.provider === this.props.ats_provider)[0];

      if (atsApplication) {
        atsApplication = atsApplication.attributes;
      }
    }

    this.state = {
      ats_application: atsApplication || null,
      current_step: Object.values(this.props.application.attributes.hiring_steps).find(
        step => step.value === this.props.application.attributes.current_state
      ),
      hired: false,
      rejected: false,
      urls: {},
    };

    this.syncWithAts = this.syncWithAts.bind(this);
    this.exportToATS = this.exportToATS.bind(this);
  }

  componentDidMount() {
    (this as any).myRef.current && (this as any).myRef.current.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    document.title = this.props.application.attributes.person.attributes.name;
  }

  /**
   * Checks if a job has an associated ats job
   * If it does it exports the application to the ATS
   */
  exportToATS() {
    window.toggleSpinner(true);
    if (this.props.application.attributes.has_ats_offer) {
      getJSON(this.props.urls.export_to_ats_url, {
        method: 'Post',
      }).then(response => {
        this.setState(() => {
          this.props.giveAlert(response);
          const atsAttributes = response.ats_application
            ? response.ats_application.data.attributes
            : null;

          return {
            ats_application: atsAttributes,
          };
        });
        window.toggleSpinner(false);
      });
    } else {
      window.Alerts.alert('You need to pair the Job first');
      window.Turbolinks.visit(this.props.urls.ats_pair_url);
    }
  }

  /**
   * Syncs with the ats_application
   */
  syncWithAts() {
    getJSON(this.props.urls.ats_sync_url, { method: 'Post' }).then(response => {
      if (response.error) {
        window.Alerts.alert(response.error);
        window.toggleSpinner(false);
      } else {
        window.location.reload();
      }
    });
  }

  render() {
    const questions = this.props.applicationQuestions || [];
    const displayInfo: boolean =
      this.props.application.attributes.text !== null || questions.length > 0;
    const hideContactsBodyClass = this.props.is_inbox ? ' lj-application-hide-contacts' : '';
    const rejected =
      this.props.application.attributes.state === 97 ||
      this.props.application.attributes.state === 99;

    return (
      <div className='lj-grid-container'>
        <ApplicationHeader
          backButton={this.props.backButton}
          hiringSteps={this.props.application.attributes.hiring_steps}
          currentState={this.props.application.attributes.current_state}
          urls={this.props.urls}
          moveToStep={this.props.moveToStep}
          application={this.props.application}
          rejectApplication={this.props.rejectApplication}
          delivered={this.props.delivered}
          stepLabel={this.props.step_label}
          waitingReview={this.props.is_inbox}
        />
        <div
          ref={(this as any).myRef}
          className={'lj-application-page-body' + hideContactsBodyClass}
        >
          <div className='lj-application-left lj-application-col'>
            <div className='lj-move-down'>
              <ApplicationBanner
                seenList={this.props.seenList}
                application={this.props.application}
                profileUrl={this.props.urls.profile_url}
                moveToStep={this.props.moveToStep}
                rejectApplication={this.props.rejectApplication}
                chatUrl={this.props.urls.chat_url}
                urls={this.props.urls}
                hiringSteps={this.props.application.attributes.hiring_steps}
                currentStep={this.props.application.attributes.current_state}
                delivered={this.props.delivered}
                stepLabel={this.props.step_label}
                waitingReview={this.props.is_inbox}
                onApplicationList={false}
                topFilter={this.props.topFilter}
                hideRatingContainer={true}
              />
            </div>
            <ApplicationEvaluation
              application={this.props.application}
              sendTest={this.props.sendTest}
              ratingsWithDimensions={this.props.ratingsWithDimensions}
              rateApplicationUrl={this.props.application.attributes.urls.rate_application_url}
              outdated={this.props.outdated}
            />
            {displayInfo ? (
              <ApplicationInfo
                application={this.props.application}
                applicationQuestions={this.props.applicationQuestions}
              />
            ) : null}
            <ApplicationLocation application={this.props.application} />
            <ApplicationContacts
              person={this.props.application.attributes.person}
              profileUrl={this.props.urls.profile_url}
              waitingForReview={this.props.is_inbox}
            />
            <ApplicationBasicProfile
              cvUrl={this.props.urls.pdf_url}
              application={this.props.application}
              profileUrl={this.props.urls.profile_url}
              waitingForReview={this.props.is_inbox}
              showBlock
            />
          </div>
          <div className='lj-application-right lj-application-col'>
            {rejected ? (
              <RejectionReason
                rejectionReason={this.props.application.attributes.rejection.rejection_reason}
                rejectionMessage={this.props.application.attributes.rejection.rejection_message}
                state={this.props.application.attributes.state}
                reconsiderApplication={this.props.reconsiderApplication}
              />
            ) : null}
            <SuggestAnotherJob
              applicationId={Number(this.props.application.id)}
              canCloseCurrentApplication={
                this.props.application.attributes.state < 90 // Closed states
              }
              isPairedWithAts={!!this.state.ats_application}
            />
            {this.props.ats_name && (
              <ApplicationATS
                syncWithATS={this.syncWithAts}
                exportToATS={this.exportToATS}
                ATSOffer={
                  this.props.ats_offer &&
                  this.props.ats_offer.data &&
                  this.props.ats_offer.data.attributes
                }
                ATSApplication={this.state.ats_application}
                ATSName={this.props.ats_name}
                applicationRejected={rejected}
              />
            )}
            {this.props.is_inbox ? (
              <ApplicationWaitingForReview
                moveToReviewing={this.props.moveToStep}
                rejectApplication={this.props.rejectApplication}
                application={this.props.application}
              />
            ) : (
              <ApplicationPageNotes
                getNotesUrl={this.props.urls.get_notes_url}
                postNoteUrl={this.props.urls.post_note_url}
                destroyNoteUrl={this.props.urls.destroy_note_url}
                applicationId={parseInt(this.props.application.id, 10)}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
