import * as React from 'react';
import ShareModal from './jobs_share_modal';
import { JobAd } from '../../__models__/models';
import { shallow } from 'enzyme';
import Modal from '../general/modal/modal';
import InlineIcon from '../general/inline_icon/inline_icon';

const jobDummy: JobAd = require('./fixtures/job_ad_dummy.json');
let wrapped;

beforeAll(() => {
  wrapped = shallow(<ShareModal jobAd={jobDummy} />);
});

it('it has a Modal', () => {
  expect(wrapped.find(Modal).length).toEqual(1);
});

it('it has 3 icons', () => {
  expect(wrapped.find(InlineIcon).length).toEqual(3);
});
