import classNames from 'classnames';
import React from 'react';
import HomepageButtons from './homepageButtons';

const styles = require('./features.module.scss');

const bulletSVG = require('../images/bullets.svg');
interface Props {
  language: {
    title: string;
    firstRow: string[];
    secondRow: string[];
    thirdRow: string[];
    buttons: {
      contractor: string;
      company: string;
      companyHero: string;
    };
  };
}

/**
 * Functional Component for the '/' page, it should display the features of landing.work
 * and a link to both become a contractor and hire talented contractors
 */

const Features: React.FunctionComponent<Props> = props => {
  const { title, buttons } = props.language;

  const features = [props.language.firstRow, props.language.secondRow, props.language.thirdRow];

  return (
    <>
      <div className={styles['home-sections what-section']}>
        <div className={styles['lw-home-section-mid']}>
          <div className={styles['section-text']}>
            <div className={styles['what-content']}>
              <div className={classNames(styles.title, styles['lw-features-title'])}>{title}</div>
              <div className={styles['what-bullets']}>
                {features.map((row, indexRow) => {
                  return (
                    <div className={styles['what-row']} key={indexRow}>
                      {row.map((item, index) => {
                        return (
                          <div key={index} className={styles['what-item']}>
                            <img src={bulletSVG} alt='bullet' />
                            <p className={styles['what-text']}>{item}</p>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['midButtons-what']}>
        <HomepageButtons trackingAction='features' language={buttons} center={'buttonCenter'} />
      </div>
    </>
  );
};

export default Features;
