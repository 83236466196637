import React from 'react';
import TagModal from './tag_modal';
import { shallow } from 'enzyme';

let wrapped;
describe('Tag Create Modal Component existance/structure', () => {
  beforeAll(() => {
    const props = {
      modalRef: jest.fn(),
      isEdit: true,
      name: 'test name',
      alternates: ['test1', 'test2'],
    };
    wrapped = shallow(<TagModal {...props} />);
  });

  it('renders', () => {
    expect(wrapped.exists()).toBe(true);
  });
});
