import React, { useEffect, useRef } from 'react';
import Spinner from 'shared/dot_spinner/DotSpinner';
import sanitizeHtml from 'sanitize-html-react';
import classNames from 'classnames';
const styles = require('./WhatsNewItem.module.scss');

interface Props {
  feature: Feature;
  loading?: boolean;
  scroll?: boolean;
}

export interface Feature {
  audience: string;
  id: number;
  title: string;
  body?: any;
  published_at: Date;
}

export default function WhatsNewItem({ feature, loading, scroll }: Props) {
  const modalEndRef = useRef(null);

  useEffect(() => {
    if (scroll && !loading) scrollToBottom();
  });

  const scrollToBottom = () => modalEndRef.current.scrollIntoView({ behavior: 'smooth' });

  const isCompany = () => {
    return feature.audience === 'companies';
  };

  const sanitizedHTMLBody = () =>
    sanitizeHtml(feature.body, {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat(['h1', 'h2', 'img', 'iframe']),
      transformTags: {
        a: sanitizeHtml.simpleTransform('a', { target: '_blank' }),
      },
      allowedAttributes: {
        a: ['href', 'target'],
        iframe: ['src', 'width', 'height'],
        img: ['src'],
      },
      allowedSchemes: ['data', 'http', 'https'],
    });

  const FeatureContent = () => {
    return (
      <div className={styles.wrapper}>
        <div ref={modalEndRef} className={styles.scrollDiv} />
        <div
          className={classNames(
            styles.header,
            isCompany() ? styles.headerCompany : styles.headerTalent
          )}
        >
          These are the latest news, features and important facts the Landing.Jobs team specially
          brought to you.
        </div>
        <div
          className={classNames(
            styles.title,
            isCompany() ? styles.titleCompany : styles.titleTalent
          )}
        >
          {feature.title}
        </div>
        <div
          className={classNames(styles.body, isCompany() ? styles.bodyCompany : styles.bodyTalent)}
          dangerouslySetInnerHTML={{ __html: sanitizedHTMLBody() }}
        />
      </div>
    );
  };

  return <>{loading ? <Spinner /> : <FeatureContent />}</>;
}
