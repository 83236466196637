import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import UsersManagementPage, { InviteUserSection } from './users_management';
import AdminCard from '../general/admin_card/admin_card';

jest.mock('./users_management_view_model', () => ({
  usersManagementPageViewModel: jest.fn().mockReturnValue({
    onInviteSent: jest.fn(),
    onDelete: jest.fn(),
    state: {
      loading: false,
      users: [{}, {}, {}],
    },
  }),
}));

const createTestProps = () => {
  return {
    userId: 1,
    companyId: 8,
    maxUsersReached: true,
    maxUsersAllowed: 2,
  };
};

let props;
let wrapped: ShallowWrapper;

describe('users management page', () => {
  beforeEach(() => {
    props = createTestProps();
  });

  it('shallow renders without crashing', () => {
    wrapped = shallow(<UsersManagementPage {...props} />);
    expect(wrapped.exists()).toBe(true);
  });

  it('renders max users CTA when max users reached', () => {
    wrapped = shallow(<UsersManagementPage {...props} />);
    expect(wrapped.find(AdminCard).length).toBe(1);
    expect(wrapped.find(InviteUserSection).length).toBe(0);
  });

  it('renders invite users section when max users not reached', () => {
    props.maxUsersAllowed = 10;
    wrapped = shallow(<UsersManagementPage {...props} />);
    expect(wrapped.find(InviteUserSection).length).toBe(1);
    expect(wrapped.find(AdminCard).length).toBe(0);
  });
});
