import React from 'react';
import { Company } from './types';
import Icon, { Size } from 'components/general/icon/icon';
import FormInput from 'components/general/form_input/form_input';
import Select from 'components/form/select/select';

const styles = require('./JobTypeButtons.module.scss');

interface Props {
  showCompanySelect: boolean;
  company: Company;
  companies?: Company[];
  setCompany: (company: Company) => void;
  setScreenType: (screenType: string) => void;
  submitError: boolean;
  setSubmitError: (submitError: boolean) => void;
}

const JobTypeButtons = (props: Props) => {
  const {
    showCompanySelect,
    company,
    companies,
    setCompany,
    setScreenType,
    submitError,
    setSubmitError,
  } = props;

  const handleSubmit = (type: string) => {
    if (showCompanySelect && !company) {
      setSubmitError(true);
      return;
    }

    if (type === 'new') {
      window.location.assign(window.Routes.new_backoffice_job_ad({ company_id: company.value }));
    } else {
      setSubmitError(false);
      setScreenType('jobListContent');
    }
  };

  return (
    <div className={styles.jobTypeContainer} data-testid='job-type-buttons'>
      {showCompanySelect && (
        <FormInput label='Company' className={styles.companyForm} required>
          {submitError && (
            <span className={styles.submitError}>Please select a company to continue</span>
          )}
          <Select
            color={'tuftsBlue'}
            value={company}
            options={companies}
            placeholder='Select a company to create or clone a job post'
            onChange={(value: Company) => setCompany(value)}
            menuPosition='fixed'
          />
        </FormInput>
      )}
      <div className={styles.jobTypeButtons}>
        <div className={styles.jobTypeButton} onClick={() => handleSubmit('new')}>
          <div>
            <Icon name='pencil' color='silverSand' size={Size.BigHero} />
          </div>
          <span>Start from scratch</span>
        </div>
        <div className={styles.jobTypeButton} onClick={() => handleSubmit('duplicate')}>
          <div>
            <Icon name='trayArrowDown' color='silverSand' size={Size.Hero} />
          </div>
          <span>Duplicate job post</span>
        </div>
      </div>
    </div>
  );
};

export default JobTypeButtons;
