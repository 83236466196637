import 'lib/globals';
import React from 'react';
import classnames from 'classnames';
const styles = require('./other_articles.module.scss');

export interface Props {
  articles: any;
  isCandidate?: boolean;
}

export default function OtherArticles(props: Props) {
  function renderArticlesList() {
    return props.articles.map(article => {
      return (
        <div className={styles.text} key={article.id}>
          <a href={articleUrl(props.isCandidate, article.attributes.id)}>
            {article.attributes.title}
          </a>
        </div>
      );
    });
  }

  return (
    <div
      className={
        props.isCandidate
          ? classnames(styles.wrapper, styles.wrapperCandidate)
          : classnames(styles.wrapper, styles.wrapperEmployer)
      }
    >
      <div
        className={
          props.isCandidate
            ? classnames(styles.title, styles.titleCandidate)
            : classnames(styles.title, styles.titleEmployer)
        }
      >
        Other Articles
      </div>
      {renderArticlesList()}
    </div>
  );
}

function articleUrl(isCandidate, id) {
  const params = { context: 'help_center' };
  return isCandidate
    ? window.Routes.dashboard_help(id, params)
    : window.Routes.employer_help(id, params);
}
