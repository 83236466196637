export function loadYears() {
  const currentYear = new Date().getFullYear();
  return Array(100)
    .fill(0)
    .map((_, index) => {
      const value = currentYear - index;
      return { label: value, value };
    });
}

export function historyTextPlaceholder() {
  return (
    'e.g. We launched our first platform in March with a simple idea: ' +
    'to gather the job offers of technology companies'
  );
}
