import * as React from 'react';
import { mount } from 'enzyme';
import SignUp, { SignUpProps } from './sign_up';

let props: SignUpProps;
let wrapped;

const createTestProps = (otherProps = {}) => {
  return {
    candidateSelected: true,
    editCompanyDisabled: false,
    urls: {
      loginUrl: '/login',
      newTalent: '/employer/join',
      newCompanyUser: '/job_seeker/join',
    },
    socialUrls: {
      linkedin: '/users/auth/linkedin',
      github: '/users/auth/github',
      facebook: '/users/auth/facebook',
      google: '/users/auth/google',
    },
    errors: null,
    values: {
      name: null,
      company_name: null,
      email: null,
      tos_consent: null,
    },
    ...otherProps,
  };
};

document.body.innerHTML =
  '<meta name="csrf-token" content="q8C2MRbESrBJqcECqi4yym47QWHUlV2nNjEePNzyjby7XI27xvQIkbjlQw1j/3/xuPcycoJc6tqbgxK/l/2aEQ==">';

describe('Sign Up for candidate', () => {
  beforeAll(() => {
    props = createTestProps();
    wrapped = mount(<SignUp {...props} />);
  });

  it('renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('shows a toggle bar with two options', () => {
    expect(wrapped.find('.toggleBar .jobsToggle')).toHaveLength(2);
  });

  it('does not show a company name input', () => {
    expect(wrapped.find('input[name="company_name"]').exists()).toBe(false);
  });

  it('shows a name input', () => {
    expect(wrapped.find('input[name="name"]').exists()).toBe(true);
  });

  it('shows an email input', () => {
    expect(wrapped.find('input[name="email"]').exists()).toBe(true);
  });

  it('shows a password input', () => {
    expect(wrapped.find('input[name="password"]').exists()).toBe(true);
  });

  it('shows a terms and conditions checkbox', () => {
    expect(wrapped.find('input[name="tos_consent"]').exists()).toBe(true);
  });

  it('shows a sign up button', () => {
    expect(wrapped.find('form button').text()).toBe('Sign up');
  });

  it('shows three social login buttons', () => {
    expect(wrapped.find('.authButtons ul.list li')).toHaveLength(4);
  });

  it('shows a LinkedIn button', () => {
    expect(wrapped.find('.lj-button--linkedinBlue').text()).toMatch('LinkedIn');
  });

  it('shows a GitHub button', () => {
    expect(wrapped.find('.lj-button--githubGray').text()).toMatch('GitHub');
  });

  it('shows a Facebook button', () => {
    expect(wrapped.find('.lj-button--facebookBlue').text()).toMatch('Facebook');
  });

  it('shows a log in button', () => {
    expect(wrapped.find('a[href="/login"]').text()).toBe('Log in here');
  });
});

describe('Sign Up for company', () => {
  beforeAll(() => {
    props = createTestProps({ candidateSelected: false });
    wrapped = mount(<SignUp {...props} />);
  });

  it('shows a toggle bar with two options', () => {
    expect(wrapped.find('.toggleBar .jobsToggle')).toHaveLength(2);
  });

  it('shows a company name input', () => {
    expect(wrapped.find('input[name="company_name"]').exists()).toBe(true);
  });

  it('shows a name input', () => {
    expect(wrapped.find('input[name="name"]').exists()).toBe(true);
  });

  it('shows an email input', () => {
    expect(wrapped.find('input[name="email"]').exists()).toBe(true);
  });

  it('shows a password input', () => {
    expect(wrapped.find('input[name="password"]').exists()).toBe(true);
  });

  it('shows a terms and conditions checkbox', () => {
    expect(wrapped.find('input[name="tos_consent"]').exists()).toBe(true);
  });

  it('shows a sign up button', () => {
    expect(wrapped.find('form button').text()).toBe('Sign up');
  });

  it('does not show social login buttons', () => {
    expect(wrapped.find('.authButtons ul.list li')).toHaveLength(0);
  });

  it('shows a log in button', () => {
    expect(wrapped.find('a[href="/login"]').text()).toBe('Log in here');
  });
});
