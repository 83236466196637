import React from 'react';
import Icon, { Size } from 'components/general/icon/icon';
import { scrollTo } from 'lib/scrollTo';
import OfficeLocationsTooltip from 'components/office_locations_tooltip/office_locations_tooltip';
import classNames from 'classnames';

const styles = require('./offer_location.module.scss');

interface Props {
  location: string;
  locationIcon: string;
  isGlobalRemote?: boolean;
  officeLocations: any;
  remoteDetails?: string;
  remoteWorkingLabel?: string;
  iconColor: string;
  mini?: boolean;
  timezoneLabel?: string;
}

export default function OfferLocation(props: Props) {
  const {
    locationIcon,
    officeLocations,
    isGlobalRemote,
    remoteDetails,
    remoteWorkingLabel,
    iconColor,
    location,
    mini,
    timezoneLabel,
  } = props;

  const showLocation = !isGlobalRemote && officeLocations.length > 0;
  const showRemoteDetails = remoteDetails && !mini;
  const onMultipleLocations = officeLocations.length > 1;
  const iconSize = mini ? Size.Medium : Size.MediumLarge;

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <Icon
          name={locationIcon}
          size={iconSize}
          className={classNames(styles.icon, styles[iconColor])}
        />
        <div className={classNames(styles.locationLabel, 'location-label')}>
          {location || remoteWorkingLabel}
          {showLocation && remoteWorkingLabel && !mini && <>&nbsp;- {remoteWorkingLabel}</>}
        </div>
        {onMultipleLocations && (
          <OfficeLocationsTooltip
            officeLocations={officeLocations}
            iconColor={iconColor}
            iconSize={iconSize}
          />
        )}
      </div>
      {(timezoneLabel || showRemoteDetails) && (
        <div className={styles.innerContainer}>
          {timezoneLabel && (
            <div className={styles.timezone}>
              <Icon
                name={'minimalistClock'}
                size={Size.MediumLarge}
                color='silverSandMidDark'
                className={styles.icon}
              />
              <div className={styles.timezoneLabelContainer}>
                <div className={styles.timezoneLabel}>{timezoneLabel}</div>
              </div>
            </div>
          )}
          {showRemoteDetails && (
            <div>
              <a className={styles.remoteDetails} onClick={() => scrollTo('#remoteDetails')}>
                More details
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
