import * as React from 'react';
import IntegrationButton from '../ats/token_button';

interface Props {
  url: string;
}

export default function Button(props: Props) {
  return (
    <IntegrationButton
      url={props.url}
      atsName={'Smart Recruiters'}
      atsPathToCreateToken={'Settings > Apps & Integrations > Credentials'}
      atsAPIDocUrl={'https://developers.smartrecruiters.com/docs/api-key/'}
    />
  );
}
