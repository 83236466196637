import React, { useState, useRef, useEffect } from 'react';

import Text from 'components/form/text/text';
import { handleRequestError, getJSON, getCSRFToken } from 'lib/request_deprecated';
import ErrorLabel from 'components/tags_page/error_label';
import TeamElementList, { teamDataMapper } from './TeamElementsList';
import ImageInput, { ImageData } from '../form/imageInput/ImageInput';
import AboutSectionSmallCard from './AboutSectionSmallCard';
import Button from 'lj_shared/button/button';

const styles = require('./TeamSection.module.scss');

const errorsDefault = {
  imageError: false,
  fullNameError: false,
  roleError: false,
  resumeError: false,
};

export default function TeamSection() {
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const [image, setImage] = useState(null);
  const [teamError, setTeamError] = useState(errorsDefault);
  const roleRef = useRef(null);
  const resumeRef = useRef(null);
  const fullNameRef = useRef(null);

  useEffect(() => {
    getJSON(window.Routes.employer_company_members())
      .then(data => {
        setMembers(teamDataMapper(data.data));
      })
      .catch(handleRequestError);
  }, []);

  function handleOnsave(imageData: ImageData) {
    setImage({
      name: imageData.image.name,
      file: imageData.image,
      cropH: imageData.cropH,
      cropW: imageData.cropW,
      cropX: imageData.cropX,
      cropY: imageData.cropY,
    });
  }

  function handleDelete() {
    setImage(null);
  }

  function BriefDescription() {
    const placeholder =
      "e.g. Get to know your future teammates. We're the ones you'll share your successes with!";

    return (
      <>
        <h3 className={styles.sectionSubTitle}>Give a brief description of your team</h3>
        <div className={styles.subSection}>
          <Text
            class={styles.hugeField}
            name='team_description'
            placeholder={placeholder}
            required={false}
            textarea={true}
            useContext={true}
          />
        </div>
      </>
    );
  }

  const imageUpload = () => {
    return (
      <>
        <h3 className={styles.sectionSubTitle}>Your future teammates</h3>
        <div className={styles.subSection}>
          <div className={styles.bigField}>{image ? userImage() : defaultImage()}</div>
        </div>
      </>
    );
  };

  const defaultImage = () => {
    return (
      <div className={styles.imageUploadContainer}>
        <ImageInput
          alt='Test'
          defaultValue='https://assets.landing.jobs/default_avatars/avatar2.png'
          onSave={handleOnsave}
          recommendedWidth={250}
          recommendedHeight={250}
        />
        {teamError.imageError && <ErrorLabel message={'Please add a Photo of your team member'} />}
      </div>
    );
  };

  const userImage = () => {
    return (
      <div className={styles.leftContainer}>
        <AboutSectionSmallCard text={image.name} handleDelete={handleDelete} />
      </div>
    );
  };

  const fullName = () => {
    return (
      <div className={styles.subSection}>
        <Text
          class={styles.smallField}
          label='Full name'
          name='fullName'
          placeholder='e.g. Ana Silva'
          required={false}
          useContext={true}
          ref={fullNameRef}
        />
        {teamError.fullNameError && (
          <ErrorLabel message={'Please give a name to your team member'} />
        )}
      </div>
    );
  };

  const role = () => {
    return (
      <div className={styles.subSection}>
        <Text
          class={styles.smallField}
          label='Role'
          name='role'
          placeholder='e.g. Co-founder & CEO'
          required={false}
          useContext={true}
          ref={roleRef}
        />
        {teamError.roleError && <ErrorLabel message={'Please assign a role to your team member'} />}
      </div>
    );
  };

  const resume = () => {
    const placeholder =
      'e.g. Experienced software developer with strong technical knowledge and coding ' +
      'skills. Karaoke warrior on the weekends.';

    return (
      <div className={styles.subSection}>
        <Text
          class={styles.hugeField}
          label='Resume'
          name='resume'
          placeholder={placeholder}
          required={false}
          textarea={true}
          useContext={true}
          ref={resumeRef}
        />
        {teamError.resumeError && (
          <ErrorLabel message={'Please give a resume to your team member'} />
        )}
      </div>
    );
  };

  const errors = (tempErrors = teamError) => {
    return Object.values(tempErrors).reduce((acc, cur) => acc || cur, false);
  };

  const checkForErrors = () => {
    setTeamError(errorsDefault);
    return errors(validateForm());
  };

  const validateForm = () => {
    const tempErrors = { ...teamError };

    tempErrors.fullNameError = fullNameRef.current.input.value.length === 0;
    tempErrors.roleError = roleRef.current.input.value.length === 0;
    tempErrors.resumeError = resumeRef.current.input.value.length === 0;
    tempErrors.imageError = !image;

    setTeamError(tempErrors);
    return tempErrors;
  };

  const addMembers = event => {
    event.preventDefault();
    if (loading || checkForErrors()) {
      return;
    }
    const fullNamePayload = fullNameRef.current.input.value;
    const rolePayload = roleRef.current.input.value;
    const resumePayload = resumeRef.current.input.value;

    setLoading(true);

    const formData = new FormData();
    formData.append('full_name', fullNamePayload);
    formData.append('role', rolePayload);
    formData.append('resume', resumePayload);
    formData.append('photo', image.file);
    formData.append('crop_h', image.cropH);
    formData.append('crop_w', image.cropW);
    formData.append('crop_x', image.cropX);
    formData.append('crop_y', image.cropY);

    fetch(window.Routes.employer_company_members(), {
      method: 'POST',
      headers: {
        'X-CSRF-Token': getCSRFToken(),
      },
      body: formData,
    })
      .then(response => response.json())
      .then(({ data }) => {
        fullNameRef.current.input.value = null;
        roleRef.current.input.value = null;
        resumeRef.current.input.value = null;
        handleDelete();
        setMembers(teamDataMapper(data));
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        handleRequestError(error);
      });
  };

  const renderAddButton = () => (
    <div className={styles.addButton}>
      <Button disabled={loading} isButton={true} buttonColor='silverSand' onClick={addMembers}>
        {loading ? 'Uploading' : 'Add'}
      </Button>
    </div>
  );

  return (
    <div className={styles.section} id='companyTeam'>
      <h3 className={styles.sectionTitle}>Team</h3>
      <div className={styles.sectionBody}>
        <BriefDescription />
      </div>
      <div className={styles.sectionBody}>
        {imageUpload()}
        {fullName()}
        {role()}
        {resume()}
        {renderAddButton()}
        {TeamElementList({ teamMembers: members }, setMembers)}
      </div>
    </div>
  );
}
