export default class RatingNotesUtils {
  dimensions: any;
  screeningNotes: any;
  hideCurationNotesIfLegacy: boolean;

  constructor(params) {
    this.hideCurationNotesIfLegacy = params.hideCurationNotesIfLegacy;
    this.dimensions =
      params.hasOwnProperty('dimensions') && params.dimensions && params.dimensions.length > 0
        ? params.dimensions
        : [];
    this.screeningNotes =
      params.hasOwnProperty('screeningNotes') &&
      params.screeningNotes &&
      params.screeningNotes.length > 0
        ? params.screeningNotes
        : this.renderEmptyNotes(params.dimensions);
  }

  renderEmptyNotes = dimensions => {
    return dimensions && dimensions.length > 0 ? '' : '<p>Screening Notes</p>';
  };

  hasAutomaticNotes = () =>
    this.dimensions.find(({ automatic_notes }) => automatic_notes.length > 0);

  prettyPrintDimensionNotes = () =>
    this.dimensions.reduce((htmlText, { automatic_notes }) => {
      htmlText += `<p>${automatic_notes.join(' ')}</p>`;
      return htmlText;
    }, '');

  props = () => {
    if (this.hideCurationNotesIfLegacy && !this.hasAutomaticNotes()) {
      return { notes: '' };
    }

    if (this.hasAutomaticNotes()) {
      return { notes: this.prettyPrintDimensionNotes() + this.screeningNotes };
    }
    return { notes: this.screeningNotes };
  };
}
