import { postJSON, patchJSON, handleRequestError } from '../../lib/request_deprecated';

class BaseForm {
  /**
   * Base class for react forms
   * It has a base constructor and some methods to save to the db
   * The goal is for this class to be extended by one more specific
   */

  saveUrl: string;
  updateUrl?: string;
  saved?: boolean;
  errors: any;

  /**
   * Given an object this method assigns the key value pairs to
   * the class
   * @param args
   */
  constructor(args) {
    // this doesn't work
    //this.update(args);
  }

  /**
   * Assigns new values to the form
   *
   * @param args
   */
  update(args) {
    if (!this.errors) {
      this.errors = {};
    } else {
      Object.keys(args).forEach(key => {
        let error;
        switch (key) {
          case 'startDateMonth':
          case 'startDateYear':
            error = 'start_date';
            break;
          case 'endDateMonth':
          case 'endDateYear':
          case 'currentPosition':
            error = 'end_date';
            break;
          case 'contractType':
            error = 'contract_type';
            break;
          default:
            error = key;
        }
        delete this.errors[error];
      });
    }

    Object.keys(args).map(k => {
      this[k] = args[k];
    });
  }

  serialize() {
    return {};
  }

  validate() {
    return true;
  }

  /**
   * Save the form the the DB. First it validates the form
   * Afterwards it checks if it is a _create_ or _update_
   * method and then ships it to the DB
   */
  async save() {
    if (this.validate()) {
      if (this.saved) {
        const promise = new Promise((resolve, reject) => {
          patchJSON(this.updateUrl, this.serialize())
            .then(response => {
              if (response.notice) {
                window.Alerts && window.Alerts.notice(response.notice);
                resolve({ saved: true, data: response.data, post: false });
              } else if (response.alert) {
                window.Alerts && window.Alerts.alert(response.alert);
                this.errors = response.errors;
                resolve({ saved: false, data: response.data });
              }
            })
            .catch(e => handleRequestError(e));
        });
        const result = await promise;
        return result;
      } else {
        const promise = new Promise((resolve, reject) => {
          postJSON(this.saveUrl, this.serialize())
            .then(response => {
              if (response.notice) {
                window.Alerts && window.Alerts.notice(response.notice);
                resolve({ saved: true, data: response.data, post: true });
              } else if (response.alert) {
                window.Alerts && window.Alerts.alert(response.alert);
                this.errors = response.errors;
                resolve({ saved: false, data: response.data });
              }
            })
            .catch(e => handleRequestError(e));
        });
        const result = await promise;
        return result;
      }
    }
  }
}

export default BaseForm;
