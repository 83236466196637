import React from 'react';
import * as Cookies from 'js-cookie';
import Button from 'lj_shared/button/button';
const styles = require('./cookie_disclaimer.module.scss');
const cookie = require('../../../images/cookie.svg');
const COOKIES_ACCEPTED = 'cookies-accepted';
interface Props {
  privacy_path: string;
}

interface State {
  open: boolean;
}

export default class CookieDisclaimer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: true,
    };
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleAccept = () => {
    Cookies.set(COOKIES_ACCEPTED, 'true');
    this.setState({
      open: false,
    });
  };

  render() {
    return (
      <div
        className={
          !this.state.open || Cookies.get(COOKIES_ACCEPTED) === 'true'
            ? styles.wrapperClosed
            : styles.wrapper
        }
      >
        <div className={styles.cookieImage}>
          <img src={cookie} alt='cookie' />
        </div>
        <div className={styles.buttons}>
          <a href={this.props.privacy_path}>Cookie Policy</a>
          <Button
            isButton={true}
            buttonSize='xSmall'
            otherClasses={styles.button}
            onClick={this.handleAccept}
          >
            Ok. I accept.
          </Button>
        </div>
        <div className={styles.iconWrapper}>
          <div className={styles.closeIcon} onClick={this.handleClose} />
        </div>
      </div>
    );
  }
}
