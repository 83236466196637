import React from 'react';
import { mount } from 'enzyme';
import ExperienceYearsInput from './ExperienceYearsInput';

const createProps = (otherProps = {}) => ({
  error: '',
  startYearsList: [2000, 2001, 2002],
  startYear: null,
  handleFormChange: jest.fn(),
  ...otherProps,
});

let props;
let wrapped;

describe('Your description here', () => {
  beforeAll(() => {
    props = createProps();
    wrapped = mount(<ExperienceYearsInput {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('shows the label', () => {
    expect(wrapped.find('.label').text()).toEqual('When did you start working in the tech area? *');
  });

  it('shows the placeholder', () => {
    expect(wrapped.find('.___placeholder > .___placeholder').text()).toEqual('e.g. 2015');
  });
});

describe('Input has one value selected', () => {
  beforeAll(() => {
    props = createProps({ startYear: { label: 2015, value: 2015 } });
    wrapped = mount(<ExperienceYearsInput {...props} />);
  });

  it('does not show the placeholder', () => {
    expect(wrapped.find('.___placeholder > .___placeholder')).toHaveLength(0);
  });

  it('shows the selected value', () => {
    expect(wrapped.find('input[name="start_year"]').props().value).toEqual(2015);
  });
});
