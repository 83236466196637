export default class RatingDimensionContainerUtils {
  landingRatingId: number;
  landingRating: number;
  dimensions: any;
  maxRating: any;

  constructor(params) {
    this.landingRatingId = params.landingRatingId;
    this.landingRating = params.landingRating;
    this.dimensions = params.dimensions;
    this.maxRating = params.maxRating;
  }

  dimensionsObject = () => {
    const dimension = this.dimensions.map(dimensionItem => ({
      uniqueKey: `${dimensionItem.dimension}_${this.landingRatingId}`,
      name: dimensionItem.dimension,
      title: `${dimensionItem.dimension.charAt(0).toUpperCase()}${dimensionItem.dimension.slice(
        1
      )}`,
      value: dimensionItem.value,
    }));
    return dimension.concat([
      {
        uniqueKey: `overall_${this.landingRatingId}`,
        name: 'overall',
        title: 'Overall',
        value: this.landingRating,
        maxRating: this.maxRating,
      },
    ]);
  };

  props = () => ({
    title: 'By dimension',
    dimensions: this.dimensionsObject(),
    maxRating: 5,
  });
}
