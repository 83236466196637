import * as React from 'react';
import LocationFilter from './location_filter';
import { shallow, ShallowWrapper } from 'enzyme';

const createTestProps = () => {
  return {
    handleLocationChange: jest.fn(),
    googleMapsApiKey: '',
    title: 'Job Location',
  };
};

let wrapped: ShallowWrapper;

describe('Candidate location Filter component', () => {
  beforeEach(() => {
    const props = createTestProps();
    wrapped = shallow(<LocationFilter {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
