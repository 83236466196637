import React, { useContext } from 'react';
import Icon from 'components/general/icon/icon';
import { JobCardCtx } from './JobCardContext';
const styles = require('./JobExperience.module.scss');

const JobExperience = () => {
  const jobCardContext = useContext(JobCardCtx);

  return (
    <div className={styles.jobCardExperience}>
      <span className={styles.experienceIcon}>
        <Icon name='barChart' />
      </span>
      <span>
        {!!(jobCardContext.job.experienceMin && jobCardContext.job.experienceMax) && (
          <>
            {`${jobCardContext.job.experienceMin} - ${jobCardContext.job.experienceMax} years of experience - `}
          </>
        )}

        {!!(jobCardContext.job.experienceMin && !jobCardContext.job.experienceMax) && (
          <> {`At least ${jobCardContext.job.experienceMin} years of experience - `}</>
        )}

        {!!(!jobCardContext.job.experienceMin && jobCardContext.job.experienceMax) && (
          <> {`At most ${jobCardContext.job.experienceMax} years of experience - `}</>
        )}

        {!!jobCardContext.job.experienceLevelLabel && (
          <>{`${jobCardContext.job.experienceLevelLabel}`}</>
        )}
      </span>
    </div>
  );
};

export default JobExperience;
