import React, { useEffect, useState } from 'react';

import {
  inviteUserSectionViewModel,
  usersManagementPageViewModel,
} from './users_management_view_model';
import AdminCard from '../general/admin_card/admin_card';
import Button from 'lj_shared/button/button';
import Select from 'components/form/select/select';
import Text from 'components/form/text/text';
import { patchJSON, deleteJSON, handleSuccessAlert, handleRequestError } from 'lib/request';
import ConfirmModal from 'components/general/confirm_modal/confirm_modal';
import AvailableTierFeatureCounter from 'components/available_tier_feature_counter/available_tier_feature_counter';

const styles = require('./users_management.module.scss');

interface Props {
  userId: number;
  companyId: number;
  maxUsersReached: boolean;
  maxUsersAllowed: number;
  businessEmail: string;
  pricingPlanUrl: string;
}

export default function UsersManagementPage(props: Props) {
  const [maxUsersReached, setMaxUsersReached] = useState(props.maxUsersReached);
  const { userId, companyId } = props;
  const maxUsersAllowed = props.maxUsersAllowed === null ? Infinity : props.maxUsersAllowed;

  const viewModel = usersManagementPageViewModel();
  const users = viewModel.state.users;
  const availableUsers = `${Math.max(0, maxUsersAllowed - users.length)}/${maxUsersAllowed}`;

  if (!maxUsersReached && users.length >= maxUsersAllowed) {
    setMaxUsersReached(true);
  } else if (maxUsersReached && users.length < maxUsersAllowed) {
    setMaxUsersReached(false);
  }

  return (
    <div>
      {props.maxUsersAllowed && (
        <div className={styles.availableUsersCounterContainer}>
          <AvailableTierFeatureCounter available={availableUsers} type={'users'} />
        </div>
      )}
      {maxUsersReached ? (
        <AdminCard companyId={companyId} pricingLimitType={'users'} />
      ) : (
        <InviteUserSection onSent={viewModel.onInviteSent} />
      )}
      {maxUsersAllowed !== 0 && (
        <UsersManagement userId={userId} users={users} onDelete={viewModel.onDelete} />
      )}
    </div>
  );
}

export function UsersManagement({ userId, users, onDelete }) {
  const options = [
    { label: 'Member', value: false },
    { label: 'Admin', value: true },
  ];

  const [selectUsers, setSelectUsers] = useState([]);

  const handleChange = (e, selected, id) => {
    if (e.label !== selected.label) {
      const tempUsers = selectUsers;
      const index = tempUsers.map(u => u.id).indexOf(id);
      tempUsers[index].selected.label = e.label;
      tempUsers[index].selected.value = e.value;
      setSelectUsers(tempUsers);

      patchJSON(
        window.Routes.company_change_user_permissions(),
        {
          admin: e.value,
          id,
        },
        handleSuccessAlert
      );
    }
  };

  const parseId = user => parseInt(user.key.split(' ').slice(-1)[0], 10);

  useEffect(() => {
    setSelectUsers(
      users.map(u => {
        return {
          ...u,
          id: parseId(u),
          selected: options.filter(op => op.value === (u.role === 'Admin'))[0],
        };
      })
    );
  }, [users]);

  const getUserName = (user: any, isUsersList = false) => {
    if (!user.name) {
      return isUsersList ? '' : user.email;
    } else {
      return user.name;
    }
  };

  function deleteCompanyUser(user) {
    const userName = getUserName(user);

    const renderConfirmationModal = () => {
      const title = 'Delete user';
      const body = () => (
        <div>
          <p>
            Are you sure you want to remove
            <span className={styles.name}> {userName} </span>
            from your list of employees?
          </p>
          <p className='lj-paragraph--small'>
            Note: {userName} will lose access to the company's account on Landing.Jobs platform.
          </p>
        </div>
      );

      return ConfirmModal.show(title, body())
        .then(confirmed => {
          if (confirmed) {
            handleDelete();
          }
        })
        .catch(handleRequestError);
    };

    const handleDelete = () => {
      window.toggleSpinner(true);

      const followUp = response => {
        if (response.success) {
          window.Alerts.notice(`${userName} successfully removed`);
          onDelete(user.email);
        } else {
          window.Alerts.alert('Oops, something went wrong.');
        }
        window.toggleSpinner(false);
      };

      deleteJSON(window.Routes.company_destroy_employee(user.id), null, followUp);
    };

    const renderSelfDeleteError = () => {
      window.Alerts.alert(`Sorry, you can't delete yourself!`);
    };

    return (
      <Button
        buttonColor='ripePlum'
        buttonType='border'
        buttonSize='xSmall'
        otherClasses={userId === user.id ? 'myself' : 'other'}
        isButton={true}
        onClick={userId === user.id ? renderSelfDeleteError : renderConfirmationModal}
      >
        Delete
      </Button>
    );
  }

  return (
    <>
      <div className={styles.rules}>
        <p className={styles.rulesText}>
          Only Admins can change the payment plan or billing info and manage users. Everything else
          on the platform is accessible by all users.
        </p>
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.th}>&nbsp;</th>
            <th className={styles.th}>User</th>
            <th className={styles.th}>Role</th>
            <th className={styles.statusCell}>Status</th>
            <th className={styles.th}>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {selectUsers.map(user => {
            const userName = getUserName(user, true);
            const hasName = userName.length > 1;
            return (
              <tr className={styles.row} key={user.key}>
                <td className={styles.avatarCell}>
                  <img alt='avatar' className={styles.avatar} src={user.avatarURL} />
                </td>
                <td className={styles.nameCell}>
                  {hasName && <span className={styles.name}>{userName}</span>}
                  <span className={styles.email}>{user.email}</span>
                </td>
                <td className={styles.roleCell}>
                  {user.id !== userId ? (
                    <Select
                      value={user.selected}
                      onChange={e => handleChange(e, user.selected, user.id)}
                      options={options}
                      isClearable={false}
                      color={'ripePlum'}
                    />
                  ) : (
                    user.role
                  )}
                </td>
                <td className={styles.statusCell}>{user.status}</td>
                <td className={styles.deleteCell}>{deleteCompanyUser(user)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export function InviteUserSection({ onSent }) {
  const viewModel = inviteUserSectionViewModel(onSent);
  const state = viewModel.state;
  const options = [
    { label: 'Member', value: false },
    { label: 'Admin', value: true },
  ];

  return (
    <div className={styles.inviteBlock}>
      <div>Invite users by adding their email address below.</div>
      <div className={styles.inviteForm}>
        <Text
          class={styles.emailInput}
          error={state.errors['email']}
          placeholder='user@example.com'
          ref={viewModel.emailRef}
        />
        <Select
          defaultValue={options[0]}
          error={state.errors['isAdmin']}
          isClearable={false}
          options={options}
          ref={viewModel.roleRef}
          color={'ripePlum'}
          wrapperClassName={styles.roleInput}
        />
        <Button
          buttonColor='ripePlum'
          disabled={state.submitting}
          isButton={true}
          otherClasses={styles.inviteButton}
          onClick={viewModel.onSend}
        >
          {state.submitting ? 'Sending...' : 'Send invitation'}
        </Button>
      </div>
    </div>
  );
}
