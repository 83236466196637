import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import ApplicationStartedToast from './application_started_toast';
import Icon from 'components/general/icon/icon';

let wrapped: ShallowWrapper;

describe('ApplicationStartedToast', () => {
  beforeEach(() => {
    wrapped = shallow(<ApplicationStartedToast />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('has one Icon', () => {
    expect(wrapped.find(Icon)).toHaveLength(1);
  });
});
