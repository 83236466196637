import { shuffle } from 'lib/string';

export default class Education {
  institutionList: any;
  blurred: boolean;

  constructor(params) {
    this.institutionList = params.institutions.sort(
      (a, b) => b.attributes.graduation - a.attributes.graduation
    );
    this.blurred = params.handshakeProfile && !params.unlocked && !params.open_profile;
  }

  degree = attributes => {
    return attributes.info ? `${attributes.degree} | ${attributes.info}` : attributes.degree;
  };

  institutionProps = attributes => ({
    id: attributes.id,
    title: this.blurred ? shuffle(attributes.institution.label) : attributes.institution.label,
    date: [attributes.graduation],
    contentTitle: this.degree(attributes),
    blurred: this.blurred,
  });

  props() {
    return this.institutionList.map(institutionItem =>
      this.institutionProps(institutionItem.attributes)
    );
  }
}
