import React from 'react';

let lan;

const defaultContextValue = {
  data: {
    // set your initial data shape here
    contextLanguage: lan,
  },
  set(newData) {
    this.setState(state => ({
      data: {
        ...state.data,
        ...newData,
      },
    }));
  },
};

const { Provider, Consumer } = React.createContext(defaultContextValue);

class ContextProviderComponent extends React.Component {
  constructor() {
    super();

    this.setData = this.setData.bind(this);
    this.state = {
      ...defaultContextValue,
      set: this.setData,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    lan = window.navigator.language;
    if (lan.includes('pt')) {
      lan = 'PT';
    } else {
      lan = 'EN';
    }
    this.setState(state => ({
      data: {
        ...state.data,
        contextLanguage: lan,
      },
    }));
  }

  setData(newData) {
    this.setState(state => ({
      data: {
        ...state.data,
        ...newData,
      },
    }));
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

export { Consumer as default, ContextProviderComponent };
