import React from 'react';
import InlineIcon from '../../general/inline_icon/inline_icon';

const markerIcon = require('iconic/map-marker.svg');
const styles = require('./application_card_header.module.scss');

interface Props {
  alt?: string;
  avatar?: string;
  children: any;
  buttonOne?: any;
  buttonTwo?: any;
  buttonThree?: any;
  cv?: any;
  className?: string;
  experienceYears?: { label: string; value: number };
  handleTitleClick?: Function;
  headerButtons?: any;
  icon?: string;
  location?: string;
  title: string;
  titleOnClick?: Function;
  titleSize?: string;
  titleUrl?: string;
}

export default function ApplicationCardHeader(props: Props) {
  const renderCardSubtitle = () => {
    if (!props.experienceYears) {
      return null;
    }
    return (
      <div className={styles.subTitle}>
        <div>{props.experienceYears.label}</div>
        <div>
          <InlineIcon alt='marker' path={markerIcon} />
          {props.location}
        </div>
      </div>
    );
  };

  const renderCardTitle = () => {
    return (
      <div className={styles.title}>
        {props.icon ? <InlineIcon alt={props.alt} path={props.icon} /> : null}
        {props.titleUrl ? (
          <a href={props.titleUrl} onClick={() => props.handleTitleClick}>
            {props.title}
          </a>
        ) : (
          <div className={styles.titleValue}>{props.title}</div>
        )}
        {renderCardSubtitle()}
      </div>
    );
  };

  const renderAvatar = () =>
    props.avatar && <div dangerouslySetInnerHTML={{ __html: props.avatar }} />;

  return (
    <div className='lj-card-header'>
      <div className='lj-card-title'>
        {renderAvatar()}
        {renderCardTitle()}
        {props.cv}
      </div>
      {props.buttonOne || props.buttonTwo || props.buttonThree ? (
        <div className='lj-buttons-top'>
          {props.buttonOne}
          <span className='u-marginHorizontal--xSmall' />
          {props.buttonTwo}
          <span className='u-marginHorizontal--xSmall' />
          {props.buttonThree}
        </div>
      ) : null}
      {props.headerButtons && <div className='lj-buttons-top'>{props.headerButtons}</div>}
    </div>
  );
}
