import { apolloClient } from 'lib/graphql';
import { ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import * as React from 'react';
import Select from 'components/form/select/select';
import classnames from 'classnames';

interface State {
  values: { label: string; value: string }[];
}

interface Props {
  clearButtonClass?: string;
  color: string;
  id: string;
  orderRef?: any;
  values?: { label: string; value: string }[];
}

const GQL_QUERY_ORDERS = gql`
  query ($query: String) {
    purchaseOrders(query: $query) {
      nodes {
        id
        identifier
      }
    }
  }
`;

export default class OrderIdMultiSelect extends React.Component<Props, State> {
  orderRef: any;
  styles: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      values: this.props.values || [],
    };
    this.styles = require('./order_id_multi_select.module.scss');
    this.clearState = this.clearState.bind(this);
    this.handleChange = this.handleChange.bind(this);
    if (this.props.orderRef) {
      this.orderRef = this.props.orderRef;
    }
  }

  componentDidMount() {
    if (this.props.clearButtonClass) {
      document
        .querySelector(this.props.clearButtonClass)
        .addEventListener('click', this.clearState);

      this.sendEvent();
    }
  }

  componentWillUnmount() {
    if (this.props.clearButtonClass) {
      document
        .querySelector(this.props.clearButtonClass)
        .addEventListener('click', this.clearState);
      this.sendEvent();
    }
  }

  clearState() {
    this.setState({ values: [] });
  }

  handleChange(e) {
    this.setState({ values: e }, () => {
      this.sendEvent();
    });
  }

  sendEvent() {
    const body: HTMLBodyElement = document.querySelector('body');
    const event = new Event('react:change');
    body.dispatchEvent(event);
  }

  render() {
    return <ApolloProvider client={apolloClient}>{this.renderInputs()}</ApolloProvider>;
  }

  renderInputs() {
    const formatOptionLabel = ({ value, label }) => (
      <div style={{ display: 'flex' }}>
        <div data-value={value}>{label}</div>
      </div>
    );

    const palette = {
      ripePlum: '#992E80',
      puertoRico: '#3BBCB0',
      tuftsBlue: '#007EFF',
    };

    const color = palette[this.props.color];
    const multiValueStyles = {
      multiValue: styles => {
        return {
          ...styles,
          backgroundColor: 'white',
          border: `1px solid ${color}`,
        };
      },
      multiValueLabel: styles => ({
        ...styles,
        color: `${color}`,
      }),
      multiValueRemove: styles => ({
        ...styles,
        color: `${color}`,
        ':hover': {
          backgroundColor: `${color}`,
          color: 'white',
        },
      }),
    };

    return (
      <>
        <div className={classnames(this.styles.inputRow, this.styles.smallSearchBar)}>
          <Select
            id={`q_${this.props.id}`}
            name={`q[${this.props.id}][]`}
            value={this.state.values}
            isMulti
            color={this.props.color}
            loadOptions={this.loadOptions}
            openMenuOnClick={false}
            placeholder='e.g. OP21-015, OH21-669'
            ref={this.orderRef}
            type='async'
            onChange={this.handleChange}
            formatOptionLabel={formatOptionLabel}
            styles={multiValueStyles}
          />
        </div>
      </>
    );
  }

  async loadOptions(query, callback) {
    const result = await apolloClient.query({
      query: GQL_QUERY_ORDERS,
      variables: { query },
    });

    return result.data.purchaseOrders.nodes.map(o => ({ label: o.identifier, value: o.id }));
  }
}
