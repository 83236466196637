import { gql } from 'lib/graphql';

export const GET_COMPANY_GQL = gql`
  query ($id: ID!) {
    company(id: $id) {
      averageHiringProcess(id: $id) {
        companyAvg
        marketAvg
      }
      coverPhotoUrl
      description
      id
      logoUrl
      name
      slug
      shortPitch
    }

    jobs(companyId: $id) {
      nodes {
        location
        id
        salary
        title
        locationIcon
        remotePolicy
        officeLocations {
          googlePlaceId
          city
          countryCode
          label
        }
      }
    }

    currentUser {
      id
      type
    }

    person {
      applications(companyId: $id) {
        id
        jobId
        state
        stateForCandidate
        inDraftStates
        submittedAt
      }
      companySubscriptions(companyId: $id) {
        id
      }
    }

    user {
      bookmarks {
        id
        jobId
      }
    }
  }
`;
