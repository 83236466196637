import React from 'react';
import Tooltip from './tooltip';
import { shallow, ShallowWrapper } from 'enzyme';

let wrapped: ShallowWrapper;

describe('Tooltip component', () => {
  wrapped = shallow(<Tooltip text='This is the tooltip text'>Hover to get more info</Tooltip>);

  it('renders correctly', () => {
    expect(wrapped.exists()).toBeTruthy();
  });

  it('shows the children', () => {
    expect(wrapped.find('.tooltip').text()).toMatch('Hover to get more info');
  });

  it('shows the tooltip text', () => {
    expect(wrapped.find('.tooltipTextAbove').text()).toBe('This is the tooltip text');
  });
});
