// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<SquareRadioButtons /> renders without crashing 1`] = `
<Fragment>
  <div
    className="squareRadioButtons vertical"
  >
    <span
      className="radio"
      key="test_0"
    >
      <input
        className="radioInput"
        id="test_0"
        name="test"
        type="radio"
        value="Item 1"
      />
      <label
        className="radioLabel"
        onClick={[Function]}
      >
        <div
          className="labelMain"
        >
          Item 1
        </div>
      </label>
    </span>
    <span
      className="radio"
      key="test_1"
    >
      <input
        className="radioInput"
        id="test_1"
        name="test"
        type="radio"
        value="Item 2"
      />
      <label
        className="radioLabel"
        onClick={[Function]}
      >
        <div
          className="labelMain"
        >
          Item 2
        </div>
      </label>
    </span>
    <span
      className="radio"
      key="test_2"
    >
      <input
        className="radioInput"
        id="test_2"
        name="test"
        type="radio"
        value="Item 3"
      />
      <label
        className="radioLabel"
        onClick={[Function]}
      >
        <div
          className="labelMain"
        >
          Item 3
        </div>
      </label>
    </span>
  </div>
</Fragment>
`;
