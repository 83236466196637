import * as React from 'react';
import Accordion from '../accordion/accordion';
import LocationInput from 'components/form/location_input/location_input';
const styles = require('./location_filter.module.scss');

interface Props {
  handleLocationChange: Function;
  className?: string;
  title: string;
}

export default class LocationFilter extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div className={styles.accordion + ' ' + this.props.className}>
        <Accordion title={this.props.title}>
          <LocationInput handleLocationChange={this.props.handleLocationChange} types='regions' />
        </Accordion>
      </div>
    );
  }
}
