import * as React from 'react';
import PageNumbers from './page_numbers';
import { shallow } from 'enzyme';

let wrapped;

beforeAll(() => {
  wrapped = shallow(
    <PageNumbers
      page_number={2}
      login_path='https://landing.jobs/login'
      sign_up_path='https://landing.jobs/join'
    />
  );
  wrapped.setState({ render: true });
});

it('shallow renders without crashing', () => {
  expect(wrapped.exists()).toBe(true);
});
