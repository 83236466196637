import * as React from 'react';
import SendApplicationByEmailModal, { Props } from './send_application_by_email_modal';
import { shallow, ShallowWrapper } from 'enzyme';
import TextInput from 'components/form/text/text';
import Modal from '../general/modal/modal';
import applicationDummy from '__fixtures__/application_dummy';

const createTestProps = moreProps => {
  return {
    sendUrl: 'url',
    topFilter: 'inbox',
    moveToStep: jest.fn(),
    hiringSteps: [],
    application: applicationDummy,
  };
};

let props: Props;
let wrapped: ShallowWrapper;

describe('Send application by email modal component', () => {
  beforeEach(() => {
    props = createTestProps({});
    wrapped = shallow(<SendApplicationByEmailModal {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('should have a modal', () => {
    expect(wrapped.find(Modal).length).toEqual(1);
  });

  it('should have 2 texts inputs', () => {
    expect(wrapped.find(TextInput).length).toEqual(2);
  });
});

test.todo('add more tests');
