// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<TextInput /> should render correctly with basic props 1`] = `
<div
  className="textarea ripePlum"
>
  <input
    className="input"
    name="test-textarea"
    onChange={[Function]}
  />
</div>
`;
