import { Job, Remote } from '__models__/gql/job';

const jobDummy: Job = {
  location: 'Nelas',
  countryCode: 'PT',
  id: '1',
  experienceLevelLabel: 'Senior',
  slug: 'devops-hero',
  title: 'DevOps Hero',
  stateName: 'published',
  company: null,
  tags: null,
  remotePolicy: 'Remote',
  isNew: false,
  experienceMin: '',
  experienceMax: '',
  mustHaveSkills: [],
  remote: Remote.None,
  salary: '',
  rate: '',
  locationIcon: '',
  officeLocations: [],
  timezoneTolerance: '',
  rateAndSalary: false,
  hiringBonus: 0,
};

export default jobDummy;
