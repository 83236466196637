import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import SearchTagItem from './SearchTagItem';
import Icon, { Style, Size } from 'components/general/icon/icon';

const createProps = () => ({
  handleDelete: jest.fn,
  label: 'Accelerator',
});

let props;
let wrapped: ShallowWrapper;

describe('Search Tag Item', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<SearchTagItem {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('has Icon', () => {
    expect(wrapped.find(Icon)).toHaveLength(1);
  });

  it('has no ratings card', () => {
    expect(wrapped.text()).toContain(props.label);
  });
});
