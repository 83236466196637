import React, { useRef, useState, useEffect } from 'react';
import LocationInput from 'components/form/location_input/location_input';
import Tag from 'components/general/tag/tag';
import Button from 'lj_shared/button/button';
import classNames from 'classnames';
import { GoogleMaps } from 'lib/scripts';
import { handleRequestError } from 'lib/request';

const styles = require('./multiple_locations_section.module.scss');

interface Props {
  currentLocations: any;
  isRequired: boolean;
  isBackoffice?: boolean;
  addOnClick?: boolean;
}

export default function MultipleLocationsSection(props: Props) {
  const { currentLocations, isRequired, isBackoffice, addOnClick = false } = props;
  const color = isBackoffice ? 'tuftsBlue' : 'ripePlum';
  const [loading, setLoading] = useState(false);
  const [legacyLocationsUpdated, setLegacyLocationsUpdated] = useState(false);
  const [locationInput, setLocationInput] = useState(null);
  const [locations, setLocations] = useState(currentLocations);
  const locationsRef = useRef([]);
  locationsRef.current = [...locations];
  const submitRef = useRef(false);

  const addLocationOfficesFormInput = () => {
    if (!submitRef.current) {
      submitRef.current = true;

      const formLocations = locationsRef.current;
      formLocations?.forEach(l => delete l.label);

      $('#job_ad_office_locations').val(JSON.stringify(formLocations));
    }
  };

  const toggleLocationsContainer = locationsCount => {
    if (locationsCount > 0) {
      $('.office-locations-container').show();
    } else {
      $('.office-locations-container').hide();
    }
  };

  useEffect(() => {
    addOnClick ? handleAddLocation() : void 0;
  }, [locationInput]);

  $(() => {
    if (!legacyLocationsUpdated) {
      void GoogleMaps.load().then(() => {
        const locationsCopy = [...locations];

        locationsCopy.forEach(location => {
          if (location.google_place_id === undefined) {
            const service = new google.maps.places.AutocompleteService();

            service
              .getPlacePredictions({ input: location.label }, results => {
                if (results.length > 0) {
                  const placeId = results[0].place_id;
                  const updatedLocation = { ...location, google_place_id: placeId };
                  const index = locationsCopy.findIndex(elem => elem.label === location.label);

                  locationsCopy[index] = updatedLocation;

                  setLocations(locationsCopy);
                }
              })
              .catch(handleRequestError);
          }
        });
      });

      setLegacyLocationsUpdated(true);
    }

    toggleLocationsContainer(locations.length);

    if ($('#job_submit_approval, #job_cancel_trigger').length > 0) {
      $('body').on(
        'mousedown',
        '#job_submit_approval, #job_cancel_trigger',
        addLocationOfficesFormInput
      );
    } else {
      window.addEventListener('submit', addLocationOfficesFormInput);
    }
  });

  const handleAddLocation = () => {
    if (locationInput?.address_components) {
      setLoading(true);

      const locationIndex = locationsRef.current.findIndex(l => {
        return l.google_place_id === locationInput.place_id;
      });

      if (locationIndex === -1) {
        const city = locationInput.address_components.find(component =>
          component.types.includes('locality')
        )?.long_name;

        const countryCode = locationInput.address_components.find(component =>
          component.types.includes('country')
        ).short_name;

        const newLocation = {
          city,
          country_code: countryCode,
          label: locationInput.formatted_address,
          google_place_id: locationInput.place_id,
        };
        const updatedLocations = [...locations].concat(newLocation);

        $('#location-filter').val(null);
        setLocationInput(null);
        setLocations(updatedLocations);
        toggleLocationsContainer(updatedLocations.length);
      }

      setLoading(false);
    }
  };

  const handleDelete = googlePlaceId => {
    const tempLocations = [...locations];
    const index = tempLocations.map(location => location.google_place_id).indexOf(googlePlaceId);

    tempLocations.splice(index, 1);
    setLocations(tempLocations);
    toggleLocationsContainer(tempLocations.length);
  };

  useEffect(() => {
    return () => {
      submitRef.current = false;
    };
  }, [submitRef.current]);

  return (
    <>
      <div className={styles.officeLocationHeader}>
        <div className={styles.labelContainer}>
          <label className={classNames(styles.label, 'ld-form-label')}>Office locations</label>
          {isRequired && <span className={classNames(styles.required, 'ld-form-required')}>*</span>}
        </div>
        <p className={classNames(styles.subLabel)}>
          Locations from where the candidates will be working.
        </p>
      </div>
      <div className={styles.officeLocationInputContainer}>
        <LocationInput
          types='regions'
          color={color}
          handleLocationChange={location => setLocationInput(location)}
        />
        {!addOnClick && (
          <Button
            buttonColor={color}
            onClick={handleAddLocation}
            disabled={loading}
            otherId={'office-location-add-button'}
          >
            Add
          </Button>
        )}
      </div>
      <div className={classNames(styles.multipleLocationsContainer, 'office-locations-container')}>
        {locations.map(location => {
          return (
            <div
              className={classNames(styles.locationTag, 'office-location-tag')}
              key={location.google_place_id || location.city}
            >
              <Tag title={location.label} color={color} labelClass={styles.labelClass} />
              <a
                className={classNames(styles.closeContainer, isBackoffice && styles.isBackoffice)}
                onClick={() => handleDelete(location.google_place_id)}
              >
                <div className={styles.close} />
              </a>
            </div>
          );
        })}
      </div>
    </>
  );
}
