import * as React from 'react';
import Button from 'lj_shared/button/button';
import './view_curation_pool.scss';

interface Props {
  curation_pool: any; // CurationPool
}

function ListAdmins(admins) {
  return admins.map(admin => (
    <li key={admin.id}>
      {' '}
      <a href={admin.url}>{admin.name}</a>
    </li>
  ));
}
function ListJobs(jobs) {
  return jobs.map(job => (
    <li key={job.id}>
      {' '}
      <a href={job.url}>
        {job.title} @ {job.company}{' '}
      </a>
      ({job.curated_applications}/{job.total_applications}) - {job.state_name}
    </li>
  ));
}
class ViewCurationPool extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    window.location = this.props.curation_pool.urls.edit;
  }

  render() {
    const deleteButtonAlert = () => {
      if (this.props.curation_pool.jobs.length > 0) {
        return (
          'There are jobs still associated with this pool, are you sure you want to delete it?' +
          ' These jobs will be moved to the Default pool.'
        );
      } else {
        return (
          'This Curation Pool will be deleted forever!' +
          " If you're really sure that you want to delete, press OK."
        );
      }
    };

    return (
      <div className='bo-container'>
        <h2 className='ld-h2 lj-pool-name'>{this.props.curation_pool.name}</h2>
        <div>
          <Button
            isButton={true}
            buttonSize='small'
            buttonColor='blue'
            onClick={this.handleClick}
            otherClasses='ld-blue-button butt-white-font'
          >
            Edit
          </Button>
          {this.props.curation_pool.name !== 'Default' && (
            <a
              className='lj-button lj-button--small ld-red-button butt-white-font'
              href={this.props.curation_pool.urls.destroy}
              data-method='delete'
              data-confirm={deleteButtonAlert()}
            >
              Delete
            </a>
          )}
        </div>

        <table className='ld-table'>
          <thead>
            <tr>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>ID</td>
              <td>{this.props.curation_pool.id}</td>
            </tr>
            <tr>
              <td>NAME</td>
              <td>{this.props.curation_pool.name}</td>
            </tr>
            <tr>
              <td>ASSIGNED CURATORS</td>
              <td>
                <ul>{ListAdmins(this.props.curation_pool.admins)}</ul>
              </td>
            </tr>
            <tr>
              <td>JOBS ASSOCIATED (curated/total)</td>
              <td>
                <ul>{ListJobs(this.props.curation_pool.jobs)}</ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
export default ViewCurationPool;
