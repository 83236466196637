import * as React from 'react';
import WizardSidebar from './wizard_sidebar';
import { shallow, ShallowWrapper } from 'enzyme';

let wrapped: ShallowWrapper;

describe('Wizard Sidebar component', () => {
  beforeEach(() => {
    wrapped = shallow(
      <WizardSidebar wizardType='' image='' name='' previous_url='' currentStep={1} />
    );
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
