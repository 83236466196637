import * as React from 'react';
import { mount } from 'enzyme';
import Footer from './Footer';

window.Routes = {
  privacy: jest.fn(),
};

describe('Footer', () => {
  it('shallow renders without crashing', () => {
    const component = mount(<Footer />);

    expect(component.exists()).toBe(true);
  });

  it('renders all default components', () => {
    const component = mount(<Footer />);

    expect(component.find('CookieDisclaimer')).toHaveLength(1);
  });
});
