import React from 'react';
import Icon, { Size } from 'components/general/icon/icon';
import classnames from 'classnames';
import Badge from 'components/general/badge/Badge';

const styles = require('./expiresIn.module.scss');

interface Props {
  days: number;
  isLabel?: boolean;
}

const ExpiresIn = (props: Props) => {
  const { days, isLabel } = props;
  const expireColor = days >= 15 ? 'tuftsBlue' : 'flamingo';

  const getText = () => (
    <>
      <Icon
        name='timerOutline'
        color={isLabel ? 'white' : expireColor}
        size={isLabel ? Size.Small : Size.MediumLarge}
      />
      <span className={classnames(styles.text, !isLabel && styles[expireColor])}>
        Expires in{' '}
        <strong>
          {days} day{days > 2 ? 's' : ''}
        </strong>
      </span>
    </>
  );

  return (
    <div className={styles.container}>
      {isLabel ? (
        <Badge className={styles.label} color={expireColor}>
          {getText()}
        </Badge>
      ) : (
        getText()
      )}
    </div>
  );
};

export default ExpiresIn;
