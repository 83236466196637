import React from 'react';
import GenericSelectRange, { Range } from './GenericSelectRange';

interface Props {
  handleFormChange: (event: any, input: string) => void;
  gas: Range;
}

const GAS_LIST: Range[] = [
  { minimum: 0, maximum: 20000 },
  { minimum: 20000, maximum: 30000 },
  { minimum: 30000, maximum: 40000 },
  { minimum: 40000, maximum: 50000 },
  { minimum: 50000, maximum: 60000 },
  { minimum: 60000, maximum: 70000 },
  { minimum: 70000, maximum: 80000 },
  { minimum: 80000 },
];

export default function GrossAnnualSalaryRange(props: Props) {
  return (
    <GenericSelectRange
      formInputLabel={'What gross annual salary do you expect as a permanent employee?'}
      formInputSubLabel={'(this information is not visible to companies)'}
      formInputName={'gas_range'}
      handleFormChange={props.handleFormChange}
      placeholder={'e.g. € 50K - € 60K'}
      profileAttribute={'gasRange'}
      range={props.gas}
      rangesList={GAS_LIST}
    />
  );
}
