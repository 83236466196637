import * as React from 'react';
import './message_container.scss';
import AnimateHeight from 'react-animate-height';
import 'pure-react-carousel/dist/react-carousel.es.css';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const chevronPath = require('svg/arrow-down-thin.svg');

interface Props {
  title: string;
  icon: string;
  children?: React.ReactNode;
}
interface State {
  height: number;
  windowHeight: number;
  windowWidth: number;
  isMobile: boolean;
  dismiss: string;
}

export default class MessageContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      height: 65,
      windowHeight: 0,
      windowWidth: 0,
      isMobile: false,
      dismiss: 'false',
    };
    this.toggle = this.toggle.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.setState({ dismiss: cookies.get('dismiss_message') });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      isMobile: window.innerWidth < 577,
    });
  }

  toggle = () => {
    const { height } = this.state;
    const newHeight: any = this.state.isMobile ? this.state.windowHeight : 482;

    this.setState({
      height: height === 65 ? newHeight : 65,
    });
  };

  renderPanel() {
    <div className='ld-help-message' />;
  }

  handleDismiss() {
    cookies.set('dismiss_message', 'true');
    this.setState({ dismiss: cookies.get('dismiss_message') });
  }

  getPosition() {
    if (this.state.windowWidth < 577) {
      return 'auto';
    } else if (this.state.windowWidth < 769) {
      return (this.state.windowWidth - 565) / 2 + 'px';
    } else {
      return '181px';
    }
  }

  render() {
    const { height, dismiss, isMobile, windowHeight } = this.state;
    const dismissMessage = this.state.isMobile ? 'Dismiss' : 'Dismiss this message';
    const messageStyle = { maxHeight: windowHeight - 300 + 'px' };
    const wrapperStyle = { left: this.getPosition() };

    const childrenWithProps = React.Children.map(this.props.children, child => {
      return React.cloneElement(child as React.ReactElement<any>, {
        mobile: isMobile,
        style: messageStyle,
        toggle: () => this.toggle(),
      });
    });

    return (
      <div
        className={dismiss === 'true' ? 'lj-help-message-none' : 'lj-help-message-wrapper'}
        style={wrapperStyle}
      >
        <AnimateHeight duration={500} height={height}>
          <div className='lj-help-message-container'>
            <div className='lj-help-message-header'>
              <div className='lj-help-actions'>
                <button
                  onClick={this.toggle}
                  id='open-panel'
                  className={height === 65 ? 'is-closed' : 'is-open'}
                >
                  <img
                    className='iconic iconic-sm lj-help-arrow'
                    src={chevronPath}
                    data-direction='up'
                    alt='chevron'
                  />
                </button>
                <button onClick={this.handleDismiss} className='ld-small-text ld-dismiss-message'>
                  {dismissMessage}
                </button>
              </div>
              <div className='lj-help-title'>
                <img src={this.props.icon} alt='help' className='iconic iconic-md' />
                <h3>{this.props.title}</h3>
              </div>
            </div>
          </div>
          <div className='lj-message-wrapper'>{childrenWithProps}</div>
        </AnimateHeight>
      </div>
    );
  }
}
