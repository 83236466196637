import * as React from 'react';
import 'lib/globals';
import './sidebar.scss';
import Icon, { Size } from '../icon/icon';

interface Features {
  projects?: boolean;
}

export interface Props {
  active: string;
  features?: Features;
  counter?: object;
  userImage?: string;
  userName?: string;
  userType: string;
}

export default class Sidebar extends React.Component<Props> {
  render() {
    if (this.props.userType === 'CompanyUser') {
      return <CompanySidebar {...this.props} />;
    } else if (this.props.userType === 'TalentUser') {
      return <TalentSidebar {...this.props} />;
    }
  }

  componentDidMount() {
    if (document.querySelector('img.iconic')) {
      window.IconicJS().inject('img.iconic');
    }
  }
}

function CompanySidebar(props) {
  const links = {
    General: {
      Handshakes: [
        'handshake',
        window.Routes.sourcing_employer({ scope_filter: 'accepted' }),
        'handshakes',
      ],
      Jobs: ['briefcase', window.Routes.employer_job_ads(), 'jobs'],
    },
    Saved: {
      'Saved Searches': [
        'magnify',
        window.Routes.employer_sourcing_saved_searches(),
        'saved_searches',
      ],
      Favourites: ['star', window.Routes.favourites_employer_sourcing(), 'favourites'],
    },
  };

  const lists = CreateSidebarGroups(links, props);

  return (
    <nav className='lj-sidebar --company'>
      {lists}
      <a href='/post' className='lj-sidebar-action'>
        <img className='iconic iconic-sm' data-src={require('iconic/plus.svg')} />
        <span>Post a job</span>
      </a>
    </nav>
  );
}

function TalentSidebar(props) {
  const { userName, userImage } = props;

  const links = {
    General: {
      Dashboard: ['grid', '/dashboard', 'overview'],
      Applications: ['rocket', '/dashboard/applications', 'apps'],
      'My Saved Jobs': ['star', '/dashboard/bookmarks', 'saved_jobs'],
      Handshakes: ['handshakeFull', '/dashboard/handshakes', 'handshakes'],
      Suggestions: ['chat', '/dashboard/suggestions', 'suggestions'],
    },
    Social: {
      Referrals: ['forward', '/dashboard/referrals/received', 'referrals'],
      'Invite Friends': ['openEnvelope', '/dashboard/invites', 'invites'],
    },
    More: {
      Subscriptions: ['bookmark', '/dashboard/subscriptions', 'subscriptions'],
      History: ['history', '/dashboard/activities/notifications', 'activity'],
      Assessments: ['documentMinimalist', '/dashboard/assessments', 'assessments'],
    },
  };

  const features = props.features || {};

  if (features.projects) {
    links.General['Projects'] = [
      require('images/icons/project.svg'),
      window.Routes.dashboard_placements(),
      'projects',
    ];
  }

  const lists = CreateSidebarGroups(links, props);

  const imageStyling = {
    backgroundImage: `url(${userImage})`,
  };

  return (
    <nav className='lj-sidebar --talent'>
      <a className='lj-sidebar-profile' href='/profile'>
        <div className='lj-sidebar-image' style={imageStyling}>
          &nbsp;
        </div>
        <div>
          <h3>{userName}</h3>
          <p>See profile</p>
        </div>
      </a>
      {lists}
    </nav>
  );
}

const CreateSidebarGroups = (links, props) => {
  const { active, counter } = props;
  const lists = [];

  for (const group in links) {
    if (links.hasOwnProperty(group)) {
      const key = Object.keys(links).indexOf(group);
      lists.push(
        <SidebarGroup
          key={key}
          groupKey={key}
          name={group}
          group={links[group]}
          active={active}
          counter={counter}
        />
      );
    }
  }

  return lists;
};

const SidebarGroup = props => {
  const { name, group, active, groupKey, counter } = props;
  const listItems = [];

  if (name !== 'General') {
    listItems.push(<br key={name} />);
  }

  for (const item in group) {
    if (group.hasOwnProperty(item)) {
      const linkKey = Object.keys(group).indexOf(item);
      listItems.push(
        <SidebarLink
          key={String(groupKey) + '_' + String(linkKey)}
          active={active === group[item][2] ? '--active' : ''}
          text={item}
          icon={group[item][0]}
          url={group[item][1]}
          counter={counter}
        />
      );
    }
  }

  return <ul>{listItems}</ul>;
};

const SidebarLink = props => {
  const { url, icon, text, active, counter } = props;

  return (
    <li className={active}>
      <a href={url}>
        <Icon name={icon} color='white' size={Size.Medium} className='icon-white' />
        <span className='lj-sidebar-item'>{text}</span>
        {counter && Object.keys(counter) && counter[text] > 0 && (
          <span className='lj-sidebar-dot'>
            {counter[text]}
            <span className='lj-sidebar-double-dot' />
          </span>
        )}
      </a>
    </li>
  );
};
