import React from 'react';
import { Company } from '__models__/gql/all';
import {
  facebookCompanyShareUrl,
  linkedinCompanyShareUrl,
  twitterCompanyShareUrl,
  whatsappCompanyShareUrl,
  facebookLandingShareUrl,
  twitterLandingShareUrl,
  whatsappLandingShareUrl,
  linkedinLandingShareUrl,
} from 'lib/marketing';
import Icon, { Size } from '../icon/icon';
import { JobAd } from '__models__/job_ad';

const styles = require('./ShareSection.module.scss');

interface Props {
  company?: Partial<Company>;
  jobAd?: Partial<JobAd>;
}

export default function ShareSection(props: Props) {
  const getSocialParams = () => {
    if (props.company) {
      return {
        name: props.company.name,
        description: props.company.description,
        url: window.Routes.company_url(props.company.slug),
      };
    } else if (props.jobAd) {
      return {
        name: props.jobAd.attributes.title,
        description: props.jobAd.attributes.role_description,
        url: window.Routes.job_ad_url(props.jobAd.attributes.slug),
      };
    }
  };

  const socialParams = getSocialParams();

  return (
    <div className={socialParams ? styles.followSection : styles.homeFollowSection}>
      <span className={styles.socialTitle}>Share</span>
      <SocialLink
        name='facebook'
        url={
          socialParams
            ? facebookCompanyShareUrl(
                socialParams['name'],
                socialParams['description'],
                socialParams['url']
              )
            : facebookLandingShareUrl()
        }
        jobsPage={socialParams}
      />
      <SocialLink
        name='twitter'
        url={
          socialParams
            ? twitterCompanyShareUrl(socialParams['name'], socialParams['url'])
            : twitterLandingShareUrl()
        }
        jobsPage={socialParams}
      />
      <SocialLink
        name='linkedin'
        url={
          socialParams
            ? linkedinCompanyShareUrl(
                socialParams['name'],
                socialParams['description'],
                socialParams['url']
              )
            : linkedinLandingShareUrl()
        }
        jobsPage={socialParams}
      />
      <SocialLink
        name='whatsapp'
        url={
          socialParams
            ? whatsappCompanyShareUrl(socialParams['name'], socialParams['url'])
            : whatsappLandingShareUrl()
        }
        jobsPage={socialParams}
      />
    </div>
  );
}

function SocialLink({ name, url, jobsPage }) {
  return (
    <a href={url} onClick={handleSocialClick} rel='noopener noreferrer' target='_blank'>
      <Icon className={jobsPage ? styles.socialIcon : styles.socialIconHomepage} name={name} size={name == 'twitter' ? Size.QuiteSmall : Size.Medium}/>
    </a>
  );
}

function handleSocialClick(event) {
  event.preventDefault();
  const url = event.currentTarget.href;
  const features = 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600';
  window.open(url, '', features);
}
