import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import RejectionReasonModal from './rejectionReasonModal';
import Button from 'lj_shared/button/button';
import Icon from 'components/general/icon/icon';
import Modal from 'components/general/modal/modal';

const createProps = () => ({
  handshakes: [
    {
      rejected_at: '2 Nov 2022',
      reason: 'Not available',
      job_title: 'Frontend developer',
    },
  ],
  color: 'ripePlum',
  showJob: false,
});

let props;
let wrapped: ShallowWrapper;

describe('Frontoffice RejectionReasonModal', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<RejectionReasonModal {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('it has a button with an icon', () => {
    const button = wrapped.find(Button);

    expect(button.length).toEqual(1);
    expect(button.find(Icon).length).toEqual(1);
  });

  it('it should have a modal with two paragraphs', () => {
    const modal = wrapped.find(Modal);

    expect(modal.length).toEqual(1);
    expect(modal.find('p').length).toEqual(2);
  });
});
