import * as React from 'react';
import { apolloClient } from 'lib/graphql';
import { ApolloProvider } from 'react-apollo';
import Select from 'components/form/select/select';
import gql from 'graphql-tag';
export interface Props {
  errors: any;
  createTagCallback: any;
  selectTagCallback: any;
  color?: string;
}

interface State {
  errors: any;
}

export default class AdvancedSkillsInput extends React.Component<Props, State> {
  tagRef: any;
  styles: any;
  mainColor: string;

  constructor(props) {
    super(props);
    this.state = { ...props };
    this.tagRef = React.createRef();
  }

  async loadOptions(query, callback) {
    const result = await apolloClient.query({
      query: GQL_QUERY_TAGS,
      variables: { query },
    });
    return result.data.tags.nodes.map(t => ({ label: t.name, value: t.id }));
  }

  createOptionMessage(inputValue: string) {
    return `Skill "${inputValue}" not found. Click here to create it`;
  }

  handleCreateNewTag = (inputValue: any) => {
    this.props.createTagCallback(inputValue);
  };

  handleSelect(option) {
    this.setState(state => ({
      errors: [],
    }));

    if (option.value == null) {
      this.setState(state => ({
        errors: ["Skill Name Can't be Blank"],
      }));
    }

    this.props.selectTagCallback(option);
  }

  render() {
    return <ApolloProvider client={apolloClient}>{this.renderInputSection()}</ApolloProvider>;
  }

  renderInputSection() {
    let error = null;
    if (this.state.errors != null && this.state.errors.length > 0) {
      error = this.state.errors[0];
    }
    return (
      <>
        {this.renderInputs()}
        {error != null ? (
          <div className='ld-row error'>
            <div className='ld-alert ld-error-alert'>
              <span className='error_text'>{error}</span>
            </div>
          </div>
        ) : null}
      </>
    );
  }

  renderInputs() {
    let selectType = 'async';
    if (this.props.createTagCallback !== null) {
      selectType = 'async-creatable';
    }
    return (
      <>
        <Select
          color={this.props.color ? this.props.color : 'tuftsBlue'}
          defaultOptions={[]}
          loadOptions={this.loadOptions}
          openMenuOnClick={false}
          placeholder='E.g. PHP'
          ref={this.tagRef}
          type={selectType}
          formatCreateLabel={this.createOptionMessage}
          onCreateOption={this.handleCreateNewTag}
          onChange={event => this.handleSelect(event)}
          styles={{
            menuPortal: base => ({
              ...base,
              zIndex: 9999,
            }),
          }}
          menuPortalTarget={document.body}
        />
      </>
    );
  }
}

const GQL_QUERY_TAGS = gql`
  query ($query: String) {
    tags(query: $query, excludeUserTags: false) {
      nodes {
        id
        name
      }
    }
  }
`;
