import React, { useRef } from 'react';
import Button from 'lj_shared/button/button';
import RequestHandshakeSvg from '../request_handshake_svg/request_handshake_svg';
import Modal from 'components/general/modal/modal';
import AdminCard from 'components/general/admin_card/admin_card';

interface Props {
  personId?: number;
  companyId: number;
  type: string;
}

// For now this component only includes the call for when the
// company has reached the maximum handshakes allowed for its pricing plan
export default function RequestHandshakeButton(props: Props) {
  const commonSquareStyle = 'lj-candidateSearch-requestButton lj-button lj-button--ripePlum ';
  const modalRef = useRef(null);
  const { personId, companyId, type } = props;

  let buttonText;
  let buttonStyle;
  let namespace;

  switch (type) {
    case 'squareButton':
      buttonText = ' Request Handshake';
      buttonStyle = commonSquareStyle + 'lj-button--small';
      namespace = `handshake-other-${personId}`;
      break;
    case 'squareBulkButton':
      buttonText = ' Request Handshakes';
      buttonStyle = commonSquareStyle + 'lj-button--xSmall u-marginRight';
      break;
    case 'roundButton':
      buttonText = 'Request';
      buttonStyle = 'lj-candidateSearchCard-statusInner lj-candidateSearchCard-request';
      namespace = `handshake-request-${personId}`;
      break;
  }

  const openModal = e => {
    e.preventDefault();
    modalRef.current.open();
  };

  return (
    <>
      <Button isButton otherClassesOnly otherClasses={buttonStyle} onClick={openModal}>
        {namespace && <RequestHandshakeSvg namespace={namespace} />}
        {buttonText}
      </Button>
      <Modal title='Request Handshake' ref={modalRef} disableOutsideClose={true}>
        <AdminCard companyId={companyId} pricingLimitType={'weekly handshakes'} />
      </Modal>
    </>
  );
}
