import React from 'react';
import { mount } from 'enzyme';
import LinkedInInput, { Props } from './LinkedInInput';

const createProps = (otherProps = {}) => ({
  error: '',
  handleAddWebsiteClick: jest.fn,
  handleDeleteWebsiteClick: jest.fn,
  website: null,
  ...otherProps,
});

let props: Props;
let wrapped;

describe('Input is empty', () => {
  beforeAll(() => {
    props = createProps();
    wrapped = mount(<LinkedInInput {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('shows the label', () => {
    expect(wrapped.find('.label').text()).toEqual('Add your LinkedIn account');
  });
});

describe('Input has value selected', () => {
  beforeAll(() => {
    props = createProps({
      website: {
        category: 'LinkedIn',
        category_key: 'linkedin',
        url: 'https://linkedin.com/in/joana-rflores/',
        id: 133902,
      },
    });
    wrapped = mount(<LinkedInInput {...props} />);
  });

  it('shows the selected value', () => {
    expect(wrapped.find('input#links').props().value).toEqual(
      'https://linkedin.com/in/joana-rflores/'
    );
  });
});
