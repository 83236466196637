import * as React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import RatingDimensionContainer from './rating_dimension_container';

const createProps = () => ({
  title: 'By dimension',
  dimensions: [
    {
      uniqueKey: 'overall_86605',
      name: 'overall',
      title: 'Overall',
      value: 5,
    },
  ],
  maxRating: 5,
});

let props;
let wrapped: ShallowWrapper;

describe('Rating Container component', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<RatingDimensionContainer {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
