import * as React from 'react';
import Hero from './hero';
import HowItWorks from './howItWorks';
import HowWeHelp from './how_we_help';
import Features from './features';
import ContextConsumer from './context';
import * as Cookies from 'js-cookie';

const EN = require('../copy/EN/home.json');
const PT = require('../copy/PT/home.json');

interface State {
  isLoading: boolean;
}

/**
 * Component which holds all the components for the '/' route
 */
export default class Index extends React.Component<{}, State> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: false });
  }

  render() {
    let currentLanguage;
    let language;

    return (
      <ContextConsumer>
        {({ data }) => {
          language = Cookies.get('locale');
          language === 'pt' ? (currentLanguage = PT) : (currentLanguage = EN);
          return (
            <>
              <Hero language={currentLanguage.Hero} />
              <HowItWorks
                title={currentLanguage.HowItWorks.title}
                step1={currentLanguage.HowItWorks.step1}
                step2={currentLanguage.HowItWorks.step2}
                step3={currentLanguage.HowItWorks.step3}
                step4={currentLanguage.HowItWorks.step4}
                step5={currentLanguage.HowItWorks.step5}
                step6={currentLanguage.HowItWorks.step6}
              />
              <HowWeHelp
                sections={[currentLanguage.Independence, currentLanguage.Support]}
                company={false}
              />
              <Features language={currentLanguage.What} />
            </>
          );
        }}
      </ContextConsumer>
    );
  }
}
