/**********************************************************
 ============== DEPRECATED - DON'T USE ===================
 *******++++++....-----**************-----....+++++++******

           Use `general/icon/icon.tsx` instead.

 **********************************************************/
import 'lib/globals';
import classNames from 'classnames';
import * as React from 'react';
import Deprecated from 'components/deprecated';

interface Props {
  alt?: string;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  data?: Object;
  onClick?: (event: any) => any;
  path: string;
  flex?: boolean;
}

class InlineIconInner extends React.Component<Props> {
  private icon: any;

  constructor(props) {
    super(props);
    this.setIcon = this.setIcon.bind(this);
  }

  componentDidMount() {
    const each = svg => svg.addEventListener('click', this.props.onClick);
    window.IconicJS().inject(this.icon, { each });
  }

  render() {
    const sizeClass = this.props.size ? `iconic-${this.props.size}` : 'iconic-sm';
    const classes = classNames('iconic', sizeClass, this.props.className);
    const data = this.props.data || {};
    const dataProps = {};

    for (const key in data) {
      if (data.hasOwnProperty(key)) dataProps[`data-${key}`] = data[key];
    }

    return (
      <span style={this.props.flex ? { display: 'flex', alignItems: 'center' } : {}}>
        <img
          alt={this.props.alt}
          className={classes}
          ref={this.setIcon}
          src={this.props.path}
          {...dataProps}
        />
      </span>
    );
  }

  setIcon(element) {
    this.icon = element;
  }
}

// Using a wrapper to force InlineIcon remount when the path changes.
class InlineIcon extends React.Component<Props> {
  render() {
    return <InlineIconInner key={this.props.path} {...this.props} />;
  }
}

export default Deprecated<Props, {}>(InlineIcon, 'Use Icon instead');
