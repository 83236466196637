import React from 'react';
import ImageInput from './ImageInput';
import { mount } from 'enzyme';
import RemoveImage from './RemoveImage';

const createProps = (otherProps = {}) => ({
  alt: 'Logo',
  ...otherProps,
});

let props;
let wrapped;

describe('ImageInput', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = mount(<ImageInput {...props} />);
  });

  it('should render upload button', () => {
    expect(wrapped.find('a').first().text()).toBe('Upload image');
  });
});
