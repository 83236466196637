import * as React from 'react';
import './empty_state.scss';

interface Props {
  children: any;
  style?: any;
}
class EmptyState extends React.Component<Props> {
  render() {
    return (
      <div className='empty-state' style={this.props.style}>
        {this.props.children}
      </div>
    );
  }
}

export default EmptyState;
