import ProfileForm from 'components/registration/profile_form';
import { contactPreference } from 'components/registration/registration_utils/registration_utils';
const Settings = require('settings.json');

export default class AboutYourself {
  profileInfo: ProfileForm;

  constructor(params) {
    this.profileInfo = params.profileInfo;
  }

  phoneNumber = () => this.profileInfo.phoneNumber;

  skypeId = () => this.profileInfo.skypeId;

  email = () => this.profileInfo.email;

  citizenships = () => {
    const citizenships = this.profileInfo.citizenships;
    if (citizenships) {
      return citizenships.map(citizenship => citizenship.label).join(', ');
    } else {
      return [];
    }
  };

  languages = () => {
    const languages = this.profileInfo.personLanguages;
    if (languages?.length === 0) {
      return [];
    }
    return languages;
  };

  birthYear = () => this.profileInfo.birthYear;

  contactPreference = () => {
    const contactPreferenceOption = contactPreference(
      Settings.person.contactPreference.options,
      this.profileInfo.contactPreference
    );
    if (contactPreferenceOption.value === Settings.person.contactPreference.otherOption.id) {
      return `${contactPreferenceOption.label} - ${this.profileInfo.contactPreferenceOther}`;
    } else {
      return contactPreferenceOption.label || null;
    }
  };
}
