import React, { useState, useEffect } from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import Tooltip from 'components/general/tooltip/tooltip';
import Icon from 'components/general/icon/icon';

const astroboy = require('images/astroboy.svg');
const styles = require('./CardAvatarContainer.module.scss');

export enum Thickness {
  Thin = 2, // default
  Medium = 4,
  Thick = 6,
}

export enum Colour {
  Blue = '#4293ea', // tuftsBlue, midLight
}

/* Higher Interpolation values will result in less computational load,
 at the cost of animation Smoothness (advised for slower computers/browsers)*/
export enum Interpolation {
  Smooth = 1,
  Balanced = 5,
  Fast = 8,
}

interface Props {
  animationProps?: AnimationProps;
  animated?: boolean;
  avatar?: any;
  color?: Colour;
  completeness?: number;
  adminUser?: any;
  handshakeProfile?: boolean;
  hideText?: boolean;
  incompleteFields?: { name: string; star: boolean }[];
  mobile?: boolean;
  publicProfilePage?: boolean;
  thickness?: Thickness;
}

interface AnimationProps {
  duration?: number;
  interpolation?: Interpolation;
}

export default function CardAvatarContainer(props: Props) {
  const { avatar, completeness, adminUser } = props;

  const animationDuration = props.animationProps?.duration || 750;
  const circularThickness = props.thickness || Thickness.Thin;
  const barColour = props.color || Colour.Blue;
  const animated = completeness && props.animated !== false;
  const animationInterpolation = props.animationProps?.interpolation || Interpolation.Balanced;

  const animationStep =
    completeness && Math.round(animationDuration / (completeness / animationInterpolation));
  const [currentCompleteness, setCurrentCompleteness] = useState(animated ? 0 : completeness);
  const incompleteStarFields = props.incompleteFields?.filter(item => item.star);
  const incompleteNonStarFields = props.incompleteFields?.filter(item => !item.star);
  const showNonStarFieldsTitle =
    incompleteNonStarFields?.length > 0 && incompleteStarFields?.length > 0;

  const completenessTooltipText = [
    <div key={'completenessTooltip'}>
      <div className={styles.completenessTooltipIntro}>
        To have your profile 100% complete it is necessary to have all fields filled out. These are
        the fields that are missing for you:
      </div>
      {incompleteStarFields?.length > 0 && (
        <div className={styles.completenessTooltipStar}>
          Essencial fields to make your profile more effective:
        </div>
      )}
      {incompleteStarFields?.map(item => {
        return (
          <div key={item.name} className={styles.completenessTooltipStar}>
            - {item.name} <Icon name='starCircle' color='black' />
          </div>
        );
      })}
      {showNonStarFieldsTitle && (
        <div className={showNonStarFieldsTitle && styles.completenessTooltipNonStar}>
          Also missing from your profile:
        </div>
      )}
      {incompleteNonStarFields?.map(item => {
        return <div key={item.name}>- {item.name}</div>;
      })}
    </div>,
  ];

  useEffect(() => {
    if (animated) {
      if (currentCompleteness < completeness) {
        sleep(animationStep)
          .then(() => {
            setCurrentCompleteness(currentCompleteness + animationInterpolation);
          })
          .catch(() => {
            setCurrentCompleteness(completeness);
          });
      } else if (currentCompleteness > completeness) {
        setCurrentCompleteness(completeness);
      }
    }
  });

  const circularBarStyles = {
    root: {},
    path: {
      stroke: barColour,
      transition: 'stroke-dashoffset 0.5s ease 0s',
      transform: 'rotate(0turn)',
      transformOrigin: 'center center',
    },
    trail: {
      stroke: '#d6d6d6',
      transform: 'rotate(0turn)',
      transformOrigin: 'center center',
    },
  };

  const imageSection = () => (
    <img
      className={adminUser ? styles.adminPicture : styles.picture}
      src={avatar || astroboy}
      alt='picture'
    />
  );

  const avatarWithoutCompleteness = () => {
    const adminType = adminUser?.type === 'talent_advocate' ? 'Talent Advocate' : 'Account Manager';

    return (
      <div>
        <div
          className={adminUser ? styles.adminImageContainer : styles.imageContainer}
          style={props.mobile ? { margin: 0 } : {}}
        >
          {imageSection()}
        </div>
        {adminUser && (
          <div>
            <span className={styles.adminNameLabel}>{adminUser.name}</span>
            <span className={styles.adminTypeLabel}>{adminType}</span>
          </div>
        )}
      </div>
    );
  };

  const avatarWithCompleteness = () => (
    <div className={styles.container}>
      <div className={styles.progressBarContainer}>
        <CircularProgressbarWithChildren
          value={currentCompleteness}
          styles={circularBarStyles}
          strokeWidth={circularThickness}
        >
          {imageSection()}
        </CircularProgressbarWithChildren>
      </div>
      {props.completeness === 100 || props.incompleteFields?.length === 0 ? (
        profileCompletenessString()
      ) : (
        <Tooltip
          color='tuftsBlue'
          textBelow
          htmlContent={completenessTooltipText}
          style={
            props.mobile
              ? {
                  textAlign: 'left',
                  marginLeft: '-3.5rem',
                  paddingLeft: '0.625rem',
                }
              : { textAlign: 'left', paddingLeft: '0.625rem' }
          }
        >
          {profileCompletenessString()}
        </Tooltip>
      )}
    </div>
  );

  const profileCompletenessString = () => (
    <div className={styles.completeness}>
      <span className={styles.percentage}>{`${completeness}% `}</span>
      Profile Completeness
    </div>
  );

  const avatarWithCompletenessNoText = () => (
    <div className={styles.container}>
      <div className={styles.progressBarContainer}>
        <CircularProgressbarWithChildren
          value={currentCompleteness}
          styles={circularBarStyles}
          strokeWidth={circularThickness}
        >
          {imageSection()}
        </CircularProgressbarWithChildren>
      </div>
    </div>
  );

  return props.handshakeProfile || !completeness || props.publicProfilePage
    ? avatarWithoutCompleteness()
    : props.hideText
    ? avatarWithCompletenessNoText()
    : avatarWithCompleteness();
}

function sleep(time: number) {
  return new Promise(resolve => setTimeout(resolve, time));
}
