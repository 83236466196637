import React from 'react';
import { FollowSection } from 'components/company/Sidebar';
import { JobAd } from '__models__/job_ad';
import { Company } from '__models__/gql/all';
import ApplyButton from './ApplyButton';
import { Application } from '__models__/models';
import AverageHiringProcessItem from 'components/averageHiringProcess/AverageHiringProcessItem';
import ShareSection from 'components/general/share_section/ShareSection';
import classNames from 'classnames';
import OfferLocation from 'components/offer_location/offer_location';
import { isMobile } from 'react-device-detect';
import ApplicationStartedToast from 'components/application_started_toast/application_started_toast';
import { getJobApplication } from 'lib/application';
import SubscribeButton from 'components/subscribeButton/SubscribeButton';

const styles = require('./Sidebar.module.scss');

interface Props {
  applications: Application[];
  averageHiringProcess: { companyAvg: string; marketAvg: string };
  bookmark: any;
  cancelReasons: any;
  company: Company;
  companySubscriptions: any;
  currentUser: any;
  fixedHeader: boolean;
  handshake: any;
  jobAd: JobAd;
  jobSuggestion: any;
  jobs: any;
  referralId: number;
  jobSearchFiltersAb?: string;
}

export default function Sidebar(props: Props) {
  const jobAdAttrs = props.jobAd.attributes;
  const application = getJobApplication(props.applications, props.jobAd);

  function referButton() {
    if (
      jobAdAttrs.referral_value &&
      jobAdAttrs.referral_value > 0 &&
      props.currentUser?.type === 'TalentUser' &&
      jobAdAttrs.state === 10
    ) {
      return (
        <div
          className={classNames(styles.referral, {
            [styles.referralWithFixedHeaderHandshake]: props.fixedHeader && props.handshake,
            [styles.referralWithFixedHeaderSuggestion]: props.fixedHeader && props.jobSuggestion,
          })}
        >
          Or{' '}
          {!props.currentUser?.type ? (
            <span>
              <a className={styles.referButton} href={window.Routes.join()}>
                {' '}
                sign up{' '}
              </a>{' '}
              to refer a friend
            </span>
          ) : (
            <a
              className={styles.referButton}
              href={window.Routes.new_job_ad_referral(props.jobAd.id)}
            >
              refer a friend
            </a>
          )}{' '}
          and earn a reward of {jobAdAttrs.referral_string}
        </div>
      );
    }
  }

  function relatedJobOpenings() {
    return (
      <div className={styles.relatedJobs}>
        <div className={styles.relatedJobsHeader}>Related job openings</div>
        {jobAdAttrs.top_related_offers.map(job => (
          <div key={job.id}>
            <a className={styles.relatedJobsTitle} href={window.Routes.job_ad(job.id)}>
              {job.title}
            </a>
            <div className={styles.relatedJobsCompany}>{job.company_name}</div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <>
      {((props.fixedHeader && (props.handshake || props.jobSuggestion)) || !props.fixedHeader) &&
        props.jobAd.attributes.state_name !== 'expired' && (
          <div className={styles.ctasContainer}>
            <div className={styles.applicationStartedToast}>
              {!isMobile &&
                application?.inDraftStates &&
                !props.jobSuggestion &&
                !props.handshake && <ApplicationStartedToast />}
            </div>
            <div className={styles.applyButtonWrapper}>
              <ApplyButton
                applications={props.applications}
                cancelReasons={props.cancelReasons}
                currentUser={props.currentUser}
                fixedHeader={props.fixedHeader}
                handshake={props.handshake}
                jobAd={props.jobAd}
                jobSuggestion={props.jobSuggestion}
                referralId={props.referralId}
                style={styles.applyButton}
                jobSearchFiltersAb={props.jobSearchFiltersAb}
                shouldHide={isMobile}
              />
              {!props.handshake && !props.jobSuggestion && (
                <SubscribeButton
                  currentUser={props.currentUser}
                  job={{
                    id: props.jobAd.id,
                    title: props.jobAd.attributes.title,
                    stateName: props.jobAd.attributes.state_name,
                  }}
                  bookmark={props.bookmark}
                  style={styles.subscribeButton}
                  size='large'
                />
              )}
            </div>
          </div>
        )}
      <div>{referButton()}</div>
      <div className={styles.shareSection}>
        <ShareSection jobAd={props.jobAd} />
      </div>
      <AverageHiringProcessItem
        companyName={props.company.name}
        companyAvg={props.averageHiringProcess.companyAvg}
        marketAvg={props.averageHiringProcess.marketAvg}
      />
      <hr />
      {jobAdAttrs.top_related_offers.length > 0 && relatedJobOpenings()}
      <MoreInfoAboutCompany company={props.company} />
      {props.jobs?.nodes?.length > 0 && (
        <JobsSection
          jobs={props.jobs.nodes}
          companyName={props.company.name}
          jobAdId={props.jobAd.id}
        />
      )}
      <div className={styles.followSection}>
        <FollowSection
          company={props.company}
          subscribed={props.companySubscriptions?.length > 0}
          user={props.currentUser}
          otherClasses={styles.subscribeCompanyButton}
        />
      </div>
    </>
  );
}

export function JobsSection({ jobs, companyName, jobAdId }) {
  const removeCurrentJob = otherJobs => {
    return otherJobs.filter(job => parseInt(job.id, 10) !== parseInt(jobAdId, 10));
  };

  jobs = removeCurrentJob(jobs);
  return (
    jobs.length > 0 && (
      <div className={styles.otherJobs}>
        <div className={styles.otherHeader}>Other jobs from {companyName}</div>
        {jobs.slice(0, 3).map(job => (
          <div className={styles.jobBox} key={job.id}>
            <div className={styles.jobSalary}>{job.salary}</div>
            <a className={styles.jobTitle} href={window.Routes.job_ad(job.id)}>
              {job.title}
            </a>
            <div className={classNames(styles.jobLocation, 'sidebar-job-location')}>
              <OfferLocation
                location={job.location}
                locationIcon={job.locationIcon}
                officeLocations={job.officeLocations}
                isGlobalRemote={job.isGlobalRemote}
                remoteWorkingLabel={job.remoteWorkingLabel}
                iconColor={'black'}
                mini={true}
              />
            </div>
          </div>
        ))}
        {jobs.length > 3 && (
          <a href={window.Routes.jobs({ q: companyName })} className={styles.seeAllOffers}>
            See all jobs ({jobs.length})
          </a>
        )}
      </div>
    )
  );
}

export function MoreInfoAboutCompany({ company }) {
  return (
    <div className={styles.moreInfoAboutCompanyWrapper}>
      <div className={styles.moreInfoAboutCompanyHeader}>More info about the company</div>
      <div className={styles.moreInfoAboutCompanyInner}>
        <div>
          <img
            src={company.logoUrl}
            className={styles.logo}
            alt={`${company.name} logo`}
            title={company.name}
          />
        </div>
        <div className={styles.moreInfoAboutCompanyText}>
          <a className={styles.moreInfoAboutCompanyName} href={window.Routes.company(company.id)}>
            {company.name}
          </a>
          <div>{company.shortPitch}</div>
        </div>
      </div>
    </div>
  );
}
