import { createContext } from 'react';
import { Bookmark } from '__models__/gql/bookmark';
import { Job } from '__models__/gql/job';
import { JobDismissal } from '__models__/gql/job_dismissal';
import { User } from '__models__/gql/user';

interface Hash<T> {
  [key: string]: T;
}
export interface JobCardContextInterface {
  applied?: boolean;
  bookmark?: Partial<Bookmark>;
  handshakes?: Hash<number>;
  className?: string;
  job: Partial<Job>;
  jobDismissal?: Partial<JobDismissal>;
  mini?: boolean;
  key: string;
  user?: Partial<User>;
  isHottest?: boolean;
  infoTags?: string[];
}

export const JobCardCtx = createContext<JobCardContextInterface>({} as JobCardContextInterface);
