import * as React from 'react';
import JobsPage from './jobs_page';
import { JobAd } from '../../__models__/models';
import TopFilters from '../jobs_page/top_filters';
import JobCard from '../jobs_page/job_card';
import EmptyState from '../general/empty_state/empty_state';
import { shallow } from 'enzyme';

let wrapped;
const Jobs: {
  data: JobAd[];
} = require('../jobs_page/fixtures/ongoing_jobs_dummy.json');

beforeAll(() => {
  wrapped = shallow(
    <JobsPage
      archived_job_ads={Jobs}
      drafts_job_ads={{ data: [] }}
      followed_job_ids={[]}
      ongoing_job_ads={Jobs}
      privacy_path={''}
      tos_path={''}
      max_jobs_reached={false}
      available_jobs={'1/3'}
    />
  );
});

it('shows top filters', () => {
  expect(wrapped.find(TopFilters).length).toEqual(1);
});

it('shows a job card', () => {
  expect(wrapped.find(JobCard).length).toEqual(2);
});

it('has no jobs', () => {
  wrapped.setProps({
    ongoing_job_ads: { data: [] },
  });

  expect(wrapped.find(JobCard).length).toEqual(0);
  expect(wrapped.find(EmptyState).length).toEqual(2);
});
