import React from 'react';
import Icon, { Size } from 'components/general/icon/icon';
import Tooltip from 'components/general/tooltip/tooltip';
import { scrollTo } from 'lib/scrollTo';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import OfferLocation from 'components/offer_location/offer_location';
import ApplyButton from './ApplyButton';
import { Application, JobAd } from '__models__/models';
import ApplicationStartedToast from 'components/application_started_toast/application_started_toast';
import { getJobApplication } from 'lib/application';
import SubscribeButton from 'components/subscribeButton/SubscribeButton';

const styles = require('./Header.module.scss');

interface Props {
  category: string;
  citizenship: boolean;
  location: string;
  officeLocations: any;
  experienceLabel: string;
  experienceMin: number;
  experienceMax: number;
  fixedHeader: boolean;
  handshake: any;
  hiringBonus: any;
  isContractor: boolean;
  jobSuggestion: any;
  jobType: string;
  niceToHaveLanguageLabel: string;
  preferredLanguageLabel: string;
  projectDuration: number;
  relocationPaid: boolean;
  remoteDetails: string;
  remoteWorkingLabel: string;
  rate: string;
  salary: string;
  visaSupport: boolean;
  locationIcon: string;
  isGlobalRemote: boolean;
  timezoneLabel?: string;
  cancelReasons: any;
  jobSearchFiltersAb?: string;
  applications: Application[];
  currentUser: any;
  jobAd: JobAd;
  referralId: number;
  bookmark: any;
  containerRef?: React.RefObject<HTMLDivElement>;
  companyName?: string;
  companySlug?: string;
}

export default function Header(props: Props) {
  const {
    experienceMin,
    experienceMax,
    fixedHeader,
    handshake,
    jobSuggestion,
    rate,
    salary,
    projectDuration,
    niceToHaveLanguageLabel,
    hiringBonus,
    officeLocations,
    locationIcon,
    isGlobalRemote,
    remoteDetails,
    remoteWorkingLabel,
    location,
    timezoneLabel,
    applications,
  } = props;

  const application = getJobApplication(applications, props.jobAd);

  function formatExp(experience) {
    return experience === 10 ? '10+' : experience;
  }

  function experienceRange() {
    if (experienceMin && experienceMax) {
      return `${experienceMin} - ${formatExp(experienceMax)} years of experience -`;
    } else if (experienceMin) {
      return `At least ${formatExp(experienceMin)} ${
        experienceMin === 1 ? 'year of experience -' : 'years of experience -'
      }`;
    } else if (experienceMax) {
      return `At most ${formatExp(experienceMax)} ${
        experienceMax === 1 ? 'year of experience -' : 'years of experience -'
      }`;
    } else {
      return ``;
    }
  }

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.fixedTitle]: fixedHeader && !handshake && !jobSuggestion,
        [styles.fixedTitleHandshake]: fixedHeader && (handshake || jobSuggestion),
      })}
    >
      {((fixedHeader && (props.handshake || props.jobSuggestion)) || !fixedHeader) && (
        <div className={styles.ctasContainer}>
          <div className={styles.toastContainer}>
            {isMobile && application?.inDraftStates && !props.handshake && !props.jobSuggestion && (
              <ApplicationStartedToast />
            )}
          </div>
          <div className={styles.applyButtonWrapper}>
            <ApplyButton
              applications={applications}
              cancelReasons={props.cancelReasons}
              currentUser={props.currentUser}
              fixedHeader={fixedHeader}
              handshake={props.handshake}
              jobAd={props.jobAd}
              jobSuggestion={props.jobSuggestion}
              referralId={props.referralId}
              style={styles.applyButton}
              jobSearchFiltersAb={props.jobSearchFiltersAb}
              shouldHide={!isMobile}
              buttonSize={application?.inDraftStates ? 'mid' : 'large'}
              containerRef={props.containerRef}
              companyName={props.companyName}
              companySlug={props.companySlug}
            />
            {!props.handshake && !props.jobSuggestion && (
              <SubscribeButton
                currentUser={props.currentUser}
                job={{
                  id: props.jobAd.id,
                  title: props.jobAd.attributes.title,
                  stateName: props.jobAd.attributes.state_name,
                }}
                bookmark={props.bookmark}
                style={styles.subscribeButton}
                size='large'
              />
            )}
          </div>
        </div>
      )}
      {hiringBonus && (
        <div className={styles.row}>
          <div className={styles.hiringBonus}>
            <Icon name='dollar' size={isMobile ? Size.Big : Size.Hero} />
            <span className={styles.hiringBonusText}>
              Land this job and get a hiring bonus of {hiringBonus} €. Know more{' '}
              <a
                href='https://landing.jobs/blog/get-paid-to-get-hired?utm_source=platform&utm_content=job-detail&utm_campaign=get-paid-get-hired'
                target='_blank'
                rel='noreferrer'
              >
                here
              </a>
              .
            </span>
          </div>
        </div>
      )}
      <div className={styles.row}>
        <div className={classNames(styles.location, 'job-header-location')}>
          {props.location && (
            <OfferLocation
              location={location}
              locationIcon={locationIcon}
              officeLocations={officeLocations}
              isGlobalRemote={isGlobalRemote}
              remoteDetails={remoteDetails}
              remoteWorkingLabel={remoteWorkingLabel}
              timezoneLabel={timezoneLabel}
              iconColor={'tuftsBlue'}
            />
          )}
        </div>
        <div className={styles.jobType}>
          {props.jobType}
          {props.isContractor && (
            <span>
              <Tooltip
                text='Click here to know more about contracting.'
                otherClasses={'mediumLargeIcon'}
              >
                <Icon
                  name='helpCircle'
                  color='tuftsBlue'
                  className={styles.helpCircle}
                  size={Size.MediumLarge}
                  clickHandler={() => scrollTo('#contractor')}
                />
              </Tooltip>{' '}
              {projectDuration && '- ' + projectDuration + ' months'}
            </span>
          )}
        </div>
        {(salary || rate) && (
          <div className={styles.salary}>
            {salary && `Gross annual salary: ${salary}`}
            {salary && rate && <br />}
            {rate && `Rate per day: ${rate}`}
          </div>
        )}
      </div>
      <div className={styles.row}>
        {props.category && (
          <div className={styles.item}>
            <Icon name='briefcase' className={styles.icon} />
            {props.category}
          </div>
        )}
        {props.experienceLabel && (
          <div className={styles.item}>
            <Icon name='signal' className={styles.icon} />
            {`${experienceRange()} ${props.experienceLabel}`}
          </div>
        )}
        <div className={styles.item}>
          {props.citizenship ? (
            <Tooltip text='Requires you to be a citizen or have a valid work permit / visa sponsorship to work in the country in which this position is based.'>
              <Icon name='card' className={styles.icon} /> Requires work permit
            </Tooltip>
          ) : props.visaSupport ? (
            <Tooltip text='This company is capable and willing to help you with visa authorisation bureaucracy.'>
              <Icon name='card' className={styles.icon} /> Visa support
            </Tooltip>
          ) : null}
        </div>
        <div className={styles.relocationPaid}>
          {props.relocationPaid && (
            <>
              <Icon name='earth' className={styles.icon} /> Relocation paid
            </>
          )}
        </div>
      </div>
      {(props.preferredLanguageLabel || niceToHaveLanguageLabel) && (
        <div className={styles.row}>
          <span>
            <Icon name='chat' className={styles.icon} /> Language(s):
            {props.preferredLanguageLabel && (
              <span className={styles.language}> Required {props.preferredLanguageLabel}</span>
            )}
          </span>
          {niceToHaveLanguageLabel && (
            <span>
              | Nice to have <span className={styles.language}>{niceToHaveLanguageLabel}</span>
            </span>
          )}
        </div>
      )}
      <hr className={styles.line} />
    </div>
  );
}
