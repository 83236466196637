import React from 'react';
import FormInput from 'components/general/form_input/form_input';
import Select from 'components/form/select/select';
import { formatMoneyRange } from 'lib/range';

export interface Props {
  formInputLabel: string;
  formInputName: string;
  formInputSubLabel?: string;
  handleFormChange: (event: any, input: string) => void;
  placeholder: string;
  profileAttribute: string;
  range: Range;
  rangesList: Range[];
}

export interface Range {
  minimum?: number;
  maximum?: number;
}

export default function GenericSelectRange(props: Props) {
  const { rangeOptions, selectedRangeOption } = getRangeOptions(props.range, props.rangesList);

  return (
    <FormInput
      name={props.formInputName}
      label={props.formInputLabel}
      sublabel={props.formInputSubLabel}
    >
      <Select
        name={props.formInputName}
        placeholder={props.placeholder}
        options={rangeOptions}
        color='silverSand'
        value={selectedRangeOption}
        size={'medium'}
        onChange={event => props.handleFormChange(event, props.profileAttribute)}
      />
    </FormInput>
  );
}

function getRangeOptions(selectedRange: Range, rangesList: Range[]) {
  // Converting the list to select options
  let rangeOptions = [createOption('-', null, null)];
  rangeOptions = rangeOptions.concat(rangesList.map(range => createOptionFromRange(range)));

  // Finding the selected option
  let selectedRangeOption = null;
  if (selectedRange.minimum != null || selectedRange.maximum != null) {
    selectedRangeOption = rangeOptions.find(
      r =>
        (r.minimum === selectedRange.minimum ||
          (r.minimum == null && selectedRange.minimum == null)) &&
        (r.maximum === selectedRange.maximum ||
          (r.maximum == null && selectedRange.maximum == null))
    );

    // If that range is no longer a valid option, we create it anyway but mark it
    // as deprecated
    if (!selectedRangeOption) {
      selectedRangeOption = createOptionFromRange(selectedRange);
      selectedRangeOption.label += ' (this range is deprecated)';
      rangeOptions.push(selectedRangeOption);
    }
  } else {
    selectedRangeOption = {};
  }

  return { rangeOptions, selectedRangeOption };
}

function createOptionFromRange(range: Range) {
  const label = formatMoneyRange(range.minimum, range.maximum);

  return createOption(label, range.minimum, range.maximum);
}

function createOption(label: string, minimum?: number, maximum?: number) {
  return { label, minimum, maximum };
}
