import React, { useEffect, useState } from 'react';
import Select from 'components/form/select/select';
import TextInput from '../../form/text/text';
import { contactPreference as contactPreferenceOptionsConverter } from 'components/registration/registration_utils/registration_utils';

const Settings = require('settings.json');

interface BaseFormSetting {
  name?: string;
}

type HandleChangeFunction = (event) => void;

interface Props {
  contactPreference: { id: number; other?: string };
  formSettings: { select?: BaseFormSetting; text?: BaseFormSetting };
  handleChangeContactPreference: HandleChangeFunction;
}

export default function ContactPreference(props: Props) {
  const [contactPreference, setContactPreference] = useState(props.contactPreference);
  useEffect(() => {
    props.handleChangeContactPreference(contactPreference);
  }, [contactPreference]);

  const handleChange = (event, context: 'select' | 'text') => {
    setContactPreference(prevContactPreference => ({
      ...prevContactPreference,
      ...(context === 'select' && { id: event.value, other: null }),
      ...(context === 'text' && { other: event.target.value }),
    }));
  };

  const options = contactPreferenceOptionsConverter(Settings.person.contactPreference.options);
  return (
    <>
      <Select
        name={props.formSettings.select?.name}
        placeholder='Select a contact preference...'
        options={options}
        value={options.filter(option => option.value === contactPreference.id)}
        color='silverSand'
        selected={contactPreference.id}
        onChange={event => handleChange(event, 'select')}
      />
      {contactPreference.id === Settings.person.contactPreference.otherOption.id && (
        <TextInput
          name={props.formSettings.text?.name}
          placeholder='e.g. Letter'
          color='silverSand'
          value={contactPreference.other}
          onChange={event => handleChange(event, 'text')}
        />
      )}
    </>
  );
}
