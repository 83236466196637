import classNames from 'classnames';
import * as React from 'react';
const chevronPath = require('../../../../app/assets/images/components/dropdown-arrow-black.svg');
const styles = require('./accordion.module.scss');
import Icon, { Size, Style } from 'components/general/icon/icon';
import InlineIcon from '../inline_icon/inline_icon';
const plus = require('iconic/plus.svg');
const minus = require('iconic/minus.svg');

interface Props {
  children: any;
  className?: string;
  closeIcon?: JSX.Element;
  defaultOpened?: boolean;
  filterList?: boolean;
  headerClassName?: string;
  headerStyle?: any;
  innerClassName?: string;
  onClick?: () => any;
  opened?: boolean;
  openIcon?: JSX.Element;
  style?: any;
  title: any;
}

interface State {
  opened: boolean;
}

export default class Accordion extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    const defaultOpened = this.props.defaultOpened;
    const opened = typeof defaultOpened === 'boolean' ? defaultOpened : this.props.opened;
    this.state = { opened };
  }

  static getDerivedStateFromProps({ opened }, prevState) {
    if (typeof opened === 'boolean' && opened !== prevState.opened) {
      return { opened };
    } else {
      return null;
    }
  }

  setOpened(opened) {
    this.setState({ opened });
  }

  toggle = event => {
    this.setState({ opened: !this.state.opened });
  };

  renderFilterIcons() {
    return <InlineIcon className={styles.chevronIcon} path={this.state.opened ? minus : plus} />;
  }

  standardIcon = () => (
    <div className={styles.accordionIcon}>
      <Icon
        name={this.state.opened ? 'chevronUp' : 'chevronDown'}
        color='black'
        size={Size.MediumBig}
        style={Style.Bold}
      />
    </div>
  );

  renderIcons = () => {
    if (this.state.opened) {
      return this.props.closeIcon || this.standardIcon();
    } else {
      return this.props.openIcon || this.standardIcon();
    }
  };

  render() {
    const isExpanded = styles['is-expanded'];
    const filter = styles['filter-list'];
    const headerClass = classNames(styles.title, this.props.headerClassName, {
      [isExpanded]: this.state.opened,
    });
    const innerClass = classNames(styles.content, this.props.innerClassName, {
      [isExpanded]: this.state.opened,
      [filter]: !this.props.filterList,
    });

    return (
      <div
        className={classNames(styles.accordion, this.props.className)}
        style={this.props.style}
        onClick={this.props.onClick}
      >
        <div className={headerClass} onClick={this.toggle} style={this.props.headerStyle}>
          {this.props.title}
          {this.renderIcons()}
        </div>
        <div className={innerClass}>{this.props.children}</div>
      </div>
    );
  }
}
