import 'rc-slider/assets/index.css';

import React from 'react';
import Slider from 'rc-slider';
import TextInput from 'components/form/text/text';
import './customized_range.scss';

const Range = Slider.Range;

interface Props {
  onLowerBoundChange: any;
  onUpperBoundChange: any;
  onSliderChange: any;
  value: number[];
}

class CustomizedRange extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Range
          min={100}
          max={1000}
          step={25}
          allowCross={false}
          value={this.props.value}
          onChange={this.props.onSliderChange}
          className='slider_color'
          trackStyle={[{ backgroundColor: 'black' }]}
          handleStyle={[
            {
              backgroundColor: 'black',
              borderColor: 'black',
              boxShadowColor: 'black',
            },
            {
              backgroundColor: 'black',
              borderColor: 'black',
              boxShadowColor: 'black',
            },
          ]}
        />

        <div className='boundsMacro'>
          <div className='boundsMicro'>
            <TextInput
              name='lowerbound'
              type='number'
              placeholder='200€'
              color='tuftsBlue'
              controlled={true}
              onChange={this.props.onLowerBoundChange}
              value={this.props.value[0] ? this.props.value[0].toString() : ''}
            />
          </div>
          <div className='boundsMicro'>
            <TextInput
              name='upperbound'
              type='number'
              placeholder='400€'
              color='tuftsBlue'
              controlled={true}
              onChange={this.props.onUpperBoundChange}
              value={this.props.value[1] ? this.props.value[1].toString() : ''}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default CustomizedRange;
