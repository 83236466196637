import React from 'react';
import Step from './step';

import './howItWorks.scss';

interface Props {
  title: string;
  step1: string[];
  step2: string[];
  step3: string[];
  step4: string[];
  step5: string[];
  step6: string[];
}

interface State {
  stepClosed: {
    step1: boolean;
    step2: boolean;
    step3: boolean;
    step4: boolean;
    step5: boolean;
    step6: boolean;
  };
}

/**
 * Component which renders the component 'Step' for all the
 * 6 steps illustrating how landing.work works
 */

class HowItWorks extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      stepClosed: {
        step1: false,
        step2: false,
        step3: false,
        step4: false,
        step5: false,
        step6: false,
      },
    };

    this.handleOpenSteps = this.handleOpenSteps.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.resize = this.resize.bind(this);
  }

  /**
   * Closes all of the steps and opens a specific one if provided
   */
  handleOpenSteps(steps, openStep = null) {
    Object.keys(steps).forEach(v => (steps[v] = false));

    if (openStep) {
      steps[openStep] = true;
    }

    this.setState({ ...steps });
  }

  /**
   * Function which is activated on mobile view
   * it looks for when a step is in the middle of the screen and changes
   * its state to true opening the text box
   */
  handleScroll() {
    const steps = this.state.stepClosed;

    if (window.screen.width >= 767) {
      this.handleOpenSteps(steps);
    } else {
      const viewPortMid = document.documentElement.clientHeight / 2;

      const stepList = {
        step1: document.getElementById('step1').getBoundingClientRect(),
        step2: document.getElementById('step2').getBoundingClientRect(),
        step3: document.getElementById('step3').getBoundingClientRect(),
        step4: document.getElementById('step4').getBoundingClientRect(),
        step5: document.getElementById('step5').getBoundingClientRect(),
        step6: document.getElementById('step6').getBoundingClientRect(),
      };

      Object.values(stepList).map((step, index) => {
        const nextStep = Object.values(stepList)[index + 1];

        if (step.top <= viewPortMid && (!nextStep || nextStep.top > viewPortMid)) {
          this.handleOpenSteps(steps, Object.keys(stepList)[index]);
        }
      });
    }
  }

  /**
   * Function which on component mount checks the window inner width live
   */
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.resize);
  }

  componentDidUpdate() {
    this.drawStepper();
  }

  resize() {
    this.drawStepper();
  }

  drawStepper() {
    const line = document.getElementById('how_it_works_line');

    let x1, x2, y1, y2;
    const first = document.getElementById('step1');
    const penultimate = document.getElementById('step5');
    const last = document.getElementById('step6');

    if (window.innerWidth < 767) {
      y1 = first.offsetTop + first.offsetHeight / 2;
      x1 = first.offsetLeft + first.offsetWidth / 2 + (parseInt(first.style.margin, 10) || 0) + 1;
      y2 = penultimate.offsetTop + penultimate.offsetHeight / 2;
      x2 =
        penultimate.offsetLeft +
        penultimate.offsetWidth / 2 +
        (parseInt(penultimate.style.margin, 10) || 0) +
        1;
    } else {
      x1 = first.offsetLeft + first.offsetWidth / 2;
      y1 = first.offsetHeight / 2 + (parseInt(first.style.margin, 10) || 0) + 2;
      x2 = last.offsetLeft + last.offsetWidth / 2;
      y2 = last.offsetHeight / 2 + (parseInt(last.style.margin, 10) || 0) + 2;
    }

    line.setAttribute('x1', x1);
    line.setAttribute('y1', y1);
    line.setAttribute('x2', x2);
    line.setAttribute('y2', y2);
  }

  render() {
    const stepsNames = ['step1', 'step2', 'step3', 'step4', 'step5', 'step6'];

    const steps = stepsNames.map(name => {
      return {
        name,
        text: this.props[name],
        closed: this.state.stepClosed[name],
      };
    });

    return (
      <>
        <div className='how-it-works-title'>{this.props.title}</div>
        <div className='section-how-it-works'>
          <div className='lw-how-it-works'>
            <svg>
              <line id='how_it_works_line' />
            </svg>
            {steps.map((step, index) => {
              return (
                <Step
                  key={index}
                  number={index + 1}
                  step={step.text}
                  idNum={step.name}
                  stepClosed={step.closed}
                  stepName={step.name}
                  scroll={this.handleScroll}
                />
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

export default HowItWorks;
