import * as React from 'react';
import uniqid from 'uniqid';
import Modal from '../modal/modal';
const styles = require('./nps_modal.module.css');

interface Props {
  rating?: string;
  url: string;
}

export default class NPSModal extends React.Component<Props> {
  form: any;
  modal: Modal;

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleSend = this.handleSend.bind(this);
    this.renderNumber = this.renderNumber.bind(this);
    this.setForm = this.setForm.bind(this);
    this.setModal = this.setModal.bind(this);
  }

  handleClose(event) {
    event.preventDefault();
    this.modal.close();
  }

  handleSend(event) {
    event.preventDefault();
    $.post(this.props.url, $(this.form).serialize());
    this.modal.close();
  }

  setLengthValidator(element) {
    const klass = 'lj-inputHint lj-inputHint--small lj-inputHint--right';
    window.attachLengthValidator($(element), klass);
  }

  renderNumber(i) {
    const id = uniqid();
    return (
      <React.Fragment key={i}>
        <input
          className='lj-buttonGroup-input'
          id={id}
          name='nps_survey[rating]'
          defaultChecked={i.toString() === this.props.rating}
          type='radio'
          value={i}
        />
        <label className='lj-buttonGroup-button lj-button lj-button--xSmall' htmlFor={id}>
          {i}
        </label>
      </React.Fragment>
    );
  }

  render() {
    const textareaId = uniqid();
    return (
      <Modal title='Give feedback' defaultOpen={true} ref={this.setModal}>
        <form className={styles.form} method='post' action={this.props.url} ref={this.setForm}>
          <input type='hidden' name='nps_survey[context]' value='candidate.dropped_by_advocate' />
          <div className='lj-input-group'>
            <label className='lj-form-label'>
              What was the impact of having a Talent Agent throughout your experience at
              Landing.Jobs?
            </label>
            <div className='lj-input--block lj-buttonGroup lj-buttonGroup--tuftsBlue'>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(this.renderNumber)}
            </div>
          </div>

          <div className='lj-input-group u-marginBottom--mid text optional nps_survey_feedback u-marginTop--mid'>
            <label
              className='lj-form-label text optional control-label ld-form-label'
              htmlFor={textareaId}
            >
              Can you tell us why you chose that score?
            </label>
            <textarea
              className='lj-input lj-input--mid lj-input--block text optional lj-input--tuftsBlue lj-textarea'
              ref={this.setLengthValidator}
              rows={5}
              maxLength={2000}
              name='nps_survey[feedback]'
              id={textareaId}
            />
          </div>

          <div className='lj-input-group u-marginTop--mid'>
            <button
              className='lj-button lj-button--small lj-button--tuftsBlue'
              onClick={this.handleSend}
            >
              Send
            </button>
            <button
              className='lj-button lj-button--small lj-button--border lj-button--silverSand js-surveyPanel-close'
              onClick={this.handleClose}
            >
              Close
            </button>
          </div>
        </form>
      </Modal>
    );
  }

  setForm(element) {
    this.form = element;
  }

  setModal(element) {
    this.modal = element;
  }
}
