import React, { useState } from 'react';
import Modal, { ButtonsLayout } from 'components/general/modal/modal';
import Text from 'components/form/text/text';

import FormError from 'components/form/form_error/form_error';
import Button from 'lj_shared/button/button';
const styles = require('./MismatchToInProcessModalContainer.module.scss');
interface Props {
  employerFeedbackRef: any;
  errorMessage: string;
  handleChange: Function;
  handleSubmitFeedback: Function;
  modalRef: any;
  onClose: any;
}

export default function MismatchToInProcessModalContainer(props: Props) {
  const [buttonName, setButtonName] = useState('SKIP');

  function handleOkClick() {
    props.handleSubmitFeedback();
  }

  function handleButtonTextChange() {
    const text = document.getElementById('mismatch_to_in_progress') as HTMLInputElement;
    if (text && text.value.length > 0) {
      setButtonName('OK');
    } else {
      setButtonName('SKIP');
    }
  }

  return (
    <Modal
      defaultOpen={true}
      ref={props.modalRef}
      title={'Feedback about this application'}
      buttonsLayout={ButtonsLayout.OkOnly}
      onClose={props.onClose}
    >
      <div className={styles.container}>
        <div className={styles.subtitle}>Feedback for Landing.Jobs</div>
        <div className={styles.note}>
          Note: This info is visible to Landing.Jobs only and it will help us improve future
          screenings. Thank you!
        </div>
        <div className={styles.text}>
          We value your feedback and it is very important for us to make improvements on the
          platform, either on curation or candidate recommendations.
        </div>
      </div>
      <Text
        inputRef={props.employerFeedbackRef}
        textarea={true}
        label='What makes you consider this application and move it forward into process?'
        onChange={handleButtonTextChange}
        id='mismatch_to_in_progress'
      />
      {props.errorMessage && <FormError text={props.errorMessage} />}
      <Button buttonColor='ripePlum' otherClasses={styles.okButton} onClick={handleOkClick}>
        {buttonName}
      </Button>
    </Modal>
  );
}
