import * as React from 'react';
import ApplicationCard from './application_card/application_card';
import Button from 'lj_shared/button/button';
import InlineIcon from 'components/general/inline_icon/inline_icon';
import './ApplicationBasicProfile.scss';
import Icon, { Size } from 'components/general/icon/icon';
import Tooltip from 'components/general/tooltip/tooltip';

// models
import { Application } from '../../__models__/models';
import { Website } from '../../__models__/website';
import { Skill } from '../../__models__/skill';
import { Certificate } from '../../__models__/certificate';
import BlockCandidateButton from './BlockCandidateButton';

const profileIcon = require('iconic/person.svg');
const downloadIcon = require('svg/download.svg');
const eyeIcon = require('iconic/eye.svg');
const markerIcon = require('iconic/map-marker.svg');

export interface Props {
  cvUrl: string;
  application: Application;
  profileUrl: string;
  showBlock?: boolean;
  waitingForReview: boolean;
}

interface SkillProps {
  skills: Skill[];
  title: string;
  titleClass: string;
  skillClass: string;
}

const ApplicationBasicProfile = (props: Props) => {
  const person = props.application.attributes.person;
  const { name, recipient_avatar, experience_years, location, websites, languages, certificates } =
    person.attributes;
  const matchingSkills: Skill[] = props.application.attributes.matching_skills;
  const otherSkills: Skill[] = props.application.attributes.other_skills;
  const languageSkills: Skill[] = languages.slice(0, 3);

  return (
    <ApplicationCard
      title='Basic Profile Info'
      icon={profileIcon}
      alt='person'
      className='lj-application-basic-profile'
      buttonOne={
        props.waitingForReview ? null : (
          <Button buttonColor='ripePlum' buttonSize='xSmall' buttonUrl={props.profileUrl}>
            <InlineIcon alt='chat' path={eyeIcon} />
            {window.innerWidth < 576 ? 'Profile' : 'View Profile'}
          </Button>
        )
      }
    >
      <ProfileHeader
        name={name}
        recipient_avatar={recipient_avatar}
        websites={websites}
        application={props.application}
        showBlock={props.showBlock}
        waitingForReview={props.waitingForReview}
      />
      <LocationAndExperience location={location} experience_years={experience_years.label} />
      <Skills
        title='Matching Skills'
        skills={matchingSkills}
        titleClass='lj-skills-matching-title'
        skillClass='lj-skill-tag lj-skill-matching-tag'
      />
      <Skills
        title='Other Skills'
        skills={otherSkills}
        titleClass='lj-skills-other-title'
        skillClass='lj-skill-tag lj-skill-other-tag'
      />
      <Skills
        title='Languages'
        skills={languageSkills}
        titleClass='lj-skills-language-title'
        skillClass='lj-skill-tag lj-skill-language-tag'
      />
      <Certificates certificates={certificates} />
    </ApplicationCard>
  );
};

const ProfileHeader = props => {
  const { name, recipient_avatar, websites, application, showBlock, waitingForReview } = props;
  return (
    <div className='lj-block-flexHeader'>
      <div className='lj-profile-header'>
        <div dangerouslySetInnerHTML={{ __html: recipient_avatar }} />
        <div className='lj-heading--mid'>{name}</div>
        <div className='lj-link-group'>
          {websites &&
            websites.map((website: Website) => {
              return waitingForReview ? iconWithTooltip(website) : iconWithLink(website);
            })}
        </div>
      </div>
      {/* {showBlock && <BlockCandidateButton application={application} />} */}
      {/* We disabled the Block Candidate feature for now only commenting out the BlockCandidateButton.
      View the following Shortcut card for more info:
      https://app.shortcut.com/landingjobs/story/17702/block-candidates-feature-disable-or-kill-it */}
    </div>
  );
};

function iconWithTooltip(website) {
  return (
    <Tooltip
      key={website.id}
      text='Move the candidate into process to access this information'
      textBelow
    >
      <div key={website.id} className='lj-user-link'>
        <Icon
          name={website.category_key}
          color={'silverSand'}
          className={'website-icons'}
          size={Size.MediumLarge}
        />
      </div>
    </Tooltip>
  );
}

function iconWithLink(website) {
  return (
    <a
      key={website.id}
      className='lj-user-link'
      href={website.full_url}
      target='_blank'
      title={website.category_key}
      rel='noreferrer'
    >
      <Icon
        name={website.category_key}
        color={'ripePlum'}
        className={'website-icons'}
        size={Size.MediumLarge}
      />
    </a>
  );
}

const LocationAndExperience = props => {
  const { location, experience_years } = props;

  return (
    <div className='lj-card-row lj-card-row-location-experience'>
      <div className='lj-card-experience'>{experience_years}</div>
      <div className='lj-card-location'>
        <InlineIcon alt='marker' path={markerIcon} />
        {location}
      </div>
    </div>
  );
};

const Skills = ({ skills, title, titleClass, skillClass }: SkillProps) => {
  return (
    <div className='lj-user-skills'>
      <div className={titleClass}>{title}</div>
      {skills.length > 0 ? (
        <div className='lj-skill-tags'>
          {skills.map((skill: Skill) => {
            return (
              <div className={skillClass} key={skill.id}>
                <div className='lj-skill-tag-label'>{skill.name}</div>
                <div className='lj-skill-tag-experience'>
                  {skill.experienceLabel || 'No experience available'}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className='lj-application-empty-state'>{title} unkown.</div>
      )}
    </div>
  );
};

const Certificates = props => {
  const documentIcon = require('iconic/document');

  return (
    <div className='lj-user-certificates'>
      <div className='lj-heading--base lj-certificates-title'>Certificates</div>
      {props.certificates.length > 0 ? (
        <div className='lj-certificate-cards'>
          {props.certificates.map((certificate: Certificate) => {
            return (
              <div className='lj-certificate-card' key={certificate.id}>
                <div className='lj-certificate-icon'>
                  <InlineIcon alt='document' path={documentIcon} />
                </div>
                <div className='lj-certificate-content'>
                  <div className='lj-certificate-title'>{certificate.title}</div>
                  <div className='lj-certificate-info'>
                    {certificate.authority}, {certificate.year}
                  </div>
                  {certificate.url && !certificate.url.endsWith('/files/original/missing.png') && (
                    <Button
                      buttonColor='ripePlum'
                      buttonSize='xSmall'
                      buttonType='link'
                      buttonUrl={certificate.url}
                      isButton={false}
                      targetBlank={true}
                    >
                      <InlineIcon alt='eye' path={eyeIcon} />
                      Show Certificate
                    </Button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className='lj-application-empty-state'>No certificates available.</div>
      )}
    </div>
  );
};

export default ApplicationBasicProfile;
