import React from 'react';
import { mount } from 'enzyme';
import RemoveImage from './RemoveImage';

const createProps = (otherProps = {}) => ({
  buttonColor: 'puertoRico base',
  kind: 'USER_AVATAR',
  ...otherProps,
});

let props;
let wrapped;

describe('RemoveImage', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = mount(<RemoveImage {...props} />);
  });

  it('should render delete button', () => {
    expect(wrapped.find('a').text()).toBe('Delete image');
  });
});
