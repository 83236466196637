import * as React from 'react';
import Button from 'lj_shared/button/button';
import InlineIcon from 'components/general/inline_icon/inline_icon';
import dateFormat from '../../lib/format_date';

import { NoItem } from './evaluation_items';

interface TestResultProps {
  completed: boolean;
  dashboard: boolean;
  date?: string;
  empty?: boolean;
  external_id?: string;
  name: string;
  score?: any;
  sendTest?: any;
  step: string;
  test_id?: number;
  test_link?: string;
  url?: string;
}

interface TestsProps {
  testResults: any;
  dashboard: boolean;
  currentStep?: any;
  sendTest?: any;
}

const downloadIcon = require('iconic/data-transfer.svg');
const cogsIcon = require('iconic/cogs.svg');
const checkIcon = require('iconic/check.svg');
const sentIcon = require('iconic/share.svg');

export const TestsItem = (testResults, currentStep, sendTest, dashboard) => {
  if (testResults?.length === 0 && !currentStep?.test_name) {
    return NoItem('There are no Technical Tests for this application.');
  }
  return (
    <Tests
      testResults={testResults}
      currentStep={currentStep}
      sendTest={sendTest}
      dashboard={dashboard}
    />
  );
};

export const Tests = (props: TestsProps) => {
  return props.testResults.length > 0 ? (
    <div className='lj-test-cards'>
      {props.testResults.map(result => {
        return (
          <TestResult
            key={result.id}
            score={result.score}
            name={result.test_name}
            step={result.hiring_step}
            url={result.pdf_url}
            date={result.date}
            completed={result.completed}
            dashboard={props.dashboard}
            test_link={result.test_link}
            external_id={result.external_id}
            test_id={result.test_id}
          />
        );
      })}
    </div>
  ) : (
    <div className='lj-test-cards'>
      <TestResult
        name={props.currentStep.test_name}
        step={props.currentStep.label}
        sendTest={props.sendTest}
        dashboard={props.dashboard}
        completed={false}
        empty
      />
    </div>
  );
};

export const TestResult = (props: TestResultProps) => {
  return (
    <div className={props.dashboard ? 'lj-candidate-test-card' : 'lj-test-card'}>
      <div className='left'>
        <div className='lj-test-icon'>
          <InlineIcon alt='document' path={cogsIcon} size={'md'} />
        </div>
        <div className='lj-test-content'>
          <div className='test-name'>{props.name}</div>
          {props.step && <div className='hiring-step-name'>Hiring Step: {props.step}</div>}
          {props.url && (
            <a
              href={window.Routes.assessment_pdf({
                assessment_id: props.test_id,
                result_id: props.external_id,
              })}
              target='_blank'
              rel='noreferrer'
            >
              <InlineIcon alt='eye' path={downloadIcon} />
              Download Report
            </a>
          )}
        </div>
      </div>
      <div className='right'>
        {props.completed && (
          <>
            <div className='test-score'>{props.score}%</div>
            <div className='test-date'>
              {props.date &&
                dateFormat(props.date, {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                })}
            </div>
          </>
        )}
        {props.dashboard ? (
          <>
            {!props.completed && (
              <Button
                isButton={false}
                buttonColor={'tuftsBlue'}
                buttonSize={'xSmall'}
                buttonUrl={props.test_link}
                targetBlank={true}
              >
                <InlineIcon alt='check' path={checkIcon} />
                GO TO TEST
              </Button>
            )}
          </>
        ) : (
          <>
            {!props.empty && !props.completed && (
              <Button
                isButton={true}
                disabled={true}
                buttonColor={'ripePlum'}
                otherClasses={'test-sent'}
                buttonSize={'xSmall'}
              >
                <InlineIcon alt='sent' path={sentIcon} />
                TEST SENT
              </Button>
            )}
            {props.empty && (
              <Button
                isButton={true}
                buttonColor={'ripePlum'}
                otherClasses={'send-test'}
                onClick={props.sendTest}
                buttonSize={'xSmall'}
              >
                SEND TEST
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
};
