import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { apolloClient } from 'lib/graphql';

export function useFind<
  T extends new (...args: any[]) => InstanceType<T>,
  F extends keyof InstanceType<T>,
>(entity: T, id: FieldValue, ...fields: (F | [F, ...(string | string[])[]])[]) {
  return find([getWeaklyTypedFindCriteria('id', id, entity, fields)]);
}

export function useFindBy<
  T extends new (...args: any[]) => InstanceType<T>,
  F extends keyof InstanceType<T>,
>(
  entity: T,
  fieldName: FieldName, // TODO: We should definitely have a strongly typed argument here
  fieldValue: FieldValue,
  ...fields: (F | [F, ...string[]])[]
) {
  return find([getWeaklyTypedFindCriteria(fieldName, fieldValue, entity, fields)]);
}

export function useFindManyBy<
  T1 extends new (...args: any[]) => InstanceType<T1>,
  F1 extends keyof InstanceType<T1>,
  T2 extends new (...args: any[]) => InstanceType<T2>,
  F2 extends keyof InstanceType<T2>,
>(findEntity1: FindCriteria<T1, F1>, findEntity2: FindCriteria<T2, F2>) {
  const findCriterias = getWeaklyTypedFindCriterias(findEntity1, findEntity2);
  return find(findCriterias);
}

export interface FindCriteria<
  T extends new (...args: any[]) => InstanceType<T>,
  F extends keyof InstanceType<T>,
> {
  entity: T;
  fieldName: FieldName; // TODO: We should definitely have a strongly typed argument here
  fieldValue: FieldValue;
  fields: (F | [F, ...string[]])[];
}

// Warning: Helping non exported stuff ahead

interface WeaklyTypedFindCriteria {
  findKey: Field;
  entityName: string;
  fields: (FieldName | FieldName[])[];
}

function getWeaklyTypedFindCriterias(...findCriterias: any[]): WeaklyTypedFindCriteria[] {
  return findCriterias.map(f => {
    return getWeaklyTypedFindCriteria(f.fieldName, f.fieldValue, f.entity, f.fields);
  });
}

function getWeaklyTypedFindCriteria(
  fieldName: FieldName,
  fieldValue: FieldValue,
  entity: any,
  fields: (FieldName | FieldName[])[]
): WeaklyTypedFindCriteria {
  return {
    findKey: { name: fieldName, value: fieldValue },
    entityName: getEntityName(entity),
    fields,
  };
}

function getEntityName(entity: any): string {
  // Hack, we should make this part of the constraints
  return (
    // eslint-disable-next-line no-console
    entity.entityName || console.info("Entities should define 'static entityName: string' member")
  );
}

const IDENTIFIER_KEY_NAME = 'id';

interface ConvertableToString {
  toString(): string;
}
type FieldName = ConvertableToString;
type FieldValue = ConvertableToString;

interface Field {
  name: FieldName;
  value: FieldValue;
}

function extractFieldToQuery(field) {
  if (Array.isArray(field)) {
    const fieldName = field[0];
    const innerFields = field.slice(1).map(innerField => extractFieldToQuery(innerField));
    return `${fieldName}{${innerFields.join(' ')}}`;
  } else {
    return field.toString();
  }
}

function find(findCriterias: WeaklyTypedFindCriteria[]) {
  // 1º Setting the fields
  // TODO: Here, we assume that non basic fields are always arrays
  // This is not great, as it forces us to always specify at least one subfield

  // 2º Settin the rest of the query
  const queryString = `query {
      ${findCriterias.map(f => {
        let fieldsString = f.fields.map(field => extractFieldToQuery(field)).join(' ');

        let entityNameString = f.entityName;
        if (f.findKey.name !== IDENTIFIER_KEY_NAME) {
          entityNameString = `${entityNameString}s`;
          fieldsString = `nodes {${fieldsString}}`;
        }
        return `${entityNameString}(${f.findKey.name}: ${f.findKey.value}) {
          ${fieldsString}
        }`;
      })}
    }
  `;

  // 3º Querying
  // TODO: we should avoid returning the nodes part here
  const query = gql(queryString);
  // console.info(queryString);
  return useQuery(query, {
    client: apolloClient,
  });
}
