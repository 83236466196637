import * as React from 'react';
import { apolloClient, authenticationError } from 'lib/graphql';
import { ApolloProvider, Mutation, graphql } from 'react-apollo';
import Select from 'components/form/select/select';
import PubSub from 'pubsub-js';
import gql from 'graphql-tag';
import Sortable from 'react-sortablejs';
import Button from 'lj_shared/button/button';

export interface Props {
  skills: any; // an array of objects like {id: 1, tag: "Rails", experienceLevel: 1 }
  proposedTags: any; // an array of objects like {id: 10, tag: "Python on Rails"}
  maximumNumberOfSkills: number;
  jobId?: number;
  errors: any;
  disableSort: boolean;
  createProposedTagCallback: any;
  updateSkills: Function;
  readOnly: boolean;
  renderSignupVersion?: boolean;
  color?: string;
  isBackoffice?: boolean;
}

interface State {
  skills: any; // an array of objects like {id: 1, tag: "Rails", experienceLevel: 1 }
  proposedTags: any; // an array of objects like {id: 10, tag: "Python on Rails"}
  errors: any;
}

export default class SkillsInput extends React.Component<Props, State> {
  tagRef: React.RefObject<any>;
  styles: any;
  addButtonColor: string;
  mainColor: string;

  constructor(props) {
    super(props);
    this.state = { ...props };
    this.tagRef = React.createRef();
    this.styles = require('./skills_input.module.scss');
    PubSub.subscribe('proposedTagCreated', (event, proposedTag) => {
      this.addProposedTag(proposedTag);
    });
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.renderSignupVersion) {
      const currentSkills = JSON.stringify(this.state.skills);
      const nextSkills = JSON.stringify(nextProps.skills);
      if (currentSkills !== nextSkills) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  }

  // Handle mutations
  handleAdd = (addMutation, whatRef) => event => {
    const tagOption = whatRef?.current.getSelectedOption();
    if ((event.type === 'keydown' && tagOption == null) || event.keyCode === 9) {
      return;
    }
    event.preventDefault();

    this.setState(state => ({
      errors: [],
    }));
    let hasError = false;
    let allow = false;

    if (tagOption == null && (event.keyCode === 13 || event.keyCode === undefined)) {
      this.setState(() => ({
        errors: ['Please add a skill'],
      }));
      hasError = true;
    } else if (event.type !== 'keydown' || event.keyCode === 13) {
      allow = true;
      const skillFound = this.state.skills.find(element => {
        return element.tag == tagOption.label;
      });
      if (skillFound != null) {
        this.setState(state => ({
          errors: ['Skill is already added, please add another'],
        }));
        hasError = true;
        this.clearInputs();
      }
    }

    if (!hasError && allow) {
      this.addSkill(addMutation, tagOption);
    }
  };

  addSkill(addMutation, tagOption) {
    // To override
  }

  clearInputs() {
    // To override
  }

  handleDelete = deleteMutation => skillOrTagId => isProposedTag => event => {
    const id = parseInt(skillOrTagId, 10);
    const newValues = { tag: 'Removing ...', experienceLevel: null };
    if (isProposedTag) {
      this.findAndUpdateTag(id, newValues);
    } else {
      this.findAndUpdateSkill(id, newValues);
    }

    deleteMutation({ variables: { id } }).then(
      result => {
        if (
          this.handleFulfilledPromisse(
            result,
            isProposedTag ? ['deleteProposedTag'] : ['candidate', 'skillDelete']
          )
        ) {
          if (isProposedTag) {
            this.findAndDeleteTag(id);
          } else {
            this.findAndDeleteSkill(id);
          }
        }
      },
      reason => this.handleRejectedPromisse(reason)
    );
  };

  // PubSub
  addProposedTag(proposedTag) {
    this.setState(state => ({
      proposedTags: state.proposedTags.concat({
        id: proposedTag.id,
        tag: proposedTag.name,
      }),
    }));
  }

  getAddSkillMutation() {
    // To override
    return null;
  }

  getDeleteProposedTagMutation() {
    return GQL_DELETE_PROPOSED_TAG;
  }

  findAndUpdateSkillOrTag(skillOrTagId, newValues, isProposedTag) {
    const updatedSkillsOrTags = isProposedTag ? this.state.proposedTags : this.state.skills;

    const skillOrTagToUpdate = updatedSkillsOrTags.find(element => {
      return element.id == skillOrTagId;
    });

    skillOrTagToUpdate.id =
      newValues.id !== undefined ? parseInt(newValues.id, 10) : skillOrTagToUpdate.id;
    skillOrTagToUpdate.tag = newValues.tag !== undefined ? newValues.tag : skillOrTagToUpdate.tag;
    if (!isProposedTag) {
      skillOrTagToUpdate.experienceLevel =
        newValues.experienceLevel !== undefined
          ? newValues.experienceLevel
          : skillOrTagToUpdate.experienceLevel;
      skillOrTagToUpdate.count =
        newValues.count !== undefined ? newValues.count : skillOrTagToUpdate.count;
    }

    if (isProposedTag) {
      this.setState(state => ({
        proposedTags: updatedSkillsOrTags,
      }));
    } else {
      this.setState(
        () => ({
          skills: updatedSkillsOrTags,
        }),
        () => {
          this.props.updateSkills(updatedSkillsOrTags);
        }
      );
    }
  }

  findAndUpdateSkill(skillId, newValues) {
    this.findAndUpdateSkillOrTag(skillId, newValues, false);
  }

  findAndUpdateTag(tagId, newValues) {
    this.findAndUpdateSkillOrTag(tagId, newValues, true);
  }

  findAndDeleteSkill(skillId) {
    const updatedSkills = this.state.skills.filter(
      skill => skill.id.toString() !== skillId.toString()
    );

    this.setState(
      () => ({
        skills: updatedSkills,
      }),
      () => {
        this.props.updateSkills(updatedSkills);
      }
    );
  }

  findAndDeleteTag(tagId) {
    const updatedTags = this.state.proposedTags.filter(
      tag => tag.id.toString() !== tagId.toString()
    );

    this.setState(state => ({
      proposedTags: updatedTags,
    }));
  }

  async loadOptions(query, callback) {
    // To override
    return null;
  }

  createOptionMessage(inputValue: string) {
    return `Skill "${inputValue}" not found. Click here to propose a new one`;
  }

  handleProposeNewTag = (inputValue: any) => {
    this.props.createProposedTagCallback(inputValue);
  };

  handleRejectedPromisse(reason) {
    // To override
  }

  handleFulfilledPromisse(result, successReturnFieldNames) {
    if (result == null || result.data == null) {
      // Uncontrolled error
      window.Alerts.alert('Oops, something went wrong.');
      return false;
    }

    let businessResult = result.data;
    successReturnFieldNames.forEach(fieldName => {
      businessResult = businessResult[fieldName];
      if (businessResult == null) {
        // Uncontrolled error
        window.Alerts.alert('Oops, something went wrong.');
        return false;
      }
    });

    if (businessResult.errors != null && businessResult.errors.length !== 0) {
      // Controlled error
      window.Alerts.alert(businessResult.errors[0].message);
      return false;
    }

    return true;
  }

  render() {
    return (
      <ApolloProvider client={apolloClient}>
        {!this.props.readOnly && this.renderInputSection()}
        {this.renderSkillsSection()}
      </ApolloProvider>
    );
  }

  renderInputSection() {
    let error = null;
    if (this.state.errors != null && this.state.errors.length > 0) {
      error = this.state.errors[0];
    }
    return (
      <>
        {/* eslint-disable-next-line react/no-children-prop */}
        <Mutation mutation={this.getAddSkillMutation()} children={this.renderInputsWithMutation} />
        {error != null ? (
          <div className='ld-row error'>
            <div className='ld-alert ld-error-alert'>
              <span className='error_text'>{error}</span>
            </div>
          </div>
        ) : null}
      </>
    );
  }

  sortSkills(orderedSkillsAndTagsIds) {
    // To override
    return null;
  }

  renderSkillsSection() {
    if (
      (this.state.skills && this.state.skills.length > 0) ||
      (this.state.proposedTags && this.state.proposedTags.length > 0)
    ) {
      return (
        <>
          <Sortable
            options={{
              animation: 150,
              ghostClass: 'ld-drag-ghost',
              chosenClass: 'ld-drag-selected',
              dataIdAttr: 'skill-id',
              filter: '.disabled',
              disabled: this.props.disableSort,
              onMove: event => {
                return !event.related.classList.contains('disabled');
              },
            }}
            onChange={items => {
              this.sortSkills(items.map(id => parseInt(id, 10)));
            }}
            className={this.styles.skillsList}
            tag='ol'
          >
            {this.state.skills &&
              this.state.skills.map(skill =>
                this.renderSkillOrTag(skill, false, this.props.isBackoffice)
              )}
            {this.state.proposedTags &&
              this.state.proposedTags.map(proposedTag => this.renderSkillOrTag(proposedTag, true))}
          </Sortable>
        </>
      );
    }
  }

  renderSkillOrTag(skillOrTag, isProposedTag = false, isBackoffice = false) {
    return <></>;
  }

  renderInputsWithMutation = (addMutation, { loading, data = null, error = null }) => (
    <>
      {this.renderInputs(addMutation, loading, data, error)}
      {error && !authenticationError(error.graphQLErrors) && <p>{error.toString()}</p>}
    </>
  );

  renderInputs(addMutation, loading, data, error) {
    let selectType = 'async';
    if (this.props.createProposedTagCallback !== null) {
      selectType = 'async-creatable';
    }
    return (
      <>
        <div className={this.styles.inputRow}>
          <Select
            color={this.props.color ? this.props.color : 'tuftsBlue'}
            defaultOptions={[]}
            loadOptions={this.loadOptions}
            openMenuOnClick={false}
            onFocus={() => this.tagRef?.current.clearSelectedValue()}
            placeholder='E.g. PHP'
            ref={this.tagRef}
            testId='skillName'
            type={selectType}
            formatCreateLabel={this.createOptionMessage}
            onCreateOption={this.handleProposeNewTag}
            onKeyDown={this.handleAdd(addMutation, this.tagRef)}
            noOptionsMessage={() => 'Start typing'}
            isClearable
            blurInputOnSelect
            cacheOptions
          />
          {this.renderInputsExtra(addMutation)}
          <Button
            buttonColor={this.addButtonColor}
            onClick={this.handleAdd(addMutation, this.tagRef)}
            disabled={loading}
            otherClasses={this.styles.button}
          >
            Add
          </Button>
        </div>
        {this.renderInputsHelper()}
      </>
    );
  }

  renderInputsExtra(addMutation) {
    // To override
    return <></>;
  }

  renderInputsHelper() {
    // To override
    return <></>;
  }
}

const GQL_DELETE_PROPOSED_TAG = gql`
  mutation ($id: ID!) {
    deleteProposedTag(id: $id) {
      errors {
        field
        message
      }
    }
  }
`;
