import classNames from 'classnames';
import React from 'react';
import 'lib/globals';

const styles = require('./placement_card.module.scss');

interface Props {
  className?: string;
  id: string;
  companyName: string;
  endDate: string;
  expectedDuration: string;
  projectName: string;
  startDate: string;
}

export default function PlacementCard(props: Props) {
  return (
    <div
      className={classNames(styles.card, props.className)}
      onClick={() => (window.location = window.Routes.dashboard_placement(props.id))}
      role='link'
    >
      <div className={styles.header}>
        <span className={styles.projectName}>{props.projectName}</span> -{' '}
        <span className={styles.companyName}>{props.companyName}</span>
      </div>
      <div>
        <span className={styles.duration}>
          Expected duration: <strong>{props.expectedDuration}</strong>
        </span>
        <span className={styles.dates}>
          Start date: <span className={styles.date}>{props.startDate}</span> - End date:{' '}
          <span className={styles.date}>{props.endDate || '...'}</span>
        </span>
      </div>
    </div>
  );
}
