import { shuffle } from 'lib/string';

export default class Certificate {
  certifications: object[];
  blurred: boolean;

  constructor(params) {
    this.certifications = params.certifications;
    this.blurred = params.handshakeProfile && !params.unlocked && !params.open_profile;
  }

  certificateProps = certificate => ({
    id: certificate.id,
    title: this.blurred ? shuffle(certificate.title) : certificate.title,
    contentTitle: this.blurred ? shuffle(certificate.authority) : certificate.authority,
    authority: certificate.authority,
    date: [certificate.year],
    file: certificate.file,
    fileName: certificate.fileName,
    url: certificate.url,
    blurred: this.blurred,
  });

  properCertificates = certifications => {
    if (certifications) {
      const certificatesSortedByDate = certifications.sort((n1, n2) => {
        return n2.year - n1.year;
      });

      return certificatesSortedByDate.map(certificate => this.certificateProps(certificate));
    }
  };

  props() {
    return this.properCertificates(this.certifications);
  }
}
