import * as React from 'react';
import Button from 'lj_shared/button/button';
import LocationInput from 'components/form/location_input/location_input';
import Selectize from 'components/general/selectize/selectize';
import DotLoader from 'lj_shared/dot_loader/dot_loader';
const styles = require('./mobile_filters.module.scss');

interface Props {
  className?: string;
  items: { id: number; title: string; value: number; group: string }[];
  itemRender: Function;
  optGroups: { groupValue: number; groupLabel: string }[];
  onChange: Function;
  selected: number[];
  handleLocationChange: Function;
  clearJobFilter: Function;
  clearLocationFilter: Function;
}

interface State {
  open: boolean;
}

export default class MobileFilters extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { open: false };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const isOpen = currentScrollPos < 100;

    if (this.state.open) {
      this.setState({
        open: isOpen,
      });
    }
  };

  openFilters() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.filters}>
          <div className={styles.title}>Job Opening</div>
          <Selectize
            className={styles.main}
            items={this.props.items}
            itemRender={this.props.itemRender}
            placeholder='e.g. Java'
            selectedRender={this.props.itemRender}
            optGroups={this.props.optGroups}
            onChange={this.props.onChange}
            selected={this.props.selected}
            selectizeOptions={{ searchField: 'title' }}
            closeIcon={true}
            handleCloseIcon={this.props.clearJobFilter}
          />
          <div className={styles.title}>Job Location</div>
          <LocationInput
            handleLocationChange={this.props.handleLocationChange}
            types='regions'
            closeIcon={true}
            handleCloseIcon={this.props.clearLocationFilter}
          />
        </div>
      </div>
    );
  }

  renderLoadingAnimation = () => {
    return (
      <div style={{ position: 'relative' }}>
        <DotLoader />
      </div>
    );
  };

  render() {
    return (
      <div className={styles.wrapper + ' ' + this.props.className}>
        {this.state.open ? (
          this.openFilters()
        ) : (
          <Button
            otherClasses={styles.refineSearchButton}
            onClick={() => this.setState({ open: true })}
          >
            Refine Search
          </Button>
        )}
      </div>
    );
  }
}
