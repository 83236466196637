import React from 'react';
import { mount } from 'enzyme';
import SkillsInput from './SkillsInput';

const createProps = (otherProps = {}) => ({
  error: '',
  skills: [],
  proposedTags: [],
  updateSkills: jest.fn(),
  maximumNumberOfSkills: 40,
  ...otherProps,
});

let props;
let wrapped;

describe('Input is empty', () => {
  beforeAll(() => {
    props = createProps();
    wrapped = mount(<SkillsInput {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('shows the label', () => {
    expect(wrapped.find('.label').text()).toEqual(
      'Add your most important skills *Please add at least 2 skills.'
    );
  });

  it('shows how many skills are left', () => {
    expect(wrapped.find('.skillsLeftHelper').text()).toEqual('40 Skills left');
  });
});

describe('Input has one value selected', () => {
  beforeAll(() => {
    props = createProps({
      skills: [{ experienceLevel: 5, id: '1433309', tag: 'Drupal' }],
    });
    wrapped = mount(<SkillsInput {...props} />);
  });

  it('shows how many skills are left', () => {
    expect(wrapped.find('.skillsLeftHelper').text()).toEqual('39 Skills left');
  });

  it('shows the selected skill', () => {
    expect(wrapped.find('.skillName').text()).toEqual('Drupal');
  });
});
