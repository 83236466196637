import * as React from 'react';
import IntegrationButton from '../ats/token_button';

interface Props {
  url: string;
}

export default function Button(props: Props) {
  return (
    <IntegrationButton
      url={props.url}
      atsName={'Homerun'}
      atsPathToCreateToken={'Profile > Company details > Advanced > Integrations > API Key'}
      atsAPIDocUrl={'https://help.homerun.co/en/articles/2240793-does-homerun-offer-an-api'}
    />
  );
}
