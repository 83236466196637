import Rating from '__models__/rating';

export default class RatingUtils {
  landingRating: Partial<Rating>;
  employerRating: Partial<Rating>;
  screeningNotes?: string;
  url: string;
  showRatingBody: boolean;
  hideCurationNotesIfLegacy: boolean;

  constructor(params) {
    this.landingRating = this.getLandingRating(params.ratings);
    this.employerRating = this.getEmployerRating(params.ratings);
    this.url = params.rateApplicationUrl;
    this.screeningNotes = params.hasOwnProperty('screeningNotes') ? params.screeningNotes : '';
    this.showRatingBody = params.hasOwnProperty('showRatingBody') ? params.showRatingBody : false;
    this.hideCurationNotesIfLegacy = params.hasOwnProperty('hideCurationNotesIfLegacy')
      ? params.hideCurationNotesIfLegacy
      : false;
  }

  getLandingRating = ratings =>
    ratings.find(rating => ['AdminUser', 'Automated'].includes(rating.rater_type));

  getEmployerRating = ratings => ratings.find(rating => rating.rater_type === 'User');
}
