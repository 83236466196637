import * as React from 'react';
import IntegrationButton from '../ats/token_button';

interface Props {
  url: string;
}

export default function Button(props: Props) {
  return (
    <IntegrationButton
      url={props.url}
      atsName={'Freshteam'}
      atsPathToCreateToken={'Profile > API Key'}
      atsAPIDocUrl={'https://developers.freshteam.com/api/#authentication'}
    />
  );
}
