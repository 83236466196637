import * as React from 'react';
import { shallow } from 'enzyme';

import OutdatedWarning from './outdated_warning';
import Icon, { Size } from 'components/general/icon/icon';

const warningProps = () => ({
  warning:
    "This screening is related to a previous version of the Job Ad which might affect curation's accuracy",
});

let props;
let wrapped;

describe('Rating Container component', () => {
  beforeEach(() => {
    props = warningProps();
    wrapped = shallow(<OutdatedWarning {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('shallow render contains Icon', () => {
    expect(wrapped.find(Icon).length).toEqual(1);
  });
});
