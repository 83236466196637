import * as React from 'react';
import ConversationsList, { Props } from './conversations_list';
import { mount } from 'enzyme';
import conversationDummy from '__fixtures__/conversation_dummy';
import messageableDummy from '__fixtures__/messageable_dummy';

const createTestProps = moreProps => {
  return {
    conversations: [messageableDummy],
    otherContacts: [conversationDummy],
    selected: conversationDummy,
    selectedTab: '',
    unread_counts: {},
    handleConversationChange: jest.fn(),
    userType: '',
    handleTabChange: jest.fn(),
    getOtherContacts: jest.fn(),
    loading: false,
    mini: false,
    ...moreProps,
  };
};

let props: Props;
let wrapped;

describe('Conversation List component', () => {
  beforeAll(() => {
    props = createTestProps({});
    wrapped = mount(<ConversationsList {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('does not show a handshake tag', () => {
    expect(wrapped.find('.lj-handshake-label')).toHaveLength(0);
  });
});

describe('Conversations for company user', () => {
  beforeAll(() => {
    const handshake = {
      exists: true,
      data: { accepted: '10 Feb, 2020', created: '10 Feb, 2020' },
    };
    messageableDummy.attributes.candidate_applications[0].handshake = handshake;
    props = createTestProps({
      conversations: [messageableDummy],
      userType: 'CompanyUser',
    });
    wrapped = mount(<ConversationsList {...props} />);
  });

  it('shows text for company user', () => {
    expect(wrapped.find('.tooltipTextBelow').text()).toMatch(
      'You thought this profile looked promising for'
    );
  });
});

describe('Conversations for talent user', () => {
  beforeAll(() => {
    const handshake = {
      exists: true,
      data: { accepted: '10 Feb, 2020', created: '10 Feb, 2020' },
    };
    messageableDummy.attributes.candidate_applications[0].handshake = handshake;
    messageableDummy.attributes.recipient_name = 'Landing.jobs';
    props = createTestProps({
      conversations: [messageableDummy],
      userType: 'TalentUser',
    });
    wrapped = mount(<ConversationsList {...props} />);
  });

  it('shows text for talent user', () => {
    expect(wrapped.find('.tooltipTextBelow').text()).toMatch(
      'Landing.jobs thought your profile looked promising for'
    );
  });
});
