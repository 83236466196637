import React from 'react';
import Placements from './placements';
import { render } from '@testing-library/react';

describe('Placements', () => {
  it('renders the passed placements', () => {
    const placements = [
      {
        companyName: 'FooCorp',
        endDate: '2015-01-01',
        expectedDuration: 'One month',
        id: '1',
        projectName: 'The Foo Project',
        startDate: '2015-01-20',
        status: 'active',
      },
      {
        companyName: 'BarCorp',
        endDate: '2016-01-01',
        expectedDuration: 'One second',
        id: '2',
        projectName: 'The Bar Project',
        startDate: '2016-01-20',
        status: 'closed',
      },
    ];
    const { queryByText } = render(<Placements placements={placements} />);

    for (const placement of placements) {
      for (const [key, value] of Object.entries(placement)) {
        if (key !== 'id') {
          expect(queryByText(value, { exact: false })).toBeTruthy();
        }
      }
    }
  });
});
