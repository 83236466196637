import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import OfficeLocationsTooltip from './office_locations_tooltip';
import QuestionMarkTooltip from 'components/question_mark_tooltip/question_mark_tooltip';

const createProps = () => ({
  officeLocations: [
    {
      google_place_id: '1232jfifnowefn',
      city: 'Lisbon',
      country_code: 'PT',
      label: 'Lisbon, Portugal',
    },
    {
      google_place_id: '1232jsssfifnowefn',
      city: 'Lisbone',
      country_code: 'PTE',
      label: 'Lisbone, Portugale',
    },
  ],
  iconColor: 'black',
  iconSize: 'mediumLarge',
});

let props;
let wrapped: ShallowWrapper;

describe('OfficeLocationsTooltip', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<OfficeLocationsTooltip {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('should have one question mark tooltip', () => {
    expect(wrapped.find(QuestionMarkTooltip)).toHaveLength(1);
  });
});

test.todo('add more tests');
