import * as React from 'react';
import ApplicationCard from './application_card/application_card';
import * as showdown from 'showdown';
import Button from 'lj_shared/button/button';
const rejectionIcon = require('iconic/circle-x.svg');

interface Props {
  rejectionReason: string;
  rejectionMessage: string;
  state: number;
  reconsiderApplication: any;
}

const RejectionReason = (props: Props) => {
  const { rejectionReason, rejectionMessage } = props;

  const converter = new showdown.Converter();
  converter.setOption('simpleLineBreaks', true);
  converter.setOption('strikethrough', true);

  return (
    <ApplicationCard title='Rejection Reason' icon={rejectionIcon}>
      <div className='lj-heading--base u-marginBottom--small'>Rejection Reason</div>
      <p className='lj-paragraph--midSmall u-textColor--silverSand is-dark'>{rejectionReason}</p>

      {rejectionMessage ? (
        <>
          <div className='lj-heading--base u-marginBottom--small'>
            {props.state === 97 ? 'Message to candidate' : 'Note for Landing.jobs'}
          </div>
          <p
            className='lj-paragraph--midSmall u-textColor--silverSand is-dark'
            dangerouslySetInnerHTML={{
              __html: converter.makeHtml(rejectionMessage),
            }}
          />
        </>
      ) : null}
      <Button buttonColor='ripePlum' buttonSize='small' onClick={props.reconsiderApplication}>
        Reconsider
      </Button>
    </ApplicationCard>
  );
};

export default RejectionReason;
