import React from 'react';
import { render } from '@testing-library/react';
import GroupTooltip, { tooltipText } from './group_tooltip';

describe('GroupTooltip', () => {
  it('when passed "Handpicked" it should render the according tooltip text', () => {
    const name = 'Handpicked';
    const { queryByText } = render(<GroupTooltip name={name} />);

    expect(queryByText(tooltipText(name), { exact: false })).toBeTruthy();
  });
});
