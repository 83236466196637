import React from 'react';
import { apolloClient } from 'lib/graphql';
import { getJSON, handleRequestError } from 'lib/request';
import { GQL_ADD_SKILL } from 'components/general/skills_input/candidate_skills_input';
import Button from 'lj_shared/button/button';
import classNames from 'classnames';
import Modal from 'components/general/modal/modal';
import ProfileForm from 'components/registration/profile_form';
import TextInput from '../form/text/text';

const styles = require('./AutofillSection.module.scss');

export interface Props {
  showLinkedin: boolean;
  handleFormChange: Function;
  handleAddWebsiteClick: Function;
  handleDeleteWebsiteClick: Function;
  profileForm: ProfileForm;
  updateSkills: Function;
}

interface State {
  loading: {
    state: boolean;
    stage: number;
  };
}

export default class AutofillSection extends React.Component<Props, State> {
  modalRef: any;
  commentsRef: any;

  constructor(props: Props) {
    super(props);

    this.modalRef = React.createRef();
    this.commentsRef = React.createRef();

    this.state = {
      loading: {
        state: false,
        stage: null,
      },
    };
  }

  renderLoadingScreen(stage) {
    const class1 =
      stage > 0 ? `${styles.progressionStep} ${styles.stepCompleted}` : styles.progressionStep;
    const class2 =
      stage > 1 ? `${styles.progressionStep} ${styles.stepCompleted}` : styles.progressionStep;
    const class3 =
      stage > 2 ? `${styles.progressionStep} ${styles.stepCompleted}` : styles.progressionStep;

    let loadingText = '';
    let imageUrl = '';
    switch (stage) {
      case 0:
        loadingText = 'Looking for your profile!';
        imageUrl = 'https://assets.landing.jobs/cms-assets/files/2037/original/Asset_53.svg';
        break;
      case 1:
        loadingText = 'Location: set!';
        imageUrl = 'https://assets.landing.jobs/cms-assets/files/2040/original/Asset_54.svg';
        break;
      case 2:
        loadingText = 'Uh... Nice skills!';
        imageUrl = 'https://assets.landing.jobs/cms-assets/files/2039/original/Asset_55.svg';
        break;
      case 3:
        loadingText = "You're a talent! Please confirm all fields are correct.";
        imageUrl = 'https://assets.landing.jobs/cms-assets/files/2038/original/Asset_56.svg';
        break;
      default:
        break;
    }

    return (
      <>
        <div className={styles.progressionBar}>
          <div className={classNames(class1)} />
          <div className={classNames(class2)} />
          <div className={classNames(class3)} />
        </div>
        <div className={styles.loadingSection}>
          <img
            alt={`Step ${stage}`}
            title={`Step ${stage}`}
            src={imageUrl}
            className={styles.loadingImage}
          />
          <p className={styles.loadingText}>{loadingText}</p>
        </div>
      </>
    );
  }

  renderLinkedinModal() {
    const linkedinUrl = this.props.profileForm.listOfLinks.find(
      link => link.category.toLowerCase() === 'linkedin'
    );
    return (
      <Modal
        title='get info from linkedin'
        defaultOpen={false}
        ref={this.modalRef}
        disableOutsideClose
      >
        {this.state.loading.state ? (
          this.renderLoadingScreen(this.state.loading.stage)
        ) : (
          <>
            <TextInput
              color='tuftsBlue'
              label={'Complete your linkedin profile link:'}
              value={linkedinUrl ? linkedinUrl.url : 'https://linkedin.com/in/'}
              inputRef={this.commentsRef}
              textarea={false}
              required
            />
            <div className={styles.modalButtons}>
              <Button
                buttonColor='tuftsBlue'
                onClick={e => this.handleFetchLinkedinData(this.commentsRef.current.value)}
              >
                Get your info
              </Button>
              <Button buttonColor='tuftsBlue' buttonType='border' onClick={this.closeModal}>
                Cancel
              </Button>
            </div>
          </>
        )}
      </Modal>
    );
  }

  openModal = () => {
    this.updateLoadingStep(false, null);
    this.modalRef.current.open();
  };

  closeModal = () => {
    this.modalRef.current.close();
  };

  updateLoadingStep = (state, step) => {
    this.setState(
      {
        loading: {
          state,
          stage: step,
        },
      },
      () => {
        state &&
          setTimeout(() => {
            step < 2 && this.updateLoadingStep(state, step + 1);
          }, 2500);
      }
    );
  };

  handleFetchLinkedinData = url => {
    if (url === 'https://linkedin.com/in/') {
      window.Alerts.alert('Please fill in your linkedin url');
      return null;
    }
    this.updateLoadingStep(true, 0);
    const oldUrl = this.props.profileForm.listOfLinks.find(
      link => link.category.toLowerCase() === 'linkedin'
    );

    if (oldUrl) {
      if (oldUrl.url !== url) {
        this.props.handleDeleteWebsiteClick(oldUrl.id).then(() => {
          this.props.handleAddWebsiteClick({ category: 'linkedin', url });
        });
      }
    } else {
      this.props.handleAddWebsiteClick({ category: 'linkedin', url });
    }

    const followUp = ({ data }) => {
      if (data.skill_tags?.length > 0) {
        data.skill_tags.forEach(skillTag => {
          this.linkedinUpdateSkills(skillTag);
        });
      }

      if (data.location) {
        this.props.handleFormChange(data.location, 'linkedinLocation');
      }

      if (data.first_working) {
        const startYear = {
          label: data.first_working,
          value: data.first_working,
        };
        this.props.handleFormChange(startYear, 'startYear');
      }
      setTimeout(() => {
        this.closeModal();
      }, 2500);
      this.updateLoadingStep(true, 3);
    };

    getJSON(window.Routes.fetch_linkedin({ url }), followUp);
  };

  linkedinUpdateSkills = skillTag => {
    if (!this.props.profileForm.skills.find(skill => skill.tag === skillTag.name)) {
      const variables = {
        tagId: skillTag.id,
        experienceLevel: null,
      };

      const mutation = GQL_ADD_SKILL;
      apolloClient
        .mutate({ mutation, variables })
        .then(({ data }) => {
          const skill = data.candidate.skillCreate.resource;
          skill.tag = skill.tag.name;
          const skills = [...this.props.profileForm.skills, skill];
          this.props.updateSkills(skills);
        })
        .catch(handleRequestError);
    }
  };

  render() {
    return (
      this.props.showLinkedin && (
        <div className={styles.autofillSection}>
          {this.renderLinkedinModal()}
          <p className={styles.autofillText}>
            Do you want us to fetch some of your info from your LinkedIn profile, to accelerate the
            signup process?
          </p>
          <Button buttonColor='tuftsBlue' onClick={this.openModal}>
            Autofill
          </Button>
        </div>
      )
    );
  }
}
