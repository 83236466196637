export const getYears = () => {
  const rangeSize = 65;
  const startingValue = new Date().getFullYear() - 18;
  return Array.from({ length: rangeSize }, (_, index) => {
    const currentValue = startingValue - index;
    return { label: currentValue, value: currentValue };
  });
};

export const citizenships = citizenshipsList =>
  citizenshipsList.map(citizenship => ({
    id: citizenship,
    value: citizenship,
    label: citizenship,
  }));

export const contactPreference = (contactPreferenceObj, contactPreferenceValue = null) => {
  const results = contactPreferenceObj
    .filter(([_, value]) => {
      return contactPreferenceValue == null || value === contactPreferenceValue;
    })
    .map(([label, value]) => ({
      label,
      value,
    }));

  if (contactPreferenceValue != null) {
    return results[0] || null;
  }
  return results;
};

export const sharePreferences = sharePreferenceObj =>
  Object.entries(sharePreferenceObj)
    .map(([label, value]) => ({
      label,
      value,
    }))
    .reverse();

export const availabilityList = availabilityObj =>
  Object.entries(availabilityObj).map(([label, value]) => ({ label, value }));

export const experienceLevel = experienceLevelList =>
  experienceLevelList.map(([label, value]) => ({ label, value }));

export const startYears = yearsList => yearsList.map(year => ({ value: year, label: year }));

export const getYearDifference = (year, experience) => {
  if (year === '') {
    return experience;
  }
  const difference = new Date().getFullYear() - year;
  if (difference < 1) {
    return '< 1 year';
  } else if (difference > 10) {
    return '10+ years';
  } else {
    return difference.toString() + ' years';
  }
};

export const remoteOptions = [
  { value: 0, label: 'On-Site' },
  { value: 1, label: 'No Preference' },
  { value: 2, label: 'Remote' },
];

export const availabilityOptions = [
  {
    value: 2,
    label: "I'm currently employed, but open to hear about new opportunities",
  },
  { value: 1, label: "I'm actively looking for a new job" },
  { value: 0, label: "I'm not looking for a new job, just curious" },
];

export const jobCategories = categoriesList =>
  categoriesList.map(category => ({
    id: category.id,
    value: category.id,
    label: category.name,
  }));
