import React from 'react';
import Modal from 'components/general/modal/modal';
import Spinner from 'shared/dot_spinner/DotSpinner';
import Button from 'lj_shared/button/button';
import Checkbox from 'components/form/checkbox/checkbox';
import { handleRequestError, postJSON } from 'lib/request_deprecated';
import Select from 'components/form/select/select';

const styles = require('./SettingsExportModal.module.scss');

interface Props {
  exportOptions: { name: string; show_deleted: number }[];
  exportSettings: number[];
  notificationPreferences: { data_report_frequency: number };
}

interface State {
  exportDeleted: boolean[];
  exportWhat: string[];
  reportTime: number;
}

export default class SettingsExportModal extends React.Component<Props, State> {
  modalRef: any;

  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
    this.handleChangeFrequency = this.handleChangeFrequency.bind(this);

    this.state = {
      reportTime: props.notificationPreferences.data_report_frequency,
      exportDeleted: [props.exportSettings.includes(1), props.exportSettings.includes(3)],
      exportWhat: this.calculateExportedWhat(props.exportSettings),
    };
  }

  open() {
    this.modalRef.current.open();
  }

  close() {
    this.modalRef.current.close();
  }

  showErrorMessage = errorMessage => {
    window.Alerts.alert(`Oops, something wrong happened: ${errorMessage}`);
  };

  calculateExportedWhat(settings) {
    const exporting = [];
    if (settings.includes(0)) {
      exporting.push(this.props.exportOptions[0].name);
    }
    if (settings.includes(1) || settings.includes(2)) {
      exporting.push(this.props.exportOptions[1].name);
    }
    if (settings.includes(3) || settings.includes(4)) {
      exporting.push(this.props.exportOptions[2].name);
    }
    return exporting;
  }

  renderLoading() {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  filterItems = [
    // {id: 1, label: "Weekly (on Mondays)"  , value: 0},
    { id: 2, label: 'Monthly (on 1st Monday of month)', value: 1 },
    { id: 3, label: 'Never', value: 99 },
  ];

  handleChangeFrequency(event) {
    this.setState({
      reportTime: event.value,
    });
  }

  handleChangeValues(optionName) {
    const exportWhat = this.state.exportWhat;
    const index = exportWhat.indexOf(optionName);
    if (index > -1) {
      exportWhat.splice(index, 1);
    } else {
      exportWhat.push(optionName);
    }

    this.setState({
      exportWhat,
    });
  }

  handleChangeDeleted(optionName) {
    const exportDeleted = this.state.exportDeleted;
    if (optionName === 'Funnel by job') {
      exportDeleted[0] = !exportDeleted[0];
    } else if (optionName === 'Process duration by job') {
      exportDeleted[1] = !exportDeleted[1];
    }

    this.setState({
      exportDeleted,
    });
  }

  handleClickSave(event) {
    event.preventDefault();
    const exportWhat = this.state.exportWhat;

    if (exportWhat.length > 0) {
      this.updateExportOptions();
    } else {
      window.alert('Please select what sections to export');
    }
  }

  updateExportOptions() {
    window.toggleSpinner(true);

    postJSON(window.Routes.update_data_report_settings_employer_url(), {
      export_what: this.state.exportWhat,
      export_deleted: this.state.exportDeleted,
      report_time: this.state.reportTime,
    })
      .then(e => {
        if (e.notice) {
          window.Alerts.notice(e.notice);
          this.close();
        } else {
          window.Alerts.alert(e.alert);
        }
        window.toggleSpinner(false);
      })
      .catch(handleRequestError);
  }

  render() {
    return (
      <Modal title='EMAIL REPORT SETTINGS' defaultOpen={false} ref={this.modalRef}>
        <div className={styles.exportModal}>
          <div className={styles.exportText}>Email frequency</div>
          <div className={styles.exportLabel}>
            <Select
              name='top_filter'
              color='ripePlum'
              options={this.filterItems}
              placeholder={'Select duration'}
              value={this.filterItems.filter(filter => filter.value === this.state.reportTime)}
              onChange={this.handleChangeFrequency}
            />
          </div>
          <div className={styles.exportLabel}>Select the data you want to export:</div>
          <div className={styles.exportButtons}>
            {this.props.exportOptions.map((option, index) => (
              <div key={option.name}>
                <Checkbox
                  name='export_form[values][]'
                  value={option.name}
                  label={option.name}
                  color='ripePlum'
                  checked={this.state.exportWhat.indexOf(option.name) !== -1}
                  onChange={() => this.handleChangeValues(option.name)}
                  controlled={true}
                >
                  {option.show_deleted ? (
                    <div key={`${option.name} unpublished`} className={styles.unpublishedBoxes}>
                      <Checkbox
                        name='deleted[values][]'
                        value={`${option.name}`}
                        label={'Include unpublished jobs'}
                        color='ripePlum'
                        checked={this.state.exportDeleted[index - 1]}
                        onChange={() => this.handleChangeDeleted(option.name)}
                        controlled={true}
                      />
                    </div>
                  ) : null}
                </Checkbox>
              </div>
            ))}
          </div>
          <div className={styles.exportButton}>
            <Button
              buttonColor='ripePlum'
              isButton={true}
              isRound
              disabled={false}
              onClick={e => this.handleClickSave(e)}
            >
              SAVE
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}
