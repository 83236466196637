import * as React from 'react';
import AsyncSelect from 'react-select/async';
import classnames from 'classnames';
import AsyncCreatableSelect from 'react-select/async-creatable';
import NormalSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import FormError from 'components/form/form_error/form_error';

const styles = require('./select.module.scss');

// This is just a wrapper around React Select to add our styles by default. Check
// https://react-select.com to see how to use.
export default class Select extends React.Component<any, {}> {
  selectRef: React.RefObject<any>;

  constructor(props) {
    super(props);
    this.selectRef = React.createRef();
  }

  blur() {
    this.selectRef.current.blur();
  }

  getSelectedOption() {
    const component = this.selectRef.current;

    switch (this.props.type) {
      case 'async':
      case 'async-creatable':
        return component.select.state.value;
      default:
        return component.state.value;
    }
  }

  getSelectedValue() {
    const option = this.getSelectedOption();
    return option ? option.value : undefined;
  }

  get clearValue() {
    return this.clearSelectedValue();
  }

  clearSelectedValue() {
    const { isMulti } = this.props;
    const value = isMulti ? [] : null;
    let component = this.selectRef.current;

    switch (this.props.type) {
      case 'async':
      case 'async-creatable':
        component = component.select;
        break;
    }

    return component.setState({ value });
  }

  focus() {
    this.selectRef.current.focus();
  }

  render() {
    if (this.props.readOnly) {
      return <div className={styles.readOnlyLabel}>{this.props.value?.label}</div>;
    }

    const Component = getComponent(this.props.type);
    let thisClassName;
    if (this.props.styleMode === 'smallWhite') {
      thisClassName = styles.smallWhite;
    } else {
      // There should be a better way of doing it
      switch (this.props.color) {
        case 'ripePlum':
          thisClassName = styles.container_ripePlum;
          break;
        case 'tuftsBlue':
          thisClassName = styles.container_tuftsBlue;
          break;
        case 'silverSand':
          thisClassName = styles.container_silverSand_xxDark;
          break;
        default:
          thisClassName = styles.container_puertoRico;
      }
    }

    let sizeClassName = null;
    if (this.props.size === 'medium') {
      sizeClassName = styles.mediumSize;
    }
    if (this.props.otherClassName || sizeClassName) {
      thisClassName = classnames(thisClassName, this.props.otherClassName, sizeClassName);
    }

    const defaultProps = {
      isClearable: true,
      className: thisClassName,
      classNamePrefix: '_',
    };
    const props = { ...defaultProps, ...this.props };
    return (
      <div className={this.props.wrapperClassName} data-testid={this.props.testId}>
        {this.renderLabel()}
        <Component {...props} ref={this.selectRef} />
        {this.props.error && <FormError text={this.props.error} />}
      </div>
    );
  }

  renderLabel() {
    const className = classnames(styles.label, {
      [styles.labelWithError]: this.props.error,
    });
    const labelInlineStyles = this.props.labelInlineStyles;
    return (
      this.props.label && (
        <label style={labelInlineStyles} className={className}>
          {this.props.label}
          {this.props.required && <span className={styles.required}> *</span>}
        </label>
      )
    );
  }
}

function getComponent(type) {
  switch (type) {
    case 'async':
      return AsyncSelect;
    case 'async-creatable':
      return AsyncCreatableSelect;
    case 'creatable':
      return CreatableSelect;
    default:
      return NormalSelect;
  }
}
