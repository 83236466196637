import * as React from 'react';
import ApplicationNotes, { Props } from './application_notes';
import { shallow, ShallowWrapper } from 'enzyme';

const createTestProps = moreProps => {
  return {
    getNotesUrl: 'http://localhost:3000/conversations1',
    postNoteUrl: 'http://localhost:3000/conversation2',
    destroyNoteUrl: 'http://localhost:3000/conversations3',
    applicationId: 1,
    ...moreProps,
  };
};

let props: Props;
let wrapped: ShallowWrapper;

describe('Application Notes component', () => {
  props = createTestProps({});
  wrapped = shallow(<ApplicationNotes {...props} />);

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
