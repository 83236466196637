export function initHotjarSurveys() {
  const script = document.createElement('script');

  if (process.env.RAILS_ENV === 'production') {
    const scriptText = document.createTextNode(getProductionCode());
    script.appendChild(scriptText);
  } else {
    const scriptText = document.createTextNode(getStagingCode());
    script.appendChild(scriptText);
  }

  document.head.appendChild(script);
}

/* Hotjar Tracking Code for https://qa.landing.jobs/ */
/* it can also work for PR-APP and local development */
function getStagingCode() {
  return generateHotjarScriptCode(1318582, 6);
}

/* Hotjar Tracking Code for https://landing.jobs/ */
function getProductionCode() {
  return generateHotjarScriptCode(257097, 6);
}

/* Generates Hotjar Tracking Code based on code copied from Hotjar */
function generateHotjarScriptCode(hjid, hjsv) {
  return `
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${hjid},hjsv:${hjsv}};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  `;
}

export function sendUserAttributes() {
  const companyUserData = window.gon.companyUserData;

  if (companyUserData) {
    window['hj']('identify', companyUserData.user_id, companyUserData);
  }
}
