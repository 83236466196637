import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import AboutCompany from './AboutCompany';

const createProps = () => ({
  companyId: 8,
  companyName: 'Landing.Jobs',
  coverPhoto: 'photo',
  logo: 'logo',
  shortPitch: 'pitch',
});

window.Routes = {
  company: () => '',
};

let props;
let wrapped: ShallowWrapper;

describe('Your description here', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<AboutCompany {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('should have cover photo and logo', () => {
    expect(wrapped.find('img')).toHaveLength(2);
  });

  it('should have a link', () => {
    expect(wrapped.find('a')).toHaveLength(1);
  });
});
