import { camelize, snakeCase } from './string';

export function getter(name: string) {
  return getProp.bind(undefined, name);
}

function getProp(name: string, obj: object) {
  return obj[name];
}

export function camelizeObj(obj: object) {
  return Object.entries(obj).reduce((accumulator, [key, value]) => {
    if (Array.isArray(value)) {
      return {
        ...accumulator,
        [camelize(key)]: value.map(valueItem => camelizeObj(valueItem)),
      };
    }

    if (value !== null && value !== undefined && typeof value === 'object') {
      return {
        ...accumulator,
        [camelize(key)]: camelizeObj(value),
      };
    }

    return {
      ...accumulator,
      [camelize(key)]: value,
    };
  }, {});
}

export function objMap(obj: object, fn: (key: string, value: any) => [string, any]) {
  const obj2 = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const [key2, value2] = fn(key, obj[key]);
      obj2[key2] = value2;
    }
  }

  return obj2;
}

export function objToSnakeCase(obj: object): object {
  return objMap(obj, (key, value) => [snakeCase(key), value]);
}
