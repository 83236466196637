import React from 'react';
import Modal, { ButtonsLayout } from '../general/modal/modal';
import { Application } from '__models__/models';
import { apolloClient } from 'lib/graphql';
import { ApolloProvider, Query } from 'react-apollo';
import gql from 'graphql-tag';
import Spinner from 'shared/dot_spinner/DotSpinner';
import dateFormat from '../../lib/format_date';

const styles = require('./multiple_processes_modal.module.scss');

interface Props {
  application: Application;
  onClose?: any;
}

const GQL_QUERY_JOBS = gql`
  query ($application_id: ID!) {
    personApplicationsToCompany(applicationId: $application_id) {
      nodes {
        id
        hiringStateLabel
        referenceDate
        rejectionReason
        jobAd {
          title
          stateName
          company {
            name
          }
        }
      }
    }
  }
`;

export default class MultipleProcessesModal extends React.Component<Props> {
  modalRef: any;

  constructor(props) {
    super(props);

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);

    this.modalRef = React.createRef();
  }

  open() {
    this.modalRef.current.open();
  }

  close() {
    this.modalRef.current.close();
  }

  renderModalContent(nodes) {
    const person = this.props.application.attributes.person;
    const companyName = nodes[0].jobAd.company.name;
    const jobs = nodes.map((item, key) => (
      <li key={item.id}>
        {item.jobAd.stateName === 'closed' ? (
          `${item.jobAd.title} (job ad closed)`
        ) : (
          <a href={window.Routes.employer_application(item.id)} target='_blank' rel='noreferrer'>
            {item.jobAd.title}
          </a>
        )}
        {item.rejectionReason ? (
          <p>
            {dateFormat(item.referenceDate, {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
            })}{' '}
            | Rejected: {item.rejectionReason}
          </p>
        ) : (
          <p>
            {dateFormat(item.referenceDate, {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
            })}{' '}
            | {item.hiringStateLabel}
          </p>
        )}
      </li>
    ));
    return (
      <>
        <div>
          {person.attributes.name} has a total of <strong>{nodes.length} applications </strong>
          with {companyName}:
        </div>
        <ul className={styles.jobList}>{jobs}</ul>
      </>
    );
  }

  renderLoading() {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  renderError(error) {
    return <div>Error: {error}</div>;
  }

  render() {
    return (
      <ApolloProvider client={apolloClient}>
        <Modal
          title='In multiple processes'
          defaultOpen={true}
          ref={this.modalRef}
          onClose={() => this.props.onClose()}
        >
          <div>
            <Query query={GQL_QUERY_JOBS} variables={{ application_id: this.props.application.id }}>
              {result => {
                if (result.loading) return this.renderLoading();
                if (result.error) return this.renderError(result.error);

                return this.renderModalContent(result.data.personApplicationsToCompany.nodes);
              }}
            </Query>
          </div>
        </Modal>
      </ApolloProvider>
    );
  }
}
