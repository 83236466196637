import * as React from 'react';
import { shallow } from 'enzyme';
import SquareRadioButtons from './square_radio_buttons';

const testItems = ['Item 1', 'Item 2', 'Item 3'];
const onClickYield = input => {
  input.id = 'testingClick';
};

describe('<SquareRadioButtons />', () => {
  const baseComponent = shallow(
    <SquareRadioButtons
      items={testItems}
      onClickYield={onClickYield}
      vertical={true}
      radioName='test'
    />
  );

  it('renders without crashing', () => {
    expect(baseComponent).toMatchSnapshot();
  });

  it('renders the correct ids', () => {
    const expectedOutput =
      '<input type="radio" class="radioInput" id="test_0" name="test" value="Item 1"/>';
    const output = baseComponent.find('span:first-child').find('input').html();
    expect(output).toEqual(expectedOutput);
  });

  it('renders the correct labels', () => {
    const expectedOutput =
      '<span class="radio">' +
      '<input type="radio" class="radioInput" id="test_0" name="test" value="Item 1"/>' +
      '<label class="radioLabel"><div class="labelMain">Item 1</div></label>' +
      '</span>';
    const output = baseComponent.find('span:first-child').html();
    expect(output).toEqual(expectedOutput);
  });

  it('onClick', () => {
    const selection = baseComponent.find('span:first-child');
    const input = selection.find('input');

    // There is no initial selection
    expect((input as any).checked).toEqual(undefined);

    selection.find('label').simulate('click', {
      target: {
        parentElement: {
          parentElement: { querySelector: () => input },
        },
      },
    });

    // The first element is now checked
    expect((input as any).id).toEqual('testingClick');
    expect((input as any).checked).toEqual(true);

    // Allow document.querySelectorAll to work
    (global as any).document = {
      querySelectorAll: _e => [input, input2],
    };

    const selection2 = baseComponent.find('span:last-child');
    const input2 = selection2.find('input');
    selection2.find('label').simulate('click', {
      target: {
        parentElement: {
          parentElement: { querySelector: () => input2 },
        },
      },
    });

    // first element is unchecked, second is cheked
    // expect((input as any).checked).toEqual(false);
    expect((input2 as any).checked).toEqual(true);
  });
});
