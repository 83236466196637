import gql from 'graphql-tag';
export const settings = require('settings.json');

export const GET_LOCATION_RATING_AND_NOTES_GQL = gql`
  query (
    $workPermit: Boolean!
    $talentCity: String!
    $talentCountryCode: String!
    $talentLocation: String!
    $timeZone: Boolean!
    $timeZoneNegative: Boolean!
    $jobType: String!
    $applicationId: Int!
  ) {
    locationRatingAndNotesComputation(
      workPermit: $workPermit
      talentCity: $talentCity
      talentCountryCode: $talentCountryCode
      talentLocation: $talentLocation
      timeZone: $timeZone
      timeZoneNegative: $timeZoneNegative
      jobType: $jobType
      applicationId: $applicationId
    ) {
      rating
      notes
    }
  }
`;

export const resolveLocationVariables = (dataValues, jobType, applicationId) => {
  const variables = {
    workPermit: dataValues['work_permit'],
    talentCity: String(dataValues['talent_city']),
    talentCountryCode: String(dataValues['talent_country_code']),
    talentLocation: String(dataValues['talent_location']),
    timeZone: dataValues['time_zone'],
    timeZoneNegative: dataValues['time_zone_negative'],
    jobType,
    applicationId,
  };
  return variables;
};
