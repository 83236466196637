import { Conversation } from 'components/conversations/components/models';

const conversationDummy: Conversation = {
  id: '5961',
  type: 'conversation',
  attributes: {
    subject: 'Performance Marketing Associate @ Landing.jobs',
    context_type: 'Application',
    context_ids: [153785],
    talent_user_id: 100288,
    company_user_id: 93481,
    company_id: 8,
    deactivated: false,
    created_at: new Date(),
    updated_at: new Date(),
    recipient_name: 'Carolina Filipe',
    recipient_avatar:
      '<div class="lj-userAvatar--small lj-userAvatar"><img src="https://assets.landing.jobs/default_avatars/avatar1.svg" class="lj-userAvatar-img" /></div>',
    candidate_applications: [
      {
        id: 153785,
        person_id: 96461,
        app_rating: 12,
        current_hiring_step_id: 7863,
        job: {
          id: 7863,
          title: 'Performance Marketing Associate',
        },
        notes: {
          data: [],
        },
        handshake: {
          exists: false,
          data: { accepted: null, created: null },
        },
      },
    ],
    person_id: 96461,
    experience_level: '< 1 year of experience',
    websites: [
      {
        id: 118402,
        full_url: 'https://linkedin.com/in/carolinapfilipe',
        title: 'Linkedin',
        category_key: 'linkedin',
      },
    ],
    has_cv: true,
    company_deleted: false,
    person_slug: '',
    recipient_recently_updated: false,
    user_deleted: false,
  },
  included: [],
};

export default conversationDummy;
