const rejectionDimensionsMapping = {
  location: [
    'candidate_doesnt_live_within_a_commuting_distance',
    'no_local_citizenship_or_valid_work_permit',
  ],
  language: ['doesnt_know_a_required_language', 'lacks_proficiency_in_a_required_language'],
  skills: ['not_enough_skills'],
  experience: ['not_enough_work_experience', 'not_the_right_background'],
};

export default rejectionDimensionsMapping;
