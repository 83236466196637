/**
 * Javascript Interface for our tracking implementation - Drip
 */

class Drip {
  category: string;
  args: any;

  constructor(category = 'action', args = {}) {
    this.category = category;
    this.args = args;
  }

  save() {
    // we hope to deprecate this one day but for now
    // its being used to track company engagement so we will ignore some actions
    if (this.category !== 'action') {
      return;
    }

    fetch('/drip', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ category: this.category, args: this.args }),
    })
      .then(() => undefined)
      .catch(() => undefined);
  }
}

export function track(category, args) {
  const drip = new Drip(category, args);
  drip.save();
}

function trackPageClicks() {
  const elements = document.querySelectorAll('.js-drip-click');

  if (elements.length > 0) {
    elements.forEach(element => {
      const attributes = element.getAttributeNames().filter(x => x.indexOf('drip-') >= 0);
      const values = attributes.map(attribute => {
        return element.attributes[attribute].value;
      });

      // transform attributes and values into an object
      const information = attributes.reduce(
        (object, key, index) => ({ ...object, [key.slice(5)]: values[index] }),
        {}
      );

      const category = information['drip-category'] || 'action';

      const drip = new Drip(category, information);

      (element as any).onclick = () => {
        drip.save();
      };
    });
  }
}

document.addEventListener('turbolinks:load', trackPageClicks);
export default track;
