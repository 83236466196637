const profileDummy = {
  availability: {
    id: 1,
    value: 1,
    label: '1 Week',
  },
  birthYear: 1988,
  citizenships: [
    {
      id: 'Portuguese',
      value: 'Portuguese',
      label: 'Portuguese',
    },
    {
      id: 'Brazilian',
      value: 'Brazilian',
      label: 'Brazilian',
    },
  ],
  city: 'Lisbon Area',
  country: 'PT',
  countryName: 'Portugal',
  description: 'Tumbas',
  email: 'cerberus.ramon@gmail.com',
  experienceYears: {
    value: 3,
    label: '3 years of experience',
  },
  startYear: {
    value: 2003,
    label: 2003,
  },
  firstName: 'boom',
  headline: 'Rea',
  jobSearchAvailability: "I'm currently employed, but open to hear about new opportunities",
  lastName: 'Rodrigues',
  listOfLinks: [
    {
      category: 'linkedin',
      id: 51168,
      title: 'linkedin',
      url: 'https://linkedin.com/in/ramongr',
    },
    {
      category: 'github',
      id: 51171,
      title: 'github',
      url: 'https://github.com/ramongr',
    },
    {
      category: 'bitbucket',
      id: 51172,
      title: 'bitbucket',
      url: 'https://bitbucket.org/ramongr',
    },
    {
      category: 'dribbble',
      id: 131842,
      title: 'dribbble',
      url: 'https://www.dribbble.com/ramongr',
    },
    {
      category: 'behance',
      id: 131843,
      title: 'behance',
      url: 'http://www.behance.net/ramongr',
    },
    {
      category: 'facebook',
      id: 131845,
      title: 'facebook',
      url: 'https://facebook.com/ramongr',
    },
    {
      category: 'twitter',
      id: 131844,
      title: 'twitter',
      url: 'https://twitter.com/ramongr',
    },
    {
      category: 'instagram',
      id: 131846,
      title: 'instagram',
      url: 'https://instagram.com/ramongr',
    },
  ],
  location: 'Lisbon Area, Portugal',
  personLanguages: [
    {
      id: 69406,
      name: 'English',
      experienceLabel: '4. Full professional proficiency',
    },
    {
      id: 69405,
      name: 'Portuguese',
      experienceLabel: '5. Native or Bilingual proficiency',
    },
  ],
  remote: 1,
  freelance: false,
  relocation: true,
  relocationCountries: [
    {
      id: 'Ireland',
      value: 'Ireland',
      label: 'Ireland',
    },
  ],
  categories: [],
  phoneNumber: '+351 91 234 5678',
  skills: [
    {
      experienceLevel: 6,
      id: '518097',
      tag: 'Linux',
    },
    {
      experienceLevel: 4,
      id: '518096',
      tag: 'Ruby on Rails',
    },
    {
      experienceLevel: 4,
      id: '518098',
      tag: 'HTML',
    },
    {
      experienceLevel: 2,
      id: '518100',
      tag: 'CoffeeScript',
    },
    {
      experienceLevel: 2,
      id: '518101',
      tag: 'JavaScript',
    },
  ],
  cvs: [
    {
      id: 60372,
      cvContentType: 'application/pdf',
      cvFileName: 'Invoice_475398493_(1).pdf',
      cvFileSize: 93399,
      standard: true,
      personId: 137656,
      createdAt: '2020-07-06T14:00:57.661Z',
      updatedAt: '2020-07-06T14:00:57.661Z',
    },
  ],
  skypeId: 'lopesdasilva33',
  url: '/profile/edit',
  errors: {},
  saved: false,
};

export default profileDummy;
