import React, { useEffect, useRef, useState } from 'react';
import Checkbox from 'components/form/checkbox/checkbox';
import Icon from 'components/general/icon/icon';
import Modal, { ButtonsLayout } from '../modal/modal';
import { Job } from '__models__/gql/job';
import EmailPreview from '../email_preview/email_preview';
import { apolloClient, gql } from 'lib/graphql';
import RichText from 'components/form/rich_text/rich_text';
import Select from 'components/form/select/select';
import Spinner from 'shared/dot_spinner/DotSpinner';
import Tooltip from 'components/general/tooltip/tooltip';
import { objToSnakeCase } from 'lib/object';

const styles = require('./engagementInitiatorModal.module.scss');

interface Props {
  job: Pick<Job, 'id' | 'title' | 'company'>;
  suggestionIdStorage: any;
  handshakeIdStorage: any;
  classNames: string;
}

const GQL_QUERY_TALENT = gql`
  query ($ids: [ID!]!) {
    backoffice {
      people(ids: $ids) {
        id
        name
      }
    }
  }
`;

const GQL_MUTATION_SUGGEST_JOB = gql`
  mutation (
    $jobAdId: ID!
    $candidateIds: [ID!]!
    $message: String
    $sendEmail: Boolean!
    $useDefaultTemplate: Boolean!
  ) {
    application {
      suggestJob(
        jobAdId: $jobAdId
        candidateIds: $candidateIds
        message: $message
        sendEmail: $sendEmail
        useDefaultTemplate: $useDefaultTemplate
      ) {
        errors {
          field
          message
        }
      }
    }
  }
`;

const GQL_MUTATION_HANDSHAKE_CANDIDATES = gql`
  mutation ($jobAdId: ID!, $candidateIds: [ID!]!) {
    backoffice {
      handshakeCandidates(jobAdId: $jobAdId, candidateIds: $candidateIds) {
        errors {
          field
          message
        }
      }
    }
  }
`;

export default function EngagementInitiatorModal(props: Props) {
  const { job, suggestionIdStorage, handshakeIdStorage, classNames } = props;

  const [suggestionCandidates, setSuggestionCandidates] = useState(null);
  const [handshakeCandidates, setHandshakeCandidates] = useState(null);
  const [suggestionErrors, setSuggestionErrors] = useState([]);
  const [handshakeErrors, setHandshakeErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [suggestionSubmitted, setSuggestionSubmitted] = useState(false);
  const [handshakeSubmitted, setHandshakeSubmitted] = useState(false);
  const [message, setMessage] = useState(null);
  const [sendEmail, setSendEmail] = useState(true);
  const [showEmailSettings, setShowEmailSettings] = useState(true);
  const [useDefaultTemplate, setUseDefaultTemplate] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const suggestionCandidatesRef = useRef(null);
  const handshakeCandidatesRef = useRef(null);
  const modalRef = useRef(null);
  const afterSubmitModalRef = useRef(null);
  const jobPlaceholder = '{{job}}';

  useEffect(() => {
    if (suggestionSubmitted && handshakeSubmitted) {
      const hasSuggestionErrors = suggestionErrors?.length > 0;
      const hasHandshakeErrors = handshakeErrors?.length > 0;

      modalRef.current.close();

      if (hasHandshakeErrors || hasSuggestionErrors) {
        window.toggleSpinner(false);
        afterSubmitModalRef.current.open();
      } else {
        let successMessage = '';
        if (hasSelectedCandidates(suggestionCandidatesRef)) {
          successMessage += 'Suggestions sent!  ';
        }
        if (hasSelectedCandidates(handshakeCandidatesRef)) {
          successMessage += `Handshakes sent!`;
        }
        if (successMessage.length > 0) {
          window.Alerts.notice(successMessage);
        }
      }
    }
  });

  const getCandidates = () => {
    setLoading(true);

    const suggestionPersonIds = suggestionIdStorage.getPersonIds();
    const handshakePersonIds = handshakeIdStorage.getPersonIds();
    const variables = {
      ids: suggestionPersonIds.concat(handshakePersonIds),
    };

    if (suggestionPersonIds.length === 0 && handshakePersonIds.length === 0) {
      setSuggestionCandidates([]);
      setHandshakeCandidates([]);
      setLoading(false);
    } else {
      apolloClient
        .query({ query: GQL_QUERY_TALENT, variables })
        .then(({ data }) => {
          const people = data.backoffice.people;
          const newSuggestionCandidates = people.filter(
            person => suggestionPersonIds?.includes(person.id)
          );
          const newHandshakeCandidates = people.filter(
            person => handshakePersonIds?.includes(person.id)
          );

          setSuggestionCandidates(newSuggestionCandidates);
          setHandshakeCandidates(newHandshakeCandidates);
          setLoading(false);
        })
        .catch(showErrorMessage);
    }
  };

  const handleClick = event => {
    event.preventDefault();
    setIsModalOpen(true);
    getCandidates();
  };

  const handleSubmit = () => {
    setSubmitting(true);

    if (validate()) {
      hasSelectedCandidates(handshakeCandidatesRef)
        ? handleHandshakeSend()
        : setHandshakeSubmitted(true);
      hasSelectedCandidates(suggestionCandidatesRef)
        ? handleJobSuggest()
        : setSuggestionSubmitted(true);
    }

    setSubmitting(false);
  };

  const handleHandshakeSend = () => {
    apolloClient
      .mutate({ mutation: GQL_MUTATION_HANDSHAKE_CANDIDATES, variables: serializeHandshakesForm() })
      .then(({ data }) => {
        const result = data.backoffice.handshakeCandidates;
        if (result.errors.length > 0) {
          const errors = result.errors.map(e => e.message);
          setHandshakeErrors(errors);
        }

        setHandshakeSubmitted(true);
      })
      .catch(showErrorMessage);
  };

  const handleJobSuggest = () => {
    const variables = serializeSuggestionsForm();

    apolloClient
      .mutate({ mutation: GQL_MUTATION_SUGGEST_JOB, variables })
      .then(({ data }) => {
        const result = data.application.suggestJob;
        if (result.errors.length > 0) {
          const errors = result.errors.map(e => e.message);
          setSuggestionErrors(errors);
        }

        setSuggestionSubmitted(true);
      })
      .catch(showErrorMessage);
  };

  const getSelectedCandidates = ref => ref?.current?.getSelectedOption?.() || [];
  const hasSelectedCandidates = ref => getSelectedCandidates(ref)?.length > 0;

  const handleSendEmailChange = (_event, _name, value) => {
    setShowEmailSettings(value);
    setSendEmail(value);
  };
  const handleMessageChange = data => data instanceof Object && setMessage(JSON.stringify(data));
  const handleUseDefaultTemplateChange = (_event, _name, value) => {
    setMessage(null);
    setUseDefaultTemplate(value);
  };

  const showErrorMessage = () => {
    window.Alerts.alert('Oops, something went wrong!');
    window.location = window.Routes.backoffice_job_ad_person_suggestions(job.id);
  };

  const validate = () => {
    const errors = [];

    if (
      hasSelectedCandidates(suggestionCandidatesRef) &&
      !suggestionSubmitted &&
      !useDefaultTemplate &&
      !message?.includes(jobPlaceholder)
    ) {
      errors.push(`Did you forget to add a message or a ${jobPlaceholder} placeholder?`);
    }

    setSuggestionErrors(errors);

    return (
      (hasSelectedCandidates(suggestionCandidatesRef) ||
        hasSelectedCandidates(handshakeCandidatesRef)) &&
      errors.length === 0
    );
  };

  const disableSubmitButton =
    !validate || submitting || (suggestionSubmitted && handshakeSubmitted);

  const renderErrors = items => {
    return (
      !submitting &&
      items.map(item => {
        return (
          <div className={styles.errorItem} key={item.split().join('-')}>
            {item}
          </div>
        );
      })
    );
  };

  const renderLoading = () => <Spinner />;

  const reload = () => {
    window.toggleSpinner(true);

    const currentPage = document.querySelector('.page.current').innerHTML;
    $('#engagement_form').append(`<input type='hidden' name='page' value='${currentPage}'/>`);

    handshakeIdStorage.clear();
    suggestionIdStorage.clear();
    $('#people_search_submit').trigger('submit');
  };

  const renderNoBookmarkedCandidates = () => {
    return <div>Oops..You have no selected candidates</div>;
  };

  const renderBookmarkedCandidates = engamentType => {
    const isSuggestion = engamentType === 'suggestion';
    const candidates = isSuggestion ? suggestionCandidates : handshakeCandidates;

    return (
      <>
        {candidates?.length ? (
          <Select
            defaultValue={candidates}
            isMulti
            options={candidates}
            getOptionLabel={c => c.name}
            getOptionValue={c => c.id}
            ref={isSuggestion ? suggestionCandidatesRef : handshakeCandidatesRef}
            color={'tuftsBlue'}
          />
        ) : (
          renderNoBookmarkedCandidates()
        )}
      </>
    );
  };

  const serializeSuggestionsForm = () => {
    const candidates = getSelectedCandidates(suggestionCandidatesRef);

    return {
      jobAdId: job.id,
      candidateIds: candidates.map(candidate => candidate.id),
      message,
      useDefaultTemplate,
      sendEmail,
    };
  };

  const serializeHandshakesForm = () => {
    const candidates = getSelectedCandidates(handshakeCandidatesRef);

    return {
      jobAdId: job.id,
      candidateIds: candidates.map(candidate => candidate.id),
    };
  };

  const serializePreviewForm = () => objToSnakeCase(serializeSuggestionsForm());

  const renderTemplateCheckbox = () => {
    return (
      <>
        <Checkbox
          className={styles.useDefaultTemplate}
          checked={useDefaultTemplate}
          controlled={true}
          id='suggestJobModal-useDefaultTemplate'
          label='Use default template'
          onChange={handleUseDefaultTemplateChange}
          color='puertoRicoMidDark'
        />
        <Tooltip className={styles.tooltip} text={renderTooltipText()}>
          <Icon color='tuftsBlue' name='helpCircle' />
        </Tooltip>
      </>
    );
  };

  const renderSendEmailCheckbox = () => {
    return (
      <div className={styles.sendEmail}>
        <Checkbox
          checked={sendEmail}
          controlled={true}
          id='suggestJobModal-sendEmail'
          label='Send Email'
          onChange={handleSendEmailChange}
          color='puertoRicoMidDark'
        />
        <Tooltip
          className={styles.tooltip}
          text={<div>Uncheck if you don't want to send an email to the candidate.</div>}
        >
          <Icon color='tuftsBlue' name='helpCircle' />
        </Tooltip>
      </div>
    );
  };

  const renderTooltipText = () => {
    return (
      <div>
        Uncheck if you want to write your own message instead of using the default template.
        <br />
        <br />
        You can use {jobPlaceholder} as a placeholder for the link to the job.
      </div>
    );
  };

  const renderMessageEditor = () => {
    if (!useDefaultTemplate) {
      return (
        <div className={styles.emailSettingsContainer}>
          <strong>Write your message below.</strong>
          <br />
          <span className={styles.messageNote}>
            <strong>Note:</strong> Use <code>{jobPlaceholder}</code> to include the job to suggest
            wherever you like. You can check the preview to see how it looks like.
          </span>
          <RichText className={styles.message} onChange={handleMessageChange} />
        </div>
      );
    }
  };

  return (
    <>
      <a className={classNames} onClick={handleClick}>
        Engage selected talent
      </a>
      <Modal
        title='Review Selected Talent'
        id='engagementInitiator'
        ref={modalRef}
        buttonsLayout={ButtonsLayout.OkCancel}
        buttonName={submitting ? 'Sending...' : 'Send'}
        buttonColor='puertoRicoMidDark'
        buttonOnClick={handleSubmit}
        isButton={true}
        disableButton={disableSubmitButton}
        cancelButton={true}
        disableOutsideClose={true}
        defaultOpen={false}
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          suggestionSubmitted && handshakeSubmitted && reload();
        }}
      >
        <div className={styles.container}>
          <label className={styles.title}>Position:</label>
          <span>{job.title}</span>
          <br />
          <label className={styles.title}>Company:</label>
          <span>{job.company as unknown as string}</span>
        </div>
        <div className={styles.hr} />
        <div className={styles.container}>
          <label className={styles.title}>Candidates to be handshaked</label>
          <div className={styles.badgePanel}>
            {loading ? renderLoading() : renderBookmarkedCandidates('handshake')}
          </div>
        </div>
        <div className={styles.hr} />
        <div className={styles.container}>
          <label className={styles.title}>Candidates to be suggested</label>
          <div className={styles.badgePanel}>
            {loading ? renderLoading() : renderBookmarkedCandidates('suggestion')}
          </div>
          {!suggestionSubmitted && renderErrors(suggestionErrors)}
        </div>
        {suggestionCandidates?.length > 0 && (
          <div className={styles.container}>
            <label className={styles.title}>Email Settings</label>
            <br />
            {renderSendEmailCheckbox()}
            {showEmailSettings && (
              <>
                {renderTemplateCheckbox()}
                {renderMessageEditor()}
                <div className={styles.emailPreviewContainer}>
                  <EmailPreview
                    hide='.js-rejectionFormContent'
                    form={{ serialize: serializePreviewForm }}
                    url={window.Routes.backoffice_person_suggestions_preview()}
                    isBackoffice={true}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </Modal>
      <Modal
        title='Talent Engagement Report'
        defaultOpen={false}
        disableOutsideClose={true}
        buttonsLayout={ButtonsLayout.OkOnly}
        onClose={() => afterSubmitModalRef.current.close()}
        ref={afterSubmitModalRef}
        buttonName='OK'
        buttonColor='tuftsBlue'
        buttonOnClick={() => afterSubmitModalRef.current.close()}
      >
        <p>Requests have been sent successfully, with some exceptions listed below.</p>
        {handshakeErrors.length > 0 && (
          <div className={styles.reportContainer}>
            <h4 className={styles.titleSmall}>Handshake failures</h4>
            {renderErrors(handshakeErrors)}
          </div>
        )}
        {suggestionErrors.length > 0 && (
          <div className={styles.reportContainer}>
            <h4 className={styles.titleSmall}>Suggestion failures</h4>
            {renderErrors(suggestionErrors)}
          </div>
        )}
      </Modal>
    </>
  );
}
