import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Select from '../../form/select/select';
import TextInput from '../../form/text/text';
import FormInput from 'components/general/form_input/form_input';
import { citizenships } from '../../registration/registration_utils/registration_utils';
import { multiValueStyles } from 'lib/select_styles';
import LabelWithStarIcon from '../LabelWithStarIcon';
import ContactPreference from '../contactPreference/contact_preference';
import Button from 'lj_shared/button/button';
import ProfileLanguages from './ProfileLanguages';
import ProfileForm from 'components/registration/profile_form';

const styles = require('./AboutYourselfModal.module.scss');

export default function AboutYourselfModal(props) {
  function renderBirthAndPhone() {
    return (
      <div>
        <div className={styles.phoneNumber}>
          <FormInput
            name='phoneNumber'
            label={
              <LabelWithStarIcon
                label='Phone number'
                value={props.profileForm.phoneNumber?.length > 1}
              />
            }
            error={props.profileForm.errors.phoneNumber}
          >
            <PhoneInput
              country={'pt'}
              value={props.profileForm.phoneNumber}
              onChange={event => props.handleFormChange(event, 'phoneNumber')}
              inputClass={styles.phoneInput}
            />
          </FormInput>
        </div>
      </div>
    );
  }

  function renderCitizenships() {
    return (
      <div>
        <FormInput
          name='citizenships'
          label={
            <LabelWithStarIcon
              label='Citizenship(s)'
              value={props.profileForm.citizenships?.length > 0}
            />
          }
          error={props.profileForm.errors.citizenships}
          required
        >
          <Select
            name='citizenships'
            placeholder='Please select your citizenship(s)...'
            options={citizenships(props.citizenshipsList).slice(1)}
            color='silverSand'
            styles={multiValueStyles('silverSand')}
            isMulti={true}
            value={props.profileForm.citizenships}
            onChange={event => props.handleFormChange(event, 'citizenships')}
          />
        </FormInput>
      </div>
    );
  }

  function renderSkypeAndEmail() {
    return (
      <div>
        <div className={styles.label}>Skype ID</div>
        <div className={styles.skypeID}>
          <TextInput
            name='skype_id'
            placeholder='e.g. astroboy123'
            color='silverSand'
            id='skype_id'
            value={props.profileForm.skypeId}
            onChange={event => props.handleFormChange(event, 'skypeId')}
            class={styles.textInput}
          />
        </div>
      </div>
    );
  }

  function renderContactPreference() {
    return (
      <div>
        <FormInput
          name='contactPreference'
          label={'Contact Preference'}
          error={props.profileForm.errors.contactPreference}
          required
        >
          <ContactPreference
            contactPreference={{
              id: props.profileForm.contactPreference,
              other: props.profileForm.contactPreferenceOther,
            }}
            formSettings={{
              select: { name: 'contactPreference' },
              text: { name: 'contactPreferenceOther' },
            }}
            handleChangeContactPreference={event =>
              props.handleFormChange(event, 'contactPreference')
            }
          />
        </FormInput>
      </div>
    );
  }

  return (
    <div className={styles.modalContainer}>
      <form>
        {renderBirthAndPhone()}
        <ProfileLanguages
          profileForm={props.profileForm}
          languages={props.languages}
          proficiencyLevels={props.proficiencyLevels}
          setProfileForm={props.setProfileForm}
          setProfileInfo={props.setProfileInfo}
        />
        {renderCitizenships()}
        {renderSkypeAndEmail()}
        {renderContactPreference()}
        <div className={styles.submitButton}>
          <Button buttonColor='tuftsBlue' onClick={event => props.handleSubmit(event)}>
            UPDATE
          </Button>
        </div>
      </form>
    </div>
  );
}
