import React, { useState } from 'react';

import { Application, Person, TestResult } from '../../../__models__/models';
import './application_tags.scss';
import Icon from '../icon/icon';
import ApplicationTag from './applicationTag/ApplicationTag';
import classNames from 'classnames';
import MultipleProcessesModal from 'components/application_page/multiple_processes_modal';
import { isMobile } from 'react-device-detect';
const interviewsImg = require('images/interviews.svg');

export interface Props {
  application?: Application;
  applicationTags: Application['attributes']['application_tags'];
  className?: string;
  handshake?: any;
  jobTitle?: string;
  personTags: Person['attributes']['person_tags'];
  recipientName?: string;
  testNotSent: boolean;
  testResults: TestResult[];
}

const ApplicationTags = (props: Props) => {
  const [multipleProcessesModal, showMultipleProcessesModal] = useState(false);
  const { multiple_processes } = props.personTags;

  const {
    candidate_search,
    days_to_expiration,
    expiration,
    labels,
    suggested_by_company,
    visa_eligible,
  } = props.applicationTags;

  const companyUserText = `You thought this profile looked promising for
                          ${props.jobTitle} and sent a handshake request on ${props.handshake?.created}.
                          ${props.recipientName} accepted on ${props.handshake?.accepted}.`;

  const handleMultipleProcessModal = event => {
    event.preventDefault();
    showMultipleProcessesModal(true);
  };

  function multipleProcessModal() {
    return (
      multipleProcessesModal && (
        <MultipleProcessesModal
          application={props.application}
          onClose={() => showMultipleProcessesModal(false)}
        />
      )
    );
  }

  if (isEmpty(props.personTags) && isEmpty(props.applicationTags) && isEmpty(props.testResults)) {
    return (
      <div className={classNames('lj-card-tags', props.className)}>
        <div className='lj-tag-wrapper'>
          <Icon name='tag' color='black' />
          <span className='tag-icon-no-text'>No Tags</span>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames('lj-card-tags', props.className)}>
      {multipleProcessModal()}
      <div className='lj-tag-wrapper'>
        <Icon name='tag' color='black' />
        <span className='tag-icon-text'>More info:</span>
      </div>
      {candidate_search && (
        <ApplicationTag
          backgroundColor='ripePlumMidDark'
          iconName='handshake'
          title={companyUserText}
        />
      )}
      {multiple_processes &&
        (isMobile ? (
          <ApplicationTag
            backgroundColor='tuftsBlueMidLight'
            iconName='document'
            title={'In multiple Processes'}
          />
        ) : (
          <a className='modalLink' onClick={handleMultipleProcessModal}>
            <ApplicationTag
              backgroundColor='tuftsBlueMidLight'
              iconName='document'
              title={'In multiple Processes' + '\n' + 'Click to know more'}
            />
          </a>
        ))}
      {labels &&
        labels.map((label, index) => {
          if (label.visible_to_companies) {
            if (label.name === 'Interviewed by Landing.Jobs') {
              const title =
                "Landing.Jobs' team has interviewed this candidate. " +
                "You can see the screening interview report by downloading the candidate's CV.";
              return (
                <ApplicationTag
                  key={index}
                  backgroundColor='puertoRicoMidDark'
                  iconName='interviews'
                  image={
                    <img
                      alt='interviews'
                      style={{ width: '90%', margin: '20% 0' }}
                      src={interviewsImg}
                      title='interviews'
                    />
                  }
                  title={title}
                />
              );
            } else {
              return (
                <ApplicationTag
                  key={index}
                  backgroundColor='puertoRico'
                  iconName='tag'
                  title={label.name}
                />
              );
            }
          }
        })}
      {expiration && (
        <ApplicationTag
          backgroundColor='flamingoMidDark'
          iconName='warning'
          title={`Expiring in ${Math.max(0, days_to_expiration)} days`}
        />
      )}
      {suggested_by_company && (
        <ApplicationTag
          backgroundColor='ripePlumMidLight'
          iconName='briefcase'
          title='Suggested by company'
        />
      )}
      {visa_eligible === 'Eligible for Visa' && (
        <ApplicationTag
          backgroundColor='tuftsBlueMidLight'
          iconName='checkCircle'
          title={'Eligible for Visa'}
        />
      )}
      {visa_eligible === 'Ineligible for Visa' && (
        <ApplicationTag backgroundColor='flamingo' iconName='ban' title={'Ineligible for Visa'} />
      )}
      {visa_eligible === 'Needs review for Visa' && (
        <ApplicationTag
          backgroundColor='jaffaMidDark'
          iconName='magnify'
          title={'Needs review for Visa'}
        />
      )}
      {props.testNotSent && (
        <ApplicationTag backgroundColor='flamingo' iconName='warning' title={'Test not sent'} />
      )}
      {props.testResults &&
        props.testResults.map(({ id, score, completed }) => {
          if (!completed) {
            return (
              <ApplicationTag
                key={id}
                backgroundColor='jaffaMidDark'
                iconName='sent'
                title='Technical Test Sent'
              />
            );
          } else {
            return (
              <ApplicationTag
                key={id}
                backgroundColor='tuftsBlueMidLight'
                iconName='check'
                title={`Technical Test Completed (${score}%)`}
              />
            );
          }
        })}
    </div>
  );
};

const isEmpty = (object: Object) => {
  if (object) {
    return Object['values'](object).every(
      x => x === null || x === '' || x === false || x.length === 0
    );
  }
};

export default ApplicationTags;
