export function pluralize(count, singular, plural?) {
  plural = plural || singular + 's';
  return `${count} ${count === 1 ? singular : plural}`;
}

export function toQueryString(obj) {
  const parts = [];
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
    }
  }
  return parts.join('&');
}

export const truncate = (text: string, length: number): string =>
  text.length <= length ? text : text.substring(0, length - 3) + '...';

export const unTitleize = (text: string): string =>
  `${text.charAt(0).toLowerCase()}${text.slice(1)}`;

export const titleize = (text: string): string => `${text.charAt(0).toUpperCase()}${text.slice(1)}`;

export function camelize(str: string): string {
  const [word, ...words] = str.split('_');
  const camelizedWords = words.map(titleize).join('');

  return `${word}${camelizedWords}`;
}

export const snakeCase = (str: string): string => {
  if (str.includes(' ')) {
    return str.split(' ').map(unTitleize).join('_');
  }
  return str.split('').reduce((accumulator, char, index) => {
    if (index === 0) {
      return char.toLowerCase();
    } else if (char >= 'A' && char <= 'Z') {
      return `${accumulator}_${char.toLowerCase()}`;
    }
    return `${accumulator}${char}`;
  }, '');
};

export const titleCamel = (str: string): string => {
  return str.split('').reduce((accumulator, char, index) => {
    if (index === 0) {
      return char.toUpperCase();
    } else if (char >= 'A' && char <= 'Z') {
      return `${accumulator} ${char.toUpperCase()}`;
    }
    return `${accumulator}${char}`;
  }, '');
};

export const titleCase = (str: string): string => str.split('_').map(titleize).join(' ');

export const shuffle = (str: string) => {
  return str
    .split('')
    .sort(() => {
      return 0.5 - Math.random();
    })
    .join('');
};

export const kebabCase = (str: string): string => {
  return snakeCase(str).split('_').join('-');
};
