import * as React from 'react';
import CurationPoolPage from './curation_pool_page';
import { shallow, ShallowWrapper } from 'enzyme';
import curationPoolDummy from '__fixtures__/curation_pool_dummy';

let wrapped: ShallowWrapper;

describe('Curation Pool Page component', () => {
  beforeEach(() => {
    wrapped = shallow(<CurationPoolPage curation_pools={[curationPoolDummy]} new_url='' />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
