import SignUpModal from 'components/job_search/sign_up_modal';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import BackToTop from 'shared/backToTop/BackToTop';
import DotSpinner from 'shared/dot_spinner/DotSpinner';
import { User } from '__models__/gql/user';
import JobList from './JobList';
import { JobResult } from './types';
import useFetchJobs from './useFetchJobs';

type JsonBookmark = [number, number];
export interface JobListInfiniteProps {
  page: number;
  lastPage: boolean;
  applications: number[];
  bookmarks: JsonBookmark[];
  handshakes?: { [key: number]: number };
  jobResults: JobResult[];
  user?: Partial<User>;
  profilePath?: string;
  jobRequestPath: string;
  urlParams?: string;
}

const JobListInfinite = (props: JobListInfiniteProps) => {
  const { lastPage, jobRequestPath, urlParams, user } = props;
  const [jobSearchResult, setJobSearchResult] = useState<JobListInfiniteProps>(props);
  const [page, setPage] = useState(props.page);
  const [showModal, setShowModal] = useState(false);
  const query = `${jobRequestPath}.json${urlParams}`.replace(
    /\bpage=[0-9a-zA-Z_@.#+-]{1,50}\b/,
    'page=' + page
  );
  const { loading, error, sendQuery } = useFetchJobs(query, page, setJobSearchResult);

  const observer = new IntersectionObserver(entries => {
    if (entries[0].isIntersecting && !lastPage) {
      setPage(prev => prev + 1);
    }
  });

  const observerRef = useRef(observer);

  const observerRefCall = useCallback(node => {
    if (node) {
      observerRef.current?.observe(node);
    }
  }, []);

  useEffect(() => {
    if (user.type === 'NilClass' && page > 2) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

      setShowModal(true);
      return;
    } else {
      if (page > 1) {
        sendQuery();
      }
    }
  }, [page]);

  return (
    <>
      {showModal && (
        <SignUpModal
          context='more'
          sign_up_path={window.Routes.join()}
          login_path={window.Routes.user_session()}
          onClose={() => setShowModal(false)}
        />
      )}
      <JobList
        applications={jobSearchResult.applications}
        bookmarks={jobSearchResult.bookmarks}
        handshakes={jobSearchResult.handshakes}
        jsonJobs={jobSearchResult.jobResults}
        user={jobSearchResult.user}
      />
      <BackToTop target={document.querySelector('.job-search-mobile-sign-up-button')} />
      {loading && <DotSpinner />}
      {error && <p>Error!</p>}
      <div ref={observerRefCall} />
    </>
  );
};

export default JobListInfinite;
