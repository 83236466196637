// Wizard / Buttons
//
// Buttons that appear on the bottom of the wizard

import * as React from 'react';
import Button from 'lj_shared/button/button';
import InlineIcon from '../../../general/inline_icon/inline_icon';
import classnames from 'classnames';
const chevronPath = require('svg/thin-chevron.svg');
const styles = require('./wizard_buttons.module.scss');

interface Props {
  nextUrl?: string;
  previousUrl?: string;
}

export default class WizardButtons extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    if (this.props.previousUrl) {
      return moveButtons(this.props);
    } else {
      return submitButton(this.props);
    }
  }

  componentDidMount() {
    if (this.props.previousUrl) {
      const nextButtons = document.querySelectorAll('.sidebar-next-button');
      const formNextButton: HTMLElement = document.querySelector('.next-button');

      for (const button of nextButtons as any) {
        button.addEventListener('click', () => {
          formNextButton.click();
        });
      }
    } else {
      (document.querySelector('#submit-approval') as HTMLElement).onclick = ev => {
        const terms: any = document.getElementById('terms');
        if (terms.checked) {
          $('.terms .ld-alert').hide();
          (document.querySelector('.new_job_ad_form') as HTMLFormElement).submit();
        } else {
          ev.preventDefault();
          $('.terms .ld-alert').show();
        }
      };
    }
  }
}

function submitButton(props) {
  return (
    <div className={styles.buttons}>
      <Button
        otherId='submit-approval'
        otherClasses='submit-button'
        buttonColor='ripePlum'
        buttonSize='large'
      >
        <span style={{ color: 'white' }}>Submit for Approval</span>
      </Button>
    </div>
  );
}

function moveButtons(props) {
  return (
    <div className={styles.buttons}>
      <Button
        buttonUrl={props.previousUrl}
        otherClasses={classnames(styles.previousButton, 'previous-button')}
        buttonColor='silverSand'
        buttonType='border'
      >
        <InlineIcon path={chevronPath} alt='previous-step' />
        Previous
      </Button>
      <Button
        otherClasses={classnames(styles.nextButton, 'next-button')}
        buttonColor='ripePlum'
        isButton={true}
      >
        Next
        <InlineIcon path={chevronPath} alt='next-step' />
      </Button>
    </div>
  );
}
