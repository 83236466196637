// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<AlertBox /> renders without crashing 1`] = `
<div
  className="alertBox"
>
  <div
    className="banner"
  >
    <div
      className="centeredBox"
    >
      <img
        className="iconic iconic-md iconic-bullhorn iconic"
        src=""
      />
    </div>
  </div>
  <div
    className="body"
  >
    <p>
      Hey!
    </p>
  </div>
</div>
`;
