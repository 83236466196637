import * as React from 'react';
require('./dot_loader.scss');

interface Props {
  style?: any;
}

export default class DotLoader extends React.Component<Props> {
  render() {
    return (
      <div className='greySpinner' style={this.props.style}>
        <div className='ld-loading-animation'>
          <span className='ld-dotcon'>
            <div className='ld-dot' />
          </span>
          <span className='ld-dotcon'>
            <div className='ld-dot' />
          </span>
          <span className='ld-dotcon'>
            <div className='ld-dot' />
          </span>
        </div>
      </div>
    );
  }
}
