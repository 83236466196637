import React from 'react';
import FormInput from 'components/general/form_input/form_input';
import CandidateSkillsSection from 'components/general/skills_input/candidate_skills_section';
const styles = require('./SkillsInput.module.scss');

interface Props {
  error: string;
  skills: { id: number; name: string; experience_label: string }[];
  proposedTags: { id: number; tag: string }[];
  updateSkills: Function;
  maximumNumberOfSkills: number;
}

export default function SkillsInput(props: Props) {
  return (
    <FormInput
      name='skills'
      label='Add your most important skills'
      sublabel='Please add at least 2 skills.'
      error={props.error}
      required={true}
    >
      <div className={styles.skills}>
        <CandidateSkillsSection
          skills={props.skills}
          proposedTags={props.proposedTags}
          errors={[]}
          updateSkills={props.updateSkills}
          maximumNumberOfSkills={props.maximumNumberOfSkills}
          readOnly={false}
          renderSignupVersion={false}
          hideText={true}
          color='silverSand'
        />
      </div>
    </FormInput>
  );
}
