// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`JobTypeButtons should render correctly 1`] = `
<div
  className="jobTypeContainer"
  data-testid="job-type-buttons"
>
  <div
    className="jobTypeButtons"
  >
    <div
      className="jobTypeButton"
      onClick={[Function]}
    >
      <div>
        <span
          className="wrapper color-silverSand size-bigHero size-bigHero style-normal"
          dangerouslySetInnerHTML={
            Object {
              "__html": "",
            }
          }
        />
      </div>
      <span>
        Start from scratch
      </span>
    </div>
    <div
      className="jobTypeButton"
      onClick={[Function]}
    >
      <div>
        <span
          className="wrapper color-silverSand size-hero size-hero style-normal"
          dangerouslySetInnerHTML={
            Object {
              "__html": "",
            }
          }
        />
      </div>
      <span>
        Duplicate job post
      </span>
    </div>
  </div>
</div>
`;
