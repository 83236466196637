import classNames from 'classnames';
import React from 'react';

const styles = require('./companyPages/Main.module.scss');

interface Props {
  children?: any;
  header?: JSX.Element;
  title: string;
}

export default function CompanySection(props: Props) {
  return (
    <section className={styles.section}>
      <div className={styles.sectionHeader}>
        <h2 className={styles.h2}>{props.title}</h2>
        {props.header}
      </div>
      <div className={classNames(styles.sectionContent, { jobs: !props.title.includes('jobs') })}>
        {props.children}
      </div>
    </section>
  );
}
