import * as React from 'react';
import { isString } from 'lodash';
import DotLoader from 'lj_shared/dot_loader/dot_loader';
import 'lib/globals';
import Accordion from 'components/general/accordion/accordion';

export interface Props {
  hide: string; // Selector of element to hide, when email preview is expanded
  form: any; // Selector of form to submit for email preview
  title?: string; // By default 'Email preview'
  url: string; // Url to where the form should be posted
  isBackoffice?: boolean;
}

interface State {
  content: string;
  loading: boolean;
}

export default class EmailPreview extends React.Component<Props, State> {
  root: any;

  constructor(props) {
    super(props);

    this.state = { content: '', loading: true };
    this.refresh = this.refresh.bind(this);
    this.scrollDown = this.scrollDown.bind(this);
    this.needsRefresh = this.needsRefresh.bind(this);
  }

  needsRefresh() {
    return this.state.content === '' || this.state.loading;
  }

  scrollDown() {
    const modalContent = $('[class^="modal-module_content"]').first();
    modalContent.scrollTop(modalContent.innerHeight());
  }

  refresh() {
    if (this.needsRefresh()) {
      this.setState(
        {
          content: '',
          loading: true,
        },
        () => {
          this.scrollDown();
        }
      );

      const form = isString(this.props.form)
        ? $(this.props.form).serialize()
        : this.props.form.serialize();

      $.post(this.props.url, form).done(data => {
        this.setState(
          {
            content: data,
            loading: false,
          },
          () => {
            this.scrollDown();
          }
        );
      });
    } else {
      this.setState(
        {
          content: '',
          loading: false,
        },
        () => {
          this.scrollDown();
        }
      );
    }
  }

  render() {
    return (
      <Accordion
        title='Email Preview'
        className='modal'
        style={{ margin: '0 -1.25rem' }}
        onClick={this.refresh}
        headerClassName={this.props.isBackoffice && 'backofficeEmailPreview'}
      >
        {this.needsRefresh() ? (
          <div style={{ position: 'relative' }}>
            <DotLoader />
          </div>
        ) : (
          <blockquote dangerouslySetInnerHTML={{ __html: this.state.content }} />
        )}
      </Accordion>
    );
  }
}
