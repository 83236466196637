import classNames from 'classnames';
import React from 'react';
const styles = require('./Badge.module.scss');

export type Color = 'black' | 'blue' | 'green' | 'red' | 'flamingo' | 'tuftsBlue';

interface Props {
  className?: string;
  children: React.ReactNode;
  color?: Color;
  inverted?: boolean;
  invertedFill?: boolean;
}

export default function Badge(props: Props) {
  let color = props.color || 'black';
  if (props.inverted) {
    color += 'Inverted';
  } else if (props.invertedFill) {
    color += 'InvertedFill';
  }

  const additionalClasses = props.className || '';
  const className = classNames(styles.badge, styles[color], additionalClasses);
  return <span className={className}>{props.children}</span>;
}
