import * as React from 'react';
import ApplicationSteps from './application_steps';
import ApplicationStateButtons from './application_state_buttons';
import { HiringStep, Application } from '../../__models__/models';

export interface Props {
  hiringSteps: HiringStep[];
  backButton: any;
  currentState: string;
  urls: any;
  moveToStep: any;
  rejectApplication: any;
  delivered: boolean;
  stepLabel: string;
  waitingReview: boolean;
  application: Application;
}

export default function ApplicationHeader(props: Props) {
  return (
    <div className='lj-application-header'>
      <div className='lj-header-left lj-application-col-head' />
      <div className='lj-header-right lj-application-col-head'>
        <div className='lj-header-step'>
          <ApplicationSteps
            hiringSteps={props.hiringSteps}
            currentStep={props.currentState}
            delivered={props.delivered}
            moveToStep={props.moveToStep}
            application={props.application}
            showLabel={false}
            urls={props.urls}
            stepLabel={props.stepLabel}
          />
        </div>
        <div className='lj-header-buttons'>
          <ApplicationStateButtons
            delivered={props.delivered}
            moveToStep={props.moveToStep}
            application={props.application}
            rejectApplication={props.rejectApplication}
            small={true}
            waitingReview={props.waitingReview}
          />
        </div>
      </div>
    </div>
  );
}
