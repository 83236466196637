import React from 'react';
import { languageOptions, proficiencyLevels } from './add_language_utils/add_language_utils';
import Select from 'components/form/select/select';
import Button from 'lj_shared/button/button';

const styles = require('./add_language.module.scss');

interface Props {
  languages: { text: string; id: string }[];
  proficiencyLevels: { [Identifier: number]: string };
  handleAddLanguage: (language: {
    language: { label: string; value: string };
    proficiency: { label: string; value: string };
  }) => void;
  id?: string;
  mandatory?: boolean;
}

interface State {
  language: { label: string; value: string };
  proficiency: { label: string; value: string };
}

class AddLanguage extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      language: { label: '', value: '' },
      proficiency: { label: '', value: '' },
    };
  }

  handleSubmit(event) {
    const { language, proficiency } = this.state;

    const languagePresent = language.value === '';
    const proficiencyPresent = proficiency.value === '';

    if (event.type === 'keydown') {
      if (languagePresent || proficiencyPresent) {
        return;
      } else {
        if (event.keyCode !== 13) {
          return;
        }
      }
    }

    if (!languagePresent && !proficiencyPresent) {
      this.props.handleAddLanguage({ language, proficiency });

      this.setState(() => ({
        language: { label: '', value: '' },
        proficiency: { label: '', value: '' },
      }));
    } else {
      let errorMessage = '';
      if (languagePresent && !proficiencyPresent) {
        errorMessage = 'language';
      } else if (proficiencyPresent && !languagePresent) {
        errorMessage = 'proficiency';
      } else {
        errorMessage = 'language and a proficiency';
      }

      window.Alerts.alert(`Please add a ${errorMessage}`);

      this.setState(() => ({
        language: { label: language.label, value: language.value },
        proficiency: { label: proficiency.label, value: proficiency.value },
      }));
    }
  }

  renderLanguage() {
    return (
      <div className={styles.language} id={this.props.id}>
        <Select
          name='language'
          placeholder='Select your language...'
          options={languageOptions(this.props.languages)}
          color='silverSand'
          value={this.state.language}
          onChange={event =>
            this.setState({
              language: { label: event.label, value: event.value },
            })
          }
        />
      </div>
    );
  }

  renderProficiency() {
    return (
      <div className={styles.proficiency} id={this.props.id}>
        <Select
          name='proficiency'
          placeholder='Select your proficiency...'
          options={proficiencyLevels(this.props.proficiencyLevels)}
          color='silverSand'
          value={this.state.proficiency}
          onChange={event =>
            this.setState({
              proficiency: { label: event.label, value: event.value },
            })
          }
          onKeyDown={this.handleSubmit}
        />
      </div>
    );
  }

  render() {
    return (
      <div className={styles.languages}>
        {this.renderLanguage()}
        {this.renderProficiency()}
        <div className={styles.networks_btn}>
          <Button buttonColor='tuftsBlue' onClick={this.handleSubmit}>
            ADD
          </Button>
        </div>
      </div>
    );
  }
}
export default AddLanguage;
