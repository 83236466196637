import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { formatDate } from 'lib/format_date';
import Button from 'lj_shared/button/button';
import Icon, { Size } from 'components/general/icon/icon';
import AcceptSuggestion from './accept_suggestion';

const styles = require('./suggestion_card.module.scss');

export interface Props {
  application?: any;
  hideBody?: boolean;
  jobSuggestion: any;
  jobAd?: any;
  cardRef?: React.RefObject<HTMLDivElement>;
  title?: JSX.Element;
  body?: JSX.Element;
  customCss?: string;
  secondaryButton?: JSX.Element;
}

export default function SuggestionCard(props: Props) {
  const [hideBody, setHideBody] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);

  function handleScroll() {
    props.hideBody && setHideBody(window.pageYOffset > 10);
  }

  return (
    <div ref={props.cardRef} className={classNames(styles.card, props.customCss)}>
      <div className={styles.header}>
        <Icon className={styles.icon} color='tuftsBlue' size={Size.Big} name='jobSuggestion' />
        <span className={styles.title}>Suggestion</span>
        {props.title}
        {!props.title && renderSuggestionState(props)}
      </div>
      {!hideBody && <div className={styles.container}>{renderBodyContent(props)}</div>}
      <div className={styles.container}>{renderButtons(props)}</div>
    </div>
  );
}

function renderSuggestionState(props: Props) {
  switch (props.jobSuggestion.state) {
    case 'accepted':
      return <span className={styles.stateAccepted}>Applied</span>;
    case 'not_interested':
      return <span className={styles.stateDismissed}>Dismissed</span>;
  }
}

function renderBodyContent(props: Props) {
  const { application, jobSuggestion } = props;
  const intro = props.body ?? (
    <>
      This job was especially suggested to you by our team at Landing.Jobs on{' '}
      {formatDate(jobSuggestion.createdAt)}.<br />
    </>
  );

  if (!props.body) {
    switch (jobSuggestion.state) {
      case 'contacted':
        return <>{intro}Go ahead and give it a try!</>;
      case 'accepted':
        if (application) {
          return (
            <>
              {intro}You applied to this job on {formatDate(application.submittedAt)}
              <br />
              Current state: {application.stateForCandidate}
            </>
          );
        }
        break;
      case 'not_interested':
        if (jobSuggestion) {
          return (
            <>
              You dismissed this Suggestion on {formatDate(jobSuggestion.updatedAt)}
              <br />
              You can still reconsider and apply for this job.
            </>
          );
        }
        break;
    }
  }

  return intro;
}

function renderButtons(props: Props) {
  const { jobSuggestion, jobAd } = props;
  switch (jobSuggestion.state) {
    case 'contacted':
    case 'not_interested':
      return (
        <div className={styles.buttons}>
          <AcceptSuggestion
            buttonSize='mid'
            cvs={jobSuggestion.cvs}
            jobAd={jobAd}
            jobSuggestion={jobSuggestion}
            hasFollowUpModal={true}
          />
          {jobSuggestion.state === 'contacted' ? (
            <Button
              buttonType='border'
              buttonColor='flamingo'
              buttonSize='mid'
              buttonUrl={window.Routes.dismiss_dashboard_suggestion(jobSuggestion.id, {
                context: 'job',
              })}
              dataMethod='PATCH'
            >
              Dismiss
            </Button>
          ) : (
            props.secondaryButton
          )}
        </div>
      );
    case 'accepted':
      return props.secondaryButton;
  }
}
