import React, { useState } from 'react';
import { Tag } from '__models__/gql/tag';
import { SelfEvaluation } from '__models__/self_evaluation';
import { resolveSkills } from '../rating/rating_notes';
import {
  generateJustifiedNotes,
  generateSpacedNotes,
} from '../rating_dimension/rating_dimension_item';
import Tooltip from '../tooltip/tooltip';

const styles = require('./CompletionBar.module.scss');

interface Props {
  average: boolean;
  evaluations?: SelfEvaluation[];
  handleCircleClick?: Function;
  mustHaveSkills?: Tag[];
  notes?: string[];
  otherRequiredSkills?: Tag[];
  title: string;
  values?: number[];
}

export default function CompletionBar(props: Props) {
  if (props.evaluations?.length > 0) {
    return <SelfEvaluationBar {...props} />;
  } else {
    return <CurationBar {...props} />;
  }

  function matchingSkills(skills) {
    const skillNames = skills.map(skill => skill.name);
    const matchingEvals = props.evaluations.filter(ev => skillNames.indexOf(ev.tag.name) >= 0);

    if (matchingEvals.length > 0) {
      return matchingEvals.map(ev => [ev.tag.name, `${ev.score}/10`]);
    }
  }

  function SelfEvaluationBar(evalProps: Props) {
    if (evalProps.average) {
      const sum = evalProps.evaluations.map(ev => ev.score).reduce((a, b) => a + b);
      const average = Math.round((sum / evalProps.evaluations.length) * 100) / 100;
      const mustHaveSkills = evalProps.mustHaveSkills && matchingSkills(evalProps.mustHaveSkills);
      const otherRequiredSkills =
        evalProps.otherRequiredSkills && matchingSkills(evalProps.otherRequiredSkills);
      const tipText = [mustHaveSkills, otherRequiredSkills];
      tipText.push([['Average', `${average}/10`]]);

      return (
        <div className={styles.completion_bar_container}>
          <div className={styles.title}>{evalProps.title}</div>
          <Tooltip
            className={styles.completion_bar}
            text={generateJustifiedNotes(tipText)}
            textBelow={true}
            style={{
              backgroundColor: '#343434',
              color: 'white',
              fontSize: '0.625rem',
              lineHeight: '1.5',
              textAlign: 'justify',
              blankLine: 'always',
            }}
          >
            <div className={styles.completed_bar} style={{ width: `${average * 10}%` }} />
            <div className={styles.background_bar} />
          </Tooltip>
        </div>
      );
    } else {
      const evaluation = evalProps.evaluations[0];
      return (
        <div className={styles.completion_bar_container}>
          <div className={styles.title}>{evalProps.title}</div>
          <Tooltip
            className={styles.completion_bar}
            text={`${evaluation.score}/10`}
            textBelow={true}
            style={{
              backgroundColor: '#343434',
              color: 'white',
              fontSize: '0.625rem',
              textAlign: 'center',
            }}
          >
            <div className={styles.completed_bar} style={{ width: `${evaluation.score * 10}%` }} />
            <div className={styles.background_bar} />
          </Tooltip>
        </div>
      );
    }
  }

  function CurationBar(curationProps: Props) {
    const value = curationProps.values[0];
    const [currentValue, setCurrentValue] = useState(value);
    const target = document.getElementById(curationProps.title);
    const bounds = target?.getBoundingClientRect();
    const start = target && bounds.left;
    const end = target && bounds.right;
    const width = target && end - start;

    const normalizeWidth = position => {
      const realPosition = position - start;
      return Math.round((realPosition * 5) / width);
    };

    const onChange = e => {
      setCurrentValue(normalizeWidth(e.pageX));
    };

    const onLeave = () => {
      setCurrentValue(value);
    };

    const onClick = e => {
      curationProps.handleCircleClick(curationProps.title.toLowerCase(), currentValue);
    };

    if (curationProps.handleCircleClick) {
      return (
        <div
          className={styles.completion_bar_container}
          onMouseMove={onChange}
          onMouseLeave={onLeave}
          onClick={onClick}
          id={curationProps.title}
        >
          <div className={styles.title}>{curationProps.title}</div>
          <Tooltip
            className={`${styles.completion_bar} ${styles.hoverBar}`}
            text={`${currentValue}/5`}
            textBelow={true}
            style={{
              backgroundColor: '#343434',
              color: 'white',
              fontSize: '0.625rem',
              textAlign: 'center',
            }}
          >
            <div
              className={styles.completed_bar}
              style={{ width: `${(currentValue / 5) * 100}%` }}
            />
            <div className={styles.background_bar} />
          </Tooltip>
        </div>
      );
    } else {
      const text =
        curationProps.title === 'Skills' &&
        curationProps.notes &&
        curationProps.notes[0]?.startsWith('{')
          ? resolveSkills(curationProps.notes, true)
          : curationProps.notes
          ? generateSpacedNotes(curationProps.notes)
          : `${value}/5`;

      return (
        <div className={styles.completion_bar_container}>
          <div className={styles.title}>{curationProps.title}</div>
          <Tooltip
            className={styles.completion_bar}
            text={text}
            textBelow={true}
            style={{
              backgroundColor: '#343434',
              color: 'white',
              fontSize: '0.625rem',
              lineHeight: '1.5',
              textAlign: curationProps.notes ? 'left' : 'center',
              blankLine: 'always',
            }}
          >
            <div className={styles.completed_bar} style={{ width: `${(value / 5) * 100}%` }} />
            <div className={styles.background_bar} />
          </Tooltip>
        </div>
      );
    }
  }
}
