import classNames from 'classnames';
import * as React from 'react';

import { Message, Messageable } from './models';
import ConversationHeader from './conversation_header';
import MessagesList from './messages_list';
import MessageForm from './message_form';
import EmptyState from './empty_state';
import DotLoader from 'lj_shared/dot_loader/dot_loader';
import { getJSON } from '../../../lib/request_deprecated';

const deletedChatIcon = require('images/chat_candidate_closed_account.svg');
const deletedChatIconCompany = require('images/chat_company_closed_account.svg');

export interface Props {
  messageable: Messageable;
  currentUserId: number;
  userType: string;
  messagesUrl: Function;
  findConversationUrl: Function;
  handleConversationClose: Function;
  handleUpdateNotification: Function;
  handleExpandClick: Function;
  handleNewConversation: Function;
  mini: boolean;
  minimized: boolean;
  handleMinimizeClick: any;
}

interface State {
  messages: Message[];
  messagesLoaded: boolean;
  prevMessageable?: Messageable;
}

export default class Conversation extends React.Component<Props, State> {
  private _isMounted = false;

  constructor(props: Props) {
    super(props);

    this.state = {
      messages: [] as Message[],
      messagesLoaded: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.messageable !== state.prevMessageable) {
      return {
        messages: [],
        messagesLoaded: false,
      };
    }
    return null;
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.messageable && this.props.messageable.type === 'conversation') {
      this.getConversation(this.props.messageable.id);
    }
    this.scrollToBottom();
    (window as any).IconicJS().inject('.iconic');
  }

  componentDidUpdate() {
    const id = Number(this.props.messageable && this.props.messageable.id);
    if (
      !this.state.messages[0] ||
      id !== Number(this.state.messages[0].attributes.conversation_id)
    ) {
      if (!this.state.messagesLoaded) this.componentDidMount();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getConversation(id: string = null) {
    const conversationId = id === null ? this.props.messageable.id : id;

    getJSON(this.props.messagesUrl(conversationId))
      .then(data => {
        if (this._isMounted) {
          this.setState(() => ({
            messages: data.included,
            messagesLoaded: true,
            prevMessageable: this.props.messageable,
          }));
        }
      })
      .catch(() => {
        window.Alerts.alert('Error getting conversation');
      });
  }

  handleMessageReceived(message) {
    if (message.conversation_id.toString() === this.props.messageable.id) {
      this.updateMessages(message);
    }
  }

  scrollToBottom() {
    const messageList = document.querySelector('.lj-messages-list');

    if (messageList && messageList.children[messageList.childElementCount - 2]) {
      messageList.children[messageList.childElementCount - 2].scrollIntoView();
    }
  }

  updateMessages(message) {
    const newMessage: Message = {
      id: message.id,
      type: 'message',
      attributes: {
        sender_type: message.sender_type,
        sender_id: message.sender_id,
        conversation_id: message.conversation_id,
        body: message.body,
        read: null,
        created_at: message.time,
        updated_at: null,
      },
    };

    this.setState<any>((prevState: State) => {
      return {
        messages: prevState.messages.concat([newMessage]),
      };
    });

    this.scrollToBottom();
  }

  talentUserDeleted() {
    return (
      <div className={'lj-conversation-deletedUser'}>
        <img src={deletedChatIcon} className='lj-conversation-deletedUserImage' />
        <span>Sorry, we're unable to access this</span>
        <span>conversation because the</span>
        <span>candidate closed their account.</span>
      </div>
    );
  }

  companyDeleted() {
    return (
      <div className={'lj-conversation-deletedCompany'}>
        <img src={deletedChatIconCompany} className='lj-conversation-deletedUserImage' />
        <span>Sorry, we're unable to access this</span>
        <span>conversation because the</span>
        <span>company closed their account.</span>
      </div>
    );
  }

  renderMessages() {
    return (
      <>
        {this.state.messages &&
        this.state.messages.length > 0 &&
        this.props.messageable.type === 'conversation' ? (
          <MessagesList
            messageable={this.props.messageable}
            messages={this.state.messages}
            userType={this.props.userType}
            currentUserId={this.props.currentUserId}
          />
        ) : (
          <EmptyState recipient_name={this.props.messageable.attributes.recipient_name} />
        )}
        <MessageForm
          messagesUrl={this.props.messagesUrl}
          userType={this.props.userType}
          messageable={this.props.messageable}
          handleNewConversation={this.props.handleNewConversation}
          mini={this.props.mini}
          handleUpdateNotification={this.props.handleUpdateNotification}
        />
      </>
    );
  }

  renderLoader() {
    return (
      <div style={{ position: 'relative' }}>
        <DotLoader />
      </div>
    );
  }

  renderConversation() {
    if (this.props.messageable.attributes.user_deleted) {
      return this.talentUserDeleted();
    } else if (this.props.messageable.attributes.company_deleted) {
      return this.companyDeleted();
    } else if (this.state.messagesLoaded || this.props.messageable.type === 'person') {
      return this.renderMessages();
    } else {
      return this.renderLoader();
    }
  }

  render() {
    if (!this.props.messageable) {
      return null;
    }

    return (
      <div
        className={classNames('lj-conversation', {
          '--hidden': this.props.minimized,
        })}
      >
        <ConversationHeader
          handleConversationClose={this.props.handleConversationClose}
          handleExpandClick={this.props.handleExpandClick}
          findConversationUrl={this.props.findConversationUrl}
          messageable={this.props.messageable}
          userType={this.props.userType}
          mini={this.props.mini}
          handleMinimizeClick={this.props.handleMinimizeClick}
          userDeleted={this.props.messageable.attributes.user_deleted}
        />
        {this.renderConversation()}
      </div>
    );
  }
}
