import React from 'react';
import classNames from 'classnames';
import Icon from 'components/general/icon/icon';
import Tooltip from 'components/general/tooltip/tooltip';
import SmallProfileDisplayItem from '../../smallProfileDisplay/SmallProfileDisplayItem';
import LabelWithStarIcon from 'components/talentUser/LabelWithStarIcon';

const styles = require('./vertical_card_entry.module.scss');

export default function VerticalCardEntry(props) {
  const isArray = () => Array.isArray(props.value);

  const arrayWithItems = () => isArray() && props.value.length > 0;

  const emptyArray = () => isArray() && props.value.length === 0;

  const colorClass = props.color ? styles[`color-${props.color}`] : '';

  const renderBoxValue = item => {
    let shareable = item;
    let nonShareable = null;

    if (typeof item === 'object' && 'shareable' in item) {
      shareable = item.shareable;
      nonShareable = item.nonShareable;
    }

    const label = nonShareable && (
      <div className={styles.underBoxText}>
        <Tooltip text='This information is not visible to companies' textBelow={true}>
          <Icon color='black' name='invisible' />
        </Tooltip>
        {nonShareable}
      </div>
    );

    if (props.color === 'tuftsBlue') {
      return (
        <div className={styles.value} key={shareable}>
          <SmallProfileDisplayItem title={shareable} label={label} />
        </div>
      );
    }

    return (
      <div className={styles.value} key={shareable}>
        <div className={classNames(styles.box, colorClass)}>{shareable}</div>
        {label}
      </div>
    );
  };

  const renderValues = () => {
    if (props.border) {
      return props.value.map(item => renderBoxValue(item));
    }

    if (props.uniqueKey === 'languages') {
      const languagesSortedByProficiency: [] = props.value.sort((n1, n2) => {
        const firstNum = n1.experienceLabel || n1.experience_label || '0';
        const secondNum = n2.experienceLabel || n2.experience_label || '0';
        if (firstNum.charAt(0) > secondNum.charAt(0)) {
          return -1;
        }
        if (firstNum.charAt(0) < secondNum.charAt(0)) {
          return 1;
        }
        return 0;
      });
      return languagesSortedByProficiency.map(item => (item ? renderLanguage(item) : '-'));
    }
    return (
      <div className={classNames(styles.value, styles.simpleText)}>
        {props.value.map(item => (item ? item : '-')).join(' - ')}
      </div>
    );
  };

  const renderEmpty = () => <div className={styles.value}>-</div>;

  const renderValue = () => (props.value ? props.value : '-');

  const renderLanguage = language => {
    return (
      <div className={styles.languages} key={language.name}>
        <SmallProfileDisplayItem
          color={props.color}
          title={language.name}
          label={
            language.experienceLabel
              ? language.experienceLabel?.slice(3)
              : language.experience_label?.slice(3)
          }
        />
      </div>
    );
  };

  const renderStarIcon = () => {
    const minLength = props.uniqueKey === 'phone_number' ? 1 : 0;
    return (
      <LabelWithStarIcon
        label={props.title}
        value={props.value?.length > minLength && props.value !== 'No info'}
      />
    );
  };

  return (
    <div className={props.blurred ? styles.blur : ''}>
      <div className={styles.title}>{props.showStarIcon ? renderStarIcon() : props.title}</div>
      {arrayWithItems() && renderValues()}

      {emptyArray() && renderEmpty()}

      {!isArray() && (
        <div className={classNames(styles.value, styles.simpleText)}>{renderValue()}</div>
      )}
    </div>
  );
}
