import * as React from 'react';
import EmptyState from './empty_state';
import { shallow, ShallowWrapper } from 'enzyme';

let wrapped: ShallowWrapper;

describe('Empty State component', () => {
  beforeEach(() => {
    wrapped = shallow(<EmptyState recipient_name='' />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
