// Libraries
import classNames from 'classnames';
import { startCase, upperFirst, lowerCase } from 'lodash';
import * as React from 'react';

// Imports
import { applicationStateForEmployer } from 'lib/labels';
import Button from 'lj_shared/button/button';
import InlineIcon from 'components/general/inline_icon/inline_icon';
import MessageModal from './message_modal';
import TechnicalTestModal from './technical_test_modal';
import TextInput from 'components/form/text/text';
import Checkbox from 'components/form/checkbox/checkbox';
import SquareRadioButtons from 'components/form/square_radio_buttons/square_radio_buttons';
import TestCardItem from './test_card_item';
import Icon, { Size } from 'components/general/icon/icon';

// Models
import * as Models from './models';
import { capitalize } from 'lodash/string';
import PricingQuestionMarkTooltip from 'components/pricing_question_mark_tooltip/pricing_question_mark_tooltip';
import ScreeningScriptModal from 'components/screeningScriptModal/ScreeningScriptModal';
import FeatureCta from 'components/featureCta/FeatureCta';

// Icons and Style
const iconMinus = require('iconic/minus.svg');
const iconPencil = require('iconic/pencil.svg');
const iconPlus = require('iconic/plus.svg');
const iconTrash = require('iconic/trash.svg');
const styles = require('./job_hiring_step.module.scss');

// Settings forms validations
const settings = require('../../../frontend/settings.json');

interface Props {
  isNew: boolean;
  isRevision: boolean;
  jobId: number;
  step: Models.Step;
  urls: any;
  onDestroy?: Function;
  onSave?: Function;
  onMessageSave?: Function;
  testIds?: any[];
  backoffice: boolean;
  standardTests: Models.AssessmentsList;
  finalSteps?: boolean;
  technicalAssessmentsEnabled: boolean;
  landingScreeningEnabled: boolean;
  landingScreeningExists?: boolean;
  screeningScript?: any;
}

interface State {
  isOpen: boolean;
  step: Models.Step;
  kind: { label: string; value: string };
  descriptionChanged: boolean;
}

const KIND_OPTIONS = [
  { label: 'Interview', value: 'interview' },
  { label: 'Tech Assessment', value: 'tech_assessment' },
  { label: 'Screening by Landing.Jobs team', value: 'landing_screening' },
];

const DEFAULT_KINDS = [
  { label: 'Fixed', value: 'fixed' },
  { label: 'Dummy Step', value: 'add_a_new_step' },
];

export default class JobHiringStep extends React.Component<Props, State> {
  messageRef: any;
  testRef: any;
  screeningScriptRef: any;
  nameValidations: any;
  descriptionValidations: any;
  stepForm: any;
  technicalAssessmentsEnabled: boolean;
  landingScreeningEnabled: boolean;
  color: string;

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      step: this.props.step,
      kind: JobHiringStep.getStateKind(props),
      descriptionChanged: false,
    };

    this.handleDestroy = this.handleDestroy.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleToggle = this.handleToggle.bind(this);

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleManualLateStageChange = this.handleManualLateStageChange.bind(this);

    this.handleMessage = this.handleMessage.bind(this);
    this.openMessageModal = this.openMessageModal.bind(this);
    this.closeMessageModal = this.closeMessageModal.bind(this);

    this.handleTest = this.handleTest.bind(this);
    this.openTestModal = this.openTestModal.bind(this);
    this.closeTestModal = this.closeTestModal.bind(this);
    this.openLandingScreeningModal = this.openLandingScreeningModal.bind(this);
    this.closeLandingScreeningModal = this.closeLandingScreeningModal.bind(this);
    this.stepIs = this.stepIs.bind(this);
    this.featureDisabled = this.featureDisabled.bind(this);
    this.newStepLabels = this.newStepLabels.bind(this);

    this.messageRef = React.createRef();
    this.testRef = React.createRef();
    this.screeningScriptRef = React.createRef();
    this.nameValidations = { maxLen: settings.input.length };
    this.descriptionValidations = { maxLen: settings.input.length };
    this.stepForm = React.createRef();
    this.technicalAssessmentsEnabled = this.props.technicalAssessmentsEnabled;
    this.landingScreeningEnabled = this.props.landingScreeningEnabled;
    this.color = this.setColor(this.props.backoffice);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.step !== state.step) {
      return {
        step: props.step,
        kind: JobHiringStep.getStateKind(props),
      };
    }
    return null;
  }

  // In order to pass the method to getDerivedStateFromProps
  static getStateKind(props) {
    const options = [...KIND_OPTIONS, ...DEFAULT_KINDS];
    return options.find(({ value }) => value === props.step.kind);
  }

  handleDestroy(event) {
    event.preventDefault();

    if (this.stepIs('landing_screening')) {
      const scriptInput = document.getElementById('screening_script') as any;
      scriptInput.value = null;
    }

    this.props.onDestroy(this.state.step);
  }

  stepIs(kind) {
    const stepKinds = [this.state.kind?.value, this.state.step.kind];

    return stepKinds.includes(kind);
  }

  featureDisabled() {
    if (this.stepIs('tech_assessment')) {
      return !this.technicalAssessmentsEnabled && this.state.step.test;
    } else if (this.stepIs('landing_screening')) {
      return !this.landingScreeningEnabled && !this.props.landingScreeningExists;
    }
  }

  handleSave(event) {
    event.preventDefault();
    event.persist();
    const step = this.state.step;

    if (!this.state.descriptionChanged && this.stepIs('landing_screening')) {
      this.setState(
        prevState => {
          const prevStep = prevState.step;
          prevStep['description'] = this.defaultDescription();

          return { step: prevStep, descriptionChanged: true };
        },
        () => this.handleSave(event)
      );
    } else {
      let error;

      if (this.featureDisabled()) {
        error = "You don't have access to this feature.";
      } else if (!step.name) {
        error = 'Please name this step.';
      } else if (!this.state.kind?.value) {
        error = 'Please select what kind of step you are adding.';
      } else if (this.stepIs('landing_screening')) {
        const scriptInput = document.getElementById('screening_script') as any;
        if (!scriptInput.value && this.stepIs('add_a_new_step')) {
          error = 'Please edit and approve the interview script.';
        }
      }

      if (this.stepForm.current.checkValidity() && !error) {
        if (this.stepIs('add_a_new_step')) {
          step.kind = this.state.kind?.value;
        }
        this.props.onSave(step);
        this.setState({ isOpen: false });
      } else {
        if (!error) {
          error = 'Oops, something went wrong.';
        }

        window.Alerts.alert(error);
      }
    }
  }

  handleToggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  isNew() {
    return this.props.isNew;
  }

  handleMessage(message, messageUpdated) {
    this.setState(
      prevState => {
        const step = prevState.step;

        step.message = message;

        return { step };
      },
      () => {
        if (messageUpdated) {
          this.handleSave.bind(this);
        }
      }
    );

    this.closeMessageModal();
  }

  handleTest(test) {
    this.setState(prevState => {
      const step = prevState.step;
      step.test = test;

      return { step };
    });

    this.closeTestModal();
  }

  changeKind(e) {
    this.setState({
      kind: KIND_OPTIONS.filter(opt => opt.label === e.value)[0],
    });
  }

  render() {
    const classes = classNames(styles.main, {
      [styles.isNew]: this.isNew(),
      [styles['lj-hiringStep--dropdown-active']]: this.state.isOpen,
      [styles.isBackoffice]: this.props.backoffice,
    });

    if (this.stepIs('fixed')) {
      return this.renderFixed(this.props.backoffice);
    } else {
      return (
        <div className={classes} data-order={this.state.step.order}>
          <div className={styles.trigger} onClick={this.handleToggle}>
            {this.renderBlock()}
          </div>
          {this.renderForm()}
        </div>
      );
    }
  }

  renderBlock() {
    if (this.isNew()) {
      return (
        <>
          <div className={styles['lj-hiringStep-block']}>
            <InlineIcon path={this.state.isOpen ? iconMinus : iconPlus} />
          </div>
          <div className={styles['lj-hiringStep-label']}>
            {upperFirst(lowerCase(this.state.step.kind))}
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className={`${styles['lj-hiringStep-block']} ${styles['lj-hiringStep-order']}`}>
            <span>{this.state.step.order}</span>
          </div>
          <div className={styles['lj-hiringStep-block']}>
            <InlineIcon path={iconPencil} />
          </div>
          <div className={styles['lj-hiringStep-label']}>{startCase(this.state.step.kind)}</div>
          <div className={styles['lj-hiringStep-subLabel']}>{this.state.step.name}</div>
          {this.props.backoffice && this.state.step?.late_stage && (
            <div className={styles['lj-hiringStep-subLabel']}>
              {`This state was ${
                this.state.step.manual_late_stage ? 'manually' : 'automatically'
              } marked as "Late Stage"`}
            </div>
          )}
        </>
      );
    }
  }

  renderFixed(isBackoffice) {
    const label = this.props.finalSteps
      ? capitalize(this.state.step.name)
      : applicationStateForEmployer(this.state.step.name);
    return (
      <div className={`lj-hiringStep lj-hiringStep--default ${isBackoffice && 'isBackoffice'}`}>
        <div className='lj-hiringStep-trigger'>
          <div className='lj-hiringStep-block'>
            <span>{this.state.step.order}</span>
          </div>
          <div className='lj-hiringStep-label'>{label}</div>
        </div>
        {!this.props.finalSteps && (
          <div className={styles['lj-hiringStep-content']}>
            <ul className={styles['lj-hiringStep-actionList']}>
              {this.renderMessageLink()}
              {this.renderFixedTooltip()}
            </ul>
          </div>
        )}
      </div>
    );
  }

  renderTooltip(text: string) {
    const klass = classNames(
      'ld-tooltip-item ld-helper ld-green-helper ld-arrow-down',
      styles.tooltip
    );
    return (
      <span className={klass}>
        <strong>?</strong>
        <span className='ld-tooltip'>{text}</span>
      </span>
    );
  }

  renderFixedTooltip() {
    const suffix =
      this.state.step.name === 'unreviewed' ? 'applied to this job' : 'an application in review';

    return this.renderTooltip(`Here you can create a message that will be automatically
                               sent to each candidate that has ${suffix}.`);
  }

  renderMarkLateStageTooltip() {
    return this.renderTooltip(`Here you can manually mark a state as being "Late Stage".
                               Notice that this action should be made individually for each state.
                               Manually marking a state will also eliminate any automatic marking.`);
  }

  handleDescriptionChange(event) {
    const value = event.target.value;

    if (this.state.step.description !== value) {
      this.setState(prevState => {
        const step = prevState.step;
        step['description'] = value;

        return { step, descriptionChanged: true };
      });
    }
  }

  handleNameChange(event) {
    const value = event.target.value;

    if (this.state.step.name !== value) {
      this.setState(prevState => {
        const step = prevState.step;
        step['name'] = value;

        return { step };
      });
    }
  }

  handleManualLateStageChange(event) {
    const value = event.target.checked;

    if (this.state.step.manual_late_stage !== value) {
      this.setState(prevState => {
        const step = prevState.step;
        step['manual_late_stage'] = value;

        return { step };
      });
    }
  }

  defaultDescription() {
    const landingScreeningText =
      'You will be contacted by Recruiter Lead to do an initial interview.\n' +
      'This interview will be intended to recognise as much relevant information' +
      ' so that we can send a complete report and thus have more chances to advance in the process.';

    return this.stepIs('landing_screening') ? landingScreeningText : '';
  }

  defaultPlaceholder() {
    return this.stepIs('interview')
      ? 'Phone Call'
      : this.stepIs('landing_screening')
      ? 'Screened by Landing'
      : 'Tech Challenge';
  }

  newStepLabels() {
    const kindOptions = this.props.landingScreeningExists
      ? KIND_OPTIONS.filter(option => option.value !== 'landing_screening')
      : KIND_OPTIONS;

    return kindOptions.map(k => k.label);
  }

  renderForm() {
    return (
      <div className={styles['lj-hiringStep-content']}>
        <form ref={this.stepForm} id={`job-hiring-step-${this.props.step.uid}`}>
          {this.stepIs('add_a_new_step') && (
            <label>
              <div className={styles['lj-hiringStep-inputLabel']}>
                What kind of step would you like to add?
              </div>
              <div className={`u-marginBottom--mid ${styles['lj-hiringStep-radioButtons']}`}>
                <SquareRadioButtons
                  radioName='kind_of_step'
                  onClickYield={e => this.changeKind(e)}
                  vertical
                  borderButtons
                  labels={this.newStepLabels()}
                  selectedLabel={this.state.kind.label}
                  color={this.color}
                  required={true}
                />
              </div>
            </label>
          )}
          {this.stepIs('landing_screening') && this.featureDisabled() ? (
            <FeatureCta type={'RPO Service'} color='ripePlum' />
          ) : (
            <>
              <label>
                <div className={styles['lj-hiringStep-inputLabel']}>
                  How would you like to name this step?
                </div>
                <div className='u-marginBottom--mid'>
                  <TextInput
                    name='job_hiring_step[name]'
                    color={this.color}
                    value={this.state.step.name || ''}
                    onChange={this.handleNameChange}
                    placeholder={this.defaultPlaceholder()}
                    maxLength={this.nameValidations.maxLen}
                    required={true}
                  />
                </div>
              </label>
              <label>
                <div className={styles['lj-hiringStep-inputLabel']}>
                  Would you like to add a description?
                </div>
                <div className='u-marginBottom--mid'>
                  <TextInput
                    name='job_hiring_step[description]'
                    color={this.color}
                    value={this.state.step.description || this.defaultDescription()}
                    onChange={this.handleDescriptionChange}
                    textarea={true}
                    placeholder={'A description of the hiring step...'}
                    maxLength={this.descriptionValidations.maxLen}
                    maxLengthShow={true}
                  />
                </div>
              </label>

              <ul className={styles['lj-hiringStep-actionList']}>
                {this.props.backoffice && this.renderMarkLateStage()}
                {this.renderMessageLink()}
                {this.stepIs('tech_assessment') && this.renderTestLink()}
                {this.stepIs('landing_screening') && this.renderLandingScreeningLink()}
              </ul>

              <div className='u-marginTop--mid'>
                <Button buttonColor={this.color} buttonSize='small' onClick={this.handleSave}>
                  Save
                </Button>
                {this.renderDestroyButton()}
              </div>
            </>
          )}
        </form>
      </div>
    );
  }

  renderMarkLateStage() {
    return (
      <li>
        <Checkbox
          className={styles.actionCheckbox}
          checked={this.props.step.manual_late_stage}
          name='manual_late_stage'
          color={this.color}
          label={'Manually mark as "Late Stage"'}
          controlled={false}
          onChange={this.handleManualLateStageChange}
        />
        {this.renderMarkLateStageTooltip()}
      </li>
    );
  }

  renderMessageLink() {
    const message = this.state.step.message;

    return (
      <li>
        <MessageModal
          commitChanges={this.props.backoffice}
          step={this.state.step}
          message={this.state.step.message}
          urls={this.props.urls.messages}
          close={this.handleMessage}
          ref={this.messageRef}
          exists={!!this.state.step.message}
          color={this.color}
        />
        <a
          className={classNames(styles.editMessageLink, {
            [styles.isBackoffice]: this.props.backoffice,
          })}
          onClick={this.openMessageModal}
        >
          <Icon name={message ? 'pencil' : 'plus'} color={this.color} size={Size.Medium} />{' '}
          {message ? 'Edit' : 'Add an'} automated message
        </a>
      </li>
    );
  }

  renderTestLink() {
    const test = this.state.step.test;
    const technicalTest =
      test && this.props.standardTests.data.filter(t => t.attributes.id === test.test_id)[0];
    const testEnabled = this.props.technicalAssessmentsEnabled;

    return (
      <>
        <li className={styles['action-technical-test']}>
          <TechnicalTestModal
            step={this.state.step}
            test={test}
            testIds={this.props.testIds}
            close={this.handleTest}
            urls={this.props.urls.tests}
            exists={!!this.state.step.test}
            ref={this.testRef}
            isRevision={this.props.isRevision}
            standardTests={this.props.standardTests}
          />
          <div className={styles.technicalTestLink}>
            <a
              className={classNames(styles.editMessageLink, {
                [styles.disabledLink]: !testEnabled,
                [styles.isBackoffice]: this.props.backoffice,
              })}
              onClick={testEnabled ? this.openTestModal : undefined}
            >
              <Icon
                name={test ? 'pencil' : 'plus'}
                color={testEnabled ? this.color : 'silverSandMidDark'}
                size={Size.Medium}
              />{' '}
              {test ? 'Edit' : 'Add a'} technical test
            </a>
            {!testEnabled && (
              <PricingQuestionMarkTooltip
                pricingDisabledFeature
                type={'technical assessments'}
                backoffice={this.props.backoffice}
              />
            )}
          </div>
        </li>
        {technicalTest && (
          <li>
            <TestCardItem
              technicalTest={technicalTest}
              selectedTest={technicalTest.attributes.id}
            />
          </li>
        )}
      </>
    );
  }

  renderLandingScreeningLink() {
    return (
      <>
        <li className={styles['action-technical-test']}>
          <ScreeningScriptModal
            screeningScript={this.props.screeningScript}
            modalRef={this.screeningScriptRef}
            color={this.color}
          />
          <div className={styles.technicalTestLink}>
            <a
              className={classNames(styles.editMessageLink, {
                [styles.isBackoffice]: this.props.backoffice,
              })}
              onClick={this.openLandingScreeningModal}
            >
              <Icon name='pencil' color={this.color} size={Size.Medium} /> Edit interview script
            </a>
          </div>
        </li>
      </>
    );
  }

  openTestModal() {
    this.testRef.current.open();
  }

  closeTestModal() {
    this.testRef.current.close();
  }

  openMessageModal() {
    this.messageRef.current.open();
  }

  closeMessageModal() {
    this.messageRef.current.close();
  }

  openLandingScreeningModal() {
    this.screeningScriptRef.current.open();
  }

  closeLandingScreeningModal() {
    this.screeningScriptRef.current.close();
  }

  renderDestroyButton() {
    return (
      <Button
        buttonColor={this.color}
        buttonSize='small'
        buttonType='border'
        onClick={this.isNew() ? this.handleToggle : this.handleDestroy}
      >
        {this.isNew() ? (
          'Cancel'
        ) : (
          <>
            <InlineIcon className={styles.trashIcon} path={iconTrash} /> Delete
          </>
        )}
      </Button>
    );
  }

  setColor(isBackoffice) {
    return isBackoffice ? 'tuftsBlue' : 'ripePlum';
  }
}
