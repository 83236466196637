import React from 'react';
import AccordionGroups from './accordion_groups';
import { render } from '@testing-library/react';

describe('AccordionGroups', () => {
  it('renders the passed items', () => {
    const items = [
      ['Active', 'John'],
      ['Draft', 'Jane'],
    ];
    const { queryByText } = render(
      <AccordionGroups getGroupName={item => item[0]} isGroupOpen={item => true} items={items}>
        {item => item[1]}
      </AccordionGroups>
    );

    for (const item of items) {
      expect(queryByText(item[0], { exact: false })).toBeTruthy();
      expect(queryByText(item[1], { exact: false })).toBeTruthy();
    }
  });
});
