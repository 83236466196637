import * as React from 'react';
import TopFilters from './top_filters';
import { shallow, ShallowWrapper } from 'enzyme';

let wrapped: ShallowWrapper;

describe('Top Filters component', () => {
  beforeEach(() => {
    wrapped = shallow(
      <TopFilters
        setTopFilter={jest.fn()}
        readyCounter={1}
        reviewCounter={11}
        waitingForScreeningCounter={13}
        activeFilter='inbox'
      />
    );
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
