import React from 'react';
import renderer from 'react-test-renderer';
import Dropdown from './Dropdown';
import { render } from '@testing-library/react';

const props = {
  placeHolder: '',
  options: [
    { value: 'a', label: 'a' },
    { value: 'b', label: 'b' },
    { value: 'c', label: 'c' },
  ],
  onChange: () => undefined,
};

describe('Dropdown', () => {
  it('should render correctly', () => {
    const wrapper = renderer.create(<Dropdown {...props} />);
    expect(wrapper).toMatchSnapshot();
  });

  it('should not render without data', () => {
    const { queryByTestId } = render(<Dropdown {...props} options={[]} />);

    expect(queryByTestId(/dropdown/i)).toBeNull();
  });

  it('should render dropdown when focus on input', () => {
    const wrapper = render(<Dropdown {...props} />);
    wrapper.getByTestId('search-input').click();

    expect(wrapper.getByTestId('dropdown')).toBeInTheDocument();
  });
});
