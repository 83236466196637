export default class RatingContainerUtils {
  ratingsWithDimensions: any;

  constructor(ratingsWithDimensions) {
    this.ratingsWithDimensions = ratingsWithDimensions;
  }

  getDimensionIds = rating => rating.relationships.dimension_ratings.data.map(item => item.id);

  getDimensions = rating => {
    const dimensionIds = this.getDimensionIds(rating);
    return this.ratingsWithDimensions.included
      .filter(({ id }) => dimensionIds.indexOf(id) !== -1)
      .map(({ attributes }) => ({
        dimension: attributes.dimension,
        value: attributes.value,
        automatic_notes: attributes.automatic_notes,
      }));
  };

  props = () => {
    if (this.ratingsWithDimensions && this.ratingsWithDimensions.data.length > 0) {
      const ratingsWithDimensions = this.ratingsWithDimensions.data.filter(
        rate => rate.attributes.rater_type === 'AdminUser'
      );
      if (ratingsWithDimensions.length > 0) {
        return [ratingsWithDimensions[0]].map(rating => ({
          id: rating.attributes.id,
          note: rating.attributes.note,
          rater_type: rating.attributes.rater_type,
          rating: rating.attributes.rating,
          dimensions: this.getDimensions(rating),
        }));
      } else {
        return [];
      }
    }
    return [];
  };
}
