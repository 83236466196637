import React from 'react';
const styles = require('./SearchTagItem.module.scss');
import Icon, { Size } from 'components/general/icon/icon';

interface Props {
  handleDelete: Function;
  label: string;
}

export default function SearchTagItem(props: Props) {
  return (
    <div className={styles.searchTag}>
      {props.label}
      <Icon name='close' color='white' size={Size.Medium} clickHandler={props.handleDelete} />
    </div>
  );
}
