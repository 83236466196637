import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import JobSection from './JobSection';

const createProps = () => ({ title: 'Description', text: 'This is your job.' });

let props;
let wrapped: ShallowWrapper;

describe('Job Section renders correctly', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<JobSection {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('should have a line', () => {
    expect(wrapped.find('hr')).toHaveLength(1);
  });

  it('should have a title', () => {
    expect(wrapped.find('.title').text()).toBe('Description');
  });
});
