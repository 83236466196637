import 'lib/globals';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import {
  initHotjarSurveys,
  sendUserAttributes as sendHotjarUserAttributes,
} from 'lib/hotjar_surveys';
import { init as initTagManager } from 'lib/tag_manager';
import { truncate } from 'lodash';

const Settings = require('settings.json');

export function initTrackingAndSurveys() {
  initTagManager();
  initSurveys();

  document.addEventListener('turbolinks:load', () => {
    initTagManagerTracking();
  });
}

export function initSurveys() {
  initHotjarSurveys();

  document.addEventListener('turbolinks:load', () => {
    sendHotjarUserAttributes();
  });
}

function initTagManagerTracking() {
  const cookieName = 'tm_event';
  let data = { event: undefined };

  try {
    data = JSON.parse(Cookies.get(cookieName));
  } catch (e) {
    if (!(e instanceof SyntaxError)) throw e;
  }

  if (Settings.tagManagerEvents.includes(data.event)) tagManagerTrack(data);
  Cookies.remove(cookieName);
}

export function tagManagerTrack(data) {
  if (!window.gon.skip_tracking && window.dataLayer) {
    window.dataLayer.push(data);
  }
}

export function facebookCompanyShareUrl(name, description, url) {
  const params = {
    utm_source: 'facebook',
    utm_medium: 'social',
    utm_campaign: 'company',
    utm_content: name,
  };
  url = `${url}?${queryString.stringify(params)}`;
  const caption = '#techjobs';
  description = truncate(description, { length: 100 });

  return facebookShareUrl(caption, description, url);
}

export function linkedinCompanyShareUrl(name, description, url) {
  const params = {
    utm_source: 'linkedin',
    utm_medium: 'social',
    utm_campaign: 'company share',
    utm_content: name,
  };
  url = `${url}?${queryString.stringify(params)}`;

  return linkedinShareUrl(url);
}

export function twitterCompanyShareUrl(name, url) {
  const params = {
    utm_source: 'twitter',
    utm_medium: 'social',
    utm_campaign: 'company share',
    utm_content: name,
  };
  url = `${url}?${queryString.stringify(params)}`;
  const hashtags = 'techjobs';
  const text = `Hey, check out ${name}!`;
  const via = 'Landing_jobs';
  return twitterShareUrl(text, hashtags, url, via);
}

export function whatsappCompanyShareUrl(name, url) {
  const params = {
    utm_source: 'whatsapp',
    utm_medium: 'social',
    utm_campaign: 'company share',
    utm_content: name,
  };
  url = `${url}?${queryString.stringify(params)}`;
  const text = `Look at this job I found:\n\n${url}`;
  return whatsappShareUrl(text);
}

export function facebookLandingShareUrl() {
  const params = {
    utm_source: 'facebook',
    utm_medium: 'social',
    utm_campaign: 'homepage',
  };
  const url = `${window.Routes.home_url()}?${queryString.stringify(params)}`;
  const caption = '#techjobs';

  return facebookShareUrl(caption, null, url);
}

export function twitterLandingShareUrl() {
  const params = {
    utm_source: 'twitter',
    utm_medium: 'social',
    utm_campaign: 'homepage',
  };
  const url = `${window.Routes.home_url()}?${queryString.stringify(params)}`;
  const hashtags = 'techjobs';
  const text = `Check this out: `;
  const via = 'Landing_jobs';
  return twitterShareUrl(text, hashtags, url, via);
}

export function linkedinLandingShareUrl() {
  const params = {
    utm_source: 'linkedin',
    utm_medium: 'social',
    utm_campaign: 'homepage',
  };
  const url = `${window.Routes.home_url()}?${queryString.stringify(params)}`;

  return linkedinShareUrl(url);
}

export function whatsappLandingShareUrl() {
  const params = {
    utm_source: 'whatsapp',
    utm_medium: 'social',
    utm_campaign: 'homepage',
  };
  const url = `${window.Routes.home_url()}?${queryString.stringify(params)}`;
  return whatsappShareUrl(url);
}

function facebookShareUrl(caption, description, link) {
  const query = {
    app_id: '594024530633157',
    caption,
    description,
    display: 'popup',
    link,
    redirect_uri: window.location.href,
  };
  return `https://www.facebook.com/dialog/feed?${queryString.stringify(query)}`;
}

function linkedinShareUrl(url) {
  const query = {
    url,
  };

  return `https://www.linkedin.com/sharing/share-offsite/?${queryString.stringify(query)}`;
}

function twitterShareUrl(text, hashtags, url, via) {
  const query = {
    text,
    hashtags,
    url,
    via,
  };

  return `https://twitter.com/intent/tweet?${queryString.stringify(query)}`;
}

function whatsappShareUrl(text) {
  const query = {
    text,
  };

  return `https://wa.me/?${queryString.stringify(query)}`;
}
