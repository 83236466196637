import { Map, Set } from 'immutable';
import { Application } from '../../__models__/models';

import { splitIntoSubArrays } from '../../lib/array';

interface Pagination {
  currentPage: number;
  applicationTotal: number;
  perPage: number;
}

export class ApplicationsState {
  boxes: Map<string, Application[][]>;
  paginations: Map<string, Pagination>;

  constructor({ boxes = null, paginations = null }) {
    this.boxes = boxes || Map<string, Application[][]>();
    this.paginations = paginations || Map<string, Pagination>();
  }

  box(name: string, command = 'default', page = this.page(name)) {
    switch (command) {
      case 'default':
        return this.getBoxPage(name, page);
      case 'fullBox':
        return this.boxes.get(name) ? this.boxes.get(name) : [];
      case 'splat':
        // eslint-disable-next-line prefer-spread
        return [].concat.apply([], this.boxes.get(name)); // same as .flat()
      default:
        return [];
    }
  }

  getCompleteList() {
    return this.boxes.reduce((accumulator, box) => [].concat.apply(accumulator, box), []);
  }

  getBoxPage(name: string, page = this.page(name)) {
    if (!this.boxes.get(name) || !this.boxes.get(name)[page]) {
      return [];
    }
    return this.boxes.get(name)[page];
  }

  count(name: string) {
    return this.paginations.get(name) ? this.paginations.get(name).applicationTotal : 0;
  }

  page(name: string) {
    return this.paginations.get(name) ? this.paginations.get(name).currentPage : 0;
  }

  perPage(name: string) {
    return this.paginations.get(name) ? this.paginations.get(name).perPage : 15;
  }

  pagination(name: string) {
    return this.paginations.get(name);
  }

  isLoaded(name, page = 0) {
    if (name === 'inbox') {
      return this.paginations
        .keySeq()
        .toArray()
        .some(box => box.includes('state'));
    }
    return this.box(name, 'default', page).length > 0;
  }

  set(
    box: string,
    applications: Application[],
    page: number,
    applicationTotalSet = this.count(box),
    perPageSet = this.perPage(box)
  ) {
    let state = new ApplicationsState(this);
    const tempApplications = this.box(box, 'fullBox');
    tempApplications[page] = applications;
    state.boxes = this.boxes.set(box, tempApplications);
    state = this.setPage(box, page, applicationTotalSet, perPageSet, state);
    return state;
  }

  setPage(
    target: string,
    currentPageSet: number,
    applicationTotalSet = this.count(target),
    perPageSet = this.perPage(target),
    state = new ApplicationsState(this)
  ) {
    const pagination = {
      currentPage: currentPageSet,
      applicationTotal: applicationTotalSet,
      perPage: perPageSet,
    };
    state.paginations = this.paginations.set(target, pagination);
    return state;
  }

  splitInPages(applications: Application[], perPage = 15) {
    return splitIntoSubArrays(applications, perPage);
  }

  remove(ids) {
    const state = new ApplicationsState(this);
    state.boxes = this.boxes.map((box, name) => {
      const tempApplications = this.box(name, 'splat');
      const apps = tempApplications.filter(a => !ids.includes(a.id));
      return apps.length === tempApplications.length
        ? box
        : this.splitInPages(apps, this.perPage(name));
    });
    return state;
  }
}
