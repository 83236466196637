import '../../lib/globals';
import * as React from 'react';
import './application_steps.scss';

import { HiringStep, Application } from '../../__models__/models';
import Selectize from '../general/selectize/selectize';
import StepColor from './step_color';
const styles = require('./application_steps.module.scss');

export interface Props {
  currentStep: string;
  delivered: boolean;
  hiringSteps: HiringStep[];
  showLabel: boolean;
  onStepChange?: Function;
  moveToStep: Function;
  stepLabel: string;
  urls: any;
  application: Application;
}

export default class ApplicationSteps extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.itemRender = this.itemRender.bind(this);
  }

  afterSubmit(stepName, data) {
    window.Turbolinks.visit(data.application_path);
    window.Alerts.notice(data.notice);
    if (this.props.onStepChange) this.props.onStepChange(stepName);
  }

  handleChange(step) {
    if (step && step.value !== this.props.currentStep) {
      this.props.moveToStep(step, this.props.application);
    }
  }

  itemRender(step) {
    return (
      <div className={styles.step}>
        <div>
          <div className={styles.stepNumber}>{step.number + 1}</div>
          <StepColor className={styles.stepColor} slug={step.value} />
          <div className={styles.stepText}>
            <div className={styles.stepTextHeading}>{step.label}</div>
            {step.sublabel && this.props.showLabel && (
              <div className={styles.stepTextSubHeading}>{step.sublabel}</div>
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const items = this.props.hiringSteps;
    const selected = [this.props.currentStep];

    if (!this.props.delivered) {
      return null;
    }

    return (
      <Selectize
        className={styles.main}
        items={items}
        itemRender={this.itemRender}
        onChange={this.handleChange}
        selected={selected}
        selectedRender={this.itemRender}
      />
    );
  }
}
