import { apolloClient } from 'lib/graphql';
import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import * as Models from '__models__/gql/all';
import { visibleTabs } from './companyPages/Tabs';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import { GET_COMPANY_GQL } from './utils/CompanyPageUtils';
import LjSpinner from 'shared/ld_spinner/LjSpinner';
const styles = require('./CompanyPage.module.scss');

interface Props {
  id: string;
}

export default function CompanyPage({ id }: Props) {
  const [activeTab, setActiveTab] = useState(null);
  const [tabs, setTabs] = useState([]);

  const { data, error, loading } = useQuery(GET_COMPANY_GQL, {
    client: apolloClient,
    onCompleted: newData => {
      const newTabs = visibleTabs(newData);
      setTabs(newTabs);
      setActiveTab(newTabs[0]);
    },
    variables: { id, authorize: true },
  });
  const setJobsTab = () => {
    setActiveTab(tabs.find(tab => tab.isJobs));
  };

  if (data?.company && activeTab) {
    const subscribed = data.person.companySubscriptions.length > 0;
    return (
      <article>
        <Header company={data.company} />
        <Navbar
          active={activeTab}
          items={tabs}
          onClick={setActiveTab}
          companyId={data.company.id}
          currentUserCompanyId={data.currentCompany?.id}
        />
        <div className={styles.body}>
          {activeTab.render(data)}
          <Sidebar
            company={data.company}
            jobs={data.jobs.nodes}
            setJobsTab={setJobsTab}
            showJobs={!activeTab.isJobs}
            subscribed={subscribed}
            user={data.currentUser}
          />
        </div>
      </article>
    );
  } else {
    return loading && <LjSpinner />;
  }
}

function Header({ company }: { company: Partial<Models.Company> }) {
  const backgroundImage = `url('${company.coverPhotoUrl}')`;

  return (
    <div className={styles.header} style={{ backgroundImage }}>
      <div className={styles.headerContent}>
        <img
          alt={`${company.name} logo`}
          className={styles.logo}
          src={company.logoUrl}
          title={company.name}
        />
        <div className={styles.headerText}>
          <h1 className={styles.title}>{company.name}</h1>
          <span className={styles.subtitle}>{company.shortPitch}</span>
        </div>
      </div>
    </div>
  );
}
