import * as React from 'react';
import CandidateSkillsInput from './candidate_skills_input';
import ProposeTag from '../propose_tag/propose_tag';
import RequiredMark from 'components/form/required_mark/required_mark';

interface Props {
  skills: any; // an array of objects like {id: 1, tag: "Rails", experienceLevel: 1 }
  proposedTags: any; // an array of objects like {id: 10, tag: "Python on Rails"}
  maximumNumberOfSkills: number;
  errors: any;
  renderSignupVersion: boolean;
  updateSkills: Function;
  readOnly: boolean;
  hideText?: boolean;
  color?: string;
  required?: boolean;
}

interface State {
  skills: any; // an array of objects like {id: 1, tag: "Rails", experienceLevel: 1 }
  proposedTags: any; // an array of objects like {id: 10, tag: "Python on Rails"}
  errors: any;
}

export default class CandidateSkillsSection extends React.Component<Props, State> {
  proposeTagComponent: any;

  constructor(props) {
    super(props);
    this.state = { ...props };
    this.handleCreateProposedTag = this.handleCreateProposedTag.bind(this);
  }

  handleCreateProposedTag(proposedTagName) {
    this.proposeTagComponent.openProposeTagForm(proposedTagName);
  }

  render() {
    if (this.props.renderSignupVersion) {
      return this.renderSignupVersion();
    } else {
      return this.renderProfileVersion();
    }
  }

  renderProfileVersion() {
    const propsCandidateSkillsInput = {
      ...this.props,
      disableSort: false,
    };
    const smallParagraphStyle = { marginBottom: 1.75 + 'rem' };
    const extraSmallParagraphStyle = {
      display: 'inline-block',
      marginLeft: 10 + 'px',
    };

    return (
      <>
        {!this.props.hideText ? (
          <>
            <p className='ld-small-paragraph'>
              Tell us all about your amazing skill set.
              <span className='ld-br-desktop'>
                Be smart and ambitious, pick the most relevant ones to showcase to a potential
                employer.
              </span>
            </p>
            <div className='ld-small-paragraph' style={smallParagraphStyle}>
              We will consider the first 5 skills as the ones you want to spend your days working
              on.
            </div>
          </>
        ) : null}
        <ProposeTag ref={ref => (this.proposeTagComponent = ref)} />
        <CandidateSkillsInput
          {...propsCandidateSkillsInput}
          createProposedTagCallback={this.handleCreateProposedTag}
        />
      </>
    );
  }

  updateSkills = skills => {
    this.setState(() => ({ skills }));
  };

  renderSignupVersion() {
    const propsCandidateSkillsInput = {
      ...this.props,
      disableSort: false,
      updateSkills: this.updateSkills,
      skills: this.state.skills,
    };
    const smallParagraphStyle = { marginBottom: 1.75 + 'rem' };
    const extraSmallParagraphStyle = {
      display: 'inline-block',
      marginLeft: 10 + 'px',
    };
    return (
      <>
        <div className='ld-wizard-formLabel lj-form-label'>
          6. Tell us a bit about your amazing set of skills!{' '}
          {this.props.required && <RequiredMark />}
        </div>
        <span className='ld-extra-small-paragraph' style={extraSmallParagraphStyle}>
          <ProposeTag ref={ref => (this.proposeTagComponent = ref)} />
        </span>
        <p className='ld-small-paragraph' style={smallParagraphStyle}>
          Be smart and ambitious, pick the most relevant ones to showcase to a potential employer.
          <br />
          We will consider the first 5 skills as the ones you want to spend your days working on.
        </p>
        <CandidateSkillsInput
          {...propsCandidateSkillsInput}
          createProposedTagCallback={this.handleCreateProposedTag}
        />
      </>
    );
  }
}
