import React from 'react';
import { SelfEvaluation } from '__models__/self_evaluation';
import CompletionBar from 'components/general/completion_bar/CompletionBar';
import Tooltip from 'components/general/tooltip/tooltip';
import { generateSpacedNotes } from 'components/general/rating_dimension/rating_dimension_item';

const styles = require('./SelfEvaluationSection.module.scss');

interface Props {
  evaluations: SelfEvaluation[];
  jobAd?: any;
  multiple: boolean;
}

export default function SelfEvaluationSection(props: Props) {
  const tipText = [
    'For details on each dimension, hover on the bar below.',
    "For further detail, tap on the candidate's name.",
  ];

  const evalTips = () => (
    <div className={styles.oneLinerTitle}>
      <div className={styles.screening}>Self-Evaluation</div>
      <Tooltip
        text={generateSpacedNotes(props.multiple ? [tipText[1]] : tipText)}
        textBelow={true}
        style={{
          backgroundColor: '#343434',
          color: 'white',
          fontSize: '0.625rem',
          lineHeight: '1.5',
          textAlign: 'left',
          blankLine: 'always',
        }}
      >
        <div className={styles.helpfulInterrogationTip}>?</div>
      </Tooltip>
    </div>
  );

  const multipleBars = () => (
    <div className={styles.completion_bar}>
      {skillsGroup('Must have skills', props.jobAd.must_have_skills)}
      {skillsGroup('Other skills', props.jobAd.other_required_skills)}
    </div>
  );

  return (
    <div className={styles.eval_container}>
      {evalTips()}
      {props.multiple ? (
        multipleBars()
      ) : (
        <div className={styles.completion_bar}>
          <CompletionBar
            title={'Skills fit overall'}
            evaluations={props.evaluations}
            mustHaveSkills={props.jobAd.must_have_skills}
            otherRequiredSkills={props.jobAd.other_required_skills}
            average
          />
        </div>
      )}
    </div>
  );

  function skillsGroup(title, skills) {
    const skillNames = skills.map(skill => skill.name);
    const matchingSkills = props.evaluations.filter(ev => skillNames.indexOf(ev.tag.name) >= 0);

    if (matchingSkills.length > 0) {
      return (
        <>
          {matchingSkills.map((ev, index) => (
            <CompletionBar key={index} title={ev.tag.name} evaluations={[ev]} average={false} />
          ))}
        </>
      );
    }
  }
}
