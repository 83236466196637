import { mount, shallow } from 'enzyme';
import OfferFilterOption from './offer_filter_option';
import React from 'react';

const opt1 = {
  label: 'label1',
  count: 1,
  id: 'id1',
  value: true,
  name: 'name1',
  help_text: 'help_test1',
};

const opt2 = {
  label: 'label2',
  count: 2,
  id: 'id2',
  value: true,
  name: 'name2',
  help_text: 'help_test2',
};

const options = [opt1, opt2];

const nestedOptions = [[opt1, opt2]];

describe('Offer Option Render', () => {
  it('shallow renders without crashing', () => {
    const wrapped = shallow(<OfferFilterOption options={options} />);
    expect(wrapped.exists()).toBe(true);
  });

  it('shallow renders without crashing', () => {
    const wrapped = shallow(
      <OfferFilterOption nestedOptions={nestedOptions} isNestedOption={true} />
    );
    expect(wrapped.exists()).toBe(true);
  });
});

describe('Nested Option elements', () => {
  it('Has a button with text', () => {
    const wrapped = mount(
      <OfferFilterOption nestedOptions={nestedOptions} isNestedOption={true} />
    );

    expect(wrapped.find('button').text()).toBe('Show 2 more');
  });

  it('Hides button after no more elements and shows elements', () => {
    const wrapped = mount(
      <OfferFilterOption nestedOptions={nestedOptions} isNestedOption={true} />
    );

    wrapped.find('button').simulate('click');

    expect(wrapped.find('.lj-inlineAccordionItem.js-slideDownItem').exists()).toBeTruthy();

    expect(wrapped.find('button').exists()).toBeFalsy();
  });
});
