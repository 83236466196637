import * as React from 'react';
import ErrorLabel from './error_label';

interface Props {
  nameRef: any;
  isInvalid: boolean;
  isEmpty: boolean;
  isLong: boolean;
  exists: boolean;
}

export default function NameErrors(props: Props) {
  return (
    <>
      {props.isEmpty && <ErrorLabel message={"This field can't be empty."} />}
      {props.isLong && (
        <ErrorLabel
          message={`Skill name cannot be longer than 50 characters. Current: ${props.nameRef.current.value.length}.`}
        />
      )}
      {props.isInvalid && <ErrorLabel message={"Skill name cannot contain ',' or ';'."} />}
      {props.exists && <ErrorLabel message={`Skill with this name already exists`} />}
    </>
  );
}
