import React from 'react';
const styles = require('./AboutCompany.module.scss');

interface Props {
  companyId: number;
  companyName: string;
  coverPhoto: string;
  logo: string;
  shortPitch: string;
}

export default function AboutCompany(props: Props) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Know more about the company</div>
      <hr className={styles.line} />
      <div className={styles.container}>
        <img
          src={props.coverPhoto}
          alt={`${props.companyName} cover photo`}
          style={{ width: '100%' }}
          title={props.companyName}
        />
        <div className={styles.textOverImage}>
          <div className={styles.logo}>
            <img src={props.logo} alt={`${props.companyName} logo`} title={props.companyName} />
          </div>
          <div className={styles.text}>
            <a className={styles.company} href={window.Routes.company(props.companyId)}>
              {props.companyName}
            </a>
            <div className={styles.pitch}>{props.shortPitch}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
