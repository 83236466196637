import React from 'react';
import { mount, shallow } from 'enzyme';
import RadioButtons from './radio_buttons';

const props = {
  selectedValue: 'value1',
  options: [
    { value: 'value1', label: 'value1' },
    { value: 'value2', label: 'value2' },
  ],
  onChange: () => undefined,
  textInsideLabel: true,
};

describe('Radio Buttons', () => {
  it('shallow renders without crashing', () => {
    const elem = <RadioButtons {...props} />;
    const wrapped = shallow(elem);
    expect(wrapped.exists()).toBe(true);
  });

  it('renders the correct number of radio buttons', () => {
    const elem = <RadioButtons {...props} />;
    const wrapped = shallow(elem);
    expect(wrapped.find('RadioButton').length).toBe(2);
  });

  it('renders the correct text of radio buttons', () => {
    const elem = <RadioButtons {...props} />;
    const wrapped = mount(elem);

    wrapped.find('RadioButton').forEach((node, index) => {
      expect(node.text()).toBe(props.options[index].label);
    });
  });
});
