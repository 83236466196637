import React, { useState } from 'react';
import { Props } from '../companyInfoForm/TeamElementsList';
import TeamMemberItem from './TeamMemberItem';

const styles = require('./TeamMemberList.module.scss');

export default function TeamMemberList(props: Props) {
  return (
    <div className={styles.teamMemberList}>
      {props.teamMembers.map(teamMember => (
        <TeamMemberItem key={teamMember.id} {...teamMember} />
      ))}
    </div>
  );
}
