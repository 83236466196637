import React from 'react';
import { mount } from 'enzyme';
import StatusInput, { Props } from './StatusInput';

const createProps = (otherProps = {}) => ({
  error: '',
  availability: '',
  handleFormChange: jest.fn,
  ...otherProps,
});

let props: Props;
let wrapped;

describe('Status Input renders correctly', () => {
  beforeAll(() => {
    props = createProps();
    wrapped = mount(<StatusInput {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('shows the label', () => {
    expect(wrapped.find('.label').text()).toEqual('What is your current employment status? *');
  });

  it('shows 3 options', () => {
    expect(wrapped.find('.toggleButton.switch')).toHaveLength(3);
  });
});

describe('Status Input shows selected option', () => {
  beforeAll(() => {
    props = createProps({ availability: 2 });
    wrapped = mount(<StatusInput {...props} />);
  });

  it('shows active class for selected', () => {
    expect(wrapped.find('.toggleButtonActive.switch').text()).toEqual(
      "I'm currently employed, but open to hear about new opportunities"
    );
  });

  it('shows other options as inactive', () => {
    expect(wrapped.find('.toggleButton.switch')).toHaveLength(2);
  });
});
