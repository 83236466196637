import * as React from 'react';

interface Props {
  setTopFilter: Function;
  waitingForScreeningCounter: number;
  readyCounter: number;
  reviewCounter: number;
  activeFilter: string;
}
class TopFilters extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  handleFilterSelect = (event, filter) => {
    event.preventDefault();
    this.props.setTopFilter(filter);
    this.setState({ activeFilter: filter });
  };

  render() {
    return (
      <div className='top-filters-container'>
        <div className='filter-labels'>
          <div
            className={this.props.activeFilter === 'waiting' ? 'label-item-active' : 'label-item'}
          >
            <div onClick={event => this.handleFilterSelect(event, 'waiting')}>
              Waiting for Screening{' '}
              <div className='badge'>{this.props.waitingForScreeningCounter}</div>
            </div>
          </div>
          <div className={this.props.activeFilter === 'inbox' ? 'label-item-active' : 'label-item'}>
            <div onClick={event => this.handleFilterSelect(event, 'inbox')}>
              Inbox <div className='badge'>{this.props.readyCounter}</div>
            </div>
          </div>

          <div
            className={this.props.activeFilter === 'reviewing' ? 'label-item-active' : 'label-item'}
          >
            <div onClick={event => this.handleFilterSelect(event, 'reviewing')}>
              In Process <div className='badge'>{this.props.reviewCounter}</div>
            </div>
          </div>
          <div className={this.props.activeFilter === 'hired' ? 'label-item-active' : 'label-item'}>
            <div onClick={event => this.handleFilterSelect(event, 'hired')}>Hired</div>
          </div>
          <div
            className={this.props.activeFilter === 'rejected' ? 'label-item-active' : 'label-item'}
          >
            <div onClick={event => this.handleFilterSelect(event, 'rejected')}>Rejected</div>
          </div>
        </div>
      </div>
    );
  }
}

export default TopFilters;
