import { apolloClient } from 'lib/graphql';
import classNames from 'classnames';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import * as React from 'react';
import SkillsInput, { Props as SkillsInputProps } from './skills_input';

const Settings = require('settings.json');

export interface Props extends SkillsInputProps {
  fieldName: string;
  formName: string;
  categoryId: number;
  updateSkills: Function;
  isBackoffice?: boolean;
}

export default class JobSkillsInput extends SkillsInput {
  tagRef: React.RefObject<any>;

  constructor(props) {
    super(props);
    this.styles = require('./job_skills_input.module.scss');
    this.addButtonColor = this.setColor(props.isBackoffice);
    this.mainColor = this.setColor(props.isBackoffice);
    this.tagRef = React.createRef();
    this.fillCounters();
  }

  seed = 0;
  generateKey() {
    return this.seed++;
  }

  addSkill(addMutation, tagOption) {
    const newSkill = { id: tagOption.value, tag: tagOption.label, count: tagOption.count };

    this.setState(state => ({
      skills: state.skills.concat(newSkill),
    }));

    this.props.updateSkills(newSkill.id);

    // Clearing the input
    this.tagRef.current.clearSelectedValue();
  }

  deleteSkill = (deleteId: number) => {
    const skillsBefore = this.state.skills;
    this.props.updateSkills(deleteId, true);
  };

  handleDelete = deleteMutation => skillOrTagId => isProposedTag => event => {
    const id = parseInt(skillOrTagId, 10);
    if (isProposedTag) {
      deleteMutation({ variables: { id } }).then(
        result => {
          if (this.handleFulfilledPromisse(result, ['deleteProposedTag'])) {
            this.findAndDeleteTag(id);
          }
        },
        reason => this.handleRejectedPromisse(reason)
      );
    } else {
      this.findAndDeleteSkill(id);
      this.props.updateSkills(id, true);
    }
  };

  // get Mutations

  getAddSkillMutation() {
    return GQL_ADD_SKILL;
  }

  fillCounters() {
    if (this.state.skills && this.state.skills.length > 0) {
      this.loadCounters().then(
        result => {
          if (
            result &&
            result.data &&
            result.data.tagsWithCounts &&
            result.data.tagsWithCounts.nodes
          ) {
            result.data.tagsWithCounts.nodes.forEach(tag => {
              this.findAndUpdateSkill(parseInt(tag.id, 10), { count: tag.count });
            });
          }
        },
        reason => {
          return;
        }
      );
    }
  }

  setColor(isBackoffice) {
    return isBackoffice ? 'tuftsBlue' : 'ripePlum';
  }

  async loadCounters() {
    const result = await apolloClient.query({
      query: GQL_QUERY_TAGS,
      variables: { ids: this.state.skills.map(skill => skill.id) },
    });
    return result;
  }

  async loadOptions(query, callback) {
    const result = await apolloClient.query({
      query: GQL_QUERY_TAGS,
      variables: { query },
    });
    return result.data.tagsWithCounts.nodes.map(tag => ({
      label: tag.name,
      value: tag.id,
      count: tag.count,
    }));
  }

  sortSkills(orderedSkillsAndTagsIds) {
    /*
     * We are going to filter the skills and only update their position
     * The proposed tags can't be ordered
     */
    const orderedSkills = [];
    const orderedSkillsIds = [];
    orderedSkillsAndTagsIds.forEach(id => {
      const skill = this.state.skills.find(s => s.id === id);
      if (skill != null) {
        orderedSkillsIds.push(id);
        orderedSkills.push(skill);
      }
    });

    this.setState(state => ({
      skills: orderedSkills,
    }));
  }

  // PubSub
  addProposedTag(proposedTag) {
    if (proposedTag.categoryId === (this.props as Props).categoryId) {
      this.setState(state => ({
        proposedTags: state.proposedTags.concat({ id: proposedTag.id, tag: proposedTag.name }),
      }));
    }
  }

  // renders

  renderSkillOrTag(skillOrTag, isProposedTag = false, isBackoffice = false) {
    const fieldName = (this.props as Props).fieldName || 'tag_ids';
    const formName = (this.props as Props).formName || 'job_ad_form';
    return (
      <li
        // eslint-disable-next-line react/no-unknown-property
        skill-id={skillOrTag.id}
        key={this.generateKey()}
        className={this.setSkillClassNames(isProposedTag, isBackoffice)}
      >
        {!isProposedTag ? (
          <input type='hidden' name={formName + '[' + fieldName + '][]'} value={skillOrTag.id} />
        ) : null}
        <Mutation mutation={isProposedTag ? this.getDeleteProposedTagMutation() : GQL_DELETE_SKILL}>
          {(deleteMutation, { loading, data = null, error = null }) => (
            <a
              className={this.styles.close}
              onClick={this.handleDelete(deleteMutation)(skillOrTag.id)(isProposedTag)}
            />
          )}
        </Mutation>
        <div className={this.styles.skillName}>
          {skillOrTag.tag}
          {isProposedTag ? (
            <span className={this.styles.proposedTagStatus}> - Waiting for approval</span>
          ) : null}
        </div>
        {!isProposedTag ? <div className={this.styles.skillStats}>{skillOrTag.count}</div> : null}
      </li>
    );
  }

  setSkillClassNames(isProposedTag, isBackoffice) {
    if (isProposedTag) {
      return classNames(this.styles.proposedTag, 'disabled');
    } else if (isBackoffice) {
      return classNames(this.styles.skill, this.styles.isBackoffice);
    } else {
      return this.styles.skill;
    }
  }
}

const GQL_QUERY_TAGS = gql`
  query ($query: String, $ids: [ID!]) {
    tagsWithCounts(query: $query, ids: $ids) {
      nodes {
        id
        name
        count
      }
    }
  }
`;

const GQL_ADD_SKILL = gql`
  mutation ($id: ID!, $tagId: ID!) {
    job {
      skillCreate(id: $id, tagId: $tagId) {
        errors {
          field
          message
        }
        resource {
          id
        }
      }
    }
  }
`;

const GQL_DELETE_SKILL = gql`
  mutation ($id: ID!) {
    candidate {
      skillDelete(id: $id) {
        errors {
          field
          message
        }
      }
    }
  }
`;
