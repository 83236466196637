import * as React from 'react';
import ApplicationList, { Props } from './application_list';
import { shallow, ShallowWrapper } from 'enzyme';
import { ApplicationsState } from '../employer_applications/applications_state';
import applicationDummy from '__fixtures__/application_dummy';

const dummyAppsState = new ApplicationsState({});

const createTestProps = moreProps => {
  return {
    applications: [applicationDummy],
    splatApplications: [applicationDummy, applicationDummy],
    bulkMoveToStep: jest.fn(),
    disengaged: false,
    bulkReject: jest.fn(),
    className: '',
    chatUrl: '',
    contactsUrl: '',
    hiringSteps: [
      {
        description: '',
        hasApplications: true,
        id: 1,
        jobId: 1,
        kind: '',
        message: {
          id: 2,
          text: '',
        },
        name: '',
        order: 1,
        slug: '',
      },
    ],
    moveToStep: jest.fn(),
    rejectApplication: jest.fn(),
    selectedJobId: '',
    setApplication: jest.fn(),
    topFilter: '',
    waitingForScreeningCounter: 20,
    urls: {
      bulkMoveToReviewing: '',
      bulkMoveToStep: '',
      bulkMoveToProposal: '',
      bulkReject: '',
      rateApplication: '',
    },
    loadMoreApplications: jest.fn(),
    appsState: dummyAppsState,
    shouldGroupApplications: jest.fn(),
    ...moreProps,
  };
};

let props: Props;
let wrapped: ShallowWrapper;

describe('Application List component', () => {
  beforeEach(() => {
    props = createTestProps({});
    wrapped = shallow(<ApplicationList {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
