const ongoingJobDummy = {
  id: '8059',
  type: 'job_ad',
  attributes: {
    title: 'Brand Ambassador',
    state: 10,
    city: 'Lisbon',
    location: 'Lisbon, Portugal',
    expires_days: 221,
    closed_at: null,
    created_at: '2019-04-22T17:28:54.688Z',
    company_id: 8,
    updated_at: '2019-05-14T16:27:35.965Z',
    status_label: 'Published',
    urls: {
      job_ad_ongoing: '/at/landing-jobs/brand-ambassador',
      job_ad_draft: '/post/brand-ambassador/submit_offer',
      job_ad_archived: '/employer/jobs/brand-ambassador/preview',
      job_ad_publish: '/employer/jobs/brand-ambassador/publish',
      job_ad_unpublish: '/employer/jobs/brand-ambassador/unpublish',
      job_ad_close: '/employer/jobs/brand-ambassador/close',
      job_ad_duplicate: '/post/from/offer?offer_id=8059',
      job_edit_url: '/employer/jobs/brand-ambassador/revisions/new',
      revision_edit_url: '/employer/jobs/brand-ambassador/edit',
      ats_pair_url: '/employer/jobs/8059/ats/new',
      ats_unpair_url: '/employer/jobs/8059/ats',
      send_to_review_url: '/employer/jobs/brand-ambassador/send_to_review',
      delete_job_url: '/employer/jobs/8059',
    },
    share_urls: {
      twitter_url:
        'https://twitter.com/intent/tweet?hashtags=techjobs&text=Hey%21+%40Landing_jobs+is+looking+for+a+Brand+Ambassador.&url=http%3A%2F%2Flocalhost%3A3000%2Fjobs%2F8059%2Fshare%2FXA1XV-sZTyOACQD_AHJWMQ%3Futm_campaign%3Djob%2Boffer%2Bshare%26utm_content%3DBrand%2BAmbassador%26utm_medium%3Dsocial%26utm_source%3Dtwitter&via=Landing_jobs',
      facebook_url:
        'https://www.facebook.com/dialog/feed?app_id=594024530633157&caption=%23techjobs+%23back-end_developer&description=&display=popup&link=http%3A%2F%2Flocalhost%3A3000%2Fjobs%2F8059%2Fshare%2FXA1XV-sZTyOACQD_AHJWMQ%3Futm_campaign%3Djob%2Boffer%2Bshare%26utm_content%3DBrand%2BAmbassador%26utm_medium%3Dsocial%26utm_source%3Dfacebook&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Femployer%2Fjobs',
      linkedin_url:
        'http://www.linkedin.com/shareArticle?mini=true&summary=%3Cp%3EHere+at+Landing+we+believe+that+individuals+must+have+ownership+of+their+professional+careers%2C...+%23techjobs&title=Brand+Ambassador+%40+Landing.jobs&url=http%3A%2F%2Flocalhost%3A3000%2Fjobs%2F8059%2Fshare%2FXA1XV-sZTyOACQD_AHJWMQ%3Futm_campaign%3Djob%2Boffer%2Bshare%26utm_content%3DBrand%2BAmbassador%26utm_medium%3Dsocial%26utm_source%3Dlinkedin',
    },
    job_has_ats_offer: false,
    company_has_ats: false,
    country: 'Portugal',
    applications_counter: 22,
    status_color_class: 'ld-status-color u-fill--puertoRico ',
    valid: null,
  },
};

export default ongoingJobDummy;
