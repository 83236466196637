import * as React from 'react';
import SkillsDimension, { getInitialSkills, Props } from './skills_dimension';
import { shallow, ShallowWrapper } from 'enzyme';
import curatorsApplicationDummy from '__fixtures__/curators_application_dummy';
import curatorsTalentUserDummy from '__fixtures__/curators_talent_user_dummy';

describe('Skills Dimension component', () => {
  let wrapped: ShallowWrapper;

  beforeEach(() => {
    const application = curatorsApplicationDummy.data.attributes;
    const talentUser = curatorsTalentUserDummy.data.attributes;

    wrapped = shallow(
      <SkillsDimension
        application={curatorsApplicationDummy.data.attributes}
        rating={3}
        skills={getInitialSkills({ application, talentUser })}
        setNotes={jest.fn()}
        setRating={jest.fn()}
        setSkills={jest.fn()}
        user={talentUser}
      />
    );
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});
