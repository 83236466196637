import { postJSON } from 'lib/request_deprecated';

class RejectionForm {
  /**
   * Form object for the Rejection Modal,
   * It handles validation and assignemnt of the variables and prepares
   * the form for sending to Rails
   */

  applicationId: number;
  application?: any;
  applications?: number[];
  applicationState: string;
  candidateMessage: string;
  employerRating: number;
  experience: number;
  language: number;
  location: number;
  errors: any;
  landingMessage: string;
  reason: string;
  reasonOther: string;
  skills: number;
  url: string;
  dimensions: any;
  rejectionDimensionsMapping: any;
  isMeetRequirements: boolean;

  constructor(args) {
    this.update(args);
  }

  update(args) {
    this.errors = {};
    Object.keys(args).forEach(k => (this[k] = args[k]));
  }

  serialize() {
    return {
      rejection: {
        reason: this.reasonSerializer(this.reason),
        reason_other: this.reasonOther,
        landing_message: this.landingMessage,
        candidate_message: this.candidateMessage,
      },
      rating: {
        employer_rating: this.employerRating,
        landing_message: this.landingMessage,
      },
    };
  }

  validate() {
    this.errors = {};

    // There must be either a reason or a reasonOther
    if (!this.reason) {
      this.errors['reason'] = 'Needs a valid reason';
    }

    if (this.reason === 'Other' && !this.reasonOther) {
      this.errors['reasonOther'] = 'Please specify the reason';
    }

    // The application ID cannot be null
    if (!this.applicationId) {
      this.errors['application'] = 'Needs an application id';
    }

    if (this.requiresLandingMessage() && !this.landingMessage) {
      this.errors['landing_message'] = 'Feedback for Landing.Jobs cannot be empty';
    }

    if (Object.keys(this.errors).length > 0) {
      return false;
    } else {
      return true;
    }
  }

  save() {
    if (this.validate()) {
      window.toggleSpinner(true);
      if (this.applications) {
        return postJSON(
          this.url.replace('~id', String(this.applications[0])) + `&ids=${this.applications}`,
          this.serialize()
        );
      } else {
        return postJSON(this.url, this.serialize());
      }
    }
  }

  requiresLandingMessage() {
    return this.reasonMatchDimension() && this.isMeetRequirements;
  }

  reasonMatchDimension() {
    if (this.reason && this.dimensions) {
      const rejectionDimension = this.inboxToDimensionMapping(this.reasonSerializer(this.reason));
      return this.dimensions.some(obj => obj.dimension === rejectionDimension && obj.value >= 4);
    }
  }

  inboxToDimensionMapping(reason) {
    const dimensions = this.rejectionDimensionsMapping;
    return Object.keys(dimensions).find(key => dimensions[key].join().match(reason));
  }

  inboxReasons() {
    return [
      'Location mismatch',
      'No local citizenship or valid work permit',
      'Lacks proficiency in a required language',
      'Not the right set of skills',
      'Not the adequate level of experience',
      'Other',
    ];
  }

  reasons() {
    return [
      'Location mismatch',
      'No local citizenship or valid work permit',
      'Lacks proficiency in a required language',
      'Not the right set of skills',
      'Not the adequate level of experience',
      'Unresponsive',
      'The candidate is no longer looking for a job',
      "Tech Assessment didn't meet the requirements",
      'Other',
    ];
  }

  reasonSerializer(reason) {
    const dictionary = {
      'Location mismatch': 'candidate_doesnt_live_within_a_commuting_distance',
      'No local citizenship or valid work permit': 'no_local_citizenship_or_valid_work_permit',
      'Lacks proficiency in a required language': 'lacks_proficiency_in_a_required_language',
      'Not the right set of skills': 'not_enough_skills',
      'Not the adequate level of experience': 'not_enough_work_experience',
      "Doesn't meet the required degree level": 'lacks_a_bachelor_or_higher_academic_degree',
      'Proceeding with other Applications': 'proceeding_with_other_applications',
      Unresponsive: 'candidate_didnt_reply_to_contact',
      'The candidate is no longer looking for a job': 'candidate_is_no_longer_looking_for_a_job',
      "Tech Assessment didn't meet the requirements": 'tech_assessment_didnt_meet_requirements',
      Other: 'other',
    };

    return dictionary[reason];
  }
}

export default RejectionForm;
