/**
 * Manage search and display when selecting an ats offer to sync with
 */

const onmount = require('onmount');

function mountAtsOfferSyncPage() {
  function search(ev) {
    const cards = document.querySelectorAll('.job #ats_offer_title');

    cards.forEach((card: any) => {
      if (card.value.toLowerCase().includes((ev.target as any).value.toLowerCase())) {
        $(card.parentElement).slideDown(500);
      } else {
        $(card.parentElement).slideUp(500);
      }
    });
  }

  const searchBar = document.getElementById('js-searchAtsOffer');

  searchBar.addEventListener('submit', ev => ev.preventDefault());
  searchBar.addEventListener('change', ev => search(ev));
  searchBar.addEventListener('keyup', ev => search(ev));
}

onmount('#js-atsOfferSync', mountAtsOfferSyncPage);
