import * as React from 'react';
import IntegrationButton from '../ats/token_button';

interface Props {
  url: string;
}

export default function Button(props: Props) {
  return (
    <IntegrationButton
      url={props.url}
      atsName={'Teamtailor'}
      atsPathToCreateToken={'Settings > API keys'}
      atsAPIDocUrl={'https://docs.teamtailor.com/#authentication'}
    />
  );
}
