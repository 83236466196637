import React from 'react';
import Button from 'lj_shared/button/button';
import { shallow, ShallowWrapper } from 'enzyme';
import PublishFeatureItem from './PublishFeatureItem';

const createProps = () => ({ feature: { id: 177013 } });

let props;
let wrapped: ShallowWrapper;

describe('Public Feature Item', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<PublishFeatureItem {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('contains 2 Buttons', () => {
    expect(wrapped.find(Button).length).toEqual(2);
  });
});

test.todo('add more tests');
