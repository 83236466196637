import React, { useState } from 'react';
import { round } from 'lib/number';
import Accordion from 'components/general/accordion/accordion';

const styles = require('./DataForCompanies.module.scss');

interface Props {
  activeJobs: any[];
  averageDuration: Function;
  averageDurationHeader: Function;
  closedJobs: any[];
  dataByJob: any;
}

export default function AverageDurationForJobs(props: Props) {
  const { activeJobs, averageDuration, averageDurationHeader, closedJobs, dataByJob } = props;

  const jobDurationSection = (job, isDeleted = false) => {
    const jobAttributes = dataByJob.filter(funnelJob => funnelJob.funnel.jobId === job.id)[0];
    const hsm = [
      ['Days to Hire', round(jobAttributes.speedMetrics.daysToHire?.average, 1) || '-'],
      ['Days to Offer', round(jobAttributes.speedMetrics.daysToOffer?.average, 1) || '-'],
      ['Days to Reject', round(jobAttributes.speedMetrics.daysToReject?.average, 1) || '-'],
    ];
    const toi = [
      ['Days to Review', round(jobAttributes.speedMetrics.daysToReview?.average, 1) || '-'],
      ['Days to Engage', round(jobAttributes.speedMetrics.daysToEngage?.average, 1) || '-'],
    ];

    return (
      <div className={styles.durationCard} key={`${job.title}${job.id}`}>
        <div className={styles.jobAdTitle}>{job.title}</div>
        {averageDuration({ hsm, toi }, false, isDeleted)}
      </div>
    );
  };

  return (
    <div>
      {averageDurationHeader(
        <>
          {activeJobs.map(job => jobDurationSection(job))}
          <Accordion title={'Closed Job Ads'} headerClassName={styles.hideClosedJobs} filterList>
            {closedJobs.map(closedJob => jobDurationSection(closedJob, true))}
          </Accordion>
        </>
      )}
    </div>
  );
}
