import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import CardAvatarContainer from './CardAvatarContainer';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import Tooltip from 'components/general/tooltip/tooltip';

const completeness = 70;
const createProps = () => ({
  completeness,
  incompleteFields: [
    { name: 'Relocation', star: false },
    { name: 'Phone Number', star: true },
  ],
});

let props;
let wrapped: ShallowWrapper;

describe('Your description here', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<CardAvatarContainer {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('has circular bar', () => {
    expect(wrapped.find(CircularProgressbarWithChildren).length).toBe(1);
  });

  it('has avatar', () => {
    expect(wrapped.find('img').length).toBe(1);
  });

  it('has correct completeness span', () => {
    expect(wrapped.find('.completeness').text()).toBe(`${completeness}% Profile Completeness`);
  });

  it('has a tooltip', () => {
    expect(wrapped.find(Tooltip).length).toBe(1);
  });
});

test.todo('add more tests');
