import classNames from 'classnames';
import Icon, { Size } from 'components/general/icon/icon';
import React, { useCallback, useEffect, useRef, useState } from 'react';
const styles = require('./BackToTop.module.scss');

interface Props {
  target?: Element;
}

const topFunction = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
};

const BackToTop = (props: Props) => {
  const { target } = props;
  const [show, setShow] = useState(false);
  const componentMounted = useRef(true);
  const hasHubspotPopup = document.getElementById('hubspot-messages-iframe-container') !== null;

  const obCallback = useCallback((payload: { isIntersecting: boolean }[]) => {
    if (!componentMounted.current) {
      return;
    }

    if (payload[0].isIntersecting === true) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, []);

  useEffect(() => {
    if (target) {
      const ob = new IntersectionObserver(obCallback, {
        threshold: 0.2,
      });
      ob.observe(target as Element);
    }
    return () => {
      componentMounted.current = false;
    };
  }, [obCallback]);

  return (
    <div
      id='back-to-top-button'
      onClick={topFunction}
      className={classNames(
        styles.backToTopContainer,
        !show && styles.hide,
        hasHubspotPopup && styles.hasHubspotPopup
      )}
    >
      <Icon name='chevronUp' className={styles.button} color='white' />
    </div>
  );
};

export default BackToTop;
