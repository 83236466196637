import * as React from 'react';
import { mount } from 'enzyme';
import HandshakeCard, { DefaultCard } from './handshake_card';
import Icon from 'components/general/icon/icon';

let props: DefaultCard;
let wrapped = null;

const createTestProps = () => {
  return {
    title: <span>this is a title'</span>,
    body: 'this is a body',
    buttons: <a>this is a test element</a>,
  };
};

describe('Accept HandshakeCard component', () => {
  beforeAll(() => {
    props = createTestProps();
    wrapped = mount(<HandshakeCard {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('contains 1 Icon', () => {
    expect(wrapped.find(Icon).length).toEqual(1);
  });
});
