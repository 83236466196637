import React from 'react';
import TextInput from 'components/form/text/text';
import Select from 'components/form/select/select';
import Button from 'lj_shared/button/button';

const styles = require('./add_website.module.scss');

interface Props {
  networks: { id: number; name: string; base_url: string; value: string }[];
  handleAddNetworkClick: Function;
}

interface State {
  category: { label: string; value: string };
  url: string;
}

class AddWebsite extends React.Component<Props, State> {
  private input: any;

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.input = React.createRef();

    this.state = {
      category: { label: '', value: '' },
      url: '',
    };
  }

  handleSubmit(event) {
    if (event.type === 'keydown') {
      if (this.state.category.value === '' || this.state.url === '') {
        return;
      } else {
        if (event.keyCode !== 13) {
          return;
        } else {
          event.preventDefault();
        }
      }
    }
    if (this.state.category && this.state.url) {
      this.props.handleAddNetworkClick({
        category: this.state.category.value,
        url: this.state.url,
      });

      this.setState({ category: { label: '', value: '' }, url: '' });
    } else {
      window.Alerts.alert('Please fill in all the details');
    }
  }

  handleCategoryChange(event) {
    const emptyEvent = JSON.stringify({ label: '', value: '' }) === JSON.stringify(event);
    if (event === null || !Object.keys(event) || emptyEvent) {
      return this.setState(() => ({ category: { label: '', value: '' }, url: '' }));
    }
    const network = this.props.networks.find(n => n.value === event.value);
    this.setState({
      category: { value: event.value, label: event.label },
      url: network.base_url,
    });
  }

  render() {
    const networks = this.props.networks.map(({ id, value, name }) => ({
      id,
      value,
      label: name,
    }));

    return (
      <div className={styles.externalContainer}>
        <div className={styles.links}>
          <div className={styles.networks}>
            <Select
              name='links'
              placeholder='Network'
              options={networks}
              color='silverSand'
              value={this.state.category}
              onChange={this.handleCategoryChange}
            />
          </div>
          <div className={styles.networksLink}>
            <TextInput
              name='links'
              id='links'
              placeholder='e.g. www.linkedin.com/jon_doe'
              color='tuftsBlue'
              value={this.state.url}
              controlled
              onChange={e => this.setState({ url: e.target.value })}
              inputRef={this.input}
              onKeyDown={this.handleSubmit}
            />
          </div>
          <div className={styles.networksBtn}>
            <Button buttonColor='tuftsBlue' onClick={this.handleSubmit}>
              ADD
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
export default AddWebsite;
