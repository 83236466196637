import React from 'react';

import Icon, { Size } from 'components/general/icon/icon';
const styles = require('./outdated_warning.module.scss');

export default function OutdatedWarning(warning: string) {
  return (
    <div className={styles.warnBox}>
      <Icon color='ripePlum' size={Size.Medium} name='warning' />
      <div className={styles.warnLabel}>{warning}</div>
    </div>
  );
}
