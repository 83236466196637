import React, { Component } from 'react';

export default function Deprecated<Props, State>(InnerComponent, msg = '') {
  // eslint-disable-next-line react/display-name
  const component = class extends Component<Props, State> {
    UNSAFE_componentWillMount() {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.warn(`${InnerComponent.name} is deprecated.`, msg);
      }
    }

    render() {
      return <InnerComponent {...this.props} />;
    }
  };

  Object.defineProperty(component, 'name', { value: 'Deprecated' });
  return component;
}
