import * as React from 'react';
import MessageContainer from './message_container';
import { shallow, ShallowWrapper } from 'enzyme';

let wrapped: ShallowWrapper;

describe('Message Container component', () => {
  beforeEach(() => {
    wrapped = shallow(<MessageContainer title='Container' icon='container_icon' />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
