import classNames from 'classnames';
import React from 'react';

import { Job } from '__models__/gql/job';
import Icon, { Size } from 'components/general/icon/icon';
import OfficeLocationsTooltip from 'components/office_locations_tooltip/office_locations_tooltip';
const styles = require('./Main.module.scss');

interface Props {
  job: Partial<Job>;
  mini?: boolean;
}

export default function Main({ job, mini }: Props) {
  return (
    <div className={classNames(styles.main, { [styles.isMini]: mini })}>
      <Title job={job} />
      <div className={styles.details}>
        {!mini && <Company company={job.company} />}
        <DetailItem icon={job.locationIcon}>
          {job.location || job.remotePolicy}
          {job.officeLocations.length > 1 && (
            <OfficeLocationsTooltip
              officeLocations={job.officeLocations}
              iconColor={'silverSand'}
              iconSize={Size.Medium}
              className={mini ? 'sidebar-job-location' : 'company-main-page-job-location'}
            />
          )}
        </DetailItem>
        {!mini && <DetailItem icon='signal'>{job.experienceLevelLabel}</DetailItem>}
      </div>

      {job.salary && <div className={styles.salary}>{job.salary}</div>}
      {!mini && <Skills job={job} />}
    </div>
  );
}

function Title({ job }) {
  return (
    <a
      className={styles.title}
      href={window.Routes.company_job_ad(job.company.slug || job.company.id, job.slug)}
    >
      {job.title}
    </a>
  );
}

function Company({ company }) {
  return (
    <div className={styles.detailsItem}>
      <a
        className={styles.company}
        href={window.Routes.company(company.slug || company.id)}
        target='_blank'
        rel='noreferrer'
      >
        {company.name}
      </a>
    </div>
  );
}

function DetailItem({ icon, children }) {
  return (
    <div className={styles.detailsItem}>
      <Icon name={icon} display={'inlineFlexCenter'} />
      <div className={styles.detailsItemText}>{children}</div>
    </div>
  );
}

function Skills({ job }) {
  return (
    <div className={styles.skills}>
      {job.tags.map(tag => (
        <div className={styles.skill} key={tag.id}>
          {tag.name}
        </div>
      ))}
    </div>
  );
}
