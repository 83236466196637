import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import AfterHandshakeModal from './after_handshake_modal';

window.Routes = {
  dashboard_applications: () => '',
  user_profile: () => '',
  jobs_search: () => '',
};

const createProps = () => ({
  handshakeAccepted: true,
  companyName: 'LandingJobs',
  jobTitle: 'Pokemon Master',
});

let props;
let wrapped: ShallowWrapper;

describe('AfterHandshakeModal', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<AfterHandshakeModal {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});
