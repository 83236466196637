import React from 'react';
import { shallow } from 'enzyme';
import Checkbox from 'components/form/checkbox/checkbox';
import InputWrapper from './InputWrapper';

describe('InputWrapper', () => {
  it('shallow renders without crashing', () => {
    const wrapped = shallow(<InputWrapper input={Checkbox} />);
    expect(wrapped.exists()).toBe(true);
  });
});
