import { shallow } from 'enzyme';
import * as React from 'react';
import Textarea from './textarea';

describe('<Textarea>', () => {
  it('shallow renders without crashing', () => {
    const wrapped = shallow(<Textarea />);
    expect(wrapped.exists()).toBe(true);
  });
});
