import Button from './button';
import React from 'react';
import track from '../../../lib/drip';

const styles = require('./benefits.module.scss');
const contractorsImg = require('../images/star-purple.svg');
const placementImg = require('../images/arrow-purple.svg');
const middlemanImg = require('../images/check-purple.svg');

interface Benefit {
  title: [string];
  description: string;
  startButton: string;
}
interface Props {
  title: any;
  benefit1: Benefit;
  benefit2: Benefit;
  benefit3: Benefit;
  buttonSubtext: string;
  startButton: Benefit;
}

const Benefits: React.FunctionComponent<Props> = props => {
  const displayBenefits = (benefit, imageSrc) => {
    return (
      <div className={styles.textSubContainer}>
        <div className={styles.textSubTop}>
          <img src={imageSrc} />
          <div className={styles.subTopTitle}>
            <span className={styles.topTitle}>{benefit.title[0]}</span>
            <span>{benefit.title[1]}</span>
          </div>
        </div>
        <p className={styles.description}>{benefit.description}</p>
      </div>
    );
  };

  return (
    <div className={styles.benefitsContainer}>
      <div className={styles.benefitsTitle}>{props.title}</div>
      <div className={styles.textContainer}>
        {displayBenefits(props.benefit1, contractorsImg)}
        {displayBenefits(props.benefit2, middlemanImg)}
        {displayBenefits(props.benefit3, placementImg)}
      </div>
      <div className={styles['midButtons-wrapper']}>
        <div className={styles.benefitBtn}>
          <Button
            onClick={() =>
              track('action', {
                name: 'work-homepage-click',
                controller: 'work',
                action: 'benefits-get-started',
              })
            }
            isRound={true}
            otherClasses={styles['midButtons-button']}
            buttonColor='ripePlum'
            buttonSize='mid'
            isButton={false}
            buttonUrl='/employer/join'
          >
            {props.startButton}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
