import * as React from 'react';
import { SelfEvaluationItem } from '../../application_page/SelfEvaluationItem';
const styles = require('../../../curators/SelfEvaluationContainer.module.scss');

export interface Props {
  selfEvaluations: any;
  job: any;
}

export default class SelfEvaluations extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.title}>Self-Evaluation</div>
        <div className={styles.subTitle}>
          Candidate's self-evaluation on their fit to job requirements
        </div>
        {SelfEvaluationItem(this.props.selfEvaluations, this.props.job)}
      </div>
    );
  }
}
