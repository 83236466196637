import React, { useState } from 'react';
import * as Cookies from 'js-cookie';
import './languages_menu.scss';
import classNames from 'classnames';
const chevron = require('../../../images/icons/chevron.svg');

interface Language {
  short: string;
  country: string;
  language: string;
}

const LanguagesMenu = () => {
  const [open, toggleMenu] = useState(false);
  const [language, setLanguage] = useState(Cookies.get('locale') || 'en');

  const updateLanguages = () => {
    const mainLanguages: Language[] = [
      { short: 'en', country: 'UK', language: 'English' },
      { short: 'pt', country: 'Portugal', language: 'Português' },
    ];
    const otherLangs: Language[] = [
      { short: 'de', country: 'Germany', language: 'Deutsch' },
      { short: 'es', country: 'Spain', language: 'Español' },
    ];
    return window.location.href.includes('remoteworking')
      ? mainLanguages
      : mainLanguages.concat(otherLangs);
  };

  const languages = updateLanguages();

  const getCountry = (lang): string => {
    return languages.find(l => l.short === lang).country;
  };

  const getImage = (lang): string => {
    return require(`../../../images/${lang}.svg`);
  };

  const changeLanguage = (lang): void => {
    setLanguage(lang);
    Cookies.set('locale', lang, { expires: 30 });
  };

  return (
    <div onClick={() => toggleMenu(!open)} className='toggle-menu'>
      <div className='topBar-languagesMenu'>
        <div className='languageFlag'>
          <img
            className='flag'
            src={getImage(language)}
            alt={getCountry(language)}
            title={getCountry(language)}
          />
          <span>{languages.find(l => l.short === language).language}</span>
        </div>
        <img className='chevron' src={chevron} alt='arrow' />
      </div>
      {open && (
        <div className='languagesMenu'>
          {languages.map(lang => {
            return (
              <a
                key={lang.short}
                href={window.location.href.replace(/locale=[a-z]{2}/, '')} // reseting the locale query param
                className={classNames('languageOption', lang.short)}
                onClick={() => changeLanguage(lang.short)}
              >
                <img className='flag' src={getImage(lang.short)} alt={lang.country} />
                <div className='languageName'>{lang.language}</div>
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default LanguagesMenu;
