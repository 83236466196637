import classNames from 'classnames';
import React, { useLayoutEffect, useRef } from 'react';
import Spinner from 'shared/dot_spinner/DotSpinner';

const styles = require('./loading_overlay.module.scss');

interface Props {
  active: boolean;
  children: any;
}

export default function LoadingOverlay(props: Props) {
  const { active, children } = props;
  const childRef = useRef(null);
  const overlayRef = useRef(null);
  const containerClass = styles.container;
  const overlayClass = classNames(styles.overlay, {
    [styles.isActive]: active,
  });

  useLayoutEffect(() => {
    const overlay = overlayRef.current;
    if (overlay) {
      overlay.style.height = `${childRef.current.offsetHeight}px`;
      overlay.style.width = `${childRef.current.offsetWidth}px`;
    }
  }, [children]);

  return (
    <div className={containerClass}>
      <div ref={childRef}>{children}</div>
      <div className={classNames(overlayClass, styles.animation)} ref={overlayRef}>
        <div>
          <Spinner />
        </div>
      </div>
    </div>
  );
}
