import Icon, { Size } from 'components/general/icon/icon';
import React from 'react';
const styles = require('./application_started_toast.module.scss');

export default function ApplicationStartedToast() {
  return (
    <div className={styles.container}>
      <Icon name='starHalfFull' size={Size.Medium} display={'inlineFlexStart'} />
      <span className={styles.text}>Hooray, you already started this application</span>
    </div>
  );
}
