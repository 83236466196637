import React from 'react';
import AlertBox from '../../general/alert_box/alert_box';
import TechnicalTestInformation from './technical_test_information';
import Tag from '../../general/tag/tag';

const styles = require('./test_notice.module.scss');

export default class TestNotice extends React.Component {
  constructor(props) {
    super(props);

    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    TechnicalTestInformation.show();
  }

  render() {
    return (
      <AlertBox>
        <div className={styles.wrapper}>
          <div className={styles.header}>Technical Test Available</div>
          <Tag color='ripePlum' title='New' />
          <div className={styles.paragraph}>
            We will help you assess your candidates' technical skills with our custom technical
            tests{' '}
            <a className={styles.link} href='javascript:void(0)' onClick={this.openModal}>
              Know more!
            </a>
          </div>
        </div>
      </AlertBox>
    );
  }
}
