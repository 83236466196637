import React from 'react';
import renderer from 'react-test-renderer';
import { render } from '@testing-library/react';
import JobList from './JobList';

const props = {
  jobList: [
    {
      value: 1,
      label: 'job',
      location: 'location',
      daysRemaining: 'daysRemaining',
      expectedNumberOfHires: 'expectedNumberOfHires',
    },
    {
      value: 2,
      label: 'job2',
      location: 'location2',
      daysRemaining: 'daysRemaining2',
      expectedNumberOfHires: 'expectedNumberOfHires2',
    },
  ],
  submitError: false,
  job: 1,
  setJob: jest.fn(),
};

describe('JobList', () => {
  it('should render correctly', () => {
    const wrapper = renderer.create(<JobList {...props} />);
    expect(wrapper).toMatchSnapshot();

    expect(wrapper.root.findByProps({ 'data-testid': 'job-list-container' })).toBeTruthy();

    expect(wrapper.root.findByProps({ 'data-testid': 'job-list' }).props.children).toHaveLength(
      props.jobList.length
    );
  });

  it('should render submit error', () => {
    const { getByTestId } = render(<JobList {...props} submitError={true} />);

    expect(getByTestId('job-list-container').querySelectorAll('.submitError')).toBeTruthy();
  });
});
