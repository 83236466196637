import * as React from 'react';
import CookieDisclaimer from './cookie_disclaimer';
import { shallow, ShallowWrapper } from 'enzyme';

let wrapped: ShallowWrapper;

describe('Ask Notifications Permission component', () => {
  beforeEach(() => {
    wrapped = shallow(<CookieDisclaimer privacy_path={'/privacy'} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
