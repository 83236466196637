import React from 'react';
import { shallow, ShallowWrapper, mount } from 'enzyme';
import GenericSelectRange, { Props } from './GenericSelectRange';
import Select from 'components/form/select/select';
import FormInput from 'components/general/form_input/form_input';

const createProps = (): Props => ({
  formInputLabel: 'This is the label',
  formInputName: 'input_name',
  formInputSubLabel: '(and this is the sublabel)',
  handleFormChange: jest.fn(),
  placeholder: 'e.g. something',
  profileAttribute: 'attribute',
  range: { minimum: 10, maximum: 20 },
  rangesList: [{ minimum: 0, maximum: 10 }, { minimum: 10, maximum: 20 }, { minimum: 20 }],
});

let props;
let wrapped: any;

describe('Generic component for selecting a range', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = mount(<GenericSelectRange {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('contains a form input component', () => {
    expect(wrapped.contains(FormInput)).toBeTruthy();
  });

  it('contains a select component', () => {
    expect(wrapped.contains(Select)).toBeTruthy();
  });
});

test.todo('add more tests');
