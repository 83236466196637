class PostWizard {
  constructor() {
    const step = (document.querySelector('.lj-wizard-post') as HTMLElement).dataset.wizardStep;

    switch (step) {
      case 'generalInformation':
        this.generalInformation();
        break;
      case 'candidateBackground':
        this.candidateBackground();
        break;
      case 'jobDescription':
        this.jobDescription();
        break;
      case 'locationDetails':
        this.locationDetails();
        break;
      case 'additionalInfo':
        this.additionalInfo();
        break;
      default:
        break;
    }
  }

  static handleLocationChange(newLocationCount = null) {
    const workPermitRelocationBlock = document.getElementById(
      'work-permit-and-relocation-block'
    ) as HTMLInputElement;

    if (workPermitRelocationBlock) {
      const globalRemote = document.getElementById(
        'job_ad_form_remote_global_remote'
      ) as HTMLInputElement;
      const locationCount = this.getLocationCount(newLocationCount);

      if (locationCount === 0) {
        workPermitRelocationBlock.style['display'] = 'none';
      } else {
        if (globalRemote.checked) {
          workPermitRelocationBlock.style['display'] = 'none';
        } else {
          workPermitRelocationBlock.style['display'] = 'block';
        }
      }
    }
  }

  static getLocationCount(newLocationCount = null) {
    if (typeof newLocationCount === 'number') {
      return newLocationCount;
    } else {
      return $('.office-location-tag').length;
    }
  }

  static handleLocationButtonsListener() {
    $('body').on('click', '.office-location-tag a', () => {
      const locationCount = PostWizard.getLocationCount();

      if (locationCount === 1) {
        const newLocationCount = 0;

        this.handleLocationChange(newLocationCount);
      }
    });

    $('body').on('click', '#office-location-add-button', () => {
      const locationCount = PostWizard.getLocationCount();

      if (locationCount === 0) {
        const newLocationCount = 1;

        this.handleLocationChange(newLocationCount);
      }
    });
  }

  generalInformation() {
    const checkedInput: HTMLElement = document.querySelector('.lj-wizard-category > input:checked');
    if (checkedInput) {
      checkedInput.setAttribute('checked', 'true');
      checkedInput.parentElement.classList.add('lj-wizard-category--active');
      checkedInput.nextElementSibling.classList.add('lj-wizard-category--active');
      (checkedInput.nextElementSibling.querySelector('.checked') as HTMLElement).style.display =
        'block';
      (
        checkedInput.nextElementSibling.querySelector('.category-image') as HTMLElement
      ).style.display = 'none';
    }

    $(document.getElementById('job_ad_form_category_id')).selectize();
  }

  candidateBackground() {
    $(document.getElementById('job_ad_form_certificate_options')).selectize();
  }

  properSanitize(elementValue: string) {
    const parsedHtml = $.parseHTML(elementValue);
    if (parsedHtml === null) {
      return elementValue;
    }
    return parsedHtml
      .filter(
        (item: HTMLElement | null) =>
          item.nodeName !== '#comment' || item.innerHTML !== '<!--block--><br>'
      )
      .map((item: HTMLElement | null) => {
        if (item.nodeName === 'FIGURE') {
          return item.children[0].outerHTML;
        }
        return item.outerHTML;
      })
      .join('');
  }

  submitTrixEditorForms(e: JQuery.ClickEvent) {
    e.preventDefault();
    const trixEditors: any = document.querySelectorAll('trix-editor');

    trixEditors.forEach(trix => {
      const str = trix.innerHTML;
      const key = trix.attributes.input.value;
      (document.getElementById(`job_ad_form_${key}`) as HTMLInputElement).value =
        this.properSanitize(str);
    });

    document.querySelector('form').submit();
  }

  jobDescription() {
    this.initTrixEditor();
    // Selectize Languages
    $(document.getElementById('job_preferred_language_2')).selectize();
    $(document.getElementById('job_preferred_language_3')).selectize();
  }

  locationDetails() {
    const dictionary = {
      '.job_ad_form_remote': [
        {
          job_ad_form_remote_onsite_job:
            "It's required to work from an office. You need to specify office locations and eventual restrictions regarding residency or citizenship.",
        },
        {
          job_ad_form_remote_partial_remote:
            "It's required to spend a portion of time at the office. You need to specify office locations and eventual restrictions regarding residency or citizenship.",
        },
        {
          job_ad_form_remote_full_remote:
            'Eventual restrictions regarding residency or citizenship. You may specify timezone restrictions and office locations (exceptionally).',
        },
        {
          job_ad_form_remote_global_remote:
            'No restrictions regarding residency or citizenship. You may specify timezone restrictions.',
        },
      ],
    };

    for (const form in dictionary) {
      if (dictionary.hasOwnProperty(form)) {
        for (const radio in dictionary[form]) {
          if (dictionary[form].hasOwnProperty(radio)) {
            const key = Object.keys(dictionary[form][radio])[0];
            const value = dictionary[form][radio][key];

            const newElem = document.createElement('p');
            newElem.innerHTML = value;
            newElem.style.marginLeft = '28px';
            newElem.classList.add('ld-form-help-text');

            document
              .querySelector(form)
              .insertBefore(newElem, document.querySelector(`label[for="${key}"]`).nextSibling);
          }
        }
      }
    }
    this.showWorkPermitAndRelocation();
    this.handleTimezoneFields();

    $(document).on('change', '#work-permit-and-relocation-block', () => {
      return this.handleRelocationOptionsToggle();
    });

    $(document).on('change', '#job_ad_form_min_timezone', () => {
      return this.handleTimezoneFields();
    });

    $(() => {
      const relocationError = $('#work-permit-and-relocation-block .ld-error-input').length > 0;
      relocationError && $('#work-permit-and-relocation-block').show();

      this.handleRelocationOptionsToggle();
    });
  }

  additionalInfo() {
    this.initTrixEditor();
  }

  initTrixEditor() {
    const trixEditors = document.querySelectorAll('trix-editor');

    const updateNumberValue = editor => {
      const num = editor.parentElement.children[0].children[0];
      const limit = Number(editor.parentElement.children[0].children[0].dataset.limit);
      num.innerHTML = String(limit + 1 - editor.editor.getDocument().toString().length) + ' ';
    };

    for (const trix of trixEditors as any) {
      const key = (trix.attributes as any).input.value;
      const str = (document.getElementById(`job_ad_form_${key}`) as HTMLInputElement).value;

      if (str) {
        trix.editor.insertHTML(this.properSanitize(str));
      }

      updateNumberValue(trix);

      trix.onkeyup = e => {
        updateNumberValue(e.target);
      };
    }

    // Remove all trix toolbar elements
    $('.attachment__toolbar')?.remove();
    $('.attachment__caption--editing')?.remove();

    $('body').on('click', '.next-button', e => {
      this.submitTrixEditorForms(e);
    });
  }

  showWorkPermitAndRelocation() {
    const remoteBlock = document.getElementById('remote-block') as HTMLInputElement;
    const workPermitRelocationBlock = document.getElementById(
      'work-permit-and-relocation-block'
    ) as HTMLInputElement;
    const locationBlock = document.getElementById('location-block') as HTMLInputElement;
    const remoteDetailsBlock = document.getElementById('remote-details-block') as HTMLInputElement;
    const timezonesIntervalBlock = document.getElementById(
      'timezones-interval-block'
    ) as HTMLInputElement;
    const onsiteJob = document.getElementById('job_ad_form_remote_onsite_job') as HTMLInputElement;
    const partialRemote = document.getElementById(
      'job_ad_form_remote_partial_remote'
    ) as HTMLInputElement;
    const fullRemote = document.getElementById(
      'job_ad_form_remote_full_remote'
    ) as HTMLInputElement;
    const globalRemote = document.getElementById(
      'job_ad_form_remote_global_remote'
    ) as HTMLInputElement;

    workPermitRelocationBlock.style['display'] = 'none';
    locationBlock.style['display'] = 'none';
    remoteDetailsBlock.style['display'] = 'none';
    timezonesIntervalBlock.style['display'] = 'none';

    if (onsiteJob.checked) {
      this.showOnSiteJobOptions(
        locationBlock,
        workPermitRelocationBlock,
        remoteDetailsBlock,
        timezonesIntervalBlock
      );
    } else if (partialRemote.checked) {
      this.showPartialRemoteJobOptions(
        locationBlock,
        workPermitRelocationBlock,
        remoteDetailsBlock,
        timezonesIntervalBlock
      );
    } else if (fullRemote.checked) {
      this.showFullRemoteJobOptions(
        locationBlock,
        workPermitRelocationBlock,
        remoteDetailsBlock,
        timezonesIntervalBlock
      );
    } else if (globalRemote.checked) {
      this.showGlobalRemoteJobOptions(
        locationBlock,
        workPermitRelocationBlock,
        remoteDetailsBlock,
        timezonesIntervalBlock
      );
    }

    remoteBlock.onchange = () => {
      if (onsiteJob.checked) {
        this.showOnSiteJobOptions(
          locationBlock,
          workPermitRelocationBlock,
          remoteDetailsBlock,
          timezonesIntervalBlock
        );
      } else if (partialRemote.checked) {
        this.showPartialRemoteJobOptions(
          locationBlock,
          workPermitRelocationBlock,
          remoteDetailsBlock,
          timezonesIntervalBlock
        );
      } else if (fullRemote.checked) {
        this.showFullRemoteJobOptions(
          locationBlock,
          workPermitRelocationBlock,
          remoteDetailsBlock,
          timezonesIntervalBlock
        );
      } else if (globalRemote.checked) {
        this.showGlobalRemoteJobOptions(
          locationBlock,
          workPermitRelocationBlock,
          remoteDetailsBlock,
          timezonesIntervalBlock
        );
      }
    };
  }

  displayPermitRelocationBlock(element) {
    if (PostWizard.getLocationCount() > 0) {
      element.style['display'] = 'block';
    }
  }

  showOnSiteJobOptions(
    locationBlock,
    workPermitRelocationBlock,
    remoteDetailsBlock,
    timezonesIntervalBlock
  ) {
    locationBlock.style['display'] = 'block';
    this.displayPermitRelocationBlock(workPermitRelocationBlock);
    remoteDetailsBlock.style['display'] = 'none';
    timezonesIntervalBlock.style['display'] = 'none';
  }

  showPartialRemoteJobOptions(
    locationBlock,
    workPermitRelocationBlock,
    remoteDetailsBlock,
    timezonesIntervalBlock
  ) {
    locationBlock.style['display'] = 'block';
    remoteDetailsBlock.style['display'] = 'block';
    this.displayPermitRelocationBlock(workPermitRelocationBlock);
    timezonesIntervalBlock.style['display'] = 'none';
    this.remoteDetailsPlaceholderToggle(true);
  }

  showFullRemoteJobOptions(
    locationBlock,
    workPermitRelocationBlock,
    remoteDetailsBlock,
    timezonesIntervalBlock
  ) {
    locationBlock.style['display'] = 'block';
    remoteDetailsBlock.style['display'] = 'block';
    this.displayPermitRelocationBlock(workPermitRelocationBlock);
    timezonesIntervalBlock.style['display'] = 'block';
    this.remoteDetailsPlaceholderToggle(false);
    this.handleTimezoneFields();
  }

  showGlobalRemoteJobOptions(
    locationBlock,
    workPermitRelocationBlock,
    remoteDetailsBlock,
    timezonesIntervalBlock
  ) {
    locationBlock.style['display'] = 'none';
    remoteDetailsBlock.style['display'] = 'block';
    workPermitRelocationBlock.style['display'] = 'none';
    timezonesIntervalBlock.style['display'] = 'block';
    this.remoteDetailsPlaceholderToggle(false);
    this.handleTimezoneFields();
  }

  handleRelocationOptionsToggle() {
    const visaSupport = $('#job_ad_form_visa_support');
    const citizenship = $('#job_ad_form_citizenship');
    const relocationSupport = $('#job_ad_form_relocation_paid');

    citizenship.prop('disabled', visaSupport.is(':checked') && !citizenship.is(':checked'));
    visaSupport.prop('disabled', citizenship.is(':checked') && !visaSupport.is(':checked'));

    if (visaSupport.is(':checked') || relocationSupport.is(':checked')) {
      $('#support-criteria').show();
    } else {
      $('#support-criteria').hide();
    }
  }

  remoteDetailsPlaceholderToggle(isHybrid) {
    const hybridText = 'e.g. Our team works at the office 3 days / week.';
    const remoteText =
      'e.g. Our team gathers at a daily standup, other than that, communication is usually async.';
    const placeholder = isHybrid ? hybridText : remoteText;

    return $('#job_ad_form_remote_details').attr('placeholder', placeholder);
  }

  handleTimezoneFields() {
    if ($('.ld-timezone-section').length > 0) {
      const minTimezone = $(document.getElementById('job_ad_form_min_timezone') as any);
      const maxTimezone = $(document.getElementById('job_ad_form_max_timezone') as any);

      if ($('.ld-timezone-section select:not(.selectized)').length > 0) {
        minTimezone.selectize();
        maxTimezone.selectize();
      }

      if (minTimezone.val() === '') {
        maxTimezone[0].selectize.clear();
        maxTimezone[0].selectize.disable();
      } else {
        maxTimezone[0].selectize.enable();
      }
    }
  }
}

document.addEventListener('turbolinks:load', () => {
  if (document.querySelector('.lj-wizard-post')) {
    const ReactRailsUJS = require('react_ujs');

    new PostWizard();

    ReactRailsUJS.mountComponents();

    PostWizard.handleLocationChange();
    PostWizard.handleLocationButtonsListener();

    $(document).on('keydown', '#location-filter', ev => {
      if (ev.key === 'Enter') {
        ev.preventDefault();
      }
    });
  }
});
