import React from 'react';
import HowWeHelpSection from './how_we_help_section';

const styles = require('./how_we_help.module.scss');

interface Section {
  title: string;
  text: string[];
  image?: any;
}

interface Props {
  sections: Section[];
  company: boolean;
}

const HowWeHelp = (props: Props) => {
  return (
    <div className={styles['independence-support-section']}>
      {props.sections.map((section, index) => (
        <HowWeHelpSection key={index} company={props.company} {...section} />
      ))}
    </div>
  );
};

export default HowWeHelp;
