import React, { useState, useEffect } from 'react';
import Accordion from '../general/accordion/accordion';
import { getJSON } from '../../lib/request';
import WhatsNewItem, { Feature } from './WhatsNewItem';
import Icon, { Size } from 'components/general/icon/icon';
import classNames from 'classnames';
const styles = require('./WhatsNewHelpContainer.module.scss');

interface Props {
  isCompany: boolean;
}

export default function WhatsNewHelpContainer(props: Props) {
  const emptyFeature = {
    id: 0,
    title: 'There are no New Features...',
    body: 'yet.',
    published_at: null,
    audience: props.isCompany ? 'companies' : 'candidates',
  } as Feature;

  const [loading, setLoading] = useState(true);
  const [feature, setFeature] = useState(emptyFeature);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const followUp = ({ serialized_feature }) => {
      if (serialized_feature.data) {
        setFeature(serialized_feature.data.attributes);
      }
      setLoading(false);
    };
    getJSON(
      props.isCompany ? window.Routes.new_feature_employer() : window.Routes.new_feature(),
      followUp
    );
  }, []);

  const Title = () => (
    <div
      className={classNames(
        styles.titleBar,
        props.isCompany ? styles.titleBarCompany : styles.titleBarTalent
      )}
    >
      <span
        className={classNames(
          styles.tinyCircle,
          props.isCompany ? styles.tinyCircleCompany : styles.tinyCircleTalent
        )}
      >
        !
      </span>
      <div className={styles.titleText}>WHAT'S NEW</div>
    </div>
  );

  const RenderOpenIcon = () => (
    <div className={styles.accordionIcon}>
      <Icon name='chevronDown' color='silverSand' size={Size.Big} />
    </div>
  );

  const RenderCloseIcon = () => (
    <div className={styles.accordionIcon}>
      <Icon name='chevronUp' color='silverSand' size={Size.Big} />
    </div>
  );

  const scrollToBottom = () => setScroll(!scroll);

  const AccordionContent = () => (
    <WhatsNewItem loading={loading} feature={feature} scroll={scroll} />
  );

  return (
    <Accordion
      title={<Title />}
      style={{ border: '1px solid #d4d7d8' }}
      onClick={scrollToBottom}
      openIcon={<RenderOpenIcon />}
      closeIcon={<RenderCloseIcon />}
    >
      <AccordionContent />
    </Accordion>
  );
}
