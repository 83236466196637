import * as React from 'react';
import IntegrationModal from './integration_modal';
import Button from './button';

interface Props {
  atsAPIDocUrl: string;
  atsName: string;
  atsPathToCreateToken: string;
  url: string;
}

export default function TokenButton(props: Props) {
  let modal;
  const handleClick = e => {
    e.preventDefault();
    modal.open();
  };

  return (
    <>
      <Button onClick={handleClick} />
      <IntegrationModal
        url={props.url}
        atsName={props.atsName}
        atsPathToCreateToken={props.atsPathToCreateToken}
        atsAPIDocUrl={props.atsAPIDocUrl}
        ref={e => (modal = e)}
      />
    </>
  );
}
