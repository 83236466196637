import React, { useState } from 'react';
const styles = require('./filtersInfo.module.scss');
const icon = require('images/icons/warning-code-info.svg');

interface Props {
  profilePath?: string;
}

const filtersInfo = (props: Props) => {
  const [showForm, setShowForm] = useState(true);

  return (
    <div id='profile-filters-banner' className={styles.infoBody + ' ' + (!showForm && 'hide')}>
      <img className={styles.imgs} src={icon} />
      <div className={styles.textBlock}>
        The applied filters are based on your profile. Keep{' '}
        <a href={props.profilePath}>your profile updated</a> for a better experience or{' '}
        <a className='js-resetSearch' onClick={e => setShowForm(false)}>
          clear the filters
        </a>
      </div>
    </div>
  );
};

export default filtersInfo;
