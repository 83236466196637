import React, { useState } from 'react';
import 'lib/globals';
import { useInterval } from 'lib/react';
import { postJSON } from 'lib/request';

const styles = require('./match_score_status.module.scss');

interface Entry {
  email: string;
  jid: string;
  status: string;
}

interface Props {
  entries: Entry[];
}

interface State {
  entries: Entry[];
}

export default function MatchScoreStatus(props: Props) {
  const UPDATE_INTERVAL = 5000;
  const [entries, setEntries] = useState(props.entries);

  useInterval(() => {
    updateStatus(entries, setEntries);
  }, UPDATE_INTERVAL);

  return (
    <div>
      <h2>Importation status</h2>
      <ul>{entries.map(renderEntry)}</ul>
    </div>
  );
}

function updateStatus(entries, setEntries) {
  const jids = entries.map(e => e.jid);

  postJSON(window.Routes.calculation_status_backoffice_match_scores(), { jids }, status => {
    setEntries(prevEntries =>
      prevEntries.map(e => (status[e.jid] ? { ...e, status: status[e.jid] } : e))
    );
  });
}

function renderEntry(entry: Entry) {
  return (
    <li key={entry.email}>
      {entry.email}: <strong className={styles[entry.status]}>{entry.status}</strong>
    </li>
  );
}
