import { JobAd } from '__models__/job_ad';

const jobAdDummy: JobAd = {
  id: '7380',
  attributes: {
    title: 'UX/UI Specialist',
    state: 99,
    location: 'Lisbon, Portugal',
    expires_days: null,
    closed_at: '2019-05-06T13:55:24.528Z',
    created_at: '2018-12-19T16:36:29.896Z',
    company_id: 8,
    updated_at: new Date(),
    status_label: 'Closed',
    urls: {
      job_ad_ongoing: '/at/landing-jobs/ux-ui-specialist',
      job_ad_draft: '/post/ux-ui-specialist/submit_offer',
      job_ad_archived: '/employer/jobs/ux-ui-specialist/preview',
      job_ad_publish: '/employer/jobs/ux-ui-specialist/publish',
      job_ad_unpublish: '/employer/jobs/ux-ui-specialist/unpublish',
      job_ad_close: '/employer/jobs/ux-ui-specialist/close',
      job_ad_duplicate: '/post/from/offer?offer_id=7380',
      job_edit_url: '/employer/jobs/ux-ui-specialist/revisions/new',
      revision_edit_url: '/employer/jobs/ux-ui-specialist/edit',
      ats_pair_url: '/employer/jobs/7380/ats/new',
      ats_unpair_url: '/employer/jobs/7380/ats',
      send_to_review_url: '/employer/jobs/ux-ui-specialist/send_to_review',
      delete_job_url: '/employer/jobs/7380',
    },
    share_urls: null,
    job_has_ats_offer: null,
    company_has_ats: null,
    status_color_class: 'ld-status-color u-fill--silverSand',
    valid: null,
    slug: '',
    state_name: '',
    office_locations: undefined,
    hiring_bonus: 0,
    waiting_counter: 0,
    inbox_counter: 0,
    review_counter: 0,
    waiting_new_counter: 0,
    inbox_new_counter: 0,
    review_new_counter: 0,
    last_published_at: '',
    role_description: '',
    main_requirements: '',
    nice_to_have: '',
    perks: '',
    job_type: '',
    office_country_codes: '',
    allows_remote: false,
    remote_details: '',
    remote_working_label: '',
    rate: '',
    salary: '',
    relocation_paid: false,
    visa_support: false,
    citizenship: false,
    support_criteria: '',
    experience_label: '',
    experience_min: 0,
    experience_max: 0,
    preferred_language_label: '',
    nice_to_have_language_label: '',
    category: '',
    must_have_skills: [],
    other_required_skills: [],
    nice_to_have_skills: [],
    referral_value: 0,
    referral_string: '',
    top_related_offers: [],
    contractor: false,
    permanent: false,
    project_duration: 0,
    min_timezone: '',
    max_timezone: '',
    location_icon: '',
    is_global_remote: false,
    timezone_label: '',
  },
};

export default jobAdDummy;
