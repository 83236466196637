import React, { useState, useRef, useEffect, Ref } from 'react';
import classNames from 'classnames';

import { Link } from '__models__/models';
import Icon, { Size } from 'components/general/icon/icon';
import { isMobile } from 'react-device-detect';
import CardAvatarContainer from 'components/general/card/cardAvatar/CardAvatarContainer';
import Links from './links';

const styles = require('./bio.module.scss');

interface BioProps {
  availability: string;
  avatarProperties?: object;
  experience: string;
  experienceValue: any;
  handshakeProfile?: boolean;
  headerKey?: string;
  headline?: string;
  headlineDefault?: string;
  jobSearchAvailability?: any;
  lifeStory: string;
  links?: Link[];
  ljColor?: string;
  location: string;
  mobile?: boolean;
  openModal?: any;
  public: boolean;
  publicProfilePage?: boolean;
  showYearsOfExperience?: boolean;
  unlocked?: boolean;
  yearsDifference?: string;
}

export default function Bio(props: BioProps) {
  if (props.mobile) {
    return (
      <div className={styles.mobileContainer}>
        <div className={styles.mobileInfoContainer}>
          <CardAvatarContainer {...props.avatarProperties} mobile />
          <div className={styles.mobileInfo}>
            <Headline {...props} />
            {props.showYearsOfExperience && <Experience experience={props.yearsDifference} />}
            <Location location={props.location} />
            <div>
              <Links
                headerKey={props.headerKey}
                openModal={props.openModal}
                links={props.links}
                ljColor={props.ljColor || 'flamingo'}
                disabled={(props.handshakeProfile && !props.unlocked) || props.publicProfilePage}
              />
            </div>
          </div>
        </div>
        <div className={styles.mobileDescription}>
          {props.jobSearchAvailability && (
            <JobSearchAvailability jobSearchAvailability={props.jobSearchAvailability} />
          )}
          <LifeStory lifeStory={props.lifeStory} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.twoPart}>
        <Headline {...props} />
        {props.showYearsOfExperience && <Experience experience={props.yearsDifference} />}
        <Location location={props.location} />
        {props.jobSearchAvailability && (
          <JobSearchAvailability jobSearchAvailability={props.jobSearchAvailability} />
        )}
      </div>
      <LifeStory lifeStory={props.lifeStory} />
    </div>
  );
}

const Headline = ({ headline, headlineDefault }: Partial<BioProps>) => {
  const content = headline || headlineDefault;
  const headlineStyles = classNames(
    styles.headlineItem,
    headline ? styles.activeText : styles.placeholder
  );

  if (content) {
    return (
      <div className={styles.bioItem}>
        <span className={headlineStyles}>{content}</span>
      </div>
    );
  } else {
    return null;
  }
};

const JobSearchAvailability = ({ jobSearchAvailability }) => (
  <div>
    <span className={classNames(styles.activeText, styles.jobSearchItem)}>
      <Icon name={'circle'} size={Size.Tiny} color={'puertoRico'} /> {jobSearchAvailability}
    </span>
  </div>
);

const Location = ({ location }) => (
  <div className={styles.bioItem}>
    <Icon name={'marker'} color={'black'} />
    <span>{location}</span>
  </div>
);

const Experience = ({ experience }) => {
  const ofExperience = ' of experience';
  return (
    <div className={styles.bioItem}>
      <span>{experience?.includes(ofExperience) ? experience : experience + ofExperience}</span>
    </div>
  );
};

const LifeStory = ({ lifeStory }) => {
  const [showText, setShowText] = useState(true);
  const [needsToggle, setNeedsToggle] = useState(true);
  const lifeStoryRef = useRef();

  const toggleText = () => setShowText(prevState => !prevState);

  useEffect(() => {
    if (lifeStoryRef.current === undefined) {
      return;
    }
    const { scrollHeight } = lifeStoryRef?.current as HTMLDivElement;
    setNeedsToggle(scrollHeight > 21 && !isMobile);
  });

  return (
    <div className={classNames(styles.activeText, styles.lifeStory)}>
      <div
        ref={lifeStoryRef}
        className={classNames({
          [styles.smallText]: showText,
        })}
      >
        {lifeStory}
      </div>
      {needsToggle && (
        <span className={classNames(styles.activeText, styles.toggle)} onClick={toggleText}>
          {showText ? 'More Info' : 'Less Info'}{' '}
          <Icon name={showText ? 'chevronDown' : 'chevronUp'} />
        </span>
      )}
    </div>
  );
};
