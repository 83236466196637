import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import ScreeningScriptModal from './ScreeningScriptModal';
import TextInput from 'components/form/text/text';
import Button from 'lj_shared/button/button';

const createProps = () => ({
  screeningScript: ['Q1', 'Q2'],
  modalRef: '',
  color: 'ripePlum',
});

let props;
let wrapped: ShallowWrapper;

describe('Your description here', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<ScreeningScriptModal {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('contains a TextInput', () => {
    expect(wrapped.find(TextInput).length).toEqual(1);
  });

  it('contains two Buttons', () => {
    expect(wrapped.find(Button).length).toEqual(2);
  });
});

test.todo('add more tests');
