import React, { useState } from 'react';
import { patchJSON, handleRequestError } from '../../lib/request_deprecated';
import Button from 'lj_shared/button/button';
import AcceptHandshakeModal from './accept_handshake_modal';
import { camelizeObj } from 'lib/object';
import AfterHandshakeModal from 'components/handshakes/after_handshake_modal/after_handshake_modal';
export interface Props {
  buttonSize?: 'xSmall' | 'small' | 'mid' | 'large' | 'xLarge';
  cvs: any[];
  handshake: any;
  handshake_company: { name: string; id: number; logo: string };
  handshake_job_ad: { title: string; id: number };
  hasFollowUpModal?: boolean;
}

export default function AcceptHandshake(props: Props) {
  const initialCvs = props.cvs.map(c => camelizeObj(c));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cvs, setCvs] = useState(initialCvs);
  const [selectedCv, setSelectedCv] = useState(initialCvs.find(c => c.standard));
  const [isAfterAccept, setIsAfterAccept] = useState(false);

  const handleAccept = () => {
    if (props.handshake_job_ad?.id) {
      setIsModalOpen(true);
    } else {
      patchJSON(window.Routes.accept_dashboard_handshake(props.handshake.id))
        .then(_ => {
          window.Alerts.notice('Handshake accepted!');
          window.location.reload();
        })
        .catch(handleRequestError);
    }
  };

  const handleChangeCv = (object, action) => {
    const cv = camelizeObj(object);

    switch (action) {
      case 'post':
        setCvs([cv, ...cvs]);
        setSelectedCv(cv);
        break;
      case 'delete':
        const remainingCvs = cvs.filter(c => +c.id !== +cv.id);
        setCvs(remainingCvs);
        if (remainingCvs.length > 0 && object !== remainingCvs[0]) {
          setSelectedCv(remainingCvs[0]);
        }
        break;
      case 'select':
        const selected = cvs.find(c => c.id === object);
        setSelectedCv(selected);
        break;
    }
  };

  return (
    <>
      <Button
        buttonColor='tuftsBlueMidLight'
        isButton={true}
        buttonSize={props.buttonSize || 'xSmall'}
        disabled={false}
        onClick={handleAccept}
      >
        {'Accept'}
      </Button>
      {isModalOpen && (
        <AcceptHandshakeModal
          {...props}
          setIsModalOpen={setIsModalOpen}
          handleChangeCv={handleChangeCv}
          cvs={cvs.sort((a, b) => b.standard - a.standard)}
          selectedCv={selectedCv}
          hasFollowUpModal={props.hasFollowUpModal}
          setIsAfterAccept={setIsAfterAccept}
        />
      )}
      {isAfterAccept && (
        <AfterHandshakeModal
          companyName={props.handshake_company.name}
          jobTitle={props.handshake_job_ad.title}
          handshakeAccepted={true}
          personId={props.handshake.person_id}
        />
      )}
    </>
  );
}
