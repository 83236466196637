import * as React from 'react';

import './application_header.scss';
import './application_evaluation.scss';

interface EvaluationProps {
  items: Item[];
}

interface Item {
  title?: string | JSX.Element;
  subtitle?: string;
  evaluationItem: any;
}

export const EvaluationItems: any = (props: EvaluationProps) => {
  return props.items.map((item, index) => {
    return (
      <div className='lj-user-tests' key={index}>
        <div className='lj-heading--base lj-tests-title'>
          {item.title}
          {item.subtitle && (
            <div className='lj-heading--base lj-tests-subtitle '>{item.subtitle}</div>
          )}
        </div>

        {item.evaluationItem}
      </div>
    );
  });
};

export const NoItem = (emptyText: string) => {
  return <div className='lj-no-item'>{emptyText}</div>;
};
