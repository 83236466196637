const curatorsTalentUserDummy = {
  data: {
    id: '174782',
    type: 'curators_talent_user',
    attributes: {
      availability: 1,
      avatar:
        '<div class="lj-userAvatar--small lj-userAvatar"><img src="https://assets.landing.jobs/default_avatars/avatar2.svg" class="lj-userAvatar-img" /></div>',
      bio: 'I’ m looking for a job opportunity in Europe to live and study. There is a lot of work for my area. I speak the following languages: Portuguese (Fluent)',
      birth_year: 1988,
      citizenships: ['Brazilian', 'Portuguese'],
      email: 'talent@example.com',
      experience_years: '8 years of experience',
      id: 1541,
      languages: [
        {
          language: 'Portuguese',
          proficiency: '5. Native or Bilingual proficiency',
        },
        {
          language: 'English',
          proficiency: '5. Native or Bilingual proficiency',
        },
      ],
      location: 'Uberlândia, Brazil',
      name: 'Marie',
      phone_number: '123456789',
      relocation_countries: ['Portugal', 'Spain'],
      skills: [
        { experience: '8 Years', name: 'Project Management' },
        { experience: '1 Year', name: 'Scrum Master' },
      ],
      skype_id: 'marie.aristogatos',
      websites: [
        {
          url: 'https://linkedin.com/in/idianacastro',
        },
      ],
      past_applications: {
        data: [
          {
            id: 170489,
            type: 'past_application',
            attributes: {
              job_title: 'Front-end Developer',
              job_ad_slug: 'senior front end developer in lisbon',
              company: 'ABOUT YOU',
              submitted_at: '12/06/2019',
              state: 'Rejected by landing',
              curator_name: 'José Cândido',
              curator_rating: 3,
              curator_note: [],
              employer_rating: {
                id: 66241,
                rater_id: 107404,
                application_id: 173506,
                rating: 4,
                note: 'note',
                created_at: '2019-06-27',
                updated_at: '2019-06-27',
                rule: null,
                start_time: null,
                rater_type: 'User',
              },
              employer_note: 'Note',
              ratings: [
                { id: 1, note: 'note', user: 'AdminUser' },
                { id: 2, note: 'note2', user: 'User' },
              ],
              hasCuratorRating: true,
            },
          },
          {
            id: 170489,
            type: 'past_application',
            attributes: {
              job_title: 'Front-end Developer',
              job_ad_slug: 'senior front end developer in lisbon',
              company: 'ABOUT YOU',
              submitted_at: '12/06/2019',
              state: 'Rejected by landing',
              curator_name: 'José Cândido',
              curator_rating: 3,
              curator_note: [],
              employer_rating: {
                id: 66242,
                rater_id: 107404,
                application_id: 173507,
                rating: 4,
                note: 'note',
                created_at: '2019-06-27',
                updated_at: '2019-06-27',
                rule: null,
                start_time: null,
                rater_type: 'User',
              },
              employer_note: 'Note',
            },
          },
        ],
      },
    },
  },
};

export default curatorsTalentUserDummy;
