const assessmentResultDummy = {
  data: [
    {
      attributes: {
        assessment_id: 60,
        end_time: '01-01-2021',
        external_id: '1234',
        score: 90,
      },
    },
  ],
};

export default assessmentResultDummy;
