import * as React from 'react';
import classNames from 'classnames';

import InlineIcon from '../general/inline_icon/inline_icon';
import ShareModal from './jobs_share_modal';
import { handleRequestError } from '../../lib/request_deprecated';
import { JobAd } from '../../__models__/models';

const iconShare = require('iconic/share.svg');
const iconClose = require('iconic/x.svg');
const iconUnpublish = require('iconic/media.svg');
const iconPublish = require('iconic/bullhorn.svg');
const iconDuplicate = require('iconic/layers.svg');
const iconATS = require('iconic/clipboard.svg');

const styles = require('./job_more_button.module.scss');

interface Props {
  closeUrl: string;
  duplicateUrl: string;
  handlePublish: Function;
  handleUnpublish: Function;
  hasATS: boolean;
  isPublished: boolean;
  isWaitingRevision: boolean;
  jobAd: JobAd;
  privacy: string;
  setJobsState: Function;
  termsOfService: string;
}

interface State {
  isOpen: boolean;
}

export default class JobMoreButton extends React.Component<Props, State> {
  wrapperRef: any;

  constructor(props) {
    super(props);
    this.state = { isOpen: false };
    this.wrapperRef = React.createRef();
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleBodyClick = this.handleBodyClick.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleATSUnpair = this.handleATSUnpair.bind(this);
  }

  componentDidMount() {
    document.body.addEventListener('click', this.handleBodyClick);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleBodyClick);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleBodyClick(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  }

  handleClick() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  handleItemClick() {
    this.setState({ isOpen: false });
  }

  handleOpenModal(event) {
    event.preventDefault();
    ShareModal.show(this.props.jobAd).catch(handleRequestError);
  }

  handleATSUnpair() {
    const url = this.props.jobAd.attributes.urls.ats_unpair_url;

    if (window.confirm('Remove the pairing of this job with the ATS?')) {
      window.toggleSpinner(true);
      $.ajax({
        url,
        type: 'DELETE',
        success: () => {
          window.toggleSpinner(false);
        },
      });
    }
  }

  render() {
    const klass = classNames(
      'lj-button lj-button--silverSand lj-button--xSmall lj-button--border',
      styles.button,
      { [styles.isOpen]: this.state.isOpen }
    );

    return (
      <div className={styles.container} ref={this.setWrapperRef}>
        <div className={klass} onClick={this.handleClick}>
          More
        </div>
        {this.state.isOpen && this.renderMenu()}
      </div>
    );
  }

  renderMenu() {
    const { isPublished } = this.props;

    return (
      <ul className={styles.menu}>
        {this.props.isPublished &&
          this.renderMenuItem({
            iconPath: iconShare,
            onClick: this.handleOpenModal,
            name: 'share-button',
            text: 'Share',
          })}

        {this.renderATS() &&
          this.renderMenuItem({
            name: 'ats-button',
            iconPath: iconATS,
            text: this.props.jobAd.attributes.job_has_ats_offer
              ? 'Unpair with ATS'
              : 'Pair with ATS',
            href: !this.props.jobAd.attributes.job_has_ats_offer
              ? this.props.jobAd.attributes.urls.ats_pair_url
              : null,
            onClick: this.props.jobAd.attributes.job_has_ats_offer ? this.handleATSUnpair : null,
          })}

        {this.renderMenuItem({
          href: this.props.duplicateUrl,
          iconPath: iconDuplicate,
          method: 'post',
          name: 'duplicate-button',
          text: 'Duplicate',
        })}

        {!this.props.isWaitingRevision &&
          this.props.isPublished &&
          this.renderMenuItem({
            onClick: this.props.handleUnpublish,
            icon: (
              <InlineIcon
                className={styles.menuItemIcon}
                path={iconUnpublish}
                alt='pause'
                data={{ state: 'pause' }}
              />
            ),
            name: 'unpublish-button',
            text: 'Unpublish',
          })}

        {this.renderMenuItem({
          href: this.props.closeUrl,
          iconPath: iconClose,
          name: 'close-button',
          text: 'Close Job',
        })}
      </ul>
    );
  }

  renderMenuItem(params) {
    const { name, icon, iconPath, text, href, method } = params;
    const onClick = params.onClick || this.handleItemClick;

    return (
      <li className={styles.menuItem} onClick={onClick}>
        <a href={href} data-method={method}>
          {icon ? icon : <InlineIcon className={styles.menuItemIcon} path={iconPath} />}
          <span className={styles.menuItemLabel} data-testid={name}>
            {text}
          </span>
        </a>
      </li>
    );
  }

  renderATS() {
    if (this.props.hasATS) {
      if (this.props.jobAd.attributes.state_name === 'waiting_revision') {
        return false;
      }
      return true;
    }
  }
}
