import * as React from 'react';
import TestCardItem from './test_card_item';
import { shallow, ShallowWrapper } from 'enzyme';

let wrapped: ShallowWrapper;

const standardTests = {
  data: [
    {
      attributes: {
        id: 1,
        name: 'Test',
        duration: 15,
        language: ['Ruby', 'React'],
        experience_levels: ['1year', 'Junior'],
      },
    },
  ],
};

describe('Test Card Item component', () => {
  beforeEach(() => {
    wrapped = shallow(
      <TestCardItem
        technicalTest={standardTests.data[0]}
        handleTestIdSelect={jest.fn()}
        selectedTest={2}
      />
    );
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});
