const assessmentDummy = {
  data: [
    {
      attributes: {
        duration: 60,
        experience_levels: ['junior'],
        id: 1,
        languages: ['ruby', 'react'],
        name: 'Software developer',
        public_url: '',
        sample_url: '',
      },
    },
  ],
};

export default assessmentDummy;
