import React from 'react';
import classnames from 'classnames';
import track from '../../../lib/drip';
import Button from './button';

const styles = require('./homepageButtons.module.scss');

interface Props {
  language: {
    contractor: string;
    company: string;
    companyHero?: string;
  };
  size?: string;
  center?: string;
  trackingAction?: string;
}

/**
 * Component which renders 'Button' components depending on language
 * and size
 *
 * If companyFeatures is present then it will render only one button
 */
export default class HomepageButtons extends React.Component<Props> {
  render() {
    const { contractor, company, companyHero } = this.props.language;
    const actionName = this.props.trackingAction || 'hero';

    return (
      <div className={classnames(styles.homepageButtonsWrapper)}>
        <Button
          onClick={() =>
            track('action', {
              name: 'work-homepage',
              controller: 'work',
              action: 'contractor-typeform' + actionName,
            })
          }
          isRound={true}
          otherClasses={`${styles['midButtons-button']} ${this.props.center}`}
          buttonColor='tuftsBlue'
          buttonSize={this.props.size ? this.props.size : 'mid'}
          isButton={false}
          buttonUrl='job_seeker/join'
        >
          {contractor}
        </Button>
        {companyHero ? (
          <div className={styles.hireContractorsText}>
            <Button
              onClick={() =>
                track('action', {
                  name: 'work-homepage',
                  controller: 'work',
                  action: 'client-link' + actionName,
                })
              }
              isRound={true}
              buttonColor='tuftsBlue'
              isButton={false}
              otherClasses={`${styles['midButtons-button']} ${this.props.center}`}
              buttonUrl={'/remoteworking/employers'}
              buttonType='border'
            >
              {companyHero}
            </Button>
          </div>
        ) : (
          <Button
            otherClasses={`${styles['midButtons-button']} ${this.props.center} ${styles['white-button']}`}
            onClick={() =>
              track('action', {
                name: 'work-homepage',
                controller: 'work',
                action: 'client-page',
              })
            }
            isRound={true}
            buttonColor='transparentWhite'
            isButton={false}
            buttonUrl={'/remoteworking/employers'}
          >
            {company}
          </Button>
        )}
      </div>
    );
  }
}
