import * as React from 'react';
import Sidebar from './Sidebar';
import { shallow } from 'enzyme';
import companyDummy from '__fixtures__/company_dummy';

describe('Sidebar', () => {
  it('shallow renders without crashing', () => {
    const component = shallow(
      <Sidebar company={companyDummy} setJobsTab={jest.fn()} showJobs={true} subscribed={false} />
    );
    expect(component.exists()).toBe(true);
  });
});
