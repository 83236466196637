import React from 'react';
import ContextConsumer from './context';
import HeroEmployer from './hero_employer';
import Benefits from './benefits';
import HowWeHelp from './how_we_help';
import Roles from './roles';
import * as Cookies from 'js-cookie';

const EN = require('../copy/EN/employer.json');
const PT = require('../copy/PT/employer.json');

let currentLanguage;
let language;

class Employers extends React.Component {
  render() {
    return (
      <ContextConsumer>
        {({ data }) => {
          document.title = 'Landing.Jobs | Clients';
          language = Cookies.get('locale');
          language === 'pt' ? (currentLanguage = PT) : (currentLanguage = EN);
          return (
            <>
              <HeroEmployer language={currentLanguage.HeroEmployer} />
              <Benefits
                title={currentLanguage.Benefits.title}
                benefit1={currentLanguage.Benefits.benefit1}
                benefit2={currentLanguage.Benefits.benefit2}
                benefit3={currentLanguage.Benefits.benefit3}
                buttonSubtext={currentLanguage.Benefits.buttonSubtext}
                startButton={currentLanguage.Benefits.startButton}
              />
              <HowWeHelp
                sections={[currentLanguage.Engagement, currentLanguage.Trust]}
                company={true}
              />
              <Roles copy={currentLanguage.Roles} />
            </>
          );
        }}
      </ContextConsumer>
    );
  }
}

export default Employers;
