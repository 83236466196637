function serializeSteps(steps, deletedSteps = []) {
  steps = steps.map(step => ({
    description: step.description || undefined,
    id: step.id || undefined,
    kind: step.kind,
    message: serializeMessage(step.message),
    name: step.name,
    order: step.order,
    test: serializeTest(step.test),
  }));

  return JSON.stringify(steps.concat(deletedSteps));
}

function serializeMessage(message) {
  if (message && message.text) {
    return { text: message.text };
  }
}

function serializeTest(test) {
  if (test) {
    return { ...test };
  }
}

// eslint-disable-next-line no-undef
if (typeof module !== 'undefined') module.exports = { serializeSteps };
