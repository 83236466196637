import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import Button from 'lj_shared/button/button';
import WhatIsAContractor from './WhatIsAContractor';

let wrapped: ShallowWrapper;

window.Routes = { jobs: () => '' };

describe('Your description here', () => {
  beforeEach(() => {
    wrapped = shallow(<WhatIsAContractor />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('should have a button', () => {
    expect(wrapped.find(Button)).toHaveLength(1);
  });

  it('should have a title', () => {
    expect(wrapped.find('.title').text()).toBe('This job also accepts contractors');
  });
});
