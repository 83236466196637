import { apolloClient } from 'lib/graphql';
import Checkbox from 'components/form/checkbox/checkbox';
import gql from 'graphql-tag';
import { camelCase, isNil, sortBy } from 'lodash';
import { List } from 'immutable';
import RatingBox from './rating_box';
import React, { useEffect, useRef } from 'react';
import Select from 'components/form/select/select';
const Settings = require('settings.json');
const styles = require('./skills_dimension.module.scss');

const SKILL_TYPES = [
  { field: 'must_have_skills', label: 'Must have' },
  { field: 'other_required_skills', label: 'Other required' },
  { field: 'nice_to_have_skills', label: 'Nice to have' },
];

export interface Props {
  application: any;
  rating: number;
  skills: any;
  setNotes: Function;
  setRating: Function;
  setSkills: any;
  user: any;
}

const TAGS_GQL = gql`
  query ($query: String) {
    tags(query: $query) {
      nodes {
        id
        name
      }
    }
  }
`;

const RATING_GQL = gql`
  query (
    $applicationId: ID!
    $mustHaveSkills: [SkillInput!]!
    $otherRequiredSkills: [SkillInput!]!
    $niceToHaveSkills: [SkillInput!]!
  ) {
    skillsRatingComputation(
      applicationId: $applicationId
      mustHaveSkills: $mustHaveSkills
      otherRequiredSkills: $otherRequiredSkills
      niceToHaveSkills: $niceToHaveSkills
    ) {
      notes
      rating
    }
  }
`;

const SELECT_STYLES = {
  control(defaultStyles) {
    return {
      ...defaultStyles,
      backgroundColor: 'transparent',
      borderLeft: 0,
      borderTop: 0,
      borderRight: 0,
      minHeight: 0,
      height: '2.2rem',
    };
  },
};

const YEAR_OPTIONS = Settings.experienceYears.map(([label, value]) => ({
  label,
  value,
}));

export default function SkillsDimension(props: Props) {
  useEffect(requestRating.bind(null, props), [props.skills]);

  return (
    <>
      <div className={styles.container}>
        <h3 className={styles.h3}>Skills Match</h3>
        <RatingBox value={props.rating} />
      </div>
      <div className={styles.questions}>
        {renderIntro()}
        <Skills {...props} />
      </div>
    </>
  );
}

function renderIntro() {
  return (
    <div className={styles.note}>
      <span className={styles.noteWord}>Note: </span>
      These are the skills from the job. Please tick the ones the candidate has and add the years of
      experience with that skill (we prefilled the ones the candidate has in his/her profile but
      please double check the CV.
    </div>
  );
}

function Skills(props: Props) {
  const otherInputRef = useRef(null);
  const { skills, setSkills } = props;

  return (SKILL_TYPES as any).map(({ field, label }) => {
    const typeSkills = skills.filter(({ type }) => type === field);
    return (
      <div key={label}>
        <strong>{label} skills</strong>
        <ul className={styles.skillsList}>{typeSkills.map(renderSkill.bind(null, setSkills))}</ul>
      </div>
    );
  });
}

function renderSkill(setSkills, { tagId, name, years }) {
  return (
    <li className={styles.skillItem} key={tagId}>
      <Checkbox
        checked={!isNil(years)}
        color='puertoRico'
        controlled={true}
        name={tagId}
        onChange={handleCheckboxChange.bind(null, setSkills)}
      />
      <span className={styles.skillName}>{name}</span> ,
      <Select
        onChange={handleYearsChange.bind(null, setSkills, tagId)}
        options={YEAR_OPTIONS}
        otherClassName={styles.yearsInput}
        styles={SELECT_STYLES}
        value={YEAR_OPTIONS.find(({ value }) => value === years) || null}
      />
    </li>
  );
}

export function getInitialSkills({ application, talentUser }) {
  const job = application.job_ad;
  const skills = [];

  for (const { field } of SKILL_TYPES) {
    for (const { id, name } of job[field]) {
      skills.push({
        type: field,
        name,
        tagId: id,
        years: candidateSkillYears(talentUser, id),
      });
    }
  }

  return List(sortBy(skills, ['name']));
}

function requestRating(props: Props) {
  apolloClient
    .query({
      query: RATING_GQL,
      variables: {
        ...serializeSkills('compute', props.skills),
        applicationId: props.application.id,
      },
    })
    .then(result => {
      props.setRating(result.data.skillsRatingComputation.rating);
      props.setNotes(result.data.skillsRatingComputation.notes);
    })
    .catch(); // Exception previously handled in graphQL.ts handleError
}

function candidateSkillYears(user, tagId: number) {
  const skill = user.skills.find(skill => skill.tag_id === tagId);
  return skill?.experience;
}

function updateSkillYears(setSkills, tagId, years) {
  setSkills(skills => {
    const idx = skills.findIndex(skill => skill.tagId === tagId);
    const skill = { ...skills.get(idx), years };
    return skills.set(idx, skill);
  });
}

function handleCheckboxChange(setSkills, event) {
  const element = event.target;
  const checked = element.checked;
  const tagId = parseInt(element.name, 10);
  const years = checked ? -1 : undefined;
  updateSkillYears(setSkills, tagId, years);
}

function handleYearsChange(setSkills, tagId, skill) {
  const years = skill?.value;
  updateSkillYears(setSkills, tagId, years);
}

export function serializeSkillsState(skills) {
  return JSON.stringify(serializeSkills('save', skills));
}

function serializeSkills(context, skills) {
  const result = {};

  for (const { field } of SKILL_TYPES) {
    const typeSkills = skills.filter(({ type }) => type === field);
    const fieldName = context === 'compute' ? camelCase(field) : field;
    result[fieldName] = typeSkills.map(toSkillInput).toArray();
  }

  return result;
}

function toSkillInput({ tagId, years }) {
  return { tagId, years };
}
