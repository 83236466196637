import Cookies from 'js-cookie';
import { CableConsumer } from 'lib/cable_consumer';
import { browserNotify } from 'lib/browser_notification';

const CHANNEL_NAME = 'RealtimeNotificationsChannel';
let subscription = null;

export function init() {
  if (document.body.dataset['userType']) {
    subscription = CableConsumer.subscriptions.create(
      { channel: CHANNEL_NAME },
      { received: handleMessageReceived }
    );
  }
}

function handleMessageReceived(message) {
  switch (message.type) {
    case 'handshake_accepted':
      handleHandshakeAccepted(message);
      break;
    case 'handshake_created':
      handleHandshakeCreated(message);
      break;
    case 'ats_account_created':
      handleAtsAccountCreated(message);
      break;
  }
}

function handleHandshakeAccepted(message) {
  const body =
    `${message.candidate} accepted your Handshake, don't lose ` + 'time, start talking now!';
  const tag = `RealtimeNotification:${message.type}${message.handshakeID}`;
  const title = `New accepted Handshake from ${message.candidate}`;
  const onClick = () => {
    window.location = message.url;
  };

  browserNotify({ body, tag, title, onClick });
}

function handleHandshakeCreated(message) {
  const body = `${message.company} wants to engage with you, congrats`;
  const tag = `RealtimeNotification:${message.type}${message.handshakeID}`;
  const title = `New Handshake request from ${message.company}`;
  const onClick = () => {
    window.location = message.url;
  };

  browserNotify({ body, tag, title, onClick });
}

function handleAtsAccountCreated(message) {
  const shouldReload = message.reloadUrls.includes(window.location.pathname);
  let body = `Your account on ${message.atsProvider} has been successfully synced! Pairing jobs is now possible.`;

  if (shouldReload) {
    body =
      body + ' Please note that the page will automatically refresh to reflect recent updates.';
  }

  window.Alerts.notice(body);
  Cookies.remove('otherAtsSync');

  if (shouldReload) {
    setTimeout(() => window.location.reload(), 4000);
  }
}
