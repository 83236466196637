import 'rc-slider/assets/index.css';
import React from 'react';
import Slider from 'rc-slider';
import BonusSlider from './bonus_slider';
import { shallow, ShallowWrapper } from 'enzyme';
import Icon from 'components/general/icon/icon';

const Range = Slider.Range;

let wrapped: ShallowWrapper;

describe('Bonus Slider component', () => {
  beforeEach(() => {
    wrapped = shallow(<BonusSlider onChange={jest.fn()} value={3} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('it has 1 Slider', () => {
    expect(wrapped.find(Range).length).toEqual(1);
  });

  it('it has 2 Icons', () => {
    expect(wrapped.find(Icon).length).toEqual(2);
  });
});

test.todo('add more tests');
