import AboutYourself from './aboutYourself';
import ProfileForm from 'components/registration/profile_form';

export default class AboutYourselFields extends AboutYourself {
  profileForm: ProfileForm;
  blurred: boolean;
  dontShowStarIcon: boolean;
  showContactPreference: boolean;

  constructor(params) {
    super(params);
    this.blurred = (params.handshakeProfile && !params.unlocked) || params.publicProfilePage;
    this.dontShowStarIcon = !(params.handshakeProfile || params.publicProfilePage);
    this.showContactPreference = !params.handshakeProfile && !params.publicProfilePage;
  }

  firstCol = () => [
    {
      title: 'Citizenship(s)',
      uniqueKey: 'citizenships',
      value: this.citizenships(),
      blurred: false,
      showStarIcon: this.dontShowStarIcon,
    },
    {
      title: 'Languages',
      uniqueKey: 'languages',
      value: this.languages(),
      blurred: false,
      showStarIcon: this.dontShowStarIcon,
    },
  ];

  secondCol = () =>
    [
      {
        title: 'Phone number',
        uniqueKey: 'phone_number',
        value: this.blurred ? '+351987654321' : this.phoneNumber(),
        blurred: this.blurred,
        showStarIcon: this.dontShowStarIcon,
      },
      {
        title: 'Skype id',
        uniqueKey: 'skype_id',
        value: this.blurred ? 'user_skype_id' : this.skypeId(),
        blurred: this.blurred,
      },
      {
        title: 'E-mail',
        uniqueKey: 'email',
        value: this.blurred ? 'user@email.com' : this.email(),
        blurred: this.blurred,
      },
      this.showContactPreference && {
        title: 'Contact preference',
        uniqueKey: 'contactPreference',
        value: this.blurred ? 'Smoke Signal' : this.contactPreference(),
        blurred: this.blurred,
      },
    ].filter(Boolean);

  props = () => ({
    firstCol: [...this.firstCol()],
    secondCol: [...this.secondCol()],
  });
}
