import React from 'react';
import SearchTagItem from './SearchTagItem';

const styles = require('./SearchTagList.module.scss');

interface Props {
  handleDeleteTag: Function;
  searchTags: SearchTagRaw[];
  topFilter: string;
}

export interface SearchTagRaw {
  label: string;
  name: string;
}

export default function SearchTagList(props: Props) {
  const sanitizedTags = () => {
    if (props.topFilter !== 'rejected') {
      return props.searchTags.filter(tag => !tag.label.startsWith('From:'));
    }
    return props.searchTags;
  };

  return (
    <div className={styles.searchTags}>
      {sanitizedTags().map(tag => (
        <SearchTagItem
          label={tag.label}
          handleDelete={() => props.handleDeleteTag(tag.name, tag.label)}
          key={`${tag.name}, ${tag.label}`}
        />
      ))}
    </div>
  );
}
