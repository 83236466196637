import React, { useRef } from 'react';
import Title from './Title';
import Header from './Header';
import Skills from './Skills';
import AboutCompany from './AboutCompany';
import { JobAd } from '__models__/job_ad';
import JobSection from './JobSection';
import Sidebar from './Sidebar';
import { GET_COMPANY_GQL } from './JobPageUtils';
import { apolloClient } from 'lib/graphql';
import { useQuery } from 'react-apollo';
import ApplyButton from './ApplyButton';
import SignUpModal from 'components/job_search/sign_up_modal';
import WhatIsAContractor from './WhatIsAContractor';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import SubscribeButton from 'components/subscribeButton/SubscribeButton';
import LjSpinner from 'shared/ld_spinner/LjSpinner';

const styles = require('./JobPage.module.scss');

interface Props {
  bookmark: any;
  cancelReasons: any;
  handshake: any;
  jobAd: JobAd;
  jobSuggestion: any;
  referralId: number;
  jobSearchFiltersAb?: string;
}

export default function JobPage(props: Props) {
  const fixedHeader = !isMobile;
  const jobAdAttrs = props.jobAd.attributes;
  const jobPageRef = useRef<HTMLDivElement>(null);

  const JOBSECTIONS = [
    {
      title: 'Description',
      text: jobAdAttrs.role_description,
      id: 'description',
    },
    {
      title: 'Requirements',
      text: jobAdAttrs.main_requirements,
      id: 'requirements',
    },
    { title: 'Nice to have', text: jobAdAttrs.nice_to_have, id: 'niceToHave' },
    { title: 'Perks', text: jobAdAttrs.perks, id: 'perks' },
    {
      title: 'Remote details',
      text: remoteDetailsText(),
      id: 'remoteDetails',
    },
  ];

  function remoteDetailsText() {
    const timezoneMin = jobAdAttrs.min_timezone;
    const timezoneMax = jobAdAttrs.max_timezone;

    const timezoneString = () => {
      if (timezoneMin && timezoneMax) {
        if (timezoneMin === timezoneMax) {
          return `zone ${timezoneMin}`;
        } else {
          return `zones between ${timezoneMin} and ${timezoneMax}`;
        }
      }
    };

    if (jobAdAttrs.min_timezone || jobAdAttrs.max_timezone) {
      return `
          <p>
            To apply for this job you must be willing to work
            in the time ${timezoneString()}.
          </p>
          <p>
            ${jobAdAttrs.remote_details}
          </p>
        `;
    } else {
      return jobAdAttrs.remote_details;
    }
  }

  const { data, error, loading } = useQuery(GET_COMPANY_GQL, {
    client: apolloClient,
    variables: { id: jobAdAttrs.company_id.toString() },
  });

  function renderSignUpModal() {
    return (
      <SignUpModal
        context='show'
        sign_up_path={window.Routes.join()}
        login_path={window.Routes.new_user_session()}
      />
    );
  }

  function renderTitle() {
    return (
      <>
        <Title
          applications={data.person.applications}
          cancelReasons={props.cancelReasons}
          companyId={jobAdAttrs.company_id}
          companySlug={data.company.slug}
          companyName={data.company.name}
          currentUser={data.currentUser}
          fixedHeader={fixedHeader}
          handshake={props.handshake}
          jobAd={props.jobAd}
          jobSuggestion={props.jobSuggestion}
          logo={data.company.logoUrl}
          referralId={props.referralId}
          shortPitch={data.company.shortPitch}
          title={jobAdAttrs.title}
          jobSearchFiltersAb={props.jobSearchFiltersAb}
          bookmark={props.bookmark}
        />
      </>
    );
  }

  return loading ? (
    <LjSpinner />
  ) : (
    <div ref={jobPageRef}>
      {!data.currentUser && renderSignUpModal()}
      {fixedHeader && renderTitle()}
      <div className={styles.wrapper}>
        <div className={styles.leftSide}>
          {!fixedHeader && renderTitle()}
          <Header
            category={jobAdAttrs.category}
            citizenship={jobAdAttrs.citizenship}
            isContractor={jobAdAttrs.contractor}
            location={jobAdAttrs.location || jobAdAttrs.remote_working_label}
            officeLocations={jobAdAttrs.office_locations}
            experienceLabel={jobAdAttrs.experience_label}
            experienceMin={jobAdAttrs.experience_min}
            experienceMax={jobAdAttrs.experience_max}
            fixedHeader={fixedHeader}
            handshake={props.handshake}
            cancelReasons={props.cancelReasons}
            jobSearchFiltersAb={props.jobSearchFiltersAb}
            applications={data.person.applications}
            currentUser={data.currentUser}
            jobAd={props.jobAd}
            referralId={props.referralId}
            hiringBonus={jobAdAttrs.hiring_bonus}
            jobSuggestion={props.jobSuggestion}
            jobType={jobAdAttrs.job_type}
            niceToHaveLanguageLabel={jobAdAttrs.nice_to_have_language_label}
            preferredLanguageLabel={jobAdAttrs.preferred_language_label}
            projectDuration={jobAdAttrs.project_duration}
            relocationPaid={jobAdAttrs.relocation_paid}
            remoteDetails={remoteDetailsText()}
            remoteWorkingLabel={jobAdAttrs.remote_working_label}
            rate={jobAdAttrs.rate}
            salary={jobAdAttrs.salary}
            visaSupport={jobAdAttrs.visa_support}
            locationIcon={jobAdAttrs.location_icon}
            isGlobalRemote={jobAdAttrs.is_global_remote}
            timezoneLabel={jobAdAttrs.timezone_label}
            bookmark={props.bookmark}
            containerRef={jobPageRef}
            companyName={data.company.name}
            companySlug={data.company.slug}
          />
          <Skills
            mustHaveSkills={jobAdAttrs.must_have_skills}
            otherRequiredSkills={jobAdAttrs.other_required_skills}
            niceToHaveSkills={jobAdAttrs.nice_to_have_skills}
          />

          {JOBSECTIONS.map(section => {
            return (
              section.text && (
                <div id={section.id} key={section.id}>
                  <JobSection title={section.title} text={section.text} />
                </div>
              )
            );
          })}
          {isMobile && (
            <>
              <div className={styles.applyButtonWrapper}>
                {!props.handshake && !props.jobSuggestion && (
                  <>
                    <ApplyButton
                      applications={data.person.applications}
                      currentUser={data.currentUser}
                      fixedHeader={fixedHeader}
                      handshake={props.handshake}
                      jobAd={props.jobAd}
                      jobSuggestion={props.jobSuggestion}
                      referralId={props.referralId}
                      style={styles.applyButton}
                      jobSearchFiltersAb={props.jobSearchFiltersAb}
                    />
                    <SubscribeButton
                      currentUser={data.currentUser}
                      job={{
                        id: props.jobAd.id,
                        title: props.jobAd.attributes.title,
                        stateName: props.jobAd.attributes.state_name,
                      }}
                      bookmark={props.bookmark}
                      size='large'
                    />
                  </>
                )}
              </div>

              <AboutCompany
                coverPhoto={data.company.coverPhotoUrl}
                companyId={jobAdAttrs.company_id}
                companyName={data.company.name}
                logo={data.company.logoUrl}
                shortPitch={data.company.shortPitch}
              />
            </>
          )}
          {jobAdAttrs.contractor && <WhatIsAContractor />}
        </div>
        {!isMobile && (
          <div
            className={classNames(styles.rightSide, {
              [styles.fixedTitle]: fixedHeader && !props.handshake,
              [styles.fixedTitleHandshake]: fixedHeader && props.handshake,
            })}
          >
            <Sidebar
              applications={data.person.applications}
              averageHiringProcess={data.company.averageHiringProcess}
              bookmark={props.bookmark}
              cancelReasons={props.cancelReasons}
              company={data.company}
              companySubscriptions={data.person.companySubscriptions}
              currentUser={data.currentUser}
              fixedHeader={fixedHeader}
              handshake={props.handshake}
              jobAd={props.jobAd}
              jobSuggestion={props.jobSuggestion}
              jobs={data.jobs}
              referralId={props.referralId}
              jobSearchFiltersAb={props.jobSearchFiltersAb}
            />
          </div>
        )}
      </div>
    </div>
  );
}
