import * as React from 'react';
import Conversation, { Props } from './conversation';
import { shallow, ShallowWrapper } from 'enzyme';
import messageableDummy from '__fixtures__/messageable_dummy';

const createTestProps = moreProps => {
  return {
    messageable: messageableDummy,
    currentUserId: 1,
    userType: '',
    messagesUrl: jest.fn(id => `http://url/${id}`),
    findConversationUrl: jest.fn(),
    handleConversationClose: jest.fn(),
    handleExpandClick: jest.fn(),
    handleNewConversation: jest.fn(),
    mini: false,
    ...moreProps,
  };
};

let props: Props;
let wrapped: ShallowWrapper;

describe('Conversation component', () => {
  beforeEach(() => {
    props = createTestProps({});
    wrapped = shallow(<Conversation {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
