import * as React from 'react';
import { camelCase } from 'lodash';
import classNames from 'classnames';

import { pluralize } from 'lib/string';
import InlineIcon from '../../general/inline_icon/inline_icon';
import { Context, FormContext } from 'components/form/FormProvider';
import FormError from '../form_error/form_error';

const styles = require('./text.module.scss');
const info = require('iconic/key.svg');

interface Props {
  class?: string;
  color?: string;
  controlled?: boolean;
  disabled?: boolean;
  error?: string;
  hint?: string;
  id?: string;
  inputRef?: any;
  label?: string;
  maxLength?: number;
  maxLengthShow?: boolean;
  name?: string;
  note?: any;
  onBlur?: any;
  onChange?: any;
  onFocus?: any;
  onFocusPopUp?: any;
  onKeyDown?: any;
  placeholder?: string;
  required?: boolean;
  rows?: number;
  textarea?: boolean;
  textAreaClass?: string;
  type?: string;
  useContext?: boolean;
  value?: string;
  autocomplete?: string;
}

interface State {
  focused: boolean;
}

class Text extends React.Component<Props, State> {
  static contextType = FormContext;

  context: Context;
  input: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      focused: false,
    };

    this.renderTextarea = this.renderTextarea.bind(this);
    this.renderInput = this.renderInput.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  clear() {
    this.input.value = '';
  }

  getCharsLeft() {
    return this.props.maxLength - (this.getValue() || '').length;
  }

  getError() {
    return this.props.useContext
      ? this.context.errors[this.props.name]?.join(' ')
      : this.props.error;
  }

  getName() {
    return this.props.useContext ? `${this.context.formName}[${this.props.name}]` : this.props.name;
  }

  getValue() {
    return this.input ? this.input.value : this.getValueFromProps();
  }

  getValueFromProps() {
    const name = this.props.name && camelCase(this.props.name);

    return this.props.useContext ? this.context.resource[name] : this.props.value;
  }

  onChange = event => {
    if (this.props.onChange) this.props.onChange(event);
    // Using `forceUpdate` here because the `X chars left` label might need
    // to be updated, and React doesn't know when it needs to update it because
    // we're using an uncontrolled input.
    this.forceUpdate();
  };

  onFocus() {
    this.setState({ focused: true });
  }

  onBlur() {
    this.setState({ focused: false });
  }

  renderTextarea() {
    return (
      <textarea
        className={classNames(styles.input, this.props.textAreaClass)}
        defaultValue={this.getValueFromProps()}
        disabled={this.props.disabled}
        id={this.props.id}
        maxLength={this.props.maxLength}
        name={this.getName()}
        onBlur={this.props.onFocusPopUp && this.onBlur}
        onChange={this.onChange}
        onFocus={this.props.onFocusPopUp && this.onFocus}
        placeholder={this.props.placeholder}
        ref={this.setInputRef}
        required={this.props.required}
        rows={this.props.rows ? this.props.rows : 5}
      />
    );
  }

  renderInput() {
    const controlledValue = {};

    this.props.controlled
      ? (controlledValue['value'] = this.getValueFromProps())
      : (controlledValue['defaultValue'] = this.getValueFromProps());

    return (
      <input
        className={styles.input}
        disabled={this.props.disabled}
        id={this.props.id}
        maxLength={this.props.maxLength}
        name={this.getName()}
        onBlur={this.props.onBlur || (this.props.onFocusPopUp && this.onBlur)}
        onChange={this.onChange}
        onFocus={this.props.onFocus || (this.props.onFocusPopUp && this.onFocus)}
        placeholder={this.props.placeholder}
        ref={this.setInputRef}
        required={this.props.required}
        type={this.props.type}
        {...controlledValue}
        onKeyDown={this.props.onKeyDown}
        autoComplete={this.props.autocomplete}
      />
    );
  }

  renderFooter() {
    const { hint, maxLength } = this.props;
    const maxLengthShow = maxLength && this.props.maxLengthShow;

    if (hint || maxLengthShow) {
      return (
        <div className={styles.footer}>
          <div className={styles.hint}>{hint}</div>
          <div className={styles.charsLeft}>
            {maxLengthShow && `${pluralize(this.getCharsLeft(), 'char')} left`}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    const error = this.getError();

    return (
      <div className={classNames(styles.textarea, styles[this.props.color], this.props.class)}>
        {this.props.label && (
          <label className={styles.label}>
            {this.props.label}
            {this.props.required && <span className={styles.required}> *</span>}
          </label>
        )}
        {this.props.note && <small className={styles.small}>{this.props.note}</small>}
        {this.props.textarea ? this.renderTextarea() : this.renderInput()}
        {this.props.onFocusPopUp && (
          <span className={classNames(styles.popUp, this.state.focused ? null : styles.hidden)}>
            <InlineIcon className={styles.popUpIcon} path={info} />
            {this.props.onFocusPopUp}
          </span>
        )}
        {error && <FormError text={error} />}
        {this.renderFooter()}
      </div>
    );
  }

  setInputRef = element => {
    this.input = element;
    const { inputRef } = this.props;

    if (typeof inputRef === 'function') {
      inputRef(element);
    } else if (inputRef) {
      inputRef.current = element;
    }
  };
}

export default Text;
