import React, { useRef, useState } from 'react';
import Modal, { ModalSize } from 'components/general/modal/modal';
import { ButtonsLayout } from '../general/modal/modal';
import Icon, { Size } from 'components/general/icon/icon';
import CVUploader from '../general/cv_uploader/cv_uploader';
import { patchJSON, handleRequestError } from '../../lib/request_deprecated';
import { Cv } from '__models__/cv';

const styles = require('./accept_handshake_modal.module.scss');

export interface Props {
  cvs: Cv[];
  handleAccept?: Function;
  handleChangeCv?: any;
  handshake: any;
  handshake_company: { name: string; id: number; logo: string };
  handshake_job_ad: { title: string; id: number };
  selectedCv?: Cv;
  setIsModalOpen: Function;
  hasFollowUpModal?: boolean;
  setIsAfterAccept?: Function;
}

export default function AcceptHandshakeModal(props: Props) {
  const modalRef = useRef(null);
  const company = props.handshake_company.name;
  const jobAd = props.handshake_job_ad.title;
  const [disabledAcceptButton, setDisabledAcceptButton] = useState(false);

  const renderRequestedHandshake = () => {
    return (
      <div className={styles.requestedHandshake}>
        {`${company} requested this Handshake and associated with a job opening: `}
        <a
          className={styles.title}
          href={window.Routes.job_ad({
            id: props.handshake_job_ad.id,
          })}
          target='_blank'
          rel='noreferrer'
        >
          {jobAd}
        </a>
        .
      </div>
    );
  };

  const renderFlavourtext = () => {
    return (
      <div className={styles.flavourText}>
        {renderRequestedHandshake()}
        <div className={styles.acceptWarning}>
          By accepting you are agreeing to create an application to this job.
        </div>
        <div className={styles.shareTitle}>{`Share your CV with ${company}`}</div>

        <div className={styles.shareYourCv}>
          {company} would like to know your life's story, so they can better analyse your profile.
          <br />
          <strong>Note:</strong> Please make sure your CV is in english. By uploading your CV you're
          adding/updating it in your profile.
        </div>
      </div>
    );
  };

  const handleAccept = () => {
    if (props.cvs.length > 0 && !props.selectedCv) {
      window.Alerts.alert("Please make sure you've selected a CV");
      return;
    }
    setDisabledAcceptButton(true);
    const body = { cv_id: props.selectedCv.id };
    patchJSON(window.Routes.accept_dashboard_handshake(props.handshake.id), body)
      .then(data => {
        props.setIsModalOpen(false);

        if (!props.hasFollowUpModal) {
          data.alert
            ? window.Alerts.alert(data.alert)
            : window.Alerts.notice('Handshake accepted!');
        }

        if (props.handleAccept) {
          props.handleAccept(data);
        } else if (props.hasFollowUpModal) {
          props.setIsAfterAccept(true);
        } else {
          window.location.reload();
        }
      })
      .catch(handleRequestError);
  };

  return (
    <Modal
      title={
        <>
          <Icon size={Size.Big} name='handshakeCircleFull' />
          Accept handshake
        </>
      }
      defaultOpen={true}
      ref={modalRef}
      cancelButton={true}
      buttonColor={'tuftsBlueMidLight'}
      buttonName='Accept'
      buttonOnClick={handleAccept}
      buttonsLayout={ButtonsLayout.OkCancel}
      cancelButtonTitle={'Cancel'}
      onClose={() => props.setIsModalOpen(false)}
      disableButton={!props.selectedCv || disabledAcceptButton}
      size={ModalSize.Big}
    >
      {renderFlavourtext()}
      <div className={styles.cvUploaderContainer}>
        <CVUploader
          handleChangeCV={props.handleChangeCv}
          cvs={props.cvs}
          kind='jobs'
          selectedCv={props.selectedCv || props.cvs[0]}
        />
      </div>
    </Modal>
  );
}
