import { gql } from 'lib/graphql';

export const GET_TALENT_ADVOCATE = gql`
  query {
    person {
      talentAdvocate {
        avatar
        email
        name
      }
    }
  }
`;
