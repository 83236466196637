import BaseForm from './base_form';

class CertificationsForm extends BaseForm {
  id: number;
  title: string;
  authority: string;
  year: number;
  file: File;
  fileName: string;
  url: string;

  constructor(args) {
    super(args);

    Object.keys(args).map(k => {
      this[k] = args[k];
    });

    if (!this.errors) {
      this.errors = {};
    }
  }

  serialize() {
    return {
      id: this.id,
      title: this.title,
      authority: this.authority,
      year: this.year,
      file: this.file,
      fileName: this.fileName,
      url: this.url,
    };
  }
}

export default CertificationsForm;
