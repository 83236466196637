import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import WhatsNewItem from './WhatsNewItem';
import Spinner from 'shared/dot_spinner/DotSpinner';

const createProps = () => ({ loading: true });
const createProps2 = () => ({ loading: false });

let props;
let wrapped: ShallowWrapper;

describe("What's new item loading", () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<WhatsNewItem {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('has a spinner if loading', () => {
    expect(wrapped.find(Spinner).length).toEqual(1);
  });
});

describe("What's new item not loading", () => {
  beforeEach(() => {
    props = createProps2();
    wrapped = shallow(<WhatsNewItem {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('has no spinner if not loading', () => {
    expect(wrapped.find(Spinner).length).toEqual(0);
  });
});
