import * as React from 'react';
import { shallow } from 'enzyme';
import JobSearchResults from './JobSearchResults';
import { act } from '@testing-library/react';

const job = require('__fixtures__/job_dummy');

const props = {
  page: 1,
  lastPage: true,
  paginationControl: '',
  profileCompleted: true,
  applications: [],
  bookmarks: [],
  jobResults: [job],
  suggestedJobs: [job],
  bonusJobs: [job],
  interestingJobs: [],
  suggestionRelevantPath: '',
  suggestionNonRelevantPath: '',
  isSearching: true,
};

describe('JobSearch render', () => {
  it('shallow renders without crashing', () => {
    const component = shallow(<JobSearchResults {...props} />);

    expect(component.exists()).toBe(true);
  });

  it('renders all default components', () => {
    const component = shallow(<JobSearchResults {...props} />);

    expect(component.find('JobOffers')).toHaveLength(1);
    expect(component.find('SuggestedJobs')).toHaveLength(1);
    expect(component.find('InterestingJobs')).toHaveLength(1);
  });

  it('it show infinite scroll list on mobile', () => {
    Object.defineProperty(window, 'innerWidth', {
      writable: true,
      configurable: true,
      value: 150,
    });

    act(() => {
      window.dispatchEvent(new Event('resize'));
    });

    const component = shallow(<JobSearchResults {...props} />);

    expect(component.find('InfiniteJobScroll')).toHaveLength(1);
  });
});
