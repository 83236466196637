import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
const styles = require('./textarea.module.scss');

interface Props {
  defaultValue?: string;
  className?: string;
  handleChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  label?: string;
  name?: string;
  placeholder?: string;
  required?: boolean;
  rows?: number;
  color?: string;
}

export default class Textarea extends React.Component<Props, {}> {
  notesRef: any;

  constructor(props) {
    super(props);
    this.notesRef = React.createRef();
  }

  get value() {
    return this.notesRef.current.value;
  }

  styles() {
    return classNames(
      styles.textarea,
      this.props.placeholder ? styles.extra : null,
      styles[this.props.color],
      this.props.className
    );
  }

  render() {
    return (
      <>
        {this.props.label && (
          <label className={styles.label}>
            {this.props.label}
            {this.props.required && <span className={styles.required}> *</span>}
          </label>
        )}
        <textarea
          className={this.styles()}
          defaultValue={this.props.defaultValue}
          name={this.props.name}
          ref={this.notesRef}
          rows={this.props.rows}
          onChange={this.props.handleChange}
          placeholder={this.props.placeholder}
        />
      </>
    );
  }
}
