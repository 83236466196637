import { apolloClient } from 'lib/graphql';
import classNames from 'classnames';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import * as React from 'react';

import Select from 'components/form/select/select';
const Settings = require('settings.json');
import SkillsInput from './skills_input';

const EXPERIENCE_YEARS = Settings.experienceYears.map(([label, value]) => ({
  label,
  value,
}));

const EXPERIENCE_YEARS_EDIT = EXPERIENCE_YEARS.slice();
EXPERIENCE_YEARS_EDIT.unshift({ label: '— Exp —', value: null });
export default class CandidateSkillsInput extends SkillsInput {
  experienceRef: React.RefObject<any>;
  tagRef: React.RefObject<any>;

  constructor(props) {
    super(props);
    this.experienceRef = React.createRef();
    this.tagRef = React.createRef();
    this.styles = require('./candidate_skills_input.module.scss');
    this.addButtonColor = 'tuftsBlue';
  }

  seed = 0;
  generateKey() {
    return this.seed++;
  }

  sortSkills(orderedSkillsAndTagsIds) {
    /*
     * We are going to filter the skills and only update their position
     * The proposed tags can't be ordered
     */
    const { skills } = this.state;
    const orderedSkills = [];
    const orderedSkillsIds = [];
    orderedSkillsAndTagsIds.forEach(id => {
      const selectedSkill = skills.find(skill => skill.id.toString() === id.toString());
      if (selectedSkill) {
        orderedSkillsIds.push(id);
        orderedSkills.push(selectedSkill);
      }
    });

    this.setState(
      () => ({
        skills: orderedSkills,
      }),
      () => this.props.updateSkills(orderedSkills)
    );

    apolloClient
      .mutate({
        mutation: GQL_SORT_SKILLS,
        variables: { ids: orderedSkillsIds },
      })
      .then(
        result => this.handleFulfilledPromisse(result, ['candidate', 'skillsSort']),
        reason => this.handleRejectedPromisse(reason)
      );
  }

  addSkill(addMutation, tagOption) {
    const tagId = this.tagRef.current.getSelectedValue();
    const experienceLevel = this.experienceRef.current.getSelectedValue();

    // Updating the UI with temporary data
    const temporaryId = -this.state.skills.length;
    this.setState(state => ({
      skills: state.skills.concat({
        id: temporaryId,
        tag: 'adding ...',
        experienceLevel: null,
      }),
    }));

    // Requesting the mutation and using the promise to update the UI with the final data
    addMutation({ variables: { experienceLevel, tagId } }).then(
      result => {
        if (this.handleFulfilledPromisse(result, ['candidate', 'skillCreate'])) {
          const createdSkill = result.data.candidate.skillCreate.resource;
          this.findAndUpdateSkill(temporaryId, {
            id: createdSkill.id,
            tag: createdSkill.tag.name,
            experienceLevel: createdSkill.experienceLevel,
          });
        } else {
          this.findAndDeleteSkill(temporaryId);
        }
      },
      reason => {
        this.findAndDeleteSkill(temporaryId);
        this.handleRejectedPromisse(reason);
      }
    );

    // Clearing the input
    this.experienceRef.current.clearSelectedValue();
    this.tagRef.current.clearSelectedValue();
  }

  handleUpdate = updateMutation => skillId => selected => {
    const id = parseInt(skillId, 10);
    const experienceLevel = selected.value;

    this.findAndUpdateSkill(id, { experienceLevel });

    updateMutation({ variables: { experienceLevel, id } });
    return true;
  };

  // get Mutations

  getAddSkillMutation() {
    return GQL_ADD_SKILL;
  }

  async loadOptions(query, callback) {
    const result = await apolloClient.query({
      query: GQL_QUERY_TAGS,
      variables: { query },
    });
    return result.data.tags.nodes.map(t => ({ label: t.name, value: t.id }));
  }

  // renders
  renderInputsExtra(addMutationFromSkillsInput) {
    return (
      <Select
        options={EXPERIENCE_YEARS}
        placeholder='Experience'
        ref={this.experienceRef}
        color={this.props.color ? this.props.color : 'tuftsBlue'}
        onKeyDown={this.handleAdd(addMutationFromSkillsInput, this.experienceRef)}
        testId='skillYears'
      />
    );
  }

  renderInputsHelper() {
    return (
      <p className={this.styles.skillsLeftHelper}>
        {this.props.maximumNumberOfSkills -
          this.state.skills.length -
          this.state.proposedTags.length}{' '}
        Skills left
      </p>
    );
  }

  renderSkillOrTag(skillOrTag, isProposedTag = false) {
    let skillClassName;
    if (isProposedTag) {
      skillClassName = classNames(this.styles.proposedTag, 'disabled');
    } else {
      skillClassName = classNames(this.styles.skill);
    }

    return (
      // eslint-disable-next-line react/no-unknown-property
      <li skill-id={skillOrTag.id} key={this.generateKey()} className={skillClassName}>
        {!this.props.readOnly && (
          <Mutation
            mutation={isProposedTag ? this.getDeleteProposedTagMutation() : GQL_DELETE_SKILL}
          >
            {(deleteMutation, { loading, data = null, error = null }) => (
              <a
                className={this.styles.close}
                onClick={this.handleDelete(deleteMutation)(skillOrTag.id)(isProposedTag)}
              />
            )}
          </Mutation>
        )}
        <div className={this.styles.skillName}>{skillOrTag.tag}</div>
        {this.renderSkillOrTagExperienceLevel(skillOrTag, isProposedTag)}
      </li>
    );
  }

  renderDynamicSkills(skillOrTag, isProposedTag = false) {
    let skillClassName;
    if (isProposedTag) {
      skillClassName = classNames(this.styles.proposedTag, 'disabled');
    } else {
      skillClassName = classNames(this.styles.skill);
    }

    return (
      <li
        // eslint-disable-next-line react/no-unknown-property
        skill-id={skillOrTag.id}
        key={this.generateKey()}
        className={skillClassName} // skillClassName
      >
        {!this.props.readOnly && (
          <Mutation
            mutation={isProposedTag ? this.getDeleteProposedTagMutation() : GQL_DELETE_SKILL}
          >
            {(deleteMutation, { loading, data = null, error = null }) => (
              <a
                className={this.styles.close}
                onClick={this.handleDelete(deleteMutation)(skillOrTag.id)(isProposedTag)}
              />
            )}
          </Mutation>
        )}
        <div className={this.styles.skillName}>{skillOrTag.tag}</div>
        {this.renderSkillOrTagExperienceLevel(skillOrTag, isProposedTag)}
      </li>
    );
  }

  renderSkillOrTagExperienceLevel(skillOrTag, isProposedTag) {
    return isProposedTag
      ? this.renderTagExperienceLevel(skillOrTag)
      : this.renderSkillExperienceLevel(skillOrTag);
  }

  renderSkillExperienceLevel(skill) {
    const experienceValue = EXPERIENCE_YEARS_EDIT.find(
      ({ value }) => value === skill.experienceLevel
    );
    return (
      <>
        {this.props.readOnly ? (
          <div>{experienceValue?.label || ''}</div>
        ) : (
          <Mutation mutation={GQL_UPDATE_SKILL}>
            {(updateMutation, { loading, data = null, error = null }) => (
              <Select
                options={EXPERIENCE_YEARS_EDIT}
                styleMode='smallWhite'
                value={experienceValue}
                onChange={this.handleUpdate(updateMutation)(skill.id)}
                isDisabled={loading}
                readOnly={this.props.readOnly}
              />
            )}
          </Mutation>
        )}
      </>
    );
  }

  renderTagExperienceLevel(tag) {
    return <span className={this.styles.proposedTagStatus}>Waiting for approval</span>;
  }
}

const GQL_QUERY_TAGS = gql`
  query ($query: String) {
    tags(query: $query, excludeUserTags: true) {
      nodes {
        id
        name
      }
    }
  }
`;

export const GQL_ADD_SKILL = gql`
  mutation ($tagId: ID!, $experienceLevel: Int) {
    candidate {
      skillCreate(tagId: $tagId, experienceLevel: $experienceLevel) {
        errors {
          field
          message
        }
        resource {
          id
          experienceLevel
          tag {
            name
          }
        }
      }
    }
  }
`;

const GQL_UPDATE_SKILL = gql`
  mutation ($id: ID!, $experienceLevel: Int) {
    candidate {
      skillUpdate(id: $id, experienceLevel: $experienceLevel) {
        errors {
          field
          message
        }
        resource {
          id
          experienceLevel
          tag {
            name
          }
        }
      }
    }
  }
`;

const GQL_DELETE_SKILL = gql`
  mutation ($id: ID!) {
    candidate {
      skillDelete(id: $id) {
        errors {
          field
          message
        }
      }
    }
  }
`;

const GQL_SORT_SKILLS = gql`
  mutation ($ids: [ID!]!) {
    candidate {
      skillsSort(ids: $ids) {
        errors {
          field
          message
        }
      }
    }
  }
`;
