import * as Cookies from 'js-cookie';
import * as React from 'react';

import 'lib/globals';
import Button from 'lj_shared/button/button';
const styles = require('./ask_notifications_permission.module.scss');
const logo = require('images/lj_logo.png');

interface Props {
  userType: string;
}

interface State {
  open: boolean;
  top: number;
}

export default class AskNotificationsPermission extends React.Component<Props, State> {
  readonly COOKIE_NAME = 'notifications-permissions';
  readonly TOP_OFFSET = 58;

  accepted = this.handleButton.bind(this, 'accepted');
  rejected = this.handleButton.bind(this, 'rejected');

  constructor(props) {
    super(props);
    this.state = { open: this.shouldOpen(), top: this.calculateTop() };
    this.updateTop = this.updateTop.bind(this);
  }

  componentDidMount() {
    $('.ld-wrapper').on('top-alert:recompute', this.updateTop);
  }

  componentWillUnmount() {
    $('.ld-wrapper').off('top-alert:recompute', this.updateTop);
  }

  calculateTop() {
    // HACK: compute this component's top based on the navbar margin-top
    const offset = $('.ld-wrapper').offset();
    const navbarTop = (offset && offset.top) || 0;
    return navbarTop + this.TOP_OFFSET;
  }

  updateTop() {
    this.setState({ top: this.calculateTop() });
  }

  shouldOpen() {
    return (
      window.Notification && Notification.permission === 'default' && !Cookies.get(this.COOKIE_NAME)
    );
  }

  handleButton(action, event) {
    event.preventDefault();
    this.setState({ open: false });

    if (action === 'accepted') {
      Notification.requestPermission();
    }
  }

  render() {
    if (this.state.open) {
      Cookies.set(this.COOKIE_NAME, 'true', { expires: 30 });
      const color = this.props.userType === 'TalentUser' ? 'puertoRico' : 'ripePlum';

      return (
        <div className={styles.container} style={{ top: this.state.top }}>
          <div className={styles.dialog}>
            <img className={styles.logo} src={logo} alt='Landing.Jobs' />
            <div className={styles.title}>Receiving Notifications</div>
            <div className={styles.text}>Would you like to subscribe to our notifications?</div>
            <div className={styles.note}>
              Why are we asking you this? By subscribing to notifications you will be able to keep
              better track of your account activity and applications.
            </div>
            <div className={styles.buttons}>
              <Button
                buttonColor='silverSand'
                buttonSize='xSmall'
                buttonType='border'
                isButton={true}
                onClick={this.rejected}
              >
                Maybe later
              </Button>
              <Button
                buttonColor={color}
                buttonSize='xSmall'
                isButton={true}
                onClick={this.accepted}
              >
                Yes, subscribe
              </Button>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
