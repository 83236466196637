import * as React from 'react';
import 'lib/globals';

interface Props {
  title: string;
  url: string;
}

interface State {
  content: string;
}

export default class DeferredTab extends React.Component<Props, State> {
  isLoaded: boolean;

  constructor(props) {
    super(props);
    this.open = this.open.bind(this);
    this.state = { content: '' };
  }

  open(event) {
    event.preventDefault();

    if (!this.isLoaded) {
      window.toggleSpinner(true);
      $.get(this.props.url)
        .always(() => window.toggleSpinner(false))
        .done(data => {
          this.setState({ content: data });
          this.isLoaded = true;
        });
    }
  }

  render() {
    const url = this.props.url;
    const title = this.props.title;
    const content = { __html: this.state.content };

    return (
      <>
        <a className='ld-tab-link' href={url} onClick={this.open}>
          {title}
        </a>
        <div className='ld-tab-content' dangerouslySetInnerHTML={content} />
      </>
    );
  }
}
