import classNames from 'classnames';
import Icon, { Size } from 'components/general/icon/icon';
import Tooltip from 'components/general/tooltip/tooltip';
import React, { useState } from 'react';
const styles = require('./offer_filter_option.module.scss');

interface Option {
  count: number;
  help_text: string;
  id: string;
  name: string;
  value: boolean;
  label: string;
}

interface Props {
  options?: Option[];
  nestedOptions?: Option[][];
  isRadio?: boolean;
  isNestedOption?: boolean;
}

const OfferFilterOption = ({ options, nestedOptions, isRadio, isNestedOption }: Props) => {
  const [subOptions, setSubOptions] = useState<Option[]>([]);
  const [remainingOptions, setRemainingOptions] = useState<Option[][]>(nestedOptions || []);

  const optionEntry = (option: Option) => (
    <div key={option.id} className='ld-form-group ld-checkbox-group optional'>
      <input
        className='ld_boolean ld-form-input'
        id={option.id}
        name={option.name}
        type={isRadio ? 'radio' : 'checkbox'}
        value={option.value?.toString()}
      />
      <label htmlFor={option.id} className={styles.optionLabel}>
        <span>
          {!isRadio && (
            <Icon
              name='check'
              className={classNames('iconic', styles.checkboxIcon)}
              color='white'
              size={Size.Small}
            />
          )}
        </span>
        <span className='filter_counter'>{`${option.label} ${
          option.count ? '(' + option.count + ')' : ''
        }`}</span>
      </label>
      {option.help_text && (
        <div id={`form-tooltip-${option.name}`} className='form-tooltip'>
          <Tooltip
            text={option.help_text}
            // eslint-disable-next-line react/no-children-prop
            children={<Icon name='helpCircle' color='tuftsBlue' size={Size.MediumLarge} />}
          />
        </div>
      )}
    </div>
  );

  const getCheckboxButtons = (optionsEntries: Option[]) =>
    optionsEntries?.map((option: Option) => optionEntry(option));

  const getNestedOptions = () => (
    <div className='lj-inlineAccordionItem js-slideDownItem'>
      {getCheckboxButtons(subOptions)}
      {remainingOptions.length > 0 && (
        <button
          className={styles.loadMoreButton}
          onClick={e => {
            e.preventDefault();
            setSubOptions(prevState => [...prevState, ...remainingOptions[0]]);
            setRemainingOptions(remainingOptions.splice(1));
          }}
        >{`Show ${remainingOptions[0].length < 5 ? remainingOptions[0].length : 5} more`}</button>
      )}
    </div>
  );

  return <>{!isNestedOption ? getCheckboxButtons(options) : getNestedOptions()}</>;
};

export default OfferFilterOption;
