import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import QuestionMarkTooltip from './question_mark_tooltip';

const createProps = () => ({
  containerClass: 'pricing-question-mark-tooltip',
  iconColor: 'tuftsBlue',
  content: <>Banana na na</>,
});

let props;
let wrapped: ShallowWrapper;

describe('QuestionMarkTooltip', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<QuestionMarkTooltip {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
