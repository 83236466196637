import * as React from 'react';
import { mount } from 'enzyme';
import AcceptSuggestionModal, { Props } from './accept_suggestion_modal';
import Modal from 'components/general/modal/modal';
import Button from 'lj_shared/button/button';
import CVUploader from '../general/cv_uploader/cv_uploader';

let props: Props;
let wrapped = null;

window.Routes = {
  job_ad: () => '',
  accept_dashboard_suggestion: () => '',
};

const createTestProps = () => {
  return {
    cvs: [],
    jobSuggestion: { createdAt: '2020-01-01', state: 'contacted' },
    jobAd: { title: 'my job', id: 177013, attributes: { company_name: 'test' } },
    setIsModalOpen: jest.fn(),
  };
};

describe('Accept Suggestion Modal component', () => {
  beforeAll(() => {
    props = createTestProps();
    wrapped = mount(<AcceptSuggestionModal {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('contains a Modal', () => {
    expect(wrapped.find(Modal).length).toEqual(1);
  });

  it('contains 2 Buttons', () => {
    expect(wrapped.find(Button).length).toEqual(2);
  });

  it('contains CVUploader', () => {
    expect(wrapped.find(CVUploader).length).toEqual(1);
  });
});
