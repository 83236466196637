import * as React from 'react';
import DotLoader from 'lj_shared/dot_loader/dot_loader';

const ChatLoading = props => {
  const items = [];
  for (let i = 0; i < 6; i++) {
    items.push(
      <li className='lj-conversation-list-item' key={i}>
        <a href='#'>
          <div className='lj-userAvatar--small lj-userAvatar lj-loading-grey' />
          <div className='lj-conversation-info'>
            <h5 className='ld-h5 lj-loading-grey'>&nbsp;</h5>
            <div className='lj-conversation-timestamp lj-loading-grey'>&nbsp;</div>
          </div>
        </a>
      </li>
    );
  }

  return (
    <div className='lj-chat lj-chat-loading'>
      <div className='lj-conversations'>
        <form id='conversation-search-form'>
          <input
            className='lj-conversation-search'
            placeholder={
              props.userType === 'CompanyUser' ? 'Job Opening, Candidate...' : 'Company...'
            }
          />
        </form>
        <ul className='lj-conversations-list'>{items}</ul>
      </div>
      <div className='lj-conversation'>
        <div className='lj-conversation-header'>
          <h3 className='lj-loading-grey'>&nbsp;</h3>
          <span className='lj-loading-grey'>&nbsp;</span>
        </div>
        <div className='lj-messages-list'>
          <DotLoader />
        </div>
        <form className='lj-message-form'>
          <textarea className='lj-input l j-input--mid lj-input--block u-marginBottom--small' />
        </form>
      </div>
    </div>
  );
};

export default ChatLoading;
