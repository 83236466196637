import React from 'react';

import AboutUs from './AboutUs';
import Jobs from './Jobs';
import Team from './Team';
import Offices from './Offices';
import { teamDataMapper } from '../../companyInfoForm/TeamElementsList';

const styles = require('./Tabs.module.scss');

interface Tab {
  isJobs?: boolean;
  isVisible: (data: any) => boolean;
  label: string;
  render: (data: any) => JSX.Element;
}

const ABOUT_US = {
  label: 'About us',

  isVisible(data) {
    return true;
  },

  render(data) {
    return (
      <main className={styles.main}>
        <AboutUs company={data.company} />
      </main>
    );
  },
};

const TEAM = {
  label: 'Team',

  isVisible(data) {
    return data.company.members.length > 0 || data.company.teamDescription;
  },

  render(data) {
    return (
      <Team
        teamMembers={teamDataMapper(
          data.company.members.map(member => {
            return { attributes: member };
          })
        )}
        description={data.company.teamDescription}
      />
    );
  },
};

const JOBS = {
  label: 'Jobs',

  isJobs: true,

  isVisible(data) {
    return data.jobs.nodes.length > 0;
  },

  render(data) {
    return (
      <main className={styles.main}>
        <Jobs
          applications={data.person.applications}
          bookmarks={data.user.bookmarks}
          jobDismissals={data.person.jobDismissals}
          jobs={data.jobs.nodes}
          user={data.currentUser}
        />
      </main>
    );
  },
};

const OFFICES = {
  label: 'Offices',

  isVisible(data) {
    return data.company.offices.length > 0;
  },

  render(data) {
    return (
      <main className={styles.main}>
        <Offices offices={data.company.offices} />
      </main>
    );
  },
};

const TABS: Tab[] = [ABOUT_US, TEAM, JOBS, OFFICES];

export function visibleTabs(data) {
  return TABS.filter(tab => tab.isVisible(data));
}
