import React from 'react';
import ContextConsumer from '../../landing_work/home/components/context';
import WelcomeContainer from './welcome_container';
import WelcomeEmail from './welcome_email';

import './welcome.scss';
import { getQueryParams } from 'lib/utils';

const EN = require('./copy/EN/welcome.json');
const PT = require('./copy/PT/welcome.json');

let currentLanguage;
let language;

interface WelcomeProps {
  email: boolean;
  urls: {
    positivePath: string;
    negativePath: string;
    jobsPath: string;
  };
  loginProps?: any;
}

class Welcome extends React.Component<WelcomeProps> {
  render() {
    return (
      <ContextConsumer>
        {({ data }) => {
          const product = 'jobs';
          document.title = `Landing.${product} | Welcome`;
          language = data.contextLanguage;
          language === 'PT' ? (currentLanguage = PT) : (currentLanguage = EN);
          let welcomeContainer = currentLanguage.WelcomeContainer['LJ'];

          // Override params if coming from a job subscription
          if (getQueryParams()?.subscribe_id) {
            const jobId = getQueryParams().subscribe_id;
            this.props.urls.jobsPath = window.Routes.job_ad(jobId);
            welcomeContainer = currentLanguage.WelcomeContainer['SJ'];
          }

          return (
            <>
              <div className='welcome_background'>
                {this.props.email ? (
                  <WelcomeEmail
                    language={currentLanguage.WelcomeEmail}
                    urls={this.props.urls}
                    loginProps={{
                      urls: this.props.loginProps,
                      language: currentLanguage.LogIn,
                    }}
                  />
                ) : (
                  <WelcomeContainer
                    language={welcomeContainer}
                    buttonColor='tuftsBlue'
                    urls={this.props.urls}
                  />
                )}
                <div className='welcome_image' />
              </div>
            </>
          );
        }}
      </ContextConsumer>
    );
  }
}

export default Welcome;
