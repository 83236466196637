import React from 'react';
import renderer from 'react-test-renderer';
import JobTypeButtons from './JobTypeButtons';
import { render } from '@testing-library/react';

const props = {
  showCompanySelect: false,
  company: {
    value: 1,
    label: 'company',
  },
  companies: [
    {
      value: 2,
      label: 'company2',
    },
  ],
  setCompany: jest.fn(),
  setScreenType: jest.fn(),
  submitError: false,
  setSubmitError: jest.fn(),
};

describe('JobTypeButtons', () => {
  it('should render correctly', () => {
    const wrapper = renderer.create(<JobTypeButtons {...props} />);
    expect(wrapper).toMatchSnapshot();

    expect(wrapper.root.findByProps({ 'data-testid': 'job-type-buttons' })).toBeTruthy();

    expect(
      wrapper.root.findByProps({ 'data-testid': 'job-type-buttons' }).props.children
    ).toHaveLength(2);
  });

  it('should render company select', () => {
    const { getByTestId } = render(<JobTypeButtons {...props} showCompanySelect={true} />);

    expect(getByTestId('job-type-buttons').querySelectorAll('.companyForm')).toBeTruthy();
  });
});
