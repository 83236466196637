import * as React from 'react';
import ErrorLabel from './error_label';

interface Props {
  alternateRef: any;
  isInvalid: boolean;
  isEmpty: boolean;
  isLong: boolean;
  isRepeated: boolean;
  isName: boolean;
}

export default function AlternateErrors(props: Props) {
  return (
    <>
      {props.isEmpty && <ErrorLabel message={"This field can't be submited empty."} />}

      {props.isLong && (
        <ErrorLabel
          message={`Skill alternate cannot be longer than 50 characters. Current: ${props.alternateRef.current.value.length}.`}
        />
      )}
      {props.isInvalid && <ErrorLabel message={"Skill alternate cannot contain ',' or ';'."} />}

      {props.isRepeated && <ErrorLabel message={'Skill alternate already added'} />}

      {props.isName && (
        <ErrorLabel message={"Skill alternate can't be the same as the skill name"} />
      )}
    </>
  );
}
