import { getJSON, handleSuccessAlert } from 'lib/request';

interface Props {
  pricingLimitType?: string;
  pricingDisabledFeature?: string;
}

export function adminUserPricingNotification(props: Props) {
  window.toggleSpinner(true);

  const params = {
    pricing_limit_type: props.pricingLimitType,
    pricing_disabled_feature: props.pricingDisabledFeature,
  };

  const url = window.Routes.company_admin_user_pricing_notification(params);

  getJSON(url, window.toggleSpinner(false) && handleSuccessAlert);
}
