import * as React from 'react';
import { isString } from 'lodash';
import DotLoader from 'lj_shared/dot_loader/dot_loader';
import 'lib/globals';
import classNames from 'classnames';
const styles = require('./email_preview_suggest_job.module.scss');

export interface Props {
  hide: string; // Selector of element to hide, when email preview is expanded
  form: any; // Selector of form to submit for email preview
  title?: string; // By default 'Email preview'
  url: string; // Url to where the form should be posted
}

interface State {
  content: string;
  loading: boolean;
}

export default class EmailPreviewSuggestJob extends React.Component<Props, State> {
  root: any;

  constructor(props) {
    super(props);

    this.state = { content: '', loading: false };
    this.refresh = this.refresh.bind(this);
    this.needsRefresh = this.needsRefresh.bind(this);
  }

  needsRefresh() {
    return this.state.loading;
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps: Props) {
    if (this.props.form.jobAdId !== nextProps.form.jobAdId) {
      this.props.form.jobAdId = nextProps.form.jobAdId;
    }

    return this.refresh();
  }

  refresh() {
    this.setState({
      content: '',
      loading: true,
    });

    const form = isString(this.props.form)
      ? $(this.props.form).serialize()
      : this.props.form.serialize();

    $.post(this.props.url, form).done(data => {
      this.setState({
        content: data,
        loading: false,
      });
    });
  }

  render() {
    return (
      <div className={classNames(styles.email, 'modal')} style={{ margin: '2 -0.8rem' }}>
        <div>
          {this.needsRefresh() ? (
            <div style={{ position: 'relative' }}>
              <DotLoader />
            </div>
          ) : (
            <div>
              <div className={classNames(styles.title)}>
                {this.state.content && this.props.title + ': '}
              </div>
              <blockquote dangerouslySetInnerHTML={{ __html: this.state.content }} />
            </div>
          )}
        </div>
      </div>
    );
  }
}
