import React from 'react';
import { render } from '@testing-library/react';
import SuggestionCard from './suggestion_card';

window.Routes = { dismiss_dashboard_suggestion: jest.fn() };

describe('SuggestionCard', () => {
  const buttonQueryOpts = { exact: false, selector: 'a, button' };

  it('shows Apply and Dismiss button when the suggestion is on state "contacted"', () => {
    const jobSuggestion = { createdAt: '2020-01-01', state: 'contacted', cvs: [] };
    const jobAd = { title: 'my job', id: 177013, attributes: { company_name: 'test' } };
    const { getByText } = render(<SuggestionCard jobSuggestion={jobSuggestion} jobAd={jobAd} />);
    expect(getByText('Accept', buttonQueryOpts)).toBeTruthy();
    expect(getByText('Dismiss', buttonQueryOpts)).toBeTruthy();
  });

  it('shows the application state when the suggestion is on state "accepted"', () => {
    const state = 'Company hiring process';
    const application = { submittedAt: '2020-01-01', stateForCandidate: state };
    const jobSuggestion = { createdAt: '2020-01-01', state: 'accepted', cvs: [] };
    const { getByText } = render(
      <SuggestionCard application={application} jobSuggestion={jobSuggestion} />
    );
    expect(getByText(state, { exact: false })).toBeTruthy();
  });

  it('shows Apply button when the suggestion is on state "not_interested"', () => {
    const jobSuggestion = {
      createdAt: '2020-01-01',
      state: 'not_interested',
      updatedAt: '2020-01-01',
      cvs: [],
    };
    const { getByText } = render(<SuggestionCard jobSuggestion={jobSuggestion} />);
    expect(getByText('Accept', buttonQueryOpts)).toBeTruthy();
  });
});
