import React from 'react';
import { Article } from '__models__/article';

const styles = require('./article_item.module.scss');

interface Props {
  article: Article;
  baseArticleUrl: string;
}

export default function ArticleItem(props: Props) {
  const articleUrl = () => props.baseArticleUrl.replace('~id', props.article.id);

  return (
    <div className={styles.container}>
      <a href={articleUrl()} className={styles.link}>
        {props.article.attributes.title}
      </a>
    </div>
  );
}
