import FormInput from 'components/general/form_input/form_input';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const styles = require('./LjDatePicker.module.scss');

interface Props {
  onChange?: Function;
  selected?: any;
  label?: string;
  open?: boolean;
  required?: boolean;
  id?: string;
}

export default function LjDatePicker(props: Props) {
  const [date, setDate] = useState(props.selected || new Date());
  const mediumContainerHeight = 206;

  useEffect(() => adjustContainerHeight(), []);

  const adjustContainerHeight = () => {
    const datePickerContainer = document.getElementById('datePickerContainer');
    const daysContainer = document.querySelector<HTMLElement>('.react-datepicker__month-container');
    const daysContainerHeight = daysContainer?.offsetHeight;

    if (daysContainerHeight > mediumContainerHeight) {
      datePickerContainer.style.height = '17rem';
    } else {
      datePickerContainer.style.height = '15.5rem';
    }
  };

  return (
    <div id='datePickerContainer' className={styles.datePickerContainer}>
      <FormInput label={props.label} required={props.required || false}>
        <DatePicker
          useWeekdaysShort={true}
          selected={date}
          onChange={value => (props.onChange && props.onChange(value)) || setDate(value)}
          onMonthChange={adjustContainerHeight}
          open={props.open || false}
          label={props.label}
          required={props.required}
          onOpen={adjustContainerHeight}
        />
        <input type='hidden' value={date} id={props.id} />
      </FormInput>
    </div>
  );
}
