import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import Skills from './Skills';

const createProps = () => ({
  mustHaveSkills: [
    { id: 1, name: 'Ruby' },
    { id: 2, name: 'React' },
  ],
  otherRequiredSkills: [{ id: 3, name: 'SQL' }],
  niceToHaveSkills: [],
});

let props;
let wrapped: ShallowWrapper;

describe('Skills render correctly', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<Skills {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('should have must have skills', () => {
    expect(wrapped.find('.mustHave').exists()).toBe(true);
  });

  it('should have other required skills', () => {
    expect(wrapped.find('.otherRequired').exists()).toBe(true);
  });

  it('should not have nice to have skills', () => {
    expect(wrapped.find('.niceToHave').exists()).toBe(false);
  });
});
