import React from 'react';
import FormInput from 'components/general/form_input/form_input';
import {
  jobCategories,
  citizenships,
} from 'components/registration/registration_utils/registration_utils';
import RadioButtons from 'components/form/radio_buttons/radio_buttons';
import { multiValueStyles } from 'lib/select_styles';
import JobTypeInterests from './JobTypeInterests';
import Select from 'components/form/select/select';
import Button from 'lj_shared/button/button';
import RemotePolicyPreferences from './RemotePolicyPreferences';

const styles = require('./JobInterestsModalContainer.module.scss');

const YES_NO_OPTIONS = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];

export default function JobInterestsModalContainer(props) {
  const renderRelocation = () => (
    <>
      <FormInput name='relocation' label='Relocation'>
        <RadioButtons
          selectedValue={props.profileForm.relocation}
          options={YES_NO_OPTIONS}
          onChange={event => props.handleFormChange(event, 'relocation')}
          name='talentProfile[relocation]'
        />
      </FormInput>
      {props.profileForm.relocation && renderRelocationCountries()}
    </>
  );

  const renderRelocationCountries = () => (
    <div>
      <FormInput name='relocation_countries' label='Do you have any country preference?'>
        <Select
          name='relocation_countries'
          placeholder='Countries'
          options={citizenships(props.countriesList).slice(1)}
          color='silverSand'
          styles={multiValueStyles('silverSand')}
          isMulti={true}
          value={props.profileForm.relocationCountries}
          onChange={event => props.handleFormChange(event, 'relocationCountries')}
        />
      </FormInput>
    </div>
  );

  const renderJobCategories = () => (
    <FormInput
      name='categories'
      label='What are your areas of interest?'
      description='Please pick up to 3 areas of interest.'
      error={props.profileForm.errors.categories}
      required
    >
      <Select
        name='categories'
        placeholder='e.g. Ux designer'
        options={jobCategories(props.categoriesList)}
        color='silverSand'
        styles={multiValueStyles('silverSand')}
        isMulti={true}
        value={props.profileForm.categories}
        onChange={event => props.handleFormChange(event, 'categories')}
      />
    </FormInput>
  );

  const renderRemotePolicies = () => (
    <RemotePolicyPreferences
      error={props.profileForm.errors.jobTypesInterest?.remotePolicy}
      jobTypesInterest={props.profileForm.jobTypesInterest}
      jobTypesList={props.jobTypesList}
      handleFormChange={props.handleFormChange}
    />
  );

  const renderJobTypes = () => (
    <JobTypeInterests
      error={props.profileForm.errors.jobTypesInterest?.jobType}
      jobTypesInterest={props.profileForm.jobTypesInterest}
      jobTypesList={props.jobTypesList}
      handleFormChange={props.handleFormChange}
      showRateAndGas={true}
      rate={{
        minimum: props.profileForm.minimumRate,
        maximum: props.profileForm.maximumRate,
      }}
      gas={{
        minimum: props.profileForm.minimumGAS,
        maximum: props.profileForm.maximumGAS,
      }}
      showRemotePreferences={false}
    />
  );

  return (
    <div className={styles.modalContainer}>
      <form>
        {renderJobCategories()}
        {renderRemotePolicies()}
        {renderJobTypes()}
        {renderRelocation()}
        <div className={styles.submitButton}>
          <Button buttonColor='tuftsBlue' onClick={event => props.handleSubmit(event)}>
            UPDATE
          </Button>
        </div>
      </form>
    </div>
  );
}
