import classNames from 'classnames';
import Button from './button';
import React from 'react';

const styles = require('./roles.module.scss');

interface Props {
  copy: any;
}

const images = {
  back_end: require('../images/back_end.svg'),
  full_stack: require('../images/full_stack.svg'),
  mobile_app: require('../images/mobile_app.svg'),
  front_end: require('../images/front_end.svg'),
  others: require('../images/others.svg'),
};

const Roles: React.FunctionComponent<Props> = props => {
  const renderRoles = (role, image) => {
    return (
      <div className={styles.column_jobs}>
        <img className={styles.image} alt='support' src={image} />
        <div>{role}</div>
      </div>
    );
  };
  return (
    <div className={styles['lw-roles-container']}>
      <h2 className={classNames(styles['h1-hero-employer'], styles.red_title)}>
        {props.copy.title}
      </h2>
      <p className={classNames(styles['lw-text-hide'], styles.description_adjust)}>
        {props.copy.description}
      </p>
      <div className={styles['row-container']}>
        <div className={styles['row_jobs']}>
          {renderRoles(props.copy.positions.role1, images.back_end)}
          {renderRoles(props.copy.positions.role2, images.full_stack)}
        </div>
        <div className={styles['row_jobs']}>
          {renderRoles(props.copy.positions.role3, images.mobile_app)}
          {renderRoles(props.copy.positions.role4, images.front_end)}
        </div>
        <div className={styles['row_jobs']}>
          {renderRoles(props.copy.positions.role5, images.others)}
        </div>
      </div>
      <div className={styles['rolesBtn']}>
        <Button
          otherClasses={styles['midButtons-button']}
          buttonColor='ripePlum'
          buttonSize='mid'
          isButton={false}
          isRound={true}
          buttonUrl='/employer/join'
        >
          {props.copy.startButton}
        </Button>
      </div>
    </div>
  );
};

export default Roles;
