import Button from 'lj_shared/button/button';
import React, { useState } from 'react';
import Icon, { Size } from 'components/general/icon/icon';
import { Job } from '__models__/gql/job';
import useBookmarks from './useBookmarks';
import { Bookmark } from '__models__/gql/bookmark';
import classNames from 'classnames';

const styles = require('./SubscribeButton.module.scss');
interface Props {
  currentUser: any;
  job: Partial<Job>;
  bookmark: Partial<Bookmark>;
  style?: string;
  size?: 'small' | 'medium' | 'large';
}

const SubscribeButton = (props: Props) => {
  const [bookmarkId, setBookmarkId] = useState(props.bookmark?.id);
  const bookmarks = useBookmarks();

  const classnames = classNames(
    props.style,
    styles.subscribeButton,
    props.size && styles[`size-${props.size}`]
  );

  const handleDeleteBookmark = () => {
    bookmarks
      .destroy(bookmarkId)
      .then(() => {
        setBookmarkId(null);
        window.Alerts.notice(`You unsaved ${props.job.title}.`, true);
      })
      .catch();
  };

  const handleCreateBookmark = () => {
    bookmarks
      .create(props.job.id)
      .then((response: Bookmark) => {
        setBookmarkId(response.id);
        window.Alerts.notice(
          `Yay! You saved ${props.job.title} to continue your application later.`,
          true
        );
      })
      .catch();
  };

  const getButtonAction = (event: Event) => {
    event.stopPropagation();
    if (props.currentUser?.type === 'TalentUser' && props.job.stateName === 'published') {
      return bookmarkId ? handleDeleteBookmark() : handleCreateBookmark();
    } else {
      return window.open(window.Routes.join({ subscribe_id: props.job.id }), '_self');
    }
  };

  return (
    props.currentUser?.type !== 'CompanyUser' &&
    props.job.stateName === 'published' && (
      <div className={classnames} data-testid='subscribe-button'>
        <Button
          onClick={getButtonAction}
          buttonUrl={void 0}
          buttonColor='tuftsBlue'
          otherClasses={styles.subscribeButton + ' lj-button--border'}
          buttonSize={props.size ?? 'mid'}
        >
          <Icon
            name={bookmarkId ? 'star' : 'starOutline'}
            className={styles.star}
            size={(props.size === 'large' ? Size.Big : Size.Small) ?? Size.Medium}
          />
        </Button>
      </div>
    )
  );
};

export default SubscribeButton;
