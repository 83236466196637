import React, { useEffect, useRef, useState } from 'react';
import Modal, { ButtonsLayout } from 'components/general/modal/modal';
import { postJSON } from 'lib/request';
import Select from 'components/form/select/select';
import LjDatePicker from 'components/ljDatePicker/LjDatePicker';
import FormInput from 'components/general/form_input/form_input';
import Textarea from 'components/form/textarea/textarea';
import FormError from 'components/form/form_error/form_error';
import classNames from 'classnames';

const styles = require('./ConfirmHireModal.module.scss');

interface Props {
  applicationId: number;
  startDate?: Date;
  hireTypes: { type: number };
  grossSalary?: number;
  requireGrossSalary: boolean;
  showHiringFeeWarning: boolean;
  freeHires?: { total: number; available: number };
  subscriptionPlanId: number;
}

export default function ConfirmHireModal(props: Props) {
  const {
    applicationId,
    hireTypes,
    freeHires,
    requireGrossSalary,
    showHiringFeeWarning,
    subscriptionPlanId,
  } = props;
  const setFormDate = props.startDate ? new Date(props.startDate) : new Date();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [hireType, setHireType] = useState(null);
  const [grossSalary, setGrossSalary] = useState(props.grossSalary?.toString() || '');
  const [startDate, setStartDate] = useState(setFormDate);
  const [customValue, setCustomValue] = useState('');
  const [description, setDescription] = useState('');
  const modalRef = useRef(null);

  useEffect(() => setSubmitEnabled(!submitting && formIsValid()));

  const handleSubmit = () => {
    setSubmitting(true);
    window.toggleSpinner(true);

    const url = window.Routes.backoffice_application_hire(applicationId);
    const params = {
      hire_type: hireType.value,
      start_date: startDate,
    };

    if (requireGrossSalary || hireType.label === 'Normal') {
      params['hire_gross_annual_salary'] = grossSalary;
    }

    if (hireType.label === 'Other') {
      params['hire_custom_value'] = customValue;
      params['hire_description'] = description;
    }

    const followUp = response => {
      window.toggleSpinner(false);

      if (response.alert) {
        window.Alerts.alert(response.alert);
      } else {
        window.Alerts.notice(response.notice);
        window.location.reload();
      }

      setSubmitting(false);
    };

    postJSON(url, params, followUp);
  };

  const hasInitialSalary = props.grossSalary && props.grossSalary > 0;
  const salaryIsValid = requireGrossSalary ? grossSalary?.length > 0 || hasInitialSalary : true;

  const formIsValid = () => {
    if (!salaryIsValid) {
      return false;
    } else {
      switch (hireType?.label) {
        case undefined:
          return false;
        case 'Other':
          return customValue?.length > 0;
        case 'Normal':
          return !showHiringFeeWarning;
        case 'Free':
          return !showHiringFeeWarning && freeHires?.available > 0;
        case 'Replacement':
          return !showHiringFeeWarning;
        default:
          return true;
      }
    }
  };

  const hireTypeOptions = Object.entries(hireTypes).map(([label, value]) => {
    const isDisabled = label === 'Free' && (!freeHires || freeHires.available <= 0);

    return { label, value, isDisabled };
  });

  const subscriptionMandatoryTypes = ['Normal', 'Replacement', 'Free'];

  return (
    <>
      <a
        className='ld-button ld-small-button ld-green-button ld-border-button'
        onClick={() => setIsModalOpen(true)}
      >
        Mark as Confirmed Hire
      </a>
      <Modal
        title='Confirm Hire'
        ref={modalRef}
        buttonsLayout={ButtonsLayout.OkCancel}
        buttonName={submitting ? 'Submitting...' : 'Submit'}
        buttonColor='puertoRicoMidDark'
        buttonOnClick={handleSubmit}
        isButton={true}
        disableButton={!submitEnabled}
        cancelButton={true}
        disableOutsideClose={true}
        defaultOpen={false}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <div className={styles.mainContainer}>
          {subscriptionMandatoryTypes.includes(hireType?.label) && !subscriptionPlanId && (
            <FormError
              text={
                'This company has no subscription matching the pre-hire date or the creation of this application.'
              }
            />
          )}
          {!!subscriptionPlanId &&
            subscriptionMandatoryTypes.includes(hireType?.label) &&
            showHiringFeeWarning && (
              <FormError
                text={
                  <>
                    Please define a 'Hiring Percentage Fee' or a 'Hiring Fee' in the&nbsp;
                    <a
                      href={window.Routes.edit_backoffice_subscription_plan(subscriptionPlanId)}
                      target='_blank'
                      rel='noreferrer'
                    >
                      application's corresponding subscription
                    </a>
                    .
                    <p className='u-marginBottom--none u-marginTop--small'>
                      Note: In case it’s another user editing the subscription, please send him the
                      link above.
                    </p>
                  </>
                }
              />
            )}
          <div className={styles.container}>
            <div className={styles.leftContainer}>
              <Select
                id='hireType'
                name='hireType'
                color='tuftsBlue'
                options={hireTypeOptions}
                label='Hire type'
                placeholder='Select hire type'
                value={hireType}
                required={true}
                onChange={selected => setHireType(selected)}
              />
              {hireType?.label === 'Free' && freeHires && (
                <>
                  <p id='freeHiresCounter' className={styles.freeHiresCounter}>
                    {freeHires.available}/{freeHires.total} available
                  </p>
                </>
              )}
              {hireType && requireGrossSalary && !hasInitialSalary && (
                <FormInput label='Gross salary' required={requireGrossSalary}>
                  <input
                    id='backofficeHireGas'
                    required={requireGrossSalary}
                    className={styles.input}
                    value={grossSalary}
                    onChange={ev => setGrossSalary(ev.target.value)}
                    placeholder={'Define the gross annual salary..'}
                  />
                </FormInput>
              )}
              {hireType?.label === 'Other' && (
                <FormInput label='Value' required={true}>
                  <input
                    id='otherHireValue'
                    className={styles.input}
                    placeholder='Custom value..'
                    value={customValue}
                    onChange={ev => setCustomValue(ev.target.value)}
                  />
                </FormInput>
              )}
            </div>
            <div className={styles.rightContainer}>
              <div className={styles.datepickerContainer}>
                <LjDatePicker
                  id='confirmHireDatePicker'
                  open={true}
                  label='Starts On'
                  required={true}
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                />
              </div>
            </div>
          </div>
          {hireType?.label === 'Other' && (
            <div className={classNames(styles.container, styles.descriptionContainer)}>
              <FormInput label='Description'>
                <Textarea
                  placeholder='Write something..'
                  className={styles.input}
                  defaultValue={description}
                  handleChange={ev => setDescription(ev.target.value)}
                />
              </FormInput>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}
