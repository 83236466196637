import * as React from 'react';

import Button from 'lj_shared/button/button';

import AdvancedAcceptModal from './advanced_accept_modal';

interface Props {
  selectedTagInputSelector: string;
  batch: boolean;
  selectedTags: any;
}

export default class AdvancedAccept extends React.Component<Props, {}> {
  fetchCheckedTags() {
    const selectedTags = document.getElementsByClassName(this.props.selectedTagInputSelector);
    return Array.from(selectedTags)
      .filter((element: HTMLInputElement) => {
        if (element.checked) {
          return true;
        }
        return false;
      })
      .map((element: HTMLInputElement) => {
        return { name: element.attributes['data']['value'] };
      });
  }

  handleCheck() {
    let proposedTags = null;
    if (this.props.batch) {
      proposedTags = this.fetchCheckedTags();
    } else {
      proposedTags = this.props.selectedTags;
    }

    proposedTags.length > 0
      ? AdvancedAcceptModal.show(proposedTags)
      : window.Alerts.alert('Please select at least one skill');
  }

  render() {
    const buttonColor = this.props.batch ? '#0058cc' : '#185b56';
    const otherClasses = this.props.batch
      ? 'ld-button ld-small-button ld-blue-button ld-border-button'
      : 'ld-button ld-link-button ld-green-button';

    return (
      <div>
        <Button
          isButton={false}
          buttonColor={buttonColor}
          otherClasses={otherClasses}
          otherClassesOnly={true}
          buttonType='border'
          buttonSize='small'
          onClick={() => this.handleCheck()}
        >
          {this.props.batch && 'Batch '}Advanced Accept
        </Button>
      </div>
    );
  }
}
