import * as React from 'react';
import { mount, shallow, ShallowWrapper } from 'enzyme';
import FooterBanner from './FooterBanner';
import Button from 'lj_shared/button/button';
import ApplyButton from 'components/jobPage/ApplyButton';

const jobDummy = require('components/jobs_page/fixtures/job_ad_dummy.json');
const createProps = () => ({
  title: 'Title',
  subtitle: 'Subtitle',
  info: 'Info',
  primaryButton: <Button>b1</Button>,
  secondaryButton: <Button>b2</Button>,
  secondaryButtonText: 'test',
});

let props;
let wrapped;

describe('Render footer banner props correctly', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = mount(<FooterBanner {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('should have an two buttons', () => {
    expect(wrapped.find(Button)).toHaveLength(2);
  });

  it('should render button with given title', () => {
    const newTitle = 'newTitle';

    wrapped.setProps({ secondaryButton: <Button>newTitle</Button> });

    expect(wrapped.find(Button).at(1).text()).toEqual(newTitle);
  });
});
