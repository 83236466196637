import React from 'react';
import classNames from 'classnames';
import Tooltip, { Size as TooltipSize } from 'components/general/tooltip/tooltip';
import Icon, { Size as IconSize } from 'components/general/icon/icon';

const styles = require('./form_input.module.scss');

interface Props {
  className?: string;
  children: any;
  description?: string;
  error?: string;
  label?: any;
  required?: boolean;
  name?: string;
  sublabel?: string;
  tooltip?: { content: string | any[]; size: TooltipSize; floating?: boolean };
  tooltipOnSublabel?: boolean;
}

const FormInput = (props: Props) => {
  const contentProps = {};

  const renderTooltip = () => {
    return (
      <Tooltip {...contentProps} size={props.tooltip.size} textBelow={true}>
        <div className={styles.iconWrapper}>
          <Icon name={'helpCircle'} color={'silverSandXxDark'} size={IconSize.Medium} />
        </div>
      </Tooltip>
    );
  };

  if (props.tooltip) {
    // Adding the correct tooltip
    contentProps[typeof props.tooltip === 'string' ? 'text' : 'htmlContent'] =
      props.tooltip.content;
  }

  return (
    <div id={props.name} className={classNames('formInput', props.className)}>
      {props.label && (
        <div className={styles.label}>
          {props.label}
          {props.required && <abbr title='required'> *</abbr>}
          {props.sublabel && (
            <div className={styles.sublabel}>
              {props.sublabel}
              {props.tooltip && props.tooltipOnSublabel && renderTooltip()}
            </div>
          )}
          {props.tooltip && !props.tooltipOnSublabel && renderTooltip()}
          <div className={styles.description}>{props.description}</div>
        </div>
      )}
      <div>
        {props.children}
        {props.error && (
          <div className={classNames('errorMessage', styles.errorMessage)}>{props.error}</div>
        )}
      </div>
    </div>
  );
};

export default FormInput;
