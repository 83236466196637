import React from 'react';
import classNames from 'classnames';
import Button from 'lj_shared/button/button';

const styles = require('./switch.module.scss');

interface Props {
  buttonColor: string;
  name?: string;
  onClick: any;
  options: { value: number; label: string }[];
  otherClasses?: string;
  selectedValue: number;
  spaceBetween?: boolean;
}

const Switch = (props: Props) => {
  return (
    <div
      className={
        props.spaceBetween
          ? classNames(styles.buttonContainer, styles.spaceBetween)
          : styles.buttonContainer
      }
    >
      {props.options.map(option => {
        const klass =
          props.selectedValue === option.value ? styles.toggleButtonActive : styles.toggleButton;
        return (
          <Button
            key={option.value}
            buttonColor={props.buttonColor}
            buttonType={props.selectedValue !== option.value && 'border'}
            otherClasses={classNames(klass, props.otherClasses)}
            extraStyle={{ marginLeft: '0' }}
            onClick={() => props.onClick(option.value, props.name)}
          >
            {option.label}
          </Button>
        );
      })}
    </div>
  );
};

export default Switch;
