import Chat from '../pages/chat';

export const onloadPrepareButtons = () => {
  if (!screenTooSmall()) {
    const buttons = document.querySelectorAll('.open-mini-chat-button');

    buttons.forEach((button: HTMLElement) => {
      button.removeAttribute('onclick');
      button.onclick = (event: any) => {
        event.preventDefault();
        const id = event.currentTarget.dataset.attribute;
        Chat.openConversationWithCandidate(id);
      };
    });
  }
};

export function screenTooSmall() {
  return window.innerWidth < 1024;
}

document.addEventListener('turbolinks:load', () => {
  if (document.getElementsByName('#lj-chatContainer')) {
    onloadPrepareButtons();
  }
});
