import * as React from 'react';
import Main from './Main';
import { shallow } from 'enzyme';

const company = require('__fixtures__/company_dummy');
const job = require('__fixtures__/job_dummy');

window.Routes = {
  company: () => '',
  company_job_ad: () => '',
};

describe('Main', () => {
  it('shallow renders without crashing', () => {
    job.company = company;
    job.officeLocations = [
      {
        googlePlaceId: '1232jfifnowefn',
        city: 'Lisbon',
        CountryCode: 'PT',
        label: 'Lisbon, Portugal',
      },
      {
        googlePlaceId: '1232jsssfifnowefn',
        city: 'Lisbone',
        CountryCode: 'PTE',
        label: 'Lisbone, Portugale',
      },
    ];

    const component = shallow(<Main job={job} />);
    expect(component.exists()).toBe(true);
  });
});
