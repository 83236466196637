import React from 'react';

import { Application, Bookmark, Job, JobDismissal, User } from '__models__/gql/all';
import CompanySection from '../CompanySection';
import JobCard from 'components/jobCard/JobCard';
import { JobCardContextInterface, JobCardCtx } from 'components/jobCard/JobCardContext';

const styles = require('./Jobs.module.scss');
interface Props {
  applications: Partial<Application>[];
  bookmarks: Partial<Bookmark>[];
  jobDismissals: Partial<JobDismissal>[];
  jobs: Partial<Job>[];
  user?: Partial<User>;
}

export default function Jobs(props: Props) {
  const { applications, bookmarks, jobDismissals, jobs, user } = props;

  return (
    <CompanySection title={`Jobs (${jobs.length})`}>
      {jobs.map(job => {
        const matchesJob = object => object.jobId === job.id;

        const JobCardContext: JobCardContextInterface = {
          applied: !!applications.find(matchesJob),
          bookmark: bookmarks.find(matchesJob),
          job,
          jobDismissal: jobDismissals.find(matchesJob),
          key: job.id,
          user,
        };

        return (
          <JobCardCtx.Provider key={job.id} value={JobCardContext}>
            <JobCard key={job.id} />
          </JobCardCtx.Provider>
        );
      })}
    </CompanySection>
  );
}
