import React from 'react';
import VerticalCardEntry from './vertical_card_entry';

const styles = require('./vertical_card_content.module.scss');

export default function VerticalCardContent(props) {
  return (
    <div className={styles.container}>
      <div className={props.thirdCol ? styles.threePart : styles.twoPart}>
        {props.firstCol.map(cardEntryProps => (
          <VerticalCardEntry
            key={cardEntryProps.uniqueKey}
            color={props.handshakeProfile ? 'ripePlum' : 'tuftsBlue'}
            {...cardEntryProps}
          />
        ))}
      </div>
      <div className={props.thirdCol ? styles.threePart : styles.twoPart}>
        {props.secondCol.map(cardEntryProps => (
          <VerticalCardEntry
            key={cardEntryProps.uniqueKey}
            color={props.handshakeProfile ? 'ripePlum' : 'tuftsBlue'}
            {...cardEntryProps}
          />
        ))}
      </div>
      {props.thirdCol && (
        <div className={styles.threePart}>
          {props.thirdCol.map(cardEntryProps => (
            <VerticalCardEntry key={cardEntryProps.uniqueKey} {...cardEntryProps} />
          ))}
        </div>
      )}
    </div>
  );
}
