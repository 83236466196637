class ImportHiringSteps {
  constructor() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    document
      .getElementById('js-hiring-steps-show-more')
      .addEventListener('click', self.showMoreHiringSteps);
    document
      .getElementById('js-hiring-steps-show-less')
      .addEventListener('click', self.showLessHiringSteps);
    self.showMoreHiringSteps(3);

    if (sessionStorage.oldJobId) {
      const importSymbol: HTMLElement = document
        .querySelector(`[data-job-id='${sessionStorage.oldJobId}']`)
        .querySelector('.import');
      const checkSymbol: HTMLElement = document
        .querySelector(`[data-job-id='${sessionStorage.oldJobId}']`)
        .querySelector('.check');

      importSymbol.style.display = 'none';
      checkSymbol.style.display = 'block';

      sessionStorage.removeItem('oldJobId');
    }
    self.setupImportButtons();
  }

  showMoreHiringSteps(count) {
    const nodeList = document.querySelectorAll(
      '.lj-hiringProcess-importSteps div[style*="display: none"]'
    );

    if (isNumber(count)) {
      for (let i = 0; i < count; i++) {
        if (nodeList[i]) {
          (nodeList[i] as HTMLElement).style.display = 'block';
        }
      }
    } else {
      for (const node of nodeList as any) {
        (node as HTMLElement).style.display = 'block';
      }
    }

    if (
      document.querySelectorAll('.lj-hiringProcess-importSteps div[style*="display: none"]')
        .length <= 0
    ) {
      document.getElementById('js-hiring-steps-show-more').style.display = 'none';
      document.getElementById('js-hiring-steps-show-less').style.display = 'flex';
    }
  }

  showLessHiringSteps() {
    const nodeList = document.querySelectorAll('.lj-hiringStep--copyStep');

    const count = nodeList.length - 3;

    for (let i = 0; i < count; i++) {
      if (nodeList[count - i]) {
        (nodeList[count - i] as HTMLElement).style.display = 'none';
      }
    }

    document.getElementById('js-hiring-steps-show-more').style.display = 'block';
    document.getElementById('js-hiring-steps-show-less').style.display = 'none';
  }

  setupImportButtons() {
    const copySteps = document.querySelectorAll('.lj-hiringStep--copyStep');
    const newJobIdString = document.querySelector('form').id.split('_');
    const newJobId = newJobIdString[newJobIdString.length - 1];
    const checkIcon = document.querySelector('.check[style*="display: block"]');
    const sessionJob = checkIcon?.closest('.lj-hiringStep')['dataset'].jobId;
    let url = '';

    for (const step of copySteps as any) {
      step.onclick = () => {
        const oldJobId = step.dataset.jobId;

        if (sessionJob && sessionJob === oldJobId) {
          url = window.Routes.clean_employer_job_ad_job_hiring_step_url(newJobId, oldJobId);
        } else {
          url = window.Routes.copy_employer_job_ad_job_hiring_step_url(newJobId, oldJobId);
        }
        (window as any).toggleSpinner();

        fetch(url, { credentials: 'same-origin' }).then(() => {
          if (!(sessionJob && sessionJob === oldJobId)) {
            window.sessionStorage.oldJobId = step.dataset.jobId;
          }
          location.reload();
        });
      };
    }
  }
}

document.addEventListener('turbolinks:load', () => {
  if (document.querySelector('.lj-hiringProcess-importSteps')) {
    new ImportHiringSteps();
  }
});

function isNumber(n) {
  return !isNaN(parseFloat(n));
}
