import * as React from 'react';
import ViewCurationPool from './view_curation_pool';
import { shallow, ShallowWrapper } from 'enzyme';
import curationPoolDummy from '__fixtures__/curation_pool_dummy';

let wrapped: ShallowWrapper;

describe('View Curation Pool component', () => {
  beforeEach(() => {
    wrapped = shallow(<ViewCurationPool curation_pool={curationPoolDummy} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
