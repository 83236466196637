import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import ProgressBar from './ProgressBar';

const createProps = () => ({});

let props;
let wrapped: ShallowWrapper;

describe('Generic Progress Bar', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<ProgressBar {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('renders all divs', () => {
    expect(wrapped.find('div')).toHaveLength(3);
  });
});
