import classNames from 'classnames';
import * as React from 'react';
const styles = require('./step_color.module.scss');

interface Props {
  className?: string;
  slug: string;
}

export default function StepColor({ className, slug }: Props) {
  const [type, id] = slug.split('-');
  const colorSuffix = type === 'step' ? 'step' : id;
  const colorClass = styles[`stepColor-${colorSuffix}`];
  const classes = classNames(styles.stepColor, colorClass, className);

  return <span className={classes} />;
}
