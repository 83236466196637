import * as React from 'react';
import SignUpModal from './sign_up_modal';
import { shallow } from 'enzyme';
import Modal from '../general/modal/modal';
import Button from 'lj_shared/button/button';

let wrapped;

beforeAll(() => {
  wrapped = shallow(
    <SignUpModal
      context='index'
      login_path='https://landing.jobs/login'
      sign_up_path='https://landing.jobs/join'
    />
  );
  wrapped.setState({ render: true });
});

it('shallow renders without crashing', () => {
  expect(wrapped.exists()).toBe(true);
});

it('it has a Modal', () => {
  expect(wrapped.find(Modal).length).toEqual(1);
});

it('it has a Button', () => {
  expect(wrapped.find(Button).length).toEqual(1);
});

it('has link to log in', () => {
  expect(wrapped.find('a').at(0).props().href).toEqual('https://landing.jobs/login');
});
