import * as React from 'react';
import './application_card.scss';

import { Application } from '../../../__models__/application';
import ApplicationCardHeader from './application_card_header';

export interface Props {
  alt?: string;
  application?: Application;
  avatar?: string;
  children: any;
  buttonOne?: any;
  buttonThree?: any;
  buttonTwo?: any;
  className?: string;
  cv?: any;
  experienceYears?: { label: string; value: number };
  headerButtons?: any;
  location?: string;
  icon?: string;
  title: string;
  titleOnClick?: Function;
  titleSize?: string;
  titleUrl?: string;
}

const ApplicationCard = (props: Props) => {
  const classes = 'lj-application-card ' + (props.className || '');

  const handleTitleClick = event => {
    event.preventDefault();

    // 1 is middle mouse button
    // 0 is left clik, with control click
    if (event.button === 1 || (event.button === 0 && (event.ctrlKey || event.metaKey))) {
      if (props.titleUrl) {
        window.open(props.titleUrl);
      }
    } else {
      props.titleOnClick(props.application);
    }
  };

  return (
    <div className={classes}>
      <ApplicationCardHeader {...props} handleTitleClick={handleTitleClick} />
      <div className='lj-card-body'>{props.children}</div>
    </div>
  );
};

export default ApplicationCard;
