import * as React from 'react';
import Accordion from 'components/general/accordion/accordion';
import Header from 'components/jobs_page/header';
import TopFilters from '../jobs_page/top_filters';
import { JobAd } from '../../__models__/models';
import JobCard from '../jobs_page/job_card';
import EmptyState from '../general/empty_state/empty_state';
import '../employer_applications/top-filters.scss';
import { partition } from 'lodash';
import AvailableTierFeatureCounter from 'components/available_tier_feature_counter/available_tier_feature_counter';

const styles = require('./jobs_page.module.scss');

interface Props {
  archived_job_ads: { data: JobAd[] };
  drafts_job_ads: { data: JobAd[] };
  followed_job_ids: string[];
  ongoing_job_ads: { data: JobAd[] };
  privacy_path: string;
  tos_path: string;
  max_jobs_reached?: boolean;
  available_jobs: string;
}

interface State {
  topFilter: string;
  filters: {
    job_id: number;
    city: string;
    country: string;
  };
  myJobs: JobAd[];
  otherJobs: JobAd[];
}

export default class JobsPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      topFilter: 'ongoing',
      filters: {
        job_id: null,
        city: '',
        country: '',
      },
      myJobs: [],
      otherJobs: [],
    };

    this.setTopFilter = this.setTopFilter.bind(this);
    this.setJobsState = this.setJobsState.bind(this);
  }

  componentDidMount() {
    this.initializeTopFilter();
    this.splitJobsByAccordion(this.JobAdsFromFilters());
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.topFilter !== this.state.topFilter || this.props !== prevProps) {
      this.splitJobsByAccordion(this.JobAdsFromFilters());
    }
  }

  initializeTopFilter() {
    const path = window.location.pathname.replace('/employer/jobs', '');
    const topFilter = path ? path.replace('/', '') : 'ongoing';

    switch (topFilter) {
      case 'drafts':
        this.setState({ topFilter });
        break;
      case 'archived':
        this.setState({ topFilter });
        break;
      default:
        this.setState({ topFilter: 'ongoing' });
        break;
    }
  }

  setTopFilter(topFilter = this.state.topFilter, force = null) {
    this.setState(() => {
      return { topFilter };
    });
  }

  setJobsState(myJobs, otherJobs) {
    this.setState({ myJobs, otherJobs });
  }

  setJobState = (action, id) => {
    const job = [...this.state.myJobs, ...this.state.otherJobs].filter(j => j.id === id)[0];
    const myJobs =
      action === 'add'
        ? [...this.state.myJobs, job]
        : this.state.myJobs.filter(j => j.id !== job.id);
    const otherJobs =
      action === 'remove'
        ? [...this.state.otherJobs, job]
        : this.state.otherJobs.filter(j => j.id !== job.id);

    this.setJobsState(myJobs, otherJobs);
  };

  JobAdsFromTopFilter() {
    switch (this.state.topFilter) {
      case 'ongoing':
        return this.props.ongoing_job_ads;
      case 'archived':
        return this.props.archived_job_ads;
      case 'drafts':
        return this.props.drafts_job_ads;
      default:
        return this.props.ongoing_job_ads;
    }
  }

  JobAdsFromFilters() {
    const jobData = this.JobAdsFromTopFilter().data;
    const jobAds = jobData.sort((a, b) =>
      a.attributes.state > b.attributes.state
        ? 1
        : a.attributes.state === b.attributes.state
        ? a.attributes.updated_at < b.attributes.updated_at
          ? 1
          : -1
        : -1
    );

    // Make shallow copy of this.state.filters to delete empty values
    const filters = Object.assign({}, this.state.filters);
    Object.keys(filters).forEach(key => !filters[key] && delete filters[key]);

    function search(jobAd: JobAd) {
      return Object.keys(this).every(key => matcher(key, jobAd, this[key]));
    }

    function matcher(key, jobAd: JobAd, filterValue) {
      switch (key) {
        case 'city':
          return jobAd.attributes[key] && jobAd.attributes[key].startsWith(filterValue);
        case 'job_id':
          return +jobAd.id === filterValue;
        default:
          return jobAd.attributes[key] === filterValue;
      }
    }

    const filteredJobAds = jobAds.filter(search, filters);

    return Object.keys(filters).length === 0 ? jobAds : filteredJobAds;
  }

  splitJobsByAccordion(jobs) {
    const [myJobs, otherJobs] = partition(jobs, job =>
      this.props.followed_job_ids.includes(job.id)
    );
    this.setJobsState(myJobs, otherJobs);
    return [myJobs, otherJobs];
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <Header />
        {this.renderTabs()}
        {this.props.available_jobs && this.state.topFilter === 'ongoing' && (
          <div className={styles.availableJobsCounterContainer}>
            <AvailableTierFeatureCounter
              available={this.props.available_jobs}
              type={'jobs'}
              pricingLimitType
            />
          </div>
        )}
        <div className={styles.jobsBody}>
          {this.renderAccordion('mine', this.state.myJobs)}
          {this.renderAccordion('other', this.state.otherJobs)}
        </div>
      </div>
    );
  }

  renderTabs() {
    return (
      <div className={styles.topBar}>
        <TopFilters
          setTopFilter={this.setTopFilter}
          ongoingCounter={this.props.ongoing_job_ads.data.length}
          draftsCounter={this.props.drafts_job_ads.data.length}
          activeFilter={this.state.topFilter}
        />
      </div>
    );
  }

  renderAccordion(type, jobs) {
    const [title, defaultOpened] = type === 'mine' ? ['My Jobs', true] : ['Other Jobs', true];

    return (
      <Accordion
        className={styles.accordion}
        defaultOpened={defaultOpened}
        headerClassName={styles.accordionHeader}
        title={<div className={styles.accordionHeaderText}>{title}</div>}
      >
        {jobs.length > 0 ? this.renderJobs(jobs, type === 'mine') : this.renderEmptyJobs()}
      </Accordion>
    );
  }

  renderJobs(jobs, isFollowedJob) {
    return jobs.map((job: JobAd) => (
      <div key={job.id}>
        <JobCard
          job={job}
          topFilter={this.state.topFilter}
          termsOfService={this.props.tos_path}
          privacy={this.props.privacy_path}
          setJobsState={this.setJobState}
          isFollowedJob={isFollowedJob}
          maxPublishedReached={this.props.max_jobs_reached || false}
        />
      </div>
    ));
  }

  renderEmptyJobs() {
    return (
      <EmptyState>
        <strong>{this.renderEmptyJobTitle(this.state.topFilter)}</strong>
        <br />
        {'Need help with posting a job? '}
        <a className={styles.link} title='Contact us' href='mailto:wegotyourback@landing.jobs'>
          We can help!
        </a>
      </EmptyState>
    );
  }

  renderEmptyJobTitle(tab) {
    switch (tab) {
      case 'drafts':
        return `You don't have any drafts`;
      case 'archived':
        return `You haven't archived any jobs yet`;
      default:
        return `You don't have any ${tab} jobs`;
    }
  }
}
