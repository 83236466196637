import React from 'react';
import Checkbox from 'components/form/checkbox/checkbox';

interface Props {
  consent: boolean;
  error: string;
  handleFormChange: Function;
}

export default function SubscribeInput(props: Props) {
  return (
    <Checkbox
      onChange={props.handleFormChange}
      name='commsConsent'
      color='tuftsBlue'
      checked={props.consent}
      controlled={false}
      label='You agree to receive email and communications, like job suggestions, community updates or notifications related to your job applications (you can unsubscribe at any time in the future).'
    />
  );
}
