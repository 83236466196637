import React, { useRef, useState } from 'react';
import Modal from 'components/general/modal/modal';
import { ButtonsLayout } from '../general/modal/modal';
import { patchJSON, handleRequestError } from '../../lib/request_deprecated';
import FormInput from 'components/general/form_input/form_input';
import RadioButtons from 'components/form/radio_buttons/radio_buttons';
import Textarea from 'components/form/textarea/textarea';

const styles = require('./RevokeHandshakeModal.module.scss');

export interface Props {
  handleRevoke?: Function;
  handshake: any;
  handshake_company: { name: string; id: number; logo: string };
  handshake_job_ad: { title: string; id: number };
  setIsModalOpen: Function;
  cancelReasons: any;
}

export default function RevokeHandshakeModal(props: Props) {
  const modalRef = useRef(null);
  const [selectedReason, setReason] = useState();
  const otherRef = useRef(null);
  const [error, setError] = useState('');
  const company = props.handshake_company.name;
  const jobAd = props.handshake_job_ad.title;

  const renderText = () => {
    return (
      <div>
        <div className={styles.revokeHandshakeBold}>
          By revoking this handshake you will close the application for{' '}
          <a
            className={styles.title}
            href={window.Routes.job_ad({
              id: props.handshake_job_ad.id,
            })}
            target='_blank'
            rel='noreferrer'
          >
            {jobAd}
          </a>{' '}
          @ {company}.
        </div>
        <div>Please take a moment to let us know why you're cancelling this application.</div>
      </div>
    );
  };

  const handleChange = reason => {
    setError('');
    setReason(reason);
  };

  const reasonOptions = () =>
    props.cancelReasons.map(reason => ({
      id: reason,
      value: reason,
      label: reason,
    }));

  const renderReasonsForm = () => {
    return (
      <FormInput label='Reason' required name='revoke_reason' error={error}>
        <RadioButtons
          onChange={reason => handleChange(reason)}
          options={reasonOptions()}
          selectedValue={selectedReason}
        />
        {isOther() && <Textarea ref={otherRef} />}
      </FormInput>
    );
  };

  const otherReasonText = () => {
    if (isOther()) {
      return otherRef.current.value;
    } else {
      return '';
    }
  };

  const isOther = () => {
    return selectedReason === 'Other';
  };

  const validate = () => {
    if (!selectedReason) {
      setError('Please select a reason.');
      return false;
    } else if (isOther() && !otherReasonText()) {
      setError('Please specify.');
      return false;
    }
    return true;
  };

  const handleRevoke = () => {
    if (validate()) {
      patchJSON(
        window.Routes.revoke_dashboard_handshake(props.handshake.id, {
          reason: selectedReason,
          reason_other: otherReasonText(),
        })
      )
        .then(data => {
          props.setIsModalOpen(false);
          window.Alerts.notice('Handshake revoked');
          if (props.handleRevoke) {
            props.handleRevoke(data);
          } else {
            window.location.reload();
          }
        })
        .catch(handleRequestError);
    }
  };

  return (
    <Modal
      title={<div className={styles.modalTitle}>Are you sure?</div>}
      defaultOpen={true}
      ref={modalRef}
      cancelButton={true}
      buttonColor={'tuftsBlueMidLight'}
      buttonName='Revoke and close application'
      buttonOnClick={handleRevoke}
      buttonsLayout={ButtonsLayout.OkCancel}
      cancelButtonTitle={'Cancel'}
      onClose={() => props.setIsModalOpen(false)}
    >
      {renderText()}
      {renderReasonsForm()}
    </Modal>
  );
}
