import * as React from 'react';
import ApplicationLocation, { Props } from './application_location';
import { shallow, ShallowWrapper } from 'enzyme';
import applicationDummy from '__fixtures__/application_dummy';

const createTestProps = moreProps => {
  return {
    application: applicationDummy,
    ...moreProps,
  };
};

let props: Props;
let wrapped: ShallowWrapper;

describe('Application Location component', () => {
  beforeEach(() => {
    props = createTestProps({});
    wrapped = shallow(<ApplicationLocation {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
