import * as React from 'react';
import { mount } from 'enzyme';
import { type JobCardContextInterface, JobCardCtx } from './JobCardContext';
import JobLocation from './JobLocation';
import job from '__fixtures__/job_dummy';
import tag from '__fixtures__/tag_dummy';

window.Routes = {
  company: () => '',
  company_job_ad: () => '',
  join: () => '',
};

window.scrollTo = jest.fn();

const JobCardContext: JobCardContextInterface = {
  applied: false,
  bookmark: {
    id: '1',
    jobId: job.id,
  },
  job,
  key: job.id,
  isHottest: true,
  infoTags: ['tag1', 'tag2'],
};

describe('JobCard Location', () => {
  it('shallow renders without crashing', () => {
    job.tags = [tag, tag];
    job.location = 'Remote';

    const component = mount(
      <JobCardCtx.Provider key={job.id} value={JobCardContext}>
        <JobLocation />,
      </JobCardCtx.Provider>
    );

    expect(component.exists()).toBe(true);
  });

  it('shows the correct icon', () => {
    job.tags = [tag, tag];
    job.location = 'Remote';

    const component = mount(
      <JobCardCtx.Provider key={job.id} value={JobCardContext}>
        <JobLocation />,
      </JobCardCtx.Provider>
    );

    const icon = component.find('Icon');

    expect(icon.prop('name')).toEqual('remote');
  });
});
