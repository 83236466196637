const languageObject = languageItem => ({
  id: languageItem.id,
  value: languageItem.id,
  label: languageItem.text,
});
const proficiencyObject = ([value, label]) => ({ value, label });

export function languageOptions(languages) {
  return languages.map(languageObject);
}

export function proficiencyLevels(proficiencyLevelsObject) {
  return Object.entries(proficiencyLevelsObject).map(proficiencyObject);
}
