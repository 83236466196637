import React from 'react';
import Modal from 'components/general/modal/modal';
import TextInput from 'components/form/text/text';
import Select from 'components/form/select/select';
import { handleRequestError } from 'lib/request_deprecated';
import FormInput from 'components/general/form_input/form_input';
import { cloneDeep } from 'lodash';
import CertificationsForm from './CertificationsForm';
import ResumeCard from './resume_card';
import Certificate from 'components/talentUser/services/Certificate';
import Button from 'lj_shared/button/button';

const styles = require('./modals.module.scss');

interface Props {
  action?: number;
  defaultOpen: boolean;
  certificationsForm?: CertificationsForm;
  handleAddClick: Function;
  handleDeleteClick?: Function;
  handleEditClick?: Function;
  fileName?: string;
}

interface State {
  certificationsForm: CertificationsForm;
  textError: string;
  file: File;
  fileCleared: boolean;
  fileName: string;
  hasFile: boolean;
  revision: boolean;
}

export default class CertificationsModal extends React.Component<Props, State> {
  modalRef: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      certificationsForm: new CertificationsForm({
        ...this.props.certificationsForm,
      }),
      textError: '',
      file: null,
      fileCleared: false,
      fileName: this.props.fileName,
      hasFile: false,
      revision: false,
    };

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.modalRef = React.createRef();
  }

  open() {
    this.modalRef.current.open();
  }

  close() {
    this.modalRef.current.close();
  }

  componentDidUpdate(prevProps) {
    if (this.props.certificationsForm !== prevProps.certificationsForm) {
      this.setState({
        certificationsForm: new CertificationsForm({
          ...this.props.certificationsForm,
        }),
      });
    }
    if (this.props.fileName !== prevProps.fileName) {
      this.setState({ fileName: this.props.fileName });
    }
  }

  uploadFile = responseData => {
    const formData = new FormData();
    formData.append('certification[file]', this.state.file);
    formData.append('certification[id]', responseData.id);

    postData(window.Routes.users_certification(responseData.id), formData)
      .then((response: { saved: boolean; data: { data: Object }; post: boolean }) => {
        if (response.saved) {
          this.handleCancel();
          this.props.handleEditClick(response.data);
        }
        this.setState({
          file: null,
          fileName: null,
          hasFile: false,
          revision: false,
        });
      })
      .catch(error => {
        handleRequestError(error);
        this.setState({ hasFile: false, revision: false });
      });
  };

  removeFile = responseData => {
    const formData = new FormData();
    formData.append('certification[id]', responseData.id);
    formData.append('certification[delete]', 'true');

    postData(window.Routes.users_certification(responseData.id), formData)
      .then((response: { saved: boolean; data: { data: Object }; post: boolean }) => {
        if (response.saved) {
          this.handleCancel();
          this.props.handleEditClick(response.data);
        }
        this.setState({ fileCleared: false, revision: false });
      })
      .catch(error => {
        handleRequestError(error);
        this.setState({ fileCleared: false, revision: false });
      });
  };

  validateBeforeUpload = file => {
    const types = /(\.|\/)pdf$/i;

    if (!types.test(file.type) || !types.test(file.name)) {
      this.setState({ textError: 'PDF only' });
      return false;
    } else if (file.size > 10485760) {
      this.setState({ textError: 'Maximum CV size is 10 Mb.' });
      return false;
    } else {
      this.setState({ textError: '' });
      return true;
    }
  };

  handleSubmit = _event => {
    this.setState({ revision: true });
    this.state.certificationsForm
      .save()
      .then((response: { saved: boolean; data: { data: Certificate }; post: boolean }) => {
        if (this.state.file && this.validateBeforeUpload(this.state.file)) {
          this.uploadFile(response.data);
        }
        if (this.state.fileCleared) {
          this.removeFile(response.data);
        }
        if (response.saved && response.post) {
          if (!this.state.hasFile) {
            this.handleCancel();
            this.setState({ revision: false });
          }
          this.props.handleAddClick(response.data);
        } else if (!this.state.hasFile && response.saved) {
          if (!this.state.fileCleared) {
            this.handleCancel();
            this.setState({ revision: false });
          }
          this.props.handleEditClick(response.data);
        } else {
          this.setState({ revision: false });
        }
      })
      .catch(e => {
        handleRequestError(e);
        this.setState({ revision: false });
      });
  };

  handleCancel = () => {
    this.modalRef.current.close();
  };

  handleFormChange = (event, input = '') => {
    !this.state.revision && this.setState({ revision: false });
    const update = {};

    if (event.target && input === 'clearFile') {
      this.setState({ fileCleared: true, fileName: null, file: null });
    } else if (event.target && event.target.id === 'file') {
      if (event.target.files && this.validateBeforeUpload(event.target.files[0])) {
        this.setState({
          fileName: event.target.files[0].name,
          hasFile: true,
          file: event.target.files[0],
        });
        update[event.target.name] = event.target.files[0];
      }
    } else if (event.target) {
      update[event.target.name] = event.target.value;
    } else {
      update[input] = event.value;
    }

    const form = cloneDeep(this.state.certificationsForm);
    form.update(update);

    this.setState({ certificationsForm: form });
  };

  handleDelete = () => {
    this.props.handleDeleteClick(this.state.certificationsForm.id);
    this.handleCancel();
  };

  getYears = () => {
    const currentYear = new Date().getFullYear();
    const range = (start, stop, step) =>
      Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
    const years = range(currentYear, currentYear - 80, -1);
    return years.map(year => ({
      value: year,
      label: year,
    }));
  };

  selectValue = data => {
    if (data) {
      return {
        label: data,
        value: data,
      };
    }
  };

  renderPDFInfo = () => {
    return (
      <div className={styles.pdfInfo}>
        <strong>
          Allowed file types: .pdf
          <br />
          Max. file size: 10 MB
        </strong>
      </div>
    );
  };

  render() {
    return (
      <Modal
        title={`${this.props.action ? 'Edit' : 'Add'} Certification`}
        defaultOpen={this.props.defaultOpen}
        ref={this.modalRef}
        disableOutsideClose={true}
      >
        <FormInput
          name='title'
          label='Title of Certification'
          error={this.state.certificationsForm.errors.title}
          required
        >
          <TextInput
            name='title'
            placeholder='Certificate of...'
            value={this.state.certificationsForm.title}
            onChange={this.handleFormChange}
            class={styles.textInput}
          />
        </FormInput>
        <FormInput
          name='year'
          label='Year Awarded'
          error={this.state.certificationsForm.errors.year}
          required
        >
          <Select
            name={'year'}
            options={this.getYears()}
            placeholder='2019...'
            color='silverSand'
            defaultValue={this.selectValue(this.state.certificationsForm.year)}
            onChange={event => this.handleFormChange(event, 'year')}
          />
        </FormInput>
        <FormInput
          name='authority'
          label='Authority that gave you the certification'
          error={this.state.certificationsForm.errors.authority}
          required
        >
          <TextInput
            name='authority'
            placeholder='Institute of...'
            value={this.state.certificationsForm.authority}
            onChange={this.handleFormChange}
            class={styles.textInput}
          />
        </FormInput>
        <FormInput
          name='certificate'
          label='Upload your Certificate'
          error={this.state.certificationsForm.errors.file}
        >
          <div className={styles.wrapper}>
            {this.state.fileName ? (
              <div className={styles.experienceCard}>
                <ResumeCard
                  title={this.state.fileName}
                  handleDeleteClick={event => this.handleFormChange(event, 'clearFile')}
                />
                {this.renderPDFInfo()}
              </div>
            ) : (
              <div className={styles.emptyState}>Your Certificate will go here...</div>
            )}
            <label className={styles.button} htmlFor='file'>
              <div className={styles.uploadButton}>UPLOAD FILE</div>
            </label>
            <input type='file' name='file' id='file' onChange={this.handleFormChange} />
            <div>
              {this.state.textError && (
                <div className={styles.mandatory}>{this.state.textError}</div>
              )}
            </div>
          </div>
        </FormInput>
        <div className={styles.footer}>
          <div className={styles.buttons}>
            {this.props.handleDeleteClick && (
              <Button
                buttonColor='silverSand'
                buttonType='border'
                onClick={() => this.handleDelete()}
                otherClasses={styles.button}
              >
                Delete
              </Button>
            )}
            <Button
              buttonColor='tuftsBlue'
              onClick={this.handleSubmit}
              otherClasses={styles.button}
              disabled={
                !this.state.certificationsForm.title ||
                !this.state.certificationsForm.authority ||
                !this.state.certificationsForm.year
              }
              isButton
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

const postData = async (url, data) => {
  const response = await fetch(url, {
    method: 'PATCH',
    mode: 'cors',
    headers: {
      'X-CSRF-Token': getCSRFToken(),
    },
    body: data,
  });
  return response.json();
};

const getCSRFToken = () => {
  const element = document.querySelector('meta[name=csrf-token]');
  const token = element && element.getAttribute('content');
  return token;
};
