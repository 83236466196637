import * as React from 'react';
import './handshake_message.scss';
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import MessageContainer from '../message_container';
const helpIconPath = require('svg/help.svg');
const image1 = require('handshake-message/handshake-message1.png');
const image2 = require('handshake-message/handshake-message2.png');
const image3 = require('handshake-message/handshake-message3.png');
const imageMobile1 = require('handshake-message/handshake-message1-mobile.png');
const imageMobile2 = require('handshake-message/handshake-message2-mobile.png');
const imageMobile3 = require('handshake-message/handshake-message3-mobile.png');

const HandshakeMessage = props => {
  return (
    <div>
      <CarouselProvider
        naturalSlideWidth={props.mobile ? 320 : 100}
        naturalSlideHeight={props.mobile ? 559 : 70}
        lockOnWindowScroll={true}
        totalSlides={3}
      >
        {props.mobile ? <DotGroup /> : null}
        <Slider>
          <Slide index={0}>
            <div className='lj-handshake-content'>
              <h4>Companies who want to engage with you</h4>
              <p>
                Congrats! If you have received a Handshake this means a company has analysed your
                anonymous profile and is interested in engaging with you.
              </p>
              <img
                src={props.mobile ? imageMobile1 : image1}
                alt='handshake-image1'
                style={props.style}
              />
              <p>
                <strong>Accepted Handshakes with an associated Job Opening</strong> will create
                automatically an application for that position in your behalf. You’ll be applying to
                the Job without having the hassle, after that you’ll follow the regular companies’
                recruitment process.
              </p>
            </div>
          </Slide>
          <Slide index={1}>
            <div className='lj-handshake-content'>
              <h4>There are two types of Handshake requests</h4>
              <p>
                A company can request a handshake without having any job opening in mind, this means
                they’re interested in your profile but aren’t yet sure what position it matches.
              </p>
              <img
                src={props.mobile ? imageMobile2 : image2}
                alt='handshake-image2'
                style={props.style}
              />
              <p>Or...</p>
              <p>
                They can directly link a specific Job Opening they have in our platform to the
                request that they’re confident matches your profile.
              </p>
            </div>
          </Slide>
          <Slide index={2}>
            <div className='lj-handshake-content'>
              <h4>Start a conversation with a company</h4>
              <p>
                By accepting the Handshakes you’re able to start a conversation through our chat and
                communicate directly with the companies which showed interest in your profile -
                you’ve got the power!
              </p>
              <img
                src={props.mobile ? imageMobile3 : image3}
                alt='handshake-image3'
                style={props.style}
              />
              <p>
                If you want to learn more or still have doubts about how Handshakes work feel free
                to read our blog post{' '}
                <a
                  className='lj-link--tuftsBlue'
                  title='Forget the job search, start handshaking'
                  href='https://landing.jobs/blog/forget-the-job-search-start-handshaking'
                  target='_blank'
                  rel='noreferrer'
                >
                  Forget the job search, start handshaking
                </a>
              </p>
              {!props.mobile ? (
                <button
                  onClick={props.toggle}
                  className='lj-button lj-button--tuftsBlueMidLight lj-button--xSmall lj-button--border'
                >
                  GOT IT, THANKS!
                </button>
              ) : null}
            </div>
          </Slide>
        </Slider>
        {props.mobile ? null : <DotGroup />}
      </CarouselProvider>
    </div>
  );
};

const RenderHandshakeMessage = () => {
  return (
    <MessageContainer title='What are Handshakes?' icon={helpIconPath}>
      <HandshakeMessage />
    </MessageContainer>
  );
};

export default RenderHandshakeMessage;
