import * as React from 'react';
import classnames from 'classnames';

import InlineIcon from '../inline_icon/inline_icon';
import Icon from '../icon/icon';

const styles = require('./tag.module.scss');

interface TagProps {
  color?: string;
  outlined?: boolean;
  rounded?: boolean;
  title: string;
  icon?: string;
  newIcon?: string;
  iconColor?: string;
  alt?: string;
  url?: string;
  iconClass?: string;
  labelClass?: string;
}

const Tag = (props: TagProps) => {
  const propIconClasses = props.iconClass
    ? props.iconClass.split(' ').map(item => styles[item])
    : '';

  const labelClasses = classnames(
    styles.tag,
    styles[props.color],
    { [styles.outlined]: props.outlined },
    { [styles.rounded]: props.rounded },
    props.labelClass
  );
  const iconClasses = classnames(propIconClasses);

  const tagContents = () => {
    return (
      <>
        {props.icon && <InlineIcon path={props.icon} alt={props.alt} className={iconClasses} />}
        {props.newIcon && (
          <Icon name={props.newIcon} color={props.iconColor} className={styles.icon} />
        )}
        <span>{props.title}</span>
      </>
    );
  };

  if (props.url) {
    return (
      <a className={labelClasses} href={props.url} target='_blank' rel='noreferrer'>
        {tagContents()}
      </a>
    );
  } else {
    return <div className={labelClasses}>{tagContents()}</div>;
  }
};

export default Tag;
