import React from 'react';
import PlacementPage from './placement_page';
import { render } from '@testing-library/react';

window.Routes = {
  dashboard_placement_invoice: jest.fn(),
  dashboard_placements: jest.fn(),
  edit_billing_info: jest.fn(),
  new_billing_info: jest.fn(),
  new_dashboard_placement_invoice: jest.fn(),
};

describe('PlacementPage', () => {
  it('renders the project information', () => {
    const data = {
      companyName: 'FooCorp',
      endDate: '2015-01-01',
      expectedDuration: 'One month',
      id: '1',
      invoices: [],
      projectDescription: 'Amazing project!',
      projectName: 'The Foo Project',
      startDate: '2015-01-20',
      status: 'active',
      totalPaid: '€1990.00',
    };
    const { queryByText } = render(<PlacementPage {...data} />);

    for (const [key, value] of Object.entries(data)) {
      if (!['id', 'invoices', 'status', 'totalPaid'].includes(key)) {
        expect(queryByText(value as string, { exact: false })).toBeTruthy();
      }
    }
  });

  it('when given invoices it renders the invoices table', () => {
    const invoice = {
      id: '17',
      code: '2010-1',
      reportMonth: 'January 2010',
      status: 'To approve',
      value: '€139.00',
      workedDays: '123',
    };
    const data = {
      companyName: 'FooCorp',
      endDate: '2015-01-01',
      expectedDuration: 'One month',
      id: '1',
      invoices: [invoice],
      projectDescription: 'Amazing project!',
      projectName: 'The Foo Project',
      startDate: '2015-01-20',
      status: 'active',
      totalPaid: '€1990.00',
    };
    const { queryByText } = render(<PlacementPage {...data} />);

    expect(queryByText(data.totalPaid, { exact: false })).toBeTruthy();

    for (const [key, value] of Object.entries(invoice)) {
      if (!['id'].includes(key)) {
        expect(queryByText(value, { exact: false })).toBeTruthy();
      }
    }
  });
});
