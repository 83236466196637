import * as React from 'react';
import { shallow } from 'enzyme';
import CompanySideHandshake, { Props } from './company_side_handshake';
import Button from 'lj_shared/button/button';

let props: Props;
let wrapped = null;

const createTestProps = () => {
  return {
    company: {
      id: 1,
      name: 'This is like... a Company or something',
      logo: '',
    },
  };
};

describe('Accept Handshake component', () => {
  beforeAll(() => {
    props = createTestProps();
    wrapped = shallow(<CompanySideHandshake {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});
