import { apolloClient, gql } from 'lib/graphql';
import { tagManagerTrack } from 'lib/marketing';
import { Bookmark } from '__models__/gql/bookmark';

const CREATE_BOOKMARK_GQL = gql`
  mutation ($jobId: ID!) {
    user {
      createBookmark(jobId: $jobId) {
        errors {
          field
          message
        }
        resource {
          id
          jobId
        }
      }
    }
  }
`;

const DELETE_BOOKMARK_GQL = gql`
  mutation ($id: ID!) {
    user {
      deleteBookmark(id: $id) {
        errors {
          field
          message
        }
      }
    }
  }
`;

const useBookmarks = () => {
  const create = async (jobId: string) => {
    const { data, errors } = await apolloClient.mutate({
      mutation: CREATE_BOOKMARK_GQL,
      variables: { jobId },
    });
    const bookmark = data?.user?.createBookmark?.resource as Partial<Bookmark>;
    if (bookmark) {
      tagManagerTrack({ event: 'candidate-subscribedJob' });

      return bookmark;
    } else {
      return errors;
    }
  };

  const destroy = async id => {
    const { data, errors } = await apolloClient.mutate({
      mutation: DELETE_BOOKMARK_GQL,
      variables: { id },
    });
    if (!data.errors) {
      return data;
    } else {
      return errors;
    }
  };

  return { create, destroy };
};

export default useBookmarks;
