import * as React from 'react';
import ApplicationsGroup, { Props } from './applications_group';
import { shallow, ShallowWrapper } from 'enzyme';
import { ApplicationsState } from '../employer_applications/applications_state';
import applicationDummy from '__fixtures__/application_dummy';

const dummyAppsState = new ApplicationsState({});

const createTestProps = moreProps => {
  return {
    applications: [applicationDummy],
    bulkMoveToStep: jest.fn(),
    bulkReject: jest.fn(),
    expanded: false,
    idx: 3,
    renderApplication: jest.fn(),
    step: {
      description: '',
      hasApplications: true,
      id: 1,
      jobId: 1,
      kind: '',
      message: {
        id: 2,
        text: '',
      },
      name: '',
      order: 1,
      slug: '',
    },
    steps: [
      {
        description: '',
        hasApplications: true,
        id: 1,
        jobId: 1,
        kind: '',
        message: {
          id: 2,
          text: '',
        },
        name: '',
        order: 1,
        slug: '',
      },
    ],
    urls: {
      bulkMoveToReviewing: '',
      bulkMoveToStep: '',
      bulkMoveToProposal: '',
      bulkReject: '',
      rateApplication: '',
    },
    ...moreProps,
    appsState: dummyAppsState,
  };
};

let props: Props;
let wrapped: ShallowWrapper;

describe('Applications Group component', () => {
  beforeEach(() => {
    props = createTestProps({});
    wrapped = shallow(<ApplicationsGroup {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
