import React from 'react';

import ApplicationDetailsCard from 'curators/application_details_card';

const styles = require('./skills_report.module.scss');

interface Abstraction {
  base_skill: string;
  features: string[];
  skill: string;
}

interface Similarity {
  skill1: string;
  skill2: string;
  similarities: string[];
}

interface Report {
  area_skills: { [key: string]: string[] };
  abstractions_of: Abstraction[];
  frameworks_of: { skill: string; programming_language: string }[];
  libraries_of: { skill: string; programming_language: string }[];
  same_skills: string[];
  similar_skills: Similarity[];
}

interface Props {
  report: Report;
}

export default function SkillsReport({ report }: Props) {
  return (
    <ApplicationDetailsCard title={'Skills Report'} fullwidth={true}>
      <div className={styles.container}>
        {report.same_skills.length > 0 && (
          <div key={'skillsInCommon'} className={styles.item}>
            <h4>Skills in common</h4>
            <ul>
              {report.same_skills.map(skill => (
                <li key={`sameSkills${skill.replace(/\s+/g, '')}`}>{skill}</li>
              ))}
            </ul>
          </div>
        )}
        {report.frameworks_of.length > 0 && (
          <div key={'frameworks'} className={styles.item}>
            <h4>Frameworks</h4>
            <ul>
              {report.frameworks_of.map(({ skill, programming_language }) => (
                <li
                  key={`frameworks${skill.replace(/\s+/g, '')}`}
                >{`${skill} is a framework of ${programming_language}`}</li>
              ))}
            </ul>
          </div>
        )}
        {report.libraries_of.length > 0 && (
          <div key={'libraries'} className={styles.item}>
            <h4>Libraries</h4>
            <ul>
              {report.libraries_of.map(({ skill, programming_language }) => (
                <li
                  key={skill.replace(/\s+/g, '')}
                >{`${skill} is a library of ${programming_language}`}</li>
              ))}
            </ul>
          </div>
        )}
        {report.similar_skills.length > 0 && (
          <div key={'similarSkills'} className={styles.item}>
            <h4>Similarities</h4>
            <ul>
              {report.similar_skills.map(({ skill1, skill2, similarities }) => {
                if (similarities.length > 0) {
                  return (
                    <li
                      key={`similarSkill{skill1.replace(/s+/g, '')}`}
                    >{`${skill1} and ${skill2} are ${similarities.join(' and ')}`}</li>
                  );
                }
                return (
                  <li
                    key={`similarSkill{skill1.replace(/s+/g, '')}`}
                  >{`${skill1} is ${skill2}`}</li>
                );
              })}
            </ul>
          </div>
        )}
        {report.abstractions_of.length > 0 && (
          <div key={'abstraction'} className={styles.item}>
            <h4>Common Features</h4>
            <ul>
              {report.abstractions_of.map(({ base_skill, skill, features }) => (
                <li
                  key={`abstraction${skill.replace(/\s+/g, '')}`}
                >{`${base_skill} and ${skill} are both based on these concept(s): ${features.join(
                  ' and '
                )}`}</li>
              ))}
            </ul>
          </div>
        )}
        {renderAreaSkills(report.area_skills)}
      </div>
    </ApplicationDetailsCard>
  );
}

function renderAreaSkills(areaSkills) {
  return Object.keys(areaSkills).map(area => (
    <div key={`${area}Area`} className={styles.item}>
      <h4>{area} skills</h4>
      <ul>
        {areaSkills[area].map(skill => (
          <li key={skill}>{skill}</li>
        ))}
      </ul>
    </div>
  ));
}
