import * as React from 'react';
import ApplicationTrail from './application_trail';
import { shallow, ShallowWrapper } from 'enzyme';
import applicationDummy from '__fixtures__/application_dummy';

let wrapped: ShallowWrapper;

describe('Application Trail component', () => {
  beforeEach(() => {
    wrapped = shallow(<ApplicationTrail application={applicationDummy} label='' />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
