import * as React from 'react';
import { shallow } from 'enzyme';
import FormError from './form_error';

describe('<Form Error />', () => {
  const component = shallow(<FormError text='testing' />);

  it('renders the correct text', () => {
    const expectedOutput = '<span>testing</span>';
    const output = component.find('span').html();
    expect(output).toEqual(expectedOutput);
  });
});
