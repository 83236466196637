import React, { useContext, useState } from 'react';

import { FormContext } from 'components/form/FormProvider';
import AboutSectionPerks from './AboutSectionPerks';
import AboutSectionNumbers from './AboutSectionNumbers';
import ImageInput from 'components/form/imageInput/ImageInput';
import InputWrapper from 'components/form/inputWrapper/InputWrapper';
import LocationInput from 'components/form/location_input/location_input';
import Select from 'components/form/select/select';
import Text from 'components/form/text/text';
import AboutSectionHistory from './AboutSectionHistory';
import SectionTitle from './SectionTitle';
import AboutSectionValues from './AboutSectionValues';
import Checkbox from 'components/form/checkbox/checkbox';

const Settings = require('settings.json');
const styles = require('./AboutSection.module.scss');

interface Props {
  companySizes: any[];
  industries: any[];
}

export default function AboutSection({ companySizes, industries }: Props) {
  return (
    <>
      <SectionTitle title='About us' id='companyAboutUs' />
      <div className={styles.sectionBody}>
        <CoverPhoto />
        <Name />
        <WebsiteURL />
        <Tagline />
        <Industry industries={industries} />
        <CompanySize sizes={companySizes} />
        <Location />
        <Logo />
        <Mission />
        <AboutSectionPerks />
        <AboutSectionValues />
        <AboutSectionNumbers />
        <AboutSectionHistory />
      </div>
    </>
  );
}

function CoverPhoto() {
  return (
    <InputWrapper
      className={styles.bigField}
      label='Cover Photo'
      name='cover_photo'
      input={ImageInput}
      inputProps={{
        alt: 'Cover Photo',
        kind: 'COMPANY_COVER_PHOTO',
        recommendedWidth: 1366,
        recommendedHeight: 284,
      }}
      required={false}
    />
  );
}

function Name() {
  return (
    <Text
      class={styles.smallField}
      label='Name'
      name='name'
      placeholder='Landing.jobs'
      required={true}
      useContext={true}
    />
  );
}

function WebsiteURL() {
  return (
    <Text
      class={styles.smallField}
      label='Website URL'
      name='website_url'
      placeholder='https://landing.jobs'
      required={true}
      useContext={true}
    />
  );
}

function Tagline() {
  return (
    <Text
      class={styles.bigField}
      hint="Please don't mention any email addresses or websites"
      label='Tagline'
      maxLength={100}
      maxLengthShow={true}
      name='short_pitch'
      placeholder='Aim for more'
      required={true}
      useContext={true}
    />
  );
}

function Industry({ industries }) {
  return (
    <InputWrapper
      className={styles.smallField}
      input={Select}
      inputProps={{
        getOptionLabel: industryLabel,
        getOptionValue: industryValue,
        options: industries,
      }}
      label='Industry'
      name='industry'
      required={true}
    />
  );
}

function CompanySize({ sizes }) {
  return (
    <InputWrapper
      className={styles.smallField}
      input={Select}
      inputProps={{
        getOptionLabel: companySizeLabel,
        getOptionValue: companySizeValue,
        options: sizes,
      }}
      label='Company size'
      name='company_size'
      required={true}
    />
  );
}

function Location() {
  const context = useContext(FormContext);
  const [fullRemoteCompany, setFullRemoteCompany] = useState(context.resource.fullRemote);
  return (
    <div className={styles.smallField}>
      <InputWrapper
        className={styles.smallField}
        label='Your company is based in'
        name='location'
        input={LocationInput}
        inputProps={{
          handleLocationChange: () => 0,
        }}
        required={!fullRemoteCompany}
      />
      <Checkbox
        name='company_form[full_remote]'
        label='Full remote company'
        color='ripePlum'
        checked={fullRemoteCompany}
        value={fullRemoteCompany}
        onChange={event => setFullRemoteCompany(event.target.checked)}
        controlled={true}
      />
    </div>
  );
}

function Logo() {
  const context = useContext<any>(FormContext);
  return (
    <InputWrapper
      className={styles.bigField}
      label='Logo'
      name='logo'
      input={ImageInput}
      inputProps={{
        alt: 'Company logo',
        fallbackLetter: context.resource?.name?.[0],
        kind: 'COMPANY_LOGO',
      }}
      required={true}
    />
  );
}

function Mission() {
  const placeholder =
    "Landing.jobs is a candidate-driven tech jobs marketplace. We're dedicated " +
    'to matchmaking great talent with great opportunities. Aim for more';

  return (
    <Text
      class={styles.bigField}
      hint='Please make a description of your company'
      label='About us'
      maxLength={Settings.company.descriptionMaxLength}
      maxLengthShow={true}
      name='description'
      placeholder={placeholder}
      required={true}
      textarea={true}
      useContext={true}
    />
  );
}

function industryLabel(industry) {
  return industry?.name;
}

function industryValue(industry) {
  return industry?.id;
}

function companySizeLabel(companySize) {
  return companySize?.label;
}

function companySizeValue(companySize) {
  return companySize?.id;
}
