import React from 'react';

const styles = require('./application_card_base.module.scss');

interface Props {
  children: any;
}

export default function ApplicationCardBody(props: Props) {
  return <div className={styles.body}>{props.children}</div>;
}
