import React from 'react';
import { shallow } from 'enzyme';
import AboutSectionSmallCard from './AboutSectionSmallCard';

describe('AboutSectionSmallCard', () => {
  it('shallow renders without crashing', () => {
    const props = {
      text: 'This is text!',
      handleDelete: jest.fn(),
    };
    const wrapped = shallow(<AboutSectionSmallCard {...props} />);
    expect(wrapped.exists()).toBe(true);
  });
});
