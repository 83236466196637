import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import TeamElementsList from './TeamElementsList';

let wrapped: ShallowWrapper;
describe('TeamElementsList', () => {
  beforeEach(() => {
    wrapped = shallow(<TeamElementsList teamMembers={[]} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});
