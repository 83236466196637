import React, { useRef, useState } from 'react';
import { handleRequestError, handleSuccessAlert, postFormData } from 'lib/request_deprecated';
import Button from 'lj_shared/button/button';
import CsvUploader from 'components/general/csvUploader/CsvUploader';
import DotLoader from 'lj_shared/dot_loader/dot_loader';
import Modal, { ButtonsLayout } from 'components/general/modal/modal';
const styles = require('./AssignLabelsModal.module.scss');

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

if (navigator.msSaveBlob) {
  // use navigator.msSaveBlob
}

interface Props {
  labelName: string;
  labelId: number;
}

export default function AssignLabelsModal(props: Props) {
  const modalRef = useRef(null);
  const [formData, setFormData] = useState(new FormData());
  const [csvError, setCsvError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClick = event => {
    event.preventDefault();
    modalRef.current.open();
    setLoading(false);
  };

  const downloadCsv = csv => {
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      // In case of IE 10+
      navigator.msSaveBlob(blob, 'failed_emails');
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'failed_emails');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
    window.location.reload();
  };

  const handleSubmit = () => {
    if (formData.has('file')) {
      setLoading(true);
      postFormData(window.Routes.upload_csv_backoffice_label(props.labelId), formData)
        .then(response => {
          handleSuccessAlert(response);
          modalRef.current.close();
          if (response.notice && response.csv) {
            downloadCsv(response.csv);
          } else if (response.notice) {
            window.location.reload();
          }
        })
        .catch(handleRequestError);
    } else {
      setCsvError('Please upload a CSV file');
    }
  };

  return (
    <div>
      <Button onClick={handleClick} buttonColor='tuftsBlue' buttonSize='small'>
        Assign To Label
      </Button>
      <Modal
        buttonColor='puertoRico'
        buttonsLayout={ButtonsLayout.OkCancel}
        buttonName='Assign To Label'
        buttonOnClick={handleSubmit}
        cancelButton
        disableButton={loading}
        disableOutsideClose
        onClose={() => setCsvError('')}
        ref={modalRef}
        title='Assign To Label'
      >
        <p>
          Upload your csv file, the people corresponding to those emails will be added to the Label{' '}
          <strong>{props.labelName}</strong>.
        </p>
        <p>
          <strong>Note:</strong> Make sure your .csv consists of a list of <strong>emails</strong>.
        </p>
        <CsvUploader formData={formData} setFormData={setFormData} />
        {loading && <DotLoader style={{ position: 'absolute', bottom: '0.5rem', left: '50%' }} />}
        <div>{csvError && <div className={styles.mandatory}>{csvError}</div>}</div>
      </Modal>
    </div>
  );
}
