import classNames from 'classnames';
import FormInput from 'components/general/form_input/form_input';
import AddLanguage from 'components/registration/add_language';
import ExperienceCard from 'components/registration/experience_card';
import ProfileForm from 'components/registration/profile_form';
import { deleteJSON, postJSON, handleRequestError } from 'lib/request_deprecated';
import { cloneDeep } from 'lodash';
import React from 'react';
import LabelWithStarIcon from '../LabelWithStarIcon';

const styles = require('./ProfileLanguages.module.scss');

interface Props {
  profileForm: ProfileForm;
  languages: { text: string; id: string }[];
  proficiencyLevels: { [Identifier: number]: string };
  setProfileForm: (profileForm: ProfileForm) => void;
  setProfileInfo: any;
  mandatory?: boolean;
}

const ProfileLanguages = (props: Props) => {
  const { profileForm, languages, proficiencyLevels, setProfileForm, setProfileInfo } = props;

  const handleAddLanguage = event => {
    postJSON(window.Routes.users_language_proficiencies(), {
      language: event.language.value,
      proficiency: event.proficiency.value,
    })
      .then(response => {
        if (response.notice) {
          window.Alerts && window.Alerts.notice(response.notice);

          const newLanguages = {
            personLanguages: [response.language, ...profileForm.personLanguages],
          };

          const form = cloneDeep(profileForm);
          form.update(newLanguages);

          setProfileForm(form);
          setProfileInfo({ ...form, newLanguages });
        } else if (response.alert) {
          window.Alerts.alert(response.alert);
        }
      })
      .catch(handleRequestError);
  };

  const handleLanguageDelete = id => {
    deleteJSON(window.Routes.users_language_proficiency(id))
      .then(response => {
        if (response.notice) {
          window.Alerts && window.Alerts.notice(response.notice);

          const newLanguages = {
            personLanguages: profileForm.personLanguages.filter(lang => lang.id !== id),
          };
          const form = cloneDeep(profileForm);
          form.update(newLanguages);

          setProfileInfo({ ...form, newLanguages });
          setProfileForm(form);
        }
      })
      .catch(handleRequestError);
  };

  return (
    <div>
      <FormInput
        name='personLanguages'
        label={
          <LabelWithStarIcon label='Language(s)' value={profileForm.personLanguages?.length > 0} />
        }
        error={profileForm.errors.personLanguages}
        required={props.mandatory}
        className={styles.languagesTitleContainer}
      >
        <AddLanguage
          languages={languages.slice(1)}
          handleAddLanguage={handleAddLanguage}
          proficiencyLevels={proficiencyLevels}
          id={styles.addLanguage}
        />
        <div className={styles.languages}>
          {profileForm.personLanguages.map(language => {
            return (
              <ExperienceCard
                key={language.id}
                title={language.name}
                description={language.experienceLabel}
                handleDeleteClick={() => handleLanguageDelete(language.id)}
                cardStyle={styles.languageCard}
              />
            );
          })}
        </div>
      </FormInput>
    </div>
  );
};

export default ProfileLanguages;
