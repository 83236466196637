import React from 'react';
import { range } from 'lib/range';
import Checkbox from '../form/checkbox/checkbox';
import InlineIcon from '../general/inline_icon/inline_icon';
const settings = require('settings.json');
const styles = require('./experience_dimension.module.scss');
const questionMarkPath = require('svg/icon_question_mark.svg');
import RatingBox from './rating_box';

export interface Props {
  classes?: string;
  value: any;
  ratingRef: any;
  handleChange: Function;
  backgroundYearsDefaultValue: boolean;
  handleChangeBackgroundYears: Function;
  sameRoleDefaultValue: boolean;
  handleChangeSameRole: Function;
  backgroundYearsRef: any;
  backgroundYearsValue: any;
  handleNoRelevantBackground: Function;
  noRelevantBackgroundValue: any;
}

export default class ExperienceDimension extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  handleChange = dimension => {
    this.props.handleChange(dimension);
  };

  handleChangeBackgroundYears = event => {
    this.props.handleChangeBackgroundYears(event.target.checked);
  };

  handleChangeSameRole = event => {
    this.props.handleChangeSameRole(event.target.checked);
  };

  render() {
    return (
      <>
        <div className={styles.container}>
          <h3 className={styles.h3}>Experience Level Match</h3>
          <RatingBox value={this.props.value} name='experience' ratingRef={this.props.ratingRef} />
        </div>
        <div className={styles.questions}>
          <div className={styles.checkbox}>
            <label>
              <Checkbox
                onChange={this.handleChangeBackgroundYears}
                name='background_checkbox'
                color='puertoRico'
                checked={this.props.backgroundYearsDefaultValue}
                controlled={true}
              />
              <div className={styles.text}>
                The candidate has a relevant background of{' '}
                <select
                  className={this.props.classes}
                  style={{ display: 'inline' }}
                  name='background_years'
                  key={'background_years' + this.props.value}
                  ref={this.props.backgroundYearsRef}
                  defaultValue={this.props.backgroundYearsValue}
                  onChange={this.handleChange}
                >
                  <option hidden />
                  {settings.experienceYears.map(([label, value]) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>{' '}
                .{' '}
              </div>
            </label>

            <div className={styles.tooltip}>
              <div className={styles.questionMarkIcon}>
                <InlineIcon path={questionMarkPath} alt='question-mark' />
              </div>

              <div className={styles.tooltipText}>
                <b>Relevant background</b>: The candidate needs to have similar/useful work
                experience in his previous and recent roles. The same is valid for education. Both
                the education and the work experience need to be pertinent to the job description
                and requirements.
              </div>
            </div>
          </div>

          <Checkbox
            onChange={this.handleChangeSameRole}
            name='same_role_performed'
            checked={this.props.sameRoleDefaultValue}
            controlled={true}
            color='puertoRico'
            label='The candidate has performed the same role.'
          />

          <Checkbox
            onChange={event => this.props.handleNoRelevantBackground(event.target.checked)}
            name='no_relevant_background'
            checked={this.props.noRelevantBackgroundValue}
            controlled={true}
            color='puertoRico'
            label={'The candidate has no relevant background for this position.'}
          />
        </div>
      </>
    );
  }
}
