import React from 'react';
import RatingBody from './rating_body';
import Rating from '__models__/rating';
import RatingBodyUtils from './utils/rating_body_utils';
import RatingContainerUtils from './utils/rating_container_utils';

const styles = require('./rating_container.module.scss');

interface Props {
  backoffice?: boolean;
  concern?: string;
  concernLevel?: number;
  concernNote?: string;
  dashboard?: boolean;
  hideCurationNotesIfLegacy?: boolean;
  hideRatingContainer?: boolean;
  outdated?: boolean;
  rateApplicationUrl?: string;
  ratings: Partial<Rating>[];
  screeningNotes?: string;
  serializedData?: boolean;
  showRatingBody?: boolean;
  toggleRatingBody?: Function;
}

export default function RatingContainer(props: Props) {
  const serializedProps = {
    ...props,
    ratings: props.serializedData ? new RatingContainerUtils(props.ratings).props() : props.ratings,
  };

  const ratingBodyProps = () => new RatingBodyUtils(serializedProps).props();

  const renderRatingBody = () => {
    if (props.showRatingBody) {
      return (
        <RatingBody
          backoffice={props.backoffice}
          concern={props.concern}
          concernNote={props.concernNote}
          concernLevel={props.concernLevel}
          {...ratingBodyProps()}
          dashboard={props.dashboard}
          outdated={props.outdated}
        />
      );
    }
    return null;
  };

  const renderRatingContainer = () => {
    if (props.hideRatingContainer) {
      return null;
    }
    return <div className={styles.cardContainer}>{renderRatingBody()}</div>;
  };
  return renderRatingContainer();
}
