import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import TeamMemberList from './TeamMemberList';

const createProps = () => ({
  teamMembers: [teamMember(1), teamMember(2), teamMember(3)],
});

const teamMember = (id: number) => {
  return {
    id,
    fullName: 'This is a name',
    role: 'This is a role',
    resume: 'This is a resume',
    photo: {
      name: 'this is a photo',
      imageURL:
        'https://lh3.googleusercontent.com/proxy/LpeDPhQdepsl56D8zn_dQoVAXCEjxF0mV4roaTnyjnsSzkLe8ezRh9tzWGzFS8eqOPi35yyIM6gavVUFpZldTkxR8PmLMerDwV7_jnUoUsbHZg',
    },
  };
};

let props;
let wrapped: ShallowWrapper;

describe('TeamMemberList', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<TeamMemberList {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});
