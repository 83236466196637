import * as React from 'react';
import EmailPreviewSuggestJob from './email_preview_suggest_job';
import { shallow, ShallowWrapper } from 'enzyme';

let wrapped: ShallowWrapper;

describe('Email Preview component', () => {
  beforeEach(() => {
    wrapped = shallow(<EmailPreviewSuggestJob hide='' form='' title='' url='' />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});
