import React from 'react';
import { shallow, ShallowWrapper, mount } from 'enzyme';
import SmallProfileDisplayList from './SmallProfileDisplayList';
import SmallProfileDisplayItem from './SmallProfileDisplayItem';

const createProps = (numberArray: number[] = []) => ({
  skills: numberArray.map(n => ({
    title: `title: ${n}`,
    label: `label: ${n}`,
  })),
});

let props;
let wrapped: ShallowWrapper;

describe('SmallProfileDisplayList renders no items', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<SmallProfileDisplayList {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('renders no items', () => {
    expect(wrapped.find(SmallProfileDisplayItem)).toHaveLength(0);
  });
});

describe('SmallProfileDisplayList renders top items', () => {
  const n = Math.floor(Math.random() * 5) + 1; // random number in [1, 5[ interval
  let mounted;
  beforeEach(() => {
    props = createProps(Array.from(Array(n).keys()));
    mounted = mount(<SmallProfileDisplayList {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(mounted.exists()).toBe(true);
  });

  it('has topHeader', () => {
    expect(mounted.find('.topHeader')).toHaveLength(1);
  });

  it("doesn't have otherHeader", () => {
    expect(mounted.find('.otherHeader')).toHaveLength(0);
  });

  it('Has N - 1 placeholders', () => {
    expect(mounted.find('.placeholder')).toHaveLength(n - 1);
  });

  it('renders N items', () => {
    expect(mounted.find(SmallProfileDisplayItem)).toHaveLength(n);
  });
});

describe('SmallProfileDisplayList renders all items', () => {
  const n = 6;
  let mounted;
  beforeEach(() => {
    props = createProps(Array.from(Array(n).keys()));
    mounted = mount(<SmallProfileDisplayList {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(mounted.exists()).toBe(true);
  });

  it('has top', () => {
    expect(mounted.find('.topHeader')).toHaveLength(1);
  });

  it('has other', () => {
    expect(mounted.find('.otherHeader')).toHaveLength(1);
  });

  it('Has N - 2 placeholders', () => {
    expect(mounted.find('.placeholder')).toHaveLength(n - 2);
  });

  it("Has N - 5 'other' items", () => {
    expect(mounted.find('.other')).toHaveLength(n - 5);
  });

  it('renders N items', () => {
    expect(mounted.find(SmallProfileDisplayItem)).toHaveLength(n);
  });
});
