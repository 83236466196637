import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Application } from '../../__models__/models';
import RejectionForm from './rejection_form';
import SquareRadioButtons from '../form/square_radio_buttons/square_radio_buttons';
import EmailPreview from '../general/email_preview/email_preview';
import Button from 'lj_shared/button/button';
import Modal from '../general/modal/modal';
import AlertBox from '../general/alert_box/alert_box';
import TextInput from '../form/text/text';
import Tag from 'components/general/tag/tag';

const styles = require('./rejection_modal.module.scss');
const iconWarning = require('iconic/warning.svg');
const handshakeIcon = require('svg/handshake.svg');

interface Props {
  application: Application;
  rejectionUrl: string;
  rejectApplication: any;
  applications?: number[];
  candidateNames?: any;
  rejectionDimensionsMapping: any;
}

interface State {
  rejectionForm: RejectionForm;
}

class RejectionModal extends React.Component<Props, State> {
  modalRef: any;
  scrollRef: any;

  constructor(props: Props) {
    super(props);
    this.modalRef = React.createRef();
    this.scrollRef = React.createRef();
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleReject = this.handleReject.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleCandidateMessage = this.handleCandidateMessage.bind(this);

    this.state = {
      rejectionForm: new RejectionForm({
        application: this.props.application,
        applicationId: this.props.application.id,
        url: this.props.rejectionUrl,
        applications: this.props.applications,
        reason: '',
        applicationState: this.props.application.attributes.employer_filter,
        dimensions: this.getDimensionsIfRating(),
        rejectionDimensionsMapping: this.props.rejectionDimensionsMapping,
        isMeetRequirements: this.isMeetRequirements(),
      }),
    };
  }

  handleCancel() {
    this.modalRef.current.close();
  }

  componentDidMount() {
    window.scrollTo(0, this.scrollRef.offsetTop);
  }

  componentDidUpdate() {
    if (document.querySelector('.lj-error')) {
      document.querySelector('.lj-error').scrollIntoView();
    }
  }

  handleReject() {
    const rejectionForm = this.state.rejectionForm;

    const reasonOther = document.getElementById('rejection_reason_other');
    const candidateMessage = document.getElementById('rejection_candidate_message');
    const landingMessage = document.getElementById('rejection_landing_message');
    const ratingMessage = document.getElementById('rejection_employer_rating_message');

    const updateObject = {
      reasonOther: reasonOther ? (reasonOther as any).value : null,
      candidateMessage: candidateMessage ? (candidateMessage as any).value : null,
      landingMessage: landingMessage ? (landingMessage as any).value : null,
      ratingMessage: ratingMessage ? (ratingMessage as any).value : null,
    };

    rejectionForm.update(updateObject);

    if (rejectionForm.validate()) {
      rejectionForm
        .save()
        .then(e => {
          if (e.notice) {
            window.Alerts.notice(e.notice);
            rejectionForm.update({ saved: true });

            this.setState(() => {
              return { rejectionForm };
            });

            this.props.rejectApplication();
            this.handleCancel();
          } else {
            window.Alerts.alert(e.alert);
            rejectionForm.update({ saved: false });
            window.toggleSpinner(false);
          }
        })
        .catch(() => {
          window.Alerts.alert('Oops, something went wrong.');
          rejectionForm.update({ saved: false });
          window.toggleSpinner(false);
        });
    } else {
      this.setState(() => {
        return { rejectionForm };
      });
    }
  }

  isMeetRequirements() {
    return (
      this.isInbox() &&
      this.props.application.attributes.ratings_with_dimensions[0]?.inbox_classification ===
        'meet_requirements'
    );
  }

  isInbox() {
    return this.props.application.attributes.employer_filter === 'inbox';
  }

  handleTextChange = event => {
    const input = event.currentTarget;
    const { name, value } = input;
    const { rejectionForm } = this.state;
    rejectionForm.update({ [name]: value });
    this.setState({ rejectionForm });
  };

  handleFormChange(input) {
    const update = {};
    update[input.name] = input.value;

    const rejectionForm = this.state.rejectionForm;
    rejectionForm.update(update);

    this.setState(() => {
      return { rejectionForm };
    });
  }

  handleCandidateMessage(event) {
    const update = {};
    update['candidateMessage'] = event.target.value;

    const rejectionForm = this.state.rejectionForm;
    rejectionForm.update(update);

    this.setState(() => {
      return { rejectionForm };
    });
  }

  renderFeedbackForLandingJobs() {
    const landingMsgLabel =
      `1. Is there any extra information you would like to share so we ` +
      'can improve our curation?';
    return (
      <>
        <div className={styles.ratingText}>
          <div className={styles.sectionTitle}>Feedback for Landing.jobs</div>
          <div className={styles.note}>
            Note: This info is visible to Landing.jobs only and it will help us improve future
            screenings. Thank you!
          </div>
        </div>

        <TextInput
          name='rejection[landing_message]'
          id='rejection_landing_message'
          color='ripePlum'
          textarea={true}
          label={landingMsgLabel}
          required={this.state.rejectionForm.requiresLandingMessage()}
          error={this.state.rejectionForm.errors.landing_message}
        />
      </>
    );
  }

  renderHanshakeAlert() {
    return (
      <AlertBox classes={styles.handshakeWarningContainer}>
        <div className={styles.handshakeWarningHeader}>
          <Tag
            title='Handshake'
            color='ripePlum'
            icon={handshakeIcon}
            labelClass={styles.handshakeWarningTag}
          />
          Application from handshake
        </div>
        <div className={styles.handshakeWarningBody}>
          This application comes from a handshake you made to the candidate. Are you sure you want
          to reject? Please keep in mind that you showed interest in getting to know the candidate.
          Did you reach out to the candidate? Do you want to do that before rejecting?
        </div>
      </AlertBox>
    );
  }

  renderButtons() {
    return (
      <div className={styles.footer}>
        <div className={styles.buttons}>
          <Button buttonColor='ripePlum' onClick={this.handleReject} otherClasses={styles.button}>
            Reject
          </Button>
          <Button
            buttonColor='ripePlum'
            buttonType='border'
            onClick={this.handleCancel}
            otherClasses={styles.button}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }

  rejectionReasons() {
    let reasons;
    if (this.isInbox()) {
      reasons = this.state.rejectionForm.inboxReasons();
    } else {
      reasons = this.state.rejectionForm.reasons();
    }
    return reasons;
  }

  getDimensionsIfRating() {
    const appAttrs = this.props.application.attributes;
    if (appAttrs.rating) {
      return appAttrs.ratings_with_dimensions[0].dimensions;
    } else {
      return null;
    }
  }

  renderCandidatesHeader() {
    if (this.props.candidateNames) {
      return `${this.props.candidateNames.length} candidates:`;
    } else {
      return this.state.rejectionForm.application.attributes.person.attributes.name;
    }
  }

  employerNote() {
    if (this.props.candidateNames) {
      return 'Note: Be aware that the same notes will be passed on to all the candidates by email.';
    } else {
      return 'Note: These notes will be passed on to the candidate by email.';
    }
  }

  renderNamesIfBulk() {
    const names = this.props.candidateNames;
    if (names) {
      const namesList = names.map(name => {
        return <li key={name}>{name}</li>;
      });

      return (
        <div className={styles.namesList}>
          <ul>{namesList}</ul>
        </div>
      );
    }
  }

  render() {
    return (
      <Modal
        className={styles.modal}
        defaultOpen={true}
        ref={this.modalRef}
        title='Reject Application'
        disableClose
      >
        <div className={styles.sectionTitle}>Feedback for {this.renderCandidatesHeader()}</div>
        {this.renderNamesIfBulk()}
        <div className={styles.note}>{this.employerNote()}</div>

        <SquareRadioButtons
          radioName='reason'
          groupLabel='1. Choose a rejection reason'
          required={true}
          vertical={true}
          error={this.state.rejectionForm.errors.reason}
          color='ripePlum'
          labels={this.rejectionReasons()}
          onClickYield={this.handleFormChange}
        />

        <div id='more-info-panel' className={styles.moreInfo}>
          {this.state.rejectionForm.reason === 'Other' && (
            <TextInput
              color='ripePlum'
              error={this.state.rejectionForm.errors.reason_other}
              id='rejection_reason_other'
              label='Could you please specify the reason?'
              name='reasonOther'
              onChange={this.handleTextChange}
              required={true}
            />
          )}

          {this.state.rejectionForm.reason === 'Position no longer available' && (
            <AlertBox classes='alert-box'>
              <h5>
                <img src={iconWarning} className='iconic iconic-sm' /> Position no longer available
              </h5>
              <p>
                By selecting <em>Position no longer available</em> we will close the job position
                and all ongoing applications will be closed.
              </p>
            </AlertBox>
          )}
          <TextInput
            name='rejection[candidate_message]'
            id='rejection_candidate_message'
            color='ripePlum'
            textarea={true}
            error={this.state.rejectionForm.errors.candidate_message}
            label='2. Give the candidate(s) more info about the rejection for future improvements:'
            onChange={this.handleCandidateMessage}
          />
        </div>

        {this.state.rejectionForm.reason && (
          <EmailPreview
            hide='.js-rejectionFormContent'
            form={this.state.rejectionForm}
            url={this.props.application.attributes.urls.preview_rejection_url}
          />
        )}
        {this.renderFeedbackForLandingJobs()}
        {this.renderButtons()}
      </Modal>
    );
  }

  static show(
    app,
    url,
    rejectApplication,
    rejectionDimensionsMapping,
    ids = null,
    candidateNames = null
  ) {
    const root = $('<div></div>');
    $('body').append(root);

    ReactDOM.render(
      <RejectionModal
        application={app}
        rejectionUrl={url}
        rejectApplication={rejectApplication}
        rejectionDimensionsMapping={rejectionDimensionsMapping}
        applications={ids}
        candidateNames={candidateNames}
      />,
      root[0]
    );
  }
}

export default RejectionModal;
