import * as React from 'react';
import Tag from 'components/general/tag/tag';
import TagWithTooltip from '../../general/tag_with_tooltip/tagWithTooltip';
const handshakeIcon = require('svg/handshake.svg');

export interface Props {
  handshake: any;
  jobTitle: string;
  noTooltip?: boolean;
  recipientName: string;
  userType: string;
}

const HandshakeTag = (props: Props) => {
  const talentUserText = `${props.recipientName} thought your profile looked promising for
                          ${props.jobTitle} and sent you a handshake request on ${props.handshake.created}.
                          You accepted on ${props.handshake.accepted}.`;
  const companyUserText = `You thought this profile looked promising for
                          ${props.jobTitle} and sent a handshake request on ${props.handshake.created}.
                          ${props.recipientName} accepted on ${props.handshake.accepted}.`;

  if (props.noTooltip) {
    return (
      <Tag
        title=''
        icon={handshakeIcon}
        labelClass='lj-handshake-label'
        color={props.userType === 'TalentUser' ? 'tuftsBlue' : 'ripePlum'}
      />
    );
  } else {
    return (
      <TagWithTooltip
        color={props.userType === 'TalentUser' ? 'tuftsBlue' : 'ripePlum'}
        icon={handshakeIcon}
        labelClass='lj-handshake-label'
        tooltipText={props.userType === 'TalentUser' ? talentUserText : companyUserText}
        textBelow={true}
      />
    );
  }
};

export default HandshakeTag;
