import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { groupBy } from 'lodash';
import Accordion from 'components/general/accordion/accordion';

const styles = require('./accordion_groups.module.scss');

interface Props<T> {
  accordionClassName?: string;
  accordionHeaderClassName?: string;
  children: (item: T) => ReactNode;
  className?: string;
  compareItems?: (item1: T, item2: T) => number;
  getGroupName: (item: T) => string;
  isGroupOpen: (item: T) => boolean;
  groupNames?: string[];
  items: T[];
}

export default function AccordionGroups<T>(props: Props<T>) {
  const { getGroupName, items } = props;
  const groupedItems = groupBy(items, getGroupName);
  const groupNames = props.groupNames || Object.keys(groupedItems);

  return (
    <div className={classNames(styles.container, props.className)}>
      {groupNames.map(name => renderAccordion(props, name, groupedItems[name]))}
    </div>
  );
}

function renderAccordion(props, name, items) {
  if (items && items.length > 0) {
    if (props.compareItems) items = items.sort(props.compareItems);

    return (
      <Accordion
        className={classNames(styles.accordion, props.accordionClassName)}
        defaultOpened={props.isGroupOpen(name)}
        headerClassName={classNames(styles.accordionHeader, props.accordionHeaderClassName)}
        innerClassName={styles.accordionContent}
        key={name}
        title={<span className={styles.accordionHeaderText}>{name}</span>}
      >
        {items.map(props.children)}
      </Accordion>
    );
  }
}
