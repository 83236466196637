import React, { useState } from 'react';
import Select from 'components/form/select/select';

interface Props {
  values: any;
  type: string;
  options: any;
  isMulti?: boolean;
}

SelectContainer.defaultProps = {
  isMulti: false,
  values: [],
};

export default function SelectContainer(props: Props) {
  const [values, setValues] = useState(props.values);

  const handleChange = selectedValues => setValues(selectedValues);

  return (
    <Select
      value={values}
      onChange={handleChange}
      options={props.options}
      isMulti={props.isMulti}
      type={props.type}
      name='help_article[tags][]'
    />
  );
}
