import Button from 'lj_shared/button/button';
import React from 'react';
import track from '../../lib/drip';

import './welcome.scss';

const astroboy = require('images/welcome-talent.svg');
const logo = require('images/lj_logo.png');

interface Props {
  language: {
    title: string;
    subtitle: string;
    text: string;
    button: string;
    button2?: string;
  };
  urls: {
    positivePath: string;
    negativePath: string;
    jobsPath?: string;
  };
  buttonColor: string;
}
const WelcomeContainer: React.FunctionComponent<Props> = props => {
  const { title, subtitle, text, button, button2 } = props.language;

  return (
    <div className='welcome_container'>
      <img className='welcome_logo' src={logo} alt='Landing.Jobs' />
      <div className='welcome_title'>{title}</div>
      <div className='welcome_text'>{subtitle}</div>
      <img className='welcome_astroboy' src={astroboy} alt='astroboy' />
      <div className='welcome_text bottom_text'>{text}</div>
      <div className='welcome_midButtons'>
        {button2 && (
          <Button
            otherClasses={'midButtons-button lj-button--border'}
            buttonColor={props.buttonColor}
            buttonSize='mid'
            isButton={false}
            buttonUrl={props.urls.jobsPath}
          >
            {button2}
          </Button>
        )}
        <Button
          onClick={() =>
            track('action', {
              name: 'work-welcome-click',
              controller: 'work',
              action: 'welcome-continue',
            })
          }
          otherClasses={'midButtons-button'}
          buttonColor={props.buttonColor}
          buttonSize='mid'
          isButton={false}
          buttonUrl={props.urls.positivePath}
        >
          {button}
        </Button>
      </div>
    </div>
  );
};

export default WelcomeContainer;
