import React from 'react';

import Icon, { Size } from 'components/general/icon/icon';
import classNames from 'classnames';

const styles = require('./handshake_card.module.scss');

export interface DefaultCard {
  title?: JSX.Element;
  body: string | JSX.Element;
  buttons?: JSX.Element;
  cardRef?: React.RefObject<HTMLDivElement>;
  customCss?: string;
}

export default function HandshakeCard(props: DefaultCard) {
  return (
    <div ref={props.cardRef} className={classNames(styles.handshakeCard, props.customCss)}>
      <div className={styles.title}>
        <Icon color='tuftsBlue' name='handshake' size={Size.Big} />
        Handshake
        {props.title}
      </div>
      <div className={styles.body}>{props.body}</div>{' '}
      <div className={styles.buttons}>{props.buttons}</div>
    </div>
  );
}
