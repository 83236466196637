import React, { useState, useRef } from 'react';
import Modal, { ButtonsLayout } from '../general/modal/modal';
import Selectize from 'components/general/selectize/selectize';
import Button from 'lj_shared/button/button';
import { getJSON, postJSON, deleteJSON } from 'lib/request';
import Icon, { Size } from '../general/icon/icon';

const styles = require('./manage_target_labels_modal.module.scss');

interface Props {
  targetId: number;
  targetType: string;
  targetName: string;
  labels: any;
}

export default function ManageTargetLabelsModal(props: Props) {
  const { targetId, targetType, targetName, labels } = props;
  const modalRef = useRef(null);
  const selectedRef = useRef(null);
  const deletingRef = useRef(null);
  const [dropdownLabels, setDropdownLabels] = useState([]);
  const [targetLabels, setTargetLabels] = useState([]);
  const [initialTargetLabelCount, setInitialTargetLabelCount] = useState(0);

  const modalOpen = () => {
    window.toggleSpinner(true);

    const followUp = response => {
      updateLabelLists(response.labels);
      setInitialTargetLabelCount(response.labels.length);
      modalRef.current.open();
    };

    getJSON(
      window.Routes.backoffice_target_labels(targetId, { target_type: targetType }),
      followUp
    );
  };

  const itemRender = item => <div id={item.value}>{item.text}</div>;

  const addLabel = () => {
    const followUp = ev => {
      if (ev.create_notice) {
        const newLabel = selectedRef.current;
        selectedRef.current = null;
        newLabel['labeled_instance_id'] = ev.labeled_instance_id;
        const newTargetLabels = targetLabels.concat(newLabel);

        updateLabelLists(newTargetLabels);
        window.Alerts.notice(ev.create_notice);

        if (ev.removed_label) {
          setTimeout(() => {
            updateLabelLists(
              newTargetLabels.filter(label => {
                return label.labeled_instance_id !== ev.removed_label.labeled_instance_id;
              })
            );
            window.Alerts.notice(ev.destroy_notice);
          }, 1000);
        }
      }
    };

    if (selectedRef.current) {
      window.toggleSpinner(true);
      const params = {
        labeled_instance: {
          label_id: selectedRef.current.label_id,
          target_id: targetId,
          target_type: targetType,
        },
      };
      const url = window.Routes.backoffice_labeled_instances(params);
      postJSON(url, null, followUp);
    }
  };

  const handleModalClose = () => {
    modalRef.current.close();

    if (initialTargetLabelCount !== targetLabels.length) {
      window.location.reload();
    }
  };

  const updateLabelLists = newTargetLabels => {
    setTargetLabels(newTargetLabels);
    setDropdownLabelsCollection(newTargetLabels);

    window.toggleSpinner(false);
  };

  const setDropdownLabelsCollection = (tempTargetLabels?) => {
    const targetLabelIds = tempTargetLabels.map(label => label.label_id);
    const newLabels = labels.filter(label => !targetLabelIds.includes(label.label_id));

    setDropdownLabels(newLabels);
  };

  const deleteLabel = ev => {
    const labeledInstanceId = ev.target.closest('li').getAttribute('id');
    deletingRef.current = parseInt(labeledInstanceId, 10);
    const url = window.Routes.backoffice_labeled_instance(labeledInstanceId);

    const followUp = e => {
      if (e.destroy_notice) {
        const newTargetLabels = targetLabels.filter(label => {
          return label.labeled_instance_id !== deletingRef.current;
        });

        deletingRef.current = null;
        updateLabelLists(newTargetLabels);
        window.Alerts.notice(e.destroy_notice);

        const hasLabel = newTargetLabels.includes(label => {
          return label.labeled_instance_id !== e.label.labeled_instance_id;
        });

        if (e.label && !hasLabel) {
          setTimeout(() => {
            updateLabelLists(newTargetLabels.concat(e.label));
            setTimeout(window.Alerts.notice(e.create_notice), 1000);
          }, 1000);
        }
      }
    };

    deleteJSON(url, null, followUp);
  };

  return (
    <>
      <a onClick={modalOpen} className={styles.dropdownLink}>
        Manage Labels
      </a>
      <Modal
        title='Manage Labels'
        ref={modalRef}
        disableOutsideClose
        cancelButton
        buttonColor={'puertoRicoMidDark'}
        buttonsLayout={ButtonsLayout.OkOnly}
        buttonName={'Done'}
        buttonOnClick={handleModalClose}
      >
        <div className={styles.container}>
          <div>
            <h4>Add Label</h4>
            <Selectize
              onChange={item => {
                selectedRef.current = {
                  label_id: item.value,
                  name: item.text,
                };
              }}
              color='puertoRico'
              items={dropdownLabels.map(item => ({
                value: item.label_id,
                text: item.name,
              }))}
              itemRender={itemRender}
              selectedRender={itemRender}
              placeholder='Top Developer'
            />
            <Button
              buttonColor='puertoRicoMidDark'
              isButton={false}
              otherClasses={styles.button}
              onClick={addLabel}
            >
              Add Label
            </Button>
          </div>
          <div className={styles.labelsContainer}>
            <h4>{targetName}'s current labels</h4>
            {targetLabels.length > 0
              ? targetLabels.map(label => {
                  return (
                    <li
                      key={label.label_id}
                      id={label.labeled_instance_id}
                      className={styles.label}
                    >
                      <div>
                        <Icon name='tagThin' color={'white'} size={Size.Medium} />
                        <span className={styles.labelText}>{label.name}</span>
                      </div>
                      <button onClick={deleteLabel}>
                        <Icon color='white' name='close' size={Size.MediumBig} />
                      </button>
                    </li>
                  );
                })
              : 'No labels yet..'}
          </div>
        </div>
      </Modal>
    </>
  );
}
