import React from 'react';
import { Link } from '__models__/models';
import Icon, { Size } from 'components/general/icon/icon';
import LabelWithStarIcon from 'components/talentUser/LabelWithStarIcon';
import Button from 'lj_shared/button/button';

const styles = require('./links.module.scss');
interface Props {
  disabled?: boolean;
  headerKey?: string;
  links: Link[];
  ljColor?: string;
  handshakeProfile?: boolean;
  openModal?: Function;
}

const Links = ({ headerKey, links, ljColor, disabled, handshakeProfile, openModal }: Props) => {
  if (!links && links === undefined) {
    return null;
  }

  if (disabled) {
    return (
      <div className={styles.blur}>
        {links.map((link: Link) => {
          return (
          <Icon
            key={link.title.toLowerCase()}
            name={link.category_key !== 'other' ? link.category_key : 'link'}
            color={ljColor || 'flamingo'}
            size={link.category_key === 'twitter' ? Size.QuiteSmall : Size.Medium}
          />
        )
        } )}
      </div>
    );
  }

  function renderNoLink(label) {
    return <LabelWithStarIcon label={label} value={false} styles={styles.noLink} />;
  }

  function renderCvButton() {
    return (
      <Button
        onClick={() => openModal(headerKey, null, '#cvFileName')}
        buttonColor='tuftsBlue'
        buttonSize='tiny'
        isRound
      >
        Add a CV
      </Button>
    );
  }

  function checkIfHasLink(category) {
    return links.some(item => item.category_key === category);
  }

  return (
    <>
      {links.map((link: Link) => (
        <a
          key={link.category_key}
          href={link.url}
          target='_blank'
          rel='noopener noreferrer'
          className={styles.linkItem}
        >
          <Icon
            key={link.title.toLowerCase()}
            name={link.category_key !== 'other' ? link.category_key : 'link'}
            color={ljColor || 'flamingo'}
            size={link.category_key === 'twitter' ? Size.QuiteSmall : Size.Medium}

          />
        </a>
      ))}
      {!handshakeProfile && !checkIfHasLink('cv') && renderCvButton()}
      {!handshakeProfile && !checkIfHasLink('linkedin') && renderNoLink('[no linkedin]')}
    </>
  );
};

export default Links;
