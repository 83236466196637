import classNames from 'classnames';
import React from 'react';

import '../pages/application_page.scss';
import 'lib/globals';
import { isMobile } from 'react-device-detect';
import AlertBox from '../general/alert_box/alert_box';
import ApplicationItem from './ApplicationItem';
import ApplicationsGroup from './applications_group';
import Button from 'lj_shared/button/button';
import CandidateSearchCTA from './CandidateSearchCTA';
import EmptyState from '../general/empty_state/empty_state';
import EngagementWarningModal from './engagementWarningModal/EngagementWarningModal';
import GroupTooltip from './group_tooltip';
import Icon from 'components/general/icon/icon';
import InlineIcon from '../general/inline_icon/inline_icon';
import Pagination, { renderShowingPages } from '../general/pagination/pagination';
import { Application } from '../../__models__/models';
import { ApplicationsState } from '../employer_applications/applications_state';
import { Step } from '../job_hiring_steps/models';
import { Urls } from './models';

const styles = require('./application_list.module.scss');
const iconChevron = require('iconic/chevron.svg');
const iconHand = require('images/icons/handpicked.svg');
const iconWarning = require('iconic/warning.svg');

export interface Props {
  applications: Application[];
  appsState: ApplicationsState;
  bulkMoveToStep: Function;
  bulkReject: Function;
  chatUrl: string;
  className?: string;
  contactsUrl: string;
  disengaged: boolean;
  handlePageClick: Function;
  hiringSteps: Step[];
  accordionsWithNewRules?: Function;
  loader: Function;
  loading: boolean;
  loadMoreApplications: Function;
  moveToStep: Function;
  rejectApplication: Function;
  seenList: number[];
  selectedJobId?: string;
  setApplication: Function;
  shouldGroupApplications: Function;
  topFilter: string;
  waitingForScreeningCounter: number;
  urls: Urls;
  numberOfTalentUsers: string;
}

interface State {
  expanded: boolean;
  showEngagementModal: boolean;
}

class ApplicationList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      expanded: true,
      showEngagementModal: false,
    };
    this.renderApplication = this.renderApplication.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
    this.handleExpandToggle = this.handleExpandToggle.bind(this);
  }

  handleExpandToggle() {
    this.setState({ expanded: !this.state.expanded });
  }

  handlePageClick = (pageData, target = this.props.topFilter, silent = false) => {
    this.props.handlePageClick(pageData, target, silent);
  };

  getGroupSteps() {
    const { hiringSteps, selectedJobId, topFilter } = this.props;
    switch (topFilter) {
      case 'inbox':
        return (
          this.props.accordionsWithNewRules(topFilter) || hiringSteps.filter(step => !step.jobId)
        );
      case 'reviewing':
        return (
          this.props.accordionsWithNewRules(topFilter) ||
          hiringSteps.filter(step => step.jobId === parseInt(selectedJobId, 10))
        );
      default:
        return hiringSteps.filter(step => step.jobId === parseInt(selectedJobId, 10));
    }
  }

  renderDisengagedWarning() {
    return (
      <AlertBox isMobile={isMobile}>
        <h5 className={styles.oh_no_marina_and_the_diamonds}>
          <img src={iconWarning} className='iconic iconic-sm' />
          Oh no!
        </h5>
        <p>
          We've noticed you haven't been very active lately. Active companies provide candidates a
          better experience, and don't have screening restrictions.
          <a
            onClick={() => this.setState(() => ({ showEngagementModal: true }))}
            className='lj-link--ripePlum'
          >
            {' '}
            Here
          </a>{' '}
          are some suggestions of actions to perform on the platform if you want to change this. If
          you have any questions please{' '}
          <a href={this.props.contactsUrl} className='lj-link--ripePlum'>
            get in touch with us.
          </a>
        </p>
      </AlertBox>
    );
  }

  renderWaitingForScreeningWarning() {
    return (
      <AlertBox isMobile={isMobile}>
        <h5 className={styles.oh_no_marina_and_the_diamonds}>Waiting for screening</h5>
        <p>
          You already have {this.props.waitingForScreeningCounter} candidates waiting for
          Landing.Jobs' screening. This should take up to 48 hours to happen, but if you can't wait
          to start going through your pipeline of candidates,{' '}
          <a
            href={window.Routes.list_waiting_employer_applications()}
            className='lj-link--ripePlum'
          >
            click here.
          </a>
        </p>
      </AlertBox>
    );
  }

  getApplicationList = applications => {
    return (
      <div className={styles.grid}>
        {applications.map(app => this.renderApplication(app, { controlled: false }, null, true))}
      </div>
    );
  };

  sortRejected = applications => {
    return applications?.sort((a, b) => {
      const dateA = new Date(a.attributes.rejection.rejected_at);
      const dateB = new Date(b.attributes.rejection.rejected_at);

      return dateB > dateA ? 1 : -1;
    });
  };

  renderApplicationList = () => {
    if (this.props.topFilter === 'rejected') {
      const totalRejected = this.props.appsState.count('rejected');
      const totalLoaded = this.props.applications.length;
      const applications = this.sortRejected(this.props.applications);

      return (
        <div>
          {this.getApplicationList(applications)}
          <div className={styles.moreButtonContainer}>
            {totalRejected > totalLoaded && (
              <Button
                isButton={true}
                buttonColor='ripePlum'
                onClick={this.handleLoadMore}
                otherClasses={`.u-float--right ${styles.loadMoreBtn}`}
                disabled={this.props.loading}
              >
                {this.props.loading ? 'Loading more Applications' : 'Load more Applications'}
              </Button>
            )}
            {this.props.loading && this.props.loader()}
          </div>
          {renderShowingPages(0, this.props.applications.length, totalRejected, 2, 'applications')}
        </div>
      );
    }

    return this.props.shouldGroupApplications(this.props.topFilter)
      ? this.renderGroupedApplications()
      : this.renderSingleApplications();
  };

  renderSingleApplications = () => {
    return (
      <div>
        <Pagination
          totalItemCount={this.props.appsState.count(this.props.topFilter)}
          perPage={this.props.appsState.perPage(this.props.topFilter)}
          handlePageClick={this.handlePageClick}
          currentPage={this.props.appsState.page(this.props.topFilter)}
          itemName='applications'
        >
          {this.getApplicationList(this.props.applications)}
        </Pagination>
      </div>
    );
  };

  render() {
    if (
      this.props.applications.length === 0 &&
      !this.props.shouldGroupApplications(this.props.topFilter)
    ) {
      return this.props.selectedJobId ? this.renderCandidateSearchCTA() : this.renderEmpty();
    } else {
      return (
        <div className={this.props.className}>
          {this.props.disengaged && this.renderDisengagedWarning()}
          {this.props.topFilter === 'inbox' &&
            this.props.waitingForScreeningCounter > 0 &&
            this.renderWaitingForScreeningWarning()}
          {this.showCandidateSearchCTAInSingleApplications() && this.renderCandidateSearchCTA()}
          {this.state.showEngagementModal && (
            <EngagementWarningModal
              onClose={() => this.setState(() => ({ showEngagementModal: false }))}
            />
          )}
          {this.renderHandpicked()}

          {this.renderApplicationList()}
        </div>
      );
    }
  }

  renderEmpty = () => (
    <div className={this.props.className}>
      <EmptyState>No matching applications were found</EmptyState>
    </div>
  );

  handleLoadMore() {
    const page = this.props.appsState.page(this.props.topFilter) + 1;
    const target = this.props.topFilter;
    this.props.loadMoreApplications(target, page);
  }

  showCandidateSearchCTAInGroupedApplications(groupsArray) {
    const checkIfAllGroupsAreEmpty = groupsArray.every(group => !group[1].length);
    const checkIfAllApplicationsHaveMoreThan14Days = groupsArray.every(group =>
      group[1].every(app => app.attributes.submitted_more_than_14_days_ago)
    );
    return (
      this.props.selectedJobId &&
      (checkIfAllGroupsAreEmpty ||
        (checkIfAllApplicationsHaveMoreThan14Days && this.props.topFilter === 'inbox'))
    );
  }

  showCandidateSearchCTAInSingleApplications() {
    const checkIfAllApplicationsHaveMoreThan14Days = this.props.applications.every(
      app => app.attributes.submitted_more_than_14_days_ago
    );

    return (
      this.props.selectedJobId &&
      checkIfAllApplicationsHaveMoreThan14Days &&
      this.props.topFilter === 'waiting'
    );
  }

  renderCandidateSearchCTA() {
    return (
      <CandidateSearchCTA
        job={this.props.selectedJobId}
        numberOfTalentUsers={this.props.numberOfTalentUsers}
      />
    );
  }

  renderGroupedApplications() {
    const expandButtonClass = classNames(styles.expandButton, {
      [styles['is-expanded']]: this.state.expanded,
    });
    const steps: Step[] = this.getGroupSteps();
    const groupsArray = [];

    for (const step of steps) {
      const { slug } = step;
      const application = this.props.appsState.box(slug);
      groupsArray.push([slug, application, step]);
    }

    return (
      <div>
        <div className={expandButtonClass} onClick={this.handleExpandToggle}>
          {this.state.expanded ? 'Close' : 'Open'} all{' '}
          {this.props.topFilter === 'inbox' ? 'sections' : 'hiring steps'}
          <InlineIcon className={styles.iconChevron} path={iconChevron} />
        </div>
        {this.showCandidateSearchCTAInGroupedApplications(groupsArray) &&
          this.renderCandidateSearchCTA()}
        {groupsArray.map((group, idx) => this.renderSanitizedStep(group, idx, steps))}
      </div>
    );
  }

  renderSanitizedStep = (group, idx, steps) => (
    <ApplicationsGroup
      applications={group[1]}
      bulkMoveToStep={this.props.bulkMoveToStep}
      bulkReject={this.props.bulkReject}
      expanded={this.state.expanded}
      idx={idx}
      slug={group[0]}
      key={group[0]}
      step={group[2]}
      steps={steps}
      renderApplication={this.renderApplication}
      urls={this.props.urls}
      handlePageClick={this.handlePageClick}
      appsState={this.props.appsState}
      loader={this.props.loader}
    />
  );

  renderApplication(
    application,
    checkboxProps = { controlled: false },
    slug = null,
    hideCheckbox = false
  ) {
    if (!application) return null;
    const { person, hiring_steps, delivered, step_label, current_state, urls, is_inbox } =
      application.attributes;

    const hideContactsBodyClass = is_inbox ? ' lj-application-hide-contacts' : '';

    return (
      <div
        key={application.id}
        className={'lj-application-profile lj-application-body' + hideContactsBodyClass}
      >
        <div id='lj-employer-lg' className='lj-move-left lj-application-left lj-application-col'>
          <ApplicationItem
            application={application}
            checkboxProps={checkboxProps}
            hideCheckbox={hideCheckbox}
            currentStep={current_state}
            delivered={delivered}
            hiringSteps={hiring_steps}
            moveToStep={this.props.moveToStep}
            onApplicationList={true}
            profileUrl={urls.profileUrl}
            rejectApplication={this.props.rejectApplication.bind(this, application)}
            seenList={this.props.seenList}
            urls={urls}
            stepLabel={step_label}
            waitingReview={is_inbox}
            setApplication={this.props.setApplication}
            topFilter={this.props.topFilter}
            horizontal={slug === 'state-probably_not_a_match'}
            handshakeAccordion={slug === 'state-handshake'}
          />
        </div>
      </div>
    );
  }

  renderHandpicked() {
    const applications = this.props.appsState.box('state-handpicked');
    const tab = this.props.topFilter;

    if (applications && applications.length > 0 && tab === 'inbox') {
      return (
        <div className={styles.handpickedContainer}>
          <div className={styles.handpickedHeader}>
            <img className={styles.handpickedHandIcon} src={iconHand} />
            <span className={styles.handpickedTitle}>Handpicked</span>
            <GroupTooltip className={styles.handpickedTooltip} name='Handpicked' />
            <span className={styles.handpickedCount}>{applications.length}</span>
            <Icon
              className={styles.handpickedPersonIcon}
              color='puertoRico-midDark'
              name='person'
            />
          </div>
          {this.getApplicationList(applications)}
        </div>
      );
    }
  }
}

export default ApplicationList;
