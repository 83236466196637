import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Icon, { Size } from 'components/general/icon/icon';
import classNames from 'classnames';

const styles = require('./SortableItem.module.scss');

type UniqueIdentifier = string | number;

interface ResizeObserverConfig {
  /** Whether the ResizeObserver should be disabled entirely */
  disabled?: boolean;
  /** Resize events may affect the layout and position of other droppable containers.
   * Specify an array of `UniqueIdentifier` of droppable containers that should also be re-measured
   * when this droppable container resizes. Specifying an empty array re-measures all droppable containers.
   */
  updateMeasurementsFor?: UniqueIdentifier[];
  /** Represents the debounce timeout between when resize events are observed and when elements are re-measured */
  timeout?: number;
}

interface Arguments {
  id: string;
  resizeObserverConfig: ResizeObserverConfig;
}

export default function SortableItem(props) {
  const resizeObserverConfig: ResizeObserverConfig = {};
  const args: Arguments = {
    id: props.id,
    resizeObserverConfig,
  };
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable(args);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      className={classNames(styles.itemContainer, styles[props.color])}
      ref={setNodeRef}
      style={style}
    >
      <div className={styles.item} {...attributes} {...listeners}>
        {props.isOrdered && <div className={styles.itemOrder}>{props.order}</div>}
        <div className={styles.itemText}>{props.item}</div>
      </div>
      {props.isRemovable && (
        <div className={styles.dismissButton}>
          <Icon
            id={'remove_' + props.id}
            name={props.deleteIcon || 'circleX'}
            color={props.color}
            size={Size.MediumLarge}
            clickHandler={props.handleRemoveItem}
          />
        </div>
      )}
    </div>
  );
}
