import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import Sidebar, { JobsSection, MoreInfoAboutCompany } from './Sidebar';
import ApplyButton from './ApplyButton';
import { FollowSection } from 'components/company/Sidebar';
import ShareSection from 'components/general/share_section/ShareSection';
import SubscribeButton from 'components/subscribeButton/SubscribeButton';
const jobDummy = require('components/jobs_page/fixtures/job_ad_dummy.json');

window.Routes = {
  company: () => '',
  job_ad: () => '',
  jobs: () => '',
};

const createProps = () => ({
  applications: [{ jobId: 123, state: -2 }],
  averageHiringProcess: { companyAvg: '2-3 weeks', marketAvg: '4-6 weeks' },
  cancelReasons: [
    'I got a more interesting proposal.',
    "I got second feelings and I'm backing down",
  ],
  jobAd: jobDummy,
  bookmark: null,
  companySubscriptions: [],
  company: {
    coverPhotoUrl: '',
    description: 'text',
    id: '100',
    logoUrl: '',
    name: 'name',
    shortPitch: 'text',
    slug: 'slug',
  },
  currentUser: { id: 3, type: 'TalentUser' },
  fixedHeader: false,
  handshake: null,
  jobs: {
    nodes: [
      {
        city: 'Lisbon',
        countryCode: 'PT',
        id: '200',
        salary: null,
        title: 'software developer',
      },
    ],
  },
  referralId: 234,
});

let props;
let wrapped: ShallowWrapper;

describe('Sidebar renders correctly', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<Sidebar {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('should have an Apply Button', () => {
    expect(wrapped.find(ApplyButton)).toHaveLength(1);
  });

  it('should have a Share Section', () => {
    expect(wrapped.find(ShareSection)).toHaveLength(1);
  });

  it('should have a Follow Section', () => {
    expect(wrapped.find(FollowSection)).toHaveLength(1);
  });

  it('should have subscribe to job button', () => {
    expect(wrapped.find(SubscribeButton)).toHaveLength(1);
  });

  it('should have other jobs section', () => {
    expect(wrapped.find(JobsSection)).toHaveLength(1);
  });

  it('should have other more info about company section', () => {
    expect(wrapped.find(MoreInfoAboutCompany)).toHaveLength(1);
  });
});
