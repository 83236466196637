import * as React from 'react';
import ApplicationTags, { Props } from './application_tags';
import { shallow, mount } from 'enzyme';
import ApplicationTag from './applicationTag/ApplicationTag';

const createTestProps = moreProps => {
  return {
    personTags: {
      multiple_processes: false,
    },
    applicationTags: {
      candidate_search: false,
      expiration: false,
      days_to_expiration: null,
      visa_eligible: '',
    },
    testResults: [],
    testNotSent: true,
    ...moreProps,
  };
};

let props: Props;
let wrapped;

describe('Application Tags component without tags', () => {
  beforeAll(() => {
    props = createTestProps({});
    wrapped = mount(<ApplicationTags {...props} />);
  });

  it('renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('returns null', () => {
    expect(wrapped.find('.lj-label').exists()).toBe(false);
  });
});
