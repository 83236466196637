import * as React from 'react';
import ContextConsumer from '../../landing_work/home/components/context';
import classnames from 'classnames';
import TextInput from 'components/form/text/text';
import Checkbox from 'components/form/checkbox/checkbox';
import Button from 'lj_shared/button/button';
import AuthButtons from './auth_buttons';
import { getCSRFToken } from 'lib/request_deprecated';
import { getQueryParams } from 'lib/utils';

const styles = require('./sign_up.module.scss');
const logo = require('images/lj_logo.png');

export interface SignUpProps {
  candidateSelected: boolean;
  editCompanyDisabled: boolean;
  urls: {
    loginUrl: string;
    newTalent: string;
    newCompanyUser: string;
    extraParams?: { [Identifier: string]: string };
  };
  socialUrls: {
    linkedin?: string;
    github: string;
    facebook: string;
    google: string;
  };
  errors?: any;
  values: {
    name: string;
    company_name?: string;
    email: string;
    tos_consent: boolean;
  };
}

interface ToggleProps {
  candidateSelected: boolean;
  changeCandidateSelected: Function;
}

interface SignUpState {
  candidateSelected: boolean;
}

class SignUp extends React.Component<SignUpProps, SignUpState> {
  constructor(props) {
    super(props);

    this.state = {
      candidateSelected: this.props.candidateSelected,
    };

    this.signUpUrl = this.signUpUrl.bind(this);
    this.changeCandidateSelected = this.changeCandidateSelected.bind(this);
  }

  renderAlreadyHaveAccount() {
    return (
      <p className='.ld-medium-gray-text.ld-center-text.ld-login-warning.u-marginTop--large'>
        Already have an account?{' '}
        <a href={this.props.urls.loginUrl} className={styles.link}>
          Log in here
        </a>
      </p>
    );
  }

  changeCandidateSelected(candidateSelected) {
    this.setState({ candidateSelected });
  }

  render() {
    return (
      <ContextConsumer>
        {() => {
          document.title = `Landing.jobs | Sign up`;
          return (
            <div className={styles.signUpMain}>
              <section className={styles.signUp}>
                <img className={styles.logo} src={logo} alt='Landing.Jobs' />
                <SignUpToggle
                  candidateSelected={this.state.candidateSelected}
                  changeCandidateSelected={this.changeCandidateSelected}
                />
                <SignUpForm
                  editCompanyDisabled={this.props.editCompanyDisabled}
                  loginUrl={this.props.urls.loginUrl}
                  signUpUrl={this.signUpUrl()}
                  company={!this.state.candidateSelected}
                  errors={this.props.errors}
                  values={this.props.values}
                />
                {this.state.candidateSelected && (
                  <AuthButtons kind='Sign up' urls={this.props.socialUrls} />
                )}
                {this.renderAlreadyHaveAccount()}
              </section>
              <div className={styles.background} />
            </div>
          );
        }}
      </ContextConsumer>
    );
  }

  getExtraParams = (): string => {
    if (!this.props.urls.extraParams || !Object.keys(getQueryParams())) {
      return '';
    } else {
      const extraParams = { ...this.props.urls?.extraParams, ...getQueryParams() };
      return `?${new URLSearchParams(extraParams).toString()}`;
    }
  };

  signUpUrl = () => {
    return this.state.candidateSelected
      ? this.props.urls.newTalent + this.getExtraParams()
      : this.props.urls.newCompanyUser;
  };
}

const SignUpToggle = (props: ToggleProps) => {
  const { candidateSelected, changeCandidateSelected } = props;

  const classes = classnames(styles.toggle, styles.jobsToggle);

  return (
    <div className={styles.toggleBar}>
      <div
        className={classnames(classes, candidateSelected && styles.active)}
        onClick={() => changeCandidateSelected(true)}
        role='button'
      >
        I'm a Job Seeker
      </div>

      <div
        className={classnames(classes, !candidateSelected && styles.active)}
        onClick={() => changeCandidateSelected(false)}
        role='button'
      >
        I'm an Employer
      </div>
    </div>
  );
};

const SignUpForm = props => {
  const { editCompanyDisabled, company, signUpUrl, errors, values } = props;

  return (
    <div className='.ld-container.ld-wizard-container.ld-auth-box'>
      <form action={signUpUrl} method='post'>
        <input name='authenticity_token' value={getCSRFToken()} type='hidden' />

        {company && (
          <TextInput
            disabled={editCompanyDisabled}
            label='Company name'
            name='company_name'
            placeholder='Company Name'
            color='tuftsBlue'
            error={errors && errors.company_name}
            value={values && values.company_name}
          />
        )}
        <TextInput
          label='First and last name'
          name='name'
          placeholder='First and last name'
          color='tuftsBlue'
          error={errors && errors.name}
          value={values && values.name}
          autocomplete='new-password'
        />
        <TextInput
          label='Email'
          name='email'
          placeholder='Email'
          color='tuftsBlue'
          type='email'
          error={errors && errors.email}
          value={values && values.email}
        />
        <TextInput
          label='Password'
          name='password'
          placeholder='Password'
          color='tuftsBlue'
          type='password'
          error={errors && errors.password}
          onFocusPopUp='Your password must have 8 or more characters'
        />
        <Checkbox
          name='tos_consent'
          id='tos_consent'
          controlled={false}
          value='true'
          className={styles.checkbox}
          label={
            <>
              By signing up you agree to our{' '}
              <a href='/tos' target='_blank' className={styles.link}>
                Terms & Conditions
              </a>
              .
            </>
          }
          error={errors && errors.tos_consent}
          checked={values && !!values.tos_consent}
        />
        <Button
          buttonColor={company ? 'ripePlum' : 'tuftsBlue'}
          otherClasses={styles.button}
          isButton={true}
        >
          Sign up
        </Button>
      </form>
    </div>
  );
};

export default SignUp;
