import * as React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import SelectContainer from './select_container';

let wrapped: ShallowWrapper;

describe('Deferred Tab component', () => {
  beforeEach(() => {
    wrapped = shallow(
      <SelectContainer values={[]} options={[]} isMulti={true} type={'creatable'} />
    );
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
