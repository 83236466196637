import React from 'react';
import PricingQuestionMarkTooltip from 'components/pricing_question_mark_tooltip/pricing_question_mark_tooltip';

interface Props {
  available: string;
  pricingDisabledFeature?: boolean;
  pricingLimitType?: boolean;
  type: string;
}

export default function AvailableTierFeatureCounter(props: Props) {
  const { available, pricingDisabledFeature, pricingLimitType, type } = props;
  const textArray = type.split(' ');
  const textType = textArray[textArray.length - 1];

  return (
    <div className='lj-available-tier-feature'>
      <div className='lj-available-tier-feature-text'>Available {textType}</div>
      <div className='lj-available-tier-feature-counter'>{available}</div>
      <PricingQuestionMarkTooltip
        pricingDisabledFeature={pricingDisabledFeature}
        pricingLimitType={pricingLimitType}
        type={type}
      />
    </div>
  );
}
