// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Dropdown should render correctly 1`] = `
Array [
  <div
    className="dropdownContainer"
  >
    <div
      className="searchBox"
      data-testid="search-input"
      onClick={[Function]}
    >
      <input
        onChange={[Function]}
        placeholder=""
        value=""
      />
      <span
        className="chevron"
      />
    </div>
  </div>,
  <div
    className="dropdownSelectedValue"
  >
    <div
      className="dropdownTags"
    />
  </div>,
]
`;
