import * as React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import ArticleItem from './article_item';

const itemProps = () => ({
  article: {
    id: '2',
    type: 'help_article',
    attributes: {
      title: 'Yep',
    },
  },
  baseArticleUrl: '/employer/help/~id',
});

let props;
let wrapped: ShallowWrapper;

describe('Last Articles Container component', () => {
  beforeEach(() => {
    props = itemProps();
    wrapped = shallow(<ArticleItem {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});
