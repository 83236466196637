import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import RequestHandshakeSvg from './request_handshake_svg';

const createProps = () => ({
  namespace: 'handshake-request-12345',
});

let props;
let wrapped: ShallowWrapper;

describe('RequestHandshakeSvg', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<RequestHandshakeSvg {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
