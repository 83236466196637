import * as React from 'react';
import ChatLoading from './chat_loading';

import { shallow } from 'enzyme';

test('ChatLoading test', () => {
  const component = shallow(<ChatLoading />);

  expect(component).toMatchSnapshot();
});
