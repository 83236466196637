import * as React from 'react';
import Chat, { Props } from './chat';
import { mount } from 'enzyme';

const createTestProps = moreProps => {
  return {
    create_conversation_url: '',
    conversations_url: '',
    messages_url: '',
    other_contacts_url: '',
    find_conversation_url: '',
    notes_url: '',
    note_destroy_url: '',
    get_notes_url: '',
    unread_counts: {},
    user_type: '',
    current_user_id: 1,
    messageable_id: '',
    messageable_type: '',
    mini: false,
    ...moreProps,
  };
};

let props: Props;
let wrapped: any;
let spy: any;

describe('Chat component', () => {
  beforeEach(() => {
    props = createTestProps({});
    wrapped = mount(<Chat {...props} />);
  });

  afterEach(() => {
    wrapped.unmount();
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

describe('when mini chat is minimized', () => {
  beforeEach(() => {
    spy = jest.spyOn(Chat.prototype, 'getConversations');
    props = createTestProps({ mini: true });
    wrapped = mount(<Chat {...props} />);
    wrapped.setState({ conversationListMinimized: true });
  });

  afterEach(() => {
    wrapped.unmount();
    spy.mockClear();
  });

  it('is hidden', () => {
    expect(wrapped.find('.lj-conversations.hidden')).toHaveLength(1);
  });

  it('does not load any conversations', () => {
    expect(spy).toHaveBeenCalledTimes(0);
  });

  it('loads conversations when clicked', () => {
    wrapped.find('.lj-chat--miniHeader').simulate('click');
    wrapped.update();
    expect(wrapped.find('.lj-conversations.hidden')).toHaveLength(0);
    expect(spy).toHaveBeenCalled();
  });
});

test.todo('add more tests');
