import React from 'react';
import ReactDOM from 'react-dom';
import Modal from '../general/modal/modal';
import TextInput from '../form/text/text';
import Button from 'lj_shared/button/button';
import { postJSON, handleRequestError } from '../../lib/request_deprecated';
const styles = require('./reconsider_modal.module.scss');

interface Props {
  reconsiderUrl: string;
  reconsiderApplication: any;
}

interface State {
  reconsider_comments: string;
  reconsider_message: string;
  email_preview: boolean;
}

type StateKeys = keyof State;
export default class ReconsiderModal extends React.Component<Props, State> {
  modalRef: any;
  commentsRef: any;
  messageRef: any;

  constructor(props: Props) {
    super(props);
    this.modalRef = React.createRef();
    this.commentsRef = React.createRef();
    this.messageRef = React.createRef();
    this.handleReconsider = this.handleReconsider.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      reconsider_comments: '',
      reconsider_message: '',
      email_preview: false,
    };
  }

  handleChange(event, key: StateKeys) {
    event.preventDefault();

    this.setState({ [key]: event.target.value } as Pick<State, keyof State>);
  }

  handleReconsider(event) {
    event.preventDefault();

    if (this.state.reconsider_comments) {
      postJSON(this.props.reconsiderUrl, {
        application: { ...this.state },
      })
        .then(e => {
          if (e.notice) {
            window.Alerts.notice(e.notice);

            this.setState({ reconsider_comments: '', reconsider_message: '' });
            this.props.reconsiderApplication();
            this.handleCancel();
          } else {
            window.Alerts.alert(e.alert);
            window.toggleSpinner(false);
          }
        })
        .catch(handleRequestError);
    } else {
      window.Alerts.alert('You must fill in a comment');
    }
  }

  handleCancel() {
    this.modalRef.current.close();
  }

  render() {
    return (
      <Modal title='RECONSIDER APPLICATION' defaultOpen={true} ref={this.modalRef}>
        <p>
          Feeling like the candidate deserves another chance? We've got you covered! Just fill out
          this form, and your candidate will be back into the game in no time! (i.e., the
          application will go back to Unreviewed state)
        </p>
        <TextInput
          name='application_reconsider_comments'
          color='ripePlum'
          id='application_reconsider_comments'
          label='Why do you feel the candidate deserves another chance? (not visible to the candidate)'
          textarea={true}
          required={true}
          onChange={event => this.handleChange(event, 'reconsider_comments')}
          inputRef={this.commentsRef}
        />
        <TextInput
          name='application_reconsider_message'
          color='ripePlum'
          id='application_reconsider_message'
          label='Message to the candidate (Optional)'
          textarea={true}
          onChange={event => this.handleChange(event, 'reconsider_message')}
          inputRef={this.messageRef}
        />
        <div className={styles.footer}>
          <div className={styles.buttons}>
            <Button
              buttonColor='ripePlum'
              onClick={this.handleReconsider}
              otherClasses={styles.button}
            >
              Reconsider
            </Button>
            <Button
              buttonColor='silverSand'
              buttonType='border'
              onClick={this.handleCancel}
              otherClasses={styles.button}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

  static show(reconsiderUrl, reconsiderApplication) {
    const root = $('<div id="reconsider-modal-container"></div>');

    $('body').append(root);

    ReactDOM.render(
      <ReconsiderModal
        reconsiderUrl={reconsiderUrl}
        reconsiderApplication={reconsiderApplication}
      />,
      root[0]
    );
  }
}
