import React from 'react';
import sanitizeHtml from 'sanitize-html-react';
import OtherArticles from './other_articles';
import Button from 'lj_shared/button/button';
import Icon from 'components/general/icon/icon';

const styles = require('./article_page.module.scss');

export interface Props {
  article: any;
  indexPath: string;
  origin?: string;
  otherArticles: any;
}

export default function ArticlePage(props: Props) {
  const { title, body } = props.article.data.attributes;
  const isCandidate = props.origin === 'candidate';

  return (
    <div className={styles.wrapper}>
      <div className={styles.leftSide}>
        <Button isButton={false} buttonColor='black' buttonType='link' buttonUrl={props.indexPath}>
          <Icon name='arrowLeft' className={styles.arrow} />
          <span className='text'>Back to help center</span>
        </Button>
        <h1 className={styles.title}>{title}</h1>
        <div
          className={styles.helpArticle}
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(body, {
              allowedTags: sanitizeHtml.defaults.allowedTags.concat(['h1', 'h2', 'img', 'iframe']),
              allowedAttributes: {
                a: ['href'],
                iframe: ['src', 'width', 'height'],
                img: ['src'],
              },
              allowedSchemes: ['data', 'http', 'https'],
            }),
          }}
        />
      </div>
      <div className={styles.rightSide}>
        {props.otherArticles.data.length > 0 && (
          <OtherArticles articles={props.otherArticles.data} isCandidate={isCandidate} />
        )}
      </div>
    </div>
  );
}
