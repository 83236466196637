import React from 'react';
import sanitizeHtml from 'sanitize-html-react';

import CompanySection from '../CompanySection';
import MetaTags from '../MetaTags';
import { Company } from '__models__/gql/all';
import CompagePageSectionTitle from '../CompanyPageSectionTitle';
import Icon from 'components/general/icon/icon';
import SectionValuesImages from 'components/companyInfoForm/utils/SectionValuesImages';
import { snakeCase } from 'lib/string';

const styles = require('./AboutUs.module.scss');

interface Props {
  company: Partial<Company>;
}

export default function CompanyPageAboutUs({ company }: Props) {
  return (
    <CompanySection header={<MetaTags company={company} />} title='About us'>
      <CompanyDescription description={company.description} />
      <CompanyPerks perks={company.perks} />
      <CompanyNumbers companyNumbers={company.numbers} />
      <CompanyValues companyValues={company.values} />
      <CompanyHistory milestones={company.milestones} />
    </CompanySection>
  );
}

function CompanyDescription({ description }) {
  const html = sanitizeHtml(description.replace(/\n/g, '<br>'), {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['center', 'h2', 'iframe', 'img']),
    allowedAttributes: {
      a: ['href', 'rel', 'target'],
      iframe: ['allow', 'allowfullscreen', 'frameborder', 'height', 'src', 'width'],
      img: ['height', 'src', 'width'],
    },
    transformTags: {
      a: sanitizeHtml.simpleTransform('a', {
        rel: 'noopener',
        target: '_blank',
      }),
    },
  });

  return <div className={styles.companyDescription} dangerouslySetInnerHTML={{ __html: html }} />;
}

function CompanyPerks({ perks }) {
  return (
    perks.length > 0 && (
      <div>
        <CompagePageSectionTitle title={'Benefits & Perks'} />
        <div className={styles.aboutUsContainer}>
          <ul className={styles.perksList}>
            {perks.map(perk => (
              <li key={perk}>{perk}</li>
            ))}
          </ul>
        </div>
      </div>
    )
  );
}

function CompanyNumbers({ companyNumbers }) {
  const convertToKey = (str: string) => str.slice(0, 10).toLowerCase().split(' ').join('');
  return (
    companyNumbers.length > 0 && (
      <div className={styles.aboutUsContainer}>
        <CompagePageSectionTitle title={'Numbers'} />
        {companyNumbers.map(companyNumber => (
          <div key={convertToKey(companyNumber)} className={styles.companyNumberItem}>
            <Icon name={'checkCircle'} color={'tuftsBlue'} />
            <div>{companyNumber}</div>
          </div>
        ))}
      </div>
    )
  );
}

function CompanyValues({ companyValues }) {
  return (
    companyValues.length > 0 && (
      <>
        <CompagePageSectionTitle title={'Values'} />
        <div className={styles.companyValuesSection}>
          {companyValues.map(companyValue => (
            <div key={companyValue} className={styles.companyValueItem}>
              <img src={SectionValuesImages[snakeCase(companyValue)]} />
              <span className={styles.companyValueText}>{companyValue}</span>
            </div>
          ))}
        </div>
      </>
    )
  );
}

function CompanyHistory({ milestones }) {
  return (
    milestones.length > 0 && (
      <div>
        <CompagePageSectionTitle title={'History'} />
        {milestones.map(milestone => (
          <HistoryItem key={milestone.id} milestone={milestone} />
        ))}
      </div>
    )
  );
}

function HistoryItem({ milestone }) {
  return (
    <div className={styles.aboutUsContainer}>
      <div className={styles.historyTitle}>{milestone.year}</div>
      <div className={styles.historyText}>{milestone.description}</div>
    </div>
  );
}
