import * as React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import LastArticlesContainer from './last_articles_container';

const containerProps = () => ({
  articles: [
    {
      id: '2',
      type: 'help_article',
      attributes: {
        title: 'Yep',
      },
    },
    {
      id: '3',
      type: 'help_article',
      attributes: {
        title: 'Le Article',
      },
    },
    {
      id: '4',
      type: 'help_article',
      attributes: {
        title: 'dfsg',
      },
    },
  ],
  baseArticleUrl: '/employer/help/~id',
});

let props;
let wrapped: ShallowWrapper;

describe('Last Articles Container component', () => {
  beforeEach(() => {
    props = containerProps();
    wrapped = shallow(<LastArticlesContainer {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});
