import * as React from 'react';
import Accordion from './accordion';
import { shallow } from 'enzyme';

describe('Accordion component', () => {
  it('shallow renders without crashing', () => {
    // eslint-disable-next-line react/no-children-prop
    const wrapped = shallow(<Accordion title='' children='' />);
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
