import * as React from 'react';
import './application_trail.scss';
const circleCheck = require('../../../../app/assets/images/iconic/circle-check.svg');
import InlineIcon from '../inline_icon/inline_icon';

interface Props {
  application: any;
  label: string;
}

// requires work
class ApplicationTrail extends React.Component<Props> {
  render() {
    const { person, step_label } = this.props.application.attributes;
    return (
      <div className='application-trail'>
        <InlineIcon className='avatar' path={circleCheck} />
        {/* <div className="avatar">
          {person.attributes.recipient_avatar ? (
            <div
              dangerouslySetInnerHTML={{
                __html: person.attributes.recipient_avatar,
              }}
            />
          ) : null}
        </div>
        <p>
          {person.attributes.name} has been successfully moved to{' '}
          <a>{this.props.label}</a>
        </p> */}
      </div>
    );
  }
}

export default ApplicationTrail;
