import React, { useRef } from 'react';
import Button from 'lj_shared/button/button';
import TransferToAnotherJobModal, {
  TransferMode,
} from 'components/general/transfer_to_another_job/transfer_to_another_job_modal';

export interface Props {
  candidateId: number;
  companyId: number;
}

export default function SuggestJobButton(props: Props) {
  const transferModalRef = useRef(null);
  const handleTransferModal = event => {
    event.preventDefault();
    transferModalRef.current.open();
  };

  return (
    <div>
      <Button
        isButton={true}
        buttonColor='ripePlum'
        buttonSize='xSmall'
        onClick={handleTransferModal}
        extraStyle={{ height: '2rem' }}
      >
        Suggest Job
      </Button>

      <TransferToAnotherJobModal
        canCloseCurrentApplication={false}
        candidateId={props.candidateId}
        companyId={props.companyId}
        isPairedWithAts={false}
        ref={transferModalRef}
        transferMode={TransferMode.CreateSuggestionWithoutApplication}
      />
    </div>
  );
}
