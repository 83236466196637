import * as React from 'react';
import PreferredLanguageProficiencyInput from './preferred_language_proficiency_input';
import { shallow, ShallowWrapper } from 'enzyme';

let wrapped: ShallowWrapper;
const preferredLanguageName = 'Dothraki';
const proficiencyLevels: {
  value: string;
  description: string;
}[] = [
  {
    value: '1',
    description: 'Just grunting',
  },
  {
    value: '2',
    description: 'Able to curse',
  },
  {
    value: '3',
    description: 'Native Dothraki',
  },
];

describe('Preferred Language Proficiency Input component', () => {
  beforeEach(() => {
    wrapped = shallow(
      <PreferredLanguageProficiencyInput
        preferredLanguageName={preferredLanguageName}
        proficiencyLevels={proficiencyLevels}
      />
    );
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('shallow renders help information', () => {
    expect(wrapped.find('.ld-form-help-text').text()).toBe(
      `This job requires knowledged in ${preferredLanguageName}, please select your proficiency level.`
    );
  });
});

test.todo('add more tests');
