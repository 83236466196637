import React from 'react';

export interface Context {
  errors: { [key: string]: string[] };
  formName: string;
  resource: any;
}

export const FormContext = React.createContext<Context>({
  errors: {},
  formName: '',
  resource: undefined,
});

interface Props {
  children: any;
  errors: { [key: string]: string[] };
  formName: string;
  resource: any;
}

export default function FormProvider(props: Props) {
  const value = {
    errors: props.errors,
    formName: props.formName,
    resource: props.resource,
  };

  return <FormContext.Provider value={value}>{props.children}</FormContext.Provider>;
}
