import 'rc-slider/assets/index.css';
import React from 'react';
import Slider from 'rc-slider';
import Icon, { Size } from 'components/general/icon/icon';
const styles = require('./bonus_slider.module.scss');
const Range = Slider.Range;
const foregroundColor = '#3bbcb0';
const backColor = '#bec2c5';

export interface Props {
  value: any;
  onChange: Function;
}

export default class BonusSlider extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  getMarks() {
    const marks = {
      1:
        this.props.value === 1
          ? {
              style: {
                color: foregroundColor,
                width: 'min-content',
              },
              label: 'Big Concern',
            }
          : '',
      2:
        this.props.value === 2
          ? {
              style: {
                color: foregroundColor,
              },
              label: 'Slight Concern',
            }
          : '',
      3:
        this.props.value === 3
          ? {
              style: {
                color: foregroundColor,
              },
              label: 'Neutral',
            }
          : '',
      4:
        this.props.value === 4
          ? {
              style: {
                color: foregroundColor,
              },
              label: 'Slight Bonus',
            }
          : '',
      5:
        this.props.value === 5
          ? {
              style: {
                color: foregroundColor,
              },
              label: 'Big Bonus',
            }
          : '',
    };
    return marks;
  }

  render() {
    return (
      <div className={styles.slider}>
        <Icon name='warning' size={Size.MediumBig} color='black' className={styles.icon} />
        <Range
          min={1}
          max={5}
          value={[this.props.value]}
          marks={this.getMarks()}
          step={null}
          onChange={this.props.onChange}
          handleStyle={[
            {
              backgroundColor: foregroundColor,
              borderColor: foregroundColor,
              boxShadowColor: foregroundColor,
            },
          ]}
          dotStyle={{
            backgroundColor: backColor,
            borderColor: backColor,
            boxShadowColor: backColor,
          }}
          railStyle={{ backgroundColor: backColor }}
        />
        <Icon name='star' size={Size.MediumBig} color='black' className={styles.icon} />
      </div>
    );
  }
}
