import * as React from 'react';
import ConversationSidebar, { Props } from './conversation_sidebar';
import { shallow, ShallowWrapper } from 'enzyme';
import messageableDummy from '__fixtures__/messageable_dummy';

const createTestProps = moreProps => {
  return {
    messageable: messageableDummy,
    notes_url: '',
    note_destroy_url: '',
    get_notes_url: '',
    userType: '',
    sidebarWidth: {},
    mini: false,
    ...moreProps,
  };
};

let props: Props;
let wrapped: ShallowWrapper;

describe('Conversation Sidebar component', () => {
  beforeEach(() => {
    props = createTestProps({});
    wrapped = shallow(<ConversationSidebar {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
