import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import SubscribeButton from './SubscribeButton';
import jobDummy from '__fixtures__/job_dummy';

window.Routes = { bookmark: jest.fn() };
const stopPropagation = jest.fn();

const createProps = () => ({
  currentUser: { id: 3, type: 'TalentUser' },
  job: { ...jobDummy, stateName: 'published' },
  bookmark: {
    created_at: '2022-09-27T09:46:41.966Z',
    id: 1,
    job_ad_id: jobDummy.id,
    updated_at: '2022-09-27T09:46:41.966Z',
    user_id: 3,
  },
});

let props;
let wrapped: ShallowWrapper;

describe('SubscribeButton render correctly', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<SubscribeButton {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it.skip('should handle button click', () => {
    const button = wrapped.find('Button');

    button.simulate('click', { stopPropagation });

    expect(stopPropagation).toHaveBeenCalledTimes(1);
  });

  it('should not create button if is not TalentUser', () => {
    wrapped.setProps({ currentUser: { type: 'CompanyUser' } });

    expect(wrapped.type()).toBe(null);
  });
});
