import * as React from 'react';
import ConversationHeader, { Props } from './conversation_header';
import { mount } from 'enzyme';
import messageableDummy from '__fixtures__/messageable_dummy';

const createTestProps = moreProps => {
  return {
    handleConversationClose: jest.fn(),
    handleExpandClick: jest.fn(),
    findConversationUrl: jest.fn(),
    messageable: messageableDummy,
    userType: '',
    mini: false,
    handleMinimizeClick: jest.fn(),
    ...moreProps,
  };
};

let props: Props;
let wrapped;

describe('Conversation Header component', () => {
  beforeAll(() => {
    props = createTestProps({});
    wrapped = mount(<ConversationHeader {...props} />);
  });

  it('renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('shows the recipient name', () => {
    expect(wrapped.find('h3').text()).toBe('Filipe Fernandes');
  });

  it('shows all application links', () => {
    expect(wrapped.find('.lj-conversation-headerContext > span > a')).toHaveLength(2);
  });

  it('shows the first application link', () => {
    expect(wrapped.find('.lj-conversation-headerContext > span > a').at(0).text()).toBe(
      'UX/UI Specialist'
    );
  });

  it('shows the second application link', () => {
    expect(wrapped.find('.lj-conversation-headerContext > span > a').at(1).text()).toBe(
      'Event Organizer Operations Intern'
    );
  });

  it('does not show a handshake tag', () => {
    expect(wrapped.find('.lj-chat-header-handshake-tag-container').children()).toHaveLength(0);
  });
});

test.todo('add more tests');
