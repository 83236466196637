import * as React from 'react';
import EmptyState from './empty_state';

import { shallow } from 'enzyme';

test('should render correctly', () => {
  const component = shallow(<EmptyState>Empty!</EmptyState>);

  expect(component).toMatchSnapshot();
});
