import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import 'lib/globals';
import Button from 'lj_shared/button/button';
import Modal from 'components/general/modal/modal';
import { apolloClient, gql } from 'lib/graphql';
import { handleRequestError } from 'lib/request';

const styles = require('./followers_modal.module.scss');

interface Props {
  jobId: string;
  modalRef: any;
  setJobsState: Function;
}

export default function FollowersModal(props: Props) {
  const { jobId, modalRef, setJobsState } = props;

  return (
    <Modal ref={modalRef} title='Followers'>
      <FollowersModalInner jobId={jobId} setJobsState={setJobsState} />
    </Modal>
  );
}

function FollowersModalInner({ jobId, setJobsState }) {
  const viewModel = followModalViewModel(jobId, setJobsState);
  const currentEmployeeId = viewModel.state.currentEmployeeId;

  return (
    <>
      <span className={styles.description}>Followers will get notifications for this job</span>
      {viewModel.state.employees.map(employee => (
        <div
          className={classNames(styles.row, {
            [styles.selfRow]: employee.id === currentEmployeeId,
          })}
          key={employee.id}
        >
          <img alt='avatar' className={styles.avatar} src={employee.avatarUrl} />
          <div className={styles.nameCell}>
            <span className={styles.name}>{employee.name}</span>
            <br />
            <span className={styles.role}>{employee.role}</span>
          </div>
          <div className={styles.buttonCell}>{renderToggleFollowButton(viewModel, employee)}</div>
        </div>
      ))}
    </>
  );
}
function renderToggleFollowButton(viewModel, employee) {
  const follow = employee.follow;

  if (follow) {
    return (
      <Button
        buttonColor='ripePlum'
        buttonSize='xSmall'
        buttonType='border'
        otherClasses={styles.destroyButton}
        onClick={viewModel.destroyFollow.bind(null, employee.id, follow.id)}
      >
        Remove
      </Button>
    );
  } else {
    return (
      <Button
        buttonColor='ripePlum'
        buttonSize='xSmall'
        otherClasses={styles.createButton}
        onClick={viewModel.createFollow.bind(null, employee.id)}
      >
        Add as follower
      </Button>
    );
  }
}

const GET_DATA_GQL = gql`
  query ($jobId: ID!) {
    currentCompany {
      employees {
        firstName
        id
        lastName
        roleLabel
        user {
          avatarUrl
        }
      }
    }
    currentEmployee {
      id
    }
    job {
      follows(id: $jobId) {
        employee {
          id
        }
        id
      }
    }
  }
`;

const CREATE_FOLLOW_GQL = gql`
  mutation ($employeeId: ID!, $jobId: ID!) {
    jobFollow {
      create(employeeId: $employeeId, jobId: $jobId) {
        errors {
          field
          message
        }
        resource {
          id
        }
      }
    }
  }
`;

const DESTROY_FOLLOW_GQL = gql`
  mutation ($id: ID!) {
    jobFollow {
      destroy(id: $id) {
        errors {
          field
          message
        }
      }
    }
  }
`;

function followModalViewModel(jobId, setJobsState) {
  const [state, setState] = useState({
    currentEmployeeId: undefined,
    employees: [],
    loading: true,
  });

  useEffect(() => {
    if (state.loading) loadData(setState, jobId).catch(handleRequestError);
  }, []);

  return {
    createFollow: createFollow.bind(null, state, setState, setJobsState, jobId),
    destroyFollow: destroyFollow.bind(null, state, setState, setJobsState, jobId),
    state,
  };
}

async function loadData(setState, jobId) {
  const { data } = await apolloClient.query({
    query: GET_DATA_GQL,
    variables: { jobId },
  });
  const currentEmployeeId = data.currentEmployee.id;
  const employees = data.currentCompany.employees.map(e => ({
    avatarUrl: e.user.avatarUrl,
    id: e.id,
    isCurrent: e.id === currentEmployeeId,
    name: `${e.firstName} ${e.lastName}`,
    follow: data.job.follows.find(f => f.employee.id === e.id),
    role: e.roleLabel,
  }));

  setState(state => ({
    ...state,
    currentEmployeeId,
    loading: false,
    employees: sortEmployees(employees),
  }));
}

async function createFollow(state, setState, setJobsState, jobId, employeeId) {
  const { data } = await apolloClient.mutate({
    mutation: CREATE_FOLLOW_GQL,
    variables: { employeeId, jobId },
  });
  const { errors, resource } = data.jobFollow.create;

  if (errors.length === 0) {
    window.Alerts.notice('Follower added');
    setState(curState => ({
      ...curState,
      employees: curState.employees.map(employee =>
        employee.id === employeeId ? { ...employee, follow: { id: resource.id } } : employee
      ),
    }));
    if (state.currentEmployeeId === employeeId) {
      setJobsState('add', jobId);
    }
  } else {
    window.Alerts.alert('Oops, something wrong happened.');
  }
}

async function destroyFollow(state, setState, setJobsState, jobId, employeeId, id) {
  const { data } = await apolloClient.mutate({
    mutation: DESTROY_FOLLOW_GQL,
    variables: { id },
  });
  const { errors } = data.jobFollow.destroy;
  if (errors.length === 0) {
    window.Alerts.notice('Follower removed');
    setState(curState => ({
      ...curState,
      employees: curState.employees.map(employee =>
        employee.follow?.id === id ? { ...employee, follow: undefined } : employee
      ),
    }));
    if (state.currentEmployeeId === employeeId) {
      setJobsState('remove', jobId);
    }
  } else {
    window.Alerts.alert('Oops, something wrong happened.');
  }
}

function sortEmployees(employees) {
  return orderBy(employees, ['isCurrent', 'name'], ['desc', 'asc']);
}
