import * as React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import RatingDimensionItem from './rating_dimension_item';
import Tooltip from 'components/general/tooltip/tooltip';

const createProps = (hasTooltip = false) => ({
  uniqueKey: 'overall_86605',
  name: 'overall',
  title: 'Overall',
  value: 5,
  maxRating: 5,
  notes: hasTooltip && ['this is a tooltip'],
  hasTooltip,
});

let props;
let wrapped: ShallowWrapper;

describe('Rating Container component (without tooltip)', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<RatingDimensionItem {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('has no tooltips', () => {
    expect(wrapped.find(Tooltip)).toHaveLength(0);
  });
});

describe('Rating Container component (with tooltip)', () => {
  beforeEach(() => {
    props = createProps(true);
    wrapped = shallow(<RatingDimensionItem {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('renders tooltip', () => {
    expect(wrapped.find(Tooltip)).toHaveLength(1);
  });
});
