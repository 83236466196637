import * as React from 'react';
import { mount } from 'enzyme';
import Sidebar, { Props } from './sidebar';
import { SidebarFormContainer } from './sidebarForm';
import curatorsUrlsDummy from '__fixtures__/curators_urls_dummy';
import curatorsApplicationDummy from '__fixtures__/curators_application_dummy';
import curatorsTalentUserDummy from '__fixtures__/curators_talent_user_dummy';

const createTestProps = moreProps => {
  return {
    applicationId: '1',
    concerns: [
      ['location', 0],
      ['language', 1],
      ['skills', 2],
      ['experience_level', 3],
      ['lack_of_information', 4],
    ],
    googleMapsApiKey: '',
    isLegacyJob: false,
    setCurrentDimension: jest.fn(),
    urls: curatorsUrlsDummy,
    isCurated: false,
    curator: '',
    ...moreProps,
  };
};

let props: Props;
let wrapped;

describe('Sidebar component', () => {
  beforeAll(() => {
    props = createTestProps({});

    wrapped = mount(
      <SidebarFormContainer.Provider
        initialState={{
          application: curatorsApplicationDummy.data.attributes,
          talentUser: curatorsTalentUserDummy.data.attributes,
        }}
      >
        <Sidebar {...props} />
      </SidebarFormContainer.Provider>
    );
  });

  it('renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

describe('Application is already curated', () => {
  beforeAll(() => {
    props = createTestProps({ isCurated: true, curator: 'Matheus Ortler' });

    wrapped = mount(
      <SidebarFormContainer.Provider
        initialState={{
          application: curatorsApplicationDummy.data.attributes,
          talentUser: curatorsTalentUserDummy.data.attributes,
        }}
      >
        <Sidebar {...props} />
      </SidebarFormContainer.Provider>
    );
  });

  it('shows state screened by landing', () => {
    expect(wrapped.find('.stateBadge').text()).toBe('Screened by Landing');
  });

  it('shows curator name', () => {
    expect(wrapped.find('.curatorName').text()).toBe('Curator: Matheus Ortler');
  });
});
