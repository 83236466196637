import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import RejectHandshakeModal from './reject_handshake_modal';
import Modal from 'components/general/modal/modal';
import Textarea from 'components/form/textarea/textarea';

let props;
let wrapped: ShallowWrapper;

window.Routes = {
  job_ad: () => '',
  reject_dashboard_handshake: () => '',
};

const createProps = () => {
  return {
    handshake: jest.fn(),
    companyName: 'Dr.Oak Industries',
    jobAd: { title: 'Pokemon Master', id: 177013 },
    setIsModalOpen: jest.fn(),
    hasFollowUpModal: true,
  };
};

describe('RejectHandshakeModal', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<RejectHandshakeModal {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('contains a Modal', () => {
    expect(wrapped.find(Modal).length).toEqual(1);
  });

  it('contains a text area', () => {
    expect(wrapped.find(Textarea).length).toEqual(1);
  });

  it('contains a link', () => {
    expect(wrapped.find('a').length).toEqual(1);
  });
});
