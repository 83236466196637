import { patchJSON, handleRequestError } from '../../lib/request_deprecated';
import { Skill, Citizenship } from '__models__/models';
import { Cv } from '__models__/cv';
const Settings = require('settings.json');

const REMOTE_POLICIES_IDS = [2, 3, 5];
const JOB_TYPES_IDS = [0, 1];

class ProfileForm {
  availability: any;
  avatar: string;
  birthYear: number;
  categories: { id: number; value: number; label: string }[];
  citizenships: Citizenship[];
  city: string;
  commsConsent: boolean;
  contactPreference: number;
  contactPreferenceOther: string;
  country: string;
  countryName: string;
  cvs: Cv[];
  cvFileName: string;
  description: string;
  email: string;
  errors: any;
  experienceYears?: { label: string; value: number };
  firstName: string;
  googlePlaceId: string;
  headline: string;
  lastName?: string;
  listOfLinks: { category: string; id: number; type: string; url: string }[];
  latitude: string;
  location: string;
  longitude: string;
  maximumRate?: number;
  minimumRate?: number;
  maximumGAS?: number;
  minimumGAS?: number;
  networks: { id: string; value: string; label: string }[];
  personId: number;
  personLanguages: Skill[];
  phoneNumber: string;
  remote: number;
  saved: boolean;
  shareProfile: number;
  skills: any;
  skypeId?: string;
  startYear?: { label: number; value: number };
  freelance: boolean;
  relocation: boolean;
  relocationCountries: { value: string; label: string }[];
  url: string;
  jobTypesInterest: { id: number; label: string }[];

  constructor(args) {
    Object.keys(args).map(k => {
      this[k] = args[k];
    });

    this.errors = {};
    this.saved = false;
  }

  update(args) {
    if (!this.errors) {
      this.errors = {};
    } else {
      Object.keys(args).forEach(key => {
        delete this.errors[key];
      });
    }

    Object.keys(args).map(key => {
      this[key] = args[key];
    });
  }

  validate(step, forceNameValidation = false) {
    this.errors = {};

    if (step === 1) {
      [
        'birthYear',
        'citizenships',
        'description',
        'location',
        'personLanguages',
        'phoneNumber',
        'shareProfile',
      ].forEach(context => {
        this.validate_mandatory(context);
      });
    }
    if (step === 2) {
      ['availability', 'categories', 'cvFileName', 'headline', 'listOfLinks', 'skills'].forEach(
        context => {
          this.validate_mandatory(context);
        }
      );
    }
    if (step === 'intro') {
      [
        'availability',
        'description',
        'firstName',
        'headline',
        'lastName',
        'location',
        'skills',
        'startYear',
      ].forEach(context => {
        this.validate_mandatory(context);
      });
    }
    if (step === 'about_yourself') {
      [
        'availability',
        'birthYear',
        'citizenships',
        'experienceYears',
        'personLanguages',
        'phoneNumber',
        'rate',
      ].forEach(context => {
        this.validate_mandatory(context);
      });
    }
    if (step === 'about_yourself_lj') {
      ['citizenships', 'contactPreference'].forEach(context => {
        this.validate_mandatory(context);
      });
    }
    if (step === 'job_interests') {
      ['categories', 'jobTypesInterest'].forEach(context => {
        this.validate_mandatory(context);
      });
    }

    if (step === 'registrationPage') {
      // missing job interests
      let fields = [
        'availability',
        'location',
        'citizenships',
        'jobTypesInterest',
        'categories',
        'skills',
        'shareProfile',
        'startYear',
      ];
      fields = fields.concat(forceNameValidation ? ['firstName', 'lastName'] : []);

      fields.forEach(context => {
        this.validate_mandatory(context);
      });
    }

    if (Object.keys(this.errors).length > 0) {
      return false;
    } else {
      return true;
    }
  }

  validate_mandatory(context) {
    // Individual validations
    if (context === 'birthYear' && !this.birthYear) {
      this.errors['birthYear'] = 'Please select your year of birth.';
    }

    if (context === 'phoneNumber' && this.phoneNumber.length < 6) {
      this.errors['phoneNumber'] = 'Phone number is required';
    }

    if (context === 'location' && !this.location) {
      this.errors['location'] = 'Please choose a valid location';
    }

    if (
      context === 'citizenships' &&
      (this.citizenships === null || this.citizenships.length === 0)
    ) {
      this.errors['citizenships'] = 'Please select your citizenship(s)';
    }

    if (context === 'description') {
      if (
        this.description.length >
        parseInt(`${Settings.textarea.talentProfileDescription.length}`, 10)
      ) {
        this.errors[
          'description'
        ] = `Please keep your description under ${Settings.textarea.talentProfileDescription.length} chars.`;
      }
    }

    if (context === 'headline') {
      if (
        this.headline.length > parseInt(`${Settings.textarea.talentProfileHeadline.length}`, 10)
      ) {
        this.errors[
          'headline'
        ] = `Please keep your headline under ${Settings.textarea.talentProfileHeadline.length} chars.`;
      }
    }

    if (context === 'firstName' && !this.firstName) {
      this.errors['firstName'] = 'Please add your first name';
    }

    if (context === 'lastName' && !this.lastName) {
      this.errors['lastName'] = 'Please add your last name';
    }

    if (context === 'categories') {
      if (this.categories === null || this.categories.length === 0) {
        this.errors['categories'] = 'Please choose at least one area of interest';
      } else if (this.categories.length > 3) {
        this.errors['categories'] = 'Please choose at most 3 areas of interest';
      }
    }

    if (context === 'availability' && this.availability.id === '') {
      this.errors['availability'] = 'Please select your current employment status';
    }

    if (context === 'experienceYears' && !this.experienceYears.value) {
      this.errors['experienceYears'] = 'Please add your years of experience';
    }

    if (context === 'startYear' && !this.startYear.value) {
      this.errors['startYear'] = 'Please select the year you started work in tech';
    }

    if (context === 'rate' && !this.minimumRate && !this.maximumRate) {
      this.errors['rate'] = 'Please add your minimum and maximum rate';
    }

    if (context === 'shareProfile' && this.shareProfile === null) {
      this.errors['shareProfile'] = 'Please select an option';
    }

    if (context === 'cvFileName' && this.cvFileName === '') {
      this.errors['cvFileName'] = 'Please add your CV';
    }

    if (context === 'personLanguages' && this.personLanguages.length === 0) {
      this.errors['personLanguages'] = 'Please add a language';
    }

    if (context === 'skills' && this.skills.length < 2) {
      this.errors['skills'] = 'Please add at least two skills';
    }

    if (context === 'listOfLinks' && this.listOfLinks.length === 0) {
      this.errors['listOfLinks'] = 'Please add a link';
    }

    if (context === 'jobTypesInterest') {
      if (this.jobTypesInterest.filter(jt => REMOTE_POLICIES_IDS.includes(jt.id)).length === 0) {
        this.errors['jobTypesInterest'] = {};

        this.errors['jobTypesInterest']['remotePolicy'] =
          'Please select at least one remote policy';
      }

      if (this.jobTypesInterest.filter(jt => JOB_TYPES_IDS.includes(jt.id)).length === 0) {
        if (!this.errors.hasOwnProperty('jobTypesInterest')) {
          this.errors['jobTypesInterest'] = {};
        }
        this.errors['jobTypesInterest']['jobType'] = 'Please select at least one job interest';
      }

      if (this.categories == null || this.categories.length === 0) {
        this.errors['categories'] = 'Please select at least one area of interest';
      } else if (this.categories.length > 3) {
        this.errors['categories'] = 'Please choose at most 3 areas of interest';
      }
    }

    if (
      context === 'contactPreference' &&
      this.contactPreference === Settings.person.contactPreference.otherOption.id
    ) {
      if (!this.contactPreferenceOther) {
        this.errors['contactPreference'] = 'Please add an alternative contact preference';
      } else if (
        this.contactPreferenceOther.length > Settings.person.contactPreference.otherOption.length
      ) {
        this.errors['contactPreference'] =
          'Please add an alternative contact preference with less than 40 characters';
      }
    }

    if (Object.keys(this.errors).length > 0) {
      return false;
    } else {
      return true;
    }
  }

  async save(userType = null, updateCompleteness?: Function, step = null) {
    const promise = new Promise((resolve, reject) => {
      patchJSON(this.url, this.serialize(userType, step))
        .then(response => {
          if (response.notice) {
            window.Alerts && window.Alerts.notice(response.notice);
            if (response.completeness && updateCompleteness) {
              updateCompleteness(response.completeness);
            }
            resolve(true);
          } else if (response.alert) {
            window.Alerts && window.Alerts.alert(response.alert);
          }
        })
        .catch(e => {
          if (e.alert) {
            window.Alerts && window.Alerts.alert(e.alert);
          }
          handleRequestError(e);
        });
    });
    const result = await promise;
    return result;
  }

  serialize(userType, step: string) {
    if (userType === 'lj') {
      return this.serializeLandingJobsUser(step);
    } else {
      return this.serializeLandingWorkUser();
    }
  }

  serializeLandingWorkUser() {
    return {
      profile_form: {
        bio: this.description,
        birth_year: this.birthYear ? this.birthYear : null,
        categories: this.categories.map(c => c.id),
        citizenships: this.citizenships.map(c => c.value),
        city: this.city,
        contact_preference: this.contactPreference,
        country_code: this.country,
        experience_level: this.experienceYears && this.experienceYears.value,
        first_name: this.firstName,
        google_place_id: this.googlePlaceId,
        headline: this.headline,
        last_name: this.lastName,
        location: this.location,
        maximum_rate: this.maximumRate,
        minimum_rate: this.minimumRate,
        networks: this.networks,
        phone_number: this.phoneNumber,
        skype_id: this.skypeId,
        work_availability: this.availability.value,
      },
    };
  }

  serializeLandingJobsUser(step: string) {
    const profile = {
      profile_form: {
        availability:
          this.availability.value !== undefined ? this.availability.value : this.availability,
        bio: this.description,
        birth_year: this.birthYear ? this.birthYear : null,
        citizenships: this.citizenships ? this.citizenships.map(c => c.value) : [],
        city: this.city,
        comms_consent: this.commsConsent,
        country_code: this.country,
        contact_preference: this.contactPreference,
        contact_preference_other: this.contactPreferenceOther,
        experience_level: this.experienceYears && this.experienceYears.value,
        start_year: this.startYear && this.startYear.value,
        first_name: this.firstName,
        headline: this.headline,
        last_name: this.lastName,
        latitude: this.latitude,
        location: this.location,
        longitude: this.longitude,
        maximum_rate: this.maximumRate,
        minimum_rate: this.minimumRate,
        maximum_gas: this.maximumGAS,
        minimum_gas: this.minimumGAS,
        networks: this.networks,
        phone_number: this.phoneNumber,
        share_profile: this.shareProfile,
        skype_id: this.skypeId,
        work_availability: this.availability.value,
        relocation: this.relocation,
        relocation_countries: this.relocationCountries
          ? this.relocationCountries.map(c => c.value)
          : [],
        categories: this.categories ? this.categories.map(c => c.id) : [],
        job_types_interest: this.jobTypesInterest ? this.jobTypesInterest.map(j => j.id) : [],
      },
      update_context: step,
    };
    return profile;
  }
}

export default ProfileForm;
