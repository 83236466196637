import * as React from 'react';
import TextInput from './text';
import FormError from '../form_error/form_error';

import { shallow } from 'enzyme';
import { render, fireEvent } from '@testing-library/react';

describe('<TextInput />', () => {
  it('should render correctly with basic props', () => {
    const component = shallow(<TextInput name='test-textarea' color='ripePlum' />);
    expect(component).toMatchSnapshot();
  });

  describe('Input rendering', () => {
    const input = shallow(
      <TextInput
        name='test-textarea'
        color='ripePlum'
        label='This is a label'
        note='This is a note'
        error='This is an error'
      />
    );

    test('Renders label', () => {
      const expectedOutput = '<label class="label">This is a label</label>';
      const output = input.find('label').html();

      expect(output).toEqual(expectedOutput);
    });

    it('Renders note', () => {
      const expectedOutput = '<small class="small">This is a note</small>';
      const output = input.find('small').html();

      expect(output).toEqual(expectedOutput);
    });

    it('Renders error', () => {
      expect(input.find(FormError).length).toEqual(1);
    });

    it('Render a text input', () => {
      const expectedOutput = '<input class="input" name="test-textarea"/>';
      const output = input.find('input').html();

      expect(output).toEqual(expectedOutput);
    });
  });

  it('Renders a textarea', () => {
    const component = shallow(<TextInput name='test-textarea' color='ripePlum' textarea={true} />);
    expect(component.find('textarea[rows=5]')).toHaveLength(1);
    expect(component.find('textarea[name="test-textarea"]')).toHaveLength(1);
  });

  it('Renders a textarea with correct rows', () => {
    const component = shallow(
      <TextInput name='test-textarea' color='ripePlum' textarea={true} rows={4} />
    );

    expect(component.find('textarea[rows=4]')).toHaveLength(1);
    expect(component.find('textarea[name="test-textarea"]')).toHaveLength(1);
  });

  it('Renders when required', () => {
    const component = shallow(
      <TextInput
        name='test-textarea'
        color='ripePlum'
        required={true}
        label='This is a required label'
      />
    );

    const expectedOutput =
      '<label class="label">This is a required label' +
      '<span class="required"> *</span>' +
      '</label>';
    const output = component.find('label').html();

    expect(output).toEqual(expectedOutput);
  });

  it('Calls the onChange prop when changed', () => {
    let callCount = 0;
    let theEvent;
    const handleChange = event => {
      theEvent = event;
      callCount++;
    };
    const initialValue = 'foo';
    const { getByDisplayValue } = render(
      <TextInput onChange={handleChange} value={initialValue} />
    );
    const input = getByDisplayValue(initialValue) as HTMLInputElement;
    const newValue = 'bar';

    fireEvent.change(input, { target: { value: newValue } });
    expect(callCount).toBe(1);
    expect(theEvent).toBeTruthy();
  });
});
