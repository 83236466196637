import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
const styles = require('./Dropdown.module.scss');

type dropDownOption = {
  value: string;
  label: string;
};

interface Props {
  placeHolder: string;
  options: dropDownOption[];
  selectedData?: dropDownOption[];
  onChange: (newValue: dropDownOption[]) => void;
}

const Dropdown = ({ placeHolder, options, selectedData, onChange }: Props) => {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedValue, setSelectedValue] = useState<Array<dropDownOption>>(selectedData || []);
  const [searchValue, setSearchValue] = useState('');
  const searchRef: React.RefObject<HTMLInputElement> = useRef();
  const inputRef: React.RefObject<HTMLInputElement> = useRef();

  useEffect(() => {
    setSearchValue('');
    if (showMenu && searchRef.current) {
      searchRef.current.focus();
    }
  }, [showMenu]);

  useEffect(() => {
    const handler = e => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    window.addEventListener('click', handler);
    return () => {
      window.removeEventListener('click', handler);
    };
  });

  const handleInputClick = () => {
    setShowMenu(!showMenu);
  };

  const getDisplay = () => (
    <div className={styles.dropdownTags}>
      {selectedValue.map(option => (
        <div key={option.value} className={styles.dropdownTagItem}>
          <span className={styles.dropdownTagLabel}>{option.label}</span>
          <span onClick={e => onTagRemove(e, option)} className={styles.dropdownTagClose} />
        </div>
      ))}
    </div>
  );

  const removeOption = (option: dropDownOption) =>
    selectedValue.filter(o => o.value !== option.value);

  const onTagRemove = (e, option: dropDownOption) => {
    e.stopPropagation();
    const newValue = removeOption(option);
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const onItemClick = (option: dropDownOption) => {
    let newValue: React.SetStateAction<dropDownOption[]>;

    if (selectedValue.findIndex(o => o.value === option.value) >= 0) {
      newValue = removeOption(option);
    } else {
      newValue = [...selectedValue, option];
    }
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const isSelected = (option: dropDownOption) =>
    selectedValue.filter(o => o.value === option.value).length > 0;

  const onSearch = (e: { target: { value: React.SetStateAction<string> } }) =>
    setSearchValue(e.target.value);

  const getOptions = () => {
    if (!searchValue) {
      return options;
    }

    return options.filter(
      option => option.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    );
  };

  return (
    <>
      <div className={styles.dropdownContainer}>
        <div
          ref={inputRef}
          onClick={handleInputClick}
          className={styles.searchBox}
          data-testid='search-input'
        >
          <input
            onChange={onSearch}
            value={searchValue}
            ref={searchRef}
            placeholder={placeHolder}
          />
          <span className={styles.chevron} />
        </div>
        {showMenu && options.length && (
          <div className={styles.dropdownMenu} data-testid='dropdown'>
            {getOptions().map(
              option =>
                !isSelected(option) && (
                  <div
                    onClick={() => onItemClick(option)}
                    key={option.value}
                    className={classNames(styles.dropdownItem)}
                  >
                    {option.label}
                  </div>
                )
            )}
          </div>
        )}
      </div>
      <div className={styles.dropdownSelectedValue}>{getDisplay()}</div>
    </>
  );
};

export default Dropdown;
