import * as React from 'react';
import showdown from 'showdown';
import '../../../lib/globals';

import * as Models from './models';
import Message from './message';

export interface MessageListProps {
  messageable: Models.Messageable;
  messages: Models.Message[];
  userType: string;
  currentUserId: number;
}

class MessagesList extends React.Component<MessageListProps> {
  private messagesEnd: React.RefObject<HTMLDivElement>;

  constructor(props: MessageListProps) {
    super(props);
    this.messagesEnd = React.createRef();
  }

  componentDidMount() {
    this.scrollToBottom();
  }
  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom() {
    if (this.messagesEnd.current && this.messagesAreFromConversation()) {
      this.messagesEnd.current.scrollIntoView();
    }
  }

  messagesAreFromConversation() {
    return (
      this.props.messages &&
      this.props.messages[0].attributes.conversation_id === Number(this.props.messageable.id)
    );
  }

  render() {
    if (!this.messagesAreFromConversation()) {
      return (
        <div className='lj-messages-list'>
          <div className='lj-grey-spinner'>
            <div className='ld-loading-animation u-display--block'>
              <span className='ld-dotcon'>
                <div className='ld-dot' />
              </span>
              <span className='ld-dotcon'>
                <div className='ld-dot' />
              </span>
              <span className='ld-dotcon'>
                <div className='ld-dot' />
              </span>
            </div>
          </div>
        </div>
      );
    }

    const { messageable, userType } = this.props;
    const converter = new showdown.Converter();
    converter.setOption('simpleLineBreaks', true);
    converter.setOption('strikethrough', true);
    const messages = this.props.messages.sort((a, b) => {
      return Date.parse(a.attributes.created_at) - Date.parse(b.attributes.created_at);
    });
    const readMessages: any = messages.filter(m => {
      return m.attributes.sender_type === userType && m.attributes.read;
    });
    const lastReadIndex = messages.indexOf(readMessages[readMessages.length - 1]);

    return (
      <div className='lj-messages-list'>
        {messages.map((message, index) => {
          let showAvatar;
          let d1;
          let d2;

          if (index !== 0) {
            d1 = Number(
              Intl.DateTimeFormat('en-US', { day: 'numeric' }).format(
                Date.parse(message.attributes.created_at)
              )
            );
            d2 = Number(
              Intl.DateTimeFormat('en-US', { day: 'numeric' }).format(
                Date.parse(messages[index - 1].attributes.created_at)
              )
            );
          }

          if (index !== messages.length - 1) {
            showAvatar =
              message.attributes.sender_type !== userType &&
              messages[index + 1].attributes.sender_type === userType;
          }

          if (index === 0 || d1 > d2) {
            return (
              <div key={'message_container' + message.id}>
                <div key={'day_' + message.id} className='date-separator'>
                  {Intl.DateTimeFormat('en-US', {
                    day: 'numeric',
                    month: 'short',
                  }).format(Date.parse(message.attributes.created_at))}
                </div>
                <Message
                  showAvatar={showAvatar}
                  key={message.id}
                  image={messageable.attributes.recipient_avatar}
                  message={message}
                  converter={converter}
                  sender={message.attributes.sender_type === userType}
                  lastMessageSeen={index === lastReadIndex}
                />
              </div>
            );
          } else {
            return (
              <div key={'message_container' + message.id}>
                <Message
                  showAvatar={showAvatar}
                  key={message.id}
                  image={messageable.attributes.recipient_avatar}
                  message={message}
                  converter={converter}
                  sender={message.attributes.sender_type === userType}
                  lastMessageSeen={index === lastReadIndex}
                />
              </div>
            );
          }
        })}
        <div style={{ float: 'left', clear: 'both' }} ref={this.messagesEnd} />
        <div className='fade-to-white' />
      </div>
    );
  }
}

export default MessagesList;
