import React from 'react';
import { deleteJSON, handleRequestError, handleSuccessAlert } from 'lib/request_deprecated';
import DotLoader from 'lj_shared/dot_loader/dot_loader';
import { Cv } from '__models__/cv';
import Sortable from 'react-sortablejs';
import dateFormat from 'lib/format_date';
import Icon from '../icon/icon';
import Tooltip from '../tooltip/tooltip';
import { RadioButton } from 'components/form/radio_buttons/radio_buttons';

const styles = require('./cv_uploader.module.scss');

interface Props {
  cvs?: Cv[];
  handleChangeCV: any;
  kind?: string;
  replace?: boolean;
  selectedCv?: Cv;
}
interface State {
  error: string;
  loading: boolean;
}

class CVUploader extends React.Component<Props, State> {
  state: State = {
    error: '',
    loading: false,
  };

  handleChange = event => {
    const file = event.target.files[0];
    const postUrl = window.Routes.resume_index();

    if (this.validateBeforeUpload(file)) {
      this.setState({ loading: true });
      const formData = new FormData();
      formData.append('cv[file]', file);
      formData.append('name', event.target.name);
      this.props.replace && formData.append('replace', 'true');

      postData(postUrl, formData)
        .then(response => {
          handleSuccessAlert(response);

          this.setState({ loading: false });
          this.props.handleChangeCV(response.cv, 'post');
        })
        .catch(error => {
          handleRequestError(error);
          this.setState({ loading: false });
        });
    }
  };

  validateBeforeUpload = file => {
    const types = /(\.|\/)pdf$/i;

    if (!types.test(file.type) || !types.test(file.name)) {
      this.setState({ error: 'PDF only' });
      return false;
    } else if (file.size > 10485760) {
      this.setState({ error: 'Maximum CV size is 10 Mb.' });
      return false;
    } else {
      this.setState({ error: '' });
      return true;
    }
  };

  handleDelete = (cv: Cv) => {
    const id = cv.standard ? 0 : cv.id;
    const deleteUrl = window.Routes.resume(id);

    deleteJSON(deleteUrl)
      .then(response => {
        if (response.success) {
          handleSuccessAlert(response);
          this.props.handleChangeCV(cv, 'delete');
        }
      })
      .catch(error => {
        handleRequestError(error);
      });
  };

  handleClick = (cv: Cv) => {
    const id = cv.standard ? 0 : cv.id;
    window.open(window.Routes.resume(id));
  };

  renderPDFInfo = () => {
    return (
      <div className={styles.pdfInfo}>
        {
          <strong>
            Allowed file types: .pdf
            <br />
            Max. file size: 10 MB
          </strong>
        }
      </div>
    );
  };

  renderSpinner = () => {
    const dotLoaderStyle = {
      minHeight: '0',
      position: 'absolute',
      bottom: '65px',
      left: '102px',
    };

    return (
      <div className={styles.spinnerWrapper}>
        Loading
        <span>
          <DotLoader style={dotLoaderStyle} />
        </span>
      </div>
    );
  };

  renderCvs = () => {
    const standardCv = this.props.cvs.find(c => c.standard);
    const otherCvs = this.props.cvs.filter(c => !c.standard);
    const sortableOptions = {
      animation: 150,
      ghostClass: 'ld-drag-ghost',
      chosenClass: 'ld-drag-selected',
      dataIdAttr: 'skill-id',
      group: { name: 'shared' },
      sort: false,
    };

    const renderAllCvs = () => {
      if (this.props.cvs.length === 0) {
        return <div className={styles.cvEmpty} />;
      }

      return this.props.cvs.map(cv => {
        return (
          <RadioButton
            key={cv.id}
            option={{ value: cv.id, label: '' }}
            selectedValue={this.props.selectedCv?.id}
            onChange={this.props.handleChangeCV}
            color={'tuftsBlue'}
            styles={{ checkmark: { top: '1.125rem' } }}
            name='select'
          >
            <div className={cv.standard ? styles.standard : styles.other}>
              {this.renderCvItem(cv)}
            </div>
          </RadioButton>
        );
      });
    };

    const renderStandardCv = () => {
      const placeholderClass =
        this.props.replace || this.props.kind === 'jobs' ? styles.cvEmpty : styles.cvsEmpty;

      return (
        <div className={styles.standard}>
          {!this.props.replace && (
            <div className={styles.standardHeader}>
              <span>Standard CV</span>
              <Tooltip text='This will be your default CV and the one shown on your profile'>
                <Icon name='helpCircle' />
              </Tooltip>
            </div>
          )}
          <Sortable
            options={sortableOptions}
            onChange={items => {
              this.props.handleChangeCV(
                items.map(id => +id),
                'standard'
              );
            }}
            className={
              standardCv
                ? styles.cvsList
                : otherCvs && otherCvs.length > 0
                ? styles.standardCvEmpty
                : placeholderClass
            }
            tag='ol'
          >
            {standardCv && this.renderCvItem(standardCv)}
          </Sortable>
        </div>
      );
    };

    const renderOtherCvs = () => {
      return (
        <div className={styles.other}>
          <div className={styles.otherHeader}>
            <span>Other CV's</span>
            <Tooltip text='These CV’s can be used for specific applications'>
              <Icon name='helpCircle' />
            </Tooltip>
          </div>
          <Sortable
            options={sortableOptions}
            onChange={items => {
              this.props.handleChangeCV(
                items.map(id => parseInt(id, 10)),
                'other'
              );
            }}
            className={otherCvs && otherCvs.length > 0 ? styles.cvsList : styles.otherCvsEmpty}
            tag='ol'
          >
            {this.props.cvs
              .filter(c => !c.standard)
              .map((cv: Cv) => {
                return this.renderCvItem(cv);
              })}
          </Sortable>
        </div>
      );
    };

    if (this.props.kind === 'jobs') {
      return renderAllCvs();
    }

    return (
      <>
        {renderStandardCv()}
        {!this.props.replace &&
          this.props.kind !== 'jobs' &&
          (standardCv || otherCvs.length > 0) &&
          renderOtherCvs()}
      </>
    );
  };

  renderCvItem(cv: Cv) {
    return (
      // eslint-disable-next-line react/no-unknown-property
      <li skill-id={cv.id} key={cv.id} className={styles.skill}>
        <a className={styles.close} onClick={() => this.handleDelete(cv)} />
        <div className={styles.skillName} onClick={() => this.handleClick(cv)}>
          {cv.cvFileName}
        </div>
        <div className={styles.date}>Updated: {dateFormat(cv.updatedAt)}</div>
      </li>
    );
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.buttonWrapper}>
          <label className={styles.button} htmlFor='cv_attachment'>
            <div className={styles.uploadButton}>
              {this.state.loading ? this.renderSpinner() : 'ADD A CV FILE'}
            </div>
          </label>
          <input
            type='file'
            name='cv_attachment'
            id='cv_attachment'
            onChange={this.handleChange}
            onClick={event => ((event.target as HTMLInputElement).value = '')}
          />
          <div>
            {this.state.error && <div className={styles.mandatory}>{this.state.error}</div>}
          </div>
          {this.renderPDFInfo()}
        </div>
        {this.renderCvs()}
      </div>
    );
  }
}

const postData = async (url, data) => {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'X-CSRF-Token': getCSRFToken(),
    },
    body: data,
  });
  return response.json();
};

const getCSRFToken = () => {
  const element = document.querySelector('meta[name=csrf-token]');
  const token = element && element.getAttribute('content');
  return token;
};

export default CVUploader;
