import classNames from 'classnames';
import * as React from 'react';
import Button from 'lj_shared/button/button';

export interface Props {
  application?: any;
  buttonClasses?: string;
  className?: string;
  delivered: boolean;
  hideMoveToStep?: boolean;
  hideReject?: boolean;
  moveToStep: any;
  rejectApplication: any;
  small: boolean;
  stopPropagation?: boolean;
  waitingReview: boolean;
}

export default function ApplicationStateButtons(props: Props) {
  const {
    buttonClasses,
    className,
    delivered,
    hideMoveToStep,
    hideReject,
    moveToStep,
    rejectApplication,
    small,
    waitingReview,
    application,
    stopPropagation,
  } = props;

  const handleMoveToStep = event => {
    if (stopPropagation) event.stopPropagation();
    moveToStep.call(null, null, application);
  };

  const handleRejectionClick = event => {
    if (stopPropagation) event.stopPropagation();
    rejectApplication();
  };

  if (delivered || waitingReview) {
    const otherClass = small ? 'lj-button--keepSmall' : '';
    const state = application && application.attributes.current_state;

    const currentStep =
      application &&
      application.attributes.hiring_steps.find((hiringStep: any) => hiringStep.value === state);
    const step = currentStep && application.attributes.hiring_steps[currentStep.number];

    let label;
    if (waitingReview) {
      label = 'Move to in process';
    } else if (state === 'state-offer_made') {
      label = 'Mark as hired';
    } else if (step && step.value === 'state-offer_made') {
      label = 'Move to Proposal';
    } else {
      label = 'Move to next step';
    }

    return (
      <div className={classNames('lj-button-group', className)}>
        {!hideMoveToStep && (
          <Button
            isButton={true}
            buttonColor='ripePlum'
            onClick={handleMoveToStep}
            otherClasses={classNames(otherClass, buttonClasses)}
            buttonSize='small'
          >
            {label}
          </Button>
        )}
        {!hideReject && (
          <Button
            isButton={true}
            buttonColor='silverSand'
            onClick={handleRejectionClick}
            otherClasses={classNames(otherClass, buttonClasses)}
            buttonType='border'
            buttonSize='xSmall'
          >
            Reject
          </Button>
        )}
      </div>
    );
  } else {
    return null;
  }
}
