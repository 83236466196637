import classNames from 'classnames';
import * as React from 'react';
const styles = require('./progress_bar.module.scss');

export interface Props {
  className?: string;
  max: number;
  type?: string;
  value: number;
  ratings?: any;
  dimensions?: any;
  changeStep?: Function;
}

export default function ProgressBar(props: Props) {
  return props.type === 'boxes' ? renderBoxes(props) : renderContinuous(props);
}

function renderContinuous(props) {
  const { className, value, max } = props;

  return (
    <div className={classNames(styles.container, className)}>
      <div className={classNames(styles.interval, styles.filled)}>&nbsp;</div>
      <div className={classNames(styles.interval, styles.empty)}>&nbsp;</div>
    </div>
  );
}

function renderBoxes(props) {
  const { className, value, max, ratings, dimensions, changeStep } = props;

  return (
    <div className={classNames(styles.container, className)}>
      {Array(max)
        .fill(undefined)
        .map((_, i) => renderBox(value, i, ratings, dimensions, changeStep))}
    </div>
  );
}

function renderBox(value, current, ratings, dimensions, changeStep) {
  const state = current < value ? 'filled' : 'empty';
  const className = classNames(styles.box, styles[state]);
  const dimension = dimensions[current].name;
  const rating = ratings[dimension];
  return (
    <div
      className={className}
      key={`${value};${current}`}
      onClick={current <= value - 1 ? event => changeStep(current) : null}
    >
      {current <= value - 1 && rating ? (
        <div className={styles.tooltipText}>
          {dimension}: {rating}/5
        </div>
      ) : (
        <div className={styles.tooltipText} id={styles.tooltipWithoutRating}>
          {dimension}
        </div>
      )}
    </div>
  );
}
