import React from 'react';
import BillingInfo, { BillingInfoProps } from './billing_info';
import { shallow, ShallowWrapper } from 'enzyme';

let wrapped: ShallowWrapper;
let props: BillingInfoProps;

window.Routes = {
  edit_billing_info: jest.fn(),
  new_billing_info: jest.fn(),
};

describe('Billing Info component', () => {
  beforeEach(() => {
    props = {
      address: 'Rua da Prata 33',
      city: 'Lisbon',
      countryCode: 'PT',
      id: 3,
      invoiceName: 'My Company Name',
      notes: 'Hello World',
      postcode: '1100-101',
      projectId: '5',
      vatid: '123456789',
    };
    wrapped = shallow(<BillingInfo {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});
