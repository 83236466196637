// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`JobList should render correctly 1`] = `
<div
  className="jobListContainer"
  data-testid="job-list-container"
>
  <div
    className="title"
  >
    Choose job
  </div>
  <div
    className="jobList"
    data-testid="job-list"
  >
    <div
      className="jobCard selected"
      onClick={[Function]}
    >
      <div
        className="jobTitle"
      >
        job
      </div>
      <div
        className="jobCardInfo"
      >
        <div
          className="jobCardInfoItem"
        >
          location
        </div>
        <div
          className="jobCardInfoItem"
        >
          <span
            className="wrapper color-silverSand size-quiteSmall size-quiteSmall style-normal"
            dangerouslySetInnerHTML={
              Object {
                "__html": "",
              }
            }
          />
          <span>
            daysRemaining
          </span>
        </div>
        <div
          className="jobCardInfoItem"
        >
          <span
            className="wrapper color-silverSand size-medium size-medium style-normal"
            dangerouslySetInnerHTML={
              Object {
                "__html": "",
              }
            }
          />
          <span>
            expectedNumberOfHires
          </span>
        </div>
      </div>
    </div>
    <div
      className="jobCard"
      onClick={[Function]}
    >
      <div
        className="jobTitle"
      >
        job2
      </div>
      <div
        className="jobCardInfo"
      >
        <div
          className="jobCardInfoItem"
        >
          location2
        </div>
        <div
          className="jobCardInfoItem"
        >
          <span
            className="wrapper color-silverSand size-quiteSmall size-quiteSmall style-normal"
            dangerouslySetInnerHTML={
              Object {
                "__html": "",
              }
            }
          />
          <span>
            daysRemaining2
          </span>
        </div>
        <div
          className="jobCardInfoItem"
        >
          <span
            className="wrapper color-silverSand size-medium size-medium style-normal"
            dangerouslySetInnerHTML={
              Object {
                "__html": "",
              }
            }
          />
          <span>
            expectedNumberOfHires2
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
`;
