import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import SmallProfileDisplayItem from './SmallProfileDisplayItem';

const title = 'Kiss-Shot Acerola-Orion Heart-Under-Blade Shinobu Oshino';
const label = 'The iron-blooded, hot-blooded, cold-blooded vampire.';

const createProps = (isOther = false) => ({
  title,
  isOther,
  label,
});

let props;
let wrapped: ShallowWrapper;

describe('SmallProfileDisplayItem displays correct info', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<SmallProfileDisplayItem {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('has correct title', () => {
    expect(wrapped.find('.title').text()).toMatch(title);
  });

  it('has correct label', () => {
    expect(wrapped.find('.label').text()).toMatch(label);
  });

  it('is not other', () => {
    expect(wrapped.find('.other')).toHaveLength(0);
  });
});

describe('SmallProfileDisplayItem displays correct info and is other', () => {
  beforeEach(() => {
    props = createProps(true);
    wrapped = shallow(<SmallProfileDisplayItem {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('has correct title', () => {
    expect(wrapped.find('.title').text()).toMatch(title);
  });

  it('has correct label', () => {
    expect(wrapped.find('.label').text()).toMatch(label);
  });

  it('is other', () => {
    expect(wrapped.find('.other')).toHaveLength(1);
  });
});
