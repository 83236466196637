import React from 'react';
import classNames from 'classnames';

const styles = require('./tooltip.module.scss');

interface Props {
  children: any;
  className?: string;
  color?: string;
  htmlContent?: any[];
  text?: string | JSX.Element;
  textBelow?: boolean;
  size?: Size;
  style?: object;
  position?: string;
  otherClasses?: string;
}

export enum Size {
  Default = 'default',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

const Tooltip = (props: Props) => {
  const color = props.color || 'silverSandXxDark';
  const size = props.size || Size.Default;
  const position = props.position || 'auto';
  const klasses = classNames(
    {
      [styles.tooltipTextBelow]: props.textBelow,
      [styles.tooltipTextAbove]: !props.textBelow,
      [styles.mediumLargeIcon]: props.otherClasses === 'mediumLargeIcon',
    },
    styles[`color-${color}`],
    styles[`size-${size}`],
    styles[`position-${position}`]
  );

  return (
    <div className={classNames(styles.tooltip, props.className)}>
      {props.children}
      <div className={klasses} style={props.style ? props.style : {}}>
        {props.text}
        {props.htmlContent}
      </div>
    </div>
  );
};

export default Tooltip;
