import * as React from 'react';
import MetaTags from './MetaTags';
import { shallow } from 'enzyme';
import companyDummy from '__fixtures__/company_dummy';

window.Routes = {
  companies: () => '',
  job_ads: () => '',
};

describe('MetaTags', () => {
  it('shallow renders without crashing', () => {
    const component = shallow(<MetaTags company={companyDummy} />);
    expect(component.exists()).toBe(true);
  });
});
