import React from 'react';

import Button from 'lj_shared/button/button';
import { DefaultCard } from '../handshakes/handshake_card';

const styles = require('./handshake_cards.module.scss');

export function renderRejected(handleReconsider: Function, date: Date, companyName: string) {
  const rejectedCard: DefaultCard = {
    title: <span className={styles.rejectedSpan}>REJECTED</span>,
    body: `On ${date}, ${companyName} requested a Handshake. Would you reconsider, giving them access to your profile and starting conversations with you?`,
    buttons: (
      <Button
        buttonColor='tuftsBlueMidLight'
        buttonSize='small'
        isButton={true}
        disabled={false}
        onClick={() => handleReconsider()}
      >
        Reconsider
      </Button>
    ),
  };
  return rejectedCard;
}

export function renderAccepted(handleRevoke: Function, date: Date, companyName: string) {
  const acceptedCard: DefaultCard = {
    title: <span className={styles.acceptedSpan}>ACCEPTED</span>,
    body: `You accepted the Handshake requested by ${companyName} on ${date}, giving them access to your full profile and to start a conversation.`,
    buttons: (
      <Button
        otherClasses={styles.revokeButton}
        buttonSize='mid'
        isButton={true}
        disabled={false}
        onClick={() => handleRevoke()}
      >
        REVOKE
      </Button>
    ),
  };
  return acceptedCard;
}

export function renderRequested(
  handleAccept: Function,
  handleReject: Function,
  companyName: string
) {
  const requestedCard: DefaultCard = {
    body: `${companyName} requested a Handshake. Would you like to give them full access to your profile and talk to them?`,
    buttons: (
      <>
        <Button
          buttonColor='tuftsBlueMidLight'
          otherClasses={styles.acceptButton}
          buttonSize='small'
          isButton={true}
          disabled={false}
          onClick={() => handleAccept()}
        >
          Accept
        </Button>
        <Button
          buttonColor='silverSand'
          otherClasses={styles.rejectButton}
          buttonType='border'
          buttonSize='small'
          isButton={true}
          onClick={() => handleReject()}
        >
          Reject
        </Button>
      </>
    ),
  };
  return requestedCard;
}
