import React, { useState, useEffect } from 'react';

import ToolTip from 'components/general/tooltip/tooltip';
import Links from './links';
import { Link } from '__models__/models';
import copyToClipboard from 'lib/copy_to_clipboard';
import Icon, { Size } from 'components/general/icon/icon';
import TagWithTooltip from 'components/general/tag_with_tooltip/tagWithTooltip';
import { type } from 'os';

const styles = require('./card_header.module.scss');

interface Props {
  children?: any;
  handleClick?: Function;
  handshakeProfile?: boolean;
  header: any;
  headerKey: string;
  iconAction?: 'edit' | 'add';
  isProfilePublic?: boolean;
  links?: Link[];
  ljColor?: string;
  name?: string;
  openModal?: Function;
  public?: boolean;
  publicLink?: string;
  publicProfilePage?: boolean;
  rate?: number;
  recentlyUpdated?: boolean;
  shareProfile?: string;
  type: HeaderType;
  unlocked?: boolean;
}

export enum HeaderType {
  Regular,
  Special,
}

export default function CardHeader(props: Props) {
  function decideStyle() {
    return props.type === HeaderType.Regular ? styles.headerRegular : styles.headerSpecial;
  }

  return (
    <div className={decideStyle()}>
      <div className={styles.header}>
        <div className={styles.headline}>
          <span className={styles.headerTitle}>
            {props.handshakeProfile ? props.name : props.header}
          </span>
          {props.type === HeaderType.Special &&
            !props.publicProfilePage &&
            !props.handshakeProfile && (
              <span className={styles.leftLinks}>
                <PublicShareLink
                  publicLink={props.publicLink}
                  ljColor={props.ljColor || 'flamingo'}
                  isProfilePublic={props.isProfilePublic}
                  handleClick={props.handleClick}
                />
                {props.shareProfile && toolTips[props.shareProfile] && (
                  <ToolTip text={toolTips[props.shareProfile].text} textBelow>
                    <Icon name={toolTips[props.shareProfile].icon} color='tuftsBlue' />
                  </ToolTip>
                )}
              </span>
            )}
          <span className={styles.rightLinks}>
            <HeaderLinks
              headerKey={props.headerKey}
              links={props.links}
              ljColor={props.ljColor || 'flamingo'}
              handshakeProfile={props.handshakeProfile}
              unlocked={props.unlocked}
              openModal={props.openModal}
              publicProfilePage={props.publicProfilePage}
            />
          </span>
        </div>
        <RightHeader {...props} />
      </div>
      <hr className={styles.ruler} />
    </div>
  );
}

const RightHeader = props => {
  if (props.recentlyUpdated && props.handshakeProfile) {
    return (
      <TagWithTooltip
        tagTitle='Recently Updated'
        tooltipText='Updated in the last 3 months.'
        color='puertoRico'
        style={{ left: '-2rem' }}
        newIcon='starCircle'
        iconColor='white'
      />
    );
  }
  if (props.public) {
    return <RateView rate={props.rate} />;
  }
  if (!props.publicProfilePage) {
    return (
      <div className={styles.editHeadline} onClick={() => props.openModal(props.headerKey, null)}>
        {props.iconAction && (
          <Icon
            name={props.iconAction === 'edit' ? 'pencil' : 'plus'}
            size={Size.MediumLarge}
            color={'black'}
          />
        )}
      </div>
    );
  }
  return null;
};

const RateView = ({ rate }) => {
  if (!rate || HeaderType.Regular) {
    return null;
  }
  return (
    <div className={styles.rate}>
      <span>
        <span className={styles.euro}>€</span>
        {rate}
      </span>
      <span className={styles.decimals} /> PER DAY
    </div>
  );
};

const PublicShareLink = ({ publicLink, ljColor, isProfilePublic, handleClick }) => {
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    copy(publicLink);
  }, [publicLink]);

  function handleEvents() {
    handleClick(publicLink);
    isProfilePublic && copy(publicLink);
  }

  function copy(linkPart) {
    const fullUrl = `https://${window.location.host}/profile/${linkPart}`;
    if (window.innerWidth > 768) {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    } else {
      setCopied(true);
    }

    copyToClipboard(fullUrl);
  }

  const tooltipText = () =>
    copied ? 'Copied!' : 'Click here to copy the link to your public profile.';

  return (
    <ToolTip text={tooltipText()} textBelow>
      <div className={styles.publicShareLink} onClick={() => handleEvents()}>
        <Icon name={'linkBox'} color={'silverSandXxDark'} />
      </div>
    </ToolTip>
  );
};

const HeaderLinks = ({
  links,
  ljColor,
  handshakeProfile,
  headerKey,
  openModal,
  publicProfilePage,
  unlocked,
}) => (
  <div className={styles.hideMobile}>
    <Links
      headerKey={headerKey}
      links={links}
      ljColor={ljColor || 'flamingo'}
      disabled={(handshakeProfile && !unlocked) || publicProfilePage}
      handshakeProfile={handshakeProfile}
      openModal={openModal}
    />
  </div>
);

const toolTips = {
  open: {
    text: 'Your profile is set to be shared with potential employers, hiding only your contact info.',
    icon: 'eye',
  },
  anonymous: {
    text: 'Your profile is set to be shared with potential employers, hiding all your personal info.',
    icon: 'eyeClosed',
  },
  not_allowed: {
    text: 'Your profile is set not to be shared with potential employers.',
    icon: 'eyeClosed',
  },
};
