import * as React from 'react';
import InlineIcon from './inline_icon';
import { mount } from 'enzyme';

const eyeIcon = require('iconic/eye.svg');

let wrapped;

describe('Inline Icon component', () => {
  beforeAll(() => {
    wrapped = mount(
      <InlineIcon
        path={eyeIcon}
        alt='eye-closed'
        data={{ state: 'closed' }}
        className='custom-eye'
      />
    );
  });

  it('should show an alt tag', () => {
    expect(wrapped.find('img').prop('alt')).toBe('eye-closed');
  });

  it('should have the image path', () => {
    expect(wrapped.find('img').prop('src')).toEqual(eyeIcon);
  });

  it('should have a data attribute', () => {
    expect(wrapped.find('img').prop('data-state')).toBe('closed');
  });

  it('should have a custom class', () => {
    expect(wrapped.find('img').prop('className')).toMatch('custom-eye');
  });
});
