import * as React from 'react';
import TransferToAnotherJobModal, { Props } from './transfer_to_another_job_modal';
import { shallow, ShallowWrapper } from 'enzyme';

const createTestProps = moreProps => {
  return {
    classNames: '',
    applicationId: 1,
    companyId: 2,
    ...moreProps,
  };
};

let props: Props;
let wrapped: ShallowWrapper;

describe('Transfer to another job modal component', () => {
  beforeEach(() => {
    props = createTestProps({});
    wrapped = shallow(<TransferToAnotherJobModal {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
