// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Sidebar Component should render company sidebar 1`] = `
<CompanySidebar
  active="overview"
  counter={
    Object {
      "Handshakes": 1,
    }
  }
  userImage="<a href='#'>"
  userName="Manuel"
  userType="CompanyUser"
/>
`;

exports[`Sidebar Component should render talent sidebar 1`] = `
<TalentSidebar
  active="overview"
  counter={
    Object {
      "Handshakes": 1,
    }
  }
  userImage="<a href='#'>"
  userName="Manuel"
  userType="TalentUser"
/>
`;
