import { Person } from '__models__/person';

const personDummy: Person = {
  id: 137640,
  attributes: {
    first_name: 'Bill',
    last_name: 'Paxton',
    user_id: 142877,
    birth_year: null,
    city: null,
    country_code: null,
    country_name: null,
    minimum_rate: null,
    maximum_rate: null,
    minimum_gas: null,
    maximum_gas: null,
    headline: null,
    cv_file_name: 'CV.pdf',
    relocation: null,
    relocation_countries: [],
    freelance: null,
    share_profile: null,
    recipient_avatar:
      '<div class="lj-userAvatar--small lj-userAvatar"><img src="https://assets.landing.jobs/default_avatars/avatar2.svg" class="lj-userAvatar-img" /></div>',
    has_cv: true,
    name: 'Bill Paxton',
    experience_years: {
      value: 123,
      label: 'No years of experience available',
    },
    start_year: {
      value: 123,
      label: '',
    },
    location: null,
    citizenships: '',
    phone_number: 'No info',
    phone: '',
    email: 'bill@neardark.com',
    websites: [
      {
        id: 133902,
        full_url: 'https://linkedin.com/in/joana-rflores/',
        title: 'Linkedin',
        category_key: 'linkedin',
      },
    ],
    languages: [],
    skills: [],
    certificates: [],
    person_tags: {
      multiple_processes: null,
    },
    work_availability: 123,
    availability: {
      id: '',
      value: '',
      label: '',
    },
    contact_preference: 123,
    categories: [],
    skype_id: 'No info',
    professional_experiences: [],
    description: null,
    cvs: [
      {
        id: 60372,
        cvContentType: 'application/pdf',
        cvFileName: 'Invoice_475398493_(1).pdf',
        cvFileSize: '93399',
        standard: true,
        personId: 137656,
        createdAt: '2020-07-06T14:00:57.661Z',
        updatedAt: '2020-07-06T14:00:57.661Z',
      },
    ],
    jobTypesInterest: [],
    slug: '',
  },
};

export default personDummy;
