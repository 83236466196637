import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import RejectionReasonModal from './rejectionReasonModal';

const createProps = () => ({
  rejectionReason: 'Not enough pokemon research',
  rejectedAt: '23 November 2022 10:45',
  label: 'Rejected',
});

let props;
let wrapped: ShallowWrapper;

describe('RejectionReasonModal', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<RejectionReasonModal {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});
