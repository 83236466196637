import React from 'react';
import { mount } from 'enzyme';
import CitizenshipInput, { Props } from './CitizenshipInput';

const createProps = (otherProps = {}) => ({
  citizenships: [],
  error: '',
  handleFormChange: jest.fn,
  options: [],
  ...otherProps,
});

let props: Props;
let wrapped;

describe('Input is empty', () => {
  beforeAll(() => {
    props = createProps();
    wrapped = mount(<CitizenshipInput {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('shows the label', () => {
    expect(wrapped.find('.label').text()).toEqual('Please select your citizenship(s)? *');
  });

  it('shows the placeholder', () => {
    expect(wrapped.find('.___placeholder > .___placeholder').text()).toEqual('E.g. Portuguese');
  });
});

describe('Input has one value selected', () => {
  beforeAll(() => {
    props = createProps({
      citizenships: [
        {
          id: 'Portuguese',
          value: 'Portuguese',
          label: 'Portuguese',
        },
      ],
    });
    wrapped = mount(<CitizenshipInput {...props} />);
  });

  it('does not show the placeholder', () => {
    expect(wrapped.find('.___placeholder > .___placeholder')).toHaveLength(0);
  });

  it('shows the selected value', () => {
    expect(wrapped.find('input[name="citizenships"]').props().value).toEqual('Portuguese');
  });
});

describe('Input has multiple values selected', () => {
  beforeAll(() => {
    props = createProps({
      citizenships: [
        {
          id: 'Portuguese',
          value: 'Portuguese',
          label: 'Portuguese',
        },
        {
          id: 'Dutch',
          value: 'Dutch',
          label: 'Dutch',
        },
        {
          id: 'Brazilian',
          value: 'Brazilian',
          label: 'Brazilian',
        },
      ],
    });
    wrapped = mount(<CitizenshipInput {...props} />);
  });

  it('shows multiple values', () => {
    const values = wrapped.find('input[name="citizenships"]').map(input => input.props().value);
    expect(values).toEqual(['Portuguese', 'Dutch', 'Brazilian']);
  });
});
