import React from 'react';
import { render } from '@testing-library/react';
import SkillsReport from './skills_report';

const report = {
  area_skills: {},
  abstractions_of: [],
  frameworks_of: [],
  libraries_of: [],
  same_skills: ['Java', 'Python'],
  similar_skills: [],
};

describe('SkillReport', () => {
  it('should show the report content', () => {
    const { getByText } = render(<SkillsReport report={report} />);
    expect(getByText(report.same_skills[0])).toBeTruthy();
    expect(getByText(report.same_skills[1])).toBeTruthy();
  });
});
