import React from 'react';
import InvoiceFormPage from './invoice_form_page';
import { render } from '@testing-library/react';

window.Routes = {
  dashboard_placement: jest.fn(),
  edit_billing_info: jest.fn(),
  new_billing_info: jest.fn(),
};

describe('InvoiceFormPage', () => {
  it('renders the form', () => {
    const data = {
      companyName: 'FooCorp',
      defaultEndDate: '2015-01-01',
      defaultStartDate: '2015-01-31',
      defaultWorkedDays: 22,
      effectiveRate: 3,
      invoiceMonth: 'January 2015',
      placementId: '1337',
      projectName: 'The Foo Project',
    };

    const { queryByText } = render(<InvoiceFormPage {...data} />);
    expect(queryByText(data.companyName, { exact: false })).toBeTruthy();
    expect(queryByText(data.projectName, { exact: false })).toBeTruthy();
    expect(queryByText('Confirm and send invoice', { exact: false })).toBeTruthy();
  });
});
