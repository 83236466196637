import * as React from 'react';
import LocationDimension from './location_dimension';
import { shallow, ShallowWrapper } from 'enzyme';

jest.mock('lib/scripts');

import Autocomplete from 'react-google-autocomplete';
import { GoogleMaps } from 'lib/scripts';

let wrapped: ShallowWrapper;
window.gon = {
  googleMapsApiKey: '',
  companyUserData: {},
};

describe('Location Dimension component', () => {
  beforeEach(() => {
    wrapped = shallow(
      <LocationDimension
        candidateAcceptedJobTimezone={true}
        candidateHasTimezone={false}
        value={4}
        ratingRef=''
        handleChangeLocation={jest.fn()}
        locationRef=''
        locationDefaultValue='Porto, Portugal'
        workPermitDefaultValue={true}
        handleChangeWorkPermit={jest.fn()}
        jobType='onsite_job'
        jobWithTimezone={false}
        citizenship={false}
        handleChangeTimeZone={jest.fn()}
        handleChangeTimeZoneNegative={jest.fn()}
        jobCountryNames='Brazil'
        timeZoneDefaultValue={false}
        timeZoneMismatch={false}
        timeZoneNegativeDefaultValue={false}
      />
    );
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('shows an autocomplete', async () => {
    expect.assertions(1);
    await GoogleMaps.load();
    expect(wrapped.find(Autocomplete).length).toEqual(1);
  });
});
