export function addErrors(alternate: string) {
  return {
    alternateIsEmpty: isEmpty(alternate),
    alternateIsLong: isLong(alternate),
    alternateIsInvalid: isInvalid(alternate),
  };
}

export function submitErrors(tagName: string) {
  return {
    nameIsEmpty: isEmpty(tagName),
    nameIsLong: isLong(tagName),
    nameIsInvalid: isInvalid(tagName),
  };
}

export const orReduce = errorObject => {
  return Object.values(errorObject).reduce(orOperation, false);
};

const orOperation = (accumulator, currentValue) => accumulator || currentValue;

const isEmpty = name => !name.trim();

const isLong = (name, maxLength = 50) => name.length > maxLength;

const isInvalid = (name, invalidChars = [',', ';']) =>
  new RegExp(invalidChars.join('|')).test(name);
