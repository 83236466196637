import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import OfferLocation from './offer_location';
import Icon from 'components/general/icon/icon';

const createProps = () => ({
  location: 'Lisbon, Portugal',
  locationIcon: 'remote',
  remoteDetails: 'details',
  remoteWorkingLabel: 'label',
  officeLocations: [
    {
      google_place_id: '1232jfifnowefn',
      city: 'Lisbon',
      country_code: 'PT',
      label: 'Lisbon, Portugal',
    },
    {
      google_place_id: '1232jsssfifnowefn',
      city: 'Lisbone',
      country_code: 'PTE',
      label: 'Lisbone, Portugale',
    },
  ],
  iconColor: 'black',
});

let props;
let wrapped: ShallowWrapper;

describe('OfferLocation renders correctly', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<OfferLocation {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('should have one icon', () => {
    expect(wrapped.find(Icon)).toHaveLength(1);
  });

  it('should have one link', () => {
    expect(wrapped.find('a')).toHaveLength(1);
  });
});

test.todo('add more tests');
