import * as React from 'react';
const styles = require('./note_for_screening.module.scss');
import InlineIcon from 'components/general/inline_icon/inline_icon';
const warningIcon = require('iconic/warning.svg');

export interface Props {
  title: any;
  note: any;
  employer?: boolean;
}

export default class NoteForScreening extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  showNotes(notes) {
    if (notes != null) {
      return notes.map((item, key) => {
        if (item != null) {
          return (
            <div key={key} className={styles.text}>
              <div
                dangerouslySetInnerHTML={{
                  __html: item.note_text,
                }}
              />
            </div>
          );
        }
      });
    } else return;
  }

  render() {
    return (
      <div className={styles.notesWrapper}>
        {this.props.employer ? (
          <>
            <div className={styles.title}>
              <InlineIcon path={warningIcon} className={styles.warningIcon} />
              {this.props.title}
            </div>
            <div
              className={styles.text}
              dangerouslySetInnerHTML={{
                __html: this.props.note,
              }}
            />
          </>
        ) : (
          <>
            <div className={styles.subtitle}>{this.props.title}</div>
            <div>{this.showNotes(this.props.note)}</div>
          </>
        )}
      </div>
    );
  }
}
