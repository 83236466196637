import React, { useRef } from 'react';
import Modal from '../general/modal/modal';
import Button from 'lj_shared/button/button';
import { postJSON, handleRequestError } from '../../lib/request_deprecated';
import 'lib/globals';

const styles = require('./send_bulk_tests_modal.module.scss');

interface Props {
  peopleIds: string[];
  testStep: object;
  sendBulkTestsAftermath: Function;
}

export default function SendBulkTestsModal(props: Props) {
  const { peopleIds, testStep, sendBulkTestsAftermath } = props;
  const modalRef = useRef(null);

  const handleSend = () => {
    window.toggleSpinner(true);

    postJSON(window.Routes.bulk_assessment_invite(), {
      step_id: testStep['id'],
      people_ids: peopleIds,
    })
      .then(e => {
        if (e.notice) {
          window.toggleSpinner(false);
          window.Alerts.notice(e.notice);
          handleCancel();
          sendBulkTestsAftermath();
        } else {
          window.Alerts.alert(e.alert);
          handleCancel();
        }
        window.toggleSpinner(false);
      })
      .catch(e => {
        handleRequestError(e);
        window.toggleSpinner(false);
      });
  };

  const handleCancel = () => {
    modalRef.current.close();
  };

  return (
    <Modal title='Send Technical Test' defaultOpen={true} ref={modalRef}>
      <div id='send-bulk-test-modal' className={styles.content}>
        Would you like to send the Technical Test to the candidates?
      </div>
      <br />
      <div className={styles.note}>
        Note: You can Cancel and send the tests later in the Candidate’s Application Page.
      </div>
      <div className={styles.footer}>
        <div className={styles.buttons}>
          <Button buttonColor='ripePlum' onClick={handleSend} otherClasses={styles.button}>
            Send Tests
          </Button>
          <Button
            buttonColor='ripePlum'
            buttonType='border'
            onClick={handleCancel}
            otherClasses={styles.button}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
}
