import './globals';

const ljLogo = require('logo_square.png');

// How much time (in milliseconds) we're going to wait before we annoy the user
// again with notifications.
const DELAY_BETWEEN_NOTIFICATIONS = 60 * 1000;

// Notifications waiting to be sent.
let notificationsQueue = [];

// When is the next time window when we are allowed to send notifications? If
// it's a past time, then we're allowed to send immediately.
let nextNotificationTime = Date.now();

interface Options {
  body?: string;
  icon?: string;
  onClick?: Function;
  requireInteraction?: boolean;
  tag?: string;
  title: string;
}

export function browserNotify(options: Options) {
  const now = Date.now();
  const notificationData = {
    icon: ljLogo,
    requireInteraction: false,
    ...options,
  };

  if (window.Notification) schedule(notificationData);
}

function schedule(notificationData) {
  notificationsQueue.push(notificationData);

  if (notificationsQueue.length === 1) {
    // we send notifications in batches; if this is the first notification of
    // the batch then we schedule a task to send this batch
    const delay = Math.max(0, nextNotificationTime - Date.now());
    setTimeout(dispatchNotifications, delay);
  }
}

function dispatchNotifications() {
  for (const data of notificationsQueue) {
    const notification = new Notification(data.title, data);
    if (data.onClick) notification.onclick = data.onClick;
  }

  notificationsQueue = [];
  nextNotificationTime = Date.now() + DELAY_BETWEEN_NOTIFICATIONS;
}
