import { DimensionItem } from '../general/rating_dimension/rating_dimension_item';
import { titleize } from 'lib/string';

export function RatingsToDimensions(ratings, handleCircleClick) {
  return Object.keys(ratings).map(title => {
    let value = ratings[title];
    let notes = null;
    if (!Number.isInteger(ratings[title]) && title !== 'Overall') {
      value = ratings[title].value;
      notes = ratings[title].notes;
    }
    return {
      maxRating: 5,
      title: titleize(title),
      uniqueKey: title,
      value,
      handleCircleClick,
      notes,
    };
  }) as DimensionItem[];
}

export const concernText = application => {
  if (application.data) {
    application = application.data;
  }
  const ratings = application.attributes.ratings_with_dimensions[0];

  return [ratings?.concern, ratings?.concern_note, ratings?.concern_level];
};

export const ratingsWithoutNotes = (dimensions, handleCircleClick) => {
  const newDimensions = Object.keys(dimensions).reduce((accumulator, dimension) => {
    accumulator[dimension] = { value: dimensions[dimension], notes: null };
    return accumulator;
  }, {});

  return RatingsToDimensions(newDimensions, handleCircleClick);
};

export function DimensionsFromApplication(application, withOverall = false, onlyOverall = false) {
  const rating = application.attributes.ratings_with_dimensions[0];
  if (!rating || onlyOverall) return { Overall: application.attributes.rating };

  const dimensions = rating.dimensions.reduce((accumulator, dimension) => {
    const { value, automatic_notes } = dimension;
    const notes = automatic_notes?.length > 0 ? automatic_notes : [rating.note];
    accumulator[dimension.dimension] = { value, notes };
    return accumulator;
  }, {});
  if (withOverall) {
    dimensions['Overall'] = { value: application.attributes.rating };
  }
  return dimensions;
}
