import React from 'react';
import QuestionMarkTooltip from 'components/question_mark_tooltip/question_mark_tooltip';
import { Size } from 'components/general/icon/icon';
import { adminUserPricingNotification } from 'lib/admin_user_pricing_notification';

interface Props {
  pricingDisabledFeature?: boolean;
  pricingLimitType?: boolean;
  type: string;
  backoffice?: boolean;
}

export default function PricingQuestionMarkTooltip(props: Props) {
  const { pricingDisabledFeature, pricingLimitType, type, backoffice } = props;

  const typeText = pricingLimitType ? 'limit is related' : 'feature is an addon';
  const iconColor =
    backoffice && type === 'personality assessments' ? 'tuftsBlue' : 'ripePlumMidLight';

  const content = backoffice ? (
    <>The current subscription plan doesn't allow adding {type}.</>
  ) : (
    <>
      This {typeText} <br />
      to your current pricing plan, <br /> to upgrade
      <a
        className='ld-primary-link'
        onClick={() =>
          adminUserPricingNotification({
            pricingLimitType: pricingLimitType && type,
            pricingDisabledFeature: pricingDisabledFeature && type,
          })
        }
      >
        &nbsp;click here
        <br />
      </a>
      and we'll contact you with more details.
    </>
  );

  return (
    <QuestionMarkTooltip
      id={'pricing-tooltip'}
      iconColor={iconColor}
      content={content}
      containerClass={'pricing-question-mark-tooltip'}
      size={Size.MediumLarge}
    />
  );
}
