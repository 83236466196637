import React from 'react';
import classnames from 'classnames';

const images = {
  independence: require('../images/independence.png'),
  engagement: require('../images/independence.png'),
  support: require('../images/support.png'),
  trust: require('../images/total-trust.jpg'),
};

const styles = require('./how_we_help.module.scss');

interface Props {
  title: string;
  text: string[];
  image?: any;
  company: boolean;
}

export default function HowWeHelpSection(props: Props) {
  const returnCompanyPage = () => {
    return (
      <>
        <div className={classnames(styles.title, styles.helpTitle)}>{props.title}</div>
        <div className={styles.companyImageContainer}>
          <img className={styles.image} alt='support' src={images[props.image]} />
        </div>
      </>
    );
  };

  const returnTalentPage = () => {
    return (
      <>
        <img className={styles.image} alt='support' src={images[props.image]} />
        <div className={classnames(styles.title, styles.helpTitle)}>{props.title}</div>
      </>
    );
  };

  return (
    <div className={classnames(styles['home-sections'], styles['how-we-help'])}>
      {props.company ? returnCompanyPage() : returnTalentPage()}
      <div className={styles.textContainer}>
        {props.text.map((textItem, index) => (
          <p
            key={`textItem${index}`}
            className={props.company ? styles.companyText : styles.text}
            dangerouslySetInnerHTML={{ __html: textItem }}
          />
        ))}
      </div>
    </div>
  );
}
