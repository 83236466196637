import React from 'react';
import classNames from 'classnames';

const styles = require('./ProgressBar.module.scss');

interface Props {
  value: number;
  className?: string;
}

export default function ProgressBar(props: Props) {
  const { value, className } = props;

  const barStyle = className || styles.midLightBar;

  return (
    <div className={styles.BarContainer}>
      <div className={styles.innerBar} />
      <div className={classNames(styles.outerBar, barStyle)} style={{ width: `${value}%` }} />
    </div>
  );
}
