import React from 'react';
import { Person, Application } from '__models__/models';
import Button from 'lj_shared/button/button';
import Icon from 'components/general/icon/icon';
import Chat from 'components/pages/chat';
import SendApplicationByEmailModal from 'components/application_page/send_application_by_email_modal';
import { handleRequestError } from 'lib/request_deprecated';
import Tooltip from 'components/general/tooltip/tooltip';
const styles = require('./ApplicationHeaderButtonsContainer.module.scss');

interface Props {
  application: Application;
  topFilter: string;
  urls: any;
  waitingReview: boolean;
  moveToStep: any;
}

export default function ApplicationHeaderButtonsContainer(props: Props) {
  function handleChatClick() {
    const id = props.application.attributes.person.attributes.user_id;
    Chat.openConversationWithCandidate(id);
  }

  const handleSendByEmailClick = event => {
    event.preventDefault();
    SendApplicationByEmailModal.show(
      props.urls.email_url,
      props.topFilter,
      props.moveToStep,
      props.application.attributes.hiring_steps,
      props.application
    ).catch(handleRequestError);
  };

  const renderButtons = () => {
    const person: Person = props.application.attributes.person;

    return (
      <div className={styles.buttons}>
        {props.application.attributes.has_application_cv ||
        props.application.attributes.person.attributes.has_cv ? (
          <Button
            buttonColor='ripePlum'
            buttonSize='xSmall'
            buttonUrl={props.urls.pdf_url}
            targetBlank={true}
            isButton={false}
            otherClasses={styles.button}
          >
            <Icon name='export' color='white' />
            CV
          </Button>
        ) : (
          <Button
            buttonColor='ripePlum'
            buttonSize='xSmall'
            targetBlank={true}
            isButton={true}
            otherClasses={styles.buttonDisabled}
            disabled
          >
            <Icon name='export' color='silverSand' />
            CV
          </Button>
        )}
        {props.waitingReview ? (
          <Tooltip
            text='Cannot chat with a candidate until he is "IN PROCESS"'
            className={styles.tooltipButton}
            color='silverSandMidDark'
            textBelow
          >
            <Button
              buttonColor='ripePlumDark'
              buttonSize='xSmall'
              dataAttribute={window.innerWidth > 1023 ? String(person.attributes.user_id) : null}
              onClick={handleChatClick}
              disabled={props.waitingReview}
              isButton={true}
              otherClasses={styles.buttonDisabled}
            >
              <Icon name='chat' color={props.waitingReview ? 'silverSand' : 'white'} />
            </Button>
          </Tooltip>
        ) : (
          <Button
            buttonColor='ripePlumDark'
            buttonSize='xSmall'
            dataAttribute={window.innerWidth > 1023 ? String(person.attributes.user_id) : null}
            onClick={handleChatClick}
            disabled={props.waitingReview}
            isButton={true}
            otherClasses={styles.button}
          >
            <Icon name='chat' color={props.waitingReview ? 'silverSand' : 'white'} />
          </Button>
        )}
        <Button
          otherClasses={styles.button}
          buttonColor='ripePlum'
          buttonType='border'
          buttonSize='xSmall'
          onClick={handleSendByEmailClick}
        >
          <Icon name='share' color='ripePlum' className={styles.shareIcon} />
          Share
        </Button>
      </div>
    );
  };
  return renderButtons();
}
