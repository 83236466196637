import RatingUtils from './rating_utils';

export default class RatingHeaderUtils extends RatingUtils {
  props = () => ({
    title: 'Rating',
    employerRating: this.employerRating ? this.employerRating.rating : 0,
    landingRating: this.landingRating ? this.landingRating.rating : 0,
    showRatingBody: this.showRatingBody,
  });
}
