import * as React from 'react';
import HelpContainer from './help_container';
import { shallow, ShallowWrapper } from 'enzyme';

const containerProps = () => ({
  data: [
    {
      id: '2',
      type: 'help_article',
      attributes: {
        title: 'Yep',
      },
    },
    {
      id: '3',
      type: 'help_article',
      attributes: {
        title: 'Le Article',
      },
    },
    {
      id: '4',
      type: 'help_article',
      attributes: {
        title: 'dfsg',
      },
    },
  ],
  baseArticleUrl: '/employer/help/~id',
});

let props;
let wrapped: ShallowWrapper;

describe('Help Container component', () => {
  beforeEach(() => {
    props = containerProps();
    wrapped = shallow(<HelpContainer {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});
