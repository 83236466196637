import React from 'react';

interface Props {
  type: number;
  url?: string;
}

const NoResults = (props: Props) => {
  const { type, url } = props;

  return (
    <ul className='ld-jobs-list list-subscriptions'>
      <li className='ld-empty-placeholder'>
        <p className='ld-small-paragraph'>
          {type === 1 ? (
            `There are no job positions that fit this search at the moment.`
          ) : (
            <>
              New matches will be listed here when available. Check all{' '}
              <a className='ld-inline-link' href={url}>
                search results{' '}
              </a>
              instead.
            </>
          )}
        </p>
      </li>
    </ul>
  );
};

export default NoResults;
