import ProfileForm from 'components/registration/profile_form';
import { formatMoneyRange } from 'lib/range';
import { ViewerContext } from '../ViewerContext';

export default class JobInterests {
  // profileForm: ProfileForm;
  viewerContext: ViewerContext;
  profileInfo: ProfileForm;

  JOB_TYPE_PERMANENT = 0;
  JOB_TYPE_CONTRACTOR = 1;
  JOB_TYPE_FULL_REMOTE = 2;
  JOB_TYPE_PARTIALLY_REMOTE = 3;
  JOB_TYPE_NOT_CERTAIN_OPTION = 4;
  JOB_TYPE_ON_SITE = 5;

  MAIN_JOB_TYPES: number[] = [this.JOB_TYPE_PERMANENT, this.JOB_TYPE_CONTRACTOR];
  REMOTE_POLICY_TYPES: number[] = [
    this.JOB_TYPE_FULL_REMOTE,
    this.JOB_TYPE_PARTIALLY_REMOTE,
    this.JOB_TYPE_ON_SITE,
  ];

  constructor(params) {
    this.viewerContext = params.viewerContext;
    this.profileInfo = params.profileInfo;
  }

  jobTypePreferences = () => {
    const preferences = this.profileInfo.jobTypesInterest?.filter(i =>
      this.MAIN_JOB_TYPES.includes(i.id)
    );
    if (preferences.length !== 0) {
      return preferences.map(i => ({
        shareable: i.label,
        nonShareable: this.financialRetributionValue(i),
      }));
    } else {
      return 'No preferences';
    }
  };

  financialRetributionValue = value => {
    let minimum: number = null;
    let maximum: number = null;
    let financialRetributionType = null;

    if (value.id === this.JOB_TYPE_PERMANENT) {
      financialRetributionType = 'Gross annual salary';
      minimum = this.profileInfo.minimumGAS;
      maximum = this.profileInfo.maximumGAS;
    } else if (value.id === this.JOB_TYPE_CONTRACTOR) {
      financialRetributionType = 'Rate per day';
      minimum = this.profileInfo.minimumRate;
      maximum = this.profileInfo.maximumRate;
    }

    const rangeString = formatMoneyRange(minimum, maximum) || '- ?';

    if (financialRetributionType && this.viewerContext.isCandidate()) {
      return `${financialRetributionType} ${rangeString}`;
    }
  };

  relocation = () => {
    const relocation = this.profileInfo.relocation;
    const relocationCountries = this.profileInfo.relocationCountries;
    if (relocationCountries && relocationCountries.length > 0) {
      return ['Yes '].concat(relocationCountries.map(country => country.label).join(', '));
    } else if (relocation) {
      return ['Yes'];
    } else if (relocation === false) {
      return ['No'];
    } else {
      return ['No info'];
    }
  };

  jobCategories = () => {
    return this.profileInfo.categories?.map(category => category.label);
  };

  remotePolicyPreferences = () => {
    const preferences = this.profileInfo.jobTypesInterest?.filter(i =>
      this.REMOTE_POLICY_TYPES.includes(i.id)
    );
    if (preferences.length !== 0) {
      return preferences.map(i => i.label).join(', ');
    } else {
      return 'No other preferences';
    }
  };
}
