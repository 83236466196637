import React from 'react';
import MobileText from './mobileText';
import { CSSTransition } from 'react-transition-group';

const flag = require('../images/stepper-flag.png');

interface Props {
  number: number;
  step: any[];
  idNum: string;
  stepClosed: boolean;
  stepName: string;
  scroll: any;
}

/**
 * Component which renders a step, this is used by the component
 * 'HowItWorks' to render the various steps of landing.work
 */

class Step extends React.Component<Props> {
  render() {
    return (
      <>
        <div className='lw-step' onScroll={this.props.scroll}>
          <div id={`${this.props.idNum}`} className='number'>
            <span>{this.props.number}</span>
            {this.props.number === 6 ? <img className='flag' src={flag} alt='flag' /> : null}
          </div>
          <div className='name'>{this.props.step[0]} </div>
          <div className='description'>
            <div>{this.props.step[1]}</div>
          </div>
        </div>
        <CSSTransition in={this.props.stepClosed} timeout={2000} classNames='my-node'>
          <MobileText stepClosed={this.props.stepClosed} step={this.props.step} />
        </CSSTransition>
      </>
    );
  }
}

export default Step;
