import React from 'react';
import ReconsiderModal from './reconsider_modal';
import Modal from '../general/modal/modal';
import Button from 'lj_shared/button/button';
import { shallow } from 'enzyme';

let wrapped;

beforeAll(() => {
  wrapped = shallow(
    <ReconsiderModal reconsiderUrl='reconsider_url' reconsiderApplication={jest.fn()} />
  );
});

it('should render correctly', () => {
  expect(wrapped.exists()).toBe(true);
});

it('should have a Modal component', () => {
  expect(wrapped.find(Modal).length).toEqual(1);
});

it('should have a reconsider reason text input', () => {
  expect(wrapped.find('#application_reconsider_comments').length).toEqual(1);
});

it('should have a reconsider message text input', () => {
  expect(wrapped.find('#application_reconsider_message').length).toEqual(1);
});

it('should have a reconsider and cancel button', () => {
  expect(wrapped.find(Button).length).toEqual(2);
});
