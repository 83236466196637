import classNames from 'classnames';
import React from 'react';

const styles = require('./state.module.scss');

interface Props {
  className?: string;
  hideLabel?: boolean;
  state: string;
}

export default function State(props: Props) {
  const label = getLabel(props.state);

  return (
    <span className={classNames(styles.container, props.className)}>
      <span className={classNames(styles.circle, styles[`state-${props.state}`])} />
      {!props.hideLabel && <span className={styles.label}>{label}</span>}
    </span>
  );
}

function getLabel(state: string) {
  switch (state) {
    case 'draft':
      return 'Draft';
    case 'waiting_revision':
      return 'Waiting revision';
    case 'published':
      return 'Published';
    case 'unpublished':
      return 'Unpublished';
    case 'expired':
    case 'dormant':
      return 'Expired';
    case 'rejected':
    case 'closed':
      return 'Archived';
  }
}
