import * as React from 'react';
import IntegrationButton from '../ats/token_button';

interface Props {
  url: string;
}

export default function Button(props: Props) {
  return (
    <IntegrationButton
      url={props.url}
      atsName={'Personio'}
      atsPathToCreateToken={'Settings > API > Recruiting API Access Token'}
      atsAPIDocUrl={'https://developer.personio.de/docs/getting-started-with-the-personio-api'}
    />
  );
}
