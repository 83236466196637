import React from 'react';

import { handleRequestError, deleteJSON } from '../../lib/request_deprecated';

import SectionCard from './SectionCard';

const styles = require('./TeamElementsList.module.scss');

export interface Props {
  teamMembers: TeamMember[];
  description?: string;
}

export interface TeamMember {
  id: number;
  fullName: string;
  role: string;
  resume: string;
  photo: MemberImage;
}

export interface MemberImage {
  name: string;
  imageURL?: string;
  image?: File;
}

export default function TeamElementList(props: Props, setMembers: Function) {
  const deleteTeamItem = (id: number) => {
    deleteJSON(window.Routes.employer_company_member(id))
      .then(data => {
        setMembers(teamDataMapper(data.data));
      })
      .catch(handleRequestError);
  };

  return (
    <div className={styles.sectionList}>
      {props.teamMembers.map(({ id, fullName, role, resume, photo }) => (
        <SectionCard
          key={id}
          header={fullName}
          text={resume}
          title={photo.name}
          subtitle={role}
          handleDelete={() => deleteTeamItem(id)}
        />
      ))}
    </div>
  );
}

export function teamDataMapper(data: any[]) {
  return data.map(elem => ({
    id: elem.attributes.id,
    fullName: elem.attributes.full_name || elem.attributes.fullName,
    role: elem.attributes.role,
    resume: elem.attributes.resume,
    photo: {
      name: elem.attributes.image_name || elem.attributes.imageName,
      imageURL: elem.attributes.image_URL || elem.attributes.imageUrl,
    },
  }));
}
