import React, { useState } from 'react';
const styles = require('./selfEvaluation.module.scss');
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import Slider, { createSliderWithTooltip } from 'rc-slider';

interface Props {
  self_evaluation_data: {
    must_have_skills: { id: number; name: string }[];
    nice_to_have_skills: { id: number; name: string }[];
    other_required_skills: { id: number; name: string }[];
    self_evaluations: { tag_id: number; score: number }[];
  };
}

const marks = { 1: 'Not Fit', 10: 'Great Fit' };
const SliderWithTooltip = createSliderWithTooltip(Slider);

export default function SelfEvaluation(props: Props) {
  const { must_have_skills, nice_to_have_skills, other_required_skills, self_evaluations } =
    props.self_evaluation_data;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        Self Evaluation <span className={styles.subHeader}>(Optional)</span>
      </div>
      <div className={styles.subHeader}>
        Make your application process go quicker by sharing your self-evaluation for the skills this
        jobs requires or favours.
      </div>
      <div className={styles.skills}>
        {[...must_have_skills, ...nice_to_have_skills, ...other_required_skills].map(skill => {
          return (
            <SkillSlider
              skill={skill}
              score={self_evaluations.find(evaluation => evaluation.tag_id === skill.id)?.score}
              key={skill.id}
            />
          );
        })}
      </div>
    </div>
  );
}

function SkillSlider({ skill, score }) {
  const [value, setValue] = useState(score || 0);
  const [color, setColor] = useState(score ? '#007eff' : '#e9e9e9');

  const handleChange = val => {
    setValue(val);
    setColor('#007eff');
  };

  const handleStyle = {
    borderColor: color,
    height: 16,
    width: 16,
    marginTop: -6,
    backgroundColor: color,
  };

  return (
    <div className={styles.skillContainer} id={`skill-${skill.id}`}>
      {skill.name}
      <div className={styles.skillWrapper}>
        <SliderWithTooltip
          onChange={handleChange}
          min={1}
          max={10}
          value={value}
          tipFormatter={val => `${val}`}
          marks={marks}
          dots={true}
          activeDotStyle={{ borderColor: 'black' }}
          trackStyle={{ backgroundColor: 'black' }}
          handleStyle={handleStyle}
        />
        <input
          type='hidden'
          name={`application_form[self_evaluations][skill-${skill.id}]`}
          value={value}
        />
      </div>
    </div>
  );
}
