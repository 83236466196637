import React, { useEffect, useState } from 'react';
import ButtonDefault from '../ats/button';
import * as Cookies from 'js-cookie';
interface Props {
  url: string;
}

export default function Button(props: Props) {
  const isSyncing = Cookies.get('otherAtsSync');
  const syncText = 'Syncing...';
  const waitNotice =
    "Please wait while syncing, it could take up to 5 min. We'll let you know when it's done.";
  const [buttonText, setButtonText] = useState(isSyncing && syncText);

  useEffect(() => {
    if (isSyncing) {
      $('.ld-integrations-list button').prop('disabled', true);
      window.Alerts.notice(waitNotice);
    }
  }, []);

  function handleClick() {
    window.Alerts.notice(waitNotice);

    Cookies.set('otherAtsSync', true);
    setButtonText(syncText);

    window.open(props.url, '_blank');
    $('.ld-integrations-list button').prop('disabled', true);
  }

  return <ButtonDefault onClick={handleClick} text={buttonText} syncing={isSyncing} />;
}
