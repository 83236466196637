import React from 'react';
import Modal, { ButtonsLayout } from '../modal/modal';
import ReactDOM from 'react-dom';

const styles = require('./confirm_modal.module.scss');

interface Props {
  message: string | React.ReactNode;
  resolve: any;
  title: string;
}

export default class ConfirmModal extends React.Component<Props> {
  modalRef: any;

  constructor(props) {
    super(props);

    this.handleNo = this.handleNo.bind(this);
    this.handleYes = this.handleYes.bind(this);
    this.handleConfirmation = this.handleConfirmation.bind(this);
    this.modalRef = React.createRef();
  }

  handleNo() {
    this.handleConfirmation(false);
  }

  handleYes() {
    this.handleConfirmation(true);
  }

  handleConfirmation(confirmation) {
    this.props.resolve(confirmation);
    this.modalRef.current.close(false);
  }

  render() {
    return (
      <Modal
        className={styles.modal}
        defaultOpen={true}
        cancelButton={true}
        cancelButtonTitle={'No'}
        buttonName={'Yes'}
        buttonsLayout={ButtonsLayout.CancelOk}
        buttonOnClick={this.handleYes}
        onClose={this.handleNo}
        disableOutsideClose={true}
        ref={this.modalRef}
        title={this.props.title}
      >
        <p className={styles.text}>{this.props.message}</p>
      </Modal>
    );
  }

  static show(title: string, message: string | React.ReactNode) {
    return new Promise(res => {
      const root = $('<div></div>');

      $('body').append(root);

      ReactDOM.render(<ConfirmModal title={title} message={message} resolve={res} />, root[0]);
      return true;
    });
  }
}
