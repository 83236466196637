import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'components/general/modal/modal';
import TextInput from 'components/form/text/text';
import Select from 'components/form/select/select';
import ProfessionalExperienceForm from './professional_experience_form';
import Checkbox from 'components/form/checkbox/checkbox';
import { handleRequestError } from 'lib/request_deprecated';
import FormInput from 'components/general/form_input/form_input';
import { cloneDeep } from 'lodash';
import { multiValueStyles } from 'lib/select_styles';
import Button from 'lj_shared/button/button';

const styles = require('./modals.module.scss');

interface Props {
  professionalExperience?: ProfessionalExperienceForm;
  categoriesList?: any;
  handleAddExperience: any;
  handleEditExperience?: any;
  handleDeleteExperience?: any;
  action?: number;
  defaultOpen: boolean;
}

interface State {
  professionalExperience: ProfessionalExperienceForm;
  revision: boolean;
  categoriesList: any;
}

export default class ProfessionalExperienceModal extends React.Component<Props, State> {
  modalRef: any;
  tagRef: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      professionalExperience: new ProfessionalExperienceForm({
        ...this.props.professionalExperience,
      }),
      revision: false,
      categoriesList: this.props.categoriesList,
    };

    this.modalRef = React.createRef();
    this.tagRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.professionalExperience !== prevProps.professionalExperience) {
      this.setState({
        professionalExperience: new ProfessionalExperienceForm({
          ...this.props.professionalExperience,
        }),
      });
    }
  }

  handleSubmit = _event => {
    this.setState({ revision: true });
    if (this.state.professionalExperience.validate()) {
      this.state.professionalExperience
        .save()
        .then((response: { saved: boolean; data: Object; post: boolean }) => {
          if (response.saved && response.post) {
            this.handleCancel();
            this.props.handleAddExperience(response.data);
          } else if (response.saved) {
            this.handleCancel();
            this.props.handleEditExperience(response.data);
          }
          this.setState({ revision: false });
        })
        .catch(e => {
          handleRequestError(e);
          this.setState({ revision: false });
        });
    } else {
      this.setState({ revision: false });
    }
  };

  handleCancel = () => {
    this.modalRef.current.close();
  };

  handleFormChange = (event, input = '') => {
    const update = {};
    if (input === 'currentPosition') {
      update[event.target.name] = event.target.checked;
    } else if (event && event.target) {
      update[event.target.name] = event.target.value;
    } else if (event && event.value != null) {
      update[input] = event.value;
    } else {
      update[input] = event;
    }

    const form = cloneDeep(this.state.professionalExperience);
    form.update(update);

    this.setState({ professionalExperience: form });
  };

  filterOption = (option, inputValue) => {
    const inputValueToUpperCase = inputValue.toUpperCase();

    if (option.label === 'Other') {
      const { categoriesList } = this.state;
      const result = categoriesList.filter(opt =>
        opt.label.toUpperCase().includes(inputValueToUpperCase)
      );
      return result;
    }

    return option.label.toUpperCase().includes(inputValueToUpperCase);
  };

  selectValue = (data, type) => {
    if (data) {
      return type === 'month'
        ? {
            label: this.state.professionalExperience.monthLabel(data),
            value: data,
          }
        : {
            label: data,
            value: data,
          };
    }
  };

  handleDelete = () => {
    this.props.handleDeleteExperience(this.state.professionalExperience.id);
    this.handleCancel();
  };

  render() {
    const contractType = this.state.professionalExperience
      .contractTypeOptions()
      .find(
        c =>
          c.label.replace(/-/g, '').toLowerCase() === this.state.professionalExperience.contractType
      );
    const contractTypeValue = contractType
      ? {
          label: contractType.label,
          value: contractType.value,
        }
      : null;

    return (
      <Modal
        title={`${this.props.action ? 'Edit' : 'Add'} Professional Experience`}
        defaultOpen={this.props.defaultOpen}
        ref={this.modalRef}
        disableOutsideClose={true}
      >
        <FormInput
          name='position'
          label='Position'
          error={this.state.professionalExperience.errors?.position}
          required
        >
          <TextInput
            name='position'
            placeholder='e.g. Fullstack Developer'
            color='tuftsBlue'
            value={this.state.professionalExperience.position}
            onChange={this.handleFormChange}
            class={styles.textInput}
          />
        </FormInput>
        <FormInput
          name='categories'
          label='Category'
          error={this.state.professionalExperience.errors?.categories}
          required
        >
          <Select
            name='categories'
            placeholder='e.g. Ux designer'
            options={this.state.categoriesList}
            color='silverSand'
            styles={multiValueStyles('silverSand')}
            isMulti={true}
            value={this.state.professionalExperience.categories}
            onChange={event => this.handleFormChange(event, 'categories')}
            filterOption={this.filterOption}
          />
        </FormInput>
        <FormInput
          name='company'
          label='Company'
          error={this.state.professionalExperience.errors?.company}
          required
        >
          <TextInput
            name='company'
            placeholder='e.g. Landing.jobs'
            color='tuftsBlue'
            value={this.state.professionalExperience.company}
            onChange={this.handleFormChange}
            class={styles.textInput}
          />
        </FormInput>
        <FormInput
          name='contract_type'
          label='Contract type'
          error={this.state.professionalExperience.errors?.contract_type}
          required
        >
          <Select
            name={'contractType'}
            options={this.state.professionalExperience.contractTypeOptions()}
            placeholder='e.g. Contractor'
            color='silverSand'
            defaultValue={contractTypeValue}
            onChange={event => this.handleFormChange(event, 'contractType')}
          />
        </FormInput>
        <FormInput
          name='start_date'
          label='Start date'
          error={this.state.professionalExperience.errors?.start_date}
          required
        >
          <div className={styles.formRow}>
            <Select
              name={'startDateMonth'}
              wrapperClassName={styles.flexibleSelect}
              options={this.state.professionalExperience.dateMonthOptions()}
              placeholder='Month'
              color='silverSand'
              defaultValue={this.selectValue(
                this.state.professionalExperience.startDateMonth,
                'month'
              )}
              onChange={event => this.handleFormChange(event, 'startDateMonth')}
            />
            <Select
              name={'startDateYear'}
              wrapperClassName={styles.flexibleSelect}
              options={this.state.professionalExperience.dateYearOptions()}
              placeholder='Year'
              color='silverSand'
              defaultValue={this.selectValue(
                this.state.professionalExperience.startDateYear,
                'year'
              )}
              onChange={event => this.handleFormChange(event, 'startDateYear')}
            />
          </div>
        </FormInput>
        <FormInput
          name='end_date'
          label='End date'
          error={this.state.professionalExperience.errors?.end_date}
          required
        >
          <Checkbox
            controlled={false}
            checked={this.state.professionalExperience.currentPosition}
            name='currentPosition'
            label='I currently work in this position'
            value={String(this.state.professionalExperience.currentPosition)}
            onChange={event => this.handleFormChange(event, 'currentPosition')}
          />
          <div className={styles.formRow}>
            {!this.state.professionalExperience.currentPosition ? (
              <>
                <Select
                  name={'endDateMonth'}
                  wrapperClassName={styles.flexibleSelect}
                  options={this.state.professionalExperience.dateMonthOptions()}
                  placeholder='Month'
                  color='silverSand'
                  defaultValue={this.selectValue(
                    this.state.professionalExperience.endDateMonth,
                    'month'
                  )}
                  onChange={event => this.handleFormChange(event, 'endDateMonth')}
                />
                <Select
                  name={'endDateYear'}
                  wrapperClassName={styles.flexibleSelect}
                  options={this.state.professionalExperience.dateYearOptions()}
                  placeholder='Year'
                  color='silverSand'
                  defaultValue={this.selectValue(
                    this.state.professionalExperience.endDateYear,
                    'year'
                  )}
                  onChange={event => this.handleFormChange(event, 'endDateYear')}
                />
              </>
            ) : null}
          </div>
        </FormInput>
        <div className={styles.footer}>
          <div className={styles.buttons}>
            {this.props.handleDeleteExperience && (
              <Button
                buttonColor='silverSand'
                buttonType='border'
                onClick={() => this.handleDelete()}
                otherClasses={styles.button}
              >
                Delete
              </Button>
            )}
            <Button
              buttonColor='tuftsBlue'
              onClick={event => this.handleSubmit(event)}
              otherClasses={styles.button}
              disabled={this.state.revision}
              isButton
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

  static show(
    form,
    handleAddExperience,
    defaultOpen,
    handleEditExperience = null,
    handleDeleteExperience = null,
    action = null
  ) {
    const root = $('<div id="professional-experience-modal-container"></div>');

    $('body').append(root);

    ReactDOM.render(
      <ProfessionalExperienceModal
        professionalExperience={form}
        handleAddExperience={handleAddExperience}
        defaultOpen={defaultOpen}
        handleEditExperience={handleEditExperience}
        handleDeleteExperience={handleDeleteExperience}
        action={action}
      />,
      root[0]
    );
  }
}
