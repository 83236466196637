import React from 'react';
import InlineIcon from 'components/general/inline_icon/inline_icon';
import Icon, { Style } from 'components/general/icon/icon';

const styles = require('./resume_card.module.scss');
const crossIcon = require('iconic/x-thin.svg');

interface Props {
  cardStyle?: string;
  title: string;
  description?: string;
  handleDeleteClick: any;
  id?: string;
  kind?: string;
  url?: string;
}

const ResumeCard = (props: Props) => {
  let cardWrapper = `${styles.card} ${props.cardStyle}`;
  let cardLeft = styles.cardLeft;
  let description = styles.description;
  let icon = styles.icon;

  if (props.kind === 'jobs') {
    cardWrapper = styles.ljWrapper;
    cardLeft = styles.ljCardLeft;
    description = styles.ljDescription;
    icon = styles.ljIcon;
  }

  const renderTitle = () => {
    if (props.kind === 'jobs') {
      return (
        <div className={styles.ljTitle}>
          <Icon className={styles.close} name='document' style={Style.Bold} />
          <a href={props.url} target='_blank' rel='noreferrer'>
            {props.title}
          </a>
        </div>
      );
    }
    return <div className={styles.title}>{props.title}</div>;
  };

  return (
    <div className={cardWrapper} id={props.id}>
      <div className={cardLeft}>
        {renderTitle()}
        <div className={description}>{props.description}</div>
      </div>
      <div className={styles.crossIcon} onClick={props.handleDeleteClick}>
        <InlineIcon path={crossIcon} className={icon} />
      </div>
    </div>
  );
};

export default ResumeCard;
