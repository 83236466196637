import React from 'react';
import FormInput from 'components/general/form_input/form_input';
import { multiValueStyles } from 'lib/select_styles';
import Select from 'components/form/select/select';

export interface Props {
  categories: any;
  error?: string;
  handleFormChange: any;
  options: any;
  optionLimit?: number;
}

export default function AreaInterestsInput(props: Props) {
  const [selectedOptions, setSelectedOptions] = React.useState(0);
  const optionLimitReached = selectedOptions >= props?.optionLimit || false;

  const options = props.options.map(category => ({
    id: category.id,
    value: category.id,
    label: category.name,
  }));

  return (
    <FormInput
      name='categories'
      label='What are your areas of interest?'
      sublabel='Please choose up to 3 areas of interest.'
      error={props.error}
      required={true}
    >
      <Select
        name='categories'
        placeholder='e.g. Ux designer'
        options={options}
        color='silverSand'
        styles={multiValueStyles('silverSand')}
        isMulti={true}
        value={props.categories}
        onChange={event => {
          props.handleFormChange(event, 'categories');
          setSelectedOptions(event.length);
        }}
        isOptionDisabled={() => optionLimitReached}
      />
    </FormInput>
  );
}
