import 'lib/globals';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { apolloClient, errorsToHash, gql } from 'lib/graphql';
import { handleRequestError } from 'lib/request';
import { orderBy } from 'lodash';

const AVATAR_URL = 'https://assets.landing.jobs/default_avatars/avatar0.png';

const GET_DATA_GQL = gql`
  query {
    currentCompany {
      employees {
        email
        firstName
        id
        isAdmin
        lastName
        user {
          avatarUrl
        }
      }
      invitations {
        email
        id
        isAdmin
      }
    }
  }
`;

export const CREATE_INVITE_GQL = gql`
  mutation ($email: String!, $isAdmin: Boolean!) {
    company {
      createInvitation(email: $email, isAdmin: $isAdmin) {
        errors {
          field
          message
        }
        resource {
          email
          id
          isAdmin
        }
      }
    }
  }
`;

export function usersManagementPageViewModel() {
  const [state, setState] = useState({
    loading: true,
    users: [],
  });

  useEffect(() => {
    if (state.loading) loadUsers(setState).catch(handleRequestError);
  });

  const onInviteSent = useCallback(invitation => {
    setState(currentState => {
      const users = sortUsers(currentState.users.concat([invitationToUser(invitation)]));
      return { ...currentState, users };
    });
  }, []);

  const onDelete = userEmail => {
    setState(currentState => {
      const users = currentState.users.filter(user => user.email !== userEmail);
      return { ...currentState, users };
    });
  };

  return {
    onInviteSent,
    onDelete,
    state,
  };
}

const userName = user => {
  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  } else if (user.firstName) {
    return user.firstName;
  } else if (user.lastName) {
    return user.lastName;
  } else {
    return null;
  }
};

async function loadUsers(setState) {
  const { data } = await apolloClient.query({ query: GET_DATA_GQL });
  let { employees, invitations } = data.currentCompany;

  employees = employees.map(e => ({
    avatarURL: e.user.avatarUrl,
    email: e.email,
    key: `Employee ${e.id}`,
    name: userName(e),
    role: e.isAdmin ? 'Admin' : 'Member',
    status: 'Active',
  }));
  invitations = invitations.map(invitationToUser);
  const users = sortUsers(invitations.concat(employees));
  setState(state => ({ ...state, loading: false, users }));
}

function invitationToUser(invitation) {
  return {
    avatarURL: AVATAR_URL,
    email: invitation.email,
    key: `Invitation ${invitation.id}`,
    name: '',
    role: invitation.isAdmin ? 'Admin' : 'Member',
    status: 'Invited',
  };
}

function sortUsers(users) {
  return orderBy(users, ['name', 'email']);
}

export function inviteUserSectionViewModel(onSent) {
  const [state, setState] = useState({ errors: {}, submitting: false });
  const emailRef = useRef(null);
  const roleRef = useRef(null);

  const onSend = useCallback(
    () => handleSendInvitation(emailRef, onSent, roleRef, setState),
    [emailRef, onSent, roleRef]
  );

  return {
    emailRef,
    onSend,
    roleRef,
    state,
  };
}

async function handleSendInvitation(emailRef, onSent, roleRef, setState) {
  setState(state => ({ ...state, submitting: true }));
  const variables = {
    email: emailRef.current.getValue(),
    isAdmin: roleRef.current.getSelectedValue(),
  };
  const { data } = await apolloClient.mutate({
    mutation: CREATE_INVITE_GQL,
    variables,
  });
  const { errors, resource } = data.company.createInvitation;
  setState({ errors: errorsToHash(errors), submitting: false });

  if (resource) {
    emailRef.current.clear();
    window.Alerts.notice(`Invitation sent to ${variables.email}.`);
    onSent(resource);
  }
}
