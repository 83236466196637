import React from 'react';

import { User } from '__models__/gql/all';
import Icon, { Size } from 'components/general/icon/icon';
import JobList from './JobList';
import { isMobile } from 'lib/utils';
import JobListInfinite from './JobListInfinite';
import { JobResult, JsonBookmark } from './types';
const receiveImg = require('images/receive.png');
const styles = require('./JobSearchResults.module.scss');

interface Props {
  page: number;
  lastPage: boolean;
  paginationControl: string;
  profileCompleted: boolean;
  applications: number[];
  bookmarks: JsonBookmark[];
  handshakes?: { [key: number]: number };
  jobResults: JobResult[];
  suggestedJobs?: JobResult[];
  interestingJobs?: JobResult[];
  bonusJobs?: JobResult[];
  user?: Partial<User>;
  suggestionRelevantPath: string;
  suggestionNonRelevantPath: string;
  profilePath?: string;
  maxHiringBonus?: number;
  isSearching: boolean;
  jobRequestPath?: string;
  urlParams?: string;
}

const JobSearchResults = (props: Props) => {
  const {
    page,
    lastPage,
    paginationControl,
    profileCompleted,
    applications,
    bookmarks,
    handshakes,
    jobResults,
    suggestedJobs,
    interestingJobs,
    bonusJobs,
    suggestionRelevantPath,
    suggestionNonRelevantPath,
    user,
    profilePath,
    maxHiringBonus,
    isSearching,
    jobRequestPath,
    urlParams,
  } = props;

  const isMobileDevice = isMobile();

  const JobOffers = () => {
    if (jobResults.length === 0) {
      return (
        <div className={styles.zeroResultsContainer}>
          <div className={styles.zeroResults}>0 results</div>
          <div>None of our job opportunities match your search criteria.</div>
          <div>In the meantime, check the opportunities we gathered for you.</div>
        </div>
      );
    }

    return (
      <>
        <JobList
          applications={applications}
          bookmarks={bookmarks}
          handshakes={handshakes}
          paginationControl={<div dangerouslySetInnerHTML={{ __html: paginationControl }} />}
          jsonJobs={jobResults}
          user={user}
        />
        {lastPage && user.type === 'TalentUser' && (
          <div className={styles.subscribeSearch}>
            Didn't find anything you like?
            <a className='ld-primary-link' href={void 0} id='subscribe_cta'>
              Subscribe
            </a>
            and we'll warn you when something fresh comes in.
          </div>
        )}
      </>
    );
  };

  const SuggestedJobs = () => {
    if (!suggestedJobs?.length) {
      return null;
    }

    const header = (
      <div className={styles.listHeader}>
        <Icon name='flame' size={Size.MediumBig} color='flamingo' />
        <h2>{isSearching ? 'Other Hot Jobs' : 'Your hottest job matches'}</h2>
        <span>- based on your profile.</span>
        {!profileCompleted && (
          <>
            <a href={profilePath}>Update your profile</a>
            <span>before applying to a job offer.</span>
          </>
        )}
      </div>
    );

    const footer = (
      <div className={styles.listFooter}>
        <span>Did these jobs match what you were looking for?</span>
        <a
          href={suggestionRelevantPath}
          className='lj-button lj-button--tuftsBlueMidLight lj-button--xSmall'
        >
          Yes, very much
        </a>
        <a
          href={suggestionNonRelevantPath}
          className='lj-button lj-button--tuftsBlueMidLight lj-button--xSmall lj-button--border'
        >
          No, not at all
        </a>
      </div>
    );

    return (
      <JobList
        header={header}
        footer={!isSearching && footer}
        applications={applications}
        bookmarks={bookmarks}
        handshakes={handshakes}
        jsonJobs={suggestedJobs}
        isHottest={true}
        user={user}
        showInfoTags={true}
      />
    );
  };

  const InterestingJobs = () => {
    if (!interestingJobs?.length) {
      return null;
    }

    const header = (
      <div className={styles.intestingJobsHeader}>
        <div className={styles.listHeader}>
          <Icon name='rocketLaunch' size={Size.MediumBig} color='tuftsBlue' />
          <h2>Interesting jobs for you</h2>
        </div>
        <div>This is based on your areas of interest</div>
        <div className={styles.resultsCount}>
          <span>{`${interestingJobs.length}`}</span>
          <span>{`result${interestingJobs.length > 1 && 's'}`}</span>
        </div>
      </div>
    );

    return (
      <JobList
        header={header}
        applications={applications}
        bookmarks={bookmarks}
        handshakes={handshakes}
        jsonJobs={interestingJobs}
        user={user}
        showInfoTags={true}
      />
    );
  };

  const BonusJobs = () => {
    if (!bonusJobs?.length) {
      return null;
    }

    const header = (
      <div className={styles.bonusListHeader}>
        <div>You're in for a treat! Get hired through us and receive up to {maxHiringBonus} €</div>
        <img alt='bonus icon' className={styles.bonusIcon} src={receiveImg} />
      </div>
    );
    return (
      <div className={styles.bonusJobs}>
        <JobList
          header={header}
          applications={applications}
          bookmarks={bookmarks}
          handshakes={handshakes}
          jsonJobs={bonusJobs}
          user={user}
          showInfoTags={true}
        />
      </div>
    );
  };

  const InfiniteJobScroll = () => {
    return (
      <JobListInfinite
        page={page}
        lastPage={lastPage}
        applications={applications}
        bookmarks={bookmarks}
        handshakes={handshakes}
        user={user}
        jobResults={jobResults}
        jobRequestPath={jobRequestPath}
        urlParams={urlParams}
      />
    );
  };

  return (
    <>
      {(!isSearching || isMobileDevice) && <SuggestedJobs />}
      <BonusJobs />
      {isMobileDevice ? <InfiniteJobScroll /> : <JobOffers />}
      {!isMobileDevice && isSearching && <SuggestedJobs />}
      <InterestingJobs />
    </>
  );
};

export default JobSearchResults;
