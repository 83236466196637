import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import CandidateSkillsDisplay from './CandidateSkillsDisplay';
import SmallProfileDisplayList from '../../../smallProfileDisplay/SmallProfileDisplayList';

function createProps(numbers: number[] = []) {
  const skills = numbers.map(n => ({
    experienceLevel: n,
    id: n,
    tag: `tag: ${n}`,
  }));

  return { color: 'tuftsBlue', skills };
}

let props;
let wrapped: ShallowWrapper;

describe('CandidateSkillsDisplay renders empty', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<CandidateSkillsDisplay {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('renders no list', () => {
    expect(wrapped.find(SmallProfileDisplayList)).toHaveLength(0);
  });
});

describe('CandidateSkillsDisplay renders top only', () => {
  beforeEach(() => {
    props = createProps(Array.from(Array(4).keys()));
    wrapped = shallow(<CandidateSkillsDisplay {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('renders 1 list', () => {
    expect(wrapped.find(SmallProfileDisplayList)).toHaveLength(1);
  });
});
