import React from 'react';
import FormInput from 'components/general/form_input/form_input';
import { startYears } from 'components/registration/registration_utils/registration_utils';
import Select from 'components/form/select/select';
const styles = require('./ExperienceYearsInput.module.scss');

interface Props {
  error: string;
  startYear: any;
  handleFormChange: Function;
  startYearsList: any;
}

export default function ExperienceYearsInput(props: Props) {
  return (
    <div className={styles.yearsInput}>
      <FormInput
        name='startYear'
        label='When did you start working in the tech area?'
        error={props.error}
        required={true}
      >
        <Select
          name='start_year'
          placeholder='e.g. 2015'
          options={startYears(props.startYearsList)}
          color='silverSand'
          value={props.startYear}
          onChange={event => props.handleFormChange(event, 'startYear')}
        />
      </FormInput>
    </div>
  );
}
