import 'lib/globals';
import { capitalize } from 'lodash/string';
import classNames from 'classnames';
import * as React from 'react';
import { startCase } from 'lodash';

import { applicationStateForEmployer } from 'lib/labels';
import Button from 'lj_shared/button/button';
import EmailPreview from 'components/general/email_preview/email_preview';
import InlineIcon from 'components/general/inline_icon/inline_icon';
import Modal from 'components/general/modal/modal';
import FormError from 'components/form/form_error/form_error';
import * as Models from './models';
import { postJSON, handleRequestError, patchJSON, deleteJSON } from 'lib/request_deprecated';

const iconPaperPlane = require('images/icons/paper-plane.svg');
const iconTrash = require('iconic/trash.svg');
const Settings = require('settings.json');
const styles = require('./message_modal.module.scss');

interface Props {
  commitChanges: boolean;
  step: Models.Step;
  message: Models.Message;
  close: any;
  readOnly?: boolean;
  exists?: boolean;
  urls: {
    preview: string;
    create: string;
    update: string;
  };
  color?: string;
}

interface State {
  errors: any;
  message: Models.Message;
}

export default class MessageModal extends React.Component<Props, State> {
  readonly PLACEHOLDERS = ['{{candidate_first_name}}', '{{job_opening}}', '{{company}}'];
  modalRef: any;
  textRef: any;

  cancel: any;
  save: any;
  destroy: any;
  color: string;

  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      message: this.props.message || {
        id: null,
        text: getPlaceholder(this.props.step),
      },
    };

    this.handlePlaceholder = this.handlePlaceholder.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);

    this.cancel = this.handleClose.bind(this, 'cancel');
    this.save = this.handleClose.bind(this, 'save');
    this.destroy = this.handleClose.bind(this, 'destroy');
    this.renderModal = this.renderModal.bind(this);
    this.color = this.props.color || 'ripePlum';

    this.modalRef = React.createRef();
    this.textRef = React.createRef();
  }

  open() {
    this.modalRef.current.open();
  }

  close() {
    this.modalRef.current.close();
  }

  handlePlaceholder(event) {
    const placeholder = event.currentTarget.textContent;
    const input = this.textRef.current;

    input.value =
      input.value.substring(0, input.selectionStart) +
      placeholder +
      input.value.substring(input.selectionStart);
  }

  handleTextChange(event) {
    const value = event.target.value;

    if (this.state.message['text'] !== value) {
      this.setState(prevState => {
        const message = prevState.message;
        message['text'] = value;

        return { message };
      });
    }
  }

  isNew() {
    return !this.props.exists && !this.state.message.id;
  }

  render() {
    if (this.props.readOnly) {
      return (
        <>
          {this.renderModal()}
          <a className={styles.openerLink} onClick={this.open}>
            Automated message
          </a>
        </>
      );
    } else {
      return this.renderModal();
    }
  }

  renderModal() {
    const errors = this.state.errors;
    const step = this.props.step;
    const stepKind = step.kind;
    const text = this.state.message.text || '';
    const subTitle =
      stepKind === 'fixed'
        ? applicationStateForEmployer(this.props.step.name)
        : startCase(stepKind);

    return (
      <Modal className={styles.modal} ref={this.modalRef} title={`Automated message: ${subTitle}`}>
        <form className='js-messageModalForm'>
          <p>
            <InlineIcon className={styles.iconPaperPlane} path={iconPaperPlane} />
            Note: This message will be sent out automatically to every candidate that you'll be
            moving to this step.
          </p>
          <p>
            Placeholders:
            {this.renderTooltip('mobile')}
            <span className={styles.placeholders}>{this.renderPlaceholders()}</span>
            {this.renderTooltip('desktop')}
          </p>
          <div className={styles.textInputGroup}>
            <textarea
              defaultValue={text}
              className={styles.textInput}
              maxLength={Settings.textarea.large.length}
              name='text'
              readOnly={this.props.readOnly}
              ref={this.textRef}
              required={true}
              onChange={this.handleTextChange}
            />
            {errors && errors.text && <FormError text={errors['text'][0]} />}
          </div>
        </form>

        <EmailPreview
          hide='.js-messageModalForm'
          form='.js-messageModalForm'
          title='Preview automated message'
          url={this.props.urls.preview}
        />

        {this.renderButtons()}
      </Modal>
    );
  }

  renderButtons() {
    if (this.props.readOnly) {
      return (
        <div className={styles.buttons}>
          <div />
          <div className={styles.buttonsRight}>
            <Button otherClasses={styles.button} buttonColor={this.color} onClick={this.cancel}>
              OK
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.buttons}>
          <div className={styles.buttonsLeft}>
            {!this.isNew() && (
              <Button
                buttonColor='silverSand'
                buttonType='border'
                onClick={this.destroy}
                otherClasses={styles.button}
              >
                <InlineIcon className={styles.iconTrash} path={iconTrash} />
                Delete
              </Button>
            )}
          </div>
          <div className={styles.buttonsRight}>
            <Button otherClasses={styles.button} buttonColor={this.color} onClick={this.save}>
              {this.isNew() ? 'Add automated message' : 'Save'}
            </Button>
            <Button
              otherClasses={styles.button}
              buttonColor={this.color}
              buttonType='border'
              onClick={this.cancel}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    }
  }

  renderTooltip(screen) {
    const klass = classNames(
      'ld-tooltip-item ld-helper ld-green-helper ld-arrow-down',
      styles.tooltip,
      styles[`is${capitalize(screen)}`]
    );
    return (
      <span className={klass}>
        <strong>?</strong>
        <span className='ld-tooltip'>
          Click on a placeholder to add it to the message. Our system replaces it with the right
          name automatically.
        </span>
      </span>
    );
  }

  renderPlaceholders() {
    return this.PLACEHOLDERS.map(p => (
      <span className={styles.placeholder} key={p} onClick={this.handlePlaceholder}>
        {p}
      </span>
    ));
  }

  handleClose(action) {
    let message;
    let errors;
    let body;
    const step = this.props.step;

    if (!this.props.readOnly && action === 'save') {
      message = this.state.message;

      if (step.kind === 'fixed') {
        body = {
          message,
          job_ad_id: step.jobId,
          state_name: step.name,
        };
      } else {
        body = { step_id: step.id, message };
      }

      errors = null; // TO-DO validate form

      // Set the new state
      this.setState(() => {
        return { message, errors };
      });

      if (!errors) {
        // If validations pass
        if (message.id) {
          // If update
          if (this.props.commitChanges || this.props.step.kind === 'fixed') {
            patchJSON(this.props.urls.update.replace('~id', message.id), body)
              .then(_response => this.props.close(message))
              .catch(handleRequestError);
          } else {
            this.props.close(message, true);
          }
        } else {
          // If Create
          if (this.props.commitChanges || this.props.step.kind === 'fixed') {
            postJSON(this.props.urls.create, body)
              .then(response => {
                message.id = response.new_id || response.id;
                this.props.close(message);
              })
              .catch(handleRequestError);
          } else {
            this.props.close(message, true);
          }
        }
      }
    } else if (!this.props.readOnly && action === 'destroy') {
      // If we want to destroy the message
      let originalID;

      if (this.state.message.id) {
        originalID = this.state.message.id.toString();
      }

      message = {
        id: null,
        text: getPlaceholder(this.props.step),
      };

      this.setState(() => {
        return { message, errors };
      });

      if ((this.props.commitChanges || this.props.step.kind === 'fixed') && originalID) {
        deleteJSON(this.props.urls.update.replace('~id', originalID))
          .then(_e => this.props.close(null))
          .catch(handleRequestError);
      } else {
        this.props.close(null, true);
      }
    } else {
      // Return the original message
      message = this.props.message;
      this.props.close(null);
    }
  }
}

function getPlaceholder(step) {
  const kind = step.kind;

  if (kind === 'fixed') {
    return step.name === 'unreviewed' ? APPLIED_PLACEHOLDER : REVIEWING_PLACEHOLDER;
  } else {
    return kind === 'tech_assessment' ? ASSESSMENT_PLACEHOLDER : INTERVIEW_PLACEHOLDER;
  }
}

const ASSESSMENT_PLACEHOLDER = `Dear {{candidate_first_name}},

Congratulations,
You have been moved to the next phase in our hiring process.
As a next step, we would like to request a tech assessment from you.
You can find the assessment here: [Paste your link here]
Please send it back to us in [Add a number] days.

Good luck!
{{company}}`;

const INTERVIEW_PLACEHOLDER = `Dear {{candidate_first_name}},

Congratulations,

Your application caught our attention and we are interested in setting up an interview with you!

Please let us know when you are available for the interview by replying to this message.

Thank you,
{{company}}`;

const APPLIED_PLACEHOLDER = `Dear {{candidate_first_name}}

Thank you for your application for {{job_opening}}.
We will review it as soon as possible.

Talk to you soon!
{{company}}`;

const REVIEWING_PLACEHOLDER = `Dear {{candidate_first_name}}

We just wanted to let you know, we are currently reviewing your application for {{job_opening}}.
If we need some additional information we’ll get in touch.

Have a good day!
{{company}}`;
