import React, { useState, useRef } from 'react';
import * as ReactDOM from 'react-dom';
import Modal, { ButtonsLayout } from '../general/modal/modal';
import Checkbox from '../form/checkbox/checkbox';
import * as Cookies from 'js-cookie';
import TextInput from 'components/form/text/text';
import { range } from 'lib/range';
import { postJSON, handleRequestError } from 'lib/request_deprecated';

const styles = require('./send_application_by_email_modal.module.scss');

const DONT_ASK_AGAIN = 'employer-skip_send_application_by_email_question';
const ALWAYS_MOVE_TO_IN_PROGRESS = 'employer-always-move-to-in-progress';

const RADIO_OPTIONS = ['Yes, always', 'Yes, only this time', 'Not now'];
export interface Props {
  sendUrl: string;
  topFilter: string;
  moveToStep: Function;
  hiringSteps: any;
  application: any;
}

interface State {
  emailSent: boolean;
  currentRadioOption: string;
  email: string;
  message: string;
}

type StateKeys = keyof State;

class SendApplicationByEmailModal extends React.Component<Props, State> {
  modalRef: any;
  scrollRef: any;
  messageRef: any;

  constructor(props: Props) {
    super(props);
    this.modalRef = React.createRef();
    this.scrollRef = React.createRef();
    this.state = {
      emailSent: false,
      currentRadioOption: null,
      email: null,
      message: null,
    };

    this.handleSend = this.handleSend.bind(this);
    this.handleDontAskAgain = this.handleDontAskAgain.bind(this);
    this.handleChangeRadioButtons = this.handleChangeRadioButtons.bind(this);
    this.handleChangeTextInput = this.handleChangeTextInput.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleSend(event) {
    event.preventDefault();
    if (this.state.email !== null) {
      postJSON(this.props.sendUrl, {
        email_application_form: {
          address: this.state.email,
          message: this.state.message,
        },
      })
        .then(e => {
          if (e.notice) {
            window.Alerts.notice(e.notice);
          } else {
            window.Alerts.alert(e.alert);
          }
        })
        .catch(handleRequestError);
      this.setState({ message: null, emailSent: true });

      if (Cookies.get(ALWAYS_MOVE_TO_IN_PROGRESS) === 'true') {
        this.props.moveToStep(this.props.hiringSteps[0], this.props.application);
      }
    } else {
      window.alert("Email can't be empty");
    }
  }

  handleSave(event) {
    if (this.state.currentRadioOption != null) {
      event.preventDefault();
      if (
        this.state.currentRadioOption === 'Yes, always' ||
        this.state.currentRadioOption === 'Yes, only this time'
      ) {
        this.props.moveToStep(this.props.hiringSteps[0], this.props.application);
      }
      if (this.state.currentRadioOption === 'Yes, always') {
        Cookies.set(ALWAYS_MOVE_TO_IN_PROGRESS, 'true');
      }
      this.modalRef.current.close();
    }
  }

  handleDontAskAgain(event) {
    Cookies.set(DONT_ASK_AGAIN, event.target.checked);
  }
  handleChangeRadioButtons(event) {
    this.setState({
      currentRadioOption: event.target.value,
    });
  }

  handleChangeTextInput(event, key: StateKeys) {
    event.preventDefault();
    this.setState({ [key]: event.target.value } as Pick<State, keyof State>);
  }

  sendEmailModal() {
    return (
      <Modal
        defaultOpen={true}
        ref={this.modalRef}
        title='Send application PDF by email'
        buttonName='Send'
        buttonOnClick={this.handleSend}
        buttonsLayout={ButtonsLayout.OkOnly}
      >
        <div className='modal-structure'>
          Email
          <TextInput
            name='email'
            color='ripePlum'
            required={true}
            onChange={event => this.handleChangeTextInput(event, 'email')}
            inputRef={this.messageRef}
          />
          <br />
          Message
          <TextInput
            name='email'
            color='ripePlum'
            required={true}
            textarea={true}
            onChange={event => this.handleChangeTextInput(event, 'message')}
            inputRef={this.messageRef}
          />
        </div>
      </Modal>
    );
  }

  notInInboxOrDontAskAgain() {
    return (
      <Modal defaultOpen={true} ref={this.modalRef} title='Send application PDF by email'>
        <div className='modal-structure'>
          <div className={styles.row}>
            Application sent to <strong>{this.state.email}</strong>
          </div>
        </div>
      </Modal>
    );
  }

  inInbox() {
    return (
      <Modal
        defaultOpen={true}
        ref={this.modalRef}
        title='Send application PDF by email'
        buttonName='Save'
        buttonOnClick={this.handleSave}
        buttonColor={this.state.currentRadioOption === null ? 'silverSand' : 'ripePlum'}
        buttonsLayout={ButtonsLayout.OkOnly}
      >
        <div className='modal-structure'>
          <div className={styles.row}>
            Application sent to <strong>{this.state.email}</strong>
          </div>{' '}
          <div className={styles.row}>
            Would ou like to mark this application as "<strong>In progress</strong>"?
            <div className={styles.radioButtons}>
              {range(0, 2).map(i => (
                <label key={i} className={styles.radioButtonLabel}>
                  <input
                    type='radio'
                    value={RADIO_OPTIONS[i]}
                    checked={this.state.currentRadioOption === RADIO_OPTIONS[i]}
                    onChange={this.handleChangeRadioButtons}
                  />
                  <span className={styles.checkmark} />
                  {RADIO_OPTIONS[i]}
                </label>
              ))}
            </div>
          </div>
          <div>
            <Checkbox
              name='send_application_by_email'
              label="Don't ask me again"
              controlled={false}
              onChange={this.handleDontAskAgain}
            />
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    return this.state.emailSent
      ? Cookies.get(DONT_ASK_AGAIN) === 'true' ||
        Cookies.get(ALWAYS_MOVE_TO_IN_PROGRESS) === 'true' ||
        this.props.topFilter !== 'inbox'
        ? this.notInInboxOrDontAskAgain()
        : this.inInbox()
      : this.sendEmailModal();
  }

  static show(sendUrl, topFilter, moveToStep, hiringSteps, application) {
    return new Promise<void>(resolve => {
      const root = $('<div></div>');

      function onDone() {
        root.remove();
        resolve();
      }

      $('body').append(root);

      ReactDOM.render(
        <SendApplicationByEmailModal
          sendUrl={sendUrl}
          topFilter={topFilter}
          moveToStep={moveToStep}
          hiringSteps={hiringSteps}
          application={application}
        />,
        root[0]
      );
    });
  }
}

export default SendApplicationByEmailModal;
