import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import UnicornWarning from './UnicornWarning';
import Icon, { Style, Size } from 'components/general/icon/icon';

const createProps = (value: number) => ({ value });
const unicornText = 'Oops, little chances of finding candidates with that set of skills';
const goodText = 'Good chances of finding potential candidates';
const greatText = 'Great chances of finding potential candidates';

let props;
let wrapped: ShallowWrapper;
let textDiv;

describe('Unicorn renders Unicorn', () => {
  beforeEach(() => {
    props = createProps(0);
    wrapped = shallow(<UnicornWarning {...props} />);
    textDiv = wrapped.find('.text-red');
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('has 5 icons', () => {
    expect(wrapped.find(Icon)).toHaveLength(5);
  });

  it('has one Red Unicorn', () => {
    expect(wrapped.find('.background-red')).toHaveLength(1);
  });

  it('has one Red Label', () => {
    expect(textDiv).toHaveLength(1);
  });

  it('has correct Label', () => {
    expect(textDiv.text()).toMatch(unicornText);
  });
});

describe('Unicorn renders good result', () => {
  const n = 1 + Math.round(Math.random()); // 1 or 2
  beforeEach(() => {
    props = createProps(n);
    wrapped = shallow(<UnicornWarning {...props} />);
    textDiv = wrapped.find('.text-blue');
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('has 5 icons', () => {
    expect(wrapped.find(Icon)).toHaveLength(5);
  });

  it('has n + 1 Blue Persons', () => {
    expect(wrapped.find('.background-blue')).toHaveLength(n + 1);
  });

  it('has one Blue Label', () => {
    expect(textDiv).toHaveLength(1);
  });

  it('has correct Label', () => {
    expect(textDiv.text()).toMatch(goodText);
  });
});

describe('Unicorn renders great result', () => {
  const n = 3 + Math.round(Math.random()); // 3 or 4
  beforeEach(() => {
    props = createProps(n);
    wrapped = shallow(<UnicornWarning {...props} />);
    textDiv = wrapped.find('.text-green');
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('has 5 icons', () => {
    expect(wrapped.find(Icon)).toHaveLength(5);
  });

  it('has n + 1 Green persons', () => {
    expect(wrapped.find('.background-green')).toHaveLength(n + 1);
  });

  it('has one Green Label', () => {
    expect(textDiv).toHaveLength(1);
  });

  it('has correct Label', () => {
    expect(textDiv.text()).toMatch(greatText);
  });
});
