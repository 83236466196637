import React from 'react';
import AccordionGroups from 'components/general/accordion_groups/accordion_groups';
import PlacementCard from './placement_card';

const styles = require('./placements.module.scss');

interface PlacementData {
  companyName: string;
  endDate: string;
  expectedDuration: string;
  id: string;
  projectName: string;
  startDate: string;
  status: string;
}

interface Props {
  placements: PlacementData[];
}

export default function Placements(props: Props) {
  return (
    <AccordionGroups
      accordionHeaderClassName={styles.accordionHeader}
      getGroupName={getGroupName}
      isGroupOpen={isOpen}
      items={props.placements}
    >
      {placement => (
        <PlacementCard className={styles.placement} key={placement.projectName} {...placement} />
      )}
    </AccordionGroups>
  );
}

function isOpen(name) {
  return name === 'Active';
}

function getGroupName(placement) {
  return statusLabel(placement.status);
}

function statusLabel(status) {
  switch (status) {
    case 'active':
      return 'Active';
    case 'closed':
      return 'Closed';
    case 'draft':
      return 'Draft';
  }
}
