import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import AddGasButton from './AddGasButton';
import Button from 'lj_shared/button/button';

const createProps = () => ({
  application_id: 1,
});

let props;
let wrapped: ShallowWrapper;

describe('Add GAS button', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<AddGasButton {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('has a Button', () => {
    expect(wrapped.find(Button)).toHaveLength(1);
  });
});
