import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import WhatsNewModalItem from './WhatsNewModalItem';
import Modal from '../general/modal/modal';

const createProps = () => ({});

let props;
let wrapped: ShallowWrapper;

describe("What's new Modal Item", () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<WhatsNewModalItem {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('has a Modal', () => {
    expect(wrapped.find(Modal).length).toEqual(1);
  });
});

test.todo('add more tests');
