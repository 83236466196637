import React, { useState } from 'react';
import classNames from 'classnames';
import Dropdown from './Dropdown';

const styles = require('./SelectWithTags.module.scss');

type dropDownOption = {
  value: string;
  label: string;
};

interface Props {
  label: string;
  subLabel?: string;
  placeholder?: string;
  isRequired: boolean;
  data: string[];
  selectedData?: string[];
  inputId: string;
  inputName: string;
}

const transformData = (data: string[]): dropDownOption[] =>
  data.map(d => ({ value: d.toLowerCase(), label: d }));

const SelectWithTags = (props: Props) => {
  const { label, subLabel, placeholder, data, selectedData, isRequired, inputId, inputName } =
    props;
  const [currentData, setCurrentData] = useState<dropDownOption[]>(
    transformData(selectedData) ?? []
  );

  return (
    <>
      <div className={styles.searchHeader}>
        <div className={styles.labelContainer}>
          <label className={classNames(styles.label, 'ld-form-label')}>{label}</label>
          {isRequired && <span className={classNames(styles.required, 'ld-form-required')}>*</span>}
          <p className={classNames(styles.subLabel)}>{subLabel}</p>
        </div>
      </div>
      <Dropdown
        placeHolder={placeholder ?? 'Select'}
        options={transformData(data)}
        selectedData={transformData(selectedData)}
        onChange={setCurrentData}
      />
      {
        <input
          type='hidden'
          id={inputId}
          name={inputName}
          value={JSON.stringify(currentData.map(v => v.label))}
        />
      }
    </>
  );
};

export default SelectWithTags;
