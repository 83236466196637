import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { JobAd } from '../../__models__/models';
import Modal from '../general/modal/modal';
import './jobs_share_modal.scss';
import InlineIcon from '../general/inline_icon/inline_icon';

const Social = require('iconic/social.svg');
interface Props {
  jobAd: JobAd;
}

class ShareModal extends React.Component<Props> {
  modalRef: any;
  scrollRef: any;

  constructor(props: Props) {
    super(props);
    this.modalRef = React.createRef();
    this.scrollRef = React.createRef();
  }
  handleLinkedinClick = _event => {
    window.open(
      this.props.jobAd.attributes.share_urls.linkedin_url,
      'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600'
    );
    return false;
  };
  handleFacebookClick = _event => {
    window.open(
      this.props.jobAd.attributes.share_urls.facebook_url,
      'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600'
    );
    return false;
  };
  handleTwitterClick = _event => {
    window.open(
      this.props.jobAd.attributes.share_urls.twitter_url,
      'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600'
    );
    return false;
  };

  render() {
    return (
      <Modal defaultOpen={true} ref={this.modalRef} title='SHARE'>
        <div className='modal-structure'>
          <div className='inside-layer first-row' onClick={this.handleLinkedinClick}>
            <div className='share-symbol hover-color-change'>
              <InlineIcon alt='linkedin' path={Social} data={{ type: 'linkedin' }} />
            </div>{' '}
            <div className='share-text hover-color-change lj-clickable'> Share on Linkedin</div>
          </div>
          <div className='inside-layer' onClick={this.handleFacebookClick}>
            <div className='share-symbol hover-color-change'>
              <InlineIcon alt='facebook' path={Social} data={{ type: 'facebook' }} />
            </div>{' '}
            <div className='share-text hover-color-change lj-clickable'> Share on Facebook</div>
          </div>
          <div className='inside-layer last-row' onClick={this.handleTwitterClick}>
            <div className='share-symbol hover-color-change'>
              <InlineIcon alt='twitter' path={Social} data={{ type: 'twitter' }} />
            </div>{' '}
            <div className='share-text hover-color-change lj-clickable'> Share on Twitter</div>
          </div>
        </div>
      </Modal>
    );
  }
  static show(jobAd) {
    return new Promise<void>(resolve => {
      const root = $('<div></div>');

      function onDone() {
        root.remove();
        resolve();
      }

      $('body').append(root);

      ReactDOM.render(<ShareModal jobAd={jobAd} />, root[0]);
    });
  }
}

export default ShareModal;
