import BaseForm from './base_form';

class ProfessionalExperienceForm extends BaseForm {
  id: number;
  position: string;
  categories: { id: number; value: number; label: string }[];
  company: string;
  contractType: string;
  startDateMonth: number;
  startDateYear: number;
  endDateMonth: number;
  endDateYear: number;
  description: Text;
  currentPosition: boolean;
  errors: {
    categories: string;
    company: string;
    position: string;
    start_date: string;
    end_date: string;
    contract_type: string;
  };

  constructor(args) {
    super(args);

    if (!this.errors) {
      this.errors = {
        categories: '',
        company: '',
        position: '',
        start_date: '',
        end_date: '',
        contract_type: '',
      };
    }

    Object.keys(args).forEach(key => {
      let error;
      switch (key) {
        case 'startDateMonth':
        case 'startDateYear':
          error = 'start_date';
          break;
        case 'endDateMonth':
        case 'endDateYear':
        case 'currentPosition':
          error = 'end_date';
          break;
        case 'contractType':
          error = 'contract_type';
          break;
        default:
          error = key;
      }
      delete this.errors[error];
    });

    Object.keys(args).map(k => {
      this[k] = args[k];
    });
  }

  validate() {
    if (this.categories == null || this.categories.length === 0) {
      this.errors['categories'] = 'Please choose at least one category';
      return false;
    } else {
      return true;
    }
  }

  dateYearOptions() {
    const currentYear = new Date().getFullYear();
    const range = (start, stop, step) =>
      Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
    const years = range(currentYear, currentYear - 100, -1);
    return years.map(year => ({
      value: year,
      label: year,
    }));
  }

  monthLabel(month) {
    const labels = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return labels[month - 1];
  }

  dateMonthOptions() {
    const months = Array.from(Array(12).keys()).map(i => i + 1);

    return months.map(month => ({
      value: month,
      label: this.monthLabel(month),
    }));
  }

  contractTypeOptions() {
    const options = [
      { label: 'Full-time', value: 0 },
      { label: 'Freelance', value: 1 },
      { label: 'Contractor', value: 2 },
      { label: 'Staffing', value: 3 },
    ];

    return options.map(option => ({
      value: option.value,
      label: option.label,
    }));
  }

  convertDate(year, month) {
    if (year && month) {
      return new Date(year, month - 1, 10).getTime() / 1000;
    } else {
      return null;
    }
  }

  serialize() {
    return {
      position: this.position,
      category_ids: this.categories.map(c => c.id),
      company: this.company,
      contract_type: this.contractType,
      description: this.description,
      start_date: this.convertDate(this.startDateYear, this.startDateMonth),
      end_date: !this.currentPosition
        ? this.convertDate(this.endDateYear, this.endDateMonth)
        : null,
      current_position: this.currentPosition ? this.currentPosition : false,
    };
  }
}

export default ProfessionalExperienceForm;
