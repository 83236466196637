import React, { useEffect, useState } from 'react';
import { getJSON } from 'lib/request';

interface Props {
  url: string;
}

export default function NotificationStats(props: Props) {
  const [data, setData] = useState(null);

  useEffect(() => {
    getJSON(props.url, setData);
  }, [props.url]);

  if (data) {
    return (
      <div>
        <h2>Last 30 days</h2>
        <br />

        <table className='tables'>
          <thead>
            <tr>
              <th>Sent</th>
              <th>Delivered</th>
              <th>Opens</th>
              <th>Unique opens</th>
              <th>Clicks</th>
              <th>Unique clicks</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{data.sent || 0}</td>
              <td>{data.delivered || 0}</td>
              <td>{data.opens || 0}</td>
              <td>{data.uniqueOpens || 0}</td>
              <td>{data.clicks || 0}</td>
              <td>{data.uniqueClicks || 0}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  } else return null;
}
