import React, { useRef } from 'react';
import Modal, { ButtonsLayout } from '../../../general/modal/modal';
import Text from '../../../form/text/text';

import { postJSON, handleRequestError } from '../../../../lib/request_deprecated';

const styles = require('./AddGasModal.module.scss');

interface Props {
  application: number;
  onClose: Function;
}

export default function AddGasModal(props: Props) {
  const { application, onClose } = props;

  const textRef = useRef(null);

  const assignGas = () => {
    const newGas = Number(textRef.current.value);

    if (!newGas || typeof newGas !== 'number' || Math.sign(newGas) !== 1) {
      window.Alerts.alert('GAS needs to be a positive number');
      return;
    }

    postJSON('/backoffice/applications/assign_gas', {
      application_id: application,
      newGas,
    })
      .then(data => {
        if (data.error) {
          window.Alerts.alert(data.error);
        } else {
          window.Alerts.notice(data.message);
          onClose();
        }
      })
      .catch(handleRequestError);
  };

  return (
    <Modal
      title='Add Gross annual salary'
      defaultOpen={true}
      onClose={onClose}
      buttonsLayout={ButtonsLayout.OkOnly}
      buttonName='Save'
      buttonOnClick={() => assignGas()}
    >
      <div className={styles.leftBox}>
        <div className={styles.title}>Gross annual salary (in EUR)</div>
        <div className={styles.textArea}>
          <Text inputRef={textRef} />
        </div>
      </div>
    </Modal>
  );
}
