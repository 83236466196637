// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`ChatLoading test 1`] = `
<div
  className="lj-chat lj-chat-loading"
>
  <div
    className="lj-conversations"
  >
    <form
      id="conversation-search-form"
    >
      <input
        className="lj-conversation-search"
        placeholder="Company..."
      />
    </form>
    <ul
      className="lj-conversations-list"
    >
      <li
        className="lj-conversation-list-item"
        key="0"
      >
        <a
          href="#"
        >
          <div
            className="lj-userAvatar--small lj-userAvatar lj-loading-grey"
          />
          <div
            className="lj-conversation-info"
          >
            <h5
              className="ld-h5 lj-loading-grey"
            >
               
            </h5>
            <div
              className="lj-conversation-timestamp lj-loading-grey"
            >
               
            </div>
          </div>
        </a>
      </li>
      <li
        className="lj-conversation-list-item"
        key="1"
      >
        <a
          href="#"
        >
          <div
            className="lj-userAvatar--small lj-userAvatar lj-loading-grey"
          />
          <div
            className="lj-conversation-info"
          >
            <h5
              className="ld-h5 lj-loading-grey"
            >
               
            </h5>
            <div
              className="lj-conversation-timestamp lj-loading-grey"
            >
               
            </div>
          </div>
        </a>
      </li>
      <li
        className="lj-conversation-list-item"
        key="2"
      >
        <a
          href="#"
        >
          <div
            className="lj-userAvatar--small lj-userAvatar lj-loading-grey"
          />
          <div
            className="lj-conversation-info"
          >
            <h5
              className="ld-h5 lj-loading-grey"
            >
               
            </h5>
            <div
              className="lj-conversation-timestamp lj-loading-grey"
            >
               
            </div>
          </div>
        </a>
      </li>
      <li
        className="lj-conversation-list-item"
        key="3"
      >
        <a
          href="#"
        >
          <div
            className="lj-userAvatar--small lj-userAvatar lj-loading-grey"
          />
          <div
            className="lj-conversation-info"
          >
            <h5
              className="ld-h5 lj-loading-grey"
            >
               
            </h5>
            <div
              className="lj-conversation-timestamp lj-loading-grey"
            >
               
            </div>
          </div>
        </a>
      </li>
      <li
        className="lj-conversation-list-item"
        key="4"
      >
        <a
          href="#"
        >
          <div
            className="lj-userAvatar--small lj-userAvatar lj-loading-grey"
          />
          <div
            className="lj-conversation-info"
          >
            <h5
              className="ld-h5 lj-loading-grey"
            >
               
            </h5>
            <div
              className="lj-conversation-timestamp lj-loading-grey"
            >
               
            </div>
          </div>
        </a>
      </li>
      <li
        className="lj-conversation-list-item"
        key="5"
      >
        <a
          href="#"
        >
          <div
            className="lj-userAvatar--small lj-userAvatar lj-loading-grey"
          />
          <div
            className="lj-conversation-info"
          >
            <h5
              className="ld-h5 lj-loading-grey"
            >
               
            </h5>
            <div
              className="lj-conversation-timestamp lj-loading-grey"
            >
               
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>
  <div
    className="lj-conversation"
  >
    <div
      className="lj-conversation-header"
    >
      <h3
        className="lj-loading-grey"
      >
         
      </h3>
      <span
        className="lj-loading-grey"
      >
         
      </span>
    </div>
    <div
      className="lj-messages-list"
    >
      <DotLoader />
    </div>
    <form
      className="lj-message-form"
    >
      <textarea
        className="lj-input l j-input--mid lj-input--block u-marginBottom--small"
      />
    </form>
  </div>
</div>
`;
