import * as React from 'react';
import HandshakeTag, { Props } from './handshake_tag';
import { mount } from 'enzyme';

const createTestProps = moreProps => {
  return {
    recipientName: 'João Pedro',
    jobTitle: 'Office Manager',
    handshake: { accepted: '10 Feb, 2020', created: '10 Feb, 2020' },
    userType: 'CompanyUser',
    ...moreProps,
  };
};

let props: Props;
let wrapped;

describe('Handshake Tag component', () => {
  beforeAll(() => {
    props = createTestProps({});
    wrapped = mount(<HandshakeTag {...props} />);
  });

  it('renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('shows job title in the tootltip text', () => {
    expect(wrapped.find('.tooltipTextBelow').text()).toMatch('Office Manager');
  });

  it('shows recipient name in the tootltip text', () => {
    expect(wrapped.find('.tooltipTextBelow').text()).toMatch('João Pedro');
  });

  it('shows the date in the tootltip text', () => {
    expect(wrapped.find('.tooltipTextBelow').text()).toMatch('10 Feb, 2020');
  });
});

describe('Handshake Tag component for Company User', () => {
  it('shows text for company user', () => {
    expect(wrapped.find('.tooltipTextBelow').text()).toMatch(
      'You thought this profile looked promising for'
    );
  });
});

describe('Handshake Tag component for Talent User', () => {
  beforeAll(() => {
    props = createTestProps({
      userType: 'TalentUser',
      recipientName: 'Landing.jobs',
    });
    wrapped = mount(<HandshakeTag {...props} />);
  });

  it('shows text for talent user', () => {
    expect(wrapped.find('.tooltipTextBelow').text()).toMatch(
      'Landing.jobs thought your profile looked promising for'
    );
  });
});
