import React from 'react';
import { render } from '@testing-library/react';
import HandpickedNotice from './handpicked_notice';

describe('HandpickedNotice', () => {
  it('should render the avatar, the name and a message', () => {
    const name = 'Stifler';
    const { queryByAltText, queryByText } = render(
      <HandpickedNotice avatarUrl='https://example.com/avatar.png' name={name} />
    );

    expect(queryByAltText('avatar')).toBeTruthy();
    expect(queryByText(name, { exact: false })).toBeTruthy();
    expect(queryByText('Handpicked', { exact: false })).toBeTruthy();
  });

  it('should render the note when present', () => {
    const name = 'This be name';
    const note = 'This be note';
    const { queryByText } = render(<HandpickedNotice avatarUrl='' note={note} name={name} />);
    expect(queryByText(note, { exact: false })).toBeTruthy();
  });
});
