const curationPoolDummy = {
  admins: [
    {
      id: 200,
      name: 'Fábio Teixeira',
      url: '/backoffice/admin_users/fabio-teixeira',
    },
    {
      id: 33,
      name: 'Gonçalo Guerreiro',
      url: '/backoffice/admin_users/goncalo-guerreiro',
    },
  ],
  id: 2,
  jobs: [
    {
      company: 'Critical Software',
      curated_applications: 78,
      id: 7233,
      title: 'Junior Software Engineer',
      total_applications: 78,
      url: '/backoffice/job_ads/junior-software-engineer-in-lisbon-2018-12',
    },
  ],
  name: 'Bar',
  urls: {
    destroy: '/backoffice/curation_pools/2',
    edit: '/backoffice/curation_pools/2/edit',
  },
};

export default curationPoolDummy;
