import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import TeamMemberItem from './TeamMemberItem';
import { TeamMember } from '../companyInfoForm/TeamElementsList';

const createProps = () => ({
  id: 1,
  fullName: 'This is a name',
  role: 'This is a role',
  resume: 'This is a resume',
  photo: {
    name: 'this is a photo',
    imageURL:
      'https://lh3.googleusercontent.com/proxy/LpeDPhQdepsl56D8zn_dQoVAXCEjxF0mV4roaTnyjnsSzkLe8ezRh9tzWGzFS8eqOPi35yyIM6gavVUFpZldTkxR8PmLMerDwV7_jnUoUsbHZg',
  },
});

let props;
let wrapped: ShallowWrapper;

describe('TeamMemberItem', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<TeamMemberItem {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('contains a Name element', () => {
    expect(wrapped.find('.teamMemberName').length).toEqual(1);
  });

  it('contains a teamMemberElement element', () => {
    expect(wrapped.find('.teamMemberElement').length).toEqual(1);
  });

  it('contains a quoting wrapper elements', () => {
    expect(wrapped.find('.resumeWrapper').length).toEqual(2);
  });

  it('contains an image', () => {
    expect(wrapped.find('img').length).toEqual(1);
  });
});
