import { resolveSkills } from 'components/general/rating/rating_notes';
import React from 'react';
import sanitizeHtml from 'sanitize-html-react';
const styles = require('./automatic_notes.module.scss');

export interface Props {
  notes: any;
}

export default class AutomaticNotes extends React.Component<Props> {
  resolveNotes() {
    const notes = [];
    const skillNotes = [];
    for (const [name, value] of Object.entries(this.props.notes)) {
      if (name === 'skills') {
        skillNotes.push(value);
      } else {
        notes.push(value);
      }
    }
    return [notes, ...skillNotes];
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.text}>
          {this.props.notes && this.resolveNotes()[1] && resolveSkills(this.resolveNotes()[1])}
          {this.props.notes &&
            this.resolveNotes()[0].map(notesArray =>
              notesArray.map((note, index) =>
                note ? (
                  <p
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(note),
                    }}
                  />
                ) : null
              )
            )}
        </div>
      </div>
    );
  }
}
