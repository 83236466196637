import React from 'react';
import Icon, { Size } from 'components/general/icon/icon';
import QuestionMarkTooltip from 'components/question_mark_tooltip/question_mark_tooltip';
import classNames from 'classnames';

const styles = require('./office_locations_tooltip.module.scss');

interface Props {
  officeLocations: any;
  iconColor: string;
  iconSize?: Size;
  className?: string;
}

export default function OfficeLocationsTooltip(props: Props) {
  const { officeLocations, iconColor, iconSize, className } = props;

  function tooltipContent() {
    return (
      <>
        {officeLocations.map((officeLocation, index) => {
          return (
            <div
              className={styles.locationTooltip}
              key={
                officeLocation.google_place_id || officeLocation.city || `OfficeLocation${index}`
              }
            >
              <Icon
                name={'mapMarkerOutline'}
                size={Size.QuiteSmall}
                className={styles.locationIcon}
              />
              <div className={styles.locationTooltipLabel}>{officeLocation.label}</div>
            </div>
          );
        })}
      </>
    );
  }

  return (
    <div className={classNames(styles.locationTooltipContainer, className)}>
      <QuestionMarkTooltip
        id={'location-tooltip'}
        iconColor={iconColor}
        size={iconSize || Size.MediumLarge}
        content={tooltipContent()}
        containerClass={'office-locations-tooltip'}
      />
    </div>
  );
}
