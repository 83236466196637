import React, { useContext, useRef, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { FormContext } from 'components/form/FormProvider';
import ImageInput from 'components/form/imageInput/ImageInput';
import Text from 'components/form/text/text';
import SectionTitle from './SectionTitle';
import Button from 'lj_shared/button/button';
import SectionCard from './SectionCard';
import ErrorLabel from 'components/tags_page/error_label';
import { handleRequestError, deleteJSON, postFormData } from 'lib/request_deprecated';
import { cropImage } from './utils/OfficeSectionUtils';
import { camelizeObj } from 'lib/object';
import LocationInput from 'components/form/location_input/location_input';
import FormInput from 'components/general/form_input/form_input';
import classNames from 'classnames';

const styles = require('./OfficeSection.module.scss');

const canvasId = 'canvas-container';

export default function OfficeSection() {
  const context = useContext(FormContext);
  const [fileData, setFileData] = useState(null);
  const [officeList, setOfficeList] = useState(context.resource.offices);
  const [loading, setLoading] = useState(false);

  const addressLineRef = useRef(null);
  const addressLineOtherRef = useRef(null);
  const postalCodeRef = useRef(null);
  const [city, setCity] = useState(null);
  const [province, setProvince] = useState(null);
  const [country, setCountry] = useState(null);
  const locationInputRef = useRef(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [addressLineError, setAddressLineError] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [postalCodeError, setPostalCodeError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const companyId = () => context.resource.id;

  const validAddress = () => addressLineRef.current.checkValidity();
  const validCity = () => city?.length > 1;
  const validCountry = () => city?.length > 1;
  const validPostalCode = () => postalCodeRef.current.checkValidity();

  const hasValidPayload = () =>
    validAddress() && validCity() && validPostalCode() && validCountry();

  const formErrors = () => {
    setAddressLineError(!validAddress());
    setLocationError(!validCity() || !validCountry());
    setPostalCodeError(!validPostalCode());
  };

  const payload = () => {
    const formData = new FormData();
    formData.append('address_line', addressLineRef.current.value);
    formData.append('address_line_other', addressLineOtherRef.current.value);
    formData.append('city', city);
    formData.append('company_id', companyId());
    formData.append('country', country);
    formData.append('state', province);
    formData.append('phone_number', phoneNumber);
    formData.append('postal_code', postalCodeRef.current.value);
    if (fileData) {
      formData.append('image', fileData.image);
      formData.append('crop_h', fileData.cropH);
      formData.append('crop_w', fileData.cropW);
      formData.append('crop_x', fileData.cropX);
      formData.append('crop_y', fileData.cropY);
    }
    return formData;
  };

  const notifyAndClear = () => {
    formErrors();
    addressLineRef.current.value = '';
    addressLineOtherRef.current.value = '';
    postalCodeRef.current.value = '';
    locationInputRef.current.value = '';
    setPhoneNumber('');
    setPhoneNumberError(false);
    setCity(null);
    setCountry(null);
    setProvince(null);

    (document.querySelector('[name=image]') as HTMLInputElement).value = '';
    const canvas = document.getElementById(canvasId) as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    canvas.hidden = true;
    window.Alerts.notice('Added office!');
  };

  const validateAndSubmit = event => {
    event.preventDefault();
    setLoading(true);
    if (hasValidPayload()) {
      postFormData(window.Routes.company_offices(), payload())
        .then(response => {
          const hasPhoneErrors = response?.errors?.phone_number?.length >= 1;
          const { offices } = response;

          setOfficeList(offices.data.map(({ attributes }) => camelizeObj(attributes)));

          if (response.hasOwnProperty('errors')) {
            formErrors();
            setPhoneNumberError(hasPhoneErrors);
          } else {
            setFileData(null);
            notifyAndClear();
          }
        })
        .finally(() => setLoading(false))
        .catch(handleRequestError);
    } else {
      formErrors();
      setLoading(false);
    }
  };

  const deleteOffice = officeId => {
    const shouldRemove = window.confirm('Do you want to remove?');
    if (!shouldRemove) {
      return;
    }
    deleteJSON(window.Routes.company_office(officeId), {
      company_id: companyId(),
    })
      .then(({ data }) => setOfficeList(data.map(item => item.attributes)))
      .catch(e => handleRequestError(e));
  };

  const renderForm = () => {
    return (
      <div className={styles.formContainer}>
        <div className={styles.addressContainer}>
          <Text
            class={styles.textInput}
            inputRef={addressLineRef}
            label={'Address'}
            name='address_line'
            placeholder='e.g. Rua Braamcamp'
            required={true}
          />
          {addressLineError && <ErrorLabel message={'Please tell us your address'} />}
          <Text
            class={styles.textInput}
            inputRef={addressLineOtherRef}
            name='address_line_other'
            placeholder='e.g. 9 S/L D'
          />
        </div>
        <div className={classNames(styles.halfWidthInput, styles.officeLocation)}>
          <FormInput name='location' label='Location' required>
            <LocationInput
              handleLocationChange={handleLocationChange}
              placeholder={'e.g. Lisbon, Portugal'}
              required={true}
              class={styles.officeLocationInput}
              color={'puertoRico'}
              inputRef={locationInputRef}
            />
          </FormInput>
          {locationError && <ErrorLabel message={'Please tell us your location'} />}
        </div>
        <div className={styles.halfWidthInput}>
          <Text
            inputRef={postalCodeRef}
            label={'Postal Code / Zip Code'}
            name='postal_code'
            placeholder='e.g. 1250-048'
            required={true}
          />
          {postalCodeError && <ErrorLabel message={'Please tell us your postal code'} />}
        </div>
        <div className={styles.addButtonContainer}>
          <Button
            disabled={loading}
            isButton={true}
            buttonColor='silverSand'
            onClick={validateAndSubmit}
          >
            {loading ? 'Uploading' : 'Add'}
          </Button>
        </div>
      </div>
    );
  };

  const findLocationObjectByType = (place, type) => {
    return place.address_components.find(component => component.types.includes(type));
  };

  const handleLocationChange = place => {
    if (place && place.address_components) {
      const cityObj = findLocationObjectByType(place, 'locality');
      const provinceObj = findLocationObjectByType(place, 'administrative_area_level_1');
      const countryObj = findLocationObjectByType(place, 'country');

      setCity(cityObj?.long_name);
      setProvince(provinceObj?.long_name);
      setCountry(countryObj?.long_name);
    }
  };

  const renderPhone = () => (
    <div className={styles.section}>
      <div className={styles.halfWidthInput}>
        <h4 className={styles.subtitle}>Phone</h4>
        <PhoneInput
          value={phoneNumber || ''}
          country={'pt'}
          inputProps={{
            name: 'phone_number',
          }}
          onChange={phone => setPhoneNumber(phone)}
        />
      </div>
      <div className={styles.phoneErrorContainer}>
        {phoneNumberError && <ErrorLabel message={'Please insert a valid phone number'} />}
      </div>
    </div>
  );

  const saveImage = data => {
    cropImage(data, canvasId);
    setFileData(data);
  };

  return (
    <div>
      <SectionTitle title='Offices' id='companyOffices' />
      <SectionImage saveImage={saveImage} />
      {renderPhone()}
      {renderForm()}
      <OfficesList officeList={officeList} deleteOffice={deleteOffice} />
    </div>
  );
}

function SectionImage({ saveImage }) {
  return (
    <div className={styles.section}>
      <h4 className={styles.subtitle}>Office Image</h4>
      <div className={styles.imageSection}>
        <canvas id={canvasId} className={styles.canvasItem} hidden />
        <ImageInput
          alt={'Office Image'}
          buttonColor={'SilverSandLight'}
          name='image'
          onSave={saveImage}
          recommendedHeight={217}
          recommendedWidth={367}
          uploadIcon={true}
          maxSize={5242880}
        />
      </div>
    </div>
  );
}

function OfficesList({ officeList, deleteOffice }) {
  return officeList.map(office => (
    <SectionCard
      key={office.id}
      handleDelete={() => deleteOffice(office.id)}
      header={office.phoneNumber}
      title={office.imageFileName}
      text={office.address}
    />
  ));
}
