import classNames from 'classnames';
import * as React from 'react';
import { cloneDeep } from 'lodash';
import SidebarFilters, {
  Dimension,
  handleCleanNameInput,
} from '../employer_applications/sidebar_filters';
import TopFilters from '../employer_applications/top_filters';
import ApplicationList from '../employer_applications/application_list';
import '../../lib/globals';
import { Application, HiringStep } from '../../__models__/models';
import { Urls } from '../employer_applications/models';
import { ApplicationsState } from '../employer_applications/applications_state';
import { getJSON, postJSON, handleRequestError } from '../../lib/request_deprecated';
import { Step } from '../job_hiring_steps/models';
import AskModal from '../job_hiring_steps/ask_modal';
import RejectionModal from '../rejection_modal/rejection_modal';
import DotLoader from 'lj_shared/dot_loader/dot_loader';
import ApplicationPage from './application_page';
import ReconsiderModal from '../application_page/reconsider_modal';
import SendTestModal from '../application_page/send_test_modal';
import './employer_applications.scss';
import { createBrowserHistory } from 'history';
import SearchTagList, { SearchTagRaw } from '../searchTag/SearchTagList';
import { createSearchTagsFromFilters } from '../searchTag/SearchTagUtils';
import { unTitleize, snakeCase } from 'lib/string';
import MismatchToInProcessModalContainer from 'components/employer_applications/mismatchToInProcessModal/MismatchToInProcessModalContainer';
import WhatsNewModalItem from '../new_feature/WhatsNewModalItem';
import RejectionForm from 'components/rejection_modal/rejection_form';
import ConfirmModal from 'components/general/confirm_modal/confirm_modal';
import { HeaderJobFilter } from 'components/employer_applications/HeaderJobFilter/HeaderJobFilter';
import SendBulkTestsModal from 'components/send_bulk_tests_modal/send_bulk_tests_modal';

const baseUrl = '/employer/applications/';
const history = createBrowserHistory();
const inboxAccordions = ['Meet requirements', 'Worth looking at', 'Probably not a match'];

export interface Props {
  application?: Application;
  applicationInformation?: any;
  applicationsUrl: string;
  candidate_locations: any[];
  chat_url: string;
  checkApplicationsUrl: string;
  contacts_url: string;
  disengaged: boolean;
  experience_years: any;
  followed_jobs: any;
  googleMapsApiKey: string;
  hiringSteps: Step[];
  other_jobs: any;
  outdated?: boolean;
  searchScope?: string;
  urls: Urls;
  rejectionDimensionsMapping: any;
  numberOfTalentUsers: string;
}

interface State {
  application: Application;
  applicationInformation: any;
  appsState: ApplicationsState;
  featureModalSeenDate: Date;
  filtered: boolean;
  filters: {
    city: string;
    country: string;
    eu_citizenship: boolean;
    experience: string;
    in_process: boolean;
    job_id: number;
    worth_looking_at: boolean;
    meet_requirements: boolean;
    name: string;
    probably_not_a_match: boolean;
    ratings: Dimension[];
  };
  isFeaturesModalOpen: boolean;
  loading: boolean;
  mobileFiltersOpen: boolean;
  new_feature_date: Date;
  ratingsWithDimensions: any;
  requiresWipe: boolean;
  rejectFormProbablyNotAMatch: RejectionForm;
  scroll: number;
  searchTags: SearchTagRaw[];
  seenList: number[];
  mismatchFeedbackModal: {
    show: boolean;
    error: string;
    app: Application;
    url: string;
    ids: string[];
    stepId: number;
    bulk: boolean;
  };
  tabCounters: TabCounters;
  target: string;
  topFilter: string;
  sendBulkTests: boolean;
  bulkTestPeopleIds: string[];
  bulkTestStep: object;
}

const defaultFilters = {
  ratings: [],
  city: '',
  country: '',
  experience: '',
  in_process: false,
  meet_requirements: false,
  worth_looking_at: false,
  probably_not_a_match: false,
  eu_citizenship: false,
  name: '',
};

const defaultRatingsWithDimensions = {
  skills: 0,
  language: 0,
  location: 0,
  experience: 0,
  bonus: 0,
};
interface TabCounters {
  waiting: number;
  inbox: number;
  reviewing: number;
}

interface ApplicationResponse {
  data: Application[];
  per_page: number;
  total: number;
}

/**
 * Component for the Employer Dashboard applications pages. It should manage both
 * applications lists and the application page.
 */
class EmployerApplications extends React.Component<Props, State> {
  mismatchModalRef: any;
  employerFeedbackRef: any;
  appChangedToStepWithTest: boolean;
  sendTestTarget: Application;

  constructor(props: Props) {
    super(props);
    const counts = {
      waiting: null,
      inbox: null,
      reviewing: null,
    };

    this.state = {
      featureModalSeenDate: null,
      filters: { ...defaultFilters, job_id: null },
      filtered: false,
      isFeaturesModalOpen: true,
      appsState: new ApplicationsState({}),
      loading: true,
      ratingsWithDimensions: defaultRatingsWithDimensions,
      scroll: 0,
      searchTags: [],
      seenList: [],
      mismatchFeedbackModal: {
        show: false,
        error: null,
        app: null,
        url: null,
        ids: null,
        stepId: null,
        bulk: null,
      },
      tabCounters: counts,
      application: this.props.application ? (this.props.application as any).data : null,
      applicationInformation: this.props.applicationInformation
        ? this.props.applicationInformation
        : null,
      topFilter: this.props.searchScope ? this.props.searchScope : 'inbox',
      target: 'state-curated',
      mobileFiltersOpen: false,
      new_feature_date: null,
      requiresWipe: false,
      rejectFormProbablyNotAMatch: new RejectionForm({
        reason: 'Proceeding with other Applications',
        reasonOther: null,
        candidateMessage: null,
        landingMessage: null,
        employerRating: null,
      }),
      sendBulkTests: false,
      bulkTestPeopleIds: [],
      bulkTestStep: {},
    };

    this.handleSubmitMismatchFeedback = this.handleSubmitMismatchFeedback.bind(this);
    this.changeMismatchFeedbackModalState = this.changeMismatchFeedbackModalState.bind(this);
    this.mismatchModalRef = React.createRef();
    this.employerFeedbackRef = React.createRef();
    this.appChangedToStepWithTest = false;
    this.sendTestTarget = null;
    this.bulkMoveToStep = this.bulkMoveToStep.bind(this);
    this.bulkReject = this.bulkReject.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.moveToStep = this.moveToStep.bind(this);
    this.rejectApplication = this.rejectApplication.bind(this);
    this.reconsiderApplication = this.reconsiderApplication.bind(this);
    this.sendTest = this.sendTest.bind(this);
    this.setTopFilter = this.setTopFilter.bind(this);
    this.setApplication = this.setApplication.bind(this);
    this.removeApplication = this.removeApplication.bind(this);
    this.handleBulkResponse = this.handleBulkResponse.bind(this);
    this.giveAlert = this.giveAlert.bind(this);
    this.openCloseMobileFilters = this.openCloseMobileFilters.bind(this);
    this.showBulkTestsModal = this.showBulkTestsModal.bind(this);
    this.verifySendBulkTests = this.verifySendBulkTests.bind(this);
    this.sendBulkTestsAftermath = this.sendBulkTestsAftermath.bind(this);
  }

  /**
   * When loading the page it checks if an application was requested.
   * It then loads the information for that specific application
   * Otherwise it loads the requested application
   */
  componentDidMount() {
    getJSON(window.Routes.current_date_employer())
      .then(({ date, feature_modal_seen_date }) => {
        this.setState(() => ({
          new_feature_date: date,
          featureModalSeenDate: feature_modal_seen_date,
        }));
      })
      .catch(handleRequestError);

    if (this.state.application) {
      this.setApplication(this.state.application);
    } else {
      this.handleToggleFilter(true);
    }

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('job_id')) {
      const job_id = +urlParams.get('job_id');
      this.setState(prevState => ({
        filters: {
          ...prevState.filters,
          job_id,
        },
        filtered: true,
      }));
    }
  }

  /**
   * When updating the component we need to update the browser history
   * so that the back button and forward do something
   */
  componentDidUpdate(_prevProps, prevState) {
    const application = this.state.application;

    if (application !== prevState.application || this.state.topFilter !== prevState.topFilter) {
      let newUrlPath = null;

      if (application) {
        newUrlPath = `${baseUrl}${String(application.id)}`;
      } else {
        if (this.state.topFilter !== 'inbox') {
          newUrlPath = `${baseUrl}${this.state.topFilter}`;
        } else {
          newUrlPath = baseUrl;
        }
      }

      // Keep job id search params when updating state
      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.has('job_id') && baseUrl.indexOf('job_id') < 0) {
        const parsedUrl = new URL(`${window.location.protocol}//${location.host}${newUrlPath}`);

        parsedUrl.searchParams.append('job_id', urlParams.get('job_id'));

        newUrlPath = `${parsedUrl.pathname}${parsedUrl.search}`;
      }

      history.push(newUrlPath);
    }

    if (this.appChangedToStepWithTest && this.sendTestTarget) {
      this.appChangedToStepWithTest = false;
      this.sendTest(this.sendTestTarget);
    }
  }

  openCloseMobileFilters() {
    this.setState(() => ({ mobileFiltersOpen: !this.state.mobileFiltersOpen }));
  }

  applyFilters = () => {
    this.handleToggleFilter(true);
  };

  setAppsState = (
    target: string,
    applications: Application[],
    tempAppsState: ApplicationsState
  ) => {
    let applicationSplice = [];
    let appIndex = 0;
    let pageIndex = 1;
    const total = applications.length;
    const perPage = this.state.appsState.perPage('screeneing');

    for (const application of applications) {
      applicationSplice.push(application);
      appIndex++;
      if (appIndex / perPage === pageIndex) {
        tempAppsState = tempAppsState.set(target, applicationSplice, pageIndex - 1, total);
        applicationSplice = [];
        pageIndex++;
      }
    }
    tempAppsState = tempAppsState.set(target, applicationSplice, pageIndex - 1, total);
    tempAppsState = tempAppsState.setPage(target, 0);
    return tempAppsState;
  };

  shouldGroupApplications = (topFilter = this.state.topFilter) => {
    const isReviewingWithFilter = topFilter === 'reviewing';
    const isInbox = topFilter === 'inbox';
    return !topFilter || isReviewingWithFilter || isInbox;
  };

  accordionsWithNewRules = (topFilter = this.state.topFilter) => {
    switch (topFilter) {
      case 'reviewing':
        const steps = this.props.hiringSteps.filter(
          step => step.jobId === parseInt(this.selectedJobId(), 10)
        );
        const handshake: Step = {
          id: null,
          description: 'If Application came from a Handshake and is Unreviewed',
          hasApplications: null,
          jobId: null,
          kind: 'handshake',
          slug: 'state-handshake',
        };
        return [handshake].concat(steps);
      case 'inbox':
      default:
        return inboxAccordions.map(name => ({
          id: null,
          description: `If Application fits: "${name}"`,
          hasApplications: null,
          jobId: null,
          kind: name,
          slug: this.upgradeToStateSlug(name),
        }));
    }
  };

  accordeonize = (applications: Application[]) => {
    const groups = this.splitInGroups(applications);

    let tempAppsState = cloneDeep(this.state.appsState);
    this.accordionsWithNewRules().forEach(s => {
      const slug = s.slug;
      if (groups[slug]) {
        tempAppsState = this.setAppsState(slug, groups[slug], tempAppsState);
      }
    });

    this.setState({
      appsState: tempAppsState,
      loading: false,
    });
  };

  splitInGroups = (applications: Application[], topFilter = this.state.topFilter) => {
    switch (topFilter) {
      case 'reviewing':
        return applications.reduce(this.reviewingApplicationReducer, {});
      case 'inbox':
      default:
        return applications.reduce(this.inboxApplicationReducer, {});
    }
  };

  inboxApplicationReducer = (accumulator, application: Application) => {
    const state = this.upgradeToStateSlug(application.attributes.inbox_classification);
    return this.groupApplication(application, accumulator, state);
  };

  reviewingApplicationReducer = (accumulator, application: Application) => {
    let state = application.attributes.current_state;
    if (application.attributes.is_handshake && [0, 23].includes(application.attributes.state)) {
      state = this.upgradeToStateSlug('handshake');
    }
    return this.groupApplication(application, accumulator, state);
  };

  groupApplication = (application: Application, groups, state: string) => {
    if (groups[state]) {
      groups[state].push(application);
    } else {
      groups[state] = [application];
    }
    return groups;
  };

  upgradeToStateSlug = (name: string) => `state-${snakeCase(name)}`;

  selectedJobId = () => {
    const jobId = this.state.filters.job_id;
    return jobId && jobId.toString();
  };

  /**
   * Function that sets the state for the Top Filter
   * TO-DO: Implement a GetJSON that checks that the current application list is correct,
   * Otherwise do an update.
   *
   * @param {string} topFilter
   */
  setTopFilter(topFilter = this.state.topFilter, force = null) {
    if (topFilter !== this.state.topFilter || force) {
      this.setState(() => {
        return { loading: true, topFilter };
      });
      if (this.state.requiresWipe) {
        this.handlePageWipe(topFilter);
      } else {
        this.loadApplications(topFilter);
      }
    } else {
      this.setState(() => {
        return { loading: false };
      });
    }
  }

  /**
   * This selects a given application. This will in turn leave the application list
   * and go the the respective application page
   *
   * @param {Application} application
   */
  setApplication(application) {
    this.setState(() => {
      return { loading: true };
    });

    getJSON(application.attributes.urls.attributes_url)
      .then(data => {
        const applicationInformation = data;

        applicationInformation.ats_offer = applicationInformation.ats_offer.data;
        applicationInformation.applicationQuestions =
          applicationInformation.applicationQuestions.data;

        const loading = false;

        this.setState(() => {
          return {
            application,
            applicationInformation,
            loading,
            seenList: this.state.seenList.concat([application.id]),
          };
        });
      })
      .catch(handleRequestError);
  }

  /**
   * This removes the application selection, returning to the application list
   */
  removeApplication() {
    this.setState(() => {
      return {
        loading: true,
        application: null,
        applicationInformation: null,
      };
    });

    this.setTopFilter(this.state.application.attributes.employer_filter, true);
  }

  /**
   * Simple component made for loading
   */
  loader() {
    return (
      <div style={{ position: 'relative' }}>
        <DotLoader />
      </div>
    );
  }

  /**
   * Set a given filter on the state
   */
  setFilter(filter, type, callback?) {
    this.setState(
      prevState => ({
        filters: {
          ...prevState.filters,
          [type]: filter,
        },
      }),
      callback
    );
  }

  setDimension = (dimension: string, rating: number) => {
    const newdimensions = { ...this.state.ratingsWithDimensions };
    newdimensions[dimension] = newdimensions[dimension] === rating ? 0 : rating;
    this.setState(
      () => ({
        ratingsWithDimensions: newdimensions,
      }),
      this.dimensionToRatingsFilter
    );
  };

  /**
   * Remove all the filters
   */
  clearFilters = () => {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        ...defaultFilters,
      },
      ratingsWithDimensions: defaultRatingsWithDimensions,
    }));
    if (this.state.filtered) {
      this.handleToggleFilter(true);
    }
  };

  handleToggleFilter = (filtered = !this.state.filtered) => {
    this.deletePages(() => this.loadApplications(this.state.topFilter, filtered, 0));
    this.setState({ filtered });
  };

  deletePages = (callback?) => {
    const appsState = new ApplicationsState({});
    this.setState({ appsState }, callback);
  };

  /**
   * Returns the applications for the list, given the top filter above,
   * The options are ['inbox', 'reviewing', 'hired', 'rejected]
   *
   * @param {string} topFilter
   */
  applicationsFromTopFilter(topFilter = this.state.topFilter) {
    return this.state.appsState.box(topFilter);
  }

  /**
   * Given a filter it will load the necessary applications if they aren't loaded
   * at that moment
   *
   * @param topFilter
   */
  loadApplications(topFilter = this.state.topFilter, filtered = this.state.filtered, page = null) {
    this.setState({ loading: true });
    const filters = this.fetchFilters(filtered);
    const newpage = page === null ? this.state.appsState.page(topFilter) : page;
    this.fetchPagedApplication(topFilter, newpage, filters);
  }

  fetchFilters = (filtered = this.state.filtered) => {
    if (filtered) {
      return JSON.stringify(this.state.filters);
    }
    return 'false';
  };

  handleSubmitMismatchFeedback() {
    const feedbackModal = this.state.mismatchFeedbackModal;
    if (feedbackModal.bulk) {
      postJSON(
        window.Routes.bulk_submit_employer_feedback_employer_applications({
          employer_feedback: this.employerFeedbackRef.current.value,
          ids: feedbackModal.ids,
        })
      ).catch(handleRequestError);
      this.bulkProceedMoveToStep(
        feedbackModal.app,
        feedbackModal.url,
        feedbackModal.ids,
        feedbackModal.stepId
      );
    } else {
      postJSON(
        window.Routes.submit_employer_feedback_employer_application(this.state.application.id, {
          employer_feedback: this.employerFeedbackRef.current.value,
        })
      ).catch(handleRequestError);
      this.proceedMoveToStep(feedbackModal.stepId, feedbackModal.app);
    }
    this.changeMismatchFeedbackModalState({ show: false });
  }

  changeMismatchFeedbackModalState(object) {
    this.setState(prevState => ({
      mismatchFeedbackModal: {
        ...prevState.mismatchFeedbackModal,
        ...object,
      },
    }));
  }

  renderMismatchFeedbackModal() {
    return (
      <>
        {this.state.mismatchFeedbackModal.show && (
          <MismatchToInProcessModalContainer
            employerFeedbackRef={this.employerFeedbackRef}
            errorMessage={this.state.mismatchFeedbackModal.error}
            handleChange={this.changeMismatchFeedbackModalState}
            handleSubmitFeedback={this.handleSubmitMismatchFeedback}
            modalRef={this.mismatchModalRef}
            onClose={() => this.changeMismatchFeedbackModalState({ show: false })}
          />
        )}
      </>
    );
  }

  moveToStep(step = null, app) {
    this.setState(() => ({
      scroll: window.pageYOffset,
    }));
    if (this.isProbablyNotAMatch(app)) {
      this.changeMismatchFeedbackModalState({
        show: true,
        bulk: false,
        step,
        app,
      });
    } else {
      this.proceedMoveToStep(step, app);
    }
  }

  /**
   * Given an Application and a Hiring step prepare them to change step
   *
   * @param {HiringStep} step
   * @param {Application } app
   */
  proceedMoveToStep(step = null, app) {
    // Get the current step
    if (!step) {
      const currentStep = app.attributes.hiring_steps.find(
        (hiringStep: any) => hiringStep.value === app.attributes.current_state
      );
      if (currentStep) {
        step = app.attributes.hiring_steps[currentStep.number];
      } else {
        step = app.attributes.hiring_steps[0];
      }
    }

    // If step is hiring step then something, else something
    if (step.kind === 'step' && !step.message) {
      const jobId = app.attributes.job_id;
      const messagesUrls = {
        update: this.props.urls.messages.update.replace('~job_id', jobId),
        create: this.props.urls.messages.create.replace('~job_id', jobId),
        preview: this.props.urls.messages.preview.replace('~job_id', jobId),
      };
      AskModal.show(jobId, step, messagesUrls)
        .then(() => this.executeMoveToStep(step, app))
        .catch(handleRequestError);
    } else {
      this.executeMoveToStep(step, app);
    }
  }

  /**
   * After being prepared, send the request to change the application state
   *
   * @param {HiringStep} step
   * @param {Application} app
   */
  executeMoveToStep(step, app) {
    const urls = app.attributes.urls;
    const kind = step.value.split('-')[0];
    const value = step.value.split('-')[1];

    if (kind === 'state') {
      switch (value) {
        case 'employer_reviewing':
          getJSON(urls.employer_reviewing, { method: 'Post' })
            .then(this.handleApplicationResponse.bind(this, app, null))
            .catch(handleRequestError);
          break;
        case 'hired':
          $.get(urls.hired);
          break;
        case 'offer_made':
          const jobTitle = app.attributes.job_title;
          const candidateName = app.attributes.person.attributes.name;
          const confirmationMessage = (
            <>
              Are you sure you want to register an offer made to candidate{' '}
              <strong>{candidateName}</strong> for job <strong>{jobTitle}</strong>
            </>
          );
          ConfirmModal.show('Move to Proposal', confirmationMessage)
            .then(confirmed => {
              if (confirmed) {
                getJSON(urls.offer_made, { method: 'Post' })
                  .then(this.handleApplicationResponse.bind(this, app, null))
                  .catch(handleRequestError);
              }
            })
            .catch(handleRequestError);
          break;
        default:
          this.giveAlert({
            message: "Sorry, couldn't process request",
            message_type: 'alert',
          });
      }
    } else {
      getJSON(urls.step + '?step_id=' + value, {
        method: 'Post',
      })
        .then(this.handleApplicationResponse.bind(this, app, step))
        .catch(handleRequestError);
    }
  }

  /**
   * Handle what happens when an application changes state
   * @param {Application} oldApp
   * @param {Object} response
   */
  handleApplicationResponse(oldApp: Application, step: HiringStep, response) {
    const newApp: Application = response.application.data;
    const oldAppWaitingForScreening = oldApp.attributes.state === 0;
    this.appChangedToStepWithTest = step && step.test_name !== null;
    this.sendTestTarget = newApp;

    if (response.message_type === 'notice') {
      if (this.state.application || oldAppWaitingForScreening) {
        const application = this.state.application || oldApp;
        const label = application.attributes.step_label;
        const callback = ['Screened by Landing', 'Waiting for Screening'].includes(label)
          ? window.location.reload()
          : this.setState(() => ({ application: newApp }));
        this.deletePages(() => callback);
      } else {
        this.handlePageWipe();
      }
    }
  }

  bulkMoveToStep(url, ids, stepId?) {
    const app = this.getFirstApplicationOfBulk(ids);
    if (this.isProbablyNotAMatch(app)) {
      this.changeMismatchFeedbackModalState({
        show: true,
        bulk: true,
        ids,
        stepId,
        app,
        url,
      });
    } else {
      this.bulkProceedMoveToStep(app, url, ids, stepId);
    }
  }

  bulkProceedMoveToStep(app, url, ids, stepId?) {
    if (!stepId) {
      const currentStep = app.attributes.hiring_steps.find(
        (hiringStep: any) => hiringStep.value === app.attributes.current_state
      );
      if (currentStep) {
        stepId = app.attributes.hiring_steps[currentStep.number].id;
      } else {
        stepId = app.attributes.hiring_steps[0].id;
      }
    }
    postJSON(url.replace('~id', stepId), { ids })
      .then(this.handleBulkResponse)
      .catch(handleRequestError);
  }

  handlePageWipe = (topFilter?) => {
    this.deletePages(() => this.loadApplications(topFilter));
    this.setState(() => ({ requiresWipe: false }));
  };

  handleBulkResponse(response) {
    this.handlePageWipe();
    this.giveAlert(response);

    const applications = response?.applications?.data;
    if (applications.length > 0) {
      this.verifySendBulkTests(applications);
    }
  }

  verifySendBulkTests(applications) {
    const firstApp = applications[0];
    const currentStep = firstApp.attributes.hiring_steps.find(
      (hiringStep: any) => hiringStep.value === firstApp.attributes.current_state
    );

    if (currentStep.test_name !== null) {
      setTimeout(() => this.showBulkTestsModal(currentStep, applications), 3000);
    }
  }

  showBulkTestsModal(step, applications) {
    const peopleIds = applications.map(app => app.attributes.person.id);

    this.setState({
      sendBulkTests: true,
      bulkTestPeopleIds: peopleIds,
      bulkTestStep: step,
    });
  }

  sendBulkTestsAftermath(applications?) {
    const stepName = this.state.bulkTestStep['value'];
    const oldApplications = this.state.appsState.box(stepName, 'fullBox')?.[0];

    if (oldApplications?.length > 0) {
      const newApplications = cloneDeep(oldApplications);
      const applicationIds = newApplications
        .filter(app => {
          return this.state.bulkTestPeopleIds.includes(app.attributes.person.id);
        })
        .map(app => app.id);

      if (applications?.length > 0) {
        applications.forEach(application => {
          const oldAppIndex = newApplications.findIndex(app => app.id === application.id);
          newApplications[oldAppIndex].attributes.test_results =
            application.attributes.test_results;
        });

        const oldAppsState = cloneDeep(this.state.appsState);
        const newAppsState = this.setAppsState(stepName, newApplications, oldAppsState);

        this.setState({
          appsState: newAppsState,
          sendBulkTests: false,
          bulkTestPeopleIds: [],
          bulkTestStep: {},
          loading: false,
        });
      } else {
        this.setState(
          {
            loading: true,
          },
          () => {
            getJSON(window.Routes.fetch_employer_applications({ ids: applicationIds }))
              .then(response => {
                this.sendBulkTestsAftermath(response.applications?.data);
              })
              .catch(e => {
                handleRequestError(e);

                this.setState({
                  sendBulkTests: false,
                  bulkTestPeopleIds: [],
                  bulkTestStep: {},
                  loading: false,
                });
              });
          }
        );
      }
    } else {
      setTimeout(() => this.sendBulkTestsAftermath(applications), 2000);
    }
  }

  fetchPagedApplication = (
    target = this.state.topFilter,
    page = 0,
    filters = this.fetchFilters()
  ) => {
    if (filters === 'false' && this.state.appsState.isLoaded(target, page)) {
      const appsState = cloneDeep(this.state.appsState).setPage(target, page);
      this.setState({
        appsState,
        loading: false,
      });
    } else {
      this.fetchRequest(target, page, filters);
    }
  };

  isUsingSideFilters = () => {
    return (
      this.state.filters.ratings.length !== 0 ||
      this.state.filters.country !== '' ||
      this.state.filters.city !== '' ||
      this.state.filters.eu_citizenship ||
      this.state.filters.name !== ''
    );
  };

  fetchRequest = (target = this.state.topFilter, page = 0, filters = 'false') => {
    const scope = `?scope=${target}`;
    const pageURL = `&page=${page + 1}`;
    const filter = `&filters=${filters}`;

    this.setState({ loading: true });

    getJSON(`${this.props.applicationsUrl}${scope}${pageURL}${filter}`, {
      method: 'Post',
    })
      .then(response => {
        this.createSearchTags();
        const responseCounters = response.pop();

        this.setState({
          tabCounters: {
            waiting: responseCounters.waiting || 0,
            inbox: responseCounters.inbox || 0,
            reviewing: responseCounters.review || 0,
          },
        });

        if (this.shouldGroupApplications(target) && target !== 'inbox') {
          response.forEach(([_, applications]) => this.accordeonize(applications.data));
        } else {
          let newAppsState = this.state.appsState
            ? cloneDeep(this.state.appsState)
            : new ApplicationsState({});
          response.forEach(([targetName, { data, total }]) => {
            const slug = target === 'inbox' ? this.upgradeToStateSlug(targetName) : targetName;
            newAppsState = newAppsState.set(slug, data, page, total);
          });
          this.setState({
            appsState: newAppsState,
            loading: false,
          });
        }
      })
      .then(() => {
        if (this.state.scroll) {
          window.scrollTo({ top: this.state.scroll, behavior: 'smooth' });
          this.setState(() => ({
            scroll: 0,
          }));
        }
      })
      .catch(handleRequestError);
  };

  getApplicationsTotal = (response: [string, ApplicationResponse][]) =>
    response.reduce((accumulator, [_, { total }]) => accumulator + total, 0);

  dimensionToRatingsFilter = () => {
    const dimensionReducer = (accumulator, [title, value]) => {
      if (value) {
        return [...accumulator, { title, value }];
      }
      return accumulator;
    };

    const ratings = Object.entries(this.state.ratingsWithDimensions).reduce(dimensionReducer, []);

    this.setFilter(ratings, 'ratings');
  };

  createSearchTags = () => {
    if (!this.state.filtered) {
      return;
    }

    this.setState(() => ({
      searchTags: createSearchTagsFromFilters({
        city: this.state.filters.city,
        country: this.state.filters.country,
        name: this.state.filters.name,
        ratings: this.state.filters.ratings,
        eu_citizenship: this.state.filters.eu_citizenship,
      }),
    }));
  };

  handleDisableFilter = (type: string, label: string) => {
    switch (type) {
      case 'rating':
        const newLabel = unTitleize(label).split(':')[0];
        const newRatings = this.state.filters.ratings.filter(rating => rating.title !== newLabel);
        this.setState(prevState => {
          prevState.ratingsWithDimensions[newLabel] = 0;
          return {
            ...prevState,
          };
        });
        this.setFilter(newRatings, 'ratings', this.applyFilters);
        return;
      case 'name':
        handleCleanNameInput();
        break;
    }
    this.setFilter(defaultFilters[type], type, this.applyFilters);
  };

  handlePageClick = (
    pageData: { selected: number },
    target = this.state.topFilter,
    silent = false
  ) => {
    const page = pageData.selected;
    const appsState = cloneDeep(this.state.appsState).setPage(target, page);
    this.setState({ appsState, loading: !silent });
    if (!appsState.isLoaded(target, page)) {
      this.fetchPagedApplication(target, page);
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  showLoader = () => {
    return this.state.loading && this.getSplatApplications().length === 0;
  };

  /**
   * On a request, handle errors and notices that may be important for the user
   *
   * @param response
   */
  giveAlert(response) {
    if (response.message) {
      if (response.message_type === 'alert') {
        window.Alerts.alert(response.message);
      } else {
        window.Alerts.notice(response.message);
      }
    }
  }

  reconsiderApplication() {
    ReconsiderModal.show(
      this.state.application.attributes.urls.reconsider_application_url.replace(
        ':id',
        this.state.application.id
      ),
      this.reconsiderApplicationAftermath.bind(this, this.state.application)
    );
  }

  reconsiderApplicationAftermath(app) {
    getJSON(this.state.application.attributes.urls.application_url)
      .then(this.handleApplicationResponse.bind(this, app, null))
      .catch(handleRequestError);
  }

  createRejectionForm(isBulk, ids) {
    let updateObject;

    if (isBulk) {
      const application = this.getFirstApplicationOfBulk(ids);
      updateObject = {
        applicationId: application,
        url: this.props.urls.bulkReject,
        applications: ids,
      };
    } else {
      updateObject = {
        applicationId: this.state.application,
        url: this.state.application.attributes.urls.rejection_url,
      };
    }
    return updateObject;
  }

  rejectApplicationProbablyNotAMatch(isBulk = false, rejectFunction, ids = []) {
    const rejectionForm = this.state.rejectFormProbablyNotAMatch;
    const updateObject = this.createRejectionForm(isBulk, ids);

    rejectionForm.update(updateObject);

    if (rejectionForm.validate()) {
      rejectionForm
        .save()
        .then(e => {
          if (e.notice) {
            window.Alerts.notice(e.notice);
            rejectionForm.update({ saved: true });

            this.setState(() => {
              return { rejectFormProbablyNotAMatch: rejectionForm };
            });

            rejectFunction();
          } else {
            window.Alerts.alert(e.alert);
            rejectionForm.update({ saved: false });
            window.toggleSpinner(false);
          }
        })
        .catch(() => {
          window.Alerts.alert('Oops, something went wrong.');
          rejectionForm.update({ saved: false });
          window.toggleSpinner(false);
        });
    } else {
      this.setState(() => {
        return { rejectFormProbablyNotAMatch: rejectionForm };
      });
    }
  }

  isProbablyNotAMatch(app) {
    return (
      app.attributes.rating &&
      app.attributes.employer_filter === 'inbox' &&
      app.attributes.inbox_classification === 'probably_not_a_match'
    );
  }

  /**
   * Reject the given application
   *
   * @param {Application} app
   */
  rejectApplication(app = this.state.application) {
    if (this.isProbablyNotAMatch(app)) {
      return this.rejectApplicationProbablyNotAMatch(
        false,
        this.rejectApplicationAftermath.bind(this, [app.id])
      );
    } else {
      RejectionModal.show(
        app,
        app.attributes.urls.rejection_url,
        this.rejectApplicationAftermath.bind(this, [app.id]),
        this.props.rejectionDimensionsMapping
      );
    }
  }

  getBulkRejectApplications(ids) {
    const allApplications = this.state.appsState.getCompleteList();
    return allApplications.filter(({ id }) => ids.includes(id));
  }

  getBulkRejectNames(apps) {
    if (apps) {
      return apps.map(app => app.attributes.person.attributes.name);
    }
  }

  getFirstApplicationOfBulk(ids) {
    const allApplications = this.state.appsState.getCompleteList();
    return allApplications.find(({ id }) => id === ids[0]);
  }

  bulkReject(ids) {
    const application = this.getFirstApplicationOfBulk(ids);
    const applications = ids.length > 1 ? this.getBulkRejectApplications(ids) : null;
    if (this.isProbablyNotAMatch(application)) {
      return this.rejectApplicationProbablyNotAMatch(
        true,
        this.rejectApplicationAftermath.bind(this, ids),
        ids
      );
    } else {
      RejectionModal.show(
        application,
        this.props.urls.bulkReject,
        this.rejectApplicationAftermath.bind(this, ids),
        this.props.rejectionDimensionsMapping,
        ids,
        this.getBulkRejectNames(applications)
      );
    }
  }

  rejectApplicationAftermath(ids) {
    const appsState = cloneDeep(this.state.appsState).remove(ids);
    if (this.state.application) {
      window.location.reload();
    } else {
      this.setState({ appsState, loading: false });
    }

    window.toggleSpinner(false);
  }

  /**
   * Send test invite to candidate
   *
   */

  sendTest(application = this.state.application) {
    if (application.attributes == null) application = this.state.application;
    if (!document.getElementById('send-test-modal')) {
      SendTestModal.show(
        application.attributes.urls.test_invite_url,
        application.attributes.urls.test_check_url,
        application.attributes.current_hiring_step_id,
        application.attributes.person.id,
        application.attributes.job_id,
        this.sendTestAftermath.bind(this, application)
      );
    }
  }

  sendTestAftermath(application) {
    getJSON(application.attributes.urls.application_url)
      .then(response => {
        this.handleApplicationResponse(application, null, response);
      })
      .catch(handleRequestError);
  }

  /**
   * Renders the EmployerApplications components depending on the
   * current state of the component
   *
   */

  getSplatApplications = () => {
    const topFilter = this.state.topFilter;

    if (topFilter === 'rejected') {
      return this.state.appsState.box(topFilter, 'splat');
    }

    return this.state.appsState.box(topFilter, 'splat');
  };

  render() {
    return (
      <>
        {this.renderApplicationsList()}
        {this.state.application && (this.state.loading ? this.loader() : this.renderApplication())}
        {this.renderMismatchFeedbackModal()}
      </>
    );
  }

  ratingsWithDimensions = () => this.state.applicationInformation.ratings_with_dimensions;

  /**
   * Render the Application page for a given application
   */
  renderApplication() {
    return (
      <div className='employer-applications-wrapper'>
        <ApplicationPage
          seenList={this.state.seenList}
          application={this.state.application}
          moveToStep={this.moveToStep}
          rejectApplication={this.rejectApplication}
          giveAlert={this.giveAlert}
          loading={this.state.loading}
          backButton={this.removeApplication}
          applicationQuestions={this.state.applicationInformation.applicationQuestions}
          urls={this.state.applicationInformation.urls}
          is_inbox={this.state.applicationInformation.is_inbox}
          ats_offer={this.state.applicationInformation.ats_offer}
          ats_provider={this.state.applicationInformation.ats_provider}
          delivered={this.state.applicationInformation.delivered}
          ats_name={this.state.applicationInformation.ats_name}
          job_editable={this.state.applicationInformation.job_editable}
          step_label={this.state.application.attributes.step_label}
          reconsiderApplication={this.reconsiderApplication}
          sendTest={this.sendTest}
          topFilter={this.state.topFilter}
          showRatingBody={false}
          ratingsWithDimensions={this.ratingsWithDimensions()}
          outdated={this.props.outdated}
        />
      </div>
    );
  }

  renderJobTitleFromFilters() {
    if (!this.state.filters.job_id && this.state.application) {
      this.setFilter(this.state.application.attributes.job_id, 'job_id');
    }

    if (this.state.filters.job_id) {
      const jobAd = [...this.props.other_jobs, ...this.props.followed_jobs].find(
        job => job.id.toString() === this.state.filters.job_id.toString()
      );

      if (jobAd) {
        return (
          <div className='job-title-from-filter'>
            {jobAd.attributes.title}
            {jobAd.attributes.location && ` (${jobAd.attributes.location})`}
          </div>
        );
      }
    }
  }

  renderNewFeatureModal() {
    return (
      this.state.new_feature_date &&
      (!this.state.featureModalSeenDate ||
        this.state.featureModalSeenDate < this.state.new_feature_date) &&
      this.state.isFeaturesModalOpen && (
        <WhatsNewModalItem
          setOpenModal={() => this.setState(() => ({ isFeaturesModalOpen: false }))}
          isCompany
        />
      )
    );
  }

  /**
   * Renders the applications list based on the current top filter
   *
   */
  renderApplicationsList() {
    const className = classNames('employer-applications-wrapper', {
      'is-hidden': !!this.state.application,
    });

    const applications = this.getSplatApplications();

    return (
      <div className={className}>
        <HeaderJobFilter
          applyFilters={this.applyFilters}
          followedJobs={this.props.followed_jobs}
          job_id={this.state.filters.job_id}
          otherJobs={this.props.other_jobs}
          setFilter={this.setFilter}
        />
        <div className={this.state.mobileFiltersOpen ? 'disabled' : 'top-bar'}>
          <TopFilters
            setTopFilter={this.setTopFilter}
            readyCounter={this.state.tabCounters.inbox}
            reviewCounter={this.state.tabCounters.reviewing}
            activeFilter={this.state.topFilter}
            waitingForScreeningCounter={this.state.tabCounters.waiting}
          />
        </div>
        <div className='applications-body'>
          <div className='left-side'>
            <SidebarFilters
              setDimension={this.setDimension}
              setFilter={this.setFilter}
              filters={this.state.filters}
              dimensions={this.state.ratingsWithDimensions}
              googleMapsApiKey={this.props.googleMapsApiKey}
              clearFilters={this.clearFilters}
              openCloseMobileFilters={this.openCloseMobileFilters}
              applyFilters={this.applyFilters}
              mobileFiltersOpen={this.state.mobileFiltersOpen}
              topFilter={this.state.topFilter}
            />
          </div>
          <div className={this.state.mobileFiltersOpen ? 'disabled' : 'right-side'}>
            {this.renderJobTitleFromFilters()}
            {this.state.filtered && (
              <SearchTagList
                topFilter={this.state.topFilter}
                searchTags={this.state.searchTags}
                handleDeleteTag={this.handleDisableFilter}
              />
            )}
            {this.showLoader() ? (
              this.loader()
            ) : (
              <ApplicationList
                seenList={this.state.seenList}
                bulkMoveToStep={this.bulkMoveToStep}
                bulkReject={this.bulkReject}
                chatUrl={this.props.chat_url}
                contactsUrl={this.props.contacts_url}
                disengaged={this.props.disengaged}
                hiringSteps={this.props.hiringSteps}
                moveToStep={this.moveToStep}
                rejectApplication={this.rejectApplication}
                selectedJobId={this.selectedJobId()}
                setApplication={this.setApplication}
                topFilter={this.state.topFilter}
                urls={this.props.urls}
                loadMoreApplications={this.fetchPagedApplication}
                handlePageClick={this.handlePageClick}
                applications={applications}
                appsState={cloneDeep(this.state.appsState)}
                loader={this.loader}
                shouldGroupApplications={this.shouldGroupApplications}
                loading={this.state.loading}
                accordionsWithNewRules={this.accordionsWithNewRules}
                waitingForScreeningCounter={this.state.tabCounters.waiting}
                numberOfTalentUsers={this.props.numberOfTalentUsers}
              />
            )}
          </div>
        </div>
        {this.renderNewFeatureModal()}
        {this.state.sendBulkTests && (
          <SendBulkTestsModal
            peopleIds={this.state.bulkTestPeopleIds}
            testStep={this.state.bulkTestStep}
            sendBulkTestsAftermath={this.sendBulkTestsAftermath}
          />
        )}
      </div>
    );
  }
}

export default EmployerApplications;
