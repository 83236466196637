import { toQueryString } from 'lib/string';

enum State {
  Unloaded,
  Loading,
  Loaded,
}

class Script {
  private promise: Promise<any>;
  private promiseResolve: Function;
  private initializer?: Function | boolean;
  private result?: any;
  private state: State;
  private url: string;

  constructor(url, initializer?) {
    this.initializer = initializer;
    this.url = url;
    this.state = State.Unloaded;
  }

  load() {
    if (this.state === State.Unloaded) {
      this.promise = new Promise<any>((resolve, reject) => {
        this.promiseResolve = resolve;
        this.state = State.Loading;
        const script = document.createElement('script');
        script.addEventListener('load', this.afterLoad.bind(this));
        script.src = this.url;
        document.head.appendChild(script);
      });
    }
    return this.promise;
  }

  private afterLoad() {
    this.state = State.Loaded;
    if (!this.initializer) {
      this.setInitialized();
    } else if (typeof this.initializer === 'function') {
      this.initializer(this);
    }
  }

  setInitialized() {
    this.promiseResolve(this.result);
    delete this.promiseResolve;
    delete this.result;
  }
}

function googleMapsUrl() {
  const params = toQueryString({
    callback: 'googleMapsInitCallback',
    key: window.gon?.googleMapsApiKey,
    language: 'en',
    libraries: 'places',
  });
  return `https://maps.googleapis.com/maps/api/js?${params}`;
}

function googleMapsInitCallback() {
  GoogleMaps.setInitialized();
}

export const GoogleMaps = new Script(googleMapsUrl(), true);

window.googleMapsInitCallback = googleMapsInitCallback;
