import React from 'react';
import InlineIcon from 'components/general/inline_icon/inline_icon';

const styles = require('./experience_card.module.scss');
const crossIcon = require('iconic/x-thin.svg');

interface Props {
  cardStyle?: string;
  title: string;
  description?: string;
  handleDeleteClick: any;
  id?: string;
}

const ExperienceCard = (props: Props) => {
  return (
    <div className={`${styles.card} ${props.cardStyle}`} id={props.id}>
      <div className={styles.cardLeft}>
        <div className={styles.title}>{props.title}</div>
        <div className={styles.description}>{props.description}</div>
      </div>
      <div onClick={props.handleDeleteClick}>
        <InlineIcon path={crossIcon} className={styles.icon} />
      </div>
    </div>
  );
};

export default ExperienceCard;
