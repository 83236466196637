import React, { useEffect, useState } from 'react';
import { patchJSON, handleRequestError } from '../../lib/request_deprecated';
import { renderRejected } from './handshake_utils';
import HandshakeCard, { type DefaultCard } from './handshake_card';
import RevokeHandshake from './RevokeHandshake';
import AcceptHandshake from './accept_handshake';
import RejectHandshake from 'components/handshakes/reject_handshake/reject_handshake';
import ExpiresIn from 'components/expiresIn/expiresIn';

const styles = require('./accept_and_reject_handshake.module.scss');

export interface Props {
  cancelReasons?: any;
  cvs: any[];
  handshake: any;
  handshake_company?: { name: string; id: number; logo: string };
  handshake_job_ad: { title: string; id: number };
  hideBody?: boolean;
  cardRef?: React.RefObject<HTMLDivElement>;
  title?: JSX.Element;
  body?: JSX.Element | string;
  customCss?: string;
  secondaryButton?: JSX.Element;
}

export default function AcceptAndRejectHandshake(props: Props) {
  const [hideBody, setHideBody] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);

  function handleScroll() {
    props.hideBody && setHideBody(window.pageYOffset > 10);
  }
  const [handshakeState, setHandshakeState] = useState(props.handshake.data.attributes.state);

  const handleReconsiderSuccess = ({ state, message, error }) => {
    setHandshakeState(state);
    error ? window.Alerts.alert(message) : window.Alerts.notice(message);
  };

  const handleReconsider = () => {
    patchJSON(window.Routes.reconsider_dashboard_handshake(props.handshake.data.id))
      .then(data => {
        handleReconsiderSuccess(data);
      })
      .catch(handleRequestError);
  };

  const renderRequested = () => {
    const requestedCard = {} as DefaultCard;

    requestedCard.title = props.title;

    if (!hideBody) {
      requestedCard.body =
        props.body ??
        `${props.handshake.data.attributes.company.name} requested a Handshake associated with this job, if you accept we will automatically create an application.`;
    }
    requestedCard.buttons = (
      <div className={styles.requestedButtonsContainer}>
        <ExpiresIn days={props.handshake.data.attributes.expires_in} />
        <div className={styles.buttons}>
          <AcceptHandshake
            buttonSize='small'
            cvs={props.cvs}
            handshake={props.handshake.data.attributes}
            handshake_company={props.handshake.data.attributes.company}
            handshake_job_ad={props.handshake_job_ad}
            hasFollowUpModal={false}
          />
          <RejectHandshake
            setHandshakeState={setHandshakeState}
            handshake={props.handshake.data.attributes}
            company={props.handshake.data.attributes.company}
            jobAd={props.handshake_job_ad}
            buttonColor={'flamingo'}
            hasFollowUpModal={false}
          />
        </div>
      </div>
    );

    return requestedCard;
  };

  const renderAccepted = () => {
    const acceptedCard: DefaultCard = {
      title: props.title ?? <span className={styles.acceptedSpan}>ACCEPTED</span>,
      body:
        props.body ??
        `${props.handshake.data.attributes.company.name} requested a Handshake associated with this job.`,
      buttons: props.secondaryButton ?? (
        <RevokeHandshake
          handshake={props.handshake.data.attributes}
          handshake_company={props.handshake.data.attributes.company}
          handshake_job_ad={props.handshake_job_ad}
          cancelReasons={props.cancelReasons}
          jobPage={true}
        />
      ),
    };
    return acceptedCard;
  };

  const renderExpired = () => {
    const expiredCard: DefaultCard = {
      title: <span className={styles.acceptedSpan}>(Expired)</span>,
      body: `${props.handshake.data.attributes.company.name} requested a Handshake associated with this job.`,
    };
    return expiredCard;
  };

  const renderHandshakeSwitch = () => {
    switch (handshakeState) {
      case 'requested':
        return renderRequested();
      case 'rejected':
        return renderRejected({
          handleReconsider,
          date: props.handshake.data.attributes.created_at,
          companyName: props.handshake.data.attributes.company.name,
          title: props.title,
          body: props.body,
          secondaryButton: props.secondaryButton,
        });
      case 'unlocked':
        return renderAccepted();
      case 'closed':
        return renderExpired();
      default:
        return renderRequested();
    }
  };
  return (
    <HandshakeCard
      customCss={props.customCss}
      cardRef={props.cardRef}
      {...renderHandshakeSwitch()}
    />
  );
}
