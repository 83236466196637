import * as React from 'react';
import Actions from './Actions';
import { shallow } from 'enzyme';
import companyDummy from '__fixtures__/company_dummy';
import jobDummy from '__fixtures__/job_dummy';

describe('Actions', () => {
  it('shallow renders without crashing', () => {
    jobDummy.company = companyDummy;
    const component = shallow(<Actions job={jobDummy} />);
    expect(component.exists()).toBe(true);
  });
});
