export const ripePlumTheme = theme => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    primary25: '#F7E3F2',
    primary50: '#DB89C8',
    primary75: '#C94CAC',
    primary: '#992E80',
  },
});

export const silverSandTheme = theme => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    primary25: '#F4F5F6',
    primary50: '#E9EBEC',
    primary75: '#D4D7D8',
    primary: '#BEC2C5',
  },
});

export const multiValueStyles = colorOption => {
  const colorDictionary = {
    ripePlum: '#992E80',
    puertoRico: '#3BBCB0',
    silverSand: '#26333C',
  };
  const color = colorDictionary[colorOption];

  return {
    multiValue: styles => {
      return {
        ...styles,
        backgroundColor: 'white',
        border: `1px solid ${color}`,
      };
    },
    multiValueLabel: styles => ({
      ...styles,
      color: `${color}`,
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: `${color}`,
      ':hover': {
        backgroundColor: `${color}`,
        color: 'white',
      },
    }),
    control: styles => ({
      ...styles,
      minHeight: '42px',
    }),
  };
};
