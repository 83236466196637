import * as React from 'react';
import NoteForScreening, { Props } from './note_for_screening';
import { shallow, ShallowWrapper } from 'enzyme';

const createTestProps = moreProps => {
  return {
    title: 'title',
    note: [{ note_text: 'note' }],
    extraNote: [{ note_text: 'note' }],
    employer: false,
  };
};

let props: Props;
let wrapped: ShallowWrapper;

describe('Note For Screening', () => {
  beforeEach(() => {
    props = createTestProps({});
    wrapped = shallow(<NoteForScreening {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
