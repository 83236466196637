import React from 'react';
import { mount } from 'enzyme';
import ShareInput, { Props } from './ShareInput';

const createProps = (otherProps = {}) => ({
  error: '',
  handleFormChange: jest.fn,
  options: {
    "I don't allow Landing.Jobs to share my profile with potential employers, I'll do the job search by myself": 0,
    'I allow Landing.jobs to anonymously share my profile with potential employers': 1,
    'I allow Landing.jobs to share my profile with any potential employer (my contact info will be hidden)': 4,
  },
  shareProfile: null,
  ...otherProps,
});

let props: Props;
let wrapped;

describe('Input is empty', () => {
  beforeAll(() => {
    props = createProps();
    wrapped = mount(<ShareInput {...props} />);
  });

  it('renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('shows the label', () => {
    expect(wrapped.find('.label').text()).toEqual('Your profile sharing preferences: *');
  });

  it('has three radio buttons', () => {
    expect(wrapped.find('input.radio')).toHaveLength(3);
  });

  it('all radio buttons are unchecked', () => {
    wrapped.find('input.radio').forEach(input => expect(input.props().checked).toBeFalsy);
  });
});

describe('Input has one value selected', () => {
  beforeAll(() => {
    props = createProps({ shareProfile: 4 });
    wrapped = mount(<ShareInput {...props} />);
  });

  it('checks the radio button', () => {
    expect(wrapped.find('input[value=4]').props().checked).toBeTruthy();
  });
});
