import * as React from 'react';
import { Assessment } from './models';
import classNames from 'classnames';
import Icon, { Size } from 'components/general/icon/icon';
const styles = require('./test_card_item.module.scss');

interface Props {
  technicalTest: Assessment;
  handleTestIdSelect?: Function;
  selectedTest: number;
}

export default function TestCardItem(props: Props) {
  function renderExperience() {
    const experience = props.technicalTest.attributes.experience_levels;
    return !experience || experience.length === 0
      ? 'General'
      : experience.map(value => value + ' ');
  }

  function createTest() {
    const { attributes } = props.technicalTest;
    return { value: attributes.id, label: attributes.name };
  }

  function selectTest() {
    const test = createTest();
    props.handleTestIdSelect(test);
  }

  function cardStyle() {
    return props.selectedTest === props.technicalTest.attributes.id ? styles.pinkContainer : null;
  }

  const showUrl = () => {
    const url = props.technicalTest.attributes.sample_url;
    if (url) {
      return (
        <a href={url} target='_blank' rel='noreferrer'>
          Sample
        </a>
      );
    }
  };

  return (
    <div className={classNames(styles.container, cardStyle())} onClick={() => selectTest()}>
      <div className={styles.leftSide}>
        <Icon name='cogs' size={Size.Big} color='ripePlum' />
      </div>
      <div className={styles.rightSide}>
        <div className={styles.firstRow}>{props.technicalTest.attributes.name}</div>
        <div className={styles.secondRow}>
          <div className={styles.item}>{showUrl()}</div>
          <div className={styles.item}>
            <Icon className={styles.icons} name='timer' size={Size.Small} />
            {props.technicalTest.attributes.duration}
          </div>
          <div className={styles.item}>
            <Icon className={styles.icons} name='signal' size={Size.Small} />
            {renderExperience()}
          </div>
        </div>
      </div>
    </div>
  );
}
