import * as React from 'react';
import { ApolloProvider, Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import { apolloClient } from 'lib/graphql';
import Modal from 'components/general/modal/modal';
import TextInput from 'components/form/text/text';
import { ButtonsLayout } from '../modal/modal';
import PubSub from 'pubsub-js';

interface State {
  jobId?: number;
  categoryId: number;
  nameCannotBeEmptyError: boolean;
  nameCannotBeLong: boolean;
  nameIsInvalid: boolean;
  descriptionCannotBeEmptyError: boolean;
  initialTagName: string;
}

const CREATE_GQL = gql`
  mutation ($name: String!, $description: String!, $jobAdId: ID, $category: ID) {
    createProposedTag(
      name: $name
      description: $description
      jobAdId: $jobAdId
      category: $category
    ) {
      resource {
        description
        id
        name
      }
      errors {
        field
        message
      }
    }
  }
`;

export default class ProposeTag extends React.Component<{}, State> {
  descriptionRef: any;
  modalRef: any;
  nameRef: any;

  constructor(props) {
    super(props);
    this.state = { ...props };
    this.modalRef = React.createRef();
    this.nameRef = React.createRef();
    this.descriptionRef = React.createRef();
  }

  openProposeTagForm(proposedTagName) {
    this.setState(state => ({
      initialTagName: proposedTagName,
    }));
    this.modalRef.current.open();
  }

  handleOpen = event => {
    event.preventDefault();
    this.modalRef.current.open();
  };

  handleSubmit = mutation => event => {
    event.preventDefault();

    let validationError = false;
    if (!this.nameRef.current.value.trim()) {
      this.setState({ nameCannotBeEmptyError: true });
      validationError = true;
    } else {
      this.setState({ nameCannotBeEmptyError: false });
    }

    if (this.nameRef.current.value.length > 50) {
      this.setState({ nameCannotBeLong: true });
      validationError = true;
    } else {
      this.setState({ nameCannotBeLong: false });
    }

    const invalidChars = [',', ';'];
    if (new RegExp(invalidChars.join('|')).test(this.nameRef.current.value)) {
      this.setState({ nameIsInvalid: true });
      validationError = true;
    } else {
      this.setState({ nameIsInvalid: false });
    }

    if (!this.descriptionRef.current.value.trim()) {
      this.setState({ descriptionCannotBeEmptyError: true });
      validationError = true;
    } else {
      this.setState({ descriptionCannotBeEmptyError: false });
    }

    if (validationError) {
      return;
    }

    const variables = {
      description: this.descriptionRef.current.value,
      name: this.nameRef.current.value,
      jobAdId: this.state.jobId,
      category: this.state.categoryId,
    };

    this.setState(state => ({
      initialTagName: null,
    }));
    mutation({ variables }).then(response => {
      if (response === null || response.data === null || response.data.createProposedTag === null) {
        // Uncontrolled error
        window.Alerts.alert('Oops, something went wrong.');
      } else if (
        response.data.createProposedTag.errors != null &&
        response.data.createProposedTag.errors.length !== 0
      ) {
        // Controlled error
        window.Alerts.alert(response.data.createProposedTag.errors[0].message);
      } else {
        const proposedTag = response.data.createProposedTag.resource;
        PubSub.publish('proposedTagCreated', {
          id: proposedTag.id,
          name: proposedTag.name,
          description: proposedTag.description,
          categoryId: this.state.categoryId,
        });
      }
    });
  };

  handleSubmitSuccess = () => {
    this.modalRef.current.close();
  };

  render() {
    return (
      <ApolloProvider client={apolloClient}>
        <Mutation
          mutation={CREATE_GQL}
          // eslint-disable-next-line react/no-children-prop
          children={this.renderModal}
          onCompleted={this.handleSubmitSuccess}
        />
      </ApolloProvider>
    );
  }

  renderModalContent() {
    const skillLabelError = message => (
      <div className='ld-alert ld-error-alert title-error'>{message}</div>
    );

    return (
      <>
        <p>
          Want to share your knowledge with us? Propose a technical hard skill, like a programming
          language, a framework or methodology (we're only accepting proposals in English).
        </p>

        <TextInput
          inputRef={this.nameRef}
          value={this.state.initialTagName}
          label='Skill name'
          name='name'
          required={true}
          placeholder='E.g. PHP'
        />
        {this.state.nameCannotBeEmptyError && skillLabelError("This field can't be empty.")}
        {this.state.nameCannotBeLong &&
          skillLabelError(
            `Skill name cannot be longer than 50 characters. Current: ${this.nameRef.current.value.length}.`
          )}
        {this.state.nameIsInvalid && skillLabelError("Skill name cannot contain ',' or ';'.")}
        <TextInput
          required={true}
          inputRef={this.descriptionRef}
          label='Skill description'
          name='description'
          textarea={true}
          placeholder="Briefly describe the Skill you're adding"
        />
        {this.state.descriptionCannotBeEmptyError && (
          <div className='ld-alert ld-error-alert title-error'>This field can't be empty.</div>
        )}
      </>
    );
  }

  renderModal = (mutate, { loading, data = null, error = null }) => {
    return (
      <Modal
        title='Propose a new skill'
        defaultOpen={false}
        ref={this.modalRef}
        // eslint-disable-next-line react/no-children-prop
        children={this.renderModalContent()}
        cancelButton={true}
        buttonName={loading ? 'Proposing...' : 'Propose'}
        buttonColor={this.state.jobId != null ? 'ripePlum' : 'tuftsBlue'}
        buttonOnClick={e => this.handleSubmit(mutate)(e)}
        buttonsLayout={ButtonsLayout.CancelOk}
      />
    );
  };
}
