import * as React from 'react';
import ApplicationHeader, { Props } from './application_header';
import { shallow, ShallowWrapper } from 'enzyme';
import applicationDummy from '__fixtures__/application_dummy';

const createTestProps = moreProps => {
  return {
    hiringSteps: [],
    backButton: '',
    currentState: '',
    urls: '',
    moveToStep: '',
    rejectApplication: '',
    delivered: true,
    stepLabel: '',
    waitingReview: true,
    application: applicationDummy,
    ...moreProps,
  };
};

let props: Props;
let wrapped: ShallowWrapper;

describe('Application Header component', () => {
  beforeEach(() => {
    props = createTestProps({});
    wrapped = shallow(<ApplicationHeader {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
