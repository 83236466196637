import React, { useRef, useState } from 'react';
import Button from 'lj_shared/button/button';
import Modal from '../modal/modal';
import RadioButtons from 'components/form/radio_buttons/radio_buttons';
import { postJSON, handleRequestError } from 'lib/request_deprecated';
import FormInput from 'components/general/form_input/form_input';

const styles = require('./mismatchTimezoneModal.module.scss');

interface Props {
  applicationId: number;
  jobTimezone: string;
  jobTitle: string;
  personTimezone: string;
  remoteDetails: string;
}

export default function MismatchTimezoneModal(props: Props) {
  const modalRef = useRef(null);
  const [selectedValue, setSelectedValue] = useState(undefined);

  const handleSubmit = () => {
    if (selectedValue !== undefined) {
      postJSON(
        window.Routes.timezone_mismatch_application_url(props.applicationId, {
          accepted_different_timezone: selectedValue,
        })
      ).catch(handleRequestError);
      modalRef.current.close();
      if (!selectedValue) {
        window.location = window.Routes.job_ads_url();
      }
    }
  };

  return (
    <Modal
      defaultOpen={true}
      ref={modalRef}
      title='Time zone mismatch'
      className={styles.background}
      disableOutsideClose
      disableClose
    >
      <div className={styles.text}>
        There is a mismatch between your time zone and the range eligible for this job.
      </div>
      <div className={styles.text}>
        <span className={styles.boldText}>Your time zone:</span> {props.personTimezone}
      </div>
      <div className={styles.text}>
        <span className={styles.boldText}>Eligible time zone range for the job:</span>{' '}
        {props.jobTimezone}
      </div>
      {props.remoteDetails && (
        <div className={styles.text}>
          <span className={styles.boldText}>Check other remote details for {props.jobTitle}:</span>{' '}
          {props.remoteDetails}
        </div>
      )}
      <div className={styles.text}>
        <FormInput
          name='application_form[accepted_different_timezone]'
          label='Are you willing to work under these work schedule conditions?'
          required
        >
          <RadioButtons
            name='application_form[accepted_different_timezone]'
            color='tuftsBlue'
            selectedValue={selectedValue}
            onChange={event => setSelectedValue(event)}
            options={[
              {
                value: true,
                label: 'I am willing to work under these work schedule conditions.',
              },
              {
                value: false,
                label:
                  'I am not willing to work under these work schedule conditions and would rather look for other jobs.',
              },
            ]}
          />
        </FormInput>
      </div>
      <div className={styles.footer}>
        <Button
          buttonColor='tuftsBlue'
          isButton={false}
          otherClasses={styles.signUpButton}
          buttonSize='mid'
          isRound
          onClick={() => handleSubmit()}
          disabled={selectedValue === undefined}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
}
