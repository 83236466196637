import 'lib/globals';
import { createElement as reactCreateElement, useEffect, useRef } from 'react';
import { render as reactDOMRender } from 'react-dom';

// For use by CoffeeScript code
export function insertReactElement(parent, componentPath, props) {
  const constructor = window.ReactRailsUJS.getConstructor(componentPath);
  const element = reactCreateElement(constructor, props, null);
  reactDOMRender(element, parent);
}

// Props to https://usehooks-typescript.com/react-hook/use-interval
export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
}
