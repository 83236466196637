import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Modal from '../general/modal/modal';
import Button from 'lj_shared/button/button';
import * as Cookies from 'js-cookie';

const styles = require('./sign_up_modal.module.scss');
const starCheck = require('images/check-blue.svg');
const circleCheck = require('images/circle-check-sm-blue.svg');
const JOBS_SEEN = 'anonymous-jobs_seen';
const FIRST_SEARCH = 'anonymous-first_search';

interface Props {
  context: string;
  sign_up_path: string;
  login_path: string;
  onClose?: Function;
}

interface State {
  render: boolean;
}

const offers = [
  'Get access to job matches',
  'Subscribe to your job searches and get notifications',
  'Receive Handshakes from companies who want to get in touch',
  'Apply to the right job',
];

export default class SignUpModal extends React.Component<Props, State> {
  modalRef: any;
  scrollRef: any;

  constructor(props: Props) {
    super(props);
    this.modalRef = React.createRef();
    this.scrollRef = React.createRef();
    this.state = { render: false };
  }

  componentDidMount() {
    if (this.props.context === 'index') {
      setTimeout(
        function () {
          this.setState({ render: true });
        }.bind(this),
        6000
      );
      this.setFirstSearchCookies();
    } else if (this.props.context === 'show') {
      this.setJobsSeenCookies();
    }
  }

  setFirstSearchCookies() {
    const firstSearch = Cookies.get(FIRST_SEARCH);
    if (!firstSearch) {
      Cookies.set(FIRST_SEARCH, 'true');
    } else if (firstSearch === 'true') {
      Cookies.set(FIRST_SEARCH, 'false');
    }
  }

  setJobsSeenCookies() {
    const jobsSeen = Cookies.get(JOBS_SEEN);
    if (!jobsSeen) {
      Cookies.set(JOBS_SEEN, '1');
    } else if (jobsSeen === '1') {
      Cookies.set(JOBS_SEEN, '2+');
    }
  }

  renderOffers() {
    return offers.map(offer => (
      <div key={offer} className={styles.text}>
        <img src={circleCheck} className={styles.circleCheck} />
        {offer}
      </div>
    ));
  }

  verifyContext() {
    return (
      (this.props.context === 'index' &&
        this.state.render &&
        Cookies.get(FIRST_SEARCH) === 'true') ||
      (this.props.context === 'show' && Cookies.get(JOBS_SEEN) === '1') ||
      this.props.context === 'more'
    );
  }

  render() {
    const renderContainer = false;
    if (this.verifyContext()) {
      return (
        <Modal
          defaultOpen={true}
          ref={this.modalRef}
          title='Ready to start?'
          className={styles.background}
          onClose={this.props.onClose}
        >
          <div className={styles.wrapper}>
            <div className={styles.innerSquare}>
              <div className={styles.title}>
                <img src={starCheck} className={styles.starCheck} />
                {this.props.context === 'more' ? 'Sign up to see more' : 'Sign up'}
              </div>
              <hr className={styles.line} />
              <div className={styles.subTitle}>Enjoy what our platform has to offer</div>
              {this.renderOffers()}
            </div>
            <div className={styles.footer}>
              <div className={styles.logIn}>
                Already have an account? <a href={this.props.login_path}>Login here</a>
              </div>
              <Button
                buttonColor='tuftsBlue'
                buttonUrl={this.props.sign_up_path}
                isButton={false}
                otherClasses={styles.signUpButton}
                buttonSize='mid'
              >
                Sign up
              </Button>
            </div>
          </div>
        </Modal>
      );
    }
    return renderContainer;
  }
}
