import React, { useRef } from 'react';
import ConfirmModal from './confirm_modal';
import { shallow } from 'enzyme';

window.Routes = {
  employer_help_index: () => '',
  dashboard_help_index: () => '',
  faq: () => '',
};

describe('ConfirmModal', () => {
  it('shallow renders without crashing', () => {
    const component = shallow(
      <ConfirmModal message={'dummy'} title={'dummy'} resolve={null} ref={null} />
    );
    expect(component.exists()).toBe(true);
  });
});
