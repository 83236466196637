import * as React from 'react';
import { mount } from 'enzyme';
import AcceptHandshakeModal, { Props } from './accept_handshake_modal';
import Modal from 'components/general/modal/modal';
import Button from 'lj_shared/button/button';
import CVUploader from '../general/cv_uploader/cv_uploader';

let props: Props;
let wrapped = null;

window.Routes = {
  job_ad: () => '',
  accept_dashboard_handshake: () => '',
};

const createTestProps = () => {
  return {
    cvs: [],
    handshake: jest.fn(),
    handshake_company: {
      name: 'test company',
      id: 10032,
      logo: 'this is a logo URL',
    },
    handshake_job_ad: { title: 'my job', id: 177013 },
    setIsModalOpen: jest.fn(),
  };
};

describe('Accept Handshake Modal component', () => {
  beforeAll(() => {
    props = createTestProps();
    wrapped = mount(<AcceptHandshakeModal {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('contains a Modal', () => {
    expect(wrapped.find(Modal).length).toEqual(1);
  });

  it('contains 2 Buttons', () => {
    expect(wrapped.find(Button).length).toEqual(2);
  });

  it('contains CVUploader', () => {
    expect(wrapped.find(CVUploader).length).toEqual(1);
  });
});
