import * as React from 'react';
import CurrentApplicationNotesCard, { Props } from './current_application_notes_card';
import { shallow, ShallowWrapper } from 'enzyme';
import curatorsApplicationDummy from '__fixtures__/curators_application_dummy';

const createTestProps = moreProps => {
  return {
    employer_notes: curatorsApplicationDummy.data.attributes.curator_notes,
    job_ad_account_manager_notes:
      curatorsApplicationDummy.data.attributes.job_ad_account_manager_notes,
    company_account_manager_notes:
      curatorsApplicationDummy.data.attributes.company_account_manager_notes,
    talent_notes: curatorsApplicationDummy.data.attributes.talent_notes,
  };
};

let props: Props;
let wrapped: ShallowWrapper;

describe('Current App Notes Card', () => {
  beforeEach(() => {
    props = createTestProps({});
    wrapped = shallow(<CurrentApplicationNotesCard {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
