import * as React from 'react';
import Message, { MessageProps } from './message';
import { shallow, ShallowWrapper } from 'enzyme';
import messageDummy from '__fixtures__/message_dummy';

const createTestProps = moreProps => {
  return {
    message: messageDummy,
    sender: true,
    converter: { makeHtml: jest.fn() },
    showAvatar: true,
    image: '',
    lastMessageSeen: true,
    ...moreProps,
  };
};

let props: MessageProps;
let wrapped: ShallowWrapper;

describe('Message component', () => {
  beforeEach(() => {
    props = createTestProps({});
    wrapped = shallow(<Message {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
