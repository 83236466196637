import React, { useState } from 'react';
import Button from 'lj_shared/button/button';
import WhatsNewModalItem from './WhatsNewModalItem';
import { patchJSON, handleRequestError } from '../../lib/request_deprecated';
import { Feature } from './WhatsNewItem';

const styles = require('./PublishFeatureItem.module.scss');

interface Props {
  feature: Feature;
}

export default function PublishFeatureItem(props: Props) {
  const [modalOpen, setOpenModal] = useState(false);
  const { feature } = props;
  const handleChange = () => {
    patchJSON(
      window.Routes.backoffice_new_feature_publish({
        new_feature_id: feature.id,
      }),
      { new_feature_id: feature.id }
    )
      .then(({ message }) => {
        window.Alerts.notice(message);
      })
      .catch(handleRequestError);
  };

  const publishButton = () => (
    <Button isButton={true} buttonColor='puertoRico' buttonSize='small' onClick={handleChange}>
      Publish
    </Button>
  );

  const previewButton = () => (
    <Button
      isButton={true}
      buttonColor='light'
      buttonSize='small'
      onClick={setOpenModal.bind(null, true)}
    >
      Preview
    </Button>
  );

  return (
    <div className={styles.buttonsSection}>
      {publishButton()}
      {previewButton()}
      {modalOpen && (
        <WhatsNewModalItem
          feature={feature}
          setOpenModal={setOpenModal}
          isPreview={true}
          isCompany={feature.audience === 'companies'}
        />
      )}
    </div>
  );
}
