import React from 'react';
import CardAvatarContainer, {
  Thickness,
} from 'components/general/card/cardAvatar/CardAvatarContainer';
import Button from 'lj_shared/button/button';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import track from 'lib/drip';
import { kebabCase } from 'lib/string';

const jobsIcon = require('images/job-search.svg');
const marketTrendsIcon = require('images/market_trends.svg');
const styles = require('./DashboardHeader.module.scss');

interface Props {
  avatar: string;
  completeness: number;
  name: string;
  talentAdvocate: any;
}

export default function DashboardHeader(props: Props) {
  const { avatar, completeness, talentAdvocate } = props;
  const jobAdsPath = window.Routes.job_ads();
  const profilePath = window.Routes.user_profile();
  const marketTrendsPath = window.Routes.dashboard_market_trends();
  const numberOfSections = [props.completeness < 80, true, true].filter(section => section).length;

  const profile = () => {
    return (
      <>
        <HeaderSection
          avatar={avatar}
          completeness={completeness}
          title='Complete your profile'
          text={
            <div>
              We recommend that you update your profile , so we can get to send you more tailored
              job openings and also to increase your chances of getting hired.
            </div>
          }
          buttonCopy='Update your profile'
          buttonUrl={profilePath}
          numberOfSections={numberOfSections}
        />
        {line()}
      </>
    );
  };

  const marketTrends = () => {
    return (
      <>
        <HeaderSection
          image={marketTrendsIcon}
          title='Market Trends'
          text={
            <div>
              Making a career decision isn’t always easy. Keep up to date with the job market and
              the tech world to make a smart choice.
            </div>
          }
          buttonCopy='See market trends'
          buttonUrl={marketTrendsPath}
          numberOfSections={numberOfSections}
        />
        {line()}
      </>
    );
  };

  const jobs = () => {
    return (
      <>
        <HeaderSection
          image={jobsIcon}
          title='Search for jobs'
          text={
            <div>
              Bookmark the ones that interest you the most. You can also set job subscriptions and
              be one of the first to receive the latest jobs, by email.
            </div>
          }
          buttonCopy='Find a job'
          buttonUrl={jobAdsPath}
          numberOfSections={numberOfSections}
        />
      </>
    );
  };

  const feedbackLink = () => {
    return (
      <div className={styles.talentAvocateFeedback}>
        <a id='feedback-link'>Please give us your feedback</a>
      </div>
    );
  };

  const talentAdvocateCard = () => {
    const talentAdvocateText = () => {
      switch (talentAdvocate?.process_status) {
        case 'inbox':
          return (
            <>
              Hey, meet {talentAdvocate.name}, <br /> your new <strong>Talent Advocate</strong>!
              <br />
              You have been assigned to <br />
              our Talent Advocacy <br />
              program, which means
              <br />
              your profile has great <br />
              potential for us!
              <br />
              We'll get in touch shortly.
            </>
          );
        case 'dropped_hired':
          return (
            <>
              <strong>
                Congratulations on your <br /> new job!{' '}
              </strong>
              <br />
              We are still here for you. <br />
              Feel free to get in touch, <br />
              anytime.
              <br />
              {talentAdvocate?.can_review_an_nps && feedbackLink()}
            </>
          );
        case 'dropped_others':
          return (
            <>
              Thank you for your time!
              <br />
              <strong>
                Every user is important to
                <br />
                us{' '}
              </strong>
              and we are glad to have
              <br />
              you. If you need any help,
              <br />
              please get in touch.
              <br />
              {talentAdvocate?.can_review_an_nps && feedbackLink()}
            </>
          );
        default:
          return (
            <>
              Keep an eye on our job <br />
              suggestions!{' '}
              <strong>
                In the mean <br />
                time can I assist you with <br />
                anything else
              </strong>
              ?
            </>
          );
      }
    };
    return (
      <>
        <HeaderSection
          avatar={talentAdvocate.avatar}
          text={<div className={styles.talentAvocateText}>{talentAdvocateText()}</div>}
          buttonCopy='Get in contact'
          buttonUrl={`mailto:${talentAdvocate.email}`}
          numberOfSections={numberOfSections}
          adminUser={talentAdvocate}
        />
        {line()}
      </>
    );
  };

  const line = () => {
    return <div className={styles.line} />;
  };

  return (
    <div className={styles.wrapper}>
      <h2>{`Great seeing you, ${props.name}!`}</h2>
      <div className={styles.mainContainer}>
        <div className={styles.bottomContainer}>
          {talentAdvocate && talentAdvocateCard()}
          {props.completeness < 80 && profile()}
          {!talentAdvocate && marketTrends()}
          {jobs()}
        </div>
      </div>
    </div>
  );
}

function HeaderSection({
  image = null,
  title = null,
  text,
  buttonCopy,
  buttonUrl,
  numberOfSections,
  avatar = null,
  completeness = null,
  adminUser = null,
}) {
  return (
    <div
      className={classNames(styles.sectionWrapper, {
        [styles.sectionWrapperTwoSections]: numberOfSections === 2 && !isMobile,
        [styles.sectionWrapperThreeSections]: numberOfSections === 3 && !isMobile,
      })}
    >
      <div className={styles.top}>
        <div className={styles.left}>
          {avatar && (completeness || adminUser) ? (
            renderAvatar(avatar, completeness, adminUser)
          ) : (
            <img src={image} className={styles.image} />
          )}
        </div>
        <div
          className={classNames(styles.right, {
            [styles.rigthTwoSections]: numberOfSections === 2 && !isMobile,
            [styles.rigthOneSection]: numberOfSections === 1 && !isMobile,
          })}
        >
          {title && <div className={styles.title}>{title}</div>}
          <div className={styles.text}>{text}</div>
        </div>
      </div>
      <div className={styles.bottom}>
        <Button
          buttonColor='tuftsBlue'
          buttonUrl={buttonUrl}
          isRound
          otherClasses={classNames(styles.button, {
            [styles.buttonOneSection]: numberOfSections === 1 && !isMobile,
          })}
          onClick={() =>
            track('action', {
              name: 'candidate-dashboard-header-click',
              controller: 'dashboard',
              action: kebabCase(buttonCopy),
            })
          }
        >
          {buttonCopy}
        </Button>
      </div>
    </div>
  );
}

const renderAvatar = (avatar, completeness, adminUser) => {
  return (
    <div className={styles.avatarContainer}>
      <CardAvatarContainer
        animated={true}
        completeness={completeness}
        avatar={avatar}
        thickness={Thickness.Medium}
        incompleteFields={[]}
        adminUser={adminUser}
      />
    </div>
  );
};
