import * as React from 'react';
import { shallow } from 'enzyme';
import DashboardJobList from './DashboardJobList';

const job = require('__fixtures__/job_dummy');

const props = {
  profileCompleted: true,
  applications: [],
  bookmarks: [],
  jobResults: [job],
  suggestedJobs: [job],
  bonusJobs: [job],
  subscriptionJobs: [],
  suggestionRelevantPath: '',
  suggestionNonRelevantPath: '',
  isSearching: true,
};

describe('JobSearch render', () => {
  it('shallow renders without crashing', () => {
    const component = shallow(<DashboardJobList {...props} />);

    expect(component.exists()).toBe(true);
  });

  it('renders all default components', () => {
    const component = shallow(<DashboardJobList {...props} />);

    expect(component.find('JobOffers')).toHaveLength(1);
    expect(component.find('SuggestedJobs')).toHaveLength(1);
    expect(component.find('SubscriptionsJobs')).toHaveLength(1);
  });
});
