import React from 'react';
import FormInput from 'components/general/form_input/form_input';
import { multiValueStyles } from 'lib/select_styles';
import { citizenships } from 'components/registration/registration_utils/registration_utils';
import Select from 'components/form/select/select';

export interface Props {
  citizenships: any;
  error: string;
  handleFormChange: any;
  options: any;
}

export default function CitizenshipInput(props: Props) {
  return (
    <FormInput
      name='citizenships'
      label='Please select your citizenship(s)?'
      error={props.error}
      required
    >
      <Select
        name='citizenships'
        placeholder='E.g. Portuguese'
        options={citizenships(props.options).slice(1)}
        color='silverSand'
        styles={multiValueStyles('silverSand')}
        isMulti={true}
        value={props.citizenships}
        onChange={event => props.handleFormChange(event, 'citizenships')}
      />
    </FormInput>
  );
}
