import * as React from 'react';
import AutomaticNotes from './automatic_notes';
import { shallow, ShallowWrapper } from 'enzyme';

let wrapped: ShallowWrapper;
const notes = { language: ['note', 'othernote'], location: ['note'] };

describe('Automatic Notes component', () => {
  beforeEach(() => {
    wrapped = shallow(<AutomaticNotes notes={notes} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});
