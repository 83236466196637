import * as React from 'react';
import classnames from 'classnames';

const iconBullhorn = require('iconic/bullhorn.svg');
const styles = require('./alert_box.module.scss');

interface Props {
  classes?: string;
  isMobile?: boolean;
  children: React.ReactNode;
}

class AlertBox extends React.Component<Props> {
  componentDidMount() {
    window.IconicJS().inject('.iconic');
  }

  renderMobileWarning = () => (
    <div className={classnames(styles.mobileAlertBox, this.props.classes)}>
      <div className={styles.mobileBanner}>
        <div className={styles.mobileCenteredBox}>
          <img
            src={iconBullhorn}
            className={classnames('iconic iconic-md iconic-bullhorn', styles.mobileIconic)}
          />
        </div>
      </div>

      <div className={styles.mobileBody}>{this.props.children}</div>
    </div>
  );

  renderDesktopWarning = () => (
    <div className={classnames(styles.alertBox, this.props.classes)}>
      <div className={styles.banner}>
        <div className={styles.centeredBox}>
          <img
            src={iconBullhorn}
            className={classnames('iconic iconic-md iconic-bullhorn', styles.iconic)}
          />
        </div>
      </div>

      <div className={styles.body}>{this.props.children}</div>
    </div>
  );

  render() {
    return this.props.isMobile ? this.renderMobileWarning() : this.renderDesktopWarning();
  }
}

export default AlertBox;
