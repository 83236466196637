import React from 'react';
import classNames from 'classnames';

import Icon from 'components/general/icon/icon';
import Tooltip from 'components/general/tooltip/tooltip';

const styles = require('./ApplicationTag.module.scss');

interface Props {
  backgroundColor: string;
  iconName: string;
  image?: any;
  title?: string;
}

export default function ApplicationTag(props: Props) {
  const className = classNames(styles.tagContainer, styles[`container-${props.backgroundColor}`]);

  const title =
    props.title &&
    props.title.split('\n').map((item, key) => {
      return (
        <span key={key}>
          {item}
          <br />
        </span>
      );
    });

  const renderIcon = () => {
    if (props.image) {
      return props.image;
    } else {
      return <Icon name={props.iconName} color='white' />;
    }
  };

  return (
    <div className={className}>
      {props.title ? <TagWithTooltip title={title}>{renderIcon()}</TagWithTooltip> : renderIcon()}
    </div>
  );
}

const TagWithTooltip = ({ children, title }) => (
  <Tooltip text={title} color='silverSandMidDark'>
    {children}
  </Tooltip>
);
