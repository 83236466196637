import * as React from 'react';
import MobileFilters from './mobile_filters';
import { shallow } from 'enzyme';
import Selectize from '../general/selectize/selectize';
import Button from 'lj_shared/button/button';
import LocationInput from 'components/form/location_input/location_input';

let wrapped;

const createTestProps = () => {
  return {
    items: [{ id: 1, title: 'Job Opening 1', value: 1, group: 'ongoing' }],
    itemRender: jest.fn(),
    optGroups: [{ groupValue: 1, groupLabel: 'ongoing' }],
    onChange: jest.fn(),
    selected: [1],
    handleLocationChange: jest.fn(),
    googleMapsApiKey: '',
    clearJobFilter: jest.fn(),
    clearLocationFilter: jest.fn(),
  };
};

describe('Mobile Filters component', () => {
  beforeEach(() => {
    const props = createTestProps();
    wrapped = shallow(<MobileFilters {...props} />);
    wrapped.setState({ open: true });
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('has one selectize component', () => {
    expect(wrapped.find(Selectize).length).toEqual(1);
  });

  it('has one LocationInput component', () => {
    expect(wrapped.find(LocationInput).length).toEqual(1);
  });

  it('has one Button component', () => {
    wrapped.setState({ open: false });
    expect(wrapped.find(Button).length).toEqual(1);
  });
});
