// Libraries
import 'lib/globals';
import * as React from 'react';
import Select from 'react-select';
import { postJSON, handleRequestError, patchJSON, deleteJSON } from 'lib/request_deprecated';
import { ripePlumTheme } from 'lib/select_styles';
import classNames from 'classnames';

// Components
import Button from 'lj_shared/button/button';
import InlineIcon from 'components/general/inline_icon/inline_icon';
import Modal from 'components/general/modal/modal';
import TextInput from 'components/form/text/text';
import Accordion from 'components/general/accordion/accordion';
import TechnicalTestInformation from 'components/post_a_job/test_notice/technical_test_information';
import TestCardsList from './test_cards_list';
import Icon, { Size } from 'components/general/icon/icon';

// Models
import { Step, TechnicalTest, AssessmentsList } from './models';

// Icons & Style
const iconTrash = require('iconic/trash.svg');
const styles = require('./technical_test_modal.module.scss');

// Props
interface Props {
  step: Step;
  test: TechnicalTest;
  close: any;
  testIds?: any[];
  readOnly?: boolean;
  isRevision?: boolean;
  exists?: boolean;
  urls: {
    create: string;
    destroy: string;
    update: string;
  };
  standardTests: AssessmentsList;
}

// State
interface State {
  errors: Object;
  test: TechnicalTest;
  open: boolean;
}

// TechnicalTestModal
export default class TechnicalTestModal extends React.Component<Props, State> {
  modalRef: any;
  cancel: any;
  save: any;
  destroy: any;

  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      test: this.props.test ? this.props.test : { id: null, description: '' },
      open: false,
    };

    this.handleTestIdSelect = this.handleTestIdSelect.bind(this);

    this.validateForm = this.validateForm.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.cancel = this.handleClose.bind(this, 'cancel');
    this.save = this.handleClose.bind(this, 'save');
    this.destroy = this.handleClose.bind(this, 'destroy');

    this.modalRef = React.createRef();
  }

  isNew() {
    return !this.props.exists && !this.state.test.id;
  }

  disableButton() {
    return !this.state.test.test_id && this.state.test.description === '';
  }

  open() {
    this.modalRef.current.open();
  }

  close() {
    this.modalRef.current.close();
  }

  handleClose(action) {
    let test = this.state.test;
    let errors;

    if (!this.props.readOnly && action === 'save') {
      const body = { step_id: this.props.step.id, test };
      errors = this.validateForm(test);

      // Set the new state
      this.setState(() => {
        return { test, errors };
      });

      if (!errors) {
        // If validations pass
        if (test.id) {
          // If update
          patchJSON(this.props.urls.update.replace('~id', test.id), body)
            .then(() => this.props.close(test))
            .catch(handleRequestError);
        } else {
          // If Create
          if (this.props.testIds && !this.props.isRevision) {
            // If in Backoffice
            postJSON(this.props.urls.create, body)
              .then(response => {
                test.id = response.new_id;
                this.props.close(test);
              })
              .catch(handleRequestError);
          } else {
            // If not
            this.props.close(test);
          }
        }
      }
    } else if (!this.props.readOnly && action === 'destroy') {
      // If we want to destroy the test
      const originalID = test.id;
      test = { id: null, description: '' };
      errors = null;

      this.setState(() => {
        return { test, errors };
      });

      if (this.props.testIds && originalID) {
        // If in the backoffice you can edit directly
        deleteJSON(this.props.urls.update.replace('~id', originalID))
          .then(_e => this.props.close(null))
          .catch(handleRequestError);
      } else {
        this.props.close(null);
      }
    } else {
      // Return the original test
      test = this.props.test;
      this.props.close(test);
    }
  }

  validateForm(test) {
    const errors = {};

    if (!test.test_id && !test.description) {
      errors['description'] = 'You must tell us a bit more about the test';
    }
    if (test.description && test.description.length > 5000) {
      errors['description'] = 'Must be under 5000 characters';
    }

    if (Object.keys(errors).length > 0) {
      return errors;
    }
  }

  render() {
    return (
      <>
        <Modal className={styles.modal} ref={this.modalRef} title={'Technical Test'}>
          {this.renderKnowMore()}
          <div onClick={() => this.setState({ open: false })}>
            <TestCardsList
              standardTests={this.props.standardTests.data}
              handleTestIdSelect={this.handleTestIdSelect}
              selectedTest={this.state.test.test_id}
            />
          </div>
          {this.renderModalForm()}
          {this.renderButtons()}
        </Modal>
      </>
    );
  }

  renderKnowMore() {
    return (
      <Accordion title='Know more' className='modal technicalTestInformation'>
        <TechnicalTestInformation isModal={false} />
      </Accordion>
    );
  }

  createTests() {
    if (this.props.testIds) {
      return this.props.testIds.map((value, _index) => {
        return { value: value[1], label: value[0] };
      });
    }
  }

  renderModalForm() {
    const errors = this.state.errors;
    const testIds = this.createTests();

    return (
      <form>
        {this.state.open ? (
          <div className={styles.accordionContainerPink}>
            <div
              className={classNames(styles.accordionTitle, styles.accordionOpen)}
              onClick={() => this.setState({ open: false })}
            >
              Request a new test
              <Icon
                name='minus'
                size={Size.Big}
                color='black'
                className={styles.accordionIconMinus}
              />
            </div>
            <TextInput
              color='ripePlum'
              name='description'
              textarea={true}
              required={true}
              error={errors ? errors['description'] : null}
              placeholder={
                'Requirements regarding technologies to be tested, seniority of the candidate, duration of the test…'
              }
              onChange={this.handleTextChange}
              value={this.state.test.description || ''}
              label='Tell us more about your requirements for the test:'
            />
          </div>
        ) : (
          this.renderModalFormClosed()
        )}
        {testIds && (
          <>
            <br />
            <Select
              defaultValue={testIds.filter(option => option.value === this.state.test.test_id)}
              onChange={this.handleTestIdSelect}
              theme={ripePlumTheme}
              options={testIds}
            />
          </>
        )}
      </form>
    );
  }

  renderModalFormClosed() {
    return (
      <div className={styles.accordionContainer} onClick={() => this.handleRequestNewTestClick()}>
        <div className={styles.accordionTitle}>
          Request a new test
          <Icon name='plus' size={Size.Big} color='black' className={styles.accordionIconPlus} />
        </div>
      </div>
    );
  }

  renderButtons() {
    if (this.props.readOnly) {
      return (
        <div className={styles.buttons}>
          <div />
          <div className={styles.buttonsRight}>
            <Button otherClasses={styles.button} buttonColor='ripePlum' onClick={this.cancel}>
              OK
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.buttons}>
          <div className={styles.buttonsLeft}>
            {!this.isNew() && (
              <Button
                buttonColor='silverSand'
                buttonType='border'
                onClick={this.destroy}
                otherClasses={styles.button}
              >
                <InlineIcon className={styles.iconTrash} path={iconTrash} />
                Delete
              </Button>
            )}
          </div>
          <div className={styles.buttonsRight}>
            <Button
              otherClasses={styles.button}
              buttonColor={this.disableButton() ? 'silverSand' : 'ripePlum'}
              onClick={this.disableButton() ? null : this.save}
            >
              {this.isNew() ? 'Add technical test' : 'Save'}
            </Button>
            <Button
              otherClasses={styles.button}
              buttonColor='ripePlum'
              buttonType='border'
              onClick={this.cancel}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    }
  }

  handleTestIdSelect(selected) {
    if (selected && this.state.test.test_id !== selected.value) {
      this.setState(prevState => {
        return {
          test: {
            ...prevState.test,
            test_id: selected.value,
          },
        };
      });
    }
  }

  handleTextChange(event) {
    const value = event.target.value;

    if (this.state.test.description !== value) {
      this.setState(prevState => {
        const test = prevState.test;
        test.description = value;

        return { test };
      });
    }
  }

  handleRequestNewTestClick() {
    this.setState({ open: true });
    this.setState(prevState => {
      return {
        test: {
          ...prevState.test,
          test_id: null,
        },
      };
    });
  }
}
