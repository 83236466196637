import * as React from 'react';
import ApplicationContacts, { Props } from './application_contacts';
import { shallow, ShallowWrapper } from 'enzyme';
import applicationPersonDummy from '__fixtures__/application_person';

const createTestProps = moreProps => {
  return {
    person: applicationPersonDummy,
    profileUrl: '',
    waitingForReview: '',
    ...moreProps,
  };
};

let props: Props;
let wrapped: ShallowWrapper;

describe('Application Contacts component', () => {
  beforeEach(() => {
    props = createTestProps({});
    wrapped = shallow(<ApplicationContacts {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
