import React from 'react';
import MatchScoreStatus from './match_score_status';
import { render } from '@testing-library/react';

window.Routes = { calculation_status_backoffice_match_scores: jest.fn() };

describe('MatchScoreStatus', () => {
  it('renders the entries', () => {
    const entry = { email: 'foo@mail.com', jid: '12345', status: 'pending' };
    const { getByText } = render(<MatchScoreStatus entries={[entry]} />);
    expect(getByText(entry.email, { exact: false })).toBeTruthy();
  });
});
