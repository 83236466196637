function init() {
  const pages = [
    '.job_ads-form',
    '.employer-post',
    '.backoffice.job_ads',
    '.backoffice.job_revisions',
  ];

  if ($('body').is(pages.join(', '))) {
    const element = $('.js-job-contract-type-input');

    if (element.length > 0) {
      element.on('change', refreshContractType);
      refreshContractType();
    }
  }
}

function refreshContractType() {
  const selected = $('.js-job-contract-type-input:checked');
  const value = selected.val();

  selected.parent('.js-job-contract-type').addClass('--active');

  $('.js-job-contract-type-input:not(:checked)')
    .parent('.js-job-contract-type')
    .removeClass('--active');

  const showSalarySection = value === 'permanent' || value === 'both';
  const showRateSection = value === 'contractor' || value === 'both';

  $('.js-job-salary-section').toggle(showSalarySection);
  $('.js-job-rate-section').toggle(showRateSection);
}

document.addEventListener('turbolinks:load', init);
