import Icon, { Size } from 'components/general/icon/icon';
import React from 'react';

interface Props {
  containerClass: string;
  iconColor: string;
  content: any;
  id: string;
  size?: Size;
}

export default function QuestionMarkTooltip(props: Props) {
  return (
    <div className={props.containerClass}>
      <div className='ld-tooltip-item ld-arrow-down'>
        <Icon name='helpCircle' color={props.iconColor} size={props.size || Size.MediumLarge} />
        <div id={props.id} className='ld-tooltip'>
          {props.content}
        </div>
      </div>
    </div>
  );
}
