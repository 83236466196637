import * as React from 'react';
import { postJSON, handleRequestError } from '../../../lib/request_deprecated';

import JobSkillsInput from './job_skills_input';
import ProposeTag from '../propose_tag/propose_tag';
import UnicornWarning from '../../unicornWarning/UnicornWarning';
import classNames from 'classnames';

const styles = require('./job_skills_section.module.scss');

export enum SkillCategory {
  MustHave = 0,
  OtherRequired = 1,
  NiceToHave = 2,
}

interface Props {
  categoryId: SkillCategory;
  errors: any;
  formName: string;
  jobId?: number;
  mandatory: boolean;
  matchfindingIndex?: number;
  maximumNumberOfSkills: number;
  proposedTags: any; // an array of objects like {id: 10, tag: "Python on Rails"}
  proposeTagDisabled: boolean;
  skills: any; // an array of objects like {id: 1, tag: "Rails", experienceLevel: 1 }
  updateSkills: Function;
  isBackoffice?: boolean;
}

interface State {
  errors: any;
  proposedTags: any; // an array of objects like {id: 10, tag: "Python on Rails"}
  skills: any; // an array of objects like {id: 1, tag: "Rails", experienceLevel: 1 }
  temporarySkills: number[];
  unicornLevel: number;
}

export default class JobSkillsSection extends React.Component<Props, State> {
  label: string;
  fieldName: string;
  fieldNote: string;
  proposeTagComponent: any;
  unicornWarning = false;

  constructor(props) {
    super(props);
    this.state = {
      ...props,
      unicornLevel: this.props.matchfindingIndex,
      temporarySkills: props.skills.map(({ id }) => id),
    };
    switch (props.categoryId) {
      case SkillCategory.MustHave: {
        this.label = 'Must have skills';
        this.fieldName = 'must_have_tag_ids';
        this.fieldNote = `The skills in Must Have are skills that are mandatory. If a
         candidate doesn’t have one of these skills, it is very likely that he will be
          rejected.`;
        this.unicornWarning = true;
        break;
      }
      case SkillCategory.OtherRequired: {
        this.label = 'Other required skills';
        this.fieldName = 'other_required_tag_ids';
        this.fieldNote = `The Other Required are all the other skills that are part of
         what’s needed to perform the job, but that you don’t want to automatically reject
          candidates who don’t have one of them.`;
        break;
      }
      case SkillCategory.NiceToHave: {
        this.label = 'Nice to have skills';
        this.fieldName = 'nice_to_have_tag_ids';
        this.fieldNote = `The Nice to Have are optional skills. They make the difference
         between a 4 and a 5 in the Skills dimension.`;
        break;
      }
      default: {
        this.label = 'ERROR';
        this.fieldName = 'ERROR';
        this.fieldNote = 'ERROR';
        break;
      }
    }
    this.handleCreateProposedTag = this.handleCreateProposedTag.bind(this);
  }

  handleCreateProposedTag(proposedTagName) {
    this.proposeTagComponent.openProposeTagForm(proposedTagName);
  }

  updateSkillsForUnicorn = (skill, isDeletion = false) => {
    if (Array.isArray(skill)) {
      return;
    }
    const currentSkills = this.state.temporarySkills;
    let payload = currentSkills;
    if (isDeletion) {
      payload = payload.filter(id => id !== skill);
    } else {
      payload = [...payload, parseInt(skill, 10)];
    }
    this.setState(_ => ({
      temporarySkills: payload,
    }));

    if (!this.props.isBackoffice) {
      return postJSON(window.Routes.users_with_this_skillset_employer_job_ad(this.props.jobId), {
        skills: payload,
      })
        .then(({ unicorn_level }) => {
          this.setState(_ => ({
            unicornLevel: unicorn_level,
          }));
        })
        .catch(handleRequestError);
    }
  };

  renderUnicornWarning = () =>
    this.state.temporarySkills.length !== 0 &&
    [0, 1, 2, 3, 4].includes(this.state.unicornLevel) && (
      <div className={styles.unicornSection}>
        <UnicornWarning value={this.state.unicornLevel} />
      </div>
    );

  render() {
    const propsProposeTag = {
      jobId: this.props.jobId,
      categoryId: this.props.categoryId,
      formName: this.props.formName,
    };

    const propsJobSkillsInput = {
      ...this.props,
      disableSort: true,
      fieldName: this.fieldName,
      readOnly: false,
      renderSignupVersion: true,
      updateSkills: this.updateSkillsForUnicorn,
    };

    const showFooterInstruction = this.state.skills && this.state.skills.length > 0;

    return (
      <>
        <div className={styles.title}>
          <label className={styles.label}>{this.label}</label>{' '}
          {this.props.mandatory && <span className={styles.mandatory}>*</span>}
        </div>
        <p className='trix-skills-label'>{this.fieldNote}</p>
        <p className={styles.instructions}>
          Please add up to {this.props.maximumNumberOfSkills} skills.
          {!this.props.proposeTagDisabled && (
            <ProposeTag {...propsProposeTag} ref={ref => (this.proposeTagComponent = ref)} />
          )}
        </p>
        <JobSkillsInput
          {...propsJobSkillsInput}
          createProposedTagCallback={
            this.props.proposeTagDisabled ? null : this.handleCreateProposedTag
          }
          color={this.props.isBackoffice ? 'tuftsBlue' : 'ripePlum'}
        />
        {showFooterInstruction && (
          <p className={classNames('small-instructions', styles.smallInstructions)}>
            Numbers tell you how many users we have with that skill.
          </p>
        )}
        {this.unicornWarning && this.renderUnicornWarning()}
      </>
    );
  }
}
