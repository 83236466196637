import React from 'react';
import { postJSON, deleteJSON, handleRequestError } from 'lib/request_deprecated';
import { camelizeObj } from 'lib/object';
import { cloneDeep } from 'lodash';
import { Person } from '__models__/models';
import AreaInterestsInput from './AreaInterestsInput';
import Button from 'lj_shared/button/button';
import CitizenshipInput from './CitizenshipInput';
import CityInput from './CityInput';
import CvInput from './CvInput';
import ExperienceYearsInput from './ExperienceYearsInput';
import Icon, { Size } from 'components/general/icon/icon';
import LinkedInInput from './LinkedInInput';
import NameInput from './NameInput';
import ProfileForm from '../registration/profile_form';
import ShareInput from './ShareInput';
import SkillsInput from './SkillsInput';
import StatusInput from './StatusInput';
import SubscribeInput from './SubscribeInput';
import AutofillSection from './AutofillSection';
import RemotePolicyPreferences from 'components/talentUser/jobInterestsModal/RemotePolicyPreferences';
import JobTypeInterests from 'components/talentUser/jobInterestsModal/JobTypeInterests';

const logo = require('images/lj_logo.png');
const styles = require('./RegistrationPage.module.scss');

export interface Props {
  redirect?: string;
  profile: {
    categories: any;
    citizenships: string[];
    job_types: any;
    showLinkedin: boolean;
    maximumNumberOfSkills: number;
    person: { data: Person };
    proposedTags: { id: number; tag: string }[];
    share_preferences: any;
    skills: any;
    start_years: any;
    urls: {
      person_institutions_url: string;
      person_institutions_destroy_url: string;
      edit_profile_url: string;
      websites_url: string;
      websites_destroy_url: string;
      languages_url: string;
      languages_destroy_url: string;
      cv_url: string;
    };
  };
}

interface State {
  hadName: boolean;
  profileForm: ProfileForm;
  revision: boolean;
}

class RegistrationPage extends React.Component<Props, State> {
  private dashboardLink: any;

  constructor(props: Props) {
    super(props);

    this.dashboardLink = React.createRef();

    const skills = props.profile.skills.data.map(({ id, attributes }) => ({
      id,
      tag: attributes.name,
      experienceLevel: attributes.experience_level,
    }));
    const attrs = this.props.profile.person.data.attributes;

    this.state = {
      hadName: !!attrs.first_name && !!attrs.last_name,
      revision: false,
      profileForm: new ProfileForm({
        firstName: attrs.first_name || '',
        lastName: attrs.last_name || '',
        birthYear: attrs.birth_year || '',
        phoneNumber: attrs.phone || '',
        city: attrs.city || '',
        country: attrs.country_code || '',
        countryName: attrs.country_name || '',
        cvs: attrs.cvs?.map(cv => camelizeObj(cv)) || [],
        location: attrs.location || '',
        googlePlaceId: '',
        citizenships: attrs.citizenships
          ? attrs.citizenships.split(', ').map(c => (c ? { value: c, label: c } : ''))
          : [],
        categories: attrs.categories || [],
        commsConsent: false,
        headline: attrs.headline || '',
        range: [1, 99],
        minimumRate: attrs.minimum_rate,
        maximumRate: attrs.maximum_rate,
        minimumGAS: attrs.minimum_gas,
        maximumGAS: attrs.maximum_gas,
        remote: 1,
        description: attrs.description || '',
        url: this.props.profile.urls.edit_profile_url,
        availability: attrs.availability,
        experienceYears: attrs.experience_years || '',
        shareProfile: 'open',
        startYear: attrs.start_year || '',
        cvFileName: attrs.cv_file_name || '',
        personLanguages: attrs.languages || [],
        jobTypesInterest: attrs.jobTypesInterest || [],
        skills,
        listOfLinks: attrs.websites.map(link => ({
          url: link.full_url,
          category: link.title,
          id: link.id,
        })),
      }),
    };
  }

  handleFormChange = (event, input = '') => {
    const update = {};
    if (!input) {
      update[event.target.name] = event.target.value;
    } else if (input === 'location') {
      const addressArray = event.formatted_address.split(', ');
      const city = addressArray[0];
      const countryShort = event.address_components.find(
        addressComponent => addressComponent.types.indexOf('country') !== -1
      ).short_name;
      const countryLong = event.address_components.find(
        addressComponent => addressComponent.types.indexOf('country') !== -1
      ).long_name;
      update['city'] = city;
      update['country'] = countryShort;
      update['countryName'] = countryLong;
      update['location'] = event.formatted_address;
      update['googlePlaceId'] = event.id;
      update['latitude'] = event.geometry?.location.lat();
      update['longitude'] = event.geometry?.location.lng();
    } else if (input === 'linkedinLocation') {
      update['city'] = event.city;
      update['country'] = event.country_code;
      update['countryName'] = event.country;
      update['location'] = `${event.city}, ${event.country}`;
      update['googlePlaceId'] = event.google_place_id;
      update['latitude'] = event.latitude;
      update['longitude'] = event.longitude;
    } else if (input === 'rateRange') {
      update['maximumRate'] = event.maximum || null;
      update['minimumRate'] = event.minimum;
    } else if (input === 'gasRange') {
      update['maximumGAS'] = event.maximum || null;
      update['minimumGAS'] = event.minimum;
    } else if (input === 'commsConsent') {
      update[input] = event.target.checked;
    } else {
      update[input] = event;
    }

    const form = cloneDeep(this.state.profileForm);
    form.update(update);
    this.setState({ profileForm: form });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.setState({
      revision: false,
    });
    const profileForm = this.state.profileForm;
    const forceNameValidation = !this.state.hadName;

    if (profileForm.validate('registrationPage', forceNameValidation)) {
      profileForm
        .save('lj')
        .then(saved => {
          if (saved && this.props.redirect) {
            window.location.href = this.props.redirect;
          } else if (saved) {
            window.location = window.Routes.dashboard();
          }
        })
        .catch(e => handleRequestError(e));
    } else {
      this.setState({
        revision: true,
      });
      window.Alerts.alert('Please make sure all required fields are complete');
    }
  };

  updateSkills = skills => {
    const form = cloneDeep(this.state.profileForm);
    form.update({ skills });

    this.setState({ profileForm: form });
  };

  handleChangeCV = (object, action) => {
    const profileForm = cloneDeep(this.state.profileForm);
    const cv = camelizeObj(object);
    let cvs: any[];

    if (action === 'post') {
      cvs = [cv];
    } else if (action === 'delete') {
      cvs = profileForm.cvs.filter(formCv => +formCv.id !== +cv.id);
    }

    profileForm.update({ cvs });

    this.setState({ profileForm });
  };

  handleAddWebsiteClick = website => {
    if (!website.url) {
      return;
    }

    postJSON(this.props.profile.urls.websites_url, {
      category: website.category,
      url: website.url,
      replace: false,
    })
      .then(response => {
        if (response.notice) {
          window.Alerts.notice(response.notice);

          const links = {
            listOfLinks: [response.website, ...this.state.profileForm.listOfLinks],
          };
          const form = cloneDeep(this.state.profileForm);
          form.update(links);

          this.setState({ profileForm: form });
        } else if (response.alert) {
          const form: ProfileForm = cloneDeep(this.state.profileForm);
          form.errors = { ...form.errors, listOfLinks: response.error };
          this.setState({ profileForm: form });
        }
      })
      .catch(e => handleRequestError(e));
  };

  handleDeleteWebsiteClick = id => {
    return new Promise(resolve => {
      deleteJSON(this.props.profile.urls.websites_destroy_url.replace('~id', id))
        .then(response => {
          if (response.notice) {
            const links = {
              listOfLinks: this.state.profileForm.listOfLinks.filter(link => link.id !== id),
            };
            const form = cloneDeep(this.state.profileForm);
            form.update(links);

            this.setState({ profileForm: form });
            resolve(true);
          }
        })
        .catch(e => handleRequestError);
    });
  };

  renderInputs = () => {
    return (
      <>
        <ol>
          {!this.state.hadName && (
            <>
              <li className={styles.inputItem}>
                <NameInput
                  error={this.state.profileForm.errors.firstName}
                  handleFormChange={this.handleFormChange}
                  kind='first'
                  value={this.state.profileForm.firstName}
                />
              </li>
              <li className={styles.inputItem}>
                <NameInput
                  error={this.state.profileForm.errors.lastName}
                  handleFormChange={this.handleFormChange}
                  kind='last'
                  value={this.state.profileForm.lastName}
                />
              </li>
            </>
          )}
          <li className={styles.inputItem}>
            <StatusInput
              error={this.state.profileForm.errors.availability}
              availability={this.state.profileForm.availability}
              handleFormChange={this.handleFormChange}
            />
          </li>
          <li className={styles.inputItem}>
            <CityInput
              error={this.state.profileForm.errors.location}
              handleFormChange={this.handleFormChange}
              city={this.state.profileForm.city}
              country={this.state.profileForm.country}
              countryName={this.state.profileForm.countryName}
            />
          </li>
          <li className={styles.inputItem}>
            <CitizenshipInput
              error={this.state.profileForm.errors.citizenships}
              options={this.props.profile.citizenships}
              citizenships={this.state.profileForm.citizenships}
              handleFormChange={this.handleFormChange}
            />
          </li>
          <li className={styles.inputItem}>
            <RemotePolicyPreferences
              error={this.state.profileForm.errors.jobTypesInterest?.remotePolicy}
              label='Which remote policies would you prefer?'
              jobTypesInterest={this.state.profileForm.jobTypesInterest}
              jobTypesList={this.props.profile.job_types}
              handleFormChange={this.handleFormChange}
            />
          </li>
          <li className={styles.inputItem}>
            <JobTypeInterests
              error={this.state.profileForm.errors.jobTypesInterest?.jobType}
              label='What type of jobs are you interested in?'
              jobTypesInterest={this.state.profileForm.jobTypesInterest}
              jobTypesList={this.props.profile.job_types}
              handleFormChange={this.handleFormChange}
              showRateAndGas={false}
              rate={{
                minimum: this.state.profileForm.minimumRate,
                maximum: this.state.profileForm.maximumRate,
              }}
              gas={{
                minimum: this.state.profileForm.minimumGAS,
                maximum: this.state.profileForm.maximumGAS,
              }}
              showRemotePreferences={false}
            />
          </li>
          <li className={styles.inputItem}>
            <AreaInterestsInput
              error={this.state.profileForm.errors.categories}
              options={this.props.profile.categories}
              categories={this.state.profileForm.categories}
              handleFormChange={this.handleFormChange}
              optionLimit={3}
            />
          </li>
          <li className={styles.inputItem}>
            <ExperienceYearsInput
              error={this.state.profileForm.errors.startYear}
              startYearsList={this.props.profile.start_years}
              startYear={this.state.profileForm.startYear}
              handleFormChange={this.handleFormChange}
            />
          </li>
          <li className={styles.inputItem}>
            <SkillsInput
              error={this.state.profileForm.errors.skills}
              skills={this.state.profileForm.skills}
              proposedTags={this.props.profile.proposedTags}
              maximumNumberOfSkills={this.props.profile.maximumNumberOfSkills}
              updateSkills={this.updateSkills}
            />
          </li>
          <li className={styles.inputItem}>
            <ShareInput
              error={this.state.profileForm.errors.shareProfile}
              shareProfile={this.state.profileForm.shareProfile}
              handleFormChange={this.handleFormChange}
              options={this.props.profile.share_preferences}
            />
          </li>
          <li className={styles.inputItem}>
            <CvInput
              error={this.state.profileForm.errors.cvFileName}
              cvs={this.state.profileForm.cvs}
              handleChangeCV={this.handleChangeCV}
            />
          </li>
          <li className={styles.inputItem}>
            <LinkedInInput
              error={this.state.profileForm.errors.listOfLinks}
              handleAddWebsiteClick={this.handleAddWebsiteClick}
              handleDeleteWebsiteClick={this.handleDeleteWebsiteClick}
              website={this.state.profileForm.listOfLinks.find(
                link => link.category.toLowerCase() === 'linkedin'
              )}
            />
          </li>
        </ol>
        <SubscribeInput
          consent={this.state.profileForm.commsConsent}
          error={this.state.profileForm.errors.commsConsent}
          handleFormChange={this.handleFormChange}
        />
      </>
    );
  };

  render() {
    return (
      <main className={styles.signUpMain}>
        <section className={styles.signUp}>
          <a className={styles.cross} href={window.Routes.account()}>
            <Icon name='close' size={Size.MediumBig} />
          </a>
          <div className={styles.header}>
            <img src={logo} alt='Landing.jobs' />
          </div>
          <div className={styles.greeting}>
            We just need a few more details, {this.props.profile.person.data.attributes.first_name}!
          </div>
          <AutofillSection
            showLinkedin={this.props.profile.showLinkedin}
            handleFormChange={this.handleFormChange}
            handleAddWebsiteClick={this.handleAddWebsiteClick}
            handleDeleteWebsiteClick={this.handleDeleteWebsiteClick}
            profileForm={this.state.profileForm}
            updateSkills={this.updateSkills}
          />
          <form>
            {this.renderInputs()}
            <div className={styles.buttons}>
              <Button
                otherId='registration-button'
                buttonColor='tuftsBlue'
                otherClasses={styles.submitButton}
                onClick={event => this.handleSubmit(event)}
              >
                Let&apos;s Start This
              </Button>
            </div>
          </form>
          <br />
        </section>
        <div className={styles.background} />
      </main>
    );
  }
}

export default RegistrationPage;
