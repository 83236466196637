import React, { useState, useContext } from 'react';
import { FormContext } from 'components/form/FormProvider';
import Select from 'components/form/select/select';
import Textarea from 'components/form/textarea/textarea';
import Button from 'lj_shared/button/button';
import { loadYears, historyTextPlaceholder } from './utils/AboutSectionUtils';
import SectionTitle from './SectionTitle';
import { handleRequestError, postJSON, deleteJSON } from 'lib/request_deprecated';
import ErrorLabel from 'components/tags_page/error_label';
import SectionCard from './SectionCard';

const styles = require('./AboutSectionHistory.module.scss');

export default function AboutSectionHistory() {
  const context = useContext(FormContext);
  const [historyList, setHistoryList] = useState(context.resource.milestones);
  const [historyError, setHistoryError] = useState(false);
  const [yearError, setYearError] = useState(false);
  let select;
  let text;

  const companyId = () => context.resource.id;

  const deleteHistoryItem = historyId => {
    const shouldRemove = window.confirm('Do you want to remove?');
    if (!shouldRemove) {
      return;
    }
    deleteJSON(window.Routes.company_milestone(historyId), {
      company_id: companyId(),
    })
      .then(({ data }) => setHistoryList(data.map(item => item.attributes)))
      .catch(e => handleRequestError(e));
  };

  const hasValidPayload = () => !(hasEmptyYear() || hasEmptyHistory());

  const hasEmptyYear = () => select.selectRef.current.state.value === null;

  const hasEmptyHistory = () => text.notesRef.current.value.length === 0;

  const payload = () => ({
    year: select.selectRef.current.state.value.label,
    description: text.notesRef.current.value,
    company_id: companyId(),
  });

  const notifyAndClear = () => {
    formErrors();
    select.selectRef.current.select.clearValue();
    text.notesRef.current.value = '';
    window.Alerts.notice('Added history!');
  };

  const formErrors = () => {
    setYearError(hasEmptyYear());
    setHistoryError(hasEmptyHistory());
  };

  const validateAndSubmit = event => {
    event.preventDefault();
    if (hasValidPayload()) {
      postJSON(window.Routes.company_milestones(), payload())
        .then(({ data }) => {
          setHistoryList(data.map(item => item.attributes));
        })
        .catch(handleRequestError);
      notifyAndClear();
    } else {
      formErrors();
    }
  };

  const renderYear = () => (
    <div className={styles.subSection}>
      <label className={styles.fieldLabel}>Year</label>
      <Select
        name='year'
        options={loadYears()}
        ref={ref => (select = ref)}
        className={styles.halfWidth}
        placeholder='e.g. 2011'
      />
      {yearError && <ErrorLabel message={'Please tell us when it happened'} />}
    </div>
  );

  const renderHistory = () => (
    <div className={styles.subSection}>
      <label className={styles.fieldLabel}>Resume</label>
      <Textarea name='history' ref={ref => (text = ref)} placeholder={historyTextPlaceholder()} />
      {historyError && <ErrorLabel message={'Please add a description to your history'} />}
    </div>
  );

  const renderButtons = () => (
    <div className={styles.fullInlineWidth}>
      <div className={styles.halfWidth} />
      <div className={styles.halfWidth}>
        <Button
          isButton={true}
          buttonColor='silverSand'
          onClick={validateAndSubmit}
          otherClasses={'u-float--right'}
        >
          Add
        </Button>
      </div>
    </div>
  );

  const renderHistoryList = () => (
    <div className={styles.sectionList}>
      {historyList.map(({ id, year, description }) => (
        <SectionCard
          key={id}
          header={year}
          text={description}
          handleDelete={() => deleteHistoryItem(id)}
        />
      ))}
    </div>
  );

  return (
    <div className={styles.section}>
      <SectionTitle title='History' id='companyHistory' />
      {renderYear()}
      {renderHistory()}
      {renderButtons()}
      {renderHistoryList()}
    </div>
  );
}
