import React from 'react';

const styles = require('./application_body_footer.module.scss');

interface Props {
  submittedAt: string;
  updatedAt: string;
  children?: any;
  vertical?: boolean;
}

export default function ApplicationBodyFooter(props: Props) {
  return (
    <div className={props.vertical ? styles.vertical : styles.main}>
      <div className={styles.dates}>
        <div className={styles.date}>
          <span className={styles.subtitleLabel}>Applied on: </span>
          <span className={styles.subtitleDate}>{shortenMonths(props.submittedAt)}</span>
        </div>
        <div className={styles.date}>
          <span className={styles.subtitleLabel}>Last Changed:</span>
          <span className={styles.subtitleDate}>{shortenMonths(props.updatedAt)}</span>
        </div>
      </div>
      <div className={styles.children}>{props.children}</div>
    </div>
  );
}

const shortenMonths = (date: string) => {
  const [month, day] = date.split(' ');
  return `${month.substring(0, 3)} ${day}`;
};
