import CompletionBar from 'components/general/completion_bar/CompletionBar';
import * as React from 'react';
const styles = require('./SelfEvaluationItem.module.scss');

export const SelfEvaluationItem = (selfEvaluations, job) => {
  if (selfEvaluations.length > 0) {
    const skillsCategories = [
      { title: 'Must Have Skills', skills: job.must_have_skills },
      { title: 'Other Required Skills', skills: job.other_required_skills },
      { title: 'Nice to Have Skills', skills: job.nice_to_have_skills },
    ];
    return (
      <div className={styles.container}>
        {skillsCategories.map(category => {
          return renderSkillsSection(category.title, category.skills);
        })}
      </div>
    );
  }

  function renderSkillsSection(title, skills) {
    const skillNames = skills.map(skill => skill.name);
    const matchingSkills = selfEvaluations.filter(ev => skillNames.indexOf(ev.tag.name) >= 0);
    if (matchingSkills.length > 0) {
      return (
        <div className={styles.skillsSection} key={title}>
          <div className={styles.skillsSectionTitle}>{title}</div>{' '}
          {matchingSkills.map(evaluation => {
            return (
              <div key={evaluation.id}>
                <CompletionBar
                  title={evaluation.tag.name}
                  evaluations={[evaluation]}
                  average={false}
                />
              </div>
            );
          })}
        </div>
      );
    }
  }
};
