import React, { useRef, useState } from 'react';
import uniqueId from 'lodash/uniqueId';
import Modal from 'components/general/modal/modal';
import Button from 'lj_shared/button/button';
import TextInput from 'components/form/text/text';
import FormInput from 'components/general/form_input/form_input';
import {
  DndContext,
  useSensor,
  useSensors,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable';
import SortableItem from 'components/sortableItem/SortableItem';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

const styles = require('./ScreeningScriptModal.module.scss');

interface Props {
  screeningScript: string[];
  modalRef: React.RefObject<Modal>;
  color: string;
}

export default function ScreeningScriptModal(props: Props) {
  const { modalRef, color } = props;
  const questionsLimit = 30;

  const initialScreeningScriptObject = () => {
    const scriptObject = {};
    const scriptInput = document.getElementById('screening_script') as any;
    const scriptQuestions =
      (scriptInput?.value?.length > 0 && JSON.parse(scriptInput.value)) || props.screeningScript;

    scriptQuestions.forEach(question => (scriptObject[uniqueId().toString()] = question));

    return scriptObject;
  };

  const [screeningScript, setScreeningScript] = useState(initialScreeningScriptObject);
  const [items, setItems] = useState(Object.keys(screeningScript));
  const newQuestionRef = useRef(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = items.indexOf(active.id);
      const newIndex = items.indexOf(over.id);
      const newItems = arrayMove(items, oldIndex, newIndex);
      setItems(newItems);
    }
  }

  const handleAddQuestion = () => {
    const newQuestion = newQuestionRef.current.input.value;
    const questions = Object.values(screeningScript).map(question =>
      question.toString().toLowerCase()
    );

    let alert;
    if (questions.includes(newQuestion.toLowerCase())) {
      alert = 'This question already exists.';
    } else if (newQuestion.length === 0) {
      alert = 'Please write a question.';
    } else if (questions.length >= questionsLimit) {
      alert = `There's a limit of ${questionsLimit} questions per interview.`;
    }

    if (alert) {
      window.Alerts.alert(alert);
    } else {
      const newId = uniqueId().toString();
      const newItems = [...items, newId];
      const newScript = { ...screeningScript };
      newScript[newId] = newQuestion;

      newQuestionRef.current.input.value = '';
      setScreeningScript(newScript);
      setItems(newItems);
    }
  };

  const handleRemoveQuestion = ev => {
    const questionId = ev.currentTarget.id.replace('remove_', '');
    const newScript = { ...screeningScript };
    const newItems = [...items];
    delete newScript[questionId];
    const questionIndex = items.indexOf(questionId);
    newItems.splice(questionIndex, 1);

    setScreeningScript(newScript);
    setItems(newItems);
  };

  const handleSave = () => {
    if (items.length === 0) {
      window.Alerts.alert('This script is empty. Please add some questions.');
    } else {
      const ordereredQuestions = items.map(id => screeningScript[id]);
      const scriptInput = document.getElementById('screening_script') as any;
      scriptInput.value = JSON.stringify(ordereredQuestions);

      modalRef.current.close();
    }
  };

  return (
    <Modal
      title='Interview Script'
      defaultOpen={false}
      ref={modalRef}
      className={styles.modal}
      disableOutsideClose={true}
    >
      <div className={styles.description}>
        Our team gathered a set of questions suited for this interview. <br />
        You can choose which ones you want to keep by deleting questions or adding your own.
      </div>
      <br />
      <div>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis]}
        >
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            {items.map((id, i) => (
              <SortableItem
                key={id}
                id={id}
                order={i + 1}
                item={screeningScript[id]}
                handleRemoveItem={ev => handleRemoveQuestion(ev)}
                isOrdered={true}
                isRemovable={true}
                color={color}
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>
      <div className={styles.addQuestionContainer}>
        <FormInput label='Add question'>
          <TextInput
            ref={newQuestionRef}
            name='company'
            placeholder='Type your question..'
            color={color}
            class={styles.textInput}
          />
        </FormInput>
        <div className={styles.buttons}>
          <Button
            buttonColor={color}
            isRound={true}
            onClick={handleAddQuestion}
            otherClasses={styles.button}
          >
            Add
          </Button>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.buttons}>
          <Button buttonColor={color} onClick={handleSave} otherClasses={styles.button}>
            Approve Script
          </Button>
        </div>
      </div>
    </Modal>
  );
}
