import React from 'react';
const styles = require('./LjSpinner.module.scss');
const spinnerAnimation = require('./../../images/spinner.gif');

const LjSpinner = () => {
  return (
    <div className={styles.spinnerContainer}>
      <div className={styles.overlay}>
        <div className={styles.spinner}>
          <img alt='Landing.Jobs' src={spinnerAnimation} />
        </div>
      </div>
    </div>
  );
};

export default LjSpinner;
