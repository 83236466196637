import React from 'react';
import FormInput from 'components/general/form_input/form_input';
import RadioButtons from 'components/form/radio_buttons/radio_buttons';
import { sharePreferences } from 'components/registration/registration_utils/registration_utils';
const styles = require('./ShareInput.module.scss');

export interface Props {
  error: string;
  handleFormChange: any;
  options: any;
  shareProfile: number;
}

export default function ShareInput(props: Props) {
  return (
    <FormInput
      name='share_profile'
      label='Your profile sharing preferences:'
      error={props.error}
      required
    >
      <div className={styles.shareOptions}>
        <RadioButtons
          selectedValue={props.shareProfile}
          options={sharePreferences(props.options)}
          onChange={props.handleFormChange}
          name='shareProfile'
        />
      </div>
    </FormInput>
  );
}
