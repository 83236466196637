import * as React from 'react';
import LanguagesMenu from './languages_menu';
import { shallow, ShallowWrapper } from 'enzyme';

let wrapped: ShallowWrapper;

describe('Languages Menu component', () => {
  beforeEach(() => {
    wrapped = shallow(<LanguagesMenu />);
  });

  it('has English as the default language', () => {
    expect(wrapped.find('.languageFlag span').text()).toBe('English');
  });

  it('opens the language menu', () => {
    wrapped.find('.toggle-menu').simulate('click');
    expect(wrapped.find('.languagesMenu')).toHaveLength(1);
  });

  it('changes the language to German', () => {
    wrapped.find('.toggle-menu').simulate('click');
    wrapped.find('.languageOption.de').simulate('click');
    expect(wrapped.find('.languageFlag span').text()).toBe('Deutsch');
  });
});

test.todo('add more tests');
