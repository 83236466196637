import { HeaderType } from 'components/general/card/card_header';
import { getYearDifference } from 'components/registration/registration_utils/registration_utils';

export default function MainCardUtils(profileForm, profileCompleteness, args = {}) {
  const isSharing = args['handshakeProfile'] || args['publicProfilePage'];
  const defaultLifeStory = isSharing
    ? ''
    : 'Tell us a little more about yourself, your motivations, your interests and ' +
      'what makes you unique.';

  const headlineDefault = isSharing ? '' : 'Title';
  const personId = args['personId'];
  const personSlug = args['personSlug'];

  const cardHeader = () => ({
    header: `${profileForm.firstName} ${profileForm.lastName}`,
    headerKey: 'intro',
    type: HeaderType.Special,
    links: links(profileForm, isSharing, personId, personSlug),
    openModal: () => null,
    iconAction: 'edit',
    public: true,
    rate: profileForm.minimumRate,
    shareProfile: profileForm.shareProfile,
    ...args,
  });

  const bio = () => ({
    availability: profileForm.availability.label,
    cvs: profileForm.cvs,
    experience: profileForm.experienceYears.label,
    experienceValue: profileForm.experienceYears.value,
    yearsDifference: getYearDifference(
      profileForm.startYear.value,
      profileForm.experienceYears.label
    ),
    headline: profileForm.headline,
    headlineDefault,
    jobSearchAvailability: profileForm.availability.label,
    lifeStory: profileForm.description || defaultLifeStory,
    links: links(profileForm, isSharing, personId, personSlug),
    location: profileForm.location,
    personId: profileForm.personId,
    public: args['handshakeProfile'],
    skills: profileForm.skills,
    url: null,
    showYearsOfExperience: true,
  });

  const skills = () => ({
    skills: profileForm.skills,
    proposedTags: [],
    maximumNumberOfSkills: 40,
    errors: null,
    disableSort: true,
    createProposedTagCallback: null,
    readOnly: true,
    updateSkills: null,
    renderSignupVersion: false,
  });

  const avatar = () => ({
    avatar: profileForm.avatar,
    completeness: profileCompleteness,
  });

  return {
    cardHeaderProps: cardHeader(),
    bioProps: bio(),
    skillsProps: skills(),
    avatarProps: avatar(),
  };
}

function personSkills(skills) {
  return skills.map(skill => ({ ...skill, tag: skill.name }));
}

export const links = (profileForm, isSharing, personId, personSlug) => {
  const linksList = profileForm.listOfLinks
    .map(({ category, id, url }) => ({
      category_key: category,
      id,
      title: category,
      type: category,
      url,
    }))
    .sort((first, second) => first.type - second.type);
  if (profileForm.cvs.some(cv => cv.standard)) {
    linksList.push({
      title: 'CV',
      category_key: 'cv',
      type: 'cv',
      url: isSharing
        ? window.Routes.employer_sourcing_person_resume({
            person_id: personSlug,
          })
        : `${window.Routes.resume(0)}`,
    });
  }

  return linksList;
};
