import { apolloClient, gql } from 'lib/graphql';
import { useState } from 'react';

const LOAD_GQL = gql`
  query {
    backoffice {
      engagementPowers {
        id
        createdAt
        company {
          id
          name
        }
        level
      }

      managedCompanies {
        id
        name
      }
    }
  }
`;

const CREATE_GQL = gql`
  mutation ($companyId: ID!, $level: String!) {
    backoffice {
      createEngagementPower(companyId: $companyId, level: $level) {
        errors {
          field
          message
        }
        resource {
          id
          createdAt
          company {
            id
            name
          }
          level
        }
      }
    }
  }
`;

const DEACTIVATE_GQL = gql`
  mutation ($id: ID!) {
    backoffice {
      deactivateEngagementPowers(id: $id) {
        errors {
          field
          message
        }
      }
    }
  }
`;

const DEACTIVATE_ALL_GQL = gql`
  mutation {
    backoffice {
      deactivateEngagementPowers {
        errors {
          field
          message
        }
      }
    }
  }
`;

export function makeState() {
  const [initialized, setInitialized] = useState(false);
  const [engagementPowers, setEngagementPowers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [managedCompanies, setManagedCompanies] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(undefined);
  const [selectedLevel, setSelectedLevel] = useState(undefined);
  const [errors, setErrors] = useState({});

  async function loadPowers() {
    if (initialized) return;

    setLoading(true);
    const { data } = await apolloClient.query({ query: LOAD_GQL });
    const powers = data.backoffice.engagementPowers;
    const companies = data.backoffice.managedCompanies.filter(company =>
      powers.every(power => power.company.id !== company.id)
    );
    setInitialized(true);
    setEngagementPowers(powers);
    setManagedCompanies(companies);
    setLoading(false);
  }

  async function createPower() {
    if (!validate()) return;

    setLoading(true);
    const { data } = await apolloClient.mutate({
      mutation: CREATE_GQL,
      variables: {
        companyId: selectedCompanyId,
        level: selectedLevel,
      },
    });
    const power = data.backoffice.createEngagementPower.resource;
    setEngagementPowers(powers => [power].concat(powers));
    setManagedCompanies(companies => companies.filter(company => company.id !== selectedCompanyId));
    setLoading(false);
  }

  async function deactivatePower(power, event) {
    event.preventDefault();
    if (!window.confirm('Delete this engagement power?')) return;

    setLoading(true);
    const { data } = await apolloClient.mutate({
      mutation: DEACTIVATE_GQL,
      variables: { id: power.id },
    });
    setEngagementPowers(powers => powers.filter(power2 => power2.id !== power.id));
    setManagedCompanies(companies => [power.company].concat(companies));
    setLoading(false);
  }

  async function deactivatePowers() {
    if (!window.confirm('Delete all engagement powers?')) return;

    setLoading(true);
    const { data } = await apolloClient.mutate({
      mutation: DEACTIVATE_ALL_GQL,
    });
    setEngagementPowers([]);
    setManagedCompanies(companies =>
      engagementPowers.map(power => power.company).concat(companies)
    );
    setLoading(false);
  }

  function setSelectedCompany(company) {
    setSelectedCompanyId(company?.id);
  }

  function validate() {
    const errors2 = {};
    let hasError = false;

    if (!selectedCompanyId) {
      errors2['company'] = 'Please select a company';
      hasError = true;
    }

    if (!selectedLevel) {
      errors2['level'] = 'Please select a value';
      hasError = true;
    }

    setErrors(errors2);
    return !hasError;
  }

  return {
    createPower,
    deactivatePower,
    deactivatePowers,
    engagementPowers,
    errors,
    initialized,
    loading,
    loadPowers,
    managedCompanies,
    setSelectedCompany,
    setSelectedLevel: object => setSelectedLevel(object?.value),
  };
}
