import React, { useState, useRef } from 'react';

import 'lib/globals';
import { apolloClient, errorsToHash, gql } from 'lib/graphql';
import BillingInfo, { BillingInfoProps } from './billing_info';
import Button from 'lj_shared/button/button';
import FormInput from 'components/general/form_input/form_input';
import Icon from 'components/general/icon/icon';
import Textarea from 'components/form/textarea/textarea';

const styles = require('./invoice_form_page.module.scss');
const CURRENCY_SYMBOL = '€';

interface Props {
  billingInfo?: BillingInfoProps;
  companyName: string;
  defaultEndDate: string;
  defaultStartDate: string;
  defaultWorkedDays: number;
  effectiveRate: number;
  invoiceMonth: string;
  placementId: string;
  projectName: string;
}

export default function InvoiceFormPage(props: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <div className={styles.projectsLinkContainer}>
          <Icon name='arrowLeft' />
          <a
            className={styles.projectsLink}
            href={window.Routes.dashboard_placement(props.placementId)}
          >
            Project
          </a>
        </div>
        {renderPlacement(props)}
        {renderForm(props)}
      </div>
      <div className={styles.sideBar}>
        <BillingInfo projectId={props.placementId} {...props.billingInfo} />
      </div>
    </div>
  );
}

function renderPlacement(props) {
  return (
    <div className={styles.project}>
      <div className={styles.projectHeader}>
        <span className={styles.projectName}>{props.projectName}</span> -{' '}
        <span className={styles.companyName}>{props.companyName}</span>
      </div>
      <div className={styles.invoiceDate}>{props.invoiceMonth} - Invoice reporting</div>
    </div>
  );
}

function renderForm(props) {
  const viewModel = useViewModel(props.placementId, props.effectiveRate);
  const { errors, documentName, submitting } = viewModel.state;
  const dateError = errors['startDate'] || errors['endDate'];

  return (
    <form onSubmit={viewModel.handleSubmit}>
      <FormInput className={styles.formInput} error={dateError} label='Dates' required={true}>
        <span>
          <input
            className={styles.dateInput}
            defaultValue={props.defaultStartDate}
            onChange={viewModel.handleDateChange}
            ref={viewModel.refs.startDate}
            required={true}
            type='date'
          />
          <input
            className={styles.dateInput}
            defaultValue={props.defaultEndDate}
            onChange={viewModel.handleDateChange}
            ref={viewModel.refs.endDate}
            required={true}
            type='date'
          />
        </span>
      </FormInput>
      <FormInput
        className={styles.formInput}
        error={errors['workedDays']}
        label='Worked days'
        required={true}
      >
        <input
          className={styles.input}
          defaultValue={props.defaultWorkedDays}
          onChange={viewModel.handleWorkedDaysChange}
          ref={viewModel.refs.workedDays}
          required={true}
          type='number'
        />
      </FormInput>
      <FormInput
        className={styles.formInput}
        error={errors['document']}
        label='Invoice document - PDF'
        required={true}
      >
        <div className={styles.documentInputGroup}>
          <span className={styles.documentName}>{documentName}</span>
          <label>
            <Button buttonColor='tuftsBlue' buttonSize='small' buttonType='border' isButton={false}>
              Select file
              <input
                className={styles.fileInput}
                onChange={viewModel.handleDocumentChange}
                ref={viewModel.refs.document}
                required={true}
                type='file'
              />
            </Button>
          </label>
        </div>
      </FormInput>
      <FormInput
        className={styles.formInput}
        error={errors['number']}
        label='Invoice number'
        required={true}
      >
        <input className={styles.input} ref={viewModel.refs.number} required={true} type='text' />
      </FormInput>
      <FormInput
        className={styles.formInput}
        error={errors['valueWithoutVat']}
        label='Total value without VAT'
        required={true}
      >
        {renderMoneyInput(
          viewModel.refs.valueWithoutVatCents,
          viewModel.handleValueWithoutVatChange
        )}
      </FormInput>
      <FormInput
        className={styles.formInput}
        error={errors['valueWithVat']}
        label='Total value with VAT'
        required={true}
      >
        {renderMoneyInput(viewModel.refs.valueWithVatCents)}
      </FormInput>
      <FormInput className={styles.formInput} error={errors['notes']} label='Notes'>
        <Textarea
          className={styles.notes}
          placeholder='Write here if you have any comments or extra expenses.'
          ref={viewModel.refs.notes}
        />
      </FormInput>
      <div className={styles.submitContainer}>
        <Button
          buttonColor='tuftsBlue'
          buttonSize='small'
          disabled={submitting}
          isButton={true}
          otherClasses={styles.submitButton}
        >
          {submitting ? 'Sending...' : 'Confirm and send invoice'}
        </Button>
      </div>
    </form>
  );
}

function renderMoneyInput(ref, onChange?, defaultValue?) {
  return (
    <span>
      <span className={styles.currency}>{CURRENCY_SYMBOL}</span>
      <input
        className={styles.currencyInput}
        defaultValue={defaultValue}
        onChange={onChange}
        ref={ref}
        required={true}
        step='.01'
        type='number'
      />
    </span>
  );
}

const CREATE_GQL = gql`
  mutation (
    $document: Upload!
    $endDate: ISO8601Date!
    $notes: String
    $number: String
    $placementId: ID!
    $startDate: ISO8601Date!
    $valueWithoutVatCents: Int!
    $valueWithVatCents: Int!
    $workedDays: Int!
  ) {
    placementInvoice {
      create(
        document: $document
        endDate: $endDate
        notes: $notes
        number: $number
        placementId: $placementId
        startDate: $startDate
        valueWithVatCents: $valueWithVatCents
        valueWithoutVatCents: $valueWithoutVatCents
        workedDays: $workedDays
      ) {
        errors {
          field
          message
        }
      }
    }
  }
`;

function useViewModel(placementId, effectiveRate) {
  const [state, setState] = useState({
    documentName: null,
    errors: {},
    valueWithoutVatOverriden: false,
    workedDaysOverriden: false,
    submitting: false,
  });
  const refs = {
    document: useRef(null),
    endDate: useRef(null),
    notes: useRef(null),
    number: useRef(null),
    startDate: useRef(null),
    valueWithVatCents: useRef(null),
    valueWithoutVatCents: useRef(null),
    workedDays: useRef(null),
  };
  const viewModel = {
    effectiveRate,
    handleDateChange: null,
    handleDocumentChange: null,
    handleValueWithoutVatChange: null,
    handleWorkedDaysChange: null,
    handleSubmit: null,
    placementId,
    refs,
    setState,
    state,
  };
  viewModel.handleDateChange = handleDateChange.bind(viewModel);
  viewModel.handleDocumentChange = handleDocumentChange.bind(viewModel);
  viewModel.handleSubmit = handleSubmit.bind(viewModel);
  viewModel.handleValueWithoutVatChange = handleValueWithoutVatChange.bind(viewModel);
  viewModel.handleWorkedDaysChange = handleWorkedDaysChange.bind(viewModel);
  return viewModel;
}

function handleDocumentChange() {
  const documentName = this.refs.document.current.files[0]?.name;
  this.setState(state => ({ ...state, documentName }));
}

function handleDateChange() {
  const rawStartDate = this.refs.startDate.current.value;
  const rawEndDate = this.refs.endDate.current.value;

  if (rawStartDate && rawEndDate) {
    const startDate = new Date(rawStartDate);
    const endDate = new Date(rawEndDate);
    const oneDay = 24 * 60 * 60 * 1000; // 24h in milliseconds
    const days = Math.floor((endDate.getTime() - startDate.getTime()) / oneDay) + 1;
    const workedDays = Math.floor((days * 5) / 7);

    if (!this.state.workedDaysOverriden) {
      this.refs.workedDays.current.value = workedDays;
      updateValueWithVat.call(this, workedDays);
    }
  }
}

function handleValueWithoutVatChange() {
  this.setState(state => ({ ...state, valueWithoutVatOverriden: true }));
}

function handleWorkedDaysChange() {
  const rawDays = this.refs.workedDays.current.value;
  if (rawDays) updateValueWithVat.call(this, parseInt(rawDays, 10));
  this.setState(state => ({ ...state, workedDaysOverriden: true }));
}

function updateValueWithVat(days) {
  const value = roundMoney(days * this.effectiveRate);
  if (!this.state.valueWithoutVatOverriden) {
    this.refs.valueWithoutVatCents.current.value = value;
  }
}

async function handleSubmit(event) {
  event.preventDefault();
  this.setState(state => ({ ...state, errors: {}, submitting: true }));
  const { refs } = this;
  const variables = {
    document: refs.document.current.files[0],
    endDate: refs.endDate.current.value,
    notes: refs.notes.current.value,
    number: refs.number.current.value,
    placementId: this.placementId,
    startDate: refs.startDate.current.value,
    valueWithVatCents: parseMoney(refs.valueWithVatCents.current.value),
    valueWithoutVatCents: parseMoney(refs.valueWithoutVatCents.current.value),
    workedDays: parseInt(refs.workedDays.current.value, 10),
  };
  const { data } = await apolloClient.mutate({
    mutation: CREATE_GQL,
    variables,
  });
  const result = data?.placementInvoice?.create;
  const errors = errorsToHash(result?.errors);
  let success = false;

  if (!result) {
    window.Alerts.alert('Oops, something wrong happened.');
  } else if (result.errors.length > 0) {
    window.Alerts.alert('Please fix the issues below.');
  } else {
    success = true;
    window.location = window.Routes.dashboard_placement(this.placementId);
  }

  if (success) {
    window.Alerts.notice('Invoice submitted.');
  } else {
    this.setState(state => ({ ...state, errors, submitting: false }));
  }
}

function roundMoney(value) {
  return Math.round(value * 100) / 100;
}

function parseMoney(value) {
  return Math.round(parseFloat(value) * 100);
}
