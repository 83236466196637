import gql from 'graphql-tag';
export const settings = require('settings.json');

export const GET_LANGUAGE_RATING_AND_NOTES_GQL = gql`
  query (
    $preferredLanguageRating: Int!
    $niceToHaveLanguageRating: Int!
    $preferredLanguage: String!
    $niceToHaveLanguage: String!
    $preferredLanguageLabel: String!
    $niceToHaveLanguageLabel: String!
  ) {
    languageRatingAndNotesComputation(
      preferredLanguageRating: $preferredLanguageRating
      niceToHaveLanguageRating: $niceToHaveLanguageRating
      preferredLanguage: $preferredLanguage
      niceToHaveLanguage: $niceToHaveLanguage
      preferredLanguageLabel: $preferredLanguageLabel
      niceToHaveLanguageLabel: $niceToHaveLanguageLabel
    ) {
      rating
      notes
    }
  }
`;

export const resolveLanguageNotesVariables = (
  dataValues,
  preferredLanguage,
  niceToHaveLanguage
) => {
  const preferredLanguageRating = parseInt(dataValues['preferred_language'] as string, 10);
  const preferredLanguageLabel = settings.languageProficiency[dataValues['preferred_language']][0];
  let niceToHaveLanguageRating = 0;
  let niceToHaveLanguageLabel = '';
  if (niceToHaveLanguage) {
    niceToHaveLanguageRating = parseInt(dataValues['nice_to_have_language'] as string, 10);
    niceToHaveLanguageLabel = settings.languageProficiency[dataValues['nice_to_have_language']][0];
  } else {
    niceToHaveLanguage = '';
  }

  const variables = {
    preferredLanguageRating,
    niceToHaveLanguageRating,
    preferredLanguage,
    niceToHaveLanguage,
    preferredLanguageLabel,
    niceToHaveLanguageLabel,
  };

  return variables;
};

export function getLanguageProficiency(currentLanguage, talentLanguages) {
  const proficiency =
    talentLanguages.find(language => language.language === currentLanguage)?.proficiency_value || 0;
  return proficiency;
}
