import classNames from 'classnames';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { apolloClient } from 'lib/graphql';

import AutomaticNotes from './automatic_notes';
import Checkbox from 'components/form/checkbox/checkbox';
import ExperienceDimension from './experience_dimension';
import FormError from '../form/form_error/form_error';
import LanguageDimension from './language_dimension';
import LoadingOverlay from '../general/loading_overlay/loading_overlay';
import LocationDimension from './location_dimension';
import ProgressBar from '../general/progress_bar/progress_bar';
import RatingBox from './rating_box';
import SidebarButtons from './sidebarButtons';
import SkillsDimension from './skills_dimension';
import Textarea from '../form/textarea/textarea';
import { SidebarFormContainer } from './sidebarForm';
import {
  bonusConcernData,
  computeDataNamesAndValues,
  CURATE_GQL,
  DIMENSIONS,
  GET_OVERALL_GQL,
  isLast,
  TOTAL_STEPS,
} from './utils/sidebarUtils';
import BonusAndConcerns from './bonus_and_concerns';

const styles = require('./sidebar.module.scss');
export interface Props {
  applicationId: string;
  canRejectApplication: boolean;
  curator: string;
  isCurated: boolean;
  setCurrentDimension: Function;
  urls: any;
}

export default function Sidebar(props: Props) {
  const [userError, setUserError] = useState(null);
  const [rejectionError, setRejectionError] = useState(null);
  const [allowNext, setAllowNext] = useState(true);
  const [step, setStep] = useState(1);
  const [curateMutation, { data, error, loading }] = useMutation(CURATE_GQL, {
    client: apolloClient,
    onCompleted: handleCompleted,
  });
  const notesRef = useRef(null);
  const rejectionLandingMessageRef = useRef(null);
  const rejectionCandidateMessageRef = useRef(null);
  const applicationId = parseInt(props.applicationId, 10);
  const form = SidebarFormContainer.useContainer();

  const handleChangeDimension = dimension => {
    props.setCurrentDimension(dimension);
  };

  const handleBack = event => {
    event.preventDefault();
    setStep(step - 1);
    handleChangeDimension(DIMENSIONS[step - 2].name);
    setUserError(null);
    setRejectionError(null);
  };

  const handleNext = () => {
    const [valid, message] = validate();
    if (!valid) {
      return setError(message);
    }

    if (isLast(step)) {
      submitCuration();
    } else {
      requestOverall();
      setStep(step + 1);
      setUserError(null);
      handleChangeDimension(DIMENSIONS[step].name);
      setRejectionError(null);
    }
  };

  const setError = message => {
    if (isLast(step) && form.rejection) {
      setRejectionError(message);
    } else {
      setUserError(message);
    }
  };

  function requestOverall() {
    const overallIsNext = step === TOTAL_STEPS - 1;
    if (overallIsNext && form.overallIsDirty) {
      form.setOverallIsDirty(false);
      const ratings = [];
      for (const [key, value] of Object.entries(form.values)) {
        if (key !== 'overall') {
          ratings.push({ name: key, rating: value });
        }
      }
      apolloClient
        .query({
          query: GET_OVERALL_GQL,
          variables: { ratings },
        })
        .then(result => {
          const newValues = {
            ...form.values,
          };
          form.setValues(newValues);
          form.setOverall(result['data']['appRatingComputation']);
        })
        .catch(e => {
          window.Alerts.alert('Oops, something went wrong.');
        });
    }
  }

  function submitCuration() {
    const ratingNames = [];
    const ratingValues = [];
    const dataNames = [];
    const dataValues = [];
    const automaticNotes = [];

    for (const [name, value] of Object.entries(form.values)) {
      ratingNames.push(name);
      ratingValues.push(value);
      automaticNotes.push(form.automaticNotesValues[name] || []);
    }

    const ratingOverall = form.overall;

    const bonusConcern = bonusConcernData(
      form.bonusConcern.bonusConcernValues,
      form.automaticNotesValues.info
    );

    computeDataNamesAndValues(dataNames, dataValues, form.valuesData, form.skills.skills);

    const baseVariables = {
      applicationId,
      automaticNotes,
      bonusConcern,
      dataNames,
      dataValues,
      handpicked: !!form.handpicked,
      notes: notesRef.current.value,
      ratingNames,
      ratingOverall,
      ratingValues,
      rejection: form.rejection,
      rejectionCandidateMessage: rejectionCandidateMessageRef.current?.value,
      rejectionLandingMessage: rejectionLandingMessageRef.current?.value,
    };

    const variables = baseVariables;

    curateMutation({ variables });
    // TODO handle submission failure
  }

  function validate() {
    if (step === 0 || ['skills', 'info'].includes(DIMENSIONS[step - 1].name)) {
      return [true];
    } else if (step !== 0 && !isLast(step)) {
      return getInputValue() ? [true] : [false, "This field can't be empty"];
    } else if (isLast(step)) {
      return form.rejection && !rejectionLandingMessageRef.current.value
        ? [false, "This field can't be empty"]
        : [true];
    }
    return [true];
  }

  function handleCompleted() {
    window.location.href = props.urls.finish_curation_path.path;
    sendSuccessMessage();
  }

  function sendSuccessMessage() {
    if (props.urls.finish_curation_path.same_person) {
      window.Alerts.notice('Curation successfully submitted!');
    } else {
      window.Alerts.notice('Curation successfully submitted! Check this next candidate.');
    }
  }

  function getInputValue() {
    const input = form.ratingRef.current;

    return input.value;
  }

  const changeStepInProgressBar = dimension => {
    setStep(dimension + 1);
    handleChangeDimension(DIMENSIONS[dimension].name);
  };

  function renderWizard() {
    const dimension = DIMENSIONS[step - 1];

    return (
      <div className={styles.main}>
        <LoadingOverlay active={!loading}>
          <div className={styles.container}>{curationLeftHeader()}</div>
          <div className={styles.progressBar}>
            <ProgressBar
              className={styles.progressBar}
              max={TOTAL_STEPS}
              type='boxes'
              value={step}
              ratings={form.values}
              dimensions={DIMENSIONS}
              changeStep={changeStepInProgressBar}
            />
          </div>
          {renderDimension(dimension)}
          {userError && <FormError text={userError} />}
          {dimension.name !== 'overall' &&
            dimension.name !== 'info' &&
            renderButtons(dimension.name)}
          {/* Notes */}
          <h3 className={styles.h3}>Notes</h3>
          The employer and the candidate will have access to this.
          <AutomaticNotes notes={getAutomaticNotes()} />
          <Textarea ref={notesRef} />
        </LoadingOverlay>
      </div>
    );
  }

  function curationLeftHeader() {
    return (
      <div className={styles.left}>
        <h2 className={styles.h2}>Curation</h2>
      </div>
    );
  }

  function getAutomaticNotes() {
    let automaticNotes = {};
    let dimensionName;

    for (let currentNotes = step - 1; currentNotes >= 0; currentNotes--) {
      dimensionName = DIMENSIONS[currentNotes].name;
      if (form.automaticNotesValues[dimensionName]) {
        if (dimensionName === 'info') {
          const info = form.automaticNotesValues[dimensionName];
          const text = info.checkbox ? info.checkbox.join(' ').concat(` ${info.note}`) : info.note;
          automaticNotes = {
            ...automaticNotes,
            [dimensionName]: [text],
          };
        } else {
          automaticNotes = {
            ...automaticNotes,
            [dimensionName]: form.automaticNotesValues[dimensionName],
          };
        }
      }
    }
    return automaticNotes;
  }

  useEffect(() => {
    form.language.fillDefaultLanguageRating(
      form.application.job_ad.preferred_language,
      form.application.job_ad.nice_to_have_language
    );
  }, []);

  useEffect(() => {
    form.location.getInitialLocationRating();
  }, []);

  function renderDimension(dimension) {
    const value = form.values[dimension.name];
    const classes = `ld-input-block ld-select-button ld-form-input ${styles.ratingInput}`;
    if (dimension.name === 'language') {
      return (
        <LanguageDimension
          classes={classes}
          value={value}
          ratingRef={form.ratingRef}
          handleChange={form.language.handleLanguageChange()}
          preferredLanguage={form.application.job_ad.preferred_language}
          niceToHaveLanguage={form.application.job_ad.nice_to_have_language}
          preferredLanguageRef={form.language.preferredLanguageRef}
          niceToHaveLanguageRef={form.language.niceToHaveLanguageRef}
          preferredLanguageValue={form.valuesData['preferred_language']}
          niceToHaveLanguageValue={form.valuesData['nice_to_have_language']}
        />
      );
    } else if (dimension.name === 'location') {
      const officeCountryNames = form.application.job_ad.office_country_names || [];

      return (
        <LocationDimension
          candidateAcceptedJobTimezone={form.application.accepted_different_timezone}
          candidateHasTimezone={!!form.talentUser.timezone}
          classes={classes}
          value={value}
          ratingRef={form.ratingRef}
          locationRef={form.location.locationRef}
          locationDefaultValue={form.location.currentLocation['talent_location']}
          handleChangeLocation={form.location.handleChangeLocation}
          workPermitDefaultValue={form.location.validWorkPermit}
          handleChangeWorkPermit={form.location.handleChangeWorkPermit}
          jobType={form.application.job_type}
          jobWithTimezone={!!form.application.job_ad.timezone}
          citizenship={form.application.job_ad.citizenship}
          handleChangeTimeZone={form.location.handleChangeTimeZone}
          handleChangeTimeZoneNegative={form.location.handleChangeTimeZoneNegative}
          jobCountryNames={officeCountryNames.join(', ')}
          timeZoneDefaultValue={form.valuesData['time_zone'] || false}
          timeZoneMismatch={form.application.time_zone_mismatch}
          timeZoneNegativeDefaultValue={form.valuesData['time_zone_negative'] || false}
        />
      );
    } else if (dimension.name === 'experience') {
      return (
        <ExperienceDimension
          classes={classes}
          value={value}
          ratingRef={form.ratingRef}
          handleChange={form.experience.handleExperienceChange()}
          backgroundYearsDefaultValue={form.valuesData['background_checkbox'] || false}
          handleChangeBackgroundYears={form.experience.handleChangeBackgroundYears}
          sameRoleDefaultValue={form.valuesData['same_role_performed'] || false}
          handleChangeSameRole={form.experience.handleChangeSameRole}
          backgroundYearsRef={form.experience.backgroundYearsRef}
          backgroundYearsValue={form.valuesData['background_years']}
          handleNoRelevantBackground={form.experience.handleNoRelevantBackground}
          noRelevantBackgroundValue={form.valuesData['no_relevant_background'] || false}
        />
      );
    } else if (dimension.name === 'skills') {
      return (
        <SkillsDimension
          application={form.application}
          rating={form.values['skills']}
          skills={form.skills.skills}
          setNotes={form.skills.setSkillsNotes}
          setRating={form.skills.setSkillsRating}
          setSkills={form.skills.setSkills}
          user={form.talentUser}
        />
      );
    } else if (dimension.name === 'overall') {
      return (
        <div>
          <div className={styles.container}>
            <h3 className={styles.h3}>{dimension.label}</h3>
            <RatingBox value={form.overall} name='overall' ratingRef={form.ratingRef} />
          </div>
          {renderButtons(dimension.name)}
          {props.canRejectApplication && (
            <RejectionInput
              candidateMessageRef={rejectionCandidateMessageRef}
              form={form}
              landingMessageRef={rejectionLandingMessageRef}
              rejectionError={rejectionError}
            />
          )}
          <HandpickedInput form={form} />
        </div>
      );
    } else if (dimension.name === 'info') {
      return (
        <>
          <BonusAndConcerns
            setAllowNext={setAllowNext}
            values={form.bonusConcern.bonusConcernValues}
            setSliderValue={form.bonusConcern.setSliderValue}
            setRadioSelected={form.bonusConcern.setRadioSelected}
            notesRef={form.bonusConcern.bonusConcernNotesRef}
            handleTextChange={form.bonusConcern.handleTextChange}
            handleCheckboxNoteChange={form.bonusConcern.handleCheckboxNoteChange}
          />
          {renderButtons(dimension.name, !allowNext)}
        </>
      );
    }
  }

  function renderButtons(dimension, disableNext = false) {
    return (
      <SidebarButtons
        dimension={dimension}
        disableNext={disableNext}
        handleBack={() => handleBack}
        handleNext={() => handleNext}
        jobType={form.application.job_type}
        jobWithTimezone={!!form.application.job_ad.timezone}
        step={step}
        overall={form.overall}
        values={form.values}
        valuesData={form.valuesData}
      />
    );
  }

  function renderAlreadyCurated() {
    return (
      <div className={styles.main}>
        <div className={styles.h5}>
          State <span className={styles.stateBadge}>Screened by Landing</span>
        </div>
        <div className={styles.curatorName}>
          <span className={styles.h5}>Curator: </span>
          {props.curator}
        </div>
      </div>
    );
  }

  if (props.isCurated) {
    return renderAlreadyCurated();
  } else {
    return renderWizard();
  }
}

function HandpickedInput({ form }) {
  const label =
    'I want to highlight this candidate as a potential hire and would like to ' +
    'highlight them for the employer.';
  const disabled = !form.application.can_request_handpicked;
  const onChange = useCallback((event, name, value) => form.setHandpicked(value), [form]);

  return (
    <div
      className={classNames(styles.handpickedContainer, {
        [styles.handpickedDisabled]: disabled,
      })}
    >
      <h3>Highlight</h3>
      <small className={styles.handpickedNote}>The employer will have access to this.</small>
      <Checkbox controlled={false} disabled={disabled} label={label} onChange={onChange} />
    </div>
  );
}

function RejectionInput({ candidateMessageRef, form, landingMessageRef, rejectionError }) {
  const label =
    "The candidate doesn't meet a specific requirement and therefore should be rejected";
  const onChange = useCallback((event, name, value) => form.setRejection(value), [form]);

  return (
    <div className={classNames(styles.container)}>
      <h3>Reject</h3>
      <div className={classNames(styles.rejectionContainer)}>
        <Checkbox controlled={false} label={label} onChange={onChange} checked={form.rejection} />
      </div>
      {form.rejection && (
        <>
          <div className={classNames(styles.rejectionContainer)}>
            <Textarea
              ref={landingMessageRef}
              label='Add details below (to be shared internally with Landing.Jobs team only):'
              required
            />
            {rejectionError && <FormError text={rejectionError} />}
          </div>
          <div className={classNames(styles.rejectionContainer)}>
            <Textarea
              ref={candidateMessageRef}
              label='Do you want to add a message for the candidate?'
            />
          </div>
        </>
      )}
    </div>
  );
}
