import React from 'react';
import TagButton from './tag_button';
import { shallow } from 'enzyme';

let wrapped;

describe('Tag Edit Component existance/structure', () => {
  const props = {
    name: 'test name',
    alternates: ['test 1', 'test2'],
    isEdit: true,
  };
  beforeAll(() => {
    wrapped = shallow(<TagButton {...props} />);
  });

  it('renders', () => {
    expect(wrapped.exists()).toBe(true);
  });
});
