import React from 'react';
import FormInput from 'components/general/form_input/form_input';
import Switch from 'components/general/switch/switch';
import { availabilityOptions } from 'components/registration/registration_utils/registration_utils';
const styles = require('./StatusInput.module.scss');

export interface Props {
  error: string;
  availability: any;
  handleFormChange: any;
}

export default function StatusInput(props: Props) {
  return (
    <FormInput
      name='availability'
      label='What is your current employment status?'
      error={props.error}
      required
    >
      <Switch
        selectedValue={
          props.availability.value !== undefined ? props.availability.value : props.availability
        }
        options={availabilityOptions}
        onClick={event => props.handleFormChange(event, 'availability')}
        buttonColor='silverSand'
        name='availability'
        otherClasses={styles.switch}
        spaceBetween
      />
    </FormInput>
  );
}
