import React, { useRef, useState } from 'react';
import Textarea from 'components/form/textarea/textarea';
import Icon, { Size } from 'components/general/icon/icon';
import Modal, { ButtonsLayout, ModalSize } from 'components/general/modal/modal';
import { handleRequestError, patchJSON } from 'lib/request_deprecated';

const styles = require('./reject_handshake_modal.module.scss');

export interface Props {
  handshake: any;
  companyName: string;
  jobAd?: { title: string; id: number };
  handleReject?: Function;
  setIsModalOpen: Function;
  hasFollowUpModal?: boolean;
  setIsAfterReject?: Function;
}

export default function RejectHandshakeModal(props: Props) {
  const { handshake, companyName, jobAd } = props;
  const modalRef = useRef(null);
  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleReject = () => {
    setIsLoading(true);
    const body = {
      rejectionReason: inputRef.current.value,
    };

    patchJSON(window.Routes.reject_dashboard_handshake(handshake.id, body))
      .then(data => {
        props.setIsModalOpen(false);

        if (!props.hasFollowUpModal) {
          data.alert
            ? window.Alerts.alert(data.alert)
            : window.Alerts.notice('Handshake rejected!');
        }

        if (props.handleReject) {
          props.handleReject(data);
        } else if (props.hasFollowUpModal) {
          props.setIsAfterReject(true);
        } else {
          window.location.reload();
        }
      })
      .catch(handleRequestError);
  };

  const renderText = () => {
    return (
      <div className={styles.container}>
        <div className={styles.text}>
          {companyName} requested this handshake
          {jobAd?.title?.length > 0 && (
            <>
              &nbsp;and associated with a job opening:&nbsp;
              <a className={styles.link} href={window.Routes.job_ad(jobAd.id)}>
                {jobAd.title}
              </a>
            </>
          )}
          &nbsp;and you selected to reject it.
        </div>
        <div className={styles.form}>
          <div>
            Tell the company why is it not the best time to accept this handshake
            <i className={styles.optional}> (optional)</i>:
          </div>
          <Textarea className={styles.textArea} ref={inputRef} color={'silverSandxLight'} />
        </div>
      </div>
    );
  };

  return (
    <Modal
      title={
        <>
          <Icon size={Size.Big} name='handshakeCircleFull' />
          Reject handshake
        </>
      }
      defaultOpen={true}
      ref={modalRef}
      cancelButton={true}
      buttonColor={'flamingo'}
      buttonName='Reject'
      buttonOnClick={handleReject}
      buttonsLayout={ButtonsLayout.OkCancel}
      cancelButtonTitle={'Cancel'}
      onClose={() => props.setIsModalOpen(false)}
      disableButton={isLoading}
      size={ModalSize.Big}
    >
      {renderText()}
    </Modal>
  );
}
