import React, { useState, useEffect } from 'react';

import { getJSON, patchJSON, postJSON, handleRequestError } from '../../lib/request_deprecated';
import { renderRejected, renderAccepted, renderRequested } from './handshake_cards';
import HandshakeCard from '../handshakes/handshake_card';

export interface Props {
  company: any;
}

export default function CompanySideHandshake(props: Props) {
  const [handshake, setHandshake] = useState(null);
  const [handshakeState, setHandshakeState] = useState(null); // 'requested'

  const { id, name } = props.company;

  useEffect(() => {
    getJSON(window.Routes.company_handshake_dashboard_handshake(id))
      .then(handshakeData => {
        if (handshakeData.error) {
          return;
        }
        const { data } = handshakeData.handshake;
        const newHandshake = data.attributes;
        setHandshake(newHandshake);
        setHandshakeState(newHandshake.state);
      })
      .catch(handleRequestError);
  }, []);

  const handleSuccess = ({ state, message, error }) => {
    setHandshakeState(state);
    error ? window.Alerts.alert(message) : window.Alerts.notice(message);
  };

  const handleAccept = () => {
    patchJSON(window.Routes.accept_dashboard_handshake(handshake.id))
      .then(data => {
        handleSuccess(data);
        window.Alerts.notice('Handshake accepted!');
      })
      .catch(handleRequestError);
  };

  const handleReject = () => {
    patchJSON(window.Routes.reject_dashboard_handshake(handshake.id))
      .then(data => {
        handleSuccess(data);
      })
      .catch(handleRequestError);
  };

  const handleRevoke = () => {
    patchJSON(window.Routes.revoke_dashboard_handshake(handshake.id))
      .then(data => {
        handleSuccess(data);
      })
      .catch(handleRequestError);
  };

  const handleReconsider = () => {
    patchJSON(window.Routes.reconsider_dashboard_handshake(handshake.id))
      .then(data => {
        handleSuccess(data);
      })
      .catch(handleRequestError);
  };

  const renderHandshakeSwitch = () => {
    const defaultResponse = renderRequested(handleAccept, handleReject, name);
    switch (handshakeState) {
      case 'requested':
        return defaultResponse;
      case 'rejected':
        return renderRejected(handleReconsider, handshake.created_at, name);
      case 'unlocked':
        return renderAccepted(handleRevoke, handshake.created_at, name);
      default:
        return null;
    }
  };
  return handshake && <HandshakeCard {...renderHandshakeSwitch()} />;
}
