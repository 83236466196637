import * as React from 'react';
import ApplicationPage, { Props } from './application_page';
import { shallow, ShallowWrapper } from 'enzyme';
import applicationDummy from '__fixtures__/application_dummy';

const createTestProps = moreProps => {
  return {
    application: applicationDummy,
    moveToStep: jest.fn(),
    rejectApplication: jest.fn(),
    giveAlert: jest.fn(),
    backButton: jest.fn(),
    loading: false,
    urls: {},
    applicationQuestions: [],
    is_inbox: true,
    ats_offer: '',
    delivered: true,
    ats_name: '',
    job_editable: true,
    step_label: '',
    ...moreProps,
  };
};

let props: Props;
let wrapped: ShallowWrapper;

describe('Application Page component', () => {
  beforeEach(() => {
    props = createTestProps({});
    wrapped = shallow(<ApplicationPage {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
