import React from 'react';
import Icon from 'components/general/icon/icon';
import Tooltip from 'components/general/tooltip/tooltip';
import classNames from 'classnames';
const styles = require('./AverageHiringProcessItem.module.scss');

interface Props {
  companyName: string;
  companyAvg: string;
  marketAvg: string;
  companyPage?: boolean;
}

export default function AverageHiringProcessItem(props: Props) {
  return (
    <div className={classNames(props.companyPage ? styles.company : styles.job, styles.wrapper)}>
      <div className={styles.firstRow}>
        <Icon name='timerOutline' color='tuftsBlue' />
        <span className={styles.title}>Average hiring process</span>
        <Tooltip
          text={'The following estimates are based on the time of the previous hiring processes.'}
          textBelow
          color='tuftsBlue'
        >
          <Icon name={'helpCircle'} color={'tuftsBlue'} />
        </Tooltip>
      </div>
      <div className={styles.averages}>
        <div className={styles.companyAvg}>
          <div className={styles.name}>{props.companyName}</div>
          <div className={styles.numbers}>{props.companyAvg}</div>
        </div>
        <div className={styles.bar}>|</div>
        <div className={styles.marketAvg}>
          <div className={styles.name}>Market</div>
          <div className={styles.numbers}>{props.marketAvg}</div>
        </div>
      </div>
    </div>
  );
}
