const PALETTE = {
  silverSand: {
    xLight: '#F6F6F6',
    light: '#EDEDED',
    midLight: '#DADADA',
    base: '#C7C7C7',
    midDark: '#A2A2A2',
    dark: '#797979',
    xDark: '#4B4B4B',
    xxDark: '#000000',
  },
  puertoRico: {
    xLight: '#E3FAF9',
    light: '#BFF3F1',
    midLight: '#54E2DB',
    base: '#00CFC5',
    midDark: '#00B1A8',
    dark: '#008F88',
    xDark: '#006C66',
  },
  tuftsBlue: {
    xxLight: '#F7FCFF',
    xLight: '#D8E9FB',
    light: '#7BBAFA',
    midLight: '#2B92FC',
    base: '#007EFF',
    midDark: '#005BB9',
    dark: '#004A95',
    xDark: '#003974',
  },
  ripePlum: {
    xLight: '#F4D6FF',
    light: '#DA94F2',
    midLight: '#CA30FF',
    base: '#A729D2',
    midDark: '#871AAD',
    dark: '#5C0E77',
    xDark: '#380849',
  },
  flamingo: {
    xLight: '#FBB5AA',
    light: '#FA7B68',
    midLight: '#FB563E',
    base: '#F51F00',
    midDark: '#D91F04',
    dark: '#BC1A02',
    xDark: '#931502',
  },
  jaffa: {
    xLight: '#FBD3AF',
    light: '#FDAD64',
    midLight: '#FD9535',
    base: '#FF7A00',
    midDark: '#DE6B01',
    dark: '#BD5C03',
    xDark: '#974800',
  },
  starship: {
    xLight: '#FFF8C4',
    light: '#FFF291',
    midLight: '#FFED64',
    base: '#FFE100',
    midDark: '#E4CA03',
    dark: '#C8B100',
    xDark: '#A99500',
  },

  black: {
    midLight: '#797979',
    base: '#343434',
    midDark: '#000000',
  },
  mainColours: {
    white: '#FFF',
    black: '#000101',
    futureWorksGreen: '#00cfc5',
  },
  secondaryColours: {
    purple: '#CA30FF',
    blue: '#007EFF',
    lightGreen: '#BFF3F1',
  },

  fern: {
    base: '#5CB85C',
    midDark: '#3F7F3F',
  },

  linkedinBlue: {
    light: '#2CB7FF',
    midLight: '#009EF0',
    base: '#0077B5',
    midDark: '#005D8E',
    dark: '#004367',
  },
  githubGray: {
    light: '#5A5A5A',
    midLight: '#474747',
    base: '#333333',
    midDark: '#1F1F1F',
    dark: '#0C0C0C',
  },
  facebookBlue: {
    light: '#607FC1',
    midLight: '#466AB4',
    base: '#3B5998',
    midDark: '#30487C',
    dark: '#25385F',
  },
  twitterBlue: {
    light: '#67C0F6',
    midLight: '#42B0F4',
    base: '#1DA1F2',
    midDark: '#0D8DDB',
    dark: '#0A75B6',
  },
  skypeBlue: {
    light: '#70C9F8',
    midLight: '#37B3F6',
    base: '#12A5F4',
    midDark: '#0A8ED5',
    dark: '#0875AF',
  },
};

export function palette(hue, tone = 'base') {
  return PALETTE[hue][tone];
}
