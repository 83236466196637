import React from 'react';
import { useQuery } from 'react-apollo';
import { apolloClient } from 'lib/graphql';
import { useFind } from '__models__/gql/data-hooks/use_find';
import { Company } from '__models__/gql/all';
import { AdminUser } from '__models__/gql/admin_user';
import BusinessGeneralPricingCard from '../business_general_pricing_card/business_general_pricing_card';
import { GET_TALENT_ADVOCATE } from './admin_card_utils';
import classNames from 'classnames';
import Button from 'lj_shared/button/button';
import { adminUserPricingNotification } from 'lib/admin_user_pricing_notification';

const styles = require('./admin_card.module.scss');

interface Props {
  companyId?: number;
  talentId?: number;
  businessEmail?: string;
  pricingPlanUrl?: string;
  isHelpModal?: boolean;
  pricingLimitType?: string;
  pricingDisabledFeature?: string;
}

export default function AdminCard(props: Props) {
  const {
    companyId,
    pricingLimitType,
    pricingDisabledFeature,
    isHelpModal,
    pricingPlanUrl,
    businessEmail,
  } = props;

  const { data } = companyId
    ? useFind(Company, companyId, ['accountManager', 'email', 'name', 'avatar'])
    : useQuery(GET_TALENT_ADVOCATE, {
        client: apolloClient,
      });

  const accountManager: AdminUser = data?.company?.accountManager;
  const talentAdvocate: AdminUser = data?.person?.talentAdvocate;
  const pricingTargetedType = pricingLimitType || pricingDisabledFeature;

  const getDescription = adminUser => {
    const helpText = () => <span>Hi! Do you need extra support? Feel free to drop me a line!</span>;
    const companyPricingGeneralText = () => (
      <span>
        For further information regarding your plan or upgrades feel free to reach out to me! <br />
        To know more about our pricing plans, check our&nbsp;
        <a
          href={pricingPlanUrl}
          className={styles.companySettingsLink}
          target='_blank'
          rel='noreferrer'
        >
          comparison page
        </a>
        .
      </span>
    );

    const companyPricingTargetedText = () => {
      return (
        <span>
          {pricingLimitType
            ? `You've reached the limit number of ${pricingLimitType} in your plan.`
            : `You don't have the ${pricingDisabledFeature} feature available in your plan.`}
          <br />
          Interested in knowing more about plan details and options?&nbsp; Click here and{' '}
          {adminUser ? 'I' : 'we'}'ll get in touch!
        </span>
      );
    };

    const companyText = pricingTargetedType
      ? companyPricingTargetedText()
      : companyPricingGeneralText();

    return isHelpModal ? helpText() : companyText;
  };

  const renderAdminCard = (adminUser: AdminUser, type) => {
    return (
      <div className={styles.container}>
        <div className={classNames(styles.box, companyId ? styles.boxCompany : styles.boxTalent)}>
          {adminUser && (
            <img
              className={classNames(
                styles.picture,
                companyId ? styles.pictureCompany : styles.pictureTalent
              )}
              src={adminUser?.avatar}
              alt='picture'
            />
          )}
          <div className={styles.middleContainer}>
            {adminUser && (
              <span
                className={classNames(
                  styles.title,
                  companyId ? styles.titleCompany : styles.titleTalent
                )}
              >
                {adminUser.name}, {type}
              </span>
            )}
            <div className={isHelpModal ? styles.helpDescription : styles.description}>
              {getDescription(adminUser)}
            </div>
          </div>
          <Button
            buttonColor={companyId ? 'ripePlum' : 'tuftsBlue'}
            buttonSize='xSmall'
            targetBlank={true}
            onClick={pricingTargetedType && triggerPipedriveLead}
            buttonUrl={pricingTargetedType ? undefined : `mailto:${adminUser.email}`}
            otherClasses={pricingTargetedType && styles.button}
          >
            contact me
          </Button>
        </div>
      </div>
    );
  };

  const triggerPipedriveLead = () => {
    adminUserPricingNotification({
      pricingLimitType,
      pricingDisabledFeature,
    });
  };

  if (accountManager || pricingTargetedType) {
    return renderAdminCard(accountManager, 'Business Developer');
  } else if (talentAdvocate) {
    return renderAdminCard(talentAdvocate, 'Talent Advocate');
  } else {
    return (
      <BusinessGeneralPricingCard businessEmail={businessEmail} pricingPlanUrl={pricingPlanUrl} />
    );
  }
}
