import React, { useRef } from 'react';
import ApplicationCard from './application_card/application_card';
const icon = require('iconic/briefcase.svg');
import Button from 'lj_shared/button/button';
import TransferToAnotherJobModal, {
  TransferMode,
} from 'components/general/transfer_to_another_job/transfer_to_another_job_modal';

export interface Props {
  applicationId: number;
  canCloseCurrentApplication: boolean;
  isPairedWithAts: boolean;
  originalJobName?: string;
}

const SuggestAnotherJob = (props: Props) => {
  const transferModalRef = useRef(null);

  const handleTransferModal = event => {
    event.preventDefault();
    showTransferModal();
  };

  const showTransferModal = () => {
    transferModalRef.current.open();
  };

  return (
    <ApplicationCard title='Suggest Another Job' icon={icon}>
      <p className='lj-paragraph--midSmall u-textColor--silverSand is-dark u-marginTop--small u-marginBottom--medium'>
        Feeling like the candidate has a profile for another job?
        <br />
        Make a suggestion now.
      </p>
      <div className='lj-application-ats-button-group'>
        <Button
          isButton={true}
          buttonColor='ripePlum'
          buttonSize='xSmall'
          onClick={handleTransferModal}
        >
          Suggest
        </Button>
      </div>
      <TransferToAnotherJobModal
        ref={transferModalRef}
        applicationId={props.applicationId}
        canCloseCurrentApplication={props.canCloseCurrentApplication}
        isPairedWithAts={props.isPairedWithAts}
        originalJobName={props.originalJobName}
        transferMode={TransferMode.CreateSuggestion}
      />
    </ApplicationCard>
  );
};

export default SuggestAnotherJob;
