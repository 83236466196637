const talentProfileDummy = {
  data: {
    id: '35713',
    type: 'profile',
    attributes: {
      contractor: null,
      person: {
        data: {
          id: '34711',
          type: 'person',
          attributes: {
            first_name: 'boom',
            last_name: 'Rodrigues',
            bio: 'Tumbas',
            user_id: 35713,
            birth_year: 1988,
            city: 'Lisbon Area',
            country_code: 'PT',
            country_name: 'Portugal',
            minimum_rate: 100,
            maximum_rate: 1000,
            headline: 'Rea',
            cv_file_name: 'ramon_rodrigues_cv_en.pdf',
            remote: 1,
            id: 34711,
            relocation: true,
            relocation_countries: ['Ireland'],
            freelance: false,
            recipient_avatar:
              '<div class="lj-userAvatar--small lj-userAvatar"><img src="https://s3-eu-west-1.amazonaws.com/jobbox-assets-local-dev/attachments/talent_users/avatars/98bce16d2c22fb85698ac7125bb10f0aaf8f17e2/original.?1582217752" class="lj-userAvatar-img" /></div>',
            has_cv: true,
            job_search_availability:
              "I'm currently employed, but open to hear about new opportunities",
            cv_url:
              'https://jobbox-assets-local-dev.s3-eu-west-1.amazonaws.com/attachments/people/cvs/fa423e39b392aba36e1e32d5b97ce50eda891075/original.pdf?response-content-disposition=attachment%3B%20filename%3D%22ramon_rodrigues_cv_en.pdf%22&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAUNDMBB2RUPQ3TPBS%2F20200220%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200220T174240Z&X-Amz-Expires=10&X-Amz-SignedHeaders=host&X-Amz-Signature=5d413e2dffca3aef8c27c08bb382cd87aa73cac445e6bfc0e2cd35dfb595142b',
            name: 'boom Rodrigues',
            experience_years: {
              value: 3,
              label: '3 years of experience',
            },
            location: 'Lisbon Area, Portugal',
            citizenships: 'Portuguese, Brazilian',
            phone_number: '+351 91 234 5678',
            phone: '',
            email: 'cerberus.ramon@gmail.com',
            websites: [
              {
                id: 51168,
                full_url: 'https://linkedin.com/in/ramongr',
                url: 'https://linkedin.com/in/ramongr',
                title: 'Linkedin',
                category_key: 'linkedin',
              },
              {
                id: 51171,
                full_url: 'https://github.com/ramongr',
                url: 'https://github.com/ramongr',
                title: 'Github',
                category_key: 'github',
              },
              {
                id: 51172,
                full_url: 'https://bitbucket.org/ramongr',
                url: 'https://bitbucket.org/ramongr',
                title: 'Bitbucket',
                category_key: 'bitbucket',
              },
              {
                id: 131842,
                full_url: 'https://www.dribbble.com/ramongr',
                url: 'https://www.dribbble.com/ramongr',
                title: 'Dribbble',
                category_key: 'dribbble',
              },
              {
                id: 131843,
                full_url: 'http://www.behance.net/ramongr',
                url: 'http://www.behance.net/ramongr',
                title: 'Behance',
                category_key: 'behance',
              },
              {
                id: 131845,
                full_url: 'https://facebook.com/ramongr',
                url: 'https://facebook.com/ramongr',
                title: 'Facebook',
                category_key: 'facebook',
              },
              {
                id: 131844,
                full_url: 'https://twitter.com/ramongr',
                url: 'https://twitter.com/ramongr',
                title: 'Twitter',
                category_key: 'twitter',
              },
              {
                id: 131846,
                full_url: 'https://instagram.com/ramongr',
                url: 'https://instagram.com/ramongr',
                title: 'Instagram',
                category_key: 'instagram',
              },
            ],
            languages: [
              {
                id: 69406,
                name: 'English',
                experience_label: '4. Full professional proficiency',
              },
              {
                id: 69405,
                name: 'Portuguese',
                experience_label: '5. Native or Bilingual proficiency',
              },
            ],
            skills: [
              {
                id: 518097,
                name: 'Linux',
                experience_label: '6 Years',
                experience_level: 6,
              },
              {
                id: 518096,
                name: 'Ruby on Rails',
                experience_label: '4 Years',
                experience_level: 4,
              },
              {
                id: 518098,
                name: 'HTML',
                experience_label: '4 Years',
                experience_level: 4,
              },
              {
                id: 518100,
                name: 'CoffeeScript',
                experience_label: '2 Years',
                experience_level: 2,
              },
              {
                id: 518101,
                name: 'JavaScript',
                experience_label: '2 Years',
                experience_level: 2,
              },
              {
                id: 568934,
                name: 'jQuery',
                experience_label: '3 Years',
                experience_level: 3,
              },
              {
                id: 518103,
                name: 'SQL',
                experience_label: '2 Years',
                experience_level: 2,
              },
              {
                id: 518108,
                name: 'CSS',
                experience_label: '2 Years',
                experience_level: 2,
              },
              {
                id: 518112,
                name: 'PHP',
                experience_label: '1 Year',
                experience_level: 1,
              },
              {
                id: 568932,
                name: 'MySQL',
                experience_label: '2 Years',
                experience_level: 2,
              },
              {
                id: 568933,
                name: 'PostgreSQL',
                experience_label: '2 Years',
                experience_level: 2,
              },
              {
                id: 1374907,
                name: 'gulp',
                experience_label: null,
                experience_level: null,
              },
            ],
            certificates: [],
            person_tags: {
              multiple_processes: null,
            },
            work_availability: {
              id: 1,
              value: 1,
              label: '1 Week',
            },
            contact_preference: {
              id: 0,
              value: 0,
              label: 'Phone',
            },
            categories: [],
            skype_id: 'lopesdasilva33',
            professional_experiences: [],
            description: 'Tumbas',
            contractor_share_profile: {
              share_profile: true,
              profile_url: 'acdb7c01657cc66d',
            },
          },
        },
      },
      institutions: {
        data: [],
      },
      currentUser: null,
      logoutUrl: '/logout',
      skills: {
        data: [
          {
            id: '518097',
            type: 'skills',
            attributes: {
              name: 'Linux',
              experience_level: 6,
              experience_label: '6 Years',
            },
          },
          {
            id: '518096',
            type: 'skills',
            attributes: {
              name: 'Ruby on Rails',
              experience_level: 4,
              experience_label: '4 Years',
            },
          },
          {
            id: '518098',
            type: 'skills',
            attributes: {
              name: 'HTML',
              experience_level: 4,
              experience_label: '4 Years',
            },
          },
          {
            id: '518100',
            type: 'skills',
            attributes: {
              name: 'CoffeeScript',
              experience_level: 2,
              experience_label: '2 Years',
            },
          },
          {
            id: '518101',
            type: 'skills',
            attributes: {
              name: 'JavaScript',
              experience_level: 2,
              experience_label: '2 Years',
            },
          },
        ],
      },
      citizenships: [
        '',
        'Afghan',
        'Albanian',
        'Algerian',
        'American',
        'American Samoan',
        'Andorran',
        'Angolan',
        'Anguillian',
        'Antiguan, Barbudan',
        'Argentinean',
        'Armenian',
        'Aruban',
        'Australian',
        'Austrian',
        'Azerbaijani',
        'Bahamian',
        'Bahraini',
        'Bangladeshi',
        'Barbadian',
        'Belarusian',
        'Belgian',
        'Belizean',
        'Beninese',
        'Bermudian',
        'Bhutanese',
        'Bolivian',
        'Bosnian, Herzegovinian',
        'Brazilian',
        'British',
        'Bruneian',
        'Bulgarian',
        'Burkinabe',
        'Burundian',
        'Cambodian',
        'Cameroonian',
        'Canadian',
        'Cape Verdian',
        'Caymanian',
        'Central African',
        'Chadian',
        'Channel Islander',
        'Chilean',
        'Chinese',
        'Christmas Island',
        'Cocos Islander',
        'Colombian',
        'Comoran',
        'Congolese',
        'Cook Islander',
        'Costa Rican',
        'Croatian',
        'Cuban',
        'Cypriot',
        'Czech',
        'Danish',
        'Djibouti',
        'Dominican',
        'Dutch',
        'East Timorese',
        'Ecuadorean',
        'Egyptian',
        'Emirian',
        'Equatorial Guinean',
        'Eritrean',
        'Estonian',
        'Ethiopian',
        'Falkland Islander',
        'Faroese',
        'Fijian',
        'Filipino',
        'Finnish',
        'French',
        'French Guianan',
        'French Polynesian',
        'Gabonese',
        'Gambian',
        'Georgian',
        'German',
        'Ghanaian',
        'Gibraltar',
        'Greek',
        'Greenlandic',
        'Grenadian',
        'Guadeloupian',
        'Guamanian',
        'Guatemalan',
        'Guinea-Bissauan',
        'Guinean',
        'Guyanese',
        'Haitian',
        'Heard and McDonald Islander',
        'Honduran',
        'Hong Kongese',
        'Hungarian',
        'I-Kiribati',
        'Icelander',
        'Indian',
        'Indonesian',
        'Iranian',
        'Iraqi',
        'Irish',
        'Israeli',
        'Italian',
        'Ivorian',
        'Jamaican',
        'Japanese',
        'Jordanian',
        'Kazakhstani',
        'Kenyan',
        'Kirghiz',
        'Kittian and Nevisian',
        'Kosovan',
        'Kuwaiti',
        'Laotian',
        'Latvian',
        'Lebanese',
        'Liberian',
        'Libyan',
        'Liechtensteiner',
        'Lithuanian',
        'Luxembourger',
        'Macedonian',
        'Malagasy',
        'Malawian',
        'Malaysian',
        'Maldivan',
        'Malian',
        'Maltese',
        'Manx',
        'Marshallese',
        'Mauritanian',
        'Mauritian',
        'Mexican',
        'Micronesian',
        'Moldovan',
        'Monegasque',
        'Mongolian',
        'Montenegrin',
        'Montserratian',
        'Moroccan',
        'Mosotho',
        'Motswana',
        'Mozambican',
        'Myanmarian',
        'Namibian',
        'Nauruan',
        'Nepalese',
        'New Caledonian',
        'New Zealander',
        'Ni-Vanuatu',
        'Nicaraguan',
        'Nigerian',
        'Niuean',
        'Norfolk Islander',
        'North Korean',
        'Norwegian',
        'Omani',
        'Pakistani',
        'Palauan',
        'Palestinian',
        'Panamanian',
        'Papua New Guinean',
        'Paraguayan',
        'Peruvian',
        'Pitcairn Islander',
        'Polish',
        'Portuguese',
        'Puerto Rican',
        'Qatari',
        'Romanian',
        'Russian',
        'Rwandan',
        'Sahrawi',
        'Saint Barthélemy Islander',
        'Saint Helenian',
        'Saint Lucian',
        'Saint Martin Islander',
        'Saint Vincentian',
        'Salvadoran',
        'Sammarinese',
        'Samoan',
        'Sao Tomean',
        'Saudi Arabian',
        'Senegalese',
        'Serbian',
        'Seychellois',
        'Sierra Leonean',
        'Singaporean',
        'Slovak',
        'Slovene',
        'Solomon Islander',
        'Somali',
        'South African',
        'South Georgia and the South Sandwich Islander',
        'South Korean',
        'South Sudanese',
        'Spanish',
        'Sri Lankan',
        'Sudanese',
        'Surinamer',
        'Swazi',
        'Swedish',
        'Swiss',
        'Syrian',
        'Tadzhik',
        'Taiwanese',
        'Tanzanian',
        'Thai',
        'Togolese',
        'Tokelauan',
        'Tongan',
        'Trinidadian',
        'Tunisian',
        'Turkish',
        'Turkmen',
        'Turks and Caicos Islander',
        'Tuvaluan',
        'Ugandan',
        'Ukrainian',
        'Uruguayan',
        'Uzbekistani',
        'Venezuelan',
        'Vietnamese',
        'Virgin Islander',
        'Wallis and Futuna Islander',
        'Yemeni',
        'Zambian',
        'Zimbabwean',
      ],
      relocation_countries: [
        'Afghanistan',
        'Albania',
        'Algeria',
        'American Samoa',
        'Andorra',
        'Angola',
        'Anguilla',
        'Antarctica',
        'Antigua and Barbuda',
        'Argentina',
        'Armenia',
        'Aruba',
        'Australia',
        'Austria',
        'Azerbaijan',
        'Bahamas',
        'Bahrain',
        'Bangladesh',
        'Barbados',
        'Belarus',
        'Belgium',
        'Belize',
        'Benin',
        'Bermuda',
        'Bhutan',
        'Bolivia, Plurinational State of',
        'Bonaire, Sint Eustatius and Saba',
        'Bosnia and Herzegovina',
        'Botswana',
        'Bouvet Island',
        'Brazil',
        'British Indian Ocean Territory',
        'Brunei Darussalam',
        'Bulgaria',
        'Burkina Faso',
        'Burundi',
        'Cambodia',
        'Cameroon',
        'Canada',
        'Cape Verde',
        'Cayman Islands',
        'Central African Republic',
        'Chad',
        'Chile',
        'China',
        'Christmas Island',
        'Cocos (Keeling) Islands',
        'Colombia',
        'Comoros',
        'Congo',
        'Congo, The Democratic Republic of the',
        'Cook Islands',
        'Costa Rica',
        'Croatia',
        'Cuba',
        'Curaçao',
        'Cyprus',
        'Czech Republic',
        "Côte d'Ivoire",
        'Denmark',
        'Djibouti',
        'Dominica',
        'Dominican Republic',
        'Ecuador',
        'Egypt',
        'El Salvador',
        'Equatorial Guinea',
        'Eritrea',
        'Estonia',
        'Ethiopia',
        'Falkland Islands (Malvinas)',
        'Faroe Islands',
        'Fiji',
        'Finland',
        'France',
        'French Guiana',
        'French Polynesia',
        'French Southern Territories',
        'Gabon',
        'Gambia',
        'Georgia',
        'Germany',
        'Ghana',
        'Gibraltar',
        'Greece',
        'Greenland',
        'Grenada',
        'Guadeloupe',
        'Guam',
        'Guatemala',
        'Guernsey',
        'Guinea',
        'Guinea-Bissau',
        'Guyana',
        'Haiti',
        'Heard Island and McDonald Islands',
        'Holy See (Vatican City State)',
        'Honduras',
        'Hong Kong',
        'Hungary',
        'Iceland',
        'India',
        'Indonesia',
        'Iran, Islamic Republic of',
        'Iraq',
        'Ireland',
        'Isle of Man',
        'Israel',
        'Italy',
        'Jamaica',
        'Japan',
        'Jersey',
        'Jordan',
        'Kazakhstan',
        'Kenya',
        'Kiribati',
        "Korea, Democratic People's Republic of",
        'Korea, Republic of',
        'Kuwait',
        'Kyrgyzstan',
        "Lao People's Democratic Republic",
        'Latvia',
        'Lebanon',
        'Lesotho',
        'Liberia',
        'Libya',
        'Liechtenstein',
        'Lithuania',
        'Luxembourg',
        'Macao',
        'Macedonia, Republic of',
        'Madagascar',
        'Malawi',
        'Malaysia',
        'Maldives',
        'Mali',
        'Malta',
        'Marshall Islands',
        'Martinique',
        'Mauritania',
        'Mauritius',
        'Mayotte',
        'Mexico',
        'Micronesia, Federated States of',
        'Moldova, Republic of',
        'Monaco',
        'Mongolia',
        'Montenegro',
        'Montserrat',
        'Morocco',
        'Mozambique',
        'Myanmar',
        'Namibia',
        'Nauru',
        'Nepal',
        'Netherlands',
        'New Caledonia',
        'New Zealand',
        'Nicaragua',
        'Niger',
        'Nigeria',
        'Niue',
        'Norfolk Island',
        'Northern Mariana Islands',
        'Norway',
        'Oman',
        'Pakistan',
        'Palau',
        'Palestine, State of',
        'Panama',
        'Papua New Guinea',
        'Paraguay',
        'Peru',
        'Philippines',
        'Pitcairn',
        'Poland',
        'Portugal',
        'Puerto Rico',
        'Qatar',
        'Republic of Kosovo',
        'Romania',
        'Russian Federation',
        'Rwanda',
        'Réunion',
        'Saint Barthélemy',
        'Saint Helena, Ascension and Tristan da Cunha',
        'Saint Kitts and Nevis',
        'Saint Lucia',
        'Saint Martin (French part)',
        'Saint Pierre and Miquelon',
        'Saint Vincent and the Grenadines',
        'Samoa',
        'San Marino',
        'Sao Tome and Principe',
        'Saudi Arabia',
        'Senegal',
        'Serbia',
        'Seychelles',
        'Sierra Leone',
        'Singapore',
        'Sint Maarten (Dutch part)',
        'Slovakia',
        'Slovenia',
        'Solomon Islands',
        'Somalia',
        'South Africa',
        'South Georgia and the South Sandwich Islands',
        'South Sudan',
        'Spain',
        'Sri Lanka',
        'Sudan',
        'Suriname',
        'Svalbard and Jan Mayen',
        'Swaziland',
        'Sweden',
        'Switzerland',
        'Syrian Arab Republic',
        'Taiwan',
        'Tajikistan',
        'Tanzania, United Republic of',
        'Thailand',
        'Timor-Leste',
        'Togo',
        'Tokelau',
        'Tonga',
        'Trinidad and Tobago',
        'Tunisia',
        'Turkey',
        'Turkmenistan',
        'Turks and Caicos Islands',
        'Tuvalu',
        'Uganda',
        'Ukraine',
        'United Arab Emirates',
        'United Kingdom',
        'United States',
        'United States Minor Outlying Islands',
        'Uruguay',
        'Uzbekistan',
        'Vanuatu',
        'Venezuela, Bolivarian Republic of',
        'Viet Nam',
        'Virgin Islands, British',
        'Virgin Islands, U.S.',
        'Wallis and Futuna',
        'Western Sahara',
        'Yemen',
        'Zambia',
        'Zimbabwe',
        'Åland Islands',
      ],
      languages: [
        {
          text: 'Afrikaans',
          id: 'af',
        },
        {
          text: 'Arabic',
          id: 'ar',
        },
        {
          text: 'Bengali',
          id: 'bn',
        },
        {
          text: 'Tibetan',
          id: 'bo',
        },
        {
          text: 'Bulgarian',
          id: 'bg',
        },
        {
          text: 'Catalan',
          id: 'ca',
        },
        {
          text: 'Czech',
          id: 'cs',
        },
        {
          text: 'Welsh',
          id: 'cy',
        },
        {
          text: 'Danish',
          id: 'da',
        },
        {
          text: 'German',
          id: 'de',
        },
        {
          text: 'Greek',
          id: 'el',
        },
        {
          text: 'English',
          id: 'en',
        },
        {
          text: 'Estonian',
          id: 'et',
        },
        {
          text: 'Basque',
          id: 'eu',
        },
        {
          text: 'Persian',
          id: 'fa',
        },
        {
          text: 'Fijian',
          id: 'fj',
        },
        {
          text: 'Finnish',
          id: 'fi',
        },
        {
          text: 'French',
          id: 'fr',
        },
        {
          text: 'Irish',
          id: 'ga',
        },
        {
          text: 'Gujarati',
          id: 'gu',
        },
        {
          text: 'Hebrew',
          id: 'he',
        },
        {
          text: 'Hindi',
          id: 'hi',
        },
        {
          text: 'Croatian',
          id: 'hr',
        },
        {
          text: 'Hungarian',
          id: 'hu',
        },
        {
          text: 'Armenian',
          id: 'hy',
        },
        {
          text: 'Indonesian',
          id: 'id',
        },
        {
          text: 'Icelandic',
          id: 'is',
        },
        {
          text: 'Italian',
          id: 'it',
        },
        {
          text: 'Japanese',
          id: 'ja',
        },
        {
          text: 'Georgian',
          id: 'ka',
        },
        {
          text: 'Central Khmer',
          id: 'km',
        },
        {
          text: 'Korean',
          id: 'ko',
        },
        {
          text: 'Latin',
          id: 'la',
        },
        {
          text: 'Latvian',
          id: 'lv',
        },
        {
          text: 'Lithuanian',
          id: 'lt',
        },
        {
          text: 'Malayalam',
          id: 'ml',
        },
        {
          text: 'Marathi',
          id: 'mr',
        },
        {
          text: 'Macedonian',
          id: 'mk',
        },
        {
          text: 'Maltese',
          id: 'mt',
        },
        {
          text: 'Mongolian',
          id: 'mn',
        },
        {
          text: 'Maori',
          id: 'mi',
        },
        {
          text: 'Malay',
          id: 'ms',
        },
        {
          text: 'Nepali',
          id: 'ne',
        },
        {
          text: 'Dutch',
          id: 'nl',
        },
        {
          text: 'Norwegian',
          id: 'no',
        },
        {
          text: 'Panjabi/Punjabi',
          id: 'pa',
        },
        {
          text: 'Polish',
          id: 'pl',
        },
        {
          text: 'Portuguese',
          id: 'pt',
        },
        {
          text: 'Quechua',
          id: 'qu',
        },
        {
          text: 'Romanian',
          id: 'ro',
        },
        {
          text: 'Russian',
          id: 'ru',
        },
        {
          text: 'Slovak',
          id: 'sk',
        },
        {
          text: 'Slovenian',
          id: 'sl',
        },
        {
          text: 'Samoan',
          id: 'sm',
        },
        {
          text: 'Spanish',
          id: 'es',
        },
        {
          text: 'Albanian',
          id: 'sq',
        },
        {
          text: 'Serbian',
          id: 'sr',
        },
        {
          text: 'Swahili',
          id: 'sw',
        },
        {
          text: 'Swedish',
          id: 'sv',
        },
        {
          text: 'Tamil',
          id: 'ta',
        },
        {
          text: 'Tatar',
          id: 'tt',
        },
        {
          text: 'Telugu',
          id: 'te',
        },
        {
          text: 'Thai',
          id: 'th',
        },
        {
          text: 'Tongan',
          id: 'to',
        },
        {
          text: 'Turkish',
          id: 'tr',
        },
        {
          text: 'Ukrainian',
          id: 'uk',
        },
        {
          text: 'Urdu',
          id: 'ur',
        },
        {
          text: 'Uzbek',
          id: 'uz',
        },
        {
          text: 'Vietnamese',
          id: 'vi',
        },
        {
          text: 'Xhosa',
          id: 'xh',
        },
        {
          text: 'Mandarin Chinese',
          id: 'zh',
        },
        {
          text: 'Bosnian',
          id: 'bs',
        },
        {
          text: 'Kannada',
          id: 'kn',
        },
        {
          text: 'Kazakh',
          id: 'kk',
        },
        {
          text: 'Cantonese Chinese',
          id: 'yue',
        },
      ],
      proficiency_levels: {
        '1': '1. Elementary',
        '2': '2. Limited working proficiency',
        '3': '3. Professional working proficiency',
        '4': '4. Full professional proficiency',
        '5': '5. Native or Bilingual proficiency',
      },
      availability: {
        Immediate: 0,
        '1 Week': 1,
        '2 Weeks': 2,
        '3 Weeks': 3,
        '4 Weeks': 4,
        '5 Weeks': 5,
        '6 Weeks': 6,
        '7 Weeks': 7,
        '8 Weeks': 8,
        '9 Weeks': 9,
        '10 Weeks': 10,
        '11 Weeks': 11,
        '12 Weeks': 12,
        '13 Weeks': 13,
        '14 Weeks': 14,
        '15 Weeks': 15,
        '16 Weeks': 16,
        '17 Weeks': 17,
        '18 Weeks': 18,
        '19 Weeks': 19,
        '20 Weeks': 20,
        '21 Weeks': 21,
        '22 Weeks': 22,
        '23 Weeks': 23,
        '24 Weeks': 24,
        'Over 24 Weeks': 25,
        "I'm just looking around": 26,
      },
      experience_years: [
        ['< 1 Year', 0],
        ['1 Year', 1],
        ['2 Years', 2],
        ['3 Years', 3],
        ['4 Years', 4],
        ['5 Years', 5],
        ['6 Years', 6],
        ['7 Years', 7],
        ['8 Years', 8],
        ['9 Years', 9],
        ['10+ Years', 10],
      ],
      contact_preferences: {
        Phone: 0,
        WhatsApp: 1,
        'E-Mail': 2,
      },
      categories: [
        {
          id: 6,
          name: 'Data Scientist',
          created_at: '2014-09-24T19:57:36.099Z',
          updated_at: '2015-11-26T15:34:47.416Z',
          slug: 'data-scientist',
          is_tech: true,
          group: null,
          disabled: false,
          emoji_symbol: null,
        },
        {
          id: 8,
          name: 'Product/Project Management',
          created_at: '2014-09-24T19:57:36.099Z',
          updated_at: '2015-11-26T15:34:47.449Z',
          slug: 'product-project-management',
          is_tech: true,
          group: null,
          disabled: false,
          emoji_symbol: null,
        },
        {
          id: 1,
          name: 'Full-stack Developer',
          created_at: '2014-09-24T19:57:36.099Z',
          updated_at: '2015-11-26T15:34:47.335Z',
          slug: 'full-stack-developer',
          is_tech: true,
          group: 'Development',
          disabled: false,
          emoji_symbol: null,
        },
        {
          id: 2,
          name: 'Front-end Developer',
          created_at: '2014-09-24T19:57:36.099Z',
          updated_at: '2015-11-26T15:34:47.367Z',
          slug: 'front-end-developer',
          is_tech: true,
          group: 'Development',
          disabled: false,
          emoji_symbol: null,
        },
        {
          id: 3,
          name: 'Back-end Developer',
          created_at: '2014-09-24T19:57:36.099Z',
          updated_at: '2015-11-26T15:34:47.383Z',
          slug: 'back-end-developer',
          is_tech: true,
          group: 'Development',
          disabled: false,
          emoji_symbol: null,
        },
        {
          id: 5,
          name: 'Mobile Apps Developer',
          created_at: '2014-09-24T19:57:36.099Z',
          updated_at: '2015-11-26T15:34:47.399Z',
          slug: 'mobile-apps-developer',
          is_tech: true,
          group: 'Development',
          disabled: false,
          emoji_symbol: null,
        },
        {
          id: 13,
          name: 'DevOps / Sysadmin',
          created_at: '2014-09-24T19:57:36.099Z',
          updated_at: '2015-11-26T15:34:47.506Z',
          slug: 'devops-sysadmin',
          is_tech: true,
          group: 'Development',
          disabled: false,
          emoji_symbol: null,
        },
        {
          id: 23,
          name: 'QA / Testing',
          created_at: '2015-09-04T15:44:19.876Z',
          updated_at: '2015-11-26T15:34:47.610Z',
          slug: 'qa-testing',
          is_tech: true,
          group: 'Development',
          disabled: false,
          emoji_symbol: null,
        },
        {
          id: 20,
          name: 'Maintenance & Support',
          created_at: '2014-10-17T09:47:32.987Z',
          updated_at: '2015-11-26T15:34:47.559Z',
          slug: 'maintenance-support',
          is_tech: false,
          group: 'Development',
          disabled: false,
          emoji_symbol: null,
        },
        {
          id: 7,
          name: 'UX / UI Designer',
          created_at: '2014-09-24T19:57:36.099Z',
          updated_at: '2016-11-16T09:49:21.182Z',
          slug: 'ux-ui-designer',
          is_tech: true,
          group: 'Design',
          disabled: false,
          emoji_symbol: null,
        },
        {
          id: 24,
          name: 'Computer & Network Security',
          created_at: '2016-12-15T15:12:37.042Z',
          updated_at: '2016-12-15T15:12:37.042Z',
          slug: 'computer-network-security',
          is_tech: true,
          group: 'Development',
          disabled: false,
          emoji_symbol: null,
        },
      ],
      urls: {
        person_institutions_url: '/person_institutions',
        person_institutions_destroy_url: '/person_institutions/~id',
        edit_profile_url: '/profile/edit',
        websites_url: '/users/websites',
        websites_destroy_url: '/users/websites/~id',
        languages_url: '/users/language_proficiencies',
        languages_destroy_url: '/users/language_proficiencies/~id',
        cv_url: '/profile/resume',
        show_cv_url: '/resume',
      },
      professionalExperienceUrls: {
        save: '/professional_experiences?person_id=34711',
        update: '/professional_experiences/~id',
      },
      googleMapsApiKey: 'AIzaSyAvWL6aCwt0FStcFFhh2Ja8PQ8MNpYADtQ',
      maximumNumberOfSkills: 40,
      proposedTags: [],
      networks: [
        {
          id: 1,
          name: 'LinkedIn',
          base_url: 'https://linkedin.com/',
          value: 'linkedin',
        },
        {
          id: 2,
          name: 'GitHub',
          base_url: 'https://github.com/',
          value: 'github',
        },
        {
          id: 3,
          name: 'Bitbucket',
          base_url: 'https://bitbucket.org/',
          value: 'bitbucket',
        },
        {
          id: 4,
          name: 'Stack Overflow',
          base_url: 'https://www.stackoverflow.com/',
          value: 'stackoverflow',
        },
        {
          id: 5,
          name: 'Dribbble',
          base_url: 'https://www.dribbble.com/',
          value: 'dribbble',
        },
        {
          id: 6,
          name: 'Behance',
          base_url: 'http://www.behance.net/',
          value: 'behance',
        },
        {
          id: 7,
          name: 'Facebook',
          base_url: 'https://facebook.com/',
          value: 'facebook',
        },
        {
          id: 8,
          name: 'Twitter',
          base_url: 'https://twitter.com/',
          value: 'twitter',
        },
        {
          id: 12,
          name: 'Instagram',
          base_url: 'https://instagram.com/',
          value: 'instagram',
        },
        {
          id: 9,
          name: 'Blog',
          base_url: 'http://',
          value: 'blog',
        },
        {
          id: 10,
          name: 'Personal website',
          base_url: 'http://',
          value: 'personal',
        },
        {
          id: 11,
          name: 'Other',
          base_url: 'http://',
          value: 'other',
        },
      ],
      public: null,
      privacyPath: '/privacy',

      errors: {
        skills: {},
      },
    },
  },
};

export default talentProfileDummy;
