import * as React from 'react';
import JobCard from './JobCard';
import { mount } from 'enzyme';
import { JobCardContextInterface, JobCardCtx } from './JobCardContext';
import { act } from '@testing-library/react';

const company = require('__fixtures__/company_dummy');
const job = require('__fixtures__/job_dummy');
const tag = require('__fixtures__/tag_dummy');
const user = require('__fixtures__/talent_profile');

window.Routes = {
  company: () => '',
  company_job_ad: () => '',
  join: () => '',
};

window.open = jest.fn();

afterEach(() => {
  jest.restoreAllMocks();
});

const JobCardContext: JobCardContextInterface = {
  applied: false,
  bookmark: {
    id: '1',
    jobId: job.id,
  },
  job,
  key: job.id,
  user,
  isHottest: true,
  infoTags: ['tag1', 'tag2'],
};

describe('JobCard render', () => {
  it('shallow renders without crashing', () => {
    job.company = company;
    job.tags = [tag, tag];

    const component = mount(
      <JobCardCtx.Provider key={job.id} value={JobCardContext}>
        <JobCard />,
      </JobCardCtx.Provider>
    );

    expect(component.exists()).toBe(true);
  });

  it('renders all default components', () => {
    job.company = company;
    job.tags = [tag, tag];

    const component = mount(
      <JobCardCtx.Provider key={job.id} value={JobCardContext}>
        <JobCard />,
      </JobCardCtx.Provider>
    );

    expect(component.find('Logo')).toHaveLength(1);
    expect(component.find('JobSkills')).toHaveLength(1);
    expect(component.find('Company')).toHaveLength(1);
    expect(component.find('SubscribeButton')).toHaveLength(1);
  });

  it('shows a badge', () => {
    job.isNew = true;

    const component = mount(
      <JobCardCtx.Provider key={job.id} value={JobCardContext}>
        <JobCard />,
      </JobCardCtx.Provider>
    );

    expect(component.find('Badge')).toHaveLength(1);
  });
});

describe('Open job detail page', () => {
  it('opens job page on card click on desktop', () => {
    const component = mount(
      <JobCardCtx.Provider key={job.id} value={JobCardContext}>
        <JobCard />,
      </JobCardCtx.Provider>
    );

    component.find('Title').simulate('click');

    expect(window.open).toHaveBeenCalled();
  });

  it('Shows a handshake badge', () => {
    JobCardContext.handshakes = {
      [job.id]: 10,
    };

    const component = mount(
      <JobCardCtx.Provider key={job.id} value={JobCardContext}>
        <JobCard />,
      </JobCardCtx.Provider>
    );

    expect(component.find('Badge').text()).toBe('Handshake Expires in 10 days');
  });

  // Skip this test for now, the mobile function is not firing up when the window is resized
  it.skip('does not open job page on card click on mobile', () => {
    const component = mount(
      <JobCardCtx.Provider key={job.id} value={JobCardContext}>
        <JobCard />,
      </JobCardCtx.Provider>
    );

    Object.defineProperty(window, 'innerWidth', {
      writable: true,
      configurable: true,
      value: 150,
    });

    act(() => {
      window.dispatchEvent(new Event('resize'));
    });

    component.find('Title').simulate('click');

    expect(window.open).not.toHaveBeenCalled();
  });
});
