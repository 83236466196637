import React from 'react';
import InvoicePage from './invoice_page';
import { render } from '@testing-library/react';

window.Routes = {
  dashboard_placement: jest.fn(),
  edit_billing_info: jest.fn(),
  new_billing_info: jest.fn(),
};

describe('InvoicePage', () => {
  it('renders the invoice details', () => {
    const data = {
      companyName: 'FooCorp',
      documentUrl: 'http://example.com',
      endDate: '2015-01-01',
      invoiceMonth: 'January 2015',
      placementId: '1337',
      projectName: 'The Foo Project',
      notes: 'Some notes',
      number: 'number123',
      startDate: '2015-01-31',
      valueWithVat: '€121',
      valueWithoutVat: '€120',
      workedDays: 22,
    };

    const { queryByText } = render(<InvoicePage {...data} />);
    for (const [key, value] of Object.entries(data)) {
      if (!['documentUrl', 'placementId'].includes(key)) {
        expect(queryByText(value.toString(), { exact: false })).toBeTruthy();
      }
    }
  });
});
