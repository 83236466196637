import * as React from 'react';
import '../../../lib/globals';
import { Messageable } from './models';

export interface Props {
  messageable: Messageable;
  userType: string;
  messagesUrl: Function;
  handleNewConversation: Function;
  handleUpdateNotification: Function;
  mini: boolean;
}

class MessageForm extends React.Component<Props> {
  private input: React.RefObject<HTMLTextAreaElement>;
  private form: React.RefObject<HTMLFormElement>;

  constructor(props: Props) {
    super(props);
    this.handleSubmitMessage = this.handleSubmitMessage.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.input = React.createRef();
    this.form = React.createRef();
  }

  handleSubmitMessage(event) {
    event.preventDefault();

    const textarea: HTMLTextAreaElement = this.input.current;
    const message = textarea.value;
    const form: HTMLFormElement = this.form.current;

    if (message && message.replace(/\s/g, '')) {
      if (this.props.messageable.type === 'conversation') {
        $.ajax({
          type: 'PUT',
          url: this.props.messagesUrl(this.props.messageable.id),
          data: { body: message },
        }).done(this.props.handleUpdateNotification(this.props.messageable.id));
      } else {
        this.props.handleNewConversation(this.props.messageable, message);
      }
    }

    textarea.value = '';
    if (!this.props.mini) {
      form.style.flexBasis = '72px';
      textarea.style.height = '42px';
    }
  }

  handleKeyPress(_event) {
    const form: HTMLFormElement = this.form.current;
    const textarea: HTMLTextAreaElement = this.input.current;

    if ((textarea as HTMLTextAreaElement).value) {
      textarea.style.height = String(textarea.scrollHeight) + 'px';
    } else {
      textarea.style.height = '42px';
    }

    let basis;
    if (!this.props.mini) {
      basis = textarea.offsetHeight + 28 < 84 ? 84 : textarea.offsetHeight + 28;
    } else {
      basis = textarea.offsetHeight < 42 ? 42 : textarea.offsetHeight;
    }
    form.style.flexBasis = String(basis) + 'px';
  }

  render() {
    let buttonClasses;
    if (this.props.userType === 'CompanyUser') {
      buttonClasses = 'lj-button lj-button--ripePlum lj-button--xSmall';
    } else {
      buttonClasses = 'lj-button lj-button--tuftsBlue lj-button--xSmall';
    }

    return (
      <form className='lj-message-form' onSubmit={this.handleSubmitMessage} ref={this.form}>
        <textarea
          placeholder='Type your message here…'
          className='text required autoExpand lj-input lj-input--mid lj-input--block u-marginBottom--small'
          autoFocus={true}
          onChange={this.handleKeyPress}
          ref={this.input}
        />
        <button id='send_message' className={buttonClasses}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            id='send-paper-plane'
          >
            <path
              fillRule='nonzero'
              d='M0 8.171l7.532 4.295L11.826 20 20 0 0 8.171zm3.289.214l12.345-5.05-7.693 7.695-4.652-2.645zm8.32 8.325l-2.644-4.653 7.693-7.695L11.61 16.71z'
            />
          </svg>
          <div className='text'>Send</div>
        </button>

        <span className='helper-text'>
          Supports
          <span className='underline'>Markdown</span>
          <span className='italic'>*italic*</span>
          <span className='title'># Big Title</span>
          <span className='bold'>**bold**</span>
          <span className='strike'>~~strike~~</span>
        </span>
      </form>
    );
  }
}

export default MessageForm;
