import React from 'react';
import { mount } from 'enzyme';
import AddGasModal from './AddGasModal';
import Text from '../../../form/text/text';
import Button from 'lj_shared/button/button';
import Modal from '../../../general/modal/modal';

const createProps = () => ({
  application_id: 1,
});

let props;
let mounted;

describe('Add GAS modal', () => {
  beforeEach(() => {
    props = createProps();
    mounted = mount(<AddGasModal {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(mounted.exists()).toBe(true);
  });

  it('has a Text', () => {
    expect(mounted.find(Text)).toHaveLength(1);
  });

  it('has 1 Button', () => {
    expect(mounted.find(Button)).toHaveLength(1);
  });

  it('has a Modal', () => {
    expect(mounted.find(Modal)).toHaveLength(1);
  });
});
