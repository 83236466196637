import React, { useEffect, useRef, useState } from 'react';
import { JobAd } from '__models__/job_ad';
import Button from 'lj_shared/button/button';
import AcceptAndRejectHandshake from 'components/handshakes/accept_and_reject_handshake';
import SuggestionCard from 'components/job_suggestions/suggestion_card';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import { getJobApplication } from 'lib/application';
import FooterOverlay from 'components/footerOverlay/FooterOverlay';
import FooterBanner from 'components/footerBanner/FooterBanner';
import { formatDate } from 'lib/format_date';

const styles = require('./ApplyButton.module.scss');

interface Props {
  applications: any;
  buttonSize?: string;
  cancelReasons?: any;
  currentUser: any;
  fixedHeader: boolean;
  handshake: any;
  jobAd: JobAd;
  jobSuggestion: any;
  referralId: any;
  style?: string;
  jobSearchFiltersAb?: string;
  shouldHide?: boolean;
  containerRef?: React.RefObject<HTMLDivElement>;
  companyName?: string;
  companySlug?: string;
}

const ApplyButton = (props: Props) => {
  const [endOfPage, setEndOfPage] = useState(false);
  const application = getJobApplication(props.applications, props.jobAd);
  const applyButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
      setEndOfPage(true);
    } else {
      setEndOfPage(false);
    }
  };

  const handshakeJobAd = {
    title: props.jobAd.attributes.title,
    id: parseInt(props.jobAd.id, 10),
  };

  const applyUrl: string = window.Routes.job_ad_new_application(props.jobAd.id, {
    referral: props.referralId,
    job_search_filters_ab: props.jobSearchFiltersAb,
    app_id: application?.id,
  });

  const applyNowButton = (currentLabel: string) => {
    return {
      label: currentLabel,
      url: applyUrl,
    };
  };

  const buttonLabelAndUrl = () => {
    if (props.currentUser?.type === 'TalentUser' && application) {
      if (application?.inDraftStates) {
        return applyNowButton('Continue Application');
      } else if (application?.state >= 0) {
        return { label: 'Already applied', url: null };
      } else {
        return applyNowButton('Apply Now');
      }
    } else {
      return applyNowButton('Apply Now');
    }
  };

  const renderButton = (ref: React.RefObject<HTMLDivElement> = null) =>
    props.currentUser?.type !== 'CompanyUser' && (
      <div ref={ref} className={styles.buttonWrapper}>
        <Button
          buttonColor={!props.currentUser || buttonLabelAndUrl().url ? 'tuftsBlue' : 'silverSand'}
          buttonSize={props.buttonSize || 'large'}
          otherClasses={props.style}
          buttonUrl={
            !props.currentUser
              ? window.Routes.new_talent_user_registration({
                  apply_for: props.jobAd.id,
                  referral: props.referralId,
                })
              : buttonLabelAndUrl().url
          }
        >
          {!props.currentUser ? 'Sign up to apply' : buttonLabelAndUrl().label}
        </Button>
      </div>
    );

  const applyButtonFooterBanner = () => (
    <FooterBanner
      title={
        <a href={props.companySlug && window.Routes.company(props.companySlug)}>
          {props.companyName}
        </a>
      }
      subtitle={props.jobAd.attributes.title}
      primaryButton={renderButton()}
      secondaryButton={
        <Button
          buttonColor={'tuftsBlue'}
          buttonSize={'small'}
          otherClasses={'lj-button--border'}
          buttonUrl={window.Routes.job_ads()}
        >
          See other jobs
        </Button>
      }
      secondaryButtonText={'See other jobs'}
    />
  );

  const handshakeFooterBanner = () => {
    const title = (
      <div className='custom-title'>
        <span className='separator'>-</span>
        <a href={props.companySlug && window.Routes.company(props.companySlug)}>
          {props.companyName}
        </a>
      </div>
    );

    const getHandshakeBody = () => {
      const body = (action: string) => (
        <div className='custom-body'>
          <span>{props.jobAd.attributes.title}</span>
          <span>{`On ${props.handshake.data.attributes.created_at}, was ${action} Handshake.`}</span>
        </div>
      );

      switch (props.handshake.data.attributes.state) {
        case 'unlocked':
          return body('accepted this');
        case 'rejected':
          return body('requested a');
        default:
          return <div className='custom-body'>{props.jobAd.attributes.title}</div>;
      }
    };

    return (
      <AcceptAndRejectHandshake
        cvs={props.handshake.data.attributes.cvs}
        handshake={props.handshake}
        handshake_job_ad={handshakeJobAd}
        cancelReasons={props.cancelReasons}
        hideBody={props.fixedHeader}
        title={title}
        body={getHandshakeBody()}
        customCss={styles.handshakeFooterBanner}
        secondaryButton={
          <Button
            buttonColor={'tuftsBlue'}
            buttonSize={'small'}
            otherClasses={'lj-button--border secondary-button'}
            buttonUrl={window.Routes.job_ads()}
          >
            See other jobs
          </Button>
        }
      />
    );
  };

  const suggestionFooterBanner = () => {
    const title = (
      <div className='custom-title'>
        <span className='separator'>-</span>
        <a href={props.companySlug && window.Routes.company(props.companySlug)}>
          {props.companyName}
        </a>
      </div>
    );

    const getSuggestionBody = () => {
      const body = (date: string, action: string) => (
        <div className='custom-body'>
          <span>{props.jobAd.attributes.title}</span>
          <span>{`On ${date}, you ${action} Suggestion.`}</span>
        </div>
      );

      switch (props.jobSuggestion.state) {
        case 'accepted':
          if (application) {
            return body(formatDate(application.submittedAt), 'applied for this');
          }
          break;
        case 'not_interested':
          return body(formatDate(props.jobSuggestion.updatedAt), 'received this');
        default:
          return <div className='custom-body'>{props.jobAd.attributes.title}</div>;
      }
    };

    return (
      <SuggestionCard
        application={application}
        hideBody={props.fixedHeader}
        jobSuggestion={props.jobSuggestion}
        jobAd={props.jobAd}
        title={title}
        body={getSuggestionBody()}
        customCss={styles.handshakeFooterBanner}
        secondaryButton={
          <Button
            buttonColor={'tuftsBlue'}
            buttonSize={'small'}
            otherClasses={'lj-button--border secondary-button'}
            buttonUrl={window.Routes.job_ads()}
          >
            See other jobs
          </Button>
        }
      />
    );
  };

  const renderSection = () => {
    if (
      props.handshake &&
      props.handshake.data.attributes.state === 'closed' &&
      !props.jobSuggestion
    ) {
      return (
        <>
          {renderButton()}
          <AcceptAndRejectHandshake
            cvs={props.handshake.data.attributes.cvs}
            handshake={props.handshake}
            handshake_job_ad={handshakeJobAd}
            cancelReasons={props.cancelReasons}
            hideBody={props.fixedHeader}
          />
        </>
      );
    } else if (props.handshake && props.handshake.data.attributes.state !== 'closed') {
      return (
        <>
          <AcceptAndRejectHandshake
            cvs={props.handshake.data.attributes.cvs}
            handshake={props.handshake}
            handshake_job_ad={handshakeJobAd}
            cancelReasons={props.cancelReasons}
            hideBody={props.fixedHeader}
            cardRef={applyButtonRef}
          />
          <FooterOverlay
            key='handshakeBanner'
            upLimit={applyButtonRef}
            downLimit={props.containerRef}
            body={handshakeFooterBanner()}
            customCss={styles.handshakeFooterOverlay}
          />
        </>
      );
    } else if (props.jobSuggestion) {
      return (
        <>
          <SuggestionCard
            application={application}
            hideBody={props.fixedHeader}
            jobSuggestion={props.jobSuggestion}
            cardRef={applyButtonRef}
            jobAd={props.jobAd}
          />
          <FooterOverlay
            key='sugestionBanner'
            upLimit={applyButtonRef}
            downLimit={props.containerRef}
            body={suggestionFooterBanner()}
            customCss={styles.handshakeFooterOverlay}
          />
        </>
      );
    } else {
      return (
        <>
          {renderButton(applyButtonRef)}
          <FooterOverlay
            key='handshakeBanner'
            upLimit={applyButtonRef}
            downLimit={props.containerRef}
            body={applyButtonFooterBanner()}
          />
        </>
      );
    }
  };

  return (
    <div
      className={classNames(styles.applyButtonWrapper, {
        [styles.applyButtonHandshakeFixedHeader]:
          props.fixedHeader &&
          ((props.handshake && props.jobSuggestion) || props.handshake) &&
          !endOfPage,
        [styles.applyButtonSuggestionFixedHeader]:
          props.fixedHeader && props.jobSuggestion && !props.handshake && !endOfPage,
        [styles.applyButtonHide]:
          props.fixedHeader && (props.handshake || props.jobSuggestion) && endOfPage,
        [styles.applyButtonMobile]: isMobile,
        [styles.shouldHide]: props.shouldHide,
      })}
    >
      {renderSection()}
    </div>
  );
};

export default ApplyButton;
