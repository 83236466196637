import * as React from 'react';
import './application_location.scss';

import { Application } from '../../__models__/application';
import ApplicationCard from './application_card/application_card';
import InlineIcon from 'components/general/inline_icon/inline_icon';

const markerIcon = require('iconic/map-marker.svg');
const warningIcon = require('iconic/warning.svg');

export interface Props {
  application: Application;
}

const ApplicationLocation = (props: Props) => {
  const { location, citizenships } = props.application.attributes.person.attributes;
  const { has_work_permit } = props.application.attributes;
  return (
    <ApplicationCard title='Location Details' icon={markerIcon} alt='marker'>
      <div className='loc-parent'>
        <div className='loc-child'>
          <div className='loc-rowH'>Based in</div>
          <div className='loc-row'>
            <InlineIcon path={markerIcon} alt='marker' />
            {location}
          </div>
        </div>

        <div className='loc-childTwo'>
          <div className='loc-rowH'>Citizenship(s)</div>
          <div className='loc-row'>{citizenships}</div>
        </div>

        <div className='loc-childTwo'>
          <div className='loc-rowH'>Workpermit</div>
          <div className='loc-row'>{has_work_permit}</div>
        </div>
      </div>
      {props.application.attributes.accepted_different_timezone && (
        <div className='loc-timezone'>
          <InlineIcon path={warningIcon} alt='marker' /> This candidate's time zone is outside of
          the range you specified for the job, but is willing to work during the hours of your time
          zone.
        </div>
      )}
    </ApplicationCard>
  );
};

export default ApplicationLocation;
