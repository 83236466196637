import classNames from 'classnames';
import Icon from 'components/general/icon/icon';
import React from 'react';
import FormError from '../form_error/form_error';
const styles = require('./checkbox.module.scss');

export interface Props {
  checked?: boolean;
  children?: any;
  className?: string;
  color?: string;
  controlled?: boolean;
  disabled?: boolean;
  error?: string;
  id?: string;
  inputRef?: any;
  label?: string | JSX.Element;
  name?: string;
  onChange?: any;
  value?: string;
}

export default class Checkbox extends React.Component<Props, {}> {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const value = event.target.checked;
    this.props.onChange(event, this.props.name, value);
  }

  render() {
    const { color, label } = this.props;
    const checkboxStyles = classNames({
      [styles.checkbox]: true,
      [styles[color]]: isValidColor(color),
    });

    return (
      <div className={classNames(styles.container, this.props.className)}>
        {this.props.controlled ? (
          <input
            checked={this.props.checked}
            className={styles.input}
            disabled={this.props.disabled}
            id={this.props.id}
            name={this.props.name}
            onChange={this.props.onChange && this.handleChange}
            ref={this.props.inputRef}
            type='checkbox'
            value={this.props.value}
          />
        ) : (
          <input
            className={styles.input}
            defaultChecked={this.props.checked}
            disabled={this.props.disabled}
            id={this.props.id}
            name={this.props.name}
            onClick={this.props.onChange && this.handleChange}
            ref={this.props.inputRef}
            type='checkbox'
            value={this.props.value}
          />
        )}
        <span className={checkboxStyles}>
          <Icon className={styles.checkIcon} name={'checkSquare'} />
        </span>
        {label && (
          <label className={classNames('checkboxLabel', styles.label)} htmlFor={this.props.id}>
            {label}
          </label>
        )}
        {this.props.children}
        {this.props.error && <FormError text={this.props.error} />}
      </div>
    );
  }
}

function isValidColor(name) {
  return (
    name === 'ripePlum' ||
    name === 'puertoRico' ||
    name === 'puertoRicoMidDark' ||
    name === 'tuftsBlue' ||
    name === 'silverSand'
  );
}
