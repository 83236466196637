// Buttons!
//

import * as React from 'react';

interface Props {
  buttonColor?: string;
  buttonSize?: string;
  buttonType?: string;
  buttonUrl?: string;
  onClick?: (event: any) => any;
  otherClasses?: string;
  otherClassesOnly?: boolean;
  otherId?: string;
  children?: any;
  isButton?: boolean;
  isRound?: boolean;
  dataAttribute?: string;
  dataMethod?: string;
  dataRemote?: boolean;
  targetBlank?: boolean;
  disabled?: boolean;
  extraStyle?: React.CSSProperties;
  type?: 'button' | 'submit' | 'reset';
}

export default class Button extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  classNameConstructor(props) {
    let classy = 'lj-button';

    if (!props.otherClassesOnly) {
      if (props.buttonColor) {
        classy += ` lj-button--${props.buttonColor}`;
      } else {
        classy += ' lj-button--puertoRico';
      }

      if (props.buttonSize) {
        classy += ` lj-button--${props.buttonSize}`;
      } else {
        classy += ' lj-button--mid';
      }

      if (props.buttonType) {
        classy += ` lj-button--${props.buttonType}`;
      }

      if (props.isRound) {
        classy += ' lj-button--round';
      }
    }

    if (props.otherClasses) {
      classy += ' ' + props.otherClasses;
    }

    return classy;
  }

  render() {
    if (this.props.isButton) {
      return (
        <button
          type={this.props.type}
          style={this.props.extraStyle}
          className={this.classNameConstructor(this.props)}
          onClick={this.props.onClick}
          id={this.props.otherId}
          data-attribute={this.props.dataAttribute}
          data-method={this.props.dataMethod}
          data-remote={this.props.dataRemote}
          disabled={this.props.disabled}
        >
          {this.props.children}
        </button>
      );
    } else {
      return (
        <a
          style={this.props.extraStyle}
          href={this.props.buttonUrl}
          className={this.classNameConstructor(this.props)}
          id={this.props.otherId}
          data-attribute={this.props.dataAttribute}
          data-method={this.props.dataMethod}
          data-remote={this.props.dataRemote}
          onClick={this.props.onClick}
          target={this.props.targetBlank ? '_blank' : '_self'}
          rel='noreferrer'
        >
          {this.props.children}
        </a>
      );
    }
  }
}
