import React from 'react';
import classNames from 'classnames';
const styles = require('./business_general_pricing_card.module.scss');

interface Props {
  businessEmail: string;
  pricingPlanUrl: string;
}

export default function BusinessGeneralPricingCard(props: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.boxCompany}>
        <div className={styles.middleContainer}>
          <div className={classNames(styles.description, styles.descriptionBusinessGeneral)}>
            <p className={styles.descriptionBusinessGeneralText}>
              To know more about our pricing plans, check our&nbsp;
              <a
                href={props.pricingPlanUrl}
                className={styles.companySettingsLink}
                target='_blank'
                rel='noreferrer'
              >
                comparison page
              </a>
              .
            </p>
            <span>
              If you want to change, extend or have any other question about your plan,&nbsp;
              <a
                href={`mailto:${props.businessEmail}`}
                className={styles.companySettingsLink}
                target='_blank'
                rel='noreferrer'
              >
                contact us
              </a>
              .
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
