// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`PostJobModal should render correctly 1`] = `
<a
  className="lj-button lj-button--puertoRico lj-button--mid ld-button ld-small-button ld-blue-button ld-border-button u-marginRight--small"
  onClick={[Function]}
  rel="noreferrer"
  style={
    Object {
      "height": "auto",
    }
  }
  target="_self"
>
  post job
</a>
`;
