import React from 'react';
import Card from 'components/general/card/card';
import CardHeader, { HeaderType } from 'components/general/card/card_header';
import HorizontalCardContent from 'components/general/card/horizontal_card_content';
import Bio from 'components/general/card/bio';
import CandidateSkillsInput from 'components/general/skills_input/candidate_skills_input';
import CardAvatarContainer from 'components/general/card/cardAvatar/CardAvatarContainer';
import { isMobile } from 'react-device-detect';
import CandidateSkillsDisplay from 'components/general/skills_input/candidateSkillsDisplay/CandidateSkillsDisplay';
import LabelWithStarIcon from '../LabelWithStarIcon';

const styles = require('./MainCardContainer.module.scss');

interface Props {
  openModal: Function;
  bioProps: any;
  cardHeaderProps: any;
  skillsProps: any;
  avatarProps: any;
  handshakeProfile: boolean;
  publicProfilePage: boolean;
  handleClick?: Function;
  incompleteFields?: { name: string; star: boolean }[];
}

export default function MainCardContainer(props: Props) {
  const handshakeProfile = props.handshakeProfile;
  const publicProfilePage = props.publicProfilePage;
  const incompleteFields = props.incompleteFields;
  const avatarProperties = {
    ...props.avatarProps,
    handshakeProfile,
    publicProfilePage,
    incompleteFields,
  };
  const renderMobile = () => (
    <>
      <CardHeader
        {...props.cardHeaderProps}
        iconAction={'edit'}
        ljColor={props.handshakeProfile ? 'ripePlum' : 'tuftsBlue'}
        openModal={props.openModal}
        public={props.handshakeProfile}
        publicProfilePage={props.publicProfilePage}
        handleClick={props.handleClick}
      />
      <Bio
        {...props.bioProps}
        avatarProperties={avatarProperties}
        headerKey={props.cardHeaderProps.headerKey}
        ljColor={props.handshakeProfile ? 'ripePlum' : 'tuftsBlue'}
        openModal={props.openModal}
        publicProfilePage={props.publicProfilePage}
        mobile
      />
    </>
  );

  const renderDesktop = () => (
    <>
      <CardAvatarContainer {...avatarProperties} />
      <div className={styles.rightContainer}>
        <CardHeader
          {...props.cardHeaderProps}
          iconAction={'edit'}
          ljColor={props.handshakeProfile ? 'ripePlum' : 'tuftsBlue'}
          openModal={props.openModal}
          public={props.handshakeProfile}
          publicProfilePage={props.publicProfilePage}
          handleClick={props.handleClick}
        />
        <HorizontalCardContent>
          <Bio {...props.bioProps} showAvatar={false} />
        </HorizontalCardContent>
      </div>
    </>
  );

  const color = handshakeProfile ? 'ripePlum' : 'tuftsBlue';
  const renderSkillsSection = skillsProps =>
    props.skillsProps.readOnly ? (
      <CandidateSkillsDisplay color={color} skills={skillsProps.skills} />
    ) : (
      <CandidateSkillsInput {...skillsProps} />
    );
  return (
    <Card>
      <div className={styles.containerWithAvatar}>
        {isMobile && renderMobile()}
        {!isMobile && renderDesktop()}
      </div>
      <HorizontalCardContent>
        <CardHeader
          header={
            handshakeProfile || publicProfilePage ? (
              'Skills'
            ) : (
              <LabelWithStarIcon label='Skills' value={props.skillsProps.skills?.length > 0} />
            )
          }
          headerKey={'skillsHeader'}
          public={true}
          type={HeaderType.Regular}
          iconAction='add'
        />
        {renderSkillsSection(props.skillsProps)}
      </HorizontalCardContent>
    </Card>
  );
}
