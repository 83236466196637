import React from 'react';
import { User } from '__models__/gql/all';
import JobCard from 'components/jobCard/JobCard';
import { JobCardContextInterface, JobCardCtx } from 'components/jobCard/JobCardContext';
import JobListUtils from './JobListUtils';

type JsonBookmark = [number, number];
interface Props {
  header?: JSX.Element;
  footer?: JSX.Element;
  paginationControl?: JSX.Element;
  applications?: number[];
  bookmarks?: JsonBookmark[];
  handshakes?: { [key: number]: number };
  jsonJobs: any[];
  user?: Partial<User>;
  isHottest?: boolean;
  showInfoTags?: boolean;
}

const JobList = (props: Props) => {
  const { convertJobAdToJob, createBookmarks, getInfoTags } = JobListUtils();
  const {
    header,
    footer,
    paginationControl,
    applications,
    bookmarks,
    handshakes,
    jsonJobs,
    user,
    showInfoTags,
    isHottest,
  } = props;

  return (
    <>
      {header}
      {jsonJobs?.map(jsonJob => {
        const convertedJob = convertJobAdToJob(jsonJob);

        const JobCardContext: JobCardContextInterface = {
          applied: applications?.includes(parseInt(convertedJob.id, 10)),
          bookmark: createBookmarks(bookmarks)?.find(
            bookmark => bookmark.jobId === convertedJob.id
          ),
          handshakes,
          user,
          job: convertedJob,
          key: convertedJob.slug,
          isHottest,
          infoTags: showInfoTags && getInfoTags(jsonJob),
        };

        return (
          <JobCardCtx.Provider key={convertedJob.slug} value={JobCardContext}>
            <JobCard key={jsonJob.id} />
          </JobCardCtx.Provider>
        );
      })}
      {paginationControl}
      {footer}
    </>
  );
};

export default JobList;
