class Range {
  start: number;
  end: number;

  constructor(...args: number[]) {
    if (args.length === 1) {
      this.start = 0;
      this.end = args[0];
    } else if (args.length === 2) {
      this.start = args[0];
      this.end = args[1];
    } else {
      throw new Error(`Range constructor expects 1 or 2 arguments but ${args.length} were passed`);
    }
  }

  map(fn) {
    const size = this.end - this.start + 1;
    const result = new Array(size);
    for (let i = 0; i < size; i++) {
      result[i] = fn(this.start + i);
    }
    return result;
  }
}

export function range(...args: number[]) {
  return new (Function.prototype.bind.apply(Range, [undefined].concat(args)))();
}

export function formatMoneyRange(start: number, end?: number) {
  const rangePartformatter = (part: number) => {
    return part >= 1000 ? `${part / 1000}k` : part;
  };

  let label = null;
  if (start && end) {
    label = `€ ${rangePartformatter(start)} - € ${rangePartformatter(end)}`;
  } else if (start <= 0 && end) {
    label = `< € ${rangePartformatter(end)}`;
  } else if (start && !end) {
    label = `> € ${rangePartformatter(start)}`;
  }
  return label;
}
