import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import constants from 'lib/constants';

const styles = require('./FooterOverlay.module.scss');

interface Props {
  upLimit?: React.RefObject<HTMLDivElement>;
  downLimit?: React.RefObject<HTMLDivElement>;
  body: React.ReactNode;
  customCss?: string;
}

const FooterOverlay = (props: Props) => {
  const { upLimit, downLimit, body, customCss } = props;
  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (!upLimit && !downLimit) {
      return;
    }

    const elementHeight = upLimit?.current?.offsetHeight ?? 0;
    const trigger = elementHeight - constants.MOBILE_NAVBAR_HEIGHT;

    if (
      upLimit?.current?.getBoundingClientRect().y + trigger < 0 &&
      downLimit?.current?.getBoundingClientRect().bottom > window.innerHeight
    ) {
      setShowFooter(true);
    } else {
      setShowFooter(false);
    }
  };

  return (
    <div className={classNames(styles.footerBanner, customCss, showFooter && styles.showFooter)}>
      {body}
    </div>
  );
};

export default FooterOverlay;
