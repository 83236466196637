import React from 'react';
import { mount } from 'enzyme';
import CityInput, { Props } from './CityInput';

const createProps = (otherProps = {}) => ({
  error: '',
  handleFormChange: jest.fn,
  city: '',
  countryName: '',
  country: '',
  ...otherProps,
});

let props: Props;
let wrapped;

describe('City input', () => {
  beforeAll(() => {
    props = createProps();
    wrapped = mount(<CityInput {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('shows the label', () => {
    expect(wrapped.find('.label').text()).toEqual('Which city are you based in? *');
  });
});

test.todo('add more tests');
