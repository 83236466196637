import * as React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import ProgressBar, { Props } from './progress_bar';
const DIMENSIONS = [
  { name: 'location', label: 'Location Match' },
  { name: 'language', label: 'Language Match' },
  { name: 'skills', label: 'Skills Match' },
  { name: 'experience', label: 'Experience Level Match' },
  { name: 'bonus', label: 'Bonus' },
  { name: 'overall', label: 'Overall' },
];

const createTestProps = moreProps => {
  return {
    max: 5,
    type: 'boxes',
    value: 3,
    ratings: { language: 3, location: 2, bonus: 1 },
    dimensions: DIMENSIONS,
  };
};

const createTestProps2 = moreProps => {
  return {
    max: 3,
    value: 1,
  };
};

let props: Props;
let wrapped: ShallowWrapper;

describe('Progress Bar component', () => {
  beforeEach(() => {
    props = createTestProps({});
    wrapped = shallow(<ProgressBar {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

describe('Progress Bar component', () => {
  beforeEach(() => {
    props = createTestProps2({});
    wrapped = shallow(<ProgressBar {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});
