/* eslint-disable no-undef */
window.onmount = require('onmount');

import { initTrackingAndSurveys } from 'lib/marketing';
import * as Scripts from 'lib/scripts';
window.Scripts = Scripts;

window.PONG = require('pong.js');

import 'lib/ats_offers';
import 'lib/import_hiring_steps';
initTrackingAndSurveys();

require('lib/realtime_notifications').init();
import 'images/icons/project.svg';

require('stylesheets/utils.scss');
import 'components/conversations/chat_functions';

// Wizard
import 'components/post_a_job/wizard/post_wizard';
require('components/post_a_job/wizard/wizard.scss');
import 'components/post_a_job/wizard/sidebar/wizard_sidebar';
import 'components/post_a_job/wizard/wizard_buttons/wizard_buttons';
import 'components/post_a_job/contract_type';
import 'components/post_a_job/contract_type.scss';

// Trix Editor
import 'trix/dist/trix.css';
import 'trix';

// Landing.work
import LWIndex from 'landing_work/home/components/index';
import LWEmployers from 'landing_work/home/components/employers';

// For use by CoffeeScript code
import { insertReactElement } from 'lib/react';
import { tagManagerTrack } from 'lib/marketing';
window.insertReactElement = insertReactElement;
window.tagManagerTrack = tagManagerTrack;

document.addEventListener('DOMContentLoaded', function () {
  // Mount React components. This needs to run after Turbolinks has loaded.
  var componentRequireContext = require.context('components', true);
  var ReactRailsUJS = require('react_ujs');
  ReactRailsUJS.useContext(componentRequireContext);

  var getConstructorOriginal = ReactRailsUJS.getConstructor;
  var mountComponentsOriginal = ReactRailsUJS.mountComponents;

  // HACK: override the constructor to inject landing_work components
  ReactRailsUJS.getConstructor = function (className) {
    if (className === 'LWIndex') {
      return LWIndex;
    } else if (className === 'LWEmployers') {
      return LWEmployers;
    } else {
      return getConstructorOriginal.apply(this, arguments);
    }
  };

  ReactRailsUJS.mountComponents = function () {
    mountComponentsOriginal.apply(this, arguments);
    document.dispatchEvent(new Event('react_rails:mounted'));
  };

  document.dispatchEvent(new Event('react_rails:loaded'));
});
