import * as React from 'react';
import { shallow } from 'enzyme';
import RejectionReason from './rejection_reason';
import ApplicationCard from './application_card/application_card';
import Button from 'lj_shared/button/button';

let wrapped;

beforeAll(() => {
  window.IconicJS = () => ({ inject: jest.fn() });
  wrapped = shallow(
    <RejectionReason
      rejectionReason='Application is 2/5 or 3/5'
      rejectionMessage=''
      state={99}
      reconsiderApplication={jest.fn()}
    />
  );
});

describe('when the rejection has a reason but no message', () => {
  it('renders within an application card', () => {
    expect(wrapped.find(ApplicationCard).length).toEqual(1);
  });

  it('shows a header', () => {
    expect(wrapped.find('.lj-heading--base').text()).toEqual('Rejection Reason');
  });

  it('shows a rejection reason', () => {
    expect(wrapped.find('.lj-paragraph--midSmall').text()).toEqual('Application is 2/5 or 3/5');
  });

  it('does not show a rejection message', () => {
    expect(wrapped.find('.lj-paragraph--midSmall').length).toEqual(1);
  });

  it('should have a reconsider button', () => {
    expect(wrapped.find(Button).length).toEqual(1);
  });
});

describe('when the rejection has a message', () => {
  it('shows the rejection message', () => {
    wrapped.setProps({
      rejectionMessage: '<div>Candidate was too junior for the role.</div>',
    });
    expect(wrapped.find('.lj-paragraph--midSmall').length).toEqual(2);
  });
});
