import React, { useContext } from 'react';
import Icon, { Size } from 'components/general/icon/icon';
import OfficeLocationsTooltip from 'components/office_locations_tooltip/office_locations_tooltip';
import { JobCardCtx } from './JobCardContext';
const styles = require('./JobLocation.module.scss');

const JobLocation = () => {
  const jobCardContext = useContext(JobCardCtx);
  const location =
    (jobCardContext.job.remotePolicy ? jobCardContext.job.remotePolicy : '') +
    (jobCardContext.job.remotePolicy && jobCardContext.job.location ? ' - ' : '') +
    (jobCardContext.job.location ? jobCardContext.job.location : '');

  const getLocationIcon = (locationType: string): string => {
    switch (locationType) {
      case 'Remote across borders':
        return 'global';
      case 'Remote':
        return 'remote';
      case 'Hybrid':
        return 'hybridRemote';
      default:
        return 'mapMarkerOutline';
    }
  };
  return (
    <div className={styles.jobCardLocation}>
      <span className={styles.locationIcon}>
        <Icon
          name={getLocationIcon(jobCardContext.job.remotePolicy || jobCardContext.job.location)}
          size={Size.Medium}
        />
      </span>
      <span className={styles.locationText}>{location}</span>
      {jobCardContext.job.officeLocations?.length > 1 && (
        <OfficeLocationsTooltip
          officeLocations={jobCardContext.job.officeLocations}
          iconColor={'tuftsBlue'}
          className={'company-main-page-job-location'}
        />
      )}
    </div>
  );
};

export default JobLocation;
