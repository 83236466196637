import { Messageable } from 'components/conversations/components/models';

const messageableDummy: Messageable = {
  id: '4809',
  type: 'conversation',
  attributes: {
    talent_user_id: 61759,
    company_id: 8,
    updated_at: new Date(),
    recipient_name: 'Filipe Fernandes',
    recipient_recently_updated: true,
    recipient_avatar:
      '<div class="lj-userAvatar--small lj-userAvatar"><img src="https://jobbox-assets-local-dev.s3-eu-west-1.amazonaws.com/attachments/talent_users/avatars/55a5c20dd7a6c44b070f28f9f485b46b7394fe31/original.jpeg?1555272625" class="lj-userAvatar-img" /></div>',
    candidate_applications: [
      {
        id: 135794,
        person_id: 59585,
        current_hiring_step_id: 7380,
        app_rating: null,
        job: {
          id: 7380,
          title: 'UX/UI Specialist',
        },
        notes: {
          data: [],
        },
        handshake: {
          exists: false,
          data: { accepted: null, created: null },
        },
      },
      {
        id: 90294,
        person_id: 59585,
        current_hiring_step_id: 7381,
        app_rating: null,
        job: {
          id: 5017,
          title: 'Event Organizer Operations Intern',
        },
        notes: {
          data: [],
        },
        handshake: {
          exists: false,
          data: { accepted: null, created: null },
        },
      },
    ],
    person_id: 59585,
    experience_level: '4 years of experience',
    websites: [
      {
        id: 90203,
        full_url: 'https://linkedin.com/in/filipemcf',
        title: 'Linkedin',
        category_key: 'linkedin',
      },
    ],
    has_cv: true,
    company_deleted: false,
    person_slug: '',
    user_deleted: false,
  },
};

export default messageableDummy;
