import React, { useState } from 'react';

import Icon from 'components/general/icon/icon';
import RadioButtons from 'components/form/radio_buttons/radio_buttons';
import Tooltip from 'components/general/tooltip/tooltip';

import { titleCase } from 'lib/string';
import { concernsTooltips } from './ConcernsUtils';

const styles = require('./ConcernsContainer.module.scss');

const TITLE_TOOLTIP = [
  <span key={'firstLine'} className={styles.tooltipText}>
    {'Please use this section to input information related to specific requirements'}
  </span>,
  <span key={'secondLine'} className={styles.tooltipText}>
    {'that are not contemplated in the curation process, namely in the '}
  </span>,
  <strong key={'notes'}>{'Notes for the Landing.Jobs team.'}</strong>,
];

interface Props {
  children?: any;
  concernValue?: string;
  concerns: RadioButtonProps[] | string[];
  handleConcern: any;
  style: 'default' | 'redbox';
}

interface RadioButtonProps {
  label: string;
  value: string;
  tooltip?: any;
}

const convertConcertsToObject = (concerns: string[]): RadioButtonProps[] =>
  concerns.reduce((accum: RadioButtonProps[], concern: string) => {
    const [label, value] = JSON.parse(concern);
    return [...accum, { label, value }];
  }, []);

export default function ConcernsContainer(props: Props) {
  const concernOptions = (): RadioButtonProps[] =>
    [
      { label: 'no_concerns', tooltip: '' },
      ...convertConcertsToObject(props.concerns as string[]),
    ].reduce(
      (options: RadioButtonProps[], accum: RadioButtonProps) => [
        ...options,
        {
          label: titleCase(accum.label),
          value: accum.value,
          tooltip: concernsTooltips[accum.label],
        },
      ],
      []
    );

  const [concern, setConcern] = useState(props.concernValue || '');

  const changeConcern = optionItem => {
    setConcern(optionItem);
    props.handleConcern(optionItem);
  };

  return (
    <div className={styles.container}>
      <div className={styles.childrenContainer}>{props.children}</div>
      <div className={props.style === 'redbox' ? styles.redBox : styles.none}>
        <div className={styles.title}>
          <h3>Concerns:</h3>
          <Tooltip htmlContent={TITLE_TOOLTIP}>
            <Icon name={'helpCircle'} color={'black'} />
          </Tooltip>
        </div>
        <RedFlagText />
        <RadioButtons onChange={changeConcern} options={concernOptions()} selectedValue={concern} />
      </div>
    </div>
  );
}

const RedFlagText = () => (
  <>
    <p>
      The employer will have access to this.
      <br />
      Is there any specific criteria (e.g. in the notes from the company) that this candidate
      doesn’t fit into? Please choose a dimension to which it is related.
    </p>
  </>
);
