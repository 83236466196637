import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import AverageHiringProcessItem from './AverageHiringProcessItem';

const createProps = () => ({
  companyName: 'Name',
  companyAvg: '2-4 weeks',
  marketAvg: '4-6 weeks',
});

let props;
let wrapped: ShallowWrapper;

describe('Your description here', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<AverageHiringProcessItem {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('name is correct', () => {
    expect(wrapped.find('.companyAvg .name').text()).toBe('Name');
  });

  it('company avg is correct', () => {
    expect(wrapped.find('.companyAvg .numbers').text()).toBe('2-4 weeks');
  });

  it('company avg is correct', () => {
    expect(wrapped.find('.marketAvg .numbers').text()).toBe('4-6 weeks');
  });
});
