import React, { useState } from 'react';
const styles = require('./CsvUploader.module.scss');

interface Props {
  formData: any;
  setFormData: any;
}

export default function CsvUploader(props: Props) {
  const [error, setError] = useState('');

  function handleChange(event) {
    const file = event.target.files[0];
    const formData = new FormData();
    if (validateBeforeUpload(file)) {
      formData.append('file', file);
      formData.append('name', event.target.name);
      formData.append('replace', 'true');

      props.setFormData(formData);
    }
  }

  function validateBeforeUpload(file) {
    const types = /(\.|\/)csv$/i;

    if (!(types.test(file.type) || types.test(file.name))) {
      setError('CSV only');
      return false;
    } else if (file.size > 10485760) {
      setError('Maximum CSV size is 10 Mb.');
      return false;
    } else {
      setError('');
      return true;
    }
  }

  const renderCSVInfo = () => {
    return (
      <div className={styles.csvInfo}>
        {
          <strong>
            Allowed file types: .csv
            <br />
            Max. file size: 10 MB
          </strong>
        }
      </div>
    );
  };

  return (
    <div>
      <div>
        <label htmlFor='csv_attachment'>
          <div className={styles.uploadTitle}>IMPORT A CSV FILE</div>
        </label>
        <input
          type='file'
          name='csv_attachment'
          id='csv_attachment'
          onChange={handleChange}
          onClick={event => ((event.target as HTMLInputElement).value = '')}
        />
        <div>{error && <div className={styles.mandatory}>{error}</div>}</div>
        {renderCSVInfo()}
      </div>
    </div>
  );
}
