import * as React from 'react';
import classNames from 'classnames';
import 'lib/globals';
import { truncate } from 'lib/string';
import { Conversation, Messageable } from './models';
import InlineIcon from '../../general/inline_icon/inline_icon';
import formatDate from 'lib/format_date';
import HandshakeTag from './handshake_tag';

const chatBubble = require('images/icons/chat-bubble.svg');
const crossIcon = require('iconic/x-thin.svg');
const searchIcon = require('iconic/magnifying-glass-sm.svg');
const chevronPath = require('iconic/chevron.svg');

export interface Props {
  conversations: Conversation[];
  otherContacts: Messageable[];
  selected: Messageable;
  unread_counts: object;
  handleConversationChange: any;
  userType: string;
  getOtherContacts: Function;
  loading: boolean;
  mini: boolean;
  minimized: boolean;
  handleMinimize: any;
}

interface State {
  query: string;
  results: Conversation[];
  otherResults: Messageable[];
}

class ConversationsList extends React.Component<Props, State> {
  private searchInput: React.RefObject<HTMLInputElement>;
  private selected: HTMLElement;

  constructor(props: Props) {
    super(props);
    this.state = {
      query: '',
      results: [],
      otherResults: [],
    };

    this.handleClick = this.handleClick.bind(this);
    this.renderContactsList = this.renderContactsList.bind(this);
    this.setClassNames = this.setClassNames.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.companyContactslist = this.companyContactslist.bind(this);
    this.renderLoadingAnimation = this.renderLoadingAnimation.bind(this);
    this.searchInput = React.createRef();
    this.selected = null;
  }

  componentDidMount() {
    this.scrollToSelected();

    if (this.props.otherContacts.length === 0 && !this.props.minimized) {
      this.props.getOtherContacts();
    }
  }

  componentDidUpdate() {
    this.scrollToSelected();
  }

  setClassNames(object) {
    let classes = 'lj-conversation-list-item';

    if (this.props.selected === object) {
      classes += ' selected';
    }

    if (
      !object.relationships?.messages?.data ||
      object.relationships?.messages?.data?.length === 0
    ) {
      classes += ' lj-conversation-never-read-item';
    }

    return classes;
  }

  getApplications(messageable) {
    const applications = messageable.attributes.candidate_applications;
    const userDeleted = messageable.attributes.user_deleted;
    if (userDeleted) {
      return 'User Deleted';
    } else if (applications && applications.length > 0) {
      const titles = applications.map(a => a.job.title);
      const hanshakeIndex = applications.map(a => a.handshake.exists).indexOf(true);
      const truncateSize = this.props.mini ? 21 : 50;
      return hanshakeIndex !== -1 && !this.props.mini ? (
        <div className='lj-chat-handshake-tag-container'>
          <div className='lj-chat-handshake-tag'>
            <HandshakeTag
              recipientName={messageable.attributes.recipient_name}
              jobTitle={applications[hanshakeIndex].job.title}
              handshake={applications[hanshakeIndex].handshake.data}
              userType={this.props.userType}
            />
            Handshake
          </div>
          {truncate(`Applied to ${titles.join(', ')}`, truncateSize)}
        </div>
      ) : (
        <>{truncate(`Applied to ${titles.join(', ')}`, truncateSize)}</>
      );
    } else {
      return 'Accepted Handshake';
    }
  }

  handleClick(object, event) {
    event.preventDefault();
    this.props.handleConversationChange(object);
  }

  renderUnread(messageable) {
    if (this.props.mini) {
      if (messageable.type === 'conversation' && messageable.id in this.props.unread_counts) {
        return <div className='lj-unread-mini'>{this.props.unread_counts[messageable.id]}</div>;
      }
    } else {
      if (messageable.type === 'conversation' && messageable.id in this.props.unread_counts) {
        return (
          <div className='lj-conversation-timestamp'>
            New
            <div className='lj-unread-counter'>{this.props.unread_counts[messageable.id]}</div>
          </div>
        );
      } else {
        return (
          <div className='lj-conversation-timestamp'>
            {formatDate(messageable.attributes.updated_at, {
              month: 'short',
              day: '2-digit',
            })}
          </div>
        );
      }
    }
  }

  getResults() {
    const searchInput: Messageable[][] = [this.props.conversations];

    if (this.props.userType === 'CompanyUser') {
      searchInput.push(this.props.otherContacts);
    }

    const results = searchInput.map(conversations => {
      const filterByName: any[] = conversations.filter(conversation => {
        return conversation.attributes.recipient_name
          .toLowerCase()
          .includes(this.state.query.toLowerCase());
      });

      const filterByApplication: any[] = conversations.filter(conversation => {
        return conversation.attributes.candidate_applications.some(application => {
          return application.job.title.toLowerCase().includes(this.state.query.toLowerCase());
        });
      });

      const filterByNameAndApplication: any[] = filterByName.concat(
        filterByApplication.filter(app => {
          return filterByName.indexOf(app) < 0;
        })
      );

      return this.props.userType === 'CompanyUser' ? filterByNameAndApplication : filterByName;
    });

    if (this.props.userType === 'CompanyUser') {
      this.setState(() => {
        return {
          results: results[0],
          otherResults: results[1],
        };
      });
    } else {
      this.setState(() => {
        return {
          results: results[0],
        };
      });
    }
  }

  handleInputChange(e) {
    e.preventDefault();
    this.setState(() => {
      return { query: this.searchInput.current.value };
    }, this.getResults);
  }

  clearSearch(e) {
    e.preventDefault();
    this.searchInput.current.value = '';
    this.setState({ query: this.searchInput.current.value });
    this.searchInput.current.focus();
  }

  renderSearch() {
    if (this.props.conversations.length > 0 || this.props.otherContacts.length > 0) {
      return (
        <form onSubmit={this.handleInputChange} id='conversation-search-form'>
          <img src={searchIcon} alt='search' className='iconic iconic-sm' id='searchIcon' />
          <input
            className='lj-conversation-search'
            placeholder={
              this.props.userType === 'CompanyUser' ? 'Search by Job, Candidate...' : 'Company...'
            }
            ref={this.searchInput}
            onChange={this.handleInputChange}
          />
          <button onClick={this.clearSearch} className={this.state.query ? '' : 'u-hide'}>
            <img src={crossIcon} alt='cross' className='iconic iconic-sm' id='crossIcon' />
          </button>
        </form>
      );
    }
  }

  conversationsAndOtherContacts(conversations) {
    const otherContacts = this.state.query ? this.state.otherResults : this.props.otherContacts;
    return conversations.concat(otherContacts);
  }

  renderContactsList(conversations) {
    if (conversations.length > 0) {
      return (
        <ul className='lj-conversations-list'>
          {conversations.map(conversation => this.renderMessageable(conversation))}
        </ul>
      );
    } else {
      const searchInput = document.querySelector('.lj-conversation-search') as HTMLInputElement;

      if (
        searchInput &&
        searchInput.value.length > 0 &&
        !(this.props.mini && this.state.otherResults.length !== 0)
      ) {
        return (
          <div className='lj-conversations-list-empty'>
            <span>Oops, we couldn’t find what you’re looking for…</span>
            <svg xmlns='http://www.w3.org/2000/svg' width='40' height='27' viewBox='0 0 40 27'>
              <path
                fill={this.props.mini ? '#000000' : '#FFFFFF'}
                fillRule='nonzero'
                d='M10.476 0C8.177 0 6.145 1.41 5.238 3.415c0 0-3.694 9.636-4.836 12.545A5.94 5.94 0 0 0 0 18.05c.008 4.58 3.624 8.292 8.095 8.292s8.095-3.712 8.095-8.292v-1.464h7.62v1.464c0 4.58 3.624 8.292 8.095 8.292 4.47 0 8.087-3.712 8.095-8.292a5.94 5.94 0 0 0-.402-2.089c-1.142-2.91-4.836-12.545-4.836-12.545C33.855 1.409 31.822 0 29.524 0c-3.156 0-5.714 2.62-5.714 5.854v.975h-7.62v-.975C16.19 2.62 13.632 0 10.476 0zm5.58 11.707h.045a.93.93 0 0 1 .09 0h7.619a.948.948 0 0 1 .836.484.996.996 0 0 1 0 .984.948.948 0 0 1-.836.484h-7.62a.947.947 0 0 1-.858-.429.998.998 0 0 1-.067-.975.955.955 0 0 1 .792-.548zm-7.96.488c3.155 0 5.714 2.62 5.714 5.854 0 3.233-2.559 5.853-5.715 5.853-3.156 0-5.714-2.62-5.714-5.853 0-3.233 2.558-5.854 5.714-5.854zm23.809 0c3.156 0 5.714 2.62 5.714 5.854 0 3.233-2.558 5.853-5.714 5.853-3.156 0-5.715-2.62-5.715-5.853 0-3.233 2.559-5.854 5.715-5.854z'
              />
            </svg>
          </div>
        );
      } else if (this.props.loading) {
        return this.renderLoadingAnimation();
      } else if (this.props.conversations.length === 0 && this.props.otherContacts.length === 0) {
        return (
          <div className='lj-conversations-list-empty'>
            <span>You don’t have any active messages, yet…</span>

            <UpsideDownChatSVG mini={this.props.mini} />
          </div>
        );
      }
    }
  }

  companyContactslist(conversations) {
    conversations = this.conversationsAndOtherContacts(conversations);

    if (this.props.mini) {
      return (
        <div
          className={classNames('lj-conversations', {
            hidden: this.props.minimized,
          })}
        >
          <div className='lj-chat--miniHeader' onClick={this.props.handleMinimize}>
            Chat
            <InlineIcon path={chevronPath} />
          </div>
          {this.renderSearch()}
          <div id='main-contacts-list'>{this.renderContactsList(conversations)}</div>
        </div>
      );
    } else {
      return (
        <div className='lj-conversations'>
          {this.renderSearch()}
          <div id='main-contacts-list'>{this.renderContactsList(conversations)}</div>
          <div className='fade-to-black' />
        </div>
      );
    }
  }

  render() {
    let conversations: Conversation[];

    if (this.state.query) {
      conversations = this.state.results;
    } else {
      conversations = this.props.conversations;
    }

    if (this.props.userType === 'CompanyUser') {
      return this.companyContactslist(conversations);
    } else {
      return (
        <div
          className={classNames('lj-conversations', {
            hidden: this.props.minimized,
          })}
        >
          {this.props.mini && (
            <div className='lj-chat--miniHeader' onClick={this.props.handleMinimize}>
              Chat
              <InlineIcon path={chevronPath} />
            </div>
          )}
          {this.renderSearch()}
          <div id='main-contacts-list'>{this.renderContactsList(conversations)}</div>
          <div className='fade-to-black' />
        </div>
      );
    }
  }

  renderMessageable(messageable) {
    return (
      <li
        className={this.setClassNames(messageable)}
        key={`${messageable.type}_${messageable.id}`}
        ref={e => this.setSelectedRef(messageable, e)}
      >
        <a href='void(0)' onClick={this.handleClick.bind(this, messageable)}>
          <div
            dangerouslySetInnerHTML={{
              __html: messageable.attributes.recipient_avatar,
            }}
          />
          <div className='lj-conversation-info'>
            <h5 className='ld-h5'>{messageable.attributes.recipient_name}</h5>
            <div className='lj-conversation-context'>{this.getApplications(messageable)}</div>
          </div>

          {this.renderUnread(messageable)}
        </a>
      </li>
    );
  }

  renderLoadingAnimation() {
    return (
      <>
        <div className='lj-conversation-spinner'>
          <div className='ld-loading-animation u-display--block'>
            <span className='ld-dotcon'>
              <div className='ld-dot' />
            </span>
            <span className='ld-dotcon'>
              <div className='ld-dot' />
            </span>
            <span className='ld-dotcon'>
              <div className='ld-dot' />
            </span>
          </div>
        </div>
      </>
    );
  }

  scrollToSelected() {
    if (this.selected && !isInViewport(this.selected)) {
      this.selected.scrollIntoView();
    }
  }

  setSelectedRef(messageable, element) {
    if (this.props.selected === messageable) {
      this.selected = element;
    }
  }
}

function isInViewport(element) {
  const documentElement = document.documentElement;
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || documentElement.clientWidth)
  );
}

const UpsideDownChatSVG = props => <img src={chatBubble} />;

export default ConversationsList;
