import React from 'react';
import { shallow, ShallowWrapper, mount } from 'enzyme';
import AverageDurationForJobs from './AverageDurationForJobs';

const createFakeJobs = (nProps: number, start = 0) => {
  const result = [];

  for (let i = start; i < nProps; i++) {
    result.push({
      id: i,
      title: `${i}`,
    });
  }
  return result;
};

const createFakeData = (nProps: number) => {
  const result = [];

  for (let i = 0; i < nProps; i++) {
    result.push({
      funnel: {
        jobId: i,
      },
      speedMetrics: {
        daysToEngage: { average: i * 2 },
        daysToHire: { average: i * 2 },
        daysToOffer: { average: i * 2 },
        daysToReject: { average: i * 2 },
        daysToReview: { average: i * 2 },
      },
    });
  }
  return result;
};

const createProps = (nJobs: number) => ({
  activeJobs: createFakeJobs(nJobs),
  averageDuration: jest.fn(),
  averageDurationHeader: jest.fn(),
  closedJobs: createFakeJobs(nJobs + 1, nJobs),
  dataByJob: createFakeData(2 * nJobs + 1),
});

let props;
let wrapped: ShallowWrapper;

describe('Average Duration for Jobs tab', () => {
  const nJobs = Math.floor(Math.random() * 5) + 1; // random number in [1, 5[ interval
  beforeEach(() => {
    props = createProps(nJobs);
    wrapped = shallow(<AverageDurationForJobs {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
