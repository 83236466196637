import React from 'react';
import classNames from 'classnames';
const styles = require('./Skills.module.scss');

interface Props {
  mustHaveSkills: { id: number; name: string }[];
  otherRequiredSkills: { id: number; name: string }[];
  niceToHaveSkills: { id: number; name: string }[];
}

export default function Skills(props: Props) {
  const renderSkills = (title, skills, border, color) => {
    return (
      skills && (
        <div className={classNames(styles.skills, color)}>
          {title}:{' '}
          {skills.map(skill => (
            <div key={skill.id} className={classNames(styles.skillsItem, border)}>
              {skill.name}
            </div>
          ))}
        </div>
      )
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Skills</div>
      <hr className={styles.line} />
      <div className={styles.skills}>
        {props.mustHaveSkills.length > 0 &&
          renderSkills('Must have', props.mustHaveSkills, styles.mustHaveBorder, styles.mustHave)}
        {props.otherRequiredSkills.length > 0 &&
          renderSkills(
            'Other Required',
            props.otherRequiredSkills,
            styles.otherRequiredBorder,
            styles.otherRequired
          )}
        {props.niceToHaveSkills.length > 0 &&
          renderSkills(
            'Nice to have',
            props.niceToHaveSkills,
            styles.niceToHaveBorder,
            styles.niceToHave
          )}
      </div>
    </div>
  );
}
