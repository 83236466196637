import * as React from 'react';
import IntegrationModal from './integration_modal';
import { shallow, ShallowWrapper } from 'enzyme';

let wrapped: ShallowWrapper;

describe('Integration Modal component', () => {
  beforeEach(() => {
    wrapped = shallow(
      <IntegrationModal url='' atsName='' atsPathToCreateToken='' atsAPIDocUrl='' />
    );
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
