const applicationPersonDummy = {
  id: '98598',
  type: 'person',
  attributes: {
    first_name: 'Kiggundu',
    last_name: 'Nicholas',
    user_id: 102495,
    recipient_avatar:
      '<div class="lj-userAvatar--small lj-userAvatar"><img src="https://assets.landing.jobs/default_avatars/avatar0.svg" class="lj-userAvatar-img" /></div>',
    has_cv: true,
    name: 'Kiggundu Nicholas',
    experience_years: '< 1 year of experience',
    location: 'Kampala, Uganda',
    citizenships: 'Ugandan',
    phone_number: '912 345 678',
    email: 'example@fakemail.com',
    websites: [],
    languages: [
      {
        id: 157691,
        name: 'English',
        experience_label: '3. Professional working proficiency',
      },
    ],
    skills: [
      {
        id: 1174504,
        name: 'Networking',
        experience_label: '2 Years',
      },
      {
        id: 1174505,
        name: 'Tester',
        experience_label: '< 1 Year',
      },
      {
        id: 1174599,
        name: 'Network Administration',
        experience_label: '2 Years',
      },
    ],
    certificates: [
      {
        id: 17622,
        title: 'CCNA',
        authority: 'International University of East Africa',
        year: 2016,
        url: '/files/original/missing.png',
      },
    ],
    person_tags: {
      multiple_processes: true,
    },
  },
};

export default applicationPersonDummy;
