import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import ConcernsContainer from './ConcernsContainer';

const createProps = () => ({
  concerns: [
    '["location", 0]',
    '["language", 1]',
    '["skills", 2]',
    '["experience_level", 3]',
    '["lack_of_information", 4]',
  ],
  dimension: {
    name: 'overall',
    label: 'Overall',
  },
  form: {
    application: '{application_letter: null, application_questions: n…}',
    automaticNotesValues: '{experience: Array(1), language: Array(1), location…}',
    handleConcern: 'handleConcern',
    overallIsDirty: false,
    ratingRef: '{current: <select />}',
    concern: null,
    setAutomaticNotes: 'bound dispatchAction',
    setOverallIsDirty: 'bound dispatchAction',
    setValues: 'bound dispatchAction',
    setValuesData: 'bound dispatchAction',
    talentUser: '{avatar: "<div class="lj-userAvatar--small lj-userA…}',
    values: '{bonus: 3, experience: 1, language: 4, location: 2,…}',
    valuesData: '{background_checkbox: false, background_years: null…}',
    location: '{currentLocation: {…}, getInitialLocationRating: ge…}',
    language: '{fillDefaultLanguageRating: fillDefaultLanguageRati…}',
    skills: '{skills: List(6), setSkillsNotes: setSkillsNotes, s…}',
    experience: '{backgroundYearsRef: {…}, handleChangeBackgroundYea…}',
    bonus: '{bonusValues: {…}, handleCheckboxChange: handleChec…}',
  },
  handleConcern: 'handleConcern',
  step: 6,
  children: ['<h3 />', '<RatingBox />', '<SidebarButtons />'],
});

let props;
let wrapped: ShallowWrapper;

describe('Your description here', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<ConcernsContainer {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
