import { Company, Job } from './types';

export default function usePostJobModal() {
  const fetchCompanies = (setCompanyList: (companies: Company[]) => void): void => {
    const loadingTimeoutID = setTimeout(() => window.toggleSpinner(true), 200);

    fetch(window.Routes.company_options_backoffice_admin_post_job_index_url(), {
      method: 'GET',
    })
      .then(response => {
        window.toggleSpinner(false);
        clearTimeout(loadingTimeoutID);
        return response.json().then(data => setCompanyList(data.options));
      })
      .catch(() => {
        window.toggleSpinner(false);
        clearTimeout(loadingTimeoutID);
        setCompanyList([]);
      });
  };

  const fetchJobs = (companyId: number, setJobList: (jobList: Job[]) => void): void => {
    if (!companyId) {
      setJobList([]);
    }

    const loadingTimeoutID = setTimeout(() => window.toggleSpinner(true), 200);

    const params = {
      company_id: companyId,
    };

    fetch(window.Routes.job_options_backoffice_admin_post_job_index_url(params), {
      method: 'GET',
    })
      .then(response => {
        window.toggleSpinner(false);
        clearTimeout(loadingTimeoutID);
        return response.json().then(data => setJobList(data.options));
      })
      .catch(() => {
        window.toggleSpinner(false);
        clearTimeout(loadingTimeoutID);
        setJobList([]);
      });
  };

  return { fetchCompanies, fetchJobs };
}
