import React from 'react';

import Icon, { Size as IconSize } from 'components/general/icon/icon';
import Tooltip, { Size as TooltipSize } from 'components/general/tooltip/tooltip';

const styles = require('./contract_type_tooltip.module.scss');

export default function ContractTypeTooltip() {
  return (
    <Tooltip htmlContent={[renderTooltipContent()]} size={TooltipSize.Large} textBelow={true}>
      <Icon name='helpCircle' color='silverSandXxDark' size={IconSize.Medium} />
    </Tooltip>
  );
}

function renderTooltipContent() {
  return (
    <span key='_'>
      <span className={styles.tooltipLine}>
        <strong>Permanent employee</strong> - Job for someone who works for an employer and is paid
        directly by that employer.
      </span>
      <span className={styles.tooltipLine}>
        <strong>Contractor</strong> - Job for someone who chooses to set up their own company or
        work as an independent worker to provide services to 1 client at a time on a mid to
        long-term project basis.
      </span>
    </span>
  );
}
