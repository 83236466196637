import * as React from 'react';
import classnames from 'classnames';
import { renderToStaticMarkup } from 'react-dom/server';
import '../../../lib/globals';
import Icon, { Size } from 'components/general/icon/icon';

import FormError from 'components/form/form_error/form_error';

const colorStyles = require('./selectize_colors.module.scss');
const styles = require('./selectize.module.scss');

interface Props {
  className?: string;
  id?: string;
  // `items` is an array of objects. Each object must have a `value` and a
  // `text` field.
  items: any[];
  color?: string;
  itemRender?: Function;
  onChange?: Function;
  name?: string;
  selected?: any[];
  selectedRender?: Function;
  optGroups?: any[];
  placeholder?: string;
  selectizeOptions?: Object;
  label?: string;
  required?: boolean;
  error?: string;
  closeIcon?: boolean;
  handleCloseIcon?: Function;
}

export default class Selectize extends React.Component<Props, {}> {
  selectRef: any;
  selectize: any;
  config: any;

  constructor(props) {
    super(props);
    this.selectRef = React.createRef();
  }

  componentDidMount() {
    this.bootstrapSelectize();
    this.handleChange();
  }

  componentDidUpdate() {
    this.bootstrapSelectize();
    this.handleChange();
  }

  bootstrapSelectize() {
    this.selectize && this.selectize.destroy();
    const render = {};
    if (this.props.itemRender) {
      render['option'] = item => renderToStaticMarkup(this.props.itemRender(item));
    }
    if (this.props.selectedRender) {
      render['item'] = item => renderToStaticMarkup(this.props.selectedRender(item));
    }
    const selectizeOptions = this.props.selectizeOptions || {};

    if (this.props.optGroups) {
      this.config = {
        ...selectizeOptions,
        items: this.props.selected || [],
        options: this.props.items,
        plugins: ['selected_fix'],
        optgroups: this.props.optGroups,
        optgroupField: 'group',
        optgroupLabelField: 'groupLabel',
        optgroupValueField: 'groupValue',
        valueField: 'value',
        labelField: 'title',
        render: {
          optgroup_header: (data, escape) => {
            return '<div class="optgroup-header">' + escape(data.groupLabel) + '</div>';
          },
        },
      };
    } else {
      this.config = {
        items: this.props.selected || [],
        options: this.props.items,
        render,
      };
    }
    if (this.props.placeholder) {
      this.config['placeholder'] = this.props.placeholder;
    }
    this.selectize = $(this.selectRef.current).selectize(this.config)[0].selectize;
  }

  handleChange() {
    const props = this.props;
    $(this.selectize.$input)
      .off('change')
      .on('change', event => {
        const selectedItem = props.items.find(
          item => String(item.value) === String(event.target.value)
        );
        props.onChange(selectedItem);
      });
  }

  render() {
    return (
      <div>
        {this.props.label && (
          <label className={styles.label}>
            {this.props.label}
            {this.props.required && <span className={styles.required}>*</span>}
          </label>
        )}
        <div className={styles.wrapper}>
          <select
            id={this.props.id}
            className={classnames(
              this.props.className,
              colorStyles[this.props.color],
              this.props.color
            )}
            name={this.props.name}
            ref={this.selectRef}
          />
          {this.props.error && <FormError text={this.props.error} />}
          {this.props.closeIcon ? (
            <Icon
              name='close'
              clickHandler={this.props.handleCloseIcon}
              className={styles.closeIcon}
              size={Size.Big}
            />
          ) : null}
        </div>
      </div>
    );
  }
}
