import React from 'react';
import Button from 'lj_shared/button/button';
import Icon, { Size } from 'components/general/icon/icon';
import 'lib/globals';

const styles = require('./header.module.scss');

export default function Header() {
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <Icon color='white' name='briefcase' size={Size.MediumBig} />
        <span className={styles.text}>Jobs</span>
      </div>

      <div className={styles.right}>
        <PostJobButton />
      </div>
    </div>
  );
}

function PostJobButton() {
  return (
    <Button
      buttonColor='whiteRipePlumText'
      buttonSize='small'
      buttonUrl={window.Routes.post()}
      otherClasses={styles.postJobButton}
    >
      <Icon color='ripePlum-midLight' size={Size.Small} name='plus' />
      Post a job
    </Button>
  );
}
