import * as React from 'react';
import JobCard from './job_card';
import JobState from 'components/general/job/state';
import { shallow } from 'enzyme';
import { JobAd } from '../../__models__/models';
import JobMoreButton from './job_more_button';

const jobDummy: JobAd = require('./fixtures/job_ad_dummy.json');
let wrapped;

beforeAll(() => {
  wrapped = shallow(
    <JobCard
      job={jobDummy}
      topFilter={'ongoing'}
      termsOfService={'tos'}
      privacy={'privacy'}
      setJobsState={jest.fn()}
      isFollowedJob
      maxPublishedReached={false}
    />
  );
});

it('it has a JobMoreButton', () => {
  expect(wrapped.find(JobMoreButton).length).toEqual(1);
});

it('it has a Edit Button', () => {
  expect(wrapped.find({ 'data-testid': 'edit-button' }).length).toEqual(1);
});

it('it has a View Button', () => {
  expect(wrapped.find({ 'data-testid': 'view-button' }).length).toEqual(1);
});

it('has a Followers button', () => {
  expect(wrapped.find({ 'data-testid': 'manage-followers-button' }).length).toEqual(1);
});

it('has a title', () => {
  expect(wrapped.find('.lj-heading--mid').text()).toEqual('Pokemon Champ');
});

it('has a location', () => {
  expect(wrapped.find({ 'data-testid': 'location' }).text()).toMatch(/Tokyo, Japan/i);
});

it('it has a JobState', () => {
  expect(wrapped.find(JobState).length).toEqual(1);
});
