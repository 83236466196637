import Icon, { Size } from 'components/general/icon/icon';
import { adminUserPricingNotification } from 'lib/admin_user_pricing_notification';
import Button from 'lj_shared/button/button';
import React from 'react';
const styles = require('./FeatureCta.module.scss');

interface Props {
  type: string;
  color: string;
}

export default function FeatureCta(props: Props) {
  const { type, color } = props;

  const text = () => {
    if (type === 'RPO Service') {
      return (
        'Your subscription does not include screening interviews conducted by our team. ' +
        'Screening interviews are preliminary assessments that gather as much relevant information ' +
        "as possible about candidates for you, saving you time when you're ready to select the ones who best match your needs."
      );
    }
  };

  const handleClick = () => {
    return adminUserPricingNotification({ pricingDisabledFeature: type });
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Icon
          name={'warning'}
          color={color + 'MidDark'}
          size={Size.Medium}
          className={styles.icon}
        />
        You do not have access to this feature
      </div>
      <div className={styles.content}>
        <div className={styles.text}>{text()}</div>
        <div className={styles.text}>
          If you're interested in this feature reach out to our team and we'll make it happen.
        </div>
      </div>
      <div className={styles.footer}>
        <Button buttonColor={color} onClick={handleClick} otherClasses={styles.button}>
          Add feature
        </Button>
      </div>
    </div>
  );
}
