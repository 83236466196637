import * as React from 'react';
import Navbar, { Props } from './Navbar';
import { mount } from 'enzyme';
import Button from 'lj_shared/button/button';

const TABS = [{ label: 'A' }, { label: 'B' }];
window.Routes = { company_edit: jest.fn() };

const createProps = (otherProps = {}) => ({
  active: TABS[0],
  companyId: 8,
  currentUserCompanyId: null,
  items: TABS,
  onClick: jest.fn,
  ...otherProps,
});

let props: Props;
let wrapped;

describe('Topbar', () => {
  beforeAll(() => {
    props = createProps();
    wrapped = mount(<Navbar {...props} />);
  });
  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('has correct number of unselected elements', () => {
    expect(wrapped.find('.navItem').length).toEqual(props.items.length - 1);
  });

  it('has correct number of selected elements', () => {
    expect(wrapped.find('.selectedNavItem').length).toEqual(1);
  });

  it('should not show the edit button', () => {
    expect(wrapped.find(Button).length).toEqual(0);
  });
});

describe('Topbar for company user', () => {
  beforeAll(() => {
    props = createProps({ currentUserCompanyId: 8 });
    wrapped = mount(<Navbar {...props} />);
  });

  it('should show the edit button', () => {
    expect(wrapped.find(Button).text()).toEqual('Edit');
  });
});
