const LABELS = {
  unreviewed: 'Candidate applied',
  pending_information: 'Pending Info',
  curated: 'Screened by Landing',
  employer_reviewing: "You're reviewing",
  offer_made: 'Proposal',
};

export function applicationStateForEmployer(name, scope = null) {
  return name === 'unreviewed' && scope === 'applications_list'
    ? 'Waiting for Screening'
    : LABELS[name];
}
