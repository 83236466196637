import gql from 'graphql-tag';

export const GET_BONUS_NOTES_GQL = gql`
  query ($bonusCheckboxes: [String!]!, $other: String, $veryNegative: Boolean) {
    bonusNotesComputation(
      bonusCheckboxes: $bonusCheckboxes
      other: $other
      veryNegative: $veryNegative
    )
  }
`;
