import React from 'react';
import ContextConsumer from '../../landing_work/home/components/context';
import LoginContainer from './login_container';

const styles = require('./login.module.scss');

const EN = require('./copy/EN/welcome.json');
const PT = require('./copy/PT/welcome.json');

let currentLanguage;
let language;

interface LogInProps {
  urls: {
    password: string;
    login: string;
    socialUrls: {
      linkedin: string;
      facebook: string;
      github: string;
      google: string;
    };
  };
}
class LogIn extends React.Component<LogInProps> {
  render() {
    return (
      <ContextConsumer>
        {({ data }) => {
          document.title = `Landing.Jobs | Log in`;
          language = data.contextLanguage;
          language === 'PT' ? (currentLanguage = PT) : (currentLanguage = EN);
          return (
            <div className={styles.loginMain}>
              <LoginContainer language={currentLanguage.LogIn} urls={this.props.urls} />
              <div className={styles.background} />
            </div>
          );
        }}
      </ContextConsumer>
    );
  }
}

export default LogIn;
