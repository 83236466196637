import React from 'react';
import Checkbox from '../../form/checkbox/checkbox';
import { InformationBit } from './informationBitsModal';
import Icon from '../../general/icon/icon';

const styles = require('./informationBitSkill.module.scss');

export default function InformationBitSkill(props: InformationBit) {
  let bitLabel: JSX.Element;
  let bitAmount: string;
  let popularSkill: JSX.Element;

  if (props.job_ad_count >= 10) {
    popularSkill = (
      <span className={styles.popularSkill}>
        This is a popular skill! We have more than 10 jobs with this skill at the moment!
      </span>
    );
  }

  if (props.amount) {
    bitAmount =
      props.amount === 1
        ? `${props.amount} year of experience`
        : `${props.amount} years of experience`;
  }

  if (typeof bitAmount !== 'undefined' && typeof popularSkill !== 'undefined') {
    bitLabel = (
      <span>
        <span>
          {props.value} - <span className={styles.infoBitAmount}>{bitAmount}</span>
        </span>
        <Icon name='star' color='puertoRico' className={styles.starIcon} />
        <br />
        {popularSkill}
      </span>
    );
  } else if (typeof bitAmount !== 'undefined' && typeof popularSkill === 'undefined') {
    bitLabel = (
      <span>
        {props.value} - <span className={styles.infoBitAmount}>{bitAmount}</span>
      </span>
    );
  } else if (typeof bitAmount === 'undefined' && typeof popularSkill !== 'undefined') {
    bitLabel = (
      <span>
        <span>{props.value}</span>
        <Icon name='star' color='puertoRico' className={styles.starIcon} />
        <br />
        {popularSkill}
      </span>
    );
  } else {
    bitLabel = <span>{props.value}</span>;
  }

  return (
    <div key={props.id} className={styles.infoBit}>
      <Checkbox
        checked
        label={bitLabel}
        name={`info_bit_${props.id}`}
        value={props.id.toString()}
        id={`info_bit_${props.id}`}
        color='tuftsBlue'
      />
    </div>
  );
}
