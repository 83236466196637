import React, { useContext, useState } from 'react';

import { FormContext } from 'components/form/FormProvider';
import Checkbox from 'components/form/checkbox/checkbox';
import { snakeCase, titleize } from 'lib/string';
import SectionValuesImages from './utils/SectionValuesImages';

const styles = require('./AboutSectionValues.module.scss');

export default function AboutSectionValues() {
  const context = useContext(FormContext);
  const { maxValues } = context.resource;
  const [companyValues, setCompanyValues] = useState(context.resource.values);
  const companyValuesList = context.resource.companyValues;

  const handleChange = () => {
    const selectedValues = Array.from(document.querySelectorAll('[name="company_form[values][]"]'))
      .filter((element: HTMLInputElement) => element.checked)
      .map((element: HTMLInputElement) => element.value);
    if (selectedValues.length <= maxValues) {
      setCompanyValues(selectedValues);
    } else {
      window.Alerts.alert(`You can only choose up to ${maxValues} values`);
    }
  };

  const renderCompanyValues = () =>
    companyValuesList.map(companyValue => (
      <div key={companyValue} className={styles.companyValueItem}>
        <img src={SectionValuesImages[snakeCase(companyValue)]} />
        <Checkbox
          className={styles.companyValueItemCheckbox}
          name='company_form[values][]'
          value={companyValue}
          label={titleize(companyValue)}
          color='ripePlum'
          checked={companyValues.includes(companyValue)}
          onChange={handleChange}
          controlled={true}
        />
      </div>
    ));

  return (
    <div className={styles.section} id='companyValues'>
      <div className={styles.header}>
        <h4 className={styles.sectionTitle}>Values</h4>
        <span className={styles.sectionSub}>{`Choose up to ${maxValues} values`}</span>
      </div>
      <div className={styles.companyValuesSection}>{renderCompanyValues()}</div>
    </div>
  );
}
