import * as React from 'react';

const styles = require('./CompanyPageSectionTitle.module.scss');

interface Props {
  title: string;
}

export default function CompagePageSectionTitle({ title }: Props) {
  return <h2 className={styles.title}>{title}</h2>;
}
