import classNames from 'classnames';
import React from 'react';
const styles = require('./handpicked_notice.module.scss');

interface Props {
  avatarUrl: string;
  className?: string;
  name: string;
  note?: string;
}

export default function HandpickedNotice(props: Props) {
  const note = props.note || `${props.name} has Handpicked this application for you`;

  return (
    <div className={classNames(styles.container, props.className)}>
      <img alt='avatar' className={styles.avatar} src={props.avatarUrl} />
      <span>{note}</span>
    </div>
  );
}
