import React, { useRef } from 'react';
import Modal, { ButtonsLayout } from '../../general/modal/modal';
import Icon, { Size } from '../../general/icon/icon';
import { postFormData, handleRequestError, handleSuccessAlert } from 'lib/request';
import InformationBitSkill from './informationBitSkill';

const styles = require('./informationBitsModal.module.scss');

export interface InformationBit {
  id: number;
  value: string;
  amount?: number;
  category: string;
  job_ad_count?: number;
}

interface Props {
  informationBits: InformationBit[];
  modalOpen?: boolean;
}

export default function InformationBitsModal(props: Props) {
  const modalRef = useRef(null);
  const infoBitsSkills = props.informationBits?.filter(bit => bit.category === 'skill');

  const handleOnSubmit = event => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const acceptPath = window.Routes.accept_dashboard_information_bits();
    postFormData(acceptPath, formData, {
      Accept: 'application/json',
    })
      .then(data => {
        handleSuccessAlert(data);
        modalRef.current.close();
        window.toggleSpinner(false);
      })
      .catch(handleRequestError);
  };

  const modalTitle = (
    <span className={styles.infoBitsModalTitle}>
      <Icon name='helpCircle' size={Size.MediumBig} color='white' /> WE WANT YOUR HELP
    </span>
  );

  return (
    <Modal
      title={modalTitle}
      defaultOpen={props.modalOpen}
      ref={modalRef}
      buttonsLayout={ButtonsLayout.CancelOk}
      buttonName={'Save'}
      buttonColor='tuftsBlue'
      isButton
      buttonType='submit'
      modalForm
      onSubmitHandler={handleOnSubmit}
      cancelButton
      disableClose
      className={styles.infoBitsModal}
      roundButtons
    >
      <div className={styles.infoBitsIntro}>
        <p>
          We have information that can make your profile even more interesting. Confirm and
          we&apos;ll automatically add it to your profile:
        </p>
      </div>
      <div className={styles.infoBitsContainer}>
        <div>
          <select name={'all_info_bits[]'} multiple hidden>
            {props.informationBits.map(bit => {
              return <option selected value={bit.id} key={bit.id.toString()} />;
            })}
          </select>
        </div>
        <h4 className={styles.infoBitCategory}>SKILLS</h4>
        {infoBitsSkills?.map(bit => {
          return (
            <InformationBitSkill
              key={`info-bit-skill-${bit.id}`}
              id={bit.id}
              value={bit.value}
              amount={bit.amount}
              category={bit.category}
              job_ad_count={bit.job_ad_count}
            />
          );
        })}
      </div>
    </Modal>
  );
}
