import React from 'react';
import { Person, Application, HiringStep } from '../../__models__/models';
import dateFormat from '../../lib/format_date';
import ApplicationTags from '../general/application_tags/application_tags';
import ApplicationStateButtons from '../application_page/application_state_buttons';
import Chat from '../pages/chat';
import ApplicationTrail from '../general/application_trail/application_trail';
import ApplicationBodyFooter from '../application_page/application_card/application_body_footer';
import ApplicationSteps from '../application_page/application_steps';
import Checkbox from 'components/form/checkbox/checkbox';
import classNames from 'classnames';
import HandpickedNotice from './handpicked_notice';
import Icon from 'components/general/icon/icon';
import RatingDimensionContainer from '../general/rating_dimension/rating_dimension_container';
import SendApplicationByEmailModal from '../application_page/send_application_by_email_modal';
import { handleRequestError } from 'lib/request_deprecated';
import { concernText, DimensionsFromApplication, RatingsToDimensions } from './ratingFilterUtils';
import RatingConcernItem from 'components/general/rating_dimension/ratingConcern/RatingConcernItem';
import ApplicationHeaderButtonsContainer from './applicationHeaderButtons/ApplicationHeaderButtonsContainer';

import StepColor from '../application_page/step_color';
import Button from 'lj_shared/button/button';
import SelfEvaluationSection from './SelfEvaluationSection';

const styles = require('./ApplicationItem.module.scss');

interface Props {
  application: Application;
  chatUrl?: string;
  checkboxProps: any;
  currentStep: string;
  delivered: boolean;
  handshakeAccordion?: boolean;
  hideCheckbox?: boolean;
  hideRatingContainer?: boolean;
  hiringSteps: HiringStep[];
  horizontal: boolean;
  moveToStep: any;
  onApplicationList: boolean;
  profileUrl: string;
  rejectApplication: any;
  seenList: number[];
  setApplication?: Function;
  stepLabel: string;
  tooManyApps?: boolean;
  topFilter: string;
  urls: any;
  waitingReview: boolean;
}

interface State {
  application: Application;
  comments: string;
  label: string;
  showRatingBody?: boolean;
  trail: boolean;
  ratingsWithDimensions: any;
  step: any;
}

const defaultRatingsWithDimensions = {
  skills: 0,
  language: 0,
  location: 0,
  experience: 0,
  overall: 0,
};

export default class ApplicationItem extends React.Component<Props, State> {
  modalRef: any;
  commentsRef: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      application: props.application,
      trail: false,
      label: null,
      showRatingBody: false,
      comments: null,
      ratingsWithDimensions: defaultRatingsWithDimensions,
      step: null,
    };

    this.handleChatClick = this.handleChatClick.bind(this);
    this.handleMoveToStep = this.handleMoveToStep.bind(this);
    this.toggleRatingBody = this.toggleRatingBody.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.modalRef = React.createRef();
    this.commentsRef = React.createRef();
  }

  setDimension = (dimension: string, rating: number) => {
    const newdimensions = { ...this.state.ratingsWithDimensions };
    newdimensions[dimension] = newdimensions[dimension] === rating ? 0 : rating;
    this.setState({
      ratingsWithDimensions: newdimensions,
    });
  };

  handleChange(event) {
    event.preventDefault();

    this.setState({ comments: event.target.value });
  }

  handleCancel() {
    this.modalRef.current.close();
  }

  handleChatClick() {
    const id = this.props.application.attributes.person.attributes.user_id;
    Chat.openConversationWithCandidate(id);
  }

  handleMoveToStep(step = null, app) {
    const state_name = this.props.application.attributes.state_name;
    if (['pending_information', 'curated', 'unreviewed'].includes(state_name)) {
      this.setState(() => ({
        trail: true,
        label: 'In Process',
      }));

      setTimeout(() => {
        this.setState(() => ({ trail: false }));
      }, 5000);
    }
    this.props.moveToStep(step, app);
  }

  toggleRatingBody = () =>
    this.setState(prevState => ({
      showRatingBody: !prevState.showRatingBody,
    }));

  handleSendByEmailClick = event => {
    event.preventDefault();
    SendApplicationByEmailModal.show(
      this.props.urls.email_url,
      this.props.topFilter,
      this.props.moveToStep,
      this.props.hiringSteps,
      this.props.application
    ).catch(handleRequestError);
  };

  render() {
    const application = this.state.application.attributes;
    const rejected = application.state === 97 || application.state === 99;
    const person: Person = application.person;
    const { person_tags } = person.attributes;

    const { submitted_at, updated_at, application_tags, test_results } = application;
    const submittedAt = dateFormat(submitted_at, {
      month: 'long',
      day: 'numeric',
    });
    const updatedAt = dateFormat(updated_at, { month: 'long', day: 'numeric' });
    const currentStep =
      application.current_state !== 'state-unreviewed'
        ? Object.values(application.hiring_steps).find(
            (step: HiringStep) => step.value === application.current_state
          )
        : null;
    const isNew =
      !this.props.seenList.some(item => this.props.application.id === item.toString()) &&
      !application.seen_by_employer;
    const klasses = classNames(
      this.props.horizontal ? styles.horizontalWrapper : styles.verticalWrapper,
      { [styles.isHandpicked]: this.props.application.attributes.is_handpicked }
    );
    const dimensions = DimensionsFromApplication(
      this.state.application,
      true,
      this.props.horizontal
    );
    const [concern, concernNote, concernLevel] = concernText(this.props.application);

    if (this.state.trail && this.props.onApplicationList) {
      return <ApplicationTrail application={this.props.application} label={this.state.label} />;
    } else if (this.props.horizontal) {
      return (
        <div className={klasses}>
          {this.renderTitle()}
          {this.renderHorizontalCardCvButton()}
          <div className={styles.concern}>
            {concern && (
              <RatingConcernItem
                concern={concern}
                concernNote={concernNote}
                concernLevel={concernLevel}
              />
            )}
          </div>
          <div className={styles.rating}>
            <RatingDimensionContainer
              dimensions={RatingsToDimensions(dimensions, null)}
              maxRating={5}
              hasTooltip={false}
              showHeader
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className={klasses}>
          {isNew && <div className={styles.newTag}>New</div>}
          <div className={styles.innerWrapper}>
            <div className={styles.header}>
              {this.props.hideCheckbox ? (
                <div className={styles.checkboxPlaceholder} />
              ) : (
                <Checkbox {...this.props.checkboxProps} />
              )}
              {this.renderButtons()}
            </div>
            {this.renderTitle()}
            {renderHandpickedNotice(this.props.application)}
            <ApplicationStateButtons
              delivered={this.props.delivered}
              moveToStep={this.handleMoveToStep}
              rejectApplication={this.props.rejectApplication}
              application={this.props.application}
              small={false}
              waitingReview={this.props.waitingReview}
              className={styles.bottomButtons}
              buttonClasses={styles.buttonclasses}
            />
            {rejected && (
              <div className={styles.rejectedStepColor}>
                <StepColor
                  className={styles.rejectedStepColorCircle}
                  slug={this.props.currentStep}
                />
                <div className='stepText'>{this.props.stepLabel}</div>
              </div>
            )}
            {application.rating && (
              <RatingDimensionContainer
                dimensions={RatingsToDimensions(dimensions, null)}
                maxRating={5}
                hasTooltip={true}
                showHeader
                showDimensions
              />
            )}
            <ApplicationSteps
              hiringSteps={this.props.hiringSteps}
              currentStep={this.props.currentStep}
              delivered={this.props.delivered}
              stepLabel={this.props.stepLabel}
              moveToStep={this.handleMoveToStep}
              showLabel={true}
              application={this.props.application}
              urls={this.props.urls}
            />
            <RatingConcernItem
              concern={concern}
              concernNote={concernNote}
              concernLevel={concernLevel}
            />

            {application.self_evaluations.length >= 1 && this.props.topFilter !== 'inbox' && (
              <SelfEvaluationSection
                evaluations={application.self_evaluations}
                jobAd={application.job_ad}
                multiple={!application.rating}
              />
            )}

            <ApplicationBodyFooter {...{ submittedAt, updatedAt, vertical: false }} />
          </div>
          <div className={styles.tagsContainer}>
            <ApplicationTags
              application={this.props.application}
              personTags={person_tags}
              applicationTags={application_tags}
              testResults={test_results}
              testNotSent={
                currentStep && currentStep.test_name !== null && test_results.length === 0
              }
              handshake={this.props.application.attributes.handshake}
              recipientName={this.props.application.attributes.person.attributes.name}
              jobTitle={this.props.application.attributes.job_title}
              className={styles.tags}
            />
          </div>
        </div>
      );
    }
  }

  renderButtons = () => {
    return (
      <ApplicationHeaderButtonsContainer
        application={this.props.application}
        topFilter={this.props.topFilter}
        urls={this.props.urls}
        waitingReview={this.props.waitingReview}
        moveToStep={this.props.moveToStep}
      />
    );
  };

  renderHorizontalCardCvButton = () => {
    const hasCv = this.props.application.attributes.person.attributes.has_cv;
    return (
      <div className={styles.cvButton}>
        <Button
          buttonColor='ripePlum'
          buttonSize='xSmall'
          buttonUrl={hasCv && this.props.urls.pdf_url}
          targetBlank={true}
          isButton={!hasCv}
          disabled={!hasCv}
        >
          <Icon name='export' color={hasCv ? 'white' : 'silverSand'} />
          CV
        </Button>
      </div>
    );
  };

  renderTitle = () => {
    const person: Person = this.props.application.attributes.person;
    const renderAvatar = () =>
      person.attributes.recipient_avatar && (
        <div
          className={styles.avatar}
          dangerouslySetInnerHTML={{
            __html: person.attributes.recipient_avatar,
          }}
        />
      );
    const titleUrl = this.props.onApplicationList
      ? this.props.application.attributes.urls.application_url
      : this.props.waitingReview
      ? null
      : this.props.profileUrl;

    if (this.props.horizontal) {
      return (
        <div className={styles.horizontalTitle}>
          {this.props.hideCheckbox ? (
            <div className={styles.checkboxPlaceholder} />
          ) : (
            <Checkbox {...this.props.checkboxProps} />
          )}
          <div className={styles.horizontalTitleContainer}>
            {renderAvatar()}
            {titleUrl ? (
              <a
                href={titleUrl}
                className={styles.horizontalTitleName}
                target='_blank'
                rel='noreferrer'
              >
                {person.attributes.name}
              </a>
            ) : (
              <div className={styles.titleValue}>{person.attributes.name}</div>
            )}
          </div>
          <div className={styles.horizontalJobTitle}>
            {this.props.application.attributes.job_ad.title}
          </div>
        </div>
      );
    }

    return (
      <div className={styles.title}>
        {renderAvatar()}
        <div className={styles.titleInfo}>
          {titleUrl ? (
            <a href={titleUrl} className={styles.titleName} target='_blank' rel='noreferrer'>
              {person.attributes.name}
            </a>
          ) : (
            <div className={styles.titleValue}>{person.attributes.name}</div>
          )}
          <div className={styles.titleExperience}>{person.attributes.experience_years.label}</div>
          <div className={styles.titleLocation}>
            <Icon name='location' />
            {person.attributes.location}
          </div>
        </div>
      </div>
    );
  };
}

function renderHandpickedNotice(application) {
  const talentManager = application.attributes.talent_manager;
  if (application.attributes.is_handpicked && talentManager) {
    return (
      <HandpickedNotice
        avatarUrl={talentManager.avatar_url}
        className={styles.handpickedNotice}
        name={talentManager.name}
        note={application.attributes.handpicked_note}
      />
    );
  }
}
