import * as React from 'react';
import ApplicationInfo, { Props } from './application_info';
import { shallow, ShallowWrapper } from 'enzyme';
import applicationDummy from '__fixtures__/application_dummy';

const createTestProps = moreProps => {
  return {
    application: applicationDummy,
    applicationQuestions: [],
    ...moreProps,
  };
};

let props: Props;
let wrapped: ShallowWrapper;

describe('Application Info component', () => {
  beforeEach(() => {
    props = createTestProps({});
    wrapped = shallow(<ApplicationInfo {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
