import React, { useEffect, useRef, useState } from 'react';
import Button from 'lj_shared/button/button';
import Modal, { ButtonsLayout } from 'components/general/modal/modal';
import JobList from './JobList';
import { Company, Job } from './types';
import usePostJobModal from './usePostJobModal';
import JobTypeButtons from './JobTypeButtons';
interface Props {
  showCompanySelect?: boolean;
  companyId?: number;
  companyName: string;
  title?: string;
}

const PostJobModal = (props: Props) => {
  const { companyId, companyName, title, showCompanySelect = false } = props;
  const modalRef = useRef(null);
  const [screenType, setScreenType] = useState<string>('jobTypeSelect');
  const [company, setCompany] = useState<Company>(
    companyId &&
      companyName && {
        value: companyId,
        label: companyName,
      }
  );
  const [job, setJob] = useState<number>();
  const [companies, setCompanies] = useState<Company[]>();
  const [jobList, setJobList] = useState<Job[]>();
  const [submitError, setSubmitError] = useState(false);

  const { fetchCompanies, fetchJobs } = usePostJobModal();

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    if (screenType === 'jobListContent') {
      getJobList(company);
    }
  }, [screenType]);

  const resetFields = () => {
    !companyId && setCompany(null);
    setJob(null);
    setSubmitError(false);
    setJobList(null);
    setScreenType('jobTypeSelect');
    setScreenType('jobTypeSelect');
  };

  const handleClick = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    modalRef.current.open();
  };

  const getCompanies = () => {
    if (companies) {
      return;
    }

    fetchCompanies(setCompanies);
  };

  const getJobList = (currentCompany: Company) => {
    fetchJobs(currentCompany?.value, setJobList);
  };

  const handleOnSubmit = (event: { stopPropagation(): unknown; preventDefault: () => void }) => {
    event.preventDefault();
    event.stopPropagation();

    if (!job) {
      window.toggleSpinner(false);
      setSubmitError(true);
      return;
    }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    fetch(window.Routes.duplicate_job_backoffice_admin_post_job_index(), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
      body: JSON.stringify({
        job_id: job,
      }),
    })
      .then(response => {
        if (response.status === 200) {
          response.json().then(data => {
            if (data.error) {
              window.Alerts.error('Something went wrong. Please try again.');
              return;
            }
            window.location.href = data.redirect_url;
          });
        }
      })
      .catch(() => {
        window.Alerts.error('Something went wrong. Please try again.');
      });
  };

  const hideButton = () => screenType === 'jobTypeSelect' && jobList?.length === 0;

  return (
    <>
      <Button
        onClick={handleClick}
        otherClasses={
          'ld-button ld-small-button ld-blue-button ld-border-button u-marginRight--small'
        }
        extraStyle={{ height: 'auto' }}
      >
        {title ?? 'Post job'}
      </Button>
      <Modal
        data-testid='post-job-modal'
        buttonColor={hideButton() ? 'tuftsBlue' : null}
        buttonName={hideButton() ? 'Start' : null}
        disableOutsideClose={true}
        ref={modalRef}
        title='Post new job'
        isButton
        buttonType='submit'
        modalForm
        onSubmitHandler={handleOnSubmit}
        buttonsLayout={ButtonsLayout.CancelOk}
        onClose={() => resetFields()}
      >
        <div>
          {screenType === 'jobTypeSelect'
            ? JobTypeButtons({
                showCompanySelect,
                company,
                companies,
                setCompany,
                setScreenType,
                submitError,
                setSubmitError,
              })
            : JobList({ jobList, job, setJob, submitError })}
        </div>
      </Modal>
    </>
  );
};

export default PostJobModal;
