import React from 'react';
import ContractTypeTooltip from './contract_type_tooltip';
import { fireEvent, render } from '@testing-library/react';

describe('ContractTypeTooltip', () => {
  it('shows the content', () => {
    const { queryByText } = render(<ContractTypeTooltip />);
    expect(queryByText('Permanent employee', { exact: false })).toBeTruthy();
  });
});
