import * as React from 'react';
import Selectize from 'components/general/selectize/selectize';
import Icon, { Style } from 'components/general/icon/icon';
const styles = require('./preferred_language_proficiency_input.module.scss');

interface Props {
  preferredLanguageName: string;
  proficiencyLevels: {
    value: string;
    description: string;
  }[];
  errorMessage?: string;
}

export default function PreferredLanguageProficiencyInput(props: Props) {
  return (
    /*
     * We are using the assets pipeline here because we want to make sure
     * this component looks like all the other components on the apply page
     * without having to duplicate the css or extend it.
     */
    <section className='ld-apply-section'>
      <div className='ld-container ld-small-container'>
        <div className='ld-row ld-form-group'>
          <label className='ld-h5'>
            Languages <span className='ld-form-required'>*</span>
          </label>
          <p className='ld-form-help-text u-marginBottom--mid'>
            This job requires knowledged in <strong>{props.preferredLanguageName}</strong>, please
            select your proficiency level.
          </p>
          <Selectize
            id='preferred_language_proficiency'
            name='application_form[preferred_language_proficiency]'
            color='tuftsBlue'
            items={props.proficiencyLevels.map(item => ({
              value: item.value,
              text: item.description,
            }))}
            placeholder='Proficiency Level'
          />
        </div>
        {props.errorMessage && (
          <div className='ld-alert ld-error-alert'>
            <Icon className={styles.close} name='close' color='white' style={Style.Bold} />
            <span className='error_text'>{props.errorMessage}</span>
          </div>
        )}
      </div>
    </section>
  );
}
