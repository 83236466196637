import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import FeatureCta from './FeatureCta';

const createProps = () => ({
  type: 'RPO Service',
  color: 'madeixaMadura',
});

let props;
let wrapped: ShallowWrapper;

describe('FeatureCta', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<FeatureCta {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
