import React from 'react';

import 'lib/globals';
import Button from 'lj_shared/button/button';
import Icon from 'components/general/icon/icon';
import BillingInfo, { BillingInfoProps } from './billing_info';

const styles = require('./placement_page.module.scss');

interface Invoice {
  code: string;
  reportMonth: string;
  status: string;
  value: string;
  workedDays: string;
}

interface Props {
  billingInfo?: BillingInfoProps;
  companyName: string;
  contractUrl?: string;
  endDate: string;
  expectedDuration: string;
  id: string;
  invoices: Invoice[];
  projectDescription: string;
  projectName: string;
  startDate: string;
  status: string;
  totalPaid: string;
}

export default function PlacementPage(props: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <div className={styles.projectsLinkContainer}>
          <Icon name='arrowLeft' />
          <a className={styles.projectsLink} href={window.Routes.dashboard_placements()}>
            Projects list
          </a>
        </div>
        {renderPlacement(props)}
        {props.invoices.length > 0 && renderInvoices(props.id, props.totalPaid, props.invoices)}
      </div>
      {renderSidebar({ projectId: props.id, ...props.billingInfo })}
    </div>
  );
}

function renderPlacement(props) {
  return (
    <div>
      <div className={styles.projectHeader}>
        <span className={styles.projectName}>{props.projectName}</span> -{' '}
        <span className={styles.companyName}>{props.companyName}</span>
      </div>
      <div>
        <span className={styles.projectDuration}>
          Expected duration: <strong>{props.expectedDuration}</strong>
        </span>
        <span className={styles.projectDates}>
          Start date: <span className={styles.date}>{props.startDate}</span> - End date:{' '}
          <span className={styles.date}>{props.endDate || '...'}</span>
        </span>
      </div>
      {props.contractUrl && (
        <div className={styles.contract}>
          <a
            href={props.contractUrl}
            target='_blank'
            className={styles.contractUrl}
            rel='noreferrer'
          >
            View Contract
          </a>
        </div>
      )}
      {props.projectDescription && (
        <div className={styles.projectDescription}>{props.projectDescription}</div>
      )}
      {renderReportInvoiceButton(props.status, props.id)}
    </div>
  );
}

function renderSidebar(props) {
  return (
    <div className={styles.sideBar}>
      <BillingInfo {...props} />
    </div>
  );
}

function renderReportInvoiceButton(status, id) {
  if (status === 'active') {
    return (
      <div className={styles.reportInvoiceButtonContainer}>
        <Button
          buttonColor='tuftsBlue'
          buttonSize='small'
          buttonUrl={window.Routes.new_dashboard_placement_invoice(id)}
        >
          Report new invoice
        </Button>
      </div>
    );
  }
}

function renderInvoices(projectId, totalPaid, invoices) {
  return (
    <div className={styles.invoicesSection}>
      <div className={styles.invoicesHeader}>
        <span>Reported invoices</span>
        <span>
          Total invoices paid: <span className={styles.totalPaid}>{totalPaid}</span>
        </span>
      </div>
      <table className={styles.invoicesTable}>
        <thead>
          <tr className={styles.invoicesTableHeadRow}>
            <td className={styles.invoicesTableHeadCell}>Invoice ID</td>
            <td className={styles.invoicesTableHeadCell}>Reported month</td>
            <td className={styles.invoicesTableHeadCell}>Worked days</td>
            <td className={styles.invoicesTableHeadCell}>Reported value</td>
            <td className={styles.invoicesTableHeadCell}>Status</td>
          </tr>
        </thead>
        <tbody>{invoices.map(renderInvoiceRow.bind(null, projectId))}</tbody>
      </table>
    </div>
  );
}

function renderInvoiceRow(projectId, invoice) {
  return (
    <tr className={styles.invoicesTableRow} key={invoice.id}>
      <td className={styles.invoicesTableCodeCell}>
        <a href={window.Routes.dashboard_placement_invoice(projectId, invoice.code)}>
          {invoice.code}
        </a>
      </td>
      <td className={styles.invoicesTableMonthCell}>{invoice.reportMonth}</td>
      <td className={styles.invoicesTableDaysCell}>{invoice.workedDays}</td>
      <td className={styles.invoicesTableValueCell}>{invoice.value}</td>
      <td className={styles.invoicesTableCell}>{invoice.status}</td>
    </tr>
  );
}
