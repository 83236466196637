import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import Title from './Title';
import ApplyButton from './ApplyButton';

const jobDummy = require('components/jobs_page/fixtures/job_ad_dummy.json');

window.Routes = {
  company: () => '',
};

const createProps = fixedHeaderABTest => ({
  title: 'Software Developer',
  companyName: 'Landing.Jobs',
  fixedHeader: fixedHeaderABTest,
  logo: 'logoUrl',
  shortPitch:
    'Candidate-driven tech careers platform – we matchmake tech talent with the right opportunities.',
  companyId: 8,
  jobAd: jobDummy,
});

let props;
let wrapped: ShallowWrapper;

describe('Title renders correctly', () => {
  beforeEach(() => {
    props = createProps(false);
    wrapped = shallow(<Title {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('should have an image', () => {
    expect(wrapped.find('img')).toHaveLength(1);
  });

  it('should have a link', () => {
    expect(wrapped.find('a')).toHaveLength(1);
  });

  it('should have a line', () => {
    expect(wrapped.find('hr')).toHaveLength(1);
  });

  it('should have the pitch', () => {
    expect(wrapped.find('.pitch').text()).toBe(
      'Candidate-driven tech careers platform – we matchmake tech talent with the right opportunities.'
    );
  });

  it('should have a title', () => {
    expect(wrapped.find('.title').text()).toBe('Software Developer');
  });

  it('should not have an apply button', () => {
    expect(wrapped.find(ApplyButton)).toHaveLength(0);
  });

  it('should have 1 apply button', () => {
    props = createProps(true);
    wrapped = shallow(<Title {...props} />);
    expect(wrapped.find(ApplyButton)).toHaveLength(1);
  });
});
