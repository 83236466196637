import React, { useState } from 'react';
import FormInput from 'components/general/form_input/form_input';
import Checkbox from 'components/form/checkbox/checkbox';
import RateRange from './RateRange';
import GrossAnnualSalaryRange from './GrossAnnualSalaryRange';
import { Size } from 'components/general/tooltip/tooltip';

const styles = require('./JobInterestsModalContainer.module.scss');

interface Props {
  error?: string;
  handleFormChange: (event: any, input: string) => void;
  jobTypesInterest: { id: number; label: string }[];
  jobTypesList: Object;
  label?: string;
  rate: { minimum: number; maximum: number };
  gas: { minimum: number; maximum: number };
  showRateAndGas?: boolean;
  showRemotePreferences?: boolean;
}

const PERMANENT_EMPLOYEE_JOB_TYPE_ID = 0;
const CONTRACTOR_JOB_TYPE_ID = 1;

const tooltipContent = [
  {
    id: 0,
    title: 'Permanent employee',
    description: 'Someone who works for an employer and is paid directly by that employer.',
  },
  {
    id: 1,
    title: 'Contractor',
    description:
      'Someone who chooses to set up their own company or work as an independent worker to provide services to 1 client at a time on a mid to long-term project basis.',
  },
  {
    id: 2,
    title: 'Remote',
    description: 'Working full-time away from the HQ from home or any other remote location.',
  },
  {
    id: 3,
    title: 'Hybrid',
    description: 'Having the possibility to spare your working hours between the HQ or remotely.',
  },
  {
    id: 5,
    title: 'Onsite',
    // eslint-disable-next-line quotes
    description: "Someone who works at the employer's office.",
  },
];

export default function JobTypeInterests(props: Props) {
  const [jobTypesInterest, setjobTypesInterest] = useState(props.jobTypesInterest);

  const updateJobTypesInterest = event => {
    const updatedJobTypesInterest = computeJobTypesChange(event.target, jobTypesInterest);
    setjobTypesInterest(updatedJobTypesInterest);
    props.handleFormChange(updatedJobTypesInterest, 'jobTypesInterest');
  };

  let jobList = props.jobTypesList;

  // Create tooltip content for each job type, filter out the remote policy preferences if needed
  const tooltip = tooltipContent
    .filter(tt => {
      if (!props.hasOwnProperty('showRemotePreferences') || props.showRemotePreferences) {
        return true;
      } else if (
        !props.showRemotePreferences &&
        (tt.id === PERMANENT_EMPLOYEE_JOB_TYPE_ID || tt.id === CONTRACTOR_JOB_TYPE_ID)
      ) {
        return true;
      } else {
        return false;
      }
    })
    .map(({ title, description }) => (
      <span key={title.replace(/\s/g, '')} className={styles.tooltipLine}>
        <strong>{title}</strong>
        {` - ${description}`}
      </span>
    ));

  if (props.hasOwnProperty('showRemotePreferences') && !props.showRemotePreferences) {
    jobList = Object.keys(props.jobTypesList)
      ?.filter(
        jt =>
          props.jobTypesList[jt] === PERMANENT_EMPLOYEE_JOB_TYPE_ID ||
          props.jobTypesList[jt] === CONTRACTOR_JOB_TYPE_ID
      )
      .reduce((obj, key) => {
        obj[key] = props.jobTypesList[key];
        return obj;
      }, {});
  }

  return (
    <>
      <FormInput
        name='job_types'
        label={props.label || 'What type of jobs are you interested in?'}
        error={props.error}
        required={true}
        tooltip={{ content: tooltip, size: Size.Default }}
        sublabel='Help me here'
        tooltipOnSublabel
      >
        {Object.entries(jobList).map(([label, value]) => (
          <div className={styles.checkbox} key={value.toString()}>
            <Checkbox
              onChange={event => updateJobTypesInterest(event)}
              name={label.toString()}
              value={value.toString()}
              controlled={false}
              label={label}
              checked={jobTypesInterest.some(jobTypeInterest => jobTypeInterest.id === value)}
            />
          </div>
        ))}
      </FormInput>
      {props.jobTypesInterest.some(
        jobTypeInterest => jobTypeInterest.id === PERMANENT_EMPLOYEE_JOB_TYPE_ID
      ) &&
        props.showRateAndGas && (
          <GrossAnnualSalaryRange gas={props.gas} handleFormChange={props.handleFormChange} />
        )}
      {props.jobTypesInterest.some(
        jobTypeInterest => jobTypeInterest.id === CONTRACTOR_JOB_TYPE_ID
      ) &&
        props.showRateAndGas && (
          <RateRange rate={props.rate} handleFormChange={props.handleFormChange} />
        )}
    </>
  );
}

const computeJobTypesChange = (target, jobTypesInterest) => {
  const id = Number(target.value);
  const label = target.name;
  const checked = target.checked;

  if (checked) {
    jobTypesInterest.push({ id, label });
  } else {
    jobTypesInterest = jobTypesInterest.filter(i => i.id !== id);
  }
  return jobTypesInterest;
};
