import React from 'react';
import Modal from '../general/modal/modal';
import Button from 'lj_shared/button/button';
import InlineIcon from '../general/inline_icon/inline_icon';
const arrow = require('components/dropdown-arrow-green.svg');
import {
  CarouselProvider,
  Slider,
  Slide,
  DotGroup,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

const styles = require('./curation_tour_modal.module.scss');
const tourContent = [
  {
    image: 'https://assets.landing.jobs/cms-assets/files/1738/original/multidimensional_tour_1.png',
    text: [
      'Curation will, from now on, be divided into five dimensions. The dimension you are currently curating is identified here.',
    ],
  },
  {
    image: 'https://assets.landing.jobs/cms-assets/files/1737/original/multidimensional_tour_2.png',
    text: [
      'You will have to check the information in this section, but most of the times it is already filled so it will only take a quick check.',
    ],
  },
  {
    image: 'https://assets.landing.jobs/cms-assets/files/1736/original/multidimensional_tour_3.png',
    text: ['The rating will be automatically calculated according to the information below.'],
  },
  {
    image: 'https://assets.landing.jobs/cms-assets/files/1735/original/multidimensional_tour_4.png',
    text: [
      'A set of notes will be automatically created from the information above. But you can still add as many notes as you want in the text field below.',
    ],
  },
  {
    image: 'https://assets.landing.jobs/cms-assets/files/1734/original/multidimensional_tour_5.png',
    text: [
      'If you feel like there is something wrong with the ratings or with the new model in general you can opt for going back to the old model but this is an option you will have to justify to the team at Landing.jobs.',
    ],
  },
  {
    image: 'https://assets.landing.jobs/cms-assets/files/1733/original/multidimensional_tour_6.png',
    text: [
      'Here is where you can access the candidate’s links. A specially important thing to check is the CV.',
    ],
  },
  {
    image: 'https://assets.landing.jobs/cms-assets/files/1732/original/multidimensional_tour_7.png',
    text: [
      'Here is where you have all the information about the job and the candidate. Each time you move on to a different dimension, the related section will be highlighted.',
    ],
  },
  {
    image:
      'https://assets.landing.jobs/cms-assets/files/1739/original/multidimenional_tour_8_9.png',
    text: [
      'If you feel like the application doesn’t have enough information, you can request the candidate for more information.',
      'A request for more info can only be done once, so if a curator has already done it in the previous attempt to curate the button will be disabled.',
    ],
  },
  {
    image:
      'https://assets.landing.jobs/cms-assets/files/1778/original/multidimensional_tour_10_11.png',
    text: [
      'The Overall rating will also be automatically calculated by the average of the 5 dimensions (except when bonus is neutral, which means it is not included in the average, which will only take the 4 dimensions into calculation).',
      'Every time there is a rating 1 in one dimension it means that the candidate will be rejected, regardless of the average and Overall rating.',
    ],
  },
];

export default class CurationTourModal extends React.Component<{}> {
  modalRef: any;

  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
    this.handleGotIt = this.handleGotIt.bind(this);
  }

  open() {
    this.modalRef.current.open();
  }

  renderCaption(index, text) {
    return index === 8 // the 8th slide has two numbers so we need to add 2 to the index to match it
      ? index + 2 + text
      : index + 1 + text;
  }

  render() {
    return (
      <Modal title={'HOW IT WORKS'} defaultOpen={false} ref={this.modalRef} disableClose={true}>
        <div>
          <div className={styles.text}>
            Are you having any doubts about the curation process and how to navigate on the platform
            while curating? This is the right place to be! Check out the steps below to understand
            how it works:
          </div>
        </div>
        <div>
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={65}
            totalSlides={tourContent.length}
            dragEnabled={false}
          >
            <ButtonBack className={styles.previousButton}>
              <InlineIcon path={arrow} alt='previous' />
            </ButtonBack>
            <ButtonNext className={styles.nextButton}>
              <InlineIcon path={arrow} alt='next' />
            </ButtonNext>
            <Slider>
              {tourContent.map((slideContent, indexSlide) => (
                <Slide index={indexSlide} key={indexSlide} className={styles.slide}>
                  <div className={styles.content}>
                    <img src={slideContent.image} />
                    <div className={styles.caption}>
                      {slideContent.text.map((text, indexText) => (
                        <div key={indexSlide * 10 + indexText} className={styles.line}>
                          <div className={styles.circle}>
                            {this.renderCaption(indexSlide, indexText)}
                          </div>
                          <div>{text}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </Slide>
              ))}
            </Slider>
            <DotGroup className={styles.dotGroup} />
          </CarouselProvider>
        </div>
        <div className={styles.buttons}>
          <Button buttonColor='puertoRico' onClick={this.handleGotIt}>
            GOT IT!
          </Button>
        </div>
      </Modal>
    );
  }

  handleGotIt() {
    this.modalRef.current.close();
  }
}
