import React from 'react';
import HomepageButtons from './homepageButtons';

const styles = require('./hero.module.scss');

let myRef;

interface Props {
  language: {
    title: string[];
    text: string[];
    buttons: {
      contractor: string;
      company: string;
    };
  };
}

interface State {
  width: number;
}

/**
 * Component which renders the landing Hero and the 'HomepageButtons'
 * components
 */

class Hero extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    myRef = React.createRef();
    this.state = {
      width: 0, // or your default width here
    };
  }

  /**
   * Function which on component mount checks live inner width size
   * of the window
   */

  componentDidMount() {
    this.handleWindowSizeChange(); // Set width
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  /**
   * Function applied to onClick event on a button to scroll the user down to the
   * next section of the landing page
   */

  scrollToMyRef = () => {
    if (window.screen.width < 767) {
      window.scrollTo(0, myRef.current.offsetTop - 100);
    } else {
      window.scrollTo(0, myRef.current.offsetTop - 80);
    }
  };

  render() {
    const { title, text, buttons } = this.props.language;

    return (
      <div className={styles['lw-hero-container']}>
        <div className={styles['lw-hero-padding']}>
          <div className={styles['lw-hero']}>
            <div className={styles['lw-hero-text-wrapper']}>
              <div>
                <div className={styles['lw-hero-heading']}>
                  {title.map((titleString, index) => {
                    return (
                      <h2 className={styles.h1} key={index}>
                        {titleString}
                      </h2>
                    );
                  })}
                </div>
                <div className={styles['lw-hero-text']}>
                  <p>{text[0]}</p>
                  <p>{text[1]}</p>
                </div>
              </div>
              <div className={styles['midButtons-wrapper']}>
                <HomepageButtons trackingAction='hero' language={buttons} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Hero;
