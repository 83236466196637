class TransferForm {
  applicationId: number;
  candidateId: number;
  closeCurrentApplication: boolean;
  jobAdId: number;
  messageToCandidate: string;

  private initialArgs;

  constructor(args) {
    this.initialArgs = args;
    this.reset();
  }

  reset() {
    Object.keys(this.initialArgs).forEach(k => {
      this[k] = this.initialArgs[k];
    });
  }

  serialize() {
    return {
      application_id: this.applicationId,
      candidate_id: this.candidateId,
      job_ad_id: this.jobAdId,
      message_to_candidate: this.messageToCandidate,
      close_current_application: this.closeCurrentApplication,
    };
  }

  validate() {
    return this.jobAdId != null;
  }
}

export default TransferForm;
