import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Modal, { ButtonsLayout } from '../general/modal/modal';
import Checkbox from '../form/checkbox/checkbox';
const styles = require('./publish_modal.module.scss');
const iconPublish = require('iconic/bullhorn.svg');
import { JobAd } from '../../__models__/models';

interface Props {
  jobAd: JobAd;
  termsOfService: string;
  privacy: string;
  handleDone: Function;
}

class PublishModal extends React.Component<Props> {
  modalRef: any;
  scrollRef: any;

  constructor(props: Props) {
    super(props);
    this.modalRef = React.createRef();
    this.scrollRef = React.createRef();

    this.handlePublish = this.handlePublish.bind(this);
    this.handleDontShowChange = this.handleDontShowChange.bind(this);
  }
  handlePublish(event) {
    const url = this.props.jobAd.attributes.urls.job_ad_publish;

    $.ajax({
      url,
      type: 'PUT',
    });
  }

  handleDontShowChange(event) {
    this.props.handleDone(event.target.checked);
  }

  render() {
    return (
      <Modal
        defaultOpen={true}
        ref={this.modalRef}
        title='Publish'
        cancelButton={true}
        buttonName='Publish'
        buttonOnClick={this.handlePublish}
        buttonIcon={iconPublish}
        buttonsLayout={ButtonsLayout.OkCancel}
      >
        <div className='modal-structure'>
          <p>
            By publishing your job you're making it publicly accessible through any Landing.jobs
            channel: Jobs Listing, RSS Feed, Newsletter, etc.
          </p>
          <p>
            You're also conforming with our{' '}
            <a className={styles.link} href={this.props.termsOfService}>
              Terms of Service
            </a>{' '}
            and{' '}
            <a className={styles.link} href={this.props.privacy}>
              Privacy Policy
            </a>
          </p>
          <div className={styles.lastRow}>
            <div className={styles.checkbox}>
              <Checkbox
                name='publish'
                label="Don't show this warning again"
                controlled={false}
                onChange={this.handleDontShowChange}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  static show(jobAd, tos, privacy, handleDone) {
    return new Promise<void>(resolve => {
      const root = $('<div></div>');

      function onDone() {
        root.remove();
        resolve();
      }

      $('body').append(root);

      ReactDOM.render(
        <PublishModal
          jobAd={jobAd}
          termsOfService={tos}
          privacy={privacy}
          handleDone={handleDone}
        />,
        root[0]
      );
    });
  }
}

export default PublishModal;
