import 'lib/globals';
import * as React from 'react';
import Sortable from 'react-sortablejs';
import uniqueId from 'lodash/uniqueId';

const HiringStepSelect = require('../../lib/hiring_step_select').HiringStepSelect;
import JobHiringStep from './job_hiring_step';
import { Step, AssessmentsList } from './models';
import ConfirmModal from 'components/general/confirm_modal/confirm_modal';
const { serializeSteps } = require('lib/hiring_steps');
const styles = require('./job_hiring_steps.module.scss');

interface Props {
  jobId: number;
  steps: Step[];
  deletedSteps: Step[];
  isRevision: boolean;
  urls: any;
  testIds?: any[];
  backoffice: boolean;
  standardTests: AssessmentsList;
  technicalAssessmentsEnabled: boolean;
  landingScreeningEnabled: boolean;
  screeningScript?: string[];
}

interface State {
  dummySteps: Step[];
  steps: Step[];
  finalSteps: Step[];
}

export default class JobHiringSteps extends React.Component<Props, State> {
  deletedSteps;
  changed;

  constructor(props) {
    super(props);

    const dummySteps = [makeDummyStep('add_a_new_step')];

    const steps = this.props.steps.map(s => ({ ...s, uid: uniqueId() }));
    const finalSteps = [
      {
        id: null,
        description: null,
        hasApplications: false,
        kind: 'fixed',
        message: null,
        test: null,
        name: 'proposal',
        order: finalStepsOrder(steps, 0),
        uid: uniqueId(),
      },
      {
        id: null,
        description: null,
        hasApplications: false,
        kind: 'fixed',
        message: null,
        test: null,
        name: 'hiring',
        order: finalStepsOrder(steps, 1),
        uid: uniqueId(),
      },
    ];
    this.state = { dummySteps, steps, finalSteps };
    this.handleDestroy = this.handleDestroy.bind(this);
    this.handleMessageSave = this.handleMessageSave.bind(this);
    this.handleReorder = this.handleReorder.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.landingScreeningExists = this.landingScreeningExists.bind(this);
    this.deletedSteps = this.props.deletedSteps;
    this.changed = false;
  }

  handleDestroy(step) {
    if (step.hasApplications) {
      this.showDestroyModal(step, this.props.isRevision);
    } else {
      this.showNoApplicationsDestroyModal(step);
    }
  }

  handleReorder(_order, sortable, event) {
    if (event.newIndex !== event.oldIndex) {
      window.toggleSpinner(true);
      const order = event.newIndex;
      const oldOrder = parseInt(event.item.dataset.order, 10);
      const idx = this.state.steps.findIndex(s => s.order === oldOrder);

      if (this.props.isRevision) {
        const steps = this.state.steps.slice();
        const newIdx = steps.findIndex(s => s.order === order);
        const [start, end, delta] = idx > newIdx ? [newIdx, idx, 1] : [idx + 1, newIdx + 1, -1];
        this.modifyOrder(steps, start, end, delta);
        steps[idx] = { ...steps[idx], order };
        steps.sort((a, b) => a.order - b.order);
        this.changed = true;
        this.setState({ steps });
        window.toggleSpinner(false);
      } else {
        const step = this.state.steps[idx];
        $.post(this.apiUrl('reorder', step.id), { order: order - 1 })
          .done(this.handleSaveSuccess.bind(this, step))
          .fail(this.handleSaveFail.bind(this));
      }
    }
  }

  handleSave(step) {
    if (this.props.isRevision) {
      const steps = this.state.steps.slice();
      let dummySteps = this.state.dummySteps;

      if (step['isDummy']) {
        step = { ...step };
        step.hasApplications = false;
        step.order = steps.length;
        step.jobId = this.props.jobId;
        step['isDummy'] = false;
        steps.push(step);
        dummySteps = dummySteps.map(s => makeDummyStep('add_a_new_step'));
        this.setFinalStepsOrder(steps);
      } else {
        const idx = steps.findIndex(s => s.uid === step.uid);
        steps[idx] = step;
      }

      this.changed = true;
      this.setState({ dummySteps, steps });
    } else {
      this.saveOnServer(step);
    }
  }

  handleMessageSave(step, object) {
    const name = step['isDummy'] ? 'dummySteps' : 'steps';
    const idx = this.state[name].findIndex(sameStep.bind(null, step));
    const steps = this.state[name].slice();
    steps[idx] = { ...step, object };
    this.changed = true;
    this.setState({ [name]: steps } as any);
  }

  handleSaveSuccess(oldStep, data) {
    window.toggleSpinner(false);

    let steps = data.steps || data;
    steps = steps.map(s => ({ ...s, uid: uniqueId() }));
    const state = { steps };

    const dummyStep = makeDummyStep('add_a_new_step');
    state['dummySteps'] = [dummyStep];

    this.setFinalStepsOrder(steps);
    this.changed = true;

    this.setState(state);
  }

  handleSaveFail() {
    window.toggleSpinner(false);
  }

  modifyOrder(steps, start, end, delta) {
    for (let i = start; i < end; i++) {
      const step = steps[i];
      const order = step.order + delta;
      steps[i] = { ...step, order };
    }
  }

  getStepIndex(steps, stepToFind) {
    return steps.findIndex(step => step.uid === stepToFind.uid);
  }

  removeStep(step, fosterStateId?) {
    if (step.id) {
      this.deletedSteps.push({
        id: step.id,
        deleted: true,
        fosterStateId,
      });
    }
    const idx = this.getStepIndex(this.state.steps, step);
    const steps = this.state.steps.slice();
    steps.splice(idx, 1);
    const delta = -1;
    this.modifyOrder(steps, idx, steps.length, delta);
    this.setFinalStepsOrder(steps);
    this.changed = true;
    window.toggleSpinner(false);
    this.setState({ steps });
  }

  landingScreeningExists() {
    return this.state.steps.some(step => step.kind === 'landing_screening');
  }

  render() {
    const steps = serializeSteps(this.state.steps, this.deletedSteps);
    return (
      <>
        {' '}
        <input type='hidden' name='hiring_step_changes' value={steps} />
        <div>
          <Sortable onChange={this.handleReorder}>
            {this.state.steps.map(s => (
              <JobHiringStep
                key={`${s.id || s.uid} - ${s.updated_at || s.created_at}`}
                isNew={false}
                jobId={this.props.jobId}
                step={s}
                onDestroy={this.handleDestroy}
                onMessageSave={this.handleMessageSave}
                onSave={this.handleSave}
                isRevision={this.props.isRevision}
                backoffice={this.props.backoffice}
                urls={this.props.urls}
                testIds={this.props.testIds}
                standardTests={this.props.standardTests}
                technicalAssessmentsEnabled={this.props.technicalAssessmentsEnabled}
                landingScreeningEnabled={this.props.landingScreeningEnabled}
                landingScreeningExists={this.landingScreeningExists()}
                screeningScript={this.props.screeningScript}
              />
            ))}
          </Sortable>
        </div>
        <div className={styles.addButtons}>
          {this.state.dummySteps.map(s => (
            <JobHiringStep
              isNew={true}
              backoffice={this.props.backoffice}
              isRevision={this.props.isRevision}
              jobId={this.props.jobId}
              key={s.uid}
              onMessageSave={this.handleMessageSave}
              onSave={this.handleSave}
              step={s}
              urls={this.props.urls}
              standardTests={this.props.standardTests}
              technicalAssessmentsEnabled={this.props.technicalAssessmentsEnabled}
              landingScreeningEnabled={this.props.landingScreeningEnabled}
              landingScreeningExists={this.landingScreeningExists()}
              screeningScript={this.props.screeningScript}
            />
          ))}
        </div>
        {this.state.finalSteps.map(s => (
          <JobHiringStep
            key={`${s.uid}`}
            isNew={false}
            jobId={this.props.jobId}
            step={s}
            isRevision={this.props.isRevision}
            backoffice={this.props.backoffice}
            urls={this.props.urls}
            standardTests={this.props.standardTests}
            finalSteps
            technicalAssessmentsEnabled={this.props.technicalAssessmentsEnabled}
            landingScreeningEnabled={this.props.landingScreeningEnabled}
            landingScreeningExists={this.landingScreeningExists()}
            screeningScript={this.props.screeningScript}
          />
        ))}
      </>
    );
  }

  saveOnServer(step) {
    const isPersisted = !!step.id;

    const data = {
      message: step.message,
      test: step.test,
      'job_hiring_step[kind]': step.kind,
      'job_hiring_step[name]': step.name,
      'job_hiring_step[description]': step.description,
      'job_hiring_step[manual_late_stage]': step.manual_late_stage,
    };

    const options = {
      data,
      dataType: 'json',
      type: isPersisted ? 'PUT' : 'POST',
      url: this.apiUrl(isPersisted ? 'update' : 'create', step.id),
    };

    window.toggleSpinner(true);

    $.ajax(options)
      .done(this.handleSaveSuccess.bind(this, step))
      .fail(this.handleSaveFail.bind(this));
  }

  showDestroyModal(step, isRevision) {
    const handler = { trigger: () => null };
    const url = this.apiUrl('destroyModal', step.id);
    const options = {
      class: 'lj-hiringStep-modal',
      okLabel: 'Move and delete',
      title: 'Delete current hiring step',
    };
    const modal = new window.ModalForm(handler, url, options);
    modal.addHandler('open', () => {
      new HiringStepSelect(modal.content().find('.js-select-hiringSteps'));
    });
    modal.addHandler('success', result => {
      modal.close();
      if (isRevision) {
        this.removeStep(step, result.fosterStateId);
      } else {
        this.handleSaveSuccess(step, result.steps);
      }
    });
    modal.render();
  }

  apiUrl(type, id) {
    switch (type) {
      case 'create':
        return this.props.urls.create;
      case 'destroy':
        return this.props.urls.destroy.replace('~id', id);
      case 'destroyModal':
        return this.props.urls.destroyModal.replace('~id', id);
      case 'destroy_test':
        return this.props.urls.destroy_test.replace('~id', id);
      case 'reorder':
        return this.props.urls.reorder.replace('~id', id);
      case 'update':
        return this.props.urls.update.replace('~id', id);
    }
  }

  showNoApplicationsDestroyModal(step) {
    const title = 'Delete step';
    const body = (
      <span>
        Are you sure you want to delete
        <span className={styles.confirmModalStepLabel}>&nbsp;{step.name}</span>?
      </span>
    );

    return ConfirmModal.show(title, body).then(confirmed => {
      if (confirmed) {
        if (this.props.isRevision) {
          this.removeStep(step);
        } else {
          const options = {
            type: 'DELETE',
            url: this.apiUrl('destroy', step.id),
          };
          window.toggleSpinner(true);
          $.ajax(options)
            .done(this.handleSaveSuccess.bind(this, step))
            .fail(this.handleSaveFail.bind(this));
        }
      }
    });
  }

  setFinalStepsOrder(steps) {
    const finalSteps = this.state.finalSteps.map((step, idx) => ({
      ...step,
      order: finalStepsOrder(steps, idx),
    }));

    this.setState({ finalSteps });
  }
}

function sameStep(a, b) {
  return a.id === b.id && a.uid === b.uid && a.kind === b.kind;
}

function makeDummyStep(kind) {
  return { kind, uid: uniqueId(), isDummy: true };
}

function finalStepsOrder(steps, order: number) {
  return steps.length + order;
}
