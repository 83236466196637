import { createContainer } from 'unstated-next';
import { unionBy } from 'lodash';
import { useState } from 'react';

import { apolloClient, gql } from 'lib/graphql';
import { getter } from 'lib/object';

const CREATE_DISMISSAL_GQL = gql`
  mutation ($jobId: ID!) {
    person {
      createJobDismissal(jobId: $jobId) {
        errors {
          field
          message
        }
        resource {
          id
          jobId
        }
      }
    }
  }
`;

const DELETE_DISMISSAL_GQL = gql`
  mutation ($id: ID!) {
    person {
      deleteJobDismissal(id: $id) {
        errors {
          field
          message
        }
      }
    }
  }
`;

function useDismissals(initial) {
  const [dismissals, setDismissals] = useState(initial);

  const create = async jobId => {
    const { data, errors } = await apolloClient.mutate({
      mutation: CREATE_DISMISSAL_GQL,
      variables: { jobId },
    });
    const dismissal = data?.person?.createJobDismissal?.resource;
    if (dismissal) {
      setDismissals(prevDismissals => unionBy(prevDismissals, [dismissal], getter('id')));
    }
  };

  const destroy = async id => {
    const { data, errors } = await apolloClient.mutate({
      mutation: DELETE_DISMISSAL_GQL,
      variables: { id },
    });
    if (!data.errors) {
      setDismissals(prevDismissals => prevDismissals.filter(dimissal => dimissal.id !== id));
    }
  };

  return { dismissals, create, destroy };
}

export const JobDismissalsContainer = createContainer(useDismissals);
