import * as React from 'react';
import ApplicationMoreButton, { Props } from './application_more_button';
import { shallow, ShallowWrapper } from 'enzyme';

const createTestProps = moreProps => {
  return {
    emailUrl: '',
    pdfUrl: '',
    ...moreProps,
  };
};

let props: Props;
let wrapped: ShallowWrapper;

describe('Application More Button component', () => {
  beforeEach(() => {
    props = createTestProps({});
    wrapped = shallow(<ApplicationMoreButton {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
