// Wizard / Sidebar
//
// This is the sidebar for wizard like forms

import React from 'react';
import './wizard_sidebar.scss';
import Button from 'lj_shared/button/button';
const chevronPath = require('svg/thin-chevron.svg');

interface Props {
  wizardType: string;
  image: string;
  name: string;
  previous_url: string;
  currentStep: number;
  finishedSteps?: [];
}

export default class WizardSidebar extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    if (this.props.wizardType === 'post') {
      return <PostSidebar {...this.props} />;
    }
  }

  componentDidMount() {
    const nextButtons: NodeListOf<HTMLElement> = document.querySelectorAll('.sidebar-next-button');
    const formNextButton: HTMLElement = document.querySelector('.next-button');

    nextButtons.forEach(button => {
      button.addEventListener('click', () => {
        formNextButton?.click();
      });
    });
  }
}

function PostSidebar(props) {
  const { image, name, previous_url, currentStep } = props;
  const steps = [
    ['Post a job', 'post_a_job'],
    ['General info', 'general_information'],
    ['Candidates’ background', 'candidate_background'],
    ['Job description & requirements', 'job_description'],
    ['Location details', 'location_details'],
    ['Additional info', 'additional_info'],
    ['Recruitment process', 'hiring_process'],
    ['Final check', 'submit_offer'],
  ];

  let finishedSteps;

  if (window.Cookies().finishedSteps) {
    finishedSteps = window.Cookies().finishedSteps.split(',');
  } else {
    finishedSteps = [];
  }

  let buttonText: string;
  let buttonType: string;

  if (props.currentStep === steps.length - 1) {
    buttonText = 'Submit for Approval';
    buttonType = null;
  } else {
    buttonText = 'Save & Exit';
    buttonType = 'border';
  }

  const imageStyling = {
    backgroundImage: `url(${image})`,
  };

  return (
    <div className={`lj-wizard-sidebar ${window.gon?.masquerading ? 'impersonating' : ''}`}>
      <div className='lj-wizard-sidebar-header'>
        <div className='lj-wizard-sidebar-image' style={imageStyling}>
          &nbsp;
        </div>
        <div className='lj-wizard-sidebar-companyName'>{name}</div>
      </div>
      <div className='lj-wizard-sidebar-steps'>
        <ol>
          {steps.map((step, i) => {
            const url = window.location.pathname.split('/');
            url[url.length - 1] = step[1];
            const urlString = url.join('/');

            if (i === currentStep) {
              if (i !== 0) {
                return (
                  <li key={i} className={['lj-wizard-step', '--current'].join(' ')}>
                    <a href={urlString}> {step[0]}</a>
                  </li>
                );
              } else {
                return (
                  <li key={i} className={['lj-wizard-step', '--current'].join(' ')}>
                    {step[0]}
                  </li>
                );
              }
            } else if (
              currentStep !== 0 &&
              ((i > 0 && finishedSteps.indexOf(steps[i - 1][1]) >= 0) ||
                (i === 1 && finishedSteps.length > 0))
            ) {
              return (
                <li key={i} className='lj-wizard-step --completed'>
                  <a href={urlString}> {step[0]}</a>
                </li>
              );
            } else if (i < currentStep) {
              if (i === 0) {
                return (
                  <li key={i} className='lj-wizard-step --completed'>
                    {step[0]}
                  </li>
                );
              } else {
                return (
                  <li key={i} className='lj-wizard-step'>
                    <a href={urlString}> {step[0]}</a>
                  </li>
                );
              }
            } else {
              return (
                <li key={i} className='lj-wizard-step'>
                  {step[0]}
                </li>
              );
            }
          })}
        </ol>
      </div>
      <div className='lj-wizard-sidebar-actions'>
        <Button
          buttonColor='ripePlum'
          buttonType={buttonType}
          buttonSize='xSmall'
          otherClasses='sidebar-next-button'
        >
          {buttonText}
        </Button>
        <WizardStepButtons
          chevronType='previous'
          url={previous_url}
          currentStep={currentStep}
          stepsLength={steps.length - 1}
        />
        <WizardStepButtons
          chevronType='next'
          className='sidebar-next-button'
          currentStep={currentStep}
          stepsLength={steps.length - 1}
        />
      </div>
    </div>
  );
}

function WizardStepButtons(props) {
  if (props.currentStep !== 0 && props.chevronType === 'previous') {
    return (
      <a href={props.url} className='lj-wizard-sidebar-chevron previous-step'>
        <img className='iconic iconic-sm' src={chevronPath} alt='previous-step' />
      </a>
    );
  } else if (props.currentStep !== props.stepsLength && props.chevronType === 'next') {
    return (
      <a href={props.url} className='lj-wizard-sidebar-chevron sidebar-next-button next-step'>
        <img className='iconic iconic-sm' src={chevronPath} alt='next-step' />
      </a>
    );
  } else {
    return null;
  }
}
