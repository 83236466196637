import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import BusinessGeneralPricingCard from './business_general_pricing_card';

const createProps = () => ({
  businessEmail: 'banana@nana.com',
  pricingPlanUrl: 'https://lepricing.com',
});

let props;
let wrapped: ShallowWrapper;

describe('BusinessGeneralPricingCard', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<BusinessGeneralPricingCard {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
