import React, { useState, useContext, useRef } from 'react';
import { FormContext } from 'components/form/FormProvider';
import Text from 'components/form/text/text';
import Button from 'lj_shared/button/button';
import AboutSectionSmallCard from './AboutSectionSmallCard';
import { handleRequestError, postJSON } from 'lib/request_deprecated';
import FormError from '../form/form_error/form_error';

const styles = require('./AboutSectionPerks.module.scss');

export default function AboutSectionPerks() {
  const context = useContext(FormContext);
  const [perksList, setPerksList] = useState(context.resource.perks);
  const [userError, setUserError] = useState(null);
  const perksText = useRef(null);

  const deletePerksItem = perk => {
    const shouldRemove = window.confirm('Do you want to remove?');
    if (!shouldRemove) {
      return;
    }
    setUserError(null);
    const payload = {
      perks: perksList.filter(item => item !== perk),
    };
    postJSON(window.Routes.update_perks_employer(), payload)
      .then(() => setPerksList(payload.perks))
      .catch(handleRequestError);
  };

  const addPerks = event => {
    event.preventDefault();
    setUserError(null);
    const perk = perksText.current.value;
    const [valid, message] = validate(perk);
    if (!valid) {
      return setUserError(message);
    }
    const payload = {
      perks: perksList.concat(perk),
    };
    postJSON(window.Routes.update_perks_employer(), payload)
      .then(() => setPerksList(payload.perks))
      .then(() => (perksText.current.value = null))
      .catch(handleRequestError);
  };

  function validate(value) {
    if (value === '') {
      return [false, "Benefits & Perks can't be empty"];
    } else if (value.length > 200) {
      return [false, "Benefits & Perks can't have more than 200 characters"];
    } else if (perksList.some(item => value === item)) {
      return [false, "Benefits & Perks can't be duplicated"];
    }
    return [true];
  }

  const renderForm = () => (
    <div className={styles.perks}>
      <Text
        name='perks'
        inputRef={perksText}
        placeholder='e.g. Flexible hours'
        label='Benefits & Perks'
        note='What makes your company unique? Advantages and benefits you have to offer.'
      />
      <div className={styles.perksButton}>{renderButtons()}</div>
    </div>
  );

  const renderButtons = () => (
    <Button isButton={true} buttonColor='silverSand' onClick={addPerks}>
      Add
    </Button>
  );

  const renderInputs = () => (
    <div className={styles.sectionList}>
      {perksList.map(perk => (
        <AboutSectionSmallCard key={perk} text={perk} handleDelete={() => deletePerksItem(perk)} />
      ))}
    </div>
  );

  return (
    <div className={styles.section}>
      {renderForm()}
      {userError && <FormError text={userError} />}
      {renderInputs()}
    </div>
  );
}
