import React from 'react';
import renderer from 'react-test-renderer';
import { fireEvent, render, waitFor } from '@testing-library/react';
import PostJobModal from './PostJobModal';

window.toggleSpinner = jest.fn();

window.Routes = {
  company_options_backoffice_admin_post_job_index_url: () => '',
};

let container;

beforeEach(() => {
  container = document.createElement('div');
  document.body.appendChild(container);
});

afterEach(() => {
  document.body.removeChild(container);
  container = null;
});

const props = {
  showCompanySelect: false,
  companyId: 1,
  companyName: 'company',
  title: 'post job',
};

describe('PostJobModal', () => {
  it('should render correctly', () => {
    const wrapper = renderer.create(<PostJobModal {...props} />);
    expect(wrapper).toMatchSnapshot();
  });

  it('should render to buttons when open modal', async () => {
    const { getByTestId, getByText } = render(<PostJobModal {...props} />);

    expect(getByText(/post job/i)).toBeTruthy();

    fireEvent.click(getByText(/post job/i));

    const listNode = await waitFor(() => getByTestId('job-type-buttons'));

    expect(listNode.querySelectorAll('.jobTypeButton')).toHaveLength(2);
  });

  it('should render company select', async () => {
    const { getByText, getByTestId, rerender } = render(<PostJobModal {...props} />);

    fireEvent.click(getByText(/post job/i));

    const listNode = await waitFor(() => getByTestId('job-type-buttons'));

    listNode.querySelectorAll('.companyForm');

    expect(listNode.querySelectorAll('.companyForm')).toHaveLength(0);

    rerender(<PostJobModal {...props} showCompanySelect={true} />);

    listNode.querySelectorAll('.companyForm');

    expect(listNode.querySelectorAll('.companyForm')).toBeTruthy();
  });
});
