import Rating from '__models__/rating';
import RatingDimension from '__models__/rating_dimension';
import React, { useState, ChangeEvent } from 'react';
import { apolloClient } from 'lib/graphql';
import { GET_OVERALL_GQL } from 'components/curators/utils/sidebarUtils';
const styles = require('./dimensions_container.module.scss');

interface Props {
  handleRating: (rating: Rating) => void;
  rating: Rating;
}

export default function DimensionsContainer(props: Props) {
  const [rating, setRating] = useState(props.rating);
  const dimensions = rating?.dimensions.filter(e => e.dimension !== 'bonus');
  const orderByKey = (first, last) =>
    RatingDimension.DIMENSIONS_ORDER[first.dimension] -
    RatingDimension.DIMENSIONS_ORDER[last.dimension];

  const refreshOverall = () => {
    const ratings = dimensions.map(dimension => ({
      name: dimension.dimension,
      rating: dimension.value,
    }));
    apolloClient
      .query({
        query: GET_OVERALL_GQL,
        variables: { ratings },
      })
      .then(result => {
        const overall = result['data']['appRatingComputation'];
        const newRating = { ...rating, rating: overall };
        setRating(newRating);
        props.handleRating(newRating);
      })
      .catch(e => {
        window.Alerts.alert('Oops, something went wrong.');
      });
  };

  const changeRating = (dimension, value) => {
    // 1º Updated the changed dimension
    const newRating = { ...rating, rating: null };
    newRating.dimensions.find(d => d.dimension.toString() === dimension).value = parseInt(
      value,
      10
    );
    setRating(newRating);

    // 2º Computing the new overall
    refreshOverall();

    // 3º Bubbling changes
    props.handleRating(newRating);
  };

  const gridDimensionRow = (dimension: RatingDimension) => {
    const dimensionLabel = RatingDimension.DIMENSIONS_FRIENDLY_NAME[dimension.dimension];
    const onChange = e => {
      changeRating(dimension.dimension, e.target.value);
    };
    return gridRow(
      dimensionLabel,
      dimension.value,
      RatingDimension.DIMENSIONS_RATING_VALUES,
      onChange
    );
  };

  const gridRow = (
    label: string,
    value: number,
    possibleValues?: number[],
    onChange?: (event: ChangeEvent) => void
  ) => {
    let valueToDisplay = <span className={styles.gridCellValue}>{value}</span>;
    if (possibleValues) {
      valueToDisplay = (
        <select
          className={`ld-select-button ${styles.select}`}
          defaultValue={value.toString()}
          onChange={onChange}
        >
          {RatingDimension.DIMENSIONS_RATING_VALUES.map(v => (
            <option key={v.toString()} value={v.toString()}>
              {v.toString()}
            </option>
          ))}
        </select>
      );
    }
    return (
      <div className={styles.gridRow} key={label}>
        <div className={styles.gridCell}>
          {' '}
          <h4>{label}</h4>
        </div>
        <div className={styles.gridCell}>{valueToDisplay}</div>
      </div>
    );
  };

  dimensions.sort(orderByKey);
  return (
    <>
      <h3>Dimensions' rating:</h3>
      <p>The employer and the candidate will have access to this.</p>
      <div className={styles.grid}>
        {dimensions.map((dimension: RatingDimension) => gridDimensionRow(dimension))}
        {gridRow('Overall', rating.rating)}
      </div>
    </>
  );
}
