import * as React from 'react';
import ApplicationCard, { Props } from './application_card';
import { shallow, ShallowWrapper } from 'enzyme';

const createTestProps = moreProps => {
  return {
    applicationUrl: '',
    daysToExpiration: 1,
    daysWaiting: 1,
    jobTitle: '',
    person: {
      avatar: '',
      experienceLabel: '',
      firstName: '',
      lastName: '',
      location: '',
    },
    ...moreProps,
  };
};

let props: Props;
let wrapped: ShallowWrapper;

describe('Application Card component', () => {
  beforeEach(() => {
    props = createTestProps({});
    wrapped = shallow(<ApplicationCard {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
