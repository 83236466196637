import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import ApplicationItem from './ApplicationItem';
import applicationDummy from '__fixtures__/application_dummy';

const createProps = moreProps => {
  return {
    seenList: [],
    delivered: true,
    application: applicationDummy,
    profileUrl: '',
    moveToStep: '',
    rejectApplication: '',
    hiringSteps: [],
    currentStep: '',
    urls: [],
    stepLabel: '',
    waitingReview: true,
    onApplicationList: true,
    ...moreProps,
  };
};

let props;
let wrapped: ShallowWrapper;

describe('Your description here', () => {
  beforeEach(() => {
    props = createProps({});
    wrapped = shallow(<ApplicationItem {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
