import CookieDisclaimer from 'components/general/cookie_disclaimer/cookie_disclaimer';
import Icon, { Size } from 'components/general/icon/icon';
import React from 'react';
import { socialLinksData, footerLinksData } from './footerData';

const logo = require('./../../images/logo-lj.svg');
const styles = require('./Footer.module.scss');

const footerLinks = () => (
  <ul className={styles.linksList}>
    {footerLinksData.map(link => (
      <li key={link.key}>
        <a
          className={styles.ldFooterLink}
          href={link.path}
          target='_blank'
          rel='noopener noreferrer'
        >
          {link.title}
          {link.img && <img src={link.img} alt={link.key} />}
        </a>
      </li>
    ))}
  </ul>
);

const socialLinks = () => (
  <ul className={styles.networkList}>
    {socialLinksData.map(network => (
      <li key={network.icon}>
        <a
          className={styles.ldFooterLink}
          href={network.url}
          target='_blank'
          rel='noopener noreferrer'
        >
          <Icon name={network.icon} className={styles.networkIcon} size={network.icon == 'twitter' ? Size.Medium : Size.MediumLarge} />
        </a>
      </li>
    ))}
  </ul>
);

const Footer = () => {
  return (
    <div className={styles.footerContainer}>
      <CookieDisclaimer privacy_path={window?.Routes.privacy()} />
      <div className={styles.linksContainer}>
        <Icon
          name={'logoSignature'}
          className={styles.ldFooterLogo}
          clickHandler={() => window.open(window.Routes.root(), '_self')}
        />
        {footerLinks()}
      </div>
      <div className={styles.logoContainer}>
        <img alt='Landing.Jobs logo' src={logo} />
      </div>
      {socialLinks()}
      <div className='ld-copyright-section'>
        <small className='ld-copyright'>
          © {new Date().getFullYear()} Landing.Jobs, a Future.Works company
        </small>
      </div>
    </div>
  );
};

export default Footer;
