import * as React from 'react';
import SignUpModal from './sign_up_modal';
import { handleRequestError } from '../../lib/request_deprecated';
const styles = require('./page_numbers.module.scss');

interface Props {
  page_number?: number;
  sign_up_path: string;
  login_path: string;
}

interface State {
  showModal: boolean;
}

export default class PageNumbers extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { showModal: false };
    this.showSignUpModal = this.showSignUpModal.bind(this);
  }

  showSignUpModal() {
    return this.state.showModal ? (
      <SignUpModal
        context='more'
        sign_up_path={this.props.sign_up_path}
        login_path={this.props.login_path}
      />
    ) : null;
  }

  // This is for non logged in users, the signup modal is displayed and the action is canceled
  handlePageNavigation = event => {
    event.preventDefault();
    this.setState({ showModal: true });
    window.scrollTo(0, 0);
  };

  renderNumbers() {
    return (
      <>
        <button onClick={this.handlePageNavigation} className={styles.numbers}>
          {this.props.page_number}
        </button>
        {this.showSignUpModal()}
      </>
    );
  }

  renderNext() {
    return (
      <>
        <button onClick={this.handlePageNavigation} className={styles.next}>
          Next
        </button>
        {this.showSignUpModal()}
      </>
    );
  }

  render() {
    return this.props.page_number ? this.renderNumbers() : this.renderNext();
  }
}
