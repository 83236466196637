import React, { useState } from 'react';
import WhatsNewModalItem from './WhatsNewModalItem';

interface Props {
  newFeatureDate: Date;
  featureModalSeenDate: Date;
}

export default function CandidateWhatsNewModalContainer(props: Props) {
  const [modal, setModalOpen] = useState(true);

  return (
    props.newFeatureDate &&
    (!props.featureModalSeenDate || props.featureModalSeenDate < props.newFeatureDate) &&
    modal && <WhatsNewModalItem setOpenModal={() => setModalOpen(false)} isCompany={false} />
  );
}
