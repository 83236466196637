import React from 'react';
import Tag from '../tag/tag';
import Tooltip from '../tooltip/tooltip';

interface Props {
  color?: string;
  icon?: string;
  labelClass?: string;
  tagTitle?: string;
  textBelow?: boolean;
  tooltipText: string;
  style?: object;
  newIcon?: string;
  iconColor?: string;
}

const TagWithTooltip = (props: Props) => {
  return (
    <Tooltip
      text={props.tooltipText}
      textBelow={props.textBelow}
      style={props.style}
      color='silverSandMidDark'
    >
      <Tag
        title={props.tagTitle}
        icon={props.icon}
        labelClass={props.labelClass}
        color={props.color}
        newIcon={props.newIcon}
        iconColor={props.iconColor}
      />
    </Tooltip>
  );
};

export default TagWithTooltip;
