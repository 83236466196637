import React, { useState } from 'react';
import Button from 'lj_shared/button/button';
import AfterHandshakeModal from 'components/handshakes/after_handshake_modal/after_handshake_modal';
import RejectHandshakeModal from 'components/reject_handshake_modal/reject_handshake_modal';

const styles = require('./reject_handshake.module.scss');

interface Props {
  buttonColor?: string;
  handshake: any;
  company: { name: string; id: number; logo: string };
  jobAd: { title: string; id: number };
  setHandshakeState?: Function;
  hasFollowUpModal?: boolean;
}

export default function RejectHandshake(props: Props) {
  const [isAfterReject, setIsAfterReject] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openRejectModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Button
        buttonColor={props.buttonColor}
        buttonSize='xSmall'
        buttonType='border'
        isButton={true}
        onClick={openRejectModal}
        otherClasses={!props.hasFollowUpModal && styles.rejectButton}
      >
        Reject
      </Button>
      {isModalOpen && (
        <RejectHandshakeModal
          handshake={props.handshake}
          companyName={props.company.name}
          jobAd={props.jobAd}
          setIsModalOpen={setIsModalOpen}
          hasFollowUpModal={props.hasFollowUpModal}
          setIsAfterReject={setIsAfterReject}
        />
      )}
      {isAfterReject && (
        <AfterHandshakeModal
          companyName={props.company.name}
          jobTitle={props.jobAd.title}
          handshakeAccepted={false}
          personId={props.handshake.person_id}
        />
      )}
    </>
  );
}
