import Autocomplete from 'react-google-autocomplete';
import React, { Component } from 'react';

import { GoogleMaps } from 'lib/scripts';
import classnames from 'classnames';
import Icon, { Size } from 'components/general/icon/icon';
import Spinner from 'shared/dot_spinner/DotSpinner';
import { snakeCase } from 'lib/string';

const styles = require('./location_input.module.scss');

interface Location {
  city?: string;
  country?: string;
  countryCode?: string;
  googlePlaceId?: string;
  latitude?: string;
  longitude?: string;
}

interface State {
  googleMapsReady: boolean;
  location: Location;
  locationFocused: boolean;
  locationSelected: boolean;
}

interface Props {
  class?: string;
  closeIcon?: boolean;
  color?: string;
  defaultValue?: Location;
  fixedClass?: string;
  formName?: string;
  handleCloseIcon?: Function;
  handleLocationChange?: Function;
  handleReady?: Function;
  handleReset?: Function;
  placeholder?: string;
  inputRef?: any;
  required?: boolean;
  styles?: Object;
  types?: string;
  value?: Location;
}

export default class LocationInput extends Component<Props, State> {
  private containerRef: any;
  private inputRef: any;

  constructor(props: Props) {
    super(props);
    const { defaultValue } = props;
    this.state = {
      location: defaultValue || ({} as Location),
      googleMapsReady: false,
      locationFocused: false,
      locationSelected: false,
    };
    this.containerRef = React.createRef();
    this.inputRef = props.inputRef || React.createRef();
    this.onLocationBlur = this.onLocationBlur.bind(this);
    this.onLocationFocus = this.onLocationFocus.bind(this);
  }

  componentDidMount() {
    this.displayRadiusFilters();
  }

  componentDidUpdate(prevProps) {
    const location = this.props.value;
    if (prevProps.value !== location) {
      this.setState(prevState => {
        return { ...prevState, location };
      });
    }
  }

  UNSAFE_componentWillMount() {
    void GoogleMaps.load().then(this.handleReady);
  }

  handleReady = () => {
    this.setState(
      prevState => {
        return { ...prevState, googleMapsReady: true };
      },
      () => this.props.handleReady && this.props.handleReady()
    );
  };

  onLocationBlur = () => {
    const input = this.inputRef.current;
    const location = {} as Location;

    if (input.value === '' && this.state.location.city) {
      $('#lr_50').prop('checked', true);

      this.setState(
        prevState => {
          return { ...prevState, location, locationFocused: false, locationSelected: false };
        },
        () => this.props.handleReset && this.props.handleReset()
      );
    } else {
      if (input.value !== '' && !(this.state.location?.city || this.state.location?.country)) {
        $('#lr_50').prop('checked', true);
        this.inputRef.current.value = '';
      }

      this.setState(prevState => {
        return { ...prevState, locationFocused: false };
      });
    }
  };

  onLocationFocus = () => {
    this.setState(prevState => {
      return { ...prevState, locationFocused: true, locationSelected: false };
    });

    if (this.props.fixedClass) {
      const pacContainer = document.querySelector('.pac-container');
      pacContainer.classList.add(this.props.fixedClass);
      this.containerRef.current.style.position = 'relative';
      this.containerRef.current.appendChild(pacContainer);
    }
  };

  getDefaultValueString() {
    const { city, country } = this.state.location;

    if (city && country) return `${city}, ${country}`;
    else if (country) return country;
    else return '';
  }

  handleClearLocationFilter = () => {
    this.props.handleCloseIcon();

    // Hack to clear location input
    this.setState(prevState => {
      return { ...prevState, googleMapsReady: false, locationSelected: false };
    });
    setTimeout(() => {
      this.setState(prevState => {
        return { ...prevState, googleMapsReady: true };
      });
    }, 5);
  };

  handleChange = place => {
    const location = {} as Location;

    if (place && place.address_components) {
      const countryObj = place.address_components.find(component =>
        component.types.includes('country')
      );
      const cityObj = place.address_components.find(component =>
        component.types.includes('locality')
      );

      if (countryObj) {
        location.country = countryObj.long_name;
        location.countryCode = countryObj.short_name;
        location.googlePlaceId = place.place_id;

        if (place.geometry && cityObj) {
          location.city = cityObj.long_name;
          const coords = place.geometry.location;
          location.latitude = coords.lat().toString();
          location.longitude = coords.lng().toString();
        }
      }

      this.setState(
        prevState => {
          return { ...prevState, location, locationSelected: true };
        },
        () => {
          this.displayRadiusFilters();

          this.props.handleLocationChange && this.props.handleLocationChange(place);
        }
      );
    }
  };

  reset() {
    this.inputRef.current.value = '';
    $('#lr_50').prop('checked', true);

    this.setState(
      prevState => {
        return { ...prevState, location: {} as Location };
      },
      () => {
        this.displayRadiusFilters();
      }
    );
  }

  displayRadiusFilters() {
    const radiusFilters: HTMLElement = document.querySelector('#radius_filters');

    if (radiusFilters === null) {
      return;
    }

    radiusFilters.style.display = this.state.location.city ? 'block' : 'none';

    this.state.location.city ? $('#lr_50').prop('checked', true) : $('#lr_0').prop('checked', true);
  }

  render() {
    const { formName } = this.props;
    const color = this.props.color ? this.props.color : 'ripePlum';
    const classNames = classnames(
      'location-search-container',
      color,
      { active: this.state.locationFocused },
      this.props.class
    );

    return (
      <div
        className={classNames}
        style={this.props.styles}
        ref={this.containerRef}
        tabIndex={1}
        onBlur={this.onLocationBlur}
      >
        <Icon className={styles.locationIcon} name='location' />
        {this.state.googleMapsReady ? (
          <>
            <Autocomplete
              defaultValue={this.getDefaultValueString()}
              id='location-filter'
              name={getInputName(formName, 'location')}
              onFocus={this.onLocationFocus}
              onPlaceSelected={this.handleChange}
              placeholder={this.props.placeholder || 'Location...'}
              ref={this.inputRef}
              required={this.props.required}
              options={{
                types: [`(${this.props.types ? this.props.types : 'cities'})`],
              }}
              inputAutocompleteValue='off'
            />
            {hiddenInput(this.state, formName, 'city')}
            {hiddenInput(this.state, formName, 'countryCode')}
            {hiddenInput(this.state, formName, 'googlePlaceId')}
            {hiddenInput(this.state, formName, 'latitude')}
            {hiddenInput(this.state, formName, 'longitude')}
          </>
        ) : (
          <Spinner />
        )}
        {this.props.closeIcon && (
          <Icon name='close' size={Size.Big} clickHandler={this.handleClearLocationFilter} />
        )}
      </div>
    );
  }
}

function hiddenInput(state, formName, name) {
  const value = state.location[name] || '';
  const inputName = getInputName(formName, name);
  return <input type='hidden' name={inputName} value={value} />;
}

function getInputName(formName, name) {
  name = snakeCase(name);
  return formName === null || formName === undefined ? name : `${formName}[${name}]`;
}
