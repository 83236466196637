import RatingDimension from './rating_dimension';

export default class Rating {
  id: number;
  rater_id: number;
  application_id: number;
  rating: number;
  note: string;
  start_time: any;
  rater_type: string;
  dimensions: Partial<RatingDimension>[];
  inbox_classification: string;
  concern: string;
  concernLevel: string;
  concernNote: number;

  static CONCERNS: {} = {
    location: '0',
    language: '1',
    skills: '2',
    experience: '3',
    lack_of_information: '4',
  };
}
