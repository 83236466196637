import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import OrderIdMultiSelect from './order_id_multi_select';

const createProps = () => ({
  color: 'tuftsBlue',
  id: 'id_eq_any',
});

let props;
let wrapped: ShallowWrapper;

describe('OrderIdMultiSelect', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<OrderIdMultiSelect {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});
