import * as React from 'react';
import { mount } from 'enzyme';
import { type JobCardContextInterface, JobCardCtx } from './JobCardContext';
import JobExperience from './JobExperience';

const company = require('__fixtures__/company_dummy');
const job = require('__fixtures__/job_dummy');
const tag = require('__fixtures__/tag_dummy');
const user = require('__fixtures__/talent_profile');

window.Routes = {
  company: () => '',
  company_job_ad: () => '',
  join: () => '',
};

const JobCardContext: JobCardContextInterface = {
  applied: false,
  bookmark: {
    id: '1',
    jobId: job.id,
  },
  job,
  key: job.id,
  user,
  isHottest: true,
  infoTags: ['tag1', 'tag2'],
};

describe('JobCard Experince', () => {
  it('shallow renders without crashing', () => {
    job.company = company;
    job.tags = [tag, tag];

    const component = mount(
      <JobCardCtx.Provider key={job.id} value={JobCardContext}>
        <JobExperience />,
      </JobCardCtx.Provider>
    );

    expect(component.exists()).toBe(true);
  });
});
