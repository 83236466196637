export function splitIntoSubArrays(array: any[], subArraySize: number) {
  const result = [];
  let auxSubArray = [];
  let subIndex = 0;
  let arrayIndex = 0;

  for (const item of array) {
    auxSubArray.push(item);
    subIndex++;
    if (subIndex / subArraySize === arrayIndex + 1) {
      result[arrayIndex] = auxSubArray;
      auxSubArray = [];
      arrayIndex++;
    }
  }
  if (auxSubArray.length) {
    result[arrayIndex] = auxSubArray;
  }
  return result;
}

export function isEmptyArray(array: any) {
  return Array.isArray(array) && !array.length;
}
