import React from 'react';

import FormContext from 'components/form/FormProvider';
import {
  CompanyInfoFormUtils,
  IndustryFormUtils,
  CompanySizesUtils,
} from './utils/CompanyInfoFormUtils';
import OfficeSection from './OfficeSection';
import AboutSection from './AboutSection';
import TeamSection from './TeamSection';

const styles = require('./CompanyInfoForm.module.scss');

interface Props {
  company: any;
  companySizes: any;
  errors: { [key: string]: string[] };
  industries: any;
  formName: string;
}

export default function CompanyInfoForm(props: Props) {
  const company = CompanyInfoFormUtils(props.company);
  const industries = IndustryFormUtils(props.industries);
  const companySizes = CompanySizesUtils(props.companySizes);

  return (
    <FormContext errors={props.errors} formName={props.formName} resource={company}>
      <section className={styles.main} id='company-info'>
        <h2 className={styles.title}>Company info</h2>
        <div className={styles.body}>
          <AboutSection companySizes={companySizes} industries={industries} />
          <TeamSection />
          <OfficeSection />
        </div>
      </section>
    </FormContext>
  );
}
