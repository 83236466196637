import * as React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';

import RatingHeader from './rating_header';

const createProps = () => ({
  title: 'Rating',
  employerRating: 0,
  landingRating: 5,
  url: '/employer/applications/199854/rating',
  showRatingBody: true,
  toggleRatingBody: 'bound ()',
});

let props;
let wrapped: ShallowWrapper;

describe('Rating Container component', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<RatingHeader {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});
