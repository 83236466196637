import * as React from 'react';
import Button from 'lj_shared/button/button';
import Icon, { Size } from 'components/general/icon/icon';

const styles = require('./auth_buttons.module.scss');

interface AuthProps {
  kind: string;
  urls: {
    linkedin?: string;
    github: string;
    google: string;
    facebook: string;
  };
}

class AuthButtons extends React.Component<AuthProps, {}> {
  render() {
    const list = [
      {
        name: 'Google',
        link: this.props.urls.google,
        kind: this.props.kind,
        buttonProps: {
          buttonColor: 'googleBlue',
          buttonSize: 'xSmall',
          otherClasses: styles.button,
        },
      },
      {
        name: 'GitHub',
        link: this.props.urls.github,
        kind: this.props.kind,
        buttonProps: {
          buttonColor: 'githubGray',
          buttonSize: 'xSmall',
          otherClasses: styles.button,
        },
      },
      {
        name: 'Facebook',
        link: this.props.urls.facebook,
        kind: this.props.kind,
        buttonProps: {
          buttonColor: 'facebookBlue',
          buttonSize: 'xSmall',
          otherClasses: styles.button,
        },
      },
    ];

    if (this.props.urls.linkedin) {
      list.unshift({
        name: 'LinkedIn',
        link: this.props.urls.linkedin,
        kind: this.props.kind,
        buttonProps: {
          buttonColor: 'linkedinBlue',
          buttonSize: 'xSmall',
          otherClasses: styles.button,
        },
      });
    }

    return (
      <div className={styles.authButtons}>
        <hr className={styles.divider} />
        <div className={styles.dividerText}>or {this.props.kind} with</div>
        <ul className={styles.list}>
          {list.map((item, index) => {
            return <AuthButton key={index} {...item} />;
          })}
        </ul>
      </div>
    );
  }
}

const AuthButton = props => {
  const { name, link, buttonProps, icon } = props;

  return (
    <li>
      <Button buttonUrl={link} {...buttonProps} dataMethod='post'>
        <Icon
          className={styles.icons}
          name={name.toLowerCase()}
          size={Size.QuiteSmall}
          color='white'
        />
        {name}
      </Button>
    </li>
  );
};

export default AuthButtons;
