import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import RatingConcernItem from './RatingConcernItem';

const createProps = () => ({
  concern: 'location',
});

let props;
let wrapped: ShallowWrapper;

describe('Your description here', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<RatingConcernItem {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
