import { apolloClient } from 'lib/graphql';
import { ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import * as React from 'react';
import Select from 'components/form/select/select';
import classnames from 'classnames';

interface State {
  values: { label: string; value: string }[];
}

interface Props {
  candidateSearch?: boolean;
  clearButtonClass?: string;
  color: string;
  id: string;
  marginRight?: string;
  notRansack?: boolean;
  tagRef?: any;
  values?: { label: string; value: string }[];
}

export default class CandidateSkillsInput extends React.Component<Props, State> {
  experienceRef: any;
  tagRef: any;
  styles: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      values: this.props.values || [],
    };
    this.experienceRef = React.createRef();
    this.styles = require('./search_skills_input.module.scss');
    this.clearState = this.clearState.bind(this);
    this.handleChange = this.handleChange.bind(this);
    if (this.props.tagRef) {
      this.tagRef = this.props.tagRef;
    }
  }

  componentDidMount() {
    if (this.props.clearButtonClass) {
      document
        .querySelector(this.props.clearButtonClass)
        .addEventListener('click', this.clearState);

      this.sendEvent();
    }
  }

  componentWillUnmount() {
    if (this.props.clearButtonClass) {
      document
        .querySelector(this.props.clearButtonClass)
        .addEventListener('click', this.clearState);
      this.sendEvent();
    }
  }

  clearState() {
    this.setState({ values: [] });
  }

  seed = 0;
  generateKey() {
    return this.seed++;
  }

  handleRejectedPromisse(reason) {
    window.Alerts.alert('Oops, something went wrong.');
  }

  handleFulfilledPromisse(result, successReturnFieldNames) {
    if (result == null || result.data == null) {
      // Uncontrolled error
      window.Alerts.alert('Oops, something went wrong.');
      return false;
    }

    let businessResult = result.data;
    successReturnFieldNames.forEach(fieldName => {
      businessResult = businessResult[fieldName];
      if (businessResult == null) {
        // Uncontrolled error
        window.Alerts.alert('Oops, something went wrong.');
        return false;
      }
    });

    if (businessResult.errors != null && businessResult.errors.length !== 0) {
      // Controlled error
      window.Alerts.alert(businessResult.errors[0].message);
      return false;
    }

    return true;
  }

  handleChange(e) {
    this.setState({ values: e }, () => {
      this.sendEvent();
    });
  }

  sendEvent() {
    const body: HTMLBodyElement = document.querySelector('body');
    const event = new Event('react:change');
    body.dispatchEvent(event);
  }

  render() {
    return <ApolloProvider client={apolloClient}>{this.renderInputs()}</ApolloProvider>;
  }

  renderInputs() {
    const formatOptionLabel = ({ value, label }) => (
      <div style={{ display: 'flex' }}>
        <div data-value={value}>{label}</div>
      </div>
    );

    const palette = {
      ripePlum: '#992E80',
      puertoRico: '#3BBCB0',
      tuftsBlue: '#007EFF',
    };

    const color = this.props.color ? palette[this.props.color] : '#3BBCB0';
    const multiValueStyles = {
      multiValue: styles => {
        return {
          ...styles,
          backgroundColor: 'white',
          border: `1px solid ${color}`,
        };
      },
      multiValueLabel: styles => ({
        ...styles,
        color: `${color}`,
      }),
      multiValueRemove: styles => ({
        ...styles,
        color: `${color}`,
        ':hover': {
          backgroundColor: `${color}`,
          color: 'white',
        },
      }),
    };

    const marginRight = {
      marginRight: `${this.props.marginRight ? this.props.marginRight : '0'}`,
    };

    const id = this.props.notRansack ? this.props.id : `q_${this.props.id}`;
    const name = this.props.notRansack ? `${this.props.id}[]` : `q[${this.props.id}][]`;

    return (
      <>
        <div
          className={classnames(
            this.styles.inputRow,
            marginRight,
            this.props.candidateSearch ? this.styles.bigSearchBar : this.styles.smallSearchBar
          )}
        >
          <Select
            id={id}
            name={name}
            value={this.state.values}
            isMulti
            color={this.props.color}
            loadOptions={this.loadOptions}
            openMenuOnClick={false}
            placeholder='e.g. PHP, Java'
            ref={this.tagRef}
            type='async'
            onChange={this.handleChange}
            formatOptionLabel={formatOptionLabel}
            styles={multiValueStyles}
          />
        </div>
      </>
    );
  }

  // get Mutations
  async loadOptions(query, callback) {
    const result = await apolloClient.query({
      query: GQL_QUERY_TAGS,
      variables: { query },
    });
    return result.data.tags.nodes.map(t => ({ label: t.name, value: t.id }));
  }
}

const GQL_QUERY_TAGS = gql`
  query ($query: String) {
    tags(query: $query, excludeUserTags: false) {
      nodes {
        id
        name
      }
    }
  }
`;
