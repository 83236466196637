import pluralize from 'pluralize';
import * as React from 'react';

const styles = require('./application_card.module.scss');
const eyeClosedIcon = require('images/icons/eye-closed.png');
const eyeClosedAltIcon = require('images/icons/eye-closed-alt.png');
const warningIcon = require('images/icons/warning.png');
const warningAltIcon = require('images/icons/warning-alt.png');

export interface Props {
  applicationUrl: string;
  daysToExpiration?: number;
  daysWaiting?: number;
  jobTitle: string;
  person: {
    avatar: string;
    experienceLabel: string;
    firstName: string;
    lastName: string;
    location: string;
  };
}

export default function ApplicationCard(props: Props) {
  return (
    <table className={styles.card}>
      <tr>
        <td className={styles.mainContent}>{mainContent(props)}</td>
        <td className={styles.sideContent}>{sideContent(props)}</td>
      </tr>
    </table>
  );
}

function mainContent(props: Props) {
  const person = props.person;

  return (
    <table>
      <tr className={styles.identification}>
        <td dangerouslySetInnerHTML={{ __html: person.avatar }} />
        <td className={styles.name}>{person.firstName + ' ' + person.lastName}</td>
      </tr>
      <tr className={styles.job}>
        <td colSpan={2}>{props.jobTitle}</td>
      </tr>
      <tr className={styles.experienceAndLocation}>
        <td colSpan={2}>{person.experienceLabel + ' - ' + person.location}</td>
      </tr>
      {requiresAttention(props)}
    </table>
  );
}

function requiresAttention(props) {
  const daysCount = props.daysToExpiration == null ? props.daysWaiting : props.daysToExpiration;

  if (daysCount != null) {
    const isExpire = props.daysToExpiration != null;
    const icon = isExpire ? warningAltIcon : eyeClosedAltIcon;
    const klass = isExpire ? styles.expiringLabel : styles.unseenLabel;
    const label = isExpire ? 'Expiring in' : 'Unopened for';

    return (
      <tr>
        <td colSpan={2}>
          <span className={klass}>
            <img className={styles.labelIcon} src={icon} />
            <span>
              {' '}
              {label} {daysCount} {pluralize('day', daysCount)}
            </span>
          </span>
        </td>
      </tr>
    );
  } else {
    return null;
  }
}

function sideContent(props: Props) {
  let daysCount;
  let icon;
  let label;

  if (props.daysToExpiration == null) {
    daysCount = props.daysWaiting;
    icon = eyeClosedIcon;
    label = 'waiting';
  } else {
    daysCount = props.daysToExpiration;
    icon = warningIcon;
    label = 'to expire';
  }

  return (
    <>
      <img src={icon} />
      <span className={styles.daysCount}>
        {daysCount} {pluralize('day', daysCount)}
      </span>
      <br />
      {label}
      <br />
      <a className={styles.button} href={props.applicationUrl} target='_blank' rel='noreferrer'>
        View Application
      </a>
    </>
  );
}
