import React from 'react';

const SearchError = () => {
  return (
    <div className='ld-no-search-results'>
      <div className='lg-search-message'>Oops! Something went wrong.</div>
      <span>
        Please{' '}
        <a className='search-resend ld-primary-link' href='#'>
          try again
        </a>{' '}
        in a few minutes.
      </span>
    </div>
  );
};

export default SearchError;
