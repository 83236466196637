import { SearchTagRaw } from '../searchTag/SearchTagList';
import { isEmptyArray } from '../../lib/array';
import { titleize, titleCase } from '../../lib/string';

export function createSearchTagsFromFilters(filters: any) {
  const searchTagReducer = (accumulator, [name, value]: any[]) => {
    if (name === 'ratings') {
      return [...accumulator, ...value.map(rating => labelizeFilter('rating', rating))];
    } else {
      return [...accumulator, labelizeFilter(name, value)];
    }
  };

  const result = Object.entries(filters)
    .filter(([_, value]) => value && !isEmptyArray(value))
    .reduce(searchTagReducer, []);

  return result as SearchTagRaw[];
}

const labelizeFilter = (name: string, value: any, label = value.toString()) => {
  const defaultValues: SearchTagRaw = { name, label };
  const location = `Based in ${value}`;
  switch (name) {
    case 'city':
    case 'location':
      defaultValues.label = location;
      break;
    case 'rating':
      defaultValues.label = `${titleize(value.title)}: ${value.value}`;
      break;
    case 'name':
      defaultValues.label = `Candidate name: ${value}`;
      break;
    case 'eu_citizenship':
      defaultValues.label = 'Has EU Citizenship';
      break;
    case 'in_process':
    case 'meet_requirements':
    case 'worth_looking_at':
    case 'probably_not_a_match':
      defaultValues.label = `From: ${titleCase(name)}`;
      break;
  }
  return defaultValues;
};
