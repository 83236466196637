// If you need new icons, look first in:
// - https://material.io/tools/icons/?style=baseline
// - https://materialdesignicons.com
//
// Grab the 24x24 SVG version. And try to remove the clutter from the file, like
// the `<?xml version="1.0" encoding="UTF-8"?>` and the DOCTYPE.
export const ICONS = {
  arrowLeft: require('!!raw-loader!../../../images/icons/material/arrow-left.svg'),
  ban: require('!!raw-loader!../../../images/icons/material/ban.svg'),
  behance: require('!!raw-loader!../../../images/icons/material/behance.svg'),
  bitbucket: require('!!raw-loader!../../../images/icons/material/bitbucket.svg'),
  bookmark: require('!!raw-loader!../../../images/icons/material/bookmark.svg'),
  briefcase: require('!!raw-loader!../../../images/icons/material/briefcase.svg'),
  barChart: require('!!raw-loader!../../../images/icons/material/bar-chart.svg'),
  calendar: require('!!raw-loader!../../../images/icons/material/calendar.svg'),
  calendarBlack: require('!!raw-loader!../../../images/icons/material/calendar-black.svg'),
  calendarOutline: require('!!raw-loader!../../../images/icons/material/calendar-outline.svg'),
  card: require('!!raw-loader!../../../images/icons/material/card.svg'),
  certificate: require('!!raw-loader!../../../images/icons/material/certificate.svg'),
  chat: require('!!raw-loader!../../../images/icons/material/chat.svg'),
  chatTextLeft: require('!!raw-loader!../../../../app/assets/images/iconic/chat-text-left.svg'),
  check: require('!!raw-loader!../../../images/icons/material/check.svg'),
  checkThin: require('!!raw-loader!../../../images/icons/material/check-thin.svg'),
  checkSquare: require('!!raw-loader!../../../images/icons/check-square.svg'),
  checkCircle: require('!!raw-loader!../../../images/icons/material/check-circle.svg'),
  chevronDown: require('!!raw-loader!../../../images/icons/material/chevron-down.svg'),
  chevronLeft: require('!!raw-loader!../../../images/icons/material/chevron-left.svg'),
  chevronRight: require('!!raw-loader!../../../images/icons/material/chevron-right.svg'),
  chevronUp: require('!!raw-loader!../../../images/icons/material/chevron-up.svg'),
  chillRed: require('!!raw-loader!../../..//images/icons/material/chill-red.svg'),
  circle: require('!!raw-loader!../../../images/icons/material/circle.svg'),
  circleX: require('!!raw-loader!../../../../app/assets/images/iconic/circle-x.svg'),
  close: require('!!raw-loader!../../../images/icons/material/close.svg'),
  cogs: require('!!raw-loader!../../../images/icons/material/cogs.svg'),
  compass: require('!!raw-loader!../../../images/icons/material/compass-outline.svg'),
  connection: require('!!raw-loader!../../../images/icons/material/transit-connection-variant.svg'),
  cv: require('!!raw-loader!../../../images/icons/material/cv.svg'),
  data: require('!!raw-loader!../../../images/icons/material/data.svg'),
  dataTransfer: require('!!raw-loader!../../../../app/assets/images/iconic/data-transfer.svg'),
  document: require('!!raw-loader!../../../images/icons/material/document.svg'),
  documentMinimalist: require('!!raw-loader!../../../images/icons/material/document-minimalist.svg'),
  dollar: require('!!raw-loader!../../../images/icons/material/dollar.svg'),
  downloadCircle: require('!!raw-loader!../../../images/icons/material/download-circle.svg'),
  dribbble: require('!!raw-loader!../../../images/icons/material/dribbble.svg'),
  earth: require('!!raw-loader!../../../images/icons/material/earth.svg'),
  error: require('!!raw-loader!../../../images/icons/material/error.svg'),
  euro: require('!!raw-loader!../../../images/icons/material/euro-symbol.svg'),
  export: require('!!raw-loader!../../../images/icons/material/export.svg'),
  eye: require('!!raw-loader!../../../images/icons/material/eye.svg'),
  eyeClosed: require('!!raw-loader!../../../images/icons/material/eye-closed.svg'),
  facebook: require('!!raw-loader!../../../images/icons/material/facebook.svg'),
  flag: require('!!raw-loader!../../../images/icons/material/flag.svg'),
  flame: require('!!raw-loader!../../../images/icons/material/flame.svg'),
  forward: require('!!raw-loader!../../../images/icons/material/forward.svg'),
  github: require('!!raw-loader!../../../images/icons/material/github.svg'),
  global: require('!!raw-loader!../../../../app/assets/images/iconic/global.svg'),
  google: require('!!raw-loader!../../../images/icons/material/google.svg'),
  grid: require('!!raw-loader!../../../../app/assets/images/iconic/grid.svg'),
  handshake: require('!!raw-loader!../../../images/icons/material/handshake.svg'),
  handshakeFull: require('!!raw-loader!../../../images/icons/material/handshake-full.svg'),
  handshakeCircleFull: require('!!raw-loader!../../../images/icons/material/handshake-circle-full.svg'),
  help: require('!!raw-loader!../../../images/icons/material/help.svg'),
  helpCircle: require('!!raw-loader!../../../images/icons/material/help-circle.svg'),
  history: require('!!raw-loader!../../../images/icons/material/history-black.svg'),
  hybridRemote: require('!!raw-loader!../../../../app/assets/images/iconic/hybrid-remote.svg'),
  instagram: require('!!raw-loader!../../../images/icons/material/instagram.svg'),
  invisible: require('!!raw-loader!../../../images/icons/material/invisible.svg'),
  jobSuggestion: require('!!raw-loader!../../../images/icons/material/job-suggestion.svg'),
  landing: require('!!raw-loader!../../../images/icons/material/landing.svg'),
  link: require('!!raw-loader!../../../images/icons/material/link.svg'),
  linkBox: require('!!raw-loader!../../../images/icons/material/link-box.svg'),
  linkedin: require('!!raw-loader!../../../images/icons/material/linkedin.svg'),
  location: require('!!raw-loader!../../../images/icons/material/location.svg'),
  login: require('!!raw-loader!../../../images/icons/material/login.svg'),
  logoSignature: require('!!raw-loader!../../../images/logo-signature.svg'),
  magnify: require('!!raw-loader!../../../images/icons/material/magnify.svg'),
  mapMarkerOutline: require('!!raw-loader!../../../../app/assets/images/iconic/map-marker-outline.svg'),
  marker: require('!!raw-loader!../../../images/icons/material/marker.svg'),
  menu: require('!!raw-loader!../../../images/icons/material/menu.svg'),
  message: require('!!raw-loader!../../../images/icons/material/message.svg'),
  microphone: require('!!raw-loader!../../../images/icons/material/microphone.svg'),
  minimalistClock: require('!!raw-loader!../../../../app/assets/images/iconic/minimalist-clock.svg'),
  minus: require('!!raw-loader!../../../images/icons/material/minus.svg'),
  mobile: require('!!raw-loader!../../../images/icons/material/mobile.svg'),
  moreInfo: require('!!raw-loader!../../../../app/assets/images/iconic/more-info.svg'),
  notSent: require('!!raw-loader!../../../images/icons/material/not-sent.svg'),
  okPuertoRico: require('!!raw-loader!../../..//images/icons/material/ok-puerto-rico.svg'),
  openEnvelope: require('!!raw-loader!../../../images/icons/material/open-envelope.svg'),
  pencil: require('!!raw-loader!../../../images/icons/material/pencil.svg'),
  people: require('!!raw-loader!../../../images/icons/material/people.svg'),
  person: require('!!raw-loader!../../../images/icons/material/person.svg'),
  plus: require('!!raw-loader!../../../images/icons/material/plus.svg'),
  remote: require('!!raw-loader!../../../../app/assets/images/iconic/vertical-remote.svg'),
  rocket: require('!!raw-loader!../../../images/icons/material/rocket.svg'),
  rocketLaunch: require('!!raw-loader!../../../../app/assets/images/iconic/rocket-launch.svg'),
  rss: require('!!raw-loader!../../../images/icons/material/rss.svg'),
  rssAlt: require('!!raw-loader!../../../../app/assets/images/iconic/rss-alt.svg'),
  school: require('!!raw-loader!../../../images/icons/material/school.svg'),
  sent: require('!!raw-loader!../../../images/icons/material/sent.svg'),
  share: require('!!raw-loader!../../../images/icons/material/share.svg'),
  shareBoxed: require('!!raw-loader!../../../images/icons/material/share-boxed.svg'),
  signal: require('!!raw-loader!../../../images/icons/material/signal.svg'),
  star: require('!!raw-loader!../../../images/icons/material/star.svg'),
  starCircle: require('!!raw-loader!../../../images/icons/material/star-circle.svg'),
  starHalfFull: require('!!raw-loader!../../../images/icons/material/star-half-full.svg'),
  starOutline: require('!!raw-loader!../../../images/icons/material/star-outline.svg'),
  tag: require('!!raw-loader!../../../images/icons/material/tag.svg'),
  tagThin: require('!!raw-loader!../../../../app/assets/images/iconic/tag.svg'),
  thumb: require('!!raw-loader!../../../images/icons/thumb.svg'),
  timer: require('!!raw-loader!../../../images/icons/material/timer.svg'),
  timerOutline: require('!!raw-loader!../../../images/icons/material/timer-outline.svg'),
  trash: require('!!raw-loader!../../../images/icons/material/trash.svg'),
  trayArrowDown: require('!!raw-loader!../../../images/icons/material/tray-arrow-down.svg'),
  twitter: require('!!raw-loader!../../../images/icons/material/twitter.svg'),
  unicorn: require('!!raw-loader!../../../images/icons/Unicorn.svg'),
  uploadFile: require('!!raw-loader!../../../images/icons/material/upload-file.svg'),
  visible: require('!!raw-loader!../../../images/icons/material/visible.svg'),
  warning: require('!!raw-loader!../../../images/icons/material/warning.svg'),
  warningCodeInfo: require('!!raw-loader!../../../images/icons/warning-code-info.svg'),
  whatsapp: require('!!raw-loader!../../../images/icons/material/whatsapp.svg'),
  wifi: require('!!raw-loader!../../../images/icons/material/wifi.svg'),
  bookmarkOutline: require('!!raw-loader!../../../images/icons/material/bookmark-outline.svg'),
  blog: require('!!raw-loader!../../../images/icons/material/blog.svg'),
  other: require('!!raw-loader!../../../images/icons/material/link.svg'),
};
