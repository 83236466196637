import * as React from 'react';
import Button from './button';

interface Props {
  url: string;
}

export default function OauthButton(props: Props) {
  return (
    <Button
      onClick={e => {
        e.preventDefault();
        location.href = props.url;
      }}
    />
  );
}
