import React from 'react';
import classNames from 'classnames';
import { Map } from 'immutable';
import { startCase } from 'lodash';

import Accordion from 'components/general/accordion/accordion';
import ApplicationStateButtons from 'components/application_page/application_state_buttons';
import Checkbox from 'components/form/checkbox/checkbox';
import EmptyState from '../general/empty_state/empty_state';
import GroupTooltip from './group_tooltip';
import Icon, { Size } from 'components/general/icon/icon';
import Pagination from '../general/pagination/pagination';
import StepColor from 'components/application_page/step_color';
import Tooltip from 'components/general/tooltip/tooltip';
import { Application } from '../../__models__/models';
import { ApplicationsState } from '../employer_applications/applications_state';
import { Step } from 'components/job_hiring_steps/models';
import { Urls } from './models';
import { applicationStateForEmployer } from 'lib/labels';

const styles = require('./applications_group.module.scss');

export interface Props {
  applications: Application[];
  appsState: ApplicationsState;
  bulkMoveToStep: Function;
  bulkReject: Function;
  expanded: boolean;
  handlePageClick: Function;
  idx: number;
  loader: Function;
  renderApplication: Function;
  slug: string;
  step: Step;
  steps: Step[];
  urls: Urls;
}

interface State {
  checkedApps: Map<string, boolean>;
  loading: boolean;
  prevApplications: Application[];
}

export default class ApplicationsGroup extends React.Component<Props, State> {
  accordionRef: any;

  constructor(props) {
    super(props);
    this.state = {
      checkedApps: null,
      loading: false,
      prevApplications: null,
    };

    this.handleMoveToStep = this.handleMoveToStep.bind(this);
    this.handleReject = this.handleReject.bind(this);
    this.accordionRef = React.createRef();
  }

  meetRequirementsTooltip =
    'These are the candidate you should look at first! They are the ones we consider as most likely to match your requirements.';

  static getDerivedStateFromProps({ applications }, state) {
    if (applications !== state.prevApplications) {
      const prevChecked = state.checkedApps ? state.checkedApps.toObject() : {};
      const checkedApps = Map<string, boolean>(
        applications.map(a => [a.id, prevChecked[a.id] || false])
      );
      return { checkedApps, prevApplications: applications };
    } else {
      return null;
    }
  }

  componentDidUpdate(prevProps) {
    const expanded = this.props.expanded;
    if (expanded !== prevProps.expanded) {
      this.accordionRef.current.setOpened(expanded);
    }
    if (this.state.loading && this.props.applications.length > 0) {
      this.setState(() => ({ loading: false }));
    }
  }

  getSelectedIds() {
    return this.state.checkedApps
      .toArray()
      .filter(e => e[1])
      .map(e => e[0]);
  }

  handleCheckboxChange = event => {
    const { name, checked } = event.target;
    this.setState(prevState => ({
      ...prevState,
      checkedApps: prevState.checkedApps.set(name, checked),
    }));
  };

  handleGroupCheckboxChange = event => {
    // Stop event propagation to avoid the accordion being toggled
    event.stopPropagation();
    const checked = event.target.checked;
    const checkedApps = this.state.checkedApps.map(() => checked);
    this.setState({ checkedApps });
  };

  handleMoveToStep() {
    const ids = this.getSelectedIds();
    const { bulkMoveToStep, step, urls } = this.props;
    let url;
    let stepId;

    if (isWaitingReview(step)) {
      url = urls.bulkMoveToReviewing;
    } else {
      const steps = this.props.steps;
      const idx = steps.indexOf(step);
      const next = steps[idx + 1];
      if (next.kind === 'fixed' && next.name === 'offer_made') {
        url = urls.bulkMoveToProposal;
      } else {
        url = urls.bulkMoveToStep;
        stepId = next.id;
      }
    }
    bulkMoveToStep(url, ids, stepId);
  }

  handleReject() {
    const ids = this.getSelectedIds();
    this.props.bulkReject(ids);
  }

  handlePageClick = (pageData, target = this.props.slug) => {
    this.setState(() => ({ loading: true }));
    this.props.handlePageClick(pageData, target, true);
  };

  render() {
    return (
      <div className={styles.container}>
        <Accordion
          // eslint-disable-next-line react/no-children-prop
          children={this.renderApplications()}
          defaultOpened={this.props.expanded}
          headerClassName={styles.header}
          ref={this.accordionRef}
          title={this.renderHeader()}
        />
      </div>
    );
  }

  renderApplications() {
    const page = this.props.appsState.page(this.props.slug);
    const total = this.props.appsState.count(this.props.slug);
    const perPage = this.props.appsState.perPage(this.props.slug);
    const apps = this.props.applications;

    if (this.state.loading) {
      return this.props.loader();
    }

    if (apps.length > 0) {
      return (
        <Pagination
          totalItemCount={total}
          perPage={perPage}
          handlePageClick={this.handlePageClick}
          currentPage={page}
          itemName='applications'
        >
          <div
            className={
              this.props.step.kind !== 'Probably not a match' ? styles.applicationGrid : ''
            }
          >
            {apps.map(this.renderApplicationWithCheckbox, this)}
          </div>
        </Pagination>
      );
    } else {
      return (
        <div>
          <EmptyState>This step has no applications available</EmptyState>
        </div>
      );
    }
  }

  renderApplicationWithCheckbox(application) {
    const checked = this.state.checkedApps.get(application.id);
    const checkboxProps = {
      checked,
      className: styles.applicationCheckbox,
      color: 'ripePlum',
      controlled: false,
      key: checked.toString(),
      name: application.id,
      onChange: this.handleCheckboxChange,
    };

    return (
      <div key={application.id} className={styles.entry}>
        <div className={styles.application}>
          {this.props.renderApplication(application, checkboxProps, this.props.slug)}
        </div>
      </div>
    );
  }

  isInProcessAccordion(name) {
    return ['Meet Requirements', 'Probably Not A Match', 'Worth Looking At'].indexOf(name) === -1;
  }

  isMeetRequirements(name) {
    return name === 'Meet Requirements';
  }

  isBulkDisabled() {
    const kind = this.props.step.kind;
    return !(kind === 'Probably not a match' || kind === 'Worth looking at');
  }

  renderHeader() {
    const checkedApps = this.state.checkedApps;
    const checked = !checkedApps.isEmpty() && checkedApps.every(x => x);
    const anyChecked = checkedApps.some(x => x);
    const step = this.props.step;
    const name =
      step.kind === 'fixed'
        ? applicationStateForEmployer(step.name, 'applications_list')
        : step.name || startCase(step.kind);
    const checkboxClass = classNames(styles.headerCheckbox, {
      [styles['is-visible']]: checkedApps.size > 0,
    });

    return (
      <div>
        <Checkbox
          checked={checked}
          className={checkboxClass}
          color='ripePlum'
          onChange={this.handleGroupCheckboxChange}
          key={checked.toString()}
          controlled={false}
        />
        {this.isInProcessAccordion(name) && (
          <>
            <span className={styles.stepIdx}>{this.props.idx + 1}</span>
            <StepColor className={styles.stepColor} slug={step.slug} />
          </>
        )}
        {this.isMeetRequirements(name) && (
          <Tooltip text={this.meetRequirementsTooltip} textBelow={true} color='ripePlum'>
            <Icon
              name='star'
              color='ripePlum'
              size={Size.MediumLarge}
              className={styles.starIcon}
            />
          </Tooltip>
        )}
        <span
          className={
            this.isMeetRequirements(name)
              ? classNames(styles.stepName, styles.meetRequirementsAccordion)
              : styles.stepName
          }
        >
          {name}
        </span>
        {!this.isInProcessAccordion(name) && <GroupTooltip name={name} />}
        <span
          className={
            this.isMeetRequirements(name)
              ? classNames(styles.stepCount, styles.meetRequirementsAccordion)
              : styles.stepCount
          }
        >
          {this.props.appsState.count(this.props.slug)}
          <Icon
            name='person'
            className={styles.personIcon}
            color={this.isMeetRequirements(name) ? 'ripePlum' : 'black'}
          />
        </span>
        {anyChecked && (
          <ApplicationStateButtons
            className={styles.stateButtons}
            delivered={isDelivered(step)}
            hideMoveToStep={isProposal(step)}
            moveToStep={this.handleMoveToStep}
            rejectApplication={this.handleReject}
            small={false}
            hideReject={this.isBulkDisabled()}
            waitingReview={isWaitingReview(step)}
            stopPropagation={true}
          />
        )}
      </div>
    );
  }
}

function isWaitingReview(step) {
  const states = ['curated', 'unreviewed', 'pending_information'];
  const inboxStates = ['Meet requirements', 'Worth looking at', 'Probably not a match'];
  return inboxStates.includes(step.kind) || states.includes(step.name);
}

function isDelivered(step) {
  const states = ['employer_reviewing', 'engaged', 'offer_made'];
  return (step.kind === 'fixed' && states.indexOf(step.name) > -1) || step.kind !== 'fixed';
}

function isProposal(step) {
  return step.kind === 'fixed' && step.name === 'offer_made';
}
