import React from 'react';
import { Job } from './types';
import Icon, { Size } from 'components/general/icon/icon';

const styles = require('./JobList.module.scss');

interface Props {
  jobList: Job[];
  submitError: boolean;
  job: number;
  setJob: (job: number) => void;
}

const JobList = (props: Props) => {
  const { jobList, submitError, job, setJob } = props;

  return (
    <div className={styles.jobListContainer} data-testid='job-list-container'>
      <div className={styles.title}>Choose job</div>
      {submitError && <span className={styles.submitError}>Please select a job to continue</span>}
      <div className={styles.jobList} data-testid='job-list'>
        {jobList?.map(jobAd => (
          <div
            key={jobAd.value}
            className={styles.jobCard + (job === jobAd.value ? ' selected' : '')}
            onClick={() => setJob(jobAd.value)}
          >
            <div className={styles.jobTitle}>{jobAd.label}</div>
            <div className={styles.jobCardInfo}>
              <div className={styles.jobCardInfoItem}>{jobAd.location}</div>
              <div className={styles.jobCardInfoItem}>
                <Icon name='calendarBlack' size={Size.QuiteSmall} />
                <span>{jobAd.daysRemaining}</span>
              </div>
              <div className={styles.jobCardInfoItem}>
                <Icon name='person' />
                <span>{jobAd.expectedNumberOfHires}</span>
              </div>
            </div>
          </div>
        ))}
        {jobList?.length === 0 && <span>No jobs</span>}
      </div>
    </div>
  );
};

export default JobList;
