import * as React from 'react';
import MismatchTimezoneModal from './mismatchTimezoneModal';
import { ShallowWrapper, shallow } from 'enzyme';
import Modal from '../modal/modal';
import FormInput from 'components/general/form_input/form_input';
import RadioButtons from 'components/form/radio_buttons/radio_buttons';
import Button from 'lj_shared/button/button';

const createTestProps = moreProps => {
  return {
    applicationId: 12,
    jobTimezone: 'From UTC-1 ti UTC+1',
    jobTitle: 'Job Title',
    personTimezone: 'UTC+1',
    remoteDetails: 'details',
  };
};

let wrapped: ShallowWrapper;

describe('Modal component', () => {
  beforeEach(() => {
    const props = createTestProps({});
    wrapped = shallow(<MismatchTimezoneModal {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('should have a modal', () => {
    expect(wrapped.find(Modal).length).toEqual(1);
  });

  it('should have one form input', () => {
    expect(wrapped.find(FormInput).length).toEqual(1);
  });

  it('should have radio buttons', () => {
    expect(wrapped.find(RadioButtons).length).toEqual(1);
  });

  it('should have one button', () => {
    expect(wrapped.find(Button).length).toEqual(1);
  });
});
