import React from 'react';
import 'lib/globals';
import Icon, { Size } from 'components/general/icon/icon';
import Button from 'lj_shared/button/button';

const styles = require('./billing_info.module.scss');

export interface BillingInfoProps {
  address: string;
  city: string;
  countryCode: string;
  id: number;
  invoiceName: string;
  notes: string;
  paymentDetails?: any;
  postcode: string;
  projectId: string;
  vatid: string;
}

export default function BillingInfo(props: BillingInfoProps) {
  const url = window.Routes.edit_billing_info({ project: props.projectId });

  return (
    <div className={styles.main}>
      <div className={styles.headerRegular}>
        <div className={styles.header}>
          <div className={styles.headline}>
            <span className={styles.headerTitle}>My Billing Info</span>
          </div>
          {props.id && (
            <a className={styles.editHeadline} href={url}>
              <Icon name={'pencil'} size={Size.MediumLarge} color={'black'} />
            </a>
          )}
        </div>
      </div>
      <hr className={styles.ruler} />
      {props.id ? renderBillingInfo(props) : renderAddBillingInfo(props)}
    </div>
  );
}

function renderBillingInfo(props) {
  return (
    <div>
      <div className={styles.billingName}>{props.invoiceName}</div>
      <div className={styles.billingLine}>
        <div>{props.address}</div>
        <div>
          {props.postcode} {props.city}
        </div>
        <div>{props.countryCode}</div>
      </div>
      <div className={styles.billingLine}>VAT: {props.vatid}</div>
      {props.paymentDetails && renderPaymentDetails(props)}
    </div>
  );
}

function renderPaymentDetails(props) {
  return Object.entries(props.paymentDetails).map(([label, detail]) => {
    return (
      <div className={styles.paymentLine} key={label}>
        <>
          {label}: {detail}
        </>
      </div>
    );
  });
}

function renderAddBillingInfo(props) {
  const url = window.Routes.new_billing_info({ project: props.projectId });

  return (
    <div>
      <div className={styles.noBillingText}>
        You have to fill in your billing info in order to start reporting your invoices.
      </div>
      <div className={styles.addBillingButton}>
        <Button buttonColor='tuftsBlue' isButton={false} buttonUrl={url}>
          Add Billing Info
        </Button>
      </div>
    </div>
  );
}
