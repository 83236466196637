import React, { useEffect, useState } from 'react';
import FormInput from 'components/general/form_input/form_input';
import TextInput from 'components/form/text/text';

export interface Props {
  error: string;
  handleAddWebsiteClick: any;
  handleDeleteWebsiteClick: any;
  website: any;
}

export default function LinkedInInput(props: Props) {
  const [url, setUrl] = useState(props.website ? props.website.url : '');
  const category = 'linkedin';

  useEffect(() => {
    if (props.website && props.website.url !== url) setUrl(props.website.url);
  }, [props.website]);

  return (
    <FormInput name='listOfLinks' label='Add your LinkedIn account' error={props.error}>
      <TextInput
        name='links'
        id='links'
        placeholder='e.g. https://linkedin.com/in/john-wick'
        color='tuftsBlue'
        value={url}
        controlled
        onChange={e => setUrl(e.target.value)}
        onBlur={() => props.handleAddWebsiteClick({ category, url })}
        onFocus={() => props.website && props.handleDeleteWebsiteClick(props.website.id)}
      />
    </FormInput>
  );
}
