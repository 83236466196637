import * as Cookies from 'js-cookie';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import Button from 'lj_shared/button/button';
import InlineIcon from 'components/general/inline_icon/inline_icon';
import MessageModal from 'components/job_hiring_steps/message_modal';
import Modal from 'components/general/modal/modal';
import * as Models from './models';
const styles = require('./ask_modal.module.scss');
const iconCheck = require('iconic/check.svg');

interface Props {
  jobId: number;
  onDone: Function;
  step: Models.Step;
  urls: any;
}

interface State {
  dontShow: boolean;
}

const DONT_SHOW_COOKIE = 'employer-dont_show_job_hiring_step_ask_modal';

export default class AskModal extends React.Component<Props, State> {
  messageModalRef: any;
  modalRef: any;

  constructor(props) {
    super(props);
    this.state = { dontShow: false };
    this.handleAdd = this.handleAdd.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleDone = this.handleDone.bind(this);
    this.handleDontShowChange = this.handleDontShowChange.bind(this);
    this.messageModalRef = React.createRef();
    this.modalRef = React.createRef();
  }

  handleAdd() {
    this.modalRef.current.close(false);
    this.messageModalRef.current.open();
  }

  handleCancel() {
    this.modalRef.current.close();
    const messageModal = this.messageModalRef.current;
    if (messageModal) {
      messageModal.close();
    }
  }

  handleDone() {
    if (this.state.dontShow) {
      Cookies.set(DONT_SHOW_COOKIE, 'true');
    }
    this.props.onDone();
  }

  handleDontShowChange(event) {
    this.setState({ dontShow: event.target.checked });
  }

  render() {
    return (
      <>
        {this.renderAskModal()}
        <MessageModal
          commitChanges
          step={this.props.step}
          message={this.props.step.message}
          urls={this.props.urls}
          close={this.handleDone}
          ref={this.messageModalRef}
        />
      </>
    );
  }

  renderAskModal() {
    return (
      <Modal
        className={styles.modal}
        defaultOpen={true}
        onClose={this.handleDone}
        ref={this.modalRef}
        title='Add an automated message'
      >
        <p className={styles.text}>
          Would you like to compose an automatic message to notify all the candidates who are moved
          to {this.props.step['label']} ?
        </p>
        <p className={styles.text}>
          Sending an automated message helps you to hand-in the tech assessments, request interviews
          and save your time by mass-contacting candidates.
        </p>
        <p className={styles.note}>
          Note: You can edit or add an automatic message to any step at any time by editing the job
          (Job &gt; Edit).
        </p>

        <div className={styles.footer}>
          <div className={`${styles.note} lj-checkboxGroup`}>
            <label className='lj-checkbox-label'>
              <input type='checkbox' onChange={this.handleDontShowChange} />
              <span className='lj-checkbox-ui'>
                <InlineIcon path={iconCheck} />
              </span>
              Don't show me this message again
            </label>
          </div>

          <div className={styles.buttons}>
            <Button
              buttonColor='ripePlum'
              buttonType='border'
              onClick={this.handleCancel}
              otherClasses={styles.button}
            >
              Do not add
            </Button>
            <Button buttonColor='ripePlum' onClick={this.handleAdd} otherClasses={styles.button}>
              Add message
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

  static show(jobId, step, urls) {
    return new Promise<void>(resolve => {
      const root = $('<div></div>');

      function onDone() {
        ReactDOM.unmountComponentAtNode(root[0]);
        root.remove();
        resolve();
      }

      if (Cookies.get(DONT_SHOW_COOKIE)) {
        resolve();
      } else {
        $('body').append(root);

        ReactDOM.render(
          <AskModal jobId={jobId} onDone={onDone} step={step} urls={urls} />,
          root[0]
        );
      }
    });
  }
}
