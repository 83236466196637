import { Application } from '__models__/models';

const applicationDummy: Application = {
  id: '99672',
  attributes: {
    updated_at: '2018-07-10T15:45:42.898Z',
    text: "The main reason this position is appealing to me is because it will give me the chance to work for a dynamic and relatively young company.\r\n\r\nI love learning new technologies and improving my existing skill set. I get the sense that Landing.jobs provides an excellent environment for this.\r\n\r\nAt this stage in my career I'm looking for a company that will provide the necessary mentorship and support to further improve my capabilities, but also a place where I can actively contribute my ideas and offer proactive solutions to ongoing challenges. Landing.jobs seems like the perfect fit!",
    current_hiring_step_id: null,
    submitted_at: '2018-06-10T11:35:16.506Z',
    submitted_more_than_14_days_ago: false,
    screening_notes: null,
    seen_by_employer: true,
    ats_applications: null,
    test_results: [
      {
        id: 9,
        test_id: 9,
        external_id: '11465346',
        score: 26.31,
        date: '2019-05-21T15:07:40.000Z',
        test_name: 'Landing.jobs Full-stack Developer',
        hiring_step: 'Hacker Rank test',
        report_url: 'https://www.hackerrank.com/x/tests/426548/candidates/9528406/report',
        completed: true,
        pdf_url: 'https://www.hackerrank.com/x/api/v3/tests/480744/candidates/11465346/pdf',
      },
    ],
    rating: 4,
    landingRating: 4,
    ratings_with_dimensions: [
      {
        id: 110378,
        rater_type: 'AdminUser',
        note: '',
        rating: 4,
        dimensions: [
          {
            dimension: 'skills',
            value: 5,
            data: [
              'The candidate has knowledge or experience in Software Engineering.',
              'The candidate has knowledge or experience in CSS (< 1 year) and HTML (< 1 year).',
              'The candidate has knowledge or experience in PostgreSQL (< 1 year) and Ruby on Rails (< 1 year).',
              "The candidate doesn't have experience with React.",
            ],
          },
          {
            dimension: 'language',
            value: 5,
            data: ['The candidate has\n      native or bilingual proficiency in  English.'],
          },
          {
            dimension: 'location',
            value: 4,
            data: [
              'The candidate is based in Oeiras, Portugal.',
              'The candidate has a valid citizenship/work permit for: Portugal.',
            ],
          },
          {
            dimension: 'experience',
            value: 1,
            data: ['The candidate has no relevant background for this position.\n'],
          },
          {
            dimension: 'bonus',
            value: 3,
            data: [],
          },
        ],
      },
    ],
    terminal: true,
    urls: {
      application_url: '/employer/applications/99672',
      rejection_url: '/employer/applications/99672/rejections',
      reconsider_application_url: '/employer/applications/99672/reconsider',
      test_invite_url: '/test_invite',
      email_url: '/employer/applications/99672/email',
      pdf_url: '/employer/applications/99672/info.pdf',
      profile_url: '/employer/search/people/99672',
      rate_application_url: '/employer/applications/99672/rating',
      attributes_url: '/employer/applications/99672/attributes',
      preview_rejection_url: '/employer/applications/99672/rejections/preview',
      employer_reviewing: '/employer/applications/99672/move_to_reviewing',
      hired: '/employer/applications/99672/hired?context=show',
      offer_made: '/employer/applications/99672/make_offer',
      step: '/employer/applications/99672/move_to_step',
    },
    has_application_cv: true,
    has_work_permit: 'Unknown',
    rejection: {
      rejection_reason: null,
      rejection_message: null,
      rejected_at: '',
    },
    has_ats_offer: false,
    application_tags: {
      candidate_search: false,
      expiration: false,
      days_to_expiration: null,
      visa_eligible: '',
      labels: [],
      suggested_by_company: false,
      testResults: [],
    },
    job_id: 5609,
    job_title: 'Junior Software Developer',
    experience_level: 5,
    job_ad: {
      title: 'Junior Software Developer',
      city: 'Lisbon',
      country: 'Portugal',
    },
    person: {
      id: 62383,
      attributes: {
        first_name: 'Miguel',
        last_name: 'Tavares',
        user_id: 64702,
        recipient_avatar:
          '<div class="lj-userAvatar--small lj-userAvatar"><img src="https://jobbox-assets-local-dev.s3-eu-west-1.amazonaws.com/attachments/talent_users/avatars/b0dede758585eed626bc4a329e88a80a5f0641e9/original.?1533144692" class="lj-userAvatar-img" /></div>',
        has_cv: true,
        name: 'Miguel Tavares',
        experience_years: { label: '5. 5+ years', value: 5 },
        location: 'Lisbon, Portugal',
        citizenships: 'Portuguese',
        phone_number: '+351 93 464 6038',
        email: 'conserva@gmail.com',
        websites: [
          {
            id: 94090,
            full_url: 'https://linkedin.com/in/miguel-tavares-conserva/',
            title: 'Linkedin',
            category_key: 'linkedin',
          },
          {
            id: 94089,
            full_url: 'https://github.com/buzzmeekz',
            title: 'Github',
            category_key: 'github',
          },
          {
            id: 94091,
            full_url: 'https://twitter.com/buzzmeekz',
            title: 'Twitter',
            category_key: 'twitter',
          },
          {
            id: 94092,
            full_url: 'http://migueltavar.es',
            title: 'Personal',
            category_key: 'personal',
          },
        ],
        languages: [
          {
            id: 129527,
            name: 'Portuguese',
            experienceLabel: '5. Native or Bilingual proficiency',
          },
          {
            id: 129528,
            name: 'Dutch',
            experienceLabel: '5. Native or Bilingual proficiency',
          },
          {
            id: 129529,
            name: 'English',
            experienceLabel: '5. Native or Bilingual proficiency',
          },
          {
            id: 129530,
            name: 'German',
            experienceLabel: '2. Limited working proficiency',
          },
          {
            id: 129531,
            name: 'Spanish',
            experienceLabel: '2. Limited working proficiency',
          },
          {
            id: 129532,
            name: 'French',
            experienceLabel: '1. Elementary',
          },
        ],
        skills: [
          {
            id: 949401,
            name: 'Ruby on Rails',
            experienceLabel: '1 Year',
          },
          {
            id: 1010047,
            name: 'AngularJS',
            experienceLabel: '1 Year',
          },
          {
            id: 1010045,
            name: 'TypeScript',
            experienceLabel: '1 Year',
          },
          {
            id: 949404,
            name: 'JavaScript',
            experienceLabel: '1 Year',
          },
          {
            id: 949403,
            name: 'CSS',
            experienceLabel: '4 Years',
          },
          {
            id: 949402,
            name: 'HTML',
            experienceLabel: '4 Years',
          },
          {
            id: 949409,
            name: 'WordPress',
            experienceLabel: '4 Years',
          },
          {
            id: 949407,
            name: 'Tester',
            experienceLabel: '4 Years',
          },
          {
            id: 949406,
            name: 'Technical Support',
            experienceLabel: '4 Years',
          },
          {
            id: 949405,
            name: 'Customer Support',
            experienceLabel: '4 Years',
          },
          {
            id: 949410,
            name: 'Customer Relations',
            experienceLabel: '4 Years',
          },
          {
            id: 1010049,
            name: 'Git',
            experienceLabel: '5 Years',
          },
          {
            id: 1010050,
            name: 'Github',
            experienceLabel: '5 Years',
          },
          {
            id: 1010051,
            name: 'PostgreSQL',
            experienceLabel: '1 Year',
          },
        ],
        certificates: [],
        person_tags: {
          multiple_processes: true,
        },
        availability: undefined,
        birth_year: 0,
        categories: [],
        city: '',
        contact_preference: 0,
        country_code: '',
        country_name: '',
        cv_file_name: '',
        description: '',
        freelance: false,
        headline: '',
        jobTypesInterest: [],
        phone: '',
        professional_experiences: [],
        relocation: false,
        relocation_countries: [],
        share_profile: 0,
        skype_id: '',
        start_year: {
          label: '',
          value: 0,
        },
        work_availability: 0,
        cvs: [],
        slug: '',
      },
    },
    hiring_steps: [
      {
        id: null,
        color: 'u-fill--starship',
        description: null,
        kind: 'state',
        test_name: null,
        label: "You're reviewing",
        message: null,
        name: null,
        number: 1,
        order: false,
        sublabel: null,
        value: 'state-employer_reviewing',
      },
      {
        id: 4475,
        color: 'u-fill--ripePlum',
        description: null,
        kind: 'step',
        test_name: 'Landing.jobs Full-stack Developer',
        label: 'Hacker Rank test',
        message: null,
        name: 'Tech Assessment',
        number: 2,
        order: 0,
        sublabel: 'Tech Assessment',
        value: 'step-4475',
      },
      {
        id: 4477,
        color: 'u-fill--ripePlum',
        description: null,
        kind: 'step',
        test_name: 'Inspire Ruby Developer Test',
        label: 'Ruby test',
        message: null,
        name: 'Tech Assessment',
        number: 3,
        order: 0,
        sublabel: 'Tech Assessment',
        value: 'step-4477',
      },
      {
        id: 23,
        color: 'u-fill--ripePlum',
        description:
          "At this stage you'll speak with Luis Pereira, Product Lead, to understand if your expectations are aligned with those of Landing.jobs and the specific position.",
        kind: 'step',
        test_name: null,
        label: 'Screening Interview',
        message: null,
        name: 'Interview',
        number: 4,
        order: 2,
        sublabel: 'Interview',
        value: 'step-23',
      },
      {
        id: 42,
        color: 'u-fill--ripePlum',
        description:
          "At this point we'll send you a challenge for you to complete. Nothing too troubling, just to see how you approach a problem, so be sure to be as expressive as you can!",
        kind: 'step',
        test_name: 'Landing.jobs Full-stack Developer',
        label: 'Technical Challenge',
        message: null,
        name: 'Tech Assessment',
        number: 5,
        order: 3,
        sublabel: 'Tech Assessment',
        value: 'step-42',
      },
      {
        id: 43,
        color: 'u-fill--ripePlum',
        description:
          "At this stage, we'll together review the challenge you previously did, and place you some questions targeting your technical abilities.",
        kind: 'step',
        test_name: null,
        label: 'Technical Interview',
        message: null,
        name: 'Interview',
        number: 6,
        order: 4,
        sublabel: 'Interview',
        value: 'step-43',
      },
      {
        id: 44,
        color: 'u-fill--ripePlum',
        description:
          "You'll have a conversation with our Co-Founder, Pedro Oliveira, to understand your cultural fit with Landing.jobs and personal potential.",
        kind: 'step',
        test_name: null,
        label: 'Cultural Interview',
        message: null,
        name: 'Interview',
        number: 7,
        order: 5,
        sublabel: 'Interview',
        value: 'step-44',
      },
      {
        id: null,
        color: 'u-border--puertoRico',
        description: null,
        kind: 'state',
        test_name: null,
        label: 'Proposal',
        message: null,
        name: null,
        number: 8,
        order: false,
        sublabel: null,
        value: 'state-offer_made',
      },
      {
        id: null,
        color: 'u-fill--puertoRico',
        description: null,
        kind: 'state',
        test_name: null,
        label: 'Hired',
        message: null,
        name: null,
        number: 9,
        order: false,
        sublabel: null,
        value: 'state-hired',
      },
    ],
    delivered: false,
    step_label: 'Hired',
    is_inbox: false,
    employer_filter: 'hired',
    current_state: 'state-hired',
    state: 30,
    state_name: 'hired',
    job_editable: false,
    self_evaluations: [],
    accepted_different_timezone: false,
    handshake: {
      created: '',
      accepted: '',
    },
    is_handpicked: false,
    is_handshake: false,
    handpicked_note: '',
    inbox_classification: '',
    matching_skills: [],
    other_skills: [],
    user_blocked: false,
  },
};

export default applicationDummy;
