import * as React from 'react';
import MessagesList, { MessageListProps } from './messages_list';
import { shallow, ShallowWrapper } from 'enzyme';
import messageDummy from '__fixtures__/message_dummy';

const createTestProps = moreProps => {
  return {
    messageable: messageDummy,
    messages: [messageDummy],
    userType: '',
    currentUserId: 1,
    ...moreProps,
  };
};

let props: MessageListProps;
let wrapped: ShallowWrapper;

describe('Messages List component', () => {
  beforeEach(() => {
    props = createTestProps({});
    wrapped = shallow(<MessagesList {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
