export class ViewerContext {
  handshakeProfile: boolean;
  publicProfilePage: boolean;

  constructor(handshakeProfile, publicProfilePage) {
    this.handshakeProfile = handshakeProfile;
    this.publicProfilePage = publicProfilePage;
  }

  isCandidate(): boolean {
    return !this.handshakeProfile && !this.publicProfilePage;
  }

  isEmployee(): boolean {
    return this.handshakeProfile;
  }

  isPublic(): boolean {
    return this.publicProfilePage;
  }
}
