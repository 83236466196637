import React from 'react';

const styles = require('./AboutSection.module.scss');

export default function SectionTitle({ title, id }) {
  return (
    <h3 className={styles.sectionTitle} id={id}>
      {title}
    </h3>
  );
}
