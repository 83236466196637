import React from 'react';
import classNames from 'classnames';
import RatingDimensionContainer from '../rating_dimension/rating_dimension_container';
import RatingDimension from '__models__/rating_dimension';
import RatingDimensionContainerUtils from '../rating_dimension/utils/rating_dimension_container_utils';
import OutdatedWarning from './outdated_warning';
import RatingNotes from './rating_notes';
import RatingConcernItem from '../rating_dimension/ratingConcern/RatingConcernItem';

const styles = require('./rating_body.module.scss');

interface Props {
  backoffice?: boolean;
  concern?: string;
  concernLevel?: number;
  concernNote?: string;
  dashboard?: boolean;
  dimensions: Partial<RatingDimension>[];
  hideCurationNotesIfLegacy: boolean;
  landingRating: number;
  landingRatingId: number;
  outdated?: boolean;
  screeningNotes?: string;
  title: string;
}

export default function RatingBody(props: Props) {
  const ratingDimensionContainer = new RatingDimensionContainerUtils(props).props();
  return (
    <div className={classNames(styles.bodyContainer, props.backoffice && styles.backoffice)}>
      <div>
        {props.outdated &&
          OutdatedWarning(
            `This screening is related to a previous version of the Job Ad, this might
      affect curation's accuracy`
          )}
      </div>
      <div className={styles.leftContainer}>
        <RatingDimensionContainer showDimensions {...ratingDimensionContainer} />
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.fullSize}>
          <RatingNotes
            dimensions={props.dimensions}
            hideCurationNotesIfLegacy={props.hideCurationNotesIfLegacy}
            screeningNotes={props.screeningNotes}
          />
        </div>
      </div>
      {props.concern && !props.hideCurationNotesIfLegacy && (
        <div className={styles.concernContainer}>
          <RatingConcernItem
            concern={props.concern}
            concernLevel={props.concernLevel}
            hideTooltip
            noStretch
          />
          <div className={styles.concernNote}>{props.concernNote}</div>
        </div>
      )}
    </div>
  );
}
