import React from 'react';
import { ApolloProvider, Query } from 'react-apollo';
import { apolloClient } from 'lib/graphql';
import gql from 'graphql-tag';

const query = gql`
  {
    jobs {
      nodes {
        id
        title
      }
    }
  }
`;

export default function GraphqlHello() {
  return (
    <ApolloProvider client={apolloClient}>
      <Query query={query}>{renderResponse}</Query>
    </ApolloProvider>
  );
}

function renderResponse({ loading, error, data }) {
  if (loading) {
    return <p>Loading...</p>;
  } else if (error) {
    return <p>Error :(</p>;
  } else {
    return <ul>{data.jobs.nodes.map(renderJob)}</ul>;
  }
}

function renderJob({ id, title }) {
  return (
    <li>
      {id} - <strong>{title}</strong>
    </li>
  );
}
