import React, { useRef, useState } from 'react';
import Modal, { ButtonsLayout } from 'components/general/modal/modal';
import Icon, { Size } from 'components/general/icon/icon';
import sanitizeHtml from 'sanitize-html-react';
import Button from 'lj_shared/button/button';
import classNames from 'classnames';

const styles = require('./rejectionReasonModal.module.scss');

export interface Props {
  label?: string;
  handshakes: any;
  showJob?: boolean;
  color: string;
}

const RejectionReasonModal = (props: Props) => {
  const modalRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const title = () => {
    return (
      <>
        <Icon name='handshake' color='silverSandMidDark' size={Size.Big} />
        <span className={styles.title}>Talent Comment</span>
      </>
    );
  };

  return (
    <>
      {props.label ? (
        <a className={classNames(styles.link, styles[props.color])} onClick={() => setIsOpen(true)}>
          {props.label}
        </a>
      ) : (
        <Button
          buttonColor={props.color}
          buttonSize='xSmall'
          buttonType='border'
          otherClasses={styles.button}
          isRound={true}
          isButton={true}
          onClick={() => setIsOpen(true)}
        >
          <Icon
            name='chatTextLeft'
            color={props.color}
            size={Size.Medium}
            className={styles.icon}
          />
        </Button>
      )}
      <Modal
        buttonsLayout={ButtonsLayout.OkOnly}
        buttonName='Close'
        buttonColor={props.color}
        title={title()}
        defaultOpen={false}
        ref={modalRef}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        buttonOnClick={() => setIsOpen(false)}
      >
        {props.handshakes.map(handshake => {
          const key = handshake.rejected_at.replace(/\D/g, '');

          return (
            <li className={styles.list} key={key}>
              {props.showJob && (
                <p className={styles.text}>
                  <strong>Job: {handshake.job_title || 'Unspecified'}</strong>
                </p>
              )}
              <p className={styles.text}>{sanitizeHtml(handshake.rejection_reason)}</p>
              <p className={styles.date}>Sent at {handshake.rejected_at}</p>
              <hr className={styles.separator} />
            </li>
          );
        })}
      </Modal>
    </>
  );
};

export default RejectionReasonModal;
