const SectionValuesImages = {
  agility: require('images/companyPage/agility.svg'),
  communication: require('images/companyPage/communication.svg'),
  courageous: require('images/companyPage/courageous.svg'),
  customer_focus: require('images/companyPage/customer_focus.svg'),
  diversity: require('images/companyPage/diversity.svg'),
  education: require('images/companyPage/education.svg'),
  empathy: require('images/companyPage/empathy.svg'),
  environment: require('images/companyPage/environment.svg'),
  growth: require('images/companyPage/growth.svg'),
  impact: require('images/companyPage/impact.svg'),
  innovation: require('images/companyPage/innovation.svg'),
  integrity: require('images/companyPage/integrity.svg'),
  mindful: require('images/companyPage/mindful.svg'),
  passion: require('images/companyPage/passion.svg'),
  personal_excellence: require('images/companyPage/personal_excellence.svg'),
  privacy: require('images/companyPage/privacy.svg'),
  respect: require('images/companyPage/respect.svg'),
  speed: require('images/companyPage/speed.svg'),
  team_spirit: require('images/companyPage/team_spirit.svg'),
};

export default SectionValuesImages;
