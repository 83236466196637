import * as React from 'react';
import { mount, shallow } from 'enzyme';
import JobListInfinite from './JobListInfinite';
import { JobResult } from './types';

/**
 * Utility function that mocks the `IntersectionObserver` API. Necessary for components that rely
 * on it, otherwise the tests will crash. Recommended to execute inside `beforeEach`.
 * @param intersectionObserverMock - Parameter that is sent to the `Object.defineProperty`
 * overwrite method. `jest.fn()` mock functions can be passed here if the goal is to not only
 * mock the intersection observer, but its methods.
 */
const setupIntersectionObserverMock = ({
  root = null,
  rootMargin = '',
  thresholds = [],
  disconnect = () => null,
  observe = () => null,
  takeRecords = () => [],
  unobserve = () => null,
} = {}): void => {
  class MockIntersectionObserver implements IntersectionObserver {
    readonly root: Element | null = root;
    readonly rootMargin: string = rootMargin;
    readonly thresholds: ReadonlyArray<number> = thresholds;
    disconnect: () => void = disconnect;
    observe: (target: Element) => void = observe;
    takeRecords: () => IntersectionObserverEntry[] = takeRecords;
    unobserve: (target: Element) => void = unobserve;
  }

  Object.defineProperty(window, 'IntersectionObserver', {
    writable: true,
    configurable: true,
    value: MockIntersectionObserver,
  });

  Object.defineProperty(global, 'IntersectionObserver', {
    writable: true,
    configurable: true,
    value: MockIntersectionObserver,
  });
};

window.Routes = {
  company: () => '',
  company_job_ad: () => '',
  join: () => '',
};

const job: JobResult = require('./fixtures/job_result_dummy.json');

const props = {
  page: 1,
  lastPage: false,
  profileCompleted: true,
  applications: [1],
  bookmarks: [],
  jobResults: [job],
  user: { type: 'TalentUser' },
  jobRequestPath: '',
  urlParams: '',
};

const useFetchJobs = jest.fn();

describe('JobList render', () => {
  beforeEach(() => {
    setupIntersectionObserverMock({ observe: useFetchJobs });
  });

  it('shallow renders without crashing', () => {
    const component = shallow(<JobListInfinite {...props} />);

    expect(component.exists()).toBe(true);
  });

  it('calls interation observer when scrolling', () => {
    const component = mount(<JobListInfinite {...props} />);

    component.first().prop('scroll');

    expect(useFetchJobs).toHaveBeenCalled();
  });
});
