import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import TeamSection from './TeamSection';
import ImageInput from '../form/imageInput/ImageInput';
import Button from 'lj_shared/button/button';
import Text from 'components/form/text/text';

let wrapped: ShallowWrapper;

describe('AboutSection', () => {
  beforeEach(() => {
    wrapped = shallow(<TeamSection />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('contains an ImageInput', () => {
    expect(wrapped.find(ImageInput).length).toEqual(1);
  });

  it('contains Text fields', () => {
    expect(wrapped.find(Text).length).toEqual(3);
  });

  it('contains a Button', () => {
    expect(wrapped.find(Button).length).toEqual(1);
  });
});
