import * as React from 'react';
import SidebarFilters, { Props } from './sidebar_filters';
import { shallow, ShallowWrapper } from 'enzyme';
import archivedJobDummy from '__fixtures__/archived_job_dummy';
import ongoingJobDummy from '__fixtures__/ongoing_job_dummy';

const createTestProps = moreProps => {
  return {
    archivedJobs: { data: [archivedJobDummy] },
    ongoingJobs: { data: [ongoingJobDummy] },
    setFilter: jest.fn(),
    filters: {
      ratings: [4, 5],
      job_id: 1,
      city: 'Lisbon',
      country: 'Portugal',
      expiration: false,
      candidate_search: false,
      multiple_processes: false,
      eu_citizenship: true,
    },
    clearFilters: jest.fn(),
    googleMapsApiKey: '',
    ...moreProps,
  };
};

let props: Props;
let wrapped: ShallowWrapper;

describe('Sidebar Filters component', () => {
  beforeEach(() => {
    props = createTestProps({});
    wrapped = shallow(<SidebarFilters {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
