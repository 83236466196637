import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import ApplicationHeaderButtonsContainer from './ApplicationHeaderButtonsContainer';
import applicationDummy from '__fixtures__/application_dummy';

const createProps = () => {
  return {
    application: applicationDummy,
    moveToStep: '',
    urls: [],
    waitingReview: true,
    topFilter: '',
  };
};
let props;
let wrapped: ShallowWrapper;

describe('Your description here', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<ApplicationHeaderButtonsContainer {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
