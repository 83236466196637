import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import EngagementWarningModal, { suggestionList } from './EngagementWarningModal';
import Modal from '../../general/modal/modal';
import Icon from 'components/general/icon/icon';

const createProps = () => ({});

let props;
let wrapped: ShallowWrapper;

describe('Engagement Warming Modal', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<EngagementWarningModal {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('has a Modal', () => {
    expect(wrapped.find(Modal)).toHaveLength(1);
  });

  it('has several Icons', () => {
    expect(wrapped.find(Icon)).toHaveLength(suggestionList.length);
  });
});
