import classNames from 'classnames';
import React, { useState } from 'react';

import SmallProfileDisplayItem, { DisplayProps } from './SmallProfileDisplayItem';

const styles = require('./SmallProfileDisplayList.module.scss');

interface Props {
  color: string;
  skills: DisplayProps[];
}

export default function SmallProfileDisplayList(props: Props) {
  const { skills } = props;
  return (
    <div className={styles.skillList}>
      {skills.map((skill, index) => (
        <SmallProfileDisplayItem
          color={props.color}
          header={generateHeader(index, props.color)}
          {...skill}
          key={skill.title}
          isOther={index > 4}
        />
      ))}
    </div>
  );
}

function generateHeader(index, color) {
  switch (index) {
    case 0:
      return <div className={classNames(styles.topHeader, styles[color])}>Top</div>;
    case 5:
      return <div className={styles.otherHeader}>Other</div>;
    default:
      return <div className={styles.placeholder}>&nbsp;</div>;
  }
}
