import * as React from 'react';
import { shallow } from 'enzyme';
import AcceptHandshake, { Props } from './accept_handshake';
import Button from 'lj_shared/button/button';

let props: Props;
let wrapped = null;

const createTestProps = () => {
  return {
    cvs: [],
    handshake: jest.fn(),
    handshake_company: {
      name: 'test company',
      id: 10032,
      logo: 'this is a logo URL',
    },
    handshake_job_ad: { title: 'my job', id: 177013 },
    hasFollowUpModal: false,
  };
};

describe('Accept Handshake component', () => {
  beforeAll(() => {
    props = createTestProps();
    wrapped = shallow(<AcceptHandshake {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('contains 1 Button', () => {
    expect(wrapped.find(Button).length).toEqual(1);
  });
});
