import JobInterests from './jobInterests';
import ProfileForm from 'components/registration/profile_form';

export default class JobInterestsFields extends JobInterests {
  profileForm: ProfileForm;

  firstCol = () => [
    {
      title: 'Areas of interest',
      uniqueKey: 'job_categories',
      value: this.jobCategories(),
      border: true,
    },
  ];

  secondCol = () => [
    {
      title: 'Job type preferences',
      uniqueKey: 'job_type_preferences',
      value: this.jobTypePreferences(),
      border: true,
    },
  ];

  thirdCol = () => [
    {
      title: 'Remote Policy preferences',
      uniqueKey: 'remote_policy_preferences',
      value: this.remotePolicyPreferences(),
    },
    {
      title: 'Location relocation',
      uniqueKey: 'relocation',
      value: this.relocation(),
    },
  ];

  props = () => ({
    firstCol: [...this.firstCol()],
    secondCol: [...this.secondCol()],
    thirdCol: [...this.thirdCol()],
  });
}
