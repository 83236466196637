import React from 'react';
import Icon, { Size } from 'components/general/icon/icon';
import Button from 'lj_shared/button/button';

const styles = require('./job_search_cta.module.scss');

interface Props {
  averageLow: number;
  categoryId: number;
  jobsBySalaryCount: number;
}

export default function JobSearchCta(props: Props) {
  const { averageLow, categoryId, jobsBySalaryCount } = props;
  const averageLowString = (averageLow * 1000).toLocaleString().replace(',', '.');
  const jobSearchUrl = window.Routes.search_job_ads({
    s_l: averageLowString,
    cc: 'EUR',
    c: [categoryId],
  });

  return (
    <div className={styles.container}>
      <Icon name={'warningCodeInfo'} size={Size.BigHero} />
      <div className={styles.containerRight}>
        <div className={styles.containerText}>
          We currently have&nbsp;
          <span className={styles.bold}>{jobsBySalaryCount} jobs </span>
          with&nbsp;
          <span className={styles.bold}>
            {averageLowString}€ of minimum salary in your job category.
          </span>
        </div>
        <Button buttonColor='tuftsBlue' isButton={false} isRound={true} buttonUrl={jobSearchUrl}>
          Check these jobs
        </Button>
      </div>
    </div>
  );
}
