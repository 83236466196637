import 'lib/globals';

export class HiringStepSelect {
  handlers: {
    change: Function[];
  };
  reduced: boolean;
  root: JQuery;
  selectize: any;
  steps: any[];

  constructor(root, options) {
    this.root = root;
    options = options || {};
    this.reduced = options.reduced;
    this.handlers = { change: [] };
    this.steps = this.root.data('steps');

    const opts = {
      options: this.steps,
      valueField: 'value',
      render: {
        item: this.elementRender.bind(this, 'item'),
        option: this.elementRender.bind(this, 'option'),
      },
    };

    this.selectize = this.root.selectize(opts);
    this.selectize.load(this.resize.bind(this));
    $(window).on('resize', this.resize.bind(this));
    this.root.on('change', this.handleChange.bind(this));
    $(document).on(
      'mousedown',
      '.js-select-hiringSteps .selectize-input',
      this.handleMouseDown.bind(this)
    );
  }

  addChangeHandler(handler) {
    this.handlers['change'].push(handler);
  }

  handleMouseDown(ev) {
    ev.preventDefault();
    $('.selectize-input').addClass('focus input-active dropdown-active');
  }

  elementRender(kind, data, escape) {
    return `<div class='${kind}'>
       <div class='item-stepContent'>
         <div class='item-stepNumber'>${escape(data.$order)}</div>
         <div class='item-stepColor ld-status-color ${escape(data.color)}'></div>
         <div class='item-stepText'>
           <div class='item-stepText-heading'>${escape(data.label)}</div>
           <div class='item-stepText-subHeading'>${escape(data.sublabel || '')}</div>
         </div>
       </div>`;
  }

  handleChange() {
    const val = this.root.val();
    for (const handler of this.handlers['change']) {
      handler(val);
    }
    if ($('body').is('.employer-job_ads.show')) {
      // This is a hack to stop weird things from happening on this page
      window.Turbolinks.visit(window.location);
    }
  }

  resize() {
    const windowSize = $(window).width();
    const itemHeight = this.reduced ? 60.2 : 86.2;
    let height;

    if (this.steps.length > 7) {
      height = itemHeight * 7 + 4;
    } else if (windowSize > 768) {
      height = itemHeight * this.steps.length + 4;
    } else {
      height = 436;
    }

    const dropdown = this.root.next('.selectize-control').find('.selectize-dropdown');
    dropdown.css('height', height);
  }
}
