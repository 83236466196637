import classNames from 'classnames';
import React from 'react';
import Button from './button';
import track from '../../../lib/drip';

const styles = require('./hero_employer.module.scss');

let myRef;

interface Props {
  language: {
    title: string[];
    text: string[];
    startButton: string;
    buttons: {
      contractor: string;
      company: string;
    };
  };
}

interface State {
  width: number;
}

/**
 * Component which renders the landing Hero and the 'HomepageButtons'
 * components
 */

class HeroEmployer extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    myRef = React.createRef();
    this.state = {
      width: 0, // or your default width here
    };
  }

  /**
   * Function which on component mount checks live inner width size
   * of the window
   */

  componentDidMount() {
    this.handleWindowSizeChange(); // Set width
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  /**
   * Function applied to onClick event on a button to scroll the user down to the
   * next section of the landing page
   */

  scrollToMyRef = () => {
    if (window.screen.width < 767) {
      window.scrollTo(0, myRef.current.offsetTop - 100);
    } else {
      window.scrollTo(0, myRef.current.offsetTop - 80);
    }
  };

  render() {
    const { title, text, startButton } = this.props.language;

    return (
      <div className={classNames(styles['lw-hero-container'], styles['lw-hero-section'])}>
        <div className={styles['lw-hero-padding']}>
          <div className={styles['lw-hero-employer']}>
            <div className={styles['lw-hero-text-wrapper']}>
              <div>
                <div
                  className={classNames(
                    styles['lw-hero-heading'],
                    styles['lw-hero-heading-employer']
                  )}
                >
                  <p>{title}</p>
                </div>
                <div
                  className={classNames(styles['lw-hero-text'], styles['lw-hero-text-employer'])}
                >
                  <p>{text[0]}</p>
                  <p>{text[1]}</p>
                </div>
              </div>
              <div className={styles['midButtons-wrapper']}>
                <Button
                  onClick={() =>
                    track('action', {
                      name: 'work-homepage',
                      controller: 'work',
                      action: 'client-hero',
                    })
                  }
                  otherClasses={styles['lw-hero-employer-button']}
                  buttonColor='ripePlum'
                  buttonSize='mid'
                  isRound={true}
                  isButton={false}
                  buttonUrl='/employer/join'
                >
                  {startButton}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div ref={myRef} />
      </div>
    );
  }
}

export default HeroEmployer;
