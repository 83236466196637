import React from 'react';

import './integration_faq.scss';
const syncGif = require('images/icons/sync.gif');
const pairGif = require('images/icons/pair.gif');
const cvGif = require('images/icons/cv.gif');

const RenderFaq = () => {
  return (
    <div>
      <div>
        <div className='ld-FAQ-title'>Frequently Asked Questions</div>

        <FAQ
          question='1. How do I connect with my ATS?'
          answer='We are currently integrating with +30 different ATS! If you cannot find your ATS on the initial list, please click Connect in "Other ATS integrations", and a second page will appear.'
        />
        <FAQ
          question="2. What happens when I click in the 'Connect' button?"
          answer={
            <>
              You will be required to authenticate the ATS integrations. Depending on your ATS, a modal will appear where you will be requested to interest your API token/access token or to insert your ATS credentials and allow the connection (see how to link your account and get your tokens <a href="https://help.merge.dev/en/collections/4258188-hris" target="_blank" rel="noopener noreferrer">here</a>). Please bare in mind that for most of the integrations, an administrator role is required in order to link to LandingJobs successfully.
            </>
          }
        />
        <FAQ
          question='3. Why is the Connect button not working?'
          answer="If you're already connected to another ATS, the buttons for the
          other ATS's will not work. If you aren't, get in touch with your
          Accoun Manager and we will check what the issue is."
        />
        <FAQ
          question='4. When does the platform sync with the ATS?'
          answer='The sync takes place at 08:00 (GMT+1) evey day.'
        />
        <FAQ
          question='5. Why are applications sometimes not being transfered automatically?'
          answer='Applications will be transfered automatically every day at 08:00 (GMT+1).
          If you want to export or sync before that time you can go to the application page and Export to ATS or Sync.'
          img={syncGif}
          alt='sync application'
        />
        <FAQ
          question="6. When I click on the 'Export to ATS' inside an application nothing happens, why?"
          answer='It can take a few seconds to respond.'
        />
        <FAQ
          question='7. Do you get access to the hiring steps from the ATS?'
          answer='The hiring steps are imported when you sync the application.'
        />
        <FAQ
          question='8. What happens when I reject the candidates in the ATS?'
          answer='The candidates get automatically rejected on Landing.jobs when you reject them
          through the ATS. The candidate also receives an automatic message informing about it.
          At the moment there is no way to avoid sending that message.'
        />
        <FAQ
          question='9. What happens if I reject a candidate in the platform?'
          answer='After syncing the candidate will also appear as rejected on the ATS.'
        />
        <FAQ
          question="10. I've already sent a rejection message to my candidate, I don't want them
          to receive another one from Landing.jobs. Can you help me?"
          answer='Please check question 8.'
        />
        <FAQ
          question='11. Is there a way to import my job descriptions from the ATS?'
          answer='Sorry, not at the moment.'
        />
        <FAQ
          question='12. What happens if I connect with the ATS after I already have candidates in pipeline?'
          answer='When you pair the job with the ATS, there is a checkbox that will automatically export all the candidates in the pipeline.'
          img={pairGif}
          alt='pair job'
        />
        <FAQ
          question='13. The ATS I use is not on the list. Is there a way to integrate it?'
          answer="If you work with another ATS that we don't have an integration with you can always export the CV (pdf) and then import it to your ATS."
          img={cvGif}
          alt='cv job'
        />
      </div>

      <div className='ld-FAQ-subtext'>
        Feel free to send us an email at
        <a href='mailto:wegotyourback@landing.jobs' className='purplefy'>
          {' wegotyourback@landing.jobs '}
        </a>
        if you need further assistance.
      </div>
    </div>
  );
};

const FAQ = props => {
  return (
    <div className='ld-FAQ-sub_title'>
      {props.question}
      <div className='ld-FAQ-text'>{props.answer}</div>
      {props.img && <img className='ld-FAQ-image' src={props.img} alt={props.alt} />}
    </div>
  );
};

export default RenderFaq;
