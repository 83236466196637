import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import JobSearchCta from './job_search_cta';

window.Routes = {
  search_job_ads: jest.fn().mockReturnValue('http://localhost:3000/job_ads/search'),
};

const createProps = () => ({
  averageLow: 28,
  categoryId: 2,
  jobsBySalaryCount: 100,
});

let props;
let wrapped: ShallowWrapper;

describe('Job Search CTA Component', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<JobSearchCta {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
