import React from 'react';

import * as Models from '__models__/gql/all';
import { BookmarksContainer } from './BookmarksContainer';
import { JobDismissalsContainer } from './JobDismissalsContainer';
import Icon from 'components/general/icon/icon';
const styles = require('./Actions.module.scss');

interface Props {
  bookmark?: Partial<Models.Bookmark>;
  job: Partial<Models.Job>;
  jobDismissal?: Partial<Models.JobDismissal>;
  user?: Partial<Models.User>;
}

export default function Actions({ bookmark, job, jobDismissal, user }: Props) {
  if (user?.type === 'TalentUser') {
    return (
      <div>
        <div className={styles.actions}>
          <Bookmark bookmark={bookmark} job={job} />
          <Dismissal jobDismissal={jobDismissal} job={job} />
        </div>
      </div>
    );
  } else {
    return null;
  }
}

function Bookmark({ bookmark, job }) {
  const bookmarks = BookmarksContainer.useContainer();
  const offProps = {
    icon: 'starOutline',
    label: 'Subscribe to job',
    onClick: () => bookmarks.create(job.id),
  };
  const onProps = {
    icon: 'star',
    label: 'Unsubscribe job',
    onClick: () => bookmarks.destroy(bookmark.id),
  };

  return <Action isOn={!!bookmark} offProps={offProps} onProps={onProps} />;
}

function Dismissal({ job, jobDismissal }) {
  const dismissals = JobDismissalsContainer.useContainer();
  const offProps = {
    icon: 'close',
    label: 'Dismiss job',
    onClick: () => dismissals.create(job.id),
  };
  const onProps = {
    icon: 'close',
    label: 'Restore',
    onClick: () => dismissals.destroy(jobDismissal.id),
  };

  return <Action isOn={!!jobDismissal} offProps={offProps} onProps={onProps} />;
}

interface ActionSubProps {
  icon: string;
  label: string;
  onClick: any;
}

interface ActionProps {
  isOn: boolean;
  offProps: ActionSubProps;
  onProps: ActionSubProps;
}

function Action({ isOn, offProps, onProps }: ActionProps) {
  const props = isOn ? onProps : offProps;

  return (
    <a className={styles.action} onClick={props.onClick}>
      <Icon name={props.icon} />
      <span className={styles.actionLabel}>{props.label}</span>
    </a>
  );
}
