import React from 'react';
import Icon, { Style } from 'components/general/icon/icon';

const styles = require('./AboutSectionSmallCard.module.scss');

interface Props {
  handleDelete?: any;
  id?: number;
  text: string;
}

export default function AboutSectionSmallCard(props: Props) {
  return (
    <div className={styles.cardContainer}>
      {props.text && <div className={styles.textContainer}>{props.text}</div>}
      <div onClick={() => props.handleDelete(props.id)}>
        <Icon className={styles.close} name='close' color='ripePlumLight' style={Style.Bold} />
      </div>
    </div>
  );
}
