import * as React from 'react';
import { shallow } from 'enzyme';
import RatingBox from './rating_box';
let wrapped;

describe('Rating Box component', () => {
  beforeEach(() => {
    wrapped = shallow(<RatingBox value={4} ratingRef='' name='language' />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});
