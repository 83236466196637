import * as React from 'react';
import TalentProfile, { Props } from './TalentProfile';
import { mount } from 'enzyme';
import talentProfileDummy from '__fixtures__/talent_profile';
import talentProfileLocked from '__fixtures__/talent_profile_locked';

const createProps = (otherProps = {}) => ({
  profile: talentProfileDummy,
  ...otherProps,
});

const supportedCommands = ['copy'];
Object.defineProperty(document, 'execCommand', {
  value: cmd => supportedCommands.includes(cmd),
});

window.Routes = {
  edit_user_profile: () => '',
  users_language_proficiencies: () => '',
  users_language_proficiency: () => '',
  person_institution: () => '',
  person_institutions: () => '',
  resume: () => '',
  dashboard_help: () => '',
};

let props: Props;
let wrapped;

describe('TalentProfile', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = mount(<TalentProfile {...props} />);
  });

  it('renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

describe('Candidate Search', () => {
  describe('Profile is locked', () => {
    beforeEach(() => {
      props = createProps({
        profile: talentProfileLocked,
        unlocked: false,
        origin: 'employer/people',
      });
      wrapped = mount(<TalentProfile {...props} />);
    });

    it('does not show the candidate picture', () => {
      expect(wrapped.find('.picture').props().src).toMatch(/default_avatars/);
    });
  });

  describe('Profile is locked and open', () => {
    beforeEach(() => {
      props = createProps({
        open_profile: true,
        unlocked: false,
        origin: 'employer/people',
      });
      wrapped = mount(<TalentProfile {...props} />);
    });

    it('shows the candidate picture', () => {
      expect(wrapped.find('.picture').props().src).toMatch(/default_avatars/);
    });
  });

  describe('Profile is unlocked', () => {
    beforeEach(() => {
      props = createProps({
        unlocked: true,
        origin: 'employer/people',
      });
      wrapped = mount(<TalentProfile {...props} />);
    });

    it('shows the candidate picture', () => {
      expect(wrapped.find('.picture').props().src).toMatch(/default_avatars/);
    });
  });
});
