import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import ManageTargetLabelsModal from './manage_target_labels_modal';

const createProps = () => ({});

let props;
let wrapped: ShallowWrapper;

describe('Your description here', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<ManageTargetLabelsModal {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
