import * as React from 'react';
import Team from './Team';
import { shallow, ShallowWrapper } from 'enzyme';
import TeamMemberList from '../../teamMember/TeamMemberList';

const createProps = () => ({
  id: 1,
  fullName: 'This is a name',
  role: 'This is a role',
  resume: 'This is a resume',
  photo: {
    name: 'this is a photo',
    imageURL: 'https://picsum.photos/200/300',
  },
});

let wrapped: ShallowWrapper;
let props;

describe('Team', () => {
  props = createProps();
  beforeAll(() => {
    wrapped = shallow(<Team {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('contains a TeamMemberList', () => {
    expect(wrapped.find(TeamMemberList).length).toEqual(1);
  });
});
