import React from 'react';
import { shallow } from 'enzyme';
import AdminCard from './admin_card';

window.Routes = {
  company: () => '',
  company_job_ad: () => '',
  join: () => '',
};

describe('AdminCard', () => {
  it.skip('shallow renders without crashing', () => {
    const component = shallow(<AdminCard />);
    expect(component.exists()).toBe(true);
  });
});
