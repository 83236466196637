import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import CodeChallenges from './code_challenges';
import assessmentDummy from '__fixtures__/assessment_dummy';
import assessmentResultDummy from '__fixtures__/assessment_result_dummy';

window.Routes = {
  assessment_pdf: () => '',
};

const createProps = () => ({
  assessments: assessmentDummy,
  results: assessmentResultDummy,
  scope: 'official',
});

let props;
let wrapped: ShallowWrapper;

describe('Your description here', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<CodeChallenges {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
