import * as React from 'react';
import EmployerApplications, { Props } from './employer_applications';
import { shallow, ShallowWrapper } from 'enzyme';
import ongoingJobDummy from '__fixtures__/ongoing_job_dummy';
import archivedJobDummy from '__fixtures__/archived_job_dummy';
import applicationDummy from '__fixtures__/application_dummy';

jest.mock('js-cookie', () => ({ get: () => '8059' }));

window.Routes = {
  current_date_employer: () => '',
};

const createTestProps = moreProps => {
  return {
    googleMapsApiKey: '',
    followed_jobs: [ongoingJobDummy],
    other_jobs: [archivedJobDummy],
    chat_url: '',
    checkApplicationsUrl: '',
    experience_years: 2,
    readyCounter: 3,
    reviewCounter: 3,
    waitingForScreeningCounter: 2,
    applicationsUrl: '',
    searchScope: '',
    application: applicationDummy,
    applicationInformation: {},
    candidate_locations: [],
    hiringSteps: [
      {
        description: '',
        hasApplications: true,
        id: 1,
        jobId: 1,
        kind: '',
        message: {
          id: 2,
          text: '',
        },
        name: '',
        order: 1,
        slug: '',
      },
    ],
    urls: {
      bulkMoveToReviewing: '',
      bulkMoveToStep: '',
      bulkMoveToProposal: '',
      bulkReject: '',
      rateApplication: '',
    },
    disengaged: false,
    contacts_url: '',
    ...moreProps,
  };
};

let props: Props;
let wrapped: ShallowWrapper;

describe('Employer Applications component', () => {
  beforeEach(() => {
    props = createTestProps({});
    wrapped = shallow(<EmployerApplications {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
