import * as React from 'react';
import TopFilters from './top_filters';
import { shallow } from 'enzyme';
import Button from 'lj_shared/button/button';

let wrapped;
let handleMock;

beforeAll(() => {
  handleMock = jest.fn(filter => filter);
  wrapped = shallow(
    <TopFilters
      setTopFilter={handleMock}
      ongoingCounter={5}
      draftsCounter={3}
      activeFilter={'ongoing'}
    />
  );
});

it('should show ongoing jobs counter', () => {
  expect(
    wrapped.find({ 'data-testid': 'ongoing' }).find({ 'data-testid': 'badge' }).text()
  ).toEqual('5');
});

it('should show drafts counter', () => {
  expect(wrapped.find({ 'data-testid': 'drafts' }).find({ 'data-testid': 'badge' }).text()).toEqual(
    '3'
  );
});

it('has an active filter', () => {
  expect(wrapped.find({ 'data-testid': 'labelActive' }).text()).toEqual('Ongoing5');
});

it('changes filter on click', () => {
  const draftsButton = wrapped.find({ 'data-testid': 'drafts' });
  draftsButton.simulate('click');

  expect(handleMock.mock.results[0].value).toBe('drafts');
});
