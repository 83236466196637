import React from 'react';
import Tag from './tag';
import InlineIcon from '../inline_icon/inline_icon';
import { shallow, ShallowWrapper } from 'enzyme';

let wrapped: ShallowWrapper;

describe('Test Notice component', () => {
  it('renders correctly', () => {
    wrapped = shallow(<Tag title='cenas' />);
    expect(wrapped.exists()).toBe(true);
  });

  it('renders a link when url is provided', () => {
    wrapped = shallow(<Tag title='cenas' url='#' />);
    expect(wrapped.find('a').exists()).toBe(true);
  });

  it('renders correctly renders classes', () => {
    wrapped = shallow(<Tag title='cenas' labelClass='ripePlum fill' />);
    expect(wrapped.find('.ripePlum.fill').exists()).toBe(true);
  });

  describe('icon', () => {
    beforeAll(() => {
      wrapped = shallow(<Tag title='cenas' icon='bullhorn.svg' iconClass='white' />);
    });

    it('renders an icon when it is provided', () => {
      expect(wrapped.find(InlineIcon).exists()).toBe(true);
    });

    it('renders classes correctly', () => {
      expect(wrapped.find('.white').exists()).toBe(true);
    });
  });
});
