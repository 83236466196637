import React, { useRef } from 'react';
import TransferToAnotherJobModal, {
  TransferMode,
} from 'components/general/transfer_to_another_job/transfer_to_another_job_modal';

interface Props {
  applicationId: number;
  canCloseCurrentApplication: boolean;
  classNames: string;
  isPairedWithAts: boolean;
  originalJobName: string;
}

export default function TransferToAnotherJob(props: Props) {
  const modalRef = useRef(null);

  const handleClick = event => {
    event.preventDefault();
    modalRef.current.open();
  };

  return (
    <>
      <a className={props.classNames} onClick={handleClick}>
        Transfer To Another Job
      </a>
      <TransferToAnotherJobModal
        ref={modalRef}
        applicationId={props.applicationId}
        canCloseCurrentApplication={props.canCloseCurrentApplication}
        isPairedWithAts={props.isPairedWithAts}
        originalJobName={props.originalJobName}
        canTransferToAnUnpublishedJob={true}
        transferMode={TransferMode.ImmediateTransfer}
      />
    </>
  );
}
