import * as React from 'react';
import IntegrationButton from '../ats/token_button';

interface Props {
  url: string;
}

export default function Button(props: Props) {
  return (
    <IntegrationButton
      url={props.url}
      atsName={'Recruitee'}
      atsPathToCreateToken={'Settings > Apps and plugins > Personal API Tokens'}
      atsAPIDocUrl={'https://docs.recruitee.com/reference#section-generate-api-token'}
    />
  );
}
