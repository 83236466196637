import * as React from 'react';
import InlineIcon from '../inline_icon/inline_icon';
import { Note } from '../../conversations/components/models';
import '../../../lib/globals';
import formatDate from '../../../lib/format_date';
import showdown from 'showdown';
import DotLoader from 'lj_shared/dot_loader/dot_loader';

const styles = require('./application_notes.module.scss');
const trashIcon = require('iconic/trash.svg');
const eyeIcon = require('iconic/eye.svg');

export interface Props {
  getNotesUrl: string;
  postNoteUrl: string;
  destroyNoteUrl: string;
  applicationId: number;
}

interface State {
  notes: Note[];
  value: string;
  loading: boolean;
}

export default class ApplicationNotes extends React.Component<Props, State> {
  private input: React.RefObject<HTMLTextAreaElement>;
  private _isMounted = false;

  constructor(props: Props) {
    super(props);

    this.state = {
      notes: [],
      value: '',
      loading: true,
    };

    this.input = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    this.getNotes();
  }

  componentDidUpdate(nextProps) {
    if (this.props.applicationId !== nextProps.applicationId) {
      this.getNotes();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getNotes = async () => {
    this.setState(prevState => {
      return { ...prevState, loading: true };
    });
    const url = this.props.getNotesUrl.replace(':id', String(this.props.applicationId));

    fetch(url)
      .then(response => {
        response
          .json()
          .then(result => {
            if (response.ok) {
              if (this._isMounted) {
                this.setState(prevState => {
                  return { ...prevState, notes: result.data, loading: false };
                });
              }
            }
          })
          .catch(() => {
            window.Alerts.alert('Error getting notes');
          });
      })
      .catch(() => {
        window.Alerts.alert('Error getting notes');
      });
  };

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      const form: HTMLElement = document.querySelector('.lj-notes-form');
      const textarea: HTMLElement = form.children[0] as HTMLElement;

      if (Number(textarea.style.height.slice(0, -2)) < 200) {
        textarea.style.height = String(Number(textarea.offsetHeight) + 24) + 'px';
        form.style.flexBasis = String(Number(textarea.offsetHeight) + 24) + 'px';
      }
    }
  };

  handleNoteSubmit = async event => {
    event.preventDefault();
    window.toggleSpinner(false);
    this.setState(prevState => {
      return { ...prevState, loading: true };
    });

    if (this.state.value.length > 3 && this.state.value.length < 3000) {
      const response = await fetch(this.props.postNoteUrl, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          application_note: { text: this.state.value },
          application_id: this.props.applicationId,
        }),
      });

      const result = await response.json();

      if (response.ok) {
        this.setState(prevState => {
          return {
            notes: [result.data, ...prevState.notes],
            loading: false,
          };
        });
      }
    } else {
      window.alert('Text must be between 3 and 3000 characters');
      this.setState({ loading: false });
    }

    this.setState(prevState => {
      return { ...prevState, value: '' };
    });
    this.input.current.style.height = '';
    this.input.current.focus();
  };

  handleNoteDelete = async (event, id) => {
    event.preventDefault();

    const url = this.props.destroyNoteUrl.replace(':id', id);

    const response = await fetch(url, { method: 'DELETE' });

    if (response.ok) {
      this.renderDeleteConfirmation(id);
      this.setState(prevState => ({
        notes: prevState.notes.filter(note => note.id !== id),
      }));
    }
  };

  renderDeleteConfirmation(id) {
    const noteDelete = document.createElement('div');
    noteDelete.classList.add('lj-applicationNotes-note', 'note-trail');
    noteDelete.setAttribute('id', 'trail');
    noteDelete.innerHTML = 'Note successfully deleted';

    document.getElementById(`note-${id}`).after(noteDelete);

    setInterval(() => {
      $('#trail').fadeOut(() => $('#trail').remove());
    }, 5000);
  }

  handleChange = event => {
    this.setState({ value: event.target.value });
  };

  render() {
    return (
      <div className='lj-application-notes'>
        <div className={styles.applicationNotes}>
          <p className='lj-applicationNotes-helperText'>
            Adding notes to the application helps you keep track of the recruitment process.
          </p>
          <div className='lj-applicationNotes-privateInfo'>
            <InlineIcon alt='eye-closed' path={eyeIcon} data={{ state: 'closed' }} />
            <span>
              This info <strong>will not</strong> be visible to the candidate.
            </span>
          </div>
          <form className='lj-notes-form' onSubmit={this.handleNoteSubmit}>
            <textarea
              className='text autoExpand lj-input lj-input--mid lj-input--block u-marginBottom--small'
              placeholder='Type your note here…'
              onKeyPress={this.handleKeyPress}
              value={this.state.value}
              onChange={this.handleChange}
              ref={this.input}
            />
            <button
              className='lj-button lj-button--ripePlum lj-button--xSmall'
              style={{ marginBottom: '0.5rem' }}
              type='submit'
            >
              Add note
            </button>
          </form>
          {this.state.loading ? (
            this.renderLoadingAnimation()
          ) : (
            <NoteList
              notes={this.state.notes}
              note_destroy_url={this.props.destroyNoteUrl}
              handleDeleteNote={this.handleNoteDelete}
            />
          )}
        </div>
      </div>
    );
  }

  renderLoadingAnimation = () => {
    return (
      <div style={{ position: 'relative' }}>
        <DotLoader />
      </div>
    );
  };
}

const NoteList = props => {
  if (props.notes.length === 0) {
    return (
      <div className='lj-applicationNotes-notes'>
        <div className='ld-application-empty-state' id='empty-note'>
          Your notes will appear here
        </div>
      </div>
    );
  }

  return (
    <div className='lj-applicationNotes-notes'>
      {props.notes.map(note => {
        return (
          <NoteDetail
            key={note.id}
            note={note}
            note_destroy_url={props.note_destroy_url}
            handleDeleteNote={props.handleDeleteNote}
          />
        );
      })}
    </div>
  );
};

const NoteDetail = props => {
  const { employee, order, step, text, created_at } = props.note.attributes;
  const date = formatDate(created_at, {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });

  const converter = new showdown.Converter();
  converter.setOption('simpleLineBreaks', true);
  converter.setOption('strikethrough', true);

  return (
    <div className='lj-applicationNotes-note' id={`note-${props.note.id}`}>
      <div className='lj-applicationNotes-header'>
        <div className='info'>
          <div className='step'>
            <div className='ld-status-color u-fill--ripePlum' />
            {step}
          </div>
          <div className='date'>
            {date}
            {employee !== 'Landing.Team' && (
              <a onClick={e => props.handleDeleteNote(e, props.note.id)} className='lj-trashNote'>
                <InlineIcon alt='trash-icon' path={trashIcon} />
              </a>
            )}
          </div>
        </div>
        <div className='author'>{employee}</div>
      </div>
      <div className='lj-applicationNotes-body'>
        <div
          className='text'
          dangerouslySetInnerHTML={{
            __html: converter.makeHtml(text),
          }}
        />
      </div>
    </div>
  );
};
