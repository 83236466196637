import React from 'react';
import { render } from '@testing-library/react';
import selectEvent from 'react-select-event';
import EngagementPowerModal from './engagement_power_modal';

window.Routes = { backoffice_company: jest.fn() };

const stateStub = {
  createPower: jest.fn(),
  deactivatePower: jest.fn(),
  deactivatePowers: jest.fn(),
  engagementPowers: [
    {
      id: '1',
      createdAt: '2020-01-01',
      company: { id: '1', name: 'TechFoo' },
      level: 'engaged',
    },
  ],
  errors: [],
  loading: false,
  loadPowers: jest.fn(),
  managedCompanies: [{ id: '2', name: 'TechBar' }],
  setSelectedCompany: jest.fn(),
  setSelectedLevel: jest.fn(),
};

describe('EngagementPowerModal', () => {
  it('should open the modal with the list of existing engagement powers', () => {
    const { getByText } = render(<EngagementPowerModal state={stateStub} />);
    getByText('Engagement Power').click();
    const companyName = stateStub.engagementPowers[0].company.name;
    expect(getByText(/Uncle Ben/)).toBeTruthy();
    expect(getByText(companyName)).toBeTruthy();
  });

  it('filling the inputs and submitting should create a power', async () => {
    const { getByText, getByTestId } = render(<EngagementPowerModal state={stateStub} />);
    getByText('Engagement Power').click();
    const companySelect = getByTestId('CompanySelect').querySelector('input');
    const levelSelect = getByTestId('EngagementSelect').querySelector('input');
    await selectEvent.select(companySelect, stateStub.managedCompanies[0].name);
    expect(stateStub.setSelectedCompany.mock.calls.length).toBe(1);
    await selectEvent.select(levelSelect, 'Disengaged');
    expect(stateStub.setSelectedLevel.mock.calls.length).toBe(1);
    getByText('Save').click();
    await selectEvent.select(levelSelect, 'Disengaged');
    expect(stateStub.createPower.mock.calls.length).toBe(1);
  });
});
