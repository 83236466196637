import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import ExpiresIn from './expiresIn';
import Icon from 'components/general/icon/icon';

let props;
let wrapped: ShallowWrapper;

describe('ExpiresIn minus than 15 days', () => {
  beforeEach(() => {
    props = {
      days: 3,
    };
    wrapped = shallow(<ExpiresIn {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('has one icon', () => {
    expect(wrapped.find(Icon)).toHaveLength(1);
  });

  it('shows correct text', () => {
    expect(wrapped.find('.text').text()).toEqual('Expires in 3 days');
  });

  it('color is flamingo', () => {
    expect(wrapped.render().find('.color-flamingo')).toHaveLength(1);
  });
});

describe('ExpiresIn in exactly 15 days', () => {
  beforeEach(() => {
    props = {
      days: 15,
    };
    wrapped = shallow(<ExpiresIn {...props} />);
  });

  it('shows correct text', () => {
    expect(wrapped.find('.text').text()).toEqual('Expires in 15 days');
  });

  it('color is tuftsBlue', () => {
    expect(wrapped.render().find('.color-tuftsBlue')).toHaveLength(1);
  });
});

describe('ExpiresIn more than 15 days', () => {
  beforeEach(() => {
    props = {
      days: 17,
    };
    wrapped = shallow(<ExpiresIn {...props} />);
  });

  it('shows correct text', () => {
    expect(wrapped.find('.text').text()).toEqual('Expires in 17 days');
  });

  it('color is tuftsBlue', () => {
    expect(wrapped.render().find('.color-tuftsBlue')).toHaveLength(1);
  });
});
