import React from 'react';
import ApplicationEvaluation from './application_evaluation';
import { Tests } from './tests_item';
import { NoItem } from './evaluation_items';
import { mount } from 'enzyme';
import ApplicationCard from './application_card/application_card';
import RatingContainer from 'components/general/rating/rating_container';
import applicationDummy from '__fixtures__/application_dummy';

let wrapped;

window.Routes = {
  assessment_pdf: () => '',
};

describe('Application Evaluation component with completed test result', () => {
  beforeAll(() => {
    wrapped = mount(
      <ApplicationEvaluation
        dashboard={false}
        application={applicationDummy}
        sendTest={jest.fn()}
      />
    );
  });

  afterAll(() => {
    wrapped.unmount();
  });

  it('renders correctly', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('is wrapped in Application Card', () => {
    expect(wrapped.find(ApplicationCard)).toHaveLength(1);
  });

  it('has a test result', () => {
    expect(wrapped.find('.lj-test-card')).toHaveLength(1);
  });

  it('has a test result card', () => {
    expect(wrapped.find(Tests)).toHaveLength(1);
    expect(wrapped.find(NoItem)).toHaveLength(0);
  });

  it('shows 3 card elements', () => {
    expect(wrapped.find('.lj-user-tests')).toHaveLength(3);
  });

  it('shows the test name', () => {
    expect(wrapped.find('.test-name').text()).toBe(
      applicationDummy.attributes.test_results[0].test_name
    );
  });

  it('shows the hiring step name', () => {
    expect(wrapped.find('.hiring-step-name').text()).toMatch(
      applicationDummy.attributes.test_results[0].hiring_step
    );
  });

  it('shows the test score', () => {
    expect(wrapped.find('.test-score').text()).toBe(
      `${String(applicationDummy.attributes.test_results[0].score)}%`
    );
  });
});

describe('Application evaluation has no test results and has screening notes', () => {
  beforeAll(() => {
    applicationDummy.attributes.test_results = [];
    applicationDummy.attributes.screening_notes = 'Great candidate!';
    wrapped = mount(
      <ApplicationEvaluation
        dashboard={false}
        application={applicationDummy}
        sendTest={jest.fn()}
      />
    );
  });

  afterAll(() => {
    wrapped.unmount();
  });

  it('has no test result card', () => {
    expect(wrapped.find(Tests)).toHaveLength(0);
  });

  it('has no ratings card', () => {
    expect(wrapped.find(RatingContainer)).toHaveLength(0);
  });

  it('shows 3 wrapped card elements', () => {
    expect(wrapped.find('.lj-user-tests')).toHaveLength(3);
    expect(wrapped.find('.lj-no-item')).toHaveLength(2);
  });
});

describe('Application with tech assessment that has not been sent yet', () => {
  beforeAll(() => {
    applicationDummy.attributes.test_results = [];

    applicationDummy.attributes.current_state = 'step-4475';
    wrapped = mount(
      <ApplicationEvaluation
        dashboard={false}
        application={applicationDummy}
        sendTest={jest.fn()}
      />
    );
  });

  afterAll(() => {
    wrapped.unmount();
  });

  it('shows a Send Test button', () => {
    expect(wrapped.find('.send-test')).toHaveLength(1);
  });
});

describe('Application with tech assessment that has been sent', () => {
  beforeAll(() => {
    applicationDummy.attributes.test_results = [
      {
        id: 10,
        score: null,
        date: null,
        test_name: 'Landing.jobs Full-stack Developer',
        hiring_step: 'Hacker Rank test',
        report_url: null,
        completed: false,
        external_id: '9a0ecgpo5bp',
        pdf_url: null,
        test_id: 426548,
      },
    ];
    wrapped = mount(<ApplicationEvaluation application={applicationDummy} sendTest={jest.fn()} />);
  });

  afterAll(() => {
    wrapped.unmount();
  });

  it('shows a Test Sent button', () => {
    expect(wrapped.find('.test-sent')).toHaveLength(1);
  });
});

describe('Application with test link on the candidate side', () => {
  beforeAll(() => {
    const newProps = { data: applicationDummy };

    applicationDummy.attributes.test_results = [
      {
        id: 10,
        score: null,
        date: null,
        test_name: 'Landing.jobs Full-stack Developer',
        hiring_step: 'Hacker Rank test',
        report_url: undefined,
        completed: false,
        external_id: '9a0ecgpo5bp',
        pdf_url: undefined,
        test_id: 426548,
      },
    ];

    wrapped = mount(
      <ApplicationEvaluation dashboard={true} application={newProps as any} sendTest={jest.fn()} />
    );
  });

  afterAll(() => {
    wrapped.unmount();
  });

  it('has a test result', () => {
    expect(wrapped.find('.lj-candidate-test-card')).toHaveLength(1);
  });

  it('has a link to the test', () => {
    expect(wrapped.find('.right a').props().href).toBe(
      applicationDummy.attributes.test_results[0].pdf_url
    );
  });
});

describe('Application with completed test on the candidate side', () => {
  beforeAll(() => {
    const newProps = { data: applicationDummy };

    applicationDummy.attributes.test_results = [
      {
        id: 9,
        score: 26.31,
        completed: true,
        date: '2019-05-21T15:07:40.000Z',
        test_name: 'Landing.jobs Full-stack Developer',
        hiring_step: 'Hacker Rank test',
        report_url: 'https://www.hackerrank.com/x/tests/426548/candidates/9528406/report',
        external_id: '9a0ecgpo5bp',
        pdf_url: 'https://www.hackerrank.com/x/tests/426548/candidates/9528406/pdf',
        test_id: 426548,
      },
    ];

    wrapped = mount(
      <ApplicationEvaluation dashboard={true} application={newProps as any} sendTest={jest.fn()} />
    );
  });

  afterAll(() => {
    wrapped.unmount();
  });

  it('has a test result', () => {
    expect(wrapped.find('.lj-candidate-test-card')).toHaveLength(1);
  });

  it('shows the test score', () => {
    expect(wrapped.find('.test-score').text()).toBe(
      `${String(applicationDummy.attributes.test_results[0].score)}%`
    );
  });
});
