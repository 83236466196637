import * as React from 'react';
import RatingContainer from 'components/general/rating/rating_container';

import { NoItem } from './evaluation_items';

export const RatingItem = (
  ratings,
  rateApplicationUrl,
  hideCurationNotesIfLegacy,
  dashboard,
  screeningNotes,
  outdated,
  concern,
  concernNote,
  concernLevel
) => {
  if (ratings.length === 0) {
    return NoItem('There is no Screening for this application.');
  }
  return (
    <RatingContainer
      concern={concern}
      concernNote={concernNote}
      concernLevel={concernLevel}
      ratings={ratings}
      rateApplicationUrl={rateApplicationUrl}
      showRatingBody={true}
      screeningNotes={screeningNotes}
      hideCurationNotesIfLegacy={hideCurationNotesIfLegacy}
      dashboard={dashboard}
      outdated={outdated}
    />
  );
};
