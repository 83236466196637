import React from 'react';
import TagWithTooltip from 'components/general/tag_with_tooltip/tagWithTooltip';
const styles = require('./LabelWithStarIcon.module.scss');
import classNames from 'classnames';

interface Props {
  label: string;
  value: boolean;
  styles?: string;
}

export default function LabelWithStarIcon(props: Props) {
  return (
    <span>
      <span className={props.styles}>{props.label}</span>
      <span
        className={classNames(styles.wrapper, {
          [styles.skillsIcon]: props.label === 'Skills',
        })}
      >
        <TagWithTooltip
          tooltipText='Filling in this field is essential to make your profile more appealing to companies.
        This can also accelerate the hiring process and increase your chances of landing a job.'
          newIcon='starCircle'
          iconColor={props.value ? 'silverSand' : 'black'}
          textBelow
          style={{
            fontSize: '0.6875rem',
            fontWeight: '500',
            textTransform: 'none',
            letterSpacing: 'normal',
          }}
        />
      </span>
    </span>
  );
}
