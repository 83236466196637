import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import 'lib/globals';
import { getter } from 'lib/object';
import { makeState } from './state';
import Badge, { Color } from 'components/general/badge/Badge';
import Button from 'lj_shared/button/button';
import Modal from 'components/general/modal/modal';
import Select from 'components/form/select/select';
import Spinner from 'shared/dot_spinner/DotSpinner';
const styles = require('./engagement_power_modal.module.scss');

const LEVELS = [
  { color: 'red' as Color, label: 'Disengaged', value: 'disengaged' },
  { color: 'blue' as Color, label: 'Engaged', value: 'engaged' },
  { color: 'green' as Color, label: 'Very Engaged', value: 'very_engaged' },
];

interface Props {
  state?: any;
}

export default function EngagementPowerModal(props: Props) {
  const state = props.state || makeState();
  const modalRef = useRef(null);
  const buttonClass = classNames(
    'ld-button ld-small-button ld-blue-button ld-border-button',
    styles.openButton
  );

  return (
    <>
      <Button
        buttonColor='tuftsBlue'
        buttonSize='small'
        buttonType='border'
        onClick={() => modalRef.current.open()}
        otherClasses={buttonClass}
        otherClassesOnly={true}
      >
        Engagement Power
      </Button>
      <Modal className={styles.modal} defaultOpen={false} ref={modalRef} title='Engagement Power'>
        <ModalContent state={state} />
      </Modal>
    </>
  );
}

function ModalContent({ state }) {
  useEffect(() => {
    state.loadPowers();
  }, [state.initialized]);

  return (
    <div className={styles.modalContent}>
      <p className={styles.intro}>
        Keeping in mind all your knowledge of the companies you work with, you can give them{' '}
        <strong>engagement power</strong>.
      </p>
      <p>
        <q className={styles.quote}>With great power comes great responsibility</q> - Uncle Ben
      </p>

      <hr className={styles.separator} />
      {state.loading && <Spinner />}
      {!state.loading && (
        <div className={styles.inputs}>
          <Select
            error={state.errors['company']}
            getOptionLabel={getter('name')}
            getOptionValue={getter('id')}
            onChange={state.setSelectedCompany}
            options={state.managedCompanies}
            placeholder='Company'
            testId='CompanySelect'
            wrapperClassName={styles.companyInput}
          />
          <Select
            error={state.errors['level']}
            options={LEVELS}
            onChange={state.setSelectedLevel}
            placeholder='Engagement'
            testId='EngagementSelect'
            wrapperClassName={styles.engagementInput}
          />
          <Button buttonColor='jaffa' disabled={state.loading} onClick={state.createPower}>
            Save
          </Button>
        </div>
      )}

      <hr className={styles.separator} />

      {state.loading ? <Spinner /> : renderTable(state)}
    </div>
  );
}

function renderTable(state) {
  if (state.engagementPowers.length > 0) {
    return (
      <>
        <p>Currently you have shared your engagement power with:</p>

        <table className={styles.table}>
          <thead>
            <tr>
              <th>Company</th>
              <th>Engagement</th>
              <th>Created at</th>
              <th />
            </tr>
          </thead>
          <tbody>{state.engagementPowers.map(renderTableEntry.bind(null, state))}</tbody>
        </table>

        <Button
          buttonColor='jaffa'
          disabled={state.loading}
          onClick={state.deactivatePowers}
          otherClasses={styles.removeAllButton}
        >
          Remove all
        </Button>
      </>
    );
  }
}

function renderTableEntry(state, power) {
  const level = LEVELS.find(level2 => level2.value === power.level);

  return (
    <tr key={power.id}>
      <td>
        <a
          href={window.Routes.backoffice_company(power.company.id)}
          target='_blank'
          rel='noreferrer'
        >
          {power.company.name}
        </a>
      </td>
      <td>
        <Badge color={level.color}>{level.label}</Badge>
      </td>
      <td>{formatDate(power.createdAt)}</td>
      <td>
        <a href='#' onClick={state.deactivatePower.bind(null, power)}>
          Remove
        </a>
      </td>
    </tr>
  );
}

function formatDate(date) {
  const format = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  const [month, , day, , year] = format.formatToParts(Date.parse(date));
  return `${year.value}-${month.value}-${day.value}`;
}
