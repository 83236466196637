export const concernsTooltips = {
  location:
    "E.g. The company states they'll offer visa support to candidates only certain conditions and this candidate doesn't meet those requirements or they only want candidates from specific locations",
  language:
    "E.g. The company states that they only want candidates that speak 2 languages and the candidate doesn't speak one of them.",
  skills:
    'E.g. The company states that they only want candidates with at least 5 years of experience on specific skill which is not the case.',
  experience_level:
    "E.g. The company states that they only want candidates with a background in a specific type of company or role and the candidate doesn't meet those requirements.",
  lack_of_information:
    "E.g. The candidate doesn't have a link that is required as a portfolio or some other important information missing.",
  no_concerns: 'No Concerns',
};
