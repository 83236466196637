import React, { useState, useEffect } from 'react';
import Modal, { ButtonsLayout } from '../general/modal/modal';
import { getJSON, handleRequestError } from '../../lib/request_deprecated';
import track from '../../lib/drip';
import WhatsNewItem, { Feature } from './WhatsNewItem';
import Icon, { Size } from 'components/general/icon/icon';
import classNames from 'classnames';
import { palette } from 'lib/styles';

const styles = require('./WhatsNewModalItem.module.scss');

interface Props {
  feature?: Feature;
  isCompany: boolean;
  isPreview?: boolean;
  setOpenModal?: Function;
}

const emptyFeature = {} as Feature;

export default function WhatsNewModalItem(props: Props) {
  const { feature, setOpenModal, isPreview } = props;
  const [loading, setLoading] = useState(true);
  const [featureBody, setFeatureBody] = useState(feature || emptyFeature);
  useEffect(() => {
    if (feature) {
      getJSON(
        window.Routes.backoffice_new_feature_body({
          new_feature_id: feature.id,
        })
      )
        .then(({ body }) => setFeature({ ...featureBody, body }))
        .catch(handleRequestError);
    } else {
      getJSON(props.isCompany ? window.Routes.new_feature_employer() : window.Routes.new_feature())
        .then(({ serialized_feature }) => setFeature(serialized_feature.data.attributes))
        .catch(handleRequestError);
    }
  }, []);

  const setFeature = newFeature => {
    setLoading(false);
    setFeatureBody(newFeature);
  };

  const Title = () => (
    <div className={styles.titleBar}>
      <Icon
        name='error'
        className={classNames(
          styles.errorIcon,
          props.isCompany ? styles.errorIconCompany : styles.errorIconTalent
        )}
        size={Size.MediumBig}
      />
      <div className={styles.titleText}>WHAT'S NEW?</div>
    </div>
  );

  const bannerStyles = () => ({
    backgroundColor: props.isCompany ? palette('ripePlum') : palette('tuftsBlue'),
  });

  const closeIconStyles = () => ({
    fill: 'white',
  });

  const handleGotIt = () => {
    props.isCompany
      ? getJSON(window.Routes.new_feature_seen_employer())
      : getJSON(window.Routes.new_feature_seen());

    setOpenModal && setOpenModal(false);
    track('action', {
      name: 'whats-new-modal',
      controller: 'new_features',
      action: 'clicked-got-it',
    });
  };
  return (
    <Modal
      title={<Title />}
      defaultOpen={true}
      onClose={isPreview ? undefined : handleGotIt}
      styles={{ header: bannerStyles, closeIcon: closeIconStyles }}
      className={styles.modalStyle}
      buttonsLayout={ButtonsLayout.OkOnly}
      buttonColor={props.isCompany ? 'ripePlum' : 'tuftsBlue'}
      buttonName='Got It!'
      buttonOnClick={isPreview ? undefined : handleGotIt}
      buttonClass={styles.button}
      noPaddingMobile={true}
    >
      <div
        className={classNames(
          styles.featureWrapper,
          props.isCompany ? styles.featureWrapperCompany : styles.featureWrapperTalent
        )}
      >
        <div
          className={classNames(
            styles.centerPart,
            props.isCompany ? styles.centerPartCompany : styles.centerPartTalent
          )}
        >
          <WhatsNewItem loading={loading} feature={featureBody} />
        </div>
      </div>
    </Modal>
  );
}
