import React from 'react';
import TestNotice from './test_notice';
import AlertBox from '../../general/alert_box/alert_box';
import { shallow, ShallowWrapper } from 'enzyme';

let wrapped: ShallowWrapper;

describe('Test Notice component', () => {
  wrapped = shallow(<TestNotice />);

  it('renders correctly', () => {
    expect(wrapped.exists()).toBe(true);
  });

  it('to be wrapped in an AlertBox component', () => {
    expect(wrapped.find(AlertBox)).toHaveLength(1);
  });
});
