import 'lib/globals';

import { statusErrorMessage, defaultErrorMessage } from './status_error_messages';

export function executeRequest(url, options = {}) {
  const params = Object.assign({}, options);
  setIfUndef(params, 'credentials', 'same-origin');
  setIfUndef(params, 'headers', {});
  setIfUndef(params['headers'], 'Accept', 'application/json');
  return fetch(url, params);
}

export function postFormData(url, body, headers = {}) {
  return fetch(url, {
    method: 'POST',
    headers: {
      'X-CSRF-Token': getCSRFToken(),
      credentials: 'same-origin',
      ...headers,
    },
    body,
  }).then(response => response.json());
}

export const getJSON = (url, followup, options = {}) => {
  executeRequest(url, options)
    .then(response => {
      const isError = handleStatusErrors(response.status);

      if (isError) {
        throw { alert: isError };
      }

      return response.json();
    })
    .then(followup)
    .catch(handleRequestError);
};

export function postJSON(url, body?, followup = null, options = {}) {
  return writeRequest('POST', url, body, options, followup);
}

export function deleteJSON(url, body?, followup = null, options = {}) {
  return writeRequest('DELETE', url, body, options, followup);
}

export function patchJSON(url, body?, followup = null, options = {}) {
  return writeRequest('PATCH', url, body, options, followup);
}

export function writeRequest(method, url, body?, options = {}, followup = null) {
  const params = { method, body: JSON.stringify(body), ...options };
  setIfUndef(params, 'headers', {});
  setIfUndef(params['headers'], 'Content-Type', 'application/json');
  params['headers']['X-CSRF-Token'] = getCSRFToken();
  return getJSON(url, followup, params);
}

export function handleRequestError(e) {
  if (e.alert) {
    window.Alerts && window.Alerts.alert(e.alert);
  } else if (e.message) {
    window.Alerts && window.Alerts.alert(e.message);
  } else {
    window.Alerts && window.Alerts.alert(defaultErrorMessage);
  }
  window.toggleSpinner && window.toggleSpinner(false);
}

const handleStatusErrors = (status: number) => {
  if (status >= 400) {
    window.toggleSpinner && window.toggleSpinner(false);
    return statusErrorMessage(status);
  }
  return false;
};

export function handleSuccessAlert(e) {
  if (e.notice) {
    window.Alerts && window.Alerts.notice(e.notice);
  } else if (e.alert) {
    window.Alerts && window.Alerts.alert(e.alert);
  }
}

function setIfUndef(object, key, value) {
  const current = object[key];

  if (current === undefined) {
    return (object[key] = value);
  } else {
    return current;
  }
}

export function getCSRFToken() {
  const element = document.querySelector('meta[name=csrf-token]');
  const token = element && element.getAttribute('content');
  return token;
}
