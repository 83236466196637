import React from 'react';
import Button from 'lj_shared/button/button';
import 'lib/globals';
const styles = require('./WhatIsAContractor.module.scss');

export default function WhatIsAContractor() {
  return (
    <div className={styles.wrapper} id='contractor'>
      <div className={styles.leftSide}>i</div>
      <div className={styles.rightSide}>
        <div className={styles.title}>This job also accepts contractors</div>
        <div className={styles.text}>
          A contractor is someone who works as an independent worker or through their own company to
          provide services to 1 client at a time on a mid to long-term project basis. This is a
          great option to work remotely to companies from abroad.
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            buttonColor='tuftsBlue'
            buttonType='border'
            buttonUrl={window.Routes.jobs({ con: 'true' })}
            buttonSize='xSmall'
            otherClasses={styles.contractorButton}
          >
            Show me jobs that accept contractors
          </Button>
        </div>
      </div>
    </div>
  );
}
