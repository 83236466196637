import * as React from 'react';
import Icon, { Size } from 'components/general/icon/icon';
import JobOpeningFilter from 'components/general/job_opening_filter/job_opening_filter';

const styles = require('./HeaderJobFilter.module.scss');

interface Props {
  applyFilters: Function;
  followedJobs: any;
  job_id: number;
  otherJobs: any;
  setFilter: Function;
}

export const HeaderJobFilter = (props: Props) => {
  function renderFilters() {
    const selected = [props.job_id];
    const { followedJobs, otherJobs } = props;
    const optGroups = [
      { groupValue: 1, groupLabel: 'My jobs' },
      { groupValue: 2, groupLabel: 'Other Jobs' },
    ];
    const items = [];
    [followedJobs, otherJobs].forEach(jobs => {
      jobs.forEach(job => {
        items.push({
          id: job.id,
          title: job.attributes.location
            ? `${job.attributes.title} (${job.attributes.location})`
            : job.attributes.title,
          value: job.id,
          group: job.attributes.group,
        });
      });
    });
    return (
      <JobOpeningFilter
        items={items}
        itemRender={itemRender}
        optGroups={optGroups}
        onChange={handleJobChange}
        selected={selected}
      />
    );
  }

  function itemRender(job) {
    return <div id={job.id}>{job.title}</div>;
  }

  function handleJobChange(job) {
    if (job && +job.value !== props.job_id) {
      props.setFilter(+job.value, 'job_id');
      props.applyFilters();

      if ('URLSearchParams' in window) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('job_id', `${+job.value}`);
        window.location.search = searchParams.toString();
      }
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <Icon name='briefcase' size={Size.MediumBig} className={styles.jobIcon} />
        <a className={styles.jobLink} href={window.Routes.employer_job_ads()}>
          Jobs
        </a>
        {'>'}
      </div>
      <div className={styles.jobFilter}> {renderFilters()}</div>
    </div>
  );
};
