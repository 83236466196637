import * as React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import RatingBody from './rating_body';

const createProps = () => ({
  title: 'Screening',
  landingRatingId: 86605,
  landingRating: 5,
  dimensions: [],
  screeningNotes: '<p>has excel, sql, bi tools</p>',
  showRatingBody: true,
  hideCurationNotesIfLegacy: false,
});

let props;
let wrapped: ShallowWrapper;

describe('Rating Container component', () => {
  beforeEach(() => {
    props = createProps();
    wrapped = shallow(<RatingBody {...props} />);
  });

  it('shallow renders without crashing', () => {
    expect(wrapped.exists()).toBe(true);
  });
});

test.todo('add more tests');
