import * as React from 'react';
import Sidebar from './sidebar';

import { shallow } from 'enzyme';

const companyProps = {
  userType: 'CompanyUser',
  active: 'overview',
  userImage: "<a href='#'>",
  userName: 'Manuel',
  counter: { Handshakes: 1 },
};

const candidateProps = {
  userType: 'TalentUser',
  active: 'overview',
  userImage: "<a href='#'>",
  userName: 'Manuel',
  counter: { Handshakes: 1 },
};

describe('Sidebar Component', () => {
  test('should render company sidebar', () => {
    const component = shallow(<Sidebar {...companyProps} />);

    expect(component).toMatchSnapshot();
    expect(component.text()).toEqual('<CompanySidebar />');
  });

  test('should render talent sidebar', () => {
    const component = shallow(<Sidebar {...candidateProps} />);

    expect(component).toMatchSnapshot();
    expect(component.text()).toEqual('<TalentSidebar />');
  });
});
