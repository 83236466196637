import classNames from 'classnames';
import React, { useContext } from 'react';
import Icon, { Size } from 'components/general/icon/icon';
import { JobCardCtx } from './JobCardContext';
import JobLocation from './JobLocation';
import JobExperience from './JobExperience';
import { isMobile } from 'lib/utils';

const styles = require('./JobDetails.module.scss');

const JobDetails = () => {
  const jobCardContext = useContext(JobCardCtx);
  const isMobileDevice = isMobile();

  const Title = () => (
    <span
      className={styles.title}
      onClick={() => {
        !isMobileDevice &&
          window.open(
            window.Routes.company_job_ad(
              jobCardContext.job.company.slug || jobCardContext.job.company.id,
              jobCardContext.job.slug
            )
          );
      }}
    >
      {jobCardContext.isHottest && (
        <Icon
          name='flame'
          size={Size.MediumBig}
          color='flamingo'
          className={styles.hotIcon}
          display='inlineFlexCenter'
        />
      )}
      <span>{jobCardContext.job.title}</span>
    </span>
  );

  const HiringBonus = () => (
    <>
      {jobCardContext.job.hiringBonus && (
        <div
          className={styles.hiringBonus}
        >{`Land this job and get € ${jobCardContext.job.hiringBonus}`}</div>
      )}
    </>
  );

  const JobSalary = () => (
    <>
      {jobCardContext.job.rateAndSalary ? (
        <div className={styles.salary}>
          <span>{jobCardContext.job.rateAndSalary}</span>
        </div>
      ) : (
        (jobCardContext.job.salary || jobCardContext.job.rate) && (
          <div className={styles.jobCardSalary}>
            {jobCardContext.job.salary && <span>GAS {jobCardContext.job.salary}</span>}
            {jobCardContext.job.salary && jobCardContext.job.rate && (
              <span className={styles.salarySeparator}>|</span>
            )}
            {jobCardContext.job.rate && <span>Rate / day {jobCardContext.job.rate}</span>}
          </div>
        )
      )}
    </>
  );

  const JobTimezone = () => (
    <>
      {jobCardContext.job.timezoneTolerance && (
        <div className={styles.jobCardTimezone}>
          <span className={styles.timezoneIcon}>
            <Icon name='minimalistClock' />
          </span>
          <span>{jobCardContext.job.timezoneTolerance}</span>
        </div>
      )}
    </>
  );

  const Company = () => (
    <div className={styles.jobCardCompany}>
      <a
        href={window.Routes.company(
          jobCardContext.job.company.slug || jobCardContext.job.company.id
        )}
        target='_blank'
        onClick={e => e.stopPropagation()}
        rel='noreferrer'
      >
        {jobCardContext.job.company.name}
      </a>
    </div>
  );

  const JobSkills = () => (
    <div className={styles.skills}>
      {jobCardContext.job.tags?.map((tag, i) => (
        <div className={styles.skill} key={i}>
          {tag.name}
        </div>
      ))}
    </div>
  );

  return (
    <div className={classNames(styles.jobCardDetails)}>
      <div className={styles.firstRow}>
        <Title />
        <HiringBonus />
        <JobLocation />
      </div>
      <div className={styles.secondRow}>
        <JobSalary />
        <JobExperience />
        <JobTimezone />
      </div>
      <Company />
      <JobSkills />
    </div>
  );
};

export default JobDetails;
