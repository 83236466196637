import React from 'react';
import HorizontalCardEntry from './horizontal_card_entry';

const styles = require('./horizontal_card_content.module.scss');

export default function HorizontalCardContent(props) {
  return (
    <div className={styles.container}>
      {props?.content &&
        props.content.map((content, index) => (
          <HorizontalCardEntry
            key={`${props.contentKey}-${index}`}
            handleDelete={props.handleDelete}
            openModal={props.openModal}
            type={props.contentKey}
            public={props.public}
            ljColor={props.ljColor}
            publicProfilePage={props.publicProfilePage}
            {...content}
          />
        ))}
      {props.children}
    </div>
  );
}
