import React from 'react';
import Icon, { Size } from '../icon/icon';
import sanitizeHtml from 'sanitize-html-react';

const styles = require('./rating_notes.module.scss');

interface Props {
  dimensions: any[];
  hideCurationNotesIfLegacy: boolean;
  screeningNotes?: string;
}

export default function RatingNotes(props: Props) {
  const { dimensions } = props;

  if (
    (props.hideCurationNotesIfLegacy || dimensions?.length === 0) &&
    props?.screeningNotes?.length === 0
  ) {
    return <></>;
  }
  const skillsDimensions = dimensions?.filter(note => note.dimension === 'skills')[0];

  const renderNotes = sDimensions => {
    if (sDimensions?.automatic_notes[0]?.startsWith('{')) {
      return (
        <>
          {resolveSkills(sDimensions?.automatic_notes)}
          {dimensions
            ?.filter(note => note.dimension !== 'skills')
            .filter(dimension => dimension.automatic_notes.length > 0)
            .map(dimension => dimension.automatic_notes.join(' '))
            .map((dimensionNote, index) => {
              if (dimensionNote) {
                return (
                  <div key={index} className={styles.noteGroup}>
                    <p className={styles.note}>{dimensionNote}</p>
                  </div>
                );
              }
            })}
        </>
      );
    } else {
      return dimensions
        .map(dimension => dimension.automatic_notes.join(' '))
        .map((dimensionNote, index) => {
          if (dimensionNote) {
            return (
              <div key={index} className={styles.noteGroup}>
                <p className={styles.note}>{dimensionNote}</p>
              </div>
            );
          }
        });
    }
  };

  return (
    <div className={styles.notes}>
      {renderNotes(skillsDimensions)}

      {props.screeningNotes && (
        <p
          className={styles.screeningNotes}
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(props.screeningNotes),
          }}
        />
      )}
    </div>
  );
}

export function resolveSkills(notes, isTooltip = false) {
  const skillNotes: {
    known: any[];
    unknown: any[];
  }[] = notes.map(s => JSON.parse(s.replaceAll(':', '"').replaceAll('=>', '":')));

  const knownSkills = skillNotes
    .map(s => s.known)
    .join()
    .split(',');

  const unknownSkills = skillNotes
    .map(s => s.unknown)
    .join()
    .split(',');

  if (notes && skillNotes) {
    return (
      <div className={styles.noteGroup}>
        {knownSkills?.map((skill, i) => {
          if (skill) {
            return (
              <div className={styles.note} key={`knownSkill${i}`}>
                <Icon
                  name='check'
                  color={isTooltip ? 'white' : 'puertoRico'}
                  size={isTooltip ? Size.QuiteSmall : Size.Medium}
                />
                <p className={styles.listNote}>{skill}</p>
              </div>
            );
          }
        })}
        {unknownSkills?.map((skill, i) => {
          return (
            <p className={`${styles.note} ${styles.unknownSkill}`} key={`unknownSkill${i}`}>
              {skill}
            </p>
          );
        })}
      </div>
    );
  }
}
